import React from "react";
import NormalModeForm from "./form/normal/index";
import AdvancedModeForm from "./form/advanced/index";
import ComponentAction from "./action";
import { Modal, Button } from "antd";
import { componentToRender } from "../../constants/index";

class ComponentModal extends React.Component {
    state = {
        formModal: {
            isVisible: false,
            componentToRender: "",
        },
        config: {
            title: "",
            width: 0,
            top: 0,
            okText: "",
            cancelText: "",
        },
    };

    config = ({ title, width = "60%", top = "10%", okText = "Guardar", cancelText = "Cancelar" }) => {
        this.setState({
            ...this.state,
            config: {
                ...this.state.config,
                title,
                width,
                top,
                okText,
                cancelText,
            },
        });
    };

    handleCancelFormModal = () => {
        this.setState({
            ...this.state,
            formModal: {
                ...this.state.formModal,
                isVisible: false,
            },
        });
    };

    render() {
        const normalModeFooter =
            this.props.componentToRender === componentToRender.normalForm
                ? [
                      <Button
                          style={{}}
                          type="primary"
                          onClick={() =>
                              this.setState({
                                  ...this.state,
                                  formModal: {
                                      isVisible: true,
                                      componentToRender: componentToRender.seeForm,
                                  },
                              })
                          }
                      >
                          Ver Formulário
                      </Button>,
                      <Button
                          style={{ marginLeft: 4, left: 0 }}
                          type="primary"
                          onClick={() =>
                              this.setState({
                                  ...this.state,
                                  formModal: {
                                      isVisible: true,
                                      componentToRender: componentToRender.runSQL,
                                  },
                              })
                          }
                      >
                          Correr SQL
                      </Button>,
                  ]
                : [];

        return (
            <Modal
                title={this.state.config.title}
                style={{ top: this.state.config.top }}
                width={this.state.config.width}
                visible={this.props.visible}
                onCancel={() => this.props.handleCancel()}
                footer={[
                    <Button style={{}} type="default" onClick={() => this.props.handleCancel()}>
                        Cancelar
                    </Button>,
                    ...normalModeFooter,
                    <Button style={{ marginLeft: 4, bottom: 0 }} type="primary" onClick={() => this.props.handleOk()}>
                        Guardar
                    </Button>,
                ]}
            >
                {this.props.componentToRender === componentToRender.normalForm ? (
                    <NormalModeForm
                        configModal={this.config.bind(this)}
                        cancelFormModal={this.handleCancelFormModal.bind(this)}
                        componentToRender={this.state.formModal.componentToRender}
                        isFormModalVisible={this.state.formModal.isVisible}
                    />
                ) : null}
                {this.props.componentToRender === componentToRender.advancedForm ? (
                    <AdvancedModeForm configModal={this.config.bind(this)} />
                ) : null}
                {this.props.componentToRender === componentToRender.action ? (
                    <ComponentAction configModal={this.config.bind(this)} data={this.props.data} />
                ) : null}
            </Modal>
        );
    }
}

export default ComponentModal;
