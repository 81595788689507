/* eslint-disable no-useless-escape */
import {
    componentType,
    graphicElement,
    xmlProps,
    componentStyle,
    fieldsToAssignAsInputTextIfNotHaveType,
} from "../constants/index";
import moment from "moment";
import { isEmpty } from "../../../business/utils";
import { Utilitaries } from "../../../business";

export const removeDuplicatedData = (data, key = undefined) => {
    let setObject = new Set();
    const newData = data?.reduce((accumulator, item) => {
        if (!key) {
            const arrayOfKeys = Object.keys(item);
            key = arrayOfKeys[0];
        }

        if (!setObject.has(item[key])) {
            setObject.add(item[key], item);
            accumulator.push(item);
        }

        return accumulator;
    }, []);

    return newData;
};

const capitalize = function (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

const removeArrayTypeInput = (form) => {
    const rootElement = form["@elemento_raiz"];

    if (!rootElement) {
        return form;
    }

    if (form[rootElement]) {
        Object.entries(form[rootElement]).forEach(([key, input]) => {
            if (typeof input === "object" && Array.isArray(input)) {
                if (input.length === 1) {
                    form[rootElement][key] = input[0];
                } else if (input.length > 1) {
                    const igualArray = input.filter(
                        (item) => item["@persiste"] === "nao" && item["@visivel"] === "nao"
                    );

                    const theyAreSome = igualArray.length === input.length;

                    if (theyAreSome) {
                        form[rootElement][key] = input[0];
                    }
                }
            }
        });
    }

    return form;
};

export const transformForm = ({ form }) => {
    let normalizedFormArray = [];
    let modalsState = {};
    let metaInfoformFields = {};

    form = removeArrayTypeInput(form);
    const { formAttributes, formFields } = getRootLevelField(form);
    const { usedFormFields } = getUiFields(formFields);
    const { formDesign } = addFirstLevelProps({
        formAttributes,
        usedFormFields,
    });
    //* Convert form tree to form array
    normalizedFormArray = Object.values(formDesign);

    // * Adding modals
    // normalizedFormArray = addModals(normalizedFormArray);
    let modals = addModals(normalizedFormArray);
    normalizedFormArray = normalizedFormArray.concat(modals);

    // * Adding properties that are missing in xml
    normalizedFormArray = setNewProperties(normalizedFormArray);

    // normalizedFormArray = xpathToJs(normalizedFormArray);

    // * Adding separators
    normalizedFormArray = addSeparators(normalizedFormArray);

    modals = normalizedFormArray.filter((item) => item["@tipo"] === componentType._popup);

    // * initial modal state
    if (modals.length > 0) {
        for (const modal of modals) {
            modalsState[modal.key] = {
                key: modal.key,
                isVisible: false,
            };
        }
    }

    // * Convert form array to form object
    const normalizedFormObject = normalizedFormArray.reduce((form, field) => {
        if (!Array.isArray(field)) {
            form[field.key] = { ...field };
        }
        return form;
    }, {});

    //* Getting meta form fields
    for (const key in formFields) {
        if (typeof formFields[key] === "string") {
            metaInfoformFields[key] = formFields[key];
        }
    }

    return {
        // normalizedFormObject: { ...metaInfoformFields, ...normalizedFormObject },
        normalizedFormObject,
        modalsState,
        metaInfoformFields,
        formAttributes,
    };
};

/**
 * * Extract the form's root element
 * * Extract meta form's properties
 */
const getRootLevelField = (form) => {
    let formAttributes = {};
    let formFields = {};
    for (const key in form) {
        if (key.startsWith("@")) {
            formAttributes[key] = form[key];
        } else {
            formFields = { ...form[key] };
        }
    }
    return { formAttributes, formFields };
};

/**
 * * Get writable form fields
 */
const getUiFields = (formFields) => {
    let usedFormFields = {};
    for (const key in formFields) {
        if (!key.startsWith("@")) {
            usedFormFields[key] = formFields[key];
        }
    }
    return { usedFormFields };
};

/**
 * *Adding extra properties to 1st level fields
 */
const addFirstLevelProps = ({ usedFormFields, formAttributes }) => {
    const formDesign = {};
    for (const key in usedFormFields) {
        /**
         * * object type fields
         */
        if (typeof usedFormFields[key] === "object") {
            formDesign[key] = {
                key,
                dataSource: [],
                formKey: formAttributes["@elemento_raiz"],
                formName: formAttributes["@nome"],
                ...usedFormFields[key],
            };
        } else {
            /**
             * * meta info
             */
            formDesign[key] = {
                key,
                value: usedFormFields[key],
            };
        }
    }
    return { formDesign };
};

export const downFile = (content, fileName, contentType) => {
    const a = document.createElement("a");

    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;

    a.click();
};

const getInputsWithTabInInputGroup = (inputs = {}, list = [], isInputGroup = false) => {
    for (const key in inputs) {
        if (typeof inputs[key] === "object" && !Array.isArray(inputs[key])) {
            if (inputs[key]["@tab"] && isInputGroup) {
                list.push(inputs[key]);
            } else if (inputs[key]["@tipo"] === componentType._inputGroup) {
                list = getInputsWithTabInInputGroup(inputs[key], list, true);
            }
        }
    }

    return list;
};

const addPropertyToObjectInArray = (obj, root = [], key, objectToAdd = {}) => {
    if (root.length === 0) {
        if (obj) {
            obj[key] = {
                ...obj[key],
                ...objectToAdd,
            };
        }
    } else {
        let el = root.shift();
        if (typeof obj === "object" && Array.isArray(obj)) {
            const index = obj.findIndex((item) => {
                return item && item.key === el;
            });
            obj[index] = addPropertyToObjectInArray(obj[index], root, key, objectToAdd);
        } else {
            obj[el] = addPropertyToObjectInArray(obj[el], root, key, objectToAdd);
        }
    }
    return obj;
};

const orderTabs = (allTabs, allFormInput) => {
    let newAllTabs = [];

    for (const tab of allTabs) {
        let tabIndex = -1;
        if (tab.inputGroupPath) {
            tabIndex = allFormInput.findIndex((it) => it.key === tab.inputGroupPath[0]);
        } else {
            tabIndex = allFormInput.findIndex((it) => it.key === tab.key);
        }

        newAllTabs.push({ ...tab, tabPosition: tabIndex });
    }

    newAllTabs.sort((a, b) => a.tabPosition - b.tabPosition);

    //
    return newAllTabs;
};

const addSeparators = (normalizedFormArray) => {
    // downFile(JSON.stringify(normalizedFormArray), "json.txt", "text/plain");
    const firstTabIndex = normalizedFormArray.findIndex((item) => item["@tab"]);
    // if input Group
    // array to object od inputs
    const fieldsObj = normalizedFormArray.reduce((accumulator, current) => {
        accumulator[current.key] = current;
        return accumulator;
    }, {});
    // Get all tabs inside input group
    const inputGroupTabs = getInputsWithTabInInputGroup(fieldsObj, []);

    let addedChildKeyes = []; //* cache values

    /**
     * * gets executed only if has a tab in form
     */
    if (firstTabIndex !== -1 || inputGroupTabs.length > 0) {
        let tabComponents = normalizedFormArray.slice(firstTabIndex);
        //
        // return;
        const tab = {
            key: `tab_${tabComponents[0] ? tabComponents[0].formKey : ""}`,
            "@tipo": componentType._tab,
            "@persiste": "nao",
            childKeys: [],
        };
        let separators = normalizedFormArray.filter((item) => item["@tab"]);

        let separatorsNormalized = [];

        const inputGroupRootIsTab = tabComponents.find((item) => item["@tipo"] === componentType._inputGroup);

        if (inputGroupRootIsTab || inputGroupTabs.length > 0) {
            // Get list of separetors
            separatorsNormalized = separators.reduce((acumulator, current) => {
                const separator = separators.filter((item) => item["@tab"] === current["@tab"]);

                if (
                    (separator.length > 1 && !acumulator.find((item) => item["@tab"] === current["@tab"])) ||
                    separator.length === 1
                ) {
                    if (separator[0]["@tipo"] === componentType._inputGroup) {
                        acumulator.push({ ...current, inputGroupPath: [separator[0].key] });
                        const list = getInputsWithTabInInputGroup(separator[0]);
                        acumulator.push(...list);
                    } else {
                        acumulator.push(current);
                    }
                }

                return acumulator;
            }, []);

            const allTabs = [...separatorsNormalized, ...inputGroupTabs];
            // inputGroupRootIsTab && separators.length > 0
            //     ? separatorsNormalized
            //     : [...separatorsNormalized, ...inputGroupTabs]
            const tabsLists = orderTabs(allTabs, normalizedFormArray);

            separatorsNormalized = tabsLists.map((item) => ({
                key: `sep_${item["@tab"]}`,
                childKeys: [],
                parent: tab.key,
                "@persiste": "nao",
                "@tipo": componentType._separator,
                name: item["@tab"],
                inputGroupPath: item.inputGroupPath,
            }));

            //----------------------------------------------------------------//
            for (const it of separatorsNormalized) {
                const itens = separatorsNormalized.filter((sep) => it.key === sep.key);

                if (itens?.length > 1) {
                    const last = itens[itens.length - 1];

                    const currentPosition = separatorsNormalized.indexOf(it);
                    const lastPosition = separatorsNormalized.indexOf(last);

                    if (currentPosition !== lastPosition) {
                        it.key = `${it.key}@${currentPosition}`;
                        // separatorsNormalized.splice(currentPosition, 1);
                    }

                    //
                }
            }
            //-----------------------------------

            for (const sep of separatorsNormalized) {
                let formItens = fieldsObj;
                // ;
                if (sep.inputGroupPath) {
                    formItens = getFieldInInputGroup(fieldsObj, [...sep.inputGroupPath]);

                    let fieldInputGroup = Object.values(formItens).filter(
                        (it) => typeof it === "object" && !Array.isArray(it)
                    );

                    let foundCurrentTab = false;

                    for (const [tabItemIndex, item] of Object.entries(fieldInputGroup)) {
                        // const index = fieldInputGroup.findIndex((formItem) => formItem.key === item.key);
                        /**
                         * * add field that points to tab
                         */
                        if (formItens?.["@tab"] === sep.name) {
                            foundCurrentTab = true;
                        }

                        if (item["@tab"] && item["@tab"] === sep.name) {
                            foundCurrentTab = true;
                            separatorsNormalized.find((s) => s.key === sep.key).childKeys.push(item.key);
                            addedChildKeyes.push(item.key);
                            normalizedFormArray = addPropertyToObjectInArray(
                                normalizedFormArray,
                                item.inputGroupPath ? [...item.inputGroupPath] : [],
                                item.key,
                                { parent: sep.key }
                            );
                        }
                        //

                        if (item["@tab"] && item["@tab"] !== sep.name && !addedChildKeyes.includes(item.key)) {
                            fieldInputGroup = fieldInputGroup.slice(tabItemIndex);
                            break;
                        }

                        /**
                         * * add fields that are next to tab pointer
                         */
                        if (!item["@tab"] && !addedChildKeyes.includes(item.key) && foundCurrentTab) {
                            separatorsNormalized.find((s) => s.key === sep.key).childKeys.push(item.key);
                            addedChildKeyes.push(item.key);
                            normalizedFormArray = addPropertyToObjectInArray(
                                normalizedFormArray,
                                item.inputGroupPath ? [...item.inputGroupPath] : [],
                                item.key,
                                { parent: sep.key }
                            );
                        }
                        /**
                         * * only add field that points to tab that was not yet being added
                         */
                        if (
                            item["@tab"] &&
                            !addedChildKeyes.includes(item.key) &&
                            item["@tab"] === sep.name &&
                            foundCurrentTab
                        ) {
                            separatorsNormalized.find((s) => s.key === sep.key).childKeys.push(item.key);
                            addedChildKeyes.push(item.key);
                            normalizedFormArray = addPropertyToObjectInArray(
                                normalizedFormArray,
                                item.inputGroupPath ? [...item.inputGroupPath] : [],
                                item.key,
                                { parent: sep.key }
                            );
                        }
                    }
                } else {
                    // pode ser input group
                    formItens = normalizedFormArray.filter((item) => item["@tab"] === sep.name);

                    for (const _item of formItens) {
                        let index = normalizedFormArray.findIndex((formItem) => formItem.key === _item.key);
                        const subItens = normalizedFormArray.slice(index);

                        for (const item of subItens) {
                            index = normalizedFormArray.findIndex((formItem) => formItem.key === item.key);
                            const itemParent = sep.key.split("@")[0];
                            /**
                             * * add field that points to tab
                             */
                            if (item["@tab"] && item["@tab"] === sep.name && !addedChildKeyes.includes(item.key)) {
                                separatorsNormalized.find((s) => s.key === sep.key).childKeys.push(item.key);
                                addedChildKeyes.push(item.key);
                                normalizedFormArray[index].parent = itemParent;
                            }
                            // /**
                            //  * * current separator is finished
                            //  */
                            if (item["@tab"] && item["@tab"] !== sep.name) {
                                // tabComponents = tabComponents.slice(tabItemIndex);
                                break;
                            }
                            /**
                             * * add fields that are next to tab pointer
                             */
                            if (
                                !item["@tab"] &&
                                !addedChildKeyes.includes(item.key) &&
                                item["@tipo"] !== componentType._popup
                            ) {
                                separatorsNormalized.find((s) => s.key === sep.key).childKeys.push(item.key);
                                addedChildKeyes.push(item.key);
                                normalizedFormArray[index].parent = itemParent;
                            }
                            /**
                             * * only add field that points to tab that was not yet being added
                             */
                            if (item["@tab"] && !addedChildKeyes.includes(item.key) && item["@tab"] === sep.name) {
                                separatorsNormalized.find((s) => s.key === sep.key).childKeys.push(item.key);
                                addedChildKeyes.push(item.key);
                                normalizedFormArray[index].parent = itemParent;
                            }
                        }
                    }
                }
            }

            //---------------------------------
            const separatorsNormalizedAux = [];

            for (const it of separatorsNormalized) {
                const itKey = it.key.split("@")[0];

                const itens = separatorsNormalized.filter((sep) => itKey === sep.key || it.key === sep.key);

                if (itens?.length > 1) {
                    const last = itens[itens.length - 1];

                    const currentPosition = separatorsNormalized.indexOf(it);
                    const lastPosition = separatorsNormalized.indexOf(last);

                    if (currentPosition !== lastPosition) {
                        const currentChildren = it.childKeys.map((child) =>
                            !it.inputGroupPath ? `root@${child}` : child
                        );
                        // it.key = `${it.key}@${currentPosition}`;
                        // separatorsNormalized[lastPosition].childKeys = [
                        //     ...separatorsNormalized[lastPosition].childKeys,
                        //     ...currentChildren,
                        // ];

                        const lasItem = separatorsNormalized[lastPosition];

                        const lastChildren = lasItem.childKeys.map((child) =>
                            !lasItem.inputGroupPath ? `root@${child}` : child
                        );
                        // it.key = `${it.key}@${currentPosition}`;
                        let uniqueList = [];
                        [...currentChildren, ...lastChildren].forEach((c) => {
                            if (!uniqueList.includes(c)) {
                                uniqueList.push(c);
                            }
                        });

                        separatorsNormalized[lastPosition].childKeys = uniqueList;

                        if (it.inputGroupPath && !separatorsNormalized[lastPosition].inputGroupPath) {
                            separatorsNormalized[lastPosition].inputGroupPath = it.inputGroupPath;
                        }
                    } else {
                        separatorsNormalizedAux.push(it);
                    }

                    //
                } else {
                    separatorsNormalizedAux.push(it);
                }
            }

            separatorsNormalized = separatorsNormalizedAux;
            //---------------------------------
        } else {
            separatorsNormalized = separators.reduce((acumulator, current) => {
                const separator = separators.filter((item) => item["@tab"] === current["@tab"]);
                if (separator.length > 1 && !acumulator.find((item) => item["@tab"] === current["@tab"])) {
                    acumulator.push(current);
                    return acumulator;
                } else if (separator.length === 1) {
                    acumulator.push(current);
                    return acumulator;
                }
                return acumulator;
            }, []);

            separatorsNormalized = separatorsNormalized.map((item) => ({
                key: `sep_${item["@tab"]}`,
                childKeys: [],
                parent: tab.key,
                "@persiste": "nao",
                "@tipo": componentType._separator,
                name: item["@tab"],
            }));

            /**
             * * add chils keyes to each separator
             */
            for (const sep of separatorsNormalized) {
                for (const [tabItemIndex, item] of Object.entries(tabComponents)) {
                    const index = normalizedFormArray.findIndex((formItem) => formItem.key === item.key);
                    /**
                     * * add field that points to tab
                     */
                    if (item["@tab"] && item["@tab"] === sep.name && !addedChildKeyes.includes(item.key)) {
                        separatorsNormalized.find((s) => s.key === sep.key).childKeys.push(item.key);
                        addedChildKeyes.push(item.key);
                        normalizedFormArray[index].parent = sep.key;
                    }
                    /**
                     * * current separator is finished
                     */
                    if (item["@tab"] && item["@tab"] !== sep.name) {
                        tabComponents = tabComponents.slice(tabItemIndex);
                        break;
                    }
                    /**
                     * * add fields that are next to tab pointer
                     */
                    if (
                        !item["@tab"] &&
                        !addedChildKeyes.includes(item.key) &&
                        item["@tipo"] !== componentType._popup
                    ) {
                        separatorsNormalized.find((s) => s.key === sep.key).childKeys.push(item.key);
                        addedChildKeyes.push(item.key);
                        normalizedFormArray[index].parent = sep.key;
                    }
                    /**
                     * * only add field that points to tab that was not yet being added
                     */
                    if (item["@tab"] && !addedChildKeyes.includes(item.key) && item["@tab"] === sep.name) {
                        separatorsNormalized.find((s) => s.key === sep.key).childKeys.push(item.key);
                        addedChildKeyes.push(item.key);
                        normalizedFormArray[index].parent = sep.key;
                    }
                }
            }
        }
        // ;
        tab.childKeys = separatorsNormalized.map((sep) => sep.key);
        separatorsNormalized.push(tab);
        normalizedFormArray = normalizedFormArray.concat(separatorsNormalized);
        return normalizedFormArray;
    }

    return normalizedFormArray;
};

/**
 * * Add separator components and setting it's child keys
 */
const addModals = (normalizedFormArray, modals = []) => {
    // let modals = [];
    // downFile(JSON.stringify(normalizedFormArray), "json.txt", "text/plain");
    for (const item of normalizedFormArray) {
        if (item["@popupform"]) {
            delete item["@tab"];
            delete item["@estilo"];
            modals.push({ ...item, key: item["@popupform"], "@tipo": componentType._popup, "@persiste": "nao" });
        } else {
            const inputKeys = Object.keys(item).filter(
                (key) => !key?.startsWith("@") && typeof item[key] === "object" && !Array.isArray(item[key])
            );
            if (inputKeys.length > 0) {
                const tmpFormArray = inputKeys.reduce((accumulator, current) => {
                    accumulator.push({
                        ...item[current],
                        key: current,
                    });
                    return accumulator;
                }, []);
                const aux = addModals(tmpFormArray, modals);
                modals.concat(aux);
            }
            // const aux = addModals()
            // for (const key in item) {
            //     if (typeof item[key] === "object") {
            //         if (item[key]["@popupform"]) {
            //             modals.push({
            //                 ...item[key],
            //                 key: item[key]["@popupform"],
            //                 "@tipo": componentType._popup,
            //                 "@persiste": "nao",
            //             });
            //         }
            //         for (const deepKey in item[key]) {
            //             if (typeof item[key][deepKey] === "object") {
            //                 if (item[key][deepKey]["@popupform"]) {
            //                     modals.push({
            //                         ...item[key][deepKey],
            //                         key: item[key][deepKey]["@popupform"],
            //                         "@tipo": componentType._popup,
            //                         "@persiste": "nao",
            //                     });
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
    return modals;
    // normalizedFormArray = normalizedFormArray.concat(modals);
    // return normalizedFormArray;
};

/**
 * !This function does mutate the state it recieves, violating functional aproach
 * !
 */
const traverseDeepChilds = ({ item, setItemProp, root }) => {
    const changeInnerChildsProps = ({ item, context }) => {
        for (const itemKey in item) {
            if (typeof item[itemKey] === "object" && itemKey !== "dataSource" && itemKey !== "inputGroupPath") {
                item[itemKey] = setItemProp({
                    item: item[itemKey],
                    context,
                    colKey: itemKey,
                });
                for (const colKey in item[itemKey]) {
                    if (typeof item[itemKey][colKey] === "object") {
                        item[itemKey][colKey] = setItemProp({
                            item: { ...item[itemKey][colKey], dbTableName: itemKey },
                            context,
                            colKey,
                            root,
                        });
                    }
                }
            }
        }
    };

    const firstLevelItems = Object.values(
        Object.values(item).filter(
            (firstLevelItem) => typeof firstLevelItem === "object" && !Array.isArray(firstLevelItem)
        )
    )[0];

    const secondLevelItems = Object.values(firstLevelItems).filter(
        (secondLevelItem) => typeof secondLevelItem === "object" && !Array.isArray(secondLevelItem)
    );
    /**
     * * counting number of obj childs and setting new type
     */

    if (secondLevelItems.length === 1 && !Object.keys(item).find((it) => it.startsWith("@accao"))) {
        // if (item.key === "dests_inq_v2") ;
        if (
            secondLevelItems[0]["@tipo"] &&
            secondLevelItems[0]["@tipo"] !== componentType.dynamicList &&
            secondLevelItems[0]["@tipo"] !== componentType.text &&
            secondLevelItems[0]["@tipo"] !== componentType.date
        ) {
            //TODO: nao verificar se nao tem tipo porque componentes to tipo input nem sempre tem tipo
            //TODO: verificar para os outros tipos de campo
            item["@tipo"] = componentType.fixedList;
        }
        // if (secondLevelItems[0]["@tipo"] && (secondLevelItems[0]["@tipo"] !== componentType.dynamicList && secondLevelItems[0]["@tipo"] ) ){
        //
        changeInnerChildsProps({ item, context: "column" });
        if (!item["@etiqueta"]) {
            item["@etiqueta"] = secondLevelItems[0]["@etiqueta"] || capitalize(item.key.replace(/_/g, " "));
        }
        item["@placeholder"] = `Selecione ${item["@etiqueta"]}`;
    } else {
        /**
         * * check and add type in columns
         */
        changeInnerChildsProps({ item, context: "column" });
    }
};

const setNewProperties = (normalizedFormArray) => {
    const getDeepEtiqueta = (item) => {
        let etiqueta = "";
        for (const key in item) {
            if (item["@etiqueta"]) {
                etiqueta = item["@etiqueta"];
                break;
            } else if (typeof item[key] === "object") {
                etiqueta = getDeepEtiqueta(item[key]);
            } else {
                continue;
            }
        }
        return etiqueta;
    };

    const setTypeAnalyzingProperty = (item) => {
        if (item["@tipo"] === componentType.datagrid) {
            const fields = Object.keys(item).filter(
                (field) => typeof item[field] === "object" && !Array.isArray(item[field])
            );

            let isDynamicDataGrid = fields.filter((field) =>
                ["botoes_seleccao"].includes(item[field]["@elemento_grafico"])
            );

            if (isDynamicDataGrid && isDynamicDataGrid.length > 0) {
                item["@tipo"] = componentType._dynamicDatagrid;
                fields.forEach((field) => {
                    item[field] = setItemProp({
                        item: item[field],
                        context: "column",
                        colKey: field,
                        root: item.key,
                        pather: componentType._dynamicDatagrid,
                    });
                });
                return item;
            }
        }

        return null;
    };

    const setItemProp = ({ item, context, colKey, root, inputGroupPath, pather, datagrid }) => {
        if ("$" in item) {
            if (item["$"].startsWith("{$param.")) {
                item.value = "";
            } else if (item["$"] === "{now()}") {
                item.value = moment().format("YYYY-MM-DD");
            } else if (item["$"] === "{now_year()}") {
                item.value = moment().format("YYYY");
            } else {
                item["$"] = item["$"] === "false" ? "f" : item["$"];
                item["$"] = item["$"] === "true" ? "t" : item["$"];
                // if (!item["$"].startsWith("$dados_pai")) {
                item.value = item["$"];
                // }
            }
        }

        if (item.value === null || item.value === undefined) {
            item.value = "";
        }

        if (
            ["valencia_designacao", "estabelecimento_nome", "cliente_nome"].includes(item.key) &&
            Object.keys(item).length === 2
        ) {
            item["@tipo"] = componentType._dataDisplay;
        }
        //set etiqueta
        if (item["@etiqueta"] !== "") {
            if (Object.keys(item).length <= 7) {
                if (item.key === "cliente_nome") {
                    item["@etiqueta"] = "Cliente";
                } else if (item.key === "estabelecimento_nome") {
                    item["@etiqueta"] = "Estabelecimento";
                } else if (item.key === "valencia_designacao") {
                    item["@etiqueta"] = "Unidade orgânica";
                } else {
                    item["@etiqueta"] =
                        item["@etiqueta"] ||
                        getDeepEtiqueta(item) ||
                        (item.key && capitalize(item.key.replace(/_/g, " ")));
                }
            } else {
                item["@etiqueta"] =
                    item["@etiqueta"] || getDeepEtiqueta(item) || (item.key && capitalize(item.key.replace(/_/g, " ")));
            }
        }

        // @estilo: "nif"
        // @etiqueta: "Segunda"
        // @sem_label: "true"
        // @tipo: "lista_estatica"
        // @valores: "Sim"
        // item["@etiqueta"] =
        //     item["@etiqueta"] === ""
        //         ? ""
        //         : item["@etiqueta"] || getDeepEtiqueta(item) || (item.key && capitalize(item.key.replace(/_/g, " ")));

        // if (item["@tipo"] === "combobox") {
        // ;
        // }
        /**
         * * only for visual fiedls
         */
        if (!["processo", "actividade", "estabelecimento", "modelo"].includes(item.key)) {
            if (context === "column") {
                item.key = colKey;
                item.isColumn = true;
                item.root = root;
                item.inputGroupPath = inputGroupPath;
            } else {
                if (item[xmlProps.graphicElement] === graphicElement.title && !item["@tipo"]) {
                    item["@tipo"] = componentType._dataDisplay;
                }
            }
            /**
             * ? why these fields haven't any type in xml
             * * text inputs and some lists
             */
            const isInputGroup =
                Object.keys(item)
                    .filter((it) => !it.startsWith("@"))
                    .filter((it) => typeof item[it] === "object" && !Array.isArray(item[it])).length > 0;

            const itemWithDynamicType = setTypeAnalyzingProperty(item);

            if (itemWithDynamicType) {
                return itemWithDynamicType;
            }

            if (
                (!item["@tipo"] && !item["@elemento_grafico"] && !item["@mascara"]) ||
                (item["@tipo"] === componentType.integer && isInputGroup)
            ) {
                if (
                    item["@chave_lista"] &&
                    item["@valor_lista"]
                    // &&
                    // !componentStyle.telefone.includes(item[xmlProps.style]) &&
                    // !componentStyle.inputText.includes(item[xmlProps.style])
                ) {
                    if (item["@visivel"] === "nao") {
                        item["@tipo"] = componentType._dataDisplay;
                    } else {
                        if (item[xmlProps.dataSource] || item["@valores"]) {
                            item["@tipo"] = componentType._select;
                            item.placeholder = "Selecione uma opção";
                        } else {
                            item["@tipo"] = componentType._input;
                            // item["@tipo"] = componentType.sugestion;
                            // item.placeholder = `Selecione um ${item["@etiqueta"]}`;
                        }
                    }
                } else if (fieldsToAssignAsInputTextIfNotHaveType.includes(item.key) && !item["@tipo"]) {
                    item["@tipo"] = componentType._input;
                    item.placeholder = `${item["@etiqueta"]}`;
                } else if (isInputGroup) {
                    item["@tipo"] = componentType._inputGroup;
                    const keys = Object.keys(item).filter(
                        (field) => typeof item[field] === "object" && !Array.isArray(item[field])
                    );

                    keys.forEach((field) => {
                        const inputGP = inputGroupPath ? [...inputGroupPath, item.key] : [item.key];

                        const itemWithIGP = setItemProp({
                            item: item[field],
                            context: "column",
                            colKey: field,
                            root: item.key,
                            inputGroupPath: inputGP,
                        });

                        item[field] = itemWithIGP;
                    });
                } else if ((item["@chave_lista"] && pather === componentType._dynamicDatagrid) || datagrid) {
                    item["@tipo"] = componentType._dataDisplay;
                } else if (item["@estilo"] === "mega_curto" || item["@dimensao"]) {
                    item["@tipo"] = componentType._input;
                    item.placeholder = `Intrduza ${item["@etiqueta"]}`;
                } else if (context === "column") {
                    // pode causar problemas
                    // item["@tipo"] = componentType._dataDisplay;
                    item["@tipo"] = componentType._input;
                } else {
                    item["@tipo"] = componentType._input;
                    item.placeholder = `Introduza ${item["@etiqueta"]}`;
                }
            } else if (item["@mascara"] === "data") {
                item["@tipo"] = componentType.date;
            } else if (
                item["@tipo"] === componentType.combobox ||
                item[xmlProps.graphicElement] === graphicElement.combobox
            ) {
                item["@tipo"] = componentType._select;
                item.placeholder = `Selecione ${item["@etiqueta"]}`;
            } else if (
                item[xmlProps.graphicElement] === graphicElement.title &&
                item[xmlProps.componentType] !== componentType.date
            ) {
                item["@tipo"] = componentType._dataDisplay;
            } else if (
                item?.["@tipo"] === componentType.icon ||
                item[xmlProps.graphicElement] === graphicElement.button ||
                item["@tipo"] === componentType._button
            ) {
                item["@tipo"] = componentType._button;
            } else if (
                (item?.[xmlProps.style]?.includes("nif") || item?.["@mascara"] === "positivo") &&
                item?.["@tipo"] !== componentType.fixedList &&
                item["@mascara"] !== "hora" &&
                item["@mascara"] !== "data" &&
                item["@etiqueta"] !== "Hora" &&
                item[xmlProps.graphicElement] !== graphicElement.multipleSelectionButtons &&
                item["@tipo"] !== componentType._button
            ) {
                item["@tipo"] = componentType._inputNumber;
            } else if (
                (item?.[xmlProps.style]?.includes("nif") &&
                    item[xmlProps.graphicElement] !== graphicElement.multipleSelectionButtons &&
                    (!item?.["@valores"] || !item?.["@dados"])) ||
                item?.["@mascara"] === "positivo"
            ) {
                item["@mascara"] === "hora"
                    ? (item["@tipo"] = componentType._inputTime)
                    : (item["@tipo"] = componentType._inputNumber);
            } else if (
                item["@tipo"] === componentType.fixedList &&
                (!item[xmlProps.graphicElement] || item[xmlProps.graphicElement] === graphicElement.singleChoice)
            ) {
                item["@tipo"] = componentType._select;
                item.placeholder = "Selecione uma opção";
            } else if (
                (item["@tipo"] === componentType.integer ||
                    (item[xmlProps.graphicElement] === graphicElement.singleChoiceList &&
                        item[xmlProps.type !== graphicElement.dynamicList]) ||
                    item[xmlProps.graphicElement] === graphicElement.suggestion ||
                    (item["@tipo"] === componentType.string &&
                        item[xmlProps.graphicElement] === graphicElement.suggestion)) &&
                item["@chave_lista"] &&
                item["@valor_lista"]
            ) {
                item["@tipo"] = componentType._select;
                item.placeholder = "Selecione uma opção";
            } else if (
                item["@mascara"] === "decimal" ||
                item["@mascara"] === "inteiro" ||
                item["@tipo"] === componentType.integer ||
                (!item[xmlProps.componentType] &&
                    !item[xmlProps.graphicElement] &&
                    componentStyle.telefone.includes(item[xmlProps.style]))
            ) {
                /**
                 * ? why this field haven't any type in xml
                 * * input number
                 */
                item["@tipo"] = componentType._inputNumber;
                item.placeholder = `Introduza ${item["@etiqueta"]}`;
            } else if (item["@mascara"] === "hora") {
                item["@tipo"] = componentType._inputTime;
                item.placeholder = `${item["@etiqueta"]}`;
            } else if (item["@mascara"] === "email") {
                item["@tipo"] = componentType._input;
                item.placeholder = `Introduza ${item["@etiqueta"]}`;
            } else if (
                (item["@tipo"] === componentType.shortText && item["@elemento_grafico"] === graphicElement.title) ||
                (item["@tipo"] === componentType.text && item["@elemento_grafico"] === graphicElement.title)
            ) {
                /**
                 * * setting tipo as the main component type identifier
                 */
                if (context === "column") {
                    item["@tipo"] = undefined;
                } else {
                    item["@tipo"] = componentType.alert;
                }
            } else if (item["@elemento_grafico"] === graphicElement.title && item["@apenas_leitura"] === "true") {
                /**
                 * * setting tipo as the main component type identifier
                 */
                item["@tipo"] = componentType._readonly;
            } else if (
                item["@elemento_grafico"] === graphicElement.singleChoiceList &&
                item[xmlProps.type] !== componentType.dynamicList
            ) {
                /**
                 * * setting tipo as the main component type identifier
                 */
                item["@tipo"] = componentType._select;
                item.placeholder = `Selecione ${item["@etiqueta"]}`;
            } else if (
                item["@elemento_grafico"] === graphicElement.selectionButtons &&
                !(item["@tipo"] === componentType.fixedList)
            ) {
                /**
                 * * setting tipo as the main component type identifier
                 */
                if (
                    (!item["@valores"] && !item["@chave_lista"] && !item["@valor_lista"]) ||
                    item["@tipo"] === componentType.boolean
                ) {
                    item["@tipo"] = componentType.boolean;
                } else if (item["@valores"]) {
                    item["@tipo"] = componentType._select;
                } else {
                    item["@tipo"] = componentType._selectionButtons;
                }
            } else if (
                item["@tipo"] === componentType.fixedList &&
                item["@elemento_grafico"] === graphicElement.selectionButtons
            ) {
                /**
                 * * setting tipo as the main component type identifier
                 */
                item["@tipo"] = componentType._selectionButtons;
            } else if (
                item["@tipo"] === componentType.integer &&
                item["@elemento_grafico"] === componentType._selectionButtons
            ) {
                item["@tipo"] = componentType._select;
                item.placeholder = "Selecione uma opção";
            } else if (item["@elemento_grafico"] === graphicElement.selectionButtons) {
                item["@tipo"] = graphicElement.selectionButtons;
            } else if (item["@tipo"] === componentType.conjunto && item["@dados"] && !item["@botoes_transacao"]) {
                /**
                 * * setting tipo as the main component type identifier
                 */
                item["@tipo"] = componentType._selectionTable;
                traverseDeepChilds({ item, setItemProp, root: item.key });
            } else if (item["@tipo"] === componentType.conjunto) {
                /**
                 * * inferring type based on type 'conjunto' and it's childs
                 * * dynamicTable vs fixedlist
                 */
                item["@tipo"] = componentType._dynamicTable;
                traverseDeepChilds({ item, setItemProp, root: item.key });
            } else if (
                item["@tipo"] === componentType.datagrid &&
                item["@mostrar_botoes_add_rem"] &&
                item["@mostrar_botoes_add_rem"] === "sim"
            ) {
                item["@tipo"] = componentType._dynamicDatagrid;
                Object.keys(item)
                    .filter((field) => typeof item[field] === "object" && !Array.isArray(item[field]))
                    .forEach((field) => {
                        item[field] = setItemProp({
                            item: item[field],
                            context: "column",
                            colKey: field,
                            root: item.key,
                        });
                    });
                // traverseDeepChilds({ item, setItemProp, root: item.key });
            } else if (item["@tipo"] === componentType.datagrid) {
                Object.keys(item)
                    .filter((field) => typeof item[field] === "object" && !Array.isArray(item[field]))
                    .forEach((field) => {
                        item[field] = setItemProp({
                            item: item[field],
                            context: "column",
                            colKey: field,
                            root: item.key,
                            datagrid: true,
                        });
                    });
            } else if (
                item["@elemento_grafico"] === graphicElement.combobox ||
                item["@tipo"] === componentType.combobox
            ) {
                item["@tipo"] = componentType._select;
                item.placeholder = `Selecione um ${item["@etiqueta"]}`;
            } else if (item["@tipo"] === componentType.float) {
                item["@tipo"] = componentType._inputNumber;
            } else if (
                item["@elemento_grafico"] === graphicElement.title &&
                item[xmlProps.componentType] !== componentType.date
            ) {
                item["@tipo"] = componentType._dataDisplay;
            } else if (item["@elemento_grafico"] === graphicElement.button) {
                item["@tipo"] = componentType._button;
            } else if (item["@estilo"] && item["@estilo"] === "nao_mostra") {
                item["@visivel"] = "nao";
            } else if (item["@estilo"] === "area_texto" && item["@elemento_grafico"] === "areatexto") {
                item["@tipo"] = componentType.text;
            } else if (
                item["@tipo"] === componentType.fixedList &&
                item["@elemento_grafico"] === graphicElement.multipleSelectionButtons
            ) {
                item["@tipo"] = componentType._checkBox;
            } else if (
                item["@tipo"] === componentType.string &&
                item[xmlProps.dataSource] &&
                item["@chave_lista"] &&
                item["@valor_lista"]
            ) {
                item["@tipo"] = componentType._select;
                item.placeholder = "Selecione uma opção";
            } else if (item["@tipo"] === componentType._file) {
                item["@tipo"] = componentType.upload;
            } else if (!item["@tipo"] && item[xmlProps.graphicElement] === graphicElement.areaTexto) {
                item["@tipo"] = componentType.text;
            }

            // else if (
            //     item["@tipo"] === componentType.fixedList &&
            //     item[xmlProps.graphicElement] === graphicElement.multipleSelectionButtons
            // ) {
            //     item["@tipo"] = componentType._checkBox;
            // }
            //  else if (item[xmlProps.graphicElement] === graphicElement.singleChoiceList) {
            //     ;
            //     item["@tipo"] = componentType._select;
            //     item.placeholder = "Selecione uma opção";
            // }
            // else if (item["@tipo"] === componentType.sugestao) {
            //     item["@tipo"] = componentType._select;
            // }
        }

        return item;
    };

    const isSpace = (item) => {
        if (!item?.key.includes("espaco")) {
            return false;
        }

        const objectsInsideItem = Object.values(item)
            .filter((values) => typeof values === "object" && !Array.isArray(values))
            .filter((prop) => prop["@persiste"] === "nao");

        return objectsInsideItem.length > 0;
    };

    normalizedFormArray = normalizedFormArray.map((item) => {
        //;
        if (isSpace(item)) {
            item["@visivel"] = "nao";
            return item;
        }
        const it = setItemProp({ item, context: "form" });

        // dataSourceTable convert array to object
        if (it[xmlProps.dataSourceTable]) {
            for (var key in it) {
                var auxItem = it[key];
                if (Array.isArray(auxItem)) {
                    for (var i = 0; i < auxItem.length; ++i) {
                        it[key] = Object.assign({ ...auxItem[i] });
                    }
                }
            }
        }
        return it;
    });

    return normalizedFormArray;
};

export const getChild = ({ parent, components }) => {
    const childs = [];
    if (parent && parent.childKeys) {
        if (parent.inputGroupPath) {
            for (const key of parent.childKeys) {
                if (key.startsWith("root@")) {
                    const input = key.split("@")[1];
                    for (const component of components) {
                        if (component.key === input && parent.key === component.parent) {
                            if (component.key !== "qualia_dr52_01_plano_sessoes_sub_pop.qml.xml") {
                                childs.push(component);
                            }
                            // childs.push(component);
                        }
                    }
                } else {
                    const input = getFieldInInputGroup(
                        components.reduce((accumulator, current) => {
                            accumulator[current.key] = current;
                            return accumulator;
                        }, {}),
                        [...parent.inputGroupPath],
                        key
                    );
                    if (input?.key !== "qualia_dr52_01_plano_sessoes_sub_pop.qml.xml") {
                        childs.push(input);
                    }
                }
            }
        } else {
            for (const component of components) {
                for (const key of parent.childKeys) {
                    if (component.key === key && parent.key === component.parent) {
                        if (component.key !== "qualia_dr52_01_plano_sessoes_sub_pop.qml.xml") {
                            childs.push(component);
                        }
                        // childs.push(component);
                    }
                }
            }
        }
    }
    return childs;
};

export const conversion = ({ xpath, item, relKey, root, rowKey, state, rootPath }) => {
    rootPath = rootPath ? [...rootPath] : [];

    if (/\.(?=\s+!)/.test(xpath)) {
        xpath = xpath.replace(/\.(?=\s+!)/g, `../${item.key}`);
    }

    // const regex = /\w*\s*(?==)|\w*(?=!=)/g;
    // const regex = /\w*\s*(?==)|\w*\s*(?=!=)|\w*\s*(?=>)|\w+(?=,'\w+'\))/g;
    const regex =
        // /\w*\s*(?=(!|=|<|>))|\w+(?=,'\w+'\))|(?<=(<|>|=)(\W*^\'))\w+|(?<=\.\.\/)\w+(?=(\)|$))|(?<=expressao_lenght\(\.\.\/)\w+/g;
        /\w*\s*(?=(!|=|<|>))|\w+(?=,'\w+'\))|(?<=(<|>|=)(\W*^\'))\w+|(?<=\.\.\/)\w+(?=(\)|$|\s))|(?<=expressao_lenght\(\.\.\/)\w+|(?!(\/\/))\w+$/g;

    if (!xpath || !xpath.match(regex)) return;

    let allFieldNames = xpath
        .match(regex)
        .filter((item) => item)
        .map((it) => it.trim());
    let jsExp = xpath;
    // if(item.key === "respon_sugestao") ;

    jsExp = jsExp
        .replace(/\sor\s/gi, " || ")
        .replace(/\sand\s/gi, " && ")
        .replace(/(?<!>|<|!|=)=(?!=)/g, "==") //TODO: replace to safer ===, that evaluates type and value
        .replace(/'true'/gi, "'t'")
        .replace(/'false'/gi, "'f'")
        .replace(/(?:\\[rn])+/g, "")
        .replace(/!==/gi, "!=");

    // form -> ''name''

    if (/''[^&&|\|\|]+''/g.test(jsExp)) {
        jsExp = jsExp.replace(/''/gi, "'");
    }

    for (const fieldName of allFieldNames) {
        if (fieldName === "parseInt" || !fieldName) {
            continue;
        }
        // const aux_regex = new RegExp("((../)+" + fieldName + ")");
        const aux_regex = new RegExp("((\\/\\/|\\.\\.\\/)+" + fieldName + ")");

        const aux_regex_for_inputGroup = new RegExp(
            `\/\/[aA-zZ_]+\/(${fieldName})|\/\/[aA-zZ_]+\/[aA-zZ_]+\/(${fieldName})`,
            "g"
        );

        let newNameChild;
        let newNameRoot;

        let currentRootPath = [...rootPath];

        if (currentRootPath && currentRootPath.length > 0) {
            if (aux_regex_for_inputGroup.test(xpath)) {
                let match = xpath.match(aux_regex_for_inputGroup);
                match = match[0].split("/").filter((it) => it);
                match = match[match.length - 2];
                const index = currentRootPath.indexOf(match);
                currentRootPath = currentRootPath.slice(0, index + 1);
            }

            newNameRoot = `state.form${currentRootPath.reduce(
                (accumulator, current) => `${accumulator}?.${current}`,
                ""
            )}?.['${fieldName}']`;

            newNameChild = `state.form${currentRootPath.reduce(
                (accumulator, current) => `${accumulator}?.${current}`,
                ""
            )}?.['${root}']['dataSource']['${rowKey}']['${fieldName}']`;

            if (eval(newNameRoot)) {
                newNameRoot = `${newNameRoot}?.value`;
            } else {
                newNameRoot = `state.form['${fieldName}']?.value`;
            }
        } else {
            newNameChild = `state.form['${root}']['dataSource']['${rowKey}']['${fieldName}']`;

            const elementCheck = state.form?.[root]?.dataSource?.[rowKey]?.[fieldName];
            if (elementCheck && typeof elementCheck === "object") newNameChild = newNameChild + `["id"]`;

            if (elementCheck === "true") {
                newNameChild = "t";
            }

            if (elementCheck === "false") {
                newNameChild = "f";
            }

            newNameRoot = `state.form['${fieldName}']?.value`;
        }

        if (currentRootPath && currentRootPath.length > 0 && aux_regex_for_inputGroup.test(jsExp)) {
            jsExp = jsExp.replace(aux_regex_for_inputGroup, newNameRoot);
        } else if (aux_regex.test(jsExp) && root /*&& eval(newNameChild) === null*/) {
            if (!rowKey) {
                if (newNameChild === "t" || newNameChild === "f") {
                    jsExp = jsExp.replace(aux_regex, newNameChild);
                } else {
                    jsExp = jsExp.replace(aux_regex, newNameRoot);
                }
            } else {
                try {
                    if (newNameChild === "t" || newNameChild === "f") {
                        jsExp = jsExp.replace(aux_regex, newNameChild);
                    } else {
                        //FIXME: review this implementation
                        let pathToNewNameChildObject = newNameChild.match(/(?<=).*\[/, "")[0];
                        pathToNewNameChildObject = pathToNewNameChildObject.substring(
                            0,
                            pathToNewNameChildObject.length - 1
                        );
                        //todo: review this condition
                        // if (eval(newNameChild)) {
                        if (
                            eval(pathToNewNameChildObject).hasOwnProperty(fieldName) ||
                            eval(newNameChild) ||
                            (root &&
                                rowKey &&
                                !(currentRootPath && currentRootPath?.length) &&
                                eval(pathToNewNameChildObject).hasOwnProperty("id"))
                        ) {
                            jsExp = jsExp.replace(aux_regex, newNameChild);
                        } else {
                            jsExp = jsExp.replace(aux_regex, newNameRoot);
                        }
                    }
                } catch (error) {
                    jsExp = jsExp.replace(aux_regex, newNameRoot);
                }
            }
        } else if (aux_regex.test(jsExp) && !root) {
            jsExp = jsExp.replace(aux_regex, newNameRoot);
        } else if (jsExp.includes("//" + fieldName)) {
            jsExp = jsExp.replace("//" + fieldName, newNameRoot);
        }
    }

    // if (item.key === "data_registo") ;

    return jsExp;
    // return jsExp.replace(/\s/g, '');
};

const pathConvertion = ({ state = null, root = null, xpath, fieldName, rowKey }) => {
    // const relativePathRegex = /\.\.\/\w*/;
    // const absolutePathRegex = /\/\/\w*\/*\w*/;
    // const countRegex = /(?<=count\().*(?=\))/;
    // const nonTrivialPath = /(?!\/)\w*\/\w*/;
    // const trivialPath = /(?=\/).*(?=\/)/;
    // if (nonTrivialPath.test(xpath)) {
    //     const fieldKeyParent = xpath.split("/")[0].replace(/\W/, "");
    //     const fieldKey = xpath.split("/")[1].replace(/\W/, "");
    //     const rootKey = Object.values(state).find((item) => fieldKeyParent in item).key;
    //     //todo: review how to count process preenchimento questionario field total
    //     if (countRegex.test(xpath)) {
    //         // return `Object.values(state.form['${rootKey}']['dataSource']['${rowKey}']['${fieldName}']`;
    //     }
    //     return `state.form['${rootKey}']['dataSource']['${rowKey}']['${fieldKey}']`;
    // } else if (root) {
    //     return `state.form['${root}']['dataSource']['${rowKey}']['${fieldName}']`;
    // } else if (trivialPath.test(xpath)) {
    //     return `state.form['${xpath.match(nonTrivialPath)[0].replace(/\W/, "")}'].value`;
    // }
};

export const countConvertion = ({ xpath, formData }) => {
    // downFile(JSON.stringify({ xpath, formData }), "json.txt", "text/plain");
    // const regex = /\w*\s*(?==)|\w*\s*(?=!=)|\w*\s*(?=>)/g;
    const rootPath = xpath.match(/\w+\s*(?=)/g)[1];

    const table = Object.values(formData).find((item) => {
        if (
            item[rootPath] &&
            (item["@tipo"] === componentType._dynamicTable || item["@tipo"] === componentType._selectionTable)
        ) {
            return true;
        } else if (
            (item["@tipo"] === componentType._dynamicDatagrid || item["@tipo"] === componentType.datagrid) &&
            item.key === rootPath
        ) {
            return true;
        }

        return false;
    });

    if (table) {
        const regex = /\w*\s*(?=(=|\)|!|>?<))/g;
        xpath = xpath.replace("/text()", "");

        let allFieldNames = xpath
            .match(regex)
            .filter((item) => item)
            .map((it) => it.trim());

        //

        // const fieldName = allFieldNames;
        const tableValues = table?.dataSource ? Object.values(table.dataSource) : [];

        /**
         * Garantir que funcione
         * count(//perfil_geral_indicador[../grau_freq_apoio != ../grau_nao_ap])
         * count(//requisito_forn/avaliacao[../avaliacao = 'C'])
         * count(//requisito_forn/avaliacao = 'NC')
         * count(//requisito_forn/id_requisito_aval_forn)
         * count(//requisito_forn/avaliacao = 'NA')
         * count(//requisito_forn/avaliacao != 'NA')
         * count(//requisito_forn/avaliacao > 'NA')
         * count(//requisito_forn/avaliacao < 'NA')
         * count(//requisito_forn/avaliacao)
         */
        let conditionXpath = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

        if (conditionXpath) {
            const fromFormData = conditionXpath[0].match(/(?<=(=|>|<))\s*(\.\.\/)\w*\s*/g);

            conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

            if (fromFormData) {
                fromFormData.forEach((fieldName) => {
                    fieldName = fieldName.replace(/\[|\/|\]|\.|\)/g, "").trim();
                    const field = formData.find((_field) => _field.key === fieldName);
                    conditionXpath = conditionXpath.replace(fieldName, field?.value);
                });
            }

            let countValue = tableValues.reduce((accumulator, current) => {
                let condition = conditionXpath;

                allFieldNames.forEach((fieldName) => {
                    condition = condition.replace(fieldName, `current["${fieldName}"]`);
                });

                const check = eval(condition);
                if (check) {
                    accumulator += 1;
                }
                return accumulator;
            }, 0);
            return countValue;
        }
    }
    return 0;
};

const sumConvertion = ({ xpath, formData, item, rootPath: tablePath, distinct }) => {
    // const regex = /\w*\s*(?==)|\w*\s*(?=!=)|\w*\s*(?=>)/g;
    // downFile(JSON.stringify({ xpath, rootPath: tablePath, formData }), "json.txt", "text/plain");

    let distinctField = null;

    if (distinct) {
        [distinctField, xpath] = xpath.split(",");
    }

    const rootPath = xpath.match(/\w+\s*(?=)/g)[1];

    if (!formData) {
        return 0;
    }

    let table = Object.values(formData).find((item) => {
        if (
            item[rootPath] &&
            (item["@tipo"] === componentType._dynamicTable ||
                item["@tipo"] === componentType._selectionTable ||
                item["@tipo"] === componentType.datagrid)
        ) {
            return true;
        } else if (
            (item["@tipo"] === componentType._dynamicDatagrid || item["@tipo"] === componentType.datagrid) &&
            item.key === rootPath
        ) {
            return true;
        }

        return false;
    });

    // if in inputGroup
    if (!table) {
        let allTable = formData;
        if (tablePath) {
            allTable = formData?.find((it) => it.key === tablePath[0]);
        }
        // ;
        table = Object.values(allTable).find((inputs) => {
            if (inputs["@tipo"] === componentType._selectionTable) {
                const tableSecondKey = Object.keys(inputs).find(
                    (tableAttributes) =>
                        !tableAttributes.startsWith("@") &&
                        tableAttributes !== "dataSource" &&
                        !Array.isArray(inputs[tableAttributes]) &&
                        typeof inputs[tableAttributes] === "object"
                );

                if (tablePath && inputs[tableSecondKey]) {
                    return true;
                } else if (inputs[tableSecondKey][rootPath]) {
                    return true;
                }
            }
            return false;
        });
    }

    if (!table && rootPath) {
        table = Object.values(formData).find((item) => {
            if (
                [componentType.conjunto, componentType._dynamicTable, componentType._selectionTable].includes(
                    item["@tipo"]
                )
            ) {
                const tableRoot = Object.values(item).find(
                    (it) => typeof it === "object" && !Array.isArray(it) && it?.["@tabela"]
                );

                if (tableRoot && tableRoot[rootPath]) {
                    return true;
                }
            }

            return false;
        });
    }

    if (table) {
        const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

        let allFieldNames = xpath
            .match(regex)
            .filter((item) => item)
            .map((it) => it.trim());

        // const fieldName = allFieldNames;
        let tableValues = Object.values(table?.dataSource || {});

        if (distinct) {
            distinctField = distinctField.replace(/\.|\/|(sum_distinct\()/g, "");

            tableValues = tableValues.reduce((accumulator, current) => {
                if (!accumulator.find((item) => item[distinctField] === current[distinctField])) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []);
        }

        /**
         * Garantir que funcione
         * count(//requisito_forn/avaliacao[../avaliacao = 'C'])
         * count(//requisito_forn/avaliacao = 'NC')
         * count(//requisito_forn/id_requisito_aval_forn)
         * count(//requisito_forn/avaliacao = 'NA')
         * count(//requisito_forn/avaliacao != 'NA')
         * count(//requisito_forn/avaliacao > 'NA')
         * count(//requisito_forn/avaliacao < 'NA')
         * count(//requisito_forn/avaliacao)
         */
        let conditionXpath = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

        if (conditionXpath) {
            conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

            let fieldToSum = xpath.match(/\w*\s*(?=\[)/g);

            if (!fieldToSum) {
                fieldToSum = conditionXpath;
            } else {
                fieldToSum = fieldToSum[0];
            }

            let countValue = tableValues.reduce((accumulator, current) => {
                let condition = conditionXpath;

                allFieldNames.forEach((fieldName) => {
                    const fieldValue =
                        typeof current[fieldName] === "object"
                            ? `current["${fieldName}"]?.id`
                            : `current["${fieldName}"]`;
                    condition = condition.replace(fieldName, fieldValue);
                });

                const check = eval(condition);
                if (check) {
                    let value = current[fieldToSum];
                    if (value && typeof value === "object") {
                        value = value?.id;
                    }
                    accumulator += Number(value);
                }
                return accumulator;
            }, 0);
            return countValue;
        }
    }
    return 0;
};

const getxLista = ({ xpath, formData }) => {
    // garantir que funcione
    // calcular="x_lista://periodos_med/id_periodo[../seleciona = ''true'']"
    // calcular="x_lista://periodos_med/id_periodo"

    let expression = Utilitaries.toString(xpath).substr(8);

    const [tableSecondKey, tableField] = expression.match(/\w+\s*(?=)/g);

    let table = Object.values(formData).find(
        (item) =>
            (item[tableSecondKey] &&
                (item["@tipo"] === componentType._dynamicTable ||
                    item["@tipo"] === componentType.conjunto ||
                    item["@tipo"] === componentType._selectionTable)) ||
            (item[tableField] && item["@tipo"] === componentType.datagrid)
    );

    if (table) {
        const tableValues = Object.values(table?.dataSource || {});

        let conditionXpath = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

        if (conditionXpath) {
            conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

            let fieldToAdd = xpath.match(/\w*\s*(?=\[)/g);

            if (!fieldToAdd) {
                fieldToAdd = conditionXpath;
            } else {
                fieldToAdd = fieldToAdd[0];
            }

            const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

            let allFieldNames = xpath
                .match(regex)
                .filter((item) => item)
                .map((it) => it.trim());

            let result = tableValues.reduce((accumulatorValue, current) => {
                let condition = conditionXpath;

                allFieldNames.forEach((fieldName) => {
                    const fieldValue =
                        typeof current[fieldName] === "object"
                            ? `current["${fieldName}"]?.id`
                            : `current["${fieldName}"]`;
                    condition = condition.replace(fieldName, fieldValue);
                });

                const check = eval(condition);
                if (check) {
                    const valueToAppend =
                        typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField];
                    if (!isEmpty(valueToAppend)) {
                        accumulatorValue = `${accumulatorValue}${accumulatorValue.length === 0 ? "" : ", "}${
                            typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField]
                        }`;
                    }
                }
                return accumulatorValue;
            }, "");
            return result;
        } else {
            let result = tableValues.reduce((accumulatorValue, current) => {
                const valueToAppend =
                    typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField];
                if (!isEmpty(valueToAppend)) {
                    accumulatorValue = `${accumulatorValue}${accumulatorValue.length === 0 ? "" : ", "}${
                        typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField]
                    }`;
                }
                return accumulatorValue;
            }, "");
            return result;
        }
    }
    return "";
};

export const mathConversion = ({ xpath, item, root, state, rowKey, formData, rootPath, isTest = false }) => {
    if (
        xpath ===
        "if(count(//serv_apoio/frequenta_serv = 'true') = 0) then 0  else sum(//serv_apoio/n_vagas[frequenta_serv='true'])"
    ) {
        // ;
    }

    const fieldNameRegex = /\.\.\/\w*|\/\/\w*\/*\w*/g;
    const ifElseRegex = /\(if(.*)then.*else.*|\if(.*)then.*else.*/g;
    let allFieldNames = xpath.match(fieldNameRegex);
    const allIfElseStatments = xpath.match(ifElseRegex);
    const countRegex = /(?<=count\().*(?=\))/;
    const sumRegex = /(?<=sum\().*(?=\))/;

    // if (countRegex.test(xpath)) return pathConvertion({});

    //if(../iguais = 'true') then round(100 div 3) else if(../preco = '0') then ../preco_fake else if(../preco_fake != '0') then ../preco_fake else ../preco
    const jsIfElseStatment =
        allIfElseStatments && allIfElseStatments.length > 0
            ? allIfElseStatments.map((item) => {
                  item = item.replace(/(?<=(^|\s))if/gi, "");
                  item = item.replace(/else/g, ":");
                  item = item.replace(/then/g, " ? ");
                  return item;
              })
            : "";

    // add more expressions that indicate it's the mathematical operation
    const needConvertToInt = /\sdiv\s/.test(xpath);

    let jsExp = xpath
        .replace(ifElseRegex, jsIfElseStatment)
        .replace(/\sor\s/gi, "|| ")
        .replace(/\sdiv\s/gi, "/")
        .replace(/\sand\s/gi, "&& ")
        .replace(/(?:\\[rn])+/g, "")
        .replace(/\s+=\s*|(?<=\w)=(?=\W)/gi, "==")
        .replace(/'true'|true/gi, "'t'")
        .replace(/'false'|false/gi, "'f'");

    // count function
    if (countRegex.test(xpath)) {
        // const expression = jsExp.match(/count\(.*?\)|count\(.*?\)$/g);
        const expression = jsExp.match(/count\([/\w\[\]='!\s\.\&]+\)|count\([/\w\[\]='\(\)!\s\W]+\)/g);

        if (expression) {
            expression.forEach((element) => {
                const resultCount = countConvertion({ xpath: element, item, root, state, rowKey, formData });
                jsExp = jsExp.replace(element, resultCount);
            });
            allFieldNames = jsExp.match(fieldNameRegex) || [];
        }
    }

    //Sum function
    if (sumRegex.test(xpath)) {
        const expression = jsExp.match(/sum\(.*?\)/g);
        expression.forEach((element) => {
            const resultCount = sumConvertion({ xpath: element, item, root, state, rowKey, formData, rootPath });
            jsExp = jsExp.replace(element, resultCount);
        });
        allFieldNames = jsExp.match(fieldNameRegex) || [];
    }

    const sumDistinctRegex = /(?<=sum_distinct\().*(?=\))/;
    if (sumDistinctRegex.test(xpath)) {
        const expression = jsExp.match(/sum_distinct\(.*?\)/g);
        expression.forEach((element) => {
            const resultCount = sumConvertion({
                xpath: element,
                item,
                root,
                state,
                rowKey,
                formData,
                rootPath,
                distinct: true,
            });
            jsExp = jsExp.replace(element, resultCount);
        });
        allFieldNames = jsExp.match(fieldNameRegex) || [];
    }

    //x_lista function

    if (Utilitaries.toString(xpath)?.startsWith("x_lista:")) {
        jsExp = getxLista({ xpath: jsExp, formData, item, rootPath });
    }

    let jsExpTest = jsExp;

    if (allFieldNames) {
        for (const name of allFieldNames) {
            if (rootPath && rootPath.length > 0) {
                // const aux_regex_for_inputGroup = new RegExp(`${name}`, "g");
                // if (aux_regex_for_inputGroup.test(xpath)) {
                //     let match = xpath.match(aux_regex_for_inputGroup);
                //     match = match[0].split("/").filter((it) => it);
                //     match = match[match.length - 2];
                //     const index = rootPath.indexOf(match);
                //     rootPath = rootPath.slice(0, index + 1);
                // }

                const cleanName = name.replace(/\W/g, "");

                // ---------------------------------
                if (root && rowKey) {
                    let valueExpression = `state.form${rootPath.reduce(
                        (accumulator, current) => `${accumulator}?.${current}`,
                        ""
                    )}?.['${root}']['dataSource']['${rowKey}']['${cleanName}']`;

                    if (eval(valueExpression)) {
                        valueExpression = valueExpression;
                    } else {
                        valueExpression = `state.form['${cleanName}']?.value`;
                    }

                    let value = valueExpression;

                    if (isTest) {
                        value = eval(valueExpression);
                        if (/^-?\d*(\.{1}\d+){0,1}$/.test(value)) {
                            jsExpTest = jsExpTest.replace(name, value);
                        } else {
                            jsExpTest = jsExpTest.replace(name, `'${value}'`);
                        }
                    }
                    jsExp = jsExp.replace(name, valueExpression);

                    // const rowValue = state.form[root]?.["dataSource"]?.[rowKey]?.[cleanName];

                    // if (name.startsWith("//") && !rowValue) {
                    //     if (state.form[cleanName]?.value) {
                    //         valueExpression = `state.form['${cleanName}']?.value`;
                    //     }
                    // }

                    // jsExp = jsExp.replace(
                    //     name,
                    //     `${needConvertToInt && !isNotNumber ? "Number(" : ""}
                    // ${valueExpression}
                    // ${needConvertToInt && !isNotNumber ? ")" : ""}`
                    // );
                    // ---------------------------------
                } else {
                    let newNameRoot = `state.form${rootPath.reduce(
                        (accumulator, current) => `${accumulator}?.${current}`,
                        ""
                    )}?.['${cleanName}']`;

                    if (eval(newNameRoot)) {
                        newNameRoot = `${newNameRoot}?.value`;
                    } else {
                        newNameRoot = `state.form['${cleanName}']?.value`;
                    }

                    let value = newNameRoot;

                    if (isTest) {
                        value = eval(newNameRoot);
                        jsExpTest = jsExpTest.replace(name, value);
                    }
                    jsExp = jsExp.replace(name, newNameRoot);
                }
            } else {
                const isZero = state.form?.[name?.replace(/\W/g, "")]?.value === "0";
                const value = state.form?.[name?.replace(/\W/g, "")]?.["value" || "$"];

                const isNotNumber = isNaN(state?.form?.[name?.replace(/\W/g, "")]?.value);

                if ((isZero || value) && !isNotNumber) {
                    const expresion = `Number(state.form['${name.replace(/\W/g, "")}'].value)`;

                    let value = expresion;

                    if (isTest) {
                        value = eval(expresion);
                        jsExpTest = jsExpTest.replace(name, value);
                    }
                    jsExp = jsExp.replace(name, expresion);
                } else {
                    if (jsExp.startsWith("comparar_datas(")) {
                        if (root) {
                            jsExp = jsExp.replace(
                                name,
                                `moment(state.form['${root}']['dataSource']['${rowKey}']['${name.replace(
                                    /\W/g,
                                    ""
                                )}']?.value)`
                            );
                        } else {
                            jsExp = jsExp.replace(name, `moment(state.form['${name.replace(/\W/g, "")}']?.value)`);
                        }
                    } else {
                        if (root) {
                            let valueExpression = `state.form['${root}']['dataSource']['${rowKey}']['${name.replace(
                                /\W/g,
                                ""
                            )}']`;

                            const rowValue = state.form[root]?.["dataSource"]?.[rowKey]?.[name.replace(/\W/g, "")];

                            if (rowValue && typeof rowValue === "object" && rowValue.hasOwnProperty("id")) {
                                valueExpression = valueExpression + `["id"]`;
                            }

                            if (name.startsWith("//") && !rowValue) {
                                // if (state.form[name.replace(/\W/g, "")]?.value) {
                                valueExpression = `state.form['${name.replace(/\W/g, "")}']?.value`;
                                // }
                            }

                            const expresion = `${needConvertToInt && !isNotNumber ? "Number(" : ""}
                            ${valueExpression}
                            ${needConvertToInt && !isNotNumber ? ")" : ""}`;
                            let value = expresion;

                            if (isTest) {
                                value = eval(expresion);
                                if (isNotNumber) {
                                    jsExpTest = jsExpTest.replace(name, `'${value}'`);
                                } else {
                                    jsExpTest = jsExpTest.replace(name, value);
                                }
                            }
                            jsExp = jsExp.replace(name, expresion);
                        } else {
                            const expresion = `${
                                needConvertToInt && !isNotNumber ? "Number(" : ""
                            }state.form['${name.replace(/\W/g, "")}']?.value${
                                needConvertToInt && !isNotNumber ? ")" : ""
                            }`;

                            let value = expresion;

                            if (isTest) {
                                value = eval(expresion);
                                if (isNotNumber) {
                                    jsExpTest = jsExpTest.replace(name, `'${value}'`);
                                } else {
                                    jsExpTest = jsExpTest.replace(name, value);
                                }
                            }
                            jsExp = jsExp.replace(name, expresion);
                        }
                    }
                }
            }
        }
    }

    if (jsExp.startsWith("comparar_datas(")) {
        jsExp = jsExp.replace(/\s>=\s(?=moment)/g, ".isSameOrAfter(").replace(/\s<=\s(?=moment)/g, ".isSameOrBefore(");
        jsExp = jsExp.replace(/']\)\)/g, "$&)");
        jsExp = `${jsExp})`;
    }

    // colocar : 0 em falta em if else
    if (/if(.*)\sthen\s(.*)else if(.*)then(.*)/.test(xpath)) {
        const hasManyElseIf = xpath.match(/else if/g);
        const hasManyThen = xpath.match(/then/g);
        //termina com ? [numero | string]
        if ((hasManyElseIf.length == 1 && hasManyThen.length == 1) || /\?\s*[\w|\d|']+$/.test(jsExp)) {
            jsExp = `${jsExp} : 0`;
        }
    }

    if (isTest) {
        return {
            jsExp,
            jsExpTest,
        };
    }

    return jsExp;
};

export const getFieldInInputGroup = (inputGroup, path = [], key) => {
    if (path.length === 0) {
        if (inputGroup) {
            if (key) {
                return inputGroup?.[key];
            } else {
                return inputGroup;
            }
        }
    } else {
        let el = path.shift();
        return getFieldInInputGroup(inputGroup?.[el], path, key);
    }
};

export const canShowInput = (field, form) => {
    if (
        field["@estilo"] === "nao_mostra" ||
        field["@visivel"] === "nao" ||
        field["@tipo"] === componentType._separator
    ) {
        return false;
    }

    if (
        Object.keys(field).filter((it) => it.startsWith("@")).length <= 3 &&
        ((field?.value && typeof field.value === "string") || typeof field.value === "number") &&
        ["estabelecimento", "cliente", "valencia"].includes(field?.key)
    ) {
        return false;
    }

    // Renderezar os campos do cabecalho como etiqueta
    if (
        Object.keys(field).filter((it) => it.startsWith("@")).length <= 3 &&
        field?.value &&
        ["valencia", "estabelecimento_nome", "valencia_designacao"].includes(field?.key)
    ) {
        field["@tipo"] = componentType._dataDisplay;
    }

    if (field["@tipo"] === "input" && field["@persiste"] === "nao" && field?.["$"]) {
        field["@tipo"] = componentType._dataDisplay;
    }

    const types = /espaco(\d*)|space_img/;
    if (types.test(field.key) && field?.["@persiste"] === "nao" && field?.["@elemento_grafico"] === "etiqueta") {
        return false;
    }

    return true;
};

export const checkHasColSize = (item, hasCol) => {
    if (item["@tipo"] === componentType._popup) {
        hasCol = false;
    }
    return hasCol;
};

export const checkHasFormItem = (item, hasFormItem = true) => {
    switch (item["@tipo"]) {
        case componentType._dynamicTable:
        case componentType._dynamicDatagrid:
        case componentType.datagrid:
        case componentType._selectionTable:
        case componentType._tab:
        case componentType._popup: {
            return false;
        }

        default:
            return hasFormItem;
    }
};

export const getInputColSize = (style, colSize, item) => {
    if (style) {
        if (style.includes("qa_col_")) {
            let col = style.match(/(?<=(qa_col_))[0-9]+/);

            try {
                col = parseInt(col);
                return col;
            } catch (err) {}
        }

        if (style.includes("area_texto") && !style.includes("coluna")) {
            return 24;
        }
        if (style.includes("area_texto_externo") && !style.includes("coluna")) {
            return 24;
        }

        if (style.includes("coluna2")) {
            return 8;
        } else if (style.includes("coluna2xp")) {
            return 4;
        } else if (style.includes("coluna100qa")) {
            return 8;
        }

        // return
        const inputSize = style?.match(/(?!(coluna))\d+/);

        if (inputSize) {
            colSize = Number(inputSize[0]);

            if (colSize < 8) {
                colSize = 8;
            } else if (colSize > 100) {
                colSize = 8;
            } else if (colSize > 12) {
                colSize = 24;
            } else if (colSize <= 12) {
                colSize = 16;
            }
        } else {
            colSize = 8;
        }
    }

    switch (item["@tipo"]) {
        case componentType.shortText: {
            colSize = 16;
            break;
        }

        case componentType._dataDisplay: {
            if (!item?.["@estilo"]?.includes("coluna2xp")) {
                var regex = /\d+/g;
                var size = item?.["@estilo"]?.match(regex)?.[0] || 0;
                if (size >= 700) {
                    colSize = 24;
                } else {
                    colSize = 8;
                }
            }

            break;
        }

        case componentType._inputGroup: {
            colSize = 24;
            break;
        }

        case componentType._dynamicTable:
        case componentType._dynamicDatagrid:
        case componentType.datagrid:
        case componentType._selectionTable: {
            colSize = 24;
            break;
        }
        case componentType._checkBox: {
            if (item["@estilo"]?.includes("coluna600qa")) {
                colSize = 24;
            }

            break;
        }
        default: {
            colSize = colSize > 0 ? colSize : 8;
            break;
        }
    }

    return colSize;
};

export const getDefaultValue = (input) => {
    if (!input?.["$"]) {
        return "";
    }

    if (input?.["$"].startsWith("$")) {
        return "";
    }

    return input?.["$"];
};

export const getDataSource = (itemData) => {
    if (!itemData["@valores"] || !itemData["@valores"].length > 0) return itemData?.dataSource || [];

    //old = \[(.*?)\]|((.*?)\;)|\w+
    let dataSource = itemData["@valores"].match(/\[(.*?)\]|((.*?)\;)|((.*?)$)|\w+/g);

    if (dataSource && dataSource[0] === itemData["@valores"]) {
        dataSource = dataSource[0].replace("[", "").replace("]", "").split(";");

        dataSource = dataSource
            .filter((el) => el)
            .map((it) => {
                if (it.match(/,/)) {
                    const [value, key] = it.split(",");
                    return {
                        key:
                            itemData?.["@tipo"] === "select"
                                ? value?.trim()
                                : value.trim().replace("true", "t").replace("false", "f"),
                        value: key.trim(),
                    };
                }
                return {
                    key:
                        itemData?.["@tipo"] === "select"
                            ? it.trim()
                            : it.trim().replace("true", "t").replace("false", "f"),
                    value: it.trim(),
                };
            });
    } else {
        dataSource = dataSource.reduce((acumulator, current) => {
            current = current.replace("[", "").replace("]", "").replace(";", "");

            if (!current) {
                return acumulator;
            }

            if (current.match(/,/)) {
                let value, key;
                const listKeyValue = current.split(",");

                if (listKeyValue.length > 2) {
                    key = listKeyValue.pop();
                    value = listKeyValue.join(",");
                } else {
                    [value, key] = current.split(",");
                }

                if (key && value) {
                    acumulator.push({
                        key:
                            itemData?.["@tipo"] === "select"
                                ? key?.trim()
                                : key.trim().replace("true", "t").replace("false", "f"),
                        value: value.trim(),
                    });
                }
                return acumulator;
            } else {
                acumulator.push({
                    key:
                        itemData?.["@tipo"] === "select"
                            ? current?.trim()
                            : current.trim().replace("true", "t").replace("false", "f"),
                    value: current.trim(),
                });
                return acumulator;
            }
        }, []);
    }
    dataSource = Array.isArray(dataSource)
        ? dataSource.map((item) => {
              if (Array.isArray(item.key)) {
                  item.key = item.key[0];
              }

              return item;
          })
        : dataSource;
    return dataSource || [];
};

export const mergeRecursive = (obj1, obj2) => {
    const objFull = { ...obj1 };
    for (let p in obj2) {
        try {
            if (obj2[p]?.constructor === Object && p !== "dataSource") {
                objFull[p] = mergeRecursive(objFull[p], obj2[p]);
            } else {
                objFull[p] = obj2[p];
            }
        } catch (e) {
            objFull[p] = obj2[p];
        }
    }

    return objFull;
};

export const getItemValueWhenLoading = (formItem, values, key) => {
    if (formItem["@tipo"] === componentType.boolean && typeof values[key] === "string") {
        return values[key].toQAFormValue();
    } else {
        return isEmpty(values[key]) ? "" : values[key];
    }
};

export const set$DadosInForm = ({ tree, values, keyData }) => {
    for (const propertie in tree) {
        if (typeof tree[propertie] === "string" && tree[propertie].startsWith(`$${keyData}.`)) {
            var strRegExPattern = "(?<=" + keyData + ".).*";
            const keyToGetData = tree[propertie].match(new RegExp(strRegExPattern, "g"));
            tree[propertie] = getItemValueWhenLoading(tree, values, keyToGetData);
        } else if (typeof tree[propertie] === "object") {
            tree[propertie] = set$DadosInForm({
                tree: tree[propertie],
                values,
                keyData,
            });
        }
    }
    return tree;
};
