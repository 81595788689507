import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Tooltip, Row, Icon, Input, message } from "antd";
import moment from "moment";

import ModalSelectUser from "../select.user";
import { sendHttpRequest } from "../http.request";

const { TextArea } = Input;

const Remember = ({ visible, onCancel, updateList, reminder, selectContributorsProps, isCreate, form }) => {
    // Open Model to select users and groups
    const [isVisibleSelectUG, setIsVisibleSelectUG] = useState(false);
    // Group added
    const [groups, setGroups] = useState([]);
    // User Added
    const [users, setUsers] = useState([]);

    // Fill the form - when is update
    useEffect(() => {
        if (reminder && Object.keys(reminder).length > 0) {
            setUsers(reminder.users);
            setGroups(reminder.groups);

            form.setFields({
                data: {
                    value: reminder.data,
                },
                hour: {
                    value: reminder.hour,
                },
                description: {
                    value: reminder.description,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reminder]);

    // Create form submit
    const onCreate = () => {
        form.validateFields(async (err, values) => {
            if (!err) {
                const __users = users.reduce(
                    (accumulator, currentValue) =>
                        accumulator === "" ? currentValue.username : accumulator + ";" + currentValue.username,
                    ""
                );

                const __groups = groups.reduce(
                    (accumulator, currentValue) =>
                        accumulator === "" ? currentValue.id : accumulator + ";" + currentValue.id,
                    ""
                );

                const formData = new FormData();
                formData.append("descricao", values.description);
                formData.append("utilizadores", __users);
                formData.append("grupos", __groups);
                formData.append("data", moment(values.data).format("YYYY-MM-DD"));
                formData.append("hora", values.hour);
                // formData.append("hora", moment(values.hour).format("HH:mm"));

                if (isCreate) {
                    formData.append("accao", "enviar");

                    sendHttpRequest("POST", "/Gestor/gerelembretes.php", formData).then((jsonResponse) => {
                        if (jsonResponse.erro) {
                            message.error("Ocorreu um erro");
                        } else {
                            updateList();
                            message.success("Lembrete criado com sucesso");
                            onCancel();
                        }
                    });
                } else {
                    formData.append("accao", "editar");
                    formData.append("id", reminder.id);

                    sendHttpRequest("POST", "/Gestor/gerelembretes.php", formData).then((jsonResponse) => {
                        if (jsonResponse.erro) {
                            message.error("Ocorreu um erro");
                        } else {
                            updateList();
                            message.success("Lembrete atualizado com sucesso");
                            onCancel();
                            // setIsVisibleCreateLembrete(false);
                            // setLembreteToUpdate(undefined);

                            setUsers([]);
                            setGroups([]);
                        }
                    });
                }
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            {/* Select user and group to reminder */}
            <ModalSelectUser
                visible={isVisibleSelectUG}
                setVisible={setIsVisibleSelectUG}
                defaultUsers={users}
                defaultGroups={groups}
                {...selectContributorsProps}
                onOk={(usersSelected, groupsSelected) => {
                    setGroups(groupsSelected);
                    setUsers(usersSelected);
                }}
            />

            <Modal
                width="60%"
                visible={visible}
                title={!isCreate ? "Atualizar Lembrete" : "Novo Lembrete"}
                okText={!isCreate ? "Atualizar Lembrete" : "Guardar Lembrete"}
                onCancel={onCancel}
                onOk={onCreate}
                destroyOnClose
            >
                <Form layout="vertical">
                    <Row type="flex" align="middle">
                        <Col span={2}>
                            <Tooltip title="Caso seja um lembrete só do seu utilizador, basta gravar e ele automáticamente ficará disponivel na data e hora que indicou.">
                                <Icon type="question-circle" />
                            </Tooltip>
                        </Col>
                        <Col span={4}>
                            <b>Enviar para:</b>
                        </Col>
                        <Col span={16}>
                            <Row>
                                <Col span={5}>
                                    <label>Utilizadores</label>
                                </Col>
                                <Col span={19}>
                                    <Input
                                        value={users.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator === ""
                                                    ? currentValue.name
                                                    : accumulator + ";" + currentValue.name,
                                            ""
                                        )}
                                        disabled
                                        style={{ marginBottom: 10 }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <label>Grupos</label>
                                </Col>
                                <Col span={19}>
                                    <Input
                                        value={groups.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator === ""
                                                    ? currentValue.value
                                                    : accumulator + ";" + currentValue.value,
                                            ""
                                        )}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={2}>
                            <Icon
                                onClick={() => setIsVisibleSelectUG(true)}
                                type="user-add"
                                style={{ float: "right", fontSize: "22px" }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label="Data">
                                {getFieldDecorator("data", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input type="date" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Hora">
                                {getFieldDecorator("hour", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input type="time" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Descrição">
                        {getFieldDecorator("description", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                        })(<TextArea rows={4} />)}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Form.create({ name: "form_remember" })(Remember);
