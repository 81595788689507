import React, { useEffect, useState } from "react";
import { Row, Form, Select, Col, Input, Checkbox, Tooltip, Button, Icon } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { plainOptions, info } from "../../constants/contributor";
import { selectDataForFixedSelectList } from "../../../fixed_list/actions/index";
import { QaDynamicSelect } from "../../../../components/DynamicList/index";
import { Utilitaries } from "../../../../business";

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

const PersonalData = (props) => {
    const [entidade, setEntidade] = useState({});
    const [morada, setMorada] = useState({});
    const [nibValidation, setNibValidation] = useState({});
    const [ibanValidation, setIbanValidation] = useState({});
    const [defaultDataSource, setDefaultDataSource] = useState(undefined);
    const { getFieldDecorator } = props.form;
    const { ficha_colaborador } = props;

    useEffect(() => {
        props.selectDataForFixedSelectList(
            {
                query: "pais",
                _valor_campo_: props?.ficha_colaborador?.entidade?.moradas?.morada?.pais,
            },
            "pais"
        );

        if (props?.ficha_colaborador?.entidade) {
            setEntidade(props.ficha_colaborador.entidade);
            // const { data_nascimento } = props.ficha_colaborador.entidade;
            // if (typeof data_nascimento !== "object") {
            //     props.getDataNascimento(data_nascimento);
            // }
            if (props.ficha_colaborador.entidade.moradas && props.ficha_colaborador.entidade.moradas.morada) {
                setMorada(props.ficha_colaborador.entidade.moradas.morada);

                const dadosMorada = props?.ficha_colaborador?.entidade?.moradas?.morada;

                // GET DADOS MORADA
                if (dadosMorada?.distrito) {
                    props.selectDataForFixedSelectList(
                        {
                            query: "distrito",
                            pais: dadosMorada?.pais,
                            _valor_campo_: dadosMorada?.distrito,
                        },
                        "distrito"
                    );
                }
                if (dadosMorada?.concelho) {
                    props.selectDataForFixedSelectList(
                        {
                            query: "concelho",
                            distrito: dadosMorada?.distrito,
                            _valor_campo_: dadosMorada?.concelho,
                        },
                        "concelho"
                    );
                }
                if (dadosMorada?.freguesia) {
                    props.selectDataForFixedSelectList(
                        {
                            query: "freguesia",
                            concelho: dadosMorada?.concelho,
                            _valor_campo_: dadosMorada?.freguesia,
                        },
                        "freguesia"
                    );
                }
            }
        }

        if (props?.ficha_colaborador?.dias_trabalho) {
            const dias_trabalho = Utilitaries.toString(props?.ficha_colaborador?.dias_trabalho)?.split(" ");
            const dias_trabalho_label = [];
            plainOptions.forEach((element) => {
                if (dias_trabalho.includes(String(element.value))) {
                    dias_trabalho_label.push(element.label);
                }
            });

            props.onChangeWorkDays(dias_trabalho_label);
        }

        // if (props?.ficha_colaborador?.data_admissao_servico) {
        //     const { data_admissao_servico } = props?.ficha_colaborador;
        //     if (typeof data_admissao_servico !== "object") {
        //         props.getDataAdmissaoServico(data_admissao_servico);
        //     }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ficha_colaborador]);

    // GET DADOS DE PAIS
    // useEffect(() => {
    //     props.selectDataForFixedSelectList(
    //         {
    //             query: "pais",
    //             _valor_campo_: dadosMorada?.pais,
    //         },
    //         "pais"
    //     );
    // }, []);

    const nibFeedback = "O NIB deve ter 21 digitos!";
    const handleNibChange = (value) => {
        const isNumber = /^\d+$/.test(value);

        if (value?.toString()?.split("")?.length !== 21 || !isNumber) {
            setNibValidation({
                validateStatus: "error",
                errorMsg: "NIB deve ter 21 dígitos numéricos",
            });
            return false;
        }

        setNibValidation({
            validateStatus: "success",
            errorMsg: "Correto",
        });

        return true;
    };

    const ibanFeedback = "O IBAN deve ter 25 carateres!";
    const handleIbanChange = (value) => {
        const firstTwoCharater = value.slice(0, 2);
        const rest = value.substring(2);
        if (!/^\d+$/.test(rest?.replace(/\s/g, "")) || !/^[a-zA-Z]+$/.test(firstTwoCharater)) {
            setIbanValidation({
                validateStatus: "error",
                errorMsg: "Os dois primeiro digitos do IBAN deve ser letras e os restantes números",
            });
            return false;
        }

        if (value?.toString()?.replace(/\s/g, "")?.split("")?.length !== 25) {
            setIbanValidation({
                validateStatus: "error",
                errorMsg: "IBAN deve ter 25 carateres",
            });
            return false;
        }

        setIbanValidation({
            validateStatus: "success",
            errorMsg: "Correto",
        });

        return true;
    };

    return (
        <Row gutter={16}>
            <Col xs={24} sm={16}>
                <Form.Item label="Nome Completo">
                    {getFieldDecorator("nome_completo", {
                        initialValue:
                            entidade.nome_completo && typeof entidade.nome_completo !== "object"
                                ? entidade.nome_completo
                                : null,
                        rules: [
                            {
                                required: true,
                                message: "Campo Nome Completo é obrigatório",
                            },
                        ],
                    })(
                        <Input
                            placeholder="Introduza nome completo"
                            onChange={(e) => props.onChangeUsername(null, e.target.value)}
                        />
                    )}
                </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
                <Form.Item label="Nome a utilizar">
                    {getFieldDecorator("nome", {
                        initialValue: entidade.nome && typeof entidade.nome !== "object" ? entidade.nome : null,
                        rules: [
                            {
                                required: true,
                                message: "Campo Nome a utilizar é obrigatório",
                            },
                        ],
                    })(<Input placeholder="Introduza nome a utilizar" />)}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Telefone">
                    {getFieldDecorator("tlf", {
                        initialValue: entidade.tlf && typeof entidade.tlf !== "object" ? entidade.tlf : null,
                    })(<Input placeholder="Introduza nº telefone" />)}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Móvel">
                    {getFieldDecorator("tlm", {
                        initialValue: entidade.tlm && typeof entidade.tlm !== "object" ? entidade.tlm : null,
                    })(<Input placeholder="Introduza nº telemóvel" />)}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="E-mail">
                    {getFieldDecorator("email", {
                        initialValue: entidade.email && typeof entidade.email !== "object" ? entidade.email : null,
                        type: "email",
                        message: "Este não é um e-mail válido!",
                    })(<Input placeholder="Introduza email" />)}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item
                    validateStatus={ibanValidation.validateStatus}
                    help={ibanValidation.errorMsg || ibanFeedback}
                    label="IBAN"
                >
                    {getFieldDecorator("iban", {
                        initialValue: entidade.iban && typeof entidade.iban !== "object" ? entidade.iban : null,
                    })(<Input onChange={(e) => handleIbanChange(e.target.value)} placeholder="Introduza IBAN" />)}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item
                    validateStatus={nibValidation.validateStatus}
                    label="NIB"
                    help={nibValidation.errorMsg || nibFeedback}
                >
                    {getFieldDecorator("nib", {
                        initialValue: entidade.nib && typeof entidade.nib !== "object" ? entidade.nib : null,
                    })(<Input onChange={(e) => handleNibChange(e.target.value)} placeholder="Introduza NIB" />)}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Banco">
                    {getFieldDecorator("bank", {
                        initialValue: entidade.bank && typeof entidade.bank !== "object" ? entidade.bank : undefined,
                    })(
                        <Select
                            showSearch
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Seleciona uma opção"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {props.fixedList.bancos !== undefined &&
                                props.fixedList.bancos.data.map((item) => {
                                    return (
                                        <Option key={item.id_banco} value={item.id_banco}>
                                            {item.nome}
                                        </Option>
                                    );
                                })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Nº Identificação Seg. Social">
                    {getFieldDecorator("niss", {
                        initialValue: entidade.niss && typeof entidade.niss !== "object" ? entidade.niss : null,
                    })(<Input placeholder="Introduza nº Identificação Seg. Social" />)}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Nº Identificação Fiscal">
                    {getFieldDecorator("nif", {
                        initialValue: entidade.nif && typeof entidade.nif !== "object" ? entidade.nif : null,
                    })(<Input placeholder="Introduza nº Identificação Fiscal" />)}
                </Form.Item>
            </Col>
            {props.user_category !== 1 && props.register_type !== "Sócio" && props.register_type !== "Outro" ? (
                <div>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Data nascimento">
                            <Input
                                type="date"
                                onChange={(e) => props.getDataNascimento(e.target.value ? moment(e.target.value) : "")}
                                // format={"DD-MM-YYYY"}
                                value={
                                    props.parentState.data_nascimento
                                        ? moment(props.parentState.data_nascimento).format("YYYY-MM-DD")
                                        : null
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="País" style={{ padding: 0, marginBottom: 0 }}>
                            {getFieldDecorator("pais", {
                                initialValue: morada.pais && typeof morada.pais !== "object" ? morada.pais : undefined,
                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    placeholder="Seleciona uma opção"
                                    onChange={(pais) =>
                                        props.selectDataForFixedSelectList(
                                            {
                                                query: "distrito",
                                                pais: pais,
                                            },
                                            "distrito"
                                        )
                                    }
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {props.fixedList.pais !== undefined &&
                                        props.fixedList.pais.data.map((item) => {
                                            return (
                                                <Option key={item.idpais} value={item.idpais}>
                                                    {item.designacao}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Distrito" style={{ padding: 0, marginBottom: 0 }}>
                            {getFieldDecorator("distrito", {
                                initialValue:
                                    morada.distrito && typeof morada.distrito !== "object"
                                        ? morada.distrito
                                        : undefined,
                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    placeholder="Seleciona uma opção"
                                    onChange={(distrito) =>
                                        props.selectDataForFixedSelectList(
                                            {
                                                query: "concelho",
                                                distrito: distrito,
                                            },
                                            "concelho"
                                        )
                                    }
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {props.fixedList.distrito !== undefined &&
                                        props.fixedList.distrito.data.map((item) => {
                                            return (
                                                <Option key={item.id_dist_ilhas} value={item.id_dist_ilhas}>
                                                    {item.descricao}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Concelho">
                            {getFieldDecorator("concelho", {
                                initialValue:
                                    morada.concelho && typeof morada.concelho !== "object"
                                        ? morada.concelho
                                        : undefined,
                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    placeholder="Seleciona uma opção"
                                    onChange={(id_distconc) =>
                                        props.selectDataForFixedSelectList(
                                            {
                                                query: "freguesia",
                                                concelho: id_distconc,
                                            },
                                            "freguesia"
                                        )
                                    }
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {props.fixedList.concelho !== undefined &&
                                        props.fixedList.concelho.data.map((item) => {
                                            return (
                                                <Option key={item.id_distconc} value={item.id_distconc}>
                                                    {item.descricao}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Freguesia">
                            {getFieldDecorator("freguesia", {
                                initialValue:
                                    morada.freguesia && typeof morada.freguesia !== "object"
                                        ? morada.freguesia
                                        : undefined,
                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="Seleciona uma opção"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {props.fixedList.freguesia !== undefined &&
                                        props.fixedList.freguesia.data.map((item) => {
                                            return (
                                                <Option key={item.id_freguesia} value={item.id_freguesia}>
                                                    {item.descricao}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16}>
                        <Form.Item label="Arruamento">
                            {getFieldDecorator("arruamento", {
                                initialValue:
                                    morada.arruamento && typeof morada.arruamento !== "object"
                                        ? morada.arruamento
                                        : null,
                            })(<Input placeholder="Introduza o arruamento" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Col xs={morada.c_postal3 && typeof morada.c_postal3 !== "object" ? 12 : 24}>
                            <Form.Item label="Código postal">
                                {getFieldDecorator("c_postal4", {
                                    initialValue:
                                        morada.c_postal4 && typeof morada.c_postal4 !== "object"
                                            ? morada.c_postal4
                                            : null,
                                })(<Input placeholder="Introduza código postal" />)}
                            </Form.Item>
                        </Col>
                        {morada.c_postal3 && typeof morada.c_postal3 !== "object" ? (
                            <Col xs={12}>
                                <Form.Item label="-">
                                    {getFieldDecorator("c_postal3", {
                                        initialValue:
                                            morada.c_postal3 && typeof morada.c_postal3 !== "object"
                                                ? morada.c_postal3
                                                : null,
                                    })(<Input placeholder="Introduza código postal" />)}
                                </Form.Item>
                            </Col>
                        ) : null}
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Porta">
                            {getFieldDecorator("porta", {
                                initialValue: morada.porta && typeof morada.porta !== "object" ? morada.porta : null,
                            })(<Input placeholder="Introduza porta" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Andar">
                            {getFieldDecorator("andar", {
                                initialValue: morada.andar && typeof morada.andar !== "object" ? morada.andar : null,
                            })(<Input placeholder="Introduza andar" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Habilitação literária" style={{ padding: 0, marginBottom: 0 }}>
                            {getFieldDecorator("habilitacao", {
                                initialValue:
                                    ficha_colaborador.habilitacao && typeof ficha_colaborador.habilitacao !== "object"
                                        ? ficha_colaborador.habilitacao
                                        : undefined,
                            })(<QaDynamicSelect name="habilitacao" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Data entrada serviço">
                            <Input
                                type="date"
                                onChange={(e) =>
                                    props.getDataAdmissaoServico(
                                        e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : ""
                                    )
                                }
                                // format={"DD-MM-YYYY"}
                                value={
                                    props.parentState.data_admissao_servico
                                        ? moment(props.parentState.data_admissao_servico).format("YYYY-MM-DD")
                                        : null
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Nº INPS">
                            {getFieldDecorator("inps", {
                                initialValue:
                                    ficha_colaborador.inps && typeof ficha_colaborador.inps !== "object"
                                        ? ficha_colaborador.inps
                                        : null,
                            })(<Input placeholder="Introduza nº INPS" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Número Mecanográfico">
                            {getFieldDecorator("num_mecanografico", {
                                initialValue:
                                    ficha_colaborador.num_mecanografico &&
                                    typeof ficha_colaborador.num_mecanografico !== "object"
                                        ? ficha_colaborador.num_mecanografico
                                        : null,
                            })(<Input placeholder="Introduza número mecanográfico" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Escalão">
                            {getFieldDecorator("escalao", {
                                initialValue:
                                    ficha_colaborador.escalao && typeof ficha_colaborador.escalao !== "object"
                                        ? ficha_colaborador.escalao
                                        : null,
                            })(<Input placeholder="Introduza escalão" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Tipo Contrato">
                            {getFieldDecorator("tipo_contrato", {
                                initialValue:
                                    ficha_colaborador.tipo_contrato &&
                                    typeof ficha_colaborador.tipo_contrato !== "object"
                                        ? ficha_colaborador.tipo_contrato
                                        : null,
                            })(<Input placeholder="Introduza tipo de contrato" />)}
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Modalidade" style={{ padding: 0, marginBottom: 0 }}>
                            {getFieldDecorator("modalidade", {
                                initialValue: ficha_colaborador.modalidade && typeof ficha_colaborador.modalidade !== "object" ? ficha_colaborador.modalidade : undefined,
                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    placeholder="Seleciona uma opção"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                  <Option value='1'
                                  >
                                      Tempo total
                                  </Option>
                                  <Option value='2'
                                  >
                                      Tempo parcial
                                  </Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Afetação">
                            {getFieldDecorator("afetacao", {
                                initialValue:
                                    ficha_colaborador.afetacao && typeof ficha_colaborador.afetacao !== "object"
                                        ? ficha_colaborador.afetacao
                                        : null,
                            })(<Input placeholder="Introduza a %" />)}
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Modelo" style={{ padding: 0, marginBottom: 0 }}>
                            {getFieldDecorator("modelo", {
                                initialValue: ficha_colaborador.modelo && typeof ficha_colaborador.modelo !== "object" ? ficha_colaborador.modelo : undefined,
                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    placeholder="Seleciona uma opção"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                  <Option value='1'>
                                    Presencial
                                  </Option>
                                  <Option value='2'>
                                     Teletrabalho
                                  </Option>
                                  <Option value='3'>
                                    Misto
                                  </Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Horas de Tabralho">
                            {getFieldDecorator("horas_trabalho", {
                                initialValue:
                                    ficha_colaborador.horas_trabalho &&
                                    typeof ficha_colaborador.horas_trabalho !== "object"
                                        ? ficha_colaborador.horas_trabalho
                                        : null,
                            })(<Input placeholder="Introduza horas de trabalho" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={16}>
                        <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                            Dias de Trabalho
                            <Checkbox
                                style={{ marginLeft: "12px" }}
                                indeterminate={props.state.indeterminate}
                                onChange={(e) => props.onCheckAllChange(e)}
                                checked={props.state.checkAll}
                            >
                                Todos os dias da semana
                            </Checkbox>
                        </div>
                        <br />
                        <CheckboxGroup
                            // defaultValue={
                            //     ficha_colaborador.dias_trabalho && typeof ficha_colaborador.dias_trabalho !== "object"
                            //         ? ficha_colaborador.dias_trabalho.split(" ").map((item) => plainOptions[item])
                            //         : props.state.checkedList
                            // }
                            options={plainOptions.map((item) => item.label)}
                            value={props.state.checkedList}
                            onChange={(e) => props.onChangeWorkDays(e)}
                        />
                    </Col>
                    <Col xs={24}>
                        <Tooltip placement="right" title={info}>
                            <Button icon="info-circle" onClick={() => props.showModal()}>
                                Acessos a validação
                            </Button>
                        </Tooltip>
                        {props.parentState.acessos_validacao && <Icon type="check" style={{ color: "green" }} />}
                    </Col>
                </div>
            ) : null}
        </Row>
    );
};
const mapDispatchToProps = {
    selectDataForFixedSelectList,
};
export default connect(null, mapDispatchToProps)(PersonalData);
