import { sendHttpRequest } from "../../../components/http.request";
import { validateResponse } from "../../../components/util";

export const getDocuments = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "consultar");
    Object.entries(requestBody).forEach(([key, val]) => {
        if (val && !Array.isArray(val)) formData.append(key, val);
    });

    const response = await sendHttpRequest("POST", "/Gestor/gereconsulta.php", formData);
    if (response) {
        const metadata = response["@attributes"];
        const lista = validateResponse(response).map((item) => ({
            ...item,
            estado: Number(item.estado),
        }));
        return {
            lista,
            metadata: {
                resultados: Number(metadata.resultados),
                pagina: Number(metadata.pagina),
                visivel: metadata.visivel,
                resultados_pagina: Number(metadata.resultados_pagina),
            },
        };
    }
    return false;
};

export const getProcess = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista_processos");
    const response = await sendHttpRequest("POST", "/Gestor/gereconsulta.php", formData);
    if (response) {
        const list = validateResponse(response);
        return list.filter((item) => typeof item.nome !== "object");
    }
    return false;
};

export const getTasks = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista_tarefas");

    if (requestBody.processo) formData.append("processo", requestBody.processo);
    const response = await sendHttpRequest("POST", "/Gestor/gereconsulta.php", formData);
    if (response) return validateResponse(response);
    return false;
};

export const getDecision = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista_decisao");

    if (requestBody.processo) formData.append("processo", requestBody.processo);
    const response = await sendHttpRequest("POST", "/Gestor/gereconsulta.php", formData);
    if (response) return validateResponse(response);
    return false;
};

export const getUsers = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista_users_consulta");
    const response = await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
    if (response) return validateResponse(response);
    return false;
};
