import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Upload, Icon, Form, Input, Tabs, Select, InputNumber, Radio, message } from "antd";
import { connect } from "react-redux";
import moment from "moment";

import { QaDynamicSelect } from "../../../components/DynamicList/index";
import { selectDataForFixedSelectList } from "../../fixed_list/actions/index";
import { handleUserInfo } from "../actions/index";
import { Session, Utilitaries } from "../../../business";
import { api_fetch } from "../../../V2/utils/HttpRequest/request";

const { TabPane } = Tabs;
const { Option } = Select;
const { Password } = Input;

const EditUserInfo = ({
    isVisible,
    showEditUserInfoModel,
    selectDataForFixedSelectList,
    fixedList,
    getProfilePhoto,
    form,
}) => {
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [confirmDirty, setConfirmDirty] = useState(false);

    const [imageUrl, setImageUrl] = useState(undefined);
    const [fullName, setFullName] = useState(undefined);
    const [nameToUse, setNameToUse] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [nif, setNif] = useState(undefined);
    const [habilityAcademic, setHabilityAcademic] = useState(undefined);
    const [birthDate, setBirthDate] = useState(undefined);
    const [gender, setGender] = useState(undefined);
    const [phone, setPhone] = useState(undefined);
    const [celphone, setCelphone] = useState(undefined);
    const [country, setCountry] = useState(undefined);
    const [district, setDistrict] = useState(undefined);
    const [residence, setResidence] = useState(undefined);
    const [parish, setParish] = useState(undefined);
    const [street, setStreet] = useState(undefined);
    const [postalCode4, setPostalCode4] = useState(undefined);
    const [postalCode3, setPostalCode3] = useState(undefined);
    const [door, setDoor] = useState(undefined);
    const [piso, setPiso] = useState(undefined);
    const [bank, setBank] = useState(undefined);
    const [iban, setIban] = useState(undefined);
    const [nib, setNib] = useState(undefined);
    const [username, setUsername] = useState(undefined);

    const [lingua, setLingua] = useState(undefined);
    const [linguaOld, setLinguaOld] = useState(undefined);
    const [listLingua, setListLingua] = useState(undefined);

    const { getFieldDecorator } = form;

    useEffect(() => {
        const loadData = async () => {
            const result = await api_fetch({
                endPoint: "Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: `buscar_linguas`,
                },
            });

            if (result.status === "SUCCESS") {
                setListLingua(result?.response.result);
            }
        };
        if (isVisible) {
            loadData();
        }
    }, [isVisible]);

    useEffect(() => {
        (async () => {
            if (isVisible) {
                return;
            }

            const response = await handleUserInfo({ accao: "get_info" });
            if (response && response.status === "sucess" && response.data) {
                setValuesToFields(response.data);
                getProfilePhoto(response.data.foto_perfil);

                selectDataForFixedSelectList(
                    {
                        query: "pais",
                        _valor_campo_: response?.data?.idpais,
                    },
                    "pais"
                );
            } else {
                message.error(response.message);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    // GET DADOS DE PAIS
    // useEffect(() => {
    //     selectDataForFixedSelectList(
    //         {
    //             query: "pais",
    //             _valor_campo_: country,
    //         },
    //         "pais"
    //     );
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        if (district) {
            selectDataForFixedSelectList(
                {
                    query: "distrito",
                    _valor_campo_: district,
                },
                "distrito"
            );
        }
    }, [district, selectDataForFixedSelectList]);
    useEffect(() => {
        if (residence) {
            selectDataForFixedSelectList(
                {
                    query: "concelho",
                    _valor_campo_: residence,
                },
                "concelho"
            );
        }
    }, [residence, selectDataForFixedSelectList]);
    useEffect(() => {
        if (parish) {
            selectDataForFixedSelectList(
                {
                    query: "freguesia",
                    _valor_campo_: parish,
                },
                "freguesia"
            );
        }
    }, [parish, selectDataForFixedSelectList]);

    const setValuesToFields = (response) => {
        if (response) {
            setUsername(response.username || undefined);
            setFullName(response.nome_completo || undefined);
            setNameToUse(response.nome || undefined);
            setPiso(response.andar || undefined);
            setStreet(response.arruamento || undefined);
            setBank(response.bank || undefined);
            setBirthDate(response.data_nascimento || undefined);
            setDistrict(response.distrito || undefined);
            setEmail(response.email || undefined);
            setHabilityAcademic(response.habilitacao || undefined);
            setIban(response.iban || undefined);
            setResidence(response.id_distconc || undefined);
            setParish(response.id_freguesia || undefined);
            setCountry(response.idpais || undefined);
            setNib(response.nib || undefined);
            setNif(response.nif || undefined);
            setDoor(response.porta || undefined);
            setPostalCode4(response.c_postal4 || undefined);
            setPostalCode3(response.c_postal3 || undefined);
            setGender(response.sexo || undefined);
            setPhone(response.tlf || undefined);
            setCelphone(response.tlm || undefined);
            setImageUrl(response.foto_perfil || undefined);
            setLingua(response.lingua || undefined);
            setLinguaOld(response.lingua || undefined);
        }
    };

    const uploadButton = (
        <div>
            <Icon type={loading ? "loading" : "plus"} />
            <div className="ant-upload-text">Clique para carregar</div>
        </div>
    );

    const handleChange = (info) => {
        getBase64(info.file.originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
            setLoading(false);
        });
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const renderPersonalInformationsFields = () => (
        <Row gutter={16}>
            <Col xs={24} md={8} style={{ justifyContent: "center", alignItems: "center" }}>
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    // beforeUpload={() => { return false; }}
                    onChange={handleChange}
                >
                    {imageUrl ? (
                        <img src={imageUrl} alt="avatar" style={{ width: "100%", maxHeight: 300 }} />
                    ) : (
                        uploadButton
                    )}
                </Upload>
            </Col>
            <Col xs={24} md={16}>
                <Form layout="vertical">
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item label="Nome completo">
                                {getFieldDecorator("nome_completo", {
                                    initialValue: fullName,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label="Nome a utilizar">
                                {getFieldDecorator("nome", {
                                    initialValue: nameToUse,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label="E-mail">
                                {getFieldDecorator("email", {
                                    initialValue: email,
                                    type: "email",
                                    message: "Este não é um e-mail válido",
                                })(<Input placeholder="E-mail" />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label="Nº de identificação social / fiscal">
                                {getFieldDecorator("nif", {
                                    initialValue: nif,
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label="Habilitação literária">
                                {getFieldDecorator("habilitacao", {
                                    initialValue: habilityAcademic,
                                })(<QaDynamicSelect name="habilitacao" />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label="Telemóvel">
                                {getFieldDecorator("tlm", {
                                    initialValue: phone,
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label="Telefone">
                                {getFieldDecorator("tlf", {
                                    initialValue: celphone,
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label="Género">
                                {getFieldDecorator("sexo", {
                                    initialValue: gender,
                                })(
                                    <Radio.Group buttonStyle="solid">
                                        <Radio.Button value="Masculino">Masculino</Radio.Button>
                                        <Radio.Button value="Feminino">Feminino</Radio.Button>
                                        <Radio.Button value="Outro">Outro</Radio.Button>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label="Data nascimento">
                                <Input
                                    type="date"
                                    defaultValue={birthDate}
                                    onChange={(e) =>
                                        setBirthDate(e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "")
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );

    const renderAdreesInformationsFields = () => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col xs={24} md={8}>
                    <Form.Item label="País">
                        {getFieldDecorator("idpais", {
                            initialValue: country,
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                onChange={(pais) =>
                                    selectDataForFixedSelectList(
                                        {
                                            query: "distrito",
                                            pais: pais,
                                        },
                                        "distrito"
                                    )
                                }
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {fixedList.pais !== undefined &&
                                    fixedList.pais.data.map((item) => {
                                        return (
                                            <Option key={item.idpais} value={item.idpais}>
                                                {item.designacao}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item label="Distrito">
                        {getFieldDecorator("distrito", {
                            initialValue: district,
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                onChange={(distrito) =>
                                    selectDataForFixedSelectList(
                                        {
                                            query: "concelho",
                                            distrito: distrito,
                                        },
                                        "concelho"
                                    )
                                }
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {fixedList.distrito !== undefined &&
                                    fixedList.distrito.data.map((item) => {
                                        return (
                                            <Option key={item.id_dist_ilhas} value={item.id_dist_ilhas}>
                                                {item.descricao}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item label="Concelho">
                        {getFieldDecorator("id_distconc", {
                            initialValue: residence,
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                onChange={(id_distconc) =>
                                    selectDataForFixedSelectList(
                                        {
                                            query: "freguesia",
                                            concelho: id_distconc,
                                        },
                                        "freguesia"
                                    )
                                }
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {fixedList.concelho !== undefined &&
                                    fixedList.concelho.data.map((item) => {
                                        return (
                                            <Option key={item.id_distconc} value={item.id_distconc}>
                                                {item.descricao}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item label="Freguesia">
                        {getFieldDecorator("id_freguesia", {
                            initialValue: parish,
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {fixedList.freguesia !== undefined &&
                                    fixedList.freguesia.data.map((item) => {
                                        return (
                                            <Option key={item.id_freguesia} value={item.id_freguesia}>
                                                {item.descricao}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item label="Arruamento">
                        {getFieldDecorator("arruamento", {
                            initialValue: street,
                        })(<Input />)}
                    </Form.Item>
                </Col>
                <Col xs={24} md={postalCode3 ? 4 : 8}>
                    <Form.Item label="Código postal">
                        {getFieldDecorator("c_postal4", {
                            initialValue: postalCode4,
                        })(<Input placeholder="Código postal" />)}
                    </Form.Item>
                </Col>
                {postalCode3 && (
                    <Col xs={24} md={4}>
                        <Form.Item label=":">
                            {getFieldDecorator("c_postal3", {
                                initialValue: postalCode3,
                            })(<Input placeholder="Código postal" />)}
                        </Form.Item>
                    </Col>
                )}
                <Col xs={24} md={8}>
                    <Form.Item label="Porta">
                        {getFieldDecorator("porta", {
                            initialValue: door,
                        })(<Input />)}
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item label="Andar">
                        {getFieldDecorator("andar", {
                            initialValue: piso,
                        })(<Input />)}
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    const renderBankInformationsFields = () => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col xs={24} md={8} offset={8}>
                    <Form.Item label="Banco">
                        {getFieldDecorator("bank", {
                            initialValue: bank,
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {fixedList.bancos !== undefined &&
                                    fixedList.bancos.data.map((item) => {
                                        return (
                                            <Option key={item.id_banco} value={item.id_banco}>
                                                {item.nome}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} md={8} offset={8}>
                    <Form.Item label="IBAN">
                        {getFieldDecorator("iban", {
                            initialValue: iban,
                        })(<Input />)}
                    </Form.Item>
                </Col>
                <Col xs={24} md={8} offset={8}>
                    <Form.Item label="NIB">
                        {getFieldDecorator("nib", {
                            initialValue: nib,
                        })(<Input placeholder="NIB" />)}
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    const renderAccessDataInformationsFields = () => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col xs={24} md={8} offset={8}>
                    <Form.Item label="Utilizador">
                        {getFieldDecorator("username", {
                            initialValue: username,
                        })(<Input disabled={true} placeholder="Utilizador" />)}
                    </Form.Item>
                </Col>
                {/* <Col xs={24} md={8} offset={8}>
                    <Form.Item label="Senha atual" hasFeedback>
                        {getFieldDecorator("senha_atual")(<Password placeholder="Senha" visibilityToggle />)}
                    </Form.Item>
                </Col>
                <Col xs={24} md={8} offset={8}>
                    <Form.Item label="Nova senha" hasFeedback>
                        {getFieldDecorator("senha", {
                            rules: [{ validator: validateToNextPassword }],
                        })(<Password placeholder="Senha" visibilityToggle />)}
                    </Form.Item>
                </Col>
                <Col xs={24} md={8} offset={8}>
                    <Form.Item label="Confirmar nova senha" hasFeedback>
                        {getFieldDecorator("confirmar_senha", {
                            rules: [{ validator: compareToFirstPassword }],
                        })(<Password onBlur={handleConfirmBlur} placeholder="Confirmar senha" visibilityToggle />)}
                    </Form.Item>
                </Col> */}
            </Row>
        </Form>
    );

    const renderPlataformConfiguration = () => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col xs={24} md={8}>
                    <Form.Item label="Língua">
                        {getFieldDecorator("lingua", {
                            initialValue: lingua,
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                onChange={(_lingua) => setLingua(_lingua)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {listLingua !== undefined &&
                                    Utilitaries.toArray(listLingua).map((item) => {
                                        return (
                                            <Option key={item.sigla} value={item.sigla}>
                                                {item.designacao}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    const handleConfirmBlur = (e) => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue("senha")) {
            callback("As senhas introduzidas não são iguais");
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        if (value && confirmDirty) {
            form.validateFields(["confirmar_senha"], { force: true });
        }
        callback();
    };

    const hanldeSaveUserInfo = () => {
        form.validateFields(async (err, values) => {
            if (!err) {
                debugger;
                setLoading(true);
                let requestBody = {};
                if (imageUrl) requestBody = { ...requestBody, foto_perfil: imageUrl };
                if (birthDate)
                    requestBody = {
                        ...requestBody,
                        data_nascimento: birthDate,
                    };
                Object.entries(values).forEach(([key, val]) => {
                    if (val && val.trim()) {
                        requestBody = {
                            ...requestBody,
                            [key]: val.trim(),
                        };
                    }
                });

                const response = await handleUserInfo({
                    accao: "editar_info",
                    dados: JSON.stringify(requestBody),
                });

                setLoading(false);
                if (response.status === "sucess") {
                    const isChangedLanguage = linguaOld !== values.lingua;
                    setValuesToFields(response.data.user);

                    getProfilePhoto(response.data.user.foto_perfil);
                    if (response && response.data && response.data.token) {
                        const user = Session.getAll();
                        if (user && user.username && user.username !== response.data.user.username) {
                            Session.update({
                                username: response.data.user.username,
                                name: response.data.user.nome,
                                token: response.data.token,
                            });
                        }
                    }
                    message.success(response.message);
                    showEditUserInfoModel();
                    if (isChangedLanguage) {
                        window.location.reload();
                    }
                } else {
                    message.error(response.message);
                }
            } else {
                const requeredFields = [
                    { name: "nome", label: "Nome a utilizar" },
                    { name: "nome_completo", label: "Nome completo" },
                ];

                requeredFields.forEach((item) => {
                    if (err[item.name]) {
                        message.error(`Campo obrigatório: ${item.label}`);
                    }
                });
            }
        });
    };

    return (
        <Modal
            centered
            confirmLoading={loading}
            destroyOnClose
            title="Editar informações"
            visible={isVisible}
            width="70%"
            bodyStyle={{ minHeight: 480 }}
            onCancel={() => showEditUserInfoModel()}
            onOk={() => hanldeSaveUserInfo()}
        >
            <Tabs defaultActiveKey={activeTab} onChange={() => {}}>
                <TabPane tab="Informação pessoal" key="1">
                    {renderPersonalInformationsFields()}
                </TabPane>
                <TabPane tab="Morada" key="2">
                    {renderAdreesInformationsFields()}
                </TabPane>
                <TabPane tab="Informação bancária" key="3">
                    {renderBankInformationsFields()}
                </TabPane>
                <TabPane tab="Dados de acesso" key="4">
                    {renderAccessDataInformationsFields()}
                </TabPane>
                <TabPane tab="Configurações da plataforma" key="5">
                    {renderPlataformConfiguration()}
                </TabPane>
            </Tabs>
        </Modal>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        listOfItems: state.dynamicList.domain.byId,
        fixedList: state.fixedList.domain.byId,
    };
};

const mapDispatchToProps = {
    selectDataForFixedSelectList,
};

const WrappedNewContributor = Form.create({ name: "edit_user" })(EditUserInfo);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNewContributor);
