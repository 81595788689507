import React, { Component } from "react";
import { PageHeader, Table, Icon, Button, Input, Row, Col, Pagination, Divider } from "antd";
import ButtonsGroup from "./buttons.group";
import CreateTimeRegistration from "./create.time.registration";
import EditTimeRegistration from "./edit.time.registration";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import * as actionCreators from "./../../actions/time.registration";
import { tableFooter, tableSortData, downloadFile } from "./../../../../components/util";
import { tableActions } from "./../../constants/time.registration";
import moment from "moment";

class TimeRegistrationIndex extends Component {
    componentDidMount() {
        if (!this.props.isFetched) this.selectData({ pagina: 1, resultados_pagina: 10 });
    }

    goto = (path) => {
        this.props.history.push({
            pathname: path,
        });
    };

    state = {
        searchText: "",
        isFormCreateVisible: false,
        isFormEditVisible: false,
        isFetching: false,
        filterParameters: {},
        tableAction: null,
    };

    selectData = (obj) => {
        this.setState({
            isFetching: true,
        });
        this.props
            .select({
                ...this.state.filterParameters,
                ...obj,
            })
            .then(() => {
                this.setState({
                    isFetching: false,
                });
            });
    };

    /** for search results in table */
    getColumnSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => {
            text = typeof text === "object" ? "" : text;
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            );
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    /****************************** */
    columns = [
        {
            title: "Data / hora de registo de entrada",
            dataIndex: "system_data_entrada",
            ...this.getColumnSearchProps(),
            sorter: (a, b) => {
                return tableSortData(moment(a.ordenacao_data_entrada), b.ordenacao_data_entrada);
            },
            defaultSortOrder: "descend",

            render: (text, record) => {
                if (typeof record.system_data_entrada === "object")
                    return <span>{`${record.data_registo} ${record.user_data_entrada}`}</span>;
                else return <span>{text}</span>;
            },
        },
        {
            title: "Data / hora de entrada",
            ...this.getColumnSearchProps(),
            dataIndex: "user_data_entrada",
            render: (text, record) => {
                if (typeof record.system_data_entrada === "object") return <span>{`${record.data_dia} ${text}`}</span>;
                else
                    return (
                        <>
                            {record.system_data_entrada === record.user_data_entrada ? (
                                <span>{text}</span>
                            ) : (
                                <span style={{ color: "#1890ff" }}>{text}</span>
                            )}
                        </>
                    );
            },
        },
        {
            title: "Data / hora de registo de saída",
            dataIndex: "system_data_saida",
            ...this.getColumnSearchProps(),
            render: (text, record) => {
                if (typeof record.user_data_saida !== "object") {
                    if (typeof record.system_data_saida === "object")
                        return <span>{`${record.data_registo} ${record.user_data_saida}`}</span>;
                    else return <span>{text}</span>;
                }
            },
        },
        {
            title: "Data / hora de saída",
            dataIndex: "user_data_saida",
            ...this.getColumnSearchProps(),
            render: (text, record) => {
                if (typeof record.user_data_saida !== "object") {
                    if (typeof record.system_data_saida === "object") {
                        return <span>{`${record.data_dia} ${text}`}</span>;
                    } else
                        return (
                            <>
                                {record.system_data_saida === record.user_data_saida ? (
                                    <span>{text}</span>
                                ) : (
                                    <span style={{ color: "#1890ff" }}>{text}</span>
                                )}
                            </>
                        );
                }
            },
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: 300,
            fixed: "right",
            render: (text, record) => {
                return (
                    <>
                        <Button
                            type="link"
                            size="small"
                            style={{
                                padding: 0,
                            }}
                            onClick={() => this.handleTableAction(record.id, tableActions.detalhes)}
                        >
                            Detalhes
                        </Button>

                        <Divider type="vertical" />
                        <Button
                            type="link"
                            size="small"
                            style={{
                                padding: 0,
                            }}
                            onClick={() => this.handleTableAction(record.id, tableActions.editar)}
                        >
                            Editar
                        </Button>

                        {typeof record.user_data_saida === "object" && (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    type="link"
                                    size="small"
                                    style={{
                                        padding: 0,
                                    }}
                                    onClick={() => this.handleTableAction(record.id, tableActions.marcarSaida)}
                                >
                                    Marcar saída
                                </Button>
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    /********************************* list */
    handlePageChange = (page, pageSize) => {
        this.selectData({ pagina: page, resultados_pagina: pageSize });
    };
    handleShowSizeChange = (current, pageSize) => {
        this.selectData({ pagina: current, resultados_pagina: pageSize });
    };
    handleTableChange = (pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val[0]) obj["filtro_" + key] = val[0];
            });
        }
        if (sorter.columnKey) {
            const value = sorter.order === "ascend" ? "ASC" : "DESC";
            obj["ordenacao_" + sorter.columnKey] = value;
        }
        const objFilter = {
            pagina: 1,
            resultados_pagina: this.props.resultadosPagina,
            ...obj,
        };
        this.setState({ filterParameters: obj }, () => {
            this.selectData(objFilter);
        });
    };

    //-------------- create time registration ----------------
    showModal = () => {
        this.props.getForm(this.props.metatadaForm).then(() => {
            this.setState({
                isFormCreateVisible: true,
            });
        });
    };

    handleCreateOk = () => {
        this.setState({
            isFormCreateVisible: false,
        });
        this.selectData({
            pagina: this.props.pagina,
            resultados_pagina: this.props.resultadosPagina,
        });
    };

    hiddeCreateModal = () => {
        this.setState({
            isFormCreateVisible: false,
        });
    };

    //-------------- edit time registration ----------------------
    handleTableAction = async (id, tableAction) => {
        await this.props.getById({ id, tableAction }).then(() => {});
        this.setState({
            isFormEditVisible: true,
            tableAction,
        });
    };

    handleEditOk = () => {
        this.setState({
            isFormEditVisible: false,
        });
        this.selectData({
            pagina: this.props.pagina,
            resultados_pagina: this.props.resultadosPagina,
        });
    };

    hiddeEditModal = () => {
        this.setState({
            isFormEditVisible: false,
        });
    };

    delete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };

    exportList = async (type) => {
        var params = {
            accao: "lista",
            lista: "qa_registo_ponto",
            saida: type,
            campos: "system_data_entrada:100:Data / hora de registo de entrada;user_data_entrada:100:Data / hora de entrada;system_data_saida:100:Data / hora de registo de saída;user_data_saida:100:Data / hora de saída;",
            downloadInBrowser: true,
        };
        if (this.state.filterParameters.filtro_system_data_entrada) {
            params.filtro_system_data_entrada = this.state.filterParameters.filtro_system_data_entrada;
        }
        if (this.state.filterParameters.filtro_user_data_entrada) {
            params.filtro_user_data_entrada = this.state.filterParameters.filtro_user_data_entrada;
        }
        if (this.state.filterParameters.filtro_system_data_saida) {
            params.filtro_system_data_saida = this.state.filterParameters.filtro_system_data_saida;
        }
        if (this.state.filterParameters.filtro_user_data_saida) {
            params.filtro_user_data_saida = this.state.filterParameters.filtro_user_data_saida;
        }
        downloadFile("/gerelistas.php", params);
    };

    render() {
        return (
            <>
                <PageHeader title="Registo de ponto" />
                {this.state.isFormCreateVisible && (
                    <CreateTimeRegistration
                        handleOk={this.handleCreateOk}
                        hiddeModal={this.hiddeCreateModal}
                        isVisible={this.state.isFormCreateVisible}
                    />
                )}
                {this.state.isFormEditVisible && (
                    <EditTimeRegistration
                        hiddelModal={this.hiddeEditModal}
                        handleOk={this.handleEditOk}
                        isVisible={this.state.isFormEditVisible}
                        tableAction={this.state.tableAction}
                    />
                )}
                <ButtonsGroup showModal={this.showModal.bind(this)} exportList={this.exportList} />
                <Table
                    rowKey={(record) => record.id}
                    dataSource={this.props.list}
                    columns={this.columns}
                    size="middle"
                    loading={this.state.isFetching}
                    pagination={false}
                    footer={() => tableFooter(this.props.total)}
                    onChange={this.handleTableChange}
                />
                {this.props.isFetched && (
                    <>
                        <Row>
                            <Col xs={24}>
                                <Pagination
                                    showTitle
                                    size="small"
                                    showSizeChanger
                                    showLessItems
                                    onShowSizeChange={this.handleShowSizeChange}
                                    onChange={this.handlePageChange}
                                    current={this.props.pagina}
                                    total={this.props.total}
                                    style={{ float: "right" }}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        list: Object.values(state.timeRegistration.domain.byId),
        isFetched: state.timeRegistration.app.isFetched,
        total: Number(state.timeRegistration.domain.metadata.attributes.resultados),
        pagina: Number(state.timeRegistration.domain.metadata.attributes.pagina),
        resultadosPagina: state.timeRegistration.domain.metadata.attributes.resultados_pagina,
        metatadaForm: state.timeRegistration.domain.metadata.formulario,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...actionCreators }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeRegistrationIndex);
