import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { List, Avatar } from "antd";

import { selectDataFromExecQuery } from "../../actions/funcional.group";

const EXEC_QUERY_NAME = {
    detentor: "detentor",
};

const Contributors = ({ selectDataFromExecQuery, dataFromExistGroup }) => {
    const { codigo } = dataFromExistGroup;
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (codigo) {
            setLoading(true);
            (async () => {
                const response = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.detentor,
                    codigo: codigo,
                });

                if (response.result) {
                    if (Array.isArray(response.result)) {
                        setDataSource(response.result);
                    }
                }
                setLoading(false);
            })();
        }
    }, [codigo]);

    return (
        <List
            header={<h3>Funcionários</h3>}
            loading={loading}
            itemLayout="horizontal"
            dataSource={dataSource}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={
                            <Avatar
                                src={
                                    item.foto_perfil ||
                                    "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                }
                            />
                        }
                        title={item.nome}
                        //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
            )}
        />
    );
};

const mapDispatchToProps = {
    selectDataFromExecQuery,
};

export default connect(null, mapDispatchToProps)(Contributors);
