import moment from "moment";
import { Utilitaries } from "../../../business";
import { toArray } from "../../../components/util";
import { getFieldInInputGroup } from "../../../modules/process_execution/constants/utils";
import { FormInputsObjectProps, FormInputsProps } from "../../types/formTypes";
import { getFormInputFromPath } from "./formHandle";

interface Props {
    componentsProperties?: FormInputsProps;
    formData: FormInputsObjectProps;
    params?: any;
    root?: any;
    record?: any;
    rowKey?: any;
    allDependencies?: any;
    allParams?: any;
    inputGroupPath?: any;
}

export const transformParams = ({
    params,
    root,
    record,
    rowKey,
    allDependencies,
    formData,
    allParams,
    inputGroupPath,
    componentsProperties,
}: Props) => {
    const paramsTransformed = params
        ?.reduce((acumulator: any, current: any) => {
            const splitedParam = current.split(",");
            const paramName = splitedParam[0];
            let paramNameToValue = splitedParam[1];
            current = { paramName, paramNameToValue };
            acumulator.push(current);
            return acumulator;
        }, [])
        .map((item: any) => {
            let value = null;
            if (item.paramNameToValue.startsWith("param.")) {
                // let paramNameToValue = item.paramNameToValue.replace("param.", "").replace(/\W/g, "").trim();
                // value = allParams?.[paramNameToValue];
            } else if (item.paramNameToValue.startsWith("x_lista:")) {
                // // //lista_presenca_sessao_v2/cliente[../participou!='false']})
                // let xpath = item.paramNameToValue.sub("x_lista:").substr(8);
                // xpath = xpath
                //     .replace(/\sor\s/gi, " || ")
                //     .replace(/\sand\s/gi, " && ")
                //     .replace(/=/gi, "==") //TODO: replace to safer ===, that evaluates type and value
                //     .replace(/'true'/gi, "'t'")
                //     .replace(/'false'/gi, "'f'")
                //     .replace(/(?:\\[rn])+/g, "")
                //     .replace(/!==/gi, "!=");
                // const rootPath = xpath.match(/\w+\s*(?=)/g)[1];
                // let table = Object.values(formData).find(
                //     (item) =>
                //         item[rootPath] &&
                //         (item["@tipo"] === componentType._dynamicTable ||
                //             item["@tipo"] === componentType._selectionTable)
                // );
                // if (table) {
                //     const regex = /\w*\s*(?=(=|\)|!|>?<))/g;
                //     let allFieldNames = xpath
                //         .match(regex)
                //         .filter((item) => item)
                //         .map((it) => it.trim());
                //     const tableValues = Object.values(table.dataSource);
                //     let conditionXpath = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);
                //     if (conditionXpath) {
                //         conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");
                //         let fieldToSend = xpath.match(/\w*\s*(?=\[)/g);
                //         if (!fieldToSend) {
                //             fieldToSend = conditionXpath;
                //         } else {
                //             fieldToSend = fieldToSend[0];
                //         }
                //         value = tableValues.reduce((accumulator, current) => {
                //             let condition = conditionXpath;
                //             allFieldNames.forEach((fieldName) => {
                //                 condition = condition.replace(fieldName, `current["${fieldName}"]`);
                //             });
                //             const check = eval(condition);
                //             if (check) {
                //                 accumulator = `${accumulator}${accumulator.length === 0 ? "" : ", "}'${
                //                     current[fieldToSend]
                //                 }'`;
                //             }
                //             return accumulator;
                //         }, "");
                //     } else if (xpath.match(/(?!=\/)\w+(?=\})/g)) {
                //         let fieldToSend = xpath.match(/(?!=\/)\w+(?=\})/g);
                //         value = tableValues.reduce((accumulator, current) => {
                //             accumulator = `${accumulator}${accumulator.length === 0 ? "" : ", "}'${
                //                 current[fieldToSend]
                //             }'`;
                //             return accumulator;
                //         }, "");
                //     }
                // }
            } else {
                //
                // ;

                const paramNameToValue = getParamNameToValue(item);

                if (Array.isArray(paramNameToValue)) {
                    let path = formData;

                    let firsts = paramNameToValue.slice(0, paramNameToValue.length - 1);
                    let last = paramNameToValue.splice(-1)[0];

                    firsts.forEach((el) => {
                        path = path[el];
                    });

                    value = path?.["dataSource"]?.[rowKey]?.[last];
                    if (!value) {
                        value = record?.[last];
                    }
                    if (!value) {
                        value = path?.[last]?.value || "";
                    }
                } else if (paramNameToValue?.startsWith("v:")) {
                    value = paramNameToValue.replace("v:", "");
                } else {
                    if (inputGroupPath || !root) {
                        if (isNaN(paramNameToValue)) {
                            const itemByKey = getItemByKey({
                                key: paramNameToValue,
                                tree: formData,
                                item,
                            });

                            const listItemByKey = toArray(itemByKey);
                            const sizeItemByKey = listItemByKey.length;
                            if (inputGroupPath && sizeItemByKey > 1) {
                                const itemValue = getFormInputFromPath(paramNameToValue, { ...formData }, [
                                    ...inputGroupPath,
                                ]);

                                value = itemValue?.value;
                            } else {
                                if (!inputGroupPath && !root && !rowKey && sizeItemByKey > 1) {
                                    if (item.paramNameToValue.startsWith("//")) {
                                        // get in root,
                                        value = getValueInRootElement({ listItemByKey, paramNameToValue });
                                    } else {
                                        value = itemByKey[0]?.value;
                                    }
                                } else {
                                    value = itemByKey[0]?.value;
                                }
                            }
                        } else {
                            value = paramNameToValue;
                        }
                        /* }
                    if (inputGroupPath) {
                        const itemValue = getFieldInInputGroup({ ...formData }, [...inputGroupPath], paramNameToValue);
                        value = itemValue?.value;
                    } else if (!root) {
                        if (isNaN(paramNameToValue)) {
                            // value = getItemByKey({
                            //     key: paramNameToValue,
                            //     tree: formData,
                            // })[0]?.value;
                            const itemByKey = getItemByKey({
                                key: paramNameToValue,
                                tree: formData,
                                item,
                            });
                            value = itemByKey[0]?.value;
                        } else {
                            value = paramNameToValue;
                        } */
                    } else {
                        if (item.paramNameToValue.startsWith("../") && record && record[paramNameToValue]) {
                            value = record?.[paramNameToValue];
                            if (typeof value === "object" && "id" in value) {
                                value = value.id;
                            }
                        } else {
                            const itemValue = Object.values(formData).find((formItem) => formItem.key === root)
                                ?.dataSource?.[rowKey]?.[paramNameToValue];
                            value = itemValue?.id || itemValue || formData?.[paramNameToValue]?.value; //TODO: alterar caso gerar problema
                            if (!value) {
                                value = record?.[paramNameToValue];
                            } else if (typeof value === "object" && "id" in value) {
                                value = value.id;
                            }
                        }
                    }
                }
            }

            //TODO: para booleam -  pode causar problema se for para enviar t ou f
            if (value === "t") {
                value = "true";
            } else if (value === "f") {
                value = "false";
            } else if (value === "{now()}") {
                value = moment().format("YYYY-MM-DD");
            }

            return { ...item, value: Utilitaries.toString(value) };
        })
        .reduce((acumulator: any, current: any) => {
            if (current.value) {
                acumulator[current.paramName] = current.value;
            }
            return acumulator;
        }, {});

    // TODO: check all cases above, if any cases are not included among the codes below, please, analyze and add it

    return paramsTransformed;
};

export const urlAndParamsSplit = (requestParamsToSplit: any, dependencies: any) => {
    // para este caso nbao houver ,virgula entre parametros
    //EX: "verificacao_higienizacoes_lin,{data_i,../data_inicio}{data_f,../data_conclusao},{espaco,//espaco},{area,//area_higienizacao},{executante,//executante})"

    if (!requestParamsToSplit) {
        return {};
    }

    requestParamsToSplit = requestParamsToSplit.replace(/}{/g, "},{");

    requestParamsToSplit = requestParamsToSplit.replace(/\s/g, "").replace(/{\$param\./g, "param.");
    const dataSourceBeginingIndex = requestParamsToSplit.indexOf("(") + 1;
    const splitResult = requestParamsToSplit.substring(dataSourceBeginingIndex).split(",{");
    const [queryName, ...params] = splitResult;
    return { queryName, params };
};

export const getParamNameToValue = (item: any) => {
    if (
        /'\w+'\}|^false\}/g.test(item.paramNameToValue) ||
        /(?<!(\.\.\/.*|\/\/.*))\w*}\)?/g.test(item.paramNameToValue)
    ) {
        return `v:${item.paramNameToValue.replace(/\W/g, "")}`;
    }

    if (item.paramNameToValue?.includes("://")) {
        const splitedParamNameToValue = item.paramNameToValue.split("/");
        return splitedParamNameToValue[splitedParamNameToValue.length - 1].replace(/\W/g, "").trim();
    } else if (/\/\/\w+\/\w+/.test(item.paramNameToValue)) {
        const replaceValueName = item.paramNameToValue
            .split("/")
            .filter((item: any) => !!item)
            .map((item: any) => item.replace(/\W/g, "").trim());
        return replaceValueName;
    }
    //  else if (!/\W/g.test(item.paramNameToValue)) {
    //     return `v:${item.paramNameToValue}`;
    // }

    return item.paramNameToValue?.replace(/\W/g, "").trim();

    // const paramNameToValue = (item.paramNameToValue.includes("://")
    //             ? (() => {
    //                   const splitedParamNameToValue = item.paramNameToValue.split("/");
    //                   return splitedParamNameToValue[splitedParamNameToValue.length - 1];
    //               })()
    //             : item.paramNameToValue
    //         )
    //             .replace(/\W/g, "")
    //             .trim();
};

interface GetItemByKeyProps {
    key?: any;
    rowKey?: any;
    tree?: any;
    field?: any;
    parent?: any;
    checkParent?: any;
    item?: any;
}

export const getItemByKey = ({
    key,
    rowKey,
    tree,
    field = [],
    parent = null,
    checkParent = false,
    item = {},
}: GetItemByKeyProps) => {
    const checkItemParent = (item: any) => {
        if (!checkParent) {
            return true;
        } else if (parent && parent === item?.parent) {
            return true;
        } else if (!parent && !item?.parent) {
            return true;
        } else {
            return false;
        }
    };

    // -- o código abaixo procura apenas na raiz --
    // if (item?.paramNameToValue?.startsWith("//")) {
    //     return [tree[key]];
    // }

    for (const propertie in tree) {
        if (propertie === key && tree[propertie]) {
            const item = tree[propertie];

            if (!item?.key && typeof item !== "string") {
                item["key"] = key;
            }

            if (checkItemParent(item)) {
                field.push(item);
            }
        } else if (propertie === "dataSource" && typeof tree[propertie] === "object") {
            getItemByKey({ key, tree: tree[propertie]?.[rowKey], field });
        } else if (typeof tree[propertie] === "object") {
            getItemByKey({ key, tree: tree[propertie], field });
        } else {
            continue;
        }
    }
    return field;
};

interface GetValueInRootElementProps {
    listItemByKey: any;
    paramNameToValue: any;
}
const getValueInRootElement = ({ listItemByKey, paramNameToValue }: GetValueInRootElementProps) => {
    for (const el of listItemByKey) {
        if (!el.isColumn && !el.root) {
            let value = el.value;
            if (typeof value === "object" && "id" in value) {
                value = value.id;
            }
            return value;
        }
    }
    return "";
};
