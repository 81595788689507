import React, { useState, memo } from "react";
import { Button, Modal, message } from "antd";
import { compareProps } from "../handle/inputHandle";
import { Group } from "../Components/Group";
import { FormLabel } from "../Components/FormLabel";
import { FormRender } from "..";
import { regexValidate } from "../handle/regexConversion";
import { CommunComponentsProps, GenericObject } from "../../../types/common";
import { Utilitaries } from "../../../../business";
import { sendHttpRequest } from "../../../../components/http.request";
import { api_fetch } from "../../../utils/HttpRequest/request";

interface ComponentsPropertiesProps extends CommunComponentsProps {}

interface ProcessMetadataProps {
    process?: string;
    task?: string;
}

const _QALinkToProcess: React.FC<ComponentsPropertiesProps> = ({
    componentsProperties,
    renderContext,
    formState,
    justSee,
    tableRow,
}) => {
    const [popupVisible, setPopupVisible] = useState(false);
    const [form, setForm] = React.useState<any>({});
    const [processMetadata, setProcessMetadata] = React.useState<ProcessMetadataProps>({});

    const [loading, setLoading] = React.useState(false);

    const getForm = async (popup_params: GenericObject) => {
        try {
            if (!componentsProperties.webaccao) {
                return;
            }
            const _formData = new FormData();
            _formData.append("processo", componentsProperties.webaccao);
            _formData.append("accao", "novo");
            if (componentsProperties.webtipo === "PROCESSO_CLIENTE") {
                _formData.append("estabelecimento", "1");
            }

            if (!Utilitaries.isEmpty(popup_params) && popup_params) {
                Object.keys(popup_params).forEach((el) => {
                    if (!_formData.has(el)) {
                        _formData.append(el, popup_params[el]);
                    }
                });
            }

            const { dados } = await sendHttpRequest("POST", "/Gestor/gereprocesso.php", _formData);

            const formBody = {
                form: dados?.formulario,
                values: dados?.valores || {},
            };
            setProcessMetadata({ process: dados?.processo["@id"], task: dados.tarefa["@id"] });
            setForm(formBody);
        } catch (error) {
            setForm({});
        }
    };

    const finishTask = async () => {
        try {
            const params = {
                accao: "terminar",
                processo: processMetadata.process || "",
                tarefa: processMetadata.task || "",
            };

            const response = await api_fetch({
                endPoint: `Gestor/gereprocesso.php`,
                method: "POST",
                params,
            });

            return response;
        } catch (error) {
            return false;
        }
    };

    const handleOnClick = async () => {
        setLoading(true);
        setPopupVisible(true);

        let params: GenericObject = {};
        if (componentsProperties.param_link) {
            const expression = regexValidate({
                expression: componentsProperties.param_link,
                formState,
                componentProperties: componentsProperties,
                rowTable: tableRow,
            });

            if (expression) {
                const paramsList = expression.split(";") || [];

                paramsList.forEach((param) => {
                    let [key, value] = param.split(",");
                    key = key.replace(/\W/g, "").trim();

                    if (key) {
                        value = value?.replace("]", "").trim();

                        if (value?.startsWith("'") && value?.endsWith("'")) {
                            value = value.substring(1, value.length - 1);
                        }

                        params[key] = value;
                    }
                });
            }
            console.log("expression", expression, params);
        }
        await getForm(params);
        setLoading(false);
    };

    const handleOnSaveForm = async () => {
        const resultFinish = await finishTask();

        if (resultFinish) {
            if (resultFinish.status === "SUCCESS") {
                setPopupVisible(false);
                setForm({});
                setProcessMetadata({});
            } else if (resultFinish.status === "ERROR") {
                message.error(resultFinish.message || "Ocorreu um erro ao terminar a tarefa");
            }
        } else {
            message.error("Ocorreu um erro");
        }
    };

    return (
        <>
            <Group componentsProperties={componentsProperties} renderContext={renderContext}>
                <FormLabel componentsProperties={componentsProperties} required={false} renderContext={renderContext}>
                    <Button
                        icon="tool"
                        disabled={justSee}
                        // disabled={currentTaskStatus || readonly}
                        onClick={() => handleOnClick()}
                    />
                </FormLabel>
            </Group>
            {popupVisible ? (
                <Modal
                    title={componentsProperties.label}
                    visible={popupVisible}
                    onCancel={() => setPopupVisible(false)}
                    width="90%"
                    footer={null}
                >
                    <FormRender
                        loadForm={loading}
                        form={form}
                        onSave={handleOnSaveForm}
                        isProcess={true}
                        isInModal={true}
                    ></FormRender>
                </Modal>
            ) : null}
        </>
    );
};

export const QALinkToProcess = memo(_QALinkToProcess, (prev, next) =>
    compareProps<ComponentsPropertiesProps>(prev, next)
);
