import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { imageLogoBase64Codification } from "../../../../constants/index";

const singleHeadRows = () => {
    return [
        [
            {
                content: "Início da atividade",
                colSpan: 3,
                styles: { halign: "center" },
            },
            {
                content: "Fim da atividade",
                colSpan: 3,
                styles: {
                    halign: "center",
                },
            },
            {
                content: "Horas",
                rowSpan: 2,
                styles: { halign: "center", valign: "center" },
            },
            {
                content: "Observações",
                rowSpan: 2,
                styles: { halign: "center", valign: "center" },
            },
        ],
        ["Sistema", "Utilizador", "Detalhes", "Sistema", "Utilizador", "Detalhes"],
    ];
};

const headRows = () => {
    return [
        [
            {
                content: "Funcionário",
                rowSpan: 2,
                styles: { halign: "center", valign: "middle" },
            },
            {
                content: "Início da atividade",
                colSpan: 3,
                styles: { halign: "center" },
            },
            {
                content: "Fim da atividade",
                colSpan: 3,
                styles: {
                    halign: "center",
                },
            },
            {
                content: "Horas",
                rowSpan: 2,
                styles: { halign: "center", valign: "middle" },
            },
            {
                content: "Observações",
                rowSpan: 2,
                styles: { halign: "center", valign: "middle" },
            },
        ],
        ["Sistema", "Utilizador", "Detalhes", "Sistema", "Utilizador", "Detalhes"],
    ];
};

function getDiferrenceInHours(startTime, endTime) {
    // const start = new Date(startTime);
    const start = moment(startTime, "DD-MM-YYYY HH:mm:ss");
    // const end = new Date(endTime);
    const end = moment(endTime, "DD-MM-YYYY HH:mm:ss");
    // const diff = end.getTime() - start.getTime();
    const diff = end.valueOf() - start.valueOf();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    const minutes = Math.floor(diff / 1000 / 60) % 60;
    const seconds = Math.floor(diff / 1000) % 60;

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        return "-";
    }

    return `${hours}h ${minutes}m ${seconds}s`;
}

export const prepareDataToReport = (value) => {
    let data = [];
    value.forEach((item) => {
        const systemStartDateTime =
            typeof item.data_registo === "object" ? item.system_data_entrada : item.data_registo;
        const userStartDateTime =
            typeof item.system_data_entrada === "object"
                ? `${item.data_dia} ${item.user_data_entrada}`
                : item.user_data_entrada;
        const startDetails =
            typeof item.motivo_alteracao_data_entrada !== "object" ? item.motivo_alteracao_data_entrada : "";
        const systemEndDateTime = typeof item.data_registo === "object" ? item.system_data_saida : item.data_registo;
        const userEndDateTime =
            typeof item.system_data_saida === "object" //&& item.system_data_saida !== null
                ? `${item.data_dia} ${item.user_data_saida}`
                : item.user_data_saida;
        const endDetails = typeof item.motivo_alteracao_data_saida !== "object" ? item.motivo_alteracao_data_saida : "";
        const observation = typeof item.observacoes !== "object" ? item.observacoes : "";
        // const startdate = new Date(userStartDateTime);
        // const endtdate = new Date(userEndDateTime);
        const details = {
            username: item.username,
            systemStartDateTime: systemStartDateTime,
            userStartDateTime: userStartDateTime,
            startDetails: startDetails,
            systemEndDateTime: systemEndDateTime,
            userEndDateTime: userEndDateTime,
            endDetails: endDetails,
            work_hours: getDiferrenceInHours(userStartDateTime, userEndDateTime), // endtdate.getHours() - startdate.getHours(),
            observation: observation,
        };
        data.push(details);
    });
    let final_data = [];
    if (data !== []) {
        data.forEach((item) => {
            if (!final_data[item.username]) {
                final_data[item.username] = [item];
            } else {
                final_data[item.username].push(item);
            }
        });
    }

    return final_data;
};

export const createPdfFile = (body_rows, interval) => {
    const color = {
        white: "#ffffff",
        textPrimary: "#000000",
        textSecondary: "#646464",
        grey: "#7a7a7a",
    };
    const doc = new jsPDF("landscape");
    const totalPagesExp = "{total_pages_count_string}";

    /** render table */
    const keys = Object.keys(body_rows);
    let columnStyle = {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },
        2: { cellWidth: 25 },
        3: { cellWidth: 40 },
        4: { cellWidth: 25 },
        5: { cellWidth: 25 },
        6: { cellWidth: 40 },
        7: { cellWidth: 15 },
    };
    if (keys.length === 1) {
        columnStyle = {
            0: { cellWidth: 25 },
            1: { cellWidth: 25 },
            2: { cellWidth: 40 },
            3: { cellWidth: 25 },
            4: { cellWidth: 25 },
            5: { cellWidth: 40 },
            6: { cellWidth: 15 },
        };
    }

    keys.forEach((item) => {
        const body_arr = [];
        const i = [];
        let a = "";
        body_rows[item].forEach((element) => {
            if (keys.length === 1) delete element.username;
            const row = Object.values(element);
            const [username, ...restOf] = row;
            a = username;
            if (keys.length === 1) i.push(row);
            else i.push(restOf);
        });
        const [first, ...others] = i;
        body_arr.push(
            [
                {
                    rowSpan: body_rows[item].length + 1,
                    content: a,
                    styles: { valign: "middle", halign: "center" },
                },
                ...first,
            ],
            ...others
        );

        doc.autoTable({
            columnStyles: columnStyle,
            styles: {
                lineColor: color.textPrimary,
                lineWidth: 0.25,
            },
            theme: "plain",
            head: keys.length === 1 ? singleHeadRows() : headRows(),
            body: keys.length === 1 ? i : body_arr,
            didDrawPage: (data) => {
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                const pageSize = doc.internal.pageSize;
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

                // Header
                doc.setFontSize(11);
                // doc.setTextColor(40);
                doc.setFontStyle("normal");
                if (imageLogoBase64Codification) {
                    doc.addImage(imageLogoBase64Codification, "PNG", data.settings.margin.left, 15);
                }
                // doc.text("Report", data.settings.margin.left + 15, 22);
                // doc.setFontStyle("bold");
                // doc.text("< Nome Instituição >", data.settings.margin.left, 32);

                /** draw grey rectangle */
                doc.setFillColor(color.grey);
                doc.rect(data.settings.margin.left, 34, pageWidth - 28, 8, "F");
                doc.setTextColor(color.white);
                doc.setFontStyle("bold");
                doc.text("REGISTO DE PONTO - detalhado", data.settings.margin.left + 1, 39);

                /** set contributor name and date */
                doc.setFontSize(8);
                doc.setTextColor(color.textPrimary);
                doc.setFontStyle("bold");
                if (keys.length === 1) {
                    doc.text("Funcionário: ", data.settings.margin.left, 48);
                    doc.setTextColor(color.textSecondary);
                    doc.text(`${item}`, data.settings.margin.left + doc.getTextWidth("Funcionário: "), 48);
                }
                doc.setTextColor(color.textPrimary);
                doc.setFontStyle("bold");
                doc.text(
                    "Período: ",
                    pageWidth -
                        doc.getTextWidth(`Período: ${interval.startDate} - ${interval.endDate}`) -
                        data.settings.margin.left,
                    48
                );
                doc.setTextColor(color.textSecondary);
                doc.text(
                    `${interval.startDate} - ${interval.endDate}`,
                    pageWidth -
                        doc.getTextWidth(`${interval.startDate} - ${interval.endDate}`) -
                        data.settings.margin.left,
                    48
                );

                // Footer
                let str = "Página " + doc.internal.getNumberOfPages();
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === "function") {
                    str = str + " de " + totalPagesExp;
                }
                doc.setFontStyle("bold");
                doc.setFontSize(6);
                doc.line(
                    data.settings.margin.left,
                    pageHeight - 21,
                    pageWidth - data.settings.margin.left,
                    pageHeight - 21
                );
                doc.text(
                    "QUA_GES_MFC_RPT_001   REGISTO DE PONTO - detalhado",
                    data.settings.margin.left,
                    pageHeight - 17
                );
                doc.addImage(imageLogoBase64Codification, "PNG", pageWidth / 2 - 15, pageHeight - 20, 30, 4);
                /** get current date and time */
                const today = new Date();
                const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
                doc.text(
                    `Produzido: ${date}`,
                    pageWidth - doc.getTextWidth(`Produzido: ${date}`) - data.settings.margin.left,
                    pageHeight - 17
                );
                doc.line(
                    data.settings.margin.left,
                    pageHeight - 15,
                    pageWidth - data.settings.margin.left,
                    pageHeight - 15
                );

                doc.text(str, pageWidth - 30, pageHeight - 10);
            },
            margin: { top: 50, bottom: 30 },
        });
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
    }

    // return doc;

    //   doc.addImage(imageLogoBase64Codification, "PNG", 15, 15);
    //   doc.setFontSize(11);
    //   doc.text("Quality Alive", 15, 32);
    //   doc.text("QUA_GES_MFC_RPT_001   REGISTO DE PONTO- detalhado ", 15, 38, );

    // const base64string = doc.output("datauri");
    // const win = window.open();
    // win.location = 'registo_ponto'
    // win.document.write(
    //     '<iframe src="' +
    //     base64string +
    //     '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    // );
    // doc.autoTable(columns, rows);
    doc.save("Registo de ponto detalhado.pdf");
};
