import actionType from "../constants/index";

const initialState = {
    menus: {
        list: [],
        query: {},
        isFetched: true,
    },
};

export const MenusReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_MENUS:
            return {
                ...state,
                menus: {
                    list: Array.isArray(action.payload["linha"])
                        ? action.payload["linha"]
                        : action.payload["linha"]
                        ? [action.payload["linha"]]
                        : [],
                    query: action.payload["@attributes"],
                    isFetched: false,
                },
            };
        default:
            return state;
    }
};
