import { Form, Input } from "antd";
import React, { useMemo } from "react";
import moment from "moment";
import { componentType, graphicElement } from "../../constants/index";
import QaDataDisplay from "../qa_components/dataDisplay";
import { canCorrectField } from "../../utils/fields";
import { FormUtilitaries } from "../../../../business";

const dateStyles = {
    width: "100%",
};

const QaDate = ({
    onChange,
    type,
    currentTaskStatus,
    itemData,
    label,
    formItemStyle,
    readonly,
    renderContext,
    correctForm,
    state,
    error,
}) => {
    const value = useMemo(() => {
        if (itemData.value === "{now()}") {
            return moment(new Date(), "YYYY-MM-DD");
        } else if (!itemData.value) {
            return null;
        } else {
            const date = moment(itemData.value, "YYYY-MM-DD");
            return date.isValid() ? date : null;
        }
    }, [itemData.value]);

    // useEffect(() => {
    //     if (state.form[itemData.key]?.value === "{now()}") {
    //         const td = moment(new Date(), "YYYY-MM-DD");
    //         onChange(td.format("YYYY-MM-DD"));
    //     }
    // }, []);
    if (itemData["@elemento_grafico"] === graphicElement.title) {
        const newItemData = { ...itemData, value: value ? value.format("DD-MM-YYYY") : value };
        return <QaDataDisplay label={label} itemData={newItemData} renderContext={renderContext} />;
    }

    const styles = renderContext === "column" ? { width: "115px" } : dateStyles;

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus;

    return (
        <Form.Item style={formItemStyle} label={label}>
            {type === componentType.date && (
                // <Input
                //     type="date"
                //     disabled={disabled}
                //     style={styles}
                //     value={value ? moment(value, "YYYY-MM-DD").format("YYYY-MM-DD") : null}
                //     onChange={(e) => onChange(moment(e.target.value).format("YYYY-MM-DD"))}
                // />

                <Input
                    type="date"
                    value={value ? moment(value, "YYYY-MM-DD").format("YYYY-MM-DD") : null}
                    disabled={disabled}
                    readOnly={readonly}
                    // disabled={currentTaskStatus || canCorrectField(itemData)}
                    style={FormUtilitaries.highlightsErrors(value, itemData.required)}
                    name={itemData["name"]}
                    onChange={(date) =>
                        onChange(date?.target?.value ? moment(date.target.value).format("YYYY-MM-DD") : "")
                    }
                    placeholder={itemData["@placeholder"] || "Data"}
                    // format={["DD/MM/YYYY"]}
                />
            )}
            {error && <span style={{ color: "red" }}>{error}</span>}
        </Form.Item>
    );
};

export default QaDate;
