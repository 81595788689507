import React, { useEffect, useState } from "react";

import { PageHeader, Row, Col, Pagination, message } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ProcessTable from "./index.table";
import { getFieldDataSource, getStablishments, getUserProcesses, initProcess } from "../actions/index";
import InitializationProcessFilter from "./index.filter";
import PopupSocialProcess from "./popup.social.process";
import { toArray } from "../../../components/util";
import { User_Preferences, Utilitaries } from "../../../business/index";

const StartProcess = ({ getStablishments, getFieldDataSource, initProcess, history }) => {
    const [stablishments, setStablishments] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        currentPageSize: 10,
    });
    const [selectedStablishment, setSelectedStablishment] = useState(null);
    const [macroProcesses, setMacroProcesses] = useState([]);
    const [selectedMacroProcess, setSelectedMacroProcess] = useState(null);
    const [mesoProcesses, setMesoProcesses] = useState([]);
    const [selectedMesoProcess, setSelectedMesoProcess] = useState(null);
    const [filter, setfilter] = useState({
        // filtro_codigo_externo: "SCL.R005.12",
        ...User_Preferences.getTemporaryInitProocessFilters(),
    });
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isMesoProcessLoading, setIsMesoProcessLoading] = useState(false);
    const [isStablishmentsLoading, setIsStablishmentsLoading] = useState(true);
    const [isMacroProcessesLoading, setIsMacroProcessesLoading] = useState(true);
    const [metadata, setMetadata] = useState({});
    const [processList, setProcessList] = useState([]);
    const [startProcessesButton, setStartProcessesButton] = useState([]);

    const [isSocialProcessSelected, setIsSocialProcessSelected] = useState(null);
    let inInitProcess = false;
    useEffect(() => {
        getStablishments().then((data) => {
            const list = toArray(data?.estabelecimentos?.linha);
            setStablishments(list);
            const defaultEstablishment = Utilitaries.isEmpty(data.estabelecimento_defeito)
                ? null
                : data.estabelecimento_defeito;

            if (User_Preferences.getStartProcessEstablishedPref()) {
                setSelectedStablishment(User_Preferences.getStartProcessEstablishedPref());
            } else if (defaultEstablishment) {
                setSelectedStablishment(defaultEstablishment);
            }
            setIsStablishmentsLoading(false);
        });
        getFieldDataSource({ dataSource: "chama_macro_sistema" }).then(({ result }) => {
            setMacroProcesses(result);
            setIsMacroProcessesLoading(false);
        });
    }, [getStablishments, getFieldDataSource]);

    useEffect(() => {
        if (selectedMacroProcess) {
            setIsMesoProcessLoading(true);
            getFieldDataSource({
                dataSource: "param_categorias_processo",
                params: { param1: selectedMacroProcess },
            }).then(({ result }) => {
                setMesoProcesses(result);
                setSelectedMesoProcess(null);
                setIsMesoProcessLoading(false);
            });
        }
    }, [getFieldDataSource, selectedMacroProcess]);

    // Get form from API
    useEffect(() => {
        let { sorter, ...objFilter } = filter;

        // if (!Utilitaries.isEmpty(filter)) {
        // save last Filter
        User_Preferences.saveTemporaryInitProocessFilters(filter);
        // }

        const values = {
            ...objFilter,
            pagina: pagination.currentPage,
            resultados_pagina: pagination.currentPageSize,
        };
        if (selectedMacroProcess) values.MACRO = selectedMacroProcess;

        if (selectedMesoProcess) values.CATEGORIA = selectedMesoProcess;
        if (sorter) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        setIsTableLoading(true);

        getUserProcesses(values).then((response) => {
            if (response) {
                const buttonsProcess = response.lista.map(() => ({
                    loading: false,
                    disabled: false,
                }));
                setStartProcessesButton(buttonsProcess);
                setProcessList(response.lista);
                setMetadata(response.metadata);
            } else {
                message.error("Ocorreu um erro");
            }
            setIsTableLoading(false);
        });
    }, [filter, pagination, selectedMacroProcess, selectedMesoProcess]);

    const handleStablishmentChange = async (value) => {
        setSelectedStablishment(value);
        User_Preferences.addStartProcessEstablishedPref(value);
    };

    const handleMacroProcessChange = (value) => {
        setSelectedMacroProcess(value);
    };

    const handleMesoProcessChange = (value) => {
        setSelectedMesoProcess(value);
    };

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val[0]) obj["filtro_" + key] = val[0];
            });
        }

        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter(obj);
    };

    const handlePageChange = (currentPage, currentPageSize) => {
        setPagination({
            currentPage,
            currentPageSize,
        });
    };
    const handleShowSizeChange = (currentPage, currentPageSize) => {
        setPagination({
            currentPage: 1,
            currentPageSize,
        });
    };

    const goTo = ({ pathname, queryParams }) => {
        let search = "";

        if (queryParams !== undefined) {
            search = Object.keys(queryParams).reduce((acumulator, current) => {
                acumulator +=
                    acumulator.length === 0
                        ? `?${current}=${queryParams[current]}`
                        : `&${current}=${queryParams[current]}`;
                return acumulator;
            }, "");
        }
        history.push({
            pathname: pathname,
            search,
        });
    };

    const handleInitProcess = async (process, params = {}) => {
        if (inInitProcess) return;
        inInitProcess = true;

        if (Utilitaries.isEmpty(selectedStablishment)) {
            message.warning("Selecione um estabelecimento");
            return;
        }

        if (process.forma_iniciar === "pop_social_mask") {
            // Open pop up
            setIsSocialProcessSelected(process);
            return;
        }

        if (process.index || process.index == 0) {
            setStartProcessesButton((prevState) => {
                return prevState.map((_item, index) => {
                    const currentProcess = process.index === index;
                    return {
                        loading: currentProcess,
                        disabled: !currentProcess,
                    };
                });
            });
        }

        let taskFormParams = "";

        if (Object.keys(params).length > 0) {
            taskFormParams = JSON.stringify(params);
        }

        const { processId, taskId } = await initProcess({
            process,
            stablishment: selectedStablishment,
            taskFormParams,
        });
        if (processId && taskId) {
            // fechar modal processo social
            setIsSocialProcessSelected(null);

            goTo({
                pathname: "/task/execution/map/" + processId,
                queryParams: {
                    taskId,
                    routeContext: "processInit",
                    ...params,
                },
            });
        } else {
            message.error("Ocorreu um erro");
            inInitProcess = false;
            setStartProcessesButton((prevSatte) =>
                prevSatte.map(() => ({
                    loading: false,
                    disabled: false,
                }))
            );
        }
    };

    return (
        <>
            <PageHeader title={"Iniciar processo"} />
            <InitializationProcessFilter
                mesoProcesses={mesoProcesses}
                isMesoProcessLoading={isMesoProcessLoading}
                macroProcesses={macroProcesses}
                isMacroProcessesLoading={isMacroProcessesLoading}
                stablishments={stablishments}
                isStablishmentsLoading={isStablishmentsLoading}
                selectedMacroProcess={selectedMacroProcess}
                selectedMesoProcess={selectedMesoProcess}
                selectedStablishment={selectedStablishment}
                handleStablishmentChange={handleStablishmentChange}
                handleMacroProcessChange={handleMacroProcessChange}
                handleMesoProcessChange={handleMesoProcessChange}
            />
            <ProcessTable
                handleInitProcess={handleInitProcess}
                data={processList}
                handleTableChange={handleTableChange}
                loading={isTableLoading}
                startProcessesButton={startProcessesButton}
            />
            <br />
            <Row>
                <Col xs={24}>
                    <Pagination
                        style={{ float: "right", marginTop: 16 }}
                        current={pagination.currentPage}
                        defaultPageSize={pagination.currentPageSize}
                        size="small"
                        total={metadata.resultados}
                        showSizeChanger
                        showTotal={(total) => `Total registos: ${total}`}
                        onChange={handlePageChange}
                        onShowSizeChange={handleShowSizeChange}
                        showTitle
                        showLessItems={true}
                        defaultCurrent={1}
                    />
                </Col>
            </Row>
            {isSocialProcessSelected && (
                <PopupSocialProcess
                    process={isSocialProcessSelected}
                    setVisible={setIsSocialProcessSelected}
                    getFieldDataSource={getFieldDataSource}
                    handleInitProcess={handleInitProcess}
                    selectedStablishment={selectedStablishment}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getFieldDataSource,
            initProcess,
            getStablishments,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(StartProcess);
