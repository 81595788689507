import React, { useEffect, useState } from "react";
import { Tabs, PageHeader, Form, Row, Col, Button, Popconfirm, message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import DocumentTabPane from "./document.tab";
import RegistrationControlTabPane from "./registrationcontrol.tab";
import TasksAssignmentTabPane from "./taskassignment.tab";
import PermissionsTabPane from "./permissions.tab";
import ProcessTabPane from "./process.tab";
import { getDocumentInitialData, FORM_INITIAL_INFO, saveDocument, cancelDocument } from "../../actions";

const { TabPane } = Tabs;

const NewDocument = ({ form }) => {
    const { id } = useParams();
    const history = useHistory();

    const [initialFormData, setInitialFormData] = useState(FORM_INITIAL_INFO);
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState({
        file: null,
        authorizations: [],
        process: [],
    });
    const [isEdit, setIsEdit] = useState(false);
    const [toReview, setToReview] = useState(false);

    useEffect(() => {
        async function getData() {
            setLoading(true);
            const data = await getDocumentInitialData(id);
            setInitialFormData(data);
            setFormState({
                ...formState,
                authorizations: data.documentsAuthorizations,
                process: data.process,
            });
            setLoading(false);

            if (data.document.id_controlo_documento) {
                setIsEdit(true);
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const saveData = () => {
        form.validateFields((err, values) => {
            if (!err) {
                values = {
                    ...values,
                    autorizacoes: formState.authorizations,
                    processos: formState.process,
                    ficheiro: formState.file,
                    document: values.nome || initialFormData.document.nome || "",
                };
                if (toReview) {
                    values.rever = initialFormData.document.id_controlo_documento;
                }
                if (id) {
                    values.id_controlo_documento = id;
                }
                if (values.data_aprovacao) {
                    values.data_aprovacao = moment(values.data_aprovacao).format("YYYY-MM-DD");
                }
                if (values.data_limite_controlo) {
                    values.data_limite_controlo = moment(values.data_limite_controlo).format("YYYY-MM-DD");
                }
                if (values.data_anulacao) {
                    values.data_anulacao = moment(values.data_anulacao).format("YYYY-MM-DD");
                }
                if (values.estado_versao === "1") {
                    values.estado_versao = "6";
                }

                saveDocument(values).then(() => {
                    history.push({
                        pathname: "/document/list",
                    });
                });
            } else {
                Object.values(err).forEach((er) => {
                    message.warn(`${er.errors[0].field}: ${er.errors[0].message}`);
                });
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        saveData();
    };

    const anular = () => {
        cancelDocument(initialFormData.document.id_controlo_documento).then(() => {
            message.success("Ação concluída com sucesso");
            history.push({
                pathname: "/document/list",
            });
        });
    };

    const { getFieldDecorator } = form;

    return (
        <div>
            <PageHeader
                onBack={() => {
                    history.push({
                        pathname: "/document/list",
                    });
                }}
                title="Inserir documentos externos"
            />
            <Form layout="vertical" onSubmit={handleSubmit}>
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        {toReview && (
                            <>
                                <Button type="primary" onClick={() => saveData()}>
                                    Guardar Revisão
                                </Button>
                                <Button type="danger" onClick={() => setToReview(false)} style={{ marginLeft: 8 }}>
                                    Fechar Revisão
                                </Button>
                            </>
                        )}
                        {isEdit && !toReview && (
                            <>
                                <Button onClick={() => setToReview(true)}>Rever</Button>
                                <Popconfirm
                                    title="Tem certeza que deseja anular?"
                                    onConfirm={anular}
                                    okText="Sim"
                                    cancelText="Não"
                                >
                                    <Button type="danger" style={{ marginLeft: 8 }}>
                                        Anular
                                    </Button>
                                </Popconfirm>
                            </>
                        )}
                        {!toReview && (
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
                                Guardar
                            </Button>
                        )}
                    </Col>
                </Row>

                <Tabs defaultActiveKey="1">
                    <TabPane tab="Documento" key="1">
                        <DocumentTabPane
                            formState={formState}
                            setFormState={setFormState}
                            initialFormData={initialFormData}
                            getFieldDecorator={getFieldDecorator}
                            enableField={!!id}
                            toReview={toReview}
                        />
                    </TabPane>
                    <TabPane tab="Permissões" key="2">
                        <PermissionsTabPane
                            formState={formState}
                            setFormState={setFormState}
                            initialFormData={initialFormData}
                        />
                    </TabPane>
                    <TabPane tab="Controlo Registos" key="3">
                        <RegistrationControlTabPane
                            getFieldDecorator={getFieldDecorator}
                            initialFormData={initialFormData}
                        />
                    </TabPane>
                    <TabPane tab="Processos" key="4">
                        <ProcessTabPane
                            formState={formState}
                            setFormState={setFormState}
                            initialFormData={initialFormData}
                        />
                    </TabPane>
                    <TabPane tab="Atribuição Tarefas" key="5">
                        <TasksAssignmentTabPane
                            getFieldDecorator={getFieldDecorator}
                            initialFormData={initialFormData}
                            id={id}
                            enableField={!!id}
                            toReview={toReview}
                        />
                    </TabPane>
                </Tabs>
            </Form>
        </div>
    );
};

const WrappedPersonalInfoTabPane = Form.create({ name: "document" })(NewDocument);

export default WrappedPersonalInfoTabPane;
