import { sendHttpRequest } from "../components/http.request";
import PropTypes from "prop-types";

export const query = ({ requestBody, url, labels, name, actionType }) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return await sendHttpRequest("POST", "/Gestor/" + url, formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            if (actionType) {
                dispatch({
                    type: actionType,
                    payload: jsonResponse,
                    labels,
                    name,
                });
                return true;
            } else {
                return jsonResponse;
            }
        }
    });
};

query.propType = {
    requestBody: PropTypes.object,
    url: PropTypes.string,
    actionType: PropTypes.string,
};
