import React, { useEffect } from "react";
import { Row, Col, Select, Popconfirm, Input, Checkbox } from "antd";

import EditableFormTable from "./tableEditor";
import { sendHttpRequest } from "../../http.request";
import { toArray } from "../../util";

const { Option } = Select;
const TAB_NAME = "clients";

export default function ClientTab({
    stablishments,
    UO,
    dataSource,
    putDataSource,
    handleFieldsChange,
    addLine,
    removeLine,
}) {
    useEffect(() => {
        const getClientesForColumns = async () => {
            try {
                if (dataSource.length === 0) {
                    return;
                }

                for (const client of dataSource) {
                    if (!client?.name?.dataSource) {
                        const formData = new FormData();
                        formData.append("query", "obter_terceiros_por_tipo");

                        formData.append("estabelecimento", client.stablishment || "");
                        formData.append("id_valencia", client.uo || "");
                        formData.append("id_tipo_terceiro", client.type || "");
                        formData.append("id_cliente_fam", "");

                        const response = await sendHttpRequest("POST", "/Gestor/execQuery.php", formData);

                        const _clients = toArray(response?.result).map((it) => ({
                            id: it.id_entidade,
                            nome: it.nome,
                            email: it.email,
                            telemovel: it.telemovel,
                        }));

                        putDataSource(TAB_NAME, client.key, "name", _clients);
                    }
                }
            } catch (error) {}
        };

        getClientesForColumns();
    }, []);

    // const handleChange = (targetKeys) => {
    //     setSelectedClients(targetKeys);
    // };

    const getClientes = async ({ clientStablishment = "", clientUO = "", clientType = "" }) => {
        try {
            if (!clientType) {
                return [];
            }
            const formData = new FormData();
            formData.append("query", "obter_terceiros_por_tipo");

            formData.append("estabelecimento", clientStablishment || "");
            formData.append("id_valencia", clientUO || "");
            formData.append("id_tipo_terceiro", clientType || "");
            formData.append("id_cliente_fam", "");

            const response = await sendHttpRequest("POST", "/Gestor/execQuery.php", formData);

            const _clients = toArray(response?.result).map((it) => ({
                id: it.id_entidade,
                nome: it.nome,
                email: it.email,
                telemovel: it.telemovel,
            }));

            return _clients;

            // setClients(_clients);
        } catch (error) {
            return [];
        }
    };

    const addNewLine = async () => {
        // const data = await getClientes();

        addLine(TAB_NAME);
    };

    const columns = [
        {
            title: "Tipo",
            dataIndex: "type",
            width: "20%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Select
                        mode="default"
                        style={{ width: "100%" }}
                        placeholder="Filtrar por tipo"
                        showSearch
                        defaultValue={text}
                        dropdownMatchSelectWidth
                        onChange={async (value) => {
                            handleFieldsChange(TAB_NAME, "type", value, record);
                            const data = await getClientes({
                                clientType: value,
                                clientStablishment: record.stablishment || "",
                                clientUO: record.uo || "",
                            });
                            putDataSource(TAB_NAME, record.key, "name", data);
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {[
                            { tipo_terceiro: "C", designacao: "Cliente" },
                            { tipo_terceiro: "FAM", designacao: "Familiares" },
                        ].map((e) => (
                            <Option value={e.tipo_terceiro}>{e.designacao}</Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Estabelecimento",
            dataIndex: "stablishment",
            width: "20%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Select
                        mode="default"
                        style={{ width: "100%" }}
                        placeholder="Filtrar por estabelecimento"
                        allowClear
                        defaultValue={text}
                        dropdownMatchSelectWidth
                        showSearch
                        onChange={async (value) => {
                            handleFieldsChange(TAB_NAME, "stablishment", value, record);
                            const data = await getClientes({
                                clientType: record.type || "",
                                clientStablishment: value || "",
                                clientUO: record.uo || "",
                            });
                            putDataSource(TAB_NAME, record.key, "name", data);
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {stablishments.map((e) => (
                            <Option value={e.id_estabelecimento}>{e.nome}</Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "UO",
            dataIndex: "uo",
            width: "20%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Select
                        mode="default"
                        style={{ width: "100%" }}
                        placeholder="Filtrar por UO"
                        allowClear
                        defaultValue={text}
                        showSearch
                        dropdownMatchSelectWidth
                        onChange={async (value) => {
                            handleFieldsChange(TAB_NAME, "uo", value, record);
                            const data = await getClientes({
                                clientType: record.type || "",
                                clientStablishment: record.stablishment || "",
                                clientUO: value || "",
                            });
                            putDataSource(TAB_NAME, record.key, "name", data);
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {UO.map((e) => (
                            <Option value={e.id_tipo_valencia}>{e.designacao}</Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Entidade",
            dataIndex: "name",
            width: "30%",
            render: (text, record) => {
                const dataSource = typeof text === "object" ? text?.dataSource || [] : [];
                const dValue = typeof text === "object" ? text?.value : text || "";
                return (
                    <Select
                        defaultValue={dValue}
                        onChange={(value) => {
                            const otherProps = {};
                            if (record?.send_email || record?.send_sms) {
                                const list = toArray(record?.name?.dataSource).find((it) => it.id === value);

                                if (list) {
                                    otherProps.email = record?.send_email ? list?.email || "" : "";
                                    otherProps.number = record?.send_sms ? list?.telemovel || "" : "";
                                }
                            }
                            handleFieldsChange(TAB_NAME, "name", value, record, otherProps);
                        }}
                        dropdownMatchSelectWidth
                        mode="default"
                        style={{ width: "100%" }}
                        placeholder="Selecionar fornecedor"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {toArray(dataSource).map((e) => (
                            <Option key={e.id} value={e.id}>
                                {e.nome}
                            </Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Enviar email",
            dataIndex: "send_email",
            width: "10%",
            render: (text, record) => {
                return (
                    <Checkbox
                        defaultChecked={text}
                        onChange={(e) => {
                            if (e.target.checked) {
                                const list = toArray(record?.name?.dataSource).find(
                                    (it) => it.id === record?.name?.value
                                );

                                if (list) {
                                    record.email = list?.email || "";
                                }
                            } else {
                                record.email = "";
                            }

                            handleFieldsChange(TAB_NAME, "send_email", e.target.checked, record);
                        }}
                    >
                        Email
                    </Checkbox>
                );
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "30%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : null;
                return record.send_email ? (
                    <Input
                        placeholder="Introduza o email"
                        onChange={(e) => handleFieldsChange(TAB_NAME, "email", e.target.value, record)}
                        value={text}
                    />
                ) : null;
            },
        },
        {
            title: "Enviar SMS",
            dataIndex: "send_sms",
            width: "10%",
            render: (text, record) => {
                return (
                    <Checkbox
                        defaultChecked={text}
                        onChange={(e) => {
                            if (e.target.checked) {
                                const list = toArray(record?.name?.dataSource).find(
                                    (it) => it.id === record?.name?.value
                                );

                                if (list) {
                                    record.number = list?.telemovel || "";
                                }
                            } else {
                                record.number = "";
                            }
                            handleFieldsChange(TAB_NAME, "send_sms", e.target.checked, record);
                        }}
                    >
                        SMS
                    </Checkbox>
                );
            },
        },
        {
            title: "Telefone",
            dataIndex: "number",
            width: "20%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : null;
                return record.send_sms ? (
                    <Input
                        placeholder="Introduza o número"
                        onChange={(e) => handleFieldsChange(TAB_NAME, "number", e.target.value, record)}
                        value={text}
                        type="number"
                    />
                ) : null;
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) => (
                <Popconfirm
                    title="Tens certeza que desejas eliminar?"
                    onConfirm={() => removeLine(TAB_NAME, record.key)}
                >
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Eliminar
                    </a>
                </Popconfirm>
            ),
        },
    ];

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <EditableFormTable dataSource={dataSource} addLine={addNewLine} columns={columns} />
            </Col>
            {/* <Col span={4}>
                <Select
                    mode="default"
                    style={{ width: "100%" }}
                    placeholder="Filtar por tipo"
                    value={clientType}
                    showSearch
                    onChange={(value, option) => setClientType(value)}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {[
                        { tipo_terceiro: "C", designacao: "Cliente" },
                        { tipo_terceiro: "FAM", designacao: "Familiares" },
                    ].map((e) => (
                        <Option value={e.tipo_terceiro}>{e.designacao}</Option>
                    ))}
                </Select>
            </Col>
            <Col span={8}>
                <Select
                    mode="default"
                    style={{ width: "100%" }}
                    placeholder="Filtar por estabelecimento"
                    allowClear
                    value={clientStablishment}
                    showSearch
                    onChange={(value, option) => setClientStablishment(value)}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {stablishments.map((e) => (
                        <Option value={e.id_estabelecimento}>{e.nome}</Option>
                    ))}
                </Select>
            </Col>
            <Col span={8}>
                <Select
                    mode="default"
                    style={{ width: "100%" }}
                    placeholder="Filtar por UO"
                    allowClear
                    showSearch
                    value={clientUO}
                    onChange={(value, option) => setClientUO(value)}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {UO.map((e) => (
                        <Option value={e.id_tipo_valencia}>{e.designacao}</Option>
                    ))}
                </Select>
            </Col>
            <Col span={24}>
                <Transfer
                    dataSource={clients}
                    showSearch
                    listStyle={{
                        width: 360,
                        height: 400,
                    }}
                    filterOption={(inputValue, option) => option.description.indexOf(inputValue) > -1}
                    targetKeys={selectedClients}
                    onChange={handleChange}
                    // onSearch={handleSearch}
                    render={(item) => item.title}
                />
            </Col> */}
        </Row>
    );
}
