/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { message, Form, Select, Button, Row, Col, Tooltip } from "antd";

import "react-orgchart/index.css";
import G6 from "@antv/g6";
import { selectAllFunctionalGroup, selectFunctionalGroupContributors } from "../../actions/funcional.group";
import "./organograma.less";

const { Option } = Select;

const Organograma = (props) => {
    const ref = React.useRef(null);
    let graph = useRef();
    const [response, setResponse] = useState([]);
    const [gruposFuncionais, setGruposFuncionais] = useState([]);
    const [dataSource, setDataSource] = useState({});
    const [rootNode, setRootNode] = useState([]);
    const [grupoFuncionalSuperior, setGrupoFuncionalSuperior] = useState(null);

    useEffect(() => {
        getAllFunctionalGroup();
        initGraph();
    }, []);

    useEffect(() => {
        const grupoFuncionais = [];
        response.forEach((item) => {
            if (typeof item.codigo !== "object" && typeof item.descricao_funcao !== "object") {
                grupoFuncionais.push({
                    id: item.id_ficha_funcao,
                    name: item.codigo,
                    title: item.descricao_funcao,
                    ...item,
                });
            }
        });
        setGruposFuncionais(grupoFuncionais);
    }, [response]);

    /**
     * criar arrays de elementos pais
     */
    useEffect(() => {
        if (props.tree) {
            const parents = getParent(props.tree);
            setRootNode(parents);
            setDataSource(props.tree);
        } else {
            const familyTree = unflatten(gruposFuncionais);
            const parents = getParent(familyTree);
            setRootNode(parents);
            setDataSource(familyTree);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gruposFuncionais, props.tree]);

    useEffect(() => {
        if (props.tree && Object.keys(props.tree).length > 0 && grupoFuncionalSuperior) {
            const data = getChildFromParent(props.tree);

            setDataSource(data);
        } else if (grupoFuncionalSuperior) {
            const arvore = unflatten(gruposFuncionais);

            const data = getChildFromParent(arvore);

            setDataSource(data);
            generateGraph(data);
        }
    }, [grupoFuncionalSuperior, props.tree]);

    const getAllFunctionalGroup = async () => {
        try {
            const response = await props.selectAllFunctionalGroup({
                accao: "funcoes",
                todos_utilizadores: true,
            });
            const { linha } = response;
            if (Array.isArray(linha) && linha.length > 0) setResponse(linha);
            else message.info("Sem dados");
        } catch (error) {
            message.error("Ocorreu um erro");
        }
    };

    const unflatten = (array, parent, tree) => {
        tree = typeof tree !== "undefined" ? tree : [];
        let children = [];

        if (!parent) {
            children = array.filter((child) => typeof child.funcao_superior === "object");
        } else {
            children = array.filter(
                (child) =>
                    child.funcao_superior === parent.descricao_funcao &&
                    child.funcao_superior !== parent.funcao_superior
            );
        }

        if (children.length > 0) {
            if (!parent) {
                tree = children;
            } else {
                parent["children"] = children;
            }
            children.forEach((child) => {
                unflatten(array, child, tree);
            });
        }
        return tree;
    };

    const getParent = (data, rootNode = []) => {
        let node = rootNode;

        if (data?.["children"] !== null && data?.["children"] !== undefined) {
            if (data.title || data.name) {
                node.push({
                    id: data["id"],
                    title: data["title"] || data["name"],
                });
            }

            getParent(data["children"], node);
        }

        if (Array.isArray(data)) {
            data.map((item) => {
                return getParent(item, node);
            });
        }

        return node;
    };

    const getChildFromParent = (data) => {
        let child = {};

        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                const val = getChildFromParent(data[i]);
                if (val && Object.keys(val).length > 0) {
                    child = val;
                    break;
                }
            }
        }

        if (data.id === grupoFuncionalSuperior.id) {
            child = data;
            return child;
        } else if (data.children) {
            child = getChildFromParent(data.children);
        }
        return child;
    };

    const initGraph = () => {
        const container = document.getElementById("graph-container");

        const defaultStateStyles = {
            hover: {
                stroke: "#000000",
                lineWidth: 2,
            },
        };

        const defaultNodeStyle = {
            fill: "#FFFFFF",
            stroke: "#1DA57B",
            radius: 5,
        };

        const defaultEdgeStyle = {
            stroke: "#000000",
            endArrow: {
                path: "M 0,0 L 12, 6 L 9,0 L 12, -6 Z",
                fill: "#000000",
                d: -20,
            },
        };

        const defaultLabelCfg = {
            style: {
                fill: "#000",
                fontSize: 6,
            },
        };

        const defaultLayout = {
            type: "compactBox",
            direction: "TB",
            getId: function getId(d) {
                return d.id;
            },
            getHeight: function getHeight() {
                return 16;
            },
            getWidth: function getWidth() {
                return 16;
            },
            getVGap: function getVGap() {
                return 40;
            },
            getHGap: function getHGap() {
                return 70;
            },
        };

        G6.registerNode(
            "icon-node",
            {
                options: {
                    size: [60, 20],
                    stroke: "#91d5ff",
                    fill: "#91d5ff",
                },
                draw(cfg, group) {
                    const styles = this.getShapeStyle(cfg);
                    const { labelCfg = {} } = cfg;

                    const w = styles.width;
                    const h = styles.height;

                    const keyShape = group.addShape("rect", {
                        attrs: {
                            ...styles,
                            x: -w / 8,
                            y: -h / 2,
                        },
                    });

                    group.addShape("rect", {
                        attrs: {
                            x: -w / 8,
                            y: -h / 2,
                            width: 120,
                            height: 20,
                            fill: "#1DA57B",
                            radius: [5, 5, 0, 0],
                        },
                        name: "title-box",
                        draggable: true,
                    });

                    if (cfg.name) {
                        group.addShape("text", {
                            attrs: {
                                ...labelCfg.style,
                                text: cfg.name,
                                x: 50 - w / 2,
                                y: 15 - h / 2,
                                fill: "#FFFFFF",
                                fontSize: 8,
                            },
                        });
                    }

                    if (cfg.title) {
                        group.addShape("text", {
                            attrs: {
                                ...labelCfg.style,
                                text: cfg.title,
                                x: 50 - w / 2,
                                y: 32 - h / 2,
                            },
                        });
                    }

                    return keyShape;
                },
                update: undefined,
            },
            "rect"
        );

        G6.registerEdge("flow-line", {
            draw(cfg, group) {
                const startPoint = cfg.startPoint;
                const endPoint = cfg.endPoint;

                const { style } = cfg;
                const shape = group.addShape("path", {
                    attrs: {
                        stroke: style.stroke,
                        endArrow: style.endArrow,
                        path: [
                            ["M", startPoint.x, startPoint.y],
                            ["L", startPoint.x, (startPoint.y + endPoint.y) / 2],
                            ["L", endPoint.x, (startPoint.y + endPoint.y) / 2],
                            ["L", endPoint.x, endPoint.y],
                        ],
                    },
                });

                return shape;
            },
        });

        const toolBarStyle = `position: absolute;
            top: 238px;
            left: 30px;
            list-style-type: none;
            padding: 6px;
            background-color: rgba(255, 255, 255, 0.9);
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            font-size: 12px;
            color: #545454;
            margin: 0;`;

        const toolBar = new G6.ToolBar({
            getContent: () => {
                return `<ul class="g6-flowchart-toolbar" style="${toolBarStyle}">
                            <li code="zoomOut">
                                <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                    <path d="M658.432 428.736a33.216 33.216 0 0 1-33.152 33.152H525.824v99.456a33.216 33.216 0 0 1-66.304 0V461.888H360.064a33.152 33.152 0 0 1 0-66.304H459.52V296.128a33.152 33.152 0 0 1 66.304 0V395.52H625.28c18.24 0 33.152 14.848 33.152 33.152z m299.776 521.792a43.328 43.328 0 0 1-60.864-6.912l-189.248-220.992a362.368 362.368 0 0 1-215.36 70.848 364.8 364.8 0 1 1 364.8-364.736 363.072 363.072 0 0 1-86.912 235.968l192.384 224.64a43.392 43.392 0 0 1-4.8 61.184z m-465.536-223.36a298.816 298.816 0 0 0 298.432-298.432 298.816 298.816 0 0 0-298.432-298.432A298.816 298.816 0 0 0 194.24 428.8a298.816 298.816 0 0 0 298.432 298.432z"></path>
                                </svg>
                            </li>
                            <li code="zoomIn">
                                <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                    <path d="M639.936 416a32 32 0 0 1-32 32h-256a32 32 0 0 1 0-64h256a32 32 0 0 1 32 32z m289.28 503.552a41.792 41.792 0 0 1-58.752-6.656l-182.656-213.248A349.76 349.76 0 0 1 480 768 352 352 0 1 1 832 416a350.4 350.4 0 0 1-83.84 227.712l185.664 216.768a41.856 41.856 0 0 1-4.608 59.072zM479.936 704c158.784 0 288-129.216 288-288S638.72 128 479.936 128a288.32 288.32 0 0 0-288 288c0 158.784 129.216 288 288 288z" p-id="3853"></path>
                                </svg>
                            </li>
                            <li code="realZoom">
                                <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="24">
                                    <path d="M384 320v384H320V320h64z m256 0v384H576V320h64zM512 576v64H448V576h64z m0-192v64H448V384h64z m355.968 576H92.032A28.16 28.16 0 0 1 64 931.968V28.032C64 12.608 76.608 0 95.168 0h610.368L896 192v739.968a28.16 28.16 0 0 1-28.032 28.032zM704 64v128h128l-128-128z m128 192h-190.464V64H128v832h704V256z"></path>
                                </svg>
                            </li>
                            <li code="autoZoom">
                                <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="24">
                                    <path d="M684.288 305.28l0.128-0.64-0.128-0.64V99.712c0-19.84 15.552-35.904 34.496-35.712a35.072 35.072 0 0 1 34.56 35.776v171.008h170.944c19.648 0 35.84 15.488 35.712 34.432a35.072 35.072 0 0 1-35.84 34.496h-204.16l-0.64-0.128a32.768 32.768 0 0 1-20.864-7.552c-1.344-1.024-2.816-1.664-3.968-2.816-0.384-0.32-0.512-0.768-0.832-1.088a33.472 33.472 0 0 1-9.408-22.848zM305.28 64a35.072 35.072 0 0 0-34.56 35.776v171.008H99.776A35.072 35.072 0 0 0 64 305.216c0 18.944 15.872 34.496 35.84 34.496h204.16l0.64-0.128a32.896 32.896 0 0 0 20.864-7.552c1.344-1.024 2.816-1.664 3.904-2.816 0.384-0.32 0.512-0.768 0.768-1.088a33.024 33.024 0 0 0 9.536-22.848l-0.128-0.64 0.128-0.704V99.712A35.008 35.008 0 0 0 305.216 64z m618.944 620.288h-204.16l-0.64 0.128-0.512-0.128c-7.808 0-14.72 3.2-20.48 7.68-1.28 1.024-2.752 1.664-3.84 2.752-0.384 0.32-0.512 0.768-0.832 1.088a33.664 33.664 0 0 0-9.408 22.912l0.128 0.64-0.128 0.704v204.288c0 19.712 15.552 35.904 34.496 35.712a35.072 35.072 0 0 0 34.56-35.776V753.28h170.944c19.648 0 35.84-15.488 35.712-34.432a35.072 35.072 0 0 0-35.84-34.496z m-593.92 11.52c-0.256-0.32-0.384-0.768-0.768-1.088-1.088-1.088-2.56-1.728-3.84-2.688a33.088 33.088 0 0 0-20.48-7.68l-0.512 0.064-0.64-0.128H99.84a35.072 35.072 0 0 0-35.84 34.496 35.072 35.072 0 0 0 35.712 34.432H270.72v171.008c0 19.84 15.552 35.84 34.56 35.776a35.008 35.008 0 0 0 34.432-35.712V720l-0.128-0.64 0.128-0.704a33.344 33.344 0 0 0-9.472-22.848zM512 374.144a137.92 137.92 0 1 0 0.128 275.84A137.92 137.92 0 0 0 512 374.08z"></path>
                                </svg>
                            </li>
                        </ul>`;
            },
        });

        graph.current = new G6.TreeGraph({
            container: container,
            width: 1000,
            height: 500,
            fitCenter: true,
            linkCenter: true,
            plugins: [toolBar],
            modes: {
                default: ["drag-canvas", "zoom-canvas"],
            },
            defaultNode: {
                type: "icon-node",
                size: [120, 40],
                style: defaultNodeStyle,
                labelCfg: defaultLabelCfg,
            },
            defaultEdge: {
                type: "flow-line",
                style: defaultEdgeStyle,
            },
            nodeStateStyles: defaultStateStyles,
            edgeStateStyles: defaultStateStyles,
            layout: defaultLayout,
        });
    };

    const generateGraph = (dataSource) => {
        graph.current.data(dataSource);
        graph.current.render();
        graph.current.fitView();
    };

    const exportGraph = () => {
        graph.current.downloadImage();
    };

    return (
        <>
            <Row>
                <Col xs={24} sm={8}>
                    <Form.Item label="Grupo funcional superior">
                        <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={(_, option) => {
                                if (_) {
                                    setGrupoFuncionalSuperior(option.props.gruposuperior);
                                } else {
                                    setGrupoFuncionalSuperior(null);
                                    setDataSource({});
                                }
                            }}
                        >
                            {rootNode.map((item) => (
                                <Option gruposuperior={item} key={item.id}>
                                    {item.title || item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tooltip title="Guardar Imagem">
                        <Button
                            style={{
                                float: "right",
                                color: "#545454",
                                marginBottom: 8,
                            }}
                            icon="file"
                            onClick={() => exportGraph()}
                        >
                            Guardar Imagem
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
            <div id="graph-container" ref={ref}></div>
        </>
    );
};

const mapDispatchToProps = {
    selectAllFunctionalGroup,
    selectFunctionalGroupContributors,
};

const mapStateToProps = (state) => {
    return {
        funcionalContributors: state.funcionalGroup.domain.form.detentor || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Organograma);
