import React, { Component } from "react";
import { Modal, Button, Row, Table, Select, Popconfirm, message } from "antd";
import { connect } from "react-redux";

import { selectDepartment, insertDepartment, selectDataFromGereFormulário } from "../../actions/contributor";

const { Option } = Select;

class DepartmentModal extends Component {
    state = {
        _isUpdated: false,
        loading: false,
        loadingOnFetch: true,
        department_list: [],
        countDataSource: 1,
        dataSource: [],
    };
    componentDidMount() {
        this.getAllDepartmentFromServer();
    }
    componentDidUpdate() {
        if (!this.state._isUpdated) {
            const { fcb_departamentos } = this.props.record;
            if (fcb_departamentos && typeof fcb_departamentos !== "object") {
                this.getDepartmentById(fcb_departamentos);
            } else {
                if (this.state.loadingOnFetch) {
                    this.setState({ loadingOnFetch: false });
                }
            }
        }
    }
    getDepartmentById = async (id) => {
        // this.setState({ loadingOnFetch: true });
        try {
            const resp = await this.props.selectDataFromGereFormulário({
                accao: "dados",
                formulario: "qa_ficha_colaborador_departamentos.qml.xml",
                id,
            });
            if (resp.valores) {
                const { valores } = resp;
                if (valores.fcb_departamentos) {
                    const { fcb_departamentos } = valores;
                    if (fcb_departamentos.fcb_lista_departamentos) {
                        const { fcb_lista_departamentos } = fcb_departamentos;
                        if (fcb_lista_departamentos.fcb_lista_departamento) {
                            const { fcb_lista_departamento } = fcb_lista_departamentos;
                            if (Array.isArray(fcb_lista_departamento)) {
                                const dataSource = [];
                                fcb_lista_departamento.map((item, index) => {
                                    if (
                                        typeof item.valencia !== "object" &&
                                        typeof item.id_fcb_lista_departamento !== "object"
                                    )
                                        dataSource.push({
                                            key: index,
                                            ...item,
                                        });
                                });
                                this.setState({
                                    dataSource,
                                    countDataSource: fcb_lista_departamento.length,
                                    _isUpdated: true,
                                    loadingOnFetch: false,
                                });
                            } else if (fcb_lista_departamento.id_fcb_lista_departamento) {
                                this.setState({
                                    dataSource: [{ key: 0, ...fcb_lista_departamento }],
                                    countDataSource: 1,
                                    _isUpdated: true,
                                    loadingOnFetch: false,
                                });
                            }
                        }
                    }
                }
            }
        } catch (error) {
            message.error("Erro ao carregar unidade orgânica!!");
            this.setState({ loadingOnFetch: false });
        }
    };
    getAllDepartmentFromServer = () => {
        try {
            this.props
                .selectDepartment({
                    query: "departamentos_atuais_manual_funcoes",
                })
                .then((response) => {
                    if (response.result !== undefined && response.result.length > 0) {
                        this.setState({ department_list: response.result });
                    } else if (response.result.length === 0) {
                        message.error("Lista de vazia!!");
                    }
                });
        } catch (error) {
            message.error("Erro ao carregar unidades orgânicas!!");
        }
    };
    column = [
        {
            title: "",
            dataIndex: "valencia",
            width: "80%",
            render: (text, record) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona a resposta social"
                    optionFilterProp="children"
                    defaultValue={text}
                    onChange={(value) => {
                        this.dataSourceDifference(value);
                        this.handleChange("valencia", value, record);
                    }}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {this.state.department_list.map((item) => {
                        return (
                            <Option key={item.id_departamento} value={item.id_departamento}>
                                {item.designacao}
                            </Option>
                        );
                    })}
                </Select>
            ),
        },
        {
            title: "",
            dataIndex: "actions",
            width: "20%",
            render: (text, record) =>
                this.state.dataSource.length >= 1 ? (
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        onConfirm={() => this.handleDelete(record.key)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    dataSourceDifference = (value) => {
        const { department_list } = this.state;
        const newDataSource = [];
        department_list.forEach((item) => {
            if (item.id_departamento !== value) {
                newDataSource.push(item);
            }
        });

        //
        //
        this.setState({
            department_list: newDataSource,
        });
    };

    handleChange = (dataIndex, value, record) => {
        const recordToAlter = { ...record };
        recordToAlter[dataIndex] = value;
        const { dataSource } = this.state;
        const index = dataSource.findIndex((item) => item.key === recordToAlter.key);
        dataSource[index] = recordToAlter;
    };
    handleOk = async () => {
        const { dataSource } = this.state;
        let empty_row = false;
        dataSource.map((item) => {
            if (!item.valencia) empty_row = true;
        });
        if (!empty_row) {
            const departmentData = {
                fcb_departamentos: {
                    fcb_lista_departamentos: {
                        fcb_lista_departamento: dataSource.map((item) => {
                            delete item.id_fcb_lista_departamento;
                            return item;
                        }),
                    },
                },
            };
            try {
                this.setState({ loading: true });
                const result = await this.props.insertDepartment({
                    accao: "guardar",
                    formulario: "qa_ficha_colaborador_departamentos.qml.xml",
                    dados: JSON.stringify(departmentData),
                });
                if (result.sucesso) {
                    message.success("Sucesso");
                    const { record } = this.props;
                    record["fcb_departamentos"] = result.sucesso;
                    record["totalOfDepartment"] = dataSource.length;
                    this.props.hiddeDepartmentModal(record);
                } else {
                    message.error("Não foi possível inserir dados dos departamentos");
                }
            } catch (error) {
                message.error("Erro ao inserir dados dos departamentos");
            } finally {
                this.setState({ loading: false });
            }
        } else message.warning("Linhas vazios tem de ser preenchidas ou removidas!!");
    };
    handleCancel = () => {
        this.props.hiddeDepartmentModal();
    };
    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };

    handleAdd = () => {
        const len = this.state.countDataSource + 1;
        const newData = {
            key: len,
        };
        this.setState({
            dataSource: [newData, ...this.state.dataSource],
            countDataSource: len,
            _isUpdated: true,
        });
    };

    render() {
        return (
            <div>
                <Modal
                    centere
                    visible={this.props.isVisible}
                    title="Unidade orgânica"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            voltar
                        </Button>,
                        <Button
                            loading={this.state.loading}
                            icon="save"
                            key="submit"
                            type="primary"
                            disabled={this.state.dataSource.length ? false : true}
                            onClick={this.handleOk}
                        >
                            Guardar
                        </Button>,
                    ]}
                >
                    <Row>
                        <Button
                            xs={24}
                            sm={12}
                            onClick={this.handleAdd}
                            icon="plus"
                            size="default"
                            type="primary"
                            style={{ marginBottom: 16, float: "right" }}
                            disabled={this.state.loadingOnFetch || this.state.loading}
                        >
                            Adicionar
                        </Button>
                    </Row>
                    <Table
                        rowKey={(record) => record.key}
                        size="small"
                        scroll={{ x: 400, y: 300 }}
                        columns={this.column}
                        dataSource={this.state.dataSource}
                        loading={this.state.loadingOnFetch}
                    />
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
    selectDataFromGereFormulário,
    selectDepartment,
    insertDepartment,
};

export default connect(null, mapDispatchToProps)(DepartmentModal);
