import React, { useState } from "react";
import { Table, Row, Button, Col, Icon } from "antd";
import PropTypes from "prop-types";

const QADynamicTable = ({
    data,
    columns,
    datakey,
    loadingData,
    readOnly,
    addRowToTable,
    onSelectRows,
    onRemoveItem,
    actions,
    changePosition,
    scroll,
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    //

    const rowSelection = {
        selectedRowKeys,
        onChange: (_selectedRowKeys) => {
            setSelectedRowKeys(_selectedRowKeys);
        },
    };

    const onChangePositionSuccess = (isUp = true) => {
        let _selectedRowKeys = [...selectedRowKeys];
        if (isUp) {
            _selectedRowKeys = _selectedRowKeys.map((it) => it - 1);
        } else {
            _selectedRowKeys = _selectedRowKeys.map((it) => it + 1);
        }

        _selectedRowKeys = _selectedRowKeys.filter((it) => it >= 0 && it <= data.length - 1);

        setSelectedRowKeys(_selectedRowKeys);
    };

    const isToDisplayRowSelection = () => {
        if (actions === false) return false;

        return true;
    };

    const renderButton = () => {
        if (actions && typeof actions === "object") {
            return (
                <Col span={24}>
                    {!!changePosition && selectedRowKeys.length > 0 && (
                        <div>
                            <Icon
                                type="up"
                                onClick={() =>
                                    changePosition({
                                        up: true,
                                        selectedRowKeys,
                                        onSuccess: onChangePositionSuccess,
                                    })
                                }
                                style={{ padding: 5 }}
                            />
                            <Icon
                                type="down"
                                onClick={() =>
                                    changePosition({
                                        up: false,
                                        selectedRowKeys,
                                        onSuccess: onChangePositionSuccess,
                                    })
                                }
                                style={{ padding: 5, marginLeft: 8 }}
                            />
                        </div>
                    )}
                    {selectedRowKeys.length > 0 ? `${selectedRowKeys.length} item(s) selecionado(s)` : ""}
                    {actions.add && (
                        <Button
                            disabled={readOnly || false}
                            onClick={() => {
                                // ;
                                addRowToTable();
                            }}
                            style={{ marginBottom: 8, float: "right" }}
                            type="primary"
                            size="small"
                            icon="plus"
                        >
                            Adicionar
                        </Button>
                    )}
                    {actions.remove && (
                        <Button
                            disabled={readOnly || false}
                            onClick={() => {
                                onRemoveItem(selectedRowKeys);
                                setSelectedRowKeys([]);
                            }}
                            style={{
                                marginBottom: 8,
                                marginRight: 8,
                                float: "right",
                            }}
                            type="danger"
                            size="small"
                            icon="minus"
                        >
                            Eliminar
                        </Button>
                    )}
                </Col>
            );
        }

        if (actions === undefined || actions) {
            return (
                <Col span={24}>
                    <div>
                        {!!changePosition && selectedRowKeys.length > 0 && (
                            <>
                                <Button
                                    size="small"
                                    icon="up"
                                    onClick={() =>
                                        changePosition({
                                            up: true,
                                            selectedRowKeys,
                                            onSuccess: onChangePositionSuccess,
                                        })
                                    }
                                />
                                <Button
                                    size="small"
                                    icon="down"
                                    onClick={() =>
                                        changePosition({
                                            up: false,
                                            selectedRowKeys,
                                            onSuccess: onChangePositionSuccess,
                                        })
                                    }
                                    style={{ marginLeft: 8 }}
                                />{" "}
                            </>
                        )}
                        {selectedRowKeys.length > 0 ? `${selectedRowKeys.length} item(s) selecionado(s)` : ""}
                    </div>
                    <Button
                        onClick={() => addRowToTable()}
                        disabled={readOnly || false}
                        style={{ marginBottom: 8, float: "right" }}
                        type="primary"
                        size="small"
                        icon="plus"
                    >
                        Adicionar
                    </Button>
                    <Button
                        onClick={() => {
                            onRemoveItem(selectedRowKeys);
                            setSelectedRowKeys([]);
                        }}
                        disabled={readOnly || false}
                        style={{
                            marginBottom: 8,
                            marginRight: 8,
                            float: "right",
                        }}
                        type="danger"
                        size="small"
                        icon="minus"
                    >
                        Eliminar
                    </Button>
                </Col>
            );
        }

        return null;
    };

    return (
        <Row>
            {renderButton()}
            <Col span={24}>
                <Table
                    // rowKey={record => record[datakey]}
                    rowKey={"__key__"}
                    size="small"
                    rowSelection={isToDisplayRowSelection() ? rowSelection : null}
                    dataSource={data.map((it, idx) => ({
                        __key__: /*new Date().getTime() + "-" +*/ idx,
                        ...it,
                    }))}
                    scroll={scroll}
                    loading={loadingData}
                    columns={columns}
                    style={{ marginTop: 20 }}
                    pagination={{
                        pageSize: 5,
                        showTotal: (total) => `Total registos: ${total}`,
                    }}
                />
            </Col>
        </Row>
    );
};

QADynamicTable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    datakey: PropTypes.string,
    loadingData: PropTypes.bool,
    addRowToTable: PropTypes.func,
    onSelectRows: PropTypes.func,
    onRemoveItem: PropTypes.func,
};

export default QADynamicTable;
