import React, { useState, useEffect } from "react";
import { message, Modal, List } from "antd";
import { getHistoryOperation } from "../../actions/execution";
import { historyTask } from "../../constants/execution";

const ModalHistory = ({ taskId, historyOperation, visible, handleClose }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = useState("");

    useEffect(() => {
        getHistoryOperation({
            taskId,
            historyOperation,
        }).then((result) => {
            if (!result || result.erro) {
                message.error(result.erro || "Ocorreu um erro.");
            } else {
                setHistory(result);
            }
            setIsLoading(false);
        });
    }, []);

    // const label = historyOperation === historyTask.cancelTask ? "anulações" : "reatribuições";

    return (
        <Modal
            title={
                historyOperation === historyTask.cancelTask
                    ? `Histórico de anulações da tarefa`
                    : `Histórico de reatribuições da tarefa`
            }
            visible={visible}
            onOk={handleClose}
            onCancel={handleClose}
            footer={null}
            width="50%"
        >
            <List
                size="small"
                header={<b>{historyOperation === historyTask.cancelTask ? `Anulações` : "Reatribuições"}</b>}
                footer={null}
                loading={isLoading}
                bordered
                dataSource={history}
                pagination={{
                    pageSize: 5,
                    size: "small",
                }}
                renderItem={(item) => (
                    <List.Item>
                        <pre style={{ whiteSpace: "pre-wrap", marginBottom: "0.3em" }}>{item}</pre>
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default ModalHistory;
