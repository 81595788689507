import React, { Component } from "react";
import { Table, Button, Input, Icon, Divider } from "antd";
import CorrectionReason from "./correction.reason";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import * as actionCreators from "./../action/index";
import RevertVersion from "./revert_version/revertVersion";

class ListCorrection extends Component {
    componentDidMount() {
        if (!this.props.isFetchedHistoric) this.selectData();
    }

    state = {
        isVisibleReason: false,
    };

    selectData = () => {
        this.setState({
            isFetching: true,
        });
        this.props
            .listCorrection({
                id: this.props.id,
            })
            .then(() => {
                this.setState({
                    isFetching: false,
                });
            });
    };

    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={typeof text === "object" ? "" : text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    /****************************** */

    columns = [
        {
            title: "Data / Hora",
            dataIndex: "data_correcao",
            width: "30%",
            ...this.getColumnSearchProps("data_correcao"),
        },
        {
            title: "Utilizador",
            dataIndex: "user_correcao",
            ...this.getColumnSearchProps("user_correcao"),
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "30%",
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                        onClick={this.showModalReason.bind(this, record)}
                    >
                        Motivo
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                        onClick={() => this.showModalCheckVersion(record)}
                    >
                        Abrir formulário
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                        onClick={() => this.showModalRevertVersion(record)}
                    >
                        Reverter para versão
                    </Button>
                </>
            ),
        },
    ];
    //------------- modal reason ------------------------
    showModalReason = (row) => {
        this.setState({
            isVisibleReason: true,
            selectRow: row,
        });
    };

    handleModalReasonCancel = () => {
        this.setState({
            isVisibleReason: false,
            selectRow: null,
        });
    };

    //------------- modal revert to version ------------------------
    showModalRevertVersion = (row) => {
        this.setState({
            isVisibleRevertVersion: true,
            isDisabledRevertForm: false,
            selectRow: row,
        });
    };

    showModalCheckVersion = (row) => {
        this.setState({
            isVisibleRevertVersion: true,
            isDisabledRevertForm: true,
            selectRow: row,
        });
    };

    handleCheckVersionOk = () => {
        this.setState({
            isVisibleRevertVersion: false,
            selectRow: null,
        });
        this.selectData();
    };

    handleModalRevertVersionCancel = () => {
        this.setState({
            isVisibleRevertVersion: false,
            selectRow: null,
        });
    };

    render() {
        return (
            <>
                {this.state.isVisibleReason && (
                    <CorrectionReason
                        handleCancel={this.handleModalReasonCancel}
                        isVisible={this.state.isVisibleReason}
                        data={this.state.selectRow}
                    />
                )}
                {this.state.isVisibleRevertVersion && (
                    <RevertVersion
                        handleCancel={this.handleModalRevertVersionCancel}
                        isVisible={this.state.isVisibleRevertVersion}
                        handleOk={this.handleCheckVersionOk}
                        row={this.state.selectRow}
                        isFormDisabled={this.state.isDisabledRevertForm}
                        formName={this.props.formName}
                    />
                )}

                <Table
                    rowKey={(record) => record.id}
                    dataSource={this.state.isFetching ? [] : this.props.list}
                    columns={this.columns}
                    size="middle"
                    loading={this.state.isFetching}
                    pagination={true}
                />
            </>
        );
    }
}

const mapDispatchToProps = {
    listCorrection: actionCreators.select,
};

const mapStateToProps = (state) => {
    return {
        list: Object.values(state.correction.domain.byId),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCorrection);
