import { Button, Form, Input, Popconfirm, Radio, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { DataChangeProps, FormDataProps, ReasonsAbsenceProps } from "../utils/types";
import moment from "moment";
import { api_fetch } from "../../../../utils/HttpRequest/request";

interface IProps extends DataChangeProps {
    formData: FormDataProps;
}

const table_key1 = "linha";
const table_key2 = "ausencia";

export function ProgrammedAbsences({ formData, handleAdd, handleDelete, onChange }: IProps) {
    const [listReasonsAbsence, setListReasonsAbsence] = useState<ReasonsAbsenceProps[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await api_fetch({
                endPoint: "/Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: "lista_motivos_ausencias",
                },
            });

            if (response.status === "SUCCESS") {
                setListReasonsAbsence(response.response.result);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: any[] = [
        {
            title: "Data início",
            dataIndex: "data_inicio",
            width: "15%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "data_inicio",
                                    value: e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : undefined}
                            type="date"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data fim",
            dataIndex: "data_fim",
            width: "15%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "data_fim",
                                    value: e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : undefined}
                            type="date"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Hora início",
            dataIndex: "inicio",
            width: "15%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm").format("HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "inicio",
                                    value: e.target.value,
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("HH:mm") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Hora fim",
            dataIndex: "fim",
            width: "15%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm").format("HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "fim",
                                    value: e.target.value,
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("HH:mm") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Motivo",
            dataIndex: "motivo_ausencia",
            width: "15%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Select
                        showSearch
                        allowClear
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        placeholder="seleciona uma opcao"
                        optionFilterProp="children"
                        defaultValue={text}
                        onChange={(value: any) =>
                            onChange({
                                dataIndex: "motivo_ausencia",
                                value,
                                row_key: record.key,
                                table_key1,
                                table_key2,
                            })
                        }
                        filterOption={(input, option) =>
                            String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {listReasonsAbsence.map((item) => (
                            <Select.Option key={item.motivo_ausencia} value={item.motivo_ausencia}>
                                {item.designacao}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Observação",
            dataIndex: "observacoes",
            width: "17%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input.TextArea
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "observacoes",
                                    value: e.target.value,
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text}
                            placeholder="Introduza observações"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "8%",
            render: (text: any, record: any) => (
                <Popconfirm title="Remover a linha?" onConfirm={() => handleDelete(record.key, table_key1, table_key2)}>
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Remover
                    </a>
                </Popconfirm>
            ),
        },
    ];
    return (
        <>
            <Row>
                <Button
                    onClick={() => handleAdd(table_key1, table_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                rowKey={(record: any) => record.key}
                size="middle"
                columns={columns}
                dataSource={formData.linha.ausencia}
            />
        </>
    );
}
