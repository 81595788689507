import actionType from "../constants/index";

const initialState = {
    domain: {
        defined_events: [],
        events_permissions: [],
        events_permission_groups: [],
        places: {
            interno: [],
            externo: [],
        },
    },
    app: {
        isFetched: false,
    },
};

export const scheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_DEFINED_EVENTS:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    defined_events: Array.isArray(action.payload["linha"])
                        ? action.payload["linha"]
                        : action.payload["linha"]
                        ? [action.payload["linha"]]
                        : [],
                },
                app: {
                    isFetched: true,
                },
            };
        case actionType.GET_EVENTS_PLACES:
            const dados = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    places: {
                        interno: dados.interno
                            ? Array.isArray(dados.interno["linha"])
                                ? dados.interno["linha"]
                                : dados.interno["linha"]
                                ? [dados.interno["linha"]]
                                : []
                            : [],
                        externo: dados.externo
                            ? Array.isArray(dados.externo["linha"])
                                ? dados.externo["linha"]
                                : dados.externo["linha"]
                                ? [dados.externo["linha"]]
                                : []
                            : [],
                    },
                },
                app: {
                    isFetched: true,
                },
            };
        case actionType.GET_EVENTS_PERMISSION:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    events_permissions: Array.isArray(action.payload["linha"])
                        ? action.payload["linha"]
                        : [action.payload["linha"]],
                },
                app: {
                    isFetched: true,
                },
            };
        case actionType.GET_EVENTS_PERMISSION_GROUPS:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    events_permission_groups: Array.isArray(action.payload["linha"])
                        ? action.payload["linha"]
                        : [action.payload["linha"]],
                },
                app: {
                    isFetched: true,
                },
            };
        default:
            return state;
    }
};
