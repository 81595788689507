import React, { useEffect } from "react";
import { Form, Button, message } from "antd";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { Group } from "../Components/Group";
import { FormLabel, getLabel } from "../Components/FormLabel";
import { CommunComponentsProps } from "../../../types/common";
import { BASE_URL } from "../../../../constants/enviroment";
import { Session, Utilitaries } from "../../../../business";
import { getFullPathRow } from "../handle/inputHandle";
import { useInputHandle } from "../hooks/InputHandle";
import { mapLoad } from "../../../utils/loadMap";

interface ComponentsPropertiesProps extends CommunComponentsProps {}

export const QALink: React.FC<ComponentsPropertiesProps> = ({
    componentsProperties,
    renderContext,
    tableRow,
    formState,
    formInputDependenceState,
    inputCalcTrigger,
    handleOnInputChangeValue,
}) => {
    print_when_component_is_rendered(componentsProperties);
    const { label } = getLabel({ componentsProperties, renderContext, required: false });
    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);
    const full_path = tableRow?.row_key ? fullPathRow : componentsProperties?.full_path;

    const {
        status: whenValueChangeStatus,
        componentValue,
        hasUpdatedValue,
        visible,
        relevant,
        readOnly,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[full_path || ""],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
        value,
    });

    if (componentsProperties.key === "seguro_upload") {
        console.log("readonly lik ");
        console.log(readOnly);
    }

    useEffect(() => {
        if (hasUpdatedValue && whenValueChangeStatus !== "IDLE") {
            handleOnInputChangeValue({
                value: componentValue,
                componentsProperties,
                row_key: tableRow?.row_key,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentValue]);

    async function onItemClick() {
        try {
            let url = "";
            if (Utilitaries.isEmpty(value)) {
                message.warning("Link inválido");
                return;
            }
            if (value && value?.startsWith("/PHP/Gestor")) {
                url = `${BASE_URL}${value}&token=Bearer ${Session.getToken()}`;
            } else {
                url = value;
            }

            window.open(url, "_blank");
            return;
        } catch (error) {}
    }

    if (!visible || !relevant) {
        return null;
    }

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={false} renderContext={renderContext}>
                {
                    <Button
                        onClick={() => onItemClick()}
                        icon="link"
                        loading={mapLoad([whenValueChangeStatus])}
                        disabled={readOnly}
                    />
                }
            </FormLabel>
        </Group>
    );
};
