import React from "react";
import { PageHeader } from "antd";
// import FunctionalGroupForm from "./form";
import FunctionalGroup from "./new.form";

const FunctionalGroupCreate = ({ history }) => {
    const goBack = () => {
        history.push({
            pathname: "/function.manual/index/1",
        });
    };

    return (
        <div>
            <PageHeader onBack={goBack} title="Grupo funcional" />
            <FunctionalGroup goBack={goBack} />
        </div>
    );
};

export default FunctionalGroupCreate;
