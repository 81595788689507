import React, { useEffect, useState } from "react";
import { Row, Col, PageHeader, Button, Icon, Checkbox, Table, Input, Pagination, Tooltip, message } from "antd";
import { getReports } from "../actions";
import { Session } from "../../../business/index";
import { baseUrl } from "../../../constants/enviroment";
import { useHistory, Link } from "react-router-dom";

const ModelsReports = () => {
    const [filter, setfilter] = useState({
        sorter: {
            columnKey: "codigo",
            order: "ascend",
        },
    });
    const [loadingData, setLoadingData] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [metadata, setMetadata] = useState({});
    const [showObsolete, setShowObsolete] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        currentPageSize: 10,
    });
    const history = useHistory();

    useEffect(() => {
        setLoadingData(true);
        const { sorter, ...objFilter } = filter;
        const values = {
            ...objFilter,
            pagina: pagination.currentPage,
            resultados_pagina: pagination.currentPageSize,
        };

        if (sorter && sorter.order) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        if (showObsolete) {
            values.obsoletos = "true";
        }

        getReports(values).then((response) => {
            if (response) {
                setDataSource(response.lista);
                setMetadata(response.metadata);
            } else {
                message.error("Ocorreu um erro");
            }

            setLoadingData(false);
        });
    }, [pagination, filter, showObsolete]);

    const getColumnSearchProps = () => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        };
    };

    const clearFormFilters = () => {
        setPagination({
            ...pagination,
            currentPage: 1,
        });
        setfilter({});
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const getColumnSortProps = (dataIndex, sortedInfo) => ({
        sorter: true,
        sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
    });

    const sortedInfo = filter.sorter || {};

    const column = [
        {
            title: "Código",
            dataIndex: "codigo",
            ...getColumnSearchProps(),
            ...getColumnSortProps("codigo", sortedInfo),
        },
        {
            title: "Nome",
            dataIndex: "nome",
            ...getColumnSearchProps(),
            ...getColumnSortProps("nome", sortedInfo),
        },
        {
            title: "Tipo",
            dataIndex: "tipo",
            ...getColumnSearchProps(),
            ...getColumnSortProps("tipo", sortedInfo),
        },
        {
            title: "Versão",
            dataIndex: "num_versao",
            ...getColumnSearchProps(),
            ...getColumnSortProps("num_versao", sortedInfo),
        },
        {
            title: "Estado",
            dataIndex: "estado_versao",
            ...getColumnSearchProps(),
            ...getColumnSortProps("estado_versao", sortedInfo),
        },
        {
            title: "Autor",
            dataIndex: "autor",
            ...getColumnSearchProps(),
            ...getColumnSortProps("autor", sortedInfo),
        },
        {
            title: "Ação",
            render: (_, row) => <Link to={`/models/reports/edit/${row.id_controlo_documento}`}>Consultar</Link>,
        },
    ];

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val[0]) obj["filtro_" + key] = val[0];
            });
        }
        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPagination({
            ...pagination,
            currentPage: 1,
        });

        setfilter(obj);
    };

    const handleShowSizeChange = (currentPage, currentPageSize) => {
        setPagination({
            currentPage: 1,
            currentPageSize,
        });
    };

    const pageChange = (currentPage, currentPageSize) => {
        setPagination({
            currentPage,
            currentPageSize,
        });
    };

    const exportList = (type) => {
        try {
            const formData = new FormData();
            formData.append("accao", "lista_relatorios");
            formData.append("saida", type);
            formData.append(
                "campos",
                "codigo:248:Código;nome:620:Nome;tipo:146:Tipo;num_versao:124:Versão;estado_versao:146:Estado;autor:146:Autor"
            );
            Object.entries(filter).forEach(([key, val]) => {
                formData.append(key, val);
            });
            formData.append("titulo", "Modelos de Relatórios");

            var request = new XMLHttpRequest();
            request.open("POST", baseUrl + "/Gestor/gererelatorios.php", true);

            request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
            request.responseType = "blob";

            request.onload = function () {
                // Only handle status code 200
                if (request.status === 200) {
                    // Try to find out the filename from the content disposition `filename` value
                    var disposition = request.getResponseHeader("content-disposition");
                    //
                    var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
                    //

                    var filename = matches != null && matches[1] ? matches[1] : "Modelos de Relatórios";

                    //
                    //     'request.getResponseHeader("content-type")',
                    //     request.getResponseHeader("content-type")
                    // );

                    // The actual download
                    var blob = new Blob([request.response], {
                        type: request.getResponseHeader("content-type"),
                    });

                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;

                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);
                }

                // some error handling should be done here...
            };

            request.send(formData);
            // const formData = new FormData();
            // formData.append("lista", listName);

            // const body = await sendHttpRequest(
            //   "POST",
            //   "Gestor/gereRelatorio.php",
            //   formData,
            // );
            //
        } catch (error) {}
    };

    return (
        <div>
            <PageHeader title="Modelos de Relatórios" />
            <Row type="flex" style={{ justifyContent: "space-between" }}>
                <Button
                    onClick={() =>
                        history.push({
                            pathname: "/models/reports/edit",
                        })
                    }
                    type="primary"
                    style={{ float: "right", marginLeft: 8, marginBottom: 16 }}
                >
                    <Icon type="plus" />
                    Registar
                </Button>
                <div>
                    <Checkbox onChange={(e) => setShowObsolete(e.target.checked)}>Incluir Obsoletos</Checkbox>
                    <Button
                        onClick={clearFormFilters}
                        type="link"
                        icon="undo"
                        style={{
                            marginLeft: 8,
                        }}
                    >
                        Limpar filtros
                    </Button>
                    <Tooltip title="Imprimir Excel">
                        <Button
                            style={{
                                marginLeft: 8,
                                color: "#237804",
                            }}
                            icon="file-excel"
                            onClick={() => exportList("xls")}
                        />
                    </Tooltip>
                    <Tooltip title="Imprimir PDF">
                        <Button
                            style={{
                                color: "#fa541c",
                                marginLeft: 8,
                            }}
                            icon="file-pdf"
                            onClick={() => exportList("pdf")}
                        />
                    </Tooltip>
                </div>
            </Row>
            <Table
                rowKey="id_controlo_documento"
                columns={column}
                pagination={false}
                dataSource={dataSource}
                loading={loadingData}
                onChange={handleTableChange}
            />
            <Row>
                <Col xs={24}>
                    <Pagination
                        current={pagination.currentPage}
                        defaultPageSize={pagination.currentPageSize}
                        size="small"
                        total={metadata.resultados}
                        showSizeChanger
                        showTotal={(total) => `Total de ${total} registo.`}
                        onChange={pageChange}
                        onShowSizeChange={handleShowSizeChange}
                        showTitle
                        showLessItems={true}
                        defaultCurrent={1}
                        style={{ float: "right", marginTop: 16 }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ModelsReports;
