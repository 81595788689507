import React, { Component } from "react";
import { Table, Button, Input, Icon, Tag, Row, Col, Pagination, Divider } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/collective.agreement";
import { tableSort, tableFooter } from "./../../../../components/util";
import Highlighter from "react-highlight-words";

class ListOfCollectiveAgreement extends Component {
    componentDidMount() {
        if (this.props.reload || !this.props.isFetched) this.selectData({ pagina: 1, resultados_pagina: 10 });
    }

    state = {
        isFetching: false,
        showDeletedRecords: false,
        filterParameters: {},
    };

    goTo = (key) => {
        return this.props.goTo("/function.manual/collective.agreement/edit/" + key);
    };

    selectData = (obj) => {
        this.setState({
            isFetching: true,
        });
        this.props
            .select({
                ...this.state.filterParameters,
                ...obj,
            })
            .then(() => {
                this.setState({
                    isFetching: false,
                });
            });
    };

    //---------------------------- filter --------------------------------

    getColumnSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={typeof text === "object" ? "" : text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    //---------------------------- endfilter -----------------------------

    /********************************* list */
    handlePageChange = (page, pageSize) => {
        this.selectData({ pagina: page, resultados_pagina: pageSize });
    };
    handleShowSizeChange = (current, pageSize) => {
        this.selectData({ pagina: current, resultados_pagina: pageSize });
    };
    handleTableChange = (pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            const value = sorter.order === "ascend" ? "ASC" : "DESC";
            obj["ordenacao_" + sorter.columnKey] = value;
        }
        this.props.setReportFilters(obj);
        const objFilter = {
            pagina: 1,
            resultados_pagina: this.props.resultadosPagina,
            ...obj,
        };
        this.setState({ filterParameters: obj }, () => {
            this.selectData(objFilter);
        });
    };

    columns = [
        {
            title: "Nome",
            dataIndex: "nome",
            width: "50%",
            ...this.getColumnSearchProps("nome"),
            sorter: (a, b) => tableSort(a.nome, b.nome),
        },
        {
            title: "Data da convenção",
            dataIndex: "data",
            width: "20%",
            ...this.getColumnSearchProps("data"),
            sorter: (a, b) => tableSort(a.data, b.data),
        },
        {
            title: "Vigor",
            dataIndex: "em_vigor",
            render: (em_vigor) => {
                return typeof em_vigor !== "object" ? (
                    <Tag color={em_vigor === "t" ? "green" : "red"}>{em_vigor === "t" ? "Sim" : "Não"}</Tag>
                ) : null;
            },
            filters: [
                {
                    text: "Não",
                    value: "f",
                },
                {
                    text: "Sim",
                    value: "t",
                },
            ],
        },
        {
            title: "Ação",
            dataIndex: "action",
            render: (text, record) => (
                <>
                    <Button type="link" onClick={() => this.goTo(record.id_convencao_coletiva)}>
                        Detalhes
                    </Button>
                    <Divider type="vertical" />
                    <Button type="link" onClick={() => this.goTo(record.id_convencao_coletiva)}>
                        Editar
                    </Button>
                </>
            ),
        },
    ];

    render() {
        return (
            <>
                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {}, // click row
                            onDoubleClick: (event) => {
                                this.goTo(record.id_convencao_coletiva);
                            },
                            // double click row
                            onContextMenu: (event) => {}, // right button click row
                            onMouseEnter: (event) => {
                                event.target.style.cursor = "pointer";
                            }, // mouse enter row
                            onMouseLeave: (event) => {
                                event.target.style.cursor = "none";
                            }, // mouse leave row
                        };
                    }}
                    size="middle"
                    columns={this.columns}
                    rowKey={(record) => record.id_convencao_coletiva}
                    dataSource={this.props.list.filter((item) => typeof item.nome !== "object")}
                    loading={this.state.isFetching}
                    pagination={false}
                    footer={() => tableFooter(this.props.total)}
                    onChange={this.handleTableChange}
                />
                {this.props.isFetched && (
                    <>
                        <Row>
                            <Col xs={24}>
                                <Pagination
                                    showTitle
                                    size="small"
                                    showSizeChanger
                                    showLessItems
                                    onShowSizeChange={this.handleShowSizeChange}
                                    onChange={this.handlePageChange}
                                    current={this.props.pagina}
                                    total={this.props.total}
                                    style={{ float: "right" }}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        list: Object.values(state.collectiveAgreement.domain.byId),
        isFetched: state.collectiveAgreement.app.isFetched,
        total: Number(state.collectiveAgreement.domain.metadata.attributes.resultados),
        pagina: Number(state.collectiveAgreement.domain.metadata.attributes.pagina),
        resultadosPagina: state.collectiveAgreement.domain.metadata.attributes.resultados_pagina,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...actionCreators }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ListOfCollectiveAgreement);
