import {
    Badge,
    Button,
    Col,
    Divider,
    Popconfirm,
    Row,
    Select,
    Table,
    Form,
    Input,
    Tooltip,
    Icon,
    Typography,
} from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Utilitaries } from "../../../../../business";
import { api_fetch } from "../../../../utils/HttpRequest/request";
import { ConventionCategoriesProps, DataChangeProps, FormDataProps } from "../utils/types";
import { DepartmentModal } from "../Components/DepartmentModal";

const { Option } = Select;

interface IProps extends DataChangeProps {
    form: WrappedFormUtils;
    formData: FormDataProps;
    functionalGroups: any[];
    conventionCategories: ConventionCategoriesProps[];
    getConventionCategories: (convention: any) => void;
}

const table_key1 = "funcoes_colab";
const table_key2 = "funcao_colab";

export function FuntionsConventions({
    form,
    formData,
    handleAdd,
    handleDelete,
    onChange,
    functionalGroups,
    conventionCategories,
    getConventionCategories,
}: IProps) {
    const { getFieldValue, getFieldDecorator, setFieldsValue } = form;
    const [collectiveConventions, setCollectiveConventions] = useState<any[]>([]);
    const [levels, setLevels] = useState<any[]>([]);
    const [showModalDepartment, setShowModalDepartment] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await api_fetch({
                endPoint: "/Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: "convencoes",
                },
            });

            if (response.status === "SUCCESS") {
                setCollectiveConventions(response.response.result);
            }
        };

        fetchData();

        // if (formData.convenc_coletiva) {
        //     getConventionCategories(formData.convenc_coletiva);
        // }

        if (formData.categoria) {
            getLevels(formData.categoria);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getLevels(category: any) {
        const response = await api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "niveis_categoria",
                categoria: category,
            },
        });

        if (response.status === "SUCCESS") {
            setLevels(response.response.result);
        }
    }

    async function getLevelInfo(level: any) {
        const response = await api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "carrega_info_nivel_colet",
                categoria: getFieldValue("categoria"),
                nivel: getFieldValue("nivel"),
                convenc_coletiva: getFieldValue("convenc_coletiva"),
            },
        });

        if (response.status === "SUCCESS") {
            setFieldsValue({
                info_nivel: response.response?.result?.[0]?.anos_transitar_nivel,
            });
        }
    }

    function showDepartmentModal(record: any) {
        setShowModalDepartment(record);
    }

    const columns = [
        {
            title: "Grupo Funcional",
            dataIndex: "id_funcao",
            width: "70%",
            render: (text: string, record: any) => (
                <Select
                    allowClear
                    showSearch
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="Seleciona o grupo funcional"
                    optionFilterProp="children"
                    onChange={(value: string) =>
                        onChange({
                            dataIndex: "id_funcao",
                            value,
                            row_key: record.key,
                            table_key1,
                            table_key2,
                        })
                    }
                    defaultValue={Utilitaries.toString(text)}
                    filterOption={(input, option) =>
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {functionalGroups
                        .filter((item) => typeof item.id_ficha_funcao !== "object" && typeof item.codigo !== "object")
                        .map((item) => {
                            return (
                                <Option key={item.id_ficha_funcao} value={item.id_ficha_funcao}>
                                    {item.descricao_funcao}
                                </Option>
                            );
                        })}
                </Select>
            ),
        },
        {
            title: "Unidade orgânica",
            dataIndex: "fcb_departamentos",
            align: "center",
            width: "20%",
            render: (_: any, record: any) => {
                const totalDepartment = typeof record.total_departamento !== "object" ? record.total_departamento : 0;
                return (
                    <Badge count={record.totalOfDepartment || totalDepartment}>
                        {record.fcb_departamentos && typeof record.fcb_departamentos !== "object" ? (
                            <Button type="primary" icon="edit" onClick={() => showDepartmentModal(record)} />
                        ) : (
                            <Button type="primary" icon="plus" onClick={() => showDepartmentModal(record)} />
                        )}
                    </Badge>
                );
            },
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "10%",
            render: (_: any, record: any) =>
                formData.funcoes_colab.funcao_colab.length >= 1 ? (
                    <Popconfirm
                        title="Eliminar a linha?"
                        onConfirm={() => handleDelete(record.key, table_key1, table_key2)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    function handleCloseDepartmentModal(isCancel: boolean, id?: string, length?: number) {
        if (!isCancel) {
            onChange({
                dataIndex: "",
                value: {
                    fcb_departamentos: id,
                    totalOfDepartment: length,
                },
                row_key: showModalDepartment.key,
                table_key1,
                table_key2,
            });
        }
        setShowModalDepartment(null);
    }

    return (
        <>
            <Row>
                {showModalDepartment && (
                    <DepartmentModal
                        id={showModalDepartment?.fcb_departamentos}
                        isVisible={showModalDepartment !== null}
                        handleClose={handleCloseDepartmentModal}
                    />
                )}
                <Button
                    onClick={() => handleAdd(table_key1, table_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                size="middle"
                scroll={{ x: 800, y: 300 }}
                columns={columns as any}
                dataSource={formData.funcoes_colab.funcao_colab}
            />
            <Divider orientation="left">Convenções</Divider>

            <Form layout="vertical">
                <Row gutter={16}>
                    <Col xs={24} sm={16} md={8}>
                        <Form.Item label="Convenção coletiva do trabalho">
                            {getFieldDecorator("convenc_coletiva", {
                                initialValue: Utilitaries.toString(formData.convenc_coletiva),
                            })(
                                <Select
                                    allowClear
                                    showSearch
                                    style={{
                                        width: "100%",
                                        margin: "0 auto",
                                        padding: "0",
                                    }}
                                    placeholder="seleciona uma opção"
                                    optionFilterProp="children"
                                    onChange={(id) => getConventionCategories(id)}
                                    filterOption={(input, option) =>
                                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {collectiveConventions
                                        ?.filter((item) => item.nome !== "" && typeof item.nome !== "object")
                                        ?.map((item) => {
                                            return (
                                                <Option
                                                    key={item.id_convencao_coletiva}
                                                    value={item.id_convencao_coletiva}
                                                >
                                                    {item.nome}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={8}>
                        <Form.Item label="Categoria">
                            {getFieldDecorator("categoria", {
                                initialValue: Utilitaries.toString(formData.categoria),
                            })(
                                <Select
                                    allowClear
                                    showSearch
                                    style={{
                                        width: "100%",
                                        margin: "0 auto",
                                        padding: "0",
                                    }}
                                    placeholder="seleciona uma opção"
                                    optionFilterProp="children"
                                    onChange={(id) => getLevels(id)}
                                    filterOption={(input, option) =>
                                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {conventionCategories
                                        ?.filter(
                                            (item) => item.designacao !== "" && typeof item.designacao !== "object"
                                        )
                                        ?.map((item) => {
                                            return (
                                                <Option key={item.id_categoria} value={item.id_categoria}>
                                                    {item.designacao}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={8}>
                        <Form.Item label="Nível">
                            {getFieldDecorator("nivel", {
                                initialValue: Utilitaries.toString(formData.nivel),
                            })(
                                <Select
                                    allowClear
                                    showSearch
                                    style={{
                                        width: "100%",
                                        margin: "0 auto",
                                        padding: "0",
                                    }}
                                    placeholder="seleciona uma opção"
                                    optionFilterProp="children"
                                    onChange={(id) => getLevelInfo(id)}
                                    filterOption={(input, option) =>
                                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {levels
                                        ?.filter((item) => item.nivel !== "" && typeof item.nivel !== "object")
                                        ?.map((item) => {
                                            return (
                                                <Option key={item.id_nivel_categoria} value={item.id_nivel_categoria}>
                                                    {item.nivel}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={8}>
                        <Form.Item label="Informação do tempo para transição">
                            {getFieldDecorator("info_nivel", {
                                initialValue: Utilitaries.toString(formData.info_nivel),
                            })(<Input readOnly placeholder="Informação do tempo para transição" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={8}>
                        <Form.Item
                            label={
                                <Row>
                                    <Tooltip title="Indique a data em que o colaborador iniciou neste nível. Se não preencher este campo não serão criadas alertas.">
                                        <Icon type="info-circle" />
                                    </Tooltip>
                                    <Typography.Text> Data em vigor</Typography.Text>
                                </Row>
                            }
                        >
                            {getFieldDecorator("data_vigor", {
                                initialValue: Utilitaries.toString(formData.data_vigor)
                                    ? moment(formData.data_vigor, "YYYY-MM-DD").format("YYYY-MM-DD")
                                    : null,
                            })(
                                <Input
                                    type="date"
                                    min={moment().format("YYYY-MM-DD")}
                                    // disabledDate={this.disabledDate}
                                    // disabledTime={this.disabledDateTime}
                                    // onChange={(e) => {
                                    //     this.props.getDataVigor(
                                    //         e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : ""
                                    //     );
                                    // }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
