import React, { Component } from "react";
import { Modal, Row, Col, Form, Input, Badge, Button, message, Skeleton, Checkbox } from "antd";
import DimensionWrapper from "./dimensao";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/standarts";
import QaDynamicSelect from "../../../../components/DynamicList/dynamicSelect";
import { countSubDimension } from "./../../actions/dimension";
import { countScale } from "./../../actions/scale";
import EditInPlace from "./editInPlace";
import Scale from "./scale";
import Confirm from "./../../../../components/qa_custom/confirm";
import { FormUtilitaries, Utilitaries } from "../../../../business";

export class AuditStandartsRegestryModal extends Component {
    componentDidMount() {
        if (this.props.isEdit) {
            this.getFormData().then((response) => {
                if (response.requisitos_norma) this.countSubDimension(response.requisitos_norma);
                else this.setState({ totalSubDimension: 0 });
                if (response.escala) this.countScale(response.escala);
                else this.setState({ totalScale: 0 });
            });
        } else {
            this.getFormulario();
        }
    }

    state = {
        isModalVisible: false,
        buttonLoading: false,
        loadingStandart: false,
        // dimensao
        isModalDimensaoVisible: false,
        dimensionDataModal: {},
        scaleDataModal: {},
        form: {
            etiquetas: ["Dimensão 1"],
        },
        //data norma
        dimensionNumber: 1,
    };

    getFormulario = () => {
        this.setState({
            loadingStandart: true,
        });
        this.props.getFormulario().then((response) => {
            if (!response) message.error("Ocorreu um erro");
            this.setState({
                loadingStandart: false,
                formMetadata: response.formulario,
                dimensionForm: response.formularioDimensao,
                scaleForm: response.formularioEscala,
                totalSubDimension: 0,
                totalScale: 0,
            });
        });
    };

    getFormData = () => {
        this.setState({
            loadingStandart: true,
        });
        return this.props.getDetails({ id: this.props.idEdit }).then((response) => {
            if (!response) message.error("Ocorreu um erro");
            else {
                const form = { ...response.values, ativo: response?.values?.ativo === "f" ? false : true };
                if (form.etiquetas.length === 0) form.etiquetas.push("Dimensão 1");

                this.setState({
                    form: {
                        ...this.state.form,
                        ...form,
                    },
                    defaultForm: {
                        ...this.state.form,
                        ...form,
                    },
                    formMetadata: response.formulario,
                    dimensionForm: response.formularioDimensao,
                    scaleForm: response.formularioEscala,
                    loadingStandart: false,
                });
                return form;
            }
            this.setState({
                loadingStandart: false,
            });
            return false;
        });
    };

    countSubDimension = (id_dimensao) => {
        this.setState({ loadingCountSubdimension: true });
        this.props.countSubDimension({ id_dimensao }).then((totalSubDimension) => {
            this.setState({
                totalSubDimension,
                loadingCountSubdimension: false,
            });
        });
    };

    countScale = (escala) => {
        this.setState({ loadingCountScale: true });

        const ids = [escala];
        this.props.countScale({ ids }).then((totalScale) => {
            this.setState({
                totalScale,
                loadingCountScale: false,
            });
        });
    };

    handleResponse = (response) => {
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleOk();
        } else message.error("Ocorreu um erro");
    };
    /** handle ok */
    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ buttonLoading: true });
                const { form, formMetadata } = this.state;

                values.id_normas_auditorias = form.id_normas_auditorias;
                values.requisitos_norma = form.requisitos_norma;
                values.escala = form.escala;
                values.etiquetas = form.etiquetas;

                values.ativo = values?.ativo ? values.ativo : "f";

                if (form.labelSubDimensao !== "Dimensão 1") values.etiqueta_subdimensao = form.labelSubDimensao;

                this.props
                    .save({
                        formulario: formMetadata,
                        values,
                    })
                    .then((response) => this.handleResponse(response));
            }
        });
    };

    /**child dimension */
    showChildDimension = () => {
        this.setState({
            isModalDimensaoVisible: true,
            dimensionDataModal: {
                dimensionId: this.state.form.requisitos_norma,
                parentDesignation: this.props.form.getFieldValue("nome_norma"),
            },
        });
    };

    assocDimension = (obj) => {
        if (!this.state.form.id_normas_auditorias || this.state.form.requisitos_norma) {
            const promise = new Promise((resolve) => {
                resolve(true);
            });
            return promise;
        }
        const { defaultForm, formMetadata } = this.state;
        const values = { ...defaultForm, requisitos_norma: obj.dimensionId };

        return this.props
            .save({
                formulario: formMetadata,
                values,
            })
            .then((response) => {
                this.setState({
                    defaultForm: values,
                });

                return response;
            });
    };

    submitChildDimension = (obj) => {
        this.setState({
            isModalDimensaoVisible: false,
            form: { ...this.state.form, requisitos_norma: obj.dimensionId },
            totalSubDimension: obj.total,
        });
    };

    cancelChildDimension = (e) => {
        this.setState({
            isModalDimensaoVisible: false,
        });
    };

    //change dymanic list
    handleOnChange = (item) => {
        this.setState({
            form: { ...this.form, [item.name]: item.value },
        });
    };

    saveEtiquetas = (dimensionNumber, value) => {
        const etiquetas = [...this.state.form.etiquetas];
        etiquetas[dimensionNumber] = value;
        this.setState({
            form: { ...this.state.form, etiquetas },
        });
    };

    /**Scale */

    showScaleModal = () => {
        this.setState({
            isVisibleScaleModal: true,
            scaleDataModal: {
                id: this.state.form.escala,
                parentDesignation: this.props.form.getFieldValue("nome_norma"),
            },
        });
    };

    assocScale = (obj) => {
        if (!this.state.form.id_normas_auditorias || this.state.form.escala) {
            const promise = new Promise((resolve) => {
                resolve(true);
            });
            return promise;
        }
        const { defaultForm, formMetadata } = this.state;
        const values = { ...defaultForm, escala: obj.id };
        return this.props
            .save({
                formulario: formMetadata,
                values,
            })
            .then((response) => {
                this.setState({
                    defaultForm: values,
                });
                return response;
            });
    };

    submitScale = (obj) => {
        this.setState((state) => {
            return {
                isVisibleScaleModal: false,
                form: {
                    ...state.form,
                    escala: obj.id,
                },
                totalScale: obj.total,
                defaultForm: {
                    ...state.defaultForm,
                    escala: obj.id,
                },
            };
        });
    };

    handleCancelScaleModal = (e) => {
        this.setState({
            isVisibleScaleModal: false,
        });
    };
    handleCancel = () => {
        if (this.props.readOnly) this.props.handleCancel();
        else Confirm(this.props.handleCancel);
    };
    render() {
        const title = this.props.isEdit ? "Editar norma" : "Registo de norma";
        const { getFieldDecorator } = this.props.form;
        const { form, dimensionNumber } = this.state;
        const currentDimension = dimensionNumber - 1;
        const propsFooter = this.props.readOnly ? { footer: null } : {};
        return (
            <Modal
                title={title}
                width="40%"
                visible={this.props.isModalVisible}
                onOk={this.handleOk}
                confirmLoading={this.state.buttonLoading}
                onCancel={this.handleCancel}
                {...propsFooter}
            >
                <Form layout="vertical">
                    <Row gutter={18}>
                        <Col xs={18}>
                            <Skeleton
                                paragraph={{ rows: 1, width: "100%" }}
                                active
                                loading={this.state.loadingStandart}
                            >
                                <Form.Item label="Norma">
                                    {getFieldDecorator("nome_norma", {
                                        initialValue: this.state.form.nome_norma,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Introduza o nome da norma"
                                            disabled={this.props.readOnly}
                                        ></Input>
                                    )}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={6}>
                            <Skeleton
                                paragraph={{ rows: 1, width: "100%" }}
                                active
                                loading={this.state.loadingStandart}
                            >
                                <Form.Item
                                    label={
                                        <EditInPlace
                                            value={form.etiquetas[currentDimension]}
                                            onChangeValue={(value) => this.saveEtiquetas(currentDimension, value)}
                                            readOnly={this.props.readOnly}
                                        />
                                    }
                                >
                                    <Badge
                                        dot={this.state.loadingCountSubdimension}
                                        count={this.state.totalSubDimension}
                                        showZero
                                    >
                                        <Button
                                            icon="edit"
                                            onClick={() => {
                                                this.showChildDimension();
                                            }}
                                        />
                                    </Badge>
                                </Form.Item>
                            </Skeleton>
                        </Col>
                    </Row>
                    <Row gutter={18}>
                        <Col xs={18}>
                            <Skeleton
                                paragraph={{ rows: 1, width: "100%" }}
                                active
                                loading={this.state.loadingStandart}
                            >
                                <Form.Item label="Âmbito">
                                    {getFieldDecorator("id_ambito_norma", {
                                        initialValue: this.state.form.id_ambito_norma,

                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(
                                        <QaDynamicSelect
                                            placeholder={"Selecione a área de gestão"}
                                            name="ambito_norma"
                                            disabled={this.props.readOnly}
                                        />
                                    )}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={6}>
                            <Skeleton
                                paragraph={{ rows: 1, width: "100%" }}
                                active
                                loading={this.state.loadingStandart}
                            >
                                <Form.Item label={"Escala global"}>
                                    <Badge dot={this.state.loadingCountScale} count={this.state.totalScale} showZero>
                                        <Button
                                            icon="edit"
                                            onClick={() => {
                                                this.showScaleModal();
                                            }}
                                        />
                                    </Badge>
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={18}>
                            <Skeleton
                                paragraph={{ rows: 1, width: "100%" }}
                                active
                                loading={this.state.loadingStandart}
                            >
                                <Form.Item label="Tipo Norma">
                                    {getFieldDecorator("id_tipo_norma", {
                                        initialValue: this.state.form.id_tipo_norma,

                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(
                                        <QaDynamicSelect
                                            placeholder={"Selecione uma opção"}
                                            name="tipo_norma"
                                            disabled={this.props.readOnly}
                                        />
                                    )}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={6}>
                            <Skeleton
                                paragraph={{ rows: 1, width: "100%" }}
                                active
                                loading={this.state.loadingStandart}
                            >
                                <Form.Item label={"Ativo"}>
                                    {getFieldDecorator("ativo", {
                                        valuePropName: "checked",
                                        initialValue: this.state.form.ativo,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(<Checkbox disabled={this.props.readOnly} />)}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                    </Row>
                </Form>
                {this.state.isModalDimensaoVisible && (
                    <DimensionWrapper
                        isModalVisible={this.state.isModalDimensaoVisible}
                        dimension={this.state.dimensionDataModal}
                        dimensionNumber={dimensionNumber}
                        formName={this.state.dimensionForm}
                        assocDimension={this.assocDimension}
                        handleOk={this.submitChildDimension}
                        handleCancel={this.cancelChildDimension}
                        etiquetas={form.etiquetas}
                        saveEtiquetas={this.saveEtiquetas}
                        readOnly={this.props.readOnly}
                    />
                )}

                {this.state.isVisibleScaleModal && (
                    <Scale
                        isModalVisible={this.state.isVisibleScaleModal}
                        formName={this.state.scaleForm}
                        scale={this.state.scaleDataModal}
                        assocScale={this.assocScale}
                        handleOk={this.submitScale}
                        handleCancel={this.handleCancelScaleModal.bind(this)}
                        readOnly={this.props.readOnly}
                    />
                )}
            </Modal>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getFormulario: actionCreators.getFormulario,
            save: actionCreators.save,
            getDetails: actionCreators.getDetails,
            countSubDimension,
            countScale,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        formStandart: state.standarts.domain.form,
        formulario: state.standarts.domain.metadata.formulario,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({})(AuditStandartsRegestryModal));
