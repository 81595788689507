import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Alert, Table, Button, Popconfirm, Select, message } from "antd";
import Confirm from "./../../../components/qa_custom/confirm";
import { getConfiguration, getTasks, saveConfiguration, getProcessWithPopupProcess } from "./../actions/index";
const { Option } = Select;

const Configuration = ({ isVisible, handleOk, hideModal, form }) => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [processList, setProcessList] = useState([]);
    const [nextKey, setNextKey] = useState(0);
    useEffect(() => {
        let mounted = true;
        const configuration = getConfiguration();
        const processList = getProcessWithPopupProcess();
        Promise.all([configuration, processList]).then((result) => {
            const list = result[0].map((item, index) => ({
                ...item,
                key: index,
            }));

            let promiseList = [];
            list.forEach((element) => {
                promiseList.push(getTasks(element.processo));
            });
            setProcessList(result[1]);
            Promise.all(promiseList).then((result) => {
                const dataSource = list.map((item, index) => ({
                    ...item,
                    taskList: result[index],
                }));
                if (result[0] && mounted) {
                    setDataSource(dataSource);
                    setNextKey(dataSource.length);
                }
                setLoadingData(false);
            });
        });

        return () => {
            mounted = false;
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        saveConfiguration(dataSource).then((response) => {
            if (response) {
                message.success("Guardado com sucesso");
                hideModal();
            } else message.error("Ocorreu um erro");
            setButtonLoading(false);
        });
    };
    const handleAdd = () => {
        const newData = {
            key: nextKey,
            processo: null,
            tarefa: null,
            taskList: [],
        };
        const list = [...dataSource, newData];
        setDataSource(list);
        setNextKey(nextKey + 1);
    };

    const updateListItem = (record) => {
        const list = [...dataSource];
        const index = list.findIndex((item) => record.key === item.key);
        list[index] = record;
        setDataSource(list);
    };

    const changeProcess = (processo, record) => {
        const recordToChange = {
            ...record,
            processo,
            tarefa: null,
            taskList: [],
        };
        loadTasks(processo, record.key);
        updateListItem(recordToChange);
    };

    const loadTasks = (processo, key) => {
        getTasks(processo).then((result) => {
            const index = dataSource.findIndex((item) => item.key === key);
            const obj = dataSource[index];
            obj.processo = processo;
            obj.tarefa = null;
            obj.taskList = result;
            updateListItem(obj);
        });
    };

    const changeTask = (tarefa, record) => {
        const recordToChange = {
            ...record,
            tarefa,
        };
        updateListItem(recordToChange);
    };

    const handleDelete = (key) => {
        setDataSource(dataSource.filter((item) => item.key !== key));
    };

    const columns = [
        {
            title: "Processo",
            dataIndex: "processo",
            width: "40%",
            render: (text, record) => {
                return (
                    <Select
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => changeProcess(e, record)}
                        value={text}
                    >
                        {processList.map((item) => (
                            <Option key={item.nome}>{item.nome}</Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Tarefa",
            dataIndex: "tarefa",
            width: "40%",
            render: (text, record) => (
                <Select
                    style={{ width: "100%" }}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    defaultValue={text}
                    value={text}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => changeTask(e, record)}
                >
                    {record.taskList.map((item) => (
                        <Option key={item.nome}>{item.nome}</Option>
                    ))}
                </Select>
            ),
        },
        {
            dataIndex: "operation",
            width: "20%",
            render: (text, record) => (
                <Popconfirm title="Tem a certeza que pretende continuar?" onConfirm={() => handleDelete(record.key)}>
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                            color: "#fa541c",
                        }}
                    >
                        Eliminar
                    </Button>
                </Popconfirm>
            ),
        },
    ];
    const propsFooter = loadingData ? { footer: null } : {};
    return (
        <>
            <Modal
                title={"Configurar dossiê"}
                visible={isVisible}
                onOk={handleSubmit}
                confirmLoading={buttonLoading}
                onCancel={() => Confirm(hideModal)}
                width="60%"
                {...propsFooter}
            >
                <Form layout="vertical">
                    <Row gutter={16}>Permite definir as tarefas a apresentar no Dossiê do Cliente!</Row>
                    <Row style={{ marginBottom: 16 }}>
                        <Col xs={24}>
                            <Button
                                onClick={handleAdd}
                                disabled={loadingData}
                                type="primary"
                                icon="plus"
                                style={{ float: "right" }}
                            >
                                Inserir
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        rowClassName={() => "editable-row"}
                        size="small"
                        loading={loadingData}
                        dataSource={dataSource}
                        columns={columns}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default Form.create({})(Configuration);
