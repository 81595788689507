import ACTIONS_TYPES from "../constants/index";
import { sendHttpRequest } from "../../../components/http.request";

export const getMenus = (pagination, filter) => async (dispatch, getState) => {
    const formData = new FormData();
    // Object.entries(requestBody).forEach(([key, value]) => {
    //     formData.append(key, value);
    // });

    formData.append("accao", "lista");

    formData.append("pagina", pagination.page);
    formData.append("resultados_pagina", pagination.perPage);

    Object.entries(filter).forEach(([key, val]) => {
        formData.append(key, val);
    });

    return await sendHttpRequest("POST", "/Gestor/geremenus.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.GET_MENUS,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const saveAutorizations = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/geremenus.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) return true;
        else return false;
    });
};
