export const actionType = {
    select: "SELECT_FUNCIONAL_GROUP",
    setValuesToFields: "SET_VALUES_TO_FUNCTIONAL_GROUP_FIELDS",
    setValuesToIndexOfFields: "SET_VALUES_TO_INDEX_OF_FIELDS_ROW",
    setValuesToIndexOfFieldsToTaskTable: "SET_VALUES_TO_INDEX_OF_FIELDS_TO_TASK_TABLE",
    addNewRowsToTable: "ADD_NEW_ROWS_TO_FUNCTIONAL_GROUP_TABLE",
    removeRowFromTable: "REMOVE_ROW_FROM_FUNCTIONAL_GROUP_TABLE",
    selectExistingGroup: "SELECT_EXISTING_GROUP",
    selectFunctionalGroupsTasks: "SELECT_FUNCTIONAL_GROUP_TASKS",
    selectFunctionalGroupContributors: "SELECT_FUNCTIONAL_GROUPS_CONTRIBUTORS",
    addNewRequisito: "ADD_NEW_REQUIRIMENT_TO_TABLE",
    addNewConhecimento: "ADD_NEW_CONHECIMENTO_TO_TABLE",
    addNewCompetencia: "ADD_NEW_COMPETENCIA_TO_TABLE",
    addNewParametroComport: "ADD_NEW_PARAMETRO_COMPORT_TO_TABLE",
    addNewTaskToFunctionalGroup: "ADD_NEW_TASK_TO_FUNCTIONAL_GROUP",
    removeTaskToFunctionalGroup: "REMOVE_TASK_TO_FUNCTIONAL_GROUP",
    removeRequisitosToFunctionalGroup: "REMOVE_REQUISITOS_TO_FUNCTIONAL_GROUP",
    removeConhecimentoToFunctionalGroup: "REMOVE_CONHECIMENTO_TO_FUNCTIONAL_GROUP",
    removeCompetenciaToFunctionalGroup: "REMOVE_COMPETENCIA_TO_FUNCTIONAL_GROUP",
    removeParametroToFunctionalGroup: "REMOVE_PARAMETRO_TO_FUNCTIONAL_GROUP",
    clearExistingGroupDataFromForm: "CLEAR_EXISTING_GROUP_DATA_FROM_FORM",
    selectTasksSIByAutorizathion: "SELECT_TASKS_SI_BY_AUTORIZATIONS",
    // insert: "INSERT_COLLECTIVE_AGREEMENT",
    // setField: "SET_FIELD_COLLECTIVE_AGREEMENT",
    // getEdit: "GET_EDIT_COLLECTIVE_AGREEMENT",
    // update: "UPDATE_COLLECTIVE_AGREEMENT",
    // delete: "DELETE_COLLECTIVE_AGREEMENT"
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
