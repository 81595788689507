import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, message, Select, Row, Col, Icon, Typography } from "antd";
import { ChromePicker } from "react-color";

import AddNotification from "./agendar.notificacao";
import { sendHttpRequest } from "../../http.request";
import ModalSelectUser from "../../select.user";
import { Utilitaries } from "../../../business";
import { toArray } from "../../util";

const EVENTS_TYPE = ["Atividades", "Formação", "Outras", "Reunião", "Sessões"];

const ModalCreate = (props) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [addNotification, setAddNotification] = useState(false);
    const [color, setColor] = useState({
        rgb: {},
        hex: props?.update?.cor || "#999999",
    });
    const [isVisibleModalSelectUserAndGroup, setIsVisibleModalSelectUserAndGroup] = useState(false);
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [avisar, setAvisar] = useState([]);

    useEffect(() => {
        setColor({
            hex: props?.update?.cor || "#999999",
        });

        const getUserAndGroup = async (id) => {
            try {
                const formData = new FormData();
                formData.append("accao", "consulta");
                formData.append("id", id);

                const jsonResponse = await sendHttpRequest("POST", "/Gestor/gereagendadefinido.php", formData);

                if (jsonResponse.erro) return;

                const _users = toArray(jsonResponse?.utilizadores?.linha).map((item) => ({
                    username: item.username,
                    name: item.nome,
                }));

                const _groups = toArray(jsonResponse?.grupos?.linha).map((item) => ({
                    id: item.grupo,
                    value: item.nome_grupo,
                }));

                setAvisar(toArray(jsonResponse?.avisar?.linha));

                setGroups(_groups);
                setUsers(_users);
            } catch (error) {}
        };

        if (props?.update?.id_evento_defeito) {
            getUserAndGroup(props.update.id_evento_defeito);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.update]);

    const handleSubmit = (e) => {
        e.preventDefault();
        return false;
    };

    const onOk = () => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const accao = Object.keys(props.update).length > 0 ? "editar" : "enviar";

                const formData = new FormData();
                formData.append("accao", accao);
                formData.append("designacao", values.designacao);
                formData.append("descricao", values.descricao);

                formData.append("avisar", JSON.stringify(avisar));
                // formData.append("avisar_numero", values.avisar.number);
                // formData.append("avisar_tipo", values.avisar.type);
                formData.append("tipo", values.tipo);

                formData.append(
                    "grupos",
                    groups.reduce(
                        (accumulator, currentValue) =>
                            accumulator === "" ? currentValue.id : accumulator + ";" + currentValue.id,
                        ""
                    )
                );
                formData.append(
                    "utilizadores",
                    users.reduce(
                        (accumulator, currentValue) =>
                            accumulator === "" ? currentValue.username : accumulator + ";" + currentValue.username,
                        ""
                    )
                );

                // formData.append("cor", parseInt(color.hex.substr(1), 16));
                formData.append("cor", color.hex);
                if (accao === "editar") {
                    formData.append("id", props.update.id_evento_defeito);
                }
                sendHttpRequest("POST", "/Gestor/gereagendadefinido.php", formData).then((jsonResponse) => {
                    if (jsonResponse.erro) {
                        message.error("Erro ao guardar eventos.");
                    } else {
                        message.success("Eventos criado com sucesso");
                        props.onCreated();
                    }
                });
            }
        });
    };

    const formItemLayout = {
        // labelCol: {
        //     xs: { span: 24 },
        //     sm: { span: 6 },
        // },
        // wrapperCol: {
        //     xs: { span: 24 },
        //     sm: { span: 18 },
        // },
    };

    const groupStyle = {
        border: "1px solid silver",
        borderRadius: "4px",
        borderStyle: "dashed",
        padding: 8,
    };

    const { getFieldDecorator } = props.form;

    const popover = {
        position: "absolute",
        zIndex: "2",
    };

    const cover = {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    };

    return (
        <>
            <ModalSelectUser
                visible={isVisibleModalSelectUserAndGroup}
                setVisible={setIsVisibleModalSelectUserAndGroup}
                defaultUsers={users}
                defaultGroups={groups}
                {...props.selectContributorsProps}
                // communication={true}
                onOk={(usersSelected, groupsSelected, sendEmail, sendSMS) => {
                    setGroups(groupsSelected);
                    setUsers(usersSelected);
                }}
            />

            <AddNotification
                visible={addNotification}
                onCancel={setAddNotification}
                defaultValues={avisar}
                onCreate={(list) => {
                    setAvisar(list);
                }}
            />

            <Modal
                title="Criar Evento Definido"
                visible={props.visible}
                onCancel={props.onCancel}
                onOk={onOk}
                width="50%"
                centered
                destroyOnClose
            >
                <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Designação">
                                {getFieldDecorator("designacao", {
                                    initialValue: Object.keys(props.update).length > 0 ? props.update.designacao : "",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    initialValue: Object.keys(props.update).length > 0 ? props.update.descricao : "",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Tipo Evento">
                                {getFieldDecorator("tipo", {
                                    initialValue:
                                        Object.keys(props.update).length > 0
                                            ? Utilitaries.toString(props.update?.tipo)
                                            : "",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Informe o tipo!",
                                        },
                                    ],
                                })(
                                    <Select>
                                        {EVENTS_TYPE.map((it) => (
                                            <Select.Option key={it} value={it}>
                                                {it}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Configurar aviso">
                                <Button icon="form" onClick={() => setAddNotification(true)} />
                                {/* {getFieldDecorator("avisar", {
                                    initialValue:
                                        Object.keys(props.update).length > 0
                                            ? {
                                                  number: Utilitaries.toNumber(props.update?.avisar_numero) || 0,
                                                  type: Utilitaries.toString(props.update?.avisar_tipo) || "",
                                              }
                                            : {},
                                })(<RememberInput />)} */}
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Typography.Text type="secondary">
                                Utilizadores que podem criar eventos desse tipo mesmo que vai sobrepor eventos já
                                agendados.
                            </Typography.Text>
                            <Row className="ant-form-item" style={groupStyle}>
                                {/* <Col className="ant-form-item-label" xs={24} sm={24}>
                                    <label>Utilizadores</label>
                                </Col> */}
                                <Col className="ant-form-item-control-wrapper" xs={24} sm={22}>
                                    <label>Utilizadores</label>
                                    <Input
                                        value={users.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator === ""
                                                    ? currentValue.name
                                                    : accumulator + ";" + currentValue.name,
                                            ""
                                        )}
                                        disabled
                                        style={{ marginBottom: 10 }}
                                    />
                                </Col>
                                {/* <Col className="ant-form-item-label" xs={24} sm={5}>
                                    <label>Grupos funcionais</label>
                                </Col> */}
                                <Col className="ant-form-item-control-wrapper" xs={24} sm={22}>
                                    <label>Grupos funcionais</label>
                                    <Input
                                        value={groups.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator === ""
                                                    ? currentValue.value
                                                    : accumulator + ";" + currentValue.value,
                                            ""
                                        )}
                                        disabled
                                    />
                                </Col>

                                <Col xs={24} sm={2}>
                                    <Icon
                                        onClick={() => setIsVisibleModalSelectUserAndGroup(true)}
                                        type="user-add"
                                        style={{ float: "right", fontSize: "22px" }}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Cor do Evento">
                                {getFieldDecorator("cor")(
                                    <Input
                                        readOnly
                                        style={{
                                            padding: "5px",
                                            background: "#fff",
                                            borderRadius: "1px",
                                            boxShadow: "0 0 0 1px rgba(0,0,0,0.1)",
                                            backgroundColor: `${color.hex}`,
                                            display: "inline-block",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setDisplayColorPicker(true)}
                                    />
                                )}
                                {displayColorPicker ? (
                                    <div style={popover}>
                                        <div style={cover} onClick={() => setDisplayColorPicker(false)} />
                                        <ChromePicker
                                            color={color.rgb}
                                            onChange={(clr) => {
                                                setColor({
                                                    rgb: clr.rgb,
                                                    hex: clr.hex,
                                                });
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

const WrappedModalCreate = Form.create({ name: "register" })(ModalCreate);

export default WrappedModalCreate;
