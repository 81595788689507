import actionType from "../constants/action.type";
import { sendHttpRequest } from "../components/http.request";
import { Session } from "../business";

// get list menu from the server
export const getMenus = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({ type: actionType.GET, payload: jsonResponse });
            return true;
        }
    });
};

// get list of permission from the server
export const getPermissions = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: actionType.GET_ALL_PERMISSIONS,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

// get list of permission from the server
export const getPermissionsByIdMenu = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return await sendHttpRequest("POST", "/Gestor/geremenus.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            // dispatch({
            //     type: actionType.GET_ALL_PERMISSIONS,
            //     payload: jsonResponse
            // });
            return jsonResponse;
        }
    });
};

// set menu to redux
export const setRoutes = (routes) => (dispatch) => dispatch({ type: actionType.SET_ROUTES, payload: routes });

// get remember from the server
export const getRemember = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return await sendHttpRequest("POST", "/Gestor/gerelembretes.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({ type: actionType.GET_LEMBRATE, payload: jsonResponse });
            return true;
        }
    });
};

// get remember from the server
export const getLembreteVisualization = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return await sendHttpRequest("POST", "/Gestor/gerelembretes.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: actionType.GET_LEMBRETES_VISUALIZATION,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

// get all contributors by estabeleciment - unit organic - functional group
export const getContributorsByFuncionalGroup = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.linha === undefined) return false;
        else {
            dispatch({
                type: actionType.GET_CONTRIBUTORS_BY_FUNCIONAL_GROUP,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

// Get count number of unread message, reminder and event
export const getBadgeResume =
    (requestBody = {}) =>
    async (dispatch) => {
        const formData = new FormData();
        Object.entries(requestBody).forEach(([key, value]) => {
            formData.append(key, value);
        });

        formData.append("accao", "resumo");

        return await sendHttpRequest("POST", "/Gestor/geraresumo.php", formData).then((jsonResponse) => {
            dispatch({
                type: actionType.GET_BADGE_RESUME,
                payload: jsonResponse,
            });
            return jsonResponse;
        });
    };

export const getStablishments = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "acessos");
    formData.append("username", Session.getUsername());
    return await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
};

export const backup = async () => {
    const formData = new FormData();
    formData.append("accao", "backup");
    return await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
};

export const changeTheme = (theme, isAllUsers) => async () => {
    const formData = new FormData();
    formData.append("accao", "change_theme");
    formData.append("theme_id", theme);
    formData.append("todos_utilizadores", isAllUsers);
    return sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
};

export const changeThemeProperty =
    ({ themeId, propValue, propName, allUser }) =>
    async () => {
        const formData = new FormData();
        formData.append("accao", "change_theme_property");
        formData.append("prop_name", propName);
        formData.append("prop_value", propValue.substring(1, propValue.length));
        formData.append("themeId", themeId);
        formData.append("todos_utilizadores", allUser);
        return sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
    };

export const getThemeProperties = (theme) => async () => {
    const formData = new FormData();
    formData.append("accao", "get_theme_props");
    formData.append("theme_id", theme);
    return sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
};

export const getAuthorizations = () => async () => {
    const formData = new FormData();
    formData.append("accao", "permissao");
    return sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
};
