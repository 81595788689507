import { actionTypes } from "../constants/index";
import { arrayToObjectById, validateResponse } from "./../../../components/util";

function resetForm() {
    return {
        nome_norma: { name: "nome_norma", value: null },
        id_area_gestao: { name: "id_area_gestao", value: null },
        id_tipo_norma: { name: "id_tipo_norma", value: null },
        requirementKeys: [],
    };
}

const initialState = {
    domain: {
        form: resetForm(),
        byId: {},
        metadata: {
            attributes: {},
            formulario: {},
            pageConfig: {},
        },
    },
    app: {},
};

export const standartsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.selectOrganizationStandart:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: validateResponse(action.payload.dados),
                        id: "id_normas_auditorias",
                    }),
                    metadata: {
                        ...state.domain.metadata,
                        attributes: {
                            ...action.payload.dados["@attributes"],
                        },
                        pageConfig: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };
        case actionTypes.cleanFormStandart:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: resetForm(),
                },
            };
        case actionTypes.getFormulario:
            const formCreate = action.payload.formulario["@attributes"];
            const requisito = action.payload.formulario.normas_auditorias.requisitos_norma["@attributes"];
            return {
                ...state,
                domain: {
                    ...state.domain,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formCreate.nome,
                            elemento_raiz: formCreate.elemento_raiz,
                            formulario_requisito: requisito.popupform,
                        },
                    },
                },
            };

        case actionTypes.setField:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        ...action.payload.fields,
                    },
                },
            };

        case actionTypes.insert:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: resetForm(),
                },
            };

        case actionTypes.getDetails:
            const formulario = action.payload.formulario["@attributes"];
            const values = action.payload.valores.normas_auditorias;

            const standartRequisito = action.payload.formulario.normas_auditorias.requisitos_norma["@attributes"];
            let formDataDetails = {
                id: values.id_normas_auditorias,
                nome_norma: {
                    name: "nome_norma",
                    value: values.nome_norma,
                },
                id_area_gestao: {
                    name: "id_area_gestao",
                    value: typeof values.id_area_gestao === "object" ? null : values.id_area_gestao,
                },
                id_tipo_norma: {
                    name: "id_tipo_norma",
                    value: typeof values.id_tipo_norma === "object" ? null : values.id_tipo_norma,
                },
                requisitos_norma: values.requisitos_norma,
            };

            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: formDataDetails,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            ...formulario,
                            formulario_requisito: standartRequisito.popupform,
                        },
                    },
                },
            };

        default:
            return state;
    }
};
