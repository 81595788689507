import { toArray } from "../components/util";
import { isEmpty } from "./utils";

export const inputValueConvert = (value, inputType) => {
    if (inputType === "boolean") {
        if (value === "true") {
            return "t";
        } else if (value === "false") {
            return "f";
        }
    }

    return value;
};

export const stringToBoolean = (value) => {
    if (value === "t") {
        return true;
    } else if (value === "f") {
        return false;
    }

    return true;
};

export const selectValue = ({ value, dataSource, key }) => {
    dataSource = toArray(dataSource);
    if (dataSource.length === 0) {
        return "";
    }

    const item = dataSource.find((it) => it[key] === value);

    if (item) {
        return value;
    }

    return "";
};

const getDefaultColSize = (type) => {
    if (type === "textArea" || type === "label" || type === "link") {
        return 24;
    }

    return 8;
};

export const getInputColSize = (item, type = "") => {
    if (isEmpty(item?.["@estilo"])) {
        return getDefaultColSize(type);
    }
    const style = item?.["@estilo"];

    if (style.includes("qa_col_")) {
        let col = style.match(/(?<=(qa_col_))[0-9]+/);

        try {
            col = parseInt(col);
            return col;
        } catch (err) {
            return getDefaultColSize(type);
        }
    } else if (style.includes("area_texto") && !style.includes("coluna")) {
        return 24;
    }

    return 8;
};

export const highlightsErrors = (value, isRequired) => {
    if (isEmpty(value) && isRequired) {
        return {
            backgroundColor: "#e78080",
            border: "1px solid #e78080",
        };
    }

    return {};
};

export const getSelectAutoFillField = (attributes) => {
    if (!attributes?.["@quando_selecionar"]) {
        return [];
    }

    const fields = String(attributes?.["@quando_selecionar"])
        .split(";")
        .map((item) => {
            const [dataSourceKey, fieldName] = item.split(",");

            return {
                value: dataSourceKey,
                key: fieldName || dataSourceKey,
            };
        });

    return fields;
};
