import { Utilitaries } from "../../../business";
import { toArray } from "../../util";
import { DEFAULT_COLORS } from "./constants";
import { defaultProps, defineNodeType } from "./elements";

export const getGraphData = (list) => {
    let data = [],
        edges = [];
    let graphHeight = null;
    let graphWidth = null;
    if (list["@attributes"]) {
        graphHeight = Utilitaries.toNumber(list?.["@attributes"]?.height);
        graphWidth = Utilitaries.toNumber(list?.["@attributes"]?.width);
    } else {
        graphHeight = Utilitaries.toNumber(list?.["@height"]);
        graphWidth = Utilitaries.toNumber(list?.["@width"]);
    }

    if (!list) {
        return { nodes: data, edges };
    }

    const nodes = toArray(list?.node);

    // let isFirst = true;

    for (const node of nodes) {
        let attributes = node?.["@attributes"];

        if (node?.["@name"]) {
            attributes = Object.entries(node).reduce((accumulator, current) => {
                const [key, value] = current;

                accumulator[key.replace("@", "")] = value;

                return accumulator;
            }, {});
        }

        const x = Utilitaries.toNumber(attributes.x);
        let y = Utilitaries.toNumber(attributes.y); // + 150;

        // if (!isFirst) {
        //     y = y + 120;
        // } else {
        //     isFirst = false;
        // }

        data.push({
            // id: `${data.length + 1}`,
            id: attributes.name,
            label: attributes.name,
            type: defineNodeType(attributes.tipo),
            x,
            y,
            size: [Utilitaries.toNumber(attributes.width), Utilitaries.toNumber(attributes.height)],
            // attributes.tipo === "inicio" || attributes.tipo === "fim"
            //     ? [49, 49]
            //     : [Utilitaries.toNumber(attributes.width), Utilitaries.toNumber(attributes.height)],
            ...defaultProps(attributes),
        });

        const destinations = Utilitaries.toString(attributes?.destinations).split("; ");

        const _edges = destinations
            .filter((it) => it)
            .map((dest) => {
                const [target, label] = dest.split(":");

                return {
                    label,
                    source: attributes.name,
                    target,
                    // sourceAnchor: 1,
                    // targetAnchor: 0,
                };
            });

        edges.push(..._edges);
    }

    return { data: { nodes: data, edges }, height: graphHeight, width: graphWidth };
};
