import React, { Component } from "react";
import { Row, Table, Form, Select, Button, Input, Popconfirm, message } from "antd";
import { connect } from "react-redux";

import { selectDataFromExecQuery } from "../../actions/contributor";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

class ProgrammedAbsencesTabPane extends Component {
    state = {
        motivos: [],
        dataSource: [],
        countDataSource: 1,
    };
    componentDidMount() {
        const { ficha_colaborador } = this.props;
        if (ficha_colaborador) {
            if (ficha_colaborador.ausencia) {
                if (ficha_colaborador.ausencia.ausencia) {
                    const { ausencia } = ficha_colaborador.ausencia;
                    const dataSource = [];
                    if (Array.isArray(ausencia)) {
                        ausencia.map((item, index) => {
                            dataSource.push({ key: index, ...item });
                        });
                    } else if (ausencia.id_ausencia) {
                        dataSource.push({ key: 0, ausencia });
                    }
                    const countDataSource = dataSource.length + 1;
                    this.setState({ dataSource, countDataSource });
                }
            }
        }
        this.getMotivoFromServer();
    }
    getMotivoFromServer = async () => {
        try {
            const motivos = await this.props.selectDataFromExecQuery({
                query: "lista_motivos_ausencias",
            });
            if (motivos.result !== undefined) {
                this.setState({ motivos: motivos.result });
            }
        } catch (error) {
            message.error("Erro ao carregar motivos!!");
        }
    };
    column = [
        {
            title: "Data início",
            dataIndex: "data_inicio",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(e) =>
                                this.handleFieldsChange(
                                    "data_inicio",
                                    e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    record
                                )
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : null}
                            type="date"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data fim",
            dataIndex: "data_fim",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(e) =>
                                this.handleFieldsChange(
                                    "data_fim",
                                    e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    record
                                )
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : null}
                            type="date"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Hora início",
            dataIndex: "inicio",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm").format("HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) => this.handleFieldsChange("inicio", e.target.value, record)}
                            defaultValue={text ? moment(text).format("HH:mm") : null}
                            format={"HH:mm"}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Hora fim",
            dataIndex: "fim",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm").format("HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) => this.handleFieldsChange("fim", e.target.value, record)}
                            defaultValue={text ? moment(text).format("HH:mm") : null}
                            format={"HH:mm"}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Motivo",
            dataIndex: "motivo_ausencia",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Select
                        showSearch
                        allowClear
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        placeholder="seleciona uma opcao"
                        optionFilterProp="children"
                        defaultValue={text}
                        onChange={(value) => this.handleFieldsChange("motivo_ausencia", value, record)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {this.state.motivos.map((item) => (
                            <Option key={item.motivo_ausencia} value={item.motivo_ausencia}>
                                {item.designacao}
                            </Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Observação",
            dataIndex: "observacoes",
            width: "17%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <TextArea
                            onChange={(e) => this.handleFieldsChange("observacoes", e.target.value, record)}
                            defaultValue={text}
                            placeholder="Introduza observações"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "8%",
            render: (text, record) =>
                this.state.dataSource.length >= 1 ? (
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        onConfirm={() => this.handleDelete(record.key)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    handleFieldsChange = (dataIndex, value, record) => {
        record[dataIndex] = value;
        const { dataSource } = this.state;
        const index = dataSource.findIndex((item) => item.key === record.key);
        dataSource[index] = record;
        this.setState({ dataSource }, this.props.getAusencia(this.state.dataSource));
    };

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState(
            {
                dataSource: dataSource.filter((item) => item.key !== key),
            },
            this.props.getAusencia(this.state.dataSource)
        );
    };

    handleAdd = () => {
        const { countDataSource, dataSource } = this.state;
        const newData = {
            key: countDataSource,
        };
        this.setState({
            dataSource: [newData, ...dataSource],
            countDataSource: countDataSource + 1,
        });
    };

    render() {
        return (
            <div>
                <Row>
                    <Row>
                        <Button
                            xs={24}
                            sm={12}
                            onClick={this.handleAdd}
                            icon="plus"
                            size="default"
                            type="primary"
                            style={{ marginBottom: 16, float: "right" }}
                        >
                            Adicionar
                        </Button>
                    </Row>
                    <Table
                        rowKey={(record) => record.key}
                        size="middle"
                        columns={this.column}
                        dataSource={this.state.dataSource}
                    />
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {
    selectDataFromExecQuery,
};

export default connect(null, mapDispatchToProps)(ProgrammedAbsencesTabPane);
