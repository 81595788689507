import { actionTypes } from "../constants/group";

const intitialState = {
    domain: {
        name: "",
    },
    app: {},
};

export const functionalGroupReducer = (state = intitialState, action) => {
    switch (action.type) {
        case actionTypes:
            return {};

        default:
            return state;
    }
};
