import React, { useState } from "react";
import { Modal } from "antd";
import { changeChatConfModalState } from "./actions/index";
import { connect } from "react-redux";
import ListComponent from "../List/index";

const ChatConf = (props) => {
    return (
        <Modal
            title="Configuração do Chat"
            visible={props.modalChatConfState}
            onOk={() => {
                props.changeChatConfModalState(false);
            }}
            width="60%"
            maskClosable={false}
            onCancel={() => props.changeChatConfModalState(false)}
        >
            <ListComponent listName={props.modalChatListName} onGetList={(list) => {}} />
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        modalChatConfState: state.chat.modalChatConfState || false,
        modalChatListName: state.chat.modalChatListName || "",
    };
};
const mapDispatchToProps = {
    changeChatConfModalState,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatConf);
