import React from "react";
import { Row, Col } from "antd";

import CarouselComponent from "./Carousel";

// import "antd/dist/antd.css"

// import "../Login/styles.css";

const Layout = ({ children, defaultStyle = {} }) => {
    return (
        <Row style={defaultStyle}>
            <Col xs={0} sm={0} lg={12} span={12}>
                <CarouselComponent />
            </Col>
            <Col xs={24} sm={24} lg={12} span={12} className="loginContainer">
                {children}
            </Col>
        </Row>
    );
};

export default Layout;
