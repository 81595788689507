import { sendHttpRequest } from "../../../components/http.request";
import { validateResponse } from "./../../../components/util";

export const FORM_INITIAL_INFO = {
    document: {},
    statusType: [],
    families: [],
    origins: [],
    languages: [],
    users: [],
};

export async function getDocuments(requestBody) {
    try {
        const result = await request("gereformulario", {
            ...requestBody,
            accao: "lista",
        });
        if (result) {
            const metadata = result["@attributes"];
            const lista = validateResponse(result).map((item, index) => ({ ...item, index }));
            return {
                lista,
                metadata: {
                    resultados: Number(metadata.resultados),
                    pagina: Number(metadata.pagina),
                    visivel: metadata.visivel,
                    resultados_pagina: Number(metadata.resultados_pagina),
                },
            };
        }

        return false;
    } catch (error) {
        return false;
    }
}

export async function getDocumentsInitialData(id) {
    try {
        const formInitialData = await request("gereformulario", {
            accao: "definicao",
            id: id ? id : -1,
        });
        const usersList = await request("gereutilizador", {
            accao: "utilizadores",
        });

        return {
            document: formInitialData.documento || {},
            statusType: validateResponse(formInitialData.tipos_estado),
            families: validateResponse(formInitialData.familias),
            origins: validateResponse(formInitialData.origens),
            languages: validateResponse(formInitialData.linguas),
            users: validateResponse(usersList),
        };
    } catch (error) {
        return FORM_INITIAL_INFO;
    }
}

function request(recource, requestBody) {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });

    return sendHttpRequest("POST", `/Gestor/${recource}.php`, formData);
}
