import React, { useState } from "react";
import { Upload, Form, Button } from "antd";
import { downloadFile } from "../../../../components/util";
import { nullSafe, canCorrectField } from "../../utils/fields";

const QaUpload = ({ itemData, label, formItemStyle, onChange, correctForm, currentTaskStatus, readonly }) => {
    //
    const [fileList, setFileList] = useState([]);

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const props = {
        onRemove: (file) => {
            onChange("");
        },
        beforeUpload: (file) => {
            getBase64(file, (img) => {
                onChange(`${img}@@${file.name}`);
            });
            setFileList([file]);

            return false;
        },
        fileList,
    };

    const renderDownloadButton = () => {
        if (itemData.value) {
            return (
                <Button
                    type="link"
                    onClick={(e) => {
                        e.preventDefault();
                        downloadFile("/geredocumentos.php", {
                            accao: "ficheiro",
                            // accao: "documento",
                            // id: itemData.value,
                            ficheiro: itemData.value,
                        });
                    }}
                >
                    Descarregar
                </Button>
            );
        } else {
            return null;
        }
    };

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus || readonly;
    return (
        <Form.Item label={label} style={formItemStyle}>
            {!disabled && (
                <Upload {...props}>
                    <Button icon="upload">Clique para carregar</Button>
                </Upload>
            )}

            {renderDownloadButton()}
        </Form.Item>
    );
};

export default QaUpload;
