import React, { useState, useEffect } from "react";
import {
    Modal,
    Button,
    Form,
    Input,
    message,
    Table,
    Divider,
    Popconfirm,
    Avatar,
    Select,
    Row,
    Col,
    Icon,
    Typography,
} from "antd";
import { ChromePicker } from "react-color";

import WrappedModalCreate from "./create";
import { sendHttpRequest } from "../../http.request";
import ModalSelectUser from "../../select.user";
import { Utilitaries } from "../../../business";
import { toArray } from "../../util";

const EVENTS_TYPE = ["Reunião", "Formação", "Sessões", "Atividades", "Outras"];

const DefinedEvent = (props) => {
    const [modalCreate, setModalCreate] = useState(false);
    const [modalUpdate, setModalUpdate] = useState({});

    const deleteEvent = (item) => {
        const formData = new FormData();
        formData.append("accao", "eliminar");
        formData.append("id", item.id_evento_defeito);
        sendHttpRequest("POST", "/Gestor/gereagendadefinido.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                message.error("Erro ao eliminar eventos");
            } else {
                message.success("Eventos eliminados com sucesso");
                props.definedEvents.get();
            }
        });
    };

    const columns = [
        {
            title: "Designação",
            dataIndex: "designacao",
            key: "designacao",
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            key: "descricao",
        },
        {
            title: "Tipo",
            dataIndex: "tipo",
            key: "tipo",
        },
        // {
        //     title: "Lembrete",
        //     render: (cor, row) =>
        //         `${Utilitaries.toString(row?.avisar_numero)} ${Utilitaries.toString(row?.avisar_tipo)}`,
        // },
        {
            title: "Cor",
            dataIndex: "cor",
            key: "cor",
            render: (cor, _) => <Avatar shape="square" size="small" style={{ backgroundColor: cor }} />,
        },
        {
            title: "Ação",
            key: "acao",
            render: (_, item) => (
                <div>
                    <a
                        href="#!"
                        onClick={(e) => {
                            e.preventDefault();
                            setModalUpdate(item);
                            setModalCreate(true);
                        }}
                    >
                        Atualizar
                    </a>
                    <Divider type="vertical" />
                    <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => deleteEvent(item)}>
                        <a
                            style={{
                                width: "100%",
                                margin: "0 auto",
                                padding: "0",
                            }}
                            href="#!"
                            className="qa-text-del"
                        >
                            Eliminar
                        </a>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <>
            {modalCreate && (
                <WrappedModalCreate
                    visible={modalCreate}
                    update={modalUpdate}
                    onCancel={() => {
                        setModalCreate(false);
                        setModalUpdate({});
                    }}
                    onCreated={() => {
                        props.definedEvents.get();
                        setModalCreate(false);
                    }}
                    selectContributorsProps={props.selectContributorsProps}
                />
            )}

            <Modal
                title="Eventos Definidos"
                visible={props.visible}
                onCancel={props.onCancel}
                footer={null}
                width="60%"
            >
                <Button
                    icon="clock-circle"
                    style={{ marginTop: 8, marginBottom: 8 }}
                    onClick={() => {
                        setModalUpdate({});
                        setModalCreate(true);
                    }}
                >
                    Adicionar Evento Definido
                </Button>

                <Table dataSource={props.definedEvents.list} columns={columns} rowKey="id_evento_defeito" />
            </Modal>
        </>
    );
};

export default DefinedEvent;
