import React, { useState } from "react";
import { Radio, Input, Checkbox, DatePicker, Modal, Table, Pagination, Row, Col } from "antd";
import dataPickerLocale from "antd/es/date-picker/locale/pt_PT";
import moment from "moment";

import { sendHttpRequest } from "../http.request";

const { Search } = Input;
const { RangePicker } = DatePicker;

const columns = (onEditClick) => [
    {
        title: "Título",
        dataIndex: "titulo",
        key: "titulo",
    },
    {
        title: "Data Início",
        dataIndex: "data_ini",
        key: "data_ini",
    },
    {
        title: "Data Fim",
        dataIndex: "data_fi",
        key: "data_fi",
    },
    {
        title: "Tipologia",
        dataIndex: "tipologia_evento",
        key: "tipologia_evento",
    },
    {
        title: "Tipo",
        dataIndex: "tipo",
        key: "tipo",
    },
    {
        title: "Ação",
        key: "acao",
        render: (_, item) => (
            <a
                onClick={(e) => {
                    e.preventDefault();
                    onEditClick(item.id);
                    return false;
                }}
                href="/"
            >
                Alterar
            </a>
        ),
    },
];

const ScheduleSearch = (props) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [events, setEvents] = useState([]);

    const [selectEventoTipologia, setSelectEventoTipologia] = useState("");
    const [eventFilterField, setEventFilterField] = useState(["titulo"]);
    const [filterData, setFilterData] = useState([]);
    const [filterKeyword, setFilterKeyword] = useState([]);

    const [pageInfo, setPageInfo] = useState({});

    const handleSearch = (value) => {
        //
        setFilterKeyword(value);
        if (!visible) {
            setVisible(true);
        }

        getEvents({ filter: value });
    };

    const getEvents = ({ filter = null, page = null, pageSize = null }) => {
        const formData = new FormData();
        formData.append("accao", "pesquisa");
        formData.append("tipologia_evento", selectEventoTipologia);
        formData.append("tipo_agenda", props.calendarType || "");
        formData.append("utilizador_agenda", props.calendarUser || "");

        if (filterKeyword || filter) {
            formData.append("pesquisa", filter || filterKeyword);
            formData.append("titulo", eventFilterField.includes("titulo"));
            formData.append("descricao", eventFilterField.includes("descricao"));
        }

        if (filterData.length > 0) {
            formData.append("datas", true);
            formData.append("inicio", moment(filterData[0]).format("DD-MM-YYYY"));
            formData.append("fim", moment(filterData[1]).format("DD-MM-YYYY"));
        }

        if (page) {
            formData.append("pagina", page);
        }

        if (pageSize) {
            formData.append("resultados_pagina", pageSize);
        }

        setLoading(true);
        sendHttpRequest("POST", "/Gestor/gereagenda.php", formData).then((jsonResponse) => {
            //
            setLoading(false);
            if (jsonResponse.erro) {
            } else {
                if (jsonResponse.linha) {
                    setEvents(Array.isArray(jsonResponse["linha"]) ? jsonResponse["linha"] : [jsonResponse["linha"]]);
                } else {
                    setEvents([]);
                }

                setPageInfo(jsonResponse["@attributes"]);
            }
        });
    };

    const onShowSizeChange = (current, pageSize) => {
        getEvents({ page: current, pageSize });
    };

    const showTotal = (total) => {
        return `Total registos: ${total}`;
    };

    const handlePageChanges = (page, pageSize) => {
        getEvents({ page, pageSize });
    };

    return (
        <>
            <Modal
                destroyOnClose
                width="70%"
                title={"Pesquisa de Eventos"}
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <FormSearch
                    handleSearch={handleSearch}
                    selectEventoTipologia={selectEventoTipologia}
                    setSelectEventoTipologia={setSelectEventoTipologia}
                    eventFilterField={eventFilterField}
                    setEventFilterField={setEventFilterField}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
                <Table
                    rowKey={(item) => item.id}
                    loading={loading}
                    style={{ marginTop: 10 }}
                    size="small"
                    dataSource={events}
                    columns={columns(props.onEditClick)}
                    pagination={false}
                />
                <Row>
                    <Col>
                        <Pagination
                            style={{ marginTop: 16, float: "right" }}
                            size="small"
                            showSizeChanger
                            onShowSizeChange={onShowSizeChange}
                            onChange={handlePageChanges}
                            defaultCurrent={1}
                            defaultPageSize={parseInt(pageInfo.resultados_pagina || 10)}
                            total={parseInt(pageInfo.resultados || 0)}
                            showTotal={showTotal}
                        />
                    </Col>
                </Row>
            </Modal>

            <FormSearch
                handleSearch={handleSearch}
                selectEventoTipologia={selectEventoTipologia}
                setSelectEventoTipologia={setSelectEventoTipologia}
                eventFilterField={eventFilterField}
                setEventFilterField={setEventFilterField}
                filterData={filterData}
                setFilterData={setFilterData}
            />
        </>
    );
};

const FormSearch = (props) => {
    const [filterByData, setFilterByData] = useState(props.filterData.length !== 0);

    const onChange = (e) => {
        const selected = e.target.value;

        props.setSelectEventoTipologia(selected);
    };

    const onChangeSelectField = (checkList) => {
        props.setEventFilterField(checkList);
    };

    const onChangeRangePicker = (date, dateString) => {
        props.setFilterData(date);
    };

    const options = [
        { label: "Título", value: "titulo" },
        { label: "Descrição", value: "descricao" },
    ];

    return (
        <>
            <Search placeholder="Contém ..." onSearch={props.handleSearch} enterButton />

            <Checkbox.Group
                style={{ marginTop: 8 }}
                options={options}
                defaultValue={props.eventFilterField}
                onChange={onChangeSelectField}
            />

            <br />

            <Radio.Group onChange={onChange} defaultValue={props.selectEventoTipologia} style={{ marginTop: 8 }}>
                <Radio value="Pessoal">Pessoal</Radio>
                <Radio value="Profissional">Profissional</Radio>
                <Radio value="">Ambas</Radio>
            </Radio.Group>
            <br />
            <Checkbox
                onChange={(e) => setFilterByData(e.target.checked)}
                defaultChecked={filterByData}
                style={{ marginTop: 8 }}
            >
                Filtrar por data
            </Checkbox>

            {filterByData && (
                <RangePicker
                    locale={dataPickerLocale}
                    style={{ marginTop: 8 }}
                    defaultValue={props.filterData}
                    onChange={onChangeRangePicker}
                />
            )}
        </>
    );
};

export default ScheduleSearch;
