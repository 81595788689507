import React, { useState, useEffect } from "react";
import { Form, Icon, Input, Button, Spin, Typography, Alert } from "antd";
import { Link, useParams } from "react-router-dom";

import { api } from "../../http.request";
import Layout from "../Common/Layout";

const { Title, Text } = Typography;

const ResetPassword = () => {
    // loading -> verificando no api
    // true -> pode
    // false -> não pode
    const [canChange, setCanChange] = useState("loading");

    const { user, token } = useParams();

    useEffect(() => {
        api(`/Gestor/recupera_senha_confirmacao.php?username=${user}&token=${token}`, new FormData())
            .then((resp) => {
                setCanChange(true);
            })
            .catch((error) => {
                setCanChange(false);
            });
    }, [token, user]);

    return canChange === "loading" ? <Loading /> : canChange === true ? <FormResetPass /> : <CanNotChangePass />;
};

const Loading = () => (
    <div style={{ margin: "auto" }}>
        <Spin />
    </div>
);

const CanNotChangePass = () => (
    <div className="reset-body">
        <Title>Não pode alterar a senha</Title>
        <Title level={4}>
            Se solicitou a alteração da senha tenha em conta que o pedido expira ao fim de 48h e só pode ser usada uma
            única vez.
        </Title>
        <Text>Caso tenha feito o pedido há mais tempo deve fazer novamente um pedido de recuperação de senha.</Text>
        <br />
        <Link className="" to="/login">
            Voltar para login
        </Link>
    </div>
);

const PassChangedSuccess = () => (
    <div style={{ marginTop: "auto", marginBottom: "auto", paddingLeft: 40 }}>
        <Title>Senha alterada</Title>
        <Text>A sua senha foi altera com sucesso!</Text>
        <br />
        <Link className="" to="/login">
            Voltar para login
        </Link>
    </div>
);

const ResetPassForm = (props) => {
    const [error, setError] = useState(null);
    const [passwordChanged, setPasswordChanged] = useState(false);

    const { user, token } = useParams();

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        props.form.validateFields(async (err, values) => {
            if (!err) {
                const formData = new FormData();
                formData.append("newPassword", values.newpassword);
                formData.append("newPasswordRepeat", values.newpasswordconfirm);
                try {
                    const resp = await api(
                        `/Gestor/recupera_senha_confirmacao.php?username=${user}&token=${token}`,
                        formData
                    );
                    setPasswordChanged(true);
                } catch (error) {
                    setError(error.erro);
                }
            }
        });
    };

    const compareToFirstPassword = (rule, value, callback) => {
        const { form } = props;
        if (value && value !== form.getFieldValue("newpassword")) {
            callback("Confirmação e senha não conferem.");
        } else {
            callback();
        }
    };

    const { getFieldDecorator } = props.form;

    return passwordChanged ? (
        <Layout>
            <PassChangedSuccess />
        </Layout>
    ) : (
        <Layout>
            <div className="login-body">
                <Title>Definir nova senha</Title>

                <div style={{ width: "300px", marginTop: "20px" }}>
                    {error && <Alert message={error} type="error" banner />}
                </div>

                <Form onSubmit={handleSubmit} className="login-form">
                    <Form.Item label="Nova senha">
                        {getFieldDecorator("newpassword", {
                            rules: [
                                {
                                    required: true,
                                    message: "Informe a nova senha!",
                                },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                                type="password"
                                placeholder="Nova senha"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Repetir a senha">
                        {getFieldDecorator("newpasswordconfirm", {
                            rules: [
                                {
                                    required: true,
                                    message: "Informe a confirmação da senha!",
                                },
                                {
                                    validator: compareToFirstPassword,
                                },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                                type="password"
                                placeholder="Degite novamente a senha"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Definir nova senha
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    );
};

const FormResetPass = Form.create({ name: "normal_reset_pass" })(ResetPassForm);

export default ResetPassword;
