import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Form, Row, Col, Input, message } from "antd";

import { insert, update } from "../../actions/hability";
const { TextArea } = Input;

const FormHabilityType = ({ insert, update, form, showModal, setShowModal, isEdit, setReload, reload, dataToEdit }) => {
    const { getFieldDecorator } = form;

    const [loading, setLoading] = useState(false);

    /**
     * save data to server
     */
    const handleOk = () => {
        form.validateFields(async (err, values) => {
            if (!err) {
                setLoading(true);
                let response = false;
                if (isEdit) {
                    response = await update({
                        accao: "guardar",
                        formulario: "qa_tipo_habilidades.xml",
                        dados: JSON.stringify({
                            tipo_habilidade: {
                                ...values,
                                id_tipo_habilidade: dataToEdit.id_tipo_habilidade,
                                activo: true,
                                versao: dataToEdit.versao || 1,
                            },
                        }),
                    });
                } else {
                    response = await insert({
                        accao: "guardar",
                        formulario: "qa_tipo_habilidades.xml",
                        dados: JSON.stringify({
                            tipo_habilidade: {
                                ...values,
                                activo: true,
                                versao: 1,
                            },
                        }),
                    });
                }

                if (response) message.success("Sucesso");
                else message.error("Erro ao guardar dados");

                setLoading(false);
                setReload(!reload);
                setShowModal(false);
            }
        });
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={isEdit ? "Editar tipo habilidade" : "Novo tipo habilidade"}
                visible={showModal}
                onOk={handleOk}
                onCancel={() => setShowModal(false)}
                width="50%"
                confirmLoading={loading}
            >
                <Form layout="vertical">
                    <Row gutter={18}>
                        <Col xs={24}>
                            <Form.Item label="Designação">
                                {getFieldDecorator("designacao", {
                                    initialValue: dataToEdit.designacao,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Habilidade">
                                {getFieldDecorator("descricao", {
                                    initialValue: dataToEdit.descricao,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<TextArea rows={4} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

const mapDispatchToProps = {
    insert,
    update,
};

const WrappedHability = Form.create({ name: "hability-type" })(FormHabilityType);

export default connect(null, mapDispatchToProps)(WrappedHability);
