import { actionType } from "./../constants/collective.agreement";
import { arrayToObjectById } from "./../../../components/util";
import moment from "moment";
const formatDateBd = "YYYY-MM-DD";

const resetFormCreate = () => ({
    nome: { name: "nome", value: null },
    data: { name: "data", value: null },
    em_vigor: { name: "em_vigor", value: null },
    categoria: [],
});
const resetFormEdit = () => ({
    id_convencao_coletiva: null,
    nome: { name: "nome", value: null },
    data: { name: "data", value: null },
    em_vigor: { name: "em_vigor", value: null },
    categoria: [],
});

const initialState = {
    domain: {
        formCreate: resetFormCreate(),
        formEdit: resetFormEdit(),
        initialDataEdit: {},
        byId: {},
        metadata: {
            attributes: {},
            formulario: {},
        },
    },
    app: {
        isFetched: false,
    },
};

export const collectiveAgreementReducer = (state = initialState, action) => {
    let listCategoria = [];
    let index = 0;

    switch (action.type) {
        case actionType.select:
            let list = [];
            if (Array.isArray(action.payload["linha"])) list = action.payload["linha"];
            else if (action.payload["linha"]) list = [action.payload["linha"]];
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: list,
                        id: "id_convencao_coletiva",
                    }),
                    metadata: {
                        ...state.domain.metadata,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };

        case actionType.insert:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formCreate: resetFormCreate(),
                },
                app: {
                    isFetched: false,
                },
            };

        case actionType.setField:
            if (action.payload.isEdit) {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            ...action.payload.fields,
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            ...action.payload.fields,
                        },
                    },
                };
            }
        case actionType.addCategory:
            listCategoria = action.payload.isEdit
                ? [...state.domain.formEdit.categoria]
                : [...state.domain.formCreate.categoria];

            const length = listCategoria.length;
            const newData = {
                key: length === 0 ? 1 : listCategoria[length - 1].key + 1,
                designacao: null,
                num_escaloes: null,
                anos_transitar_escalao: null,
                level: [],
            };

            if (action.payload.isEdit) {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            categoria: [...state.domain.formEdit.categoria, newData],
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            categoria: [...state.domain.formCreate.categoria, newData],
                        },
                    },
                };
            }

        case actionType.deleteCategory:
            if (action.payload.isEdit) {
                listCategoria = [...state.domain.formEdit.categoria];
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            categoria: listCategoria.filter((item) => item.key !== action.payload.key),
                        },
                    },
                };
            } else {
                listCategoria = [...state.domain.formCreate.categoria];
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            categoria: listCategoria.filter((item) => item.key !== action.payload.key),
                        },
                    },
                };
            }

        case actionType.updateCategory:
            listCategoria = action.payload.isEdit
                ? [...state.domain.formEdit.categoria]
                : [...state.domain.formCreate.categoria];
            index = listCategoria.findIndex((item) => action.payload.record.key === item.key);
            listCategoria[index] = action.payload.record;
            if (action.payload.isEdit) {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            categoria: listCategoria,
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            categoria: listCategoria,
                        },
                    },
                };
            }

        case actionType.getById:
            const values = action.payload.valores.convencao_coletiva;
            const atributosFormulario = action.payload.formulario["@attributes"];
            const formulario = action.payload.formulario;
            if (Array.isArray(values.categorias.categoria)) listCategoria = values.categorias.categoria;
            else if (values.categorias.categoria) listCategoria = [values.categorias.categoria];

            const data = typeof values.data === "object" ? null : moment(values.data, formatDateBd);
            const formData = {
                id_convencao_coletiva: values.id_convencao_coletiva,
                nome: { name: "nome", value: values.nome },
                data: {
                    name: "data",
                    value: data,
                },
                em_vigor: {
                    name: "em_vigor",
                    value: values.em_vigor === "t" ? 1 : 0,
                },
                categoria: listCategoria.map((item, index) => {
                    return {
                        ...item,
                        level: [],
                        key: index + 1,
                        isFetchedNivel: false,
                    };
                }),
            };
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: {
                        ...state.domain.formEdit,
                        ...formData,
                    },
                    initialDataEdit: action.payload.valores,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: atributosFormulario.nome,
                            elemento_raiz: atributosFormulario.elemento_raiz,
                            formulario_nivel:
                                formulario.convencao_coletiva.categoria.lista_niveis_categoria["@attributes"].popupform,
                            //      formulario_nivel:
                            // formulario.convencao_coletiva.categorias.categoria.lista_niveis_categoria["@attributes"]
                            //     .popupform,
                        },
                    },
                },
            };

        case actionType.getNiveis:
            const valuesNivel =
                action.payload.jsonResponse.valores.lista_niveis_categoria.niveis_categoria.nivel_categoria;
            const formularioNivel = action.payload.jsonResponse.formulario["@attributes"];
            let listNiveis = [];
            if (Array.isArray(valuesNivel)) listNiveis = valuesNivel;
            else if (valuesNivel) listNiveis = [valuesNivel];

            listNiveis = listNiveis.map((item, index) => ({
                ...item,
                key: index + 1,
            }));

            const categorias = [...state.domain.formEdit.categoria];
            const indexCategory = categorias.findIndex((item) => action.payload.record.key === item.key);
            categorias[indexCategory] = {
                ...action.payload.record,
                level: listNiveis,
                isFetchedNivel: true,
            };
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: {
                        ...state.domain.formEdit,
                        categoria: categorias,
                    },
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            ...state.domain.metadata.formulario,
                            elemento_raiz_nivel: formularioNivel.elemento_raiz,
                        },
                    },
                },
            };

        case actionType.saveNiveis:
            listCategoria = [...state.domain.formEdit.categoria];

            index = listCategoria.findIndex((item) => item.id_categoria === action.payload.values.id_categoria);
            listCategoria[index].num_niveis = action.payload.values.level.length;
            listCategoria[index].isFetchedNivel = false;
            listCategoria[index].level = [];
            listCategoria[index].lista_niveis_categoria = action.payload.values.lista_niveis_categoria;

            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: {
                        ...state.domain.formEdit,
                        categoria: listCategoria,
                    },
                    initialDataEdit: action.payload.initialDataEdit,
                },
            };

        case action.update:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: resetFormEdit(),
                },
            };

        default:
            return state;
    }
};
