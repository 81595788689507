import { message, PageHeader, Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as acDomain from "../../../fixed_list/actions/index";
import {
    updateProcessDefinition,
    setTaskAuthorizations,
    setTaskDescription,
    setAttributionType,
    getGroupsToDelegate,
    getUserByGroup,
    removeGoalIndicator,
    setGroupToDelegateProcessDef,
    addGoalIndicator,
    setUserToDelegateProcessDef,
    setPolicyGoalIndicator,
} from "../../actions/details";
import { getPolitics } from "../../../politics/actions/index";
import TaskTable from "../../../process_execution/components/execution/tasks.table";
import { getProcessDetails } from "../../actions/index";
import { componentType } from "../../constants/index";
import ProcessForm from "../form";
import AssigmentTasks from "./assignment.tasks";
import ButtonsGroup from "./buttons.group";
// import FlowChart from "./flowChart";
import GoalsIndicators from "./goals.indicators";
// import FlowChart from "../../../process_execution/components/execution/flowchart";
import FlowChart from "../../../../components/FlowChart";

import "./style.css";
import { toArray } from "../../../../components/util";
import { Utilitaries } from "../../../../business";

const { TabPane } = Tabs;

class ProcessDetails extends React.Component {
    processId = this.props.match.params.id;
    state = {
        activeKey: "process",
        isEditionDisabled: true,
        isFormValid: {
            process: true,
            taskDescription: true,
            taskAssignment: true,
            goalsIndicators: true,
        },
        organicUnits: [],
        categories: [],
        processosAssociados: [],
        processos: [],
        groupsToDelegate: [],
        usersByGroupToDelegate: [],
        fluxograma: {},
    };

    async componentDidMount() {
        await this.props.getProcessDetails(this.processId).then((data) => {
            const {
                autorizacoes,
                tarefas,
                processos,
                objectivos_processo,
                departamentos,
                fluxograma,
                categorias,
                processos_associados,
            } = data;
            const tasks = autorizacoes?.tarefa;
            const policiesGoalsIndicators = objectivos_processo?.linha;

            const task_authorizations = Utilitaries.toArray(tasks);

            task_authorizations.forEach((task_authorization) => {
                const [, task_id, task_auth] = /(\w+):(.*)/.exec(task_authorization);

                const authsPermissions = task_auth.split(";").filter((item) => item);

                for (const authPermissions of authsPermissions) {
                    const [authId, ...permissions] = authPermissions.split(":");
                    this.props.setTaskAuthorizations({
                        authId,
                        taskId: task_id,
                        exec: permissions.includes("exec"),
                        read: permissions.includes("read"),
                        print: permissions.includes("print"),
                    });
                }
            });

            // if (tasks) {
            //     if (Array.isArray(tasks)) {
            //         tasks.forEach((authTask) => {
            //             const authsPermissions = authTask.split(";").filter((item) => item);
            //             for (const authPermissions of authsPermissions) {
            //                 if (!authPermissions) {
            //                     continue;
            //                 }
            //                 const [taskAuth, authId, ...permissions] = authPermissions.split(":");
            //                 // const [exec, read, print] = permissions;
            //                 // const taskId = tarefas?.[index]?.id;
            //                 this.props.setTaskAuthorizations({
            //                     authId,
            //                     taskId: taskAuth,
            //                     // exec: !!exec,
            //                     // read: !!read,
            //                     // print: !!print,
            //                     exec: permissions.includes("exec"),
            //                     read: permissions.includes("read"),
            //                     print: permissions.includes("print"),
            //                 });
            //             }
            //         });
            //     } else {
            //         const authsPermissions = tasks.split(";").filter((item) => item);
            //         for (const authPermissions of authsPermissions) {
            //             if (!authPermissions) {
            //                 continue;
            //             }
            //             const [taskAuth, authId, ...permissions] = authPermissions.split(":");
            //             // const [exec, read, print] = permissions;
            //             // const taskId = tarefas?.[index]?.id;
            //             this.props.setTaskAuthorizations({
            //                 authId,
            //                 taskId: taskAuth,
            //                 exec: permissions.includes("exec"),
            //                 read: permissions.includes("read"),
            //                 print: permissions.includes("print"),
            //             });
            //         }
            //     }
            // }

            if (Array.isArray(policiesGoalsIndicators)) {
                for (const [index, item] of policiesGoalsIndicators.entries()) {
                    for (const name in item) {
                        this.props.setPolicyGoalIndicator({ name: `id_${name}`, value: item[name], key: index });
                    }
                }
            } else {
                for (const name in policiesGoalsIndicators) {
                    this.props.setPolicyGoalIndicator({
                        name: `id_${name}`,
                        value: policiesGoalsIndicators[name],
                        key: 1,
                    });
                }
            }

            this.setState({
                processos: toArray(processos?.linha),
                organicUnits: departamentos?.linha,
                categories: categorias?.linha,
                processosAssociados: toArray(processos_associados?.linha).map((it) => it.nome),
                fluxograma: fluxograma,
            });
        });

        await this.props.getGroupsToDelegate().then((data) => {
            this.setState({ groupsToDelegate: data.grupos.linha });
        });

        if (!this.props.politicsState.app.isPoliticsFetched) {
            this.props.getPolitics();
        }
    }

    render() {
        const AssigmentTasksData =
            process.tasks !== undefined
                ? Object.values(process.tasks)
                : Object.values(this.props.processState.domain.form.tasks);

        return (
            <div>
                <PageHeader
                    onBack={() => {
                        this.props.history.goBack();
                        // this.props.history.push({
                        //     pathname: "/process/definition/index",
                        // })
                    }}
                    title={"Definição do processo: " + this.props.processState?.domain?.form?.nome?.value}
                />
                <ButtonsGroup
                    isEditionDisabled={this.state.isEditionDisabled}
                    goTo={this.goTo.bind(this)}
                    toggleEdition={this.toggleEdition.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                />

                {this.props.processState?.domain?.form?.forma_iniciar?.value === "pop_social_mask" ? (
                    <ProcessForm
                        type="edit"
                        organicUnits={this.state.organicUnits}
                        categories={this.state.categories}
                        processosAssociados={this.state.processosAssociados}
                        processos={this.state.processos}
                        isDisabled={this.state.isEditionDisabled}
                        handleFormValidation={this.handleFormValidation.bind(this)}
                    />
                ) : (
                    <>
                        <Tabs
                            defaultActiveKey={this.state.activeKey}
                            activeKey={this.state.activeKey}
                            onChange={this.handleTabChange}
                        >
                            <TabPane tab="Processo" key="process">
                                <ProcessForm
                                    type="edit"
                                    organicUnits={this.state.organicUnits}
                                    categories={this.state.categories}
                                    processosAssociados={this.state.processosAssociados}
                                    processos={this.state.processos}
                                    isDisabled={this.state.isEditionDisabled}
                                    handleFormValidation={this.handleFormValidation.bind(this)}
                                />
                            </TabPane>

                            <TabPane tab="Fluxograma" key="flowchart">
                                {/* <FlowChart components={flowchartComponents} x={500} y={30} export /> */}
                                <FlowChart processFlowChart={this.state.fluxograma} />
                            </TabPane>
                            <TabPane tab="Descrição Tarefas" key="taskDescription">
                                <TaskTable
                                    type="description"
                                    isDisabled={this.state.isEditionDisabled}
                                    data={
                                        process.tasks !== undefined
                                            ? Object.values(process.tasks)
                                            : Object.values(this.props.processState.domain.form.tasks)
                                    }
                                    setTaskDescription={this.props.setTaskDescription}
                                    handleFormValidation={this.handleFormValidation.bind(this)}
                                />
                            </TabPane>
                            <TabPane tab="Atribuição Tarefas" key="taskAssignment">
                                <AssigmentTasks
                                    isDisabled={this.state.isEditionDisabled}
                                    data={AssigmentTasksData}
                                    processState={this.props.processState}
                                    groupsToDelegate={this.state.groupsToDelegate}
                                    usersByGroupToDelegate={this.state.usersByGroupToDelegate}
                                    setUserToDelegateProcessDef={this.props.setUserToDelegateProcessDef}
                                    handleSetUsersByGroup={this.handleSetUsersByGroup.bind(this)}
                                    tasksAuthorizations={Object.values(this.props.processState.domain.form.tasks)}
                                    setAttributionType={this.props.setAttributionType}
                                    handleFormValidation={this.handleFormValidation.bind(this)}
                                />
                            </TabPane>
                            <TabPane tab="Objectivos e Indicadores" key="goalsIndicators">
                                <GoalsIndicators
                                    isDisabled={this.state.isEditionDisabled}
                                    addGoalIndicator={this.props.addGoalIndicator}
                                    handleFormValidation={this.handleFormValidation.bind(this)}
                                    removeGoalIndicator={this.props.removeGoalIndicator}
                                    setPolicyGoalIndicator={this.props.setPolicyGoalIndicator}
                                    policyGoalsIndicators={this.props.processState.domain.form.goalsIndicators}
                                />
                            </TabPane>
                        </Tabs>
                    </>
                )}
                {/* </Tabs> */}
            </div>
        );
    }

    handleTabChange = (key) => {
        this.setState({ activeKey: key });
    };

    handleSetUsersByGroup = ({ group, task }) => {
        this.props.setGroupToDelegateProcessDef({ rowIndex: task, value: group });
        this.props.getUserByGroup(group).then((data) => {
            this.setState({ usersByGroupToDelegate: { [task]: Utilitaries.toArray(data?.linha) } });
        });
    };

    handleFormValidation = (formTab, isValid) => {
        this.setState({
            isFormValid: { ...this.state.isFormValid, [formTab]: isValid },
        });
    };

    handleUpdate = async (e) => {
        let allValid = true;
        debugger;
        const allForms = this.state.isFormValid;
        for (const key in allForms) {
            if (allForms[key] === false) {
                allValid = false;
                continue;
            }
        }
        try {
            if (allValid)
                try {
                    const result = await this.props.updateProcessDefinition();

                    if (result !== undefined) {
                        this.toggleEdition();
                        message.success("Sucesso");
                    }
                    return result;
                } catch (error) {
                    message.error("Ocorreu um erro");
                }
            else {
                for (const key in allForms) {
                    if (allForms[key] === false) {
                        this.setState({ activeKey: key });
                    }
                }
            }
        } catch (error) {
            message.error("Ocorreu um erro");
        }
    };

    toggleEdition = () =>
        this.setState({
            isEditionDisabled: !this.state.isEditionDisabled,
        });

    goTo = (param) => this.props.history.push(param + this.processId);
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProcessDetails,
            addGoalIndicator,
            getPolitics,
            getGroupsToDelegate,
            getUserByGroup,
            setTaskAuthorizations,
            setGroupToDelegateProcessDef,
            removeGoalIndicator,
            setUserToDelegateProcessDef,
            setTaskDescription,
            setAttributionType,
            setPolicyGoalIndicator,
            updateProcessDefinition,
            ...acDomain,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        processState: state.processDefinition,
        politicsState: state.politics,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessDetails);
