import jsPDF from "jspdf";
import "jspdf-autotable";
import { imageLogoBase64Codification } from "../../../../constants/index";

const singleHeadRows = () => {
    return [["Norma", "Área de gestão"]];
};

export const createPdfStandartList = (body_rows) => {
    const color = {
        white: "#ffffff",
        textPrimary: "#000000",
        textSecondary: "#646464",
        grey: "#7a7a7a",
    };
    const doc = new jsPDF();
    const totalPagesExp = "{total_pages_count_string}";

    /** render table */
    const keys = Object.keys(body_rows);

    let columnStyle = {
        0: { cellWidth: 100 },
        1: { cellWidth: 80 },
    };

    // body_rows[item].forEach(element => {
    //     if (keys.length === 1) delete element.username;
    //     const row = Object.values(element);
    //     const [username, ...restOf] = row;
    //     a = username;
    //     if (keys.length === 1) i.push(row);
    //     else i.push(restOf);
    // });
    // const [first, ...others] = i;
    // body_arr.push(
    //     [
    //         {
    //             rowSpan: body_rows[item].length + 1,
    //             content: "a",
    //             styles: { valign: "middle", halign: "center" }
    //         },
    //         ...first
    //     ],
    //     ...others
    // );
    //
    doc.autoTable({
        columnStyles: columnStyle,
        styles: {
            lineColor: color.textPrimary,
            lineWidth: 0.25,
        },
        theme: "plain",
        head: singleHeadRows(),
        body: body_rows,
        didDrawPage: (data) => {
            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

            // Header
            doc.setFontSize(11);
            // doc.setTextColor(40);
            doc.setFontStyle("normal");
            if (imageLogoBase64Codification) {
                doc.addImage(imageLogoBase64Codification, "PNG", data.settings.margin.left, 15);
            }
            // doc.text("Report", data.settings.margin.left + 15, 22);
            doc.setFontStyle("bold");
            doc.text("< Nome Instituição >", data.settings.margin.left, 32);

            /** draw grey rectangle */
            doc.setFillColor(color.grey);
            doc.rect(data.settings.margin.left, 34, pageWidth - 28, 8, "F");
            doc.setTextColor(color.white);
            doc.setFontStyle("bold");
            doc.text("NORMAS DA ORGANIZAÇÃO", data.settings.margin.left + 1, 39);

            /** set contributor name and date */
            doc.setFontSize(8);
            doc.setTextColor(color.textPrimary);
            doc.setFontStyle("bold");

            doc.setTextColor(color.textPrimary);
            doc.setFontStyle("bold");
            // doc.text(
            //     "Período: ",
            //     pageWidth -
            //         doc.getTextWidth(
            //             `Período: ${interval.startDate} a ${interval.endDate}`
            //         ) -
            //         data.settings.margin.left,
            //     48
            // );
            doc.setTextColor(color.textSecondary);
            // doc.text(
            //     `${interval.startDate} a ${interval.endDate}`,
            //     pageWidth -
            //         doc.getTextWidth(
            //             `${interval.startDate} a ${interval.endDate}`
            //         ) -
            //         data.settings.margin.left,
            //     48
            // );

            // Footer
            let str = "Página " + doc.internal.getNumberOfPages();
            // Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === "function") {
                str = str + " de " + totalPagesExp;
            }
            doc.setFontStyle("bold");
            doc.setFontSize(6);
            doc.line(
                data.settings.margin.left,
                pageHeight - 21,
                pageWidth - data.settings.margin.left,
                pageHeight - 21
            );
            doc.text("QUA_GES_MFC_RPT_001   NORMAS DA ORGANIZAÇÃO", data.settings.margin.left, pageHeight - 17);
            doc.addImage(imageLogoBase64Codification, "PNG", pageWidth / 2 - 15, pageHeight - 20, 30, 4);
            /** get current date and time */
            const today = new Date();
            const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
            doc.text(
                `Produzido: ${date}`,
                pageWidth - doc.getTextWidth(`Produzido: ${date}`) - data.settings.margin.left,
                pageHeight - 17
            );
            doc.line(
                data.settings.margin.left,
                pageHeight - 15,
                pageWidth - data.settings.margin.left,
                pageHeight - 15
            );

            doc.text(str, pageWidth - 30, pageHeight - 10);
        },
        margin: { top: 50, bottom: 30 },
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
    }

    // return doc;

    //   doc.addImage(imageLogoBase64Codification, "PNG", 15, 15);
    //   doc.setFontSize(11);
    //   doc.text("Quality Alive", 15, 32);
    //   doc.text("QUA_GES_MFC_RPT_001   REGISTO DE PONTO- detalhado ", 15, 38, );

    const base64string = doc.output("datauri");
    const win = window.open();
    win.document.write(
        '<iframe src="' +
            base64string +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
    // doc.autoTable(columns, rows);
    // doc.save("table.pdf");
};
