import ACTIONS_TYPES from "../constants/index";
import { sendHttpRequest } from "../../http.request";
import moment from "moment";

// To Toggle chat modal state
export const changeChatModalState = (state) => async (dispatch) =>
    dispatch({
        type: ACTIONS_TYPES.CHANGE_MODAL_CHAT_STATE,
        payload: state,
    });

export const setFilter = (state) => async (dispatch) => {
    if (state) {
        let start = "",
            end = "";
        if (state.data) {
            start = moment(state.data[0]).format("YYYY-MM-DD");
            end = moment(state.data[1]).format("YYYY-MM-DD");
        }
        dispatch({
            type: ACTIONS_TYPES.SET_FILTER,
            payload: {
                ...state,
                username: state.username || "",
                search: state.search || "",
                start,
                end,
            },
        });
    } else {
        dispatch({
            type: ACTIONS_TYPES.SET_FILTER,
            payload: null,
        });
    }
};

// Toggle chat config modal state
export const changeChatConfModalState = (state, list = null) => async (dispatch) =>
    dispatch({
        type: ACTIONS_TYPES.CHANGE_MODAL_CHAT_CONF_STATE,
        payload: { state, list },
    });

export const setSelectedRoom = (id) => async (dispatch) =>
    dispatch({
        type: ACTIONS_TYPES.SET_SELECTED_ROOM,
        payload: id,
    });

// Get chat salas
export const getRooms = () => async (dispatch) => {
    const formData = new FormData();
    // Object.entries(requestBody).forEach(([key, value]) => {
    //     formData.append(key, value);
    // });

    formData.append("accao", "get_salas_chat");

    return await sendHttpRequest("POST", "/Gestor/gerestreaming.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.ROOMS,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const getMessages = () => async (dispatch, getState) => {
    const { selected, filter } = getState().chat.room;
    const formData = new FormData();

    formData.append("accao", "get");
    formData.append("sala", selected);
    if (filter) {
        Object.entries(filter).forEach(([key, value]) => {
            if (value) formData.append(key, value);
        });
    }

    return await sendHttpRequest("POST", "/Gestor/gerestreaming.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.MESSAGES,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const getUsers = () => async (dispatch, getState) => {
    const formData = new FormData();
    // Object.entries(requestBody).forEach(([key, value]) => {
    //     formData.append(key, value);
    // });

    formData.append("accao", "get-users");
    formData.append("sala", getState().chat.room.selected);

    return await sendHttpRequest("POST", "/Gestor/gerestreaming.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.GET_USERS,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const sendMessage = ({ message, fileList, type }) => async (dispatch, getState) => {
    const formData = new FormData();

    formData.append("accao", "send");
    formData.append("message", message || "");
    formData.append("level", type || "");
    fileList = fileList || [];
    fileList.forEach((file) => {
        formData.append("files[]", file);
    });
    formData.append("sala", getState().chat.room.selected);

    return await sendHttpRequest("POST", "/Gestor/gerestreaming.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.ADD_MESSAGES,
                payload: jsonResponse,
            });
            return true;
        }
    });
};
