import React, { useEffect, useState } from "react";
import { Button, Row, Col, Tooltip, Modal, Input, Icon } from "antd";
import { connect } from "react-redux";

import { QATable } from "../../components/qa_custom/index";
import { getMenus } from "./actions/index";
import MenuEditor from "./components/Editor";
import { downloadFile } from "../../components/util";

const Menu = ({ menus, getMenus }) => {
    const [modelForm, setModelForm] = useState(null);
    const [filter, setfilter] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });
    const [title, setTitle] = useState(null);
    const [visibility, setVisisbility] = useState(false);

    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getMenus(pagination, filter);
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, filter, reload]);

    const exportList = async (type) => {
        const REPORT_PARAMS = {
            accao: "lista",
            campos: "titulo:24:Título;descricao:24:Descrição;pai:24:Menu pai",
            titulo: "Menus",
        };

        await downloadFile("geremenus.php", {
            ...REPORT_PARAMS,
            ...filter,
            saida: type,
            downloadInBrowser: true,
        });
    };

    //---------------------------- filter --------------------------------

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val[0]) obj["filtro_" + key] = val[0];
            });
        }
        if (sorter.columnKey) {
            const value = sorter.order === "ascend" ? "ASC" : "DESC";
            obj["ordenacao_" + sorter.columnKey] = value;
        }

        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter(obj);
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const getColumnSearchProps = () => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        };
    };
    //---------------------------- endfilter -----------------------------

    const onShowClick = (record) => {
        if (record) {
            setTitle("Editar menu");
            setModelForm(record);
            setIsEdit(true);
        } else {
            setTitle("Novo menu");
            setModelForm({});
            setIsEdit(false);
        }
        setVisisbility(true);
    };

    const onCloseEditor = () => {
        setVisisbility(false);
    };

    // Apagar um item na tabela
    const onDeleteItem = (record) => {
        // const fd = new FormData();
        // fd.append("accao", "eliminar");
        // fd.append("lista", listName);
        // fd.append("chave1", record[key]);
        // sendHttpRequest("POST", "/Gestor/gerelistas.php", fd).then(result => {
        //
        //     updateList();
        // });
        //
    };

    const updateList = () => {
        // const formData = new FormData();
        // formData.append("accao", "lista");
        // formData.append("lista", listName);
        // formData.append("pagina", pagination.page);
        // formData.append("resultados_pagina", pagination.perPage);
        // Object.entries(filter).forEach(([key, val]) => {
        //     formData.append(key, val);
        // });
        // setLoadingData(true);
        // sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then(
        //     data => {
        //         setLoadingData(false);
        //         setList(data);
        //     }
        // );
    };

    const columns = [
        {
            title: "Título",
            dataIndex: "titulo",
            key: "titulo",
            ...getColumnSearchProps("titulo"),
            sorter: true,
            ellipsis: true,
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            key: "descricao",
            ...getColumnSearchProps("descricao"),
            sorter: true,
            ellipsis: true,
        },
        {
            title: "Menu Pai",
            dataIndex: "pai",
            key: "pai",
            ...getColumnSearchProps("pai"),
            sorter: true,
            ellipsis: true,
        },
        {
            title: "Ação",
            render: (_, record) => {
                return (
                    <>
                        <Button
                            type="link"
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}
                            onClick={() => onShowClick(record)}
                        >
                            Consultar
                        </Button>

                        {/* <Button
                            type="link"
                            style={{
                                paddingLeft: 6,
                                color: "#fa541c"
                            }}
                            onClick={() => onDeleteItem(record)}
                        >
                            Eliminar
                        </Button> */}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <MenuEditor
                isEdit={isEdit}
                title={title}
                visibility={visibility}
                onCloseEditor={onCloseEditor}
                modelForm={modelForm}
                setReload={setReload}
                reload={reload}
                // onOk={onOk}
                // onCancel={onCancel}
            />

            <h3>Listagem de Menus</h3>
            <Row>
                <Col span={16}>
                    {/* <Button
                        type="primary"
                        title={"Adicionar Sala de Chat"}
                        icon="plus"
                        onClick={() => onShowClick(null)}
                    >
                        Inserir
                    </Button> */}
                </Col>
                <Col span={8}>
                    <Tooltip title="Imprimir PDF">
                        <Button
                            onClick={() => exportList("pdf")}
                            style={{
                                float: "right",
                                color: "#fa541c",
                                marginLeft: 8,
                            }}
                            icon="file-pdf"
                        />
                    </Tooltip>
                    <Tooltip title="Imprimir Excel">
                        <Button
                            onClick={() => exportList("xls")}
                            style={{
                                float: "right",
                                color: "#237804",
                            }}
                            icon="file-excel"
                        />
                    </Tooltip>
                </Col>
                <Col span={24}>
                    <QATable
                        columns={columns}
                        data={menus.list}
                        loadingData={loading}
                        datakey={"idmenu"}
                        currentPage={menus.query ? parseInt(menus.query.pagina) : 1}
                        totalResult={menus.query ? parseInt(menus.query.resultados) : 1}
                        pageChange={(page, perPage) => {
                            setPagination({
                                page,
                                perPage,
                            });
                        }}
                        tableChange={handleTableChange}
                    />
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        menus: state.menus.menus,
    };
};
const mapDispatchToProps = {
    getMenus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
