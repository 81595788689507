import { actionType } from "./../constants/contributor";
import { arrayToObjectById } from "./../../../components/util";

const resetFormCreate = () => ({});
const resetFormEdit = () => ({
    id: null,
});

const initialState = {
    domain: {
        formCreate: resetFormCreate(),
        formEdit: resetFormEdit(),
        byId: {},
        metadata: {
            attributes: {},
            // formulario: {}
        },
    },
    app: {
        isFetched: false,
    },
};

export const contributorReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.select:
            let list = [];

            if (action?.payload?.["linha"] && Array.isArray(action?.payload?.["linha"])) {
                list = [...action?.payload?.["linha"]];
            } else if (action?.payload?.["linha"]?.id_ficha_colaborador) {
                list = [action?.payload?.["linha"]];
            }

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: list,
                        id: "id_ficha_colaborador",
                    }),
                    metadata: {
                        ...state.domain.metadata,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };

        case actionType.insert:
            const keys = Object.keys(state.domain.byId);
            const key = keys[keys.length - 1] + 1;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [key]: {
                            id: key,
                            designation: state.domain.form.designation.value,
                            key,
                            description: state.domain.form.description.value,
                        },
                    },
                },
            };

        case actionType.setField:
            if (typeof action.payload.id === "undefined") {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        form: {
                            ...state.domain.form,
                            ...action.payload,
                        },
                    },
                };
            }
        default:
            return state;
    }
};
