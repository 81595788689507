import React from "react";
import { Tabs, PageHeader } from "antd";

import Hability from "./hability";
import HabilityType from "./hability.type";

const { TabPane } = Tabs;

const HabilityIndex = () => {
    const goto = (path) => {
        this.props.history.push({
            pathname: path,
        });
    };

    return (
        <>
            <PageHeader title="Lista Habilidades" />
            <Tabs defaultActiveKey="1">
                <TabPane tab="Tipos de habilidades" key="1">
                    <HabilityType />
                </TabPane>
                <TabPane tab="Habilidades" key="2">
                    <Hability />
                </TabPane>
            </Tabs>
        </>
    );
};
export default HabilityIndex;
