import { message } from "antd";
import { sendHttpRequest } from "../../../components/http.request";
import { toArray } from "../../../components/util";

export const inputHeaders = ["processo", "cliente_nome", "valencia_designacao", "actividade", "estabelecimento_nome"];

export const isHeaderInput = (item) => {
    const isHeader = inputHeaders.includes(item?.key);
    //;

    return isHeader;
};

export const validateFormAfterSave = async ({ name, form, params, formAttributes, context }) => {
    try {
        if (name === "qualia_ds23_01_convocatoria_reuniao_part_pop.qml.xml") {
            const participantesList = toArray(Object.values(form?.participantes?.dataSource || {}));

            const convocados = participantesList.filter((it) => it?.convocar === "t");

            const formData = new FormData();
            formData.append("accao", "validar_evento");

            formData.append("data_inicio", `${params?.data_inicio} ${params?.hora_inicio}`);
            formData.append("data_fim", `${params.data_fim} ${params?.hora_fim}`);

            formData.append(
                "utilizadores",
                convocados.reduce(
                    (accumulator, currentValue) =>
                        accumulator === "" ? currentValue.id_entidade : accumulator + ";" + currentValue.id_entidade,
                    ""
                )
            );

            const jsonResponse = await sendHttpRequest("POST", "/Gestor/gereagenda.php", formData);
            if (jsonResponse.erro) {
                message.error(jsonResponse.erro);
                return null;
            } else if (jsonResponse.users) {
                return {
                    users: jsonResponse.users,
                    events: jsonResponse.events,
                };
            } else {
                return null;
            }
        } else {
            const validateOnSave = formAttributes?.rootElementProps?.["@warning_on_save"];
            if (validateOnSave) {
                const { params, dataSource } = context.urlAndParamsSplit(validateOnSave);

                const paramsTransformed = context.transformParams({
                    params,
                    formData: form,
                    // root: item?.root,
                    // rowKey: record?.key,
                    // allParams,
                    // inputGroupPath: item?.inputGroupPath,
                });

                // const allParams = context.modalParams;

                const dispatch = context.dispatch;

                const response = await dispatch(
                    context.getFieldDataSource({
                        dataSource,
                        hack: true,
                        params: {
                            ...paramsTransformed,
                            idProcesso: form?.processo?.value,
                        },
                    })
                );

                if (response) {
                    if (response.erro) {
                        message.error(response.erro);
                        return null;
                    } else if (response.result && Array.isArray(response.result)) {
                        const hasWarning = response.result.find((el) => el.warning === "t");
                        if (hasWarning) {
                            return {
                                warnings: response.result
                                    .filter((f) => f.warning === "t")
                                    .map((el) => el.error_message),
                            };
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                }
            }

            return null;
        }
    } catch (error) {
        return null;
    }
};

export const formsToGetAoCarregarSameItSaved = (formName) => {
    const formList = ["qualia_dr47.03_PI.qml.xml", "qualia_gerir_pertences_pop.qml.xml"];

    return formList.includes(formName);
};
