import React, { useEffect } from "react";
import { Input } from "antd";

const { TextArea } = Input;

const ComponentAction = (props) => {
    useEffect(() => {
        props.configModal({ title: "Acção a definir" });
    });

    return (
        <>
            <TextArea rows={20} defaultValue={props.data.name} />
        </>
    );
};

export default ComponentAction;
