import React from "react";
import { Row, Col, Card, Empty } from "antd";
import { dashboardKey } from "./../constants/index";
import PizzaChart from "./pizzaChart";
import HorizontalBarChart from "./horizontalBarChart";
import MultipleBarChart from "./multipleBarChart";
import MultipleLineChart from "./multipleLineChart";
import SpeedomerChart from "./speedometerChart";
import MultipleSpeedometer from "./multipleSpeedometer";
import Widgets from "./widgets";
import { TableChart } from "./tableChart";
import { NestedTableChart } from "./nestedTable";

const getData = (props, data) => {
    const [firstElement, ...remaining] = props;
    const dataDashboard = data[firstElement];
    if (!dataDashboard) return null;
    else if (props.length === 1) return dataDashboard;
    else return getData(remaining, dataDashboard);
};

const RenderDashboard = ({ metadata, dataSource }) => {
    if (!metadata) {
        return (
            <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col xs={24} md={24}>
                    <Card size="small">
                        <Empty description={"Sem dados"} />
                    </Card>
                </Col>
            </Row>
        );
    }
    return (
        <>
            <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                {!metadata.elements || metadata.elements.length === 0 ? (
                    <Col xs={24} md={24}>
                        <Card size="small">
                            <Empty description={"Sem elementos para apresentar"} />
                        </Card>
                    </Col>
                ) : (
                    metadata.elements.map((item) => {
                        const data =
                            item.data && item.data.length !== 0 && dataSource ? getData(item.data, dataSource) : null;

                        // if (Array.isArray(data) && !data.length) {
                        //     return <></>;
                        // }
                        // let shouldNotRenderChart = true;
                        // let haveArrayChild = false;
                        // if (data && Array.isArray(data)) {
                        //     data.forEach((it) => {
                        //         if (it?.data && Array.isArray(it?.data) && it.data.length) {
                        //             shouldNotRenderChart = false;
                        //             haveArrayChild = true;
                        //         }
                        //     });
                        // }

                        // if (shouldNotRenderChart && ) {
                        //     return <></>;
                        // }

                        switch (item.type) {
                            case dashboardKey.pizza:
                                return <PizzaChart metadata={item} dataSource={data || []} />;

                            case dashboardKey.horizontalBar:
                                return <HorizontalBarChart metadata={item} dataSource={data} />;

                            case dashboardKey.multipleBar:
                                return <MultipleBarChart metadata={item} dataSource={data || []} />;

                            case dashboardKey.multipleLine:
                                return <MultipleLineChart metadata={item} dataSource={data || []} />;

                            case dashboardKey.speedometer:
                                return <SpeedomerChart metadata={item} dataSource={data} />;

                            case dashboardKey.multipleSpeedometer:
                                return <MultipleSpeedometer metadata={item} dataSource={data} />;

                            case dashboardKey.widgets:
                                return <Widgets metadata={item} dataSource={data} />;

                            case dashboardKey.nestedTable:
                                return <NestedTableChart metadata={item} dataSource={data} />;

                            case dashboardKey.table:
                                return <TableChart metadata={item} dataSource={data} />;

                            default:
                                return <div>Nenhum gráfico definido</div>;
                        }
                    })
                )}
            </Row>
        </>
    );
};

export default RenderDashboard;
