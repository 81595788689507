import React, { useState, useEffect } from "react";
import { PageHeader, Row, Col, Select, Button, Spin, message, DatePicker, Form } from "antd";
import moment from "moment";
import RenderDahsboard from "./renderDashboard";
import { dashboardList, customFilter } from "./../constants/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "./../action/index";
import {
    selectDataForFixedSelectList as selectFixeList,
    selectEstabeleciment,
    selectUnitOrganicByEstabeleciment as selectOrganic,
} from "./../../fixed_list/actions/index";

const { Option } = Select;
const { RangePicker } = DatePicker;

const DashboardIndex = ({
    form,
    selectEstabeleciment,
    selectFixeList,
    selectOrganic,
    getData,
    stablishmentList,
    organicUnitList,
    inqueritoList,
}) => {
    const [dataDashboard, setDataDashboard] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingOrganic, setLoadingOrganic] = useState(false);
    const [isFetchedOrganic, setIsFetchedOrganic] = useState(false);
    const [loadingStablishment, setLoadingStablishment] = useState(true);
    const [loadingInquiry, setLoadingInquiry] = useState(false);
    const [isFetchedInquiry, setIsFetchedInquiry] = useState(false);
    const [filterDashboard, setFilterDashboard] = useState([]);
    const { getFieldDecorator } = form;
    useEffect(() => {
        let mounted = true;

        if (stablishmentList.length === 0) {
            selectEstabeleciment({
                accao: "lista",
                lista: "lista_estabelecimentos",
                id_instituicao: "1",
            }).then((sucess) => {
                if (!sucess) message.error("Impossível ler estabelecimentos do servidor");
                if (mounted) setLoadingStablishment(false);
            });
        } else if (mounted) setLoadingStablishment(false);

        return () => {
            mounted = false;
        };
    }, [selectFixeList, selectEstabeleciment, stablishmentList.length]);

    const handleChangeStablishment = (estabelecimentId) => {
        if (!filterDashboard.includes(customFilter.unidadeOrganica)) {
            if (isFetchedOrganic) {
                setIsFetchedOrganic(false);
            }
            return false;
        } else {
            form.setFields({
                organicUnit: { value: null },
            });
            loadOrganic(estabelecimentId);
        }
    };

    const search = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setLoadingData(true);
                const metadata = dashboardList[values.dashboard];
                values.dashboard = metadata.key;
                getData(values).then((response) => {
                    setDataDashboard({
                        metadata: metadata,
                        data: response || null,
                    });
                    setLoadingData(false);
                });
            }
        });
    };
    const loadInquiry = () => {
        let mounted = true;
        setLoadingInquiry(true);
        selectFixeList({ query: "rel_lista_tipo_inquerito" }, "rel_lista_tipo_inquerito").then(() => {
            if (mounted) {
                setLoadingInquiry(false);
                setIsFetchedInquiry(true);
            }
        });
    };
    const loadOrganic = (estabelecimentId) => {
        setLoadingOrganic(true);
        const values = { accao: "lista" };

        if (estabelecimentId) {
            values.lista = "lista_departamentos_por_estabelecimento";
            values.id_estabelecimento = estabelecimentId;
        } else values.lista = "lista_departamentos";

        selectOrganic(values).then((sucess) => {
            setLoadingOrganic(false);
            setIsFetchedOrganic(true);
            if (!sucess) message.error("Impossível ler departamentos do servidor");
        });
    };
    const handleChangeDashboard = (e) => {
        let filters = [];
        if (e) {
            const { filter } = dashboardList[e];
            filters = filter || [];
        }

        setFilterDashboard(filters);

        if (filters.includes(customFilter.unidadeOrganica) && !isFetchedOrganic) {
            // load organic
            const selectedStablishment = form.getFieldValue("stablishment");
            loadOrganic(selectedStablishment);
        }
        if (!isFetchedInquiry && filters.includes(customFilter.tipoInquerito)) {
            loadInquiry();
        }
    };

    return (
        <>
            <PageHeader title="Dashboard"></PageHeader>
            <Form layout="vertical">
                <Row gutter={16}>
                    <Col xs={24} md={6}>
                        <Form.Item label="Dashboard">
                            {getFieldDecorator("dashboard", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório!",
                                    },
                                ],
                            })(
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Selecione o dashboard"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: "100%" }}
                                    onChange={handleChangeDashboard}
                                >
                                    {dashboardList.map((item, index) => (
                                        <Option value={index} key={"dashboard-" + index}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col md={6}>
                        <Form.Item label="Data">
                            {getFieldDecorator("date")(
                                <RangePicker
                                    format={["DD-MM-YYYY", "DD-MM-YYYY"]}
                                    defaultPickerValue={[null, moment()]}
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                        <Form.Item label="Estabelecimento">
                            {getFieldDecorator("stablishment")(
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Selecione o estabelecimento"
                                    loading={loadingStablishment}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: "100%" }}
                                    onChange={(e) => handleChangeStablishment(e)}
                                >
                                    {stablishmentList.map((item) => (
                                        <Option
                                            title={item.nome}
                                            value={item.estabelecimento}
                                            key={"stablishment-" + item.estabelecimento}
                                        >
                                            {item.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    {filterDashboard.includes(customFilter.unidadeOrganica) && (
                        <Col xs={24} md={6}>
                            <Form.Item label="Unidade orgânica">
                                {getFieldDecorator("organicUnit")(
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Selecione a unidade orgânica"
                                        optionFilterProp="children"
                                        loading={loadingOrganic}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        {!loadingOrganic &&
                                            Array.isArray(organicUnitList) &&
                                            organicUnitList.map((item) => (
                                                <Option value={item.id} key={"organicUni-" + item.id}>
                                                    {item.descricao}
                                                </Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}

                    {filterDashboard.includes(customFilter.tipoInquerito) && (
                        <Col md={6}>
                            <Form.Item label="Tipo inquérito">
                                {getFieldDecorator("tipo_inquerito")(
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Selecione uma opção"
                                        loading={loadingInquiry}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        {inqueritoList.map((item) => (
                                            <Option value={item.valor} key={"inquerito " + item.valor}>
                                                {item.valor}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}
                    <Col xs={24} md={6}>
                        <Form.Item label="&nbsp;">
                            <Button type="primary" loading={loadingData} onClick={search}>
                                Consultar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <br />
            <Spin spinning={loadingData}>
                <RenderDahsboard metadata={dataDashboard.metadata} dataSource={dataDashboard.data} />
            </Spin>
            {/* <br /> <br /> <br />
      <h2>Exemplos de gráficos</h2>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Template />
      </Row> */}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            selectEstabeleciment,
            selectFixeList,
            selectOrganic,
            getData: actionCreators.getDataDashboard,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    const fixedList = state.fixedList.domain.byId;
    return {
        stablishmentList: fixedList.estabelecimento ? fixedList.estabelecimento.data : [],
        organicUnitList: fixedList.unidade_organica ? fixedList.unidade_organica.data : [],
        macroProcessoList: fixedList.macro_processo ? fixedList.macro_processo.data : [],
        inqueritoList: fixedList.rel_lista_tipo_inquerito ? fixedList.rel_lista_tipo_inquerito.data : [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({})(DashboardIndex));
