import { message } from "antd";
import moment from "moment";
import { Utilitaries } from "../../../business";
import { api_fetch } from "../../../V2/utils/HttpRequest/request";
import { EventFilterType, EventProps, User, Group } from "../types";

const SPECIAL_DATES_COLOR = "#629EEE";
const SPECIAL_DATES_AND_HOLIDAY_COLOR = "#629EEE";
const HOLIDAYS_COLOR = "#629EAA";

interface GetEventsProps {
    _start: string | null;
    _end: string | null;
    calendarDateRange: {
        start: string;
        end: string;
    };
    eventsFilter: EventFilterType;
}

export const getEvents = async ({ _end = null, _start = null, calendarDateRange, eventsFilter }: GetEventsProps) => {
    if (_start == null && calendarDateRange.start === "" && _end == null && calendarDateRange.end === "") {
        return;
    }

    const formData = new FormData();
    formData.append("accao", "lista");
    formData.append("data_inicio", _start ? _start : calendarDateRange.start);
    formData.append("data_fim", _end ? _end : calendarDateRange.end);

    if (Object.keys(eventsFilter).length > 0) {
        if (eventsFilter.type === "Outros") {
            formData.append("tipo_agenda", "Outra");
            formData.append("utilizador_agenda", eventsFilter.user);
        } else {
            formData.append("tipologia_evento", eventsFilter.typology);
        }
    }

    const { response, status } = await api_fetch({
        endPoint: "/Gestor/gereagenda.php",
        method: "POST",
        body: formData,
    });

    const result = {
        holidays: [],
        workHolidays: [],
        specialDates: [],
        events: [] as EventProps[],
    };

    if (status === "ERROR") {
        message.error("Erro ao obter eventos");
        return;
    }

    const dados: any[] = Utilitaries.toArray(response?.eventos?.["linha"]);
    const _workHolidays = Utilitaries.toArray(response?.ferias?.["linha"]);

    // Events
    let _events = dados.map((item: any) => {
        const __event: EventProps = {
            id: item.id,
            start: moment(item.data_ini, "DD-MM-YYYY HH:mm:ii").format("YYYY-MM-DD HH:mm"),
            end: moment(item.data_fi, "DD-MM-YYYY HH:mm:ii").format("YYYY-MM-DD HH:mm"),
            title: typeof item.local === "object" ? "Sem Local" : item.local, //item.titulo,
            _description: Utilitaries.toString(item.descricao),
            _title: Utilitaries.toString(item.titulo),
            _location: Utilitaries.toString(item.local),
            color: item.cor,
        };

        return __event;
    });
    // Holidays
    _events.push(
        ..._workHolidays.map((item: any, idx: number) => ({
            id: new Date().getTime() + idx,
            start: moment(item.data).format("YYYY-MM-DD HH:mm"),
            end: moment(item.data2).format("YYYY-MM-DD"),
            title: `(${item.opcao}) Feria`, //item.titulo,
            color: "#ABCDCA",
        }))
    );
    result.workHolidays = _workHolidays;

    // Vocation
    const holiday = Utilitaries.toArray(response?.feriados?.["linha"]);
    result.holidays = holiday.map((i: any) => i.data);

    _events.push(
        ...holiday.map((item: any, idx: number) => {
            const _date = moment(item.data).format("YYYY-MM-DD");
            return {
                id: `feriado_${new Date().getTime() + idx}`,
                start: _date,
                end: _date,
                allDay: true,
                title: `(Feriado) ${item.designacao}`, //item.titulo,
                color: HOLIDAYS_COLOR,
                _description: Utilitaries.toString(item.designacao),
                _title: "Feriado",
            };
        })
    );

    // Special Dates
    const allSpecialDates = Utilitaries.toArray(response?.special_dates?.["linha"]).map((item: any) => ({
        date: moment(item.data).format("YYYY-MM-DD"),
        title: item.name,
        color: SPECIAL_DATES_COLOR,
        description: Utilitaries.toString(item.description),
    }));

    result.specialDates = allSpecialDates;

    const specialDatesEvent = allSpecialDates.reduce((accumulator: any, currentDate: any) => {
        if (accumulator[currentDate.date]) {
            accumulator[currentDate.date] = {
                ...accumulator[currentDate.date],
                total: accumulator[currentDate.date].total + 1,
            };
        } else {
            accumulator[currentDate.date] = { ...currentDate, total: 1 };
        }

        return accumulator;
    }, {});

    const list = Object.values(specialDatesEvent).map((specialDate: any, idx) => ({
        id: `special_dates_${new Date().getTime() + idx}`,
        start: specialDate.date,
        end: specialDate.date,
        allDay: true,
        title: specialDate.title,
        color: specialDate.color,
        _description: Utilitaries.toString(specialDate.description),
        _title: "Feriado",
        _total: specialDate.total - 1,
    }));

    _events.push(...list);
    result.events = _events;

    return result;
};

export const getOverlaySettings = async () => {
    const { response, status } = await api_fetch({
        endPoint: "/Gestor/gereagendadefinicao.php",
        method: "POST",
        params: {
            accao: "get-overlay-settings",
        },
    });

    if (status === "ERROR") {
        message.error("Erro ao obter configurações de sobreposição");
        return;
    }

    const { utilizadores, grupos } = response;

    const _users: User[] = Utilitaries.toArray(utilizadores?.linha).map((item: any) => ({
        username: item.username,
        name: item.nome,
    }));

    const _groups: Group[] = Utilitaries.toArray(grupos?.linha).map((item: any) => ({
        id: item.grupo,
        value: item.nome_grupo,
    }));

    return {
        users: _users,
        groups: _groups,
    };
};

export const saveOverlaySettings = async (users: User[], groups: Group[]) => {
    const { status } = await api_fetch({
        endPoint: "/Gestor/gereagendadefinicao.php",
        method: "POST",
        params: {
            accao: "save-overlay-settings",
            utilizadores: users.map((item: User) => item.username).join(";"),
            grupos: groups.map((item: Group) => item.id).join(";"),
        },
    });

    if (status === "ERROR") {
        message.error("Erro ao salvar configurações de sobreposição");
        return false;
    } else {
        message.success("Configurações de sobreposição salvas com sucesso");
        return true;
    }
};
