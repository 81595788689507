import React, { useState, useEffect } from "react";
import { Modal, Row, Col, List, Icon, Select, Checkbox, Skeleton } from "antd";
import { Session, Utilitaries } from "../business";
import { toArray } from "./util";
import { sendHttpRequest } from "./http.request";

const { Option } = Select;

const SelectUser = (props) => {
    const [params, setParams] = useState({
        id_estabelecimento: "",
        id_unit_organic: "",
        id_funcional_group: "",
    });

    const [groupsOjbect, setGroupsOjbect] = useState({});
    const [groups, setGroups] = useState([]);

    const [users, setUsers] = useState([]);
    const [usersOjbect, setUsersOjbect] = useState({});
    const [sendEmail, setSendEmail] = useState({
        users: {},
        groups: {},
    });
    const [sendSMS, setSendSMS] = useState({
        users: {},
        groups: {},
    });

    const [contributores, setContributores] = useState([]);

    const [selectedGroup, setSelectedGroup] = useState({});
    const [stablishments, setStablishments] = useState([]);
    const [UO, setUO] = useState([]);

    useEffect(() => {
        const _getStablishments = async () => {
            try {
                const formData = new FormData();
                formData.append("accao", "acessos");
                formData.append("username", Session.getUsername());

                const response = await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);

                const estabelecimentos = toArray(response?.lista_estabelecimentos?.linha);

                setStablishments(estabelecimentos);
            } catch (error) {}
        };

        const _getUO = async () => {
            try {
                const formData = new FormData();
                // formData.append("accao", "query");
                formData.append("query", "all_unidade_organica_v1");

                const response = await sendHttpRequest("POST", "/Gestor/execQuery.php", formData);

                const list = toArray(response?.result);

                setUO(list);
            } catch (error) {}
        };

        if (stablishments.length === 0) {
            _getStablishments();
        }
        if (UO.length === 0) {
            _getUO();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setContributores(props.contributorsByFuncionalGroup);
    }, [props.contributorsByFuncionalGroup]);

    useEffect(() => {
        if (props.defaultUsers) {
            let newUsers = {};
            props.defaultUsers.forEach((item) => {
                newUsers[item.username] = item;
            });
            setUsers(Object.values(newUsers));
            setUsersOjbect(newUsers);
        }

        if (props.defaultGroups) {
            let newGroups = {};
            props.defaultGroups.forEach((item) => {
                newGroups[item.value] = item;
            });
            setGroups(Object.keys(newGroups));
            setGroupsOjbect(newGroups);
        }
    }, [props.defaultUsers, props.defaultGroups]);

    useEffect(() => {
        if (!params || !params.id_funcional_group) {
            return;
        }

        // id_estabelecimento
        // id_unit_organic
        // id_funcional_group

        let _params = {};

        if (params.id_funcional_group !== "allUsers") {
            _params.id_funcional_group = params.id_funcional_group;
        }

        if (params.id_estabelecimento) {
            _params.id_estabelecimento = params.id_estabelecimento;
        }

        if (params.id_unit_organic) {
            _params.id_unit_organic = params.id_unit_organic;
        }

        _params.status = "1";

        props.loadingContributorsByFuncionalGroup(_params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const removeGroupFromSelected = (item) => {
        const newGroups = groupsOjbect;
        const group = { ...groupsOjbect[item] };

        delete newGroups[item];
        setGroups(Object.keys(newGroups));
        setGroupsOjbect(newGroups);

        // Remove from list to send Email
        let newList = { ...sendEmail.groups };
        delete newList[group.id];
        setSendEmail({ users: sendEmail.users, groups: newList });

        // Remove from list to send SMS
        let _newList = { ...sendSMS.groups };
        delete _newList[group.id];
        setSendSMS({ users: sendSMS.users, groups: _newList });
    };

    const removeUserFromSelected = (username) => {
        let newUsers = usersOjbect;
        delete newUsers[username];
        setUsers(Object.values(newUsers));
        setUsersOjbect(newUsers);

        // Remove from list to send Email
        let newList = { ...sendEmail.users };
        delete newList[username];
        setSendEmail({ users: newList, groups: sendEmail.groups });

        // Remove from list to send SMS
        let _newList = { ...sendSMS.users };
        delete _newList[username];
        setSendSMS({ users: _newList, groups: sendSMS.groups });
    };

    const handleChange = (value, option, key) => {
        let { id_estabelecimento, id_funcional_group, id_unit_organic } = params;

        if (key === "id_funcional_group") {
            const id = option?.props?.data_id;
            id_funcional_group = id;
            if (id === "allUsers") {
                setSelectedGroup({});
            } else {
                setSelectedGroup({
                    value,
                    id,
                });
            }
        } else if (key === "id_unit_organic") {
            id_unit_organic = value;
        } else if (key === "id_estabelecimento") {
            id_estabelecimento = value;
        }

        setParams({ id_estabelecimento, id_funcional_group, id_unit_organic });
    };

    const handleSelectGroup = (e) => {
        if (Object.keys(selectedGroup).length <= 0) {
            return;
        }

        const newGroups = groupsOjbect;

        if (e.target.checked) {
            // add
            newGroups[selectedGroup.value] = selectedGroup;
        } else {
            //remove
            delete newGroups[selectedGroup.value];
        }

        setGroups(Object.keys(newGroups));
        setGroupsOjbect(newGroups);
    };

    const handleUsersList = (e, item) => {
        let newUsers = usersOjbect;
        if (e.target.checked) {
            // add
            newUsers[item.username] = item;
        } else {
            //remove
            delete newUsers[item.username];
        }
        setUsers(Object.values(newUsers));
        setUsersOjbect(newUsers);
    };

    const handleSendEmailCheck = (e, item, isUser = true) => {
        if (isUser) {
            let newList = { ...sendEmail.users };

            if (e.target.checked) {
                newList[item.username] = item;
            } else {
                delete newList[item.username];
            }
            setSendEmail({ groups: sendEmail.groups, users: newList });
        } else {
            let newList = { ...sendEmail.groups };

            item = groupsOjbect[item];

            if (e.target.checked) {
                newList[item.id] = item;
            } else {
                delete newList[item.id];
            }
            setSendEmail({ users: sendEmail.users, groups: newList });
        }
    };

    const handleSendSMSCheck = (e, item, isUser = true) => {
        if (isUser) {
            let newList = { ...sendSMS.users };

            if (e.target.checked) {
                newList[item.username] = item;
            } else {
                delete newList[item.username];
            }
            setSendSMS({ groups: sendSMS.groups, users: newList });
        } else {
            let newList = { ...sendSMS.groups };

            item = groupsOjbect[item];

            if (e.target.checked) {
                newList[item.id] = item;
            } else {
                delete newList[item.id];
            }
            setSendSMS({ users: sendSMS.users, groups: newList });
        }
    };

    const onCheckAllChange = (e) => {
        const _allUsers = [...props.allContributors.funcionarios.data];

        let newUsers = usersOjbect;

        if (e.target.checked) {
            const toAdd = _allUsers.reduce((accumulator, currentValue) => {
                accumulator[currentValue.username] = {
                    name: currentValue.nome,
                    username: currentValue.username,
                };
                return accumulator;
            }, {});

            newUsers = { ...newUsers, ...toAdd };
        } else {
            _allUsers.forEach((it) => {
                delete newUsers[it.username];
            });
        }

        setUsers(Object.values(newUsers));
        setUsersOjbect(newUsers);
    };

    const handleCommunicationCheckAll = (e, isEmail, isUser) => {
        const allUser = { ...usersOjbect };
        const allGroup = { ...groupsOjbect };

        // check all user to email
        // remove all user to email
        if (e.target.checked && isEmail && isUser) {
            setSendEmail({ users: allUser, groups: sendEmail.groups });
        } else if (!e.target.checked && isEmail && isUser) {
            setSendEmail({ users: {}, groups: sendEmail.groups });
        }

        // check all user to SMS
        // remove all user to SMS
        if (e.target.checked && !isEmail && isUser) {
            setSendSMS({ users: allUser, groups: sendSMS.groups });
        } else if (!e.target.checked && !isEmail && isUser) {
            setSendSMS({ users: {}, groups: sendSMS.groups });
        }

        // check all group - email
        if (e.target.checked && isEmail && !isUser) {
            setSendEmail({ users: sendEmail.users, groups: allGroup });
        } else if (!e.target.checked && isEmail && !isUser) {
            setSendEmail({ users: sendEmail.users, groups: {} });
        }

        if (e.target.checked && !isEmail && !isUser) {
            setSendSMS({ users: sendSMS.users, groups: allGroup });
        } else if (!e.target.checked && !isEmail && !isUser) {
            setSendSMS({ users: sendSMS.users, groups: {} });
        }
    };

    const listOfFuncionalGroups = props.funcionalGroups.map((item) =>
        typeof item.id_ficha_funcao !== "object" &&
        typeof item.descricao_funcao !== "object" &&
        item?.estado === "Ativo" ? (
            <Option key={item.codigo} data_id={item.id_ficha_funcao}>
                {item.codigo}
            </Option>
        ) : null
    );

    listOfFuncionalGroups.push(
        <Option key="allUsers" data_id="allUsers">
            Todos Utilizadores
        </Option>
    );

    return (
        <Modal
            width="50%"
            style={{ top: 20 }}
            visible={props.visible}
            title="Adicionar Grupos e Utilizadores"
            okText="Guardar"
            onCancel={() => props.setVisible(false)}
            onOk={() => {
                if (props.communication) {
                    props.onOk(Object.values(usersOjbect), Object.values(groupsOjbect), sendEmail, sendSMS);
                } else {
                    props.onOk(Object.values(usersOjbect), Object.values(groupsOjbect));
                }
                props.setVisible(false);
            }}
        >
            <Row>
                <Col span={12}>
                    <Select
                        mode="default"
                        style={{ width: "100%", marginRight: 5 }}
                        placeholder="Filtrar por estabelecimento"
                        allowClear
                        showSearch
                        onChange={(value, option) => handleChange(value, option, "id_estabelecimento")}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {stablishments.map((e) => (
                            <Option value={e.id_estabelecimento}>{e.nome}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={12}>
                    <Select
                        mode="default"
                        style={{ width: "100%", marginLeft: 5 }}
                        placeholder="Filtrar por UO"
                        allowClear
                        showSearch
                        onChange={(value, option) => handleChange(value, option, "id_unit_organic")}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {UO.map((e) => (
                            <Option value={e.id_departamento}>{e.designacao}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={12} style={{ paddingRight: 5, paddingTop: 10 }}>
                    <List
                        className="qa-list"
                        size="small"
                        header={
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingRight: 14,
                                }}
                            >
                                <div>Grupos funcionais</div>
                                <div>
                                    {props.communication && (
                                        <>
                                            <Checkbox
                                                checked={
                                                    Object.keys(groupsOjbect).length > 0 &&
                                                    Object.keys(groupsOjbect).filter(
                                                        (it) =>
                                                            !Object.keys(sendEmail.groups)
                                                                .map((group) => sendEmail.groups?.[group]?.value)
                                                                .includes(it)
                                                    ).length === 0
                                                }
                                                onChange={(e) => handleCommunicationCheckAll(e, true, false)}
                                            >
                                                Email
                                            </Checkbox>
                                            <Checkbox
                                                checked={
                                                    Object.keys(groupsOjbect).length > 0 &&
                                                    Object.keys(groupsOjbect).filter(
                                                        (it) =>
                                                            !Object.keys(sendSMS.groups)
                                                                .map((group) => sendEmail.groups?.[group]?.value)
                                                                .includes(it)
                                                    ).length === 0
                                                }
                                                onChange={(e) => handleCommunicationCheckAll(e, false, false)}
                                            >
                                                SMS
                                            </Checkbox>
                                        </>
                                    )}
                                </div>
                            </div>
                        }
                        footer={null}
                        bordered
                        dataSource={groups}
                        renderItem={(item) => (
                            <List.Item key={item}>
                                <List.Item.Meta description={item} />
                                <div>
                                    {props.communication && (
                                        <>
                                            <Checkbox
                                                checked={
                                                    !Utilitaries.isEmpty(
                                                        Object.values(sendEmail.groups).find((it) => it.value === item)
                                                    )
                                                }
                                                onChange={(e) => handleSendEmailCheck(e, item, false)}
                                            >
                                                Email
                                            </Checkbox>
                                            <Checkbox
                                                checked={
                                                    !Utilitaries.isEmpty(
                                                        Object.values(sendSMS.groups).find((it) => it.value === item)
                                                    )
                                                }
                                                onChange={(e) => handleSendSMSCheck(e, item, false)}
                                            >
                                                SMS
                                            </Checkbox>
                                        </>
                                    )}
                                    <Icon
                                        onClick={() => removeGroupFromSelected(item)}
                                        className="qa-list-action"
                                        style={{ color: "red" }}
                                        type="delete"
                                    />
                                </div>
                            </List.Item>
                        )}
                        style={{ marginBottom: 10 }}
                    />

                    <List
                        className="qa-list"
                        size="small"
                        header={
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingRight: 28,
                                }}
                            >
                                <div>Utilizadores</div>
                                <div>
                                    {props.communication && (
                                        <>
                                            <Checkbox
                                                checked={
                                                    Object.keys(usersOjbect).length > 0 &&
                                                    Object.keys(usersOjbect).filter(
                                                        (it) => !Object.keys(sendEmail.users).includes(it)
                                                    ).length === 0
                                                }
                                                onChange={(e) => handleCommunicationCheckAll(e, true, true)}
                                            >
                                                Email
                                            </Checkbox>
                                            <Checkbox
                                                checked={
                                                    Object.keys(usersOjbect).length > 0 &&
                                                    Object.keys(usersOjbect).filter(
                                                        (it) => !Object.keys(sendSMS.users).includes(it)
                                                    ).length === 0
                                                }
                                                onChange={(e) => handleCommunicationCheckAll(e, false, true)}
                                            >
                                                SMS
                                            </Checkbox>
                                        </>
                                    )}
                                </div>
                            </div>
                        }
                        footer={null}
                        bordered
                        dataSource={users}
                        renderItem={(item) => (
                            <List.Item key={item}>
                                <List.Item.Meta description={item.name} />
                                <div>
                                    {props.communication && (
                                        <>
                                            <Checkbox
                                                checked={sendEmail.users[item.username]}
                                                onChange={(e) => handleSendEmailCheck(e, item)}
                                            >
                                                Email
                                            </Checkbox>
                                            <Checkbox
                                                checked={sendSMS.users[item.username]}
                                                onChange={(e) => handleSendSMSCheck(e, item)}
                                            >
                                                SMS
                                            </Checkbox>
                                        </>
                                    )}
                                    <Icon
                                        onClick={() => removeUserFromSelected(item.username)}
                                        className="qa-list-action"
                                        style={{ color: "red" }}
                                        type="delete"
                                    />
                                </div>
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={12} style={{ paddingLeft: 5, paddingTop: 10 }}>
                    <Select
                        mode="default"
                        style={{ width: "100%" }}
                        placeholder="Filtrar por Grupo funcional"
                        allowClear
                        showSearch
                        onChange={(value, option) => handleChange(value, option, "id_funcional_group")}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {listOfFuncionalGroups}
                    </Select>

                    <Skeleton
                        loading={props.isloadingContributorsByFuncionalGroup}
                        paragraph={{ rows: 5, width: "100%" }}
                        active
                    >
                        <List
                            className="qa-list-user"
                            size="small"
                            style={{ marginTop: 8 }}
                            header={
                                <div>
                                    {!Utilitaries.isEmpty(selectedGroup) && (
                                        <Checkbox
                                            checked={groups.includes(selectedGroup.value)}
                                            onChange={(e) => handleSelectGroup(e)}
                                        >
                                            Selecionar grupo
                                        </Checkbox>
                                    )}

                                    {Utilitaries.isEmpty(selectedGroup) && (
                                        <Checkbox
                                            indeterminate={
                                                Object.keys(usersOjbect).length > 0 &&
                                                contributores.filter(
                                                    (it) => !Object.keys(usersOjbect).includes(it.username)
                                                ).length > 0
                                            }
                                            onChange={onCheckAllChange}
                                            checked={
                                                toArray(props?.allContributors?.funcionarios?.data).filter(
                                                    (it) => !Object.keys(usersOjbect).includes(it.username)
                                                ).length === 0
                                            }
                                        >
                                            Selecionar todos utilizadores
                                        </Checkbox>
                                    )}
                                </div>
                            }
                            footer={null}
                            bordered
                            dataSource={contributores.map((item) => ({
                                name: item.nome,
                                username: item.username,
                            }))}
                            renderItem={(item) => (
                                <List.Item key={item.username}>
                                    <Checkbox
                                        checked={usersOjbect[item.username] !== undefined}
                                        onChange={(e) => handleUsersList(e, item)}
                                    >
                                        {item.name}
                                    </Checkbox>
                                </List.Item>
                            )}
                        />
                    </Skeleton>
                </Col>
            </Row>
        </Modal>
    );
};

export default SelectUser;
