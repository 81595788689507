import React, { useState, useEffect } from "react";
import { Modal, Row, Form, Select, Input, Col } from "antd";
// import { connect } from "react-redux";

// import { setValuesToTasksTable } from "./../../actions/funcional.group";

const { Option } = Select;
const { TextArea } = Input;

const EXEC_QUERY_NAME = {
    grupos_funcionais: "grupos_funcionais",
};

const SubstitutosModalForTask = ({ data, visible, close, autorizations, selectDataFromExecQuery }) => {
    const { field, record } = data;
    const [functionalGroup, setFunctionalGroup] = useState([]);
    const [substitutoAutorization, setSubstitutoAutorization] = useState([]);
    const [substitutoGrupo, setSubstitutoGrupo] = useState(undefined);
    const [procedimento, setProcedimento] = useState(undefined);

    useEffect(() => {
        (async () => {
            const response = await selectDataFromExecQuery({
                query: EXEC_QUERY_NAME.grupos_funcionais,
            });
            if (response.result) {
                if (Array.isArray(response.result)) {
                    setFunctionalGroup(response.result);
                }
            }
        })();
    }, []);

    //
    const handleOk = () => {
        let newData = {};
        if (field === "tarefa_nao_si") {
            newData = {
                ...data,
                record: {
                    ...record,
                    grupo_substituto: substitutoAutorization,
                    grupo_func_substituto: substitutoGrupo,
                    sem_substituto: procedimento,
                },
            };
        } else {
            newData = {
                ...data,
                record: {
                    ...record,
                    grupo_substituto_si: substitutoAutorization,
                    grupo_func_substituto_si: substitutoGrupo,
                    sem_substituto: procedimento,
                },
            };
        }

        //

        close(newData);
    };
    const handleCancel = () => close();

    return (
        <Modal title="Substitutos" width="50%" centered visible={visible} onOk={handleOk} onCancel={handleCancel}>
            <Row>
                <Form layout="vertical">
                    <Col xs={24}>
                        <Form.Item label="Substitutos (autorizações)">
                            <Select
                                allowClear
                                showSearch
                                autoFocus
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Selecione um opção"
                                optionFilterProp="children"
                                defaultValue={
                                    field === "tarefa_si"
                                        ? record.grupo_substituto_si
                                        : field === "tarefa_nao_si"
                                        ? record.grupo_substituto
                                        : undefined
                                }
                                onChange={(value) => setSubstitutoAutorization(value)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {autorizations &&
                                    autorizations.map((item, index) => (
                                        <Option
                                            key={index}
                                            value={
                                                field === "tarefa_si" ? item.grupo_substituto_si : item.grupo_substituto
                                            }
                                        >
                                            {item.designacao}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Grupo de substituição">
                            <Select
                                allowClear
                                showSearch
                                autoFocus
                                style={{ width: "100%" }}
                                placeholder="Selecione um opção"
                                optionFilterProp="children"
                                defaultValue={
                                    field === "tarefa_si"
                                        ? record.grupo_func_substituto_si
                                        : record && record.grupo_func_substituto
                                        ? record.grupo_func_substituto
                                        : undefined
                                }
                                onChange={(value) => setSubstitutoGrupo(value)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {functionalGroup.map((item, index) => {
                                    return (
                                        <Option
                                            key={index}
                                            value={
                                                field === "tarefa_si"
                                                    ? item.grupo_func_substituto_si
                                                    : item.grupo_func_substituto
                                            }
                                        >
                                            {item.descricao_funcao}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Procedimento se não existir substituto">
                            <TextArea
                                placeholder="Introduza o procedimento"
                                onChange={(e) => setProcedimento(e.target.value)}
                                defaultValue={record ? record.sem_substituto : undefined}
                                rows={3}
                            />
                        </Form.Item>
                    </Col>
                </Form>
            </Row>
        </Modal>
    );
};

// const mapStateToProps = (state, ownProps) => {
// 	return {
// 		tarefas: state.funcionalGroup.domain.form.tarefas,
// 	};
// };

// const mapDispatchToProps = {
// 	setValuesToTasksTable,
// };

export default SubstitutosModalForTask;
