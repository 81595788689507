import React, { useState, useEffect } from "react";
import { List, Select, Avatar, Input, Modal, Row, Col } from "antd";

import moment from "moment";
import { Utilitaries } from "../../business";

const { Search } = Input;
const { Option } = Select;

const Visualization = (props) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        let data = props.data.filter((item) => typeof item.username === "string");

        if (props.estado) {
            data = props.data.filter(
                (item) =>
                    typeof item.username === "string" && parseInt(item.estado) === parseInt(props.estado == 1 ? 1 : 0)
            );
        }

        setUsers(data);
    }, [props.data, props.estado]);

    return (
        <Modal
            title="Lista de visualizações"
            visible={props.visible}
            footer={null}
            onCancel={props.onCancel}
            destroyOnClose
        >
            <Row>
                <Col span={16}>
                    <Search
                        placeholder="Pesquise por utilizador"
                        onSearch={(value) => {
                            if (value) {
                                setUsers(
                                    users.filter((item) => item.username.toLowerCase().startsWith(value.toLowerCase()))
                                );
                            } else {
                                setUsers(props.data.filter((item) => typeof item.username === "string"));
                            }
                        }}
                        enterButton
                    />
                </Col>
                <Col span={8}>
                    <Select
                        defaultValue={props.estado ? props.estado : ""}
                        style={{
                            width: "100%",
                            marginLeft: 8,
                            marginBottom: 8,
                        }}
                        onChange={(value) => {
                            if (value == 1) {
                                setUsers(
                                    props.data.filter(
                                        (item) => item.estado === "1" && typeof item.username === "string"
                                    )
                                );
                            } else if (value == 2) {
                                setUsers(
                                    props.data.filter(
                                        (item) => item.estado === "0" && typeof item.username === "string"
                                    )
                                );
                            } else {
                                setUsers(props.data.filter((item) => typeof item.username === "string"));
                            }
                        }}
                    >
                        <Option value="">Ver Todos</Option>
                        <Option value={1}>Visualizaram</Option>
                        <Option value={2}>Não Visualizou</Option>
                    </Select>
                </Col>
            </Row>

            <List
                header={null}
                footer={null}
                bordered
                style={{ maxHeight: 320, overflow: "auto" }}
                dataSource={users}
                renderItem={(item) => (
                    <List.Item style={{ paddingBottom: 6, paddingTop: 6 }}>
                        <List.Item.Meta
                            style={{ alignItems: "center" }}
                            avatar={
                                <Avatar
                                    size={14}
                                    style={{
                                        backgroundColor: `${item.estado === "1" ? "#18BC86" : "#FF5733"}`,
                                    }}
                                />
                            }
                            title={item.username}
                        />
                        {item.estado === "1" && !Utilitaries.isEmpty(item.visto_em) && (
                            <div>{moment(item.visto_em, "YYYY-MM-DD HH:ii:ss").calendar()}</div>
                        )}
                    </List.Item>
                )}
            />
        </Modal>
    );
};
export default Visualization;
