const actionType = {
    GET: "MENU_OBTER",
    SET_ROUTES: "MENU_SET_ROUTES",
    GET_LEMBRATE: "LEMBRATE_GET",
    GET_CONTRIBUTORS_BY_FUNCIONAL_GROUP: "CONTRIBUTORS_BY_FUNCIONAL_GROUP",
    GET_LEMBRETES_VISUALIZATION: "GET_LEMBRETES_VISUALIZATION",
    GET_BADGE_RESUME: "GET_BADGE_RESUME",
    GET_ALL_PERMISSIONS: "GET_ALL_PERMISSIONS",
};

export default actionType;
