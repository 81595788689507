import { Utilitaries } from "../../business";
import { UserAuthorization } from "../types/user";
import { api_fetch } from "../utils/HttpRequest/request";

export const getUserAuthorizations = async () => {
    const result = await api_fetch({
        method: "POST",
        endPoint: "/Gestor/gereutilizador.php",
        params: {
            accao: "permissao",
        },
    });
    const userAuthorizations: UserAuthorization[] = [];

    if (result.status === "SUCCESS") {
        for (const authorization of Utilitaries.toArray(result.response?.linha)) {
            userAuthorizations.push({
                descricao: authorization.descricao,
                idautorizacao: authorization.idautorizacao,
            });
        }
    }
    return userAuthorizations;
};

export interface ContributorsProps {
    id: string;
    nome: string;
    username: string;
}
export const getContributors = async (params: any) => {
    const result = await api_fetch({
        method: "POST",
        endPoint: "/Gestor/gerelistas.php",
        params: {
            accao: "lista",
            lista: "qa_registo_ponto_lista_colab",
            ...params,
        },
    });
    let contributors: ContributorsProps[] = [];
    let total = 0;

    if (result.status === "SUCCESS") {
        const response = result.response;
        total = parseInt(response?.dados?.["@attributes"]?.resultados ?? 0);
        contributors = Utilitaries.toArray(response?.dados?.linha);
    }
    return {
        contributors,
        total,
    };
};

export interface ContributorsPropsTimeRegistration {
    data_dia: string;
    data_registo: string;
    id: string;
    motivo_alteracao_data_entrada: string;
    motivo_alteracao_data_saida: string;
    observacoes: string;
    ordenacao_data_entrada: string;
    system_data_entrada: string;
    system_data_saida: string;
    user_data_entrada: string;
    user_data_saida: string;
    username: string;
}
export const getContributorsTimeRegistration = async (params: any) => {
    const result = await api_fetch({
        method: "POST",
        endPoint: "/Gestor/gerelistas.php",
        params: {
            accao: "lista",
            lista: "qa_registo_ponto",
            ...params,
        },
    });
    let contributorsTimeRegistration: ContributorsPropsTimeRegistration[] = [];
    let total = 0;

    if (result.status === "SUCCESS") {
        const response = result.response;
        total = parseInt(response?.dados?.["@attributes"]?.resultados ?? 0);
        for (const contributor of Utilitaries.toArray(response?.dados?.linha)) {
            contributorsTimeRegistration.push({
                data_dia: Utilitaries.toString(contributor.data_dia),
                data_registo: Utilitaries.toString(contributor.data_registo),
                id: Utilitaries.toString(contributor.id),
                motivo_alteracao_data_entrada: Utilitaries.toString(contributor.motivo_alteracao_data_entrada),
                motivo_alteracao_data_saida: Utilitaries.toString(contributor.motivo_alteracao_data_saida),
                observacoes: Utilitaries.toString(contributor.observacoes),
                ordenacao_data_entrada: Utilitaries.toString(contributor.ordenacao_data_entrada),
                system_data_entrada: Utilitaries.toString(contributor.system_data_entrada),
                system_data_saida: Utilitaries.toString(contributor.system_data_saida),
                user_data_entrada: Utilitaries.toString(contributor.user_data_entrada),
                user_data_saida: Utilitaries.toString(contributor.user_data_saida),
                username: Utilitaries.toString(contributor.username),
            });
        }
    }
    return {
        contributorsTimeRegistration,
        total,
    };
};
