import React, { useContext } from "react";
import { Col, Form, Icon, Button, message } from "antd";
import { QADivider, QAToolTip } from "./";
import FormContext from "../context/FormContext";
import { sendHttpRequest } from "../../http.request";
import { downloadFile } from "../../util";
import { FormUtilitaries, Utilitaries } from "../../../business";
import { getInputLabel } from "../utils/functions";
import { LineBreak } from "./lineBreak";

const ButtonGroup = Button.Group;

export default function Report({ inputAttribute, visible, ...inputProps }) {
    const { formData, justSee } = useContext(FormContext);
    const { inTable } = inputProps;

    async function onItemClick({ type }) {
        try {
            let params = inputAttribute["@param_popup"];
            let report = inputAttribute["@relatorio"];

            let body = {
                downloadInBrowser: true,
                nome: report,
                saida: type,
                accao: "relatorio_nome",
            };

            let nullValues = false;

            if (formData) {
                params = params.replace(/[\[\]]/g, "");
                params.split(";").forEach((it) => {
                    if (it) {
                        const item = it.split(",");

                        if (/^[[A-zÀ-ú]|[0-9]|\s]+$/.test(item[1])) {
                            body[item[0]?.trim()] = item[1]?.trim()?.replace(/]/g, "");
                        } else {
                            let value = "";
                            const key = item[1].replace("../", "").replace("//", "").trim();
                            if (inTable) {
                                if (item[1]?.trim()?.startsWith("../")) {
                                    value = inputProps.items.data[key];
                                }
                                if (Utilitaries.isEmpty(value)) {
                                    value = formData[key] || "";
                                }
                            } else {
                                value = formData[key] || "";
                            }

                            if (value === "t") {
                                value = "true";
                            } else if (value === "f") {
                                value = "false";
                            }
                            body[item[0]?.trim()] = value;
                        }

                        // if (!formData[value.trim()]) {
                        //     nullValues = true;
                        //     message.warning(`${item[0]} invalido`);
                        // }
                    }
                });
            }

            if (nullValues) {
                // return;
            }

            downloadFile("gererelatorios.php", body);
        } catch (error) {}
    }

    const RenderItens = () => (
        <>
            <ButtonGroup>
                <Button disabled={justSee} onClick={() => onItemClick({ type: "pdf" })} type="default">
                    <Icon title="Relatório PDF" style={{ color: "red" }} type="file-pdf" />
                </Button>
                <Button disabled={justSee} onClick={() => onItemClick({ type: "doc" })} type="default">
                    <Icon title="Relatório Word" style={{ color: "#1a0dab" }} type="file-word" />
                </Button>
                <Button disabled={justSee} onClick={() => onItemClick({ type: "xls" })} type="default">
                    <Icon title="Relatório Excel" style={{ color: "green" }} type="file-excel" />
                </Button>
            </ButtonGroup>
        </>
    );

    return visible ? (
        <>
            {inputProps.inTable ? (
                RenderItens()
            ) : (
                <>
                    {inputAttribute["@grupo"] && <QADivider text={inputAttribute["@grupo"]} />}
                    {<LineBreak item={inputAttribute} />}
                    <Col xs={24}>
                        <Form.Item
                            label={<QAToolTip label={getInputLabel(inputAttribute)} help={inputAttribute["@ajuda"]} />}
                        >
                            {RenderItens()}
                        </Form.Item>
                    </Col>
                </>
            )}

            {/* {inputAttribute["@grupo"] && <QADivider text={inputAttribute["@grupo"]} />}
            {<LineBreak item={inputAttribute} />}

            <Col xs={24}>
                <Form.Item label={<QAToolTip label={getInputLabel(inputAttribute)} help={inputAttribute["@ajuda"]} />}>
                    {RenderItens()}
                </Form.Item>
            </Col> */}
        </>
    ) : null;
}
