import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PageHeader } from "antd";

import Organograma from "../functional_group/organograma";
import { query } from "../../../../actions/makeHttpRequest";

const Hierarquie = ({ getHierarquia }) => {
    return (
        <>
            <PageHeader title="Hierarquia" />
            <Organograma />
        </>
    );
};

const mapDispatchToProps = {
    getHierarquia: query,
};

const mapStateToProps = (state) => {
    return {
        funcionalContributors: state.funcionalGroup.domain.form.detentor || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hierarquie);
