import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Upload, Select } from "antd";

import QADynamicSelect from "../../DynamicList/dynamicSelect";

const { TextArea } = Input;
const { Option } = Select;

const Editor = ({ onChange, onSubmit, submitting, value }) => {
    // const [fileList, setFileList] = useState([]);

    const fileProps = {
        onRemove: (file) => {
            const fileList = value.fileList;
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            onChange({
                ...value,
                fileList: newFileList,
            });
        },
        beforeUpload: (file) => {
            onChange({
                ...value,
                fileList: value.fileList ? [...value.fileList, file] : [file],
            });
            return false;
        },
        fileList: value ? value.fileList : [],
    };

    return (
        <div>
            <Row type="flex" justify="space-between">
                <Col span={24}>
                    <Form.Item>
                        <TextArea
                            rows={3}
                            onChange={(e) =>
                                onChange({
                                    ...value,
                                    message: e.target.value,
                                })
                            }
                            value={value.message}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item>
                        <Upload {...fileProps} multiple>
                            <Button>Selecionar Ficheiros</Button>
                        </Upload>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item>
                        <QADynamicSelect
                            placeholder="Prioridade da mensagem"
                            name="nivel_mensagem_chat"
                            value={value.type}
                            listValue="designacao"
                            listKey="designacao"
                            onChange={(type) =>
                                onChange({
                                    ...value,
                                    type,
                                })
                            }
                        />
                    </Form.Item>
                </Col>

                <Col span={4}>
                    <Form.Item>
                        <Button icon="mail" htmlType="submit" loading={submitting} onClick={onSubmit} block>
                            Enviar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default Editor;
