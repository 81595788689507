import React from "react";
import { Input, Button, Row, Icon, Table } from "antd";
import { componentToRender } from "../../constants/index";

const ActionsComponent = (props) => {
    const actionsColumns = [
        {
            title: "Nome",
            width: "88%",
            dataIndex: "name",
            render: (text) => <Input placeholder="Introduza o nome" defaultValue={text} />,
        },
        {
            title: "Acção",
            width: "12%",
            dataIndex: "action",
            render: (text, record) => (
                <a
                    href="javasccript:;"
                    onClick={() => {
                        props.showComponentModal(componentToRender.action, record);
                    }}
                >
                    Alterar Evento
                </a>
            ),
        },
        {
            render: (text, record) => (
                <a href="javasccript:;" className="qa-text-del" onClick={() => this.handleDelete}>
                    Eliminar
                </a>
            ),
        },
    ];

    return (
        <>
            <Row>
                <Button
                    onClick={() => props.handleAdd("actionsDataSource")}
                    type="primary"
                    style={{ float: "right", marginBottom: 16 }}
                >
                    <Icon type="plus" />
                    Inserir
                </Button>
            </Row>

            <Table
                rowClassName={() => "editable-row"}
                dataSource={props.dataSource}
                columns={actionsColumns}
                size="default"
            />
        </>
    );
};

export default ActionsComponent;
