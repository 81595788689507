import { sendHttpRequest } from "../../../components/http.request";
const formatDate = "YYYY-MM-DD";

export const getDataDashboard = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("dados", payload.dashboard);
    if (payload.organicUnit) formData.append("id_unidade_organica", payload.organicUnit);

    if (payload.stablishment) formData.append("id_estabelecimento", payload.stablishment);

    if (payload.date && payload.date.length !== 0) {
        formData.append("start_date", payload.date[0].format(formatDate));
        formData.append("end_date", payload.date[1].format(formatDate));
    }

    if (payload.tipo_inquerito) formData.append("tipo_inquerito", payload.tipo_inquerito);

    return await sendHttpRequest("POST", "/Gestor/gereDashboard.php", formData).then((jsonResponse) => {
        if (jsonResponse) {
            return jsonResponse;
        } else return false;
    });
};
