import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "./../../actions/contributor";
import { Table, Input, Button, Icon, Row, Col, Pagination, Tooltip, Tag, message, Radio } from "antd";
import { tableSort, tableFooter } from "./../../../../components/util";
import Highlighter from "react-highlight-words";

const ContributorsIndex = ({
    goto,
    activeTabPaneKey,
    setReportFilters,
    showInatives,
    filterValues,
    setFilterValues,
}) => {
    const [loadingData, setLoadingData] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        setLoadingData(true);
        const { sorter, pagination, ...objFilter } = filterValues;
        let values = {
            ...objFilter,
            pagina: pagination?.currentPage || 1,
            resultados_pagina: pagination?.currentPageSize || 10,
            accao: "colaboradores",
        };
        if (showInatives) {
            values = {
                ...values,
                todos_utilizadores: showInatives,
            };
        }
        if (sorter && sorter.order) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        actionCreators.getContributors(values).then((response) => {
            if (response) {
                setDataSource(response.lista);
                setMetadata(response.metadata);
            } else {
                message.error("Ocorreu um erro");
            }

            setLoadingData(false);
        });
    }, [filterValues, showInatives]);

    // componentDidMount() {
    //     this.selectData({ pagina: 1, resultados_pagina: 10 });
    // }

    // state = {
    //     searchText: null,
    //     isFetching: false,
    //     showDeletedRecords: false,
    //     filterParameters: {},
    // };

    // const _goTo = (key) => {
    //     return goTo("/function.manual/collecthis.
    //     this.setState({
    //         isFetching: true,
    //     });
    //     this.props
    //         .select({
    //             ...this.state.filterParameters,
    //             ...obj,
    //         })
    //         .then(() => {
    //             this.setState({
    //                 isFetching: false,
    //             });
    //         });
    // };

    //---------------------------- filter --------------------------------

    const getColumnSearchProps = (dataIndex) => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys}
                        onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
            filteredValue: filterValues[`filtro_${dataIndex}`] || null,
        };
    };

    const getcustomizedColumnSearchProps = (dataIndex) => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => {
                return (
                    <div style={{ padding: 8, minWidth: "143px" }}>
                        <Radio.Group
                            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                            value={selectedKeys}
                        >
                            {filters.map((item, index) => (
                                <Radio
                                    ref={searchInput}
                                    key={`radio-${index}`}
                                    value={item.value}
                                    style={{
                                        marginBottom: 8,
                                        display: "block",
                                    }}
                                >
                                    {item.text}
                                </Radio>
                            ))}
                        </Radio.Group>
                        <div className="ant-table-filter-dropdown-btns">
                            <a
                                className="ant-table-filter-dropdown-link confirm"
                                onClick={() => handleSearch(selectedKeys, confirm)}
                            >
                                Pesquisar
                            </a>
                            <a
                                className="ant-table-filter-dropdown-link clear"
                                onClick={() => handleReset(clearFilters)}
                            >
                                Limpar
                            </a>
                        </div>
                    </div>
                );
            },
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : "" }} />,
            filteredValue: filterValues[`filtro_${dataIndex}`] || null,
        };
    };

    const handleSearch = (_selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };
    const getColumnSortProps = (dataIndex, sortedInfo) => ({
        sorter: true,
        sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
    });

    const { pagination } = filterValues;
    const sortedInfo = filterValues.sorter || {};

    const columns = [
        // {
        //     title: "Número",
        //     dataIndex: "num_mecanografico",
        //     key: "num_mecanografico",
        //     ...this.getColumnSearchProps("num_mecanografico")
        // },
        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
            ...getColumnSearchProps("nome"),
            ...getColumnSortProps("nome", sortedInfo),
        },
        {
            title: "Tipo",
            dataIndex: "tipo",
            key: "tipo",
            ...getColumnSearchProps("tipo"),
            ...getColumnSortProps("tipo", sortedInfo),
        },
        {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps("email"),
            ...getColumnSortProps("email", sortedInfo),
        },
        {
            title: "Telemóvel / Telefone",
            dataIndex: "tlm",
            key: "tlm",
            ...getColumnSearchProps("tlm"),
            ...getColumnSortProps("tlm", sortedInfo),
        },
        {
            title: "Unidade Orgânica",
            dataIndex: "unidades_organicas",
            key: "unidades_organicas",
            ...getColumnSearchProps("unidades_organicas"),
            ...getColumnSortProps("unidades_organicas", sortedInfo),
            render: (text) => {
                text = typeof text === "object" ? "" : text;
                return text?.length >= 25 ? (
                    <Tooltip title={text}>
                        <div
                            style={{
                                textOverflow: "ellipsis",
                                width: "160px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            {text}
                        </div>
                    </Tooltip>
                ) : (
                    <div>{text}</div>
                );
            },
        },
        {
            title: "Utilizador",
            dataIndex: "username",
            key: "username",
            ...getColumnSearchProps("username"),
            ...getColumnSortProps("username", sortedInfo),
        },
        {
            title: "Estado",
            dataIndex: "status",
            key: "status",
            filters: [
                { value: "1", text: "Ativo" },
                { value: "2", text: "Suspenso" },
                { value: "3", text: "Inativo" },
            ],
            ...getcustomizedColumnSearchProps("status"),
            ...getColumnSortProps("status", sortedInfo),
            render: (text) => {
                if (typeof text === "object") {
                    return null;
                }
                switch (text) {
                    case "3":
                        return <Tag color="red">Inativo</Tag>;

                    case "2":
                        return <Tag color="orange">Suspenso</Tag>;

                    default:
                        return <Tag color="green">Ativo</Tag>;
                }
            },
        },
        {
            title: "Ações",
            key: "action",
            render: (text, record) => (
                <span
                    onClick={() => {
                        activeTabPaneKey("2");
                        goto(`/function_manual/colaborators/${record.id_ficha_colaborador}`);
                        // goto(`/function.manual/colaborators/create/${record.id_ficha_colaborador}`);
                    }}
                >
                    <a href="javascript:;">Editar</a>
                </span>
            ),
        },
    ];

    const handleTableChange = (_pagination, filters, sorter) => {
        let _filters = {};
        const obj = {
            pagination: {
                ...filterValues.pagination,
                currentPage: 1,
            },
        };
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) {
                    obj["filtro_" + key] = val;
                    _filters["filtro_" + key] = val;
                }
            });
        }
        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };

            _filters["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }
        setReportFilters(_filters);
        setFilterValues(obj);
    };

    const handleShowSizeChange = (_currentPage, currentPageSize) => {
        setFilterValues({
            ...filterValues,
            pagination: {
                currentPage: 1,
                currentPageSize,
            },
        });
    };

    const pageChange = (currentPage, currentPageSize) => {
        setFilterValues({
            ...filterValues,
            pagination: {
                currentPage,
                currentPageSize,
            },
        });
    };

    return (
        <>
            <Table
                size="middle"
                loading={loadingData}
                columns={columns}
                pagination={false}
                dataSource={dataSource}
                onChange={handleTableChange}
            />

            <Row>
                <Col xs={24}>
                    <Pagination
                        current={pagination.currentPage}
                        defaultPageSize={pagination.currentPageSize}
                        size="small"
                        total={metadata.resultados}
                        showSizeChanger
                        showTotal={(total) => `Total de ${total} registo.`}
                        onChange={pageChange}
                        onShowSizeChange={handleShowSizeChange}
                        showTitle
                        showLessItems={true}
                        defaultCurrent={1}
                        style={{ float: "right", marginTop: 16 }}
                    />
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        list: Object.values(state.contributor.domain.byId),
        isFetched: state.contributor.app.isFetched,
        total: Number(state.contributor.domain.metadata.attributes.resultados),
        pagina: Number(state.contributor.domain.metadata.attributes.pagina),
        resultadosPagina: state.contributor.domain.metadata.attributes.resultados_pagina,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...actionCreators }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ContributorsIndex);
