import React, { Component } from "react";
import {
    PageHeader,
    Typography,
    Row,
    Col,
    Table,
    Form,
    Input,
    ConfigProvider,
    Icon,
    Button,
    message,
    Pagination,
} from "antd";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";

import DetailsContributorsTimeRegistration from "./details.time.registration";
import { selectContributors, selectPointRegisterByContributor } from "../../actions/contributor.time.registration";
import MenuOptionsToGeneratePdfReport from "./report.options";
import { Utilitaries } from "../../../../business";

const { Text } = Typography;

class ContributorTimeRegistration extends Component {
    componentDidMount() {
        if (!this.props.isDataLoadded) this.getContributors();
    }
    getContributors = (obj) => {
        this.setState({ fecthingContributors: true });
        let params = {
            accao: "lista",
            lista: "qa_registo_ponto_lista_colab",
            pagina: this.state.currentPage,
            resultados_pagina: this.state.pageSize,
        };
        if (obj) params = obj;
        this.props.selectContributors(params).then((sucess) => {
            if (!sucess) message.error("Ocorreu um erro");
            this.setState({ fecthingContributors: false });
        });
    };
    /** handle state */
    state = {
        showDrawer: false,
        fecthingContributors: false,
        fetchingTimeRegister: false,
        currentPage: 1,
        pageSize: 10,
        showDetails: false,
        timeRegisterByContributor: {},
        contributorName: "",
        contributorId: "",
    };
    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) => {
            this.getRegisterTimeWithPagination(1, 10);
            return true;
            // return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => {
            const texto = typeof text !== "object" ? text : "";
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={texto.toString()}
                />
            );
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    /****************************** */
    /** handle table columns */
    contributorsColumn = [
        {
            title: "Nome",
            dataIndex: "nome",
            width: "100%",
            ...this.getColumnSearchProps("nome"),
        },
    ];
    pointRegistryColumn = [
        {
            title: "Data / Hora do registo",
            dataIndex: "system_data_entrada",
            ...this.getColumnSearchProps("system_data_entrada"),
            render: (text, record) => {
                return typeof text === "object" ? (
                    <span>{`${record.data_registo} ${record.user_data_entrada}`}</span>
                ) : (
                    <span>{text}</span>
                );
            },
        },
        {
            title: "Data / Hora Entrada",
            dataIndex: "user_data_entrada",
            ...this.getColumnSearchProps("user_data_entrada"),
            render: (text, record) => {
                if (typeof record.system_data_entrada === "object")
                    return <span>{`${record.data_dia} ${Utilitaries.toString(text)}`}</span>;
                else
                    return (
                        <div>
                            {record.system_data_entrada === record.user_data_entrada ? (
                                <span>{text}</span>
                            ) : (
                                <span style={{ color: "#1890ff" }}>{text}</span>
                            )}
                        </div>
                    );
            },
        },
        {
            title: "Data / Hora Saída",
            dataIndex: "user_data_saida",
            ...this.getColumnSearchProps("user_data_saida"),
            render: (text, record) => {
                if (typeof record.system_data_saida === "object")
                    return (
                        <span>
                            {typeof record.data_dia !== "object"
                                ? `${record.data_dia} ${Utilitaries.toString(text)}`
                                : ""}
                        </span>
                    );
                else
                    return (
                        <div>
                            {record.system_data_saida === record.user_data_saida ? (
                                <span>{text}</span>
                            ) : (
                                <span style={{ color: "#1890ff" }}>{text}</span>
                            )}
                        </div>
                    );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            align: "center",
            render: (text, record) => (
                <Button onClick={() => this.showDetailsTimeRegistration(record)} type="link">
                    Detalhes
                </Button>
            ),
        },
    ];
    showDetailsTimeRegistration = (record) => {
        this.setState({
            showDetails: true,
            detailsData: {
                ...record,
            },
        });
    };
    closeDetailsTimeRegistration = () => {
        this.setState({
            showDetails: false,
        });
    };
    /** customize render empty */
    customRenderEmpty = () => {
        if (this.state.contributorName === "") {
            return (
                <div style={{ textAlign: "center" }}>
                    <Icon type="smile" style={{ fontSize: 30 }} />
                    <p>Seleciona um funcionário/a para ver o registo de ponto</p>
                </div>
            );
        } else {
            return (
                <div style={{ textAlign: "center" }}>
                    <Icon type="inbox" style={{ fontSize: 30 }} />
                    <p>{`${this.state.contributorName} não fez nenhum registo de ponto`}</p>
                </div>
            );
        }
    };
    /** handle row click */
    onRowClick = (record) => {
        this.setState({ fetchingTimeRegister: true });
        this.props
            .selectPointRegisterByContributor(
                {
                    accao: "lista",
                    lista: "qa_registo_ponto",
                    username: record.username,
                    pagina: 1,
                    resultados_pagina: 10,
                },
                record.id
            )
            .then((sucess) => {
                if (sucess)
                    this.setState({
                        fetchingTimeRegister: false,
                        contributorName: record.nome,
                        contributorId: record.id,
                        username: record.username,
                        timeRegisterByContributor: {
                            ...this.props.timeRegisterByContributor[record.id].pointRegister,
                        },
                    });
                else {
                    this.setState({
                        fetchingTimeRegister: false,
                    });
                }
            });
    };
    /** paginations functions */
    onShowSizeChange = (current, pageSize) => {
        this.setState({ currentPage: current, pageSize }, () => this.getContributors());
    };
    handlePageChange = (page, pageSize) => {
        this.setState({ currentPage: page, pageSize }, () => this.getContributors());
    };
    onShowSizeChangeForTimeRegister = (current, pageSize) => {
        this.getRegisterTimeWithPagination(current, pageSize);
    };
    handlePageChangeForTimeRegister = (page, pageSize) => {
        this.getRegisterTimeWithPagination(page, pageSize);
    };
    getRegisterTimeWithPagination = (page, pageSize) => {
        this.setState({ fetchingTimeRegister: true });
        this.props
            .selectPointRegisterByContributor(
                {
                    accao: "lista",
                    lista: "qa_registo_ponto",
                    username: this.state.username,
                    pagina: page,
                    resultados_pagina: pageSize,
                },
                this.state.contributorId
            )
            .then((sucess) => {
                if (sucess)
                    this.setState({
                        fetchingTimeRegister: false,
                        timeRegisterByContributor: {
                            ...this.props.timeRegisterByContributor[this.state.contributorId].pointRegister,
                        },
                    });
                else {
                    this.setState({
                        fetchingTimeRegister: false,
                    });
                }
            });
    };
    /** right drawer */
    showDrawer = () => {
        this.setState({
            showDrawer: true,
        });
    };
    onClose = () => {
        this.setState({
            showDrawer: false,
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        let obj = {
            accao: "lista",
            lista: "qa_registo_ponto_lista_colab",
            pagina: this.state.currentPage,
            resultados_pagina: this.state.pageSize,
        };
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val[0]) obj["filtro_" + key] = val[0];
            });
        }
        if (sorter.columnKey) {
            const value = sorter.order === "ascend" ? "ASC" : "DESC";
            obj["ordenacao_" + sorter.columnKey] = value;
        }
        this.getContributors(obj);
    };

    render() {
        return (
            <>
                {this.state.showDrawer && (
                    <MenuOptionsToGeneratePdfReport show={this.state.showDrawer} hidde={this.onClose.bind(this)} />
                )}

                {this.state.showDetails && (
                    <DetailsContributorsTimeRegistration
                        isVisible={this.state.showDetails}
                        detailsData={this.state.detailsData}
                        closeModal={this.closeDetailsTimeRegistration.bind(this)}
                    />
                )}
                <PageHeader title="Listagem de Registo de Ponto" />
                <Row gutter={20}>
                    <Col xs={24} sm={8}>
                        <Table
                            size="small"
                            style={{ cursor: "pointer" }}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: () => {
                                        this.onRowClick(record);
                                    },
                                };
                            }}
                            rowKey={(record) => record.id}
                            loading={this.state.fecthingContributors}
                            columns={this.contributorsColumn}
                            dataSource={this.props.contributors}
                            pagination={false}
                            onChange={this.handleTableChange}
                        />
                        {this.props.isDataLoadded ? (
                            <Pagination
                                style={{
                                    float: "right",
                                    marginBottom: 16,
                                    marginTop: 16,
                                }}
                                showSizeChanger
                                size="small"
                                onChange={this.handlePageChange}
                                onShowSizeChange={this.onShowSizeChange}
                                defaultPageSize={parseInt(this.props.defaultPageSize)}
                                defaultCurrent={parseInt(this.props.defaultCurrent)}
                                total={parseInt(this.props.totalRegisto)}
                            />
                        ) : null}
                    </Col>

                    <Col xs={24} sm={16}>
                        <Text>
                            <b>Registo de Ponto</b>
                        </Text>
                        <Row gutter={20}>
                            <Form layout="vertical">
                                <Col xs={16}>
                                    <Form.Item label="Nome">
                                        <Input value={this.state.contributorName} readOnly />
                                    </Form.Item>
                                </Col>
                                <Col xs={8}>
                                    <Form.Item label="Gerar Relatório">
                                        <Button
                                            type="primary"
                                            onClick={this.showDrawer}
                                            style={{
                                                marginBottom: 16,
                                            }}
                                        >
                                            <Icon type="file-pdf" style={{ marginRight: 12 }} />
                                            Exportar para pdf
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Form>
                        </Row>
                        <ConfigProvider renderEmpty={this.customRenderEmpty}>
                            <Table
                                rowKey={(record) => record.id}
                                size="small"
                                loading={this.state.fetchingTimeRegister}
                                columns={this.pointRegistryColumn}
                                dataSource={this.state.timeRegisterByContributor.data}
                                pagination={false}
                            />
                        </ConfigProvider>
                        {Object.entries(this.state.timeRegisterByContributor).length !== 0 ? (
                            <Pagination
                                style={{
                                    float: "right",
                                    marginBottom: 16,
                                    marginTop: 16,
                                }}
                                showSizeChanger
                                size="small"
                                onChange={this.handlePageChangeForTimeRegister}
                                onShowSizeChange={this.onShowSizeChangeForTimeRegister}
                                defaultPageSize={parseInt(
                                    this.state.timeRegisterByContributor.attributes.resultados_pagina
                                )}
                                defaultCurrent={parseInt(this.state.timeRegisterByContributor.attributes.pagina)}
                                total={parseInt(this.state.timeRegisterByContributor.attributes.resultados)}
                            />
                        ) : null}
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contributors: Object.values(state.contributorsTimeRegistration.domain.byId),
        isDataLoadded: state.contributorsTimeRegistration.app.isFetched,
        totalRegisto: state.contributorsTimeRegistration.domain.metaData.attributes.resultados,
        defaultCurrent: state.contributorsTimeRegistration.domain.metaData.attributes.pagina,
        defaultPageSize: state.contributorsTimeRegistration.domain.metaData.attributes.resultados_pagina,
        timeRegisterByContributor: state.contributorsTimeRegistration.domain.byId,
    };
};

const mapDispatchToProps = {
    selectContributors,
    selectPointRegisterByContributor,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContributorTimeRegistration);
