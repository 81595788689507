import { Button, Form, Input, Popconfirm, Radio, Row, Table } from "antd";
import React from "react";
import { DataChangeProps, FormDataProps } from "../utils/types";
import moment from "moment";

interface IProps extends DataChangeProps {
    formData: FormDataProps;
}

const table_key1 = "horas_extras";
const table_key2 = "hora_extra";

export function OverTime({ formData, handleAdd, handleDelete, onChange }: IProps) {
    const columns: any[] = [
        {
            title: "Data",
            dataIndex: "data",
            width: "15%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "data",
                                    value: e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Hora Inicial",
            dataIndex: "hora_ini",
            width: "15%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "hora_ini",
                                    value: e.target.value,
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("HH:mm") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Hora Final",
            dataIndex: "hora_fim",
            width: "15%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "hora_fim",
                                    value: e.target.value,
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("HH:mm") : undefined}
                            // format={"HH:mm"}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Tempo Extra",
            dataIndex: "tempo",
            width: "10%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "tempo",
                                    value: e.target.value,
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("HH:mm") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Observações",
            dataIndex: "observacoes",
            width: "20%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input.TextArea
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "observacoes",
                                    value: e.target.value,
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text}
                            placeholder="Introduza observações"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Registo",
            dataIndex: "registo",
            width: "15%",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Radio.Group
                        size="small"
                        buttonStyle="solid"
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        onChange={(e) =>
                            onChange({
                                dataIndex: "registo",
                                value: e.target.value,
                                row_key: record.key,
                                table_key1,
                                table_key2,
                            })
                        }
                        defaultValue={text}
                    >
                        <Radio.Button value="Externo">Externo</Radio.Button>
                        <Radio.Button value="Local">Local</Radio.Button>
                    </Radio.Group>
                );
            },
        },
        {
            title: "Ações",
            dataIndex: "actions",
            key: "actions",
            width: "10%",
            render: (text: any, record: any) => (
                <Popconfirm title="Remover a linha?" onConfirm={() => handleDelete(record.key, table_key1, table_key2)}>
                    <a
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        href="!#"
                        className="qa-text-del"
                    >
                        Remover
                    </a>
                </Popconfirm>
            ),
        },
    ];
    return (
        <>
            <Row>
                <Button
                    onClick={() => handleAdd(table_key1, table_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                rowKey={(record: any) => record.key}
                size="middle"
                columns={columns}
                dataSource={formData.horas_extras.hora_extra}
            />
        </>
    );
}
