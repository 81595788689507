import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Icon, Button, List, Divider, Tag } from "antd";
import moment from "moment";

import { sendHttpRequest } from "../http.request";
import { Session, Utilitaries } from "../../business";
import { baseUrl } from "../../constants/enviroment";
import Visualization from "../Visualization/index";
import content from "../Visualization/see";
import { toArray, downloadFile } from "../util";

const MessageView = (props) => {
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(false);

    // switch visualization model status
    const [visualizationData, setVisualizationData] = useState({
        estado: "",
        data: [],
    });

    useEffect(() => {
        if (!props.visible) {
            return;
        }

        const formData = new FormData();
        formData.append("accao", "consultar-by-id");
        formData.append("id", props.item.id);
        setLoading(true);
        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            //
            if (jsonResponse.erro) {
                setMessage({});
            } else {
                props.getBadge();
                setMessage(jsonResponse);
                props.changeLocalStatus(props.item.id);
                setLoading(false);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item.id]);

    const modalFooter = () => (
        <div>
            <Button onClick={() => props.forward(message.mensagem)}>Reencaminhar</Button>
            {message &&
                message.mensagem &&
                !Utilitaries.isEmpty(message?.mensagem?.utilizador) &&
                Utilitaries.toString(message.mensagem.utilizador) !== Session.getUsername() && (
                    <Button onClick={() => props.reply(message.mensagem)}>Responder</Button>
                )}
        </div>
    );

    const showVisualization = (id, estado) => {
        setVisualizationData({
            estado,
            data: toArray(message.visualizacao),
        });
    };

    // const downloadDocument =()=>{}

    const downloadDocument = (e, file) => {
        e.preventDefault();
        const params = {
            accao: "anexo",
            id: file.id,
            downloadInBrowser: true,
        };
        downloadFile("/geremensagens.php", params);

        // const formData = new FormData();
        // formData.append("accao", "anexo");
        // formData.append("id", file.id);

        // var request = new XMLHttpRequest();
        // request.open("POST", baseUrl + "/Gestor/geremensagens.php", true);

        // request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
        // request.responseType = "blob";

        // request.onload = function () {
        //     // Only handle status code 200
        //     if (request.status === 200) {
        //         // Try to find out the filename from the content disposition `filename` value
        //         var disposition = request.getResponseHeader("content-disposition");

        //         var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);

        //         var filename = matches != null && matches[1] ? matches[1] : file.nome_ficheiro;

        //         // The actual download
        //         var blob = new Blob([request.response], {
        //             type: request.getResponseHeader("content-type"),
        //         });

        //         var link = document.createElement("a");
        //         link.href = window.URL.createObjectURL(blob);
        //         link.download = filename;

        //         document.body.appendChild(link);

        //         link.click();

        //         document.body.removeChild(link);
        //     }

        // };

        // request.send(formData);

        // request.send("accao=anexo,id=" + file.id);
        // setLoading(true);
        // sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then(
        //     jsonResponse => {
        //
        //         // if (jsonResponse.erro) {
        //         //     setMessage({});
        //         // } else {
        //         //     setMessage(jsonResponse);
        //         //     setLoading(false);
        //         // }
        //     }
        // );
    };

    return (
        <>
            {/* Visualization */}
            <Visualization
                visible={visualizationData.data.length > 0}
                onCancel={() => setVisualizationData({ estado: "", data: [] })}
                {...visualizationData}
            />
            <Modal
                title={"Mensagem " + props.type}
                visible={props.visible}
                width="50%"
                onOk={() => {}}
                onCancel={() => props.onCancel(false)}
                footer={modalFooter()}
            >
                {loading ? (
                    <Icon type="loading" />
                ) : (
                    <Row>
                        <Col span={4} style={{ marginTop: 8 }}>
                            {"De:"}
                        </Col>
                        <Col span={20} style={{ marginTop: 8 }}>
                            {Utilitaries.toString(message?.mensagem?.utilizador)}
                        </Col>

                        {message.visualizacao && (
                            <>
                                <Col span={6} style={{ marginTop: 8 }}>
                                    {"Para:"}
                                </Col>
                                <Col span={18} style={{ marginTop: 8, cursor: "pointer" }}>
                                    <>{content(toArray(message.visualizacao), null, showVisualization)}</>
                                </Col>
                            </>
                        )}

                        <Col span={4} style={{ marginTop: 8 }}>
                            {"Em:"}
                        </Col>
                        <Col span={20} style={{ marginTop: 8 }}>
                            {message.mensagem ? moment(message.mensagem.data).calendar() : ""}
                        </Col>
                        {message.cc_nome && (
                            <>
                                <Col span={4} style={{ marginTop: 8 }}>
                                    {"CC:"}
                                </Col>
                                <Col span={20} style={{ marginTop: 8 }} className="cc_container">
                                    {Utilitaries.toArray(message.cc_nome, ";").map((name) => {
                                        if (Utilitaries.isEmpty(name)) return null;
                                        return <Tag className="cc_itens">{name}</Tag>;
                                    })}
                                </Col>
                            </>
                        )}

                        <Col span={4} style={{ marginTop: 8 }}>
                            {"Assunto:"}
                        </Col>
                        <Col span={20} style={{ marginTop: 8 }}>
                            <pre style={{ whiteSpace: "pre-wrap" }}>
                                {message.mensagem ? message.mensagem.assunto : ""}
                            </pre>
                        </Col>

                        <Col span={4} style={{ marginTop: 8 }}>
                            {"Mensagem:"}
                        </Col>
                        <Col span={20} style={{ marginTop: 8, marginBottom: 8 }}>
                            <pre style={{ whiteSpace: "pre-wrap" }}>
                                {message.mensagem ? message.mensagem.mensagem : ""}
                            </pre>
                        </Col>

                        {message.anexos && (
                            <>
                                <Divider type="horizontal" />
                                <Col span={4} style={{ marginTop: 8 }}>
                                    Anexos
                                </Col>
                                <Col span={20} style={{ marginTop: 8 }}>
                                    <List
                                        size="small"
                                        className="demo-loadmore-list"
                                        itemLayout="horizontal"
                                        dataSource={Array.isArray(message.anexos) ? message.anexos : [message.anexos]}
                                        renderItem={(item) => (
                                            <List.Item
                                                actions={[
                                                    <a
                                                        href="/"
                                                        onClick={(e) => downloadDocument(e, item)}
                                                        key="list-loadmore-edit"
                                                    >
                                                        Descarregar
                                                    </a>,
                                                ]}
                                            >
                                                <List.Item.Meta
                                                    className="qa_anexo_name"
                                                    style={{ width: 200 }}
                                                    description={item.nome_ficheiro}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                )}
            </Modal>
        </>
    );
};

export default MessageView;
