import React, { useEffect, useState } from "react";
import { Row, Table, Button, Icon } from "antd";

const TranslationTabPane = ({ initialFormData }) => {
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        setDataSource(initialFormData.languages);
    }, []);

    const column = [
        {
            title: "Língua",
            dataIndex: "designacao",
        },
        {
            title: "Sigla",
            dataIndex: "lingua",
        },
        // {
        //     title: "Tradução",
        //     dataIndex: "existe",
        //     render: () => {
        //         return (
        //             <Button>
        //                 <Icon type="form" />
        //             </Button>
        //         );
        //     },
        // },
    ];

    return (
        <div>
            <Row>
                <Table pagination={false} columns={column} dataSource={dataSource} />
            </Row>
        </div>
    );
};

export default TranslationTabPane;
