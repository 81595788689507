import moment from "moment";

import ACTIONS_TYPES from "../constants/index";
import { sendHttpRequest } from "../../http.request";

export const changeForumModalState = (state) => async (dispatch) =>
    dispatch({
        type: ACTIONS_TYPES.CHANGE_MODAL_FORUM_STATE,
        payload: state,
    });

export const changeForumConfModalState =
    (state, form = null) =>
    async (dispatch) =>
        dispatch({
            type: ACTIONS_TYPES.CHANGE_MODAL_FORUM_CONF_STATE,
            payload: {
                state,
                form,
            },
        });

export const createRooms = (rooms) => async (dispatch, getState) => {
    const formData = new FormData();

    formData.append("accao", "guardar-sala");
    formData.append("salas", JSON.stringify(rooms));

    return await sendHttpRequest("POST", "/Gestor/gereforum.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            // dispatch({
            //     type: ACTIONS_TYPES.SAVE_ROOMS,
            //     payload: jsonResponse
            // });
            return true;
        }
    });
};

export const saveTopicsPermissions = (permissions) => async (dispatch, getState) => {
    const formData = new FormData();

    formData.append("accao", "guardar-topico-permissao");
    formData.append("permissao", JSON.stringify(permissions));

    return await sendHttpRequest("POST", "/Gestor/gereforum.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            return true;
        }
    });
};

export const getUserForumRooms = () => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "get-salas");

    return await sendHttpRequest("POST", "/Gestor/gereforum.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.USER_ROOMS,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const getForumRooms = () => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "get-all");

    return await sendHttpRequest("POST", "/Gestor/gereforum.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.ROOMS,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const getTopicsPermission = () => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "get-topico-permissao");

    return await sendHttpRequest("POST", "/Gestor/gereforum.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.TOPICS_PERMISSION,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const getMessages = (sala, filter) => async (dispatch, getState) => {
    // const { selected, filter } = getState().chat.room;
    const formData = new FormData();

    formData.append("accao", "get-message");
    formData.append("sala", sala);

    if (filter) {
        let start = "",
            end = "";

        if (filter.data) {
            start = moment(filter.data[0]).format("YYYY-MM-DD");
            end = moment(filter.data[1]).format("YYYY-MM-DD");

            formData.append("start", start);
            formData.append("end", end);

            delete filter.data;
        }
        Object.entries(filter).forEach(([key, value]) => {
            if (value) formData.append(key, value);
        });
    }

    return await sendHttpRequest("POST", "/Gestor/gereforum.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.MESSAGES,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const getRoomPermission = (sala) => async (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const formData = new FormData();

        formData.append("accao", "permissao-topico");
        formData.append("sala", sala);

        sendHttpRequest("POST", "/Gestor/gereforum.php", formData)
            .then((jsonResponse) => {
                if (jsonResponse.erro) resolve(false);
                else {
                    return resolve(jsonResponse.permissao === "t");
                }
            })
            .catch((e) => reject(false));
    });

export const getRoomUsers = (sala) => async (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const formData = new FormData();

        formData.append("accao", "get-sala-users");
        formData.append("sala", sala);

        sendHttpRequest("POST", "/Gestor/gereforum.php", formData)
            .then((jsonResponse) => {
                if (jsonResponse.erro) resolve(false);
                else {
                    return resolve(
                        Array.isArray(jsonResponse.linha)
                            ? jsonResponse.linha
                            : jsonResponse.linha && Object.keys(jsonResponse.linha).length === 1
                            ? [jsonResponse.linha]
                            : []
                    );
                }
            })
            .catch((e) => reject(false));
    });

export const sendMessage =
    ({ message, fileList, sala }) =>
    async (dispatch, getState) => {
        const formData = new FormData();

        formData.append("accao", "send-message");
        formData.append("message", message || "");
        fileList = fileList || [];
        fileList.forEach((file) => {
            formData.append("files[]", file);
        });
        formData.append("sala", sala);

        return await sendHttpRequest("POST", "/Gestor/gereforum.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) return false;
            else {
                dispatch({
                    type: ACTIONS_TYPES.ADD_MESSAGES,
                    payload: jsonResponse,
                });
                return true;
            }
        });
    };
