import React from "react";
import { Col, Card } from "antd";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import EmptyData from "./emptyData";
import { TotalMultipleData as TotalValues } from "./totalValues";
import ChartHelp from "./chartHelp";

const MultipleBarChart = ({ metadata, dataSource }) => {
    let fields = [],
        data = [],
        total = [],
        labelsTotal = [];

    if (dataSource && dataSource.length !== 0) {
        //get labels
        dataSource.forEach((item) => {
            if (metadata.showTotal) labelsTotal.push(item.label);
            item.data.forEach((element) => {
                if (!fields.includes(element.label)) fields.push(element.label);
            });
        });

        if (metadata.sortType === "number")
            fields = fields.sort((a, b) => {
                if (a === "Sem data") return -1;
                else return a - b;
            });

        //get initial values to labels
        const initialValuesChart = fields.reduce(
            (accumulator, currentValue) => ({
                ...accumulator,
                [currentValue]: 0,
            }),
            {}
        );

        data = dataSource.map((item) => {
            let itemChart = {};
            let totalItens = 0;
            if (item.data) {
                const dataFormat = item.data.map((element) => ({
                    ...element,
                    valor: Number(element.valor),
                }));
                totalItens = dataFormat.reduce((accumulator, currentValue) => accumulator + currentValue.valor, 0);

                itemChart = dataFormat.reduce((accumulator, currentValue) => {
                    return {
                        ...accumulator,
                        [currentValue.label]: currentValue.valor,
                    };
                }, initialValuesChart);
            }

            return {
                name: item.label,
                ...itemChart,
                totalItens,
            };
        });
        total = data.map((item) => item.totalItens);
    }

    const ds = new DataSet();
    const dv = ds.createView().source(data);
    dv.transform({
        type: "fold",
        fields: fields,
        key: "label",
        value: "valor",
        retains: ["name", "totalItens"],
    });
    const cols = {
        label: {
            alias: metadata.horizontalLabel || "Elementos",
        },
        valor: {
            alias: metadata.verticalLabel || "Valor",
        },
    };
    return (
        <Col xs={24} md={12}>
            <Card
                size="small"
                title={metadata.title ? metadata.title : " "}
                extra={metadata.help ? <ChartHelp text={metadata.help} /> : null}
                className={"qa-title-dashboard"}
            >
                {fields.length === 0 || !dataSource || dataSource.length === 0 ? (
                    <EmptyData />
                ) : (
                    <Chart height={344} data={dv} scale={cols} padding={"auto"} forceFit>
                        <Axis name="label" title />
                        <Axis name="valor" title />
                        <Legend />
                        <Tooltip
                            crosshairs={{
                                type: "y",
                            }}
                        />
                        <TotalValues labels={labelsTotal} total={total} />
                        <Geom
                            type="interval"
                            position="label*valor"
                            color={"name"}
                            adjust={[
                                {
                                    type: "dodge",
                                    marginRatio: 1 / 32,
                                },
                            ]}
                            tooltip={[
                                "key*valor*name*totalItens",
                                (key, valor, name, totalItens) => {
                                    valor = valor || 0;
                                    return {
                                        name: name + ": " + valor + "/" + totalItens,
                                        value: totalItens ? ((valor * 100) / totalItens).toFixed(2) + "%" : "",
                                    };
                                },
                            ]}
                        />
                    </Chart>
                )}
            </Card>
        </Col>
    );
};

export default MultipleBarChart;
