import React, { useContext } from "react";
import { Col, Form, Button, message, Modal } from "antd";
import { QADivider, QAToolTip } from "./";
import FormContext from "../context/FormContext";
import { Session, Utilitaries } from "../../../business";
import { baseUrl, BASE_URL } from "../../../constants/enviroment";
import { input_validations } from "../utils/validations";
import * as utils from "../../util";
import { getInputLabel } from "../utils/functions";
import { sendHttpRequest } from "../../http.request";
import { LineBreak } from "./lineBreak";

const getParams = (inputAttribute) => {
    let paramsKey = undefined;

    if (inputAttribute["@accao_gera"]) {
        paramsKey = "@accao_gera";
    } else if (inputAttribute["@accao_gera5"]) {
        paramsKey = "@accao_gera5";
    } else if (inputAttribute["@accao_gera1"]) {
        paramsKey = "@accao_gera1";
    } else if (inputAttribute["@accao_gera8"]) {
        paramsKey = "@accao_gera8";
    } else if (inputAttribute["@accao_gera2"]) {
        paramsKey = "@accao_gera2";
    } else if (inputAttribute["@accao_gera7"]) {
        paramsKey = "@accao_gera7";
    } else if (inputAttribute["@accao_gera3"]) {
        paramsKey = "@accao_gera3";
    } else if (inputAttribute["@accao_gera6"]) {
        paramsKey = "@accao_gera6";
    }

    return paramsKey;
};

export default function QALink({ inputAttribute, field, visible, renderGroup, colSize, ...inputProps }) {
    const { formData, justSee } = useContext(FormContext);

    // eslint-disable-next-line no-unused-vars
    const auto_preenche_valor = async (query, ...params) => {
        const fd = new FormData();
        fd.append("query", query);

        let nullValues = false;

        params
            .map((it) => it.replace(/[{}\s]/g, "").split(","))
            .forEach((it) => {
                fd.append(it[0], eval(it[1]) || "");

                if (eval(it[1]) === undefined) {
                    if (!(query === "gera_link_saft" && (it[0] === "nif" || it[0] === "cliente"))) {
                        nullValues = true;
                        message.warning(`${it[0]} invalido`);
                    }
                }
            });

        if (nullValues) {
            return;
        }

        if (query?.startsWith("gera_link") || query?.startsWith("orbis_link")) {
            try {
                const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", fd);
                const result = data?.result?.[0];
                let link = result?.link;

                if (!link) {
                    const keys = Object.keys(result || {});

                    if (keys.length === 1) {
                        link = result[keys[0]];
                    }
                }
                console.log(`${BASE_URL}/${link}&token=Bearer ${Session.getToken()}`);

                if (link) {
                    window.open(`${BASE_URL}/${link}&token=Bearer ${Session.getToken()}`, "_blank");
                } else {
                    message.error("Erro ao gerar link");
                }
            } catch (error) {
                message.error("Erro ao gerar link");
            }
        } else {
            downloadFile(baseUrl + "/Gestor/gererelatorios.php", query, fd);
        }
    };

    const downloadFile = (url, query = undefined, fd) => {
        try {
            var request = new XMLHttpRequest();
            request.open("POST", url, true);
            // request.open("POST", baseUrl + "/Gestor/geradmrsocial.php", true);

            request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
            request.responseType = "blob";

            request.onload = function () {
                // Only handle status code 200
                if (request.status === 200) {
                    // Try to find out the filename from the content disposition `filename` value
                    const disposition = request.getResponseHeader("content-disposition");
                    //
                    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);

                    const filename = matches != null && matches[1] ? matches[1].replace(/"/g, "") : query;

                    //
                    //
                    //     'request.getResponseHeader("content-type")',
                    //     request.getResponseHeader("content-type")
                    // );

                    // The actual download
                    const blob = new Blob([request.response], {
                        type: request.getResponseHeader("content-type"),
                    });

                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;

                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);
                }

                // some error handling should be done here...
            };

            request.send(fd);
            // const data = await sendHttpRequest(
            //   "POST",
            //   "/Gestor/geradmrsocial.php",
            //   fd,
            // );
            //

            // const _value = data.result[0].dias_disp;
            //

            // setValue(_value);

            // changeInputValue({
            //   value: _value, // valor selecionado  in value
            //   key: field, // Nome do campo
            //   inputAttribute, // input atributos
            //   ...inputProps, // propriedades da tabela
            //   callback() {
            //     hasError({ [field]: _value });
            //   },
            // });
        } catch (error) {
            //
        }
    };

    if (
        inputProps &&
        inputProps.items &&
        inputProps.items.data &&
        inputProps.items.data.tamanho &&
        inputProps.items.data.tamanho === "Eliminado"
    ) {
        return null;
    }

    async function onItemClick() {
        try {
            if (inputAttribute?.["@controller"]) {
                let params = inputAttribute["@param_popup"];

                let queryParams = "";

                params = params.replace(/[\[\]]/g, "");
                params.split(";").forEach((it) => {
                    if (it) {
                        const item = it.split(",");
                        let value = item[1].replace("../", "").replace("//", "");

                        value = formData[value.trim()] || "";

                        if (value === "t") {
                            value = "true";
                        } else if (value === "f") {
                            value = "false";
                        }

                        queryParams = `${queryParams}${queryParams.length === 0 ? "" : "&"}${item[0]}=${value}`;
                    }
                });

                if (queryParams.length > 0) {
                    queryParams = `${queryParams}&`;
                }

                window.open(
                    `${baseUrl}/Gestor/${
                        inputAttribute["@controller"]
                    }?${queryParams}token=Bearer ${Session.getToken()}`,
                    "_blank"
                );
                return;
            }

            if (inputAttribute && inputAttribute["@url"]) {
                window.open(`${baseUrl}/Gestor${inputAttribute["@url"]}&token=Bearer ${Session.getToken()}`, "_blank");

                return;
            }

            if (inputProps && inputProps.items) {
                const { gerar_relatorio, download, tamanho } = inputProps.items.data;

                if (gerar_relatorio) {
                    window.open(`${BASE_URL}${gerar_relatorio}&token=${`Bearer ${Session.getToken()}`}`, "_blank");
                } else if (download && typeof download === "object") {
                    if (tamanho && tamanho === "Eliminado") {
                        return;
                    }

                    const { url, ...rest } = download;

                    window.open(
                        `${baseUrl}/Gestor${url}?accao=download&ficheiro=${
                            rest.ficheiro
                        }&token=Bearer ${Session.getToken()}`,
                        "_blank"
                    );

                    // const fd = new FormData()

                    // Object.entries(rest).forEach((item) => {
                    // 	fd.append(item[0], item[1])
                    // })

                    // utils.downloadFileV2(url, rest);
                    // utils.downloadFile(url, rest)
                    // downloadFile(`${BASE_URL}${url}`, "", fd)
                } else {
                    window.open(
                        `${BASE_URL}${inputProps.items.data[field]}&token=${`Bearer ${Session.getToken()}`}`,
                        "_blank"
                    );
                }

                return;
            }

            const params = getParams(inputAttribute);
            if (!params) {
                return;
            }

            let expression = input_validations({
                item: inputAttribute,
                relKey: params,
                formData,
                functions: {},
                returnExpression: true,
            });

            expression = expression.replace(/{[\w|\[|\]|,|\s|'|/|?|=|_|&|\.|-]+}|\w+(?=,)/g, '"$&"');

            if (expression.substr(expression.length - 2) === '}"') {
                expression = `${expression})`;
            }

            eval(expression);
        } catch (error) {}
    }

    const RenderButtom = () => <Button onClick={() => onItemClick()} icon="link" disabled={justSee} />;

    const { inTable } = inputProps;

    if (!visible) {
        return null;
    }
    return inTable ? (
        RenderButtom()
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            {<LineBreak item={inputAttribute} />}
            <Col xs={colSize}>
                <Form.Item
                    label={<QAToolTip label={getInputLabel(inputAttribute, field)} help={inputAttribute["@ajuda"]} />}
                >
                    {RenderButtom()}
                </Form.Item>
            </Col>
        </>
    );
}
