import React, { Component } from "react";
import { Row, Table, Form, Input, Button, Popconfirm, Radio } from "antd";
import moment from "moment";

const { TextArea } = Input;

class OverTimeTabPane extends Component {
    /** handle state */
    state = {
        dataSource: [],
        countDataSource: 1,
    };
    componentDidMount() {
        const { ficha_colaborador } = this.props;
        if (ficha_colaborador) {
            const { horas_extras } = ficha_colaborador;
            if (horas_extras.hora_extra) {
                const { hora_extra } = horas_extras;
                const dataSource = [];
                if (Array.isArray(hora_extra)) {
                    hora_extra.map((item, index) => {
                        dataSource.push({ key: index, ...item });
                    });
                } else if (hora_extra.id_hora_extra) {
                    dataSource.push({ key: 0, ...hora_extra });
                }
                const countDataSource = dataSource.length + 1;
                this.setState({ dataSource, countDataSource });
            }
        }
    }
    /** handle table columns */
    column = [
        {
            title: "Data",
            dataIndex: "data",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(e) =>
                                this.handleFieldsChange(
                                    "data",
                                    e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    record
                                )
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : null}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Hora Inicial",
            dataIndex: "hora_ini",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) => this.handleFieldsChange("hora_ini", e.target.value, record)}
                            defaultValue={text ? moment(text).format("HH:mm") : null}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Hora Final",
            dataIndex: "hora_fim",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) => this.handleFieldsChange("hora_fim", e.target.value, record)}
                            defaultValue={text ? moment(text).format("HH:mm") : null}
                            format={"HH:mm"}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Tempo Extra",
            dataIndex: "tempo",
            width: "10%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "HH:mm") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="time"
                            onChange={(e) => this.handleFieldsChange("tempo", e.target.value, record)}
                            defaultValue={text ? moment(text).format("HH:mm") : null}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Observações",
            dataIndex: "observacoes",
            width: "20%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <TextArea
                            onChange={(e) => this.handleFieldsChange("observacoes", e.target.value, record)}
                            defaultValue={text}
                            placeholder="Introduza observações"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Registo",
            dataIndex: "registo",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Radio.Group
                        size="small"
                        buttonStyle="solid"
                        style={{
                            zIndex: "-1",
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        onChange={(e) => this.handleFieldsChange("registo", e.target.value, record)}
                        defaultValue={text}
                    >
                        <Radio.Button value="Externo">Externo</Radio.Button>
                        <Radio.Button value="Local">Local</Radio.Button>
                    </Radio.Group>
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            key: "actions",
            width: "10%",
            render: (text, record) =>
                this.state.dataSource.length >= 1 ? (
                    <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => this.handleDelete(record)}>
                        <a
                            style={{
                                width: "100%",
                                margin: "0 auto",
                                padding: "0",
                            }}
                            href="!#"
                            className="qa-text-del"
                        >
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];
    handleFieldsChange = (dataIndex, value, record) => {
        record[dataIndex] = value;
        const { dataSource } = this.state;
        const index = dataSource.findIndex((item) => item.key === record.key);
        dataSource[index] = record;
        this.setState({ dataSource }, this.props.getHorasExtras(this.state.dataSource));
    };
    /** handle dynamic table rows */
    handleAdd = () => {
        const { countDataSource, dataSource } = this.state;
        const newData = {
            key: countDataSource,
        };
        this.setState({
            dataSource: [newData, ...dataSource],
            countDataSource: countDataSource + 1,
        });
    };
    handleDelete = (record) => {
        const dataSource = [...this.state.dataSource];
        this.setState(
            {
                dataSource: dataSource.filter((item) => item.key !== record.key),
            },
            this.props.getHorasExtras(this.state.dataSource)
        );
    };

    render() {
        return (
            <div>
                <Row>
                    <Button
                        xs={24}
                        sm={12}
                        onClick={this.handleAdd}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{ marginBottom: 16, float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Row>
                <Table
                    rowKey={(record) => record.key}
                    size="middle"
                    columns={this.column}
                    dataSource={this.state.dataSource}
                />
            </div>
        );
    }
}

export default OverTimeTabPane;
