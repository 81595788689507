import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Popconfirm, Divider, Table, message } from "antd";

import { select, remove } from "../../actions/hability";
import { getColumnSearchProps } from "../search.into.table.columns";
import { tableSort, tableFooter } from "../../../../components/util";
import ButtonsGroup from "./buttons.group";
import FormHability from "./form.hability";

const REPORT_PARAMS = {
    accao: "lista",
    lista: "habilidades",
    campos: "tipo_habilidade:400:Tipo de habilidade;designacao:400:Habilidade;",
    titulo: "Habilidades",
};

const Hability = ({ select, remove }) => {
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({});
    const [permission, setPermission] = useState({});
    const [loading, setLoading] = useState(false);

    const [showDeletedRecords, setShowDeletedRecords] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [reload, setReload] = useState(false);
    const [dataToEdit, setDataToEdit] = useState({});
    const [filters, setFilters] = useState({});
    /**
     * fecth "habilidades" from server
     */
    useEffect(() => {
        (async () => {
            setLoading(true);
            const response = await select({
                accao: "lista",
                lista: "habilidades",
                pagina: 1,
                resultados_pagina: 0,
                mostra_lista_eliminados: showDeletedRecords,
                ...filters,
            });

            if (response["@attributes"]) {
                setPermission(response["@attributes"]);
            }

            if (response["dados"] && response["dados"]["@attributes"]) {
                setPagination(response["dados"]["@attributes"]);
            }

            if (response["dados"] && response["dados"]["linha"]) {
                const { linha } = response["dados"];

                if (Array.isArray(linha)) setDataSource(linha);
                else if (Object.keys(linha).length > 0) setDataSource([linha]);
                else if (Object.keys(linha).length === 0) setDataSource([]);
            }
            setLoading(false);
        })();
    }, [showDeletedRecords, reload, filters]);

    const handleEdit = (record) => {
        setIsEdit(true);
        setDataToEdit(record);
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        const response = await remove({
            accao: "eliminar",
            lista: "habilidades",
            chave1: id,
        });

        if (response) {
            message.success("Sucesso");
            setReload(!reload);
        } else message.error("Ocorreu um erro");
    };

    /**
     * columns
     */
    const columns = [
        {
            title: "Tipo de habilidade",
            dataIndex: "tipo_habilidade",
            width: "30%",
            sorter: (a, b) => tableSort(a.tipo_habilidade, b.tipo_habilidade),
            sortDirections: ["descend", "ascend"],
            ...getColumnSearchProps("tipo_habilidade"),
        },
        {
            title: "Habilidade",
            dataIndex: "designacao",
            width: "50%",
            sorter: (a, b) => tableSort(a.designacao, b.designacao),
            sortDirections: ["descend", "ascend"],
            ...getColumnSearchProps("designacao"),
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "20%",
            render: (text, record) => {
                return record._eliminado_ === "t" ? (
                    <>
                        <Button
                            type="link"
                            style={{
                                padding: 0,
                            }}
                            onClick={() => handleEdit(record)}
                        >
                            Editar
                        </Button>
                        <Divider type="vertical" />
                        <Popconfirm
                            title="Tens certeza que desejas eliminar?"
                            okText="Ok"
                            cancelText="Não"
                            onConfirm={() => handleDelete(record.id_habilidade)}
                        >
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    color: "#fa541c",
                                }}
                            >
                                Eliminar
                            </Button>
                        </Popconfirm>
                    </>
                ) : null;
            },
        },
    ];

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        let ordernacao = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            const order = sorter.order !== "descend" ? "ASC" : "DESC";
            ordernacao = { [`ordenacao_${sorter.columnKey}`]: order };
        }

        // console.log(_pagination);
        setFilters({
            ...filters,
            ...obj,
            ...ordernacao,
        });

        // this.setState({ ...this.state, currentPage: 1, filter: { ...obj, ...ordernacao } }, () => this.getCompetence());
    };

    return (
        <>
            <ButtonsGroup
                showDeletedRecords={setShowDeletedRecords}
                deletedRecords={showDeletedRecords}
                handleOnClick={setShowModal}
                setIsEdit={setIsEdit}
                REPORT_PARAMS={REPORT_PARAMS}
                filter={filters}
            />

            <Table
                rowKey={(record) => record.id_habilidade}
                dataSource={dataSource}
                columns={columns}
                size="middle"
                footer={() => tableFooter(pagination.resultados || 0)}
                loading={loading}
                onChange={handleTableChange}
            />

            <FormHability
                showModal={showModal}
                setShowModal={setShowModal}
                isEdit={isEdit}
                setReload={setReload}
                reload={reload}
                dataToEdit={dataToEdit}
            />
        </>
    );
};

const mapDispatchToProps = {
    select,
    remove,
};

export default connect(null, mapDispatchToProps)(Hability);
