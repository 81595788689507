import { sendHttpRequest } from "../../../components/http.request";
import { validateResponse } from "../../../components/util";

export const getProcessWithPopupProcess = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista_processos_all");
    const response = await sendHttpRequest("POST", "/Gestor/gereconsulta.php", formData);
    if (response) {
        const list = validateResponse(response);
        return list.filter((item) => typeof item.nome !== "object");
    }
    return false;
};

export const getCustomers = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista");
    formData.append("lista", "dossier_clientes");
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });

    const response = await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData);
    if (response) {
        const metadata = response.dados["@attributes"];
        return {
            lista: validateResponse(response.dados),
            metadata: {
                resultados: Number(metadata.resultados),
                pagina: Number(metadata.pagina),
                visivel: metadata.visivel,
                resultados_pagina: Number(metadata.resultados_pagina),
                nomes_campos: metadata.nomes_campos,
            },
            pageAttributes: response["@attributes"],
        };
    }
    return false;
};

export const getDossier = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "selecao_cliente");
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });

    const response = await sendHttpRequest("POST", "/Gestor/geredossiercliente.php", formData);
    if (response) {
        const data = validateResponse(response);
        const lista = data.map((item) => ({
            ...item,
        }));
        const metadata = response["@attributes"];
        return {
            lista,
            metadata: {
                resultados: Number(metadata.resultados),
                pagina: Number(metadata.pagina),
                visivel: metadata.visivel,
                resultados_pagina: Number(metadata.resultados_pagina),
            },
        };
    }
    return false;
};
export const getClientDocuments = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "documents");
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });

    const response = await sendHttpRequest("POST", "/Gestor/geredossiercliente.php", formData);
    if (response) {
        const data = validateResponse(response);
        const lista = data.map((item, index) => ({
            key: index + 1,
            ...item,
        }));
        return lista;
    }

    return [];
};

export const getConfiguration = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista_configuracoes");
    const response = await sendHttpRequest("POST", "/Gestor/geredossiercliente.php", formData);
    if (response) {
        const list = validateResponse(response).filter((item) => typeof item.processo !== "object" && item.processo);
        return list.map((item) => ({
            ...item,
            tarefa: typeof item.tarefa === "object" ? null : item.tarefa,
        }));
    }
    return false;
};

export const getTasks = async (payload) => {
    if (!payload) return [];
    const formData = new FormData();
    formData.append("accao", "lista_tarefas_nome_externo");
    formData.append("processo", payload);
    const response = await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
    if (response) return validateResponse(response);
    return false;
};

export const saveConfiguration = async (payload) => {
    const formData = new FormData();

    const list = payload.map((item) => ({
        nome: item.processo,
        tarefa: item.tarefa,
    }));

    const obj = {};
    obj["processos"] = {
        processo: list,
    };
    formData.append("accao", "guardar_configuracoes");
    formData.append("dados", JSON.stringify(obj));
    const response = await sendHttpRequest("POST", "/Gestor/geredossiercliente.php", formData);
    if (response) {
        if (response.erro) return { status: false, message: response.erro };
        return { status: true };
    }
    return { status: false, message: "Ocorreu um erro" };
};
