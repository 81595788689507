import React from "react";
import { Button } from "antd";
 
const ButtonsGroup = (props, filter) => {

    return (
        <div className="qa-group-actions-right">
            {/* <Link to="/process/create/advanced">
				<Button type="primary" size="default">
					Elaborar
				</Button>
			</Link> */}

            <Button type="primary" size="default" onClick={() => props.showRegistarModal()}>
                Registar
            </Button>
            {/* 
      <Button onClick={() => {}} size="default" type="primary">
        Interação de Processos
      </Button> */}
        </div>
    );
};

export default ButtonsGroup;
