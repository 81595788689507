import React, { useEffect, useState } from "react";
import { PageHeader, Table, Input, Button, Icon, Row, Tooltip, Pagination, Col } from "antd";
import { getCustomers } from "./../actions/index";
import Dossier from "./dossier";
import Configuration from "./configuration";
import ButtonsGroup from "./buttons.group";
import { downloadFile } from "./../../../components/util";
import { Utilitaries } from "../../../business";

const getTableColumns = (nomes_campos, pageAttributes) => {
    if (!nomes_campos || !pageAttributes.headers) {
        return [];
    }
    const arrayHeaders = Utilitaries.toArray(pageAttributes.headers?.split(";"));
    const arrayColumns = Utilitaries.toArray(nomes_campos?.split(";"));

    const columns = [];
    const headersLength = arrayHeaders.length;
    let index = 0;
    for (const el of arrayColumns) {
        if (index === headersLength) {
            break;
        }
        if (el !== `id_${pageAttributes.chaves}`) {
            const columnText = arrayHeaders[index] === "Resposta Social" ? "Unidade Orgânica" : arrayHeaders[index];
            const column = { dataIndex: el, title: columnText };
            columns.push(column);
        }
        index++;
    }
    return columns;
};

const ClientDossier = ({ history }) => {
    const [dataSource, setDataSource] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [filter, setfilter] = useState({});
    const [columnsToRender, setColumnsToRender] = useState([]);

    const [modalDossier, setModalDossier] = useState({ isVisible: false });
    const [isVisibleConfiguration, setIsVisibleConfiguration] = useState(false);

    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });

    // Get form from API
    useEffect(() => {
        setLoadingData(true);
        const { sorter, ...objFilter } = filter;

        const values = {
            ...objFilter,
            pagina: pagination.page,
            resultados_pagina: pagination.perPage,
        };
        if (sorter) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }
        getCustomers(values).then((response) => {
            const listMetadata = response.metadata;
            const columnsToRender = getTableColumns(listMetadata.nomes_campos, response?.pageAttributes);
            setColumnsToRender(columnsToRender);
            setDataSource(response.lista);
            setMetadata(listMetadata);
            setLoadingData(false);
        });
    }, [pagination, filter]);

    const handleRedirect = ({ pathname, search }) => {
        history.push({
            pathname,
            search,
        });
    };

    //---------------------------- filter --------------------------------

    const getColumnSearchProps = (dataIndex) => {
        let searchInput = React.createRef();

        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys}
                        onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
            filteredValue: filter[`filtro_${dataIndex}`] || null,
        };
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };
    const getColumnSortProps = (dataIndex, sortedInfo) => ({
        sorter: true,
        sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
    });

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter(obj);
    };
    //---------------------------- endfilter -----------------------------

    const showModal = (record) => {
        setModalDossier({
            isVisible: true,
            clientNumber: record.cliente,
            clientName: record.nome,
        });
    };
    const hideModal = () => {
        setModalDossier({ isVisible: false });
    };

    const showConfiguration = () => {
        setIsVisibleConfiguration(true);
    };
    const hideConfiguration = () => {
        setIsVisibleConfiguration(false);
    };

    const sortedInfo = filter.sorter || {};

    let columns = columnsToRender.map((el) => {
        const obj = {
            ...el,
            ...getColumnSearchProps(el.dataIndex),
            ...getColumnSortProps(el.dataIndex, sortedInfo),
        };
        if (el.dataIndex === "observacoes") {
            return {
                ...obj,
                width: "30%",
                render: (text) => {
                    text = Utilitaries.toString(text);

                    return text.length >= 50 ? (
                        <Tooltip title={text}>
                            <div
                                style={{
                                    textOverflow: "ellipsis",
                                    width: "180px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                }}
                            >
                                {text}
                            </div>
                        </Tooltip>
                    ) : (
                        <div>{text}</div>
                    );
                },
            };
        }
        return obj;
    });

    columns.push({
        title: "Ações",
        dataIndex: "actions",
        width: "10%",
        render: (text, record) => (
            <>
                <Button
                    type="link"
                    style={{
                        padding: 0,
                    }}
                    onClick={() => showModal(record)}
                >
                    Consultar
                </Button>
            </>
        ),
    });

    const pageChange = (page, perPage) => {
        setPagination({
            page,
            perPage,
        });
    };

    const clearFormFilters = () => {
        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter({});
    };
    const exportList = async (saida) => {
        const { sorter, ...objFilter } = filter;

        const values = {
            ...objFilter,
            pagina: pagination.page,
            resultados_pagina: pagination.perPage,
        };
        if (sorter) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }
        const formExport = {};

        Object.entries(values).forEach(([key, val]) => {
            if (val) formExport[key] = val;
        });
        formExport.lista = "dossier_clientes";
        formExport.tipo_ficheiro = saida;
        return await downloadFile("/gereRelatorioListagen.php", formExport);
    };
    return (
        <>
            <PageHeader title="Listagem de clientes" />
            <Row>
                <Col xs={24} md={24}>
                    <Button
                        style={{
                            float: "right",
                            marginBottom: 16,
                            marginLeft: 8,
                        }}
                        onClick={showConfiguration}
                    >
                        Configurações
                    </Button>
                    <ButtonsGroup exportList={exportList} />
                    {Object.keys(filter).length !== 0 && (
                        <Button
                            onClick={clearFormFilters}
                            type="link"
                            size="default"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "right",
                            }}
                        >
                            Limpar filtros
                        </Button>
                    )}
                </Col>
            </Row>
            <Table
                size="small"
                rowKey={(data) => data.id_cliente + "-table"}
                pagination={false}
                dataSource={dataSource}
                loading={loadingData}
                columns={columns}
                onChange={handleTableChange}
            />
            <Row>
                <Pagination
                    style={{ float: "right", marginTop: 16 }}
                    current={metadata.pagina}
                    defaultPageSize={metadata.resultados_pagina}
                    size="small"
                    total={metadata.resultados}
                    showSizeChanger
                    showTotal={(total) => `Total de ${total} registo.`}
                    onShowSizeChange={pageChange}
                    onChange={pageChange}
                />
            </Row>
            {modalDossier.isVisible && (
                <Dossier
                    isModalVisible={modalDossier.isVisible}
                    clientNumber={modalDossier.clientNumber}
                    clientName={modalDossier.clientName}
                    hideModal={hideModal}
                    redirect={handleRedirect}
                />
            )}
            {isVisibleConfiguration && (
                <Configuration isVisible={isVisibleConfiguration} hideModal={hideConfiguration} />
            )}
        </>
    );
};

export default ClientDossier;
