import React, { Component } from "react";
import { Modal, Table, Button, Popconfirm, Input, Row, Col, Form, Icon, Select, message } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/holiday";
import { selectEstabeleciment } from "../../../fixed_list/actions/index";
import QaDynamicSelect from "../../../../components/DynamicList/dynamicSelect";
import Confirm from "./../../../../components/qa_custom/confirm";
import { isEmpty } from "../../../process_execution/utils/fields";
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;

class FormCreateHoliday extends Component {
    componentDidMount() {
        this.props.getFormulario();
        // if (this.props.holidayForm.year.value === null) {
        // get max element
        const holidays = this.props.holidayList.map(({ ano }) => Number(ano));

        let data = null;
        if (holidays.length === 0) {
            data = moment(new Date()).year();
        } else {
            data = Math.max(...holidays) + 1;
        }

        this.props.setField({
            year: { name: "year", value: data },
        });
        this.setState({
            mindate: `${data}-01-01`,
            maxdate: `${data}-12-31`,
            registeredHolidays: this.props.holidayList.sort((a, b) => Number(b.ano) - Number(a.ano)),
        });
        // }

        if (this.props.estabelecimentos === undefined) {
            this.setState({ estabelecimentoLoading: true });
            this.props
                .selectEstabeleciment({
                    accao: "lista",
                    lista: "lista_estabelecimentos",
                    id_instituicao: "1",
                })
                .then((sucess) => {
                    if (!sucess) message.error("Ocorreu um erro");
                    this.setState({ estabelecimentoLoading: false });
                });
        } else {
            this.setState({ estabelecimentoLoading: false });
        }
    }
    state = {
        estabelecimentoLoading: true,
        loadingNature: false,
        loadingImportHoliday: false,
        triedSave: false,
        mindate: null,
        maxdate: null,
        registeredHolidays: [],
    };

    save = (dataIndex, text, record) => {
        const obj = { ...record, [dataIndex]: text };
        this.props.setFieldList({ obj });
    };

    delete = (key) => {
        this.props.removeDay({ key });
    };

    handleResponse = (response) => {
        this.setState({ buttonLoading: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleOk();
        } else message.error("Ocorreu um erro");
    };
    checkInvalidHolidays = (holidays) => {
        const defaultDate = moment(this.state.mindate);
        for (const item of holidays) {
            if (
                isEmpty(item.data) ||
                isEmpty(item.natureza) ||
                isEmpty(item.designacao) ||
                isEmpty(item.estabelecimentos)
            ) {
                return { requiredField: true };
            } else if (!defaultDate.isSame(item.data, "year")) {
                return {
                    invalidDate: true,
                };
            }
        }
        return {
            requiredField: false,
            invalidDate: false,
        };
    };
    handleOk = (e) => {
        e.preventDefault();
        this.setState({ triedSave: true });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //validate fiels
                const { requiredField, invalidDate } = this.checkInvalidHolidays(this.props.holidayForm.holidays);
                if (requiredField) {
                    message.error("Campos obrigatórios em branco");
                } else if (invalidDate) {
                    message.error("Tem data inválida");
                } else {
                    this.setState({ buttonLoading: true });
                    const obj = {
                        ...values,
                        holidays: this.props.holidayForm.holidays,
                    };
                    this.props.insert({ formulario: this.props.formulario, values: obj }).then((response) => {
                        this.handleResponse(response);
                    });
                }
            }
        });
    };

    importHoliday = (value) => {
        this.setState({ loadingImportHoliday: true });

        this.props
            .importHoliday({
                id: value,
                ano: this.props.holidayForm.year.value,
            })
            .then(() => {
                this.setState({ loadingImportHoliday: false });
            });
    };

    requiredLabel = (text) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minWidth: "80px",
                }}
            >
                <span>
                    <i style={{ color: "red" }}>*</i>
                    &nbsp;{text}&nbsp;
                </span>
            </div>
        );
    };

    columns = [
        {
            title: this.requiredLabel("Dia"),
            dataIndex: "data",
            width: "20%",
            render: (value, record) => {
                const errorRequired = this.state.triedSave && !value;
                let invalidDate = false;

                if (!errorRequired && value) {
                    const momentDate = moment(value);
                    if (momentDate.isValid()) {
                        const defaultDate = moment(this.state.mindate);
                        invalidDate = !momentDate.isSame(defaultDate, "year");
                    } else {
                        invalidDate = true;
                    }
                }
                return (
                    <>
                        <Input
                            type="date"
                            value={value ? value : null}
                            name="data"
                            min={this.state.mindate}
                            max={this.state.maxdate}
                            onChange={(date) =>
                                this.save("data", date?.target?.value ? date.target.value : null, record)
                            }
                        />
                        {errorRequired && <span style={{ color: "red" }}>Campo obrigatório</span>}
                        {invalidDate && <span style={{ color: "red" }}>Valor inválido</span>}
                    </>
                );
            },
        },
        {
            title: this.requiredLabel("Designação"),
            dataIndex: "designacao",
            render: (value, record) => {
                const error = this.state.triedSave && !value;
                return (
                    <>
                        <Input
                            ref={(node) => (this.input = node)}
                            value={value}
                            onChange={(event) => {
                                return this.save("designacao", event.target.value, record);
                            }}
                        />
                        {error && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: this.requiredLabel("Natureza"),
            dataIndex: "natureza",
            width: "20%",
            render: (value, record) => {
                const error = this.state.triedSave && !value;
                return (
                    <>
                        <QaDynamicSelect
                            placeholder={"Selecione a área de gestão"}
                            defaultValue={value}
                            onChange={(event) => {
                                return this.save("natureza", event, record);
                            }}
                            name="natureza_feriado"
                        />
                        {error && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: this.requiredLabel("Estabelecimento"),
            dataIndex: "estabelecimentos",
            width: "30%",
            render: (value, record) => {
                const error = this.state.triedSave && (!value || value.length === 0);
                return (
                    <>
                        <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            placeholder="Selecione estabelecimentos"
                            onChange={(event) => {
                                return this.save("estabelecimentos", event, record);
                            }}
                            defaultValue={value}
                        >
                            {!this.state.estabelecimentoLoading &&
                                this.props.estabelecimentos?.data &&
                                this.props.estabelecimentos.data
                                    .filter((f) => typeof f.nome !== "object")
                                    .map((item) => (
                                        <Option key={item.estabelecimento} value={item.estabelecimento}>
                                            {item.nome}
                                        </Option>
                                    ))}
                        </Select>
                        {error && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        okText="Ok"
                        cancelText="Não"
                        onConfirm={() => this.delete(record.key)}
                    >
                        <Button
                            type="link"
                            style={{
                                padding: 0,
                                color: "#fa541c",
                            }}
                        >
                            Eliminar
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    render() {
        const columns = this.columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    title: col.title,
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title="Configuração de feriados"
                visible={this.props.isVisible}
                onOk={this.handleOk}
                onCancel={() => Confirm(this.props.handleCancel)}
                confirmLoading={this.state.buttonLoading}
                width="90%"
                centered
            >
                <Form layout="vertical" onSubmit={this.props.handleOk}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={6}>
                            <Form.Item label="Ano civil">
                                {getFieldDecorator("year", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input disabled />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<TextArea autoSize={{ minRows: 1, maxRows: 6 }} />)}
                            </Form.Item>
                        </Col>
                        {this.props.holidayForm.year.value !== null && (
                            <>
                                <Col xs={24} sm={24} md={6}>
                                    <Form.Item label="&nbsp;">
                                        <Select
                                            placeholder="Importar do ano"
                                            allowClear
                                            showSearch
                                            style={{
                                                float: "right",
                                                marginLeft: 8,
                                                marginBottom: 16,
                                            }}
                                            loading={this.state.loadingImportHoliday}
                                            onChange={this.importHoliday}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.registeredHolidays.map((item) => (
                                                <Option key={item.id_config_feriado} value={item.id_config_feriado}>
                                                    {item.ano}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={4}>
                                    <Form.Item label="&nbsp;">
                                        <Button
                                            onClick={this.props.addNewDay}
                                            type="primary"
                                            style={{
                                                float: "right",
                                                marginLeft: 8,
                                                marginBottom: 16,
                                            }}
                                        >
                                            <Icon type="plus" />
                                            Inserir
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>
                    <Table
                        rowKey={(record) => record.key}
                        rowClassName={() => "editable-row"}
                        dataSource={this.props.holidayForm.holidays}
                        columns={columns}
                        loading={this.state.loadingImportHoliday}
                        size="small"
                        scroll={{ y: 300 }}
                    />
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...actionCreators, selectEstabeleciment }, dispatch);
};

const mapStateToProps = (state) => {
    return {
        holidayForm: state.holiday.domain.formCreate,
        formulario: state.holiday.domain.metadata.formulario,
        estabelecimentos: state.fixedList.domain.byId.estabelecimento,
        natureza: state.dynamicList.domain.byId.natureza_feriado,
        holidayList: Object.values(state.holiday.domain.byId),
    };
};

const formCreate = Form.create({
    mapPropsToFields(props) {
        return {
            year: Form.createFormField({
                ...props.holidayForm.year,
                value: props.holidayForm.year.value,
            }),
            descricao: Form.createFormField({
                ...props.holidayForm.descricao,
                value: props.holidayForm.descricao.value,
            }),
        };
    },
    onFieldsChange(props, fields) {
        props.setField(fields);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(formCreate(FormCreateHoliday));
