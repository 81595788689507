/* eslint-disable no-unused-vars */
/* eslint-disable no-eval */
import { actionType } from "../constants/index";
import { componentType } from "../constants/index";
import { transformForm, conversion, set$DadosInForm } from "../constants/utils";
import { sendHttpRequest } from "../../../components/http.request";
import { FormUtilitaries, Session, Utilitaries } from "../../../business";
import { toString } from "../../../business/utils";
import { validateResponse, toArray } from "./../../../components/util";
import moment from "moment";
import { message } from "antd";
import { isEmpty, validateBoolean } from "../utils/fields";
import { isRequired, isFieldVisibility, restriction } from "../utils/input_attributes";

export const toggleModal = (payload) => ({
    type: actionType.toggleModal,
    payload,
});

export const setSubProcess = (payload) => ({
    type: actionType.setSubProcess,
    payload,
});

export const toggleIsFormReady = () => {
    return {
        type: actionType.isFormReady,
    };
};

export const changeViewerSelected = (view) => {
    return {
        type: actionType.changeViewerSelected,
        payload: view,
    };
};

export const getStablishments = () => (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "acessos");
    formData.append("username", Session.getUsername());
    return sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
};

export const getUserProcesses = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista_procs_utilizador");
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });

    const response = await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
    if (response) {
        if (response.erro) return false;
        const lista = validateResponse(response).map((item, index) => ({
            ...item,
            index,
        }));

        const metadata = response["@attributes"];

        return {
            lista,
            metadata: {
                resultados: Number(metadata.resultados),
                pagina: Number(metadata.pagina),
                visivel: metadata.visivel,
                resultados_pagina: Number(metadata.resultados_pagina),
            },
        };
    }
    return false;
};

export const initProcess =
    ({ process, stablishment, taskFormParams }) =>
    (dispatch) => {
        const formData = new FormData();
        formData.append("accao", "novo");
        formData.append("processo", process.nome);
        formData.append("estabelecimento", stablishment);
        formData.append("taskFormParams", taskFormParams || "");

        if (Session.isCLient()) {
            const params = Session.getAll();
            formData.append("usr_parametro1", params.parametro1);
            formData.append("usr_parametro2", params.parametro2);
            formData.append("usr_parametro3", params.parametro3);
        }

        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then(async (data) => {
            const { processo: processAttributes, tarefa: taskAttributes, formulario: form, erro } = data.dados;

            if (erro) {
                return {
                    erro,
                };
            }

            let process = {};
            const { normalizedFormObject, modalsState, metaInfoformFields } = transformForm({ form });
            // Packing all transformed data into 1 object
            process = {
                ...processAttributes,
                tasks: {
                    [taskAttributes["@id"]]: {
                        ...taskAttributes,
                        key: taskAttributes["@id"],
                        name: taskAttributes["@nome"],
                        position: 0,
                        status: "doing",
                        formMeta: [
                            {
                                form: form["@nome"],
                                meta: metaInfoformFields,
                            },
                        ],
                        page: normalizedFormObject,
                        details: {
                            readonlyDocuments: [],
                            uploadedDocuments: [],
                        },
                        transitions: {}, // todo: get this info in backend
                        actions: {}, // todo: get this info in backend
                    },
                },
            };
            await dispatch({
                type: actionType.initProcess,
                payload: process,
            });
            await dispatch({
                type: actionType.initModals,
                payload: modalsState,
            });
            return {
                taskId: taskAttributes["@id"],
                processId: processAttributes["@id"],
            };
        });
    };

export const getFieldDataSource =
    ({ dataSource, params, componentType: tipo, controller = "execQuery", sendRequestWithEmptyParams = false }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        if (controller === "execQuery") {
            formData.append("query", dataSource);
        } else if (controller === "geredatagrid") {
            formData.append("accao", "lista");
            formData.append("lista", dataSource);
            if (params.hasOwnProperty("lista")) {
                delete params.lista;
            }
        } else if (controller === "execListaDinamica") {
            formData.append("nome", dataSource);
        } else {
            formData.append("tabela", dataSource);
            params = null;
        }
        if (params) {
            for (const key in params) {
                if (!sendRequestWithEmptyParams && !params[key] && params[key] !== 0) {
                    continue;
                }
                if (typeof params[key] === "string" || typeof params[key] === "number") {
                    formData.append(key, params[key]);
                } else if (typeof params[key] === "object" && !Array.isArray(params[key]) && params[key].id) {
                    formData.append(key, params[key].id);
                } else if (Array.isArray(params[key])) {
                    for (const item of params[key]) {
                        try {
                            // because dataDisplay input
                            if (typeof item === "object") {
                                formData.append(key, item[Object.keys(item)[0]]);
                            } else {
                                formData.append(key, `'${item.replace(undefined, "")}'`);
                            }
                        } catch (error) {}
                    }
                } else {
                    // * send explicity param in ''
                    formData.append(key, `'${params[key]}'`.replace(undefined, ""));
                }
            }
        }

        return sendHttpRequest("POST", `/Gestor/${controller}.php`, formData).then((data) => {
            let transformedData = [];
            if (data) {
                if (controller === "execListaDinamica") {
                    transformedData = { result: validateResponse(data, "dados") };
                } else {
                    transformedData = data;
                }
            }
            return transformedData;
        });
    };

export const getForm =
    ({ form, taskId, processId }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        formData.append("accao", "consultar");
        /**
         * * Remove hack flag,
         * ! this is a trick used in backend
         */
        formData.append("hack", "true");
        formData.append("formulario", form);

        if (Session.isCLient()) {
            const params = Session.getAll();
            formData.append("usr_parametro1", params.parametro1);
            formData.append("usr_parametro2", params.parametro2);
            formData.append("usr_parametro3", params.parametro3);
        }

        return sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((data) => {
            const { normalizedFormObject, metaInfoformFields } = transformForm({
                form: data.formulario,
                parent: form,
            });
            dispatch({
                type: actionType.getForm,
                payload: {
                    formMeta: [
                        {
                            form: data.formulario["@nome"],
                            meta: metaInfoformFields,
                        },
                    ],
                    taskId: taskId,
                    processId: processId,
                },
            });
            return { form: normalizedFormObject, formMeta: metaInfoformFields };
        });
    };

// export const getFixTaskData = ({id}) => (dispatch, getState) => {
//     const formData = new FormData();
//     formData.append("accao", "consultar_correcao");
//     formData.append("id", id);

//     return sendHttpRequest("POST", "/Gestor/gereformulario.php", formData)
//         .then(({ erro, result, formulario }) => {
//             if (erro) throw Error("Erro!");
//             let valores = null;

//             if (id) {
//                 valores = result.valores;
//             }
//             formulario = result.formulario;

//             const { normalizedFormObject, metaInfoformFields } = transformForm({
//                 form: formulario,
//             });

//             const values = valores?.[formulario?.["@elemento_raiz"]];
//             let form = [];

//             if (values) {
//                 form = Object.values(normalizedFormObject)
//                     .map((formItem) => {
//                         // let tableWithValueInSecoundKey = false;
//                         let inputKey = formItem.key;

//                         if (formItem["@tipo"] === componentType._dynamicTable && !(formItem.key in values)) {
//                             inputKey = Object.keys(formItem).find(
//                                 (it) =>
//                                     typeof formItem[it] === "object" &&
//                                     !Array.isArray(formItem[it]) &&
//                                     Object.keys(formItem[it]).length > 0
//                             );
//                             // tableWithValueInSecoundKey = true;
//                         }

//                         const value = values[inputKey];
//                         if (inputKey in values) {
//                             // * Table cases
//                             if (
//                                 formItem["@tipo"] === componentType._dynamicTable ||
//                                 formItem["@tipo"] === componentType._selectionTable ||
//                                 formItem["@tipo"] === componentType.datagrid
//                             ) {
//                                 let dataSource = Object.values(value)[0];
//                                 if (Array.isArray(dataSource)) {
//                                     formItem.dataSource = dataSource.reduce((acumulator, current, index) => {
//                                         current.key = index + 1;
//                                         acumulator[index + 1] = current;
//                                         return acumulator;
//                                     }, {});
//                                 } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
//                                     formItem.dataSource = {
//                                         1: { key: 1, ...dataSource },
//                                     };
//                                 }
//                             } else if (formItem["@tipo"] === componentType._dynamicDatagrid) {
//                                 const _dataSource = value[formItem.key];

//                                 if (Array.isArray(_dataSource)) {
//                                     formItem.dataSource = _dataSource.reduce((acumulator, current, index) => {
//                                         current.key = index + 1;
//                                         acumulator[index + 1] = current;
//                                         return acumulator;
//                                     }, {});
//                                 } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
//                                     formItem.dataSource = {
//                                         1: { key: 1, ..._dataSource },
//                                     };
//                                 }

//                                 // formItem.dataSource = _dataSource;
//                             }
//                             // * Other cases
//                             else if (formItem["@tipo"] === componentType._inputGroup) {
//                                 const _inputValues = value;
//

//                                 formItem = setInputGroupData(formItem, _inputValues);
//                                 formItem.value = _inputValues;

//                             } else {

//                                 formItem.value = value;
//                             }
//                         }

//                         return formItem;
//                     })
//                     .reduce((acumulator, current) => {
//                         acumulator[current.key] = current;
//                         return acumulator;
//                     }, {});

//                 const valuesThatKeyStartWithID = Object.keys(values).filter((field) => field.startsWith("id_"));

//                 valuesThatKeyStartWithID.forEach((key) => {
//                     if (!form?.[key]) {
//                         form[key] = {
//                             key,
//                             value: values[key],
//                             "@visivel": "nao",
//                         };
//                     }
//                 });
//             } else {
//                 form = normalizedFormObject;
//             }

//             dispatch({
//                 type: actionType.getForm,
//                 payload: {
//                     formMeta: [
//                         {
//                             form: formulario["@nome"],
//                             meta: metaInfoformFields,
//                         },
//                     ],
//                     taskId: taskId,
//                     processId: processId,
//                 },
//             });

//             return { form, formMeta: metaInfoformFields };
//         })
//         .catch((err) => {
//
//         });
// };

export const getFormData =
    ({ form, id, taskId, processId, allPopupParams }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        // formData.append("accao", id ? "dados" : "consultar");
        formData.append("accao", "dados");

        /**
         * append params popu to formData
         */
        Object.entries(allPopupParams).forEach(([key, val]) => {
            formData.append(key, val || "");
        });

        if (processId) formData.append("idProcesso", processId);
        if (taskId) formData.append("idActividade", taskId);

        /**
         * * Remove hack flag,
         * ! this is a trick used in backend
         */

        formData.append("hack", "true");
        formData.append("formulario", form);
        // if (id) {
        /* formData.append("id", id || 0); */
        formData.append("id", id || "");
        // }

        if (Session.isCLient()) {
            const params = Session.getAll();
            formData.append("usr_parametro1", params.parametro1);
            formData.append("usr_parametro2", params.parametro2);
            formData.append("usr_parametro3", params.parametro3);
        }

        return sendHttpRequest("POST", "/Gestor/gereformulario.php", formData)
            .then(({ erro, result, formulario }) => {
                if (erro) throw Error("Erro!");
                let valores = null;

                if (id || !isEmpty(result?.valores)) {
                    valores = result.valores;
                    // formulario = result.formulario;
                }
                formulario = result.formulario;

                const { normalizedFormObject, metaInfoformFields } = transformForm({
                    form: formulario,
                });
                debugger;
                const values = valores?.[formulario?.["@elemento_raiz"]];
                let form = [];

                /**
                 * check if have to hidde save and cancel buttons on popup when form is only to see.
                 */
                let hiddeSaveButtomOnPopup = false;
                const consulta_formulario =
                    result?.formulario?.[formulario?.["@elemento_raiz"]]?.["@consulta_formulario"];
                if (consulta_formulario === "sim") {
                    hiddeSaveButtomOnPopup = true;
                }

                /**
                 * * Mapping values to form fields
                 * ! On tables the value is treated as dataSource
                 * * form converted in array to easely operations perform and then turned back to object
                 */

                if (values) {
                    form = Object.values(normalizedFormObject)
                        .map((formItem) => {
                            // let tableWithValueInSecoundKey = false;
                            let inputKey = formItem.key;

                            if (formItem["@tipo"] === componentType._dynamicTable && !(formItem.key in values)) {
                                inputKey = Object.keys(formItem).find(
                                    (it) =>
                                        typeof formItem[it] === "object" &&
                                        !Array.isArray(formItem[it]) &&
                                        Object.keys(formItem[it]).length > 0
                                );
                                // tableWithValueInSecoundKey = true;
                            }

                            const value = values[inputKey];
                            if (inputKey in values) {
                                // * Table cases
                                if (
                                    formItem["@tipo"] === componentType._dynamicTable ||
                                    formItem["@tipo"] === componentType._selectionTable ||
                                    formItem["@tipo"] === componentType.datagrid
                                ) {
                                    let dataSource = Object.values(value)[0];

                                    if (Array.isArray(dataSource)) {
                                        formItem.dataSource = dataSource.reduce((acumulator, current, index) => {
                                            const objCurrent = {};
                                            for (let key in current) {
                                                objCurrent[key] = toString(current[key]);
                                            }
                                            objCurrent.key = index + 1;
                                            acumulator[index + 1] = objCurrent;
                                            return acumulator;
                                        }, {});
                                    } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                                        const objValues = {};
                                        for (let key in dataSource) {
                                            objValues[key] = toString(dataSource[key]);
                                        }
                                        formItem.dataSource = {
                                            1: { key: 1, ...objValues },
                                        };
                                    }
                                } else if (formItem["@tipo"] === componentType._dynamicDatagrid) {
                                    const _dataSource = value[formItem.key];

                                    if (Array.isArray(_dataSource)) {
                                        formItem.dataSource = _dataSource.reduce((acumulator, current, index) => {
                                            current.key = index + 1;
                                            acumulator[index + 1] = current;
                                            return acumulator;
                                        }, {});
                                    } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                                        formItem.dataSource = {
                                            1: { key: 1, ..._dataSource },
                                        };
                                    }

                                    // formItem.dataSource = _dataSource;
                                }
                                // * Other cases
                                else if (formItem["@tipo"] === componentType._inputGroup) {
                                    const _inputValues = value;

                                    formItem = setInputGroupData(formItem, _inputValues);
                                    formItem.value = _inputValues;
                                    // for (const _key in _inputValues) {
                                    //
                                    //     if (formItem?.[_key]) {
                                    //         formItem[_key]["value"] = _inputValues[_key];
                                    //     }
                                    // }
                                    // item.dataSource = _dataSource;
                                } else {
                                    if (
                                        formItem["@persiste"] === "nao" &&
                                        Utilitaries.isEmpty(value) &&
                                        !Utilitaries.isEmpty(formItem["$"])
                                    ) {
                                        formItem.value = formItem["$"];
                                    } else {
                                        formItem.value = validateBoolean(value, formItem);
                                    }

                                    // formItem.value = value;
                                }
                            }

                            return formItem;
                        })
                        .reduce((acumulator, current) => {
                            acumulator[current.key] = current;
                            return acumulator;
                        }, {});

                    const valuesThatKeyStartWithID = Object.keys(values).filter((field) => field.startsWith("id_"));

                    valuesThatKeyStartWithID.forEach((key) => {
                        if (!form?.[key]) {
                            form[key] = {
                                key,
                                value: values[key],
                                "@visivel": "nao",
                            };
                        }
                    });
                } else {
                    form = normalizedFormObject;
                }

                dispatch({
                    type: actionType.getForm,
                    payload: {
                        formMeta: [
                            {
                                form: formulario["@nome"],
                                meta: metaInfoformFields,
                            },
                        ],
                        taskId: taskId,
                        processId: processId,
                    },
                });

                return { form, formMeta: metaInfoformFields, values, hiddeSaveButtomOnPopup };

                // const formValues = valores[formulario["@elemento_raiz"]];
                // let formWithValues = [];
                // /**
                //  * * gets executed only if exists values
                //  */
                // if (formValues) {
                //     formWithValues = formStruct.map((item) => {
                //         if (
                //             item["@tipo"] === componentType._dynamicTable ||
                //             item["@tipo"] === componentType._selectionTable ||
                //             item["@tipo"] === componentType.datagrid
                //         ) {
                //             const dataSource = Object.values(Object.values(formValues[item.key]))[0];
                //             if (Array.isArray(dataSource)) {
                //                 item.dataSource = dataSource.reduce((acumulator, current, index) => {
                //                     current.key = index + 1;
                //                     acumulator[index + 1] = current;
                //                     return acumulator;
                //                 }, {});
                //             } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                //                 item.dataSource = { 1: { key: 1, ...dataSource } };
                //             }
                //             return item;
                //         } else if (item["@tipo"] === componentType._dynamicDatagrid) {
                //             const _dataSource = formValues[item.key][item.key];

                //             if (Array.isArray(_dataSource)) {
                //                 item.dataSource = _dataSource.reduce((acumulator, current, index) => {
                //                     current.key = index + 1;
                //                     acumulator[index + 1] = current;
                //                     return acumulator;
                //                 }, {});
                //             } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                //                 item.dataSource = {
                //                     1: { key: 1, ..._dataSource },
                //                 };
                //             }

                //             // item.dataSource = _dataSource;
                //         } else if (item["@tipo"] === componentType._inputGroup) {
                //             const _inputValues = formValues[item.key];

                //             item.value = _inputValues;
                //             for (const _key in _inputValues) {
                //                 item[_key].value = _inputValues[_key];
                //             }
                //             // item.dataSource = _dataSource;
                //         } else {
                //             if (formValues[item.key] && formValues[item.key]["$"]) {
                //                 item.value = formValues[item.key]["$"];
                //             } else {
                //                 item.value = formValues[item.key];
                //             }
                //         }
                //         return item;
                //     });
                // } else {
                //     formWithValues = formStruct;
                // }

                // const tableNamesId = Object.keys(formValues).find((field) => field.startsWith("id_"));

                // return {
                //     ...formWithValues,
                //     [Object.keys(formWithValues).length]: {
                //         key: tableNamesId,
                //         value: formValues[tableNamesId],
                //         parent: form,
                //         formName: form,
                //         formKey: formulario["@elemento_raiz"],
                //     },
                // };
            })
            .catch((err) => {});
    };

export const getCorrectionFormData =
    ({ id }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        formData.append("accao", "consultar_correcao");
        formData.append("id", id || "");

        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData)
            .then(({ erro, result, formulario }) => {
                if (erro) throw Error("Erro!");
                let valores = null;

                if (id) {
                    valores = result.valores;
                    // formulario = result.formulario;
                }
                formulario = result.formulario;

                const { normalizedFormObject, metaInfoformFields } = transformForm({
                    form: formulario,
                });

                const values = valores?.[formulario?.["@elemento_raiz"]];
                let form = [];
                /**
                 * * Mapping values to form fields
                 * ! On tables the value is treated as dataSource
                 * * form converted in array to easely operations perform and then turned back to object
                 */

                if (values) {
                    form = Object.values(normalizedFormObject)
                        .map((formItem) => {
                            // let tableWithValueInSecoundKey = false;
                            let inputKey = formItem.key;

                            if (formItem["@tipo"] === componentType._dynamicTable && !(formItem.key in values)) {
                                inputKey = Object.keys(formItem).find(
                                    (it) =>
                                        typeof formItem[it] === "object" &&
                                        !Array.isArray(formItem[it]) &&
                                        Object.keys(formItem[it]).length > 0
                                );
                                // tableWithValueInSecoundKey = true;
                            }

                            const value = values[inputKey];
                            if (inputKey in values) {
                                // * Table cases
                                if (
                                    formItem["@tipo"] === componentType._dynamicTable ||
                                    formItem["@tipo"] === componentType._selectionTable ||
                                    formItem["@tipo"] === componentType.datagrid
                                ) {
                                    let dataSource = Object.values(value)[0];
                                    if (Array.isArray(dataSource)) {
                                        formItem.dataSource = dataSource.reduce((acumulator, current, index) => {
                                            current.key = index + 1;
                                            acumulator[index + 1] = current;
                                            return acumulator;
                                        }, {});
                                    } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                                        formItem.dataSource = {
                                            1: { key: 1, ...dataSource },
                                        };
                                    }
                                } else if (formItem["@tipo"] === componentType._dynamicDatagrid) {
                                    const _dataSource = value[formItem.key];

                                    if (Array.isArray(_dataSource)) {
                                        formItem.dataSource = _dataSource.reduce((acumulator, current, index) => {
                                            current.key = index + 1;
                                            acumulator[index + 1] = current;
                                            return acumulator;
                                        }, {});
                                    } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                                        formItem.dataSource = {
                                            1: { key: 1, ..._dataSource },
                                        };
                                    }

                                    // formItem.dataSource = _dataSource;
                                }
                                // * Other cases
                                else if (formItem["@tipo"] === componentType._inputGroup) {
                                    const _inputValues = value;

                                    formItem = setInputGroupData(formItem, _inputValues);
                                    formItem.value = _inputValues;
                                    // for (const _key in _inputValues) {
                                    //
                                    //     if (formItem?.[_key]) {
                                    //         formItem[_key]["value"] = _inputValues[_key];
                                    //     }
                                    // }
                                    // item.dataSource = _dataSource;
                                } else {
                                    // if (formItem.key === "processo") {
                                    //     ;
                                    // }
                                    formItem.value = value;
                                }
                            }

                            return formItem;
                        })
                        .reduce((acumulator, current) => {
                            acumulator[current.key] = current;
                            return acumulator;
                        }, {});

                    const valuesThatKeyStartWithID = Object.keys(values).filter((field) => field.startsWith("id_"));

                    valuesThatKeyStartWithID.forEach((key) => {
                        if (!form?.[key]) {
                            form[key] = {
                                key,
                                value: values[key],
                                "@visivel": "nao",
                            };
                        }
                    });
                } else {
                    form = normalizedFormObject;
                }

                // dispatch({
                //     type: actionType.getForm,
                //     payload: {
                //         formMeta: [
                //             {
                //                 form: formulario["@nome"],
                //                 meta: metaInfoformFields,
                //             },
                //         ],
                //         taskId: taskId,
                //         processId: processId,
                //     },
                // });

                return { form, formMeta: metaInfoformFields, values };
            })
            .catch((err) => {});
    };

// export const getFormData = ({ form, id, formStruct }) => (dispatch, getState) => {
//     const formData = new FormData();
//     formData.append("accao", "dados");
//     /**
//      * * Remove hack flag,
//      * ! this is a trick used in backend
//      */
//     formData.append("hack", "true");
//     formData.append("formulario", form);
//     formData.append("id", id);
//     return sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then(({ erro, result }) => {
//         if (erro) throw Error("Erro!");
//         const { valores, formulario } = result;
//         const formValues = valores[formulario["@elemento_raiz"]];
//         let formWithValues = [];
//         /**
//          * * gets executed only if exists values
//          */
//         if (formValues) {
//             formWithValues = formStruct.map((item) => {
//                 if (
//                     item["@tipo"] === componentType._dynamicTable ||
//                     item["@tipo"] === componentType._selectionTable ||
//                     item["@tipo"] === componentType.datagrid
//                 ) {
//                     const dataSource = Object.values(Object.values(formValues[item.key]))[0];
//                     if (Array.isArray(dataSource)) {
//                         item.dataSource = dataSource.reduce((acumulator, current, index) => {
//                             current.key = index + 1;
//                             acumulator[index + 1] = current;
//                             return acumulator;
//                         }, {});
//                     } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
//                         item.dataSource = { 1: { key: 1, ...dataSource } };
//                     }
//                     return item;
//                 } else if (item["@tipo"] === componentType._dynamicDatagrid) {
//                     const _dataSource = formValues[item.key][item.key];

//                     if (Array.isArray(_dataSource)) {
//                         item.dataSource = _dataSource.reduce((acumulator, current, index) => {
//                             current.key = index + 1;
//                             acumulator[index + 1] = current;
//                             return acumulator;
//                         }, {});
//                     } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
//                         item.dataSource = {
//                             1: { key: 1, ..._dataSource },
//                         };
//                     }

//                     // item.dataSource = _dataSource;
//                 } else if (item["@tipo"] === componentType._inputGroup) {
//                     const _inputValues = formValues[item.key];

//                     item.value = _inputValues;
//                     for (const _key in _inputValues) {
//                         item[_key].value = _inputValues[_key];
//                     }
//                     // item.dataSource = _dataSource;
//                 } else {
//                     if (formValues[item.key] && formValues[item.key]["$"]) {
//                         item.value = formValues[item.key]["$"];
//                     } else {
//                         item.value = formValues[item.key];
//                     }
//                 }
//                 return item;
//             });
//         } else {
//             formWithValues = formStruct;
//         }

//         const tableNamesId = Object.keys(formValues).find((field) => field.startsWith("id_"));

//         return {
//             ...formWithValues,
//             [Object.keys(formWithValues).length]: {
//                 key: tableNamesId,
//                 value: formValues[tableNamesId],
//                 parent: form,
//                 formName: form,
//                 formKey: formulario["@elemento_raiz"],
//             },
//         };
//     });
// };

export const getTask =
    ({ processId, taskId, isInit }) =>
    (dispatch) => {
        const formData = new FormData();
        formData.append("accao", "consultar");
        /**
         * * Remove hack flag,
         * ! this is a trick used in backend
         */
        formData.append("hack", "true");
        formData.append("processo", processId);
        formData.append("tarefa", taskId);

        if (Session.isCLient()) {
            const params = Session.getAll();
            formData.append("usr_parametro1", params.parametro1);
            formData.append("usr_parametro2", params.parametro2);
            formData.append("usr_parametro3", params.parametro3);
        }

        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
            if (!data?.dados) return;
            const { processo: processAttributes, tarefa: taskAttributes, formulario: form, valores } = data?.dados;
            const { normalizedFormObject, modalsState, metaInfoformFields } = transformForm({ form });

            if (isInit) {
                dispatch({
                    type: actionType.initProcess,
                    payload: {
                        ...processAttributes,
                        tasks: {
                            [taskAttributes["@id"]]: {
                                ...taskAttributes,
                                key: taskAttributes["@id"],
                                name: taskAttributes["@nome"],
                                status: "doing",
                                formMeta: [
                                    {
                                        form: form["@nome"],
                                        meta: metaInfoformFields,
                                    },
                                ],
                                page: normalizedFormObject,
                                details: {
                                    readonlyDocuments: [],
                                    uploadedDocuments: [],
                                },
                                transitions: {}, // todo: get this info in backend
                                actions: {}, // todo: get this info in backend
                            },
                        },
                    },
                });
            } else {
                dispatch({
                    type: actionType.setTaskDetails,
                    payload: {
                        ...taskAttributes,
                        processId,
                        taskId,
                        key: taskAttributes["@id"],
                        name: taskAttributes["@nome"],
                        status: "doing",
                        formMeta: [
                            {
                                form: form["@nome"],
                                meta: metaInfoformFields,
                            },
                        ],
                        page: normalizedFormObject,
                        details: {
                            readonlyDocuments: [],
                            uploadedDocuments: [],
                        },
                        transitions: {}, // todo: get this info in backend
                        actions: {}, // todo: get this info in backend
                    },
                });
            }
            dispatch({ type: actionType.initModals, payload: modalsState });
            return { formulario: normalizedFormObject, taskAttributes, valores };
        });
    };

export const getTaskData =
    ({ processId, taskId }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        formData.append("accao", "consultar");
        /**
         * * Remove hack flag,
         * ! this is a trick used in backend
         */
        formData.append("hack", "true");
        formData.append("processo", processId);
        formData.append("tarefa", taskId);

        if (Session.isCLient()) {
            const params = Session.getAll();
            formData.append("usr_parametro1", params.parametro1);
            formData.append("usr_parametro2", params.parametro2);
            formData.append("usr_parametro3", params.parametro3);
        }

        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
            if (!data?.dados) {
                return;
            }
            const { valores, formulario, tarefa } = data?.dados;
            const { normalizedFormObject, formAttributes, metaInfoformFields } = transformForm({
                form: formulario,
            });

            const values = valores?.[formulario?.["@elemento_raiz"]];
            // const formWithoutValues = Object.assign({}, { ...normalizedFormObject });
            const formWithoutValues = JSON.parse(JSON.stringify(normalizedFormObject));

            let form = [];

            /**
             * * Mapping values to form fields
             * ! On tables the value is treated as dataSource
             * * form converted in array to easely operations perform and then turned back to object
             */

            if (values) {
                form = Object.values({ ...normalizedFormObject })
                    .map((formItem) => {
                        const value = values[formItem.key];
                        if (formItem.key in values) {
                            // * Table cases
                            if (
                                formItem["@tipo"] === componentType._dynamicTable ||
                                formItem["@tipo"] === componentType._selectionTable ||
                                formItem["@tipo"] === componentType.datagrid
                            ) {
                                let dataSource = Object.values(value)[0];
                                if (Array.isArray(dataSource)) {
                                    formItem.dataSource = dataSource.reduce((acumulator, current, index) => {
                                        Object.keys(current).forEach((key) => {
                                            if (
                                                typeof current[key] === "object" &&
                                                Array.isArray(current[key]) &&
                                                current[key].length === 0
                                            ) {
                                                current[key] = "";
                                            }
                                        });

                                        current.key = index + 1;
                                        acumulator[index + 1] = current;
                                        return acumulator;
                                    }, {});
                                } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                                    formItem.dataSource = {
                                        1: { key: 1, ...dataSource },
                                    };
                                }
                            } else if (formItem["@tipo"] === componentType._dynamicDatagrid) {
                                const _dataSource = value[formItem.key];

                                if (Array.isArray(_dataSource)) {
                                    formItem.dataSource = _dataSource.reduce((acumulator, current, index) => {
                                        current.key = index + 1;
                                        acumulator[index + 1] = current;
                                        return acumulator;
                                    }, {});
                                } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                                    formItem.dataSource = {
                                        1: { key: 1, ..._dataSource },
                                    };
                                }

                                // formItem.dataSource = _dataSource;
                            }
                            // * Other cases
                            else if (formItem["@tipo"] === componentType._inputGroup) {
                                const _inputValues = value;

                                formItem = setInputGroupData(formItem, _inputValues);
                                formItem.value = _inputValues;
                                // for (const _key in _inputValues) {
                                //
                                //     if (formItem?.[_key]) {
                                //         formItem[_key]["value"] = _inputValues[_key];
                                //     }
                                // }
                                // item.dataSource = _dataSource;
                            } else if (formItem["@tipo"] === componentType.boolean && typeof value === "string") {
                                formItem.value = value.toQAFormValue();
                            } else if (formItem["@tipo"] === componentType.date) {
                                formItem.value = isEmpty(value) ? "" : value;
                            } else {
                                formItem.value = validateBoolean(value, formItem);
                            }
                        }

                        return formItem;
                    })
                    .reduce((acumulator, current) => {
                        acumulator[current.key] = current;
                        return acumulator;
                    }, {});

                const valuesThatKeyStartWithID = Object.keys(values).filter((field) => field.startsWith("id_"));

                valuesThatKeyStartWithID.forEach((key) => {
                    if (!form?.[key]) {
                        form[key] = {
                            key,
                            value: values[key],
                            "@visivel": "nao",
                        };

                        formWithoutValues[key] = {
                            key,
                            value: values[key],
                            "@visivel": "nao",
                        };
                    }
                });
            } else {
                form = normalizedFormObject;
            }
            return {
                taskData: form,
                values,
                isLastTask: tarefa["@ultima_tarefa"],
                formAttributes,
                taskAtributes: tarefa,
                metaInfoformFields,
                formWithoutValues,
            };
        });
    };

export const getAoCarregar = async ({ form, metaInfo, values, allPopupParams, forceGetAoCarregar }) => {
    const isToLoadAdditionalData = metaInfo?.["@aoCarregar"];
    if (isToLoadAdditionalData && (isEmpty(values) || forceGetAoCarregar)) {
        const aditionalDatas = isToLoadAdditionalData.split(";");

        for await (const item of aditionalDatas) {
            if (!item || item === "") {
                continue;
            }
            const currentAditionalData = item
                .replace(/\s/g, "")
                .match(/(?<=\()'.*'/)?.[0]
                ?.split(",");
            const keyData = currentAditionalData?.[0]?.replace(/'/g, "");
            const query = currentAditionalData?.[1]?.replace(/'/g, "");

            const formData = new FormData();
            formData.append("query", query);

            const params = {
                utilizador: Session.getUsername(),
                // idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                idProcesso: form?.processo?.value,
                idActividade: form?.actividade?.value,
                idEstabelecimento: form?.estabelecimento?.value,
                idModelo: form?.modelo?.value,
                idValencia: form?.valencia?.value,
                idProjeto: form?.id_projeto?.value,
                ...allPopupParams,
            };

            for (const key in params) {
                if (!params[key] && params[key] !== 0) {
                    continue;
                }
                if (typeof params[key] === "string" || typeof params[key] === "number") {
                    formData.append(key, params[key]);
                } else if (typeof params[key] === "object" && !Array.isArray(params[key]) && params[key].id) {
                    formData.append(key, params[key].id);
                } else if (Array.isArray(params[key])) {
                    for (const item of params[key]) {
                        try {
                            // because dataDisplay input
                            if (typeof item === "object") {
                                formData.append(key, item[Object.keys(item)[0]]);
                            } else {
                                formData.append(key, `'${item.replace(undefined, "")}'`);
                            }
                        } catch (error) {}
                    }
                } else {
                    // * send explicity param in ''
                    formData.append(key, `'${params[key]}'`.replace(undefined, ""));
                }
            }

            if (Session.isCLient()) {
                const params = Session.getAll();
                formData.append("usr_parametro1", params.parametro1);
                formData.append("usr_parametro2", params.parametro2);
                formData.append("usr_parametro3", params.parametro3);
            }

            const data = await sendHttpRequest("POST", `/Gestor/execQuery.php`, formData);

            const values = Array.isArray(data.result) && data.result[0] ? data.result[0] : {};

            form = set$DadosInForm({
                tree: form,
                values,
                keyData,
            });
        }
    }
    return form;
};

export const getTaskDataByRollback =
    ({ id }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        formData.append("accao", "consultar");
        /**
         * * Remove hack flag,
         * ! this is a trick used in backend
         */
        formData.append("hack", "true");
        formData.append("accao", "consultar_correcao");
        formData.append("id", id);
        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
            const { valores, formulario } = data.result;
            const { normalizedFormObject, formAttributes, metaInfoformFields } = transformForm({
                form: formulario,
            });
            const values = valores?.[formulario?.["@elemento_raiz"]];
            let form = [];
            /**
             * * Mapping values to form fields
             * ! On tables the value is treated as dataSource
             * * form converted in array to easely operations perform and then turned back to object
             */

            if (values) {
                form = Object.values(normalizedFormObject)
                    .map((formItem) => {
                        const value = values[formItem.key];
                        if (formItem.key in values) {
                            // * Table cases
                            if (
                                formItem["@tipo"] === componentType._dynamicTable ||
                                formItem["@tipo"] === componentType._selectionTable ||
                                formItem["@tipo"] === componentType.datagrid
                            ) {
                                let dataSource = Object.values(value)[0];
                                if (Array.isArray(dataSource)) {
                                    formItem.dataSource = dataSource.reduce((acumulator, current, index) => {
                                        Object.keys(current).forEach((key) => {
                                            if (
                                                typeof current[key] === "object" &&
                                                Array.isArray(current[key]) &&
                                                current[key].length === 0
                                            ) {
                                                current[key] = "";
                                            }
                                        });

                                        current.key = index + 1;
                                        acumulator[index + 1] = current;
                                        return acumulator;
                                    }, {});
                                } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                                    formItem.dataSource = {
                                        1: { key: 1, ...dataSource },
                                    };
                                }
                            } else if (formItem["@tipo"] === componentType._dynamicDatagrid) {
                                const _dataSource = value[formItem.key];

                                if (Array.isArray(_dataSource)) {
                                    formItem.dataSource = _dataSource.reduce((acumulator, current, index) => {
                                        current.key = index + 1;
                                        acumulator[index + 1] = current;
                                        return acumulator;
                                    }, {});
                                } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                                    formItem.dataSource = {
                                        1: { key: 1, ..._dataSource },
                                    };
                                }

                                // formItem.dataSource = _dataSource;
                            }
                            // * Other cases
                            else if (formItem["@tipo"] === componentType._inputGroup) {
                                const _inputValues = value;

                                formItem = setInputGroupData(formItem, _inputValues);
                                formItem.value = _inputValues;
                                // for (const _key in _inputValues) {
                                //
                                //     if (formItem?.[_key]) {
                                //         formItem[_key]["value"] = _inputValues[_key];
                                //     }
                                // }
                                // item.dataSource = _dataSource;
                            } else if (formItem["@tipo"] === componentType.boolean && typeof value === "string") {
                                formItem.value = value.toQAFormValue();
                            } else {
                                formItem.value = value;
                            }
                        }

                        return formItem;
                    })
                    .reduce((acumulator, current) => {
                        acumulator[current.key] = current;
                        return acumulator;
                    }, {});
                const valuesThatKeyStartWithID = Object.keys(values).filter((field) => field.startsWith("id_"));

                valuesThatKeyStartWithID.forEach((key) => {
                    if (!form?.[key]) {
                        form[key] = {
                            key,
                            value: values[key],
                            "@visivel": "nao",
                        };
                    }
                });
            } else {
                form = normalizedFormObject;
            }
            return { taskData: form, values, formAttributes, metaInfoformFields };
        });
    };

export function setInputGroupData(inputData, value) {
    for (const key in inputData) {
        if (inputData?.[key]?.key === "profissao") {
            debugger;
        }

        if (typeof inputData[key] === "object" && inputData[key]["@tipo"] && inputData[key]["@tipo"] === "inputGroup") {
            // if (value[key]) {
            inputData[key] = setInputGroupData(inputData[key], value[key] || {});
            // }
        } else if (inputData[key] && typeof inputData[key] === "object" && inputData[key].key) {
            if (Utilitaries.isEmpty(value[key]) || (Array.isArray(value) && value.length === 0)) {
                // return inputData;
                inputData[key].value = "";
                continue;
            }
            if (
                inputData[key]["@tipo"] === componentType._dynamicTable ||
                inputData[key]["@tipo"] === componentType._selectionTable ||
                inputData[key]["@tipo"] === componentType.datagrid
            ) {
                let dataSource = Object.values(value[key])[0];
                if (Array.isArray(dataSource)) {
                    inputData[key].dataSource = dataSource.reduce((acumulator, current, index) => {
                        current.key = index + 1;
                        acumulator[index + 1] = current;
                        return acumulator;
                    }, {});
                } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                    inputData[key].dataSource = {
                        1: { key: 1, ...dataSource },
                    };
                }
            } else if (inputData[key]["@tipo"] === componentType._dynamicDatagrid) {
                const _dataSource = value[inputData[key].key];

                if (Array.isArray(_dataSource)) {
                    inputData[key].dataSource = _dataSource.reduce((acumulator, current, index) => {
                        current.key = index + 1;
                        acumulator[index + 1] = current;
                        return acumulator;
                    }, {});
                } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                    inputData[key].dataSource = {
                        1: { key: 1, ..._dataSource },
                    };
                }

                // formItem.dataSource = _dataSource;
            } else if (inputData[key]["@tipo"] === componentType.fixedList) {
                let dataSource = Object.values(value[key])[0];

                let values = Object.keys(value[key])[0];
                let fieldKey = null;

                for (const k in inputData[key][values]) {
                    if (
                        inputData[key][values][k] &&
                        typeof inputData[key][values][k] === "object" &&
                        inputData[key][values][k].key
                    ) {
                        fieldKey = k;
                    }
                }

                if (fieldKey) {
                    inputData[key].value = toArray(dataSource).map((item) => item[fieldKey]);
                } else {
                    inputData[key].value = dataSource || "";
                }
            } else {
                inputData[key].value = value[key] || "";
            }
        }
    }

    if (value) {
        const inputDataArray = Object.keys(inputData);
        const valuesThatKeyStartWithID = Object.keys(value).filter(
            (field) => field.startsWith("id_") && !inputDataArray.some((item) => item === field)
        );

        valuesThatKeyStartWithID.forEach((key) => {
            if (value[key]) {
                inputData = {
                    ...inputData,
                    [key]: {
                        key: key,
                        value: value[key],
                    },
                };
            }
        });
    }
    return inputData;
}

const getValueRealValue = ({ value, allPopupParams = {}, required, visible, fatherVisibility, input, temporary }) => {
    // if (!value || !isNaN(value)) {
    //     return value;
    // }
    if (!isEmpty(value)) {
        if (typeof value === "object") {
            value = "";
        } else {
            if (typeof value !== "number" && typeof value !== "boolean") {
                if (value?.startsWith("{$param.")) {
                    value = value.split("param.")[1].replace(/\W/g, "");

                    value = allPopupParams[value] || "";
                    /* } else if (/\$\w+\.\w+|dados_(mae|pai).\w+/.test(value)) {
                    value = ""; */
                } else if (value === "{now()}") {
                    value = moment().format("YYYY-MM-DD");
                }
            }
        }

        if (value === "t") {
            value = "true";
        }

        if (value === "f") {
            value = "false";
        }
    }

    const _visibility = input && typeof input === "object" ? input.visible : visible;

    // debugger;

    if (
        isEmpty(value) &&
        !temporary &&
        required &&
        _visibility &&
        (!fatherVisibility || !fatherVisibility?.includes(false))
    ) {
        debugger;
        if (Utilitaries.isEmpty(input?.requiredMessage)) {
            message.error("Tem campos obrigatórios em branco");
        } else {
            message.error(input.requiredMessage);
        }
        throw new Error("Empty");
    }

    return value;
};

function getFieldValue({ formDataItem, allPopupParams = {}, temporary, formData }) {
    let value = "";
    let requiredField = [];

    if (formDataItem.key === "lista_presencas_sessao_v2") {
        debugger;
    }

    if (formDataItem["@tipo"] === componentType.fixedList) {
        let upperKey = "";
        let deeperKey = "";
        for (const key in formDataItem) {
            if (
                typeof formDataItem[key] === "object" &&
                key !== "value" &&
                key !== "dataSource" &&
                key !== "inputGroupPath"
            ) {
                upperKey = key;
                for (const innerKey in formDataItem[key]) {
                    if (typeof formDataItem[key][innerKey] === "object") {
                        deeperKey = innerKey;
                    }
                }
            }
        }
        if (Array.isArray(formDataItem.value)) {
            value = {
                [upperKey]: formDataItem.value.map((item) => ({
                    [deeperKey]: item,
                })),
            };
        }
        return { [formDataItem.key]: value };
    }
    // table format data to be saved

    if (
        formDataItem["@tipo"] === componentType._dynamicTable ||
        formDataItem["@tipo"] === componentType._selectionTable ||
        formDataItem["@tipo"] === componentType._dynamicDatagrid ||
        formDataItem["@tipo"] === componentType.datagrid
    ) {
        // let allParamsDef = [];
        let upperKey = "";
        const checkRequiredIsExpression = /..\/.*|\/\/.*/;
        requiredField = [];

        if (
            formDataItem["@tipo"] === componentType.datagrid ||
            formDataItem["@tipo"] === componentType._dynamicDatagrid
        ) {
            upperKey = formDataItem.key;
            for (const key in formDataItem) {
                if (
                    typeof formDataItem[key] === "object" &&
                    !["value", "dataSource", "inputGroupPath", "path"].includes(key) &&
                    (formDataItem[key]?.["@obrigatorio"] === "true()" ||
                        formDataItem[key]?.["@obrigatorio"] === "true" ||
                        checkRequiredIsExpression.test(formDataItem[key]?.["@obrigatorio"]))
                ) {
                    requiredField.push(key);
                }
            }
        } else {
            for (const key in formDataItem) {
                if (
                    typeof formDataItem[key] === "object" &&
                    !["value", "dataSource", "inputGroupPath", "path"].includes(key)
                ) {
                    upperKey = key;
                    // allParamsDef = formDataItem[key];
                }
            }
            if (
                (formDataItem["@tipo"] === componentType._dynamicTable && formDataItem.visible !== false) ||
                formDataItem["@tipo"] !== componentType._dynamicTable
            ) {
                for (const key in formDataItem[upperKey]) {
                    if (
                        typeof formDataItem[upperKey][key] === "object" &&
                        !["value", "dataSource", "inputGroupPath", "path"].includes(key) &&
                        (formDataItem[upperKey][key]?.["@obrigatorio"] === "true()" ||
                            formDataItem[upperKey][key]?.["@obrigatorio"] === "true" ||
                            checkRequiredIsExpression.test(formDataItem[upperKey][key]?.["@obrigatorio"]))
                    ) {
                        requiredField.push(key);
                    }
                }
            }
        }

        // for (const key in formDataItem) {
        //     if (
        //         typeof formDataItem[key] === "object" &&
        //         !["value", "dataSource", "inputGroupPath", "path"].includes(key)
        //     ) {
        //         upperKey = key;
        //         // allParamsDef = formDataItem[key];
        //     }
        // }

        // SEMPRE ESTAVA COMENTADO
        // if (formDataItem["@tipo"] === componentType._dynamicDatagrid) {
        // 	upperKey = "linha";
        // }

        // requiredField = [];
        // for (const key in formDataItem[upperKey]) {
        //     if (
        //         typeof formDataItem[upperKey][key] === "object" &&
        //         !["value", "dataSource", "inputGroupPath", "path"].includes(key) &&
        //         (formDataItem[upperKey][key]?.["@obrigatorio"] === "true()" ||
        //             checkRequiredIsExpression.test(formDataItem[upperKey][key]?.["@obrigatorio"]))
        //     ) {
        //         requiredField.push(key);
        //     }
        // }

        const state = {
            form:
                typeof formData === "object" && !Array.isArray(formData)
                    ? formData
                    : formData.reduce((acummulator, currentInput) => {
                          acummulator[currentInput.key] = currentInput;

                          return acummulator;
                      }, {}),
        };

        const dataSource = Object.values(formDataItem?.dataSource || {});

        if (dataSource) {
            if (dataSource.length > 0 && !Utilitaries.isEmpty(dataSource[0]?._key_)) {
                //order by _key_
                dataSource.sort((a, b) => {
                    if (a._key_ > b._key_) return 1;
                    else if (a._key_ < b._key_) return -1;
                    return 0;
                });
            }
        }

        value = {
            [upperKey]: dataSource.map((item) => {
                const { key, ...withoutKey } = item;

                const rowKey = key;

                debugger;

                for (const key in withoutKey) {
                    let value = withoutKey[key];

                    const input = formDataItem?.[upperKey]?.[key] || formDataItem?.[key];

                    if (key.startsWith("id_") && !input) {
                        withoutKey[key] = value;
                        continue;
                    }
                    if (typeof value === "object") value = withoutKey[key]?.id;

                    // if (input.key === "qtd_existe_lote") {
                    /* if (input.key === "escala_utilizar") {
                        debugger;
                    } */

                    if (!input) {
                        delete withoutKey[key];
                        continue;
                    }

                    let visible = true;

                    let required = requiredField.includes(key);

                    if (required && checkRequiredIsExpression.test(input?.["@obrigatorio"])) {
                        const requiredEXP = conversion({
                            xpath: input?.["@obrigatorio"], //input?.["@relevante"],
                            item: input,
                            root: input?.root,
                            rowKey,
                            // relKey: itemKeys.find((key) => key.includes("relevante")),
                            state,
                            rootPath: input?.inputGroupPath,
                        });
                        const t = "t";
                        const f = "f";
                        try {
                            required = eval(requiredEXP) ? true : false;
                        } catch (error) {
                            console.error(error);
                            required = false;
                        }
                    }

                    const isRequiredField = input?.["@relevante"];
                    if (formDataItem?.visible === false) {
                        visible = false;
                    } else if (isRequiredField) {
                        const visibleEXP = conversion({
                            xpath: isRequiredField, //input?.["@relevante"],
                            item: input,
                            root: input?.root,
                            rowKey,
                            // relKey: itemKeys.find((key) => key.includes("relevante")),
                            state,
                            rootPath: input?.inputGroupPath,
                        });
                        const t = "t";
                        const f = "f";
                        try {
                            visible = eval(visibleEXP) ? true : false;
                        } catch (error) {
                            console.error(error);
                            visible = false;
                        }
                    } else if (input?.["@tipo"] === componentType._dataDisplay && !input?.["@dados"]) {
                        required = false;
                    }

                    withoutKey[key] = getValueRealValue({
                        value,
                        allPopupParams,
                        required,
                        visible /*: formDataItem[upperKey]?.[key]?.visible || true*/,
                        input: { ...input, visible },
                        temporary,
                    });
                }
                return withoutKey;
            }),
        };

        if (formDataItem["@tipo"] === componentType._dynamicDatagrid) {
            return { linha: { [formDataItem.key]: value[upperKey] } };
        } else if (formDataItem["@tipo"] === componentType.datagrid && formDataItem["@resultados_pagina"]) {
            return { linha: { [formDataItem.key]: value[upperKey] } };
        }
        return { [formDataItem.key]: value };
    }
}

function getInputGroupData({ inputData, result = {}, fatherVisibility = [], temporary, formData }) {
    for (const key in inputData) {
        if (inputData?.[key]?.key === "pai") {
            debugger;
        }
        if (typeof inputData[key] === "object" && inputData[key]["@tipo"] && inputData[key]["@tipo"] === "inputGroup") {
            let visible = inputData[key]?.visible;
            if (inputData[key]?.["@tab_relevante"]) {
                visible = restriction({
                    xpath: inputData[key]?.["@tab_relevante"],
                    state: { form: formData },
                    item: inputData[key],
                });
            }
            fatherVisibility.push(visible);
            result[key] = getInputGroupData({
                inputData: inputData[key],
                result: result[key] || {},
                fatherVisibility,
                temporary,
                formData,
            });
        } else if (inputData[key] && typeof inputData[key] === "object" && inputData[key].key) {
            if (
                inputData[key]["@tipo"] === componentType._dynamicTable ||
                inputData[key]["@tipo"] === componentType._selectionTable ||
                inputData[key]["@tipo"] === componentType._dynamicDatagrid ||
                inputData[key]["@tipo"] === componentType.datagrid ||
                inputData[key]["@tipo"] === componentType.fixedList
            ) {
                result = {
                    ...result,
                    ...getFieldValue({ formDataItem: inputData[key], temporary, formData }),
                };
            } else {
                result[key] = getValueRealValue({
                    value: isEmpty(inputData[key].value) ? "" : inputData[key].value,
                    required: inputData[key]?.required,
                    visible: inputData?.visible,
                    fatherVisibility,
                    input: inputData[key],
                    temporary,
                });
            }
        }
    }

    return result;
}

const getRequiredFieldsWhithoutRequiredKey = ({ field, state }) => {
    let required = field?.required;

    if (typeof required !== "undefined") {
        return required;
    }

    if (field?.["@obrigatorio"]) {
        required = isRequired({ itemData: field, state });
        return required;
    }

    return false;
};

export const saveForm =
    ({ formData, context, formName, formKey, params, process, task, isPortal, allPopupParams, temporary }) =>
    (dispatch, getState) => {
        // const temporaryRelRowKey = getState().processExecution.app.temporaryRelRowKey;
        // const temporaryRel = getState().processExecution.app.temporaryRel[temporaryRelRowKey];

        let hasRequiredFieldEmpty = false;
        let hasErrorEmpty = null;

        if (!formKey) {
            formKey = Object.values(formData).find((item) => item["formName"] === formName).formKey;
        }
        const formDataWrapper = new FormData();

        let data = Object.values(formData)
            // ===> get data only from form to post
            // .filter((formDataItem) => formDataItem.formKey === formKey)
            .filter(
                (formDataItem) =>
                    (!formDataItem["@persiste"] ||
                        formDataItem["@persiste"] !== "nao" ||
                        (formDataItem["@minVal"] && formDataItem["@tipo"] === componentType._dataDisplay)) &&
                    !Array.isArray(formDataItem)
            )
            // ===> transform data structure based on especific rules
            .map((formDataItem) => {
                let value = null;

                if (formDataItem.key === "lista_presencas_sessao_v2") {
                    debugger;
                }

                try {
                    //* fixed list data to be saved
                    if (
                        formDataItem["@tipo"] === componentType._dynamicTable ||
                        formDataItem["@tipo"] === componentType._selectionTable ||
                        formDataItem["@tipo"] === componentType._dynamicDatagrid ||
                        formDataItem["@tipo"] === componentType.datagrid ||
                        formDataItem["@tipo"] === componentType.fixedList
                    ) {
                        return getFieldValue({ formDataItem, allPopupParams, temporary, formData });
                    }

                    if (formDataItem["@tipo"] === componentType._inputGroup) {
                        if (formDataItem.visible === false) {
                            value = {};
                        } else {
                            value = getInputGroupData({ inputData: formDataItem, result: {}, temporary, formData });
                        }
                        return { [formDataItem.key]: value };
                    } else {
                        if (
                            formDataItem["@tipo"] === componentType._dataDisplay &&
                            formDataItem["@persiste"] === "nao" &&
                            formDataItem.visible &&
                            formDataItem["@minVal"]
                        ) {
                            if (
                                Utilitaries.toNumber(formDataItem?.value) <
                                Utilitaries.toNumber(formDataItem["@minVal"])
                            ) {
                                hasErrorEmpty = formDataItem?.["@minValErrorMessage"];
                                throw new Error("EmptyTable");
                            }
                        }

                        const fieldIsRequired = getRequiredFieldsWhithoutRequiredKey({
                            field: formDataItem,
                            state: { form: formData },
                        });

                        const fieldIsVisible = isFieldVisibility({ field: formDataItem, state: { form: formData } });

                        value = getValueRealValue({
                            value: formDataItem.value,
                            allPopupParams,
                            required: fieldIsRequired,
                            visible: fieldIsVisible,
                            temporary,
                            input: formDataItem,
                        });

                        // if (formDataItem?.required === true && isEmpty(value)) {
                        //     hasRequiredFieldEmpty = true;
                        // }

                        // if (!(formDataItem["@restricao"] && formDataItem["@tipo"] === componentType._dataDisplay)) {
                        return { [formDataItem.key]: value };
                        // }
                    }
                } catch (error) {
                    console.error(error, error.message, formDataItem);
                    if (error?.message === "Empty") {
                        hasRequiredFieldEmpty = true;
                    }
                    return value;
                }
            })
            // ===> convert array to object
            .reduce((acumulator, current) => {
                if (current?.linha) {
                    acumulator = {
                        ...acumulator,
                        linha: {
                            ...acumulator.linha,
                            ...current.linha,
                        },
                    };
                } else {
                    acumulator = { ...acumulator, ...current };
                }
                return acumulator;
            }, {});

        // ===> full task save
        // return Promise.resolve("empty");
        if (hasRequiredFieldEmpty && !temporary) {
            // message.error("Tem campos obrigatórios em branco");
            return Promise.resolve("empty");
        }

        if (hasErrorEmpty) {
            message.error(hasErrorEmpty);
            return Promise.resolve("empty");
        }

        let formMeta = getState().processExecution.domain.byId[process].tasks[task].formMeta[0];
        data.actividade = data.actividade || formMeta.meta.actividade;
        if (context === "root") {
            // ===> get the root formMeta : formMeta[0]
            if (!isPortal) {
                formDataWrapper.append("processo", process);
                formDataWrapper.append("tarefa", task);
            }
            // TODO: enviar valencia e valencia_desgnacao se necessarios
            data.processo = process;
            // data.actividade = data.actividade || formMeta.meta.actividade;
            data.modelo = data?.modelo || formMeta.meta.modelo;
            data.valencia = data?.valencia || formMeta.meta?.valencia;
            data.estabelecimento = data?.estabelecimento || formMeta.meta.estabelecimento;
            data.cliente = data.cliente || formMeta.meta?.cliente;
        }
        // TODO: Remover
        if (context === "modal") {
            if (!data.processo) {
                data.processo = allPopupParams.idProcesso;
            }
            data.actividade_origem = data.actividade_origem || allPopupParams.idActividadeOrigem;
            // data.modelo = allPopupParams.idModeloOrigem;
            // data.modelo = 2;
            // Necessario no modal - qualia_dr39_01_registo_clientes_pop.qml.xml
            data.modelo = data.modelo || allPopupParams?.idModelo || allPopupParams.idModeloOrigem;

            if (data?.modelo?.startsWith("{$param.idModelo")) {
                data.modelo = allPopupParams?.idModelo || allPopupParams.idModeloOrigem;
            }

            data.modelo_origem = allPopupParams.idModeloOrigem;
            data.estabelecimento = allPopupParams.idEstabelecimento;
        }

        // let felteredData = {};
        for (const key in data) {
            if (formData && formData.length && key !== "linha") {
                const element = formData.find((item) => item.key === key);

                if ((element?.key === key && element?.["@persiste"] === "nao") || !element) {
                    delete data[key];
                }

                if (Utilitaries.isEmpty(data[key])) {
                    delete data[key];
                }
            }
        }

        if (Session.isCLient()) {
            const params = Session.getAll();
            formDataWrapper.append("usr_parametro1", params.parametro1);
            formDataWrapper.append("usr_parametro2", params.parametro2);
            formDataWrapper.append("usr_parametro3", params.parametro3);
        }

        formDataWrapper.append("accao", "guardar");
        formDataWrapper.append("formulario", formName);
        formDataWrapper.append("dados", JSON.stringify({ [formKey]: data }));
        formDataWrapper.append("obrigatorios", temporary ? "true" : "false");
        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formDataWrapper, {}, formData);
    };
