import { Utilitaries } from "../../../business";
import { DEFAULT_COLORS } from "../../../components/FlowChart/utils/constants";
import { toArray } from "../../../components/util";

const itemSize = (text = "") => {
    let width = displayTextWidth(text);
    return [width, 30];
};

function displayTextWidth(text) {
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = "14px Arial";
    let metrics = context.measureText(text);
    return metrics.width;
}

const getGraphData = (items = []) => {
    const data = {
        nodes: [],
        edges: [],
    };

    toArray(items).forEach((item) => {
        const fathers = Utilitaries.toString(item.entrada).split(";");
        const childrens = Utilitaries.toString(item.saida).split(";");

        toArray(fathers).forEach((father, index) => {
            if (father) {
                data.nodes.push({
                    id: father,
                    dataType: "alps",
                    label: father,
                    size: itemSize(father),
                    style: {
                        fontSize: 12,
                        // stroke: DEFAULT_COLORS.TASKS_FINISHED,
                    },
                });

                data.edges.push({
                    id: `${data.edges.length}`,
                    source: father,
                    target: item.nome,
                });
            }
        });

        data.nodes.push({
            id: item.nome,
            dataType: "alps",
            label: item.nome,
            size: itemSize(item.nome),
            style: {
                fontSize: 12,
                // stroke: DEFAULT_COLORS.TASKS_GOING,
            },
        });

        childrens.forEach((child) => {
            if (child) {
                data.nodes.push({
                    id: child,
                    dataType: "alps",
                    label: child,
                    size: itemSize(child),
                    style: {
                        fontSize: 12,
                        // stroke: DEFAULT_COLORS.TASKS_UNASSIGNED,
                    },
                });

                data.edges.push({
                    id: `${data.edges.length}`,
                    source: item.nome,
                    target: child,
                });
            }
        });
    });

    return data;
};

export default getGraphData;
