import { Button, message, Modal, Popconfirm, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Utilitaries } from "../../../../../business";
import { api_fetch } from "../../../../utils/HttpRequest/request";

const { Option } = Select;

interface IProps {
    isVisible: boolean;
    handleClose: (isCancel: boolean, id?: string, length?: number) => void;
    id: string;
    // functionalGroups: any[];
}

export function DepartmentModal({ isVisible, handleClose, id }: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [allDepartments, setAllDepartments] = useState<any[]>([]);
    const [departmentsList, setDepartmentsList] = useState<any[]>([]);

    useEffect(() => {
        if (id) {
            getForm(id);
        }

        getDepartments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    async function getForm(id: string) {
        setIsLoading(true);

        const resp = await api_fetch({
            endPoint: "/Gestor/gereformulario.php",
            method: "POST",
            params: {
                accao: "dados",
                formulario: "qa_ficha_colaborador_departamentos.qml.xml",
                id,
            },
        });

        if (resp.status === "SUCCESS") {
            const { valores } = resp.response;
            if (valores) {
                if (valores.fcb_departamentos) {
                    const { fcb_departamentos } = valores;
                    if (fcb_departamentos.fcb_lista_departamentos) {
                        const { fcb_lista_departamentos } = fcb_departamentos;
                        if (fcb_lista_departamentos.fcb_lista_departamento) {
                            const { fcb_lista_departamento } = fcb_lista_departamentos;
                            if (Array.isArray(fcb_lista_departamento)) {
                                const _dataSource: any[] = [];
                                fcb_lista_departamento.forEach((item, index) => {
                                    if (
                                        typeof item.valencia !== "object" &&
                                        typeof item.id_fcb_lista_departamento !== "object"
                                    )
                                        _dataSource.push({
                                            key: index + 1,
                                            ...item,
                                        });
                                });
                                setDataSource(_dataSource);
                            } else if (fcb_lista_departamento.id_fcb_lista_departamento) {
                                setDataSource([{ key: 1, ...fcb_lista_departamento }]);
                            }
                        }
                    }
                }
            }
        }
        setIsLoading(false);
    }

    function getDepartments() {
        api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "departamentos_atuais_manual_funcoes",
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                const { result } = response.response;
                setAllDepartments(Utilitaries.toArray(result));
                setDepartmentsList(Utilitaries.toArray(result));
            }
        });
    }

    function handleDelete(key: number) {
        const _dataSource = [...dataSource];
        setDataSource(_dataSource.filter((item, index) => item.key !== key));
    }

    function handleAdd() {
        const newData = { key: dataSource.length + 1 };
        setDataSource([newData, ...dataSource]);
    }

    async function handleOk() {
        let empty_row = false;

        let fcb_departamentos: any = {
            fcb_lista_departamentos: {
                fcb_lista_departamento: dataSource.map((item) => {
                    if (!item.valencia) {
                        empty_row = true;
                    }
                    delete item.id_fcb_lista_departamento;
                    return item;
                }),
            },
        };

        if (!empty_row) {
            setIsLoading(true);

            const resp = await api_fetch({
                endPoint: "/Gestor/gereprocesso.php",
                method: "POST",
                params: {
                    accao: "guardar",
                    formulario: "qa_ficha_colaborador_departamentos.qml.xml",
                    dados: JSON.stringify({ fcb_departamentos }),
                },
            });

            setIsLoading(false);
            if (resp.status === "SUCCESS") {
                message.success("Sucesso!");
                const { sucesso } = resp.response;
                handleClose(false, sucesso, dataSource.length);
            } else {
                message.error("Erro ao guardar departamentos!");
            }
        } else message.warning("Linhas vazios tem de ser preenchidas ou removidas!!");
    }

    function handleCancel() {
        handleClose(true);
    }

    function onChange(dataIndex: string, value: any, record: any) {
        record[dataIndex] = value;
        const index = dataSource.findIndex((item) => record.key === item.key);
        dataSource[index] = record;
        setDataSource([...dataSource]);
    }

    function dataSourceDifference(id: string) {
        const userDepartamento = dataSource.map((item) => item.valencia);

        const newDataSource = allDepartments.filter((item) => !userDepartamento.includes(item.id_departamento));

        setDepartmentsList(newDataSource);
    }

    const columns = [
        {
            title: "",
            dataIndex: "valencia",
            width: "80%",
            render: (text: string, record: any) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona a resposta social"
                    optionFilterProp="children"
                    defaultValue={text}
                    onChange={(value: string) => {
                        dataSourceDifference(value);
                        onChange("valencia", value, record);
                    }}
                    filterOption={(input, option) =>
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {departmentsList.map((item) => {
                        return (
                            <Option key={item.id_departamento} value={item.id_departamento}>
                                {item.designacao}
                            </Option>
                        );
                    })}
                </Select>
            ),
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (_: any, record: any) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="Eliminar a linha?" onConfirm={() => handleDelete(record.key)}>
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    return (
        <Modal
            centered
            width="70%"
            visible={isVisible}
            title="Unidade orgânica"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    voltar
                </Button>,
                <Button
                    icon="save"
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={handleOk}
                    disabled={dataSource.length ? false : true}
                >
                    Guardar
                </Button>,
            ]}
        >
            <div>
                <Row>
                    <Button
                        onClick={handleAdd}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{ marginBottom: 16, float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Row>
                <Table
                    rowKey={(record: any) => record.key}
                    size="small"
                    scroll={{ x: 850, y: 300 }}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                />
            </div>
        </Modal>
    );
}
