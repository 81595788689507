import React, { useState, useEffect } from "react";
import { Drawer, Form, Col, Select, Radio, DatePicker, Button, Skeleton, message } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/pt_PT";
import { connect } from "react-redux";

import {
    selectEstabeleciment,
    selectUnitOrganicByEstabeleciment,
    selectFuncionalGroupByUnitORganic,
    selectAllContributors,
} from "../../../fixed_list/actions/index";
import { selectPointRegisterByParametrization } from "../../actions/contributor.time.registration";
import { createPdfFile, prepareDataToReport } from "./createPdfReport";

const { Option } = Select;
const { RangePicker } = DatePicker;

const MenuOptionsToGeneratePdfReport = ({
    show,
    hidde,
    fixedList,
    selectEstabeleciment,
    selectUnitOrganicByEstabeleciment,
    selectFuncionalGroupByUnitORganic,
    selectAllContributors,
    selectPointRegisterByParametrization,
}) => {
    const [showDrawer, setShowDrawer] = useState(show);
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({ loadingButton: false });

    const getUnitOrganic = (estabelecimentID) => {
        setState({ ...state, estabelecimentID });
        if (estabelecimentID) {
            selectUnitOrganicByEstabeleciment({
                accao: "lista",
                lista: "lista_departamentos_por_estabelecimento",
                id_estabelecimento: estabelecimentID,
            }).then((sucess) => {
                if (!sucess) message.error("Ocorreu um erro");
            });
        } else {
            selectUnitOrganicByEstabeleciment({
                accao: "lista",
                lista: "lista_departamentos",
            }).then((sucess) => {
                if (!sucess) message.error("Ocorreu um erro");
            });
        }
    };

    const getFuncionalGroup = (unitOrganicID) => {
        setState({ ...state, unitOrganicID });
        if (unitOrganicID) {
            selectFuncionalGroupByUnitORganic({
                accao: "lista",
                lista: "lista_grupofuncional_por_departamento",
                id_departamento: unitOrganicID,
            }).then((sucess) => {
                if (!sucess) message.error("Ocorreu um erro");
            });
        } else {
            selectFuncionalGroupByUnitORganic({
                accao: "lista",
                lista: "lista_grupo_funcional",
            }).then((sucess) => {
                if (!sucess) message.error("Ocorreu um erro");
            });
        }
    };

    useEffect(() => {
        if (fixedList.estabelecimento === undefined) {
            selectEstabeleciment({
                accao: "lista",
                lista: "lista_estabelecimentos",
                id_instituicao: "1",
            }).then((sucess) => {
                if (!sucess) message.error("Ocorreu um erro");
                setLoading(false);
            });
        } else setLoading(false);

        selectUnitOrganicByEstabeleciment({
            accao: "lista",
            lista: "lista_departamentos",
        }).then((sucess) => {
            if (!sucess) message.error("Ocorreu um erro");
        });

        selectFuncionalGroupByUnitORganic({
            accao: "lista",
            lista: "lista_grupo_funcional",
        }).then((sucess) => {
            if (!sucess) message.error("Ocorreu um erro");
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let param = {
            accao: "lista_funcionarios",
            lista: "0",
        };
        if (state.estabelecimentID !== undefined) {
            param = { ...param, id_estabelecimento: state.estabelecimentID };
        }
        if (state.unitOrganicID !== undefined) {
            param = { ...param, id_unit_organic: state.unitOrganicID };
        }
        if (state.funcionalGroupID !== undefined) {
            param = { ...param, id_funcional_group: state.funcionalGroupID };
        }
        selectAllContributors({ ...param }).then((sucess) => {
            if (!sucess) message.error("Ocorreu um erro ao carregar utilizadores");
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.estabelecimentID, state.unitOrganicID, state.funcionalGroupID]);

    const getPointRegistryByParameters = () => {
        setState({ ...state, loadingButton: true });
        let param = {
            startDate: state.startDate,
            endDate: state.endDate,
        };
        if (state.username !== undefined) param = { ...param, username: state.username };
        selectPointRegisterByParametrization({
            accao: "lista_parametrizado_registo_ponto",
            lista: "0",
            ...param,
        }).then((result) => {
            setState({ ...state, loadingButton: false });
            if (result) {
                if (result.length > 0) {
                    const value = prepareDataToReport(result);
                    createPdfFile(value, {
                        startDate: state.startDate,
                        endDate: state.endDate,
                    });
                } else {
                    message.info(
                        `${state.username} não possui dados sobre registo de ponto no intervalo da data indicado`
                    );
                }
            } else {
                message.error("Ocorreu um erro");
            }
        });
    };

    const prepareValue = (data) => {
        if (data) {
            if (Array.isArray(data)) return data;
            else if (Object.keys(data).length > 0) return [data];
        }
        return [];
    };

    return (
        <Drawer
            title="Gerar relatório de registo de ponto"
            placement="right"
            width={520}
            closable={true}
            onClose={hidde}
            visible={showDrawer}
            getContainer={false}
        >
            <Form layout="vertical">
                <Col xs={24}>
                    <Skeleton loading={loading} paragraph={{ rows: 1, width: "100%" }} active>
                        <Form.Item label="Estabelecimento">
                            <Select
                                disabled={state.loadingButton}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                onChange={(estabelecimentID) => getUnitOrganic(estabelecimentID)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {!loading &&
                                    prepareValue(fixedList.estabelecimento.data).map((item) => (
                                        <Option key={item.estabelecimento} value={item.estabelecimento}>
                                            {typeof item.nome === "string" && item.nome}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Skeleton>
                </Col>
                <Col xs={24}>
                    <Skeleton loading={loading} paragraph={{ rows: 1, width: "100%" }} active>
                        <Form.Item label="Unidade orgânica">
                            <Select
                                disabled={state.loadingButton}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                onChange={(unitOrganicID) => getFuncionalGroup(unitOrganicID)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {fixedList.unidade_organica !== undefined &&
                                    prepareValue(fixedList.unidade_organica.data).map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.descricao}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Skeleton>
                </Col>
                <Col xs={24}>
                    <Skeleton loading={loading} paragraph={{ rows: 1, width: "100%" }} active>
                        <Form.Item label="Grupo funcional">
                            <Select
                                disabled={state.loadingButton}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                onChange={(funcionalGroupID) => setState({ ...state, funcionalGroupID })}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {fixedList.grupo_funcional !== undefined &&
                                    prepareValue(fixedList.grupo_funcional.data).map((item) => (
                                        <Option key={item.id_ficha_funcao} value={item.id_ficha_funcao}>
                                            {item.codigo}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Skeleton>
                </Col>
                <Col xs={24}>
                    <Skeleton loading={loading} paragraph={{ rows: 1, width: "100%" }} active>
                        <Form.Item label="Todos os funcionários?">
                            <Radio.Group
                                defaultValue={false}
                                buttonStyle="solid"
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        allContributors: e.target.value,
                                    })
                                }
                            >
                                <Radio.Button value={true}>Sim</Radio.Button>
                                <Radio.Button value={false}>Não</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Skeleton>
                </Col>
                {!state.allContributors && (
                    <Col xs={24}>
                        <Skeleton loading={loading} paragraph={{ rows: 1, width: "100%" }} active>
                            <Form.Item label="Funcionário/a">
                                <Select
                                    disabled={state.loadingButton}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(contributorID) =>
                                        setState({
                                            ...state,
                                            username: contributorID,
                                        })
                                    }
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {fixedList.funcionarios !== undefined &&
                                        prepareValue(fixedList.funcionarios.data).map((item) => (
                                            <Option key={item.username} value={item.username}>
                                                {item.nome}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Skeleton>
                    </Col>
                )}
                <Col xs={24}>
                    <Skeleton loading={loading} paragraph={{ rows: 1, width: "100%" }} active>
                        <Form.Item style={{ marginBottom: 70 }} label="Selecione o intervalo da data (de - até)">
                            <RangePicker
                                locale={locale}
                                ranges={{
                                    Hoje: [moment(), moment()],
                                    "Este mês": [moment().startOf("month"), moment().endOf("month")],
                                }}
                                onChange={(dates, dateStrings) => {
                                    setState({
                                        ...state,
                                        startDate: dateStrings[0],
                                        endDate: dateStrings[1],
                                    });
                                }}
                            />
                        </Form.Item>
                    </Skeleton>
                </Col>
            </Form>
            <div
                style={{
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    width: "100%",
                    borderTop: "1px solid #e9e9e9",
                    padding: "10px 16px",
                    background: "#fff",
                    textAlign: "right",
                }}
            >
                <Button onClick={hidde} style={{ marginRight: 8 }}>
                    Cancelar
                </Button>

                <Button
                    loading={state.loadingButton}
                    disabled={
                        (state.allContributors && state.startDate && state.endDate) ||
                        (state.startDate && state.endDate && state.username)
                            ? false
                            : true
                    }
                    onClick={getPointRegistryByParameters}
                    type="primary"
                >
                    Exportar
                </Button>
            </div>
        </Drawer>
    );
};
/** return state from redux */
const mapStateToProps = (state) => {
    return {
        fixedList: state.fixedList.domain.byId,
    };
};

/** get actionscCreators */
const mapDispatchToProps = {
    selectEstabeleciment,
    selectUnitOrganicByEstabeleciment,
    selectFuncionalGroupByUnitORganic,
    selectAllContributors,
    selectPointRegisterByParametrization,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuOptionsToGeneratePdfReport);

/** handle estabeleciment select change */
const handleEstabelecimentChenge = (value) => {};
