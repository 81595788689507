import { Button, Col, Icon, Result, Row, Spin, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { Session } from "../../../business";
import { api } from "../../http.request";
import { getuserComputerInfo, queryToString } from "../../util";

const { Paragraph, Text } = Typography;

export const LoginUserPortal = () => {
    const history = useHistory();

    const [error, setError] = React.useState(null);

    const userInfo = getuserComputerInfo();

    React.useEffect(() => {
        // login do portal
        if (Session.isAuthenticated()) {
            history.replace("/");
        } else {
            autoLoginPortal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const autoLoginPortal = async () => {
        const formData = new FormData();
        formData.append("accao", "login-json");
        formData.append("utilizador", "_portal_");
        formData.append("senha", "_portal_");
        formData.append("os", userInfo.plataform);
        formData.append("browser", userInfo.browser);
        formData.append("computer", userInfo.computer_uuid);

        try {
            const resp = await api("/Gestor/gereutilizador.php", formData);
            if (resp.erro) {
                setError(resp.erro);
            } else {
                Session.save({ username: resp.username, name: resp.nome, token: resp.token, type: resp.tipo });
                const query = queryToString();

                if (query["redirect"]) {
                    const redirect = query["redirect"];
                    if (redirect.startsWith("/login")) {
                        window.location.hash = "/";
                    } else {
                        window.location.hash = redirect || "/";
                    }
                } else {
                    window.location.reload();
                }
            }
        } catch (error) {
            console.error(error);
            setError(error.erro);
        }
    };

    const goto = (path) => {
        history.push({
            pathname: path,
        });
    };

    return (
        <Row
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Col xs={24} md={16} lg={12}>
                {!error ? (
                    <Result icon={<Icon type="loading" />} title="Aguarde o portal esta sendo carregando..." />
                ) : (
                    // <Spin spinning={true}>
                    //     <div style={{ width: "100vw", height: "100vh" }}></div>
                    // </Spin>
                    <Result
                        status="error"
                        title="Falha na submissão"
                        subTitle="Volte a tentar novamente ou comunique o administrador da página"
                        extra={[
                            <Button type="primary" key="console" onClick={() => goto("/login")}>
                                Login como utilizador
                            </Button>,
                            <Button
                                key="buy"
                                onClick={() => {
                                    setError(null);
                                    autoLoginPortal();
                                }}
                            >
                                Tentar novamente
                            </Button>,
                        ]}
                    >
                        <div className="desc">
                            <Paragraph>
                                <Text
                                    strong
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    Erro:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Icon style={{ color: "red" }} type="close-circle" /> {error}
                            </Paragraph>
                        </div>
                    </Result>
                )}
            </Col>
        </Row>
    );
};
