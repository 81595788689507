import React, { Component } from "react";
import { Modal, Form, Row, Col, Input, message } from "antd";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/requirement.type";
const { TextArea } = Input;

class FormRequirementType extends Component {
    state = {
        loading: false,
    };
    checkResponse = (sucess) => {
        if (sucess) {
            message.success("Guardado com sucesso");
            this.props.hiddeModal();
            //TODO: remover posteriormente
            this.props.select({
                accao: "lista",
                lista: "tipos_requisitos",
                pagina: 1,
                resultados_pagina: 0,
                mostra_lista_eliminados: this.props.showDeletedRecords,
            });
            /****************************** */
        } else {
            message.error("Ocorreu um erro");
            this.setState({ loading: false });
        }
    };
    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                if (this.props.isEdit) {
                    this.props
                        .update({
                            accao: "guardar",
                            formulario: "qa_tipos_requisitos.xml",
                            dados: JSON.stringify({
                                tipo_requisito: {
                                    ...values,
                                    id_tipo_requisito: this.props.dataToEdit.id_tipo_requisito,
                                    activo: true,
                                    versao:
                                        typeof this.props.dataToEdit.versao !== "object"
                                            ? this.props.dataToEdit.versao + 1
                                            : 1,
                                },
                            }),
                        })
                        .then((sucess) => {
                            this.checkResponse(sucess);
                        });
                } else {
                    this.props
                        .insert({
                            accao: "guardar",
                            formulario: "qa_tipos_requisitos.xml",
                            dados: JSON.stringify({
                                tipo_requisito: {
                                    ...values,
                                    activo: true,
                                    versao: 1,
                                },
                            }),
                        })
                        .then((sucess) => {
                            this.checkResponse(sucess);
                        });
                }
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const title = this.props.isEdit ? "Editar tipo de requisito" : "Novo tipo de requisito";

        return (
            <Modal
                title={title}
                visible={this.props.isVisible}
                onOk={this.handleOk}
                onCancel={this.props.hiddeModal}
                width="50%"
                confirmLoading={this.state.loading}
            >
                <Form layout="vertical" onSubmit={this.handleOk}>
                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Designação">
                                {getFieldDecorator("designacao", {
                                    initialValue: this.props.dataToEdit.designacao,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    initialValue: this.props.dataToEdit.descricao,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<TextArea rows={4} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    setField: actionCreators.setField,
    insert: actionCreators.insert,
    select: actionCreators.select,
    update: actionCreators.update,
};

const mapStateToProps = (state) => {
    return {
        formRequirementType: state.requirementType.domain.form,
    };
};

const formCreate = Form.create({
    mapPropsToFields(props) {
        if (!props.isEdit) {
            return {
                designacao: Form.createFormField({
                    ...props.formRequirementType.designacao,
                    value: props.formRequirementType.designacao.value,
                }),
                descricao: Form.createFormField({
                    ...props.formRequirementType.descricao,
                    value: props.formRequirementType.descricao.value,
                }),
            };
        }
    },
    onFieldsChange(props, fields) {
        if (!props.isEdit) props.setField(fields);
    },
})(FormRequirementType);

export default connect(mapStateToProps, mapDispatchToProps)(formCreate);
