import React from "react";
import { baseUrl } from "../constants/enviroment";
import { useHistory } from "react-router-dom";
import { Session } from "../business";
import { message, Modal } from "antd";
// To cancel request
const controller = new AbortController();
const signal = controller.signal;

const removeDataForRequest = (formData) => {
    let isSave = false;
    let formKey = null;
    let process = null;
    let task = null;

    for (var pair of formData.entries()) {
        if (pair[0] === "accao" && pair[1] === "guardar") {
            isSave = true;
        } else if (pair[0] === "formulario") {
            formKey = pair[1];
        } else if (pair[0] === "tarefa") {
            task = pair[1];
        } else if (pair[0] === "processo") {
            process = pair[1];
        }
    }

    if (task && process) {
        formKey = `${process}-${task}`;
    }

    if (isSave && formKey) {
        localStorage.removeItem(formKey);
    }
};

const saveDataForRequest = (formData, route = "", backup = null) => {
    if (!route.includes("gereprocesso") || !backup) {
        return;
    }

    let isSave = false;
    let formKey = null;
    let process = null;
    let task = null;

    for (var pair of formData.entries()) {
        if (pair[0] === "accao" && pair[1] === "guardar") {
            isSave = true;
        } else if (pair[0] === "formulario") {
            formKey = pair[1];
        } else if (pair[0] === "tarefa") {
            task = pair[1];
        } else if (pair[0] === "processo") {
            process = pair[1];
        }
    }

    if (task && process) {
        formKey = `${process}-${task}`;
    }

    if (!isSave || !formKey) {
        return;
    }

    const tempSaveData = btoa(JSON.stringify(backup));
    localStorage.setItem(formKey, tempSaveData);
};

export const sendHttpRequest = (method, endPoint, data, header = {}, backup = null) => {
    return fetch(`${baseUrl}${endPoint}`, {
        method: method,
        signal,
        body: data,
        headers: {
            Authorization: `Bearer ${Session.getToken()}`,
        },
    })
        .then((response) => {
            if (response.status >= 400) {
                if (response.status == 401 || response.status == 403) {
                    // alert("Ir para login");

                    saveDataForRequest(data, endPoint, backup);
                    Session.remove();
                    window.location.reload();
                }

                return response.json().then((responseError) => {
                    const error = new Error("");
                    error.data = responseError;
                    throw error;
                });
            }
            removeDataForRequest(data);

            return response
                .clone()
                .text()
                .then((text) => {
                    if (/error/i.test(text) || /erroqa/i.test(text) || /context:[\W\w]+/.test(text)) {
                        const erro = text.replace(/error:|erroqa:|context:[\W\w]+/gi, "");
                        console.log({ erro, text });

                        // Modal.warning({
                        //     title: "Aviso",
                        //     content: (
                        //         <div>
                        //             <span style={{ whiteSpace: "pre-line" }}>{erro.replaceAll("\\n", "\n")}</span>
                        //         </div>
                        //     ),

                        // });

                        if (backup) {
                            message.warn(
                                <div>
                                    <span style={{ whiteSpace: "pre-line" }}>{erro.replaceAll("\\n", "\n")}</span>{" "}
                                </div>,
                                10
                            );
                        }
                    }

                    return response.json();
                });

            // return response.json();
        })
        .catch((error) => {
            console.log({ error });
            return false;
        });
};

// Use on login
export const api = (endPoint, data, method = "POST", backup = null) =>
    new Promise(async (resolve, reject) => {
        fetch(`${baseUrl}${endPoint}`, {
            method: method,
            body: data,
            signal,
            headers: {
                Authorization: `Bearer ${Session.getToken()}`,
            },
        })
            .then((response) => {
                // console.log("res", response);
                // console.log("res", response.text());
                // console.log("res", response.body);
                if (response.status >= 400) {
                    if (response.status == 401 || response.status == 403) {
                        // alert("Ir para login");
                        saveDataForRequest(data, endPoint, backup);
                        // controller.abort();

                        Session.remove();

                        // const hash = window.location?.hash?.replace("#", "");

                        // history.replace("/login?redirect=" + hash);

                        // window.location.hash = "/login?redirect=" + hash;

                        window.location.reload();
                        // const history = useHistory();
                        // const hash = window.location?.hash?.replace("#", "");

                        // history.replace("/login?redirect=" + hash);

                        // window.location.href = "/login?redirect=" + window.location.pathname;
                    }
                    // !response.ok
                    return response.json().then((responseError) => {
                        return reject(responseError);
                    });
                }

                removeDataForRequest(data);

                return response
                    .clone()
                    .text()
                    .then((text) => {
                        if (/error/i.test(text) || /erroqa/i.test(text) || /line/.test(text)) {
                            return reject({
                                erro: text.replace(/error:|erroqa:|context:[\W\w]+/gi, ""),
                            });
                        }

                        return response.json().then((response) => {
                            if (response.erro) {
                                return reject(response);
                            } else {
                                return resolve(response);
                            }
                            // return reject(responseError);
                        });
                    });

                // return response.json().then((response) => {
                //   if (response.erro) {
                //     console.log("eror1", response, response.erro);
                //     return reject(response);
                //   } else {
                //     return resolve(response);
                //   }
                //   // return reject(responseError);
                // });
                // return resolve(response.json());
                // const contentType = response.headers.get("content-type");
                // console.log(
                //     "Content",
                //     contentType,
                //     contentType.indexOf("application/json")
                // );
                // if (
                //     contentType &&
                //     contentType.indexOf("application/json") !== -1
                // ) {
                //     return response.json().then(data => {
                //         return resolve(data);
                //     });
                // } else {
                //     return response.text().then(text => {
                //         return reject({
                //             erro: text
                //         });
                //     });
                // }
            })
            .catch((error) => {
                return reject({
                    erro: error.message,
                });
            });
    });

//use to get file
export const sendSimpleHttpRequest = (method, endPoint, data, header = {}) => {
    return fetch(`${baseUrl}${endPoint}`, {
        method: method,
        signal,
        body: data,
        headers: {
            Authorization: `Bearer ${Session.getToken()}`,
        },
    })
        .then((response) => {
            if (response.status >= 400) {
                if (response.status >= 401 || response.status <= 403) {
                    Session.remove();
                    // controller.abort();
                    // window.location.href = "/login?redirect=" + window.location.pathname;
                    // const hash = window.location?.hash?.replace("#", "");

                    // history.replace("/login?redirect=" + hash);

                    // window.location.hash = "/login?redirect=" + hash;

                    window.location.reload();
                }

                return response;
            }
            return response;
        })
        .catch((error) => {
            return false;
        });
};
