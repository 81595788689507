import { actionType } from "../constants/details";
import { api, sendHttpRequest } from "../../../components/http.request";
import { toArray } from "../../../components/util";
import { message } from "antd";

export const setAttributionType = (payload) => ({
    type: actionType.setAttributionType,
    payload,
});

export const setTaskDescription = (payload) => ({
    type: actionType.setTaskDescription,
    payload,
});

export const setTaskAuthorizations = (payload) => ({
    type: actionType.setTaskAuthorizations,
    payload,
});

export const removeTaskAuthorizations = (payload) => ({
    type: actionType.removeTaskAuthorizations,
    payload,
});

export const setGroupToDelegateProcessDef = (payload) => ({
    type: actionType.setGroupToDelegateProcessDef,
    payload,
});

export const addGoalIndicator = () => ({
    type: actionType.addGoalIndicator,
});

export const removeGoalIndicator = (payload) => ({
    type: actionType.removeGoalIndicator,
    payload,
});

export const setUserToDelegateProcessDef = (payload) => ({
    type: actionType.setUserToDelegateProcessDef,
    payload,
});

export const setPolicyGoalIndicator = (payload) => ({
    type: actionType.setPolicyGoalIndicator,
    payload,
});

export const getGroupsToDelegate = (payload) => (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "lista_delegacoes");
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
};

export const getUserByGroup = (group) => (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "utilizadores_grupo");
    formData.append("grupo", group);
    return sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
};

export const updateProcessDefinition = () => (dispatch, getState) => {
    const data = getState().processDefinition.domain.form;
    const formData = new FormData();
    const {
        id_definicao,
        definicao,
        processosAssociados,
        ambito,
        codigo,
        dono,
        goalsIndicators,
        categoria,
        versao,
        departamento,
        nome,
        tasks,
    } = data;
    // todo: figure out more info about nome_externo field
    if (!ambito?.value || !codigo?.value || !categoria?.value || !departamento?.value || !nome?.value || !dono.value) {
        message.error(
            `Campos obrigatórios em branco no separador Processo:${!ambito.value ? " Ambito" : ""} ${
                !codigo.value ? " Código" : ""
            } ${!categoria.value ? " Categoria" : ""} ${!departamento.value ? " Unidade orgânica" : ""} ${
                !nome.value ? " Nome" : ""
            } ${!dono.value ? " Dono" : ""}`
        );
        return undefined;
    }
    const tarefas = Object.values(tasks).map((task) => {
        const autorizacoes = task.allAuthorizations.reduce((acum, curr, index) => {
            const { authId, ...acessos } = curr;
            for (const key in acessos) {
                if (acessos[key]) {
                    acum = [...acum, { autorizacao: authId, acesso: key }];
                }
            }
            return acum;
        }, []);
        return { id: task.id, autorizacoes, ...task };
    });

    const dados = {
        processo: {
            id: id_definicao.value,
            definicao: definicao.value,
            id_definicao: id_definicao.value,
            ambito: ambito.value,
            codigo: codigo.value,
            dono: dono.value,
            categoria: categoria.value,
            versao: versao.value,
            departamento: departamento.value,
            proc_associados: toArray(processosAssociados.value).join(";"),
            objectivo_processo: Object.values(goalsIndicators || {})
                .map((item) => {
                    const { key, ...rest } = item;
                    return rest;
                })
                .filter(({ id_politica, id_objectivo, id_indicador }) => id_politica && id_objectivo && id_indicador),
            nome_externo: nome.value,
            tarefa: tarefas,
        },
    };

    formData.append("dados", JSON.stringify(dados));
    formData.append("accao", "guardar_definicao");
    return api("/Gestor/gereprocesso.php", formData, "POST");
};
