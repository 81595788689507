import { Col, Form, Input } from "antd";
import React, { useContext } from "react";

import FormContext from "../context/FormContext";
import { getInputLabel } from "../utils/functions";
import { QADivider, QAToolTip } from "./";
import { LineBreak } from "./lineBreak";

const QATime = ({
    value,
    inputAttribute,
    field,
    visible,
    renderGroup,
    colSize,
    disabled,
    isRequired,
    ...inputProps
}) => {
    const { changeInputValue, justSee } = useContext(FormContext);
    const dateStyles = {
        width: "100%",
    };

    if (!visible) {
        return null;
    }

    const RenderInput = () => (
        <Input
            type="time"
            value={value ? value : null}
            disabled={justSee || disabled}
            style={dateStyles}
            onChange={(time) => {
                changeInputValue({
                    value: time.target.value,
                    key: field,
                    inputAttribute,
                    ...inputProps,
                });
            }}
        />
    );

    const { inTable } = inputProps;

    return inTable ? (
        RenderInput()
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            {<LineBreak item={inputAttribute} />}
            <Col xs={colSize}>
                <Form.Item
                    label={
                        <QAToolTip
                            label={getInputLabel(inputAttribute, field)}
                            help={inputAttribute["@ajuda"]}
                            isRequired={isRequired}
                        />
                    }
                >
                    {RenderInput()}
                </Form.Item>
            </Col>
        </>
    );
};

export default QATime;
