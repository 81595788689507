import React from "react";
import { Icon } from "antd";
import { Link, useLocation } from "react-router-dom";

import { canRenderMenu } from "../util";
import { IMenu } from "../../V2/types/menuTypes";
import { toArray } from "../../business/utils";

interface IProps {
    menus: IMenu[];
    setCalculatorVisible: (isCalculatorVisible: boolean) => void;
    setGenerateReportVisible: (visivel: boolean) => void;
    children?: React.ReactNode;
}

export function TopQuickAccessMenu({ menus, setCalculatorVisible, setGenerateReportVisible, children }: IProps) {
    const location = useLocation();

    const LIST_MENUS = [
        "qa_gerar_relatorio",
        "menu_dashboard",
        "qa_calculadora",
        "qa_painel",
        "qa_iniciar_processo",
        "qa_cosulta_documento_registo",
        "qa_manual_funcoes",
        "qa_dossie_cliente",
        "qa_cond_cons_doss_clie",
    ];
    const menusObj = toArray(menus)
        .filter((item: IMenu) => LIST_MENUS.includes(item.identificador))
        .reduce((acc: any, item: IMenu) => {
            acc[item.identificador] = item;
            return acc;
        }, {});

    return (
        <>
            <div className="setting-row">
                {canRenderMenu("OpenGerarRelatorios", menus) && (
                    <div>
                        <Link
                            to="/report/index"
                            className="setting-link"
                            onClick={(e) => {
                                if (location.pathname !== "/") {
                                    e.preventDefault();
                                    if (location.pathname !== "/report/index") {
                                        setGenerateReportVisible(true);
                                    }
                                }
                            }}
                        >
                            <div className="setting-item">
                                <Icon className="setting-item-icon" type="file-text" />
                                <span className="setting-item-title">
                                    {menusObj?.["qa_gerar_relatorio"]?.titulo || "Gerar relatório"}
                                </span>
                            </div>
                        </Link>
                    </div>
                )}

                {canRenderMenu("OpenDashboard", menus) && (
                    <div>
                        <Link to="/dashboard" className="setting-link">
                            <div className="setting-item">
                                <Icon className="setting-item-icon" type="pie-chart" />
                                <span className="setting-item-title">
                                    {menusObj?.["menu_dashboard"]?.titulo || "Dashboard"}
                                </span>
                            </div>
                        </Link>
                    </div>
                )}

                {/* OpenCalculadora */}
                {canRenderMenu("OpenCalculadora", menus) && (
                    <div>
                        <Link
                            to="/"
                            className="setting-link"
                            onClick={(e) => {
                                e.preventDefault();
                                setCalculatorVisible(true);
                            }}
                        >
                            <div className="setting-item">
                                <Icon className="setting-item-icon" type="calculator" />
                                <span className="setting-item-title">
                                    {menusObj?.["qa_calculadora"]?.titulo || "Calculadora"}
                                </span>
                            </div>
                        </Link>
                    </div>
                )}

                {location.pathname !== "/" && (
                    <>
                        {canRenderMenu("OpenPainel", menus) && (
                            <div>
                                <Link to="/task/panel" className="setting-link">
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="unordered-list" />
                                        <span className="setting-item-title">
                                            {menusObj?.["qa_painel"]?.titulo || "Painel de Tarefas"}
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        )}

                        {canRenderMenu("OpenInicarNovoprocesso", menus) && (
                            <div>
                                <Link to="/process/execution/index" className="setting-link">
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="play-square" />
                                        <span className="setting-item-title">
                                            {menusObj?.["qa_iniciar_processo"]?.titulo || "Iniciar Processo"}
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        )}

                        {canRenderMenu("qa_cosulta_documento_registo", menus) && (
                            <div>
                                <Link to="/document_record_consult" className="setting-link">
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="folder-open" />
                                        <span className="setting-item-title">
                                            {menusObj?.["qa_cosulta_documento_registo"]?.titulo ||
                                                "Consulta de Documentos e Registos"}
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        )}

                        {canRenderMenu("qa_manual_funcoes", menus) && (
                            <div>
                                <Link to="/function.manual/index" className="setting-link">
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="team" />
                                        <span className="setting-item-title">
                                            {menusObj?.["qa_manual_funcoes"]?.titulo || "Manual de Funções"}
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        )}

                        {canRenderMenu("dossier_clientes", menus) && (
                            <div>
                                <Link to="/client_dossier" className="setting-link">
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="snippets" />
                                        <span className="setting-item-title">
                                            {menusObj?.["qa_dossie_cliente"]?.titulo ||
                                                menusObj?.["qa_cond_cons_doss_clie"]?.titulo ||
                                                "Dossiê de Cliente"}
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </>
                )}
                {children}
            </div>
        </>
    );
}
