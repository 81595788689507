import { scaleProps } from "../constants/index";
import { sendHttpRequest } from "../../../components/http.request";
import { count_scale } from "../constants/index";

const prepareData = (listTable) => {
    let list = [];

    if (Array.isArray(listTable)) list = [...listTable];
    else if (listTable && Object.keys(listTable).length !== 0) list = [listTable];

    return list.map((item, index) => ({
        key: index,
        ...item,
        aplicar_todos: item.aplicar_todos === "t" ? true : false,
    }));
};

export const selectScale = (payload) => async (dispatch) => {
    if (!payload.formulario) return false;
    const formData = new FormData();
    formData.append("hack", true);
    formData.append("formulario", payload.formulario);
    if (payload.id) {
        formData.append("accao", "dados");
        formData.append("id", payload.id);
        return await sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((jsonResponse) => {
            if (!jsonResponse) return false;
            const listTable = jsonResponse.result.valores.escalas_norma.escala_norma.escala;
            return {
                list: prepareData(listTable),
                formulario: jsonResponse.result.formulario,
            };
        });
    } else {
        formData.append("accao", "consultar");
        return await sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((jsonResponse) => {
            if (!jsonResponse) return false;

            return {
                list: [],
                formulario: jsonResponse.formulario,
            };
        });
    }
};

export const save = (payload) => async (dispatch) => {
    const elemento_raiz = payload.formulario["@elemento_raiz"];

    //get props dimension
    const list = payload.values.dataSource.map((item) => {
        return { ...item };
    });

    const listToSave = list.map((item) => {
        delete item.category;
        delete item.key;
        item.aplicar_todos = item.aplicar_todos ? "t" : "f";
        return item;
    });

    const obj = {
        [elemento_raiz]: {
            [scaleProps.key]: {
                [scaleProps.table]: listToSave,
            },
        },
    };

    if (payload.values.id) obj[elemento_raiz].id_norma_escala = payload.values.id;

    const formData = new FormData();
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario["@nome"]);
    formData.append("dados", JSON.stringify(obj));

    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse.sucesso && jsonResponse.sucesso !== "0" ? jsonResponse.sucesso : false;
    });
};

export const countScale = (payload) => async (dispatch) => {
    let ids = [];
    payload.ids.forEach((element) => {
        if (element) ids.push(Number(element));
    });

    if (ids.length === 0) return payload.dimensionNumber ? [] : 0;

    const formData = new FormData();
    formData.append("query", count_scale);
    formData.append("ids", ids);

    return await sendHttpRequest("POST", "/Gestor/execQuery.php", formData).then((jsonResponse) => {
        if (Array.isArray(jsonResponse.result)) {
            if (payload.dimensionNumber) {
                return jsonResponse.result.map((item) => ({
                    ...item,
                    total: Number(item.total),
                }));
            } else {
                if (jsonResponse.result.length === 0) return 0;
                return Number(jsonResponse.result[0].total);
            }
        }
        return 0;
    });
};
