import React, { useState } from "react";
import { Button } from "antd";
import { downloadFile } from "./../../../components/util";

const ButtonDownloadDocument = (props) => {
    const [loading, setLoading] = useState(false);
    const handleOnClick = (e, ficheiro) => {
        e.preventDefault();
        setLoading(true);

        const formDocument = {
            accao: "ficheiro",
            ficheiro: ficheiro,
        };

        downloadFile("/geredocumentos.php", formDocument).then(() => {
            setLoading(false);
        });
    };

    return (
        <Button type="link" icon="download" loading={loading} onClick={(e) => handleOnClick(e, props.ficheiro)}>
            {props.text}
        </Button>
    );
};

export default ButtonDownloadDocument;
