import React, { memo, useState, useEffect } from "react";
import { Input } from "antd";
import moment from "moment";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";
import { FormLabel } from "../Components/FormLabel";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { Group } from "../Components/Group";
import { CommunComponentsProps } from "../../../types/common";
import { compareProps, getFullPathRow } from "../handle/inputHandle";
import { useInputHandle } from "../hooks/InputHandle";

interface ComponentsPropertiesProps extends CommunComponentsProps {}

export const QATime: React.FC<ComponentsPropertiesProps> = ({
    componentsProperties,
    renderContext,
    handleOnInputChangeValue,
    formState,
    formInputDependenceState,
    tableRow,
    inputCalcTrigger,
    justSee,
    triedSave,
}) => {
    // const debounceChange = debounce(onChange, 500);
    const [showError, setShowError] = useState(false);

    print_when_component_is_rendered(componentsProperties);

    useEffect(() => setShowError(triedSave), [triedSave]);

    // const handleOnChange = (time) => {
    //     onChange(time.target.value);
    //     // handleInputChange({ value: timeString, key: itemData.key, root: itemData.root, rowKey: itemData.recordKey });
    // };

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);

    const full_path = tableRow?.row_key ? fullPathRow : componentsProperties?.full_path;

    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    const {
        // data: whenValueChangeData,
        status: whenValueChangeStatus,
        componentValue,
        hasUpdatedValue,
        calculated,
        required,
        restriction,
        visible,
        relevant,
        readOnly,
        error,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[full_path!],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
        value,
        showError,
    });

    // const canEdit = canCorrectField(itemData) && correctForm;

    // const disabled = canEdit ? false : currentTaskStatus;

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={required} renderContext={renderContext}>
                {componentsProperties.type === COMPONENT_TYPE.INPUT_TIME && (
                    <>
                        <Input
                            type="time"
                            value={value}
                            disabled={justSee || readOnly}
                            onChange={(e) => {
                                handleOnInputChangeValue({
                                    value: e.target.value,
                                    componentsProperties,
                                    row_key: tableRow?.row_key,
                                    full_path,
                                });
                            }}
                        />
                    </>
                )}
                {showError && error && <span style={{ color: "red" }}>{error}</span>}
            </FormLabel>
        </Group>
    );
};

export const QATimePicker = memo(QATime, (prev, next) => compareProps<ComponentsPropertiesProps>(prev, next));
