import React, { useState } from "react";
import moment from "moment";
import {
    Descriptions,
    Typography,
    Row,
    Input,
    Badge,
    Table,
    Icon,
    Divider,
    Button,
    Switch,
    Collapse,
    message,
    Form,
    Modal,
    Upload,
    Alert,
    Popconfirm,
    Col,
} from "antd";
import { Utilitaries } from "../../../../business";
import ModalHistory from "./modal.history";
import ModalObservationList from "./observation.history";
import { historyTask, taskOperation } from "../../constants/execution";

const { Panel } = Collapse;
const { TextArea } = Input;
const customPanelStyle = {
    marginBottom: 0,
    border: 0,
    overflow: "hidden",
};
const { Text, Title } = Typography;

const TaskDetails = (props) => {
    const [isToCancelProcess, setIsToCancelProcess] = useState(false);
    const [isToEditTask, setIsToEditTask] = useState(false);
    const [isToReAssignTask, setIsToReAssignTask] = useState(false);
    const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
    const [operation, setOperation] = useState("");
    const [reason, setReason] = useState("");
    const [historyOperation, setHistoryOperation] = useState("");
    const [loadingSave, setLoadingSave] = useState(false);
    let readonlyDocumentsCount = props?.details?.readonlyDocuments?.length;
    let uploadedDocumentsCount = Object.values(props?.details?.uploadedDocuments || {}).length;

    const { taskData, processData } = props;

    const uploadProps = (docKey) => {
        return {
            name: "file",
            multiple: false,
            showUploadList: {
                showDownloadIcon: false,
            },
            customRequest: ({ file, onSuccess }) => {
                setTimeout(() => {
                    onSuccess("ok");
                }, 0);
            },
            onRemove: () => {
                setIsToCancelProcess(false);
                setIsToEditTask(false);
                setIsToReAssignTask(false);
                setIsHistoryModalVisible(false);
                // setLabel("");
                setOperation("");
                setHistoryOperation("");
            },
            beforeUpload: async (file) => {
                // let fileReaded = null;
                // ===> read file assync
                // const readAsync = () =>
                //     new Promise((resolve, reject) => {
                //         const reader = new FileReader();
                //         reader.readAsDataURL(file);
                //         reader.onload = () => {
                //             resolve(reader.result);
                //         };
                //         reader.onerror = reject;
                //     });
                // // ===> read file safely
                // try {
                //     fileReaded = await readAsync();
                // } catch (err) {
                //
                // }
                props.addUploadedDoc({
                    nome_ficheiro: file.name,
                    // file: fileReaded,
                    file: file,
                    docKey,
                });
                return false;
            },
            onChange(info) {
                if (info.file.status !== "uploading") {
                }
                if (info.file.status === "done") {
                    message.success(`O ficheiro foi carregado com successo`);
                } else if (info.file.status === "error") {
                    message.error(`Ocorreu um erro`);
                }
            },
        };
    };

    const handleSaveDocument = (key) => {
        props.saveDocument(key);
    };

    const handleDownloadDocument = (id) => {
        props.downloadDocument(id);
    };

    const handleXFormatDownloadDocument = (id) => {
        props.xFormatDownloadDocument(id);
    };

    const handleAdd = () => {
        props.addDocument();
    };

    const handleRemove = (key) => {
        props.removeDocument(key);
    };

    const handleDelete = (key) => {
        props.deleteDocument(key);
    };

    const toggleCancelProcess = (value) => {
        setOperation(taskOperation.requestCancelTask);
        setIsToCancelProcess(value);
        setIsToEditTask(false);
        setIsToReAssignTask(false);
    };

    const toggleEditionTask = (value) => {
        setOperation(taskOperation.saveObservation);
        setIsToEditTask(value);
        setIsToCancelProcess(false);
        setIsToReAssignTask(false);
    };

    const toggleReAssignTask = (value) => {
        setOperation(taskOperation.requestReAssign);
        setIsToCancelProcess(false);
        setIsToEditTask(false);
        setIsToReAssignTask(value);
    };

    const showModal = (historyType) => {
        setHistoryOperation(historyType);
        setIsHistoryModalVisible(true);
    };

    const handleCloseModalHistory = (e) => {
        setIsHistoryModalVisible(false);
    };

    const handleSaveOperation = async () => {
        if (!reason?.trim()) {
            let text;
            if (operation === taskOperation.requestCancelTask) {
                text = `É preciso informar uma justificação para a anulação da tarefa`;
            } else if (operation === taskOperation.requestCancelTask) {
                text = `É preciso informar uma justificação para a reatribuição da tarefa`;
            } else {
                text = `É preciso introduzir a observação`;
            }

            message.warning(text);
            return;
        }
        setLoadingSave(true);
        const result = await props.handleProcessOperationRequest({ operation, reason });
        if (result) {
            switch (operation) {
                case taskOperation.requestCancelTask:
                    toggleCancelProcess();
                    break;
                case taskOperation.requestReAssign:
                    toggleReAssignTask();
                    break;
                case taskOperation.saveObservation:
                    toggleEditionTask();
                    break;
                default:
                    break;
            }
        }
        setLoadingSave(false);
    };

    const handleDocDescriptionChange = ({ value, docKey }) => {
        props.changeDocDescription({ value, docKey });
    };

    const filesColumns = [
        {
            title: "Data",
            dataIndex: "data_anexacao",
            width: "15%",
            render: (dateObj, record) =>
                record.data_anexacao ? record.data_anexacao : moment(dateObj).format("DD-MM-YYYY"),
        },
        {
            title: "Descrição",
            width: "40%",
            dataIndex: "descricao",
            render: (text, record) => {
                return (
                    <Input
                        onChange={(e) =>
                            handleDocDescriptionChange({
                                docKey: record.key,
                                value: e.target.value,
                            })
                        }
                        value={record.descricao}
                        disabled={!!record.id_ficheiro}
                        placeholder={record.id_ficheiro ? "Não definido" : "Introduza a descrição"}
                    ></Input>
                );
            },
        },
        {
            title: "Ficheiro",
            width: "30%",
            dataIndex: "file",
            render: (text, record) => {
                return record.id_ficheiro ? (
                    record.nome_ficheiro
                ) : (
                    <Upload {...uploadProps(record.key)}>
                        <a href="javascript:;">Carregar</a>
                    </Upload>
                );
            },
        },
        {
            title: "Ações",
            width: "15%",
            dataIndex: "actions",
            render: (text, record) => (
                <div>
                    {record.id_ficheiro ? (
                        <>
                            <a href="javascript:;" onClick={(e) => handleDownloadDocument(record.id_ficheiro)} download>
                                Download
                            </a>
                            <Divider type="vertical"></Divider>
                            <Popconfirm
                                title="Tens certeza que desejas eliminar?"
                                onConfirm={() => handleDelete(record.key)}
                            >
                                <a href="javascript:;" className="qa-text-del">
                                    Eliminar
                                </a>
                            </Popconfirm>
                        </>
                    ) : (
                        <>
                            <a href="javascript:;" onClick={(e) => handleSaveDocument(record.key)}>
                                Guardar
                            </a>
                            <Divider type="vertical"></Divider>
                            <a href="javascript:;" className="qa-text-del" onClick={() => handleRemove(record.key)}>
                                Eliminar
                            </a>
                        </>
                    )}
                </div>
            ),
        },
    ];
    const documentsColumns = [
        {
            title: "Tipo",
            dataIndex: "type",
            width: "20%",
            render: () => <label>Relatório</label>,
        },
        {
            title: "Descrição",
            dataIndex: "documento",
            width: "60%",
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "20%",
            render: (text, record) => {
                return (
                    <>
                        {record.pdf === 't' && (
                            <a
                                href="javascript:;"
                                title="Download PDF"
                                onClick={() =>
                                    handleXFormatDownloadDocument({
                                        id: record?.id_report,
                                        saida: "pdf",
                                    })
                                }
                            >
                                <Icon type="file-pdf" style={{ color: "#ff6666", fontSize: 22 }} />
                            </a>
                        )}
                        {record.pdf === 'f' && (
                            <Icon type="file-pdf" style={{ color: "#e5e8eb", fontSize: 22, disabled: true }} />
                        )}
                        <Divider type="vertical" />
                        {record.rtf === 't' && (
                            <a
                                href="javascript:;"
                                title="Download Word"
                                onClick={() =>
                                    handleXFormatDownloadDocument({
                                        id: record?.id_report,
                                        saida: "doc",
                                    })
                                }
                            >
                                <Icon type="file-word" style={{ color: "#66c2ff", fontSize: 22 }} />
                            </a>
                        )}
                        {record.rtf === 'f' && (
                            <Icon type="file-word" style={{ color: "#e5e8eb", fontSize: 22, disabled: true }} />
                        )}
                        <Divider type="vertical" />
                        {record.xls === 't' && (
                            <a
                                href="javascript:;"
                                title="Download Excel"
                                onClick={() =>
                                    handleXFormatDownloadDocument({
                                        id: record?.id_report,
                                        saida: "xls",
                                    })
                                }
                            >
                                <Icon type="file-excel" style={{ color: "#80ff80", fontSize: 22 }} />
                            </a>
                        )}
                        {record.xls === 'f' && (
                            <Icon type="file-excel" style={{ color: "#e5e8eb", fontSize: 22, disabled: true }} />
                        )}
                    </>
                );
            },
        },
    ];

    const renderModalHistory = () => {
        if (isHistoryModalVisible) {
            if (historyOperation == historyTask.reAssignTask || historyOperation == historyTask.cancelTask) {
                return (
                    <ModalHistory
                        visible={isHistoryModalVisible}
                        handleClose={handleCloseModalHistory}
                        historyOperation={historyOperation}
                        taskId={props.taskId}
                    />
                );
            } else {
                return (
                    <ModalObservationList
                        visible={isHistoryModalVisible}
                        handleClose={handleCloseModalHistory}
                        historyOperation={historyOperation}
                        taskId={props.taskId}
                    />
                );
            }
        }
        return null;
    };

    return (
        <>
            {processData && !Utilitaries.isEmpty(taskData) && (
                <>
                    <Row>
                        <Col xs={16}>
                            <p>
                                <Title level={4}>
                                    Tarefa:
                                    <Text style={{ marginLeft: "10px" }}>
                                        {taskData["@id"]} - {taskData["@nome"]}{" "}
                                    </Text>
                                </Title>
                            </p>
                        </Col>
                        <Col xs={8}>
                            {!Utilitaries.isEmpty(taskData["@info"]) && (
                                <Alert message={taskData["@info"]} type="warning" showIcon />
                            )}
                        </Col>
                        <Col xs={24}>
                            <p style={{ marginLeft: 10 }}>
                                <label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        marginRight: 10,
                                    }}
                                >
                                    Descrição:
                                </label>
                                <Text>{taskData["@descricao"]}</Text>
                            </p>
                        </Col>
                        <Col xs={24} md={14}>
                            <p style={{ marginLeft: 10 }}>
                                <label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        marginRight: 10,
                                    }}
                                >
                                    Processo:
                                </label>
                                <Text>
                                    {processData["@id"]} - {processData["@nome"]}
                                </Text>
                            </p>
                        </Col>
                        <Col xs={24} md={6}>
                            <p style={{ marginLeft: 10 }}>
                                <label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        marginRight: 10,
                                    }}
                                >
                                    Estabelecimento:
                                </label>
                                <Text>{taskData["@nome_estabelecimento"]}</Text>
                            </p>
                        </Col>
                        <Col xs={24} md={4}>
                            <p style={{ marginLeft: 10 }}>
                                <label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        marginRight: 10,
                                    }}
                                >
                                    Versão:
                                </label>
                                <Text>{processData["@versao"]}</Text>
                            </p>
                        </Col>
                        {/** SHOW ALERT IF USER HAVE NO PERMISSION TO EXECUTE THE TASK */}
                        {/* {taskData["@info"] && (
                            <Col xs={24}>
                                <Alert message="Atenção!!" description={taskData["@info"]} type="warning" showIcon />
                            </Col>
                        )} */}
                    </Row>
                    <Row>
                        <Descriptions bordered size="small">
                            <Descriptions.Item label="Estado">{taskData["@estado"]}</Descriptions.Item>
                            <Descriptions.Item label="Data Início">
                                {taskData["@inicio"] !== "" ? taskData["@inicio"] : "----"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Data Conclusão">
                                {taskData["@fim"] !== "" ? taskData["@fim"] : "----"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Documento">
                                {taskData["@documento"] !== "" ? taskData["@documento"] : "----"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Responsável">
                                {taskData["@utilizador"] !== "" ? taskData["@utilizador"] : "----"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Data Limite">
                                {taskData["@limite"] !== "" ? taskData["@limite"] : "----"}
                            </Descriptions.Item>
                            <Descriptions.Item span={4} label="Operações">
                                <div id="qa-task-actions">
                                    <div
                                        style={{
                                            marginLeft: 10,
                                            display: "inline",
                                        }}
                                    >
                                        <Text>Anular Processo</Text>
                                        <Switch
                                            size="small"
                                            style={{ marginLeft: 5 }}
                                            onChange={toggleCancelProcess}
                                            checked={isToCancelProcess}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            marginLeft: 10,
                                            display: "inline",
                                        }}
                                    >
                                        <Text>Editar Tarefa</Text>
                                        <Switch
                                            size="small"
                                            style={{ marginLeft: 5 }}
                                            onChange={toggleEditionTask}
                                            checked={isToEditTask}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            marginLeft: 10,
                                            marginRight: 10,
                                            display: "inline",
                                        }}
                                    >
                                        <Text> Reatribuir Tarefa</Text>
                                        <Switch
                                            size="small"
                                            style={{ marginLeft: 5 }}
                                            onChange={toggleReAssignTask}
                                            checked={isToReAssignTask}
                                        />
                                    </div>
                                </div>
                            </Descriptions.Item>
                            {isToCancelProcess || isToEditTask || isToReAssignTask ? (
                                <Descriptions.Item style={{ width: "100%" }} span={4} label={null}>
                                    <Row type="flex" style={{ width: "100%" }}>
                                        <>
                                            <Col xs={24} style={{ marginBottom: 0 }}>
                                                <TextArea
                                                    onChange={(e) => setReason(e.target.value)}
                                                    compact
                                                    placeholder={"Introduza o motivo"}
                                                    autoSize={{
                                                        minRows: 2,
                                                        maxRows: 7,
                                                    }}
                                                />
                                            </Col>

                                            <div style={{ marginTop: "10px" }}>
                                                <Button
                                                    icon="save"
                                                    onClick={handleSaveOperation}
                                                    size="small"
                                                    style={{ float: "right" }}
                                                    type="primary"
                                                    loading={loadingSave}
                                                >
                                                    Guardar
                                                </Button>
                                            </div>
                                        </>
                                    </Row>
                                </Descriptions.Item>
                            ) : null}

                            {!Utilitaries.isEmpty(taskData["@observacoes"]) && (
                                <Descriptions.Item style={{ width: "100%" }} span={4} label="Observações">
                                    <Row type="flex" style={{ width: "100%" }}>
                                        <Col xs={24} style={{ marginBottom: 0 }}>
                                            <pre
                                                style={{
                                                    whiteSpace: "pre-wrap",
                                                    marginBottom: "0.3em",
                                                    maxHeight: "11em",
                                                }}
                                            >
                                                {taskData["@observacoes"]}
                                            </pre>
                                        </Col>
                                        <div style={{ marginTop: "10px" }}>
                                            <Button
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                size="small"
                                                icon="history"
                                                onClick={() => showModal(historyTask.observation)}
                                            >
                                                Histórico
                                            </Button>
                                        </div>
                                    </Row>
                                </Descriptions.Item>
                            )}

                            {!Utilitaries.isEmpty(taskData["@anulacao"]) && (
                                <Descriptions.Item style={{ width: "100%" }} span={4} label="Anulação">
                                    <Row type="flex" style={{ width: "100%" }}>
                                        <Col xs={24} style={{ marginBottom: 0 }}>
                                            <pre
                                                style={{
                                                    whiteSpace: "pre-wrap",
                                                    marginBottom: "0.3em",
                                                    maxHeight: "11em",
                                                }}
                                            >
                                                {taskData["@anulacao"]}
                                            </pre>
                                        </Col>
                                        <div style={{ marginTop: "10px" }}>
                                            <Button
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                size="small"
                                                icon="history"
                                                onClick={() => showModal(historyTask.cancelTask)}
                                            >
                                                Histórico
                                            </Button>
                                        </div>
                                    </Row>
                                </Descriptions.Item>
                            )}
                            {!Utilitaries.isEmpty(taskData["@reatribuicao"]) ? (
                                <Descriptions.Item style={{ width: "100%" }} span={4} label="Reatribuição">
                                    <Row type="flex" style={{ width: "100%" }}>
                                        <Col xs={24} style={{ marginBottom: 0 }}>
                                            <pre
                                                style={{
                                                    whiteSpace: "pre-wrap",
                                                    marginBottom: "0.3em",
                                                    maxHeight: "11em",
                                                }}
                                            >
                                                {taskData["@reatribuicao"]}
                                            </pre>
                                        </Col>
                                        <div style={{ marginTop: "10px" }}>
                                            <Button
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                size="small"
                                                icon="history"
                                                onClick={() => showModal(historyTask.reAssignTask)}
                                            >
                                                Histórico
                                            </Button>
                                        </div>
                                    </Row>
                                </Descriptions.Item>
                            ) : null}
                        </Descriptions>
                    </Row>
                    <br />
                    <Row gutter={18}>
                        <Collapse
                            bordered={false}
                            defaultActiveKey={[]}
                            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                        >
                            {readonlyDocumentsCount > 0 && (
                                <Panel
                                    header={
                                        <>
                                            <span style={{ marginRight: 10 }}>Documentos de consulta</span>
                                            <Badge count={readonlyDocumentsCount} />
                                        </>
                                    }
                                    key="2"
                                    style={customPanelStyle}
                                >
                                    {readonlyDocumentsCount > 0 && (
                                        <Table
                                            dataSource={props.details.readonlyDocuments}
                                            columns={documentsColumns}
                                            size="middle"
                                            pagination={false}
                                        />
                                    )}
                                </Panel>
                            )}

                            <Panel
                                header={
                                    <>
                                        <span style={{ marginRight: 10 }}>Documentos carregados</span>
                                        <Badge count={uploadedDocumentsCount} />
                                    </>
                                }
                                key="1"
                                style={customPanelStyle}
                            >
                                <Row>
                                    <Button
                                        icon="plus"
                                        type="primary"
                                        onClick={handleAdd}
                                        style={{
                                            float: "right",
                                            marginBottom: 10,
                                        }}
                                    >
                                        Adicionar
                                    </Button>
                                </Row>
                                <Table
                                    dataSource={Object.values(props.details.uploadedDocuments)}
                                    columns={filesColumns}
                                    size="middle"
                                    pagination={false}
                                />
                            </Panel>
                        </Collapse>
                    </Row>
                    {renderModalHistory()}
                </>
            )}
        </>
    );
};

export default TaskDetails;
