import { actionType } from "../constants/index";

const initialState = {
    domain: {
        byId: {},
    },
    app: {
        isFetched: false,
    },
};

export const correctionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.select:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: [...action.payload],
                },
                app: {
                    isFetched: true,
                },
            };

        default:
            return state;
    }
};
