import React from "react";
import { Form, Icon, Tooltip } from "antd";
import { Utilitaries } from "../../../../business";
import { FormInputsProps, RenderContextType } from "../../../types/formTypes";

interface Props {
    componentsProperties: FormInputsProps;
    renderContext?: RenderContextType;
    required: boolean;
}

export const getLabel = ({ componentsProperties, renderContext, required }: Props) => {
    let textLabel = "";
    let colon = true;

    if (componentsProperties.label) textLabel = componentsProperties.label;
    else if (componentsProperties.label === "") {
        textLabel = "";
        colon = false;
    } else textLabel = Utilitaries.toPascalCase(componentsProperties?.key, true);

    if (renderContext === "column") {
        return { label: null, textLabel, colon: false };
    }

    const isBold = Utilitaries.toString(componentsProperties?.style).includes("negrito");

    // if (Utilitaries.isEmpty(textLabel) && !required) {
    //     return { label: null, textLabel };
    // }

    const label = (
        <span>
            {required && <i style={{ color: "red" }}>*&nbsp;</i>}
            {Utilitaries.isEmpty(textLabel) ? null : isBold ? <b> {textLabel}</b> : textLabel}
            {componentsProperties?.help && (
                <Tooltip title={componentsProperties?.help}>
                    <Icon style={{ marginLeft: 8 }} type="question-circle-o" />
                </Tooltip>
            )}
        </span>
    );

    return { label, textLabel, colon };
};

export const FormLabel: React.FC<Props> = ({ componentsProperties, renderContext, required, children }) => {
    const { label, textLabel, colon } = getLabel({ componentsProperties, renderContext, required });

    // const showLabel =
    // componentsProperties?.label !== undefined &&
    // !componentsProperties?.label?.trim() &&
    // componentsProperties?.group &&
    // !componentsProperties?.help
    //     ? {}
    //     : { label };

    return renderContext === "column" ? (
        <>{children}</>
    ) : (
        <Form.Item label={label} colon={colon}>
            {children}
        </Form.Item>
    );
};
