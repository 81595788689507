import { AutoComplete, Form } from "antd";
import React from "react";
import { FormLabel, getLabel } from "../Components/FormLabel";
import { FormInputsProps, RenderContextType } from "../../../types/formTypes";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { Group } from "../Components/Group";
// import { canCorrectField } from "../../utils/fields";

interface ComponentsPropertiesProps {
    componentsProperties: FormInputsProps;
    renderContext: RenderContextType;
}

const dateStyles = {
    width: "100%",
};

export const QAAutoComplete: React.FC<ComponentsPropertiesProps> = ({ componentsProperties, renderContext }) => {
    print_when_component_is_rendered(componentsProperties);

    const { label } = getLabel({ componentsProperties, renderContext, required: false });
    // let key = itemData["@chave_lista"] || "key";
    // let value = itemData["@valor_lista"] || "value";

    // const _data = dataSource && dataSource.length === 0 ? (columnDataSource ? columnDataSource : []) : dataSource;

    // if (_data && Array.isArray(_data) && _data.length > 0) {
    //     if (!_data[0][value]) {
    //         value = "designacao";
    //     }

    //     if (!_data[0][key]) {
    //         key = itemData.key;
    //     }
    // }

    // const canEdit = canCorrectField(itemData) && correctForm;

    // const disabled = canEdit ? false : currentTaskStatus || readonly;

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={false} renderContext={renderContext}>
                <AutoComplete
                    // disabled={disabled}
                    // disabled={disabled}
                    // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                    placeholder={null}
                    // dataSource={_data.filter(it => it[key]).map((it) => (
                    //     <AutoComplete.Option key={it[key]} value={it[key]}>
                    //         {it[value]}
                    //     </AutoComplete.Option>
                    // ))}
                    // value={itemData.value !== undefined ? String(itemData.value) : itemData.value}
                    // filterOption={(inputValue, option) =>
                    //     option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    // }
                    // onChange={(selectValue) => {
                    //     const description = _data.find((it) => it[key] === selectValue);
                    //     onChange(selectValue, { selectValueText: description?.[value] });
                    // }}
                />
                {/* {error && <span style={{ color: "red" }}>{error}</span>} */}
            </FormLabel>
        </Group>
    );
};
