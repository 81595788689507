import React from "react";
import { Row, Col, Skeleton, Spin } from "antd";
import { FormInputsObjectProps, FormInputsProps, FormRootElementAttributesProps } from "../../../types/formTypes";
import { Utilitaries } from "../../../../business";
import { ComponentRender } from "./ComponentRender";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";

interface Props {
    form?: FormInputsObjectProps;
    loading?: boolean;
    isTab: boolean;
    isProcess: boolean;
    justSee?: boolean;
    rootElementAttributes: FormRootElementAttributesProps;
}

export const inputHeaders = [
    "processo",
    "cliente_nome",
    "valencia_designacao",
    "actividade",
    "estabelecimento_nome",
    "estabelecimento",
    "modelo",
];
export const inputHeadersNotDisplay = ["estabelecimento"];

export const isHeaderInput = (item: FormInputsProps) => {
    const isHeader = inputHeaders.includes(item?.key);

    return isHeader;
};

let old_rendered_group: string | undefined = undefined;

export function FormExecution({ form, loading, isTab, isProcess, justSee, rootElementAttributes }: Props) {
    return form && !loading ? (
        <Row gutter={18}>
            <Spin spinning={false}>
                {!isTab &&
                    isProcess &&
                    form &&
                    Object.values(form)
                        .filter(
                            (input) =>
                                !Utilitaries.isEmpty(input) &&
                                !inputHeadersNotDisplay.includes(input.key) &&
                                isHeaderInput(input)
                        )
                        .map((input, index) => {
                            input.type = COMPONENT_TYPE.DATA_DISPLAY;
                            return (
                                <ComponentRender
                                    key={`qa_component_header_${index}`}
                                    componentsProperties={input}
                                    renderContext="header"
                                    rootElementAttribute={rootElementAttributes}
                                />
                            );
                        })}

                {form &&
                    Object.values(form)
                        .filter((input) => !Utilitaries.isEmpty(input) && !isHeaderInput(input))
                        .filter((input) => {
                            if (isTab) {
                                return true;
                            }

                            if (
                                (!input.parent || input.type === COMPONENT_TYPE.POPUP) &&
                                (input.type || input.graphic_element) &&
                                input.key !== "controlo_documento" &&
                                !input.tab
                            ) {
                                return true;
                            }

                            return false;
                        })
                        .map((input, index) => {
                            const input_ender = {
                                ...input,
                            };
                            if (old_rendered_group && old_rendered_group === input_ender.group) {
                                delete input_ender.group;
                            } else {
                                old_rendered_group = input_ender.group;
                            }
                            return (
                                <ComponentRender
                                    key={`qa_component_inputs_${index}`}
                                    componentsProperties={JSON.parse(JSON.stringify(input_ender))}
                                    renderContext="form"
                                    justSee={justSee}
                                    rootElementAttribute={rootElementAttributes}
                                />
                            );
                        })}
            </Spin>
        </Row>
    ) : (
        <Row>
            <Col span={24}>
                <Skeleton active />
            </Col>
        </Row>
    );
}
