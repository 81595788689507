import React from "react";
import { Select, Input, Row, Button, Icon, Table } from "antd";

const { Option } = Select;

function onChange(value) {}

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

const TransitionsComponent = (props) => {
    const transitionColumns = [
        {
            title: "Descrição",
            dataIndex: "description",
            width: "50%",
            render: (text, record) => <Input placeholder="Introduza uma descrição" />,
        },
        {
            title: "Para",
            width: "50%",
            dataIndex: "to",
            render: () => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Selecione um componente"
                    optionFilterProp="children"
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="jack">Inicio</Option>
                    <Option value="lucy">Tarefa 1</Option>
                </Select>
            ),
        },
        {
            render: (text, record) => (
                <a href="javasccript:;" onClick={() => props.handleDelete(record.key)} className="qa-text-del">
                    Eliminar
                </a>
            ),
        },
    ];

    return (
        <>
            <Row>
                <Button
                    onClick={() => props.handleAdd("transitionsDataSource")}
                    type="primary"
                    style={{ float: "right", marginBottom: 16 }}
                >
                    <Icon type="plus" />
                    Inserir
                </Button>
            </Row>
            <Table
                rowClassName={() => "editable-row"}
                dataSource={props.dataSource}
                columns={transitionColumns}
                size="default"
            />
        </>
    );
};

export default TransitionsComponent;
