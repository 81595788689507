import React, { useState, useEffect } from "react";
import { Modal, Row, Col, message } from "antd";

import { sendHttpRequest, api } from "../http.request";

import ListMessage from "./list";
import MenuComponent from "./menu";
import NewMessage from "./new-message";
import FolderComponent from "./folder";

import { body } from "./constants";

import "./index.css";
import { toArray } from "../util";
import { Utilitaries } from "../../business";

const MessageComponent = (props) => {
    const [currentBody, setCurrentBody] = useState("");
    const [selectFolderID, setSelectFolderID] = useState(0);

    const [folders, setFolders] = useState([]);

    const [messages, setMessages] = useState([]);
    const [messageInfo, setMessageInfo] = useState({
        current: 1,
        total: 0,
        perPage: 10,
    });

    const [loading, setLoading] = useState(false);

    const [forwardMessage, setForwardMessage] = useState(null);

    const [createMessageType, setCreateMessageType] = useState(null);

    useEffect(() => {
        getPasta();
    }, []);

    useEffect(() => {
        if (props.visible) {
            getMessage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectFolderID, props.visible, props.unread]);

    const getMessage = (_props) => {
        const formData = new FormData();
        formData.append("accao", "lista");
        formData.append("pasta", selectFolderID);
        formData.append("pagina", _props?.current || messageInfo.current);
        formData.append("resultado_pagina", _props?.pageSize || messageInfo.perPage);
        setLoading(true);
        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                setMessages([]);
                setMessageInfo({
                    current: 1,
                    total: 0,
                    perPage: 10,
                });
            } else {
                if (jsonResponse["@attributes"]) {
                    setMessageInfo({
                        current: Number(jsonResponse["@attributes"].pagina),
                        total: Number(jsonResponse["@attributes"].resultados),
                        perPage: Number(jsonResponse["@attributes"].resultados_pagina),
                    });
                }

                if (jsonResponse["linha"]) {
                    setMessages(jsonResponse["linha"]);
                } else {
                    setMessages([]);
                }
            }
            setLoading(false);
        });
    };

    const getPasta = () => {
        const formData = new FormData();
        formData.append("accao", "pastas");

        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                setFolders([]);
            } else {
                setFolders(jsonResponse["linha"]);
            }
        });
    };

    const search = (text) => {
        const formData = new FormData();
        formData.append("accao", "pesquisa");
        formData.append("texto", text);
        setLoading(true);
        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                setMessages([]);
            } else {
                setMessages(jsonResponse["linha"]);
            }
            setLoading(false);
        });
    };

    const changeFoldersOrder = (data) => {
        const formData = new FormData();
        const order = toArray(data).map((item, index) => ({ id: item.id, ordem: index + 1 }));
        formData.append("accao", "ordenar");
        formData.append("ordem", JSON.stringify(order));
        setLoading(true);
        api("/Gestor/geremensagens.php", formData)
            .then((jsonResponse) => {
                if (jsonResponse.erro) {
                    message.error(jsonResponse.erro || "Erro a organizar a pasta!");
                } else {
                    getPasta();
                }
                setLoading(false);
            })
            .catch((error) => {
                message.error(error.message || "Erro a organizar a pasta!");
                setLoading(false);
            });
    };

    const changeMesageStatus = (to, ids) => {
        const formData = new FormData();
        formData.append("accao", to);
        formData.append("id", ids);

        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                message.error("Ocorreu um erro.");
            } else {
                message.success("Sucesso");
                getMessage();
                props.getBadge();
            }
        });
    };

    const changeMesageDelete = (id) => {
        const formData = new FormData();
        formData.append("accao", "eliminar");
        formData.append("id", id);
        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                message.error("Ocorreu um erro.");
            } else {
                message.success("Mensagen eliminada com sucesso");
                getMessage();
                props.getBadge();
            }
        });
    };

    const onMoveToNewFolder = (ids, folder, callback = (success) => {}) => {
        const formData = new FormData();
        formData.append("accao", "mudar_pasta");
        formData.append("id", ids);
        formData.append("pasta", folder);

        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                message.error("Ocorreu um erro");
                callback(false);
            } else {
                message.success("Mensagem movida com sucesso");
                getMessage();
                callback(true);
            }
        });
    };

    const changeFolderName = (name, folder) => {
        const formData = new FormData();
        formData.append("accao", "renomear_pasta");
        formData.append("id", folder);
        formData.append("nome", name);

        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                message.error("Ocorreu um erro");
            } else {
                message.success("Pasta renomiada com sucesso");
                getPasta();
            }
        });
    };

    const changeFolderCreate = (name) => {
        const formData = new FormData();
        formData.append("accao", "criar_pasta");
        formData.append("nome", name);

        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                message.error("Ocorreu um erro");
            } else {
                message.success("Pasta criada com sucesso");
                getPasta();
            }
        });
    };

    const deletefolder = (id) => {
        const formData = new FormData();
        formData.append("accao", "apagar_pasta");
        formData.append("id", id);

        sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                message.error("Não pode eliminar uma pasta com mensagens!");
            } else {
                message.success("Pasta eliminada com sucesso");
                getPasta();
            }
        });
    };

    const changeToPage = (page) => {
        if (page !== body.create) {
            setSelectFolderID(page);
        } else {
            setForwardMessage(null);
            setCreateMessageType(null);
        }
        setCurrentBody(page);
    };

    // Reencaminhar uma mensagem
    const forward = (message) => {
        setCurrentBody(body.create);
        setForwardMessage(message);
        setCreateMessageType(null);
    };

    // Responder uma mensagem
    const reply = (message) => {
        setCurrentBody(body.create);
        setForwardMessage(message);
        setCreateMessageType("reply");
    };

    const changeLocalStatus = (messageId) => {
        const auxList = toArray(messages);

        const newMessages = auxList.map((message) => {
            if (message.id === messageId) {
                message.estado = 1;
            }

            return message;
        });

        setMessages(newMessages);
    };

    const pageChange = (current, pageSize) => {
        setMessageInfo({
            ...messageInfo,
            current,
            perPage: pageSize,
        });

        getMessage({ current, pageSize });
    };

    const renderBody = () => {
        switch (currentBody) {
            case body.create:
                return (
                    <NewMessage
                        message={forwardMessage}
                        messageType={createMessageType}
                        selectContributorsProps={props.selectContributorsProps}
                        onSave={() => {
                            changeToPage(1);
                        }}
                    />
                );
            case body.folder:
                return (
                    <FolderComponent
                        folders={folders.filter((item) => parseInt(item.id) > 2)}
                        changeFolderName={changeFolderName}
                        changeFolderCreate={changeFolderCreate}
                        deletefolder={deletefolder}
                        changeFolderOrder={changeFoldersOrder}
                    />
                );
            default:
                return (
                    <ListMessage
                        folders={Array.isArray(folders) ? folders.filter((item) => parseInt(item.id) > 2) : []}
                        data={Array.isArray(messages) ? messages : [messages]}
                        info={messageInfo}
                        getBadge={props.getBadge}
                        loading={loading}
                        search={search}
                        changeLocalStatus={changeLocalStatus}
                        isSendEmailPage={currentBody == 1}
                        onChangeState={changeMesageStatus}
                        onMoveToNewFolder={onMoveToNewFolder}
                        delete={changeMesageDelete}
                        changeToPage={changeToPage}
                        forward={forward}
                        reply={reply}
                        pageChange={pageChange}
                    />
                );
        }
    };

    return (
        <Modal
            title="Caixa de Mensagens"
            visible={props.visible}
            onOk={null}
            onCancel={props.onCancel}
            footer={null}
            width="90%"
            style={{ top: 20 }}
            bodyStyle={{
                padding: 0,
                border: "1px solid #ccc",
                borderTop: "none",
            }}
        >
            <Row>
                <Col
                    span={4}
                    style={{
                        padding: "5px 10px 10px 10px",
                        borderRight: "1px solid #ccc",
                        height: "100%",
                    }}
                >
                    <MenuComponent folders={folders} unread={props.unread} changeToPage={changeToPage} />
                </Col>
                <Col span={20} style={{ padding: 5, borderLeft: "1px solid #ccc", marginLeft: -1 }}>
                    {renderBody()}
                </Col>
            </Row>
        </Modal>
    );
};

export default MessageComponent;
