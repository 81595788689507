import React, { Component } from "react";
import { PageHeader, Divider, Table, Input, Button, Icon } from "antd";
import ButtonsGroup from "./buttons.group";
import FormCreateHoliday from "./create.form";
import FormEditHoliday from "./edit.form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import * as actionCreators from "./../../actions/holiday";
import { tableSortNumber, tableFooter } from "./../../../../components/util";
import { filter } from "lodash";

class Holiday extends Component {
    componentDidMount() {
        // if (!this.props.isFetched)
        this.selectData();
    }

    goTo = (key) => {
        this.props.history.push({
            pathname: "/function.manual/holiday/details/" + key,
        });
    };

    selectData = () => {
        this.setState({
            isFetching: true,
        });

        console.log();
        this.props.select({ mostra_lista_eliminados: this.state.showDeletedRecords, ...this.state.filter }).then(() => {
            this.setState({
                isFetching: false,
            });
        });
    };

    state = {
        showDeletedRecords: false,
        isFetching: false,
        searchText: "",
        isCreateHolidayVisible: false,
        filter: {},
    };
    //----------------- create holiday -----------------------------
    showModalCreate = () => {
        this.setState({
            isCreateHolidayVisible: true,
        });
    };
    handleCreateCancel = () => {
        this.setState({
            isCreateHolidayVisible: false,
        });
    };

    handleCreateOk = (e) => {
        this.setState({
            isCreateHolidayVisible: false,
        });
        this.selectData();
    };

    //----------------- Edit holiday -----------------------------

    handleFormEdit = (row) => {
        // e.preventDefafult();
        this.setState({
            isEditHolidayVisible: true,
            idEdit: row.id_config_feriado,
        });
    };
    handleEditCancel = () => {
        this.setState({
            isEditHolidayVisible: false,
            selectRow: null,
        });
    };

    handleEditOk = (e) => {
        this.setState({
            isEditHolidayVisible: false,
        });
        this.selectData();
    };

    //----------------- correction time registration -----------------------------
    showModalCorrection = (row) => {
        this.setState({
            isCorrectionVisible: true,
            selectRow: row,
        });
    };

    handleCorrectionClose = () => {
        this.setState({
            isCorrectionVisible: false,
            selectRow: null,
        });
    };

    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        // onFilter: (value, record) => record[dataIndex].toString().includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: (visible) => {
        //     if (visible) {
        //         setTimeout(() => this.searchInput.select());
        //     }
        // },
        // render: (text) => (
        //     <Highlighter
        //         highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        //         searchWords={[this.state.searchText]}
        //         autoEscape
        //         textToHighlight={
        //             typeof text === "object" ? "" : text //.toString()
        //         }
        //     />
        // ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex = null) => {
        this.setState({
            searchText: selectedKeys,
            filter: {
                ...this.state.filter,
                [`filtro_${dataIndex}`]: selectedKeys,
            },
        });
        confirm();
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    getColumnSortProps = (dataIndex, sortedInfo) => {
        return {
            sorter: true,
            sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
        };
    };
    /****************************** */

    columns = [
        {
            title: "Ano",
            dataIndex: "ano",
            width: "15%",
            ...this.getColumnSearchProps("ano"),
            sorter: (a, b) => tableSortNumber(a.ano, b.ano),
            defaultSortOrder: "descend",
        },
        {
            title: "Data de registo",
            dataIndex: "data",
            width: "15%",
            ...this.getColumnSearchProps("data"),
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            width: "50%",
            ...this.getColumnSearchProps("descricao"),
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "20%",
            render: (text, record) => {
                return (
                    <>
                        {this.props.pageConfig.permite_consultar === "t" && (
                            <>
                                <Button
                                    type="link"
                                    style={{
                                        padding: 0,
                                    }}
                                    onClick={() => this.goTo(record.id_config_feriado)}
                                >
                                    Consultar
                                </Button>
                                <Divider type="vertical" />
                            </>
                        )}
                        {this.props.pageConfig.permite_alterar === "t" && (
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                }}
                                onClick={() => this.handleFormEdit(record)}
                            >
                                Editar
                            </Button>
                        )}
                    </>
                );
            },
        },
    ];

    handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        let ordernacao = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order !== "descend" ? "ASC" : "DESC",
            };

            ordernacao = { [`ordenacao_${obj.sorter.columnKey}`]: obj.sorter.order };
            delete obj.sorter;
        }

        this.setState({ ...this.state, currentPage: 1, filter: { ...obj, ...ordernacao } }, () => this.selectData());
    };

    render() {
        return (
            <>
                <PageHeader title="Feriados" />
                {this.state.isCreateHolidayVisible && (
                    <FormCreateHoliday
                        handleCancel={this.handleCreateCancel}
                        handleOk={this.handleCreateOk}
                        hiddeModal={this.hiddeCreateModal}
                        isVisible={this.state.isCreateHolidayVisible}
                    />
                )}
                {this.state.isEditHolidayVisible && (
                    <FormEditHoliday
                        handleCancel={this.handleEditCancel}
                        handleOk={this.handleEditOk}
                        isVisible={this.state.isEditHolidayVisible}
                        row={this.state.selectRow}
                        idEdit={this.state.idEdit}
                    />
                )}
                {this.props.isFetched && (
                    <ButtonsGroup filter={this.state.filter} handleOnClick={this.showModalCreate} />
                )}
                <Table
                    rowKey={(record) => record.id_config_feriado}
                    dataSource={[...this.props.holidayList]}
                    columns={this.columns}
                    size="middle"
                    loading={this.state.isFetching}
                    pagination={{
                        showTitle: true,
                        showSizeChanger: true,
                        showLessItems: true,
                    }}
                    footer={() => tableFooter(this.props.totalRegisto)}
                    onChange={this.handleTableChange}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        holidayList: Object.values(state.holiday.domain.byId),
        isFetched: state.holiday.app.isFetched,
        totalRegisto: Number(state.holiday.domain.metadata.attributes.resultados),
        metatadaForm: state.holiday.domain.metadata.formulario,
        pageConfig: state.holiday.domain.metadata.pageConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...actionCreators }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Holiday);
