import React from "react";
import { Alert, Col, Form } from "antd";
import { FormInputsProps } from "../../../types/formTypes";
import { print_when_component_is_rendered } from "../../../../utils/develop";

interface ComponentsPropertiesProps {
    componentsProperties: FormInputsProps;
}

export const QAAlert: React.FC<ComponentsPropertiesProps> = ({ componentsProperties }) => {
    // const componentsProperties = useComponentsProperties(key)

    print_when_component_is_rendered(componentsProperties);

    const { label, values } = componentsProperties;
    return (
        <Col
            xs={24}
            // md={itemData.colSize}
        >
            <Form.Item>
                <Alert message={label} description={values} type="info" />
            </Form.Item>
        </Col>
    );
};
