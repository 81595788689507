import { Statistic, Form, Descriptions, Tooltip } from "antd";
import React, { memo } from "react";

const QaDataDisplay = ({ label, itemData, renderContext, numbersOfColumnsToRender }) => {
    if (renderContext === "header" && itemData?.key === "actividade") {
        itemData["@etiqueta"] = "Tarefa";
    }

    if ((itemData?.isColumn === true && renderContext !== "inputGroup") || !itemData?.["@etiqueta"]) {
        if (typeof itemData?.value === "number") {
            return itemData?.value;
        } else if (itemData.value) {
            if (itemData.value.length > 30 && numbersOfColumnsToRender > 3) {
                return (
                    <Tooltip title={itemData.value}>
                        <div
                            style={{
                                textOverflow: "ellipsis",
                                // width: " 250px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            {itemData.value}
                        </div>
                    </Tooltip>
                );
            } else {
                return itemData.value;
            }
        }
        return null;
    } else {
        if (typeof itemData?.value !== "number" && itemData?.value?.includes("\n")) {
            return (
                <Form.Item label={label}>
                    {/* <Descriptions>
                        <Descriptions.Item> */}
                    <pre>
                        {typeof itemData?.value === "object" ? itemData?.value[0]?.[itemData.key] : itemData?.value}
                    </pre>
                    {/* </Descriptions.Item>
                    </Descriptions> */}
                </Form.Item>
            );
        }

        return (
            <Form.Item label={label}>
                {/* <Descriptions layout="vertical">
                    <Descriptions.Item label={label}> */}
                {typeof itemData?.value === "object" ? itemData?.value?.[0]?.[itemData.key] : itemData?.value}
                {/* </Descriptions.Item>
                </Descriptions> */}
            </Form.Item>
        );
    }
};

const compareProps = (prevProps, nextProps) => {
    if (
        prevProps.itemData.value &&
        prevProps.itemData.key === "idade_atual" &&
        prevProps.itemData["@persiste"] === "nao" &&
        !nextProps.itemData.value
    ) {
        return true;
    }

    return prevProps.itemData.value === nextProps.itemData.value;
};

export default memo(QaDataDisplay, compareProps);
