import { Modal } from "antd";
import React, { useState } from "react";
import { MenuFormRender } from "../../../shared/FormHandler";
import { AllModalProps, FormInputsProps } from "../../../types/formTypes";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { HandleOnInputChangeValueProps, InputInTableProps } from "../../../types/common";
import Confirm from "../../../../components/qa_custom/confirm";
// import Confirm from "../../qa_custom/confirm";

interface ComponentsPropertiesProps {
    componentsProperties: FormInputsProps;
    modals: AllModalProps;
    changeModalState: (component: FormInputsProps, modalState: boolean, tableRow?: InputInTableProps) => void;
    handleOnInputChangeValue: (props: HandleOnInputChangeValueProps) => void;
    justSee?: boolean;
}

function _QAPopUP({
    componentsProperties,
    modals,
    changeModalState,
    handleOnInputChangeValue,
    justSee,
}: ComponentsPropertiesProps) {
    const [justSeePopup, setJustSeePopup] = useState(false);

    print_when_component_is_rendered(componentsProperties);

    const handleOnSave = (value?: string) => {
        const currentModal = modals[componentsProperties.key];
        const field = currentModal?.field;

        if (field?.key) {
            handleOnInputChangeValue({
                value,
                componentsProperties: field,
                row_key: currentModal.rowKey,
            });
        }
        changeModalState(componentsProperties, false);
    };

    const onCancelModal = () => {
        if (justSeePopup) {
            changeModalState(componentsProperties, false);
        } else {
            Confirm(() => {
                changeModalState(componentsProperties, false);
            });
        }
    };

    return modals[componentsProperties.key]?.isVisible ? (
        <Modal
            title={componentsProperties.label}
            visible={modals[componentsProperties.key]?.isVisible}
            onCancel={() => onCancelModal()}
            width="90%"
            footer={null}
        >
            <MenuFormRender
                form_name={componentsProperties.key}
                popup_params={modals[componentsProperties.key]?.params}
                form_id={modals[componentsProperties.key]?.id}
                onSave={handleOnSave}
                justSee={justSee}
                setJustSeePopup={setJustSeePopup}
                isInModal={true}
            />
        </Modal>
    ) : null;
}

const compareProps = (prevProps: ComponentsPropertiesProps, nextProps: ComponentsPropertiesProps) => {
    const key = prevProps.componentsProperties.key;
    if (prevProps.modals[key].isVisible !== nextProps.modals[key].isVisible) {
        return false;
    }

    return prevProps.componentsProperties.value === nextProps.componentsProperties.value;
};

export const QAPopUP = React.memo(_QAPopUP, compareProps);
