import React, { useEffect } from "react";
import { Form } from "antd";
import {
    FormInputCalcTriggerProps,
    FormInputsObjectProps,
    FormInputsProps,
    RenderContextType,
} from "../../../types/formTypes";

import QaDynamicSelect from "../../../../components/DynamicList/dynamicSelect";
// import { nullSafe, canCorrectField } from "../../utils/fields";
// import { toArray } from "../../../../components/util";
import { Utilitaries } from "../../../../business";
import { FormLabel, getLabel } from "../Components/FormLabel";
import { nullSafe } from "../../../../modules/process_execution/utils/fields";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { Group } from "../Components/Group";
import { getFullPathRow } from "../handle/inputHandle";
import { CommunComponentsProps, InputInTableProps } from "../../../types/common";
import { useInputHandle } from "../hooks/InputHandle";

interface DynamicListProps extends CommunComponentsProps {
    // componentsProperties: FormInputsProps;
    // formState: FormInputsObjectProps;
    // renderContext: RenderContextType;
    // tableRow?: InputInTableProps;
    // inputCalcTrigger: FormInputCalcTriggerProps;
}

export function QADynamicList({
    // root,
    // itemData,
    // currentTaskStatus,
    // onChange,
    // label,
    // formItemStyle,
    // handleSelectChange,
    // correctForm,
    // rowKey,
    // error,
    // dataSource,
    // readonly,
    // formItems,
    // record,
    // modalParams,
    // tableDataSourceLength,
    formState,
    componentsProperties,
    renderContext,
    formInputDependenceState,
    inputCalcTrigger,
    tableRow,
    handleOnInputChangeValue,
    justSee,
}: DynamicListProps) {
    print_when_component_is_rendered(componentsProperties);

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);

    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;
    const {
        data: whenValueChangeData,
        status: whenValueChangeStatus,
        componentValue,
        hasUpdatedValue,
        required,
        visible,
        relevant,
        readOnly,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[componentsProperties?.full_path || ""],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
    });

    useEffect(() => {
        if (hasUpdatedValue && whenValueChangeStatus !== "IDLE") {
            handleOnInputChangeValue({
                value: componentValue,
                componentsProperties,
                row_key: tableRow?.row_key,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentValue]);

    const handleOnChange = (value: string) => {
        handleOnInputChangeValue({
            value: value,
            componentsProperties,
            row_key: tableRow?.row_key,
        });
    };

    const dataSource = undefined;
    const record: any = {};
    const modalParams: any = {};

    let key_list = componentsProperties.key_list; // itemData["@valor_lista"];

    if (typeof key_list === "string" && key_list.includes(";")) {
        key_list = key_list.split(";")[0];
    }

    if (typeof key_list === "string" && key_list.includes(":")) {
        key_list = key_list.split(":")[0];
    }

    let hasDependence = Object.keys(componentsProperties).find(
        (it) =>
            it.startsWith("@accao_") &&
            componentsProperties[it]?.startsWith("auto_preenche") &&
            !componentsProperties[it]?.startsWith("auto_preenche_valor")
    );

    let listFilter = null;

    if (hasDependence && dataSource && typeof dataSource === "object") {
        listFilter = {
            filterName: Utilitaries.toArray(Object.keys(dataSource))[0],
            filterValue: Utilitaries.toArray(Object.values(dataSource))[0], //||
            // record?.[Utilitaries.toArray(Object.keys(dataSource))[0]]?.id,
        };
    } else {
        const fieldList = Utilitaries.toString(componentsProperties?.list_fields)?.split(";");
        const numberOfFields = fieldList?.length;
        if (numberOfFields > 1) {
            const targetField = Utilitaries.toString(fieldList[numberOfFields - 1]).split(":")[0];

            // if (targetField /*&& modalParams?.[targetField]*/) {
            if (targetField && modalParams?.[targetField]) {
                // Planeamento de avaliações FPR
                listFilter = {
                    filterName: targetField,
                    filterValue: modalParams?.[targetField] || record?.[targetField],
                };
            }
        }
    }

    const tableDataSourceLength = 0;

    if (!visible || !relevant) {
        return null;
    }

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={required} renderContext={renderContext}>
                <QaDynamicSelect
                    disabled={justSee || readOnly}
                    name={componentsProperties.key}
                    listKey={componentsProperties.key_list}
                    value={value}
                    col={24}
                    tableLineNumber={Utilitaries.toNumber(tableRow?.row_key, true)}
                    listValue={componentsProperties.value_list}
                    onChange={handleOnChange}
                    // record={record}
                    defaultDataSource={listFilter}
                    tableDataSourceLength={tableDataSourceLength}
                    // dddddddddddddddddd
                    defaultValue={""}
                    placeholder={""}
                    // getDynamicList, // efetuar a consulta -> from redux
                    // listOfItems, // lista -> from redux,
                    // label,
                    // attributes,
                    // postDynamicList,
                    // allListDynamic,
                />
                {/* {error && <span style={{ color: "red" }}>{error}</span>} */}
            </FormLabel>
        </Group>
    );
}

export default QADynamicList;
