import React from "react";
import { Table, Button, Input, Icon, Tooltip } from "antd";
import { Utilitaries, User_Preferences } from "../../../business/index";

function ProcessTable({ handleInitProcess, handleTableChange, data, loading, startProcessesButton }) {
    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const getColumnSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={React.createRef()}
                    placeholder={`Pesquisar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 198, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 100, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" icon="cl" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
    });

    const getDefaultFilter = (key) => {
        const defualtFilter = User_Preferences.getTemporaryInitProocessFilters();

        if (defualtFilter?.[key]) {
            return {
                defaultFilteredValue: [defualtFilter[key]],
            };
        }

        return {};
    };

    const columns = [
        {
            title: "Código",
            dataIndex: "codigo_externo",
            key: "codigo_externo",
            ...getDefaultFilter("filtro_codigo_externo"),
            ...getColumnSearchProps("codigo_externo"),
            sorter: true,
        },
        {
            title: "Nome Processo",
            dataIndex: "nome_externo",
            key: "nome_externo",
            ...getDefaultFilter("filtro_nome_externo"),
            ...getColumnSearchProps("nome_externo"),
            sorter: true,
        },
        {
            title: "Âmbito",
            dataIndex: "ambito",
            key: "ambito",
            ...getColumnSearchProps("ambito"),
            sorter: true,
            ...getDefaultFilter("filtro_ambito"),
            render: (text) => (
                <Tooltip title={text}>
                    <div
                        style={{
                            textOverflow: "ellipsis",
                            width: " 200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                        }}
                    >
                        {text}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: "Ações",
            key: "action",
            render: (text, record, index) => {
                const metadata = startProcessesButton[index];
                return (
                    <Button
                        type="link"
                        onClick={() => {
                            handleInitProcess(record);
                        }}
                        loading={metadata ? metadata.loading : false}
                        disabled={metadata ? metadata.disabled : false}
                    >
                        {Utilitaries.isEmpty(record.forma_iniciar) ? "Iniciar" : "Opções"}
                    </Button>
                );
            },
        },
    ];

    return (
        <Table
            size="middle"
            rowKey={(data) => data.index + "-table"}
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => {},
                    onDoubleClick: () => {
                        handleInitProcess(record);
                    },
                    onMouseEnter: (event) => {
                        event.target.style.cursor = "pointer";
                    },
                    onMouseLeave: (event) => {
                        event.target.style.cursor = "none";
                    },
                };
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={loading}
            onChange={handleTableChange}
        />
    );
}

export default ProcessTable;
