import { actionType, elemento_raiz } from "./../constants/index";
import { sendHttpRequest } from "../../../components/http.request";

export const select = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "lista_correcoes");
    formData.append("elemento_raiz", elemento_raiz);
    formData.append("id_tabela", payload.id);
    await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse) {
            let list = [];
            if (Array.isArray(jsonResponse.linha)) {
                list = [...jsonResponse.linha];
            } else if (jsonResponse.linha.id) list = [jsonResponse.linha];
            dispatch({ type: actionType.select, payload: list });
        }
    });
};

export const getDetailsVersion = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "consultar_correcao");
    formData.append("id", payload.id);
    await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        dispatch({
            type: payload.actionType,
            payload: jsonResponse,
        });
    });
};
