import React from "react";
import { Col, Card } from "antd";
import { Chart, Axis, Coord, Geom, Guide, Shape } from "bizcharts";
import EmptyData from "./emptyData";
import ChartHelp from "./chartHelp";
const { Html, Arc } = Guide;
const SpeedomerChart = ({ metadata, dataSource }) => {
    Shape.registerShape("point", "pointer", {
        drawShape(cfg, group) {
            let point = cfg.points[0];
            point = this.parsePoint(point);
            const center = this.parsePoint({
                x: 0,
                y: 0,
            });
            group.addShape("line", {
                attrs: {
                    x1: center.x,
                    y1: center.y,
                    x2: point.x,
                    y2: point.y,
                    stroke: cfg.color,
                    lineWidth: 2,
                    lineCap: "round",
                },
            });
            return group.addShape("circle", {
                attrs: {
                    x: center.x,
                    y: center.y,
                    r: 4,
                    stroke: cfg.color,
                    lineWidth: 2,
                    fill: "#fff",
                },
            });
        },
    });

    const objChart = {
        percentage: dataSource?.percentagem / 10,
        valor: dataSource?.valor,
        total: dataSource?.total,
    };
    const data = [objChart];

    const cols = {
        percentage: {
            min: 0,
            max: 10,
            tickInterval: 2.5,
            nice: false,
        },
    };

    const checkNull = (element) => {
        return element === undefined || element === null;
    };
    return (
        <Col xs={24} md={6}>
            <Card
                size="small"
                title={metadata.title ? metadata.title : " "}
                extra={metadata.help ? <ChartHelp text={metadata.help} /> : null}
                className={"qa-title-dashboard"}
            >
                {!dataSource ||
                checkNull(objChart.percentage) ||
                checkNull(objChart.valor) ||
                checkNull(objChart.total) ? (
                    <EmptyData />
                ) : (
                    <Chart height={344} data={data} scale={cols} padding={[0, 0, 130, 0]} forceFit>
                        <span className="qa-sub-title-dashboard">
                            {metadata.labelTotal ? `${metadata.labelTotal}: ${objChart.valor} / ${objChart.total}` : ""}
                        </span>
                        <Coord
                            type="polar"
                            startAngle={((-9 / 8) * Math.PI) / 1.016}
                            endAngle={(1 / 8) * Math.PI}
                            radius={1}
                        />
                        <Axis
                            name="percentage"
                            zIndex={2}
                            line={null}
                            label={{
                                offset: -13,
                                formatter: (val) => {
                                    return Number(val) * 10;
                                },
                                textStyle: {
                                    fontSize: 11,
                                    textAlign: "center",
                                    textBaseline: "middle",
                                },
                            }}
                            subTickCount={4}
                            subTickLine={{
                                length: -8,
                                stroke: "#fff",
                                strokeOpacity: 1,
                            }}
                            tickLine={{
                                length: -18,
                                stroke: "#fff",
                                strokeOpacity: 1,
                            }}
                        />
                        <Axis name="1" visible={false} />
                        <Guide>
                            <Arc
                                zIndex={0}
                                start={[0, 0.965]}
                                end={[10, 0.965]}
                                style={{
                                    // 底灰色
                                    stroke: "#CBCBCB",
                                    lineWidth: 8,
                                }}
                            />
                            <Arc
                                zIndex={1}
                                start={[0, 0.965]}
                                end={[data[0].percentage, 0.965]}
                                style={{
                                    stroke: "#1890FF",
                                    lineWidth: 8,
                                }}
                            />
                            <Html
                                position={["50%", "110%"]}
                                html={() =>
                                    `<div style="width: 300px;text-align: center;font-size: 11px!important;"><p style="font-size: 1.5em; color: rgba(0,0,0,0.43);margin: 0;">${
                                        data[0].valor
                                    } / ${data[0].total}
                                    </p><p style="font-size: 2em;color: rgba(0,0,0,0.85);margin: 0;">${(
                                        data[0].percentage * 10
                                    ).toFixed(2)}%</p></div>`
                                }
                            />
                        </Guide>
                        <Geom
                            type="point"
                            position="percentage*1"
                            shape="pointer"
                            color="#1890FF"
                            active={false}
                            style={{ stroke: "#fff", lineWidth: 1 }}
                        />
                    </Chart>
                )}
            </Card>
        </Col>
    );
};

export default SpeedomerChart;
