import React, { useState } from "react";
import { Button, Tooltip, message } from "antd";

const ButtonsGroup = ({ exportList }) => {
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const handleExportToPdf = async () => {
        setLoadingPdf(true);
        const response = await exportList("pdf");
        if (!response) message.error("Ocorreu um erro");
        setLoadingPdf(false);
    };
    const handleExportToExcel = async () => {
        setLoadingExcel(true);
        const response = await exportList("xsl");
        if (!response) message.error("Ocorreu um erro");
        setLoadingExcel(false);
    };

    return (
        <>
            <Tooltip title="Exportar para pdf">
                <Button
                    onClick={handleExportToPdf}
                    loading={loadingPdf}
                    icon="file-pdf"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#fa541c",
                    }}
                />
            </Tooltip>
            <Tooltip title="Exportar para excel">
                <Button
                    onClick={handleExportToExcel}
                    loading={loadingExcel}
                    icon="file-excel"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#237804",
                    }}
                />
            </Tooltip>
        </>
    );
};

export default ButtonsGroup;
