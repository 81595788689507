import React from "react";
import { Form, Col, Input, Row, Select, Radio, Upload, Icon, Button } from "antd";
import moment from "moment";
import { downloadFile } from "../../../../components/util";

const { Option } = Select;

const DocumentTabPane = ({ enableField, formState, setFormState, initialFormData, getFieldDecorator, toReview }) => {
    const { file } = formState;

    const props = {
        onRemove: (file) => {
            setFormState({ ...formState, file: undefined });
        },
        beforeUpload: (file) => {
            setFormState({ ...formState, file });
            return false;
        },
        fileList: file ? [file] : [],
    };

    const handleNull = (value) => {
        if (value && typeof value === "object") {
            return "";
        } else if (value) {
            return value;
        }

        return "";
    };

    const {
        id_controlo_documento,
        codigo,
        nome,
        modelo_base,
        arquivo_original,
        num_versao,
        estado_versao,
        metodo_arquivo,
        tipo,
        familia_documento,
        origem,
        revisao_obrigatoria,
        periodicidade_revisao,
        data_limite_controlo,
        nome_ficheiro,
    } = initialFormData.document;

    return (
        <div>
            <Row gutter={18}>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Código">
                        {getFieldDecorator("codigo", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: handleNull(codigo) || "",
                        })(<Input disabled={enableField} />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Nome">
                        {getFieldDecorator("nome", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: handleNull(nome) || "",
                        })(<Input disabled={toReview ? false : enableField} />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Modelo Base">
                        {getFieldDecorator("modelo_base", {
                            rules: [],
                            initialValue: handleNull(modelo_base) || "",
                        })(<Input disabled={enableField} />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Arquivo Original">
                        {getFieldDecorator("arquivo_original", {
                            rules: [],
                            initialValue: handleNull(arquivo_original) || "",
                        })(<Input disabled={enableField} />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Edição">
                        {getFieldDecorator("num_versao", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: handleNull(num_versao) || "1",
                        })(<Input disabled />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Estado">
                        {getFieldDecorator("estado_versao", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: handleNull(estado_versao) || "1",
                        })(
                            <Select
                                showSearch
                                allowClear
                                disabled
                                style={{ width: "100%" }}
                                placeholder="Selecione uma opção"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {initialFormData.statusType.map((it) => (
                                    <Option key={`status_${it.id_estado_versao}`} value={it.id_estado_versao}>
                                        {it.designacao}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Método Arquivo">
                        {getFieldDecorator("metodo_arquivo", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: handleNull(metodo_arquivo) || "informático",
                        })(<Input disabled />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Tipo">
                        {getFieldDecorator("tipo", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: handleNull(tipo) || "Documento",
                        })(<Input disabled />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Família">
                        {getFieldDecorator("familia_documento", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: handleNull(familia_documento) || "",
                        })(
                            <Select
                                showSearch
                                allowClear
                                disabled={enableField}
                                style={{ width: "100%" }}
                                placeholder="Selecione uma opção"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {initialFormData.families.map((it) => (
                                    <Option key={`familia_${it.id_familia_documento}`} value={it.id_familia_documento}>
                                        {it.designacao}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Origem">
                        {getFieldDecorator("origem", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: handleNull(origem) || "",
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                disabled={enableField}
                                placeholder="Selecione uma opção"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {initialFormData.origins.map((it) => (
                                    <Option key={`origins_${it.id_origem_documento}`} value={it.id_origem_documento}>
                                        {it.designacao}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Revisão Obrigatória">
                        {getFieldDecorator("revisao_obrigatoria", {
                            rules: [],
                            initialValue: handleNull(revisao_obrigatoria)
                                ? revisao_obrigatoria === "FALSE" ||
                                  revisao_obrigatoria === "false" ||
                                  revisao_obrigatoria === "False" ||
                                  revisao_obrigatoria === "f"
                                    ? "f"
                                    : revisao_obrigatoria === "TRUE" ||
                                      revisao_obrigatoria === "true" ||
                                      revisao_obrigatoria === "True" ||
                                      revisao_obrigatoria === "t"
                                    ? "t"
                                    : ""
                                : "",
                        })(
                            <Radio.Group buttonStyle="solid" disabled={toReview ? false : enableField}>
                                <Radio.Button value={"t"}>Sim</Radio.Button>
                                <Radio.Button value={"f"}>Não</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Periodicidade Revisão">
                        {getFieldDecorator("periodicidade_revisao", {
                            rules: [],
                            initialValue: handleNull(periodicidade_revisao) || "",
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                disabled={toReview ? false : enableField}
                                placeholder="Selecione uma opção"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="mensal">Mensal</Option>
                                <Option value="trimestral">Trimestral</Option>
                                <Option value="semestral">Semestral</Option>
                                <Option value="anual">Anual</Option>
                                <Option value="bienal">Bienal</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Data Limite">
                        {getFieldDecorator("data_limite_controlo", {
                            rules: [],
                            initialValue: handleNull(data_limite_controlo)
                                ? moment(data_limite_controlo).format("YYYY-MM-DD")
                                : null,
                        })(<Input type="date" disabled={enableField} format={"DD-MM-YYYY"} />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={4}>
                    <Form.Item label="Ficheiro">
                        {getFieldDecorator("ficheiro", {
                            rules: [
                                {
                                    required: toReview ? false : enableField,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: null,
                        })(
                            <Upload {...props}>
                                <Button disabled={toReview ? false : enableField}>
                                    <Icon type="file-add" /> Clique para carregar
                                </Button>
                            </Upload>
                        )}
                        {nome_ficheiro && (
                            <Button
                                type="link"
                                onClick={() => {
                                    downloadFile("/geredocumentos.php", {
                                        accao: "documento",
                                        id: id_controlo_documento,
                                    });
                                }}
                            >
                                {nome_ficheiro}
                            </Button>
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

// const WrappedPersonalInfoTabPane = Form.create({ name: 'document' })(
// 	DocumentTabPane
// )

export default DocumentTabPane;
