import React, { Component } from "react";
import { Row, Select, Divider, Table, Button, Form, Input, Popconfirm, Icon, message } from "antd";
import moment from "moment";
import { connect } from "react-redux";

import { selectDataFromExecQuery } from "../../actions/contributor";
import { QaDynamicSelect } from "../../../../components/DynamicList";
import { Utilitaries } from "../../../../business";
import { orderBy } from "../../../../business/utils";

const { Option } = Select;
const { TextArea } = Input;

class FormationTabPane extends Component {
    componentDidMount() {
        const { ficha_colaborador } = this.props;
        if (ficha_colaborador) {
            const { formacoes_externa } = ficha_colaborador;
            if (formacoes_externa.formacao_externa) {
                const dataSource = [];
                if (Array.isArray(formacoes_externa.formacao_externa)) {
                    formacoes_externa.formacao_externa.map((item, index) => {
                        dataSource.push({ key: index, ...item });
                    });
                } else if (formacoes_externa.formacao_externa.id_formacao_externa) {
                    dataSource.push({
                        key: 0,
                        ...formacoes_externa.formacao_externa,
                    });
                }
                const countDataSource = dataSource.length;
                this.setState({
                    formacoes_externa: orderBy({
                        list: dataSource,
                        key: "form_ext_inicio",
                        order: "desc",
                        byDate: true,
                    }),
                    countDataSource,
                });
            }
            this.getFormacaoInterna(ficha_colaborador.username);
        }
    }
    getFormacaoInterna = async (username) => {
        this.setState({ loading: true });
        try {
            const response = await this.props.selectDataFromExecQuery({
                query: "buscar_formacoes_colaborador",
                username,
            });
            if (response.result) {
                this.setState({ formacoes_interna: response.result });
            }
        } catch (error) {
            message.error("Erro ao carregar formações internas!!");
        } finally {
            this.setState({ loading: false });
        }
    };
    /** handle state */
    state = {
        /** search on table */
        searchText: "",
        loading: false,
        formacoes_interna: [],
        formacoes_externa: [],
        countDataSource: 1,
        nivel: [],
        avaliacao: [],
    };
    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    /****************************** */
    /** handle tables columns */
    formacoes_interna_column = [
        {
            title: "Formação",
            dataIndex: "form_int_nome",
            width: "35%",
            ...this.getColumnSearchProps("form_int_nome"),
        },
        {
            title: "Data início",
            dataIndex: "form_int_inicio",
            width: "15%",
            ...this.getColumnSearchProps("form_int_inicio"),
        },
        {
            title: "Data fim",
            dataIndex: "form_int_fim",
            width: "15%",
            ...this.getColumnSearchProps("form_int_fim"),
        },
        {
            title: "Avaliação",
            dataIndex: "form_int_avaliacao",
            width: "35%",
            ...this.getColumnSearchProps("form_int_avaliacao"),
        },
    ];
    formacoes_externa_column = [
        {
            title: "Data início",
            dataIndex: "form_ext_inicio",
            ...this.getColumnSearchProps("form_ext_inicio"),
            onFilter: (value, record) => {
                const my = moment(record["form_ext_inicio"], "YYYY-MM-DD").format("DD/MM/YYYY").replace(/\W/g, "");
                return Utilitaries.toString(my)?.includes(value.replace(/\W/g, ""));
            },
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: 150,
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(e) =>
                                this.handleFieldsChange(
                                    "form_ext_inicio",
                                    e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    record
                                )
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : null}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data fim",
            dataIndex: "form_ext_fim",
            ...this.getColumnSearchProps("form_ext_fim"),
            onFilter: (value, record) => {
                return Utilitaries.toString(
                    moment(record["form_ext_fim"], "YYYY-MM-DD").format("DD/MM/YYYY")
                )?.includes(value);
            },
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: 150,
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(e) =>
                                this.handleFieldsChange(
                                    "form_ext_fim",
                                    e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    record
                                )
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : null}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Nome",
            dataIndex: "form_ext_nome",
            ...this.getColumnSearchProps("form_ext_nome"),
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <TextArea
                        onChange={(e) => this.handleFieldsChange("form_ext_nome", e.target.value, record)}
                        defaultValue={text}
                        allowClear
                        placeholder="Introduza nome"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                    />
                );
            },
        },
        {
            title: "Tipo",
            dataIndex: "form_ext_tipo",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Select
                        allowClear
                        showSearch
                        style={{
                            width: 100,
                            margin: "0 auto",
                            padding: "0",
                        }}
                        placeholder="seleciona uma opção"
                        optionFilterProp="children"
                        onChange={(value) => this.handleFieldsChange("form_ext_tipo", value, record)}
                        defaultValue={text}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="Interna">Interna</Option>
                        <Option value="Externa">Externa</Option>
                    </Select>
                );
            },
        },
        {
            title: "Nível",
            dataIndex: "form_ext_nivel",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <QaDynamicSelect
                        style={{ width: 70 }}
                        onChange={(value) => this.handleFieldsChange("form_ext_nivel", value, record)}
                        defaultValue={text}
                        name="form_ext_nivel"
                    />
                );
            },
        },
        {
            title: "Nº de horas",
            dataIndex: "form_ext_n_horas",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Form.Item
                        style={{
                            width: 100,
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(e) => this.handleFieldsChange("form_ext_n_horas", e.target.value, record)}
                            defaultValue={text}
                            type="number"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Avaliação",
            dataIndex: "form_ext_avaliacao",
            // ...this.getColumnSearchProps("form_ext_avaliacao"),
            // onFilter: (value, record) => {
            //     const filteredValue = this.state.meses
            //         .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
            //         .map((item) => item.id);
            //     return filteredValue.includes(record["form_ext_avaliacao"]);
            // },
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <QaDynamicSelect
                        style={{ minWidth: "150px" }}
                        onChange={(value) => this.handleFieldsChange("form_ext_avaliacao", value, record)}
                        defaultValue={text}
                        name="form_ext_avaliacao"
                    />
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            render: (text, record) =>
                this.state.formacoes_externa.length >= 1 ? (
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        onConfirm={() => this.handleDelete(record.key)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];
    handleFieldsChange = (dataIndex, value, record) => {
        record[dataIndex] = value;
        const { formacoes_externa } = this.state;
        const index = formacoes_externa.findIndex((item) => item.key === record.key);
        formacoes_externa[index] = record;
        this.setState({ formacoes_externa }, this.props.getFormacoesExternas(this.state.formacoes_externa));
    };
    /** handle dynamic table */
    handleDelete = (key) => {
        const formacoes_externa = [...this.state.formacoes_externa];
        this.setState(
            {
                formacoes_externa: formacoes_externa.filter((item) => item.key !== key),
            },
            this.props.getFormacoesExternas(this.state.formacoes_externa)
        );
    };

    handleAdd = () => {
        const { countDataSource, formacoes_externa } = this.state;
        const newData = {
            key: countDataSource,
        };
        this.setState({
            formacoes_externa: [newData, ...formacoes_externa],
            countDataSource: countDataSource + 1,
        });
    };
    render() {
        const { ficha_colaborador } = this.props;
        return (
            <div>
                {ficha_colaborador.id_ficha_colaborador && (
                    <>
                        <Table
                            title={() => <strong>Formações Internas</strong>}
                            size="middle"
                            loading={this.state.loading}
                            columns={this.formacoes_interna_column}
                            dataSource={this.state.formacoes_interna}
                        />
                        <Divider />
                    </>
                )}

                <Row>
                    <Button
                        xs={24}
                        sm={12}
                        onClick={this.handleAdd}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{ marginBottom: 16, float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Row>
                <Table
                    rowKey={(record) => record.key}
                    title={() => <strong>Outras formações</strong>}
                    size="middle"
                    columns={this.formacoes_externa_column}
                    dataSource={this.state.formacoes_externa}
                />
            </div>
        );
    }
}

const mapDispatchToProps = {
    selectDataFromExecQuery,
};

export default connect(null, mapDispatchToProps)(FormationTabPane);
