import actionType from "../constants/index";

const initialState = {
    modalChatState: false,
    modalChatConfState: false,
    modalChatListName: "",
    room: {
        list: [],
        selected: 0,
        isFetched: true,
        filter: null,
    },
    message: {
        list: [],
        isFetched: true,
    },
    user: {
        list: [],
        isFetched: true,
    },
};

export const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.CHANGE_MODAL_CHAT_STATE:
            return {
                ...state,
                modalChatState: action.payload,
            };
        case actionType.CHANGE_MODAL_CHAT_CONF_STATE:
            return {
                ...state,
                modalChatConfState: action.payload.state,
                modalChatListName: action.payload.list,
            };
        case actionType.SET_FILTER:
            return {
                ...state,
                room: {
                    ...state.room,
                    filter: action.payload,
                },
            };
        case actionType.ROOMS:
            return {
                ...state,
                room: {
                    ...state.room,
                    list: Array.isArray(action.payload["linha"])
                        ? action.payload["linha"]
                        : action.payload["linha"]
                        ? [action.payload["linha"]]
                        : [],
                    isFetched: false,
                },
            };
        case actionType.MESSAGES:
            let list = Array.isArray(action.payload["linha"])
                ? action.payload["linha"]
                : action.payload["linha"]
                ? [action.payload["linha"]]
                : [];

            if (list.length === 1 && Object.keys(list[0]).length === 0) {
                list = [];
            }
            return {
                ...state,
                message: {
                    ...state.message,
                    list,
                    isFetched: false,
                },
            };
        case actionType.GET_USERS:
            return {
                ...state,
                user: {
                    ...state.message,
                    list: Array.isArray(action.payload["linha"])
                        ? action.payload["linha"]
                        : action.payload["linha"]
                        ? [action.payload["linha"]]
                        : [],
                    isFetched: false,
                },
            };
        case actionType.ADD_MESSAGES:
            if (!action.payload || !action.payload["linha"]) {
                return state;
            }
            return {
                ...state,
                message: {
                    ...state.message,
                    list: [...state.message.list, action.payload["linha"]],
                    isFetched: false,
                },
            };
        case actionType.SET_SELECTED_ROOM:
            return {
                ...state,
                room: {
                    ...state.room,
                    selected: action.payload,
                },
            };
        default:
            return state;
    }
};
