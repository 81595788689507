import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import ptPtLocate from "@fullcalendar/core/locales/pt-br";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";

import { api_fetch } from "../../../utils/HttpRequest/request";
import { message, Tooltip, Typography } from "antd";
import { Utilitaries } from "../../../../business";
import { CalendarEventProps, formatDataCalendarEvents } from "../formFunctions";
import { GenericObject } from "../../../types/common";

interface MeetingRoomScheduleProps {
    comboValue?: string;
    phpFile?: string;
    refreshSchedule?: boolean;
    handleEditSchedule: (id?: string) => void;
    handleNewSchedule: (props: GenericObject) => void;
}

interface GetToolTipContentProps {
    title?: string;
    dataIni?: string;
    fullHour?: string;
    description?: string;
}

const getToolTipContent = ({ title, dataIni, fullHour, description }: GetToolTipContentProps) => {
    return (
        <>
            <span>
                <b>{title}</b>
            </span>
            {dataIni && (
                <>
                    <br />
                    <span>{dataIni}</span>
                    {fullHour && <span>{` ${fullHour}`}</span>}
                </>
            )}

            {description && (
                <>
                    <br />
                    <span>{description}</span>
                </>
            )}
        </>
    );
};

const MeetingRoomSchedule = ({
    comboValue,
    phpFile,
    refreshSchedule,
    handleNewSchedule,
    handleEditSchedule,
}: MeetingRoomScheduleProps) => {
    const [scheduleEvents, setScheduleEvents] = useState<CalendarEventProps[]>([]);

    const [visibleDateOnCalendar, setVisibleDateOnCalendar] = useState({
        start: "",
        end: "",
    });

    const handleDatesRender = (info: any) => {
        const _start = moment(info.view.activeStart).format("YYYY-MM-DD");
        const _end = moment(info.view.activeEnd).format("YYYY-MM-DD");
        const { start, end } = visibleDateOnCalendar;

        if (moment(start).format("YYYY-MM-DD") !== _start && moment(end).format("YYYY-MM-DD") !== _end) {
            setVisibleDateOnCalendar({
                start: _start,
                end: _end,
            });
        }
    };

    const handleEventClick = (info: any) => {
        if (!info.event.id) {
            return;
        }
        handleEditSchedule(info.event.id);
    };

    useEffect(() => {
        const loadCalendarEvents = async (_start: string, _end: string) => {
            try {
                if (!comboValue) {
                    return;
                }
                const params = {
                    accao: "consulta",
                    id_tipo_requisicao: comboValue,
                    gestor: "",
                    data_inicio: _start,
                    data_fim: _end,
                };
                const {
                    response,
                    status,
                    message: messageError,
                } = await api_fetch({
                    endPoint: `Gestor/${phpFile}`,
                    method: "POST",
                    params,
                });
                if (status === "ERROR") {
                    message.error(messageError || "Ocorreu um erro");
                    return;
                }

                if (status === "SUCCESS") {
                    const result = Utilitaries.toArray(response?.requisicoes?.linha);
                    const dataSource = formatDataCalendarEvents(result);
                    setScheduleEvents(dataSource);
                }
            } catch (error) {
                // setComboLoading(false);
            }
        };
        const { start, end } = visibleDateOnCalendar;
        loadCalendarEvents(start, end);
    }, [refreshSchedule, phpFile, comboValue, visibleDateOnCalendar]);

    const handleRenderEvent = (info: any) => {
        const { _description } = info.event?.extendedProps || {};
        const { start, end, allDay, title } = info.event || {};

        const dateStart = start ? moment(start) : null;
        const dataIni = dateStart ? dateStart.format("YYYY-MM-DD") : undefined;
        const hourStart = dateStart ? dateStart.format("HH:mm") : undefined;

        const dateEnd = end ? moment(end) : null;
        const hourEnd = dateEnd ? dateEnd.format("HH:mm") : undefined;

        let fullHour;
        if (!allDay && hourStart) {
            fullHour = hourStart + (hourEnd ? ` - ${hourEnd}` : "");
        }

        const toolTipContent = getToolTipContent({ title, dataIni, fullHour, description: _description });

        const content = (
            <Tooltip overlayStyle={{ maxWidth: "80%" }} title={toolTipContent}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography.Paragraph ellipsis style={{ color: "inherit", margin: 0 }}>
                        <div className="fc-content">
                            {!allDay && fullHour && (
                                <div className="fc-time" data-start={hourStart} data-full={fullHour}>
                                    <span>{fullHour}</span>
                                </div>
                            )}
                            <div className="fc-title">
                                {title} <br />
                                {_description}
                            </div>
                        </div>
                    </Typography.Paragraph>
                </div>
            </Tooltip>
        );

        ReactDOM.render(content, info.el);
    };

    const handleDateSelect = (arg: any) => {
        const params = {
            tipo_agendamento: "definido",
            dataInicio: moment(arg.start).format("YYYY-MM-DD"),
            dataFim: moment(arg.end).format("YYYY-MM-DD"),
        } as GenericObject;

        if (!arg.allDay) {
            params.horaInicio = moment(arg.start).format("HH:mm");
            params.horaFim = moment(arg.end).format("HH:mm");
        }
        handleNewSchedule({ ...params });
    };

    return (
        <FullCalendar
            defaultView="timeGridWeek"
            datesRender={handleDatesRender}
            eventRender={handleRenderEvent}
            editable={false}
            selectable={true}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            locales={[ptPtLocate]}
            locale="pt-pt"
            header={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            buttonText={{
                today: "Hoje",
                month: "Mês",
                week: "Semana",
                day: "Dia",
                list: "Lista",
            }}
            events={scheduleEvents}
            select={handleDateSelect}
            eventClick={handleEventClick}
        />
    );
};

export default MeetingRoomSchedule;
