import { Utilitaries } from "../../../../business";
import { changeObjectPropertyRecursive, getFormInputFromPath } from "../../../utils/FormMapper/formHandle";
import { COMPONENT_TYPE, GRAPHIC_ELEMENT } from "../../../utils/FormMapper/XMLInputType";
import { GenericObject } from "../../../types/common";
import {
    FormInputCalcTriggerProps,
    FormInputsObjectProps,
    FormInputsProps,
    InputCalcTriggerProps,
    TableDataSourceProps,
} from "../../../types/formTypes";

interface setNewTableRowValuesFromPathProps {
    form: FormInputsObjectProps;
    path: string[];
    key: string;
    value: any;
    root: string;
    row_key: string;
    other_values?: any;
}
export function setNewTableRowValuesFromPath({
    form,
    path,
    key,
    value,
    root,
    other_values,
    row_key,
}: setNewTableRowValuesFromPathProps) {
    if (path.length === 0) {
        if (form) {
            let new_value = value;
            const data_source = form?.[root]?.DATA_SOURCE as GenericObject;
            if (
                data_source?.[row_key]?.[key] &&
                typeof data_source?.[row_key]?.[key] === "object" &&
                !Array.isArray(data_source?.[row_key]?.[key])
            ) {
                new_value = {
                    ...data_source?.[row_key]?.[key],
                    id: value,
                };
            }

            form[root].DATA_SOURCE = {
                ...data_source,
                [row_key]: {
                    ...data_source?.[row_key],
                    [key]: new_value,
                    ...other_values,
                },
            };
        }
    } else {
        const new_path = [...path];
        let el = new_path.shift();

        if (el) {
            form[el] = setNewTableRowValuesFromPath({
                form: form[el],
                path: new_path,
                key,
                value,
                root,
                row_key,
            }) as FormInputsProps;
        }
    }

    return form;
}

interface ChangeObjectPropertyRecursivelyProps {
    form: FormInputsObjectProps;
    path: string[];
    key: string;
    value: any;
    data_source: any;
    force_value?: boolean;
}
export const changeObjectPropertyRecursively = ({
    form,
    path = [],
    key,
    value,
    data_source = null,
    force_value = false,
}: ChangeObjectPropertyRecursivelyProps) => {
    if (path.length === 0) {
        if (form) {
            if (force_value) {
                form[key].value = value || data_source;
            } else {
                if (value !== null) {
                    form[key].value = value;
                }
                if (data_source) {
                    form[key].DATA_SOURCE = data_source;
                }
            }
        } else {
            const item: FormInputsProps = {
                key,
            };

            if (value) {
                item.value = value;
            }

            if (data_source) {
                item.DATA_SOURCE = data_source;
            }

            form = {
                [key]: {
                    ...item,
                },
            };
        }
    } else {
        const new_path = [...path];
        let el = new_path.shift();
        if (el) {
            form[el] = changeObjectPropertyRecursively({
                form: form[el],
                path: new_path,
                key,
                value,
                data_source,
                force_value,
            }) as FormInputsProps;
        }
    }
    return form;
};

const addPropertyToObject = (obj: GenericObject, root: string[], key: string, itemToAdd: GenericObject) => {
    if (root.length === 0) {
        if (obj) {
            obj[key] = {
                ...obj[key],
                ...itemToAdd,
            };
        }
    } else {
        const new_root = [...root];
        let el = new_root.shift();
        if (el) {
            obj[el] = addPropertyToObject(obj[el], new_root, key, itemToAdd);
        }
    }

    return obj;
};

interface MergeStateProps {
    state: FormInputsObjectProps;
    new_input_data: {
        key: string;
        value?: any;
        data_source?: any;
    };
    root?: string;
    row_key?: string;
    full_path?: string[];
    input: FormInputsProps;
}
export const mergeState = ({
    state,
    new_input_data,
    root = "",
    row_key = "",
    full_path = [],
    input,
}: MergeStateProps) => {
    const getValueRow = (row: any, new_date: any): any => {
        if (input.select_all === "sim") {
            new_date = {
                ...new_date,
                id: row["id_" + input.db_table_name]
                    ? new_date.id
                    : Utilitaries.toString(
                          Utilitaries.toArray(new_input_data.data_source).map((item: any) => item[input.key_list || ""])
                      ),
            };
        }

        return new_date;
    };

    let new_state = { ...state };

    if (!new_input_data.key) {
        return state;
    }

    if (root && row_key) {
        const dataSourceItem =
            full_path.length > 0
                ? state?.[full_path[0]]?.[root]?.DATA_SOURCE?.[row_key]?.[new_input_data.key]
                : state?.[root]?.DATA_SOURCE?.[row_key]?.[new_input_data.key];

        let newDate = {};

        if (new_input_data.value) {
            newDate = new_input_data.value;
        } else {
            newDate = {
                id: typeof dataSourceItem === "object" ? dataSourceItem.id : dataSourceItem,
                DATA_SOURCE: new_input_data.data_source,
            };
        }

        if (full_path) {
            new_state = {
                ...new_state,
                [full_path[0]]: {
                    ...new_state[full_path[0]],
                    [root]: {
                        ...new_state[full_path[0]]?.[root],
                        DATA_SOURCE: {
                            ...new_state[full_path[0]]?.[root]?.DATA_SOURCE,
                            [row_key]: {
                                ...new_state[full_path[0]]?.[root]?.DATA_SOURCE?.[row_key],
                                [new_input_data.key]: newDate,
                            },
                        },
                    },
                },
            };
        } else {
            new_state = {
                ...new_state,
                [root]: {
                    ...new_state[root],
                    DATA_SOURCE: {
                        ...new_state[root]?.DATA_SOURCE,
                        [row_key]: {
                            ...new_state[root]?.DATA_SOURCE?.[row_key],
                            [new_input_data.key]: getValueRow(new_state[root]?.DATA_SOURCE?.[row_key], newDate),
                        },
                    },
                },
            };
        }
    } else if (full_path && full_path.length > 0) {
        new_state = {
            ...new_state,
            ...addPropertyToObject(new_state, full_path, new_input_data.key, {
                value: new_input_data.value,
                DATA_SOURCE: new_input_data.data_source,
            }),
        };
    } else {
        new_state = {
            ...new_state,
            [new_input_data.key]: {
                ...new_state[new_input_data.key],
                value: new_input_data?.value,
                DATA_SOURCE: new_input_data?.data_source,
            },
        };
    }

    return new_state;
};

interface SetOnInputChangeValueProps {
    value: any;
    more_values: any;
    key: string;
    input_group_path?: string[];
    root?: string;
    row_key?: string;
    child_root_key?: string;
    select_value_text?: any;
    state: FormInputsObjectProps;
}
export function handleInputChangeValue({
    value,
    key,
    root,
    input_group_path,
    row_key,
    child_root_key,
    select_value_text,
    more_values,
    state,
}: SetOnInputChangeValueProps) {
    const otherValues: GenericObject = more_values;

    if (input_group_path && input_group_path.length > 0) {
        let new_state = {};
        if (root && row_key) {
            new_state = setNewTableRowValuesFromPath({
                form: state,
                path: input_group_path,
                key,
                value,
                root,
                row_key,
                other_values: otherValues,
            });
        } else {
            new_state = changeObjectPropertyRecursively({
                form: state,
                path: input_group_path,
                key,
                value,
                data_source: value,
                force_value: true,
            });
        }

        return new_state;
    } else if (root) {
        const data_source = state[root]?.DATA_SOURCE as GenericObject;
        if (!row_key) {
            return {
                ...state,
                [root]: {
                    ...state[root],
                    ...otherValues,
                    [key]: {
                        ...state[root][key],
                        value,
                    },
                },
            };
        } else if (
            data_source?.[row_key]?.[key] &&
            typeof data_source[row_key]?.[key] === "object" &&
            !Array.isArray(data_source[row_key][key])
        ) {
            return {
                ...state,

                [root]: {
                    ...state[root],
                    DATA_SOURCE: {
                        ...data_source,
                        [row_key]: {
                            ...data_source[row_key],
                            ...otherValues,
                            [key]: value,
                        },
                    },
                },
            };
        } else {
            const data_source = state?.[root]?.DATA_SOURCE as GenericObject;

            return {
                ...state,
                [root]: {
                    ...state?.[root],
                    DATA_SOURCE: {
                        ...data_source,
                        [row_key]: {
                            ...data_source?.[row_key],
                            ...otherValues,
                            [key]: value,
                        },
                    },
                },
            };
        }
    } else {
        if (child_root_key) {
            let rootChildKey = "";
            let fieldKey = "";
            Object.keys(state[key]).forEach((item, index) => {
                if (item !== "DATA_SOURCE" && item !== "value" && typeof state[key][item] === "object") {
                    rootChildKey = item;
                }
            });

            Object.keys(state[key][rootChildKey]).forEach((item, index) => {
                if (item !== "dataSource" && typeof state[key][rootChildKey][item] === "object") {
                    fieldKey = item;
                }
            });

            return {
                ...state,
                [key]: {
                    ...state[key],
                    value,
                    [rootChildKey]: {
                        ...state[key][rootChildKey],
                        [fieldKey]: {
                            ...state[key][rootChildKey][fieldKey],
                            value,
                        },
                    },
                },
            };
        } else {
            return {
                ...state,

                ...state,
                ...otherValues,
                [key]: {
                    ...state[key],
                    value,
                },
            };
        }
    }
}

interface SetOnTableChangeDataSourceProps {
    key: string;
    input_group_path?: string[];
    dataSource: any[];
    state: FormInputsObjectProps;
}
export function handleTableChangeDataSource({
    key,
    input_group_path,
    dataSource,
    state,
}: SetOnTableChangeDataSourceProps) {
    // preparar dataSource
    let objDataSource: any;
    if (Array.isArray(dataSource)) {
        objDataSource = dataSource.reduce((acumulator, current, index) => {
            const objCurrent: GenericObject = {};
            for (let key in current) {
                objCurrent[key] = Utilitaries.toString(current[key]);
            }
            objCurrent.key = index + 1;
            acumulator[index + 1] = objCurrent;
            return acumulator;
        }, {} as GenericObject);
    }

    if (input_group_path && input_group_path.length > 0) {
        // const otherValues: GenericObject = {};

        // const objectOtherValues = Object.keys(otherValues).reduce((acc, key) => {
        //     acc[key] = otherValues[key];
        //     return acc;
        // }, {} as GenericObject);
        // let new_state = {};
        // if (root && row_key) {
        //     new_state = setNewTableRowValuesFromPath({
        //         form: state,
        //         path: input_group_path,
        //         key,
        //         value,
        //         root,
        //         row_key,
        //         other_values: objectOtherValues,
        //     });
        // } else {
        //     new_state = changeObjectPropertyRecursively({
        //         form: state,
        //         path: input_group_path,
        //         key,
        //         value,
        //         data_source: value,
        //     });
        // }
        return { state, newTableDataSource: objDataSource };
    } else {
        return {
            state: {
                ...state,
                [key]: {
                    ...state[key],
                    DATA_SOURCE: { ...objDataSource },
                },
            },
            newTableDataSource: objDataSource,
        };
    }
}
interface AddTableRowProps {
    table_key: string;
    columns: FormInputsProps[];
    input_group_path: string[] | null;
    state: FormInputsObjectProps;
}
export function addTableRow({ table_key, columns, input_group_path, state }: AddTableRowProps) {
    let tableComponentsProperties: FormInputsProps = {} as FormInputsProps;

    if (input_group_path) {
        tableComponentsProperties = getFormInputFromPath(table_key, state, input_group_path);
    } else {
        tableComponentsProperties = state[table_key];
    }
    let data_index = 0;
    const data_source = tableComponentsProperties.DATA_SOURCE || {};
    const data_source_length = Object.values(data_source).length;
    if (data_source_length > 0) {
        const last_data_source_item: any = Object.values(data_source)[data_source_length - 1];
        data_index = Number(last_data_source_item.key);
    } else {
        data_index = 0;
    }

    data_index = data_index + 1;

    const new_row = {
        [String(data_index)]: {
            key: String(data_index),
            ...columns
                .filter((column) => column.type !== "deleteAction" && column)
                .reduce((acumulator, column) => {
                    const default_value = column.default_value || "";

                    if (column.type === COMPONENT_TYPE.SELECT) {
                        // const has_actions = Object.keys(column).some((key) => key.startsWith("@accao_"));
                        acumulator[column.key] = default_value;
                        // if (has_actions) {
                        //     acumulator[column.key] = default_value;
                        // } else {
                        //     acumulator[column.key] = default_value;
                        // }
                    } else if (column.tyoe === COMPONENT_TYPE.BOOLEAN) {
                        if (column.graphic_element === GRAPHIC_ELEMENT.SELECTION_BUTTONS && column.values) {
                            acumulator[column.key] = default_value;
                        } else {
                            const booleanValue = default_value.toLowerCase();
                            acumulator[column.key] =
                                booleanValue === "true" ? "t" : booleanValue === "false" ? "f" : default_value;
                        }
                    } else {
                        acumulator[column.key] = default_value;
                    }
                    return acumulator;
                }, {} as TableDataSourceProps),
        },
        ...data_source,
    };

    let new_state: FormInputsObjectProps = { ...state };
    if (input_group_path) {
        new_state = changeObjectPropertyRecursive({
            key: table_key,
            value: null,
            path: [...input_group_path],
            data_source: new_row,
            formInputsObject: new_state,
        });
    } else {
        new_state[table_key] = {
            ...new_state[table_key],
            DATA_SOURCE: new_row,
        };
    }

    return new_state;
}

interface RemoveTableRowProps {
    table_key: string;
    row_key: string;
    input_group_path: string[] | null;
    state: FormInputsObjectProps;
}
export function removeTableRow({ table_key, row_key, input_group_path, state }: RemoveTableRowProps) {
    let tableComponentsProperties: FormInputsProps = {} as FormInputsProps;

    if (input_group_path) {
        tableComponentsProperties = getFormInputFromPath(table_key, state, input_group_path);
    } else {
        tableComponentsProperties = state[table_key];
    }
    const data_source = tableComponentsProperties.DATA_SOURCE ? { ...tableComponentsProperties.DATA_SOURCE } : {};
    delete data_source[row_key];

    let new_state: FormInputsObjectProps = { ...state };
    if (input_group_path) {
        new_state = changeObjectPropertyRecursive({
            key: table_key,
            value: null,
            path: [...input_group_path],
            data_source: data_source,
            formInputsObject: new_state,
        });
    } else {
        new_state[table_key] = {
            ...new_state[table_key],
            DATA_SOURCE: data_source,
        };
    }

    return new_state;
}

interface SetOnInputChangeCalcTriggerProps {
    prevState: FormInputCalcTriggerProps;
    inputsCalcTrigger: InputCalcTriggerProps[];
}

export function handleInputChangeCalcTrigger({ prevState, inputsCalcTrigger }: SetOnInputChangeCalcTriggerProps) {
    const newState = {
        ...prevState,
        ...inputsCalcTrigger.reduce((acc, el) => {
            const obj: InputCalcTriggerProps = {
                ...prevState[el.full_path],
                full_path: el.full_path,
            };
            if (el.calculateTrigger) {
                obj.calculateTrigger = !prevState[el.full_path]?.calculateTrigger;
            }
            if (el.relevantTrigger) {
                obj.relevantTrigger = !prevState[el.full_path]?.relevantTrigger;
            }
            if (el.visibleTrigger) {
                obj.visibleTrigger = !prevState[el.full_path]?.visibleTrigger;
            }
            if (el.restrictionTrigger) {
                obj.restrictionTrigger = !prevState[el.full_path]?.restrictionTrigger;
            }
            if (el.requiredTrigger) {
                obj.requiredTrigger = !prevState[el.full_path]?.requiredTrigger;
            }
            if (el.readOnlyTrigger) {
                obj.readOnlyTrigger = !prevState[el.full_path]?.readOnlyTrigger;
            }
            acc[el.full_path] = { ...obj };
            return acc;
        }, {} as FormInputCalcTriggerProps),
    };
    return newState;
}
