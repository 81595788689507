import { Col, Form, AutoComplete } from "antd";
import React, { useContext, memo, useEffect, useState } from "react";
import { sendHttpRequest } from "../../http.request";
import FormContext from "../context/FormContext";
import { QADivider, QAToolTip } from "./";
import { input_validations, getAllDependencies } from "../utils/validations";
import { getElementAction, addDoubleQuotesToParams, getInputLabel } from "../utils/functions";
import { Utilitaries } from "../../../business";

const QaAutoComplete = ({
    inputAttribute,
    value,
    field,
    visible,
    disabled,
    hasError,
    renderGroup,
    isRequired,
    objectInputs,
    colSize,
    ...inputProps
}) => {
    const { formData, changeInputValue, updateError, formErrors, flagAddTable, justSee } = useContext(FormContext);
    const [list, setList] = useState([]);
    const [error, setError] = useState(null);

    // const hasDeps = hasInputDependence({
    //     input: inputAttribute,
    //     formData,
    //     options: inputProps,
    //     formInputs: objectInputs,
    // });
    // Get value
    let valueDep = [];
    let inputActionsName = null;

    const elementAction = getElementAction({ item: inputAttribute });
    if (elementAction) {
        valueDep = getAllDependencies(elementAction.valueDep);
        inputActionsName = elementAction.inputActionsName;
    }

    if (field === "lote") {
    }

    useEffect(() => {
        const getData = async () => {
            if (!inputAttribute["@dados"]) {
                //
                return;
            }

            let dados = inputAttribute["@dados"];

            const fd = new FormData();
            fd.append("accao", "query");
            fd.append("query", dados);

            try {
                return sendHttpRequest("POST", "/Gestor/execQuery.php", fd).then((data) => {
                    if (data && typeof data === "object") {
                        setList(data.result);
                    }
                });
            } catch (error) {}
        };

        if (inputAttribute["@dados"]) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputAttribute["@dados"]]);

    useEffect(
        () => {
            const getData = async () => {
                try {
                    if (field === "lote") {
                    }
                    let expression = input_validations({
                        item: inputAttribute,
                        relKey: inputActionsName,
                        returnExpression: true,
                        formData,
                        inputProps,
                    });
                    expression = addDoubleQuotesToParams(expression);
                    let expressionValue = null;
                    try {
                        expressionValue = await eval(`${expression}`);
                    } catch (error) {}

                    // if (expression.includes("auto_preenche")) {
                    //     changeInputValue({
                    //         value: "",
                    //         allValues: expressionValue,
                    //         key: field,
                    //         inputAttribute,
                    //         noMessage: true, // nao mostrar pop up com mesagem de campo errado
                    //         ...inputProps,
                    //     });
                    //     return;
                    // }

                    //

                    // const resultKeys =
                    //     expressionValue && typeof expressionValue === "object" ? Object.keys(expressionValue) : null;
                    // const inputValue = resultKeys
                    //     ? resultKeys.length === 1
                    //         ? expressionValue[resultKeys[0]]
                    //         : expressionValue[inputAttribute["@chave_lista"]]
                    //     : expressionValue;

                    // if (field === "valida") {
                    //     ;
                    // }

                    // changeInputValue({
                    //     value: FormUtilitaries.inputValueConvert(inputValue),
                    //     key: field,
                    //     inputAttribute,
                    //     noMessage: true, // nao mostrar pop up com mesagem de campo errado
                    //     ...inputProps,
                    // });
                } catch (error) {
                    console.error(error, field, inputAttribute);
                }
            };

            if (!inputAttribute[inputActionsName] || !formData) {
                return;
            }

            getData();
        },
        //eslint(react-hooks/exhaustive-deps)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueDep
            .filter((it) => it !== field)
            .map((it) => {
                if (inputProps?.items?.data[it]) return inputProps.items.data[it];

                return formData ? formData[it] : null;
            })
    );

    useEffect(() => {
        if (inputProps.inTable) {
            if (flagAddTable.hasOwnProperty(inputProps?.items?.table)) {
                setError(hasError({ [field]: value }));
            }
        } else if (updateError) {
            setError(formErrors[field]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateError, flagAddTable[inputProps?.items?.table], isRequired]);

    const auto_preenche_valor = async (query, ...params) => {
        const fd = new FormData();
        fd.append("query", query);
        fd.append("accao", "query");

        let nullValues = false;
        params
            .map((it) => it.replace(/[{}\s]/g, "").split(","))
            .forEach((it) => {
                // eslint-disable-next-line no-eval
                const val = eval(it[1]);

                // if (!value || val === undefined) {
                //     nullValues = true;
                // }
                if (val === undefined) {
                    nullValues = true;
                }
                fd.append(it[0], val === "t" ? "true" : val);
            });

        if (nullValues) {
            return;
        }
        try {
            const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", fd);
            setList(data.result);
            return data.result[0];
        } catch (error) {
            return null;
        }
    };

    // eslint-disable-next-line no-unused-vars
    const auto_preenche = auto_preenche_valor;

    const RenderInput = () => (
        <AutoComplete
            disabled={justSee || disabled}
            placeholder={null}
            dataSource={list?.map((it) => (
                <AutoComplete.Option
                    key={it[inputAttribute["@chave_lista"]]}
                    value={it[inputAttribute["@chave_lista"].split(";")?.[0]]}
                >
                    {inputAttribute["@valor_lista"]
                        .split(";")
                        .filter((it) => !Utilitaries.isEmpty(it))
                        .map((itKey) => it[itKey])
                        .join(" - ")}
                </AutoComplete.Option>
            ))}
            value={value}
            filterOption={(inputValue, option) =>
                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(value) => {
                const _err = hasError({ [field]: value });
                setError(_err);
                changeInputValue({
                    value,
                    key: field,
                    inputAttribute,
                    ...inputProps,
                });
            }}
        />
    );

    const { inTable } = inputProps;

    return inTable ? (
        <>
            {RenderInput()}
            {error && <span style={{ color: "red" }}>{error}</span>}
        </>
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            <Col xs={colSize}>
                <Form.Item
                    label={<QAToolTip label={getInputLabel(inputAttribute, field)} help={inputAttribute["@ajuda"]} />}
                >
                    {RenderInput()}
                    {error && <span style={{ color: "red" }}>{error}</span>}
                </Form.Item>
            </Col>
        </>
    );
};

export default memo(QaAutoComplete);
