import { Col, Modal, Row, Input, message, Form } from "antd";
import React, { useState } from "react";
import { Session } from "../../../business";
import { sendHttpRequest } from "../../../components/http.request";

const { Password } = Input;

function ChangePassword({ isVisibleChangePassword, closeModal, form }) {
    const [loading, setLoading] = useState(false);
    const [confirmDirty, setConfirmDirty] = useState(false);

    const hanldeOk = () => {
        form.validateFields(async (err, values) => {
            if (!err) {
                setLoading(true);

                const formData = new FormData();
                formData.append("accao", "alterar_senha_v1");
                formData.append("senha_atual", values.senha_atual);
                formData.append("senha_nova", values.senha);
                // formData.append('confirmar_senha', values.confirmar_senha);

                const response = await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);

                setLoading(false);
                if (response.success) {
                    message.success("Guardado com sucesso");
                    closeModal();
                    Session.update({ ...Session.getAll(), isFirstLogin: undefined });
                } else {
                    message.error(response.erro);
                }
            }
        });
    };

    const { getFieldDecorator } = form;

    const handleConfirmBlur = (e) => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue("senha")) {
            callback("As senhas introduzidas não são iguais");
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        if (value && confirmDirty) {
            form.validateFields(["confirmar_senha"], { force: true });
        }
        callback();
    };

    return (
        <Modal
            centered
            confirmLoading={loading}
            destroyOnClose
            title="Editar informações"
            visible={isVisibleChangePassword}
            // width="40%"
            // bodyStyle={{ minHeight: 480 }}
            onCancel={() => closeModal()}
            onOk={() => hanldeOk()}
        >
            <Form layout="vertical">
                <Row gutter={24}>
                    <Col xs={24} md={16} offset={4}>
                        <Form.Item label="Senha atual" hasFeedback>
                            {getFieldDecorator("senha_atual", { rules: [{ required: true, message: "Senha atual" }] })(
                                <Password placeholder="Senha" visibilityToggle />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={16} offset={4}>
                        <Form.Item label="Nova senha" hasFeedback>
                            {getFieldDecorator("senha", {
                                rules: [
                                    { required: true, message: "Informe a nova senha" },
                                    { validator: validateToNextPassword },
                                ],
                            })(<Password placeholder="Senha" visibilityToggle />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={16} offset={4}>
                        <Form.Item label="Confirmar nova senha" hasFeedback>
                            {getFieldDecorator("confirmar_senha", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Confirma sua nova senha",
                                    },
                                    { validator: compareToFirstPassword },
                                ],
                            })(<Password onBlur={handleConfirmBlur} placeholder="Confirmar senha" visibilityToggle />)}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default Form.create({ name: "edit_user_pass" })(ChangePassword);
