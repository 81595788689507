import { actionType } from "./../constants/knowledge.type";
import { arrayToObjectById } from "./../../../components/util";

const resetFormCreate = () => ({
    designacao: { name: "designacao", value: null },
    descricao: { name: "descricao", value: null },
});

const resetFormEdit = () => ({
    id_tipo_conhecimento: null,
    designacao: { name: "designacao", value: null },
    descricao: { name: "descricao", value: null },
});

const initialState = {
    domain: {
        formCreate: resetFormCreate(),
        formEdit: resetFormEdit(),
        byId: {},
        metadata: {
            attributes: {},
            formulario: {},
        },
    },
    app: {
        isFetched: false,
    },
};

export const knowledgeTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.select:
            let list = [];
            if (Number(action.payload["@attributes"].resultados) > 1) list = [...action.payload["linha"]];
            else if (Number(action.payload["@attributes"].resultados) === 1) list = [action.payload["linha"]];

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: list,
                        id: "id_tipo_conhecimento",
                    }),
                    metadata: {
                        ...state.domain.metadata,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };

        case actionType.getForm:
            const formCreate = action.payload.formulario["@attributes"];
            return {
                ...state,
                domain: {
                    ...state.domain,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formCreate.nome,
                            elemento_raiz: formCreate.elemento_raiz,
                        },
                    },
                },
            };

        case actionType.insert:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formCreate: resetFormCreate(),
                },
            };

        case actionType.setField:
            if (action.payload.isEdit) {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            ...action.payload.fields,
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            ...action.payload.fields,
                        },
                    },
                };
            }

        case actionType.getById:
            const values = action.payload.valores.tipo_conhecimento;
            const formulario = action.payload.formulario["@attributes"];
            const formData = {
                id_tipo_conhecimento: values.id_tipo_conhecimento,
                designacao: {
                    name: "designacao",
                    value: values.designacao,
                },
                descricao: {
                    name: "descricao",
                    value: typeof values.descricao === "object" ? null : values.descricao,
                },
            };
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: {
                        ...state.domain.formEdit,
                        ...formData,
                    },
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formulario.nome,
                            elemento_raiz: formulario.elemento_raiz,
                        },
                    },
                },
            };

        default:
            return state;
    }
};
