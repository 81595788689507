import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Modal, Form, Input, Col, Checkbox, Button, Icon } from "antd";

import PermissionsTransfer from "./PermissionsTransfer";
import { getPermissions, getPermissionsByIdMenu } from "../../../actions/index";
import { toArray } from "../../../components/util";

const MenuEditor = ({
    title,
    visibility,
    onCloseEditor,
    modelForm,
    isEdit,
    setReload,
    reload,
    form, //form props
    // getPermissions, // redux action
    getPermissionsByIdMenu, // redux action
    // listOfpermissions, // redux state
}) => {
    const [showPermissions, setShowPermissions] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [menuPermissions, setMenuPermissions] = useState([]);
    const { getFieldDecorator } = form;

    // useEffect(() => {
    //     getPermissions({ accao: "permissao" });
    // }, []);

    useEffect(() => {
        if (modelForm) {
            const getMenuPermissions = async () => {
                const menuPermissions = await getPermissionsByIdMenu({
                    accao: "autorizacoes",
                    id: modelForm.idmenu,
                });
                // const validMenuPermissions = validateMenuPErmissions(menuPermissions);
                const { autorizacoes, lista_autorizacoes } = menuPermissions;

                const menuPermissionList = toArray(autorizacoes?.linha);
                const listaAutorizacoes = toArray(lista_autorizacoes?.linha);

                setMenuPermissions(
                    menuPermissionList.map((item) => ({
                        key: item.idautorizacao,
                        ...item,
                    }))
                );

                setPermissions(
                    listaAutorizacoes.map((item) => ({
                        key: item.idautorizacao,
                        ...item,
                    }))
                );
            };
            getMenuPermissions();
        }
    }, [modelForm]);

    // useEffect(() => {
    //
    //     if (listOfpermissions.length) {
    //         const newPermissions = [];
    //         listOfpermissions.forEach((item) => {
    //             newPermissions.push({ key: item.idautorizacao, ...item });
    //         });
    //

    //         setPermissions(newPermissions);
    //     }
    // }, [showPermissions]);

    // const validateMenuPErmissions = (menuPermissions) => {
    //     const newMenuPermissions = [];
    //     if (menuPermissions.autorizacoes) {
    //         const { autorizacoes, lista_autorizacoes } = menuPermissions;

    //         const menuPermissionList = toArray(autorizacoes.linha);
    //         const listaAutorizacoes = toArray(lista_autorizacoes.linha);

    //         menuPermissionList.forEach((item) => {
    //             newMenuPermissions.push({
    //                 key: item.idautorizacao,
    //                 ...item,
    //             });
    //         });
    //     }
    //     return newMenuPermissions;
    // };

    const handleOnCancel = () => {
        onCloseEditor();
    };
    const formItemLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
    };
    return (
        <Modal
            destroyOnClose={true}
            width="80%"
            title={title}
            visible={visibility}
            footer={null}
            onCancel={handleOnCancel}
        >
            <Form layout="horizontal">
                <Row>
                    <Col xs={24} sm={12}>
                        <Form.Item label="Título" {...formItemLayout}>
                            {getFieldDecorator("titulo", {
                                // rules: [
                                // 	{
                                // 		required: true,
                                // 		message: "Campo obrigatório",
                                // 	},
                                // ],
                                initialValue:
                                    modelForm && typeof modelForm.titulo !== "object" ? modelForm.titulo : undefined,
                            })(<Input readOnly />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label="Descrição" {...formItemLayout}>
                            {getFieldDecorator("descricao", {
                                // rules: [
                                // 	{
                                // 		required: true,
                                // 		message: "Campo obrigatório",
                                // 	},
                                // ],
                                initialValue:
                                    modelForm && typeof modelForm.descricao !== "object"
                                        ? modelForm.descricao
                                        : undefined,
                            })(<Input readOnly />)}
                        </Form.Item>
                    </Col>
                    {!isEdit && (
                        <Col xs={24} sm={12}>
                            <Form.Item label="Ativo" {...formItemLayout}>
                                {getFieldDecorator("ativo", {
                                    // rules: [
                                    // 	{
                                    // 		required: true,
                                    // 		message: "Campo obrigatório",
                                    // 	},
                                    // ],
                                    initialValue:
                                        modelForm && typeof modelForm.ativo !== "object" ? modelForm.ativo : undefined,
                                })(<Checkbox />)}
                            </Form.Item>
                        </Col>
                    )}

                    <Col xs={24} sm={12}>
                        <Form.Item label="Posição" {...formItemLayout}>
                            {getFieldDecorator("posicao", {
                                // rules: [
                                // 	{
                                // 		required: true,
                                // 		message: "Campo obrigatório",
                                // 	},
                                // ],
                                initialValue:
                                    modelForm && typeof modelForm.posicao !== "object" ? modelForm.posicao : undefined,
                            })(<Input readOnly />)}
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item label="Menu Pai" {...formItemLayout}>
                            {getFieldDecorator("pai", {
                                // rules: [
                                // 	{
                                // 		required: true,
                                // 		message: "Campo obrigatório",
                                // 	},
                                // ],
                                initialValue:
                                    modelForm && typeof modelForm.pai !== "object" ? modelForm.pai : undefined,
                            })(<Input readOnly />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label="Ação" {...formItemLayout}>
                            {getFieldDecorator("webaccao", {
                                // rules: [
                                // 	{
                                // 		required: true,
                                // 		message: "Campo obrigatório",
                                // 	},
                                // ],
                                initialValue:
                                    modelForm && typeof modelForm.webaccao !== "object"
                                        ? modelForm.webaccao
                                        : undefined,
                            })(<Input readOnly />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label="Tipo" {...formItemLayout}>
                            {getFieldDecorator("webtipo", {
                                // rules: [
                                // 	{
                                // 		required: true,
                                // 		message: "Campo obrigatório",
                                // 	},
                                // ],
                                initialValue:
                                    modelForm && typeof modelForm.webtipo !== "object" ? modelForm.webtipo : undefined,
                            })(<Input readOnly />)}
                        </Form.Item>
                    </Col>
                    {isEdit && (
                        <Col xs={24}>
                            <Row>
                                <Button
                                    type="primary"
                                    icon="unlock"
                                    style={{ margin: 2 }}
                                    onClick={() => {
                                        if (showPermissions) setShowPermissions(false);
                                        else setShowPermissions(true);
                                    }}
                                >
                                    Permissões
                                    {showPermissions ? <Icon type="up" /> : <Icon type="down" />}
                                </Button>
                            </Row>
                            {showPermissions && (
                                <Row style={{ top: 5 }}>
                                    <PermissionsTransfer
                                        webtipo={modelForm.webtipo}
                                        webaccao={modelForm.webaccao}
                                        idmenu={modelForm.idmenu}
                                        onCloseEditor={onCloseEditor}
                                        leftDataSource={permissions}
                                        rightDataSource={menuPermissions}
                                        setReload={setReload}
                                        reload={reload}
                                    />
                                </Row>
                            )}
                        </Col>
                    )}
                </Row>
            </Form>
        </Modal>
    );
};

const mapDispatchToProps = {
    getPermissions,
    getPermissionsByIdMenu,
};

const mapStateToProps = (state, ownProps) => {
    return {
        listOfpermissions: state.home.domain.permissions || [],
    };
};

const WrappedMenuEditor = Form.create({ name: "menu_editor" })(MenuEditor);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedMenuEditor);
