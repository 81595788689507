import React, { useState, useEffect } from "react";
import { Form, Tabs, Row, Col, Icon, Button, message, Spin } from "antd";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// import {
//     select,
//     insert,
//     insertFunctionTasks,
//     clearForm,
//     selectDataFromExecQuery
// } from "../../actions/funcional.group";
import { selectDataFromExecQuery, insertFunctionTasks, insert } from "../../actions/funcional.group";
import Description from "./description";
import Profile from "./profile";
import Tasks from "./tasks";
import Contributors from "./contributors";
import { Utilitaries } from "../../../../business";

const { TabPane } = Tabs;
const EXEC_QUERY_NAME = {
    busca_grupo_exist: "busca_grupo_exist",
    todas_autorizacoes: "todas_autorizacoes",
    requisitos_nec_grupos_func: "requisitos_nec_grupos_func",
    competencias_nec_grupos_func: "competencias_nec_grupos_func",
    conhecimentos_nec_grupos_func: "conhecimentos_nec_grupos_func",
    habilidades_nec_grupos_func: "habilidades_nec_grupos_func",
    parametros_comp_grupos_func: "parametros_comp_grupos_func",
    tarefas_funcao: "tarefas_funcao",
    tarefas_nao_geridas_ffuncao: "tarefas_nao_geridas_ffuncao",
};

const FunctionalGroup = ({ form, selectDataFromExecQuery, insertFunctionTasks, insert, goBack }) => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [loadingOnFetching, setLoadingOnFetching] = useState(false);

    const [selectedGrupoExist, setSelectedGroupExist] = useState(undefined);
    const [dataFromExistGroup, setDataFromExistGroup] = useState({});
    const [descricaoFuncao, setDescricaoFuncao] = useState(undefined);
    const [listOfAutorizationIds, setListOfAutorizationIds] = useState(undefined);
    const [autorizations, setAutorizations] = useState([]);

    const [requisito_necessario, set_requisito_necessario] = useState([]);
    const [conhece_necessarios, set_conhece_necessarios] = useState([]);
    const [competencia_necessaria, set_competencia_necessaria] = useState([]);
    const [habilidade_necessaria, set_habilidade_necessaria] = useState([]);
    const [parametro_comportamental, set_parametro_comportamental] = useState([]);

    const [ficha_funcao_tarefas, set_ficha_funcao_tarefas] = useState({});

    /**
     * fetch from exist group data
     */
    useEffect(() => {
        if (selectedGrupoExist || id) {
            (async () => {
                setLoadingOnFetching(true);
                const response = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.busca_grupo_exist,
                    sel_grupo_exist: selectedGrupoExist || id,
                });

                if (response.result) {
                    if (Array.isArray(response.result)) {
                        setDataFromExistGroup(response.result[0]);
                        setDescricaoFuncao(response.result[0].descricao_funcao);
                    }
                }

                /**
                 * fetch for profiles data
                 */
                const requirements = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.requisitos_nec_grupos_func,
                    id_ficha_funcao: id,
                    sel_grupo_exist: selectedGrupoExist,
                });
                if (requirements.result) {
                    if (Array.isArray(requirements.result)) {
                        const data = [];
                        requirements.result.forEach((item, index) => {
                            data.push({ __key__: index, ...item });
                        });
                        set_requisito_necessario(data);
                    }
                }

                const competences = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.competencias_nec_grupos_func,
                    id_ficha_funcao: id,
                    sel_grupo_exist: selectedGrupoExist,
                });
                if (competences.result) {
                    if (Array.isArray(competences.result)) {
                        const data = [];
                        competences.result.forEach((item, index) => {
                            data.push({ __key__: index, ...item });
                        });
                        set_competencia_necessaria(data);
                    }
                }

                const knowledges = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.conhecimentos_nec_grupos_func,
                    id_ficha_funcao: id,
                    sel_grupo_exist: selectedGrupoExist,
                });
                if (knowledges.result) {
                    if (Array.isArray(knowledges.result)) {
                        const data = [];
                        knowledges.result.forEach((item, index) => {
                            data.push({ __key__: index, ...item });
                        });
                        set_conhece_necessarios(data);
                    }
                }

                const habilitys = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.habilidades_nec_grupos_func,
                    id_ficha_funcao: id,
                    sel_grupo_exist: selectedGrupoExist,
                });
                if (habilitys.result) {
                    if (Array.isArray(habilitys.result)) {
                        const data = [];
                        habilitys.result.forEach((item, index) => {
                            data.push({ __key__: index, ...item });
                        });
                        set_habilidade_necessaria(data);
                    }
                }

                const behaviors = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.parametros_comp_grupos_func,
                    id_ficha_funcao: id,
                    sel_grupo_exist: selectedGrupoExist,
                });
                if (behaviors.result) {
                    if (Array.isArray(behaviors.result)) {
                        const data = [];
                        behaviors.result.forEach((item, index) => {
                            data.push({ __key__: index, ...item });
                        });
                        set_parametro_comportamental(data);
                    }
                }

                /**
                 * fetch for tasks data
                 */

                let params = {};
                // const { ficha_funcao_tarefas } = dataFromExistGroup;
                if (response.result[0].ficha_funcao_tarefas) {
                    params = {
                        ...params,
                        id_ficha_funcao_tarefas: response.result[0].ficha_funcao_tarefas,
                    };
                }
                if (selectedGrupoExist) {
                    params = {
                        ...params,
                        grupo_exist: selectedGrupoExist,
                    };
                }
                if (Object.keys(params).length) {
                    const tasksNotSI = await selectDataFromExecQuery({
                        query: EXEC_QUERY_NAME.tarefas_nao_geridas_ffuncao,
                        ...params,
                    });
                    if (tasksNotSI.result) {
                        if (Array.isArray(tasksNotSI.result)) {
                            const tasksNaoSi = tasksNotSI.result.map((item, index) => {
                                return { __key__: index, ...item };
                            });
                            set_ficha_funcao_tarefas({ ...ficha_funcao_tarefas, tarefa_nao_si: tasksNaoSi });
                        }
                    }
                }

                setLoadingOnFetching(false);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGrupoExist]);

    useEffect(() => {
        let params = {};
        if (dataFromExistGroup.ficha_funcao_tarefas) {
            params = {
                ...params,
                id_ficha_funcao_tarefas: dataFromExistGroup.ficha_funcao_tarefas,
            };
        }
        if (selectedGrupoExist) {
            params = {
                ...params,
                grupo_exist: selectedGrupoExist,
            };
        }
        if (listOfAutorizationIds) {
            params = {
                ...params,
                lista_nova: listOfAutorizationIds,
            };
        }

        if (listOfAutorizationIds) {
            if (Object.keys(params).length) {
                (async () => {
                    // setLoading(true);
                    const tarefasSi = await selectDataFromExecQuery({
                        query: EXEC_QUERY_NAME.tarefas_funcao,
                        ...params,
                    });
                    if (tarefasSi.result) {
                        if (Array.isArray(tarefasSi.result)) {
                            const tasksSi = tarefasSi.result.map((item, index) => {
                                return { key: index, ...item };
                            });
                            set_ficha_funcao_tarefas({ ...ficha_funcao_tarefas, tarefa_si: tasksSi });
                        }
                    }
                    // setLoading(false);
                })();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOfAutorizationIds, selectedGrupoExist, dataFromExistGroup]);

    /**
     * fetch all autorizations
     */
    useEffect(() => {
        (async () => {
            const response = await selectDataFromExecQuery({
                query: EXEC_QUERY_NAME.todas_autorizacoes,
            });
            if (response.result) {
                if (Array.isArray(response.result)) {
                    setAutorizations(response.result);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Save "grupo funcional"
     */
    const save = () => {
        form.validateFields(async (error, values) => {
            if (!error) {
                const { autorizacao_func } = values;
                delete values.autorizacao_func;

                let data = {
                    ...values,
                    linha: {
                        autorizacao_func: Array.isArray(autorizacao_func)
                            ? autorizacao_func.map((item) => {
                                  return { autorizacao: item };
                              })
                            : [],
                        requisito_necessario,
                        conhece_necessarios,
                        competencia_necessaria,
                        habilidade_necessaria,
                        parametro_comportamental,
                    },
                };

                if (id !== undefined) {
                    data = {
                        ...data,
                        id_ficha_funcao: id,
                    };
                }

                setLoading(true);
                const funcao_tarefas = await saveTasks(ficha_funcao_tarefas, values.descricao_funcao);

                if (!funcao_tarefas) {
                    message.error("Ocorreu um erro");
                    setLoading(false);
                    return;
                }

                const ficha_funcao = {
                    ...data,
                    ficha_funcao_tarefas: funcao_tarefas,
                };

                const response = await insert({
                    accao: "guardar",
                    formulario: "ficha_funcao.qml.xml",
                    dados: JSON.stringify({ ficha_funcao }),
                });

                setLoading(false);
                if (response) {
                    message.success("Sucesso");
                    goBack();
                } else message.error("Ocorreu um erro");
            }
        });
    };

    /**
     * save "tarefas"
     */
    const saveTasks = async (ficha_funcao_tarefas, descricao_funcao) => {
        const { tarefa_si, tarefa_nao_si } = ficha_funcao_tarefas;

        const new_tarefa_si = [];
        const new_tarefa_nao_si = [];

        if (tarefa_nao_si && Array.isArray(tarefa_nao_si)) {
            tarefa_nao_si.forEach((element) => {
                const { grupo, grupo_substituto } = element;
                new_tarefa_nao_si.push({
                    ...element,
                    grupo: Utilitaries.toString(grupo, ","), //Utilitaries.toString(grupo, ","), //; grupo && grupo.length ? grupo.join(",") : null,
                    grupo_substituto: Utilitaries.toString(grupo_substituto, ","), // grupo_substituto && grupo_substituto.length ? grupo_substituto.join(",") : null,
                });
            });
        }

        if (tarefa_si && Array.isArray(tarefa_si)) {
            tarefa_si.forEach((element) => {
                const { grupo_substituto_si } = element;
                new_tarefa_si.push({
                    ...element,
                    grupo_substituto_si: Utilitaries.toString(grupo_substituto_si, ","), //grupo_substituto_si && grupo_substituto_si.length ? grupo_substituto_si.join(",") : null,
                });
            });
        }

        const data = {
            ficha_funcao_tarefas: {
                descricao_funcao,
                linha: {
                    tarefa_si: new_tarefa_si,
                    tarefa_nao_si: new_tarefa_nao_si,
                },
            },
        };

        const response = await insertFunctionTasks({
            accao: "guardar",
            formulario: "ficha_funcao_tarefas.qml.xml",
            dados: JSON.stringify(data),
        });

        return response;
    };

    return (
        <Form layout="vertical">
            {loadingOnFetching ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin />
                </div>
            ) : (
                <>
                    <Tabs>
                        <TabPane tab="Descrição" key="1">
                            <Description
                                form={form}
                                dataFromExistGroup={dataFromExistGroup}
                                selectedGrupoExist={selectedGrupoExist}
                                setSelectedGroupExist={setSelectedGroupExist}
                                setDescricaoFuncao={setDescricaoFuncao}
                                setListOfAutorizationIds={setListOfAutorizationIds}
                                autorizations={autorizations}
                            />
                        </TabPane>

                        <TabPane tab="Perfil" key="2">
                            <Profile
                                form={form}
                                dataFromExistGroup={dataFromExistGroup}
                                selectedGrupoExist={selectedGrupoExist}
                                set_requisito_necessario={set_requisito_necessario}
                                requisito_necessario={requisito_necessario}
                                set_conhece_necessarios={set_conhece_necessarios}
                                conhece_necessarios={conhece_necessarios}
                                set_competencia_necessaria={set_competencia_necessaria}
                                competencia_necessaria={competencia_necessaria}
                                set_habilidade_necessaria={set_habilidade_necessaria}
                                habilidade_necessaria={habilidade_necessaria}
                                set_parametro_comportamental={set_parametro_comportamental}
                                parametro_comportamental={parametro_comportamental}
                            />
                        </TabPane>

                        <TabPane tab="Tarefas" key="3">
                            <Tasks
                                form={form}
                                descricaoFuncao={descricaoFuncao}
                                selectedGrupoExist={selectedGrupoExist}
                                dataFromExistGroup={dataFromExistGroup}
                                listOfAutorizationIds={listOfAutorizationIds}
                                autorizations={autorizations}
                                set_ficha_funcao_tarefas={set_ficha_funcao_tarefas}
                                ficha_funcao_tarefas={ficha_funcao_tarefas}
                            />
                        </TabPane>

                        <TabPane tab="Funcionários" key="4">
                            <Contributors dataFromExistGroup={dataFromExistGroup} />
                        </TabPane>
                    </Tabs>

                    <Row gutter={18} style={{ marginTop: 16 }}>
                        <Col xs={24} sm={12}>
                            <div className="qa-group-actions-left">
                                <Button
                                    // loading={this.state.loading}
                                    type="primary"
                                    onClick={save}
                                    icon="save"
                                    loading={loading}
                                >
                                    Guardar
                                </Button>
                                {/* <Button type="primary">
							<Icon type="printer" />
							Imprimir
						</Button>
						<Button type="primary">
							<Icon type="save" />
							Guardar Temporário
						</Button> */}
                            </div>
                        </Col>

                        <Col xs={24} sm={12}>
                            {/* <div className="qa-group-actions-right">
						<Button icon="undo" type="primary" htmlType="submit">
							Reformular
						</Button>
						<Button
							icon="cloud-upload"
							type="primary"
							htmlType="submit"
						>
							Carregar Local
						</Button>
						<Button
							icon="play-circle"
							type="primary"
							htmlType="submit"
						>
							Gravar Local
						</Button>
					</div> */}
                        </Col>
                    </Row>
                </>
            )}
        </Form>
    );
};

const WrappedFunctionalGroup = Form.create({ name: "functional_g" })(FunctionalGroup);

const mapDispatchToProps = {
    selectDataFromExecQuery,
    insertFunctionTasks,
    insert,
};

export default connect(null, mapDispatchToProps)(WrappedFunctionalGroup);
