import React, { Component } from "react";
import { Modal, Form, Row, Col, Input, DatePicker, message, Button } from "antd";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/time.registration";
import { tableActions } from "./../../constants/time.registration";
import locale from "antd/lib/date-picker/locale/pt_PT";
import moment from "moment";
const { TextArea } = Input;
const formatTime = "HH:mm";
const formatDateTime = "DD-MM-YYYY HH:mm";
const formatRegisterDate = "DD-MM-YYYY HH:mm";

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

class EditTimeRegistration extends Component {
    constructor(props) {
        super(props);
        let title = null;
        let disableDataEntrada = true;
        let disableDataSaida = true;
        let disableObservacoes = true;
        if (this.props.tableAction === tableActions.detalhes) {
            title = "Detalhes do registo de ponto";
            // disableDataEntrada
        } else if (this.props.tableAction === tableActions.editar) {
            title = "Editar registo de ponto";
            disableDataEntrada = false;
            disableObservacoes = false;
            disableDataSaida = false;
        } else {
            title = "Marcar hora de saída";
            disableDataSaida = false;
            disableObservacoes = false;
        }

        this.state = {
            title,
            disableDataEntrada,
            disableObservacoes,
            disableDataSaida,
        };
    }

    disabledDataEntrada = (startTime) => {
        const exitDate = this.props.timeEdit.hora_saida.value;
        if (exitDate) {
            return startTime && (startTime > moment() || startTime.isAfter(exitDate, "minute"));
        }
        return startTime && startTime > moment();
    };

    disabledHoraEntrada = () => {
        const startDate = this.props.timeEdit.hora_entrada.value;
        const exitDate = this.props.timeEdit.hora_saida.value;
        const dataAtual = moment();

        if (startDate.isSame(exitDate, "day")) {
            return {
                disabledHours: () => range(exitDate.hour() + 1, 24),
                disabledMinutes: () => {
                    return startDate.hour() === exitDate.hour() ? range(exitDate.minute(), 60) : [];
                },
            };
        } else if (startDate.isSame(dataAtual, "day")) {
            return {
                disabledHours: () => range(dataAtual.hour() + 1, 24),
                disabledMinutes: () => {
                    return startDate.hour() === dataAtual.hour() ? range(dataAtual.minute() + 1, 60) : [];
                },
            };
        }
    };

    disabledDataSaida = (current) => {
        return (
            current &&
            (current.isAfter(moment(), "day") || current.isBefore(this.props.timeEdit.hora_entrada.value, "day"))
        );
    };

    disabledExitTime = () => {
        const startDate = this.props.timeEdit.hora_entrada.value;

        const exitDate = this.props.timeEdit.hora_saida.value;
        const dataAtual = moment();
        let disablehours = [];
        let disableMinutes = [];

        if (dataAtual?.isSame(exitDate, "day")) {
            disablehours =
                exitDate.minute() > dataAtual.minute() ? range(dataAtual.hour(), 24) : range(dataAtual.hour() + 1, 24);

            if (dataAtual.hour() === exitDate.hour()) disableMinutes = range(dataAtual.minute() + 1, 60);
        }

        if (startDate?.isSame(exitDate, "day")) {
            const limitHours = exitDate.minute() >= startDate.minute() ? startDate.hour() : startDate.hour() + 1;

            disablehours = [...range(0, limitHours), ...disablehours];

            if (startDate.hour() === exitDate.hour()) disableMinutes = range(0, startDate.minute());
        }

        return {
            disabledHours: () => disablehours,
            disabledMinutes: () => disableMinutes,
        };
    };

    changeExitTime = (e) => {
        const startDate = this.props.timeEdit.hora_entrada.value;
        const dataAtual = moment();
        if (e.isSame(dataAtual, "day") && e.isSameOrAfter(dataAtual, "minute")) {
            e = e.hour(dataAtual.hour()).minute(dataAtual.minute());
            if (this.props.tableAction === tableActions.marcarSaida) {
                const field = {
                    system_data_saida: {
                        name: "system_data_saida",
                        value: dataAtual,
                    },
                };
                this.props.setField({
                    fields: field,
                    id: this.props.timeEdit.id_registo_ponto,
                });
            }
        } else if (e.isSame(startDate, "day") && e.isBefore(startDate)) {
            e = e.hour(23).minute(59);
        }
    };

    handleResponse = (response) => {
        this.setState({ loading: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleOk();
        } else message.error("Ocorreu um erro");
    };

    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                values.id_registo_ponto = this.props.timeEdit.id_registo_ponto;
                values.username = this.props.timeEdit.username.value;
                this.props
                    .update({
                        formulario: this.props.formulario,
                        values,
                        tableAction: this.props.tableAction,
                    })
                    .then((response) => {
                        this.handleResponse(response);
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title={this.state.title}
                visible={this.props.isVisible}
                onOk={this.handleOk}
                onCancel={this.props.hiddelModal}
                width="50%"
                footer={
                    this.props.tableAction === tableActions.detalhes
                        ? null
                        : [
                              [
                                  <Button key="back" onClick={this.props.hiddelModal}>
                                      Cancelar
                                  </Button>,
                                  <Button
                                      key="submit"
                                      type="primary"
                                      loading={this.state.loading}
                                      onClick={this.handleOk}
                                  >
                                      Ok
                                  </Button>,
                              ],
                          ]
                }
            >
                <Form layout="vertical" onSubmit={this.handleOk}>
                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Data / hora do registo de entrada">
                                {getFieldDecorator("system_data_entrada")(
                                    <DatePicker format={formatRegisterDate} disabled style={{ width: "100%" }} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Data / hora entrada">
                                {getFieldDecorator("hora_entrada", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        showTime={{ format: formatTime }}
                                        ref={(node) => (this.input = node)}
                                        locale={locale}
                                        format={formatDateTime}
                                        disabledDate={this.disabledDataEntrada}
                                        disabledTime={this.disabledHoraEntrada}
                                        placeholder="Selecione a data"
                                        disabled={this.state.disableDataEntrada}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={18}>
                        {!this.props.timeEdit.system_data_entrada.value.isSame(
                            this.props.timeEdit.hora_entrada.value
                        ) && (
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item label="Motivo de alteração da data / hora de entrada">
                                    {getFieldDecorator("motivo_alteracao_data_entrada", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(
                                        <TextArea
                                            autoSize={{
                                                minRows: 1,
                                                maxRows: 6,
                                            }}
                                            disabled={this.state.disableDataEntrada}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        )}
                    </Row>

                    {this.props.timeEdit.hora_saida.value !== null && (
                        <Row gutter={18}>
                            <Col xs={24} sm={24} md={8}>
                                <Form.Item label="Data / hora de registo saída">
                                    {getFieldDecorator("system_data_saida")(
                                        <DatePicker format={formatRegisterDate} disabled style={{ width: "100%" }} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                                <Form.Item label="Data / hora saída">
                                    {getFieldDecorator("hora_saida", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(
                                        <DatePicker
                                            showTime={{ format: formatTime }}
                                            ref={(node) => (this.input = node)}
                                            locale={locale}
                                            format={formatDateTime}
                                            onChange={this.changeExitTime}
                                            disabledDate={this.disabledDataSaida}
                                            disabledTime={this.disabledExitTime}
                                            placeholder="Selecione a data"
                                            disabled={this.state.disableDataSaida}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={18}>
                        {this.props.timeEdit.system_data_saida.value &&
                            !this.props.timeEdit.system_data_saida.value.isSame(
                                this.props.timeEdit.hora_saida.value,
                                "minute"
                            ) && (
                                <Col xs={24} sm={24} md={24}>
                                    <Form.Item label="Motivo de alteração da hora de saída">
                                        {getFieldDecorator("motivo_alteracao_data_saida", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(
                                            <TextArea
                                                autoSize={{
                                                    minRows: 1,
                                                    maxRows: 6,
                                                }}
                                                disabled={this.state.disableDataSaida}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            )}
                    </Row>

                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Observações">
                                {getFieldDecorator(
                                    "observacoes",
                                    {}
                                )(
                                    <TextArea
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        disabled={this.state.disableObservacoes}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    setField: actionCreators.setField,
    update: actionCreators.update,
};

const mapStateToProps = (state) => {
    return {
        timeEdit: state.timeRegistration.domain.formEdit,
        formulario: state.timeRegistration.domain.metadata.formulario,
    };
};

const formEdit = Form.create({
    mapPropsToFields(props) {
        return {
            system_data_entrada: Form.createFormField({
                ...props.timeEdit.system_data_entrada,
                value: props.timeEdit.system_data_entrada.value,
            }),
            hora_entrada: Form.createFormField({
                ...props.timeEdit.hora_entrada,
                value: props.timeEdit.hora_entrada.value,
            }),
            system_data_saida: Form.createFormField({
                ...props.timeEdit.system_data_saida,
                value: props.timeEdit.system_data_saida.value,
            }),
            hora_saida: Form.createFormField({
                ...props.timeEdit.hora_saida,
                value: props.timeEdit.hora_saida.value,
            }),
            motivo_alteracao_data_entrada: Form.createFormField({
                ...props.timeEdit.motivo_alteracao_data_entrada,
                value: props.timeEdit.motivo_alteracao_data_entrada.value,
            }),
            motivo_alteracao_data_saida: Form.createFormField({
                ...props.timeEdit.motivo_alteracao_data_saida,
                value: props.timeEdit.motivo_alteracao_data_saida.value,
            }),
            observacoes: Form.createFormField({
                ...props.timeEdit.observacoes,
                value: props.timeEdit.observacoes.value,
            }),
        };
    },
    onFieldsChange(props, fields) {
        props.setField({ fields, id: props.timeEdit.id_registo_ponto });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(formEdit(EditTimeRegistration));
