import React, { useEffect, useRef } from "react";
import { Modal } from "antd";
import { NumberButtom } from "./NumberButton";

import "./style.css";

interface IProps {
    isCalculatorVisible: boolean;
    setCalculatorVisible: (isCalculatorVisible: boolean) => void;
}

const initialState = {
    real: "",
    display: "",
};

export function Calulator({ isCalculatorVisible, setCalculatorVisible }: IProps) {
    const [expression, setExpression] = React.useState(initialState);
    const [error, setError] = React.useState<string | null>(null);
    const displayRef = useRef<HTMLTextAreaElement>() as React.MutableRefObject<HTMLTextAreaElement>;

    useEffect(() => {
        if (displayRef.current) {
            displayRef.current.style.height = "auto";
            displayRef.current.style.height = `${displayRef.current.scrollHeight}px`;
        }
    }, [displayRef, expression]);

    useEffect(() => {
        if (isCalculatorVisible && displayRef.current) {
            displayRef.current.focus();
        }
    }, [displayRef, isCalculatorVisible]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    });

    const handleKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                submitInput();
                break;
            case "Backspace":
                delExpression();
                break;
            case "Delete":
                resetExpression();
                break;
            default:
                break;
        }
    };

    const handleCancel = () => {
        setCalculatorVisible(false);
    };

    const addExpression = (label: string, real: number | string) => {
        setExpression((prev) => ({
            real: prev.real.concat(String(real)),
            display: prev.display.concat(label),
        }));
    };

    const resetExpression = () => {
        setExpression(initialState);
    };

    const delExpression = () => {
        setExpression((prev) => ({
            real: prev.real.substring(0, prev.real.length - 1),
            display: prev.display.substring(0, prev.display.length - 1),
        }));
    };

    const submitInput = () => {
        setExpression((prev) => {
            try {
                console.log(prev.real);

                const submit = String(eval(prev.real));
                setError(null);
                return {
                    real: submit,
                    display: submit.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                };
            } catch (error) {
                setError("Expressão inválida");
                console.error(error);
                return prev;
            }
        });
    };

    const forceChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value.replace(/\n/g, "");
        setExpression({
            real: value,
            display: value,
        });
    };

    const KEYS = [
        // { label: "√", real: 7, onClick: addExpression },
        // { label: "⅟", real: 7, onClick: addExpression },
        // { label: "%", real: 7, onClick: addExpression },
        // { label: "", real: 7, onClick: addExpression },
        { label: "7", real: 7, onClick: addExpression },
        { label: "8", real: 8, onClick: addExpression },
        { label: "9", real: 9, onClick: addExpression },
        { label: "del", onClick: delExpression, classes: ["special", "reset"] },
        { label: "4", real: 4, onClick: addExpression },
        { label: "5", real: 5, onClick: addExpression },
        { label: "6", real: 6, onClick: addExpression },
        { label: "+", real: "+", onClick: addExpression, classes: ["special"] },
        { label: "1", real: 1, onClick: addExpression },
        { label: "2", real: 2, onClick: addExpression },
        { label: "3", real: 3, onClick: addExpression },
        { label: "-", real: "-", onClick: addExpression, classes: ["special"] },
        { label: "0", real: 0, onClick: addExpression },
        { label: ".", real: ".", onClick: addExpression },
        { label: "/", real: "/", onClick: addExpression, classes: ["special"] },
        { label: "x", real: "*", onClick: addExpression, classes: ["special"] },
        {
            label: "reset",
            onClick: resetExpression,
            classes: ["bottom-buttons", "reset"],
        },
        { label: "=", onClick: submitInput, classes: ["bottom-buttons", "submit"] },
    ];

    return (
        <Modal
            title="Calculadora"
            visible={isCalculatorVisible}
            width="370px"
            footer={null}
            onCancel={handleCancel}
            bodyStyle={{
                paddingTop: 0,
            }}
        >
            {error && <div className="error">{error}</div>}
            <div className="display">
                <textarea value={expression.display} ref={displayRef} rows={1} onChange={forceChange} />
            </div>
            <div className="key_pad">
                {KEYS.map((key, i) => (
                    <NumberButtom
                        label={key.label}
                        onClick={key.onClick}
                        real={key.real}
                        classes={key.classes}
                        key={i}
                    />
                ))}
            </div>
        </Modal>
    );
}
