import { actionType } from "./../constants/contrubitor.time.registration";
import { arrayToObjectById, validateResponse } from "./../../../components/util";

const initialState = {
    domain: {
        byId: {},
        metaData: {
            attributes: {},
        },
    },
    app: {
        isFetched: false,
    },
};

export const contributorTimeRegistrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.selectContributors:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: validateResponse(action.payload),
                        id: "id",
                    }),
                    metaData: {
                        ...state.domain.metaData,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };
        case actionType.timeRegistrationByContributor:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [action.contributorId]: {
                            ...state.domain.byId[action.contributorId],
                            pointRegister: {
                                attributes: action.payload["@attributes"],
                                data: validateResponse(action.payload),
                            },
                        },
                    },
                },
            };
        default:
            return state;
    }
};
