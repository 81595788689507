import { actionType } from "./../constants/holiday";
import { arrayToObjectById } from "./../../../components/util";
import moment from "moment";
const formatDate = "DD-MM-YYYY";

function resetFormCreate() {
    return {
        year: { name: "year", value: null },
        descricao: { name: "descricao", value: null },
        holidays: [],
    };
}
function resetFormRevertVersion() {
    return {
        year: { name: "year", value: null },
        descricao: { name: "descricao", value: null },
        holidays: [],
    };
}

function newDay() {
    return {
        data: null,
        designacao: null,
        natureza: null,
        estabelecimentos: [],
        initialEstabelecimentos: [],
    };
}

const findIndexFeriado = (list, obj) => {
    return list.findIndex((feriado) => feriado.designacao === obj.designacao && feriado.data === obj.data);
};

function resetFormEdit() {
    return {
        id: null,
        year: { name: "year", value: null },
        descricao: { name: "descricao", value: null },
        data: { name: "data", value: null },
        holidays: [],
        motivo: { name: "motivo", value: null },
    };
}

function mapResponseToForm(payload) {
    const values = payload.valores.config_feriado;
    const valuesFeriados = values.linhas_feriados["linha_feriado"];

    let list = [];
    let element = 0;
    let indexElement = 0;
    if (Array.isArray(valuesFeriados)) {
        for (indexElement = 0; indexElement < valuesFeriados.length; indexElement++) {
            const estab =
                typeof valuesFeriados[indexElement].estabs === "object" ? null : valuesFeriados[indexElement].estabs;
            const estabs = estab ? [estab] : [];
            element = findIndexFeriado(list, valuesFeriados[indexElement]);
            if (element >= 0) {
                list[element] = {
                    ...list[element],
                    estabelecimentos: [...list[element].estabelecimentos, ...estabs],
                    initialEstabelecimentos: [
                        ...list[element].initialEstabelecimentos,
                        {
                            id_linha_feriado: valuesFeriados[indexElement].id_linha_feriado,
                            id_estabelecimento: estab,
                        },
                    ],
                };
            } else {
                list.push({
                    data: moment(valuesFeriados[indexElement].data),
                    designacao: valuesFeriados[indexElement].designacao,
                    natureza:
                        typeof valuesFeriados[indexElement].id_natureza === "object"
                            ? null
                            : valuesFeriados[indexElement].id_natureza,
                    estabelecimentos: [estab],
                    initialEstabelecimentos: [
                        {
                            id_linha_feriado: valuesFeriados[indexElement].id_linha_feriado,
                            id_estabelecimento: estab,
                        },
                    ],
                });
            }
        }
    } else if (valuesFeriados) {
        list = [
            {
                data: moment(valuesFeriados.data),
                designacao: valuesFeriados.designacao,
                natureza: typeof valuesFeriados.id_natureza === "object" ? null : valuesFeriados.id_natureza,
                estabelecimentos: [valuesFeriados.estabs],
                initialEstabelecimentos: [
                    {
                        id_linha_feriado: valuesFeriados.id_linha_feriado,
                        id_estabelecimento: valuesFeriados.estabs,
                    },
                ],
            },
        ];
    }

    const length = list.length;
    list.sort(function (a, b) {
        if (moment(a.data).isBefore(b.data)) {
            return -1;
        } else if (moment(a.data).isAfter(b.data)) {
            return 1;
        } else return 0;
    });
    list = list.map((item, index) => ({ key: length - index, ...item, data: item.data.format("YYYY-MM-DD") }));

    return list;
}

const initialState = {
    domain: {
        formCreate: resetFormCreate(),
        formEdit: resetFormEdit(),
        formRevertToVersion: resetFormRevertVersion(),
        byId: {},
        metadata: {
            attributes: {},
            formulario: {},
            pageConfig: {},
        },
    },
    app: {},
};

export const holidayReducer = (state = initialState, action) => {
    let listHolidays = [];
    switch (action.type) {
        case actionType.select:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: action.payload.dados["linha"],
                        id: action.payload["@attributes"].chaves,
                    }),
                    metadata: {
                        ...state.domain.metadata,
                        attributes: {
                            ...action.payload.dados["@attributes"],
                        },
                        pageConfig: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };

        case actionType.getFormulario:
            const formCreate = action.payload.formulario["@attributes"];
            return {
                ...state,
                domain: {
                    ...state.domain,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formCreate.nome,
                            elemento_raiz: formCreate.elemento_raiz,
                        },
                    },
                },
            };

        case actionType.addNewDay:
            listHolidays = action.payload.isEdit
                ? [...state.domain.formEdit.holidays]
                : [...state.domain.formCreate.holidays];
            const length = listHolidays.length;
            const key = length === 0 ? 1 : listHolidays[0].key + 1;
            if (action.payload.isEdit) {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            holidays: [{ key, ...newDay() }, ...state.domain.formEdit.holidays],
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            holidays: [{ key, ...newDay() }, ...state.domain.formCreate.holidays],
                        },
                    },
                };
            }

        case actionType.setField:
            if (action.payload.isEdit) {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            ...action.payload.fields,
                        },
                    },
                };
            } else
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            ...action.payload,
                        },
                    },
                };

        case actionType.setFieldList:
            listHolidays = action.payload.isEdit
                ? [...state.domain.formEdit.holidays]
                : [...state.domain.formCreate.holidays];
            const index = listHolidays.findIndex((item) => action.payload.obj.key === item.key);
            listHolidays[index] = action.payload.obj;

            if (action.payload.isEdit) {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            holidays: listHolidays,
                        },
                    },
                };
            } else
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            holidays: listHolidays,
                        },
                    },
                };
        case actionType.removeDayFromHoliday:
            if (action.payload.isEdit) {
                listHolidays = [...state.domain.formEdit.holidays];
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            holidays: listHolidays.filter((item) => item.key !== action.payload.key),
                        },
                    },
                };
            } else {
                listHolidays = [...state.domain.formCreate.holidays];
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            holidays: listHolidays.filter((item) => item.key !== action.payload.key),
                        },
                    },
                };
            }

        case actionType.importHoliday:
            const importedHolidays = action.payload.importedHolidays;
            const listImportHoliday = mapResponseToForm(importedHolidays);
            let formHolidays = [];

            listImportHoliday.forEach((item, index) => {
                if (
                    item.data !== `29-02-${importedHolidays.ano}` ||
                    moment(`29-02-${action.payload.ano}`, formatDate).isValid()
                ) {
                    formHolidays[index] = {
                        ...item,
                        data: moment(item.data).year(action.payload.ano).format("YYYY-MM-DD"),
                    };
                }
            });
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formCreate: {
                        ...state.domain.formCreate,
                        holidays: formHolidays,
                    },
                },
            };

        case actionType.insert:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formCreate: resetFormCreate(),
                },
            };

        case actionType.getDetails:
            const formulario = action.payload.formulario["@attributes"];
            const values = action.payload.valores.config_feriado;

            let formDataDetails = {
                id: values.id_config_feriado,
                year: { name: "year", value: values.ano },
                data: {
                    name: "data",
                    value: values.data,
                },
                descricao: {
                    name: "descricao",
                    value: typeof values.descricao === "object" ? "" : values.descricao,
                },
                motivo: { name: "motivo", value: typeof values.motivo === "object" ? "" : values.motivo },
            };

            formDataDetails.holidays = mapResponseToForm(action.payload);

            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: formDataDetails,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            ...formulario,
                        },
                    },
                },
            };

        case actionType.update:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: resetFormEdit(),
                },
            };

        case actionType.getDetailsVersion:
            const formularioHoliday = {};
            const attributesForm = Object.entries(action.payload.result.formulario);

            for (const [key, value] of attributesForm) {
                if (key.startsWith("@")) formularioHoliday[key.substring(1)] = value;
            }

            const valuesVersion = action.payload.result.valores.config_feriado;

            let formDataVersion = {
                id: valuesVersion.id_config_feriado,
                year: {
                    name: "year",
                    value: valuesVersion.ano,
                },
                data: {
                    name: "data",
                    value: valuesVersion.data,
                },
                descricao: {
                    name: "descricao",
                    value: valuesVersion.descricao,
                },
            };
            formDataVersion.holidays = mapResponseToForm(action.payload.result);

            return {
                ...state,
                domain: {
                    ...state.domain,
                    formRevertToVersion: formDataVersion,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            ...formularioHoliday,
                        },
                    },
                },
            };

        default:
            return state;
    }
};
