import React, { useState, useEffect, useRef } from "react";

const EditInPlace = ({ value, onChangeValue, readOnly }) => {
    const [isEditing, setIsEditing] = useState(false);
    let inputRef = useRef(null);
    const edit = () => setIsEditing(true);
    const done = () => {
        onChangeValue(inputRef.current.value);
        setIsEditing(false);
    };

    useEffect(() => {
        if (isEditing) inputRef.current.focus();
    }, [isEditing]);

    if (isEditing)
        return (
            <input
                className={"ant-input ant-input-sm"}
                defaultValue={value}
                ref={inputRef}
                onBlur={done}
                onKeyPress={(event) => event.key === "Enter" && done()}
            />
        );

    return (
        <span onClick={readOnly ? null : edit} className={readOnly ? null : "edit-in-place"}>
            {value}
        </span>
    );
};

export default EditInPlace;
