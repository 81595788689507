import { Button, Table, Row, Col } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import React, { useEffect, useMemo, useState } from "react";
import { COMPONENT_TYPE } from "../../../../utils/FormMapper/XMLInputType";
import { useData } from "../../hooks/useData";
import {
    AddTableRowProps,
    FormAttributesProps,
    FormInputCalcTriggerProps,
    FormInputDependenceStateProps,
    FormInputsObjectProps,
    FormInputsProps,
    FormRootElementAttributesProps,
    RemoveTableRowProps,
    RenderContextType,
} from "../../../../types/formTypes";
import { getColumns } from "./getColumns";
import { Group } from "../../Components/Group";
import { handleOnTableChangeValueProps } from "../../../../types/common";
import { useInputHandle } from "../../hooks/InputHandle";
import { mapLoad } from "../../../../utils/loadMap";

interface Props {
    componentsProperties: FormInputsProps;
    renderContext: RenderContextType;
    formState: FormInputsObjectProps;
    handleTableAddRow: ({ table_key, columns, input_group_path }: AddTableRowProps) => void;
    handleTableRemoveRow: ({ table_key, columns, input_group_path }: RemoveTableRowProps) => void;
    handleOnTableChangeDataSource: (props: handleOnTableChangeValueProps) => void;
    justSee?: boolean;
    formInputDependenceState?: FormInputDependenceStateProps;
    rootElementAttribute: FormRootElementAttributesProps;
    inputCalcTrigger: FormInputCalcTriggerProps;
}
const tablePagination: PaginationConfig = {
    defaultPageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registos`,
};

const getTableColumns = (componentsProperties: FormInputsProps) => {
    const tableFields = [];
    for (const key in componentsProperties) {
        if (
            typeof componentsProperties[key] === "object" &&
            key !== "DATA_SOURCE" &&
            !Array.isArray(componentsProperties[key])
        ) {
            tableFields.push({ ...componentsProperties[key] });
        }
    }
    return tableFields;
};

export function QADataGrid({
    componentsProperties,
    renderContext,
    formState,
    handleTableAddRow,
    handleTableRemoveRow,
    handleOnTableChangeDataSource,
    formInputDependenceState,
    justSee,
    rootElementAttribute,
    inputCalcTrigger,
}: Props) {
    const currentTaskStatus = true;

    const [triggerLoadData, setTriggerLoadData] = useState(0);

    const changeAll = (value: boolean, input: FormInputsProps) => {};

    const data_source = componentsProperties?.DATA_SOURCE || {};

    let table_data_source: never[] = [];
    if (data_source) {
        if (!Array.isArray(data_source)) {
            table_data_source = Object.values(data_source);
        }
    } else {
        table_data_source = [];
    }

    const formInputDependence = formInputDependenceState?.[componentsProperties?.full_path || ""];

    useEffect(() => {
        if (formInputDependence) {
            setTriggerLoadData((prev) => prev + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInputDependence?.trigger]);

    const { data, status } = useData({
        endPoint: "geredatagrid.php",
        componentsProperties,
        state: formState,
        triggerDataGridData: triggerLoadData,
        rootElementAttribute,
    });

    const {
        data: whenValueChangeData,
        status: whenValueChangeStatus,
        // componentValue,
        hasUpdatedValue,
        // required,
        visible,
        relevant,
        // readOnly,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[componentsProperties?.full_path || ""],
        formState,
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
    });

    useEffect(() => {
        if (status === "FETCHED") {
            handleOnTableChangeDataSource({
                dataSource: data,
                key: componentsProperties.key,
                input_group_path: componentsProperties.input_group_path,
                columns: [...getTableColumns(componentsProperties)],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, status]);

    useEffect(() => {
        if (hasUpdatedValue) {
            handleOnTableChangeDataSource({
                dataSource: whenValueChangeData,
                key: componentsProperties.key,
                input_group_path: componentsProperties.input_group_path,
                columns: [...getTableColumns(componentsProperties)],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUpdatedValue]);
    // const data: any[] = [];
    // let status = "success";

    const columns = getColumns({
        componentsProperties,
        currentTaskStatus,
        changeAll,
        disabled: justSee,
        renderContext,
        justSee,
        rootElementAttribute,
        handleTableRemoveRow,
    });

    function handleTableAdd() {
        handleTableAddRow({
            columns: [...columns],
            input_group_path: componentsProperties.input_group_path ? [...componentsProperties.input_group_path] : null,
            table_key: componentsProperties.key,
        });
    }

    const renderButtonLoad = useMemo(() => {
        if (componentsProperties.load_action) {
            return false;
        }
        if (Object.keys(componentsProperties).find((l) => l.startsWith("@accao_"))) {
            return false;
        }
        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentsProperties.key]);

    return (
        <>
            <Group componentsProperties={componentsProperties} renderContext={renderContext}>
                {!justSee && (
                    <Row>
                        <Col xs={24}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 12,
                                }}
                            >
                                <div>
                                    {renderButtonLoad && (
                                        <Button
                                            icon="reload"
                                            size="small"
                                            disabled={false}
                                            onClick={() => {
                                                setTriggerLoadData((prev) => prev + 1);
                                            }}
                                        >
                                            Carregar
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    {componentsProperties.show_buttons_add_remove === "sim" && (
                                        <Button
                                            icon="plus"
                                            type="primary"
                                            size="small"
                                            disabled={false}
                                            loading={false}
                                            onClick={handleTableAdd}
                                        >
                                            Adicionar
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                {componentsProperties.type === COMPONENT_TYPE.DATAGRID && (
                    <>
                        <Table
                            columns={columns}
                            pagination={tablePagination}
                            dataSource={table_data_source}
                            loading={mapLoad([status, whenValueChangeStatus])}
                            size="small"
                            style={{ marginBottom: 16 }}
                            scroll={{ x: "max-content" }}
                        />
                    </>
                )}

                {componentsProperties.type === COMPONENT_TYPE.DYNAMIC_DATAGRID && (
                    <>
                        {componentsProperties["@titulo_datagrid"]}
                        <Table
                            pagination={tablePagination}
                            columns={columns}
                            dataSource={table_data_source}
                            loading={mapLoad([status, whenValueChangeStatus])}
                            size="small"
                            style={{ marginBottom: 16 }}
                            scroll={{ x: "max-content" }}
                        />
                    </>
                )}
            </Group>
        </>
    );
}
