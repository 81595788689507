import React, { useState, useEffect } from "react";
import { Modal, Table, Alert, message, Result, Typography, Collapse } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { sendHttpRequest } from "../http.request";
import { toArray } from "../util";
import { Utilitaries } from "../../business/index";
import { changeUpdateModalState } from "./actions/index";

const UpdateModal = ({
    modalUpdateState,
    changeUpdateModalState,
    canUpdate,
    showModalUpdates: automaticShowUpdateAvailable,
    closeModal,
}) => {
    const [updateList, setUpdateList] = useState({ erro: null, lista: [] });
    const [loadingVersions, setLoadingVersions] = useState(true);
    const [currentVersionInfo, setCurrentVersionInfo] = useState({ erro: null, lista: [] });

    const getData = async () => {
        const formData = new FormData();
        formData.append("accao", "last_versions");
        const { updates, pending_updates } = await sendHttpRequest("POST", "/Gestor/gereupdates.php", formData);

        setLoadingVersions(false);

        setCurrentVersionInfo(updates);
        setUpdateList(pending_updates);
    };

    useEffect(() => {
        if (modalUpdateState) {
            getData();
        }
    }, [modalUpdateState]);

    // call one tyme for alert update
    useEffect(() => {
        if (canUpdate && automaticShowUpdateAvailable) {
            getData();
        }
    }, [canUpdate, automaticShowUpdateAvailable]);

    const columns = [
        {
            title: "Produto",
            dataIndex: "produto",
            key: "produto",
        },
        {
            title: "Versão",
            dataIndex: "versao",
            key: "versao",
        },
        {
            title: "Data",
            dataIndex: "data",
            key: "data",
            render: (data) => (Utilitaries.isEmpty(data) ? "-" : moment(data).calendar()),
        },
    ];

    const handleUpdate = async () => {
        let formData = new FormData();
        formData.append("accao", "update");
        sendHttpRequest("POST", "/Gestor/gereupdates.php", formData);
        message.success("A aplicação vai ser atualizada. Será criado uma alerta no final do processo.");
        changeUpdateModalState(false);
        if (automaticShowUpdateAvailable) {
            closeModal();
        }
    };

    const showUserUpdatesAvailableAfterLogin = automaticShowUpdateAvailable && toArray(updateList?.lista).length > 0;

    return (
        <>
            <Modal
                title="Atualizar plataforma"
                visible={modalUpdateState || showUserUpdatesAvailableAfterLogin}
                onOk={handleUpdate}
                width="40%"
                destroyOnClose
                okText="Atualizar"
                okButtonProps={{
                    disabled:
                        loadingVersions ||
                        !!currentVersionInfo?.erro ||
                        !!updateList?.erro ||
                        toArray(updateList?.lista).length === 0,
                }}
                cancelText="Cancelar"
                onCancel={() => {
                    changeUpdateModalState(false);
                    if (automaticShowUpdateAvailable) {
                        closeModal();
                    }
                }}
            >
                {!showUserUpdatesAvailableAfterLogin && (
                    <>
                        {currentVersionInfo?.erro && (
                            <Alert message="Erro" description={currentVersionInfo.erro} type="error" />
                        )}
                        {currentVersionInfo?.lista && (
                            <>
                                <Table
                                    loading={loadingVersions}
                                    rowKey={(record, index) => `${record.produto}-${index}`}
                                    size="small"
                                    columns={columns}
                                    dataSource={toArray(currentVersionInfo.lista)}
                                    pagination={false}
                                />
                            </>
                        )}
                    </>
                )}
                {updateList?.erro ? (
                    <Result status="error" title={updateList.erro} />
                ) : toArray(updateList?.lista).length > 0 ? (
                    <>
                        <Result
                            status="warning"
                            title={`Há atualizações disponíveis (${toArray(updateList?.lista).length})`}
                        />
                        <Collapse defaultActiveKey={["1"]} ghost>
                            <Collapse.Panel header="Ver atualizações">
                                {toArray(updateList?.lista).map((item, idx) => (
                                    <div key={`key-${idx}`}>
                                        <Typography.Text strong>
                                            {item.produto} {"  "}
                                        </Typography.Text>
                                        <Typography.Text code>{item.versao}</Typography.Text>
                                        <Typography.Paragraph>
                                            ({item.data}) {" - "}
                                            {typeof item.info_cliente === "object" ? "" : item.info_cliente}
                                        </Typography.Paragraph>
                                    </div>
                                ))}
                            </Collapse.Panel>
                        </Collapse>
                    </>
                ) : (
                    <Result status="success" title="Atualmente não há atualizações disponíveis" />
                )}
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        modalUpdateState: state.updateApp.modalUpdateState || false,
    };
};
const mapDispatchToProps = {
    changeUpdateModalState,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateModal);
