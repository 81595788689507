import React, { useState, useEffect } from "react";
import { Row, Form, Select, Col, Input, Radio, Button } from "antd";
import { useParams } from "react-router-dom";
import { toArray } from "../../../../components/util";

const { Password } = Input;
const { Option } = Select;

const AcessData = (props) => {
    const { id } = useParams();
    const [changePassword, setChangePassword] = useState(false);
    const [username, setUsername] = useState(null);
    const [senha, setSenha] = useState(null);
    const [sexo, setSexo] = useState(null);
    const [status, setStatus] = useState(undefined);
    const [motivo_suspensao, setMotivoSuspensao] = useState(undefined);
    const [estab, setEstab] = useState(undefined);
    const [tipo_contracto, setTipoContracto] = useState(undefined);
    const [exclusividade, setExclusividade] = useState(null);
    const { getFieldDecorator } = props.form;

    useEffect(() => {
        const { ficha_colaborador } = props;
        if (ficha_colaborador !== "") {
            if (typeof ficha_colaborador.username !== "object") setUsername(ficha_colaborador.username);
            if (typeof ficha_colaborador.senha !== "object") setSenha(ficha_colaborador.senha);
            if (typeof ficha_colaborador.sexo !== "object") setSexo(ficha_colaborador.sexo);
            if (typeof ficha_colaborador.status !== "object") setStatus(ficha_colaborador.status);
            if (typeof ficha_colaborador.motivo_suspensao !== "object")
                setMotivoSuspensao(ficha_colaborador.motivo_suspensao);
            if (typeof ficha_colaborador.estab !== "object") setEstab(ficha_colaborador.estab);
            if (typeof ficha_colaborador.tipo_contracto !== "object")
                setTipoContracto(ficha_colaborador.tipo_contracto);
            if (typeof ficha_colaborador.exclusividade !== "object") setExclusividade(ficha_colaborador.exclusividade);
        }
    }, [props, props.ficha_colaborador]);

    return (
        <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Utilizador">
                    {getFieldDecorator("username", {
                        initialValue: username,
                        rules: [
                            {
                                required: true,
                                message: "Campo Utilizador é obrigatório",
                            },
                        ],
                    })(
                        <Input
                            disabled={id ? true : false}
                            placeholder="Introduza utilizador"
                            onChange={(e) => props.onChangeUsername(e.target.value, null)}
                        />
                    )}
                </Form.Item>
            </Col>
            {id ? (
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Mudar Senha?">
                        <Radio.Group
                            buttonStyle="solid"
                            defaultValue={changePassword}
                            onChange={(e) => setChangePassword(e.target.value)}
                        >
                            <Radio.Button value={true}>Sim</Radio.Button>
                            <Radio.Button value={false}>Não</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            ) : null}

            {id && changePassword ? (
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Senha">
                        {getFieldDecorator("senha", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo Senha é obrigatório",
                                },
                            ],
                        })(<Password placeholder="Introduza senha" visibilityToggle />)}
                    </Form.Item>
                </Col>
            ) : !id ? (
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Senha">
                        {getFieldDecorator("senha", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo Senha é obrigatório",
                                },
                            ],
                        })(<Password placeholder="Introduza senha" visibilityToggle />)}
                    </Form.Item>
                </Col>
            ) : null}

            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Género">
                    {getFieldDecorator("sexo", {
                        initialValue: sexo,
                    })(
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="Masculino">Masculino</Radio.Button>
                            <Radio.Button value="Feminino">Feminino</Radio.Button>
                            <Radio.Button value="Outro">Outro</Radio.Button>
                        </Radio.Group>
                    )}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Estado">
                    {getFieldDecorator("status", {
                        initialValue: status,
                        rules: [
                            {
                                required: true,
                                message: "Campo Estado é obrigatório",
                            },
                        ],
                    })(
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            placeholder="Seleciona uma opção"
                            onChange={(value) => setStatus(value)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {[
                                { key: "1", value: "Ativo" },
                                { key: "2", value: "Suspenso" },
                                { key: "3", value: "Inativo" },
                            ].map((item, index) => {
                                return (
                                    <Option key={item.key} value={item.key}>
                                        {item.value}
                                    </Option>
                                );
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            {status === "2" ? (
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Motivo Suspensão">
                        {getFieldDecorator("motivo_suspensao", {
                            initialValue: motivo_suspensao,
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                placeholder="Seleciona uma opção"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {["Baixa médica", "Licença sem vencimento", "Processo disciplinar", "Outros"].map(
                                    (item, index) => {
                                        return (
                                            <Option key={index} value={item}>
                                                {item}
                                            </Option>
                                        );
                                    }
                                )}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            ) : null}

            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Estabelecimento">
                    {getFieldDecorator("estab", {
                        initialValue: estab,
                    })(
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            placeholder="Seleciona uma opção"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {props.fixedList.estabelecimento !== undefined &&
                                props.fixedList.estabelecimento.data.map((item) => {
                                    if (typeof item.estabelecimento !== "object" && typeof item.nome !== "object") {
                                        return (
                                            <Option key={item.estabelecimento} value={item.estabelecimento}>
                                                {item.nome}
                                            </Option>
                                        );
                                    }
                                })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Tipo de Contrato">
                    {getFieldDecorator("tipo_contracto", {
                        initialValue: tipo_contracto,
                    })(
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            placeholder="Seleciona uma opção"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {toArray(props?.fixedList?.tipo_contrato_ficha_colaborador?.data).map((item) => {
                                return (
                                    <Option key={item.id_tipo_contracto} value={item.id_tipo_contracto}>
                                        {item.nome}
                                    </Option>
                                );
                            })}
                        </Select>
                    )}
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label="Com exclusividade?">
                    {getFieldDecorator("exclusividade", {
                        initialValue: exclusividade === "t" ? true : false,
                    })(
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value={true}>Sim</Radio.Button>
                            <Radio.Button value={false}>Não</Radio.Button>
                        </Radio.Group>
                    )}
                </Form.Item>
            </Col>
        </Row>
    );
};

export default AcessData;
