import { Col, Form, Checkbox } from "antd";
import React, { useContext, useEffect } from "react";

import QADivider from "./divider";
import QAToolTip from "./ToolTip";
import FormContext from "../context/FormContext";
import { input_validations, getAllDependencies } from "../utils/validations";
import { getElementAction, addDoubleQuotesToParams, getInputLabel } from "../utils/functions";
import { sendHttpRequest } from "../../http.request";
import { Utilitaries } from "../../../business";
import { LineBreak } from "./lineBreak";

const QaCheckBox = ({ inputAttribute, value, field, visible, renderGroup, disabled, colSize, ...inputProps }) => {
    const {
        changeInputValue,
        justSee,
        formData,
        isInitial,
        formAttributes,
        params: formParams,
    } = useContext(FormContext);

    useEffect(() => {
        //if (!value) value = false;
        changeInputValue({
            value: Utilitaries.toBoolean(value), //value === "t" || value === "true" ? true : false,
            key: field,
            inputAttribute,
            appLoading: true,
            ...inputProps,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Get value
    let valueDep = [];
    let inputActionsName = null;

    const elementAction = getElementAction({ item: inputAttribute });
    if (elementAction) {
        valueDep = getAllDependencies(elementAction.valueDep);
        inputActionsName = elementAction.inputActionsName;
    }

    useEffect(
        () => {
            const getData = async () => {
                try {
                    let expression = input_validations({
                        item: inputAttribute,
                        relKey: inputActionsName,
                        returnExpression: true,
                        formData,
                        inputProps,
                    });
                    expression = addDoubleQuotesToParams(expression);
                    let expressionValue = null;
                    try {
                        expressionValue = await eval(`${expression}`);
                    } catch (error) {}

                    const resultKeys =
                        expressionValue && typeof expressionValue === "object" ? Object.keys(expressionValue) : null;
                    const inputValue = resultKeys
                        ? resultKeys.length === 1
                            ? expressionValue[resultKeys[0]]
                            : expressionValue[inputAttribute["@chave_lista"]]
                        : expressionValue;

                    changeInputValue({
                        value: Utilitaries.toBoolean(inputValue),
                        key: field,
                        inputAttribute,
                        appLoading: true,
                        noMessage: true, // nao mostrar pop up com mesagem de campo errado
                        ...inputProps,
                    });
                } catch (error) {
                    console.error(error, field, inputAttribute);
                }
            };

            if (
                !inputAttribute[inputActionsName] ||
                !formData ||
                (isInitial && !Utilitaries.isEmpty(formData[`id_${formAttributes["@elemento_raiz"]}`]))
                //  ||
                // valueDep.filter((it) => {
                //     if (inputProps.items.data[it]) return false;
                //     if (formData[it]) return false;
                //     return true;
                // }).length > 0
            ) {
                return;
            }

            // formulario Nota de Credito - não carregar preso porque ja vem no query que carrega os dados na tabela
            if (
                inputProps?.inTable &&
                inputProps.items.data[field] &&
                formParams &&
                formParams?.tipodoc === "NC" &&
                field === "iva_incluido"
            ) {
                return;
            }

            getData();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueDep
            .filter((it) => it !== field)
            .map((it) => {
                if (inputProps?.items?.data[it]) return inputProps.items.data[it];

                return formData ? formData[it] : null;
            })
    );
    const auto_preenche_valor = async (query, ...params) => {
        const fd = new FormData();
        fd.append("query", query);
        fd.append("accao", "query");

        let nullValues = false;
        params
            .map((it) => it.replace(/[{}\s]/g, "").split(","))
            .forEach((it) => {
                // eslint-disable-next-line no-eval
                const val = eval(it[1]);
                if (val === undefined) {
                    nullValues = true;
                }
                fd.append(it[0], val === "t" ? "true" : val);
            });

        if (nullValues) {
            return;
        }
        try {
            const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", fd);
            return data.result[0];
        } catch (error) {
            return null;
        }
    };
    // eslint-disable-next-line no-unused-vars
    const auto_preenche = auto_preenche_valor;

    if (!visible) {
        return null;
    }

    const { inTable } = inputProps;

    const RenderCheckBox = () => (
        <Checkbox
            defaultChecked={value ? (value === "t" ? true : false) : false}
            disabled={justSee || disabled}
            onChange={(e) => {
                // setInputValue(e.target.checked);
                //const _err = hasError({ [self]: value });
                changeInputValue({
                    value: e.target.checked,
                    key: field,
                    inputAttribute,
                    ...inputProps,
                });
            }}
        >
            {!inTable && (
                <QAToolTip help={inputAttribute["@ajuda"]} label={getInputLabel(inputAttribute, field)} noRow={true} />
            )}
        </Checkbox>
    );

    return inTable ? (
        <RenderCheckBox />
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            {<LineBreak item={inputAttribute} />}
            <Col key={"col-"} xs={colSize}>
                <Form.Item>
                    <RenderCheckBox />
                    {/* {error && <span style={{ color: "red" }}>{error}</span>} */}
                </Form.Item>
            </Col>
        </>
    );
};
export default QaCheckBox;
