import React, { Component } from "react";
import {
    Modal,
    Row,
    Select,
    Button,
    Table,
    Popconfirm,
    Col,
    message,
    Alert,
    Checkbox,
    Tooltip,
    Typography,
    Icon,
} from "antd";
import { selectDataForFixedSelectList as selectFixeList } from "./../../../fixed_list/actions/index";
import * as actionCreators from "./../../actions/scale";
import Confirm from "./../../../../components/qa_custom/confirm";
import { connect } from "react-redux";
import { toArray } from "../../../../components/util";

const { Option } = Select;

export class Scale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: true,
            dataSource: [],
            count: 0,
            setDefaultCategory: false,
        };
    }

    componentDidMount() {
        this.props
            .selectScale({
                formulario: this.props.formName,
                id: this.props.scale.id,
            })
            .then((response) => {
                if (!response) this.props.handleCancel();
                else {
                    let ids = toArray(response.list).map((item) => {
                        return item.id_escala;
                    });
                    if (ids.length === 0) ids = null;
                    const getCategory = this.props.selectFixeList(
                        { query: "lista_categoria_escala", ids },
                        "lista_categoria_escala"
                    );
                    const getScale = this.props.selectFixeList(
                        { query: "lista_escala_norma", ids },
                        "lista_escala_norma"
                    );

                    Promise.all([getCategory, getScale]).then(() => {
                        this.setState({
                            isLoadingData: false,
                            dataSource: response.list,
                            count: response.list.length,
                            formMetadata: response.formulario,
                        });
                    });
                }
            });
    }

    componentDidUpdate() {
        if (!this.state.setDefaultCategory) {
            const scaleList = this.props.scaleList;
            if (!this.state.isLoadingData && scaleList && scaleList.length > 0) {
                const list = [...this.state.dataSource];
                const dataSource = list.map((item) => {
                    const scale = scaleList.find((element) => element.id_escala === item.id_escala);
                    return {
                        ...item,
                        category: scale.codigo_categoria,
                    };
                });
                this.setState({
                    dataSource,
                    setDefaultCategory: true,
                });
            }
        }
    }

    columns = [
        {
            title: "Categoria",
            dataIndex: "category",
            width: "30%",
            render: (text, record) => {
                return (
                    <Select
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => this.changeCategory(e, record)}
                        value={text}
                        disabled={this.props.readOnly}
                    >
                        {toArray(this.props.categoryList).map((item) => (
                            <Option key={"category-" + item.id_categoria_escala} value={item.codigo}>
                                {item.designacao}
                            </Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Escala",
            dataIndex: "id_escala",
            width: "30%",
            render: (text, record) => (
                <Select
                    style={{ width: "100%" }}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    defaultValue={text}
                    value={text}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => this.changeScale(e, record)}
                    disabled={this.props.readOnly}
                >
                    {toArray(this.props.scaleList).map((item) => {
                        if (item.codigo_categoria === record.category)
                            return (
                                <Option key={item.id_escala} value={item.id_escala}>
                                    {item.nome_escala}
                                </Option>
                            );
                        return null;
                    })}
                </Select>
            ),
        },
        {
            title: (
                <>
                    <Typography.Text>Aplicar a todos</Typography.Text>

                    <Tooltip title="Se sim, nos items aparecem as escalas globais e específica; se não, apenas aparece a escala específica e a global aparece nos items que não tenham escala específica.">
                        <Icon type="info-circle" style={{ color: "#faad14" }} />
                    </Tooltip>
                </>
            ),

            dataIndex: "aplicar_todos",
            width: "20%",
            render: (value, record) => (
                <Checkbox
                    defaultChecked={value}
                    disabled={this.props.readOnly}
                    onChange={(e) => {
                        this.changeValue("aplicar_todos", e.target.checked, record);
                    }}
                >
                    {/* {!inTable && (
                    <QAToolTip
                        help={inputAttribute["@ajuda"]}
                        label={inputAttribute["@etiqueta"] || field.toPascalCase(true)}
                        noRow={true}
                    />
                )} */}
                </Checkbox>
            ),
        },
        {
            dataIndex: "operation",
            width: "10%",
            render: (text, record) => {
                return this.state.dataSource.length >= 1
                    ? !this.props.readOnly && (
                          <Popconfirm
                              title="Tens certeza que desejas eliminar?"
                              onConfirm={() => this.handleDelete(record.key)}
                          >
                              <Button
                                  type="link"
                                  style={{
                                      padding: 0,
                                      color: "#fa541c",
                                  }}
                              >
                                  Eliminar
                              </Button>
                          </Popconfirm>
                      )
                    : null;
            },
        },
    ];

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };

    handleAdd = () => {
        const { count, dataSource } = this.state;
        const newData = {
            key: count,
            category: null,
            id_escala: null,
        };
        this.setState({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
    };

    changeValue = (key, value, record) => {
        const recordToChange = { ...record, [key]: value };
        const dataSource = [...this.state.dataSource];
        const index = dataSource.findIndex((item) => recordToChange.key === item.key);
        dataSource[index] = recordToChange;
        this.setState({
            dataSource,
        });
    };

    changeScale = (value, record) => {
        const recordToChange = { ...record, id_escala: value };
        const dataSource = [...this.state.dataSource];
        const index = dataSource.findIndex((item) => recordToChange.key === item.key);
        dataSource[index] = recordToChange;
        this.setState({
            dataSource,
        });
    };

    changeCategory = (value, record) => {
        const recordToChange = { ...record, category: value, id_escala: null };
        const dataSource = [...this.state.dataSource];
        const index = dataSource.findIndex((item) => recordToChange.key === item.key);
        dataSource[index] = recordToChange;
        this.setState({
            dataSource,
        });
    };

    handleResponse = (response, total) => {
        if (response) {
            const obj = {
                key: this.props.scale.key,
                id: response,
                total,
            };
            this.props.assocScale(obj).then((status) => {
                if (status) {
                    message.success("Guardado com sucesso");
                    this.props.handleOk(obj);
                } else message.error("Ocorreu um erro");
            });
        } else {
            message.error("Ocorreu um erro");
        }
        this.setState({ buttonLoading: false });
    };

    handleOk = () => {
        const { dataSource, formMetadata } = this.state;
        this.setState({ buttonLoading: true });

        const values = {
            dataSource,
            id: this.props.scale.id,
        };

        this.props
            .save({
                formulario: formMetadata,
                values,
            })
            .then((response) => this.handleResponse(response, dataSource.length))
            .catch(() => {
                message.error("Ocorreu um erro");
                this.setState({ buttonLoading: false });
            });
    };

    handleCancel = () => {
        if (this.props.readOnly) this.props.handleCancel();
        else Confirm(this.props.handleCancel);
    };

    render() {
        const { dataSource } = this.state;
        const propsFooter = this.props.readOnly ? { footer: null } : {};
        return (
            <Modal
                title="Escala"
                visible={this.props.isModalVisible}
                width="60%"
                onOk={() => this.handleOk()}
                confirmLoading={this.state.buttonLoading}
                onCancel={this.handleCancel}
                {...propsFooter}
            >
                <Row style={{ marginBottom: 16 }}>
                    <Col xs={24} md={18}>
                        <Alert message={this.props.scale.parentDesignation} type="info" />
                    </Col>
                    <Col xs={24} md={6}>
                        {!this.props.readOnly && (
                            <Button
                                onClick={this.handleAdd}
                                disabled={this.state.isLoadingData}
                                type="primary"
                                icon="plus"
                                style={{ float: "right" }}
                            >
                                Inserir
                            </Button>
                        )}
                    </Col>
                </Row>
                <Table
                    rowClassName={() => "editable-row"}
                    size="middle"
                    loading={this.state.isLoadingData}
                    dataSource={dataSource}
                    columns={this.columns}
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    selectFixeList,
    selectScale: actionCreators.selectScale,
    save: actionCreators.save,
};

const mapStateToProps = (state) => {
    const fixedList = state.fixedList.domain.byId;
    return {
        categoryList: fixedList.lista_categoria_escala ? fixedList.lista_categoria_escala.data : [],
        scaleList: fixedList.lista_escala_norma ? fixedList.lista_escala_norma.data : [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Scale);
