import { Col, Form, Upload, Button, Icon, message } from "antd";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { connect } from "react-redux";

import { postDataToGereFicheiros } from "../../../modules/function_manual/actions/contributor";
import FormContext from "../context/FormContext";
// import AppContext from "../../AppContext";
import { QADivider, QAToolTip } from "./";
import { downloadFile } from "../../../components/util";

import { baseUrl } from "../../../constants/enviroment";
import { Session } from "../../../business";
import { getInputLabel } from "../utils/functions";

const QaInput = ({
    inputAttribute,
    value,
    field,
    visible,
    // disabled,
    preview,
    postDataToGereFicheiros,
    renderGroup,
    ...inputProps
}) => {
    const [image, setImage] = useState(null);
    // const [fileList, setFileList] = useState([]);
    // const [image1, setImage1] = useState(null);
    const [loading, setLoading] = useState(false);

    const { changeInputValue, formData, justSee } = useContext(FormContext);
    // const { getLogo } = useContext(AppContext);

    const sendBase64 =
        inputAttribute["@tipo"] === "ficheiro" &&
        [
            "doc_upload",
            "foto_upload",
            "ficheiro",
            "ficheiro_novo",
            "code",
            "jasper",
            "comprovativo",
            "docs_seguros_upload",
            "imagem_assinatura",
            "cond_upload",
            "upl_terceiro",
        ].includes(field);

    const filter = useMemo(() => {
        if (inputAttribute?.["@filtro"]) {
            return inputAttribute["@filtro"].replace(/\*/g, "").replace(/\;/g, ",");
        }
        return "";
    }, [inputAttribute]);

    useEffect(() => {
        if (!preview) return;
        try {
            let intVal = parseInt(formData[field]);

            if (intVal) {
                const fd = new FormData();
                fd.append("accao", "ficheiro");
                fd.append("ficheiro", formData[field]);

                var request = new XMLHttpRequest();
                request.open("POST", baseUrl + "/Gestor/geredocumentos.php", true);

                request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
                request.responseType = "blob";

                request.onload = function () {
                    // Only handle status code 200
                    if (request.status === 200) {
                        // The actual download
                        // var blob = new Blob([request.response], {
                        //     type: request.getResponseHeader("content-type")
                        // });

                        getBase64(request.response, (img) => {
                            setImage(img);
                        });
                    }

                    // some error handling should be done here...
                };

                request.send(fd);
            }
        } catch (error) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData[field]]);

    if (!visible) {
        return null;
    }

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                setImage(imageUrl);
                setLoading(false);
            });
        }
    };

    const props = sendBase64
        ? {
              onRemove: (file) => {
                  changeInputValue({
                      key: field,
                      value: "",
                      ...inputProps,
                  });
              },
              beforeUpload: (file) => {
                  //
                  if (["ficheiro_novo", "ficheiro", "code", "jasper"].includes(field)) {
                      uploadFileToServer(file);
                  } else {
                      getBase64(file, (img) => {
                          changeInputValue({
                              key: field,
                              value: `${img}@@${file.name}`,
                              ...inputProps,
                          });
                      });
                  }

                  return false;
              },
          }
        : {
              name: "dados",
              action: `${baseUrl}/Gestor/gereprocesso.php`,
              headers: {
                  authorization: `Bearer ${Session.getToken()}`,
              },
              data: {
                  accao: "upload",
                  tipo: "logo_instituicao",
                  type: "binary",
              },
              // transformFile(file) {
              //     return new Promise((resolve, reject) => {
              //         getBase64(file, imageUrl => {
              //             resolve(imageUrl)
              //         });
              //     })
              // },
              onChange(info) {
                  if (info.file.status !== "uploading") {
                  }
                  if (info.file.status === "done") {
                      getBase64(info.file.originFileObj, (imageUrl) => {
                          // setImage1(imageUrl);
                          changeInputValue({
                              key: field,
                              value: imageUrl,
                          });
                      });
                  } else if (info.file.status === "error") {
                  }
              },
          };

    const uploadFileToServer = async (file) => {
        const data = {
            accao: "upload",
            nome: field === "instituicao_imagem_canto" ? "logo_instituicao.jpg" : file.name,
            descricao: file.type,
            ficheiro: file,
            chave_sessao: "teste",
        };
        try {
            setLoading(true);
            const response = await postDataToGereFicheiros(data);
            if (response.sucesso && response.sucesso !== "0") {
                changeInputValue({
                    key: field,
                    value: response.sucesso,
                    appLoading: true,
                    ...inputProps,
                });
                message.success("O ficheiro foi carregado com successo");

                // if (field === "instituicao_imagem_canto") {
                //     getLogo(response.sucesso);
                // }
            } else {
                message.error("Ocorreu um erro");
            }
        } catch (error) {
            message.error("Ocorreu um erro");
        } finally {
            setLoading(false);
        }
    };

    const uploadButton = (
        <div>
            <Icon type={loading ? "loading" : "plus"} />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const renderDownloadButton = () => {
        if (!sendBase64) {
            return null;
        }

        if (value && typeof value === "string" && !isNaN(value)) {
            return (
                <Button
                    type="link"
                    onClick={(e) => {
                        e.preventDefault();
                        downloadFile("/geredocumentos.php", {
                            accao: "ficheiro",
                            ficheiro: value,
                        });
                    }}
                >
                    Descarregar
                </Button>
            );
        }
        return null;
    };

    const RenderInput = () =>
        preview ? (
            <>
                <Upload listType="picture-card" showUploadList={false} disabled={true}>
                    {image ? <img src={image} alt="avatar" style={{ width: "100%" }} /> : null}
                </Upload>
                <Upload
                    name="avatar"
                    showUploadList={false}
                    disabled={justSee}
                    beforeUpload={(file) => {
                        uploadFileToServer(file);
                        return false;
                    }}
                    onChange={handleChange}
                >
                    <Button loading={loading} icon="upload">
                        Clique para carregar
                    </Button>
                </Upload>
                {/* <Upload
					name="avatar"
					listType="picture-card"
					className="avatar-uploader"
					showUploadList={false}
					disabled={justSee}
					beforeUpload={(file) => {
						uploadFileToServer(file);
						return false;
					}}
					onChange={handleChange}
				>
					{image ? (
						<img
							src={image}
							alt="avatar"
							style={{ width: "100%" }}
						/>
					) : (
						uploadButton
					)}
				</Upload> */}
            </>
        ) : (
            <>
                <Upload {...props} accept={filter} disabled={justSee}>
                    <Button loading={loading} icon="upload">
                        Clique para carregar
                    </Button>
                </Upload>
                {renderDownloadButton()}
            </>
        );

    const { inTable } = inputProps;

    return inTable ? (
        RenderInput()
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            <Col xs={8}>
                <Form.Item
                    label={<QAToolTip label={getInputLabel(inputAttribute, field)} help={inputAttribute["@ajuda"]} />}
                >
                    {RenderInput()}
                    {/* {sendBase64 && (
						<Button
							type="link"
							onClick={() => {
								
								downloadFile("geredocumentos", {
									accao: "documento",
									id: 301,
								});
							}}
						>
							Descarregar
						</Button>
					)} */}
                </Form.Item>
            </Col>
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    postDataToGereFicheiros,
};

export default connect(mapStateToProps, mapDispatchToProps)(QaInput);

/**
 * <Upload
				name="avatar"
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				disabled={justSee}
				beforeUpload={(file) => {
					uploadFileToServer(file);
					return false;
				}}
				onChange={handleChange}
			>
				{image ? (
					<img src={image} alt="avatar" style={{ width: "100%" }} />
				) : (
					uploadButton
				)}
			</Upload>
 */
