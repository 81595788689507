import React, { Component } from "react";
import { Row, Table, Button, Select, Form, Input, Popconfirm, Upload, message, Col } from "antd";
import { connect } from "react-redux";
import moment from "moment";

import { editList, selectById, insert } from "../../../dynamic_list/actions/index";
import { QaDynamicSelect } from "../../../../components/DynamicList/index";
import { postDataToGereFicheiros } from "../../actions/contributor";
import ButtonDownloadDocument from "../../../utilities/component/button.download.document";

const { Option } = Select;

/** handle file upload */

class DocumentTabPane extends Component {
    state = {
        countDataSource: 1,
        dataSource: [],
    };
    componentDidMount() {
        const { ficha_colaborador } = this.props;
        if (ficha_colaborador) {
            if (ficha_colaborador.docs_colaboradores) {
                const { docs_colaboradores } = ficha_colaborador;
                if (docs_colaboradores.doc_colaborador) {
                    const { doc_colaborador } = docs_colaboradores;
                    const dataSource = [];
                    if (Array.isArray(doc_colaborador)) {
                        doc_colaborador.map((item, index) => {
                            dataSource.push({ key: index, ...item });
                        });
                    } else if (doc_colaborador.id_doc_colaborador) {
                        dataSource.push({ key: 0, ...doc_colaborador });
                    }
                    const countDataSource = dataSource.length + 1;
                    this.setState({ dataSource, countDataSource });
                }
            }
        }
    }
    column = [
        {
            title: "Tipo de documento",
            dataIndex: "tipo_documento",
            width: "30%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Col>
                        <QaDynamicSelect
                            onChange={(value) => this.handleFieldsChange("tipo_documento", value, record)}
                            defaultValue={text}
                            name="tipo_documento"
                        />
                    </Col>
                );
            },
        },
        {
            title: "Número",
            dataIndex: "numero",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            placeholder="Introduza o número"
                            onChange={(e) => this.handleFieldsChange("numero", e.target.value, record)}
                            defaultValue={text}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data de emissão",
            dataIndex: "data_emissao",
            width: "15%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            // onChange={(date, dataString) => this.handleFieldsChange("data_emissao", dataString, record)}
                            onChange={(date) =>
                                this.handleFieldsChange(
                                    "data_emissao",
                                    date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    record
                                )
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : null}
                            format={"YYYY-MM-DD"}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data validade",
            dataIndex: "data_validade",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(date) =>
                                this.handleFieldsChange(
                                    "data_validade",
                                    date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    record
                                )
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : null}
                            // onChange={(date, dataString) =>
                            //     this.handleFieldsChange("data_validade", dataString, record)
                            // }
                            // defaultValue={text}
                            // format={"YYYY-MM-DD"}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Documento",
            dataIndex: "planta_atual",
            align: "center",
            width: "15%",
            render: (text, record) => {
                if (record["planta_atual"] && Object.entries(record["planta_atual"]).length !== 0) {
                    return <ButtonDownloadDocument ficheiro={record["planta_atual"]} text="Descarregar" />;
                } else {
                    return (
                        <Upload
                            openFileDialogOnClick
                            defaultFileList={typeof text !== "object" && text ? [text] : []}
                            beforeUpload={(file) => {
                                this.uploadFileToServer(file, record);
                                return false;
                            }}
                            name="file"
                            multiple={false}
                            onRemove={() => this.onRemove("planta_atual", record)}
                        >
                            <Button type="primary" icon="file-add" />
                        </Upload>
                    );
                }
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) =>
                this.state.dataSource.length >= 1 ? (
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        onConfirm={() => this.handleDelete(record.key)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];
    /*** upload file */
    uploadFileToServer = async (file, record) => {
        const data = {
            accao: "upload",
            nome: file.name,
            descricao: file.type,
            ficheiro: file,
            chave_sessao: "teste",
        };
        try {
            const response = await this.props.postDataToGereFicheiros(data);
            if (response.sucesso && response.sucesso !== "0") {
                this.handleFieldsChange("planta_atual", response.sucesso, record);
            } else {
                message.error("Erro ao gravar ficheiro");
            }
        } catch (error) {
            message.error("Ocorreu um erro");
        }
    };
    handleFieldsChange = (dataIndex, value, record) => {
        record[dataIndex] = value;
        const { dataSource } = this.state;
        const index = dataSource.findIndex((item) => item.key === record.key);
        dataSource[index] = record;
        this.setState({ dataSource }, this.props.getDocsColaboradores(this.state.dataSource));
    };
    onRemove = (dataIndex, record) => {
        const { dataSource } = this.state;
        delete record[dataIndex];
        const index = dataSource.findIndex((item) => item.key === record.key);
        dataSource[index] = record;
        this.setState({
            dataSource,
        });
    };

    /** handle dynamic table rows */
    handleAdd = () => {
        const { countDataSource, dataSource } = this.state;
        const newData = {
            key: countDataSource,
        };
        this.setState({
            dataSource: [newData, ...dataSource],
            countDataSource: countDataSource + 1,
        });
    };
    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState(
            {
                dataSource: dataSource.filter((item) => item.key !== key),
            },
            this.props.getDocsColaboradores(this.state.dataSource)
        );
    };

    /**************************** */
    render() {
        return (
            <div>
                <Row>
                    <Button
                        xs={24}
                        sm={12}
                        onClick={this.handleAdd}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{ marginBottom: 16, float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Row>
                <Table size="small" scroll={{ x: 500 }} columns={this.column} dataSource={this.state.dataSource} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { dynamicLists: state.dynamicList.domain.byId };
};

const mapDispatchToProps = {
    editList,
    selectById,
    insert,
    postDataToGereFicheiros,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTabPane);
