import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Menu, Empty } from "antd";

const Rooms = ({ list, changeRoom }) => {
    useEffect(
        () => {
            if (list && list.length > 0) {
                changeRoom(list[0].id_qa_salas_chat);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [list]
    );

    const handlerSalaClick = (e) => {
        changeRoom(e.item.props["data-id"]);
    };

    return (
        <Menu
            onClick={handlerSalaClick}
            defaultSelectedKeys={["0"]}
            mode="inline"
            style={{
                maxHeight: 400,
                overflowY: "auto",
                overflowX: "hidden",
            }}
        >
            {list && Array.isArray(list) ? (
                list.map((it, idx) => (
                    <Menu.Item key={idx} data-id={it.id_qa_salas_chat}>
                        {it.nome}
                    </Menu.Item>
                ))
            ) : (
                <Empty />
            )}
        </Menu>
    );
};

Rooms.propTypes = {
    list: PropTypes.array,
    changeRoom: PropTypes.func,
};

export default Rooms;
