import React from "react";
import { Modal, Row, Col, Card } from "antd";

const Scale = (props) => {
    const dimension = props.dimension;
    const scalesDesignation = dimension["Nome escala"] ? dimension["Nome escala"].split("£") : [];
    const scales = dimension["Escala"] ? dimension["Escala"].split("£") : [];

    const list = scalesDesignation
        ? scalesDesignation.map((item, index) => {
              return {
                  designation: item,
                  list: scales[index] ? scales[index].split("##") : [],
              };
          })
        : [];

    return (
        <Modal
            title="Escalas"
            visible={props.isModalVisible}
            width="60%"
            onCancel={() => props.handleCancel()}
            footer={null}
        >
            {list.length > 0 ? (
                list.map((item) => {
                    return (
                        <Row gutter={16}>
                            <Col md={24}>
                                <Card size={"small"} title={item.designation} style={{ width: "100%", marginTop: 16 }}>
                                    <ol>
                                        {item.list.map((element) => {
                                            const descriptionSplit = element.split("#");
                                            return (
                                                <li>
                                                    {descriptionSplit[0]}
                                                    <dl style={{ marginBotton: "0.3 em" }}>{descriptionSplit[1]}</dl>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </Card>
                            </Col>
                        </Row>
                    );
                })
            ) : (
                <p>Sem escalas</p>
            )}
        </Modal>
    );
};

export default Scale;
