import { actionType } from "../constants/requirement.type";
import { sendHttpRequest } from "../../../components/http.request";

export const insert = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            dispatch({ type: actionType.insert });
            return true;
        } else return false;
    });
};

export const update = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            dispatch({ type: actionType.update });
            return true;
        } else return false;
    });
};

export const remove = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) return true;
        else return false;
    });
};

export const select = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        dispatch({
            type: actionType.select,
            payload: jsonResponse["dados"],
        });
    });
};

export const setField = (payload) => ({ type: actionType.setField, payload });
