import React, { Component } from "react";
import { Button, Row, Form, Input, Col, Modal, Switch, Typography, TimePicker, Radio, message, Alert } from "antd";
import moment from "moment";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import { connect } from "react-redux";

import { selectDataFromExecQuery, postDataToGereProcesso } from "../../actions/contributor";
// import { plainOptions } from "../../constants/contributor"

const { TextArea } = Input;
const { Text } = Typography;

const WEEK_DAYS = [
    { label: "Domingo", value: 0 },
    { label: "Segunda", value: 1 },
    { label: "Terça", value: 2 },
    { label: "Quarta", value: 3 },
    { label: "Quinta", value: 4 },
    { label: "Sexta", value: 5 },
    { label: "Sabado", value: 6 },
];

class DaysModal extends Component {
    componentDidMount() {
        if (this.props.dias_trabalho && this.props.dias_trabalho.length) {
            const { dias_trabalho } = this.props;

            const sanitizedDaysWork = dias_trabalho.map((item) => (item === 7 ? 0 : item));
            let daysToBeDisabled = [];
            WEEK_DAYS.forEach((item) => {
                if (!sanitizedDaysWork.includes(item.value)) {
                    daysToBeDisabled.push(item.value);
                }
            });

            // WEEK_DAYS.forEach((item, index) => {
            //     if (!dias_trabalho.includes(item)) daysToBeDisabled.push(index);
            // });

            // WEEK_DAYS.forEach((item, index) => {

            //     dias_trabalho.forEach(it => {
            //         if (item === it)
            //             if (!daysToBeDisabled.includes(index))
            //                 daysToBeDisabled.push(index)
            //     })

            // })

            this.setState({ disabledDays: daysToBeDisabled });
        } else {
            this.setState({ disabledDays: WEEK_DAYS });
        }

        if (
            this.props.record.faltas_colaborador_dias &&
            this.props.record.faltas_colaborador_dias !== "" &&
            typeof this.props.record.faltas_colaborador_dias !== "object"
        ) {
            this.getFoulsDaysFromServer(this.props.record.faltas_colaborador_dias);
        }
    }
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            disabledHours: [],
            hora_fim: 0,
            hora_inicio: 0,
            total_horas: "",
            dia_todo: 0,
            loading: false,
            observacoes: "",
            selectedDays: [],
            disabledDays: [0, 6],
        };
    }
    //get user "dias de faltas"
    getFoulsDaysFromServer = async (id) => {
        try {
            const response = await this.props.selectDataFromExecQuery({
                query: "colaboradores_faltas_dias",
                id,
            });
            let days = [];
            const date = [];
            if (response.result !== undefined) {
                const resp = response.result[0];
                const { dias_mes, observacoes, hora_inicio, hora_fim, total_horas } = resp;

                days = dias_mes.split(" ");
                days.map((item) => {
                    date.push(new Date(this.props.record.ano, this.props.record.mes - 1, item));
                });

                this.setState({
                    selectedDays: date,
                    observacoes,
                    hora_inicio,
                    hora_fim,
                    total_horas,
                    dia_todo: Number(hora_inicio) !== 0 ? 0 : 1,
                });
            }
        } catch (error) {
            message.error("Erro ao carregar dias de faltas!!");
        }
    };
    /** handle select day from calendar */
    handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({ selectedDays });
    }
    /** handle dialog event click */
    handleOk = async () => {
        const { selectedDays } = this.state;
        const days = [];
        selectedDays.map((item) => {
            days.push(moment(item).format("D"));
        });
        let data = {
            faltas_colaborador_dias: {
                username: this.props.username,
                colaborador: this.props.nome_completo,
                dias_mes: days.join(" "),
                observacoes: this.state.observacoes,
                hora_inicio: this.state.hora_inicio,
                hora_fim: this.state.hora_fim,
                total_horas: this.state.total_horas,
            },
        };
        const { faltas_colaborador_dias } = this.props.record;
        if (faltas_colaborador_dias && faltas_colaborador_dias !== "" && typeof faltas_colaborador_dias !== "object") {
            data.faltas_colaborador_dias["id_faltas_colaborador_dias"] = faltas_colaborador_dias;
        }
        let result = "";
        try {
            this.setState({ loading: true });
            result = await this.props.postDataToGereProcesso({
                accao: "guardar",
                formulario: "qa_ficha_colaboradodias_pop.xml",
                dados: JSON.stringify(data),
            });
        } catch (error) {
            this.setState({ loading: false });
            message.error("Erro ao guardar dias de faltas!!");
        } finally {
            if (result.sucesso) {
                message.success("Sucesso");
                const { record } = this.props;
                record["faltas_colaborador_dias"] = result.sucesso;
                record["numero_dias"] = days.length;
                record["observacoes"] = this.state.observacoes;
                record["horas_mascara"] = this.state.total_horas;
                record["tipo_falta"] = "FC";
                if (this.state.total_horas) {
                    record["numero_horas"] = Number(this.state.total_horas?.split(":")?.join(","));
                }
                this.props.hiddeDaysModal(record);
            }
        }
    };
    handleCancel = () => {
        this.props.hiddeDaysModal(this.props.record);
    };
    /** handle inputs event */
    onChangeObs = (e) => {
        this.setState({
            observacoes: e.target.value,
        });
    };
    handleAtivateWeekend = (checked) => {
        if (checked) {
            this.setState({
                disabledDays: [],
            });
        } else {
            this.setState({
                disabledDays: [0, 6],
            });
        }
    };

    disabledHours = (hour) => {
        const len = Number(hour);

        const hours = [];
        for (let i = 0; i <= len; i++) {
            if (i < 10) hours.push(i);
            else hours.push(i);
        }

        this.setState({ disabledHours: hours });
    };

    calcTotalHours = () => {
        const { hora_inicio, hora_fim } = this.state;

        if (hora_inicio && hora_fim) {
            var hrs = Number(hora_inicio.match(/^(\d+)/)[1]);
            var mnts = Number(hora_inicio.match(/:(\d+)/)[1]);
            var hours = hrs.toString();
            var minutes = mnts.toString();
            // alert(hours + ":" + minutes);

            const date1 = new Date();
            date1.setHours(hours);
            date1.setMinutes(minutes);
            // alert(date1);

            var hrs = Number(hora_fim.match(/^(\d+)/)[1]);
            var mnts = Number(hora_fim.match(/:(\d+)/)[1]);
            var hours = hrs.toString();
            var minutes = mnts.toString();
            // alert(hours + ":" + minutes);
            const date2 = new Date();
            date2.setHours(hours);
            date2.setMinutes(minutes);
            // alert(date2);

            var diff = date2.getTime() - date1.getTime();

            var hours = Math.floor(diff / (1000 * 60 * 60));
            diff -= hours * (1000 * 60 * 60);

            var mins = Math.floor(diff / (1000 * 60));
            diff -= mins * (1000 * 60);
            // alert(hours + " hours : " + mins + " minutes : ");

            this.setState({ total_horas: `${hours}:${mins}` });
        }
    };

    handleHoraInicioChange = (time, timeString) => {
        const _time = timeString?.split(":");

        this.disabledHours(_time["0"]);
        this.setState({ hora_inicio: timeString });
    };
    handleHoraFimChange = (time, timeString) => {
        this.setState({ hora_fim: timeString });
    };
    render() {
        return (
            <div>
                <Modal
                    width="60%"
                    centered
                    visible={this.props.isVisible}
                    title="Dias correspondentes"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            voltar
                        </Button>,
                        <Button
                            loading={this.state.loading}
                            icon="save"
                            key="submit"
                            type="primary"
                            onClick={this.handleOk}
                        >
                            Guardar
                        </Button>,
                    ]}
                >
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Row>
                                <Col xs={24}>
                                    {!this.props.dias_trabalho.length && (
                                        <Alert
                                            message="Preencher dias de trabalhos no tab Informação Pessoal para ver dias de trabalhos aqui no calendário!"
                                            type="info"
                                        />
                                    )}

                                    <DayPicker
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        selectedDays={this.state.selectedDays}
                                        onDayClick={this.handleDayClick}
                                        canChangeMonth={false}
                                        initialMonth={
                                            new Date(this.props.record.ano, Number(this.props.record.mes) - 1, 11)
                                        }
                                        disabledDays={{
                                            daysOfWeek: this.state.disabledDays,
                                        }}
                                    />
                                </Col>
                                {/* <Col xs={24}>
                                    <Text>
                                        Ativar os finais de semana
                                        <Switch
                                            style={{ marginLeft: "8px" }}
                                            defaultChecked={false}
                                            onChange={this.handleAtivateWeekend}
                                        />
                                    </Text>
                                </Col> */}
                            </Row>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form layout="vertical">
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <Form.Item label="Colaborador">
                                            <Input disabled defaultValue={this.props.nome_completo} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item label="Observações">
                                            <TextArea
                                                allowClear
                                                value={this.state.observacoes}
                                                onChange={this.onChangeObs}
                                                rows={3}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item label="Dia todo?">
                                            <Radio.Group
                                                buttonStyle="solid"
                                                value={this.state.dia_todo}
                                                onChange={(e) =>
                                                    this.setState({
                                                        dia_todo: e.target.value,
                                                        total_horas: e.target.value === 1 ? "8:00" : "",
                                                    })
                                                }
                                            >
                                                <Radio.Button value={1}>Sim</Radio.Button>
                                                <Radio.Button value={0}>Não</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Hora inicio">
                                            <TimePicker
                                                onOpenChange={this.calcTotalHours}
                                                disabled={this.state.dia_todo === 1 ? true : false}
                                                onChange={this.handleHoraInicioChange}
                                                format={"HH:mm"}
                                                value={
                                                    this.state.hora_inicio
                                                        ? moment(this.state.hora_inicio, "HH:mm")
                                                        : undefined
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Hora fim">
                                            <TimePicker
                                                disabledHours={() => this.state.disabledHours}
                                                onOpenChange={this.calcTotalHours}
                                                disabled={this.state.dia_todo === 1 ? true : false}
                                                onChange={this.handleHoraFimChange}
                                                value={
                                                    this.state.hora_fim
                                                        ? moment(this.state.hora_fim, "HH:mm")
                                                        : undefined
                                                }
                                                format={"HH:mm"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item label="Total horas">
                                            <Input disabled value={this.state.total_horas} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
    selectDataFromExecQuery,
    postDataToGereProcesso,
};

export default connect(null, mapDispatchToProps)(DaysModal);
