import actionType from "../constants/index";

const initialState = {
    modalForumState: false,
    modalForumConfState: false,
    formForumConf: "",
    forum_rooms: [],
    user_forum_rooms: [],
    topics_permissions: [],
    messages: [],
};

export const forumReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.CHANGE_MODAL_FORUM_STATE:
            return {
                ...state,
                modalForumState: action.payload,
            };
        case actionType.CHANGE_MODAL_FORUM_CONF_STATE:
            return {
                ...state,
                modalForumConfState: action.payload.state,
                formForumConf: action.payload.form,
            };
        case actionType.ROOMS:
            return {
                ...state,
                forum_rooms: Array.isArray(action.payload["linha"])
                    ? action.payload["linha"]
                    : action.payload["linha"]
                    ? [action.payload["linha"]]
                    : [],
            };
        case actionType.USER_ROOMS:
            return {
                ...state,
                user_forum_rooms: Array.isArray(action.payload["linha"])
                    ? action.payload["linha"]
                    : action.payload["linha"]
                    ? [action.payload["linha"]]
                    : [],
            };
        case actionType.TOPICS_PERMISSION:
            return {
                ...state,
                topics_permissions: Array.isArray(action.payload["linha"])
                    ? action.payload["linha"]
                    : action.payload["linha"] && Object.keys(action.payload["linha"]).length > 0
                    ? [action.payload["linha"]]
                    : [],
            };

        case actionType.MESSAGES:
            let list = Array.isArray(action.payload["linha"])
                ? action.payload["linha"]
                : action.payload["linha"]
                ? [action.payload["linha"]]
                : [];

            if (list.length === 1 && Object.keys(list[0]).length === 0) {
                list = [];
            }
            return {
                ...state,
                messages: list,
            };

        case actionType.ADD_MESSAGES:
            if (!action.payload || !action.payload["linha"]) {
                return state;
            }
            return {
                ...state,
                messages: [...state.messages, action.payload["linha"]],
            };
        default:
            return state;
    }
};
