import React, { Component } from "react";
import { Modal, Form, Row, Col, Input, DatePicker, message } from "antd";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/time.registration";
import locale from "antd/lib/date-picker/locale/pt_PT";
import moment from "moment";
const { TextArea } = Input;
const formatTime = "HH:mm";
const formatDateTime = "DD-MM-YYYY HH:mm";

function disabledDate(current) {
    return current && current > moment();
}

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

class CreateTimeRegistration extends Component {
    constructor(props) {
        super(props);
        const data = moment(new Date());
        props.setField({
            system_data_entrada: { name: "system_data_entrada", value: data },
            hora_entrada: { name: "hora_entrada", value: data },
        });
    }

    state = {
        loading: false,
    };

    disabledDateTime = () => {
        const system_data_entrada = this.props.timeRegistration.system_data_entrada.value;
        const data_entrada = this.props.timeRegistration.hora_entrada.value;
        if (system_data_entrada.isSame(data_entrada, "minute")) {
            return {
                disabledHours: () => range(0, 24).splice(system_data_entrada.hour() + 1, 24),
                disabledMinutes: () => {
                    return data_entrada.hour() === system_data_entrada.hour()
                        ? range(system_data_entrada.minute() + 1, 60)
                        : [];
                },
            };
        }
    };

    handleResponse = (response) => {
        this.setState({ loading: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleOk();
        } else message.error("Ocorreu um erro");
    };

    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                this.props.insert({ formulario: this.props.formulario, values }).then((response) => {
                    this.handleResponse(response);
                });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title="Registo de ponto"
                visible={this.props.isVisible}
                onOk={this.handleOk}
                onCancel={this.props.hiddeModal}
                confirmLoading={this.state.loading}
                width="50%"
            >
                <Form layout="vertical" onSubmit={this.handleOk}>
                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Data / hora do registo de entrada">
                                {getFieldDecorator("system_data_entrada", {
                                    initialValue: this.props.timeRegistration.system_data_entrada.value,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<DatePicker format="DD-MM-YYYY HH:mm" disabled style={{ width: "100%" }} />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Data / hora entrada">
                                {getFieldDecorator("hora_entrada", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        showTime={{ format: formatTime }}
                                        disabledTime={this.disabledDateTime}
                                        ref={(node) => (this.input = node)}
                                        locale={locale}
                                        format={formatDateTime}
                                        disabledDate={disabledDate}
                                        placeholder="Selecione a data"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={18}>
                        {this.props.timeRegistration.system_data_entrada.value !== null &&
                            !moment(this.props.timeRegistration.system_data_entrada.value).isSame(
                                this.props.timeRegistration.hora_entrada.value,
                                "minute"
                            ) && (
                                <Col xs={24} sm={24} md={24}>
                                    <Form.Item label="Motivo de alteração da data / hora de entrada">
                                        {getFieldDecorator("motivo_alteracao_data", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(
                                            <TextArea
                                                autoSize={{
                                                    minRows: 1,
                                                    maxRows: 6,
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            )}
                    </Row>
                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Observações">
                                {getFieldDecorator(
                                    "observacoes",
                                    {}
                                )(<TextArea autosize={{ minRows: 2, maxRows: 6 }} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    setField: actionCreators.setField,
    insert: actionCreators.insert,
};

const mapStateToProps = (state) => {
    return {
        timeRegistration: state.timeRegistration.domain.formCreate,
        formulario: state.timeRegistration.domain.metadata.formulario,
    };
};

const formCreate = Form.create({
    mapPropsToFields(props) {
        return {
            system_data_entrada: Form.createFormField({
                ...props.timeRegistration.system_data_entrada,
                value: props.timeRegistration.system_data_entrada.value,
            }),
            hora_entrada: Form.createFormField({
                ...props.timeRegistration.hora_entrada,
                value: props.timeRegistration.hora_entrada.value,
            }),
            observacoes: Form.createFormField({
                ...props.timeRegistration.observacoes,
                value: props.timeRegistration.observacoes.value,
            }),
            motivo_alteracao_data: Form.createFormField({
                ...props.timeRegistration.motivo_alteracao_data,
                value: props.timeRegistration.motivo_alteracao_data.value,
            }),
        };
    },
    onFieldsChange(props, fields) {
        props.setField(fields);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(formCreate(CreateTimeRegistration));
