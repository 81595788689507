import { actionType } from "./../constants/competence";
import { arrayToObjectById } from "./../../../components/util";

const initialState = {
    domain: {
        form: {
            tipo_competencia: { name: "tipo_competencia", value: null },
            designacao: { name: "designacao", value: null },
            contribui_qv: { name: "contribui_qv", value: null },
            n_inc_cri_neg: { name: "n_inc_cri_neg", value: null },
            n_inc_cri_posi: { name: "n_inc_cri_posi", value: null },
            n_sugests: { name: "n_sugests", value: null },
        },
        byId: {},
        metaData: {
            attributes: {},
        },
    },
    app: {
        isFetched: false,
        isFetching: false, //TODO: for check if app is calling to server
    },
};

export const competenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.insert:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        tipo_competencia: {
                            name: "tipo_competencia",
                            value: null,
                        },
                        designacao: { name: "designacao", value: null },
                        contribui_qv: { name: "contribui_qv", value: null },
                        n_inc_cri_neg: { name: "n_inc_cri_neg", value: null },
                        n_inc_cri_posi: { name: "n_inc_cri_posi", value: null },
                        n_sugests: { name: "n_sugests", value: null },
                    },
                },
            };

        case actionType.setField:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        ...action.payload,
                    },
                },
            };
        case actionType.select:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: Array.isArray(action.payload["linha"])
                            ? action.payload["linha"]
                            : [action.payload["linha"]],
                        id: "id_competencia",
                    }),
                    metaData: {
                        ...state.domain.metaData,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };
        case actionType.update:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        tipo_competencia: {
                            name: "tipo_competencia",
                            value: null,
                        },
                        designacao: { name: "designacao", value: null },
                        contribui_qv: { name: "contribui_qv", value: null },
                        n_inc_cri_neg: { name: "n_inc_cri_neg", value: null },
                        n_inc_cri_posi: { name: "n_inc_cri_posi", value: null },
                        n_sugests: { name: "n_sugests", value: null },
                    },
                },
            };
        default:
            return state;
    }
};
