import React from "react";
import { Button, List, Avatar, Divider, Icon, Badge } from "antd";
import { Utilitaries } from "../../business";

import { body } from "./constants";

const messageIcon = ["inbox", "message", "delete"];

export default function Menu(props) {
    return (
        <>
            <Button type="primary" block onClick={() => props.changeToPage(body.create)}>
                Nova Mensagem
            </Button>
            <List
                className="demo-loadmore-list"
                itemLayout="horizontal"
                dataSource={Utilitaries.toArray(props.folders).filter((item) => parseInt(item.id) <= 2)}
                style={{ marginTop: 5 }}
                renderItem={(item) => (
                    <List.Item className="qa_pointer" onClick={() => props.changeToPage(item.id)}>
                        <List.Item.Meta
                            avatar={
                                <Avatar className="icon-personal" size="small" icon={messageIcon[parseInt(item.id)]} />
                            }
                            title={
                                props.unread && parseInt(item.id) === 0 ? (
                                    <>
                                        <Badge status="error" />
                                        {item.nome}
                                    </>
                                ) : (
                                    item.nome
                                )
                            }
                        />
                    </List.Item>
                )}
            />

            <Divider style={{ backgroundColor: "#ccc" }} />

            <List
                className="demo-loadmore-list"
                itemLayout="horizontal"
                dataSource={props.folders.filter((item) => parseInt(item.id) > 2)}
                style={{ marginTop: 15, maxHeight: "300px", overflowY: "auto" }}
                renderItem={(item) => (
                    <List.Item className="qa_pointer" onClick={() => props.changeToPage(item.id)}>
                        <List.Item.Meta title={item.nome} />
                        <div>
                            <Icon type="folder" />
                        </div>
                    </List.Item>
                )}
            />
        </>
    );
}
