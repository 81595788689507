import React from "react";

export const TotalValues = ({ label, total }) => {
    label = label || "";
    return <span className="qa-sub-title-dashboard">{total ? `Total: ${total} ${label}` : ""}</span>;
};
export const TotalMultipleData = ({ labels = [], total = [] }) => {
    return (
        <span className="qa-sub-title-dashboard">
            {labels.map((item, index) => (
                <span className="qa-sub-title-multiple-data">{item + ": " + (total[index] || 0)}</span>
            ))}
        </span>
    );
};
