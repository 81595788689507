import React from "react";
import { Select, Form } from "antd";
import { getDataSource } from "../../constants/utils";
import { canCorrectField } from "../../utils/fields";

const { Option } = Select;

const getValueKeyName = (field) => {
    for (const key in field) {
        if (typeof field[key] === "object" && key !== "dataSource" && key !== "value") {
            for (const innerKey in field[key]) {
                if (typeof field[key][innerKey] === "object") {
                    return innerKey;
                }
            }
        }
    }
};

const getPath = (field) => {
    for (const key in field) {
        if (typeof field[key] === "object" && key !== "dataSource" && key !== "value") {
            for (const innerKey in field[key]) {
                if (typeof field[key][innerKey] === "object") {
                    return [key, innerKey];
                }
            }
        }
    }
};

const getValue = (valueItem, innerKey) => {
    if (!valueItem.value) {
        return [];
    } else {
        let values = valueItem.value[innerKey] || valueItem.value;
        const valueKeyName = getValueKeyName(valueItem);
        if (Array.isArray(values) && values.length > 1) {
            if (typeof values[0] === "object") {
                return values.map((item) => item[valueKeyName]);
            } else {
                return values;
            }
        } else if (Array.isArray(values) && values.length === 1) {
            if (typeof values[0] === "object") {
                return [values[valueKeyName] || values[0][valueKeyName]];
            } else {
                return values;
            }
        } else if (typeof values === "object" && !Array.isArray(values)) {
            return [values[valueKeyName]];
        } else {
            return [];
        }
    }
};

const QaFixedList = ({
    root,
    dataSource,
    itemData,
    label,
    formItemStyle,
    rowKey,
    currentTaskStatus,
    handleSelectChange,
    correctForm,
    renderContext,
    showFieldEditorModal,
}) => {
    const childRootKey = Object.keys(itemData).find((item) => typeof itemData[item] === "object");
    const innerChildPath = getPath(itemData);

    if (itemData.key === "cuidados_esp_pre") {
    }

    let innerChild;
    let value;
    if (Array.isArray(innerChildPath)) {
        innerChild = itemData[innerChildPath[0]][innerChildPath[1]];
        value = getValue(itemData, innerChildPath[0]);
    }

    if (!dataSource || dataSource.length === 0) {
        dataSource = getDataSource(innerChild);
    }

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus;

    const selectStyle = renderContext === "column" ? { width: showFieldEditorModal ? 150 : "100%" } : { width: "100%" };

    return (
        <Form.Item label={label} style={formItemStyle}>
            <Select
                allowClear
                disabled={disabled}
                // disabled={currentTaskStatus || canCorrectField(itemData)}
                value={value}
                onChange={(value) =>
                    handleSelectChange({
                        value,
                        key: itemData.key,
                        root,
                        rowKey,
                        childRootKey,
                        inputGroupPath: itemData.inputGroupPath
                    })
                }
                mode="multiple"
                style={selectStyle}
                placeholder={itemData["@placeholder"]}
            >
                {dataSource.map((item) => {
                    const optionValue = String(item[innerChild?.["@valor_lista"] || "value"]);
                    return (
                        <Option key={item[innerChild?.["@chave_lista"] || "key"]} title={optionValue}>
                            {optionValue}
                        </Option>
                    );
                })}
            </Select>
        </Form.Item>
    );
};

export default QaFixedList;
