import React, { Component } from "react";
import { Row, Form, Select, Col, Divider, message, Skeleton } from "antd";
import { connect } from "react-redux";

import ValidationPermissionModal from "./validationpermission";
import { editList, selectById, insert } from "../../../dynamic_list/actions/index";
import { selectDataForFixedSelectList, selectEstabeleciment } from "../../../fixed_list/actions/index";
import { select as selectFunctionalGroup } from "../../actions/funcional.group";
import { plainOptions } from "../../constants/contributor";
import AcessData from "./personalinfo.tabe.acess.data";
import PersonalData from "./personalinfo.tabe.personal.data";
import { toArray } from "../../../../components/util";

const { Option } = Select;

class PersonalInfoTabPane extends Component {
    state = {
        isVisibleToEdit: false, // if true the modal of dynamic list with list of item to edit will show,
        dynamicListName: "", // to set name of dynamic lis to edit
        user_category_selected: this.props.user_category,
        user_type_selected: this.props.register_type,
        checkedList: [],
        indeterminate: false,
        checkAll: false,
        show_modal: false, // if true the modal of "acessos a validações" will show
        isFetching: false,
    };
    _isMounted = false;
    componentDidMount() {
        this._isMounted = true;

        this.setState({
            user_category_selected: this.props.user_category,
            user_type_selected: this.props.register_type,
        });
        // const dias_trabalho = this.props?.ficha_colaborador?.dias_trabalho?.split(" ");
        //
        // this.onChangeWorkDays(dias_trabalho);
        this.getDataToPopulateSelectFields();
    }
    // componentDidUpdate() {
    //
    //
    //     // if (!this.state.user_category_selected) {
    //     //     this.setState({
    //     //         user_category_selected: this.props.user_category,
    //     //         user_type_selected: this.props.listOfItems[
    //     //             "tipo_colaborador"
    //     //         ].items.map(
    //     //             (item) =>{
    //     //                 if( item.id_tipo_colaborador === this.props.register_type) return
    //     //             }
    //     //                 &&
    //     //                 item.designacao
    //     //         ),
    //     //     });
    //     // }
    // }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getDataToPopulateSelectFields = async () => {
        this.setState({ isFetching: true });
        try {
            await this.props.selectFunctionalGroup({
                accao: "funcoes",
                todos_utilizadores: true,
                pagina: 1,
                resultados_pagina: 0,
            });
            await this.props.selectById({ accao: "consultar", nome: "tipo_colaborador" }).then((res) => {
                if (!res) message.error(`Erro ao carregar dados do tipo_colaborador!`);
            });
            await this.props.selectDataForFixedSelectList(
                { query: "tipo_contrato_ficha_colaborador" },
                "tipo_contrato_ficha_colaborador"
            );
            await this.props.selectDataForFixedSelectList({ query: "bancos" }, "bancos");
            await this.props.selectEstabeleciment({
                accao: "lista",
                lista: "lista_estabelecimentos",
                id_instituicao: "1",
            });
        } catch (error) {
            message.error("Erro ao carregar dados! ");
        } finally {
            this.setState({ isFetching: false });
        }
    };
    /** HANDLE CHANGES WHEN USER CHOOSE VALUE IN SELECT "Categoria de utilizador" */
    onChangeUserCategory = (value) => {
        this.setState({
            user_category_selected: value,
        });
        this.props.handleUserCategoryChanges(value);
    };
    /** HANDLE CHANGES WHEN USER CHOOSE VALUE IN SELECT "Tipo de utilizador" */
    onChangeUserType = (value, user_category) => {
        this.setState({
            user_type_selected: value,
        });
        this.props.getRegisterType(value, user_category);
    };
    hiddeEditModal = () => {
        this.setState({
            isVisibleToEdit: false,
        });
    };
    showModal = () => {
        this.setState({
            show_modal: true,
        });
    };
    /** handle dynamic list */
    returValidationPermissionFromModal = (acesso_validacao) => {
        this.props.getAcessoValidacao(acesso_validacao);
        this.setState({ show_modal: false });
    };
    /*** share username and full name to father component */
    onChangeUsername = (username, full_name) => {
        this.props.setUsernameAndFullName(username, full_name);
    };
    onChangeWorkDays = (checkedList) => {
        this.setState(
            {
                checkedList,
                indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
                checkAll: checkedList.length === plainOptions.length,
            },
            () => {
                const dias_trabalho = [];
                plainOptions.forEach((item) => {
                    if (this.state.checkedList.includes(item.label)) {
                        dias_trabalho.push(item.value);
                    }
                });

                this.props.getDaysOfWork(dias_trabalho);
            }
        );
    };
    onCheckAllChange = (e) => {
        this.setState({
            checkedList: e.target.checked ? plainOptions.map((item) => item.label) : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        const dias_trabalho = plainOptions.map((item) => item.value);

        this.props.getDaysOfWork(e.target.checked ? dias_trabalho : []);
    };
    renderLoaddingSkeleton = () => (
        <Row>
            <Col xs={8}>
                <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
            </Col>
            <Col xs={8}>
                <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
            </Col>
            <Col xs={8}>
                <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
            </Col>
        </Row>
    );
    render() {
        const { getFieldDecorator } = this.props.form;
        const { ficha_colaborador } = this.props;
        if (!this.state.isFetching) {
            return (
                <>
                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col xs={24} sm={12}>
                                <Form.Item label="Categoria de utilizador">
                                    {getFieldDecorator("tipo_utilizador", {
                                        initialValue:
                                            ficha_colaborador && typeof ficha_colaborador.tipo_utilizador !== "object"
                                                ? ficha_colaborador.tipo_utilizador
                                                : undefined,
                                    })(
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="Seleciona uma opção"
                                            onChange={this.onChangeUserCategory}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="2">Utilizador interno</Option>
                                            <Option value="1">Utilizador externo</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item label="Tipo de utilizador">
                                    {getFieldDecorator("tipo_colaborador", {
                                        initialValue:
                                            ficha_colaborador && typeof ficha_colaborador.tipo_colaborador !== "object"
                                                ? ficha_colaborador.tipo_colaborador
                                                : undefined,
                                    })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="Seleciona uma opção"
                                            onChange={(value, option) =>
                                                this.onChangeUserType(
                                                    option?.props?.user_type,
                                                    this?.props?.user_category
                                                )
                                            }
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.props.listOfItems["tipo_colaborador"] &&
                                            Object.entries(typeof this.props.listOfItems["tipo_colaborador"]).length !==
                                                0
                                                ? toArray(this.props.listOfItems["tipo_colaborador"].items).map(
                                                      (item) => {
                                                          if (
                                                              this.props.user_category === "2" &&
                                                              item.categoria_utilizador !== "1"
                                                          ) {
                                                              return (
                                                                  <Option
                                                                      key={item.id_tipo_colaborador}
                                                                      value={item.id_tipo_colaborador}
                                                                      user_type={item.designacao}
                                                                  >
                                                                      {item.designacao}
                                                                  </Option>
                                                              );
                                                          }

                                                          if (
                                                              this.props.user_category === "1" &&
                                                              item.categoria_utilizador !== "2"
                                                          ) {
                                                              return (
                                                                  <Option
                                                                      key={item.id_tipo_colaborador}
                                                                      value={item.id_tipo_colaborador}
                                                                      user_type={item.designacao}
                                                                  >
                                                                      {item.designacao}
                                                                  </Option>
                                                              );
                                                          }

                                                          return null;
                                                      }
                                                  )
                                                : null}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider orientation="left">Dados de acesso</Divider>
                        <AcessData
                            ficha_colaborador={ficha_colaborador}
                            form={this.props.form}
                            onChangeUsername={this.onChangeUsername.bind(this)}
                            fixedList={this.props.fixedList}
                        />
                        <Divider orientation="left">Dados Pessoal</Divider>
                        <PersonalData
                            parentState={this.props.parentState}
                            ficha_colaborador={ficha_colaborador}
                            form={this.props.form}
                            onChangeUsername={this.onChangeUsername.bind(this)}
                            fixedList={this.props.fixedList}
                            state={this.state}
                            listOfItems={this.props.listOfItems}
                            onChangeWorkDays={this.onChangeWorkDays.bind(this)}
                            onCheckAllChange={this.onCheckAllChange.bind(this)}
                            showModal={this.showModal.bind(this)}
                            getDataNascimento={this.props.getDataNascimento}
                            getDataAdmissaoServico={this.props.getDataAdmissaoServico}
                            user_category={this.props.user_category}
                            register_type={this.props.register_type}
                        />
                        {this.state.show_modal && (
                            <ValidationPermissionModal
                                ficha_colaborador={ficha_colaborador}
                                isVisible={this.state.show_modal}
                                senData={this.returValidationPermissionFromModal.bind(this)}
                            />
                        )}
                    </Form>
                </>
            );
        } else {
            return this.renderLoaddingSkeleton();
        }
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        listOfItems: state.dynamicList.domain.byId,
        fixedList: state.fixedList.domain.byId,
    };
};
const mapDispatchToProps = {
    editList,
    selectById,
    insert,
    selectEstabeleciment,
    selectDataForFixedSelectList,
    selectFunctionalGroup,
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoTabPane);
