/** constants */
const actionType = {
    select: "SELECT_DYNAMIC_LIST",
    selectById: "SELECT_DYNAMIC_LIST_BY_ID",
    update: "UPDATE_DYNAMIC_LIST",
    insert: "INSERT_DYNAMIC_LIST",
    remove: "REMOVE_DYNAMIC_LIST",
};

export default actionType;
