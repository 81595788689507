import React, { Component } from "react";
import {
    Divider,
    Checkbox,
    Popconfirm,
    Table,
    Row,
    Button,
    Select,
    Form,
    Input,
    Icon,
    message,
    Upload,
    Badge,
} from "antd";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";

import { selectDataFromExecQuery, postDataToGereFicheiros } from "../../actions/contributor";
import DaysModal from "./days.modal";
import ButtonDownloadDocument from "../../../utilities/component/button.download.document";
const { Option } = Select;

class FoulsTabPane extends Component {
    componentDidMount() {
        const { ficha_colaborador } = this.props;
        if (ficha_colaborador) {
            const { falta } = ficha_colaborador;
            if (falta?.falta) {
                let faltas = [];
                if (Array.isArray(falta.falta)) {
                    falta.falta.map((item, index) => {
                        faltas.push({ key: index, ...item });
                    });
                } else if (falta.falta.id_falta) {
                    faltas.push({ key: 0, ...falta.falta });
                }
                faltas = faltas.sort((a, b) => {
                    return a.key < b.key ? 1 : -1;
                });
                const countFaltas = faltas.length;
                this.setState({ faltas, countFaltas });
            }
            this.getFaltasSistema(ficha_colaborador.id_ficha_colaborador);
        }
        this.getDataFromServerToPopulateSelects();
    }
    /** get data from server to list in selects */
    getFaltasSistema = async (id) => {
        this.setState({ loading: true });
        try {
            const response = await this.props.selectDataFromExecQuery({
                query: "buscar_faltas_sistema_user",
                id,
            });
            if (response.result) {
                this.setState({ faltas_sistema: response.result });
            }
        } catch (error) {
            message.error("Erro ao carregar faltas de sistema!!");
        } finally {
            this.setState({ loading: false });
        }
    };
    getDataFromServerToPopulateSelects = async () => {
        try {
            const { ficha_colaborador } = this.props;

            const motivos = await this.props.selectDataFromExecQuery({
                query: "lista_motivos_faltas",
                tipo: "FC",
                username: ficha_colaborador.username,
            });
            const meses = await this.props.selectDataFromExecQuery({
                query: "meses",
            });
            const anos = await this.props.selectDataFromExecQuery({
                query: "lista_anos",
            });
            this.setState({
                motivos: motivos.result,
                meses: meses.result,
                anos: anos.result,
            });
        } catch (error) {
            message.error("Erro ao caregar lista!!");
        }
    };
    /** handle state */
    state = {
        loading: false,
        faltas_sistema: [],
        faltas: [],
        record: {},
        mode: "year", //for datapicker
        openDataPicker: false, //for datapicker
        defaultYear: "", //for datapicker
        searchText: "" /** search on the table */,
        show_days_modal: false,
        countFaltas: 1,
    };
    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    /****************************** */
    /** handle table columns */
    //other fouls column
    faltas_column = [
        {
            title: "Motivo",
            dataIndex: "motivo_falta",
            width: "25%",
            ...this.getColumnSearchProps("motivo_falta"),
            onFilter: (value, record) => {
                const filteredValue = this.state.motivos
                    .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.motivo_falta);
                return filteredValue.includes(record["motivo_falta"]);
            },
            render: (text, record) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona uma opção"
                    optionFilterProp="children"
                    onChange={(value) => this.handleFieldsChange("motivo_falta", value, record)}
                    defaultValue={typeof text !== "object" && text !== "" ? text : undefined}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {this.state.motivos &&
                        this.state.motivos.map((item) => (
                            <Option key={item.motivo_falta} value={item.motivo_falta}>
                                {item.designacao}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            title: "Ano",
            dataIndex: "ano",
            width: "10%",
            ...this.getColumnSearchProps("ano"),
            onFilter: (value, record) => {
                const filteredValue = this.state.anos
                    .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.ano);
                return filteredValue.includes(record["ano"]);
            },
            render: (text, record) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona uma opção"
                    optionFilterProp="children"
                    onChange={(value) => this.handleFieldsChange("ano", value, record)}
                    defaultValue={typeof text !== "object" && text !== "" ? text : undefined}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {this.state.anos &&
                        this.state.anos
                            .filter((it) => it.ano <= new Date().getFullYear())
                            .map((item) => (
                                <Option key={item.ano} value={item.ano}>
                                    {item.designacao}
                                </Option>
                            ))}
                </Select>
            ),
        },
        {
            title: "Mês",
            dataIndex: "mes",
            width: "10%",
            ...this.getColumnSearchProps("mes"),
            onFilter: (value, record) => {
                const filteredValue = this.state.meses
                    .filter((item) => item.nome.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.mes);
                return filteredValue.includes(record["mes"]);
            },
            render: (text, record) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona uma opção"
                    optionFilterProp="children"
                    onChange={(value) => this.handleFieldsChange("mes", value, record)}
                    defaultValue={typeof text !== "object" && text !== "" ? text : undefined}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {this.state.meses &&
                        this.state.meses
                            .filter((it) => (record.id_falta ? true : it.mes <= new Date().getMonth() + 1))
                            .map((item) => (
                                <Option key={item.mes} value={item.mes}>
                                    {item.nome}
                                </Option>
                            ))}
                </Select>
            ),
        },
        {
            title: "Justificação",
            dataIndex: "justificacao",
            align: "center",
            width: "10%",
            render: (text, record) => (
                <Checkbox
                    defaultChecked={text === "t" && typeof text !== "object" ? true : false}
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    onChange={(e) => this.handleFieldsChange("justificacao", e.target.checked, record)}
                />
            ),
        },
        {
            title: "Documento",
            dataIndex: "ficheiro",
            align: "center",
            width: "15%",
            render: (text, record) => {
                if (record["ficheiro"] && Object.entries(record["ficheiro"]).length !== 0) {
                    return <ButtonDownloadDocument ficheiro={record["ficheiro"]} text="Descarregar" />;
                } else {
                    return (
                        <Upload
                            defaultFileList={typeof text !== "object" && text ? [text] : []}
                            beforeUpload={(file) => {
                                this.uploadFileToServer(file, record);
                                return false;
                            }}
                            name="file"
                            multiple={false}
                            onRemove={() => this.onRemove("ficheiro", record)}
                        >
                            {!record["ficheiro"] ? (
                                <Button type="primary" icon="file-add" />
                            ) : Object.entries(record["ficheiro"]).length === 0 ? (
                                <Button type="primary" icon="file-add" />
                            ) : null}
                        </Upload>
                    );
                }
            },
        },
        {
            title: "Dias",
            dataIndex: "days",
            width: "5%",
            render: (text, record) => (
                <Badge count={typeof record["numero_dias"] !== "object" ? record["numero_dias"] : 0}>
                    <Button
                        icon={typeof record["faltas_colaborador_dias"] !== "object" ? "check" : "plus"}
                        type="primary"
                        disabled={
                            record.ano && typeof record.ano !== "object" && record.mes && typeof record.mes !== "object"
                                ? false
                                : true
                        }
                        onClick={() => this.showDaysModel(record)}
                    />
                </Badge>
            ),
        },
        {
            title: "Ações",
            dataIndex: "action",
            width: "10%",
            render: (text, record) => (
                <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => this.handleDelete(record.key)}>
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Eliminar
                    </a>
                </Popconfirm>
            ),
        },
    ];
    //system fouls column
    faltas_sistema_column = [
        {
            title: "Data",
            dataIndex: "data_f_si",
            width: 200,
            ...this.getColumnSearchProps("data_f_si"),
        },
        {
            title: "Justificação",
            dataIndex: "justificacao_f_si",
            ...this.getColumnSearchProps("justificacao_f_si"),
        },
    ];
    /*** upload file */
    uploadFileToServer = async (file, record) => {
        const data = {
            accao: "upload",
            nome: file.name,
            descricao: file.type,
            ficheiro: file,
            chave_sessao: "teste",
        };
        try {
            const response = await this.props.postDataToGereFicheiros(data);
            if (response.sucesso && response.sucesso !== "0") {
                this.handleFieldsChange("ficheiro", response.sucesso, record);
            } else {
                message.error("Erro ao gravar ficheiro");
            }
        } catch (error) {
            message.error("Ocorreu um erro");
        }
    };

    /** handle dynamics table actions */
    handleAdd = () => {
        const { faltas, countFaltas } = this.state;
        const newData = {
            key: countFaltas,
        };
        this.setState({
            faltas: [newData, ...faltas],
            countFaltas: countFaltas + 1,
        });
    };
    handleDelete = (key) => {
        const faltas = [...this.state.faltas];
        this.setState(
            {
                faltas: faltas.filter((item) => item.key !== key),
            },
            this.props.getFaltas(this.state.faltas)
        );
    };
    onRemove = (dataIndex, record) => {
        const { faltas } = this.state;
        delete record[dataIndex];
        const index = faltas.findIndex((item) => item.key === record.key);
        faltas[index] = record;
        this.setState({
            faltas,
        });
    };
    /** handle modal status */
    showDaysModel = (record) => {
        this.setState({
            record,
            show_days_modal: true,
        });
    };
    hiddeDaysModal = (record) => {
        const { faltas } = this.state;
        const index = faltas.findIndex((item) => item.key === record.key);
        faltas[index] = record;
        this.setState({ faltas, show_days_modal: false }, this.props.getFaltas(this.state.faltas));
    };
    /** handle inputs change value */
    handleFieldsChange = (dataIndex, value, record) => {
        record[dataIndex] = value;
        const { faltas } = this.state;
        const index = faltas.findIndex((item) => item.key === record.key);
        faltas[index] = record;
        this.setState({ faltas }, this.props.getFaltas(this.state.faltas));
    };
    render() {
        const { ficha_colaborador } = this.props;
        return (
            <div>
                {ficha_colaborador.id_ficha_colaborador && (
                    <>
                        <Table
                            title={() => <strong>Faltas de sistema</strong>}
                            size="middle"
                            loading={this.state.loading}
                            columns={this.faltas_sistema_column}
                            dataSource={this.state.faltas_sistema}
                        />
                        <Divider />
                    </>
                )}

                <Row>
                    <Button
                        xs={24}
                        sm={12}
                        onClick={this.handleAdd}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{ marginBottom: 16, float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Row>
                <Table
                    rowKey={(record) => record.key}
                    title={() => <strong>Outras Faltas</strong>}
                    size="middle"
                    columns={this.faltas_column}
                    dataSource={this.state.faltas}
                />
                {this.state.show_days_modal && (
                    <DaysModal
                        username={this.props.username}
                        nome_completo={this.props.nome_completo}
                        record={this.state.record}
                        isVisible={this.state.show_days_modal}
                        hiddeDaysModal={this.hiddeDaysModal.bind(this)}
                        dias_trabalho={this.props.dias_trabalho}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    selectDataFromExecQuery,
    postDataToGereFicheiros,
};

export default connect(null, mapDispatchToProps)(FoulsTabPane);
