import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Radio, Select, Input } from "antd";
import { useParams } from "react-router-dom";

import { selectDataFromExecQuery } from "../../actions/funcional.group";

const { Option } = Select;
const { TextArea } = Input;

const EXEC_QUERY_NAME = {
    busca_grupo_func_exist: "busca_grupo_func_exist",
    busca_grupo_exist: "busca_grupo_exist",
    auto_func_grupos_func: "auto_func_grupos_func",
    lista_funcoes: "lista_funcoes",
};

const Description = ({
    selectDataFromExecQuery, //redux action
    form, // from father component
    selectedGrupoExist,
    setSelectedGroupExist,
    dataFromExistGroup,
    setDescricaoFuncao,
    setListOfAutorizationIds,
    autorizations,
}) => {
    const { id } = useParams();
    const { getFieldDecorator } = form;

    const [grupoExist, setGroupExist] = useState(false);
    const [selGrupoExist, setSelGroupExist] = useState([]);
    const [funcaoAutorization, setFuncaoAutorization] = useState([]);
    const [listFunction, setListFunction] = useState([]);

    /**
     * check if id is pass by route param
     */
    // useEffect(() => {
    // 	if (selectedGrupoExist) setSelectedGroupExist(selectedGrupoExist);
    // 	else if (id) setSelectedGroupExist(id);
    // }, [id, selectedGrupoExist]);

    /**
     * fetch all functions to populate "funcao superior field"
     */
    useEffect(() => {
        (async () => {
            const response = await selectDataFromExecQuery({
                query: EXEC_QUERY_NAME.lista_funcoes,
            });
            if (response.result) {
                if (Array.isArray(response.result)) {
                    setListFunction(response.result);
                }
            }
        })();
    }, []);

    /**
     * fetch all exist group
     */
    useEffect(() => {
        if (grupoExist) {
            (async () => {
                const response = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.busca_grupo_func_exist,
                });
                if (response.result) {
                    if (Array.isArray(response.result)) {
                        const filteredResponse = response.result.filter((item) => item.descricao !== "");
                        setSelGroupExist(filteredResponse);
                    }
                }
            })();
        }
    }, [grupoExist]);

    /**
     * fetch autorizations by group id and select group
     */
    useEffect(() => {
        if (selectedGrupoExist || id) {
            (async () => {
                const response = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.auto_func_grupos_func,
                    id_ficha_funcao: id || 0,
                    sel_grupo_exist: selectedGrupoExist,
                });
                if (response.result) {
                    if (Array.isArray(response.result)) {
                        const funcao_autorizations_id = response.result.map((item) => item.autorizacao);
                        setFuncaoAutorization(funcao_autorizations_id);

                        setListOfAutorizationIds(funcao_autorizations_id.join(","));
                    }
                }
            })();
        }
    }, [selectedGrupoExist]);

    return (
        <>
            <Row gutter={18}>
                {!id && (
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Carregar grupo existente?">
                            {getFieldDecorator("grupo_exist", {
                                initialValue: grupoExist,
                            })(
                                <Radio.Group
                                    buttonStyle="solid"
                                    onChange={() => setGroupExist(!grupoExist)}
                                    // value={grupoExist}
                                >
                                    <Radio.Button value={true}>Sim</Radio.Button>
                                    <Radio.Button value={false}>Não</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Col>
                )}
                {grupoExist && (
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Grupo existente">
                            {getFieldDecorator("sel_grupo_exist", {
                                initialValue: selectedGrupoExist,
                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    autoFocus
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    onChange={(value) => setSelectedGroupExist(value)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {selGrupoExist.map((item) => {
                                        return (
                                            <Option value={item.sel_grupo_exist} key={item.sel_grupo_exist}>
                                                {item.descricao}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={18}>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Código">
                        {getFieldDecorator("codigo", {
                            initialValue: dataFromExistGroup.codigo,
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                        })(<Input onChange={(e) => {}} />)}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Estado">
                        {getFieldDecorator("activo", {
                            initialValue: dataFromExistGroup.activo,
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                        })(
                            <Select onChange={(value) => {}}>
                                <Option value={"true"}>Ativo</Option>
                                <Option value={"false"}>Inativo</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Descrição">
                        {getFieldDecorator("descricao_funcao", {
                            initialValue: dataFromExistGroup.descricao_funcao,
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                        })(<Input onChange={(e) => setDescricaoFuncao(e.target.value)} />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Função superior hierárquica">
                        {getFieldDecorator("funcao_superior", {
                            initialValue: dataFromExistGroup.funcao_superior,
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                onChange={(value) => {}}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {listFunction.map((item) => {
                                    return (
                                        <Option value={item.funcao_superior} key={item.funcao_superior}>
                                            {item.descricao}
                                        </Option>
                                    );
                                })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Autorizações do grupo funcional">
                        {getFieldDecorator("autorizacao_func", {
                            initialValue: funcaoAutorization.length > 0 ? funcaoAutorization : undefined,
                        })(
                            <Select onChange={(value) => {}} allowClear mode="multiple" style={{ width: "100%" }}>
                                {autorizations.map((item) => {
                                    return (
                                        <Option key={item.autorizacao} value={item.autorizacao}>
                                            {item.designacao}
                                        </Option>
                                    );
                                })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Missão">
                        {getFieldDecorator("missao", {
                            initialValue: dataFromExistGroup.missao,
                        })(<TextArea onChange={(e) => {}} rows={3} />)}
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

const mapDispatchToProps = {
    selectDataFromExecQuery,
};

export default connect(null, mapDispatchToProps)(Description);
