import {
    FormAttributesProps,
    FormInputsProps,
    FormRootElementAttributesProps,
    XMLFormInputProps,
    XMLFormProps,
    XMLFormRootElementProps,
} from "../../types/formTypes";

export function removeArrayFromXML(form: XMLFormProps) {
    const rootElement = form["@elemento_raiz"];

    if (!rootElement) {
        return form;
    }

    if (form[rootElement]) {
        Object.entries(form[rootElement]).forEach(([key, input]) => {
            if (typeof input === "object" && Array.isArray(input)) {
                if (input.length === 1) {
                    form[rootElement][key] = input[0];
                } else if (input.length > 1) {
                    const igualArray = input.filter(
                        (item) => item["@persiste"] === "nao" && item["@visivel"] === "nao"
                    );

                    const theyAreSome = igualArray.length === input.length;

                    if (theyAreSome) {
                        form[rootElement][key] = input[0];
                    }
                }
            }
        });
    }

    return form;
}

interface GenericInputProps {
    [key: string]: XMLFormInputProps;
}

export const getFormAttributesAndFields = (form: XMLFormProps) => {
    let formRootElement: XMLFormRootElementProps = {} as XMLFormRootElementProps;

    for (const key in form) {
        if (!key.startsWith("@")) {
            formRootElement = { ...form[key] };
        }
    }

    const formInputs: GenericInputProps = {};
    for (const key in formRootElement) {
        if (!key.startsWith("@")) {
            formInputs[key] = formRootElement[key];
        }
    }

    const formAttributes: FormAttributesProps = {
        code: form["@codigo"],
        name: form["@nome"],
        version: form["@versao"],
        rootElement: form["@elemento_raiz"] || "",
        status: form["@estado"],
        corrections: form["@correcoes"],
        cleanId: form["@clean_id"],
        tableId: form["@id_tabela"],
        revision: form["@revisao"],
    };

    const formRootElementAttributes: FormRootElementAttributesProps = {
        title: formRootElement["@titulo"],
        version: formRootElement["@versao"],
        tables_prefix: formRootElement["@prefixo_tabelas"],
        table: formRootElement["@tabela"],
        buttons: formRootElement["@botoes"],
        when_save: formRootElement["@ao_guardar"],
        when_loading: formRootElement["@aoCarregar"],
        printer: formRootElement["@imprimir"],
        report: formRootElement["@relatorio"],
        type: formRootElement["@tipo"],
        consult_form: formRootElement["@consulta_formulario"] === "sim",
        ignore_data: formRootElement["@ignora_dados"] === "sim",
    };

    return { formAttributes, formInputs, formRootElementAttributes };
};

export const addKeyToFirstsLevelInputs = (formInputs: GenericInputProps, formAttributes: FormAttributesProps) => {
    const formInputsWithKeyInRoot: GenericInputProps = {};
    for (const key in formInputs) {
        if (typeof formInputs[key] === "object") {
            formInputsWithKeyInRoot[key] = {
                form_key: formAttributes.rootElement,
                form_name: formAttributes.name,
                ...formInputs[key],
                key: key,
            };
        } else {
            formInputsWithKeyInRoot[key] = {
                key,
                value: formInputs[key],
            };
        }
    }
    return formInputsWithKeyInRoot;
};

const XML_MAP = {
    "@tab": "tab",
    "@etiqueta": "label",
    "@grupo": "group",
    "@obrigatorio": "required",
    "@tipo": "type",
    "@elemento_grafico": "graphic_element",
    "@dados": "data_query",
    "@chave_lista": "key_list",
    "@valor_lista": "value_list",
    "@valores": "values",
    "@estilo": "style",
    "@visivel": "visible",
    "@restricao": "restriction",
    "@apenas_leitura": "read_only",
    "@relevante": "relevant",
    "@msg_erro": "error_message",
    "@ajuda": "help",
    "@imagem": "image",
    "@calcular": "calculate",
    "@quando_valor_alterado": "when_value_changed",
    "@campos_lista": "list_fields",
    "@seleccionar_todos": "select_all",
    "@persiste": "persist",
    "@tabela": "table",
    "@popupform": "popupform",
    "@param_link": "param_link",
    "@webtipo": "webtipo",
    "@webaccao": "webaccao",
    "@botoes_transacao": "transaction_buttons",
    "@botao_eliminar": "remove_buttons",
    "@param_popup": "popup_param",
    "@rowSpan": "row_span",
    "@mostrar_botoes_add_rem": "show_buttons_add_remove",
    "@resultados_pagina": "result_per_page",
    "@ignora_parametros": "ignoring_parameters",
    "@mascara": "mask",
    "@carregar_accao": "load_action",
    "@relatorio": "report",
    $: "default_value",
};

export function mapXMLInputAttributes(input: XMLFormInputProps): FormInputsProps {
    let newInput: FormInputsProps = {
        key: input.key || "",
        ...input,
    };

    const inputProps = Object.keys(input);

    Object.entries(XML_MAP).forEach(([key, value]) => {
        const hasProp = inputProps.find((el) => el === key);
        if (hasProp) {
            newInput[value] = input[key];
            delete newInput[key];
        }
    });

    const children = Object.keys(input).filter(
        (key) => !key.startsWith("@") && typeof input[key] === "object" && !Array.isArray(input[key])
    );

    if (children.length > 0) {
        children.forEach((key) => {
            newInput[key] = mapXMLInputAttributes(input[key]);
        });
    }

    return newInput;
}

export const INPUTS_TO_DISPLAY_WITH_LABEL = ["valencia_designacao", "estabelecimento_nome", "cliente_nome"];
