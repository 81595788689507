import { actionType } from "../constants/requirement";
import { sendHttpRequest } from "../../../components/http.request";

export const insert = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            return true;
        } else return false;
    });
};

export const update = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            return true;
        } else return false;
    });
};

export const remove = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) return true;
        else return false;
    });
};

export const select = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};
