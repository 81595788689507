import { actionTypes } from "../constants/index";
import { arrayToObjectById } from "../../../components/util";

const initialState = {
    domain: { form: {}, byId: {} },
    app: { isPoliticsFetched: false },
};

export const politicsReducer = (state = initialState, action) => {
    switch (action.type) {
        //TODO: review this bug
        case actionTypes.getPolitics:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: action.payload,
                        id: "id",
                    }),
                },
                app: {
                    isPoliticsFetched: true,
                },
            };

        case actionTypes.setPolitic:
            return {};

        default:
            return state;
    }
};
