import React from "react";
import { Col, Card } from "antd";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import EmptyData from "./emptyData";
import { TotalValues } from "./totalValues";
import ChartHelp from "./chartHelp";

const HorizontalBarChart = ({ metadata, dataSource }) => {
    let data = [],
        cols = null,
        total = 0;
    if (dataSource && dataSource.length !== 0) {
        total = dataSource.reduce((total, item) => total + Number(item.valor), 0);
        data = dataSource.map((item) => {
            const valor = Number(item.valor);
            return {
                label: item.label,
                valor,
                percentage: (valor * 100) / total,
            };
        });
        cols = {
            label: {
                tickInterval: 2,
                alias: metadata.horizontalLabel,
            },
            valor: {
                alias: metadata.verticalLabel,
            },
        };
    }
    if (metadata.checkData && !dataSource) {
        return null;
    }

    return (
        <Col xs={24} md={12}>
            <Card
                size="small"
                title={metadata.title ? metadata.title : " "}
                extra={metadata.help ? <ChartHelp text={metadata.help} /> : null}
                className={"qa-title-dashboard"}
            >
                {!dataSource || dataSource.length === 0 ? (
                    <EmptyData />
                ) : (
                    <Chart height={344} data={data} scale={cols} padding={"auto"} forceFit>
                        <TotalValues label={metadata.labelTotal} total={total.toFixed(2)} />
                        <Axis name="label" title />
                        <Axis name="valor" title />
                        <Tooltip
                            crosshairs={{
                                type: "y",
                            }}
                        />
                        <Geom
                            type="interval"
                            position="label*valor"
                            tooltip={[
                                "label*valor*percentage",
                                (label, valor, percentage) => {
                                    return {
                                        name: `${metadata.verticalLabel}: ${valor}/${total.toFixed(2)}`,
                                        value: percentage ? percentage.toFixed(2) + "%" : "",
                                        title: label,
                                    };
                                },
                            ]}
                        />
                    </Chart>
                )}
            </Card>
        </Col>
    );
};

export default HorizontalBarChart;
