import React, { useEffect } from "react";
import { Form, Row, Col, Input, Button, Descriptions } from "antd";

const SeeForm = (props) => {
    useEffect(() => {
        props.configModal({
            title: "Visualização Formulário",
            width: "80%",
            top: "5%",
        });
    });

    return (
        <Form layout="vertical">
            <Row gutter={18}>
                <Col xs={24} md={6}>
                    <Form.Item label="{$param.idProcesso}">
                        <Input />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item label="{$param.idActividade}">
                        <Input />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item label="{$param.idModelo}">
                        <Input />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item label="&nbsp;">
                        <Button type="primary">Recarregar</Button>
                    </Form.Item>
                </Col>
            </Row>

            <div
                style={{
                    border: "1px solid silver",
                    borderRadius: "4px",
                    borderStyle: "dashed",
                    padding: 20,
                }}
            >
                <Descriptions
                    title="Titulo Formulario"
                    bordered
                    size="small"
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label="Processo">Processo A</Descriptions.Item>
                    <Descriptions.Item label="Tarefa">Tarefa 1</Descriptions.Item>
                    <Descriptions.Item label="Modelo">V01</Descriptions.Item>
                </Descriptions>
                <br />
                <Row gutter={18}>
                    {props.fields.map((field) => (
                        <Col xs={24} md={6}>
                            <Form.Item label={field.name}>
                                <Input value={field.value} placeholder={field.placeHolder} />
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
            </div>
        </Form>
    );
};

export default SeeForm;
