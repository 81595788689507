import React, { createContext, useContext } from "react";
import {
    FieldEditorProps,
    GenericObject,
    HandleOnInputChangeValueProps,
    handleOnTableChangeValueProps,
    InputInTableProps,
} from "../../../types/common";
import {
    AddTableRowProps,
    AllModalProps,
    FormInputCalcTriggerProps,
    FormInputDependenceStateProps,
    FormInputsObjectProps,
    FormInputsProps,
    InputCalcTriggerProps,
    InputDependenceProps,
    ModalProps,
    RemoveTableRowProps,
} from "../../../types/formTypes";
import {
    addTableRow,
    handleInputChangeCalcTrigger,
    handleInputChangeValue,
    handleTableChangeDataSource,
    removeTableRow,
} from "../handle/stateHandle";
import {
    ActionsProps,
    getFormInputCalcTrigger,
    GetFormInputCalcTriggerProps,
    getInputCalcTrigger,
    getInputThatDependsOnOtherInput,
} from "../../../utils/FormMapper/formHandle";
// import { downFile } from "../../../../modules/process_execution/constants/utils";
import { regexValidate } from "../handle/regexConversion";
// import { getInputCalcTrigger, getInputThatDependsOfCurrentField, InputDependsOfCurrentField } from "../../../utils/FormMapper/formHandle";

interface FormStateProviderProps {
    state: FormInputsObjectProps;
    setState: (state: any) => void;
    modalState: AllModalProps;
    setModalState: React.Dispatch<React.SetStateAction<AllModalProps>>;
    formInputDependenceState: FormInputDependenceStateProps;
    setFormInputDependenceState: React.Dispatch<React.SetStateAction<FormInputDependenceStateProps>>;
    setInputCalcTrigger: React.Dispatch<React.SetStateAction<FormInputCalcTriggerProps>>;
    inputCalcTrigger: FormInputCalcTriggerProps;
    popup_params?: GenericObject;
    setFieldEditor: React.Dispatch<React.SetStateAction<FieldEditorProps>>;
    mask?: string;
    triedSave: boolean;
}

interface FormStateContextProps {
    state: FormInputsObjectProps;
    modals: AllModalProps;
    changeModalState: (component: FormInputsProps, modalState: boolean, tableRow?: InputInTableProps) => void;
    handleOnInputChangeValue: (props: HandleOnInputChangeValueProps) => void;
    formInputDependenceState: FormInputDependenceStateProps;
    setFormInputDependenceChange: (componetProprerties: FormInputsProps) => void;
    inputCalcTrigger: FormInputCalcTriggerProps;
    buttonClick: (input: FormInputsProps, rowTable?: InputInTableProps) => void;
    handleTableAddRow: ({ table_key, columns, input_group_path }: AddTableRowProps) => void;
    handleTableRemoveRow: ({ table_key, columns, input_group_path }: RemoveTableRowProps) => void;
    handleOnTableChangeDataSource: (props: handleOnTableChangeValueProps) => void;
    setFieldEditor: React.Dispatch<React.SetStateAction<FieldEditorProps>>;
    popup_params?: GenericObject;
    triedSave: boolean;
}

const FormStateContext = createContext<FormStateContextProps>({} as FormStateContextProps);

export const FormStateProvider: React.FC<FormStateProviderProps> = ({
    state,
    setState,
    modalState,
    setModalState,
    children,
    formInputDependenceState,
    setFormInputDependenceState,
    setInputCalcTrigger,
    inputCalcTrigger,
    popup_params = {},
    setFieldEditor,
    triedSave,
}) => {
    console.log("FormStateProvider");
    console.log(state);
    function handleOnInputChangeValue({
        value,
        componentsProperties,
        // key,
        // mask,
        // root,
        // input_group_path,
        row_key,
        full_path,
        child_root_key,
        select_value_text,
        more_values,
    }: HandleOnInputChangeValueProps) {
        setState((prev: any) => {
            const new_state = handleInputChangeValue({
                value,
                key: componentsProperties.key,
                root: componentsProperties.root,
                input_group_path: componentsProperties.input_group_path,
                row_key,
                child_root_key,
                select_value_text,
                more_values,
                state: { ...prev },
            });

            return new_state;
        });

        const inputsCalcTrigger = getFormInputCalcTrigger({
            componentsProperties,
            row_key,
            form_inputs: state,
            full_path,
        } as GetFormInputCalcTriggerProps);

        setInputCalcTrigger((prevState: any) => {
            const newState = handleInputChangeCalcTrigger({ prevState, inputsCalcTrigger });
            return { ...newState };
        });

        setFormInputDependenceChange(componentsProperties, row_key);
    }

    function setFormInputDependenceChange(componentsProperties: FormInputsProps | FormInputsProps[], row_key?: string) {
        const depends: ActionsProps[] = [];
        if (Array.isArray(componentsProperties)) {
            componentsProperties.forEach((component: FormInputsProps) => {
                const deps = getInputThatDependsOnOtherInput(component, state, row_key);
                depends.push(...deps);
            });
        } else {
            const deps = getInputThatDependsOnOtherInput(componentsProperties, state, row_key);
            depends.push(...deps);
        }

        const keys = depends.reduce((accumulator, current) => {
            const item = {
                key: `${current.full_path!}`,
                action: [current.action],
                trigger: false,
            };

            if (accumulator.length > 0) {
                const item_ = accumulator.find((it: any) => it.key === current.full_path);
                if (item_) {
                    item.action = [...item_.action, current.action];
                }
            }

            accumulator.push(item);

            return accumulator;
        }, [] as InputDependenceProps[]);

        setFormInputDependenceState((prevState: any) => {
            const newState = {
                ...prevState,
                ...keys.reduce((acc, action) => {
                    acc[action.key] = {
                        action: action.action,
                        key: action.key,
                        trigger: !prevState[action.key]?.trigger,
                    };
                    return acc;
                }, {} as FormInputDependenceStateProps),
            };

            return newState;
        });
    }

    function buttonClick(item: FormInputsProps, rowTable?: InputInTableProps) {
        setFormInputDependenceChange(item, rowTable?.row_key);
    }

    function changeModalState(component: FormInputsProps, _modalState: boolean, tableRow?: InputInTableProps) {
        const modalKey = component.popupform!!;
        let id: any;
        if (tableRow) {
            id = tableRow.row_data?.[component.key];
        } else {
            id = component.value;
        }

        id = typeof id === "object" ? id?.id : id;

        let params: GenericObject = {
            ...popup_params,
        };
        if (component.popup_param) {
            const expression = regexValidate({
                expression: component.popup_param,
                formState: state,
                componentProperties: component,
                rowTable: tableRow,
            });

            if (expression) {
                const paramsList = expression.split(";") || [];

                paramsList.forEach((param) => {
                    let [key, value] = param.split(",");
                    key = key.replace(/\W/g, "").trim();

                    if (key) {
                        value = value?.replace("]", "").trim();

                        if (value?.startsWith("'") && value?.endsWith("'")) {
                            value = value.substring(1, value.length - 1);
                        }

                        params[key] = value;
                    }
                });
            }
            console.log("expression", expression, params);
        }

        if (state?.processo) {
            params.processo = state?.processo?.value;
        }

        // if (state?.modelo) {
        //     params.idModelo = state?.modelo?.value;
        // }

        if (state?.estabelecimento) {
            params.idEstabelecimento = state?.estabelecimento?.value;
        }

        if (state?.valencia) {
            params.idValencia = state?.valencia?.value;
        }

        const modal = { ...modalState };
        if (modal[modalKey]) {
            if (component.form_key) {
                params = {
                    [`id_${component.form_key}`]: state[`id_${component.form_key}`]?.value || "",
                    ...params,
                };
            }

            const modalProps: ModalProps = { ...modal[modalKey], isVisible: _modalState, params, id };
            if (_modalState) {
                modalProps.field = component;
                modalProps.rowKey = tableRow?.row_key;
            }

            setModalState({
                ...modalState,
                [modalKey]: {
                    ...modalProps,
                },
            });
        }
    }

    function handleTableAddRow({ table_key, columns, input_group_path }: AddTableRowProps) {
        setState((prev: any) => {
            const new_state = addTableRow({
                table_key,
                columns,
                input_group_path,
                state: { ...prev },
            });
            return new_state;
        });
    }

    function handleTableRemoveRow({ table_key, row_key, columns, input_group_path }: RemoveTableRowProps) {
        const new_state = removeTableRow({
            table_key,
            row_key,
            input_group_path,
            state: { ...state },
        });

        setState(new_state);

        const objectInputsCalc: FormInputCalcTriggerProps = {};
        columns.forEach((column) => {
            const inputsColumnCalcTriggers = getInputCalcTrigger({
                form_inputs: state,
                key: column.key,
                row_key,
                typeProperty: "calculate",
                root: table_key,
            });

            inputsColumnCalcTriggers.forEach((el) => {
                if (!el.full_path.startsWith("table@")) {
                    objectInputsCalc[el.full_path] = { ...el, calculateTrigger: true };
                }
            });
        });

        let inputsCalcTrigger: InputCalcTriggerProps[] = Object.values(objectInputsCalc);

        setInputCalcTrigger((prevState: any) => {
            const newState = handleInputChangeCalcTrigger({ prevState, inputsCalcTrigger });
            return { ...newState };
        });
    }

    function handleOnTableChangeDataSource({
        dataSource,
        key,
        input_group_path,
        columns,
    }: handleOnTableChangeValueProps) {
        let newTableDataSource: any = {};

        setState((prev: any) => {
            const new_state = handleTableChangeDataSource({
                key,
                input_group_path,
                dataSource,
                state: { ...prev },
            });
            newTableDataSource = new_state.newTableDataSource;
            return { ...new_state.state };
        });

        //trigger dependencies
        const objectInputsCalc: FormInputCalcTriggerProps = {};
        columns.forEach((column) => {
            const inputsColumnCalcTriggers = getInputCalcTrigger({
                form_inputs: state,
                key: column.key,
                // row_key,
                typeProperty: "calculate",
                root: key,
            });

            inputsColumnCalcTriggers.forEach((el) => {
                if (!el.full_path.startsWith("table@")) {
                    objectInputsCalc[el.full_path] = { ...el, calculateTrigger: true };
                }
            });
        });

        //trigger dependencies
        Object.keys(newTableDataSource).forEach((table_row) => {
            setFormInputDependenceChange(columns, table_row);
        });

        let inputsCalcTrigger: InputCalcTriggerProps[] = Object.values(objectInputsCalc);

        setInputCalcTrigger((prev) => {
            const inputsCalc = { ...prev };
            Object.keys(inputsCalc).forEach((input) => {
                if (input.startsWith(`table@${key}`)) {
                    delete inputsCalc[input];
                }
            });
            const newState = handleInputChangeCalcTrigger({ prevState: inputsCalc, inputsCalcTrigger });
            return { ...newState };
        });
    }

    return (
        <FormStateContext.Provider
            value={{
                state,
                handleOnInputChangeValue,
                setFormInputDependenceChange,
                formInputDependenceState,
                inputCalcTrigger,
                buttonClick,
                changeModalState,
                modals: modalState,
                handleTableAddRow,
                handleTableRemoveRow,
                handleOnTableChangeDataSource,
                setFieldEditor,
                popup_params,
                triedSave,
            }}
        >
            {children}
        </FormStateContext.Provider>
    );
};

export const useFormState = () => {
    const contex = useContext(FormStateContext);
    return contex;
};
