import actionType from "../constants/action.type";
import { arrayToObjectById, toArray } from "./../../../components/util";

const initialState = {
    domain: {
        form: {
            descricao: { name: "descricao", value: null },
            esconde: { name: "esconde", value: null },
        },
        byId: {},
        metaData: {
            attributes: {},
        },
    },
    app: {
        isFetched: false,
    },
};

export const dynamicListReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.editItems:
            let id = action.payload.id;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [id]: action.payload,
                    },
                },
            };
        case actionType.setField:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        ...action.payload,
                    },
                },
            };
        case actionType.select:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: toArray(action?.payload?.["linha"]),
                    }),
                    metaData: {
                        ...state.domain.metaData,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };
        case actionType.selectById:
            if (action.payload && action.name && action.payload.result) {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        byId: {
                            ...state.domain.byId,
                            [action.name]: {
                                ...state.domain.byId[action.name],
                                items: action.payload.result ? action.payload.result : [],
                                estrutura: action.labels,
                            },
                        },
                    },
                };
            }

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state?.domain?.byId,
                        [action.payload?.lista?.["@attributes"]?.nome]: {
                            ...state?.domain?.byId?.[action?.payload?.lista?.["@attributes"]?.nome],
                            items: action?.payload?.lista?.linha ? action?.payload?.lista?.linha : [],
                            estrutura: action?.payload?.estrutura,
                            attributes: action?.payload?.lista?.["@attributes"],
                            sublista: action?.payload?.sublista,
                        },
                    },
                },
            };

        default:
            return state;
    }
};
