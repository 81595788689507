import { Row, Button, message, Col, Skeleton, Form, Card, Modal, Spin } from "antd";
import React, { useState } from "react";
import { MyContext } from "./execution.form.provider";
import ComponentRendererWrapper from "../execution/component.render.wrapper";
import { isHeaderInput, validateFormAfterSave } from "./../../utils/form";
import CorrectTaskSee from "../qa_components/modal_see_correction";
import { Session, Utilitaries } from "../../../../business";
import { api_fetch } from "../../../../V2/utils/HttpRequest/request";
const { confirm } = Modal;

const ExecutionForm = ({
    formItemsRender,
    dispatch,
    appState,
    handleDetails,
    isToDisableSave,
    isRoot,
    isPortal,
    handleNavegateToHome,
    formKey,
    isTab,
    tabName,
    visibleModalSee,
    setVisibleModalSee,
    formAttributes,
    formName,
}) => {
    const [_formKey, setFormKey] = useState(
        formItemsRender ? Object.values(formItemsRender).find((item) => item?.formKey)?.formKey : {}
    );
    const [loading, setLoading] = useState({
        save: false,
        saveTemporary: false,
    });

    if (!formKey) {
        formKey = _formKey;
    }

    return (
        <MyContext.Consumer>
            {(context) => {
                function showConfirm(content, parameters) {
                    confirm({
                        title: "Pretende continuar?",
                        content,
                        onOk() {
                            return handleSave(parameters);
                        },
                        onCancel() {},
                    });
                }

                const handleSave = async ({
                    origin,
                    taskId,
                    processId,
                    saveForm,
                    formData,
                    formName,
                    temporary,
                    validateOnSave = true,
                }) => {
                    // To show error - if tried to save with empty required field
                    debugger;

                    const inactivedValuesErroes = context.getInactivedValueError();

                    if (inactivedValuesErroes) {
                        message.error(inactivedValuesErroes.join(", "));
                        return;
                    }

                    if (!temporary) {
                        const isValid = context.validateFormData();

                        if (!isValid) {
                            return;
                        }
                        setLoading((prev) => ({ ...prev, save: true }));
                    } else {
                        setLoading((prev) => ({ ...prev, saveTemporary: true }));
                    }

                    if (validateOnSave) {
                        const validateForm = await validateFormAfterSave({
                            name: formName,
                            form: formData,
                            formAttributes,
                            context,
                        });
                        if (validateForm !== null && validateForm?.warnings) {
                            const content = (
                                <div>
                                    {validateForm.warnings.map((el) => (
                                        <p>{el}</p>
                                    ))}
                                </div>
                            );
                            showConfirm(content, {
                                origin,
                                taskId,
                                processId,
                                saveForm,
                                formData,
                                formName,
                                temporary,
                                validateOnSave: false,
                            });
                            setLoading((prev) => ({ ...prev, save: false }));
                            return;
                        }
                    }

                    context.setTriedSave(temporary ? false : true);
                    return dispatch(
                        saveForm({
                            formName,
                            formKey,
                            task: taskId,
                            process: processId,
                            context: origin,
                            formData: formData,
                            isPortal,
                            temporary,
                        })
                    ).then((resp) => {
                        setLoading(() => ({ save: false, saveTemporary: false }));
                        if (resp !== "empty") {
                            if (!resp || resp.sucesso === "0") {
                                message.error("Ocorreu um erro");
                            } else {
                                if (isPortal) {
                                    message.success("Guardado com sucesso!");
                                    // se tipo for funcionario, terminar a tarefa
                                    if (!Session.isPortalOrClient()) {
                                        api_fetch({
                                            method: "POST",
                                            endPoint: `/Gestor/gereprocesso.php`,
                                            params: {
                                                accao: "terminar",
                                                processo: processId,
                                                tarefa: taskId,
                                            },
                                        })
                                            .then((resp) => {
                                                if (resp.status === "SUCCESS" && resp.response?.tarefa) {
                                                    message.success("Tarefa terminada com sucesso!");
                                                } else {
                                                    message.error("Ocorreu um erro ao terminar a tarefa");
                                                }
                                            })
                                            .catch((err) => {
                                                message.error("Ocorreu um erro ao terminar a tarefa");
                                            });

                                        // const formData = new FormData();
                                        // formData.append("processo", processId);
                                        // formData.append("tarefa", taskId);
                                        // formData.append("accao", "terminar");
                                        // return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
                                    }
                                    handleNavegateToHome();
                                } else if (temporary) {
                                    message.success(
                                        "Tarefa guardada com sucesso! Podes voltar a ela a qualquer momento e continuar o trabalho. Não podemos avançar sem guarda-la em definitivo."
                                    );
                                } else {
                                    message.success("Tarefa guardada com sucesso");
                                    handleDetails({ isSaved: true });
                                }
                            }
                        }
                    });
                };

                return formItemsRender?.length > 0 ? (
                    <Row gutter={18}>
                        <Spin spinning={context.loadingQuandoValorAlterado && !isTab}>
                            {!isTab &&
                                formItemsRender &&
                                Object.values(context.state.form)
                                    .filter((item) => !Utilitaries.isEmpty(item) && isHeaderInput(item))
                                    .map((item) => {
                                        item["@tipo"] = "etiqueta";
                                        return (
                                            <ComponentRendererWrapper
                                                renderContext="header"
                                                appState={appState}
                                                item={item}
                                                isRoot={isRoot}
                                                context={context}
                                                formKey={formKey}
                                                tabName={tabName}
                                            />
                                        );
                                    })}
                            <>
                                {formItemsRender &&
                                    Object.values(formItemsRender)
                                        .filter((item) => !Utilitaries.isEmpty(item) && !isHeaderInput(item))
                                        .map((item) => (
                                            <ComponentRendererWrapper
                                                renderContext="form"
                                                appState={appState}
                                                item={item}
                                                isRoot={isRoot}
                                                context={context}
                                                formKey={formKey}
                                                tabName={tabName}
                                            />
                                        ))}
                            </>

                            {isRoot && (
                                <>
                                    <Col
                                        xs={24}
                                        style={{
                                            marginTop: "10px",
                                        }}
                                    >
                                        <div className="qa-group-actions-left">
                                            <Button
                                                type="primary"
                                                disabled={context?.correctForm ? false : isToDisableSave}
                                                icon="save"
                                                size="default"
                                                onClick={(e) =>
                                                    handleSave({
                                                        processId: context.processId,
                                                        taskId: context.taskId,
                                                        origin: "root",
                                                        saveForm: context.saveForm,
                                                        formData: context.state.form,
                                                        formName,
                                                    })
                                                }
                                                loading={loading.save}
                                            >
                                                Guardar
                                            </Button>

                                            <Button
                                                disabled={isToDisableSave}
                                                icon="save"
                                                type="primary"
                                                size="default"
                                                onClick={(e) =>
                                                    handleSave({
                                                        processId: context.processId,
                                                        taskId: context.taskId,
                                                        origin: "root",
                                                        saveForm: context.saveForm,
                                                        formData: context.state.form,
                                                        temporary: true,
                                                        formName,
                                                    })
                                                }
                                                loading={loading.saveTemporary}
                                            >
                                                Guardar Temporário
                                            </Button>
                                        </div>

                                        <div className="qa-group-actions-right">
                                            <Button
                                                disabled={isToDisableSave}
                                                icon="undo"
                                                type="danger"
                                                size="small"
                                                ghost
                                                onClick={() => {
                                                    Modal.confirm({
                                                        title: "Confirmar que pretende anular a informação anteriormente guardada?",
                                                        content: "",
                                                        onOk() {
                                                            context.hangleResetFormData();
                                                        },
                                                        onCancel() {},
                                                    });
                                                }}
                                            >
                                                Reformular
                                            </Button>
                                        </div>
                                    </Col>

                                    {/* <Col xs={24} sm={12}  style={{
                                            marginTop: "10px",
                                        }}>
                                        <div className="qa-group-actions-right">
                                            <Button
                                                // disabled={saving}
                                                icon="undo"
                                                type="primary"
                                                htmlType="submit"
                                                // onClick={hangleReset}
                                            >
                                                Reformular
                                            </Button> */}

                                    {/* <Upload {...fileProps} className="load-local"> */}
                                    {/* <Button
                                                icon="cloud-upload"
                                                type="primary"
                                                htmlType="submit"
                                                disabled={isToDisableSave}
                                            >
                                                Carregar Local
                                            </Button> */}
                                    {/* </Upload> */}

                                    {/* <Button
                                                disabled={isToDisableSave}
                                                icon="play-circle"
                                                type="primary"
                                                htmlType="submit"
                                                // onClick={handleLocalSave}
                                            >
                                                Gravar Local
                                            </Button>
                                        </div>
                                    </Col> */}
                                </>
                            )}

                            <CorrectTaskSee
                                visibleModalSee={visibleModalSee}
                                setVisibleModalSee={setVisibleModalSee}
                                renderContext="modal"
                                {...context}
                            />
                            {/* </Form> */}
                        </Spin>
                    </Row>
                ) : (
                    <Row>
                        {formItemsRender &&
                            Object.keys(formItemsRender).map(() => (
                                <Col xs={24} md={8}>
                                    <Skeleton paragraph={{ rows: 1 }} />
                                </Col>
                            ))}
                    </Row>
                );
            }}
        </MyContext.Consumer>
    );
};

export default ExecutionForm;
