import { actionType } from "../constants/collective.agreement";
import { sendHttpRequest } from "../../../components/http.request";

export const select = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "convencoes");
    Object.entries(payload).forEach(([key, val]) => {
        formData.append(key, val);
    });
    await sendHttpRequest("POST", "/Gestor/geremanualfuncoes.php", formData).then((jsonResponse) => {
        if (jsonResponse) dispatch({ type: actionType.select, payload: jsonResponse });
    });
};

export const setField = (payload) => ({ type: actionType.setField, payload });
export const addCategory = (payload) => ({
    type: actionType.addCategory,
    payload,
});

export const deleteCategory = (payload) => ({
    type: actionType.deleteCategory,
    payload,
});
export const updateCategory = (payload) => ({
    type: actionType.updateCategory,
    payload,
});

export const insert = (payload) => async (dispatch) => {
    const obj = {};
    let categorias = [];
    //------------ save nivel for each category -------------
    for (let index = 0; index < payload.listCategoria.length; index++) {
        const item = payload.listCategoria[index];
        const niveis = item.level.map((itemLevel) => ({
            nivel: itemLevel.nivel,
            n_nivel: itemLevel.n_nivel,
            anos_transitar_nivel: itemLevel.anos_transitar_nivel,
        }));

        const objNivel = {
            lista_niveis_categoria: {
                niveis_categoria: { nivel_categoria: niveis },
            },
        };

        const formDataNivel = new FormData();
        formDataNivel.append("accao", "guardar");
        formDataNivel.append("formulario", "qa_ficha_nivel_categorias_profissionais.qml.xml");
        formDataNivel.append("dados", JSON.stringify(objNivel));

        await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formDataNivel).then((jsonResponse) => {
            if (jsonResponse.sucesso) payload.listCategoria[index].lista_niveis_categoria = jsonResponse.sucesso;
        });
    }

    // -------------- end save nivel -------

    const formData = new FormData();

    payload.listCategoria.forEach((item) => {
        const categoria = {
            designacao: item.designacao,
            num_escaloes: item.num_escaloes,
            anos_transitar_escalao: item.anos_transitar_escalao,
            num_niveis: item.level.length,
            lista_niveis_categoria: item.lista_niveis_categoria,
        };
        categorias.push(categoria);
    });

    obj["convencao_coletiva"] = {
        nome: payload.nome,
        data: payload.data,
        em_vigor: payload.em_vigor,
        categorias: { categoria: categorias },
    };
    formData.append("accao", "guardar");
    formData.append("formulario", "ficha_convencao_coletiva.qml.xml");
    formData.append("dados", JSON.stringify(obj));
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            dispatch({ type: actionType.insert });
            return true;
        } else {
            return false;
        }
    });
};

export const getById = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("formulario", "ficha_convencao_coletiva.qml.xml");
    formData.append("id", payload);
    return await sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((jsonResponse) => {
        dispatch({ type: actionType.getById, payload: jsonResponse });
    });
};

export const getNiveis = (payload) => async (dispatch) => {
    if (!payload.record.lista_niveis_categoria) return true;
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("formulario", payload.formulario.formulario_nivel);
    formData.append("id", payload.record.lista_niveis_categoria);
    return await sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((jsonResponse) => {
        return dispatch({
            type: actionType.getNiveis,
            payload: { ...payload, jsonResponse },
        });
    });
};

export const saveNiveis = (payload) => async (dispatch) => {
    //------------ save nivel -------------
    const niveis = payload.values.level.map((itemLevel) => ({
        id_nivel_categoria: itemLevel.id_nivel_categoria,
        nivel: itemLevel.nivel,
        n_nivel: itemLevel.n_nivel,
        anos_transitar_nivel: itemLevel.anos_transitar_nivel,
    }));

    const objNivel = {
        lista_niveis_categoria: {
            niveis_categoria: { nivel_categoria: niveis },
        },
    };

    if (typeof payload.values.lista_niveis_categoria !== "object") {
        objNivel.lista_niveis_categoria.id_lista_niveis_categoria = payload.values.lista_niveis_categoria;
    }

    const formDataNivel = new FormData();
    formDataNivel.append("accao", "guardar");
    formDataNivel.append("formulario", "qa_ficha_nivel_categorias_profissionais.qml.xml");
    formDataNivel.append("dados", JSON.stringify(objNivel));
    let responseNivel = null;
    await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formDataNivel).then((jsonResponse) => {
        if (jsonResponse.sucesso) responseNivel = jsonResponse.sucesso;
    });
    // -------------- end save nivel -------
    if (responseNivel === null) return false;
    const convencao = payload.initialDataEdit.convencao_coletiva;

    let listCategoria = [];

    if (Array.isArray(convencao.categorias.categoria)) listCategoria = convencao.categorias.categoria;
    else if (convencao.categorias.categoria) listCategoria = [convencao.categorias.categoria];

    const index = listCategoria.findIndex((item) => item.id_categoria === payload.values.id_categoria);
    if (Number(listCategoria[index].num_niveis) === payload.values.level.length) {
        dispatch({
            type: actionType.saveNiveis,
            payload: {
                ...payload,
                initialDataEdit: { convencao_coletiva: convencao },
            },
        });
        return true;
    }

    listCategoria[index].num_niveis = payload.values.level.length;
    listCategoria[index].lista_niveis_categoria = responseNivel;
    convencao.categorias.categoria = listCategoria;
    payload.values.lista_niveis_categoria = responseNivel;
    const obj = {};
    obj["convencao_coletiva"] = convencao;

    const formDataConvencao = new FormData();
    formDataConvencao.append("accao", "guardar");
    formDataConvencao.append("formulario", payload.formulario.nome);
    formDataConvencao.append("dados", JSON.stringify(obj));

    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formDataConvencao).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            dispatch({
                type: actionType.saveNiveis,
                payload: { ...payload, initialDataEdit: obj },
            });
            return true;
        } else {
            return false;
        }
    });
};

export const update = (payload) => async (dispatch) => {
    const formData = new FormData();
    //------------ save nivel for each category -------------
    for (let index = 0; index < payload.values.listCategoria.length; index++) {
        const item = payload.values.listCategoria[index];

        if (
            item.level.length !== 0 &&
            (item.lista_niveis_categoria === undefined || typeof item.lista_niveis_categoria === "object")
        ) {
            const niveis = item.level.map((itemLevel) => ({
                nivel: itemLevel.nivel,
                n_nivel: itemLevel.n_nivel,
                anos_transitar_nivel: itemLevel.anos_transitar_nivel,
            }));

            const objNivel = {
                lista_niveis_categoria: {
                    niveis_categoria: { nivel_categoria: niveis },
                },
            };

            const formDataNivel = new FormData();
            formDataNivel.append("accao", "guardar");
            formDataNivel.append("formulario", "qa_ficha_nivel_categorias_profissionais.qml.xml");
            formDataNivel.append("dados", JSON.stringify(objNivel));

            await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formDataNivel).then((jsonResponse) => {
                if (jsonResponse.sucesso)
                    payload.values.listCategoria[index].lista_niveis_categoria = jsonResponse.sucesso;
            });
        }
    }

    // -------------- end save nivel -------
    const categorias = payload.values.listCategoria.map((item) => {
        const categoria = {
            id_categoria: item.id_categoria,
            designacao: item.designacao,
            num_escaloes: item.num_escaloes,
            anos_transitar_escalao: item.anos_transitar_escalao,
            num_niveis: item.num_niveis,
            lista_niveis_categoria: item.lista_niveis_categoria,
        };

        if (typeof item.lista_niveis_categoria === "object")
            categoria.lista_niveis_categoria = item.lista_niveis_categoria;
        if (!item.id_categoria) categoria.num_niveis = item.level.length;

        return categoria;
    });

    const obj = {};
    obj[payload.formulario.elemento_raiz] = {
        id_convencao_coletiva: payload.values.id_convencao_coletiva,
        nome: payload.values.nome,
        data: payload.values.data,
        em_vigor: payload.values.em_vigor,
        categorias: { categoria: categorias },
    };
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario.nome);
    formData.append("dados", JSON.stringify(obj));
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse.sucesso ? true : false;
    });
};

export const remove = (payload) => async (dispatch) => {
    //todo: remove in api, then store
    return dispatch({ type: actionType.remove, payload });
};
