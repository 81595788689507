import React, { useState, useEffect } from "react";
import { message, Modal, Table, Button, Alert, Descriptions } from "antd";
import { getHistoryOperation } from "../../actions/execution";
import { historyTask } from "../../constants/execution";

const ModalObservationList = ({ taskId, historyOperation, visible, handleClose }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    useEffect(() => {
        getHistoryOperation({
            taskId,
            historyOperation: historyTask.observation,
        }).then((result) => {
            if (!result || result.erro) {
                message.error(result.erro || "Ocorreu um erro");
            } else {
                setDataSource(result);
            }
            setIsLoading(false);
        });
    }, []);

    const columns = [
        {
            title: "Utilizador",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Data",
            dataIndex: "data",
            key: "data",
        },
        {
            title: "Versão",
            dataIndex: "versao",
            key: "versao",
        },
        {
            title: "Ação",
            key: "obs",
            render: (record) => (
                <Button
                    type="link"
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        color: "#8c8c8c",
                    }}
                    onClick={() => {
                        setSelectedRow(record);
                        setIsModalVisible(true);
                    }}
                >
                    Consultar
                </Button>
            ),
        },
    ];

    return (
        <Modal
            title={`Histórico de observações`}
            visible={visible}
            onOk={handleClose}
            onCancel={handleClose}
            footer={null}
            width="50%"
        >
            <Table
                dataSource={dataSource}
                columns={columns}
                size="small"
                loading={isLoading}
                pagination={{
                    pageSize: 5,
                    size: "small",
                }}
            />
            {isModalVisible && (
                <Modal
                    title={`Histórico`}
                    visible={isModalVisible}
                    destroyOnClose
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                    width="40%"
                >
                    <Alert message={`Utilizador ${selectedRow.username} em ${selectedRow.data}`} banner type="info" />
                    <Descriptions style={{ marginTop: "0.9em" }} bordered layout="vertical" size="small">
                        <Descriptions.Item label="Observação">
                            <pre>{selectedRow.obs}</pre>
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>
            )}
        </Modal>
    );
};

export default ModalObservationList;
