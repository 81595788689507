import React, { useState, useEffect } from "react";
import { Modal, Input } from "antd";

const { TextArea } = Input;

const ModelEditField = ({ name, onChange, onCloseEditor, value, disabled }) => {
    const [text, setText] = useState("");

    useEffect(() => {
        if (value) {
            setText(value);
        } else {
            setText("");
        }
    }, [value]);

    return (
        <Modal
            title={`Editar ${name}`}
            visible={onChange !== undefined}
            destroyOnClose
            onOk={() => {
                onChange(text);
                onCloseEditor();
            }}
            okButtonProps={{
                disabled: disabled,
            }}
            onCancel={onCloseEditor}
        >
            <TextArea
                onChange={(e) => setText(e.target.value)}
                autoSize={{ minRows: 10, maxRows: 10 }}
                style={{ width: "100%" }}
                disabled={disabled || false}
                value={text}
                compact
                allowClear
            />
        </Modal>
    );
};

export default ModelEditField;
