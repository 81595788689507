import { FormInputsProps, XMLFormInputProps } from "../../types/formTypes";
import { XML_INPUT_TYPE, COMPONENT_TYPE } from "./XMLInputType";

export const getFormModals = (inputPropsArray: XMLFormInputProps[], modals: FormInputsProps[] = []) => {
    for (const item of inputPropsArray) {
        if (item[XML_INPUT_TYPE.POPUPFORM]) {
            // delete item[XML_INPUT_TYPE.TAB];
            delete item[XML_INPUT_TYPE.STYLE];
            modals.push({
                ...item,
                key: item[XML_INPUT_TYPE.POPUPFORM],
                "@tipo": COMPONENT_TYPE.POPUP,
                "@presiste": "nao",
            });
        } else {
            const inputKeys = Object.keys(item).filter(
                (key) => !key?.startsWith("@") && typeof item[key] === "object" && !Array.isArray(item[key])
            );
            if (inputKeys.length > 0) {
                const tmpFormArray = inputKeys.reduce((accumulator: FormInputsProps[], current: string) => {
                    accumulator.push({
                        ...item[current],
                        key: current,
                    });
                    return accumulator;
                }, []);
                const aux = getFormModals(tmpFormArray, modals);
                modals.concat(aux);
            }
        }
    }
    return modals;
};
