import React, { Component } from "react";
import { Modal, Table, Button, Popconfirm, Input, Row, Col, Form, Icon, Select, message, Skeleton } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/holiday";
import QaDynamicSelect from "../../../../components/DynamicList/dynamicSelect";
import Confirm from "./../../../../components/qa_custom/confirm";
import { selectEstabeleciment } from "../../../fixed_list/actions/index";
import { isEmpty } from "../../../process_execution/utils/fields";
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;

class FormEditHoliday extends Component {
    componentDidMount() {
        this.props.getDetails({ id: this.props.idEdit }).then(() => {
            this.setState({
                loadingData: false,
                mindate: `${this.props.formHoliday.year.value}-01-01`,
                maxdate: `${this.props.formHoliday.year.value}-12-31`,
            });
        });

        if (this.props.estabelecimentos === undefined) {
            this.setState({ estabelecimentoLoading: true });
            this.props
                .selectEstabeleciment({
                    accao: "lista",
                    lista: "lista_estabelecimentos",
                    id_instituicao: "1",
                })
                .then((sucess) => {
                    if (!sucess) message.error("Ocorreu um erro");
                    this.setState({ estabelecimentoLoading: false });
                });
        } else {
            this.setState({ estabelecimentoLoading: false });
        }
    }

    state = {
        estabelecimentoLoading: true,
        loadingNature: false,
        loadingData: true,
        triedSave: false,
        mindate: null,
        maxdate: null,
    };

    save = (dataIndex, text, record) => {
        const obj = { ...record, [dataIndex]: text };
        this.props.setFieldList({ isEdit: true, obj });
    };

    delete = (obj) => {
        this.props.removeDay(obj);
    };

    handleResponse = (response) => {
        this.setState({ buttonLoading: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleOk();
        } else message.error("Ocorreu um erro");
    };

    checkInvalidHolidays = (holidays) => {
        const defaultDate = moment(this.state.mindate);
        for (const item of holidays) {
            if (
                isEmpty(item.data) ||
                isEmpty(item.natureza) ||
                isEmpty(item.designacao) ||
                isEmpty(item.estabelecimentos)
            ) {
                return { requiredField: true };
            } else if (!defaultDate.isSame(item.data, "year")) {
                return {
                    invalidDate: true,
                };
            }
        }
        return {
            requiredField: false,
            invalidDate: false,
        };
    };

    handleOk = (e) => {
        e.preventDefault();
        this.setState({ triedSave: true });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //validate fiels
                const { requiredField, invalidDate } = this.checkInvalidHolidays(this.props.formHoliday.holidays);
                if (requiredField) {
                    message.error("Campos obrigatórios em branco");
                } else if (invalidDate) {
                    message.error("Tem data inválida");
                } else {
                    this.setState({ buttonLoading: true });
                    const obj = {
                        ...values,
                        id: this.props.formHoliday.id,
                        data: this.props.formHoliday.data,
                        holidays: this.props.formHoliday.holidays,
                    };

                    console.log(obj);
                    this.props.update({ formulario: this.props.formulario, values: obj }).then((response) => {
                        this.handleResponse(response);
                    });
                }
            }
        });
    };

    requiredLabel = (text) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minWidth: "80px",
                }}
            >
                <span>
                    <i style={{ color: "red" }}>*</i>
                    &nbsp;{text}&nbsp;
                </span>
            </div>
        );
    };

    columns = [
        {
            title: this.requiredLabel("Dia"),
            dataIndex: "data",
            width: "20%",
            render: (value, record) => {
                const errorRequired = this.state.triedSave && !value;
                let invalidDate = false;

                if (!errorRequired && value) {
                    const momentDate = moment(value);
                    if (momentDate.isValid()) {
                        const defaultDate = moment(this.state.mindate);
                        invalidDate = !momentDate.isSame(defaultDate, "year");
                    } else {
                        invalidDate = true;
                    }
                }
                return (
                    <>
                        <Input
                            type="date"
                            value={value ? value : null}
                            name="data"
                            min={this.state.mindate}
                            max={this.state.maxdate}
                            onChange={(date) => {
                                this.save("data", date?.target?.value ? date.target.value : null, record);
                            }}
                        />
                        {errorRequired && <span style={{ color: "red" }}>Campo obrigatório</span>}
                        {invalidDate && <span style={{ color: "red" }}>Valor inválido</span>}
                    </>
                );
            },
        },
        {
            title: this.requiredLabel("Designação"),
            dataIndex: "designacao",
            render: (value, record) => {
                const error = this.state.triedSave && !value;
                return (
                    <>
                        <Input
                            ref={(node) => (this.input = node)}
                            value={value}
                            onChange={(event) => {
                                return this.save("designacao", event.target.value, record);
                            }}
                        />
                        {error && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: this.requiredLabel("Natureza"),
            dataIndex: "natureza",
            width: "20%",
            render: (value, record) => {
                const error = this.state.triedSave && !value;
                return (
                    <>
                        <QaDynamicSelect
                            defaultValue={value}
                            onChange={(event) => {
                                return this.save("natureza", event, record);
                            }}
                            name="natureza_feriado"
                        />
                        {error && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: this.requiredLabel("Estabelecimento"),
            dataIndex: "estabelecimentos",
            width: "30%",
            render: (value, record) => {
                const error = this.state.triedSave && (!value || value.length === 0);
                return (
                    <>
                        <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            value={value}
                            onChange={(event) => {
                                return this.save("estabelecimentos", event, record);
                            }}
                        >
                            {!this.state.estabelecimentoLoading &&
                                this.props.listEstabelecimentos?.data &&
                                this.props.listEstabelecimentos.data
                                    .filter((f) => typeof f.nome !== "object")
                                    .map((item) => (
                                        <Option key={item.estabelecimento} value={item.estabelecimento}>
                                            {item.nome}
                                        </Option>
                                    ))}
                        </Select>
                        {error && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "10%",
            render: (_text, record) => (
                <Popconfirm
                    title="Tens certeza que desejas eliminar?"
                    okText="Ok"
                    cancelText="Não"
                    onConfirm={() => this.delete({ isEdit: true, key: record.key })}
                >
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                            color: "#fa541c",
                        }}
                    >
                        Eliminar
                    </Button>
                </Popconfirm>
            ),
        },
    ];

    render() {
        const columns = this.columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    title: col.title,
                }),
            };
        });
        const { getFieldDecorator } = this.props.form;
        const propsFooter = this.state.loadingData ? { footer: null } : {};
        return (
            <Modal
                title="Configuração de feriados"
                visible={this.props.isVisible}
                onOk={this.handleOk}
                onCancel={() => Confirm(this.props.handleCancel)}
                confirmLoading={this.state.buttonLoading}
                width="90%"
                {...propsFooter}
                centered
            >
                <Form layout="vertical" onSubmit={this.props.handleOk}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={5}>
                            <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={this.state.loadingData}>
                                <Form.Item label="Ano civil">
                                    {getFieldDecorator("year", {
                                        initialValue: this.props.formHoliday.year.value,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={24} sm={24} md={9}>
                            <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={this.state.loadingData}>
                                <Form.Item label="Descrição">
                                    {this.props.formHoliday.year.value &&
                                        getFieldDecorator("descricao", {
                                            initialValue: this.props.formHoliday.descricao.value,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(
                                            <TextArea
                                                autoSize={{
                                                    minRows: 1,
                                                    maxRows: 6,
                                                }}
                                            />
                                        )}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={24} sm={24} md={10}>
                            <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={this.state.loadingData}>
                                <Form.Item label="&nbsp;">
                                    <Button
                                        onClick={() =>
                                            this.props.addNewDay({
                                                isEdit: true,
                                            })
                                        }
                                        type="primary"
                                        style={{
                                            float: "right",
                                            marginBottom: 16,
                                        }}
                                    >
                                        <Icon type="plus" />
                                        Inserir
                                    </Button>
                                </Form.Item>
                            </Skeleton>
                        </Col>
                    </Row>
                    <Table
                        rowKey={(record) => record.key}
                        rowClassName={() => "editable-row"}
                        dataSource={this.state.loadingData ? [] : this.props.list}
                        columns={columns}
                        size="small"
                        loading={this.state.loadingData}
                        scroll={{ y: 300 }}
                    />
                    <Row>
                        <Col xs={24} sm={24} md={14}>
                            <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={this.state.loadingData}>
                                <Form.Item label="Motivo de alteração">
                                    {this.props.formHoliday.year.value &&
                                        getFieldDecorator("motivo", {
                                            initialValue: this.props.formHoliday.motivo.value,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(
                                            <TextArea
                                                autoSize={{
                                                    minRows: 1,
                                                    maxRows: 6,
                                                }}
                                            />
                                        )}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...actionCreators, selectEstabeleciment }, dispatch);
};

const mapStateToProps = (state) => {
    return {
        formHoliday: state.holiday.domain.formEdit,
        list: state.holiday.domain.formEdit.holidays,
        formulario: state.holiday.domain.metadata.formulario,
        listEstabelecimentos: state.fixedList.domain.byId.estabelecimento,
        natureza: state.dynamicList.domain.byId.natureza_feriado,
    };
};

const formEdit = Form.create();
export default connect(mapStateToProps, mapDispatchToProps)(formEdit(FormEditHoliday));
