export const actionType = {
    insert: "INSERT_REQUIREMENT_TYPE",
    setField: "SET_FIELD_REQUIREMENT_TYPE",
    update: "UPDATE_REQUIREMENT_TYPE",
    remove: "REMOVE_REQUIREMENT_TYPE",
    select: "SELECT_REQUIREMENT_TYPE",
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
