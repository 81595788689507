import { sendHttpRequest, api } from "../../../components/http.request";
import { transformForm } from "../constants/utils";
import { actionType, historyTask, taskOperation } from "../constants/execution";
import { actionType as actionTypeIndex, componentType } from "../constants/index";
import { baseUrl } from "../../../constants/enviroment";
import { Session, Utilitaries } from "../../../business";
import { message } from "antd";
import { toArray } from "../../../components/util";
import { getSubprocessFromFluxograma } from "../utils/process";
import { getUsername } from "../../../business/auth";

export const addDocument = (payload) => ({
    type: actionType.addDocument,
    payload,
});

export const addUploadedDoc = (payload) => ({
    type: actionType.addUploadedDoc,
    payload,
});

export const changeDocDescription = (payload) => ({
    type: actionType.changeDocDescription,
    payload,
});

export const removeDocument = (payload) => ({
    type: actionType.removeDocument,
    payload,
});

export const setTemporaryRelRowKey = (payload) => ({
    type: actionType.setTemporaryRelRowKey,
    payload,
});

export const setTemporaryRelId = (payload) => ({
    type: actionType.setTemporaryRelId,
    payload,
});

export const setTemporaryRelName = (payload) => ({
    type: actionType.setTemporaryRelName,
    payload,
});

export const setTaskDetails = (payload) => ({
    type: actionTypeIndex.setTaskDetails,
    payload,
});

export const setProcessToStep = (payload) => (dispatch, getState) => {
    payload.flowChart = payload.fluxograma;

    const tasks = getState().processExecution?.domain?.byId?.[payload.processId]?.tasks;
    const keysLis = Object.keys(tasks);
    const finder = keysLis.find((item) => Number(item) > Number(payload.taskId));
    const isCurrentLastTask = !finder;
    if (payload.fluxograma && isCurrentLastTask) {
        const isTaskLoaded = tasks[payload.taskId];
        if (isTaskLoaded) {
            const previousTasksOrdered = orderPreviousTasksV1(payload.fluxograma, payload.taskId, tasks);
            dispatch({
                type: actionTypeIndex.setPreviousTasks,
                payload: {
                    tasks: previousTasksOrdered,
                    processId: payload.processId,
                },
            });
        }
    }
    // const payloadUpdated = handleReports(data, payload);

    dispatch({
        type: actionType.getTaskDetails,
        payload,
    });
};

const orderPreviousTasksV1 = (fluxograma, currentTaskId, currentTasks) => {
    const tasks = fluxograma["root-container"].node.filter(
        (item) => item["@tipo"] === "tarefa" || item["@tipo"] === "processo"
    );
    const previousTasks = tasks
        .filter((el) => el["@id_tarefa"])
        .reduce((acumulator, task) => {
            if (task["@id_tarefa"] && Number(task["@id_tarefa"]) !== Number(currentTaskId)) {
                const taskId = task["@id_tarefa"];
                const subprocessExp = /[0-9]*:[0-9]*:.*:[0-9]*:.*/;

                // const aaa = taskId.match(subprocessExp);
                if (taskId.match(subprocessExp)) {
                    for (const taks of taskId.split(";")) {
                        if (!Utilitaries.isEmpty(taks)) {
                            const [processId, taskIdMixed, taskName, ...rest] = taks.split(":");
                            acumulator[taskIdMixed.replace(";", "")] = {
                                key: taskIdMixed.replace(";", ""),
                                processId,
                                isProcess: true,
                                type: "sub",
                                ...currentTasks[taskIdMixed],
                                name: task["@name"],
                                // ...currentTasks[taskId.replace(";", "")],
                            };
                        }
                    }

                    return acumulator;
                }

                const taskLists = toArray(taskId.split(";")).filter((_taskID) => !Utilitaries.isEmpty(_taskID));
                if (taskId && taskLists.length > 1) {
                    for (const _taksId_ of taskLists) {
                        acumulator[_taksId_] = {
                            key: _taksId_,
                            name: task["@name"],
                            ...currentTasks[_taksId_],
                        };
                    }

                    return acumulator;
                }

                acumulator[taskId.replace(";", "")] = {
                    key: taskId.replace(";", ""),
                    name: task["@name"],
                    ...currentTasks[taskId.replace(";", "")],
                };
            } else {
                acumulator[currentTaskId.replace(";", "")] = {
                    ...currentTasks[currentTaskId.replace(";", "")],
                };
            }
            return acumulator;
        }, {});
    const previousTasksOrdered = Object.keys(previousTasks)
        .sort((prev, next) => {
            if (Number(prev) > Number(next)) {
                return -1;
            } else if (Number(prev) < Number(next)) {
                return 1;
            } else return 0;
        })
        .reduce((acumulator, current) => {
            acumulator[current] = {
                ...previousTasks[current],
            };
            return acumulator;
        }, {});
    return previousTasksOrdered;
};

export const getTaskDetailsMap = (payload) => (dispatch, getState) => {
    const formData = new FormData();

    let requestAction = "detalhe";

    if (payload?.action === "next") {
        requestAction = "seguinte_detalhe";
    } else if (payload?.action === "prev") {
        requestAction = "anterior_detalhe";
    }

    formData.append("accao", requestAction);
    formData.append("hack", "true");
    formData.append("processo", payload.processId);
    formData.append("tarefa", payload.taskId);

    if(Session.isCLient()) {
        const params = Session.getAll();
        formData.append("usr_parametro1", params.parametro1);
        formData.append("usr_parametro2", params.parametro2);
        formData.append("usr_parametro3", params.parametro3);
    }

    return api("/Gestor/gereprocesso.php", formData).then((data) => {
        const { erro, fluxograma, processo: processAttributes, tarefa: taskAttributes, formulario: form } = data?.dados;

        // payload.flowChart = fluxograma;

        if (erro) {
            message.error(erro);
            return { erro };
        }

        const tasks = getState().processExecution?.domain?.byId?.[processAttributes["@id"]]?.tasks;
        const payloadUpdated = handleReports(data?.dados, payload);
        // ;
        if (Utilitaries.isEmpty(tasks)) {
            dispatch({
                type: actionType.initProcess,
                payload: {
                    ...processAttributes,
                    tasks: {
                        [taskAttributes["@id"]]: {
                            ...taskAttributes,
                            key: taskAttributes["@id"],
                            name: taskAttributes["@nome"],
                            status: "doing",
                            formMeta: [
                                {
                                    form: form["@nome"],
                                    meta: {},
                                },
                            ],
                            page: {},
                            details: {
                                readonlyDocuments: toArray(payloadUpdated?.readonlyDocuments),
                                uploadedDocuments: toArray(payloadUpdated?.uploadedDocuments),
                            },
                            transitions: {}, // todo: get this info in backend
                            actions: {}, // todo: get this info in backend
                        },
                    },
                },
            });
        } else {
            dispatch({
                type: actionTypeIndex.setTaskDetails,
                payload: {
                    ...taskAttributes,
                    processId: processAttributes["@id"],
                    taskId: taskAttributes["@id"],
                    key: taskAttributes["@id"],
                    name: taskAttributes["@nome"],
                    status: "doing",
                    formMeta: [
                        {
                            form: form["@nome"],
                            meta: {},
                        },
                    ],
                    page: {},
                    details: {
                        readonlyDocuments: toArray(payloadUpdated?.readonlyDocuments),
                        uploadedDocuments: toArray(payloadUpdated?.uploadedDocuments),
                    },
                    transitions: {}, // todo: get this info in backend
                    actions: {}, // todo: get this info in backend
                },
            });
        }

        // const subprocessList = getSubprocessFromFluxograma(fluxograma);

        return {
            processName: processAttributes["@nome"],
            processId: processAttributes["@id"],
            taskId: taskAttributes["@id"],
            fluxograma,
            parentProcessId: taskAttributes["@proc_pai"] === "0" ? null : taskAttributes["@proc_pai"],
            parentProcessTaskId: taskAttributes["@proc_pai_tarefa"],
            parentProcessName: taskAttributes["@proc_pai_nome"],
            // subprocessList,
        };

        // const isCurrentLastTask = !Object.keys(tasks).find((item) => item > payload.taskId);
        // if (fluxograma && !payload.isOnChange && isCurrentLastTask) {
        //     const isTaskLoaded = tasks[payload.taskId];
        //     if (isTaskLoaded) {
        //         const previousTasksOrdered = orderPreviousTasks(fluxograma, payload.taskId, tasks);
        //         dispatch({
        //             type: actionTypeIndex.setPreviousTasks,
        //             payload: {
        //                 tasks: previousTasksOrdered,
        //                 processId: payload.processId,
        //             },
        //         });
        //     }
        // }
        // const payloadUpdated = handleReports(data, payload);
        // dispatch({
        //     type: actionType.getTaskDetails,
        //     payload,
        // });
    });
};

export const getTaskDetails = (payload) => (dispatch, getState) => {
    const formData = new FormData();
    formData.append("accao", "detalhe");
    formData.append("processo", payload.processId);
    formData.append("tarefa", payload.taskId);

    if(Session.isCLient()) {
        const params = Session.getAll();
        formData.append("usr_parametro1", params.parametro1);
        formData.append("usr_parametro2", params.parametro2);
        formData.append("usr_parametro3", params.parametro3);
    }

    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
        const { fluxograma } = data;
        payload.flowChart = fluxograma;

        const tasks = getState().processExecution?.domain?.byId?.[payload.processId]?.tasks;
        const isCurrentLastTask = !Object.keys(tasks).find((item) => item > payload.taskId);
        if (fluxograma && !payload.isOnChange && isCurrentLastTask) {
            const isTaskLoaded = tasks[payload.taskId];
            if (isTaskLoaded) {
                const previousTasksOrdered = orderPreviousTasks(fluxograma, payload.taskId, tasks);
                dispatch({
                    type: actionTypeIndex.setPreviousTasks,
                    payload: {
                        tasks: previousTasksOrdered,
                        processId: payload.processId,
                    },
                });
            }
        }
        const payloadUpdated = handleReports(data, payload);
        dispatch({
            type: actionType.getTaskDetails,
            payload,
        });
    });
};

export const processOperationRequest = ({ process, tarefa, operation, reason }) => {
    const formData = new FormData();
    formData.append("accao", operation);
    formData.append("processo", process);

    if (!Utilitaries.isEmpty(reason)) {
        if (operation === taskOperation.requestCancelTask) {
            reason = `Solicitado pedido de anulação do processo pelo utilizador: ${getUsername()}. Motivo: ${reason}`;
            formData.append("motivo", reason);
        } else if (operation === taskOperation.requestReAssign) {
            reason = `Reatribuição solicitada por ${getUsername()}. Motivo: ${reason}`;
            formData.append("motivo", reason);
        } else if (operation === taskOperation.saveObservation) {
            formData.append("observacoes", reason);
        }
    }

    if (tarefa) formData.append("tarefa", tarefa);
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
};

export const getHistoryOperation = ({ historyOperation, taskId }) => {
    const formData = new FormData();
    formData.append("accao", historyOperation);
    formData.append("tarefa", taskId);
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((result) => {
        if (!result) {
            return { erro: "Ocorreu um erro." };
        } else if (result.erro) {
            return result;
        } else {
            let data = [];
            if (historyOperation === historyTask.cancelTask) {
                data = Utilitaries.toString(result?.anulacoes).split("\n\n");
            } else if (historyOperation === historyTask.reAssignTask) {
                data = Utilitaries.toString(result?.reatribuicoes).split("\n\n");
            } else if (historyOperation === historyTask.observation) {
                data = Utilitaries.toArray(result?.linha);
            }
            return data;
        }
    });
};

export const deleteDocument =
    ({ docKey, taskId, processId }) =>
    (dispatch) => {
        const formData = new FormData();
        formData.append("accao", "eliminar_ficheiro");
        formData.append("ficheiro", Number(docKey));
        formData.append("tarefa", Number(taskId));
        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((resp) => {
            dispatch({
                type: actionType.removeDocument,
                payload: { docKey, taskId, processId },
            });
        });
    };

export const saveDocument =
    ({ processId, taskId, key }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        const { file, descricao, nome_ficheiro } =
            getState().processExecution.domain.byId[processId].tasks[taskId].details.uploadedDocuments[key];
        formData.append("accao", "guardar_ficheiro");
        formData.append("tarefa", taskId);
        formData.append("nome_ficheiro", nome_ficheiro);
        formData.append("ficheiro", file);
        formData.append("descricao", descricao);
        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((resp) => {
            dispatch({
                type: actionType.setDocumentField,
                payload: {
                    id_ficheiro: resp[0],
                    processId,
                    taskId,
                    docKey: key,
                },
            });
        });
    };

export const setSubProcessToBase = (payload) => ({ type: actionType.setSubProcessToBase, payload });

export const downloadDocumentByName =
    ({ ...rest }) =>
    () => {
        let otherParams = "";
        Object.keys(rest).forEach((key) => {
            otherParams = `${otherParams}&${key}=${rest[key]}`;
        });

        window.open(
            `${baseUrl}/Gestor/gererelatorios.php?accao=relatorio_nome${otherParams}&token=Bearer ${Session.getToken()}`,
            "_blank"
        );
    };

export const downloadDocument =
    ({ id }) =>
    () => {
        window.open(
            `${baseUrl}/Gestor/geredocumentos.php?accao=ficheiro&ficheiro=${id}&token=Bearer ${Session.getToken()}`,
            "_blank"
        );
    };

export const xFormatDownloadDocument =
    (props = {}) =>
    (dispatch) => {
        const queryParams = Object.keys(props).reduce((accumulator, current) => {
            accumulator = `${accumulator}${current}=${props[current]}&`;
            return accumulator;
        }, "");
        window.open(
            `${baseUrl}/Gestor/gererelatorios.php?accao=relatorio&${queryParams}IMPRIMIR_BRANCO=false&token=Bearer ${Session.getToken()}`,
            "_blank"
        );
    };

export const finishTask =
    ({ processId, taskId }) =>
    (dispatch) => {
        const formData = new FormData();
        formData.append("processo", processId);
        formData.append("tarefa", taskId);
        formData.append("accao", "terminar");
        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
    };

export const getNextTask =
    ({ processo, tarefa: taskId }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        formData.append("accao", "seguinte");
        formData.append("processo", processo);
        formData.append("tarefa", taskId);
        // return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
        return api("/Gestor/gereprocesso.php", formData)
            .then((data) => {
                const { processo: processAttributes, tarefa: taskAttributes, valores, formulario, erro } = data.dados;
                const processObj = getState().processExecution?.domain?.byId?.[processAttributes["@id"]];
                if (erro) {
                    return { erro };
                }

                let normalizedFormObject, modalsState, metaInfoformFields;

                const rootElement = formulario["@elemento_raiz"];
                if (formulario[rootElement]) {
                    const result = transformForm({
                        form: formulario,
                    });
                    normalizedFormObject = result.normalizedFormObject;
                    modalsState = result.modalsState;
                    metaInfoformFields = result.metaInfoformFields;
                }

                if (processo === processAttributes["@id"]) {
                    dispatch({
                        type: actionTypeIndex.setTaskDetails,
                        payload: {
                            ...taskAttributes,
                            processId: processo,
                            taskId: taskAttributes["@id"],
                            key: taskAttributes["@id"],
                            name: taskAttributes["@nome"],
                            status: "doing",
                            formMeta: [
                                {
                                    form: formulario["@nome"],
                                    meta: metaInfoformFields,
                                },
                            ],
                            page: normalizedFormObject,
                            details: {
                                readonlyDocuments: [],
                                uploadedDocuments: [],
                            },
                            transitions: {}, // todo: get this info in backend
                            actions: {}, // todo: get this info in backend
                        },
                    });
                } else if (processo !== processAttributes["@id"] && processObj) {
                    dispatch({
                        type: actionTypeIndex.setTaskDetails,
                        payload: {
                            ...taskAttributes,
                            processId: processAttributes["@id"],
                            taskId: taskAttributes["@id"],
                            key: taskAttributes["@id"],
                            name: taskAttributes["@nome"],
                            status: "doing",
                            formMeta: [
                                {
                                    form: formulario["@nome"],
                                    meta: metaInfoformFields,
                                },
                            ],
                            page: normalizedFormObject,
                            details: {
                                readonlyDocuments: [],
                                uploadedDocuments: [],
                            },
                            transitions: {}, // todo: get this info in backend
                            actions: {}, // todo: get this info in backend
                        },
                    });
                } else if (!processObj) {
                    const process = {
                        ...processAttributes,
                        baseProcessId: processo,
                        taskToSubProcess: taskAttributes["@id"],
                        tasks: {
                            [taskAttributes["@id"]]: {
                                ...taskAttributes,
                                key: taskAttributes["@id"],
                                name: taskAttributes["@nome"],
                                position: 0,
                                status: "doing",
                                formMeta: [
                                    {
                                        form: formulario["@nome"],
                                        meta: metaInfoformFields,
                                    },
                                ],
                                page: normalizedFormObject,
                                details: {
                                    readonlyDocuments: [],
                                    uploadedDocuments: [],
                                },
                                transitions: {}, // todo: get this info in backend
                                actions: {}, // todo: get this info in backend
                            },
                        },
                    };
                    dispatch({
                        type: actionType.initProcess,
                        payload: process,
                    });
                    dispatch({
                        type: actionType.initModals,
                        payload: modalsState,
                    });
                }
                const values = valores?.[formulario?.["@elemento_raiz"]];
                let form = [];
                /**
                 * * Mapping values to form fields
                 * ! On tables the value is treated as dataSource
                 * * form converted in array to easely operations perform and then turned back to object
                 */

                if (values) {
                    form = Object.values(normalizedFormObject)
                        .map((formItem) => {
                            const value = values[formItem.key];
                            if (formItem.key in values) {
                                // * Table cases
                                if (
                                    formItem["@tipo"] === componentType._dynamicTable ||
                                    formItem["@tipo"] === componentType._selectionTable ||
                                    formItem["@tipo"] === componentType.datagrid
                                ) {
                                    let dataSource = Object.values(value)[0];
                                    if (Array.isArray(dataSource)) {
                                        formItem.dataSource = dataSource.reduce((acumulator, current, index) => {
                                            current.key = index + 1;
                                            acumulator[index + 1] = current;
                                            return acumulator;
                                        }, {});
                                    } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                                        formItem.dataSource = {
                                            1: { key: 1, ...dataSource },
                                        };
                                    }
                                } else if (formItem["@tipo"] === componentType._dynamicDatagrid) {
                                    const _dataSource = value[formItem.key];
                                    if (Array.isArray(_dataSource)) {
                                        formItem.dataSource = _dataSource.reduce((acumulator, current, index) => {
                                            current.key = index + 1;
                                            acumulator[index + 1] = current;
                                            return acumulator;
                                        }, {});
                                    } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                                        formItem.dataSource = {
                                            1: { key: 1, ..._dataSource },
                                        };
                                    }
                                }
                                // * Other cases
                                else if (formItem["@tipo"] === componentType._inputGroup) {
                                    const _inputValues = value;
                                    formItem.value = _inputValues;
                                    for (const _key in _inputValues) {
                                        if (formItem?.[_key]) {
                                            formItem[_key]["value"] = _inputValues[_key];
                                        }
                                    }
                                } else {
                                    formItem.value = value;
                                }
                            }
                            return formItem;
                        })
                        .reduce((acumulator, current) => {
                            acumulator[current.key] = current;
                            return acumulator;
                        }, {});
                } else {
                    form = normalizedFormObject;
                }
                return {
                    taskId: taskAttributes["@id"],
                    notification: taskAttributes?.["@notificacao"],
                    isLastTask: taskAttributes["@ultima_tarefa"],
                    valores,
                    formName: formulario["@nome"],
                    form,
                    newProcessId: processAttributes["@id"],
                };
            })
            .catch((err) => {
                // message.error(err.erro);
                return { erro: err.erro };
            });
    };

export const getPreviousTask =
    ({ processo, tarefa: taskId }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        formData.append("accao", "anterior");
        formData.append("processo", processo);
        formData.append("tarefa", taskId);
        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
            if (!data?.dados) return;
            const { processo: processAttributes, tarefa: taskAttributes, valores, formulario, erro } = data.dados;
            const processObj = getState().processExecution?.domain?.byId?.[processAttributes["@id"]];
            if (erro) {
                return { erro };
            }
            let normalizedFormObject, modalsState, metaInfoformFields;
            const rootElement = formulario["@elemento_raiz"];
            if (formulario[rootElement]) {
                const result = transformForm({
                    form: formulario,
                });
                normalizedFormObject = result.normalizedFormObject;
                modalsState = result.modalsState;
                metaInfoformFields = result.metaInfoformFields;
            }
            if (processo === processAttributes["@id"]) {
                dispatch({
                    type: actionTypeIndex.setTaskDetails,
                    payload: {
                        ...taskAttributes,
                        processId: processo,
                        taskId: taskAttributes["@id"],
                        key: taskAttributes["@id"],
                        name: taskAttributes["@nome"],
                        status: "doing",
                        formMeta: [
                            {
                                form: formulario["@nome"],
                                meta: metaInfoformFields,
                            },
                        ],
                        page: normalizedFormObject,
                        details: {
                            readonlyDocuments: [],
                            uploadedDocuments: [],
                        },
                        transitions: {}, // todo: get this info in backend
                        actions: {}, // todo: get this info in backend
                    },
                });
            } else if (processo !== processAttributes["@id"] && processObj) {
                dispatch({
                    type: actionTypeIndex.setTaskDetails,
                    payload: {
                        ...taskAttributes,
                        processId: processAttributes["@id"],
                        taskId: taskAttributes["@id"],
                        key: taskAttributes["@id"],
                        name: taskAttributes["@nome"],
                        status: "doing",
                        formMeta: [
                            {
                                form: formulario["@nome"],
                                meta: metaInfoformFields,
                            },
                        ],
                        page: normalizedFormObject,
                        details: {
                            readonlyDocuments: [],
                            uploadedDocuments: [],
                        },
                        transitions: {}, // todo: get this info in backend
                        actions: {}, // todo: get this info in backend
                    },
                });
            } else if (!processObj) {
                const process = {
                    ...processAttributes,
                    baseProcessId: processo,
                    taskToSubProcess: taskAttributes["@id"],
                    tasks: {
                        [taskAttributes["@id"]]: {
                            ...taskAttributes,
                            key: taskAttributes["@id"],
                            name: taskAttributes["@nome"],
                            position: 0,
                            status: "doing",
                            formMeta: [
                                {
                                    form: formulario["@nome"],
                                    meta: metaInfoformFields,
                                },
                            ],
                            page: normalizedFormObject,
                            details: {
                                readonlyDocuments: [],
                                uploadedDocuments: [],
                            },
                            transitions: {}, // todo: get this info in backend
                            actions: {}, // todo: get this info in backend
                        },
                    },
                };
                dispatch({
                    type: actionType.initProcess,
                    payload: process,
                });
                dispatch({
                    type: actionType.initModals,
                    payload: modalsState,
                });
            }
            const values = valores?.[formulario?.["@elemento_raiz"]];
            let form = [];
            /**
             * * Mapping values to form fields
             * ! On tables the value is treated as dataSource
             * * form converted in array to easely operations perform and then turned back to object
             */

            if (values) {
                form = Object.values(normalizedFormObject)
                    .map((formItem) => {
                        const value = values[formItem.key];
                        if (formItem.key in values) {
                            // * Table cases
                            if (
                                formItem["@tipo"] === componentType._dynamicTable ||
                                formItem["@tipo"] === componentType._selectionTable ||
                                formItem["@tipo"] === componentType.datagrid
                            ) {
                                let dataSource = Object.values(value)[0];
                                if (Array.isArray(dataSource)) {
                                    formItem.dataSource = dataSource.reduce((acumulator, current, index) => {
                                        current.key = index + 1;
                                        acumulator[index + 1] = current;
                                        return acumulator;
                                    }, {});
                                } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                                    formItem.dataSource = {
                                        1: { key: 1, ...dataSource },
                                    };
                                }
                            } else if (formItem["@tipo"] === componentType._dynamicDatagrid) {
                                const _dataSource = value[formItem.key];

                                if (Array.isArray(_dataSource)) {
                                    formItem.dataSource = _dataSource.reduce((acumulator, current, index) => {
                                        current.key = index + 1;
                                        acumulator[index + 1] = current;
                                        return acumulator;
                                    }, {});
                                } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                                    formItem.dataSource = {
                                        1: { key: 1, ..._dataSource },
                                    };
                                }

                                // formItem.dataSource = _dataSource;
                            }
                            // * Other cases
                            else if (formItem["@tipo"] === componentType._inputGroup) {
                                const _inputValues = value;

                                formItem.value = _inputValues;
                                for (const _key in _inputValues) {
                                    if (formItem?.[_key]) {
                                        formItem[_key]["value"] = _inputValues[_key];
                                    }
                                }
                                // item.dataSource = _dataSource;
                            } else {
                                formItem.value = value;
                            }
                        }

                        return formItem;
                    })
                    .reduce((acumulator, current) => {
                        acumulator[current.key] = current;
                        return acumulator;
                    }, {});
            } else {
                form = normalizedFormObject;
            }
            return { taskId: taskAttributes["@id"], form, valores };
        });
    };

export const getNextTaskDetails =
    ({ processo, isSubProcess, tarefa: taskId, ...payload }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        formData.append("accao", "seguinte_detalhe");
        formData.append("processo", processo);
        formData.append("tarefa", taskId);
        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
            const { fluxograma, tarefa } = data;
            if (fluxograma && !payload.isOnChange) {
                const tasks = getState().processExecution?.domain?.byId?.[processo]?.tasks;
                const isTaskLoaded = tasks[taskId];
                if (!isTaskLoaded || isSubProcess) {
                    const previousTasksOrdered = orderPreviousTasks(
                        fluxograma,
                        /*tarefa["@attributes"]?.id*/ taskId,
                        tasks
                    );
                    dispatch({
                        type: actionTypeIndex.setPreviousTasks,
                        payload: {
                            tasks: previousTasksOrdered,
                            processId: processo,
                        },
                    });
                    return;
                }
            }
            const payloadUpdated = handleReports(data, payload);
            dispatch({
                type: actionType.getTaskDetails,
                payload: {
                    processId: processo,
                    taskId,
                    ...payloadUpdated,
                },
            });
        });
    };

export const getPreviousTaskDetails =
    ({ processo, isSubProcess, tarefa: taskId, ...payload }) =>
    (dispatch, getState) => {
        const formData = new FormData();
        formData.append("accao", "anterior_detalhe");
        formData.append("processo", processo);
        formData.append("tarefa", taskId);
        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
            const { fluxograma, tarefa } = data;
            if (fluxograma && !payload.isOnChange) {
                const tasks = getState().processExecution?.domain?.byId?.[processo]?.tasks;
                const isTaskLoaded = tasks[taskId];
                if (!isTaskLoaded || isSubProcess) {
                    const previousTasksOrdered = orderPreviousTasks(
                        fluxograma,
                        /*tarefa["@attributes"]?.id*/ taskId,
                        tasks
                    );
                    dispatch({
                        type: actionTypeIndex.setPreviousTasks,
                        payload: {
                            tasks: previousTasksOrdered,
                            processId: processo,
                        },
                    });
                    return;
                }
            }
            const payloadUpdated = handleReports(data, payload);
            dispatch({
                type: actionType.getTaskDetails,
                payload: { processId: processo, taskId, ...payloadUpdated },
            });
        });
    };

//valencias_salas

const handleReports = (data, payload) => {
    const { ficheiros, relatorios } = data;
    if (ficheiros) {
        //* system's default documents
        if (!Utilitaries.isEmpty(toArray(ficheiros.linha))) {
            const uploadedDocumentsArray = toArray(ficheiros.linha);
            // array to object transformation
            const uploadedDocuments = uploadedDocumentsArray.map((file) => ({ ...file, key: file.id_ficheiro }));
            // .reduce((acumulator, current, index) => {
            //     acumulator[current.key] = current;
            //     return acumulator;
            // }, {});

            payload.uploadedDocuments = uploadedDocuments;
        } else {
            payload.uploadedDocuments = [];
        }
    }
    if (relatorios) {
        //* imported documents
        if (Array.isArray(relatorios?.linha)) {
            const readonlyDocuments = relatorios.linha;
            payload.readonlyDocuments = readonlyDocuments;
        } else if (Object.keys(relatorios?.linha).length > 0) {
            payload.readonlyDocuments = [relatorios.linha];
        }
    }
    return payload;
};

/**
 * * Order tasks to build the circles representing executed tasks
 */
const orderPreviousTasks = (fluxograma, currentTaskId, currentTasks) => {
    const tasks = fluxograma["root-container"].node.filter(
        (item) => item["@attributes"].tipo === "tarefa" || item["@attributes"].tipo === "processo"
    );
    const previousTasks = tasks
        .filter((el) => el["@attributes"].id_tarefa)
        .reduce((acumulator, task) => {
            if (task["@attributes"]?.id_tarefa && Number(task["@attributes"]?.id_tarefa) !== Number(currentTaskId)) {
                const taskId = task["@attributes"].id_tarefa;
                const subprocessExp = /[0-9]*:[0-9]*:.*:[0-9]*:.*/;

                // const aaa = taskId.match(subprocessExp);
                if (taskId.match(subprocessExp)) {
                    for (const taks of taskId.split(";")) {
                        if (!Utilitaries.isEmpty(taks)) {
                            const [processId, taskIdMixed, taskName, ...rest] = taks.split(":");
                            acumulator[taskIdMixed.replace(";", "")] = {
                                key: taskIdMixed.replace(";", ""),
                                name: task["@attributes"].name,
                                processId,
                                isProcess: true,
                                type: "sub",
                                ...currentTasks[taskIdMixed],
                                // ...currentTasks[taskId.replace(";", "")],
                            };
                        }
                    }

                    return acumulator;
                }
                acumulator[taskId.replace(";", "")] = {
                    key: taskId.replace(";", ""),
                    name: task["@attributes"].name,
                    ...currentTasks[taskId.replace(";", "")],
                };
            } else {
                acumulator[currentTaskId.replace(";", "")] = {
                    ...currentTasks[currentTaskId.replace(";", "")],
                };
            }
            return acumulator;
        }, {});
    const previousTasksOrdered = Object.keys(previousTasks)
        .sort((prev, next) => {
            if (Number(prev) > Number(next)) {
                return -1;
            } else if (Number(prev) < Number(next)) {
                return 1;
            } else return 0;
        })
        .reduce((acumulator, current) => {
            acumulator[current] = {
                ...previousTasks[current],
            };
            return acumulator;
        }, {});
    return previousTasksOrdered;
};
