import React from "react";
import { Select, Form, Row, Col, Button } from "antd";
import { Utilitaries } from "../../../business";

const { Option } = Select;

const ProcessFilter = ({
    selectedMacroProcess,
    changeMacroProcess,
    macroProcesses,
    selectedMesoProcess,
    mesoProcesses,
    changeMesoProcess,
    selectedProcess,
    processes,
    changeProcess,
    processGraphData,
}) => {
    return (
        <Form
            labelCol={{
                xs: { span: 24 },
                sm: { span: 8 },
            }}
            wrapperCol={{
                xs: { span: 24 },
                sm: { span: 16 },
            }}
            layout="horizontal"
        >
            <Row>
                <Col xs={24} md={8}>
                    <Form.Item label="Macro-Processo" style={{ width: "100%" }}>
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Selecione uma opção"
                            allowClear
                            onChange={(value) => {
                                changeMacroProcess(value);
                            }}
                        >
                            {macroProcesses.map(({ id_parametro, valor }) => (
                                <Option key={id_parametro}>{valor}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item label="Meso-Processo">
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Selecione uma opção"
                            value={selectedMesoProcess}
                            allowClear
                            disabled={!selectedMacroProcess}
                            onChange={(value) => changeMesoProcess(value)}
                        >
                            {Utilitaries.toArray(mesoProcesses).map(({ id_parametro, valor }) => (
                                <Option key={id_parametro}>{valor}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item label="Processo">
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Selecione uma opção"
                            value={selectedProcess}
                            allowClear
                            onChange={(value) => changeProcess(value)}
                        >
                            {processes.map(({ id, nome }) => (
                                <Option key={id}>{nome}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} md={2}>
                    <Form.Item>
                        <Button icon="play-circle" onClick={processGraphData} style={{ marginLeft: 10 }}>
                            Gerar Interação
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default ProcessFilter;
