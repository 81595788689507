export const actionTypes = {
    selectData: "SELECT_DASHBOARD_DATA",
};

export const dashboardKey = {
    horizontalBar: "horizontalBar",
    multipleBar: "multipleBar",
    multipleLine: "multipleLine",
    pizza: "pizza",
    speedometer: "speedometer",
    multipleSpeedometer: "multipleSpeedometer",
    widgets: "widgets",
    table: "table",
    nestedTable: "nestedTable",
};

export const customFilter = {
    unidadeOrganica: "unidadeOrganica",
    tipoInquerito: "tipoInquerito",
};

const sortByNumber = "number";

export const dashboardList = [
    {
        key: "controle_tarefas",
        title: "Controle das tarefas",
        filter: [customFilter.unidadeOrganica],
        elements: [
            {
                type: dashboardKey.widgets,
                data: ["total_execucao"],
                widgets: [
                    {
                        label: "Total tarefas",
                        value: 0,
                        icon: "align-left",
                        color: "#69c0ff",
                        rgba: "#69c0ff40",
                    },
                    {
                        label: "Tarefas iniciadas",
                        value: 1,
                        icon: "snippets",
                        color: "#1890ff",
                        rgba: "#1890ff40",
                    },
                    {
                        label: "Tarefas não iniciadas",
                        value: 2,
                        color: "#cf1322",
                        rgba: "#cf132240",
                        icon: "frown",
                    },
                    {
                        label: "Tarefas terminadas",
                        value: 3,
                        color: "#3f8600",
                        rgba: "#3f860040",
                        icon: "smile",
                    },
                    {
                        label: "Tarefas não terminadas",
                        value: 4,
                        color: "#595959",
                        rgba: "#59595940",
                        icon: "exclamation-circle",
                    },
                ],
            },
            {
                type: dashboardKey.multipleBar,
                title: "Tarefas",
                data: ["execucao_atividades"],
                horizontalLabel: "Anos",
                verticalLabel: "Quantidade tarefas",
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do período",
                data: ["taxa_execucao"],
                labelTotal: "atividades",
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do ano atual",
                data: ["taxa_execucao_ano_corrente"],
                labelTotal: "atividades",
            },
        ],
    },
    {
        key: "auditoria",
        title: "Auditorias internas",
        filter: [],
        elements: [
            {
                type: dashboardKey.multipleBar,
                title: "Número das auditorias",
                data: ["gestão_auditorias", "auditorias", "planeado_executado"],
                horizontalLabel: "Anos",
                verticalLabel: "Nº de auditorias",
                sortType: sortByNumber,
                showTotal: true,
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do período",
                data: ["gestão_auditorias", "auditorias", "taxa_execucao"],
                labelTotal: "auditorias",
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de excução do ano atual",
                data: ["gestão_auditorias", "auditorias", "taxa_execucao_currente_ano"],
                labelTotal: "auditorias",
            },
            {
                type: dashboardKey.pizza,
                title: "Taxa de conformidade",
                labelTotal: "items",
                data: ["gestão_auditorias", "items_auditados", "grau_conformidade"],
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de conformidade",
                data: ["gestão_auditorias", "items_auditados", "taxa_conformidade"],
                labelTotal: "items",
            },
        ],
    },
    {
        key: "reclamacoes",
        title: "Reclamações e sugestões",
        filter: [customFilter.unidadeOrganica],
        elements: [
            {
                type: dashboardKey.multipleBar,
                title: "Reclamações por unidade orgânica",
                data: ["gestao_reclamacoes", "reclamacoes"],
                horizontalLabel: "Unidade orgânica",
                verticalLabel: "Nº de reclamações",
                showTotal: true,
            },
            {
                type: dashboardKey.multipleBar,
                title: "Número de sugestões",
                data: ["gestao_reclamacoes", "sugestoes"],
                horizontalLabel: "Unidade orgânica",
                verticalLabel: "Nº de sugestões",
                showTotal: true,
            },
            {
                type: dashboardKey.multipleBar,
                title: "Reclamações, sugestões e elogios legítimos",
                data: ["gestao_reclamacoes", "reclamacoes_sugestoes_elogios_legitimos"],
                horizontalLabel: "Unidade orgânica",
                verticalLabel: "Nº",
                showTotal: true,
            },
            {
                type: dashboardKey.multipleBar,
                title: "Total das reclamações, sugestões e elogios",
                data: ["gestao_reclamacoes", "reclamacoes_sugestoes_elegios_registados"],
                horizontalLabel: "Unidade orgânica",
                verticalLabel: "Nº",
                showTotal: true,
            },
        ],
    },
    {
        key: "abertura_inquerito",
        title: "Inquéritos",
        filter: [customFilter.tipoInquerito],
        elements: [
            {
                type: dashboardKey.nestedTable,
                labelTotal: "Total de inquéritos",
                title: "Quantidades dos inquéritos por tipo",
                data: ["abertura_inquerito", "abertura_inqueritos_tipo"],
                horizontalLabel: "Anos",
                verticalLabel: "Nº de inqueritos",
                sortType: sortByNumber,
                showTotal: false,
                columns: [{ key: "label", label: "Tipos de inquéritos" }],
                expandedRowColumns: [
                    { key: "label", label: "Ano" },
                    { key: "valor", label: "Quantidade" },
                ],
            },
            {
                type: dashboardKey.horizontalBar,
                checkData: true,
                title: "Tipo de inquérito aberto por entidade",
                labelTotal: "inquéritos",
                data: ["abertura_inquerito", "numero_tipo_enquerito_aberto_por_entidade"],
                horizontalLabel: "Tipo de entidades",
                verticalLabel: "Nº",
            },
            {
                type: dashboardKey.horizontalBar,
                checkData: true,
                title: "Média tipo de inquérito por entidade",
                labelTotal: "inquéritos",
                data: ["abertura_inquerito", "media_tipo_inquerito_por_entidade"],
                horizontalLabel: "Tipo de entidades",
                verticalLabel: "Nº",
            },
            // {
            // 	type: dashboardKey.multipleSpeedometer,
            // 	title: "Grau ",
            // 	data: ["abertura_inquerito", "grau_satisfacao_por_entidade"],
            // },
        ],
    },
    {
        key: "manual_funcoes",
        title: "Manual de funções",
        filter: [customFilter.unidadeOrganica],
        elements: [
            {
                type: dashboardKey.pizza,
                title: "Funcionários por estado operacional",
                labelTotal: "funcionários",
                data: ["manual_funcoes", "funcionarios", "estado_operacional"],
            },
            {
                type: dashboardKey.pizza,
                title: "Categorização dos funcionários suspensos",
                labelTotal: "funcionários suspensos",
                data: ["manual_funcoes", "funcionarios", "motivo_suspensao"],
            },
            {
                type: dashboardKey.horizontalBar,
                title: "Funcionários por grau de habilitação",
                labelTotal: "funcionários",
                data: ["manual_funcoes", "funcionarios", "grau_habilitacao"],
                horizontalLabel: "Habilitações académicas",
                verticalLabel: "Nº funcionários",
            },
            {
                type: dashboardKey.horizontalBar,
                title: "Funcionários por faixa etária",
                labelTotal: "funcionários",
                data: ["manual_funcoes", "funcionarios", "faixa_etaria"],
                horizontalLabel: "Faixas etárias",
                verticalLabel: "Nº funcionários",
            },
            {
                type: dashboardKey.pizza,
                title: "Funcionários por género",
                labelTotal: "funcionários",
                data: ["manual_funcoes", "funcionarios", "genero"],
            },
            {
                type: dashboardKey.pizza,
                title: "Taxa de assiduidade",
                labelTotal: "dias previstos",
                data: ["manual_funcoes", "faltas", "taxa_assiduidade"],
            },
            {
                type: dashboardKey.table,
                title: "Distribuição das faltas por motivo",
                labelTotal: "Total de faltas",
                data: ["manual_funcoes", "faltas", "motivos_faltas"],
                columns: [
                    { key: "label", label: "Motivo das faltas" },
                    { key: "valor", label: "Nº das faltas" },
                ],
            },
            {
                type: dashboardKey.pizza,
                title: "Formações",
                labelTotal: "horas",
                data: ["manual_funcoes", "formacao", "horas_formacoes"],
            },
            {
                type: dashboardKey.multipleBar,
                title: "Avaliação de execução da formação planeada",
                showTotal: true,
                data: ["manual_funcoes", "formacao", "execucao_formacao_planeado"],
                horizontalLabel: "Tipo de formação",
                verticalLabel: "Nº de horas",
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de avaliação da formação",
                labelTotal: ["Participantes"],
                data: ["manual_funcoes", "formacao", "taxa_avaliacao"],
            },
            {
                type: dashboardKey.speedometer,
                title: "Eficácia da formação",
                labelTotal: ["Avaliações"],
                data: ["manual_funcoes", "formacao", "taxa_eficacia_formacao"],
            },
            {
                type: dashboardKey.multipleBar,
                title: "Taxa de desempenho dos funcionários e equipas",
                showTotal: true,

                // labelTotal: ["Total avaliação individual", "Total avaliação de equipa"],
                data: ["manual_funcoes", "avaliacao_desempenho"],
                horizontalLabel: "Classificação",
                verticalLabel: "Nº dos avaliados",
            },
        ],
    },
    {
        key: "comunicacao_organizacional",
        title: "Comunicação organizacional",
        filter: [],
        elements: [
            {
                type: dashboardKey.multipleBar,
                title: "Número das comunicações",
                data: ["comunicacao_organizacional", "planeado_executado"],
                horizontalLabel: "Anos",
                verticalLabel: "Nº de comunicações",
                sortType: sortByNumber,
                showTotal: true,
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do periodo",
                data: ["comunicacao_organizacional", "taxa_execucao"],
                labelTotal: "Comunicações",
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do ano atual",
                data: ["comunicacao_organizacional", "taxa_execucao_ano_currente"],
                labelTotal: "Comunicações",
            },
        ],
    },
    {
        key: "reunioes",
        title: "Reuniões",
        filter: [],
        elements: [
            {
                type: dashboardKey.multipleBar,
                title: "Número das reuniões",
                data: ["plano_reunioes", "planeado_executado"],
                horizontalLabel: "Anos",
                verticalLabel: "Nº de reuniões",
                sortType: sortByNumber,
                showTotal: true,
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do período",
                data: ["plano_reunioes", "taxa_execucao"],
                labelTotal: "Reuniões",
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do ano atual",
                data: ["plano_reunioes", "taxa_execucao_ano_currente"],
                sortType: sortByNumber,
                labelTotal: "Reuniões",
            },
        ],
    },
    {
        key: "g_parceria",
        title: "Parcerias",
        filter: [],
        elements: [
            {
                type: dashboardKey.multipleBar,
                title: "Número das parcerias",
                data: ["atividades_em_parceria", "planeado_executado"],
                horizontalLabel: "Anos",
                verticalLabel: "Nº de parcerias",
                sortType: sortByNumber,
                showTotal: true,
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do período",
                data: ["atividades_em_parceria", "taxa_execucao"],
                labelTotal: "Parcerias",
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do ano atual",
                data: ["atividades_em_parceria", "taxa_execucao_currente_ano"],
                labelTotal: "Parcerias",
            },
            {
                type: dashboardKey.nestedTable,
                title: "Parcerias executadas por atividades",
                data: ["atividades_em_parceria", "executado_por_atividade"],
                labelTotal: "Parcerias",
                columns: [{ key: "label", label: "Atividades" }],
                expandedRowColumns: [
                    { key: "label", label: "Ano" },
                    { key: "valor", label: "Quantidade" },
                ],
            },
        ],
    },
    {
        key: "espaco_equipamentos",
        title: "Espaços e equipamentos",
        filter: [],
        elements: [
            {
                type: dashboardKey.multipleBar,
                title: "Número das higienizações de espaços",
                data: ["gestao_espaco_equipamentos", "higienizacao_espaco", "planeado_executado"],
                horizontalLabel: "Anos",
                verticalLabel: "Nº de higienizações",
                sortType: sortByNumber,
                showTotal: true,
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do período",
                data: ["gestao_espaco_equipamentos", "higienizacao_espaco", "taxa_execucao"],
                labelTotal: "Higienizações de espaços",
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do ano atual",
                data: ["gestao_espaco_equipamentos", "higienizacao_espaco", "taxa_execucao_currente_ano"],
                labelTotal: "Higienizações",
            },
            {
                type: dashboardKey.multipleBar,
                title: "Número de manutenções de equipamentos",
                data: ["gestao_espaco_equipamentos", "manutencao_equipamentos", "planeado_executado"],
                horizontalLabel: "Anos",
                verticalLabel: "Nº de equipamentos",
                sortType: sortByNumber,
                showTotal: true,
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do período",
                data: ["gestao_espaco_equipamentos", "manutencao_equipamentos", "taxa_execucao"],
                labelTotal: "Manutenções",
            },
            {
                type: dashboardKey.speedometer,
                title: "Taxa de execução do ano atual",
                data: ["gestao_espaco_equipamentos", "manutencao_equipamentos", "taxa_execucao_currente_ano"],
                labelTotal: "Manutenções",
            },
        ],
    },
    {
        key: "classificacao_fornecedores",
        title: "Fornecedores",
        elements: [
            {
                type: dashboardKey.multipleBar,
                title: "Avaliação dos fornecedores",
                data: ["gestao_fornecedores", "classificacao_fornecedores"],
                horizontalLabel: "Classificação",
                verticalLabel: "Nº fornecedores",
                showTotal: true,
            },
        ],
    },
    {
        key: "riscos_opurtunidades",
        title: "Riscos e oportunidades",
        elements: [
            {
                type: dashboardKey.multipleBar,
                title: "Riscos e oportunidades",
                data: ["gestao_riscos_opurtunidades", "riscos_opurtunidades"],
                horizontalLabel: "Categorias",
                showTotal: true,
            },
            {
                type: dashboardKey.pizza,
                title: "Riscos por categoria",
                labelTotal: "riscos",
                data: ["gestao_riscos_opurtunidades", "taxa_riscos_categoria"],
            },
            {
                type: dashboardKey.pizza,
                title: "Oportunidades por categoria",
                labelTotal: "oportunidades",
                data: ["gestao_riscos_opurtunidades", "taxa_riscos_categoria"],
            },
        ],
    },
];
