import React, { useEffect, useState, useCallback } from "react";
import { Form, Input, InputNumber, Button, Modal } from "antd";
import { componentType } from "../../constants/index";
import debounce from "lodash.debounce";
import { nullSafe, canCorrectField, tableInputWidth } from "../../utils/fields";
import { isEmpty } from "../../../../business/utils";
import { FormUtilitaries } from "../../../../business";
import { runMain } from "module";

const { TextArea } = Input;

const QaInput = ({
    type,
    itemData,
    onChange,
    currentTaskStatus,
    label,
    textLabel,
    readonly,
    renderContext,
    error,
    openEditorFieldModal,
    formItemStyle,
    correctForm,
    fixedSize,
    showFieldEditorModal,
}) => {
    const debounceChange = debounce(onChange, 500);
    const [currentValue, setCurrentValue] = useState();

    useEffect(() => {
        if ((isEmpty(currentValue) && !isEmpty(itemData.value)) || currentValue !== nullSafe(itemData.value)) {
            setCurrentValue(nullSafe(itemData.value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemData.value]);

    const handleChange = (event) => {
        const { value } = event.target;
        setCurrentValue(value);
    };

    const showLabel =
        itemData?.["@etiqueta"] !== undefined &&
        !itemData?.["@etiqueta"]?.trim() &&
        itemData?.["@grupo"] &&
        !itemData?.["@ajuda"]
            ? {}
            : { label };

    const autosize =
        renderContext === "column" && showFieldEditorModal
            ? { minRows: 1, maxRows: 1 }
            : { minRows: renderContext === "column" ? 1 : 2, maxRows: 50 };
    const textAreaStyle = renderContext === "column" && showFieldEditorModal && fixedSize ? { width: "150px" } : {};

    const textAreaProps = {
        autoSize: autosize,
        style: textAreaStyle,
        allowClear: renderContext === "column" && showFieldEditorModal ? false : true,
    };

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus || readonly;

    const inputWidth = tableInputWidth(itemData, fixedSize);

    const stylesInput =
        renderContext === "column" && showFieldEditorModal && type !== componentType._inputNumber
            ? {
                  display: "flex",
                  ...inputWidth,
              }
            : inputWidth;

    let stylesInputNumber = { width: "100%" };
    if (renderContext === "column" && type === componentType._inputNumber && itemData?.["dataIndex"] === "meta") {
        stylesInputNumber = { width: 100 };
    }

    const getInputLength = () => {
        let size = 0;
        const style = itemData["@estilo"];
        if (style) {
            if (style.includes("maxLength")) {
                size = style.match(/(?<=(maxLength))[0-9]+/);

                try {
                    size = parseInt(size[0]);
                } catch (err) {}
            }
        }
        if (!size || !style) {
            return {};
        }

        return { maxLength: size };
    };

    const maxSize = getInputLength();

    return (
        <>
            <Form.Item {...showLabel} style={formItemStyle} colon={itemData["@etiqueta"] !== ""}>
                <div style={stylesInput}>
                    {type === componentType._input && (
                        <Input
                            {...maxSize}
                            disabled={disabled}
                            placeholder={itemData["@placeholder"]}
                            value={currentValue}
                            allowClear
                            onChange={handleChange}
                            onBlur={() => onChange(currentValue)}
                            style={FormUtilitaries.highlightsErrors(currentValue, itemData.required)}
                        />
                    )}

                    {(type === componentType.shortText ||
                        type === componentType.areaTexto ||
                        type === componentType.text) && (
                        <TextArea
                            onChange={handleChange}
                            // defaultValue={nullSafe(itemData.value)}
                            // autoSize={autosize}
                            // style={textAreaStyle}
                            onBlur={() => onChange(currentValue)}
                            {...textAreaProps}
                            disabled={disabled}
                            // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                            // value={nullSafe(itemData.value)}
                            value={currentValue}
                            compact
                            style={FormUtilitaries.highlightsErrors(currentValue, itemData.required)}
                            // allowClear
                            placeholder={
                                itemData["@placeholder"] || "Introduza " + itemData["@etiqueta"]?.toLowerCase()
                            }
                        />
                    )}

                    {/* {type === componentType.text && (
                        <TextArea
                            onChange={onChange}
                            // autoSize={autosize}
                            // style={textAreaStyle}
                            {...textAreaProps}
                            disabled={disabled}
                            // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                            value={nullSafe(itemData.value)}
                            compact
                            // allowClear
                            placeholder={itemData["@placeholder"]}
                        />
                    )} */}
                    {/* {type === componentType.areaTexto && (
                        <TextArea
                            onChange={onChange}
                            // autoSize={autosize}
                            // style={textAreaStyle}
                            {...textAreaProps}
                            disabled={disabled}
                            // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                            value={nullSafe(itemData.value)}
                            compact
                            // allowClear
                            placeholder={itemData["@placeholder"]}
                        />
                    )} */}

                    {type === componentType._inputNumber && (
                        <InputNumber
                            style={{
                                ...FormUtilitaries.highlightsErrors(nullSafe(itemData.value), itemData.required),
                                ...stylesInputNumber,
                            }}
                            allowClear
                            value={nullSafe(itemData.value)}
                            disabled={disabled}
                            // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                            // onChange={debounceChange}
                            onBlur={onChange}
                            // onChange={onChange}
                            // min={0}
                            placeholder={itemData.placeholder}
                        />
                    )}
                    {renderContext === "column" && showFieldEditorModal && type !== componentType._inputNumber && (
                        <Button
                            style={{ marginLeft: 5 }}
                            icon="edit"
                            onClick={() =>
                                openEditorFieldModal({
                                    name: textLabel,
                                    onChange,
                                    disabled, //: currentTaskStatus || readonly || canCorrectField(itemData),
                                    value: nullSafe(itemData.value),
                                })
                            }
                        />
                    )}
                </div>
                {error && <span style={{ color: "red" }}>{error}</span>}
            </Form.Item>
        </>
    );
};
export default QaInput;
