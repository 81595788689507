import React, { useEffect } from "react";
import { Input } from "antd";

const { TextArea } = Input;

const AdvancedModeForm = (props) => {
    useEffect(() => {
        props.configModal({ title: "Propriedades do campo" });
    });
    return <TextArea rows={20} />;
};

export default AdvancedModeForm;
