export const actionType = {
    select: "SELECT_KNOWLEDGE",
    insert: "INSERT_KNOWLEDGE",
    getForm: "GET_FORM_KNOWLEDGE",
    setField: "SET_FIELD_KNOWLEDGE",
    getById: "GET_KNOWLEDGE_BY_ID",
    // update: "UPDATE_KNOWLEDGE_TYPE",
    delete: "DELETE_KNOWLEDGE",
    // getEdit: "GET_EDIT_KNOWLEDGE",
    // update: "UPDATE_KNOWLEDGE",
    // delete: "DELETE_KNOWLEDGE"
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
export const lista = "conhecimentos";
