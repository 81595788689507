import React, { Component } from "react";
import { Table, Tag, Divider, Tooltip, Icon, Button, Input } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";

const ProcessTable = ({ tableDatasource, loading, goTo, handleTableChange, filter }) => {
    const handleSearch = (selectedKeys, confirm, columnSearched) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={React.createRef()}
                    placeholder={`Pesquisar`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 198, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 100, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" icon="cl" style={{ width: 90 }}>
                    Limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        filteredValue: filter[`filtro_${dataIndex}`] || null,
    });

    const getColumnSortProps = (dataIndex) => {
        return {
            sorter: true,
            sortOrder: filter?.sorter?.columnKey === dataIndex && filter?.sorter?.order,
        };
    };

    const columns = [
        {
            title: "Código",
            dataIndex: "codigo_externo",
            key: "codigo_externo",
            width: "10%",
            ...getColumnSearchProps("codigo_externo"),
            ...getColumnSortProps("codigo_externo"),
            sorter: true,
        },
        {
            title: "Nome",
            dataIndex: "nome_externo",
            key: "nome_externo",
            width: "15%",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            ...getColumnSearchProps("nome_externo"),
            ...getColumnSortProps("nome_externo"),
        },
        {
            title: "Âmbito",
            width: "25%",
            dataIndex: "ambito",
            key: "ambito",
            ...getColumnSearchProps("ambito"),
            // render: (text) =>
            //     text.length > 40 ? (
            //         <Tooltip title={text}>
            //             <div
            //                 style={{
            //                     textOverflow: "ellipsis",
            //                     width: "500px",
            //                     whiteSpace: "nowrap",
            //                     overflow: "hidden",
            //                 }}
            //             >
            //                 {text}
            //             </div>
            //         </Tooltip>
            //     ) : (
            //         text
            //     ),
        },
        {
            title: "Versão",
            dataIndex: "versao",
            key: "versao",
            width: "10%",
            ...getColumnSearchProps("versao"),
            ...getColumnSortProps("versao"),
            sorter: true,
        },
        {
            title: "Estado",
            dataIndex: "estado_processo",
            key: "estado_processo",
            width: "10%",
            filters: [
                {
                    text: "Aprovado",
                    value: "Aprovado",
                },
                {
                    text: "Em elaboração",
                    value: "Em elaboração",
                },
                {
                    text: "Obsoleto",
                    value: "Obsoleto",
                },
            ],
            filterMultiple: false,
            // onFilter: (value, record) => record.type.indexOf(value) === 0,
            render: (text, record) => {
                const color = text === "Aprovado" ? "green" : text === "Obsoleto" ? "red" : "rgba(0, 0, 0, 0.65)";
                return (
                    <span
                        style={{
                            color: color,
                            width: 120,
                            textAlign: "center",
                        }}
                    >
                        {text}
                    </span>
                );
            },
        },
        {
            title: "Utilizado",
            dataIndex: "utilizado",
            key: "utilizado",
            width: "10%",
            filters: [
                {
                    text: "Sim",
                    value: "Sim",
                },
                {
                    text: "Não",
                    value: "Não",
                },
            ],
            filterMultiple: false,
            // onFilter: (value, record) => record.type.indexOf(value) === 0,
            render: (text, record) => {
                const color = text === "Sim" ? "green" : "red";
                return (
                    <span
                        style={{
                            color: color,
                            width: 120,
                            textAlign: "center",
                        }}
                    >
                        {text}
                    </span>
                );
            },
        },
        {
            title: "Dono do processo",
            dataIndex: "dono",
            key: "dono",
            width: "10%",
            sorter: true,
            ...getColumnSearchProps("dono"),
            ...getColumnSortProps("dono"),
        },
        {
            title: "Ações",
            dataIndex: "",
            width: "10%",
            key: "x",
            render: (text, record) => (
                <span>
                    <a href="javascript:;" onClick={() => goTo(record.id)}>
                        Detalhes
                    </a>
                    {record.forma_iniciar === "pop_social_mask" && (
                        <>
                            <Divider type="vertical" />
                            <a href="javascript:;" onClick={() => goTo(record.id, true)}>
                                Subprocessos
                            </a>
                        </>
                    )}
                    {/* <Divider type="vertical" />
            <a href="javascript:;" className="qa-text-del">
              Eliminar
            </a> */}
                </span>
            ),
        },
    ];

    return (
        <Table
            size="middle"
            loading={loading}
            rowKey={(data) => data.id + "-table"}
            onRow={(record, rowIndex) => {
                return {
                    onDoubleClick: (event) => {
                        goTo(record.id);
                    }, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {
                        event.target.style.cursor = "pointer";
                    }, // mouse enter row
                    onMouseLeave: (event) => {
                        event.target.style.cursor = "none";
                    }, // mouse leave row
                };
            }}
            pagination={false}
            columns={columns}
            dataSource={tableDatasource}
            onChange={handleTableChange}
        />
    );
};
export default ProcessTable;
