import { Alert, Icon, Input, Form, Button, Row, Col, Select } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Session } from "../../../business";
import { getFieldDataSource } from "../../../modules/process_execution/actions";
import { api } from "../../http.request";
import { getuserComputerInfo } from "../../util";

import Layout from "../Common/Layout";

import "./register_unicv.css";

const RegisterUniCV = (props) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadData, setLoadData] = useState({
        pais: true,
        distrito: false,
        freguesia: false,
        concelho: false,
    });
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [districts, setDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const [municipalities, setMunicipalities] = useState([]);
    const [selectedMunicipality, setSelectedMunicipality] = useState("");
    const [parishes, setParishes] = useState([]);
    const [selectedParish, setSelectedParish] = useState("");

    const userInfo = getuserComputerInfo();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getFieldDataSource({
                dataSource: "pais",
                params: {
                    guest: true,
                },
            })
        ).then((data) => {
            setCountries(data.result);
            setLoadData({
                ...loadData,
                pais: false,
            });
        });
    }, []);

    useEffect(() => {
        if (!selectedCountry) return;
        setLoadData({
            ...loadData,
            distrito: true,
        });
        dispatch(
            getFieldDataSource({
                dataSource: "distrito",
                params: {
                    guest: true,
                    pais: selectedCountry,
                },
            })
        ).then((data) => {
            setDistricts(data.result);
            setLoadData({
                ...loadData,
                distrito: false,
            });
        });
    }, [selectedCountry]);

    useEffect(() => {
        if (!selectedDistrict) return;

        setLoadData({
            ...loadData,
            concelho: true,
        });
        dispatch(
            getFieldDataSource({
                dataSource: "concelho",
                params: {
                    guest: true,
                    distrito: selectedDistrict,
                },
            })
        ).then((data) => {
            setMunicipalities(data.result);
            setLoadData({
                ...loadData,
                concelho: false,
            });
        });
    }, [selectedDistrict]);

    useEffect(() => {
        if (!selectedMunicipality) return;

        setLoadData({
            ...loadData,
            freguesia: true,
        });
        dispatch(
            getFieldDataSource({
                dataSource: "freguesia",
                params: {
                    guest: true,
                    concelho: selectedMunicipality,
                },
            })
        ).then((data) => {
            setParishes(data.result);
            setLoadData({
                ...loadData,
                freguesia: false,
            });
        });
    }, [selectedMunicipality]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        props.form.validateFields(async (err, values) => {
            if (!err) {
                Session.remove();
                Session.unlockScreen();

                setLoading(true);

                const formData = new FormData();
                formData.append("accao", "unicv-register");
                formData.append("utilizador", values.username);
                formData.append("senha", values.password);
                formData.append("pais", values.pais);
                formData.append("distrito", values.distrito);
                formData.append("concelho", values.concelho);
                formData.append("freguesia", values.freguesia);
                formData.append("os", userInfo.plataform);
                formData.append("browser", userInfo.browser);
                formData.append("computer", userInfo.computer_uuid);
                try {
                    const resp = await api("/Gestor/gereutilizador.php", formData);
                    if (resp.erro) {
                        setError(resp.erro);
                    } else {
                        Session.save({
                            username: resp.username,
                            name: resp.nome,
                            token: resp.token,
                            type: resp.tipo,
                        });

                        window.location.hash = "/";

                        window.location.reload();
                    }
                } catch (error) {
                    console.error(error);
                    setError(error.erro);
                }
                setLoading(false);
            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <Layout>
            <div className="register-body">
                <Title>Residência Estudantil Registo Aluno</Title>

                <div style={{ maxWidth: "500px", marginTop: "20px" }}>
                    {error && <Alert message={error} type="error" banner />}
                </div>

                <Form onSubmit={handleSubmit} className="register-form">
                    <Row gutter={18}>
                        <Col xs={24} md={12}>
                            <Form.Item>
                                {getFieldDecorator("username", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Informe o código estudante!",
                                        },
                                    ],
                                })(
                                    <Input
                                        disabled={loading}
                                        prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                                        placeholder="Código estudante"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item>
                                {getFieldDecorator("password", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Informe a senha!",
                                        },
                                    ],
                                })(
                                    <Input
                                        disabled={loading}
                                        prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                                        type="password"
                                        placeholder="Senha"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item>
                                {getFieldDecorator("pais", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Informe o país!",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        loading={loadData.pais}
                                        placeholder={"País"}
                                        onChange={(value) => {
                                            setSelectedCountry(value);
                                        }}
                                        filterOption={(input, option) =>
                                            option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                        }
                                    >
                                        {countries.map((item) => (
                                            <Select.Option key={item.idpais} value={item.idpais}>
                                                {item.designacao}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item>
                                {getFieldDecorator("distrito", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Informe o distrito/ilha!",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        loading={loadData.distrito}
                                        placeholder={"Distrito"}
                                        onChange={(value) => {
                                            setSelectedDistrict(value);
                                        }}
                                        filterOption={(input, option) =>
                                            option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                        }
                                    >
                                        {districts.map((item) => (
                                            <Select.Option key={item.id_dist_ilhas} value={item.id_dist_ilhas}>
                                                {item.descricao}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item>
                                {getFieldDecorator("concelho", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Informe o Concelho!",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        loading={loadData.concelho}
                                        placeholder={"Concelho"}
                                        onChange={(value) => {
                                            setSelectedMunicipality(value);
                                        }}
                                        filterOption={(input, option) =>
                                            option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                        }
                                    >
                                        {municipalities.map((item) => (
                                            <Select.Option key={item.id_distconc} value={item.id_distconc}>
                                                {item.descricao}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item>
                                {getFieldDecorator("freguesia", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Informe o freguesia!",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        loading={loadData.freguesia}
                                        placeholder={"Freguesia"}
                                        onChange={(value) => {
                                            setSelectedParish(value);
                                        }}
                                        filterOption={(input, option) =>
                                            option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                        }
                                    >
                                        {parishes.map((item) => (
                                            <Select.Option key={item.id_freguesia} value={item.id_freguesia}>
                                                {item.descricao}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Link className="register-form-forgot" to="/login">
                            Tenho uma conta. Login!
                        </Link>
                        <Button loading={loading} type="primary" htmlType="submit" className="register-form-button">
                            Criar Conta
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    );
};

export default Form.create({ name: "normal_register" })(RegisterUniCV);
