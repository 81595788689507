import React, { Component } from "react";
import { Form, Row, Col, Select, Input } from "antd";

const { Option } = Select;

function onChange(value) {}

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

class FormTabPane extends Component {
    render() {
        return (
            <div>
                <Form layout="vertical">
                    <Row gutter={18}>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Código">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Revisão">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    onChange={this.onChange}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="Em construção">Em construção</Option>
                                    <Option value="Em revisão">Em revisão</Option>
                                    <Option value="Verificado">Verificado</Option>
                                    <Option value="Aprovado">Aprovado</Option>
                                    <Option value="Anulado">Anulado</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Modo">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    onChange={this.onChange}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="Modo Normal">Modo Normal</Option>
                                    <Option value="Modo Avançado">Modo Avançado</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Título formulário">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Nome principal">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item label="Tipo formulário">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    onChange={this.onChange}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="Modo Normal">Processo</Option>
                                    <Option value="Popup">Popup</Option>
                                    <Option value="Menu">Menu</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default FormTabPane;
