import { Tabs } from "antd";
import React from "react";
import ExecutionForm from "../execution/execution.form";
import { getChild, conversion } from "../../constants/utils";
import { restriction } from "../../utils/input_attributes";
import { Utilitaries } from "../../../../business";

const { TabPane } = Tabs;

const QaSeparator = ({
    itemData,
    state,
    isFormReady,
    formName,
    dispatch,
    toggleIsFormReady,
    currentTaskStatus,
    ...restProps
}) => {
    const formItems = state.form ? Object.values(state.form) : [];

    return (
        <Tabs defaultActiveKey={"0"}>
            {getChild({
                parent: itemData,
                components: formItems,
            }).map((separatorItem, index) => {
                // ;

                const childs = getChild({
                    parent: separatorItem,
                    components: formItems,
                });

                const xpath = childs[0]?.["@tab_relevante"];
                let condition = true;

                if (xpath) {
                    condition = restriction({
                        xpath: xpath,
                        state,
                        item: childs[0],
                    });
                }

                //
                // const test = getChild({
                //     parent: separatorItem,
                //     components: formItems,
                // });

                return condition ? (
                    <TabPane tab={separatorItem.name} key={index} forceRender={!currentTaskStatus}>
                        <ExecutionForm
                            formItemsRender={childs}
                            formName={formName}
                            isRoot={false}
                            toggleIsFormReady={toggleIsFormReady}
                            isFormReady={isFormReady}
                            {...restProps}
                            isTab={true}
                            tabName={separatorItem.name}
                        />
                    </TabPane>
                ) : null;
            })}
        </Tabs>
    );
};

export default QaSeparator;
