import React, { useEffect, useContext } from "react";
import { sendHttpRequest } from "../../http.request";
import FormContext from "../context/FormContext";
import { input_validations, regex_match } from "../utils/validations";

export default function QaInputActions({ inputAttribute, field, ...inputProps }) {
    const { formData, changeInputValue } = useContext(FormContext);

    const action = Object.keys(inputAttribute).filter((it) => it.startsWith("@accao_"));

    useEffect(
        () => {
            const auto_preenche = async (query, ...params) => {
                //
                const fd = new FormData();
                fd.append("query", query);
                fd.append("accao", "query");

                let nullValues = false;

                params
                    .map((it) => it.replace(/[{}\s]/g, "").split(","))
                    .forEach((it) => {
                        fd.append(it[0], eval(it[1]));

                        if (eval(it[1]) === undefined) {
                            nullValues = true;
                        }
                    });

                if (nullValues) {
                    return;
                }

                try {
                    const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", fd);
                    //

                    const _value = data.result[0];

                    // changeInputValue({
                    //   value: _value, // valor selecionado  in value
                    //   key: field, // Nome do campo
                    //   inputAttribute, // input atributos
                    //   ...inputProps, // propriedades da tabela
                    // });
                } catch (error) {
                    //
                }
            };

            const getValue = () => {
                let expression = input_validations({
                    item: inputAttribute,
                    relKey: action[0],
                    formData: inputProps.inTable ? inputProps.items.data : formData,
                    functions: {},
                    returnExpression: true,
                });

                expression = expression.replace(/{[\w[\],\s']+}|\w+(?=,)/g, '"$&"');

                eval(expression);
            };

            if (action.length > 0) {
                getValue();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        regex_match(inputAttribute[action[0]])
            .filter((it) => !parseInt(it))
            .map((it) => eval(`formData['${it}']`))
    );

    return <p>Ola</p>;
}
