import React, { useState } from "react";
import { Radio, Select } from "antd";
import { sendHttpRequest } from "../http.request";

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const ScheduleConfiguration = (props) => {
    const [selectConfiguration, setSelectConfiguration] = useState("Própria");
    const [selectConfigurationOwner, setSelectConfigurationOwner] = useState("Ambos");

    const [users, setUsers] = useState([]);

    const onChange = (e) => {
        const selected = e.target.value;
        setSelectConfiguration(selected);
    };

    const onChangeOwner = (e) => {
        const selected = e.target.value;
        setSelectConfigurationOwner(selected);
        props.onChange(selected, null, selectConfiguration);
    };

    const handleChangeGroups = (value, option) => {
        // const id = option.props.data_id;
        // get users

        const formData = new FormData();

        formData.append("accao", "utilizadores_disponiveis_agenda");
        formData.append("grupo", value);

        sendHttpRequest("POST", "/Gestor/gereagenda.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
            } else {
                setUsers(Array.isArray(jsonResponse["linha"]) ? jsonResponse["linha"] : [jsonResponse["linha"]]);
            }
        });
    };

    const handleChangeUsers = (value) => {
        props.onChange(null, value, selectConfiguration);
    };

    return (
        <>
            <Radio.Group onChange={onChange} value={selectConfiguration}>
                <Radio value="Própria">Própria</Radio>
                <Radio value="Outros">Outros</Radio>
            </Radio.Group>

            <br />

            {(() => {
                switch (selectConfiguration) {
                    case "Outros":
                        return (
                            <>
                                <Select
                                    showSearch
                                    style={{ width: "100%", marginTop: 8 }}
                                    placeholder="Grupos"
                                    onChange={handleChangeGroups}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {props.eventsPermissionGroups.list.map((item) => (
                                        <Option key={item.grupo} value={item.grupo}>
                                            {item.nome_grupo}
                                        </Option>
                                    ))}
                                </Select>

                                <Select
                                    showSearch
                                    style={{ width: "100%", marginTop: 4 }}
                                    placeholder="Utilizadores"
                                    onChange={handleChangeUsers}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {users.map((item) => (
                                        <Option key={item.username} value={item.username}>
                                            {item.nome}
                                        </Option>
                                    ))}
                                </Select>
                            </>
                        );

                    default:
                        return (
                            <Radio.Group
                                style={{ marginTop: 8 }}
                                onChange={onChangeOwner}
                                value={selectConfigurationOwner}
                            >
                                <Radio value="Pessoal">Pessoal</Radio>
                                <Radio value="Profissional">Profissional</Radio>
                                <Radio value="Ambos">Ambos</Radio>
                            </Radio.Group>
                        );
                }
            })()}
        </>
    );
};

export default ScheduleConfiguration;
