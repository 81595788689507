import React from "react";
import { Button, Icon, message } from "antd";
import { FormLabel } from "../Components/FormLabel";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { Group } from "../Components/Group";
import { CommunComponentsProps, GenericObject } from "../../../types/common";
import { regexValidate } from "../handle/regexConversion";
import { Session, Utilitaries } from "../../../../business";
import { baseUrl, BASE_URL } from "../../../../constants/enviroment";
import { useInputHandle } from "../hooks/InputHandle";
import { getFullPathRow } from "../handle/inputHandle";

interface ComponentsPropertiesProps extends CommunComponentsProps {}

const ButtonGroup = Button.Group;

export const QAReport: React.FC<ComponentsPropertiesProps> = ({
    componentsProperties,
    renderContext,
    formState,
    tableRow,
    inputCalcTrigger,
}) => {
    print_when_component_is_rendered(componentsProperties);

    const downloadReport = (type: string) => {
        if (Utilitaries.isEmpty(componentsProperties.report)) {
            message.warning("Relatório inválido");
            return;
        }

        let url = `${baseUrl}/Gestor/gererelatorios.php?accao=relatorio_nome`;

        let params: GenericObject = {
            saida: type,
            nome: componentsProperties.report,
        };
        if (componentsProperties.popup_param) {
            const expression = regexValidate({
                expression: componentsProperties.popup_param,
                formState,
                componentProperties: componentsProperties,
                rowTable: tableRow,
            });

            if (expression) {
                const paramsList = expression.split(";") || [];

                paramsList.forEach((param) => {
                    let [key, value] = param.split(/,(.*)/s);
                    key = key.replace(/\W/g, "").trim();

                    if (key) {
                        value = value?.replace("]", "").trim();
                        debugger;
                        if (value?.startsWith("'") && value?.endsWith("'")) {
                            // value = value.substring(1, value.length - 1);
                            try {
                                // eslint-disable-next-line no-eval
                                value = eval(value);
                            } catch (error) {
                                value = "";
                            }
                        }

                        params[key] = value;
                    }
                });
            }
        }

        const urlParams = Object.entries(params).reduce((accumulator, current) => {
            accumulator = `${accumulator}&${current[0]}=${current[1]}`;
            return accumulator;
        }, "");

        url += urlParams + `&token=Bearer ${Session.getToken()}`;
        window.open(url, "_blank");
    };

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);

    const { visible, relevant } = useInputHandle({
        componentProperties: componentsProperties,
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
    });

    if (!visible || !relevant) {
        return null;
    }
    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={false} renderContext={renderContext}>
                <ButtonGroup>
                    <Button type="default" onClick={() => downloadReport("pdf")}>
                        <Icon title="Relatório PDF" style={{ fontSize: 20, color: "red" }} type="file-pdf" />
                    </Button>
                    <Button type="default" onClick={() => downloadReport("doc")}>
                        <Icon title="Relatório Word" style={{ color: "#2962FF", fontSize: 20 }} type="file-word" />
                    </Button>
                    <Button type="default" onClick={() => downloadReport("xls")}>
                        <Icon title="Relatório Excel" style={{ color: "green", fontSize: 20 }} type="file-excel" />
                    </Button>
                </ButtonGroup>
            </FormLabel>
        </Group>
    );
};
