import React, { Component } from "react";
import { Button, Row, Popover, Checkbox, Col, Tooltip } from "antd";
import { downloadFile } from "../../../components/util";

class ButtonsGroup extends Component {
    insert = (control) => {
        if (control === "GF") {
            this.props.goto("/function.manual/group/create");
            this.props.activeTabPaneKey("1");
        }
        if (control === "FUNC") {
            // this.props.goto("/function.manual/colaborators/create");
            this.props.goto("/function_manual/colaborators/");
            this.props.activeTabPaneKey("2");
        }
        if (control === "CC") {
            this.props.goto("/function.manual/collective.agreement/create");
            this.props.activeTabPaneKey("3");
        }
    };

    exportList = async (type) => {
        let params = {
            saida: type,
            downloadInBrowser: true,
            ...this.props.filter,
        };

        let endPoint = null;

        if (this.props.tabControl) {
            endPoint = this.props.REPORT_PARAMS[this.props.tabControl].endPoint;
            delete this.props.REPORT_PARAMS[this.props.tabControl].endPoint;
            params = {
                ...params,
                ...this.props.REPORT_PARAMS[this.props.tabControl],
            };
        } else {
            endPoint = this.props.REPORT_PARAMS.endPoint || "geremanualfuncoes.php";
            delete this.props.REPORT_PARAMS.endPoint;
            params = {
                ...params,
                ...this.props.REPORT_PARAMS,
            };
        }

        console.log(this.props.filter);

        await downloadFile(endPoint, params);
    };

    render() {
        return (
            <div>
                <Row>
                    <Button
                        onClick={() => this.insert(this.props.tabControl)}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{
                            marginBottom: 16,
                            marginLeft: 8,
                            float: "right",
                        }}
                    >
                        Inserir
                    </Button>
                    <Tooltip title="Exportar lista para pdf">
                        <Button
                            onClick={() => this.exportList("pdf")}
                            // loading={this.props.pdf_loading}
                            icon="file-pdf"
                            size="default"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "right",
                                color: "#fa541c",
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Exportar lista para excel">
                        <Button
                            onClick={() => this.exportList("xls")}
                            // loading={this.props.excel_loading}
                            icon="file-excel"
                            size="default"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "right",
                                color: "#237804",
                            }}
                        />
                    </Tooltip>
                    {/* {this.props.filterValues && Object.keys(this.props.filterValues).length > 0 ? (
                        <Button
                            onClick={this.props.clearFormFilters}
                            type="link"
                            size="default"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "right",
                            }}
                        >
                            Limpar filtros
                        </Button>
                    ) : null} */}

                    {this.props.tabControl !== "CC" && (
                        <Checkbox checked={this.props.showInatives} onChange={this.props.setShowInatives}>
                            Mostrar todos
                        </Checkbox>
                    )}
                </Row>
            </div>
        );
    }
}

export default ButtonsGroup;
