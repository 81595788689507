import { Button, Col, Form, Input, message, Popconfirm, Row, Table, Upload } from "antd";
import React from "react";
import { Utilitaries } from "../../../../../business";
import { QaDynamicSelect } from "../../../../../components/DynamicList";
import { DataChangeProps, FormDataProps } from "../utils/types";
import moment from "moment";
import ButtonDownloadDocument from "../../../../../modules/utilities/component/button.download.document";
import { postDataToGereFicheiros } from "../../../../../modules/function_manual/actions/contributor";
import { api_fetch } from "../../../../utils/HttpRequest/request";

interface IProps extends DataChangeProps {
    formData: FormDataProps;
}

const table_key1 = "docs_colaboradores";
const table_key2 = "doc_colaborador";

export function Documents({ formData, handleAdd, handleDelete, onChange }: IProps) {
    function handleOnChange(dataIndex: string, value: any, row_key: any) {
        onChange({
            dataIndex,
            value,
            row_key,
            table_key1,
            table_key2,
        });
    }

    async function uploadFileToServer(file: any, record: any) {
        try {
            const response = await api_fetch({
                endPoint: "/Gestor/gereficheiros.php",
                method: "POST",
                params: {
                    accao: "upload",
                    nome: file.name,
                    descricao: file.type,
                    ficheiro: file,
                    chave_sessao: "teste",
                },
            });
            if (response.status === "SUCCESS") {
                handleOnChange("planta_atual", response.response.sucesso, record.key);
            } else {
                message.error("Erro ao gravar ficheiro!!");
            }
        } catch (error) {
            message.error("Ocorreu um erro");
        }
    }

    const columns: any[] = [
        {
            title: "Tipo de documento",
            dataIndex: "tipo_documento",
            width: "30%",
            render: (text: string, record: any) => {
                text = Utilitaries.toString(text);
                return (
                    <Col>
                        <QaDynamicSelect
                            onChange={(value: any) => handleOnChange("tipo_documento", value, record.key)}
                            defaultValue={text}
                            name="tipo_documento"
                        />
                    </Col>
                );
            },
        },
        {
            title: "Número",
            dataIndex: "numero",
            width: "15%",
            render: (text: string, record: any) => {
                text = Utilitaries.toString(text);
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            placeholder="Introduza o número"
                            onChange={(e) => handleOnChange("numero", e.target.value, record.key)}
                            defaultValue={text}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data de emissão",
            dataIndex: "data_emissao",
            width: "15%",
            render: (text: any, record: any) => {
                // text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(date) =>
                                handleOnChange(
                                    "data_emissao",
                                    date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    record.key
                                )
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data validade",
            dataIndex: "data_validade",
            width: "15%",
            render: (text: any, record: any) => {
                text = Utilitaries.toString(text) && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(date) =>
                                handleOnChange(
                                    "data_validade",
                                    date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    record.key
                                )
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Documento",
            dataIndex: "planta_atual",
            align: "center",
            width: "15%",
            render: (text: any, record: any) => {
                if (record["planta_atual"] && Object.entries(record["planta_atual"]).length !== 0) {
                    return <ButtonDownloadDocument ficheiro={record["planta_atual"]} text="Descarregar" />;
                } else {
                    return (
                        <Upload
                            openFileDialogOnClick
                            defaultFileList={typeof text !== "object" && text ? [text] : []}
                            beforeUpload={(file) => {
                                uploadFileToServer(file, record);
                                return false;
                            }}
                            name="file"
                            multiple={false}
                            onRemove={() => handleOnChange("planta_atual", "", record.key)}
                        >
                            <Button type="primary" icon="file-add" />
                        </Upload>
                    );
                }
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text: any, record: any) =>
                formData.docs_colaboradores.doc_colaborador.length >= 1 ? (
                    <Popconfirm
                        title="Eliminar a linha?"
                        onConfirm={() => handleDelete(record.key, table_key1, table_key2)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];
    return (
        <>
            <Row>
                <Button
                    onClick={() => handleAdd(table_key1, table_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                size="small"
                scroll={{ x: 500 }}
                columns={columns}
                dataSource={formData.docs_colaboradores.doc_colaborador}
            />
        </>
    );
}
