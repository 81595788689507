import UserIndex from "../modules/user/components/index";
import UserCreate from "../modules/user/components/create";
import ProcessIndex from "../modules/process_definition/components/index";
import ProcessDetails from "../modules/process_definition/components/details/index";
import ProcessComponentDetails from "../modules/process_definition/components/component/details";
import ProcessAdvancedCreate from "../modules/process_definition/components/advanced/create";
import ProcessEditCreate from "../modules/process_definition/components/advanced/edit";
import ProcessInteraction from "../modules/process_interaction/components/index";
import TaskPanel from "../modules/process_execution/components/panel/index";
import FunctionalManualIndex from "../modules/function_manual/components/index";
import FunctionalGroupCreate from "../modules/function_manual/components/functional_group/create";
import Home from "../components/home";
import NewCollectiveAgreement from "../modules/function_manual/components/collective_agreement/create";
import EditCollectiveAgreement from "../modules/function_manual/components/collective_agreement/edit";
import NewContributor from "../modules/function_manual/components/contributors/new.contributor";
import Knowledge from "../modules/function_manual/components/knowledge/index";
import Competence from "../modules/function_manual/components/competence/index";
import BehavioralParameter from "../modules/function_manual/components/behavioral.parameter/index";
import Requirement from "../modules/function_manual/components/requirement/index";
import Hability from "../modules/function_manual/components/hability/index";
import Holiday from "../modules/function_manual/components/holiday/index";
import HolidayDetails from "../modules/function_manual/components/holiday/details";
import TimeRegistration from "../modules/function_manual/components/time_registration/index";
import ContributorTimeRegistration from "../modules/function_manual/components/time_registration/contributor.time.registration";
import StartProcess from "../modules/process_execution/components/index";
import TaskExecutionMap from "../modules/process_execution/components/execution/map";
import DocumentsList from "../modules/documents/components/index";
import NewDocument from "../modules/documents/components/register/index";
import DynamicList from "../modules/dynamic_list/components/index";
import QaStandarts from "../modules/standards/components/qa/index";
import OrganizationalStandarts from "../modules/standards/components/organizational/index";
import Form from "./Form";
import List from "./List";
import MenuSettings from "../modules/Menu/index";
import ReportBuilderIndex from "../modules/reports/components/index";
import DashboardIndex from "../modules/dashboard/components/index";
import AuthorizationsIndex from "../modules/authorizations/components/index";
import DocumentRecordConsult from "../modules/document_record_consult/component/index.js";
import ClientDossier from "../modules/client_dossier/component/index";
import ReportConfig from "../modules/report_config/index";
import ModelsDocuments from "../modules/models_documents/components/index";
import ModelsDocumentsEdit from "../modules/models_documents/components/edit/index";
import ModelsReports from "../modules/models_reports/components/index";
import ModelsReportsEdit from "../modules/models_reports/components/edit/index";
import Hierarquie from "../modules/function_manual/components/hierarquia";
import PortalProcess from "./../modules/portal/process";
import SpecialDates from "./../modules/specials_dates/index";
import Tests from "../tests";
import UnicvPayment from "../modules/unicv_payment";
import { MenuFormRender } from "../V2/shared/FormHandler";
import { ListagemTimeRegistration } from "../modules/function_manual/components/time_registration/listagem_time_registration";
import MeetingRoomRequest from "../V2/pages/MeetingRoomRequest/index";
import RentalReservation from "../V2/pages/RentalReservation/index";
import ContributorForm from "../V2/pages/FunctionManual/ContributorForm";

export const qaRoutesFromDB = {
    OpenHabilidades: {
        path: "/function.manual/component/hability",
        component: Hability,
    },
    UnicvPayment: {
        path: "/unicv_payments",
        component: UnicvPayment,
    },
    OpenInteracaoProcessos: {
        path: "/process_interaction",
        component: ProcessInteraction,
    },
    OpenHierarquias: {
        path: "/hierarquia",
        component: Hierarquie,
    },
    OpenConfiguracaoRelatorio: {
        path: "/configuracao/relatorio",
        component: ReportConfig,
    },
    OpenUtilizadores: { path: "/user/index", component: UserIndex },
    OpenProcessos: {
        path: "/process/definition/index/:id?",
        component: ProcessIndex,
    },
    OpenMenus: {
        path: "/config/menu",
        component: MenuSettings,
    },
    OpenAutorizacoes: {
        path: "/authorization",
        component: AuthorizationsIndex,
    },
    OpenPainel: { path: "/task/panel", component: TaskPanel },
    OpenManualFuncoes: {
        path: "/function.manual/index/:activeKey?",
        component: FunctionalManualIndex,
    },
    conhecimentos: {
        exat: true,
        path: "/function.manual/component/knowledge",
        component: Knowledge,
    },
    tipos_conhecimentos: {
        exat: true,
        path: "/function.manual/component/knowledge/type",
        component: Knowledge,
    },
    competencias: {
        exat: true,
        path: "/function.manual/component/competence",
        component: Competence,
    },
    tipos_competencias: {
        exat: true,
        path: "/function.manual/component/competence/type",
        component: Competence,
    },
    requisitos: {
        path: "/function.manual/component/requirement",
        component: Requirement,
    },
    tipos_requisitos: {
        exat: true,
        path: "/function.manual/component/requirement/type",
        component: Requirement,
    },
    tipo_param_comportam: {
        path: "/function.manual/component/behavioral.parameter",
        component: BehavioralParameter,
    },
    config_holidays_year_manual_funcoes: {
        path: "/function_manual/holiday",
        component: Holiday,
    },
    qa_registo_ponto: {
        path: "/function_manual/time_registration",
        component: TimeRegistration,
    },
    qa_registo_ponto_lista_colab: {
        path: "/function_manual/contributor_Time_registration",
        // component: ContributorTimeRegistration,
        component: ListagemTimeRegistration,
    },
    dossier_clientes: { path: "/client_dossier", component: ClientDossier },
    OpenDocumentos: { path: "/document/list", component: DocumentsList },
    OpenListasDinamicas: { path: "/dynamiclist", component: DynamicList },
    util_inserir_normas_list: {
        path: "/standarts/qa/index",
        component: QaStandarts,
    },
    normas_instituicao: {
        path: "/standarts/organizational/index",
        component: OrganizationalStandarts,
    },
    OpenModeloDocumentos: {
        exact: true,
        path: "/models/documents",
        component: ModelsDocuments,
    },
    OpenModeloRelatorios: {
        exact: true,
        path: "/models/reports",
        component: ModelsReports,
    },
    ESPAÇOS: { path: "/ESPAÇO", component: MeetingRoomRequest },
};

export const qaRoutesLocal = [
    { path: "/dev-test", component: Tests },
    { path: "/user/create", component: UserCreate },
    { path: "/f/:name", component: Form },
    { path: "/f2/:name/:id?", component: MenuFormRender },
    { path: "/l/:name", component: List },
    { path: "/", exact: true, component: Home },
    { path: "/report/index", component: ReportBuilderIndex },
    {
        path: "/function.manual/group/create/:id?",
        component: FunctionalGroupCreate,
    },
    { path: "/process/details/:id", component: ProcessDetails },
    {
        path: "/function.manual/collective.agreement/create",
        component: NewCollectiveAgreement,
    },
    {
        path: "/function.manual/collective.agreement/edit/:id",
        component: EditCollectiveAgreement,
    },
    {
        path: "/function.manual/colaborators/create/:id?",
        component: NewContributor,
    },
    {
        path: "/function_manual/colaborators/:id?",
        component: ContributorForm,
    },
    {
        path: "/task/execution/map/:id",
        component: TaskExecutionMap,
    },
    { path: "/process/execution/index", component: StartProcess },
    {
        path: "/function.manual/holiday/details/:id",
        component: HolidayDetails,
    },
    {
        path: "/dashboard",
        component: DashboardIndex,
    },
    { path: "/document/register/:id?", component: NewDocument },
    { path: "/document_record_consult", component: DocumentRecordConsult },
    { path: "/client_dossier", component: ClientDossier },
    { path: "/models/documents/edit/:id?", component: ModelsDocumentsEdit },
    { path: "/models/reports/edit/:id?", component: ModelsReportsEdit },
    { path: "/portal/process/:processId/:taskId", component: PortalProcess },
    { path: "/ALUGUER", component: RentalReservation },
];
