import React, { Component } from "react";
import { Tabs, PageHeader } from "antd";
import Requirement from "./requirement";
import RequirementType from "./requirement.type";
const { TabPane } = Tabs;

class RequirementIndex extends Component {
    goto = (path) => {
        this.props.history.push({
            pathname: path,
        });
    };

    render() {
        return (
            <>
                <PageHeader title="Lista Requisitos" />
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Tipos de requisitos" key="1">
                        <RequirementType />
                    </TabPane>
                    <TabPane tab="Requisitos" key="2">
                        <Requirement />
                    </TabPane>
                </Tabs>
            </>
        );
    }
}
export default RequirementIndex;
