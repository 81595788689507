import React, { useEffect, useState } from "react";
import { Row, Form, Col, Select, Divider, Input, Radio, Checkbox, Tooltip, Button, Icon } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import moment from "moment";
import { Utilitaries } from "../../../../../business";
import { FormDataProps } from "../utils/types";
import { api_fetch } from "../../../../utils/HttpRequest/request";
import { QaDynamicSelect } from "../../../../../components/DynamicList";

const CheckboxGroup = Checkbox.Group;

interface IProps {
    form: WrappedFormUtils;
    formData: FormDataProps;
    contributorTypes: any[];
    establishments: any[];
    typesContract: any[];
    countries: any[];
    banks: any[];
    contributorTypeDescription: string;
    setContributorTypeDescription: (contributorTypeDescription: string) => void;
    accessValidation: string;
    showModalAccessValidation: () => void;
}

export function PersonalInfo({
    form,
    formData,
    contributorTypes,
    setContributorTypeDescription,
    establishments,
    typesContract,
    countries,
    banks,
    contributorTypeDescription,
    accessValidation,
    showModalAccessValidation,
}: IProps) {
    const [districts, setDistricts] = useState<any[]>([]);
    const [counties, setCounties] = useState<any[]>([]);
    const [parish, setParih] = useState<any[]>([]);

    const [nibValidation, setNibValidation] = useState({
        validateStatus: "",
        errorMsg: "",
    });
    const [ibanValidation, setIbanValidation] = useState({
        validateStatus: "",
        errorMsg: "",
    });

    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

    useEffect(() => {
        if (formData.entidade.moradas?.morada?.distrito || getFieldValue("pais")) {
            api_fetch({
                endPoint: "/Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: "distrito",
                    pais: getFieldValue("pais") || formData.entidade.moradas.morada?.pais,
                    _valor_campo_: formData.entidade.moradas.morada?.distrito,
                },
            }).then((response) => {
                if (response.status === "SUCCESS") {
                    const { result } = response.response;
                    setDistricts(Utilitaries.toArray(result));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue("pais"), formData.entidade?.moradas?.morada?.distrito]);

    useEffect(() => {
        if (formData.entidade.moradas.morada?.concelho || getFieldValue("distrito")) {
            api_fetch({
                endPoint: "/Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: "concelho",
                    distrito: getFieldValue("distrito") || formData.entidade.moradas.morada?.distrito,
                    _valor_campo_: formData.entidade.moradas.morada?.concelho,
                },
            }).then((response) => {
                if (response.status === "SUCCESS") {
                    const { result } = response.response;
                    setCounties(Utilitaries.toArray(result));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue("distrito"), formData.entidade?.moradas?.morada?.concelho]);

    useEffect(() => {
        if (formData.entidade.moradas.morada?.freguesia || getFieldValue("concelho")) {
            api_fetch({
                endPoint: "/Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: "freguesia",
                    concelho: getFieldValue("concelho") || formData.entidade.moradas.morada?.concelho,
                    _valor_campo_: formData.entidade.moradas.morada?.freguesia,
                },
            }).then((response) => {
                if (response.status === "SUCCESS") {
                    const { result } = response.response;
                    setParih(Utilitaries.toArray(result));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue("concelho"), formData.entidade.moradas.morada?.freguesia]);

    const nibFeedback = "O NIB deve ter 21 digitos!";
    const handleNibChange = (value: string) => {
        const isNumber = /^\d+$/.test(value);

        if (value?.toString()?.split("")?.length !== 21 || !isNumber) {
            setNibValidation({
                validateStatus: "error",
                errorMsg: "NIB deve ter 21 dígitos numéricos",
            });
            return false;
        }

        setNibValidation({
            validateStatus: "success",
            errorMsg: "Correto",
        });

        return true;
    };

    const ibanFeedback = "O IBAN deve ter 25 carateres!";
    const handleIbanChange = (value: string) => {
        const firstTwoCharater = value.slice(0, 2);
        const rest = value.substring(2);
        if (!/^\d+$/.test(rest?.replace(/\s/g, "")) || !/^[a-zA-Z]+$/.test(firstTwoCharater)) {
            setIbanValidation({
                validateStatus: "error",
                errorMsg: "Os dois primeiro digitos do IBAN deve ser letras e os restantes números",
            });
            return false;
        }

        if (value?.toString()?.replace(/\s/g, "")?.split("")?.length !== 25) {
            setIbanValidation({
                validateStatus: "error",
                errorMsg: "IBAN deve ter 25 carateres",
            });
            return false;
        }

        setIbanValidation({
            validateStatus: "success",
            errorMsg: "Correto",
        });

        return true;
    };

    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col xs={24} sm={12}>
                    <Form.Item label="Categoria de utilizador">
                        {getFieldDecorator("tipo_utilizador", {
                            initialValue: Utilitaries.toString(formData?.tipo_utilizador),
                        })(
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                placeholder="Seleciona uma opção"
                                // onChange={this.onChangeUserCategory}
                                filterOption={(input, option) =>
                                    String(option?.props?.children || "")
                                        ?.toLowerCase()
                                        ?.indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="2">Utilizador interno</Select.Option>
                                <Select.Option value="1">Utilizador externo</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item label="Tipo de utilizador">
                        {getFieldDecorator("tipo_colaborador", {
                            initialValue: Utilitaries.toString(formData?.tipo_colaborador),
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                placeholder="Seleciona uma opção"
                                onChange={(value, option) => {
                                    const contributorType = contributorTypes.find(
                                        (contributorType) => contributorType.id_tipo_colaborador === value
                                    );
                                    setContributorTypeDescription(contributorType?.designacao);
                                }}
                                filterOption={(input: any, option: any) =>
                                    String(option?.props?.children || "")
                                        ?.toLowerCase()
                                        ?.indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {contributorTypes.map((item) => {
                                    if (getFieldValue("tipo_utilizador") === "2" && item.categoria_utilizador !== "1") {
                                        return (
                                            <Select.Option
                                                key={item.id_tipo_colaborador}
                                                value={item.id_tipo_colaborador}
                                            >
                                                {item.designacao}
                                            </Select.Option>
                                        );
                                    }

                                    if (getFieldValue("tipo_utilizador") === "1" && item.categoria_utilizador !== "2") {
                                        return (
                                            <Select.Option
                                                key={item.id_tipo_colaborador}
                                                value={item.id_tipo_colaborador}
                                            >
                                                {item.designacao}
                                            </Select.Option>
                                        );
                                    }

                                    return null;
                                })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation="left">Dados de acesso</Divider>
            <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Utilizador">
                        {getFieldDecorator("username", {
                            initialValue: Utilitaries.toString(formData?.username),
                            rules: [
                                {
                                    required: true,
                                    message: "Campo Utilizador é obrigatório",
                                },
                            ],
                        })(
                            <Input
                                disabled={formData.id_ficha_colaborador ? true : false}
                                placeholder="Introduza utilizador"
                            />
                        )}
                    </Form.Item>
                </Col>
                {formData.id_ficha_colaborador && (
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Mudar Senha?">
                            {getFieldDecorator("change_password", {
                                initialValue: false,
                            })(
                                <Radio.Group
                                    buttonStyle="solid"
                                    // defaultValue={changePassword}
                                    // onChange={(e) => setChangePassword(e.target.value)}
                                >
                                    <Radio.Button value={true}>Sim</Radio.Button>
                                    <Radio.Button value={false}>Não</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Col>
                )}

                {((formData.id_ficha_colaborador && getFieldValue("change_password")) ||
                    !formData.id_ficha_colaborador) && (
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Senha">
                            {getFieldDecorator("senha", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo Senha é obrigatório",
                                    },
                                ],
                            })(<Input.Password placeholder="Introduza senha" visibilityToggle />)}
                        </Form.Item>
                    </Col>
                )}

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Género">
                        {getFieldDecorator("sexo", {
                            initialValue: Utilitaries.toString(formData?.sexo),
                        })(
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value="Masculino">Masculino</Radio.Button>
                                <Radio.Button value="Feminino">Feminino</Radio.Button>
                                <Radio.Button value="Outro">Outro</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Estado">
                        {getFieldDecorator("status", {
                            initialValue: Utilitaries.toString(formData?.status),
                            rules: [
                                {
                                    required: true,
                                    message: "Campo Estado é obrigatório",
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                placeholder="Seleciona uma opção"
                                filterOption={(input, option) =>
                                    String(option?.props?.children)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {[
                                    { key: "1", value: "Ativo" },
                                    { key: "2", value: "Suspenso" },
                                    { key: "3", value: "Inativo" },
                                ].map((item, index) => {
                                    return (
                                        <Select.Option key={item.key} value={item.key}>
                                            {item.value}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                {getFieldValue("status") === "2" && (
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Motivo Suspensão">
                            {getFieldDecorator("motivo_suspensao", {
                                initialValue: Utilitaries.toString(formData?.motivo_suspensao),
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    placeholder="Seleciona uma opção"
                                    filterOption={(input, option) =>
                                        String(option.props?.children)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {["Baixa médica", "Licença sem vencimento", "Processo disciplinar", "Outros"].map(
                                        (item, index) => {
                                            return (
                                                <Select.Option key={index} value={item}>
                                                    {item}
                                                </Select.Option>
                                            );
                                        }
                                    )}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                )}

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Estabelecimento">
                        {getFieldDecorator("estab", {
                            initialValue: Utilitaries.toString(formData?.estab),
                        })(
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                placeholder="Seleciona uma opção"
                                filterOption={(input, option) =>
                                    String(String(option.props.children))?.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                            >
                                {establishments
                                    .filter(
                                        (item) =>
                                            typeof item.estabelecimento !== "object" && typeof item.nome !== "object"
                                    )
                                    .map((item) => (
                                        <Select.Option key={item.estabelecimento} value={item.estabelecimento}>
                                            {item.nome}
                                        </Select.Option>
                                    ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Tipo de Contrato">
                        {getFieldDecorator("tipo_contracto", {
                            initialValue: Utilitaries.toString(formData?.tipo_contracto),
                        })(
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                placeholder="Seleciona uma opção"
                                filterOption={(input, option) =>
                                    String(option.props.children)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {typesContract.map((item) => (
                                    <Select.Option key={item.id_tipo_contracto} value={item.id_tipo_contracto}>
                                        {item.nome}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Com exclusividade?">
                        {getFieldDecorator("exclusividade", {
                            initialValue: Utilitaries.toString(formData.exclusividade) === "t" ? true : false,
                        })(
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value={true}>Sim</Radio.Button>
                                <Radio.Button value={false}>Não</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation="left">Dados Pessoal</Divider>
            <Row gutter={16}>
                <Col xs={24} sm={16}>
                    <Form.Item label="Nome Completo">
                        {getFieldDecorator("nome_completo", {
                            initialValue: Utilitaries.toString(formData?.entidade.nome_completo),
                            rules: [
                                {
                                    required: true,
                                    message: "Campo Nome Completo é obrigatório",
                                },
                            ],
                        })(<Input placeholder="Introduza nome completo" />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={8}>
                    <Form.Item label="Nome a utilizar">
                        {getFieldDecorator("nome", {
                            initialValue: Utilitaries.toString(formData?.entidade.nome),
                            rules: [
                                {
                                    required: true,
                                    message: "Campo Nome a utilizar é obrigatório",
                                },
                            ],
                        })(<Input placeholder="Introduza nome a utilizar" />)}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Telefone">
                        {getFieldDecorator("tlf", {
                            initialValue: Utilitaries.toString(formData?.entidade.tlf),
                        })(<Input placeholder="Introduza nº telefone" />)}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Móvel">
                        {getFieldDecorator("tlm", {
                            initialValue: Utilitaries.toString(formData?.entidade.tlm),
                        })(<Input placeholder="Introduza nº telemóvel" />)}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="E-mail">
                        {getFieldDecorator("email", {
                            initialValue: Utilitaries.toString(formData?.entidade.email),
                            rules: [
                                {
                                    type: "email",
                                    message: "Este não é um e-mail válido!",
                                },
                            ],
                        })(<Input placeholder="Introduza email" />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        validateStatus={ibanValidation.validateStatus as any}
                        help={ibanValidation.errorMsg || ibanFeedback}
                        label="IBAN"
                    >
                        {getFieldDecorator("iban", {
                            initialValue: Utilitaries.toString(formData?.entidade.iban),
                        })(<Input onChange={(e) => handleIbanChange(e.target.value)} placeholder="Introduza IBAN" />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        validateStatus={nibValidation.validateStatus as any}
                        label="NIB"
                        help={nibValidation.errorMsg || nibFeedback}
                    >
                        {getFieldDecorator("nib", {
                            initialValue: Utilitaries.toString(formData?.entidade.nib),
                        })(<Input onChange={(e) => handleNibChange(e.target.value)} placeholder="Introduza NIB" />)}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Banco">
                        {getFieldDecorator("bank", {
                            initialValue: Utilitaries.toString(formData?.entidade.bank),
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Seleciona uma opção"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {banks.map((item) => (
                                    <Select.Option key={item.id_banco} value={item.id_banco}>
                                        {item.nome}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Nº Identificação Seg. Social">
                        {getFieldDecorator("niss", {
                            initialValue: Utilitaries.toString(formData?.entidade.niss),
                        })(<Input placeholder="Introduza nº Identificação Seg. Social" />)}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Nº Identificação Fiscal">
                        {getFieldDecorator("nif", {
                            initialValue: Utilitaries.toString(formData?.entidade.nif),
                        })(<Input placeholder="Introduza nº Identificação Fiscal" />)}
                    </Form.Item>
                </Col>

                {getFieldValue("tipo_utilizador") !== "1" &&
                    contributorTypeDescription !== "Sócio" &&
                    contributorTypeDescription !== "Outro" && (
                        <>
                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="Data nascimento">
                                    {getFieldDecorator("data_nascimento", {
                                        initialValue: Utilitaries.toString(formData?.entidade.data_nascimento)
                                            ? moment(formData?.entidade.data_nascimento).format("YYYY-MM-DD")
                                            : null,
                                    })(
                                        <Input
                                            // onChange={(e) => props.getDataNascimento(e.target.value ? moment(e.target.value) : "")}
                                            // format={"DD-MM-YYYY"}
                                            type="date"
                                        />
                                    )}
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="País" style={{ padding: 0, marginBottom: 0 }}>
                                    {getFieldDecorator("pais", {
                                        initialValue: Utilitaries.toString(formData?.entidade.moradas.morada?.pais),
                                    })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="Seleciona uma opção"
                                            filterOption={(input, option) =>
                                                String(option.props.children)
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {countries.map((item) => (
                                                <Select.Option key={item.idpais} value={item.idpais}>
                                                    {item.designacao}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="Distrito" style={{ padding: 0, marginBottom: 0 }}>
                                    {getFieldDecorator("distrito", {
                                        initialValue: Utilitaries.toString(formData?.entidade.moradas.morada?.distrito),
                                    })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="Seleciona uma opção"
                                            filterOption={(input, option) =>
                                                String(option.props.children)
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {districts.map((item) => (
                                                <Select.Option key={item.id_dist_ilhas} value={item.id_dist_ilhas}>
                                                    {item.descricao}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="Concelho">
                                    {getFieldDecorator("concelho", {
                                        initialValue: Utilitaries.toString(formData?.entidade.moradas.morada?.concelho),
                                    })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="Seleciona uma opção"
                                            filterOption={(input, option) =>
                                                String(option.props.children)
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {counties.map((item) => (
                                                <Select.Option key={item.id_distconc} value={item.id_distconc}>
                                                    {item.descricao}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="Freguesia">
                                    {getFieldDecorator("freguesia", {
                                        initialValue: Utilitaries.toString(
                                            formData?.entidade.moradas.morada?.freguesia
                                        ),
                                    })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: "100%" }}
                                            placeholder="Seleciona uma opção"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                String(option.props.children)
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {parish.map((item) => (
                                                <Select.Option key={item.id_freguesia} value={item.id_freguesia}>
                                                    {item.descricao}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={16}>
                                <Form.Item label="Arruamento">
                                    {getFieldDecorator("arruamento", {
                                        initialValue: Utilitaries.toString(
                                            formData?.entidade.moradas.morada?.arruamento
                                        ),
                                    })(<Input placeholder="Introduza o arruamento" />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Col xs={Utilitaries.toString(formData.entidade.moradas.morada?.c_postal3) ? 12 : 24}>
                                    <Form.Item label="Código postal">
                                        {getFieldDecorator("c_postal4", {
                                            initialValue: Utilitaries.toString(
                                                formData?.entidade.moradas.morada?.c_postal4
                                            ),
                                        })(<Input placeholder="Introduza código postal" />)}
                                    </Form.Item>
                                </Col>
                                {Utilitaries.toString(formData.entidade.moradas.morada?.c_postal3) && (
                                    <Col xs={12}>
                                        <Form.Item label="-">
                                            {getFieldDecorator("c_postal3", {
                                                initialValue: Utilitaries.toString(
                                                    formData?.entidade.moradas.morada?.c_postal3
                                                ),
                                            })(<Input placeholder="Introduza código postal" />)}
                                        </Form.Item>
                                    </Col>
                                )}
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="Porta">
                                    {getFieldDecorator("porta", {
                                        initialValue: Utilitaries.toString(formData?.entidade.moradas.morada?.porta),
                                    })(<Input placeholder="Introduza porta" />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="Andar">
                                    {getFieldDecorator("andar", {
                                        initialValue: Utilitaries.toString(formData?.entidade.moradas.morada?.andar),
                                    })(<Input placeholder="Introduza andar" />)}
                                </Form.Item>
                            </Col>
                            {getFieldValue("tipo_utilizador") === "2" && (
                                <>
                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item
                                            label="Habilitação literária"
                                            style={{ padding: 0, marginBottom: 0 }}
                                        >
                                            {getFieldDecorator("habilitacao", {
                                                initialValue: Utilitaries.toString(formData?.habilitacao),
                                            })(<QaDynamicSelect name="habilitacao" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="Data entrada serviço">
                                            {getFieldDecorator("data_admissao_servico", {
                                                initialValue: Utilitaries.toString(formData?.data_admissao_servico)
                                                    ? moment(formData?.data_admissao_servico).format("YYYY-MM-DD")
                                                    : null,
                                            })(
                                                <Input
                                                    type="date"
                                                    // onChange={(e) =>
                                                    //     props.getDataAdmissaoServico(
                                                    //         e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : ""
                                                    //     )
                                                    // }
                                                    // format={"DD-MM-YYYY"}
                                                    // value={
                                                    //     props.parentState.data_admissao_servico
                                                    //         ? moment(props.parentState.data_admissao_servico).format(
                                                    //               "YYYY-MM-DD"
                                                    //           )
                                                    //         : null
                                                    // }
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="Nº INPS">
                                            {getFieldDecorator("inps", {
                                                initialValue: Utilitaries.toString(formData?.inps),
                                            })(<Input placeholder="Introduza nº INPS" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="Número Mecanográfico">
                                            {getFieldDecorator("num_mecanografico", {
                                                initialValue: Utilitaries.toString(formData?.num_mecanografico),
                                            })(<Input placeholder="Introduza número mecanográfico" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="Escalão">
                                            {getFieldDecorator("escalao", {
                                                initialValue: Utilitaries.toString(formData?.escalao),
                                            })(<Input placeholder="Introduza escalão" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="Tipo Contrato">
                                            {getFieldDecorator("tipo_contrato", {
                                                initialValue: Utilitaries.toString(formData?.tipo_contrato),
                                            })(<Input placeholder="Introduza tipo de contrato" />)}
                                        </Form.Item>
                                    </Col>
                                </>
                            )}

                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="Modalidade" style={{ padding: 0, marginBottom: 0 }}>
                                    {getFieldDecorator("modalidade", {
                                        initialValue: Utilitaries.toString(formData?.modalidade),
                                    })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="Seleciona uma opção"
                                            filterOption={(input, option) =>
                                                String(option.props.children)
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Select.Option value="1">Tempo total</Select.Option>
                                            <Select.Option value="2">Tempo parcial</Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            {getFieldValue("modalidade") === "2" && (
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="Afetação">
                                        {getFieldDecorator("afetacao", {
                                            initialValue: Utilitaries.toString(formData?.afetacao),
                                        })(<Input placeholder="Introduza a %" />)}
                                    </Form.Item>
                                </Col>
                            )}

                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="Modelo" style={{ padding: 0, marginBottom: 0 }}>
                                    {getFieldDecorator("modelo", {
                                        initialValue: Utilitaries.toString(formData?.modelo),
                                    })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="Seleciona uma opção"
                                            filterOption={(input, option) =>
                                                String(option.props.children)
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Select.Option value="1">Presencial</Select.Option>
                                            <Select.Option value="2">Teletrabalho</Select.Option>
                                            <Select.Option value="3">Misto</Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            {getFieldValue("tipo_utilizador") === "2" && (
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="Horas de Tabralho">
                                        {getFieldDecorator("horas_trabalho", {
                                            initialValue: Utilitaries.toString(formData?.horas_trabalho),
                                        })(<Input placeholder="Introduza horas de trabalho" />)}
                                    </Form.Item>
                                </Col>
                            )}
                            <div>
                                {getFieldValue("tipo_utilizador") === "2" && getFieldValue("horas_trabalho") != "0" && (
                                    <Col xs={24} md={16}>
                                        <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                                            Dias de Trabalho
                                            <Checkbox
                                                style={{ marginLeft: "12px" }}
                                                // indeterminate={
                                                //     Utilitaries.toArray(getFieldValue("dias_trabalho")?.lenght) > 0 &&
                                                //     Utilitaries.toArray(getFieldValue("dias_trabalho")?.lenght) < 7
                                                // }
                                                onChange={(e) => {
                                                    setFieldsValue({
                                                        dias_trabalho: e.target.checked
                                                            ? ["1", "2", "3", "4", "5", "6", "0"]
                                                            : [],
                                                    });
                                                }}
                                                defaultChecked={
                                                    Utilitaries.toArray(getFieldValue("dias_trabalho")).lenght === 7
                                                }
                                            >
                                                Todos os dias da semana
                                            </Checkbox>
                                        </div>
                                        {getFieldDecorator("dias_trabalho", {
                                            initialValue: Utilitaries.toString(formData?.dias_trabalho)
                                                ? formData?.dias_trabalho.split(" ")
                                                : [],
                                        })(
                                            <CheckboxGroup
                                                // defaultValue={
                                                //     ficha_colaborador.dias_trabalho && typeof ficha_colaborador.dias_trabalho !== "object"
                                                //         ? ficha_colaborador.dias_trabalho.split(" ").map((item) => plainOptions[item])
                                                //         : props.state.checkedList
                                                // }
                                                options={[
                                                    { label: "Segunda", value: "1" },
                                                    { label: "Terça", value: "2" },
                                                    { label: "Quarta", value: "3" },
                                                    { label: "Quinta", value: "4" },
                                                    { label: "Sexta", value: "5" },
                                                    { label: "Sábado", value: "6" },
                                                    { label: "Domingo", value: "0" },
                                                ]}
                                                // onChange={(e) => props.onChangeWorkDays(e)}
                                            />
                                        )}
                                        <br />
                                    </Col>
                                )}
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="Trabalhador por turnos?">
                                        {getFieldDecorator("trab_turnos", {
                                            initialValue:
                                                Utilitaries.toString(formData.trab_turnos) === "t" ? true : false,
                                        })(
                                            <Radio.Group buttonStyle="solid">
                                                <Radio.Button value={true}>Sim</Radio.Button>
                                                <Radio.Button value={false}>Não</Radio.Button>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Tooltip
                                        placement="right"
                                        title={"Utilizar apenas se o módulo de Gestão de Salários estiver disponível"}
                                    >
                                        <Button icon="info-circle" onClick={() => showModalAccessValidation()}>
                                            Acessos a validação
                                        </Button>
                                    </Tooltip>
                                    {accessValidation && <Icon type="check" style={{ color: "green" }} />}
                                </Col>
                            </div>
                        </>
                    )}
            </Row>
        </Form>
    );
}
