import moment from "moment";
import { Utilitaries } from "../../../business";
import { downFile, mergeRecursive } from "../../../modules/process_execution/constants/utils";
import { COMPONENT_TYPE } from "./constants";
import { input_validations } from "./validations";

export const handleRestrinction = ({
    options,
    key,
    value,
    formData,
    formErrors,
    comparar_datas,
    valueKey,
    formFields = {},
}) => {
    let _formErrors = { ...formErrors };
    let _showError;

    if (options && options.inputAttribute && options.inputAttribute["@restricao"] && key !== "data_ini") {
        // handle some specifics cases
        if (
            (options.inputAttribute["@tipo"] === "data" || options.inputAttribute["@mascara"] === "hora") &&
            value === "Invalid date"
        ) {
            value = "";
            //
        }
        let currentState = {};
        //

        if (options && options.inTable) {
            if (formData[options.items.table]) {
                if (options.items.key && formData[options.items.table][options.items.key]) {
                    currentState = Array.isArray(formData[options.items.table][options.items.key])
                        ? {
                              ...formData[options.items.table][options.items.key][options.items.data.__key__],
                          }
                        : formData[options.items.table][options.items.key];
                } else if (!options.items.key) {
                    currentState = Array.isArray(formData[options.items.table])
                        ? { ...formData[options.items.table] }
                        : formData[options.items.table];
                }
            }
        } else {
            currentState = { ...formData };
        }

        let supposedNewValues = { [key]: value };
        if (valueKey === "ano" && !currentState[valueKey]) {
            supposedNewValues = {
                ...supposedNewValues,
                [valueKey]: moment().format("YYYY"),
            };
        }
        currentState = Object.assign({}, currentState, supposedNewValues);
        if (options && options.inTable) {
            options.items.data = currentState;
        }

        if (options.inputAttribute["@restricao"]) {
            if (
                !input_validations({
                    item: options.inputAttribute,
                    relKey: "@restricao",
                    // formData: currentState,
                    formData,
                    functions: { comparar_datas, moment },
                    inputProps: options,
                    selfValue: value,
                    allInputs: formFields,
                })
            ) {
                // alert("validar " + options.inputAttribute["@restricao"]);
                const error = options.inputAttribute["@msg_erro"]
                    ? options.inputAttribute["@msg_erro"]
                    : `${options.inputAttribute["@etiqueta"] ? options.inputAttribute["@etiqueta"] : key} inválido`;
                //

                if (!options.noMessage) {
                    _showError = [error];
                }
                if (!options?.inTable) {
                    const listOfError = {
                        ...formErrors,
                        [key]: error,
                    };

                    _formErrors = listOfError;
                }

                // return;
            } else {
                if (!options?.inTable) {
                    let currentFormErrors = { ...formErrors };
                    //
                    delete currentFormErrors[key];
                    //
                    _formErrors = currentFormErrors;
                }
            }
        }
    } else {
        if (
            options &&
            options.inputAttribute &&
            (options.inputAttribute["@tipo"] === "data" || options.inputAttribute["@mascara"] === "hora") &&
            value === "Invalid date"
        ) {
            value = "";
        }
    }

    return { _formErrors, _showError, value };
};

export const handleRequered = ({ formData, options, value, formErrors, key }) => {
    let _formErrors = { ...formErrors };
    if (options && options.inputAttribute) {
        let isRequered = false;
        if (options?.inputAttribute?.["@obrigatorio"]) {
            if (
                options?.inputAttribute["@obrigatorio"] === "true" ||
                options?.inputAttribute["@obrigatorio"] === "true()"
            ) {
                isRequered = true;
            } else {
                isRequered = input_validations({
                    item: options?.inputAttribute,
                    relKey: "@obrigatorio",
                    // formData: options.inTable ? options.items.data : formData,
                    formData: formData,
                    functions: {},
                    inputProps: options,
                });
            }

            if (isRequered) {
                const error = `${options.inputAttribute["@etiqueta"]} obrigatório`;

                if (value) {
                    if (!options?.inTable) {
                        let currentFormErrors = { ...formErrors };
                        //
                        if (currentFormErrors[key] === error) {
                            delete currentFormErrors[key];
                        }
                        //
                        _formErrors = currentFormErrors;
                    }
                } else {
                    // if (!options.noMessage) {
                    //     showError([error]);
                    // }
                    if (!options?.inTable) {
                        const listOfError = {
                            ...formErrors,
                            [key]: error,
                        };

                        _formErrors = listOfError;
                    }
                }
            } else {
                if (!options?.inTable) {
                    let currentFormErrors = { ...formErrors };
                    //
                    delete currentFormErrors[key];
                    //
                    _formErrors = currentFormErrors;
                }
            }
        }
    }

    return _formErrors;
};

export const handleSetNewValue = ({
    options,
    formData,
    key,
    value,
    valueKey,
    allValues,
    changeObjectPropertyRecursively,
    formulario,
}) => {
    let newValues = null;
    let newFormData = {};
    let data = Object.assign({}, formData);

    if (options && options.inTable) {
        if (formData[options.items.table]) {
            //
            let currentState = [];
            if (options.items.key && formData[options.items.table][options.items.key]) {
                currentState = Array.isArray(formData[options.items.table][options.items.key])
                    ? formData[options.items.table][options.items.key]
                    : [formData[options.items.table][options.items.key]];
            } else if (!options.items.key) {
                currentState = Array.isArray(formData[options.items.table])
                    ? formData[options.items.table]
                    : [formData[options.items.table]];
            }

            newValues = [];

            currentState.forEach((it, idx) => {
                //
                if (idx === options.items.data.__key__) {
                    it[key] = value;
                    //
                    if (valueKey === "ano" && !it[valueKey]) {
                        it[[valueKey]] = moment().format("YYYY");
                    }
                    it = { ...it, ...allValues };
                }
                newValues.push(it);
                //
            });

            newValues = handlerCopyValueForTable({ formulario, key, newValues, options });

            if (options.items.key) {
                newFormData = {
                    ...data,
                    [options.items.table]: {
                        ...formData[options.items.table],
                        [options.items.key]: newValues,
                    },
                };
            } else {
                newFormData = {
                    // ...data,
                    [options.items.table]: newValues,
                };
            }
        } else if (options._nodes) {
            let currentState = [];
            const node = options._nodes[0];
            //
            if (formData[node][options.items.table][options.items.key]) {
                currentState = Array.isArray(formData[node][options.items.table][options.items.key])
                    ? formData[node][options.items.table][options.items.key]
                    : [formData[node][options.items.table][options.items.key]];

                newValues = [];

                currentState.forEach((it, idx) => {
                    //
                    if (idx === options.items.data.__key__) {
                        it[key] = value;
                        //
                        if (valueKey === "ano" && !it[valueKey]) {
                            it[[valueKey]] = moment().format("YYYY");
                        }
                    }
                    newValues.push(it);
                    //
                });

                newFormData = {
                    // ...data,
                    [options._nodes[0]]: {
                        ...formData[options._nodes[0]],
                        [options.items.table]: {
                            [options.items.key]: newValues,
                        },
                    },
                };
            }
        }
    } else {
        if (options._nodes) {
            // let data = Object.assign({}, formData);
            let nodes = [...options._nodes];
            console.log("s", data, nodes, key, allValues);

            newFormData = changeObjectPropertyRecursively(data, [...nodes], key, value, allValues);
            // downFile(JSON.stringify({ data, nodes, key, value, allValues }), "wilson.json", "application/json");
        } else {
            newFormData = {
                // ...data,
                ...allValues,
                [key]: value,
            };

            newFormData = checkCopyValue(options, newFormData, key, formulario);

            if (valueKey === "ano" && !formData[valueKey]) {
                newFormData[[valueKey]] = moment().format("YYYY");
            }
        }
    }

    return { newValues, newFormData: mergeRecursive(data, newFormData), updated: newFormData };
};

const handlerCopyValueForTable = ({ options, formulario, newValues, key }) => {
    if (options && options.inputAttribute && options.inputAttribute["@quando_valor_alterado"]) {
        const quandoValorAlterado = options.inputAttribute["@quando_valor_alterado"];

        // ;
        // Get all brothers elements with "@accao_${quandoValorAlterado}"
        // const dependentInput = [];
        quandoValorAlterado.split(";").forEach((action) => {
            Object.entries(
                formulario.inputs[options.items.key] &&
                    formulario.inputs[options.items.key]["@tipo"] === COMPONENT_TYPE.datagrid
                    ? formulario.inputs[options.items.key]
                    : formulario.inputs[options.items.table][options.items.key]
            )
                .filter(([name, attr]) => attr["@accao_" + action])
                .forEach(async ([name, attr]) => {
                    const depAction = attr["@accao_" + action];

                    // Copiar
                    if (/texto/.test(depAction)) {
                        let valKey = depAction.replace(/texto|\W/gi, "");
                        if (key !== valKey) {
                            return;
                        }

                        newValues = newValues.map((it, idx) => {
                            if (idx === options.items.data.__key__) {
                                it[name] = options._value;
                            }
                            return it;
                        });
                    }
                });
        });
    }

    return newValues;
};

const checkCopyValue = (options, newValues, changedInputName, formulario) => {
    // ;
    if (options && options.inputAttribute && options.inputAttribute["@quando_valor_alterado"]) {
        const quandoValorAlterado = options.inputAttribute["@quando_valor_alterado"]
            .split(";")
            .filter((it) => it !== "");

        // Get all brothers elements with "@accao_${quandoValorAlterado}"
        let inputs = {};

        if (options.inTable) {
            inputs = formulario.inputs[options.items.table][options.items.key];
        } else {
            inputs = formulario.inputs;
        }

        Object.entries(inputs).forEach(([inputName, inputAttributes]) => {
            for (const key of quandoValorAlterado) {
                if (inputAttributes.hasOwnProperty(`@accao_${key}`)) {
                    if (inputAttributes[`@accao_${key}`].startsWith("texto(")) {
                        let valKey = inputAttributes[`@accao_${key}`].replace(/texto|\W/gi, "");

                        if (changedInputName !== valKey) {
                            return;
                        }

                        if (options.inTable) {
                            newValues = newValues.map((it, idx) => {
                                if (idx === options.items.data.__key__) {
                                    it[inputName] = options._value;
                                }
                                return it;
                            });
                        } else {
                            newValues[inputName] = options._value || newValues[changedInputName];
                        }
                        break;
                    }
                }
            }
        });
    }
    // ;
    return newValues;
};

// eslint-disable-next-line no-unused-vars
const round3 = (x) => x;
const round = (x) => x;

export const handleCalculateValue = ({
    options,
    formFields,
    tableData = [],
    newFormData,
    changeObjectPropertyRecursively,
    formErrors,
    showError,
    setFormErrors,
}) => {
    let _newFormData = { ...newFormData };

    let updatedState = {};

    const calculateKey = "@on_change_calculate";
    if (options?.inputAttribute?.[calculateKey]) {
        const dependentFieldPattern = Utilitaries.toString(options?.inputAttribute[calculateKey])
            .split(";")
            .filter((it) => !Utilitaries.isEmpty(it));

        const dependentField = dependentFieldPattern.map((it) => getFieldForPattern(it, formFields));

        for (const { input, key } of dependentField) {
            if (!input?.["@calculate_value"]) {
                continue;
            }
            try {
                let expression = input_validations({
                    item: input,
                    relKey: "@calculate_value",
                    // formData: options?.inTable ? tableData : _newFormData,
                    formData: _newFormData,
                    functions: {},
                    returnExpression: true,
                    allInputs: formFields,
                });

                let value = eval(expression);

                const {
                    _formErrors,
                    _showError,
                    value: alteredValue,
                    valueKey,
                } = handleRestrinction({
                    comparar_datas: () => {},
                    formData: _newFormData,
                    formErrors,
                    key,
                    options: { inputAttribute: input },
                    value,
                });

                const requiredErrors = handleRequered({ formErrors, key, options, value: alteredValue });

                // showError(_showError);
                setFormErrors({ ..._formErrors, ...requiredErrors });

                value = alteredValue;

                const { newFormData: __newFormData, updated } = handleSetNewValue({
                    allValues: {},
                    changeObjectPropertyRecursively,
                    formData: _newFormData,
                    key,
                    // options,
                    options: {},
                    value,
                    formulario: formFields,
                });

                updatedState = updated; //__newFormData;
            } catch (error) {
                console.error(error);
                continue;
            }
        }
    }

    return updatedState;
};

const getFieldForPattern = (pattern, formFields) => {
    const paths = Utilitaries.toString(pattern)
        .split("/")
        .filter((it) => !Utilitaries.isEmpty(it));

    let input = {};

    for (const path of paths) {
        input = formFields[path];
    }

    return { input, key: paths[paths.length - 1] };
};
