import { actionTypes } from "../constants/index";

const initialState = {
    domain: {},
    app: {},
};

export const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.setFilterFieldValue:
            break;

        case actionTypes.setFilterFields:
            break;

        default:
            return state;
    }
};
