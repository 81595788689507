import React, { useEffect, useState } from "react";
import { formMapper } from "../../utils/FormMapper";
import {
    AllModalProps,
    FormAttributesProps,
    FormInputCalcTriggerProps,
    FormInputDependenceStateProps,
    FormInputsObjectProps,
    FormRootElementAttributesProps,
} from "../../types/formTypes";
import { FormStateProvider } from "./hooks/FormState";
import { FormExecution } from "./Components/FormExecution";
import { addValuesToFormInput, executeWhenLoading } from "../../utils/FormMapper/formHandle";
import { Button, Icon, Result } from "antd";
import { Link } from "react-router-dom";
import { Utilitaries } from "../../../business";
import { QaFormActions } from "./Components/FormButtonsActions";
import { FieldEditorProps, GenericObject } from "../../types/common";
import { QAModalEditField } from "./FormComponents/ModalEditField";
interface Props {
    form: any;
    loadForm: boolean;
    isProcess?: boolean;
    isInModal: boolean;
    justSee?: boolean;
    popup_params?: GenericObject;
    onSave: (value?: string) => void;
}

export function FormRender({ form, loadForm, popup_params, onSave, isProcess = false, justSee, isInModal }: Props) {
    // const { setState: setFormState, state: formState } = useCustomState<FormInputsObjectProps>({});
    const [formState, setFormState] = useState<FormInputsObjectProps>({});

    const [formInputDependenceState, setFormInputDependenceState] = useState<FormInputDependenceStateProps>({});
    const [inputCalcTrigger, setInputCalcTrigger] = useState<FormInputCalcTriggerProps>({});
    const [modalsState, setModalsState] = useState<AllModalProps>({});
    const [loading, setLoading] = useState(false);
    const [formAttributesState, setFormAttributesState] = useState<FormAttributesProps>();
    const [rootElementAttributes, setRootElementAttributes] = useState<FormRootElementAttributesProps>();
    const [triedSave, setTriedSave] = useState(false);

    const DEFAULT_FIELD_EDITOR_ATTRIBUTES: FieldEditorProps = {
        disabled: false,
        onChange: undefined,
        onCloseEditor: () => {
            setFieldEditor(DEFAULT_FIELD_EDITOR_ATTRIBUTES);
        },
        value: "",
        componentsProperties: undefined,
    };

    const [fieldEditor, setFieldEditor] = useState<FieldEditorProps>(DEFAULT_FIELD_EDITOR_ATTRIBUTES);

    useEffect(() => {
        const prepareForm = async () => {
            if (form.form) {
                setLoading(true);
                const { formInputs, modal, formAttributes, formRootElementAttributes } = formMapper(form.form);

                const result = await executeWhenLoading(formRootElementAttributes, formInputs);

                let values = form?.values;
                const formKey = `id_${formAttributes.rootElement}`;
                if (formAttributes.tableId && formAttributes.tableId !== "0" && Utilitaries.isEmpty(values)) {
                    values = {
                        [formAttributes.rootElement]: {
                            [formKey]: formAttributes.tableId,
                        },
                    };
                    values[formAttributes.rootElement] = {
                        [formKey]: formAttributes.tableId,
                    };
                }

                const formInputsWithValues = addValuesToFormInput(values || {}, formAttributes, result);

                setFormState(formInputsWithValues);
                setModalsState(modal);
                setFormAttributesState(formAttributes);
                setRootElementAttributes(formRootElementAttributes);
                setLoading(false);
            }
        };

        prepareForm();
    }, [form, setFormState]);

    if (!loading && !loadForm && Utilitaries.isEmpty(formAttributesState)) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Lamentamos, não foi possível carregar o formulário"
                extra={
                    <Link to="/">
                        <Button type="primary">Voltar tela principal</Button>
                    </Link>
                }
            />
        );
    }

    return (
        <FormStateProvider
            state={formState}
            setState={setFormState}
            formInputDependenceState={formInputDependenceState}
            setFormInputDependenceState={setFormInputDependenceState}
            setInputCalcTrigger={setInputCalcTrigger}
            inputCalcTrigger={inputCalcTrigger}
            modalState={modalsState}
            setModalState={setModalsState}
            popup_params={popup_params}
            setFieldEditor={setFieldEditor}
            triedSave={triedSave}
        >
            <div style={{ cursor: "default" }}>
                {(loading || loadForm) && <Result icon={<Icon type="loading" />} title="Carregando..." />}

                {!loading && !loadForm && Utilitaries.isEmpty(formState) && (
                    <Result
                        status="404"
                        title="404"
                        subTitle="Lamentamos, não foi possível carregar o formulário!"
                        extra={
                            <Link to="/">
                                <Button type="primary">Voltar tela principal</Button>
                            </Link>
                        }
                    />
                )}
                {!loading && !loadForm && (
                    <>
                        {/* {!isInModal && isProcess && (<h2>Process title when render process not in modal</h2>)} */}
                        <FormExecution
                            form={{ ...formState }}
                            loading={loading}
                            isTab={false}
                            isProcess={isProcess}
                            justSee={justSee}
                            rootElementAttributes={rootElementAttributes!}
                        />
                        {formAttributesState && (
                            <QaFormActions
                                form={formState}
                                formAttributes={formAttributesState}
                                onSave={onSave}
                                rootElementAttributes={rootElementAttributes}
                                justSee={justSee}
                                setTriedSave={setTriedSave}
                                isModal={isInModal}
                                isProcess={isProcess}
                            />
                        )}
                    </>
                )}

                <QAModalEditField
                    {...fieldEditor}
                    onCloseEditor={() => setFieldEditor(DEFAULT_FIELD_EDITOR_ATTRIBUTES)}
                />
            </div>
        </FormStateProvider>
    );
}
