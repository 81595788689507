import React from "react";
import { Table, Select, Tag, Button, Icon, Input } from "antd";

const { Option } = Select;

function TaskTable({
    type,
    data,
    isDisabled,
    authorizations,
    handleSetUsersByGroup,
    groupsToDelegate,
    usersByGroupToDelegate,
    setUserToDelegateProcessDef,
    showModal,
    setAttributionType,
    setTaskDescription,
}) {
    let columns = [
        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
            width: "20%",
        },
    ];

    if (type === "toAssign") {
        columns.push({
            title: "Autorizações",
            key: "auth",
            width: "25%",
            render: (text, record, index) => {
                return (
                    <div>
                        {record.allAuthorizations.map((selectedAuth) => {
                            const designacao = authorizations.find(
                                ({ autorizacao }) => autorizacao === selectedAuth.authId
                            )?.designacao;
                            return <Tag color={isDisabled ? "" : "blue"}>{designacao}</Tag>;
                        })}
                        <Button
                            type="primary"
                            disabled={isDisabled}
                            title="Editar autorizações"
                            size="small"
                            onClick={() => showModal(record.id)}
                        >
                            <Icon type="edit" style={{ fontSize: "18px" }} />
                        </Button>
                    </div>
                );
            },
        });

        columns.push({
            title: "Tipo Atribuição",
            key: "tipo_atribuicao",
            width: "15%",
            render: (text, record) => (
                <Select
                    showSearch
                    allowClear
                    disabled={isDisabled}
                    style={{ width: "100%" }}
                    placeholder="Selecione o tipo atribuição"
                    optionFilterProp="children"
                    defaultValue={record.tipo_atribuicao}
                    onChange={(value) => setAttributionType(value, record.id)}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="1">Candidatura</Option>
                    <Option value="2">Delegação superior</Option>
                    <Option value="3">Mista</Option>
                </Select>
            ),
        });

        columns.push({
            title: "Responsável Delegação",
            width: "40%",
            key: "resp_delegacao",
            render: (text, record) => {
                let { tipo_atribuicao, grupo_delegacao, resp_delegacao, id } = record;
                if (tipo_atribuicao === "2" || tipo_atribuicao === "3") {
                    const isGroupSelected = typeof grupo_delegacao !== "object" && grupo_delegacao;
                    grupo_delegacao = typeof grupo_delegacao !== "object" ? grupo_delegacao : null;
                    resp_delegacao = typeof resp_delegacao !== "object" ? resp_delegacao : null;
                    return (
                        <>
                            <Select
                                showSearch
                                allowClear
                                style={{
                                    width: isGroupSelected ? "49%" : "75%",
                                }}
                                placeholder="Selecione o grupo"
                                optionFilterProp="children"
                                value={grupo_delegacao}
                                disabled={isDisabled}
                                onChange={(value) => handleSetUsersByGroup({ group: value, task: id })}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {groupsToDelegate?.map(({ id, grupo }) => {
                                    const descGrupo = typeof grupo !== "object" ? grupo : "---";
                                    return <Option value={id}>{descGrupo}</Option>;
                                })}
                            </Select>
                            {isGroupSelected && (
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "49%", marginLeft: "0.3em" }}
                                    placeholder="Selecione o responsável"
                                    optionFilterProp="children"
                                    disabled={isDisabled}
                                    value={resp_delegacao}
                                    onChange={(value) => setUserToDelegateProcessDef({ rowIndex: id, value })}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {usersByGroupToDelegate?.[id]?.map(({ util, nome }) => (
                                        <Option value={util}>{nome}</Option>
                                    ))}
                                </Select>
                            )}
                        </>
                    );
                } else return <span style={{ margin: "0 auto" }}>---</span>;
            },
        });
    }

    if (type === "description") {
        columns.push({
            width: "75%",
            title: "Descrição",
            dataIndex: "descricao",
            key: "descricao",
            render: (text, record) => {
                const descricao = typeof text === "object" ? "" : text;

                return (
                    <Input
                        defaultValue={descricao}
                        onChange={(e) => setTaskDescription({ rowIndex: record.id, value: e.target.value })}
                        disabled={isDisabled}
                    ></Input>
                );
            },
        });
    }

    return <Table type columns={columns} size="middle" dataSource={data} />;
}

export default TaskTable;
