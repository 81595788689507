import { Form, Row, Modal } from "antd";
import React, { useState, memo } from "react";
import { Utilitaries } from "../../business";
import { isEmpty } from "../../modules/process_execution/utils/fields";

import { QATab, QAPopUp } from "./qa_components";
import { getDataFormInputActions } from "./utils/functions";

import renderInput from "./utils/renderInputs";
import { input_validations } from "./utils/validations";

const FormRender = ({ form, formData, removeItemFromTable, addItemToTable, changeInputValue, params, rootElement }) => {
    const [popUp, setPopUp] = useState(null);

    const handleInputChange = (value, key, options = {}) => {
        changeInputValue({
            key,
            value,
            ...options,
        });
    };

    const checkIfFieldCanBeVisible = ({ input, options = {}, objectInputs = {}, arrayFields = [], field }) => {
        // downFile(JSON.stringify({ formData }), "json.json", "text/plain");

        if (input["@visivel"] === "nao") {
            return false;
        }

        let inputAttr = { ...input };

        let key = "@relevante";
        if (!inputAttr[key]) {
            if (inputAttr["@grupo_relevante_setor_de_atividade"]) {
                // key = "@grupo_relevante_setor_de_atividade";
                return formData && formData["controlo"] === "f";
            } else if (inputAttr["@grupo_relevante_fornecedores"]) {
                // key = "@grupo_relevante_fornecedores";
                return formData && formData["controlo"] === "f";
            } else if (inputAttr["@grupo_relevante_cabecalho"]) {
                key = "@grupo_relevante_cabecalho";
            } else if (inputAttr["@grupo_relevante_rodape"]) {
                key = "@grupo_relevante_rodape";
            } else {
                const keys = Object.keys(inputAttr);
                key = keys.find((el) => el.startsWith("@grupo_relevante_"));

                if (!key && !Utilitaries.isEmpty(objectInputs) && field) {
                    const AllFieldsArray = [...arrayFields];

                    const index = AllFieldsArray.findIndex((it) => it === field);
                    const fieldsToSearch = AllFieldsArray.splice(0, index).reverse();

                    for (const _field of fieldsToSearch) {
                        const _key = Object.keys(objectInputs[_field]).find((el) => el.startsWith("@grupo_relevante_"));

                        if (_key) {
                            key = _key;
                            inputAttr = { ...inputAttr, [_key]: objectInputs[_field][_key] };
                            break;
                        }
                    }
                }
            }
        }

        if (!inputAttr[key]) {
            return true;
        } else {
            return input_validations({
                item: inputAttr,
                relKey: key,
                formData,
                functions: {},
                inputProps: options,
                allInputs: objectInputs,
            });
        }
    };

    const checkInputRestrinctions = ({ input, options = {}, values, showInModal, field }) => {
        // return false;
        //;
        // const readOnly = checkIfFieldCanBeEdited(input, options);
        let newOption = { ...options };

        if (!input?.["@restricao"] && !input?.["@obrigatorio"]) {
            return true;
        } else {
            // let data = options.inTable ? options.items.data : formData;
            let data = formData;
            if (values) {
                if (options?.inTable) {
                    newOption = {
                        ...options,
                        items: {
                            ...options.items,
                            data: {
                                ...options.items.data,
                                ...values,
                            },
                        },
                    };
                } else {
                    data = {
                        ...data,
                        ...values,
                    };
                }
            }

            const verifyData = options?.inTable ? newOption.items.data : data;

            //
            let isRequered = false;
            if (input?.["@obrigatorio"]) {
                if (input?.["@obrigatorio"] === "true" || input?.["@obrigatorio"] === "true()") {
                    isRequered = true;
                } else {
                    isRequered = input_validations({
                        item: input,
                        relKey: "@obrigatorio",
                        formData: formData,
                        functions: {},
                        inputProps: options,
                    });
                }

                if (isRequered) {
                    if (isEmpty(verifyData[field])) {
                        const mess = input["@msg_erro"]
                            ? input["@msg_erro"]
                            : `${input["@etiqueta"] ? input["@etiqueta"] : ""} obrigatório`;

                        if (showInModal) {
                            Modal.error({
                                title: "Erro",
                                content: <div>{mess}</div>,
                            });
                        }

                        return mess;
                    }
                    // return ""; // comentado para validar a restricao caso nao encontrar erro no obrigatorio
                }
                // return "";
            }
            /* if (input?.["@obrigatorio"] === "true" || input?.["@obrigatorio"] === "true()") {
                if (isEmpty(verifyData[field])) {
                    const mess = input["@msg_erro"]
                        ? input["@msg_erro"]
                        : `${input["@etiqueta"] ? input["@etiqueta"] : ""} obrigatório`;

                    if (showInModal) {
                        Modal.error({
                            title: "Erro",
                            content: <div>{mess}</div>,
                        });
                    }

                    return mess;
                }

                return "";
            } */
            if (!input?.["@restricao"]) return undefined;
            if (
                input_validations({
                    item: input,
                    relKey: "@restricao",
                    formData: data,
                    functions: {},
                    inputProps: newOption,
                    selfValue: values[field],
                })
            ) {
                return undefined;
            } else {
                const mess = input["@msg_erro"]
                    ? input["@msg_erro"]
                    : `${input["@etiqueta"] ? input["@etiqueta"] : ""} inválido`;

                if (showInModal) {
                    Modal.error({
                        title: "Erro",
                        content: <div>{mess}</div>,
                    });
                }

                return mess;
            }
        }
    };

    const canRenderTab = ({ tabInputKeys, formInputs }) => {
        // @tab property is in the first item
        const firstTabInput = formInputs[tabInputKeys[0]];

        if (firstTabInput?.["@relevante"]) {
            const restrinction = input_validations({
                item: firstTabInput,
                relKey: "@relevante",
                formData: formData,
                functions: {},
                inputProps: {},
            });

            return restrinction;
        } else {
            return true;
        }
    };

    const checkIfFieldCanBeEdited = (input, options = {}) => {
        let key = "@apenas_leitura";

        if (!input["@apenas_leitura"]) {
            return false;
        } else if (input["@apenas_leitura"] && input["@apenas_leitura"] === "true") {
            return true;
        } else {
            // if (input["@apenas_leitura"] === "../id_funcao != ''") ;
            return input_validations({
                item: input,
                relKey: key,
                // formData: options.inTable ? options.items.data : formData,
                formData: formData,
                functions: {},
                inputProps: options,
            });
            // const relevateInput = input["@apenas_leitura"].split("=").map((it) => it.trim());
            // let _field = relevateInput[0].substring(3);
            // let _value = relevateInput[1].substring(1, relevateInput[1].length - 1);

            // // Obter o tipo do imput alvo
            // let targetAttributes;
            // if (options && options.inTable) {
            //     _field = _field.split("/");
            //     _field = _field.pop();
            //     targetAttributes = form.inputs[options.items.table][options.items.key][_field];
            // } else {
            //     targetAttributes = form.inputs[_field];
            // }

            // if (targetAttributes && targetAttributes["@tipo"] === "boolean") {
            //     _value = _value === "true" ? "t" : "f";
            // }

            // if (options && options.inTable) {
            //     return options.items.data[_field] === _value;
            //     // return (
            //     //   formData[options.items.table][options.items.key][
            //     //     options.items.data.__key__
            //     //   ][_field] === _value
            //     // );
            // } else {
            //     return formData[_field] === undefined || formData[_field] === _value;
            // }
        }
    };

    const checkIsRequired = ({ input, options = {}, values, showInModal, field }) => {
        let key = "@obrigatorio";
        if (!input?.["@obrigatorio"]) {
            return false;
        } else if (input["@obrigatorio"] === "true" || input["@obrigatorio"] === "true()") {
            return true;
        } else {
            return input_validations({
                item: input,
                relKey: key,
                // formData: options.inTable ? options.items.data : formData,
                formData: formData,
                functions: {},
                inputProps: options,
            });
        }
    };

    const onSaveInPopUpUpdateField = async (field, options, id) => {
        changeInputValue({
            key: field,
            value: id,
            ...options,
        });
        setPopUp(null);
        const results = await getDataFormInputActions({
            key: field,
            id,
            formInputs: form.inputs,
            formData,
            options,
            rootElement,
        });

        for (const result of results) {
            changeInputValue({
                key: result.key,
                value: result.value,
                _nodes: [result.table],
                ...options,
            });
        }
    };

    const popUpParams = (input, options = {}, field) => {
        let formParams = {};
        // If exist params
        if (input && input["@param_popup"]) {
            // get params
            let param_popup = input["@param_popup"];
            // removel all white space, [ and ]
            param_popup = param_popup.replace(/ +/g, "").replace(/[[ | \]]/g, "");

            // get object with values in the table inside the root element or in the root element if it is not a table
            let valueIn = {};
            if (options && options.inTable) {
                // valueIn =
                //     formData[options.items.table][options.items.key][
                //         options.items.data.__key__
                //     ]; se tabela tem 1 item fica como objeto nao array da erro
                valueIn = options.items.data;
            } else {
                valueIn = formData;
            }

            // split by ;
            param_popup.split(";").forEach((it) => {
                // for each item split by , return [x1, x2]
                let param = it.split(",");

                if (!Utilitaries.isEmptyArrayItems(param)) {
                    let paramValue;

                    if (param[1].includes("../") || param[1].includes("//")) {
                        const path = param[1].split("/").filter((it) => it && it !== "..");

                        if (param[1].startsWith("//")) {
                            paramValue = formData[path[0]];
                        }

                        if (!paramValue) {
                            if (path.length === 2 && path[0] === options.items.key) {
                                paramValue = valueIn[path[1]];
                            } else {
                                paramValue = valueIn[path[0]];

                                if (!paramValue && params) {
                                    paramValue = params[path[0]];
                                }
                            }
                        }
                    } else if (param[1].includes("$params.")) {
                        const key = param[1].replace("$params.", "");
                        paramValue = params[key];
                    } else {
                        paramValue = param[1];
                    }

                    formParams = {
                        ...formParams,
                        [param[0]]: paramValue,
                    };
                }
            });
        }

        // if (params && params["espaco_exemplo"]) {
        //     formParams["espaco_exemplo"] = params["espaco_exemplo"];
        // }

        setPopUp({
            input,
            params: { ...params, ...formParams },
            formId: options && options.items ? options.items.data[options.items.__id] : formData[field],
            updateOnSave: {
                options,
                field,
                callback: onSaveInPopUpUpdateField,
            },
        });
    };

    const renderItems = renderInput({
        fieldsValue: formData,
        params: params,
        handleInputChange,
        checkIfFieldCanBeVisible,
        checkIfFieldCanBeEdited,
        removeItemFromTable,
        addItemToTable,
        setPopUp: popUpParams,
        checkInputRestrinctions,
        checkIsRequired,
    });

    return (
        <>
            <Form layout="vertical">
                <Row gutter={18} type="flex">
                    {(() => {
                        // if have tabs and input without tab
                        if (form && form.fields && form.fields["fields"] && form.fields["tab0"]) {
                            let inputs = [];

                            inputs = renderItems({
                                arrayFields: form.fields["fields"],
                                objectInputs: form.inputs,
                            });

                            return [
                                ...inputs,
                                <QATab key={"tab"} form={form} renderItems={renderItems} canRenderTab={canRenderTab} />,
                            ];
                        }

                        const type = form && form.qa_type ? form.qa_type : "";

                        switch (type) {
                            case "tab":
                                return <QATab form={form} renderItems={renderItems} canRenderTab={canRenderTab} />;

                            default:
                                return form
                                    ? renderItems({
                                          arrayFields: form.fields["fields"],
                                          objectInputs: form.inputs,
                                      })
                                    : null;
                        }
                    })()}
                </Row>
            </Form>
            <QAPopUp
                visible={popUp !== null}
                onCancel={() => setPopUp(null)}
                // Have {input, params, formId, updateOnSave}
                {...popUp}
            />
        </>
    );
};

export default memo(FormRender);
