import { sendHttpRequest } from "../../../components/http.request";

export const getProcesses = async (macro, meso) => {
    const formData = new FormData();
    formData.append("accao", "interacao_v1");

    if (macro) {
        formData.append("macro", macro);
    }

    if (meso) {
        formData.append("meso", meso);
    }
    try {
        const data = await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
        return data;
    } catch (error) {
        return [];
    }
};
