import { Utilitaries } from "../../../business";
import { sendHttpRequest } from "../../../components/http.request";
import { validateResponse } from "./../../../components/util";

export const getUsers = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista");
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });

    const response = await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
    if (response) {
        const data = validateResponse(response);
        const lista = data.map((item) => ({
            ...item,
            tipo: typeof item.tipo === "object" ? "" : item.tipo.toString(),
        }));
        const metadata = response["@attributes"];

        return {
            lista,
            metadata: {
                resultados: Number(metadata.resultados),
                pagina: Number(metadata.pagina),
                visivel: metadata.visivel,
                resultados_pagina: Number(metadata.resultados_pagina),
            },
        };
    }
    return false;
};

export const getDetails = async (payload) => {
    const formData = new FormData();
    formData.append("accao", "acessos");
    formData.append("username", payload);

    const response = await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
    if (response) {
        const autorizacoes = validateResponse(response.autorizacoes).map((item) => item.idautorizacao);
        const estabelecimentos = validateResponse(response.estabelecimentos).map((item) => item.id_estabelecimento);
        const estabelecimento_defeito = Utilitaries.isEmpty(response.estabelecimento_defeito)
            ? ""
            : response.estabelecimento_defeito;
        const lista_estabelecimentos = validateResponse(response.lista_estabelecimentos).map((item) => item);
        const lista_autorizacoes = validateResponse(response.lista_autorizacoes).map((item) => item);

        return {
            autorizacoes,
            estabelecimentos,
            lista_estabelecimentos,
            estabelecimento_defeito,
            lista_autorizacoes,
        };
    }
    return false;
};

export const save = async (payload) => {
    const autorizacoes = payload.autorizacoes.toString().replace(/,/g, ";");
    const estabelecimentos = payload.estabelecimentos.toString().replace(/,/g, ";");

    const formData = new FormData();
    formData.append("accao", "guardar_acessos");
    formData.append("username", payload.username);
    formData.append("estabelecimentos", estabelecimentos);
    formData.append("autorizacoes", autorizacoes);
    formData.append("senha", payload.senha ? payload.senha : "");
    formData.append("estabelecimento_defeito", payload.estabelecimento_defeito ? payload.estabelecimento_defeito : "");

    const response = await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
    if (response) {
        if (response.erro) return { status: false, message: response.erro };
        return { status: true };
    }
    return { status: false, message: "Ocorreu um erro" };
};

export const handleUserInfo = async (requestBody) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });

    const response = await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
    return response;
};
