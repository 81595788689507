import React, { Component } from "react";
import { Tabs, PageHeader, Row, Col, Icon, Button, Divider, message, Form } from "antd";
import { connect } from "react-redux";

import PersonalInfoTabPane from "./personalinfo.tabe";
import ProgrammedAbsencesTabPane from "./programmedabsences.tabe";
import FuntionsConventionsTabPane from "./functions.tabe";
import DocumentTabPane from "./document.tabe";
import OverTimeTabPane from "./overtime";
import FoulsTabPane from "./fouls";
import FormationTabPane from "./formation";
import { selectDataFromGereFormulário, postDataToGereProcesso } from "../../actions/contributor";
import { Historic } from "./historic";

const { TabPane } = Tabs;

class NewContributor extends Component {
    state = {
        loading: false,
        ficha_colaborador: "",
        username: "",
        full_name: "",
        register_type: "Outro",
        user_category: "",
        funcoes_colab: [],
        horas_extras: [],
        ausencia: [],
        faltas: [],
        formacao_externa: [],
        docs_colaboradores: [],
        progs_contratuais: [],
        progs_carreiras: [],
        progs_funcionais: [],
        progs_remunatorias: [],
        modalidade: [],
        afetacao: undefined,
        modelo: [],
        dias_trabalho: [],
        acessos_validacao: undefined,
        data_nascimento: undefined,
        data_admissao_servico: undefined,
        data_vigor: "",
        convenc_coletiva: undefined,
        categoria: undefined,
        nivel: undefined,
        info_nivel: undefined,
    };
    componentDidMount() {
        if (this.props.match.params.id) {
            const { id } = this.props.match.params;
            this.getContributorDataById(id);
        }
    }

    getContributorDataById = async (id) => {
        try {
            const response = await this.props.selectDataFromGereFormulário({
                accao: "dados",
                formulario: "qa_ficha_colaborador.xml",
                id,
            });
            if (response.valores) {
                if (response?.valores?.ficha_colaborador) {
                    const { ficha_colaborador } = response.valores;
                    const {
                        tipo_utilizador,
                        tipo_colaborador,
                        username,
                        entidade,
                        funcoes_colab,
                        docs_colaboradores,
                        progs_contratuais,
                        progs_carreiras,
                        progs_funcionais,
                        progs_remunatorias,
                        modalidade,
                        afetacao,
                        modelo,
                        horas_extras,
                        ausencia,
                        falta,
                        formacoes_externa,
                    } = ficha_colaborador;

                    let newState = {
                        ficha_colaborador,
                        username,
                        full_name: entidade.nome_completo,
                        user_category: tipo_utilizador,
                        register_type: tipo_colaborador,
                        modalidade,
                        afetacao,
                        modelo,
                    };

                    if (typeof ficha_colaborador?.data_admissao_servico !== "object") {
                        newState = { ...newState, data_admissao_servico: ficha_colaborador.data_admissao_servico };
                    }

                    if (typeof entidade?.data_nascimento !== "object") {
                        newState = { ...newState, data_nascimento: entidade.data_nascimento };
                    }

                    if (typeof entidade?.identidade !== "object") {
                        newState = { ...newState, identidade: entidade.identidade };
                    }

                    if (typeof entidade?.moradas?.morada?.idmorada !== "object") {
                        newState = { ...newState, idmorada: entidade?.moradas?.morada?.idmorada };
                    }

                    if (typeof ficha_colaborador?.acessos_validacao !== "object") {
                        newState = { ...newState, acessos_validacao: ficha_colaborador.acessos_validacao };
                    }

                    if (Array.isArray(funcoes_colab?.funcao_colab)) {
                        newState = {
                            ...newState,
                            funcoes_colab: funcoes_colab?.funcao_colab.map((item, key) => {
                                return { key, ...item };
                            }),
                        };
                    } else if (funcoes_colab?.funcao_colab?.id_funcao_colab) {
                        const { funcao_colab } = funcoes_colab;
                        newState = {
                            ...newState,
                            funcoes_colab: [{ key: 0, ...funcao_colab }],
                        };
                    }

                    if (typeof ficha_colaborador?.convenc_coletiva !== "object") {
                        newState = { ...newState, convenc_coletiva: ficha_colaborador.convenc_coletiva };
                    }

                    if (typeof ficha_colaborador?.categoria !== "object") {
                        newState = { ...newState, categoria: ficha_colaborador.categoria };
                    }

                    if (typeof ficha_colaborador?.nivel !== "object") {
                        newState = { ...newState, nivel: ficha_colaborador.nivel };
                    }

                    if (typeof ficha_colaborador?.info_nivel !== "object") {
                        newState = { ...newState, info_nivel: ficha_colaborador.info_nivel };
                    }

                    if (typeof ficha_colaborador?.data_vigor !== "object") {
                        newState = { ...newState, data_vigor: ficha_colaborador.data_vigor };
                    }

                    if (Array.isArray(docs_colaboradores?.doc_colaborador)) {
                        newState = {
                            ...newState,
                            docs_colaboradores: docs_colaboradores.doc_colaborador.map((item, key) => {
                                return { key, ...item };
                            }),
                        };
                    } else if (docs_colaboradores?.doc_colaborador?.id_doc_colaborador) {
                        const { doc_colaborador } = docs_colaboradores;
                        newState = {
                            ...newState,
                            docs_colaboradores: [{ key: 0, ...doc_colaborador }],
                        };
                    }

                    if (Array.isArray(horas_extras?.hora_extra)) {
                        newState = {
                            ...newState,
                            horas_extras: horas_extras.hora_extra.map((item, key) => {
                                return { key, ...item };
                            }),
                        };
                    } else if (horas_extras?.hora_extra?.id_hora_extra) {
                        const { hora_extra } = horas_extras;
                        newState = {
                            ...newState,
                            horas_extras: [{ key: 0, ...hora_extra }],
                        };
                    }

                    if (Array.isArray(ausencia?.ausencia)) {
                        newState = {
                            ...newState,
                            ausencia: ausencia.ausencia.map((item, key) => {
                                return { key, ...item };
                            }),
                        };
                    } else if (ausencia?.ausencia?.id_ausencia) {
                        // const { ausencia } = ausencia;
                        newState = {
                            ...newState,
                            ausencia: [{ key: 0, ...ausencia.ausencia }],
                        };
                    }

                    if (Array.isArray(falta?.falta)) {
                        newState = {
                            ...newState,
                            faltas: falta.falta.map((item, key) => {
                                return { key, ...item };
                            }),
                        };
                    } else if (falta?.falta?.id_falta) {
                        // const faltas = falta.falta;
                        newState = {
                            ...newState,
                            faltas: [{ key: 0, ...falta.falta }],
                        };
                    }

                    if (Array.isArray(formacoes_externa?.formacao_externa)) {
                        newState = {
                            ...newState,
                            formacao_externa: formacoes_externa.formacao_externa.map((item, key) => {
                                return { key, ...item };
                            }),
                        };
                    } else if (formacoes_externa?.formacao_externa?.id_formacao_externa) {
                        const { formacao_externa } = formacoes_externa;
                        newState = {
                            ...newState,
                            formacao_externa: [{ key: 0, ...formacao_externa }],
                        };
                    }

                    this.setState({
                        ...newState,
                    });
                }
            }
        } catch (error) {
            message.error("Erro ao carregar dados!!");
        }
    };

    /** get data from server to list in selects */
    getFaltasSistema = async (id) => {
        this.setState({ loading: true });
        try {
            const response = await this.props.selectDataFromExecQuery({
                query: "buscar_faltas_sistema_user",
                id,
            });
            if (response.result) {
                this.setState({ faltas_sistema: response.result });
            }
        } catch (error) {
            message.error("Erro ao carregar faltas de sistema!!");
        } finally {
            this.setState({ loading: false });
        }
    };

    handleUserCategoryChanges = (user_category) => {
        this.setState({ user_category });
    };

    getRegisterType = (type, user_category) => {
        this.setState({
            register_type: type,
            user_category,
        });
    };

    setUsernameAndFullName = (username, full_name) => {
        if (username !== null) {
            this.setState({
                username,
            });
        }

        if (full_name !== null) {
            this.setState({
                full_name,
            });
        }
    };

    getFuncoesColab = (funcoes_colab) => {
        this.setState({ funcoes_colab });
    };

    getHorasExtras = (horas_extras) => {
        this.setState({ horas_extras });
    };

    getAusencia = (ausencia) => {
        this.setState({ ausencia });
    };

    getFaltas = (faltas) => {
        this.setState({ faltas });
    };

    getFormacoesExternas = (formacao_externa) => {
        this.setState({ formacao_externa });
    };

    getDocsColaboradores = (docs_colaboradores) => {
        this.setState({ docs_colaboradores });
    };
    getProgsContratuais = (progs_contratuais) => {
        this.setState({ progs_contratuais });
    };
    getProgsCarreiras = (progs_carreiras) => {
        this.setState({ progs_carreiras });
    };
    getProgsFuncionais = (progs_funcionais) => {
        this.setState({ progs_funcionais });
    };
    getProgsRemunatorias = (progs_remunatorias) => {
        this.setState({ progs_remunatorias });
    };

    getDaysOfWork = (dias_trabalho) => {
        this.setState({ dias_trabalho });
    };

    getAcessoValidacao = (data) => {
        this.setState({ acessos_validacao: data.sucesso || this.state.acessos_validacao });
    };

    getDataNascimento = (data_nascimento) => {
        this.setState({ data_nascimento });
    };

    getDataAdmissaoServico = (data_admissao_servico) => {
        this.setState({ data_admissao_servico });
    };

    getDataVigor = (data_vigor) => {
        this.setState({ data_vigor });
    };

    getConvencColetiva = (convenc_coletiva) => {
        this.setState({ convenc_coletiva });
    };

    getCategoria = (categoria) => {
        this.setState({ categoria });
    };

    getNivel = (nivel) => {
        this.setState({ nivel });
    };

    getInfoNivel = (info_nivel) => {
        this.setState({ info_nivel });
    };

    validateNib = (value) => {
        if (value) {
            const isNumber = /^\d+$/.test(value);

            if (value?.toString()?.split("")?.length !== 21 || !isNumber) {
                message.error("NIB deve ter 21 dígitos numéricos");
                return false;
            }
        }
        return true;
    };

    validateIban = (value) => {
        if (value) {
            const firstTwoCharater = value.slice(0, 2);
            const rest = value.substring(2);
            if (!/^\d+$/.test(rest?.replace(/\s/g, "")) || !/^[a-zA-Z]+$/.test(firstTwoCharater)) {
                message.error("Os dois primeiro digitos do IBAN deve ser letras e os restantes números");

                return false;
            }

            if (value?.toString()?.replace(/\s/g, "")?.split("")?.length !== 25) {
                message.error("IBAN deve ter 25 carateres");
                return false;
            }
        }
        return true;
    };

    saveFichaFuncionarioToServer = () => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (!this.validateIban(values.iban) || !this.validateNib(values.nib)) {
                    return;
                }

                let ficha_colaborador = {
                    ficha_colaborador: {
                        tipo_utilizador: values.tipo_utilizador,
                        tipo_colaborador: values.tipo_colaborador,
                        username: values.username,
                        senha: values.senha,
                        sexo: values.sexo,
                        status: values.status,
                        motivo_suspensao: values.motivo_suspensao,
                        estab: values.estab,
                        estabelecimento_defeito: this.state.ficha_colaborador.estabelecimento_defeito || "",
                        tipo_contracto: values.tipo_contracto,
                        exclusividade: values.exclusividade,
                        entidade: {
                            nome_completo: values.nome_completo,
                            nome: values.nome,
                            tlf: values.tlf,
                            tlm: values.tlm,
                            email: values.email,
                            iban: values.iban,
                            nib: values.nib,
                            bank: values.bank,
                            niss: values.niss,
                            nif: values.nif,
                            data_nascimento: this.state.data_nascimento,
                            moradas: {
                                morada: {
                                    pais: values.pais,
                                    distrito: values.distrito,
                                    concelho: values.concelho,
                                    freguesia: values.freguesia,
                                    arruamento: values.arruamento,
                                    c_postal4: values.c_postal4,
                                    c_postal3: values.c_postal3,
                                    porta: values.porta,
                                    andar: values.andar,
                                },
                            },
                        },
                        habilitacao: values.habilitacao,
                        data_admissao_servico: this.state.data_admissao_servico,
                        inps: values.inps,
                        num_mecanografico: values.num_mecanografico,
                        escalao: values.escalao,
                        tipo_contrato: values.tipo_contrato,
                        horas_trabalho: values.horas_trabalho,
                        dias_trabalho: this.state.dias_trabalho.join(" "),
                        acessos_validacao: this.state.acessos_validacao,
                        funcoes_colab: {
                            funcao_colab: this.state.funcoes_colab,
                        },
                        docs_colaboradores: {
                            doc_colaborador: this.state.docs_colaboradores,
                        },
                        progs_contratuais: {
                            doc_colaborador: this.state.progs_contratuais,
                        },
                        progs_carreiras: {
                            doc_colaborador: this.state.progs_carreiras,
                        },
                        progs_funcionais: {
                            doc_colaborador: this.state.progs_funcionais,
                        },
                        progs_remunatorias: {
                            doc_colaborador: this.state.progs_remunatorias,
                        },
                        horas_extras: {
                            hora_extra: this.state.horas_extras,
                        },
                        linha: {
                            ausencia: this.state.ausencia,
                            falta: this.state.faltas,
                        },
                        convenc_coletiva: this.state.convenc_coletiva,
                        categoria: this.state.categoria,
                        nivel: this.state.nivel,
                        info_nivel: this.state.info_nivel,
                        data_vigor: this.state.data_vigor,
                        formacoes_externa: {
                            formacao_externa: this.state.formacao_externa,
                        },
                    },
                };
                if (this.props.match.params.id)
                    ficha_colaborador = {
                        ficha_colaborador: {
                            id_ficha_colaborador: this.props.match.params.id,
                            ...ficha_colaborador.ficha_colaborador,
                        },
                    };
                ficha_colaborador.ficha_colaborador["id_ficha_colaborador"] = this.props.match.params.id;

                if (this.state?.identidade) {
                    ficha_colaborador.ficha_colaborador.entidade["identidade"] = this.state?.identidade;
                }
                if (this.state?.idmorada) {
                    ficha_colaborador.ficha_colaborador.entidade.moradas.morada["idmorada"] = this.state?.idmorada;
                }

                console.log(ficha_colaborador);

                try {
                    this.setState({ loading: true });
                    const result = await this.props.postDataToGereProcesso({
                        accao: "guardar",
                        formulario: "qa_ficha_colaborador.xml",
                        dados: JSON.stringify(ficha_colaborador),
                    });

                    if (result.sucesso) {
                        message.success("Ficha do funcionário gravado com sucesso!!");
                        this.goBack();
                    } else if (result.Erro) {
                        message.error(result.Erro);
                    } else message.error("Erro ao gravar ficha do funcionário!!");
                } catch (error) {
                    message.error("Erro ao gravar ficha do funcionário!!");
                } finally {
                    this.setState({ loading: false });
                }
            } else {
                const fields = {
                    username: "Utilizador",
                    senha: "Senha",
                    status: "Estado",
                    nome_completo: "Nome Completo",
                    nome: "Nome a utilizar",
                };
                for (const key in err) {
                    message.error(`${fields[key]} é campo obrigatório!`);
                }
            }
        });
    };

    goBack = () => {
        this.props.history.push({
            pathname: "/function.manual/index/2",
        });
    };

    render() {
        return (
            <div>
                <PageHeader onBack={this.goBack} title="Ficha do funcionário" />

                <Tabs defaultActiveKey="1">
                    <TabPane tab="Informação Pessoal" key="1">
                        <PersonalInfoTabPane
                            handleUserCategoryChanges={this.handleUserCategoryChanges.bind(this)}
                            user_category={this.state.user_category}
                            register_type={this.state.register_type}
                            form={this.props.form}
                            ficha_colaborador={this.state.ficha_colaborador}
                            setUsernameAndFullName={this.setUsernameAndFullName.bind(this)}
                            getRegisterType={this.getRegisterType.bind(this)}
                            getDaysOfWork={this.getDaysOfWork.bind(this)}
                            getAcessoValidacao={this.getAcessoValidacao.bind(this)}
                            getDataNascimento={this.getDataNascimento.bind(this)}
                            getDataAdmissaoServico={this.getDataAdmissaoServico.bind(this)}
                            parentState={this.state}
                        />
                    </TabPane>
                    {this.state.user_category !== 1 &&
                    this.state.register_type !== "Outro" &&
                    this.state.register_type !== "Sócio" &&
                    typeof this.state.register_type !== "object" ? (
                        <TabPane tab="Funções e Convenções" key="3">
                            <FuntionsConventionsTabPane
                                form={this.props.form}
                                getFuncoesColab={this.getFuncoesColab.bind(this)}
                                ficha_colaborador={this.state.ficha_colaborador}
                                getDataVigor={this.getDataVigor.bind(this)}
                                getConvencColetiva={this.getConvencColetiva.bind(this)}
                                getCategoria={this.getCategoria.bind(this)}
                                getNivel={this.getNivel.bind(this)}
                                getInfoNivel={this.getInfoNivel.bind(this)}
                            />
                        </TabPane>
                    ) : null}
                    {this.state.user_category !== 1 &&
                    this.state.register_type !== "Sócio" &&
                    this.state.register_type !== "Outro" &&
                    typeof this.state.register_type !== "object" ? (
                        <TabPane tab="Documentos" key="4">
                            <DocumentTabPane
                                getDocsColaboradores={this.getDocsColaboradores.bind(this)}
                                ficha_colaborador={this.state.ficha_colaborador}
                            />
                        </TabPane>
                    ) : null}

                    {/* <TabPane tab="Histórico" key="8">
                        <Historic
                        handleUserCategoryChanges={this.handleUserCategoryChanges.bind(this)}
                        user_category={this.state.user_category}
                        register_type={this.state.register_type}
                        form={this.props.form}
                        ficha_colaborador={this.state.ficha_colaborador}
                        setUsernameAndFullName={this.setUsernameAndFullName.bind(this)}
                        getRegisterType={this.getRegisterType.bind(this)}
                        getDaysOfWork={this.getDaysOfWork.bind(this)}
                        getAcessoValidacao={this.getAcessoValidacao.bind(this)}
                        getDataNascimento={this.getDataNascimento.bind(this)}
                        getDataAdmissaoServico={this.getDataAdmissaoServico.bind(this)}
                        parentState={this.state}
                        />
                    </TabPane> */}

                    {this.state.user_category !== 1 &&
                    this.state.register_type !== "Sócio" &&
                    this.state.register_type !== "Outro" &&
                    typeof this.state.register_type !== "object" ? (
                        <TabPane tab="Horas Extraordinárias" key="5">
                            <OverTimeTabPane
                                getHorasExtras={this.getHorasExtras.bind(this)}
                                ficha_colaborador={this.state.ficha_colaborador}
                            />
                        </TabPane>
                    ) : null}
                    <TabPane tab="Ausências Programadas" key="2">
                        <ProgrammedAbsencesTabPane
                            getAusencia={this.getAusencia.bind(this)}
                            ficha_colaborador={this.state.ficha_colaborador}
                        />
                    </TabPane>
                    {this.state.user_category !== 1 &&
                    this.state.register_type !== "Sócio" &&
                    this.state.register_type !== "Outro" &&
                    typeof this.state.register_type !== "object" ? (
                        <TabPane tab="Faltas" key="6">
                            <FoulsTabPane
                                getFaltas={this.getFaltas.bind(this)}
                                ficha_colaborador={this.state.ficha_colaborador}
                                username={this.state.username}
                                nome_completo={this.state.full_name}
                                dias_trabalho={this.state.dias_trabalho}
                            />
                        </TabPane>
                    ) : null}
                    {this.state.user_category !== 1 &&
                    this.state.register_type !== "Sócio" &&
                    this.state.register_type !== "Outro" &&
                    typeof this.state.register_type !== "object" ? (
                        <TabPane tab="Formação" key="7">
                            <FormationTabPane
                                getFormacoesExternas={this.getFormacoesExternas.bind(this)}
                                ficha_colaborador={this.state.ficha_colaborador}
                            />
                        </TabPane>
                    ) : null}
                </Tabs>
                <Divider />
                <Row gutter={18}>
                    <Col xs={24} sm={12}>
                        <div className="qa-group-actions-left">
                            <Button
                                icon="save"
                                loading={this.state.loading}
                                type="primary"
                                onClick={() => this.saveFichaFuncionarioToServer()}
                            >
                                Guardar
                            </Button>
                            {/* <Button type="primary">
                                <Icon type="printer" />
                                Imprimir
                            </Button>
                            <Button type="primary">
                                <Icon type="save" />
                                Guardar Temporário
                            </Button> */}
                        </div>
                    </Col>

                    <Col xs={24} sm={12}>
                        <div className="qa-group-actions-right">
                            {/* <Button
                                icon="undo"
                                type="primary"
                                htmlType="submit"
                            >
                                Reformular
                            </Button>
                            <Button
                                icon="cloud-upload"
                                type="primary"
                                htmlType="submit"
                            >
                                Carregar Local
                            </Button>
                            <Button
                                icon="play-circle"
                                type="primary"
                                htmlType="submit"
                            >
                                Gravar Local
                            </Button> */}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {
    selectDataFromGereFormulário,
    postDataToGereProcesso,
};
const WrappedNewContributor = Form.create({ name: "new_contributor" })(NewContributor);
export default connect(null, mapDispatchToProps)(WrappedNewContributor);
