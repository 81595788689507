import React, { Component } from "react";
import ProfessionalCategory from "./professional.category";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/collective.agreement";
import moment from "moment";

import { Form, Row, Col, Input, Radio, Divider, Button, message, Skeleton } from "antd";

function disabledDate(current) {
    return current && current > moment();
}

class FormCollectiveAgreement extends Component {
    componentDidMount() {
        if (this.props.isEdit) {
            this.setState({ isFetching: true });
            this.props.getById(this.props.id).then(() => {
                this.setState({ isFetching: false });
            });
        }
    }

    state = {
        isFetching: false,
    };

    handleResponse = (response) => {
        this.setState({ isButtonLoading: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.redirect();
        } else message.error("Ocorreu um erro");
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const obj = {
                    ...values,
                    listCategoria: this.props.listCategoria,
                };

                this.setState({ isButtonLoading: true });
                if (this.props.isEdit) {
                    obj.id_convencao_coletiva = this.props.formAgreement.id_convencao_coletiva;
                    this.props
                        .update({
                            formulario: this.props.formulario,
                            values: obj,
                        })
                        .then((response) => {
                            this.handleResponse(response);
                        });
                } else {
                    this.props.insert(obj).then((response) => {
                        this.handleResponse(response);
                    });
                }
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <>
                <Form layout="vertical" onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                            <Skeleton active loading={this.state.isFetching} paragraph={{ rows: 1, width: "100%" }}>
                                <Form.Item label="Nome">
                                    {getFieldDecorator("nome", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(<Input />)}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Skeleton active loading={this.state.isFetching} paragraph={{ rows: 1, width: "100%" }}>
                                <Form.Item label="Data da convenção">
                                    {getFieldDecorator("data", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(<Input type="date" disabledDate={disabledDate} />)}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Skeleton active loading={this.state.isFetching} paragraph={{ rows: 1, width: "100%" }}>
                                <Form.Item label="Em vigor?">
                                    {getFieldDecorator("em_vigor", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(
                                        <Radio.Group buttonStyle="solid">
                                            <Radio.Button value={1}>Sim</Radio.Button>
                                            <Radio.Button value={0}>Nao</Radio.Button>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left">Categorias Profissionais</Divider>
                        <ProfessionalCategory
                            addCategory={this.props.addCategory}
                            deleteCategory={this.props.deleteCategory}
                            saveCategory={this.props.updateCategory}
                            listCategoria={this.state.isFetching ? [] : this.props.listCategoria}
                            getNiveis={this.props.getNivies}
                            nomeConvencao={this.props.formAgreement.nome.value}
                            isEdit={this.props.isEdit}
                            formulario={this.props.formulario}
                            isFetching={this.state.isFetching}
                        />
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24}>
                            <Form.Item>
                                <div className="qa-group-actions-left">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        icon="save"
                                        loading={this.state.isButtonLoading}
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}

const mapDispatchToProps = {
    setField: actionCreators.setField,
    addCategory: actionCreators.addCategory,
    deleteCategory: actionCreators.deleteCategory,
    updateCategory: actionCreators.updateCategory,
    insert: actionCreators.insert,
    getById: actionCreators.getById,
    getNivies: actionCreators.getNiveis,
    update: actionCreators.update,
};

const mapStateToProps = (state, ownProps) => {
    const formAgreement = ownProps.isEdit
        ? state.collectiveAgreement.domain.formEdit
        : state.collectiveAgreement.domain.formCreate;

    return {
        formAgreement,
        listCategoria: formAgreement.categoria,
        formulario: state.collectiveAgreement.domain.metadata.formulario,
    };
};

const formCreate = Form.create({
    mapPropsToFields(props) {
        return {
            nome: Form.createFormField({
                ...props.formAgreement.nome,
                value: props.formAgreement.nome.value,
            }),
            data: Form.createFormField({
                ...props.formAgreement.data,
                value: props.formAgreement.data.value
                    ? moment(props.formAgreement.data.value).format("YYYY-MM-DD")
                    : "",
            }),
            em_vigor: Form.createFormField({
                ...props.formAgreement.em_vigor,
                value: props.formAgreement.em_vigor.value,
            }),
        };
    },
    onFieldsChange(props, fields) {
        props.setField({ isEdit: props.isEdit, fields });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(formCreate(FormCollectiveAgreement));
