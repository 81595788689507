import React, { useEffect, useRef } from "react";
import { G6 } from "gg-editor";
import ReactDOM from "react-dom";
import { toArray } from "./components/util";
import { Utilitaries } from "./business";
import { Button, Col, Input, Modal, Row, Select, Table, Typography } from "antd";
import { SelectInput } from "./V2/components/SelectInput";

const options = [];
for (let i = 0; i < 10000; i = i + 1) {
    options.push({ value: i, label: `Option ${i}` });
}

export default function Teste(props) {
    const ref = useRef(null);
    const [visible, setVisible] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    const onChange = (value) => {
        console.log(value);
    };

    const onSelect = (value) => {
        console.log(value);
    };

    const onCancel = () => {
        setVisible(false);
    };

    const onOk = () => {
        setVisible(false);
    };

    const onClick = () => {
        setVisible(true);
    };

    const optionsList = options.map((item, idx) => (
        <Select.Option key={"option1" + idx} value={item.value} title={item.label}>
            {item.label}
        </Select.Option>
    ));

    return (
        <div className="graph-container" id="divContainerGraph">
            <Typography.Title>Teste</Typography.Title>

            <SelectInput options={options} onChange={onChange} />

            <Select
                showSearch
                allowClear
                placeholder=""
                dropdownMatchSelectWidth={false}
                optionFilterProp="children"
                onChange={(value, option) => {
                    console.log("onChange", value, option);
                }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {optionsList}
                {/* {options.map((item, idx) => (
                    <Select.Option key={"option1" + idx} value={item.value} title={item.label}>
                        {item.label}
                    </Select.Option>
                    
                ))} */}
            </Select>

            <Row>
                <Col>
                    <Input placeholder="Input with clear icon" allowClear disabled />
                    <Button
                        onClick={onClick}
                        style={{ width: "100%", textAlign: "left", color: "rgba(0, 0, 0, 0.25)" }}
                    >
                        Selecione um item
                    </Button>
                </Col>
            </Row>

            <SelectModal visible={visible} onCancel={onCancel} onOk={onOk} options={options} onChange={onChange} />
        </div>
    );
}

function SelectModal({ visible, onCancel, onOk, options, onChange }) {
    const dataSource = [
        {
            key: "1",
            name: "Mike",
            age: 32,
            address: "10 Downing Street",
        },
        {
            key: "2",
            name: "John",
            age: 42,
            address: "10 Downing Street",
        },
    ];

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Age",
            dataIndex: "age",
            key: "age",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
    ];

    const onRow = (record, index) => {
        console.log(record, index);
        return {
            onClick: () => {
                console.log("onRow", record, index);
            },
        };
    };

    return (
        <div>
            <Modal title="Selecione um item" visible={visible} onCancel={onCancel} width="50%">
                <Table dataSource={dataSource} columns={columns} size="small" onRow={onRow} />
            </Modal>
        </div>
    );
}
