import { Col, Form, Input } from "antd";
import React, { useState, useContext, useEffect } from "react";

import { QADivider, QAToolTip } from "./";
import FormContext from "../context/FormContext";
import { getInputLabel } from "../utils/functions";

const { TextArea } = Input;

const QaTextArea = ({
    inputAttribute,
    value,
    // fields,
    field,
    colSize,
    visible,
    // handleInputChange,
    isRequired,
    renderGroup,
    ...inputProps
}) => {
    const [currentValue, setCurrentValue] = useState();
    const { changeInputValue, justSee, updateError, formErrors } = useContext(FormContext);

    const [error, setError] = useState(null);

    useEffect(() => {
        if (updateError) setError(formErrors[field]);
    }, [field, formErrors, updateError]);

    useEffect(() => {
        if ((currentValue && value) || currentValue !== value) {
            setCurrentValue(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const { inTable } = inputProps;

    if (!visible) {
        return null;
    }
    let rows;
    if (inTable) {
        rows = 1;
    } else {
        const regex = /(?<=\s|^)area_texto_enorme(?=$|\s)/g;
        rows = inputAttribute["@estilo"]?.match(regex) ? 12 : 4;
    }

    const renderInput = () => (
        <TextArea
            rows={rows}
            placeholder={null}
            style={{ minWidth: 150 }}
            // defaultValue={value}
            // value={value}
            value={currentValue}
            disabled={justSee}
            onChange={(e) => setCurrentValue(e.target.value)}
            onBlur={(e) => {
                changeInputValue({
                    value: e.target.value,
                    key: field,
                    inputAttribute,
                    noMessage: true, // nao mostrar pop up com mesagem de campo errado
                    ...inputProps,
                });
            }}
            // onChange={(e) => {
            //     // setInputValue(e.target.value);
            //     changeInputValue({
            //         value: e.target.value,
            //         key: field,
            //         inputAttribute,
            //         ...inputProps,
            //     });
            // }}
            // onBlur={() =>
            // 	changeInputValue({
            // 		value: inputValue,
            // 		key: field,
            // 		inputAttribute,
            // 		...inputProps,
            // 	})
            // }
        />
    );

    return inTable ? (
        renderInput()
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            <Col xs={colSize}>
                <Form.Item
                    label={
                        <QAToolTip
                            label={getInputLabel(inputAttribute, field)}
                            help={inputAttribute["@ajuda"]}
                            isRequired={isRequired}
                        />
                    }
                >
                    {renderInput()}
                    {error && <span style={{ color: "red" }}>{error}</span>}
                </Form.Item>
            </Col>
        </>
    );
};
export default QaTextArea;
