import { Tabs, Row, Col } from "antd";
import React, { useState, memo } from "react";
import { downFile } from "../../../modules/process_execution/constants/utils";

const { TabPane } = Tabs;

const QaTab = ({ form, renderItems, canRenderTab }) => {
    const [selectedTab, setSelectedTab] = useState(`tab-${form.attributes["@codigo"]}-0`);
    //

    // downFile(JSON.stringify(form), "json.txt", "text/plain");

    // const canRenderTab = (tabKey, tabInputKeys, formInputs) => {
    //     // @tab property is in the first item
    //     const firstTabInput = formInputs[tabInputKeys[0]];

    //     if (firstTabInput?.["@relevante"]) {
    //
    //         return false;
    //     } else {
    //         return true;
    //     }
    // };
    const isToForceRender = form.attributes["@nome"] === "orbis_mov_lancamentos_condominios.xml";

    return (
        // <Col span={24}>
        <Tabs
            defaultActiveKey={selectedTab}
            onChange={(key) => {
                setSelectedTab(key);
            }}
            style={{ width: "100%" }}
        >
            {form.tabs
                .map((tab, index) => ({
                    tab,
                    render: canRenderTab({ tabInputKeys: form.fields["tab" + index], formInputs: form.inputs }),
                    index,
                }))
                .filter((tab) => tab.render)
                .map(({ tab, index }) => (
                    <TabPane tab={tab} key={`tab-${form.attributes["@codigo"]}-${index}`} forceRender={isToForceRender}>
                        <Row gutter={18} type="flex" align="middle">
                            {form &&
                                renderItems({
                                    arrayFields: form.fields["tab" + index],
                                    objectInputs: form.inputs,
                                })}
                        </Row>
                    </TabPane>
                ))}
        </Tabs>
        // </Col>
    );
};

export default memo(QaTab);
