import React, { useRef, useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";

import AppLayout from "./components/app.layout";
import Login from "./components/Authentication/Login";
import RequestResetPassword from "./components/Authentication/RequestResetPassword";
import ResetPassword from "./components/Authentication/ResetPassword";
import { Session } from "./business";
import "./App.css";
import LockScreen from "./components/Authentication/LockScreen";
import { LoginUserPortal } from "./components/Authentication/PortalLogin";
import RegisterUniCV from "./components/Authentication/Register/RegisterUnicv";
import { sendHttpRequest } from "./components/http.request";

const App = () => {
    let idleTimer = useRef();

    const [isTimedOut, setIsTimedOut] = useState(false);
    // const [showLockScreenModal, setShoeLockScreenModal] = useState(false);

    let user = Session.getUsername();

    const history = useHistory();

    const hash = window.location?.hash?.replace("#", "");

    const isPortal = Session.getUsername() === "_portal_";

    useEffect(() => {
        const isLocked = Session.isLockedScreen();

        if (isLocked && user && !isPortal) {
            if (idleTimer.current) {
                idleTimer.reset();
            }
            setIsTimedOut(true);
        }
    }, [isPortal, user]);

    if (
        !user &&
        !hash.startsWith(`/login`) &&
        !hash.includes("reset-password") &&
        !hash.includes("portal") &&
        !hash.includes("register/")
    ) {
        history.replace("/login?redirect=" + hash);
    }

    const handleOnAction = (event) => {
        //
        // setIsTimedOut(false);
        // Session.unlockScreen();
    };

    const handleOnActive = (event) => {
        // setIsTimedOut(false);
        // Session.unlockScreen();
    };

    const logout = async () => {
        const formData = new FormData();
        formData.append("accao", "logout");

        await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);
    };

    const handleOnIdle = async (event) => {
        if (!isTimedOut) {
            // setShoeLockScreenModal(true);
            idleTimer.reset();
            setIsTimedOut(true);
            Session.lockScreen();

            console.log("Idle, lock screen, closse session");
            logout();
        }
    };

    const unlocked = () => {
        idleTimer.reset();
        setIsTimedOut(false);
        Session.unlockScreen();
    };

    return (
        <div id="layout">
            <Switch>
                {!user ? (
                    <>
                        <Route path="/portal" component={LoginUserPortal} />
                        <Route path="/login" component={Login} />
                        <Route path="/register/unicv" exact component={RegisterUniCV} />
                        <Route path="/request-reset-password" exact component={RequestResetPassword} />
                        <Route path="/reset-password/:user/:token" exact component={ResetPassword} />
                        {/* <Route path="/" component={Login} /> */}
                        {/* <Redirect to="/login" /> */}
                    </>
                ) : (
                    <>
                        <IdleTimer
                            ref={(ref) => {
                                idleTimer = ref;
                            }}
                            timeout={process.env.NODE_ENV === "development" ? 30 * 60 * 1000 : 5 * 60 * 1000}
                            onActive={handleOnActive}
                            onIdle={handleOnIdle}
                            onAction={handleOnAction}
                            debounce={250}
                        />
                        <div style={{ display: isTimedOut ? "none" : "block" }}>
                            <AppLayout history={history} />
                        </div>

                        {isTimedOut && <LockScreen unlocked={unlocked} />}
                    </>
                )}
            </Switch>
        </div>
    );
};

// eslint-disable-next-line no-extend-native
String.prototype.toPascalCase = function (space = false) {
    // if (this.match(/\d*/gi)) {
    // 	return this;
    // }
    return this.match(/[a-z]+/gi)
        ?.map(function (word) {
            return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
        })
        .join(space ? " " : "");
};

// eslint-disable-next-line no-extend-native
String.prototype.toQAFormValue = function () {
    switch (this.trim()) {
        case "true":
            return "t";
        case "false":
            return "f";
        default:
            return this.trim();
    }
};

export default App;
