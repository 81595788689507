export const actionType = {
    select: "SELECT_CONTRIBUTOR",
    // insert: "INSERT_COLLECTIVE_AGREEMENT",
    // setField: "SET_FIELD_COLLECTIVE_AGREEMENT",
    // getEdit: "GET_EDIT_COLLECTIVE_AGREEMENT",
    // update: "UPDATE_COLLECTIVE_AGREEMENT",
    // delete: "DELETE_COLLECTIVE_AGREEMENT"
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};

export const plainOptions = [
    { label: "Segunda", value: 1 },
    { label: "Terça", value: 2 },
    { label: "Quarta", value: 3 },
    { label: "Quinta", value: 4 },
    { label: "Sexta", value: 5 },
    { label: "Sábado", value: 6 },
    { label: "Domingo", value: 0 },
];

export const defaultCheckedList = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"];
export const info = "Utilizar apenas se o módulo de Gestão de Salários estiver disponível.";
