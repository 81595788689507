import React, { useEffect, useState } from "react";
import { Row, PageHeader, Button, Icon, Checkbox, Table, Input, Pagination, Tooltip } from "antd";
import { getDocuments } from "../actions";
import { useHistory, Link } from "react-router-dom";
import { downloadFile } from "../../../components/util";

const Documents = () => {
    const [filter, setfilter] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [showObsolete, setShowObsolete] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });
    const history = useHistory();

    useEffect(() => {
        setLoadingData(true);

        const { sorter, ...objFilter } = filter;

        if (sorter) {
            objFilter["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        const values = {
            ...objFilter,
            pagina: pagination.page,
            resultados_pagina: pagination.perPage,
        };

        if (showObsolete) {
            values.obsoletos = "true";
        }

        getDocuments(values).then((response) => {
            setDataSource(response.lista);
            setMetadata(response.metadata);
            setLoadingData(false);
        });
    }, [pagination, filter, showObsolete]);

    const getColumnSearchProps = () => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        };
    };

    const getColumnSortProps = (dataIndex, sortedInfo) => {
        //
        return {
            sorter: true,
            sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
        };
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const sortedInfo = filter.sorter || {};

    const column = [
        {
            title: "Código",
            dataIndex: "codigo",
            ...getColumnSearchProps(),
            ...getColumnSortProps("codigo", sortedInfo),
        },
        {
            title: "Nome",
            dataIndex: "nome",
            ...getColumnSearchProps(),
            ...getColumnSortProps("nome", sortedInfo),
        },
        {
            title: "Versão",
            dataIndex: "num_versao",
            ...getColumnSearchProps(),
            ...getColumnSortProps("num_versao", sortedInfo),
        },
        {
            title: "Família",
            dataIndex: "familia",
            ...getColumnSearchProps(),
            ...getColumnSortProps("familia", sortedInfo),
        },
        {
            title: "Estado",
            dataIndex: "estado_versao",
            ...getColumnSearchProps(),
            ...getColumnSortProps("estado_versao", sortedInfo),
        },
        {
            title: "Ação",
            render: (_, row) => <Link to={`/document/register/${row.id_controlo_documento}`}>Consultar</Link>,
        },
    ];

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val[0]) obj["filtro_" + key] = val[0];
            });
        }

        if (sorter?.columnKey && sorter?.order) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPagination({
            ...pagination,
            page: 1,
        });

        setfilter(obj);
    };

    const pageChange = (page, perPage) => {
        setPagination({
            page,
            perPage,
        });
    };

    const exportList = (type) => {
        try {
            const { sorter, ...objFilter } = filter;

            if (sorter) {
                objFilter["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
            }

            const params = {
                accao: "lista_documentos",
                saida: type,
                titulo: "Listagem de documentos externos",
                downloadInBrowser: true,
                campos: "codigo:248:Código;nome:620:Nome;num_versao:124:Versão;familia:206:Família;estado_versao:146:Estado",
            };
            Object.entries(objFilter).forEach(([key, val]) => {
                params[key] = val;
            });

            if (showObsolete) {
                params.obsoletos = "true";
            }

            downloadFile("/geredocumentos.php", params);
        } catch (error) {}
    };

    return (
        <div>
            <PageHeader title="Listagem de documentos externos" />
            <Row type="flex" style={{ justifyContent: "space-between" }}>
                <Button
                    onClick={() =>
                        history.push({
                            pathname: "/document/register",
                        })
                    }
                    type="primary"
                    style={{ float: "right", marginLeft: 8, marginBottom: 16 }}
                >
                    <Icon type="plus" />
                    Registar
                </Button>

                <div>
                    <Checkbox onChange={(e) => setShowObsolete(e.target.checked)}>Incluir Obsoletos</Checkbox>
                    <Tooltip title="Exportar para excel">
                        <Button
                            style={{
                                marginLeft: 8,
                                color: "#237804",
                            }}
                            icon="file-excel"
                            onClick={() => exportList("xls")}
                        />
                    </Tooltip>
                    <Tooltip title="Exportar para pdf">
                        <Button
                            style={{
                                color: "#fa541c",
                                marginLeft: 8,
                            }}
                            icon="file-pdf"
                            onClick={() => exportList("pdf")}
                        />
                    </Tooltip>
                </div>
            </Row>
            <Table
                rowKey="id_controlo_documento"
                columns={column}
                pagination={false}
                dataSource={dataSource}
                loading={loadingData}
                onChange={handleTableChange}
            />
            <Row>
                <Pagination
                    style={{ float: "right", marginTop: 16 }}
                    current={metadata.pagina}
                    defaultPageSize={metadata.resultados_pagina}
                    size="small"
                    total={metadata.resultados}
                    showSizeChanger
                    showTotal={(total) => `Total registo: ${total}`}
                    onShowSizeChange={pageChange}
                    onChange={pageChange}
                />
            </Row>
        </div>
    );
};

export default Documents;
