export const getItemByKey = ({ key, rowKey, tree, field = [], parent = null, checkParent = false, item = {} }) => {
    const checkItemParent = (item) => {
        if (!checkParent) {
            return true;
        } else if (parent && parent === item?.parent) {
            return true;
        } else if (!parent && !item?.parent) {
            return true;
        } else {
            return false;
        }
    };

    // -- o código abaixo procura apenas na raiz --
    // if (item?.paramNameToValue?.startsWith("//")) {
    //     return [tree[key]];
    // }

    for (const propertie in tree) {
        if (propertie === key && tree[propertie]) {
            const item = tree[propertie];

            if (!item?.key && typeof item !== "string") {
                item["key"] = key;
            }

            if (checkItemParent(item)) {
                field.push(item);
            }
        } else if (propertie === "dataSource" && typeof tree[propertie] === "object") {
            getItemByKey({ key, tree: tree[propertie]?.[rowKey], field });
        } else if (typeof tree[propertie] === "object") {
            getItemByKey({ key, tree: tree[propertie], field });
        } else {
            continue;
        }
    }
    return field;
};

export const canCorrectField = (item) => {
    if (item.key === "tipo_inquerito") {
    }

    if (item?.["@corrigir"] && item?.["@corrigir"] === "nao") {
        return false;
    }

    return true;
};

export const nullSafe = (value) => {
    if (typeof value === "object") {
        return value;
    }

    if (typeof value === "number") {
        return value;
    }

    if (
        value === null ||
        value === undefined ||
        value === "null" ||
        (typeof value === "object" && Array.isArray(value) && value.length === 0) ||
        value?.startsWith("$dados_") ||
        value?.startsWith("$dados.") ||
        value?.startsWith("$destin.") ||
        value?.startsWith("$param.")
    ) {
        return "";
    }

    if (value === "undefined" || value === "NaN") {
        return "";
    }

    if (typeof value === "number" && isNaN(value)) {
        return "";
    }

    return value;
};

export const isEmpty = (value) => {
    if (typeof value === "boolean" || typeof value === "number") {
        return false;
    } else if (typeof value === "object" && Array.isArray(value) && value.length === 0) {
        return true;
    } else if (!value) {
        return true;
    }

    return false;
};

export const setValueByPopupParams = ({ item, params, getFromLabel = false }) => {
    const regex = /{.*}/;

    const target = getFromLabel ? item?.["@etiqueta"] : item?.value;

    let value = target;

    if (regex.test(target || item?.["$"])) {
        const name = (target || item["$"]).replace(/\W|param/gi, "");
        // const popupParamsItem = allParams.find((param) => param.key === name);
        // item.value = allParams ? popupParamsItem.value || popupParamsItem["$"] : item.value;
        value = params?.[name] || target;
    }

    if (getFromLabel) {
        item["@etiqueta"] = value;
    } else {
        item.value = value;
    }

    return item;
};

export const validateBoolean = (value, item) => {
    if (item?.["@tipo"] === "botoes_seleccao") {
        if (value === "false") {
            return "f";
        }
        if (value === "true") {
            return "t";
        }
    }
    return value;
};

export const tableInputWidth = (item, fixedSize = false) => {
    if (!item?.recordKey || !item.isColumn || !fixedSize) {
        return {};
    }

    return {
        width: "200px",
    };
};
