import React, { useEffect, useMemo, useState } from "react";

import { Button, Col, Form, Icon, Input, message, Modal, Row, Select } from "antd";
import { GenericObject } from "../../../types/common";
// import 'antd/dist/antd.css';
import { FormComponentProps } from "antd/lib/form";
import { api_fetch } from "../../../utils/HttpRequest/request";
import { Utilitaries } from "../../../../business";
import CustomerModal from "./CustomerModel";
import { highlightsErrors } from "../../../utils/functions";

const { Option } = Select;

export interface FormRentalProps extends FormComponentProps<any> {
    id?: string;
    visible: boolean;
    formParams?: GenericObject;
    propertie?: string;
    handleCancel: () => void;
    handleOnSave: () => void;
}

const FormRental = ({ visible, form, propertie, formParams, handleCancel, handleOnSave }: FormRentalProps) => {
    const [visiblleModalCustomer, setVsibleModalCustomer] = useState(false);
    // const [selectedCustomer, setSelectedCustomer] = useState<string>();

    const [customersLoading, setCustomersLoading] = useState(false);
    const [customers, setCustomers] = useState<any[]>([]);

    const { getFieldDecorator } = form;

    useEffect(() => {
        const loadCustomers = async () => {
            try {
                setCustomersLoading(true);
                const params = {
                    accao: "imoveis",
                };

                const {
                    response,
                    status,
                    message: messageError,
                } = await api_fetch({
                    endPoint: `Gestor/gerealuguer.php`,
                    method: "POST",
                    params,
                });

                if (status === "SUCCESS") {
                    const result = response?.result;
                    if (result) {
                        if (result.erro) {
                            message.error(Utilitaries.toString(result.erro) || "Ocorreu um erro");
                        } else {
                            const customers = Utilitaries.toArray(result?.clientes?.linha).map((el: any) => ({
                                id: el.id_rent_cliente,
                                nome: el.nome,
                            }));
                            setCustomers(customers);
                        }
                    }
                } else if (status === "ERROR") {
                    message.error(messageError || "Ocorreu um erro");
                }

                setCustomersLoading(false);
            } catch (error) {
                setCustomersLoading(false);
            }
        };

        loadCustomers();
    }, []);

    useEffect(() => {
        if (formParams) {
            form.setFieldsValue({ tipo: propertie, data_inicio: formParams.dataInicio, data_fim: formParams.dataFim });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formParams, propertie]);

    const saveForm = async () => {
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            if (!propertie) {
                message.warning("Não foi indicado nenhum imóvel");
            }

            const params = {
                ...values,
                accao: "inserir",
                imovel: propertie,
            };
            const { status, message: messageError } = await api_fetch({
                endPoint: `Gestor/gerealuguer.php`,
                method: "POST",
                params,
            });

            if (status === "ERROR") {
                message.error(messageError || "Ocorreu um erro");
                return;
            } else if (status === "SUCCESS") {
                handleOnSave();
            }
        });
    };

    const handleOpen = () => {
        setVsibleModalCustomer(true);
    };

    const handleCancelModalCustomer = () => {
        setVsibleModalCustomer(false);
    };
    const handleSaveModalCustomer = (value?: string) => {
        form.setFieldsValue({ terceiro: value });
        setVsibleModalCustomer(false);
    };

    const selectedCustomer = form.getFieldValue("terceiro");
    const selectedCustomerName = useMemo(() => {
        if (!selectedCustomer) {
            return null;
        }
        const row = customers.find((el: any) => el.id === selectedCustomer);
        return row ? row?.nome : "";
    }, [customers, selectedCustomer]);

    const styleCustomer: any = highlightsErrors({
        value: form.getFieldValue("terceiro"),
        required: true,
        restriction: true,
    });

    const customStyleCustomer = styleCustomer.border ? { border: styleCustomer.border } : {};

    return (
        <Modal
            title={`Aluguer do imóvel`}
            visible={visible}
            onCancel={handleCancel}
            onOk={saveForm}
            width={"80%"}
            destroyOnClose
        >
            <Form>
                <Row gutter={[16, 8]}>
                    <Col md={8}>
                        <Form.Item label="Data início" key="data_inicio">
                            {getFieldDecorator("data_inicio", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(<Input type="date" />)}
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Data fim" key="data_fim">
                            {getFieldDecorator("data_fim", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(<Input type="date" />)}
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Tipo operação" key="tipo">
                            {getFieldDecorator("tipo", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(
                                <Select showSearch allowClear dropdownMatchSelectWidth={false}>
                                    <Option key="tipo_operacao_1" value="1">
                                        Reservar
                                    </Option>
                                    <Option key="tipo_operacao_2" value="2">
                                        Manutenção
                                    </Option>
                                    <Option key="tipo_operacao_3" value="3">
                                        Indisponível
                                    </Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col md={24}>
                        <Form.Item label="Cliente" key="terceiro">
                            {getFieldDecorator("terceiro", {
                                rules: [
                                    {
                                        required: true,
                                    },
                                ],
                            })(
                                <>
                                    <Button
                                        onClick={handleOpen}
                                        style={{
                                            width: "100%",
                                            ...customStyleCustomer,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ flex: "1", display: "flex", justifyContent: "left" }}>
                                                {Utilitaries.isEmpty(selectedCustomer) ? (
                                                    <span style={{ color: "rgba(0, 0, 0, 0.25)" }}>
                                                        Selecione uma opção
                                                    </span>
                                                ) : (
                                                    <span style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                                                        {selectedCustomerName}
                                                    </span>
                                                )}
                                            </div>
                                            <Icon type={customersLoading ? "loading" : "down"} />
                                        </div>
                                    </Button>
                                    <Input type="hidden" />
                                </>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col md={24}>
                        <Form.Item label="Observações" key="observacoes">
                            {getFieldDecorator("observacoes")(<Input.TextArea />)}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {visiblleModalCustomer && (
                <CustomerModal
                    visible={visiblleModalCustomer}
                    handleCancel={handleCancelModalCustomer}
                    handleOnSave={handleSaveModalCustomer}
                />
            )}
        </Modal>
    );
};

export default Form.create<FormRentalProps>({})(FormRental);
