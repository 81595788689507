export const actionType = {
    insert: "INSERT_REQUIREMENT",
    setField: "SET_FIELD_REQUIREMENT",
    update: "UPDATE_REQUIREMENT",
    remove: "REMOVE_REQUIREMENT",
    select: "SELECT_ALL_REQUIREMENTS",
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
