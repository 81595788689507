import React from "react";
import moment from "moment";
import { Icon, Avatar } from "antd";

import content from "../Visualization/see";
import { Utilitaries } from "../../business";

const getLembreteVisualizationUsers = (item) => {
    let list = [];
    let users = Utilitaries.toString(item.lembrete_users);

    if (users) {
        list = users.split(", ").reduce((accumulator, currentValue) => {
            const user = currentValue.split(":");
            accumulator.push({
                username: user[0],
                estado: user[1],
            });
            return accumulator;
        }, []);
    }

    return list;
};

export const columnsLembrete = (seeMore, showVisualization) => [
    {
        title: "Descrição",
        dataIndex: "descricao",
        key: "descricao",
        sorter: (a, b) => a.descricao.toLowerCase() > b.descricao.toLowerCase(),
        ellipsis: true,
        render: (description, item) => (
            <span onClick={() => seeMore(item)} style={{ cursor: "pointer" }}>
                {item.read === "f" && item.owner !== "t" && (
                    <Avatar
                        size={10}
                        style={{
                            backgroundColor: "#000000",
                            marginRight: 8,
                        }}
                    />
                )}
                {description}
            </span>
        ),
    },
    {
        title: "Utilizadores",
        align: "center",
        key: "visto_por",
        render: (_, item) => (
            <div>{content(getLembreteVisualizationUsers(item), item.id_lembrete, showVisualization)}</div>
        ),
        width: 200,
    },
    {
        title: "Date / Hora",
        dataIndex: "data",
        key: "data",
        sorter: (a, b) => new Date(a.data) - new Date(b.data),
        width: 200,
    },
];

export const columnsLembreteFuturos = (seeMore, updateFunction, showVisualization) => [
    {
        title: "Descrição",
        dataIndex: "descricao",
        key: "descricao",
        ellipsis: true,
        sorter: (a, b) => a.descricao.toLowerCase() > b.descricao.toLowerCase(),
        render: (description, item) => (
            <span onClick={() => seeMore(item)} style={{ cursor: "pointer" }}>
                {item.read === "f" && item.owner !== "t" && (
                    <Avatar
                        size={10}
                        style={{
                            backgroundColor: "#000000",
                            marginRight: 8,
                        }}
                    />
                )}
                {description}
            </span>
        ),
    },
    {
        title: "Data",
        dataIndex: "data",
        key: "data",
        render: (data) => moment(data, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD"),
        sorter: (a, b) =>
            new Date(moment(a.data, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD")) -
            new Date(moment(b.data, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD")),
        width: 170,
    },
    {
        title: "Hora",
        dataIndex: "data",
        key: "hora",
        render: (_, item) => moment(item.data, "DD-MM-YYYY HH:mm").format("HH:mm"),
        width: 150,
    },
    {
        title: "Utilizadores",
        align: "center",
        key: "visto_por",
        render: (_, item) => (
            <div>{content(getLembreteVisualizationUsers(item), item.id_lembrete, showVisualization)}</div>
        ),
        width: 180,
    },
    {
        title: "Ação",
        render: (_, item) => <Icon onClick={() => updateFunction(item)} type="edit" />,
        width: 100,
    },
];
