const actionType = {
    CHANGE_MODAL_FORUM_STATE: "CHANGE_MODAL_FORUM_STATE",
    CHANGE_MODAL_FORUM_CONF_STATE: "CHANGE_MODAL_FORUM_CONF_STATE",
    SAVE_ROOMS: "SAVE_ROOMS",
    ROOMS: "FORUM_ROOMS",
    USER_ROOMS: "USER_ROOMS",
    TOPICS_PERMISSION: "TOPICS_PERMISSION",
    MESSAGES: "FORUM_MESSAGES",
    ADD_MESSAGES: "FORUM_ADD_MESSAGES",
};

export const themes = [
    {
        title: "Ciência",
        key: "science",
    },
    {
        title: "Economia",
        key: "economy",
    },
    {
        title: "Legislação",
        key: "legislation",
    },
    {
        title: "Notícias",
        key: "news",
    },
    {
        title: "Recursos Humanos",
        key: "rh",
    },
    {
        title: "Tecnologia",
        key: "tecnology",
    },
];

export default actionType;
