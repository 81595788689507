import React from "react";
import SpeedomerChart from "./speedometerChart";

const MultipleSpeedometer = ({ metadata, dataSource }) => {
    return (
        <>
            {dataSource.map((item) => {
                const element = {
                    title: metadata.title + " - " + item.label,
                    labelTotal: item.label,
                };
                return <SpeedomerChart metadata={element} dataSource={item.data} />;
            })}
        </>
    );
};

export default MultipleSpeedometer;
