import React, { Component } from "react";
import { Modal, Form, Row, Col, Input, Skeleton } from "antd";
import { connect } from "react-redux";
import { selectById as selectDynamicList } from "./../../../dynamic_list/actions/index";
import { getDetails } from "../../actions/qa.standart";
import ButtonDownloadDocument from "./../../../utilities/component/button.download.document";

class DetailsImportExport extends Component {
    componentDidMount() {
        this.setState({
            loadingData: true,
        });
        this.props.getDetails({ id: this.props.id }).then(() => {
            this.setState({
                loadingData: false,
            });
        });
    }
    state = {
        loading: false,
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title={"Detalhes"}
                visible={this.props.isVisible}
                onOk={this.handleOk}
                onCancel={this.props.handleCancel}
                maskClosable={false}
                width="90%"
                style={{ top: 20 }}
                footer={null}
            >
                <Form layout="vertical" onSubmit={this.props.handleOk}>
                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={6}>
                            <Skeleton active loading={this.state.loadingData} paragraph={{ rows: 1, width: "100%" }}>
                                <Form.Item label="Ação">
                                    {getFieldDecorator("acao", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(<Input style={{ width: "100%" }} disabled />)}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            <Skeleton active loading={this.state.loadingData} paragraph={{ rows: 1, width: "100%" }}>
                                <Form.Item label="Designação da norma">
                                    {getFieldDecorator("designacao", {
                                        initialValue: this.props.nome,
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(<Input style={{ width: "100%" }} disabled />)}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        {this.state.loadingData || typeof this.props.formNorma.ficheiro !== "object" ? (
                            <Col xs={24} md={6}>
                                <Skeleton
                                    active
                                    loading={this.state.loadingData}
                                    paragraph={{ rows: 1, width: "100%" }}
                                >
                                    <Form.Item label="&nbsp;">
                                        <ButtonDownloadDocument
                                            text={"Descarregar ficheiro"}
                                            ficheiro={this.props.formNorma.ficheiro}
                                        />
                                    </Form.Item>
                                </Skeleton>
                            </Col>
                        ) : null}
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    selectDynamicList,
    getDetails,
};

const mapStateToProps = (state) => {
    return {
        formNorma: state.qaStandarts.domain.formEdit,
        areaList: state.dynamicList.domain.byId.area_gestao,
    };
};

const formCreate = Form.create({
    mapPropsToFields(props) {
        return {
            acao: Form.createFormField({
                ...props.formNorma.acao,
                value: props.formNorma.acao.value,
            }),
            data: Form.createFormField({
                ...props.formNorma.data,
                value: props.formNorma.data.value,
            }),
            norma: Form.createFormField({
                ...props.formNorma.norma,
                value: props.formNorma.norma.value,
            }),
        };
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(formCreate(DetailsImportExport));
