import { Tabs } from "antd";
import React from "react";
import { FormExecution } from "../Components/FormExecution";
import {
    FormInputsObjectProps,
    FormInputsProps,
    FormRootElementAttributesProps,
    RenderContextType,
} from "../../../types/formTypes";
import { getChild } from "../../../utils/FormMapper/formHandle";
import { Group } from "../Components/Group";

interface Props {
    componentsProperties: FormInputsProps;
    formState: FormInputsObjectProps;
    renderContext: RenderContextType;
    justSee?: boolean;
    rootElementAttribute: FormRootElementAttributesProps;
}

export function QATab({ componentsProperties, formState, renderContext, justSee, rootElementAttribute }: Props) {
    const tabs = getChild({ parent: componentsProperties, formInputsObject: formState });

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <Tabs defaultActiveKey={`0`}>
                {tabs
                    .filter((tab) => {
                        if (tab.child_keys && tab.child_keys.length > 0) {
                            return true;
                        }
                        return false;
                    })
                    .map((tab, index) => {
                        const childs = getChild({ parent: tab, formInputsObject: formState });
                        const childsObject = childs.reduce((acc, child) => {
                            acc[child.key] = child;
                            return acc;
                        }, {} as FormInputsObjectProps);
                        // TODO: validar tab_relevante
                        return (
                            <Tabs.TabPane tab={tab.name} key={`${index}`}>
                                <FormExecution
                                    isProcess={false}
                                    isTab={true}
                                    form={childsObject}
                                    justSee={justSee}
                                    rootElementAttributes={rootElementAttribute}
                                />
                            </Tabs.TabPane>
                        );
                    })}
            </Tabs>
        </Group>
    );
}
