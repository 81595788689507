import { Col } from "antd";
import React, { useContext } from "react";

import { BASE_URL } from "../../../constants/enviroment";

import FormContext from "../context/FormContext";
import { QADivider } from "./";

const QaImage = ({
    inputAttribute,
    // value,
    field,
    // visible,
    // disabled,
    renderGroup,
    ...inputProps
}) => {
    // const [inputValue, setInputValue] = useState(null);

    const { formData } = useContext(FormContext);

    let valueDep = null;
    if (inputAttribute["@accao_muda_imagem"]) {
        valueDep = inputAttribute["@accao_muda_imagem"].replace(/\W/g, "");
    }

    const imageWidth =
        inputAttribute["@imagem"] && inputAttribute["@imagem"] === "/imagens/localizacoes.jpg"
            ? {
                  width: 646,
                  heighh: 225,
              }
            : { maxWidth: 200 };

    const RenderInput = () => (
        <img
            alt=""
            style={{ ...imageWidth }}
            src={
                valueDep && formData[valueDep] ? formData[valueDep] : BASE_URL + "/" + inputAttribute["@imagem"]
                /* (inputAttribute["@imagem"] === "imagens/logo_instituicao.jpg"
                          ? "imagens/logo_instituicao"
                          : inputAttribute["@imagem"])*/
            }
        />
    );

    const { inTable } = inputProps;

    return inTable ? (
        RenderInput()
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            <Col xs={8}>
                {/* <QAToolTip
					label={inputAttribute["@etiqueta"] || field.toPascalCase()}
					help={inputAttribute["@ajuda"]}
				/> */}
                {RenderInput()}
            </Col>
        </>
    );
};
export default QaImage;
