// import { uiComponent } from "../../../constants/index";

export const actionType = {
    insertProcess: "INSERT_PROCESS",
    getProcesses: "GET_PROCESSES",
    setField: "SET_FIELD",
    getProcessDetails: "GET_PROCESS_DETAILS",
    initProcessDefForm: "INIT_PROCESS_DEF_FORM",
};

export const componentActionType = {
    insertActionComponent: "INSERT_ACTION_COMPONENT",
    updateActionComponent: "UPDATE_ACTION_COMPONENT",
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};

export const componentType = {
    task: "TASK",
    paralelOperation: "PARALEL_OPERATION",
    decision: "DECISION",
    process: "PROCESS",
    group: "GROUP",
};

export const componentToRender = {
    normalForm: "NORMAL_FORM",
    advancedForm: "ADVANCED_FORM",
    pcForm: "PC_FORM",
    action: "ACTION",
    seeForm: "SEE_FORM",
    runSQL: "RUN_SQL",
    fieldProperties: "FIELD_PROPERTIES",
};

export const processType = {
    managment: "MANAGMENT",
    support: "SUPPORT",
    realization: "REALIZATION",
    measurement: "MEASURMENT",
};

// export const initialState = {
//   domain: {
//     form: {
//       ambito: { name: "", value: "" },
//       category: { name: "", value: "" },
//       relatedProcess: { name: "", value: "" },
//       nome: { name: "", value: "" },
//       organicUnit: { name: "", value: "" },
//       owner: { name: "", value: "" },
//       codigo_externo: { name: "", value: "" }
//     },
//     byId: {
//       // 1: {
//       //   key: 1,
//       //   id: 1,
//       //   nome: "Inscricao Cafap",
//       //   type: processType.realization,
//       //   relatedProcess: "",
//       //   scope: "",
//       //   organicUnit: "",
//       //   owner: "wac",
//       //   status: "ativo",
//       //   version: "1.0.0",
//       //   codigo_externo: "R01",
//       //   components: {
//       //     parallelOperations: [],
//       //     tasks: [
//       //       {
//       //         key: "task-1",
//       //         name: "Tarefa X",
//       //         position: 0,
//       //         scope: "",
//       //         aditionalInfo: "",
//       //         transitions: [
//       //           {
//       //             component: "end-node"
//       //           }
//       //         ],
//       //         actions: {
//       //           0: {
//       //             key: 0,
//       //             name: "Primeira accao"
//       //           }
//       //         }
//       //       }
//       //     ],
//       //     decisions: []
//       //   }
//       // },
//       // 2: {
//       //   key: 2,
//       //   id: 2,
//       //   name: "Elaboracao do PIAF",
//       //   type: processType.managment,
//       //   relatedProcess: "",
//       //   scope: "",
//       //   organicUnit: "",
//       //   owner: "wac",
//       //   status: "ativo",
//       //   version: "1.0.0",
//       //   codigo_externo: "G01",
//       //   components: {
//       //     parallelOperations: [],
//       //     tasks: [
//       //       {
//       //         key: "task-1",
//       //         name: "Registar funcionario",
//       //         scope: "",
//       //         position: 0,
//       //         aditionalInfo: "",
//       //         transitions: [
//       //           {
//       //             component: "task-2"
//       //           }
//       //         ],
//       //         actions: {
//       //           0: {
//       //             key: 0,
//       //             name: "Primeira accao"
//       //           }
//       //         }
//       //       },
//       //       {
//       //         key: "task-2",
//       //         name: "Atribuir autorizacao",
//       //         scope: "",
//       //         position: 1,
//       //         aditionalInfo: "",
//       //         transitions: [
//       //           {
//       //             component: "end-node"
//       //           }
//       //         ]
//       //       }
//       //     ],
//       //     decisions: []
//       //   }
//       // },
//       // 3: {
//       //   key: 3,
//       //   id: 3,
//       //   relatedProcess: "",
//       //   name: "Admissao ao W2N",
//       //   type: processType.support,
//       //   scope: "",
//       //   organicUnit: "",
//       //   owner: "wac",
//       //   status: "inativo",
//       //   version: "1.0.0",
//       //   codigo_externo: "S01",
//       //   components: {
//       //     process: [{}],
//       //     parallelOperations: [
//       //       {
//       //         key: "parallelOPeration-1",
//       //         position: 4,
//       //         transitions: [
//       //           {
//       //             component: "end-node"
//       //           }
//       //         ]
//       //       }
//       //     ],
//       //     tasks: [
//       //       {
//       //         key: "task-1",
//       //         name: "Tarefa A",
//       //         scope: "",
//       //         position: 0,
//       //         aditionalInfo: "",
//       //         transitions: [
//       //           {
//       //             component: "decision-0"
//       //           }
//       //         ],
//       //         actions: {
//       //           0: {
//       //             key: 0,
//       //             name: "Primeira accao"
//       //           }
//       //         }
//       //       },
//       //       {
//       //         key: "task-2",
//       //         name: "Tarefa B",
//       //         scope: "",
//       //         position: 3,
//       //         aditionalInfo: "",
//       //         transitions: [
//       //           {
//       //             component: "parallelOPeration-1"
//       //           }
//       //         ]
//       //       },
//       //       {
//       //         key: "task-3",
//       //         name: "Tarefa C",
//       //         scope: "",
//       //         position: 2,
//       //         aditionalInfo: "",
//       //         transitions: [
//       //           {
//       //             component: "parallelOPeration-1"
//       //           }
//       //         ]
//       //       }
//       //     ],
//       //     decisions: [
//       //       {
//       //         key: "decision-0",
//       //         name: "Decisao 1",
//       //         position: 1,
//       //         transitions: [
//       //           {
//       //             component: "task-2"
//       //           },
//       //           {
//       //             component: "task-3"
//       //           }
//       //         ]
//       //       }
//       //     ]
//       //   }
//       // },
//       4: {
//         key: 4,
//         id: 4,
//         nome: "Criação de questionário",
//         type: processType.support,
//         relatedProcess: "",
//         ambito: "Criação de questionário",
//         organicUnit: "",
//         dono: "wac",
//         estado: "ativo",
//         versao: "1.0.0",
//         codigo_externo: "S69",
//         parallelOperations: {},
//         tasks: {
//           1: {
//             key: "1",
//             name: "criar questionário",
//             position: 0,
//             scope: "criar questionário",
//             aditionalInfo: "",
//             transitions: [
//               {
//                 component: "end-node"
//               }
//             ],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.radio.advanced,
//                 name: "isToSelectExistedQuiz",
//                 title: "Selecionar questionário existente?",
//                 dataSource: [
//                   { key: true, value: true, text: "Sim" },
//                   { key: false, value: false, text: "Não" }
//                 ],
//                 conditions: {
//                   true: {
//                     key: "true",
//                     isSelected: false,
//                     childKeys: ["c2", "c3", "c4", "c5"]
//                   },
//                   false: {
//                     key: "false",
//                     isSelected: true,
//                     childKeys: []
//                   }
//                 },
//                 colSize: 8
//               },
//               c2: {
//                 key: "c2",
//                 isChild: true,
//                 uiComponent: uiComponent.divider.basic,
//                 name: "Dados do Questionário Existente"
//               },
//               c3: {
//                 key: "c3",
//                 name: "quizType",
//                 isChild: true,
//                 title: "Tipo de Questionário",
//                 uiComponent: uiComponent.select.basic,
//                 placeholder: "Selecione o tipo de Questionário",
//                 colSize: 8,
//                 dataSource: [
//                   "Cliente",
//                   "Colaborador",
//                   "Familiares",
//                   "Outro",
//                   "Comunidade",
//                   "Parceiro",
//                   "Financiador"
//                 ]
//               },
//               c4: {
//                 key: "c4",
//                 name: "quiz",
//                 isChild: true,
//                 title: "Questionário",
//                 uiComponent: uiComponent.select.basic,
//                 colSize: 8,
//                 placeholder: "Selecione o Questionário",
//                 dataSource: [
//                   "Cliente",
//                   "Colaborador",
//                   "Familiares",
//                   "Outro",
//                   "Comunidade",
//                   "Parceiro",
//                   "Financiador"
//                 ]
//               },
//               c5: {
//                 key: "c5",
//                 isChild: true,
//                 name: "Destinatários do Questionário",
//                 uiComponent: uiComponent.fixedList.basic,
//                 colSize: 8,
//                 placeholder: "Selecione o destinatário do Questionário",
//                 value: [
//                   "Cliente",
//                   "Colaborador",
//                   "Familiares",
//                   "Outro",
//                   "Comunidade",
//                   "Parceiro",
//                   "Financiador"
//                 ]
//               },
//               c6: {
//                 key: "c6",
//                 isChild: false,
//                 uiComponent: uiComponent.tab.basic,
//                 childKeys: ["c7", "c18"]
//               },
//               c7: {
//                 key: "c7",
//                 uiComponent: uiComponent.separator.basic,
//                 isChild: false,
//                 name: "Identificação",
//                 childKeys: ["c8", "c9", "c10", "c11", "c14", "c15", "c16"]
//               },
//               c8: {
//                 key: "c8",
//                 uiComponent: uiComponent.dynamicList.basic,
//                 name: "Tipo de questionário",
//                 isChild: true,
//                 colSize: 8,
//                 dataSource: [
//                   "Cliente",
//                   "Colaborador",
//                   "Familiares",
//                   "Outro",
//                   "Comunidade",
//                   "Parceiro",
//                   "Financiador"
//                 ],
//                 placeholder: "Selecione o tipo de questionário"
//               },
//               c9: {
//                 key: "c9",
//                 isChild: true,
//                 uiComponent: uiComponent.input.basic,
//                 colSize: 8,
//                 placeholder: "Introduza o nome do questionário",
//                 name: "Nome do Questionário"
//               },
//               c10: {
//                 key: "c10",
//                 isChild: true,
//                 uiComponent: uiComponent.fixedList.basic,
//                 name: "Destinatários do Questionário",
//                 placeholder: "Selecione os destinatários do Questionário",
//                 colSize: 8,
//                 value: [
//                   "Cliente",
//                   "Colaborador",
//                   "Familiares",
//                   "Outro",
//                   "Comunidade",
//                   "Parceiro",
//                   "Financiador"
//                 ]
//               },
//               c11: {
//                 key: "c11",
//                 isChild: true,
//                 uiComponent: uiComponent.radio.advanced,
//                 name: "quizHasFillInstructions",
//                 title: "O questionário tem instruções de preenchimento?",
//                 dataSource: [
//                   { key: true, value: true, text: "Sim" },
//                   { key: false, value: false, text: "Não" }
//                 ],
//                 conditions: {
//                   true: {
//                     key: "true",
//                     isSelected: false,
//                     childKeys: ["c12", "c13"]
//                   },
//                   false: {
//                     key: "false",
//                     isSelected: true,
//                     childKeys: []
//                   }
//                 }
//               },
//               c12: {
//                 key: "c12",
//                 title: "Instruções",
//                 name: "instructions",
//                 isChild: true,
//                 colSize: 8,
//                 uiComponent: uiComponent.input.textArea,
//                 placeholder: "Introduza as instruções",
//                 dataSource: {}
//               },
//               c13: {
//                 key: "c13",
//                 name: "isToShowQuizInstructionsTitle",
//                 isChild: true,
//                 title:
//                   "Mostrar título instruções de preenchimento no questionário?",
//                 colSize: 16,
//                 uiComponent: uiComponent.radio.basic
//               },
//               c14: {
//                 key: "c14",
//                 isChild: true,
//                 uiComponent: uiComponent.divider.basic,
//                 name: "Identificação do inquirido"
//               },
//               c15: {
//                 key: "c15",
//                 isChild: true,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: {
//                       label: "Questão de identificação",
//                       description:
//                         "Questão de identificação parcial do Questionário.Exemplo: Sexo, Idade."
//                     },
//                     dataIndex: "identificationQuestion",
//                     key: "identificationQuestion",
//                     type: uiComponent.input.basic,
//                     placeholder: "Indroduza a questão de identificação",
//                     width: "75%"
//                   },
//                   {
//                     title: "Respostas de identificação",
//                     dataIndex: "identificationAnswer",
//                     operation: "openModal",
//                     modal: "c16",
//                     key: "identificationAnswer",
//                     type: uiComponent.button.basic,
//                     icon: "edit",
//                     width: "25%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     identificationQuestion: "Mike",
//                     identificationAnswer: 2
//                   },
//                   2: {
//                     key: "2",
//                     identificationQuestion: "John",
//                     identificationAnswer: 2
//                   }
//                 }
//               },
//               c16: {
//                 key: "c16",
//                 isChild: true,
//                 uiComponent: uiComponent.modal.basic,
//                 name: "Respostas às perguntas de identificação",
//                 width: "80%",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c17"]
//               },
//               c17: {
//                 key: "c17",
//                 isChild: true,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     text: "Adicionar",
//                     type: "primary",
//                     operation: "add",
//                     icon: "plus"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: {
//                       label: "Resposta",
//                       description:
//                         "Definir as respostas possíveis para a questão selecionada e a respetiva ordem pela qual as respostas serão apresentadas(esta ordem é considerada dentro da questão selecionada)."
//                     },
//                     dataIndex: "answer",
//                     key: "answer",
//                     type: uiComponent.input.basic,
//                     placeholder: "Indroduza a resposta",
//                     width: "85%"
//                   },
//                   {
//                     title: "Nº de Ordem",
//                     dataIndex: "orderNumber",
//                     key: "orderNumber",
//                     type: uiComponent.input.number,
//                     placeholder: "nº de ordem",
//                     width: "15%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   2: {
//                     key: "2",
//                     name: "John",
//                     age: 42,
//                     address: "10 Downing Street"
//                   }
//                 }
//               },
//               c18: {
//                 key: "c18",
//                 isChild: false,
//                 uiComponent: uiComponent.separator.basic,
//                 name: "Escala",
//                 childKeys: ["c19", "c20"]
//               },
//               c19: {
//                 key: "c19",
//                 isChild: true,
//                 size: "middle",
//                 name: "Escalas utilizadas no questionário",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     text: "Adicionar",
//                     type: "primary",
//                     operation: "add",
//                     icon: "plus"
//                   }
//                 ],
//                 addButton: true,
//                 columns: [
//                   {
//                     title: {
//                       label: "Designação",
//                       description:
//                         "Definir as escalas que serão utilizadas no âmbito deste Questionário."
//                     },
//                     dataIndex: "designation",
//                     key: "designation",
//                     type: uiComponent.input.basic,
//                     placeholder: "Indroduza a designação",
//                     width: "95%"
//                   },
//                   {
//                     title: "Escala",
//                     dataIndex: "scale",
//                     key: "scale",
//                     type: uiComponent.button.basic,
//                     operation: "openModal",
//                     modal: "c20",
//                     icon: "edit",
//                     width: "5%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   2: {
//                     key: "2",
//                     name: "John",
//                     age: 42,
//                     address: "10 Downing Street"
//                   }
//                 }
//               },
//               c20: {
//                 key: "c20",
//                 uiComponent: uiComponent.modal.basic,
//                 isChild: true,
//                 name: "Escala do Questionário",
//                 width: "80%",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c21", "c22", "c23"]
//               },
//               c21: {
//                 key: "c21",
//                 uiComponent: uiComponent.radio.basic,
//                 name: "average",
//                 isChild: true,
//                 colSize: 8,
//                 title: {
//                   label: "Apresentar Média",
//                   description:
//                     "Mostra ou esconde a média da questão no relatório de tratamento de questionário. N.B. ao indicar que não pretende mostrar a média implicará que nos cálculos finais a questão também não será tida em conta."
//                 }
//               },
//               c22: {
//                 key: "c22",
//                 uiComponent: uiComponent.radio.basic,
//                 name: "standardDeviation",
//                 isChild: true,
//                 colSize: 8,
//                 title: {
//                   label: "Apresentar Desvio Padrão",
//                   description:
//                     "Mostra ou esconde o desvio padrão da questão no relatório de tratamento de questionário. N.B. ao indicar que não pretende mostrar o desvio padrão implicará que nos cálculos finais a questão também não será tida em conta."
//                 }
//               },
//               c23: {
//                 key: "c23",
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 colSize: 24,
//                 isChild: true,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     text: "Adicionar",
//                     type: "primary",
//                     operation: "add",
//                     icon: "plus"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Grau da escala",
//                     dataIndex: "scaleDegree",
//                     key: "scaleDegree",
//                     type: uiComponent.input.basic,
//                     placeholder: "Indroduza o grau da escala",
//                     width: "25%"
//                   },
//                   {
//                     title: "Abreviatura",
//                     dataIndex: "abbreviation",
//                     key: "abbreviation",
//                     type: uiComponent.input.basic,
//                     placeholder: "Introduza a abreviatura",
//                     width: "25%"
//                   },
//                   {
//                     title: "Valor",
//                     dataIndex: "value",
//                     key: "value",
//                     type: uiComponent.input.number,
//                     placeholder: "Introduza o valor",
//                     width: "25%"
//                   },
//                   {
//                     title: "Grau conta para Cálculos",
//                     dataIndex: "abbreviation",
//                     key: "abbreviation",
//                     type: uiComponent.radio.basic,
//                     width: "25%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   2: {
//                     key: "2",
//                     name: "John",
//                     age: 42,
//                     address: "10 Downing Street"
//                   }
//                 }
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           2: {
//             key: "2",
//             name: "definir questões",
//             position: 1,
//             scope: "definir questões",
//             aditionalInfo: "",
//             transitions: [
//               {
//                 component: "end-node"
//               }
//             ],
//             status: "todo",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Nome do Grupo",
//                     dataIndex: "groupName",
//                     key: "groupName",
//                     type: uiComponent.input.basic,
//                     placeholder: "Introduza o nome do grupo",
//                     width: "80%"
//                   },
//                   {
//                     title: "Nº de ordem",
//                     dataIndex: "orderNumber",
//                     key: "orderNumber",
//                     placeholder: "Nº de ordem",
//                     type: uiComponent.input.number,
//                     width: "15%"
//                   },
//                   {
//                     title: "Questões",
//                     operation: "openModal",
//                     modal: "c2",
//                     type: uiComponent.button.basic,
//                     dataIndex: "questions",
//                     icon: "edit",
//                     width: "5%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     groupName: "Grupo 1",
//                     orderNumber: 1
//                   },
//                   2: {
//                     key: "2",
//                     groupName: "Grupo 2",
//                     orderNumber: 2
//                   }
//                 }
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 uiComponent: uiComponent.modal.basic,
//                 width: "90%",
//                 heigth: "90%",
//                 name: "Definição de questões do questionário",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c3", "c4", "c5"]
//               },
//               c3: {
//                 key: "c3",
//                 isChild: true,
//                 uiComponent: uiComponent.input.textArea,
//                 scope: "Âmbito",
//                 title: "Âmbito",
//                 colSize: 12
//               },
//               c4: {
//                 key: "c4",
//                 isChild: true,
//                 uiComponent: uiComponent.table.topOperations,
//                 size: "small",
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Nº de ordem",
//                     dataIndex: "orderNumber",
//                     key: "orderNumber",
//                     type: uiComponent.input.number,
//                     placeholder: "Nº de ordem",
//                     width: "10%"
//                   },
//                   {
//                     title: "Tipo Questão",
//                     dataIndex: "questionType",
//                     key: "questionType",
//                     type: uiComponent.select.basic,
//                     dataSource: [
//                       "Questionario teste 1",
//                       "Questionario teste 2",
//                       "Questionario teste 3"
//                     ],
//                     placeholder: "Selecione um tipo de questão",
//                     width: "15%"
//                   },
//                   {
//                     title: "Questão",
//                     dataIndex: "question",
//                     key: "question",
//                     type: uiComponent.input.basic,
//                     placeholder: "Introduza uma questão",
//                     width: "15%"
//                   },
//                   {
//                     title: "Escala a utilizar",
//                     dataIndex: "scaleToUse",
//                     key: "scaleToUse",
//                     dataSource: [
//                       "Questionario teste 1",
//                       "Questionario teste 2",
//                       "Questionario teste 3"
//                     ],
//                     type: uiComponent.select.basic,
//                     placeholder: "Selecione uma escala",
//                     width: "15%"
//                   },
//                   {
//                     title: "Questões de resposta aberta",
//                     dataIndex: "openAnswer",
//                     key: "openAnswer",
//                     dataSource: [
//                       "Questionario teste 1",
//                       "Questionario teste 2",
//                       "Questionario teste 3"
//                     ],
//                     placeholder: "Selecione uma resposta",
//                     type: uiComponent.select.basic,
//                     width: "15%"
//                   },
//                   {
//                     title: "Questões respostas multiplas",
//                     dataIndex: "multipleAnswer",
//                     key: "multipleAnswer",
//                     type: uiComponent.button.basic,
//                     icon: "edit",
//                     width: "15%"
//                   },
//                   {
//                     title: "",
//                     width: "5%",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     orderNumber: 1,
//                     questionType: "",
//                     question: "Questao 1",
//                     scaleToUse: "",
//                     openAnswer: "",
//                     multipleAnswer: ""
//                   },
//                   2: {
//                     key: "2",
//                     orderNumber: 2,
//                     questionType: "",
//                     question: "Questao 2",
//                     scaleToUse: "",
//                     openAnswer: "",
//                     multipleAnswer: ""
//                   }
//                 }
//               },
//               c5: {
//                 key: "c5",
//                 isChild: true,
//                 uiComponent: uiComponent.modal.basic,
//                 name: "Respostas às perguntas de escolha múltipla",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c6"]
//               },
//               c6: {
//                 key: "c6",
//                 isChild: true,
//                 uiComponent: uiComponent.table.basic,
//                 size: "middle",
//                 name: "",
//                 operations: [
//                   { type: "primary", icon: "plus", text: "Adicionar" }
//                 ],
//                 columns: [
//                   {
//                     title: { label: "Resposta", description: "" },
//                     dataIndex: "answer",
//                     key: "answer",
//                     type: uiComponent.input.basic,
//                     placeholder: "Introduza a resposta",
//                     width: "60%"
//                   },
//                   {
//                     title: "Abreviatura",
//                     dataIndex: "abbreviation",
//                     key: "abbreviation",
//                     type: uiComponent.input.basic,
//                     placeholder: "abreviatura",
//                     width: "20%"
//                   },
//                   {
//                     title: "Nº de ordem",
//                     dataIndex: "orderNumber",
//                     key: "orderNumber",
//                     type: uiComponent.input.number,
//                     placeholder: "Nº de ordem",
//                     width: "20%"
//                   }
//                 ]
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           }
//         },
//         decisions: {}
//       },
//       5: {
//         key: 5,
//         id: 5,
//         nome: "Impressão de questionário",
//         type: processType.support,
//         relatedProcess: "",
//         ambito: "Impressão de questionário",
//         organicUnit: "",
//         dono: "wac",
//         estado: "ativo",
//         versao: "1.0.0",
//         codigo_externo: "S70",
//         parallelOperations: {},
//         tasks: {
//           1: {
//             key: "1",
//             name: "selecionar questionário",
//             position: 0,
//             scope: "selecionar questionário",
//             aditionalInfo: "",
//             transitions: [{ component: "end-node" }],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.dynamicList.basic,
//                 name: "Tipo de questionário",
//                 placeholder: "Selectione o tipo de questionário",
//                 colSize: 8,
//                 dataSource: [
//                   "Avaliação das necessidades e expectativas",
//                   "Avaliação da satisfação",
//                   "QUESTIONÁRIO DE AVALIAÇÃO DE EVENTOS"
//                 ]
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 uiComponent: uiComponent.fixedList.basic,
//                 name: "Destinatários Questionário",
//                 placeholder: "Selecione os destinatários do questionário",
//                 colSize: 8,
//                 value: [
//                   "Cliente",
//                   "Colaborador",
//                   "Familiares",
//                   "Outro",
//                   "Comunidade",
//                   "Parceiro",
//                   "Financiador"
//                 ]
//               },
//               c3: {
//                 key: "c3",
//                 isChild: false,
//                 uiComponent: uiComponent.select.basic,
//                 name: "quiz",
//                 title: "Questionário",
//                 colSize: 8,
//                 placeholder: "Selecione o questionário",
//                 dataSource: [
//                   "QUESTIONÁRIO DE AVALIAÇÃO DE EVENTOS",
//                   "QA - Avaliação da Satisfação 2012 - Financiadores"
//                 ]
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           }
//         },
//         decisions: {}
//       },
//       6: {
//         key: 6,
//         id: 6,
//         nome: "Abertura de inquérito",
//         type: processType.support,
//         relatedProcess: "",
//         ambito: "Abertura de inquérito",
//         organicUnit: "",
//         dono: "wac",
//         estado: "ativo",
//         versao: "1.0.0",
//         codigo_externo: "S91",
//         parallelOperations: {},
//         tasks: {
//           1: {
//             key: "1",
//             name: "abrir inquérito",
//             position: 0,
//             scope: "abrir inquérito",
//             aditionalInfo: "",
//             transitions: [
//               {
//                 component: ""
//               }
//             ],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.date.range,
//                 name: "collectionRangeDate",
//                 colSize: 8,
//                 title: {
//                   label: "Período de recolha dados",
//                   description:
//                     "Definir período de recolha de dados do inquérito. Em caso de necessidade de prolongamento do prazo a tarefa poderá ser corrigida. Em recolhas diferentes (p.e. anos diferentes) não pode ser utilizada uma tarefa que já tenha sido utilizada pois os dados serão adicionados aos já existentes. Nesse caso deve ser iniciado no processo e não corrigido períodos de recolha."
//                 },
//                 placeholder: ["Data de início", "Data de fim"]
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 uiComponent: uiComponent.tab.basic,
//                 childKeys: ["c20", "c21", "c22"]
//               },
//               c20: {
//                 key: "c20",
//                 uiComponent: uiComponent.separator.basic,
//                 isChild: false,
//                 name: "Dados de inquérito",
//                 childKeys: [
//                   "c3",
//                   "c4",
//                   "c5",
//                   "c6",
//                   "c7",
//                   "c8",
//                   "c9",
//                   "c10",
//                   "c11"
//                 ]
//               },
//               c3: {
//                 key: "c3",
//                 isChild: true,
//                 uiComponent: uiComponent.input.basic,
//                 colSize: 8,
//                 placeholder: "Introduza a designação de inquérito",
//                 name: "Designação de inquérito"
//               },
//               c4: {
//                 key: "c4",
//                 isChild: true,
//                 uiComponent: uiComponent.dynamicList.basic,
//                 colSize: 8,
//                 placeholder: "Selecione o tipo de inquérito",
//                 name: "Tipo de inquérito",
//                 dataSource: [
//                   "QUESTIONÁRIO DE AVALIAÇÃO DE EVENTOS",
//                   "QUESTIONÁRIO DE CONSULTA AOS TRABALHADORES",
//                   "Avaliação de outra coisa",
//                   "Avaliação da satisfação"
//                 ]
//               },
//               c5: {
//                 key: "c5",
//                 isChild: true,
//                 name: "Destinatários da avaliação",
//                 uiComponent: uiComponent.fixedList.basic,
//                 colSize: 8,
//                 placeholder: "Selecione os destinatários da avaliação",
//                 value: [
//                   "Comunidade",
//                   "Colaborador",
//                   "Familiares",
//                   "Outro",
//                   "Comunidade",
//                   "Parceiro",
//                   "Financiador"
//                 ]
//               },
//               c6: {
//                 key: "c6",
//                 isChild: true,
//                 uiComponent: uiComponent.select.basic,
//                 name: "quizToApply",
//                 title: "Questionário a aplicar",
//                 placeholder: "Selectione o questionário a aplicar",
//                 colSize: 8,
//                 dataSource: [
//                   "Inquérito de avaliação de clientes preenchida na QA",
//                   "Avaliação de satisfação 2017r",
//                   "SEGURANÇA E SAÚDE NO TRABALHO- NECESSIDADES DE FORMAÇÃO",
//                   "Satisfação de colaboradores de CAO - 2018"
//                 ]
//               },
//               c7: {
//                 key: "c7",
//                 isChild: true,
//                 title: "Filtrar por unidade orgânica",
//                 name: "socialAnseerFilter",
//                 colSize: 8,
//                 uiComponent: uiComponent.radio.basic
//               },
//               c8: {
//                 key: "c8",
//                 isChild: true,
//                 title: "Avaliação de necessidades e expectativas",
//                 name: "needsExpectationsMeasure",
//                 colSize: 8,
//                 uiComponent: uiComponent.radio.basic
//               },
//               c9: {
//                 key: "c9",
//                 isChild: true,
//                 uiComponent: uiComponent.divider.basic,
//                 name: "Questões de identificação adicionais"
//               },
//               c10: {
//                 key: "c10",
//                 isChild: true,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: {
//                       label: "Questão",
//                       description: ""
//                     },
//                     dataIndex: "question",
//                     key: "question",
//                     type: uiComponent.input.basic,
//                     placeholder: "Indroduza uma questão de identificação",
//                     width: "75%"
//                   },
//                   {
//                     title: "Possibilidade de resposta",
//                     dataIndex: "identificationAnswer",
//                     operation: "openModal",
//                     modal: "c11",
//                     key: "identificationAnswer",
//                     type: uiComponent.button.basic,
//                     icon: "edit",
//                     width: "25%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   2: {
//                     key: "2",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   3: {
//                     key: "3",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   }
//                 }
//               },
//               c11: {
//                 key: "c11",
//                 isChild: true,
//                 uiComponent: uiComponent.modal.basic,
//                 name: "Respostas possíveis",
//                 width: "80%",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c12"]
//               },
//               c12: {
//                 key: "c12",
//                 isChild: true,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     text: "Adicionar",
//                     type: "primary",
//                     operation: "add",
//                     icon: "plus"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: {
//                       label: "Resposta",
//                       description:
//                         "Definir as respostas possíveis para a questão selecionada e a respetiva ordem pela qual as respostas serão apresentadas(esta ordem é considerada dentro da questão selecionada)."
//                     },
//                     dataIndex: "answer",
//                     key: "answer",
//                     type: uiComponent.input.basic,
//                     placeholder: "Indroduza a resposta",
//                     width: "75%"
//                   },
//                   {
//                     title: "Nº de Ordem",
//                     dataIndex: "orderNumber",
//                     key: "orderNumber",
//                     type: uiComponent.input.number,
//                     placeholder: "nº de ordem",
//                     width: "15%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   2: {
//                     key: "2",
//                     name: "John",
//                     age: 42,
//                     address: "10 Downing Street"
//                   }
//                 }
//               },
//               c21: {
//                 key: "c21",
//                 uiComponent: uiComponent.separator.basic,
//                 isChild: false,
//                 name: "Público-alvo",
//                 childKeys: ["c23", "c18"]
//               },
//               c23: {
//                 uiComponent: uiComponent.select.conditional,
//                 isChild: true,
//                 order: 1,
//                 key: "c23",
//                 name: "Metodologia da pesquisa",
//                 placeholder: "Selecione uma metodologia da pesquisa",
//                 colSize: 8,
//                 dataSource: ["universo", "amostra"],
//                 conditions: {
//                   universo: {
//                     key: "universo",
//                     isSelected: true,
//                     childKeys: []
//                   },
//                   amostra: {
//                     key: "amostra",
//                     isSelected: false,
//                     childKeys: ["c32", "c13", "c14", "c15", "c16", "c17"]
//                   }
//                 }
//               },
//               c18: {
//                 key: "c18",
//                 order: 2,
//                 isChild: true,
//                 uiComponent: uiComponent.select.basic,
//                 name: "collectionDataTechnique",
//                 title: "Técnica de recolha de dados",
//                 placeholder: "Selecione a técnica de recolha de dados",
//                 colSize: 8,
//                 dataSource: ["Inquérito", "Entrevista"]
//               },
//               c32: {
//                 key: "c32",
//                 isChild: true,
//                 uiComponent: uiComponent.select.basic,
//                 name: "samplingTechnique",
//                 title: "Técnica de amostragem",
//                 dataSource: [
//                   "Amostragem aleatória simples",
//                   "Amostragem selecionada"
//                 ],
//                 placeholder: "Selecione a técnica de amostragem",
//                 colSize: 8
//               },
//               c13: {
//                 key: "c13",
//                 isChild: true,
//                 title: "Escolha uma opção",
//                 name: "option",
//                 colSize: 8,
//                 dataSource: [
//                   {
//                     key: "personalNumber",
//                     value: "personalNumber",
//                     text: "Numero de pessoas"
//                   },
//                   {
//                     key: "percentage",
//                     value: "percentage",
//                     text: "Percentagem"
//                   }
//                 ],
//                 uiComponent: uiComponent.radio.custom
//               },
//               c14: {
//                 key: "c14",
//                 isChild: true,
//                 uiComponent: uiComponent.input.number,
//                 name: "quantity",
//                 title: "Quantidade",
//                 placeholder: "Introduza a quantidade",
//                 colSize: 8
//               },
//               c15: {
//                 key: "c15",
//                 isChild: true,
//                 title: {
//                   label: "Gerar listagem",
//                   description:
//                     "Gerar uma listagem aleatória, com sugestão de clientes, colaboradores ou fornecedores a ser inquiridos, com base nas escolhas selecionadas. Esta listagem poderá servir para a distribuição de inquéritos em papel por amostragem. Para os inquéritos preenchidos na aplicação é gerada a listagem quer seja mostrada quer não. Apenas as pessoas previstas nesta listagem terão o questionário disponível para preenchimento."
//                 },
//                 name: "option",
//                 colSize: 8,
//                 uiComponent: uiComponent.radio.basic
//               },
//               c16: {
//                 key: "c16",
//                 isChild: true,
//                 uiComponent: uiComponent.divider.basic,
//                 name: "Filtros"
//               },
//               c17: {
//                 key: "c17",
//                 isChild: true,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: {
//                       label: "Filtro",
//                       description:
//                         "Definir os filtros para a lista de pessoas a gerar. Os filtros são aplicados comulativamente. Os filtros não são uma lista dinâmica."
//                     },
//                     dataIndex: "filter",
//                     key: "filter",
//                     type: uiComponent.select.basic,
//                     dataSource: ["Ano de parceria", "Curso", "Resposta social"],
//                     placeholder: "Selecione um filtro",
//                     width: "30%"
//                   },
//                   {
//                     title: "Comparador",
//                     dataIndex: "comparator",
//                     key: "comparator",
//                     type: uiComponent.select.basic,
//                     dataSource: [],
//                     placeholder: "Selecione um comparador",
//                     width: "30%"
//                   },
//                   {
//                     title: "Valor",
//                     dataIndex: "value",
//                     key: "value",
//                     type: uiComponent.select.basic,
//                     dataSource: [],
//                     placeholder: "Selecione um valor",
//                     width: "30%"
//                   },
//                   {
//                     title: "",
//                     width: "10%",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   2: {
//                     key: "2",
//                     name: "John",
//                     age: 42,
//                     address: "10 Downing Street"
//                   }
//                 }
//               },
//               c22: {
//                 key: "c22",
//                 uiComponent: uiComponent.separator.basic,
//                 isChild: false,
//                 name: "Preenchimento",
//                 childKeys: ["c19"]
//               },
//               c19: {
//                 key: "c19",
//                 isChild: true,
//                 title: {
//                   label: "Método de introdução dos dados",
//                   description: ""
//                 },
//                 name: "dataEntryMethod",
//                 colSize: 16,
//                 dataSource: {
//                   paperFill: {
//                     key: "paperFill",
//                     value: "paperFill",
//                     text: "Preenchimento no papel"
//                   },
//                   applicationFill: {
//                     key: "applicationFill",
//                     value: "applicationFill",
//                     text: "Preenchimento na aplicação"
//                   },
//                   both: {
//                     key: "both",
//                     value: "both",
//                     text: "Ambos"
//                   }
//                 },
//                 uiComponent: uiComponent.radio.customAdvanced,
//                 conditions: {
//                   paperFill: {
//                     key: "paperFill",
//                     isSelected: true,
//                     childKeys: ["c33"]
//                   },
//                   applicationFill: {
//                     key: "applicationFill",
//                     isSelected: false,
//                     childKeys: ["c34"]
//                   },
//                   both: {
//                     key: "both",
//                     isSelected: false,
//                     childKeys: ["c35", "c36"]
//                   }
//                 }
//               },
//               c36: {
//                 key: "c36",
//                 uiComponent: uiComponent.select.basic,
//                 name: "employee",
//                 title: {
//                   label: "Funcionário",
//                   description:
//                     "Definir quais os funcionários responsáveis pela introdução dos questionários na aplicação. O questionário só estará disponível aos funcionários definidos neste conjunto."
//                 },
//                 placeholder: "Selectione o funcionário",
//                 colSize: 8,
//                 dataSource: [
//                   "Wagner Centeio",
//                   "Wilson Varela",
//                   "Nataniel Pina",
//                   "Ivo Domingues"
//                 ]
//               },
//               c35: {
//                 key: "c35",
//                 title: {
//                   label:
//                     "Enviar email aos destinatários a pedir resposta ao questionário",
//                   description:
//                     "Serão enviados emails para os destinatários que tiverem email configurado na aplicação. Esta opção apenas se aplica no caso dos inquéritos serem preenchidos diretamente na aplicação."
//                 },
//                 colSize: 12,
//                 uiComponent: uiComponent.radio.basic
//               },

//               c34: {
//                 key: "c34",
//                 title: {
//                   label:
//                     "Enviar email aos destinatários a pedir resposta ao questionário",
//                   description:
//                     "Serão enviados emails para os destinatários que tiverem email configurado na aplicação. Esta opção apenas se aplica no caso dos inquéritos serem preenchidos diretamente na aplicação."
//                 },
//                 colSize: 8,
//                 uiComponent: uiComponent.radio.basic
//               },
//               c33: {
//                 key: "c33",
//                 isChild: true,
//                 uiComponent: uiComponent.select.basic,
//                 name: "employee",
//                 title: {
//                   label: "Funcionário",
//                   description:
//                     "Definir quais os funcionários responsáveis pela introdução dos questionários na aplicação. O questionário só estará disponível aos funcionários definidos neste conjunto."
//                 },
//                 placeholder: "Selectione o funcionário",
//                 colSize: 8,
//                 dataSource: [
//                   "Wagner Centeio",
//                   "Wilson Varela",
//                   "Nataniel Pina",
//                   "Ivo Domingues"
//                 ]
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           2: {
//             key: "2",
//             name: "definir obrigatórios",
//             position: 1,
//             scope: "definir obrigatórios",
//             aditionalInfo: "",
//             transitions: [
//               {
//                 component: ""
//               }
//             ],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.radio.basic,
//                 name: "isAllQuestionsRequired",
//                 colSize: 8,
//                 title: "Definir todas as questões como obrigatórias"
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 colSize: 24,
//                 operations: [
//                   {
//                     type: "primary",
//                     opType: uiComponent.tableOperation.add,
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: { label: "Questão", description: "" },
//                     dataIndex: "question",
//                     key: "question",
//                     type: uiComponent.select.basic,
//                     dataSource: ["Grupo 1 - questão 1", "Grupo 1 - questão 2"],
//                     placeholder: "Selecione a questão",
//                     width: "30%"
//                   },
//                   {
//                     title: { label: "Condicional", decription: "" },
//                     dataSource: ["condição 1", "condição 2"],
//                     dataIndex: "conditional",
//                     key: "conditional",
//                     type: uiComponent.select.basic,
//                     placeholder: "Selecione a condição",
//                     width: "30%"
//                   },
//                   {
//                     title: { label: "Resposta condicional", description: "" },
//                     dataSource: [
//                       "Resposta condicional 1",
//                       "Resposta condicional 2"
//                     ],
//                     placeholder: "Selecione a resposta condicional",
//                     dataIndex: "condicionalAnswer",
//                     key: "condicionalAnswer",
//                     type: uiComponent.select.basic,
//                     width: "30%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   2: {
//                     key: "2",
//                     name: "John",
//                     age: 42,
//                     address: "10 Downing Street"
//                   }
//                 }
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           3: {
//             key: "3",
//             name: "selecionar pessoas",
//             position: 2,
//             scope: "selecionar pessoas",
//             aditionalInfo: "",
//             transitions: [
//               {
//                 component: "end-node"
//               }
//             ],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 size: "middle",
//                 uiComponent: uiComponent.table.selection,
//                 columns: [
//                   {
//                     title: "Foto",
//                     dataIndex: "picture",
//                     key: "picture",
//                     src: "",
//                     type: uiComponent.dataDisplay.picture,
//                     width: "20%"
//                   },
//                   {
//                     title: "Nome",
//                     dataIndex: "name",
//                     key: "name",
//                     width: "40%"
//                   },
//                   {
//                     title: "Variável diferenciadora",
//                     dataIndex: "differentiatingVariable",
//                     key: "differentiatingVariable",
//                     width: "40%"
//                   }
//                 ],
//                 dataSource: [
//                   { name: "Ivo Domingues", differentiatingVariable: "teste" },
//                   { name: "Nataniel Pina", differentiatingVariable: "teste" },
//                   {
//                     name: "Wagner Centeio",
//                     differentiatingVariable: "teste"
//                   },
//                   { name: "Wilson Varela", differentiatingVariable: "teste" }
//                 ]
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           }
//         },
//         decisions: {}
//       },
//       7: {
//         key: 7,
//         id: 7,
//         nome: "Preenchimento de questionário",
//         type: processType.support,
//         relatedProcess: "",
//         ambito: "Preenchimento de questionário",
//         organicUnit: "",
//         dono: "wac",
//         estado: "ativo",
//         versao: "1.0.0",
//         codigo_externo: "S92",
//         parallelOperations: {},
//         tasks: {
//           1: {
//             key: "1",
//             name: "preencher questionário",
//             position: 0,
//             scope: "preencher questionário",
//             aditionalInfo: "",
//             transitions: [{ component: "end-node" }],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.select.basic,
//                 name: "inquiry",
//                 title: "Inquérito",
//                 placeholder: "Selecione o inquérito",
//                 colSize: 8,
//                 dataSource: [
//                   "Inquérito de avaliação de clientes preenchida na QA",
//                   "Avaliação de satisfação 2017r",
//                   "SEGURANÇA E SAÚDE NO TRABALHO- NECESSIDADES DE FORMAÇÃO",
//                   "Satisfação de colaboradores de CAO - 2018"
//                 ]
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 uiComponent: uiComponent.divider.basic,
//                 name: "Preencher questionário"
//               },
//               c3: {
//                 key: "c3",
//                 isChild: false,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 colSize: 24,
//                 operations: [
//                   { type: "primary", icon: "plus", text: "Adicionar" }
//                 ],
//                 operationsInfo: [
//                   {
//                     title: "Nº total de selecionados",
//                     value: 2,
//                     icon: "check"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Resposta Social",
//                     dataIndex: "socialAnswer",
//                     key: "socialAnswer",
//                     type: uiComponent.input.readonly,
//                     placeholder: "",
//                     width: "95%"
//                   },
//                   {
//                     title: "Questionário",
//                     dataIndex: "quiz",
//                     operation: "openModal",
//                     modal: "",
//                     key: "quiz",
//                     type: uiComponent.button.basic,
//                     icon: "edit",
//                     width: "5%"
//                   },
//                   {
//                     title: "",
//                     type: "actions",
//                     child: [{ title: "Remover" }]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   2: {
//                     key: "2",
//                     name: "John",
//                     age: 42,
//                     address: "10 Downing Street"
//                   }
//                 }
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           }
//         },
//         decisions: {}
//       },
//       8: {
//         key: 8,
//         id: 8,
//         nome: "Tratamento de dados",
//         type: processType.support,
//         relatedProcess: "",
//         ambito: "Tratamento de dados",
//         organicUnit: "",
//         dono: "wac",
//         estado: "ativo",
//         versao: "1.0.0",
//         codigo_externo: "M16",
//         parallelOperations: {},
//         tasks: {
//           1: {
//             key: 1,
//             name: "indicar inquérito",
//             position: 0,
//             scope: "indicar inquérito",
//             aditionalInfo: "",
//             transitions: [],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.select.basic,
//                 title: "Inquérito",
//                 colSize: 8,
//                 placeholder: "Selecione o inquérito",
//                 name: "inquiry",
//                 dataSource: ["Inquerito 1", "Inquerito 2"]
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 uiComponent: uiComponent.date.range,
//                 name: "collectionRangeDate",
//                 colSize: 8,
//                 title: "Período de recolha dados",
//                 placeholder: ["Data de início", "Data de fim"]
//               },
//               c3: {
//                 key: "c3",
//                 isChild: false,
//                 uiComponent: uiComponent.input.number,
//                 title: {
//                   label: "Nº de questionários preenchidos",
//                   descrition: ""
//                 },
//                 name: "filledQuizCount",
//                 placeholder: "Nº de questionários",
//                 colSize: 8
//               },
//               c4: {
//                 key: "c4",
//                 isChild: false,
//                 uiComponent: uiComponent.input.number,
//                 title: {
//                   label: "Nº de questionários inseridos",
//                   descrition: ""
//                 },
//                 name: "insertedQuizCount",
//                 placeholder: "Nº de questionários inseridos",
//                 colSize: 8
//               },
//               c5: {
//                 key: "c5",
//                 isChild: false,
//                 uiComponent: uiComponent.table.basic,
//                 size: "middle",
//                 name: "",
//                 operations: [
//                   { type: "primary", icon: "plus", text: "Adicionar" }
//                 ],
//                 columns: [
//                   {
//                     title: "Processo",
//                     dataIndex: "process",
//                     key: "process",
//                     type: uiComponent.input.basic,
//                     placeholder: "Introduza a resposta",
//                     width: "20%"
//                   },
//                   {
//                     title: "Nome",
//                     dataIndex: "name",
//                     key: "name",
//                     type: uiComponent.input.basic,
//                     placeholder: "",
//                     width: "20%"
//                   },
//                   {
//                     title: "Fim da tarefa",
//                     dataIndex: "endTask",
//                     key: "endTask",
//                     type: uiComponent.input.basic,
//                     placeholder: "",
//                     width: "20%"
//                   },
//                   {
//                     title: "Anulado",
//                     dataIndex: "canceled",
//                     key: "canceled",
//                     type: uiComponent.input.basic,
//                     placeholder: "",
//                     width: "20%"
//                   },
//                   {
//                     title: "Nº de inq. inseridos",
//                     dataIndex: "canceled",
//                     key: "canceled",
//                     type: uiComponent.input.basic,
//                     placeholder: "",
//                     width: "20%"
//                   }
//                 ],
//                 dataSource: []
//               },
//               c6: {
//                 key: "c6",
//                 isChild: false,
//                 colSize: 8,
//                 uiComponent: uiComponent.radio.advanced,
//                 name: "deletedQuizes",
//                 title: "Questionários eliminados?",
//                 dataSource: [
//                   { key: true, value: true, text: "Sim" },
//                   { key: false, value: false, text: "Não" }
//                 ],
//                 conditions: {
//                   true: {
//                     key: "true",
//                     isChild: false,
//                     isSelected: false,
//                     childKeys: ["c7", "c8", "c9"]
//                   },
//                   false: {
//                     key: "false",
//                     isSelected: true,
//                     childKeys: []
//                   }
//                 }
//               },
//               c7: {
//                 key: "c7",
//                 isChild: true,
//                 uiComponent: uiComponent.input.number,
//                 title: {
//                   label: "Nº de questionários eliminados",
//                   descrition: ""
//                 },
//                 name: "personalData",
//                 placeholder: "Nº de questionários eliminados",
//                 colSize: 8
//               },
//               c8: {
//                 key: "c8",
//                 isChild: true,
//                 uiComponent: uiComponent.input.number,
//                 title: {
//                   label: "Dados pessoais não registados",
//                   descrition: ""
//                 },
//                 name: "personalData",
//                 placeholder: "Nº de dados pessoais não registados",
//                 colSize: 8
//               },
//               c9: {
//                 key: "c9",
//                 isChild: true,
//                 uiComponent: uiComponent.input.number,
//                 title: {
//                   label: "Respostas omissas",
//                   descrition: ""
//                 },
//                 name: "filledQuizCount",
//                 placeholder: "Nº de respostas omissas",
//                 colSize: 8
//               },
//               c10: {
//                 key: "c10",
//                 isChild: false,
//                 uiComponent: uiComponent.dataDisplay.basic,
//                 title: "Nº de questionários validados",
//                 value: 0,
//                 colSize: 8
//               },
//               c11: {
//                 key: "c11",
//                 isChild: false,
//                 uiComponent: uiComponent.dataDisplay.basic,
//                 title: "Taxa de aproveitamento de questionários (%)",
//                 value: 0,
//                 colSize: 8
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           2: {
//             key: 2,
//             name: "medir necessidades",
//             position: 1,
//             scope: "medir necessidades",
//             aditionalInfo: "",
//             transitions: [],
//             status: "doing",
//             page: {
//               c1: {
//                 uiComponent: uiComponent.table.basic,
//                 isChild: false,
//                 key: "c1",
//                 size: "middle",
//                 columns: [
//                   {
//                     title: "Grupo",
//                     dataIndex: "group",
//                     key: "group",
//                     type: uiComponent.dataDisplay.basic,
//                     width: "20%"
//                   },
//                   {
//                     title: "Questão",
//                     dataIndex: "question",
//                     key: "question",
//                     type: uiComponent.dataDisplay.basic,
//                     width: "20%"
//                   },
//                   {
//                     title: "Clientes",
//                     dataIndex: "customers",
//                     key: "customers",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Significativos",
//                     dataIndex: "significant",
//                     key: "significant",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Funcionários",
//                     dataIndex: "employees",
//                     key: "employees",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Parceiros",
//                     dataIndex: "partners",
//                     key: "partners",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Fornecedores",
//                     dataIndex: "suppliers",
//                     key: "suppliers",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Financiadores",
//                     dataIndex: "financiers",
//                     key: "financiers",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   }
//                 ],
//                 dataSource: [
//                   { question: "Question 1", group: "gp1" },
//                   { question: "Question 2", group: "gp2" }
//                 ]
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           3: {
//             key: 3,
//             name: "medir expectativas",
//             position: 2,
//             scope: "medir expectativas",
//             aditionalInfo: "",
//             transitions: [],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.table.basic,
//                 size: "middle",
//                 columns: [
//                   {
//                     title: "Grupo",
//                     dataIndex: "group",
//                     key: "group",
//                     type: uiComponent.dataDisplay.basic,
//                     width: "20%"
//                   },
//                   {
//                     title: "Questão",
//                     dataIndex: "question",
//                     key: "question",
//                     type: uiComponent.dataDisplay.basic,
//                     width: "20%"
//                   },
//                   {
//                     title: "Clientes",
//                     dataIndex: "customers",
//                     key: "customers",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Significativos",
//                     dataIndex: "significant",
//                     key: "significant",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Funcionários",
//                     dataIndex: "employees",
//                     key: "employees",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Parceiros",
//                     dataIndex: "partners",
//                     key: "partners",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Fornecedores",
//                     dataIndex: "suppliers",
//                     key: "suppliers",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   },
//                   {
//                     title: "Financiadores",
//                     dataIndex: "financiers",
//                     key: "financiers",
//                     type: uiComponent.radio.basicSmall,
//                     width: "11%"
//                   }
//                 ],
//                 dataSource: [
//                   { question: "Question 1", group: "gp1" },
//                   { question: "Question 2", group: "gp2" }
//                 ]
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           4: {
//             key: 4,
//             name: "resultados",
//             position: 3,
//             scope: "resultados",
//             aditionalInfo: "",
//             transitions: [],
//             status: "doing",
//             page: {
//               //TODO: find more information about this field
//               c1: {
//                 key: "c1",
//                 colSize: 8,
//                 isChild: false,
//                 uiComponent: uiComponent.dataDisplay.docs,
//                 title:
//                   "Consulte o relatório com os resultados do questionário antes de preencher este registo"
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 colSize: 8,
//                 uiComponent: uiComponent.radio.basic,
//                 title: "Ações de melhoria"
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           }
//         },
//         decisions: {}
//       },
//       9: {
//         key: 9,
//         id: 9,
//         nome: "Bolsa de auditores",
//         type: processType.support,
//         relatedProcess: "",
//         ambito: "Bolsa de auditores",
//         organicUnit: "",
//         dono: "wac",
//         estado: "ativo",
//         versao: "1.0.0",
//         codigo_externo: "S102",
//         parallelOperations: {},
//         tasks: {
//           1: {
//             key: "1",
//             name: "criar bolsa de auditores",
//             position: 0,
//             scope: "criar bolsa de auditores",
//             aditionalInfo: "",
//             transitions: [{ component: "end-node" }],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 colSize: 24,
//                 uiComponent: uiComponent.alert.basic,
//                 title: "Qualificações mínimas dos auditores da qualidade",
//                 description:
//                   "Ter experiência na implementação de SGQ; Possuir a escolaridade mínima obrigatória (9º ano de escolaridade); Possuir o curso de 'Auditorias da Qualidade', com pelo menos 40h de duração."
//               },
//               c2: {
//                 uiComponent: uiComponent.divider.basic,
//                 isChild: false,
//                 name: "Lista de auditores da qualidade qualificados"
//               },
//               c3: {
//                 key: "c3",
//                 isChild: false,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     type: uiComponent.radio.custom,
//                     width: "10%",
//                     isChild: true,
//                     title: "Tipo de auditor",
//                     key: "auditorType",
//                     dataIndex: "auditorType",
//                     dataSource: {
//                       internalAuditor: {
//                         key: "internalAuditor",
//                         value: "internalAuditor",
//                         text: "Auditor interno"
//                       },
//                       externalAuditor: {
//                         key: "externalAuditor",
//                         value: "externalAuditor",
//                         text: "Auditor externo"
//                       }
//                     },
//                     conditions: {
//                       internalAuditor: {
//                         key: "internalAuditor",
//                         value: "internalAuditor",
//                         text: "Auditor interno"
//                       },
//                       externalAuditor: {
//                         key: "externalAuditor",
//                         value: "externalAuditor",
//                         text: "Auditor externo"
//                       }
//                     }
//                   },
//                   {
//                     title: "Grupo funcional",
//                     dataIndex: "functionalGroup",
//                     key: "functionalGroup",
//                     placeholder: "Selecione um grupo",
//                     type: uiComponent.select.basic,
//                     dataSource: ["teste 1", "teste 2", "teste 3"],
//                     width: "25%"
//                   },
//                   {
//                     title: "Funcionário",
//                     dataIndex: "employee",
//                     placeholder: "Selecione um funcionário",
//                     key: "employee",
//                     type: uiComponent.select.basic,
//                     dataSource: ["teste 1", "teste 2", "teste 3"],
//                     width: "25%"
//                   },
//                   {
//                     title: "Nome auditor externo",
//                     dataIndex: "externalAuditorName",
//                     key: "externalAuditorName",
//                     placeholder: "Introduza o nome",
//                     type: uiComponent.input.basic,
//                     width: "30%"
//                   },
//                   {
//                     type: uiComponent.radio.custom,
//                     width: "10%",
//                     isChild: true,
//                     title: "Estado",
//                     key: "status",
//                     dataIndex: "status",
//                     dataSource: {
//                       active: {
//                         key: "active",
//                         value: "active",
//                         text: "Ativo"
//                       },
//                       inactive: {
//                         key: "inactive",
//                         value: "inactive",
//                         text: "Inativo"
//                       }
//                     },
//                     conditions: {
//                       active: {
//                         key: "active",
//                         value: "active",
//                         text: "Ativo"
//                       },
//                       inactive: {
//                         key: "inactive",
//                         value: "inactive",
//                         text: "Inativo"
//                       }
//                     }
//                   },
//                   {
//                     title: "Normas",
//                     dataIndex: "standarts",
//                     key: "standarts",
//                     icon: "edit",
//                     type: uiComponent.button.basic,
//                     operation: "openModal",
//                     modal: "c4",
//                     width: "20%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     name: "Mike",
//                     age: 32,
//                     address: "10 Downing Street"
//                   },
//                   2: {
//                     key: "2",
//                     name: "John",
//                     age: 42,
//                     address: "10 Downing Street"
//                   }
//                 }
//               },
//               c4: {
//                 key: "c4",
//                 uiComponent: uiComponent.modal.basic,
//                 isChild: false,
//                 name: "Normas do auditor",
//                 width: "40%",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c5"]
//               },
//               c5: {
//                 key: "c5",
//                 isChild: true,
//                 uiComponent: uiComponent.fixedList.basic,
//                 name: "Lista",
//                 placeholder: "Selecione as normas do auditor",
//                 colSize: 24,
//                 value: ["Norma 1", "Norma 2", "Norma 3"]
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           }
//         },
//         decisions: {}
//       },
//       10: {
//         key: 10,
//         id: 10,
//         nome: "Auditorias internas",
//         type: processType.support,
//         relatedProcess: "",
//         ambito: "Auditorias internas",
//         organicUnit: "",
//         dono: "wac",
//         estado: "ativo",
//         versao: "1.0.0",
//         codigo_externo: "M101",
//         parallelOperations: {},
//         tasks: {
//           1: {
//             key: "1",
//             name: "selecionar ano",
//             position: 0,
//             scope: "selecionar ano",
//             aditionalInfo: "",
//             transitions: [{ component: "end-node" }],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 colSize: 8,
//                 title: "Ano",
//                 name: "year",
//                 uiComponent: uiComponent.select.basic,
//                 dataSource: ["2016", "2017", "2018"],
//                 placeholder: "Selecione o ano"
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 colSize: 8,
//                 title: "Estrutura a auditar",
//                 name: "auditStructure",
//                 uiComponent: uiComponent.select.basic,
//                 dataSource: ["CAD", "CAF", "CAFAP", "CAO", "Clientes Externos"],
//                 placeholder: "Selecione a estrutura"
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           2: {
//             key: "2",
//             name: "planear auditorias",
//             position: 1,
//             scope: "planear auditorias",
//             aditionalInfo: "",
//             transitions: [{ component: "end-node" }],
//             status: "todo",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.alert.oneLine,
//                 title: "Ano",
//                 value: 2016,
//                 dependencies: {
//                   processId: 4,
//                   taskKey: "1",
//                   fieldKey: "c1"
//                 },
//                 colSize: 8
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 uiComponent: uiComponent.divider.basic,
//                 name: "Lista de auditorias"
//               },
//               c3: {
//                 key: "c3",
//                 isChild: false,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Natureza da auditoria",
//                     dataIndex: "auditNature",
//                     key: "auditNature",
//                     type: uiComponent.input.basic,
//                     placeholder: "Indroduza a questão de identificação",
//                     width: "25%"
//                   },
//                   // {
//                   //   title: "Data de inicio",
//                   //   dataIndex: "beginDate",
//                   //   key: "beginDate",
//                   //   placeholder: "Data inicio",
//                   //   type: uiComponent.date.basic,
//                   //   width: "15%"
//                   // },
//                   // {
//                   //   title: "Data de fim",
//                   //   placeholder: "Data fim",
//                   //   dataIndex: "endDate",
//                   //   key: "endDate",
//                   //   type: uiComponent.date.basic,
//                   //   width: "15%"
//                   // },
//                   {
//                     title: "Duração",
//                     dataIndex: "duration",
//                     key: "duration",
//                     type: uiComponent.date.range,
//                     placeholder: ["Data inicio", "Data fim"],
//                     width: "30%"
//                   },
//                   {
//                     title: "Norma",
//                     name: "standart",
//                     dataIndex: "standart",
//                     placeholder: "Selecione a norma",
//                     type: uiComponent.select.basic,
//                     width: "15%",
//                     dataSource: [""]
//                   },
//                   {
//                     title: "Processos",
//                     name: "processes",
//                     dataIndex: "processes",
//                     type: uiComponent.button.basic,
//                     operation: "openModal",
//                     modal: "c4",
//                     icon: "edit",
//                     width: "5%"
//                   },
//                   {
//                     title: "Observações",
//                     dataIndex: "obs",
//                     key: "obs",
//                     type: uiComponent.input.basic,
//                     placeholder: "Indroduza uma observação",
//                     width: "25%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     identificationQuestion: "Mike",
//                     identificationAnswer: 2
//                   },
//                   2: {
//                     key: "2",
//                     identificationQuestion: "John",
//                     identificationAnswer: 2
//                   }
//                 }
//               },
//               c4: {
//                 key: "c4",
//                 isChild: false,
//                 uiComponent: uiComponent.modal.basic,
//                 name: "Listagem de processos da norma",
//                 width: "90%",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c5", "c6", "c7"]
//               },
//               c5: {
//                 key: "c5",
//                 isChild: true,
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Processo",
//                     dataIndex: "process",
//                     key: "process",
//                     type: uiComponent.select.basic,
//                     placeholder: "Selecione o processo",
//                     width: "20%",
//                     dataSource: [""]
//                   },
//                   {
//                     title: "Tarefa",
//                     dataIndex: "task",
//                     key: "task",
//                     type: uiComponent.select.basic,
//                     placeholder: "Selecione a tarefa",
//                     width: "20%",
//                     dataSource: [""]
//                   },
//                   {
//                     type: uiComponent.radio.custom,
//                     width: "15%",
//                     isChild: true,
//                     title: "Tipo de registos",
//                     dataIndex: "registryType",
//                     key: "registryType",
//                     dataSource: {
//                       lastExecution: {
//                         key: "lastExecution",
//                         value: "lastExecution",
//                         text: "Ultima execução"
//                       },
//                       sinceDate: {
//                         key: "sinceDate",
//                         value: "sinceDate",
//                         text: "Desde a data"
//                       }
//                     },
//                     conditions: {
//                       lastExecution: {
//                         key: "lastExecution",
//                         value: "lastExecution",
//                         text: "Ultima execução"
//                       },
//                       sinceDate: {
//                         key: "sinceDate",
//                         value: "sinceDate",
//                         text: "Desde a data"
//                       }
//                     }
//                   },
//                   {
//                     title: "Desde a data",
//                     dataIndex: "sinceDate",
//                     key: "sinceDate",
//                     placeholder: "Data",
//                     type: uiComponent.date.basic,
//                     width: "15%"
//                   },
//                   {
//                     title: "Registos",
//                     name: "records",
//                     dataIndex: "records",
//                     type: uiComponent.dataDisplay.basic,
//                     width: "5%"
//                   },
//                   {
//                     title: "Nº a auditar",
//                     name: "numberToAudit",
//                     dataIndex: "numberToAudit",
//                     type: uiComponent.input.number,
//                     width: "10%"
//                   },
//                   {
//                     title: "Auditados",
//                     dataIndex: "audited",
//                     key: "audited",
//                     type: uiComponent.button.basic,
//                     operation: "openModal",
//                     modal: "c6",
//                     icon: "edit",
//                     width: "5%"
//                   },
//                   {
//                     title: "Critérios",
//                     dataIndex: "criteria",
//                     key: "criteria",
//                     type: uiComponent.button.basic,
//                     operation: "openModal",
//                     modal: "c7",
//                     icon: "edit",
//                     width: "5%"
//                   },
//                   {
//                     title: "Observações",
//                     dataIndex: "obs",
//                     key: "obs",
//                     type: uiComponent.input.basic,
//                     placeholder: "Indroduza uma observação",
//                     width: "30%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     identificationQuestion: "Mike",
//                     identificationAnswer: 2,
//                     records: 23
//                   },
//                   2: {
//                     key: "2",
//                     identificationQuestion: "John",
//                     identificationAnswer: 2,
//                     records: 10
//                   }
//                 }
//               },
//               c6: {
//                 key: "c6",
//                 isChild: true,
//                 uiComponent: uiComponent.modal.basic,
//                 name: "Listagem de audições",
//                 style: { top: 20 },
//                 width: "90%",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c8", "c9", "c10"]
//               },
//               c8: {
//                 key: "c8",
//                 isChild: true,
//                 name: "Funcionários",
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 colSize: 12,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Grupo funcional",
//                     dataIndex: "functionalGroup",
//                     key: "functionalGroup",
//                     type: uiComponent.select.basic,
//                     placeholder: "Selecione o grupo funcional",
//                     dataSource: [""],
//                     width: "50%"
//                   },
//                   {
//                     title: "Funcionário",
//                     dataIndex: "employee",
//                     key: "employee",
//                     type: uiComponent.select.basic,
//                     dataSource: [""],
//                     placeholder: "Selecione o funcionário",
//                     width: "50%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     identificationQuestion: "Mike",
//                     identificationAnswer: 2
//                   },
//                   2: {
//                     key: "2",
//                     identificationQuestion: "John",
//                     identificationAnswer: 2
//                   }
//                 }
//               },
//               c9: {
//                 key: "c9",
//                 isChild: true,
//                 name: "Fornecedores",
//                 size: "middle",
//                 uiComponent: uiComponent.table.topOperations,
//                 colSize: 12,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Setor",
//                     dataIndex: "sector",
//                     key: "sector",
//                     type: uiComponent.select.basic,
//                     dataSource: [""],
//                     placeholder: "Selecione o setor",
//                     width: "50%"
//                   },
//                   {
//                     title: "Fornecedor",
//                     dataIndex: "supplier",
//                     key: "supplier",
//                     dataSource: [""],
//                     type: uiComponent.select.basic,
//                     placeholder: "Selecione o fornecedor",
//                     width: "50%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     identificationQuestion: "Mike",
//                     identificationAnswer: 2
//                   },
//                   2: {
//                     key: "2",
//                     identificationQuestion: "John",
//                     identificationAnswer: 2
//                   }
//                 }
//               },
//               c10: {
//                 key: "c10",
//                 isChild: true,
//                 name: "Parceiros",
//                 uiComponent: uiComponent.fixedList.basic,
//                 colSize: 8,
//                 placeholder: "Selecione os parceiros",
//                 value: [
//                   "Cliente",
//                   "Colaborador",
//                   "Familiares",
//                   "Outro",
//                   "Comunidade",
//                   "Parceiro",
//                   "Financiador"
//                 ]
//               },
//               c7: {
//                 key: "c7",
//                 isChild: true,
//                 uiComponent: uiComponent.modal.basic,
//                 name: "Selecionar critérios",
//                 width: "40%",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c11", "c13", "c12"]
//               },
//               c11: {
//                 key: "c11",
//                 isChild: true,
//                 uiComponent: uiComponent.alert.oneLine,
//                 title: "Processo",
//                 value: "admissão",
//                 colSize: 8
//               },
//               c13: {
//                 key: "c13",
//                 uiComponent: uiComponent.divider.basic,
//                 name: "Requisitos"
//               },
//               c12: {
//                 uiComponent: uiComponent.table.basic,
//                 isChild: true,
//                 key: "c12",
//                 size: "middle",
//                 columns: [
//                   {
//                     title: "Critério",
//                     dataIndex: "criterion",
//                     key: "criterion",
//                     type: uiComponent.dataDisplay.basic,
//                     width: "20%"
//                   },
//                   {
//                     title: "Selecionar",
//                     dataIndex: "select",
//                     key: "select",
//                     type: uiComponent.radio.basicSmall,
//                     width: "20%"
//                   }
//                 ],
//                 dataSource: [
//                   { criterion: "Critério 1", group: "gp1" },
//                   { criterion: "Critério 2", group: "gp2" }
//                 ]
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           3: {
//             key: "3",
//             name: "verificar documento",
//             position: 2,
//             scope: "verificar documento",
//             aditionalInfo: "",
//             transitions: [{ component: "end-node" }],
//             status: "todo",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.date.basic,
//                 title: "Data de registo",
//                 width: "100%",
//                 colSize: 8,
//                 name: "registrationDate"
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 uiComponent: uiComponent.radio.basic,
//                 title: "Verificado",
//                 colSize: 8,
//                 name: "verified"
//               },
//               c3: {
//                 key: "c3",
//                 isChild: false,
//                 uiComponent: uiComponent.input.textArea,
//                 title: "Justificação",
//                 placeholder: "Introduza a justificação",
//                 colSize: 8,
//                 name: "justification"
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           4: {
//             key: "4",
//             name: "aprovar documento",
//             position: 3,
//             scope: "aprovar documento",
//             aditionalInfo: "",
//             transitions: [{ component: "end-node" }],
//             status: "todo",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.date.basic,
//                 title: "Data de registo",
//                 width: "100%",
//                 colSize: 8,
//                 name: "registrationDate"
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 uiComponent: uiComponent.radio.basic,
//                 title: "Verificado",
//                 colSize: 8,
//                 name: "verified"
//               },
//               c3: {
//                 key: "c3",
//                 isChild: false,
//                 uiComponent: uiComponent.input.textArea,
//                 title: "Justificação",
//                 placeholder: "Introduza a justificação",
//                 colSize: 8,
//                 name: "justification"
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           }
//         },
//         decisions: {}
//       },
//       11: {
//         key: 11,
//         id: 11,
//         nome: "Execução de auditoria",
//         type: processType.support,
//         relatedProcess: "",
//         ambito: "Execução de auditoria",
//         organicUnit: "",
//         dono: "wac",
//         estado: "ativo",
//         versao: "1.0.0",
//         codigo_externo: "M102",
//         parallelOperations: {},
//         tasks: {
//           4: {
//             key: "4",
//             name: "selecionar auditoria",
//             position: 0,
//             scope: "selecionar auditoria",
//             aditionalInfo: "",
//             transitions: [{ component: "end-node" }],
//             status: "doing",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.select.basic,
//                 title: "Ano",
//                 placeholder: "Selecione o ano",
//                 colSize: 8,
//                 dataSource: ["2018", "2019"],
//                 name: "year"
//               },
//               c2: {
//                 key: "c2",
//                 isChild: false,
//                 placeholder: "Selecione a unidade orgânica",
//                 dataSource: ["CAF", "SIF"],
//                 uiComponent: uiComponent.select.basic,
//                 title: "Unidade orgânica",
//                 colSize: 8,
//                 name: "organicUnit"
//               },
//               c3: {
//                 key: "c3",
//                 isChild: false,
//                 uiComponent: uiComponent.divider.basic,
//                 name: "Definir auditoria"
//               },
//               c4: {
//                 key: "c4",
//                 isChild: false,
//                 uiComponent: uiComponent.dataDisplay.basic,
//                 title: "Natureza da auditoria",
//                 colSize: 8,
//                 name: "auditNature",
//                 value: "Âmbito teste"
//               },
//               c5: {
//                 key: "c5",
//                 isChild: false,
//                 uiComponent: uiComponent.dataDisplay.basic,
//                 title: "Norma",
//                 colSize: 8,
//                 name: "standart",
//                 value: "Norma teste"
//               },
//               c6: {
//                 key: "c6",
//                 isChild: false,
//                 uiComponent: uiComponent.radio.customAdvanced,
//                 title: "Auditoria realizada?",
//                 colSize: 8,
//                 dataSource: [
//                   { key: true, value: true, text: "Sim" },
//                   { key: false, value: false, text: "Não" }
//                 ],
//                 conditions: {
//                   true: {
//                     key: "true",
//                     isChild: false,
//                     isSelected: true,
//                     childKeys: ["c8"]
//                   },
//                   false: {
//                     key: "false",
//                     isChild: false,
//                     isSelected: false,
//                     childKeys: ["c9"]
//                   }
//                 },
//                 name: "audit"
//               },
//               c8: {
//                 key: "c8",
//                 isChild: true,
//                 name: "Equipa auditora",
//                 size: "middle",
//                 uiComponent: uiComponent.table.basic,
//                 columns: [
//                   {
//                     title: "Auditor",
//                     dataIndex: "auditor",
//                     key: "auditor",
//                     type: uiComponent.dataDisplay.basic,
//                     placeholder: "",
//                     width: "30%"
//                   },
//                   {
//                     title: "Tipo",
//                     dataIndex: "type",
//                     key: "type",
//                     type: uiComponent.dataDisplay.basic,
//                     placeholder: "",
//                     width: "30%"
//                   },
//                   {
//                     title: "Equipa auditora?",
//                     dataIndex: "type",
//                     key: "type",
//                     type: uiComponent.radio.basic,
//                     placeholder: "",
//                     width: "30%"
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     identificationQuestion: "Mike",
//                     identificationAnswer: 2,
//                     auditor: "Ambito teste",
//                     type: "Interno"
//                   },
//                   2: {
//                     key: "2",
//                     identificationQuestion: "John",
//                     identificationAnswer: 2,
//                     auditor: "Ambito teste",
//                     type: "Externo"
//                   }
//                 }
//               },
//               c9: {
//                 key: "c9",
//                 isChild: true,
//                 uiComponent: uiComponent.input.textArea,
//                 title: "Motivo da não realização",
//                 placeholder: "Introduza o motivo da não realização",
//                 colSize: 8,
//                 name: "notRealizingReason"
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           },
//           5: {
//             key: "5",
//             name: "executar auditoria",
//             position: 1,
//             scope: "executar auditoria",
//             aditionalInfo: "",
//             transitions: [{ component: "end-node" }],
//             status: "todo",
//             page: {
//               c1: {
//                 key: "c1",
//                 isChild: false,
//                 uiComponent: uiComponent.alert.oneLine,
//                 title: "Norma",
//                 value: "NP EN ISO 9001 - 2015",
//                 // dependencies: {
//                 //   processId: 4,
//                 //   taskKey: "1",
//                 //   fieldKey: "c1"
//                 // },
//                 colSize: 8
//               },
//               c2: {
//                 uiComponent: uiComponent.table.basic,
//                 isChild: false,
//                 key: "c2",
//                 size: "middle",
//                 columns: [
//                   {
//                     title: "Natureza da auditoria",
//                     dataIndex: "auditNature",
//                     key: "auditNature",
//                     type: uiComponent.dataDisplay.basic
//                   },
//                   {
//                     title: "Equipa auditora",
//                     dataIndex: "auditTeam",
//                     key: "auditTeam",
//                     type: uiComponent.dataDisplay.basic
//                   }
//                 ],
//                 dataSource: [{ auditScope: "ambito 1", auditTeam: "gp1" }]
//               },
//               c3: {
//                 uiComponent: uiComponent.table.basic,
//                 isChild: false,
//                 key: "c3",
//                 size: "middle",
//                 columns: [
//                   {
//                     title: "Processo",
//                     dataIndex: "process",
//                     key: "process",
//                     type: uiComponent.dataDisplay.basic,
//                     width: "40%"
//                   },
//                   {
//                     title: "Tarefa",
//                     dataIndex: "task",
//                     key: "task",
//                     type: uiComponent.dataDisplay.basic,
//                     width: "40%"
//                   },
//                   {
//                     title: "Audições",
//                     dataIndex: "auditions",
//                     key: "auditions",
//                     width: "10%",
//                     operation: "openModal",
//                     modal: "c5",
//                     type: uiComponent.button.basic,
//                     icon: "edit"
//                   },
//                   {
//                     title: "Auditoria",
//                     dataIndex: "audits",
//                     key: "audits",
//                     width: "10%",
//                     modal: "c13",
//                     operation: "openModal",
//                     type: uiComponent.button.basic,
//                     icon: "edit"
//                   }
//                 ],
//                 dataSource: [{ process: "proceso1", task: "gp1" }]
//               },
//               c4: {
//                 key: "c4",
//                 isChild: false,
//                 uiComponent: uiComponent.input.textArea,
//                 title: "Observações",
//                 placeholder: "Introduza observações",
//                 colSize: 8,
//                 name: "notRealizingReason"
//               },
//               c5: {
//                 key: "c5",
//                 isChild: false,
//                 uiComponent: uiComponent.modal.basic,
//                 name: "Registo de audições",
//                 style: { top: 20 },
//                 width: "90%",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c6", "c7", "c8", "c9", "c10", "c11", "c12"]
//               },
//               c6: {
//                 key: "c6",
//                 isChild: true,
//                 uiComponent: uiComponent.alert.oneLine,
//                 title: "Norma",
//                 value: "NP EN ISO 9001 - 2015",
//                 colSize: 8
//               },
//               c7: {
//                 key: "c7",
//                 isChild: true,
//                 uiComponent: uiComponent.alert.oneLine,
//                 title: "Processo",
//                 value: "abertura de vaga para recrutamento",
//                 colSize: 8
//               },
//               c8: {
//                 key: "c8",
//                 isChild: true,
//                 uiComponent: uiComponent.alert.oneLine,
//                 title: "Tarefa",
//                 value: "abre vaga para recrutamento",
//                 colSize: 8
//               },
//               c9: {
//                 key: "c9",
//                 isChild: true,
//                 size: "middle",
//                 name: "Funcionários",
//                 uiComponent: uiComponent.table.topOperations,
//                 colSize: 12,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Grupo funcional",
//                     dataIndex: "functionalGroup",
//                     key: "functionalGroup",
//                     type: uiComponent.select.basic,
//                     placeholder: "Selecione o grupo funcional",
//                     dataSource: [""],
//                     width: "50%"
//                   },
//                   {
//                     title: "Funcionário",
//                     dataIndex: "employee",
//                     key: "employee",
//                     type: uiComponent.select.basic,
//                     dataSource: [""],
//                     placeholder: "Selecione o funcionário",
//                     width: "50%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     identificationQuestion: "Mike",
//                     identificationAnswer: 2
//                   },
//                   2: {
//                     key: "2",
//                     identificationQuestion: "John",
//                     identificationAnswer: 2
//                   }
//                 }
//               },
//               c10: {
//                 key: "c10",
//                 isChild: true,
//                 size: "middle",
//                 name: "Clientes",
//                 uiComponent: uiComponent.table.topOperations,
//                 colSize: 12,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Unidade orgânica",
//                     dataIndex: "organicUnit",
//                     key: "organicUnit",
//                     type: uiComponent.select.basic,
//                     placeholder: "Selecione a unidade orgânica",
//                     dataSource: [""],
//                     width: "50%"
//                   },
//                   {
//                     title: "Cliente",
//                     dataIndex: "customer",
//                     key: "customer",
//                     type: uiComponent.select.basic,
//                     dataSource: [""],
//                     placeholder: "Selecione o cliente",
//                     width: "50%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     identificationQuestion: "Mike",
//                     identificationAnswer: 2
//                   },
//                   2: {
//                     key: "2",
//                     identificationQuestion: "John",
//                     identificationAnswer: 2
//                   }
//                 }
//               },
//               c11: {
//                 key: "c11",
//                 isChild: true,
//                 size: "middle",
//                 name: "Fornecedores",
//                 uiComponent: uiComponent.table.topOperations,
//                 colSize: 12,
//                 operations: [
//                   {
//                     opType: uiComponent.tableOperation.add,
//                     type: "primary",
//                     icon: "plus",
//                     text: "Adicionar"
//                   }
//                 ],
//                 columns: [
//                   {
//                     title: "Setor",
//                     dataIndex: "sector",
//                     key: "sector",
//                     type: uiComponent.select.basic,
//                     dataSource: [""],
//                     placeholder: "Selecione o setor",
//                     width: "50%"
//                   },
//                   {
//                     title: "Fornecedor",
//                     dataIndex: "supplier",
//                     key: "supplier",
//                     dataSource: [""],
//                     type: uiComponent.select.basic,
//                     placeholder: "Selecione o fornecedor",
//                     width: "50%"
//                   },
//                   {
//                     title: "",
//                     type: uiComponent.tableOperation.key,
//                     child: [
//                       {
//                         text: "Remover",
//                         type: uiComponent.tableOperation.remove
//                       }
//                     ]
//                   }
//                 ],
//                 dataSource: {
//                   1: {
//                     key: "1",
//                     identificationQuestion: "Mike",
//                     identificationAnswer: 2
//                   },
//                   2: {
//                     key: "2",
//                     identificationQuestion: "John",
//                     identificationAnswer: 2
//                   }
//                 }
//               },
//               c12: {
//                 key: "c12",
//                 isChild: true,
//                 name: "Outros",
//                 uiComponent: uiComponent.fixedList.basic,
//                 colSize: 8,
//                 placeholder: "Selecione os parceiros",
//                 value: ["Parceiros", "Financiadores", "Outras Entidades"]
//               },
//               c13: {
//                 key: "c13",
//                 isChild: false,
//                 uiComponent: uiComponent.modal.basic,
//                 name: "Registo de auditoria",
//                 style: { top: 20 },
//                 width: "90%",
//                 operations: [
//                   {
//                     text: "Imprimir",
//                     type: "default",
//                     operation: "print",
//                     icon: "printer"
//                   },
//                   {
//                     text: "Cancelar",
//                     type: "default",
//                     operation: "cancel",
//                     icon: ""
//                   },
//                   {
//                     text: "Guardar",
//                     type: "primary",
//                     operation: "save",
//                     icon: "save"
//                   }
//                 ],
//                 childKeys: ["c14", "c15", "c16", "c17"]
//               },
//               c14: {
//                 key: "c14",
//                 isChild: true,
//                 uiComponent: uiComponent.alert.oneLine,
//                 title: "Norma",
//                 value: "NP EN ISO 9001 - 2015",
//                 colSize: 8
//               },
//               c15: {
//                 key: "c15",
//                 isChild: true,
//                 uiComponent: uiComponent.alert.oneLine,
//                 title: "Processo",
//                 value: "abertura de vaga para recrutamento",
//                 colSize: 8
//               },
//               c16: {
//                 key: "c16",
//                 isChild: true,
//                 uiComponent: uiComponent.alert.oneLine,
//                 title: "Tarefa",
//                 value: "abre vaga para recrutamento",
//                 colSize: 8
//               },
//               c17: {
//                 uiComponent: uiComponent.table.basic,
//                 isChild: true,
//                 key: "c17",
//                 size: "middle",
//                 columns: [
//                   {
//                     title: "Cláusula",
//                     dataIndex: "clause",
//                     key: "clause",
//                     type: uiComponent.select.basic,
//                     dataSource: ["Cláusula 1", "Cláusula 2"],
//                     width: "20%"
//                   },
//                   {
//                     title: "Sub-Cláusula",
//                     dataIndex: "subClause",
//                     key: "subClause",
//                     dataSource: ["Sub-cláusula 1", "Sub-Cláusula 2"],
//                     type: uiComponent.select.basic,
//                     width: "20%"
//                   },
//                   {
//                     title: "Alínea",
//                     dataIndex: "line",
//                     key: "line",
//                     dataSource: ["Alínea 1", "Alínea 2"],
//                     type: uiComponent.select.basic,
//                     width: "20%"
//                   },
//                   {
//                     title: "Conforme",
//                     dataIndex: "according",
//                     key: "according",
//                     type: uiComponent.radio.basic,
//                     width: "20%"
//                   },
//                   {
//                     title: "Auditor",
//                     dataIndex: "auditor",
//                     key: "auditor",
//                     dataSource: ["Auditor 1", "Auditor 2"],
//                     type: uiComponent.select.basic,
//                     width: "20%"
//                   },
//                   {
//                     title: "Observações",
//                     dataIndex: "observations",
//                     key: "observations",
//                     type: uiComponent.input.basic,
//                     width: "20%"
//                   }
//                 ],
//                 dataSource: [
//                   { criterion: "Critério 1", group: "gp1" },
//                   { criterion: "Critério 2", group: "gp2" }
//                 ]
//               }
//             },
//             actions: {
//               0: {
//                 key: 0,
//                 name: "Primeira accao"
//               }
//             }
//           }
//         },
//         decisions: {}
//       }
//     }
//   },
//   app: {}
// };
