export const actionType = {
    select: "SELECT_HOLIDAY",
    getFormulario: "GET_FORMULARIO_HOLIDAY",
    insert: "INSERT_HOLIDAY",
    addNewDay: "INSERT_NEW_DAY_HOLIDAY",
    setField: "SET_FIELD_HOLIDAY",
    setFieldList: "SET_FIELD_DATA_FORM_LIST_HOLIDAY",
    removeDayFromHoliday: "REMOVE_DAY_FROM_HOLIDAY",
    importHoliday: "IMPORT_HOLIDAYS_YEAR",
    getDetails: "GET_DETAILS_HOLIDAY",
    update: "UPDATE_HOLIDAY",
    getDetailsVersion: "GET_DETAILS_VERSION_HOLIDAY_HISTORIC",
};

export const lista = "config_holidays_year";

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
