import { Button, Divider, Form, Icon, Input, Popconfirm, Row, Select, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DataChangeProps, FormDataProps } from "../utils/types";
import moment from "moment";
import { api_fetch } from "../../../../utils/HttpRequest/request";
import Highlighter from "react-highlight-words";
import { Utilitaries } from "../../../../../business";

interface IProps extends DataChangeProps {
    formData: FormDataProps;
}

const table_key1 = "formacoes_externa";
const table_key2 = "formacao_externa";

export function Formation({ formData, handleAdd, handleDelete, onChange }: IProps) {
    const [loadInternalFormations, setLoadInternalFormations] = useState(false);
    const [internalFormations, setInternalFormations] = useState<any[]>([]);
    const [evaluations, setEvaluations] = useState<any[]>([]);
    const [levels, setLevels] = useState<any[]>([]);

    const [searchText, setSearchText] = useState("");

    const searchInput = useRef<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoadInternalFormations(true);
            const response = await api_fetch({
                endPoint: "/Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: "buscar_formacoes_colaborador",
                    username: formData.username,
                },
            });

            if (response.status === "SUCCESS") {
                setInternalFormations(response.response.result);
            }
            setLoadInternalFormations(false);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const list_values = formData.formacoes_externa.formacao_externa?.map((formation: any) =>
                Utilitaries.toNumber(formation.form_ext_avaliacao, true)
            );

            const response = await api_fetch({
                endPoint: "/Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: "manual_funcoes_formacoes_avaliacoes",
                    username: formData.username,
                    lista_valores: list_values.filter((item) => item).join(",") || 0,
                },
            });

            if (response.status === "SUCCESS") {
                setEvaluations(response.response.result);
            }
            const list_values_level = formData.formacoes_externa.formacao_externa?.map((formation: any) =>
                Utilitaries.toNumber(formation.form_ext_nivel, true)
            );
            const response1 = await api_fetch({
                endPoint: "/Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: "manual_funcoes_formacoes_nivel",
                    username: formData.username,
                    lista_valores: list_values_level.filter((item) => item).join(",") || 0,
                },
            });

            if (response1.status === "SUCCESS") {
                setLevels(response1.response.result);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getColumnSearchProps = (dataIndex: string) => {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: any) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
            onFilter: (value: any, record: any) =>
                record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text: any) => (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={Utilitaries.toString(text).toString()}
                />
            ),
        };
    };

    const handleSearch = (selectedKeys: any, confirm: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText("");
    };

    const internal_formation_column = [
        {
            title: "Formação",
            dataIndex: "form_int_nome",
            width: "35%",
            ...getColumnSearchProps("form_int_nome"),
        },
        {
            title: "Data início",
            dataIndex: "form_int_inicio",
            width: "15%",
            ...getColumnSearchProps("form_int_inicio"),
        },
        {
            title: "Data fim",
            dataIndex: "form_int_fim",
            width: "15%",
            ...getColumnSearchProps("form_int_fim"),
        },
        {
            title: "Avaliação",
            dataIndex: "form_int_avaliacao",
            width: "35%",
            ...getColumnSearchProps("form_int_avaliacao"),
        },
    ];

    // onChange({ dataIndex: "motivo_falta", value, row_key: record, table_key1, table_key2 })
    const columns: any[] = [
        {
            title: "Data início",
            dataIndex: "form_ext_inicio",
            ...getColumnSearchProps("form_ext_inicio"),
            onFilter: (value: any, record: any) => {
                const my = moment(record["form_ext_inicio"], "YYYY-MM-DD").format("DD/MM/YYYY").replace(/\W/g, "");
                return Utilitaries.toString(my)?.includes(value.replace(/\W/g, ""));
            },
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: 150,
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "form_ext_inicio",
                                    value: e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data fim",
            dataIndex: "form_ext_fim",
            ...getColumnSearchProps("form_ext_fim"),
            onFilter: (value: any, record: any) => {
                return Utilitaries.toString(
                    moment(record["form_ext_fim"], "YYYY-MM-DD").format("DD/MM/YYYY")
                )?.includes(value);
            },
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: 150,
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "form_ext_fim",
                                    value: e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text ? moment(text).format("YYYY-MM-DD") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Nome",
            dataIndex: "form_ext_nome",
            ...getColumnSearchProps("form_ext_nome"),
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Input.TextArea
                        onChange={(e) =>
                            onChange({
                                dataIndex: "form_ext_nome",
                                value: e.target.value,
                                row_key: record.key,
                                table_key1,
                                table_key2,
                            })
                        }
                        defaultValue={text}
                        allowClear
                        placeholder="Introduza nome"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                    />
                );
            },
        },
        {
            title: "Tipo",
            dataIndex: "form_ext_tipo",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Select
                        allowClear
                        showSearch
                        style={{
                            width: 100,
                            margin: "0 auto",
                            padding: "0",
                        }}
                        placeholder="seleciona uma opção"
                        optionFilterProp="children"
                        onChange={(value: any) =>
                            onChange({ dataIndex: "form_ext_tipo", value, row_key: record.key, table_key1, table_key2 })
                        }
                        defaultValue={text}
                        filterOption={(input, option) =>
                            String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Select.Option value="Interna">Interna</Select.Option>
                        <Select.Option value="Externa">Externa</Select.Option>
                    </Select>
                );
            },
        },
        {
            title: (
                <span>
                    Nível
                    <Tooltip
                        title={
                            "Esta Lista Dinâmica pode ser alterada no menu Definições -> Listas dinâmicas - form_ext_nivel"
                        }
                    >
                        <Icon type="question-circle-o" />
                    </Tooltip>
                </span>
            ),
            dataIndex: "form_ext_nivel",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    // <QaDynamicSelect
                    //     style={{ width: 70 }}
                    //     onChange={(value: any) =>
                    //         onChange({
                    //             dataIndex: "form_ext_nivel",
                    //             value,
                    //             row_key: record.key,
                    //             table_key1,
                    //             table_key2,
                    //         })
                    //     }
                    //     defaultValue={text}
                    //     name="form_ext_nivel"
                    // />
                    <Select
                        allowClear
                        showSearch
                        style={{
                            width: 100,
                            margin: "0 auto",
                            padding: "0",
                        }}
                        placeholder="seleciona uma opção"
                        optionFilterProp="children"
                        dropdownMatchSelectWidth={false}
                        onChange={(value: any) =>
                            onChange({
                                dataIndex: "form_ext_nivel",
                                value,
                                row_key: record.key,
                                table_key1,
                                table_key2,
                            })
                        }
                        defaultValue={text}
                        filterOption={(input, option) =>
                            String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {levels.map((item) => (
                            <Select.Option key={item.id_form_ext_nivel} value={item.id_form_ext_nivel}>
                                {item.designacao}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Nº de horas",
            dataIndex: "form_ext_n_horas",
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Form.Item
                        style={{
                            width: 100,
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(e) =>
                                onChange({
                                    dataIndex: "form_ext_n_horas",
                                    value: e.target.value,
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                            defaultValue={text}
                            type="number"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: (
                <span>
                    Avaliação
                    <Tooltip
                        title={
                            "Esta Lista Dinâmica pode ser alterada no menu Definições -> Listas dinâmicas - form_ext_avaliacao"
                        }
                    >
                        <Icon type="question-circle-o" />
                    </Tooltip>
                </span>
            ),
            dataIndex: "form_ext_avaliacao",
            // ...getColumnSearchProps("form_ext_avaliacao"),
            // onFilter: (value: any, record: any) => {
            //     const filteredValue = this.state.meses
            //         .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
            //         .map((item) => item.id);
            //     return filteredValue.includes(record["form_ext_avaliacao"]);
            // },
            render: (text: any, record: any) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    // <QaDynamicSelect
                    //     style={{ minWidth: "150px" }}
                    //     onChange={(value: any) =>
                    //         onChange({
                    //             dataIndex: "form_ext_avaliacao",
                    //             value,
                    //             row_key: record.key,
                    //             table_key1,
                    //             table_key2,
                    //         })
                    //     }
                    //     defaultValue={text}
                    //     name="form_ext_avaliacao"
                    // />
                    <Select
                        allowClear
                        showSearch
                        style={{
                            width: 100,
                            margin: "0 auto",
                            padding: "0",
                        }}
                        dropdownMatchSelectWidth={false}
                        placeholder="seleciona uma opção"
                        optionFilterProp="children"
                        onChange={(value: any) =>
                            onChange({
                                dataIndex: "form_ext_avaliacao",
                                value,
                                row_key: record.key,
                                table_key1,
                                table_key2,
                            })
                        }
                        defaultValue={text}
                        filterOption={(input, option) =>
                            String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {evaluations.map((item) => (
                            <Select.Option key={item.id_form_ext_avaliacao} value={item.id_form_ext_avaliacao}>
                                {item.designacao}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Ações",
            dataIndex: "action",
            width: "10%",
            render: (text: any, record: any) => (
                <Popconfirm title="Remover a linha?" onConfirm={() => handleDelete(record.key, table_key1, table_key2)}>
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Remover
                    </a>
                </Popconfirm>
            ),
        },
    ];
    //
    return (
        <>
            <Divider orientation="left">Formações internas</Divider>
            <Table
                rowKey={(record: any) => record.key}
                size="middle"
                loading={loadInternalFormations}
                columns={internal_formation_column}
                dataSource={internalFormations}
            />

            <Divider orientation="left">Outras formações</Divider>

            <Row>
                <Button
                    onClick={() => handleAdd(table_key1, table_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                rowKey={(record: any) => record.key}
                size="middle"
                columns={columns}
                dataSource={formData.formacoes_externa.formacao_externa}
            />
        </>
    );
}
