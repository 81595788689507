import React, { useState, useEffect } from "react";
import { Modal, Row, Drawer, Col, Popover, Button, Tree, Input, Menu } from "antd";
import { connect } from "react-redux";

import {
    changeForumModalState,
    getUserForumRooms,
    getMessages,
    getRoomPermission,
    sendMessage,
    getRoomUsers,
} from "./actions/index";

import ForumRooms from "./components/Rooms";
import MessageComponent from "./components/Messages";
import EditorComponent from "./components/Editor";
import SearchComponent from "./components/Search";

const Forum = ({
    user_forum_rooms,
    getUserForumRooms,
    modalForumState,
    changeForumModalState,
    getMessages,
    message,
    sendMessage,
    getRoomPermission,
    getRoomUsers,
}) => {
    const [selectRoom, setSelectRoom] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [newMessage, setNewMessage] = useState("");
    const [visibleSearch, setVisibleSeach] = useState(false);
    const [usersForum, setUsersForum] = useState([]);
    const [filter, setFilter] = useState(null);
    const [canEditRoom, setCanEditRoom] = useState({});

    useEffect(() => {
        getUserForumRooms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getMessages(selectRoom, filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectRoom, filter]);

    useEffect(() => {
        if (Number.isNaN(parseInt(selectRoom)) && !canEditRoom[selectRoom]) {
            getRoomPermission(selectRoom).then((res) => {
                setCanEditRoom({
                    ...canEditRoom,
                    [selectRoom]: res,
                });
            });
        }

        getRoomUsers(selectRoom).then((res) => setUsersForum(res));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectRoom]);

    const onSubmit = async () => {
        if (!newMessage) {
            return;
        }

        setSubmitting(true);

        await sendMessage({ ...newMessage, sala: selectRoom });
        setNewMessage("");
        setSubmitting(false);
    };

    return (
        // <Modal
        //     title="Fórum"
        //     visible={modalForumState}
        //     width="80%"
        //     style={{ top: 20 }}
        //     onCancel={() => changeForumModalState(false)}
        //     footer={null}
        // >
        <Drawer
            title="Fórum"
            placement="bottom"
            destroyOnClose
            closable={true}
            onClose={() => changeForumModalState(false)}
            height="100%"
            visible={modalForumState}
            bodyStyle={{ paddingTop: 0 }}
        >
            <Row>
                <Col xs={24} sm={6}>
                    <ForumRooms
                        list={user_forum_rooms}
                        changeRoom={(roomID) => {
                            setSelectRoom(roomID);
                        }}
                    />
                </Col>
                <Col xs={24} sm={18}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <>
                            {filter && !visibleSearch && (
                                <a
                                    href="!#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setFilter(null);
                                    }}
                                    style={{ marginRight: 5 }}
                                >
                                    Limpar filtros
                                </a>
                            )}
                            <Popover
                                trigger="click"
                                visible={visibleSearch}
                                title="Filtrar Mensagens"
                                onVisibleChange={(visible) => setVisibleSeach(visible)}
                                content={
                                    <SearchComponent
                                        setVisible={setVisibleSeach}
                                        users={usersForum}
                                        setFilter={setFilter}
                                    />
                                }
                            >
                                <Button
                                    shape="circle"
                                    icon="search"
                                    type="link"
                                    onClick={() => setVisibleSeach(true)}
                                />
                            </Popover>
                        </>
                    </div>
                    <MessageComponent message={message} />

                    {((Number.isNaN(parseInt(selectRoom)) && canEditRoom[selectRoom]) ||
                        !Number.isNaN(parseInt(selectRoom))) && (
                        <EditorComponent
                            onChange={setNewMessage}
                            onSubmit={onSubmit}
                            submitting={submitting}
                            value={newMessage}
                        />
                    )}
                </Col>
            </Row>
            {/* </Modal> */}
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    return {
        modalForumState: state.forum.modalForumState || false,
        user_forum_rooms: state.forum.user_forum_rooms,
        message: state.forum.messages,
    };
};
const mapDispatchToProps = {
    changeForumModalState,
    getUserForumRooms,
    getMessages,
    sendMessage,
    getRoomPermission,
    getRoomUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(Forum);
