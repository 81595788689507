import { sendHttpRequest, api } from "../../../components/http.request";
import { validateResponse } from "./../../../components/util";

export const FORM_INITIAL_INFO = {
    documentsAuthorizations: [],
    families: [],
    authorizationList: [],
    processList: [],
    origins: [],
    process: [],
    statusType: [],
    users: [],
    document: {},
};

export async function saveDocument(document) {
    try {
        const file = document.ficheiro;
        if (file) {
            document.ficheiro = {
                name: file.name,
                type: file.type,
            };
        }

        const requestBody = {
            accao: "guardar_definicao",
            dados: JSON.stringify({ controlo_documento: document }),
            ficheiro: file,
            document: document.document,
        };

        if (document.rever) {
            requestBody.rever = document.rever;
        }

        //
        const result = await request("geredocumentos", requestBody);
    } catch (error) {}
}

export async function cancelDocument(id) {
    try {
        const result = await request("geredocumentos", {
            accao: "anular",
            id,
        });
        return validateResponse(result);
    } catch (error) {
        return [];
    }
}

export async function getTasks(process) {
    try {
        const result = await request("gereprocesso", {
            accao: "lista_tarefas_nome",
            processo: process,
        });
        return validateResponse(result);
    } catch (error) {
        return [];
    }
}

export async function getDocumentInitialData(id) {
    try {
        const formInitialData = await request("geredocumentos", {
            accao: "definicao",
            id: id ? id : -1,
        });

        const usersList = await request("gereutilizador", {
            accao: "utilizadores",
        });

        return {
            documentsAuthorizations: validateResponse(formInitialData.autorizacoes_documento),
            families: validateResponse(formInitialData.familias),
            authorizationList: validateResponse(formInitialData.lista_autorizacoes),
            processList: validateResponse(formInitialData.lista_processos),
            origins: validateResponse(formInitialData.origens),
            process: validateResponse(formInitialData.processos),
            statusType: validateResponse(formInitialData.tipos_estado),
            document: formInitialData.documento || {},
            users: validateResponse(usersList),
        };
    } catch (error) {
        return FORM_INITIAL_INFO;
    }
}

function request(recource, requestBody) {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return sendHttpRequest("POST", `/Gestor/${recource}.php`, formData);
    // return api(`/Gestor/${recource}.php`, formData);
    // return sendHttpRequest("POST", `/Gestor/${recource}.php`, formData);
}

export async function getDocuments(requestBody) {
    try {
        const result = await request("geredocumentos", {
            ...requestBody,
            accao: "lista_documentos",
        });

        if (result) {
            const metadata = result["@attributes"];
            const lista = validateResponse(result);
            return {
                lista,
                metadata: {
                    resultados: Number(metadata.resultados),
                    pagina: Number(metadata.pagina),
                    visivel: metadata.visivel,
                    resultados_pagina: Number(metadata.resultados_pagina),
                },
            };
        }

        return {
            lista: [],
            metadata: {
                resultados: 0,
                pagina: 0,
                visivel: null,
                resultados_pagina: 0,
            },
        };
    } catch (error) {
        return {};
    }
}
