import { actionTypes, lista } from "../constants/index";
import { sendHttpRequest } from "../../../components/http.request";
import { downloadFile } from "../../../components/util";

export const selectQaStandart = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "lista");
    formData.append("lista", lista.normas_qa);
    Object.entries(payload).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse)
            dispatch({
                type: actionTypes.selectQaStandart,
                payload: jsonResponse["dados"],
            });
    });
};

export const getFormulario = () => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista.normas_qa);
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.formulario) {
            dispatch({
                type: actionTypes.getImportFormulario,
                payload: jsonResponse,
            });
            return true;
        } else {
            return false;
        }
    });
};

export const insert = (payload) => async (dispatch) => {
    const norma = {
        designacao: payload.values.nome_norma,
        ambito: payload.values.ambito,
        tipo: payload.values.tipo,
        lista: payload.values.list,
        escalaGlobal: payload.values.globalScale,
    };

    const formDataNorma = new FormData();
    formDataNorma.append("accao", "importar_norma");
    formDataNorma.append("dados", JSON.stringify(norma));
    formDataNorma.append("nome_ficheiro", payload.fileName);
    formDataNorma.append("ficheiro", payload.file);
    formDataNorma.append("descricao", payload.file.type);

    let fileId;
    let normaId;
    let data;
    await sendHttpRequest("POST", "/Gestor/gera_norma.php", formDataNorma).then((jsonResponse) => {
        if (jsonResponse) {
            fileId = jsonResponse.file;
            normaId = jsonResponse.norma;
            data = jsonResponse.date;
        } else return false;
    });

    const values = {
        acao: payload.values.acao,
        norma: normaId,
        ficheiro: fileId,
        data,
    };
    const obj = {};
    obj[payload.formulario.elemento_raiz] = values;

    const formData = new FormData();
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario.nome);
    formData.append("dados", JSON.stringify(obj));

    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            return true;
        } else return false;
    });
};

export const getDetails = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista.normas_qa);
    formData.append("chave1", payload.id);
    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        dispatch({
            type: actionTypes.getDetailsQaNormas,
            payload: jsonResponse,
        });
    });
};

export const exportStandart = (payload) => (async) => {
    const obj = {
        accao: "exportar_norma",
        id: payload.values?.norma,
        formulario: payload.formulario.nome,
        elemento_raiz: payload.formulario.elemento_raiz,
        downloadInBrowser: true,
    };

    return downloadFile("/gera_norma.php", obj);
};
