import React, { useState, useEffect } from "react";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import { initProcess } from "./../process_execution/actions/index";

import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import {
    setTemporaryRelRowKey,
    setTemporaryRelName,
    setTemporaryRelId,
    getTaskDetailsMap,
} from "../process_execution/actions/execution";
import {
    getFieldDataSource,
    getForm,
    getFormData,
    toggleModal,
    toggleIsFormReady,
    saveForm,
    getTaskData,
    getAoCarregar,
} from "../process_execution/actions/index";

import ExecutionForm from "./../process_execution/components/execution/execution.form";
import TaskExecutionFormProvider from "./../process_execution/components/execution/execution.form.provider";
import { selectById } from "../dynamic_list/actions/index";
import { Utilitaries } from "../../business";
import { Spin } from "antd";

const processName = "registo de reclamações e sugestões";
const ProcessPortal = ({ history, location, match, finishTask }) => {
    /**
     * * react redux state and dispatch declarations
     */
    const processState = useSelector((state) => state.processExecution.domain.byId);
    const appState = useSelector((state) => state.processExecution.app);
    const dispatch = useDispatch();

    /**
     * * regular const variables declarations
     */
    const { processId, taskId } = match.params;

    // const [form, setForm] = useState({
    //     [processState[processId]?.tasks[taskId]?.formMeta[0].form]: processState[processId]?.tasks[taskId]?.page,
    // });

    //const [form, setForm] = useState(processState[processId]?.tasks[taskId]?.page);
    const [form, setForm] = useState({});

    const formItemsRender = Object.values(form).filter(
        (item) => !item.parent && (item["@tipo"] || item["@elemento_grafico"])
    );

    useEffect(() => {
        dispatch(toggleIsFormReady());
    }, []);

    useEffect(() => {
        setForm({});
        dispatch(getTaskDetailsMap({ taskId, processId }));
        dispatch(getTaskData({ processId, taskId })).then(
            ({
                taskData,
                values,
                formAttributes: formAttrib,
                metaInfoformFields,
                formWithoutValues: cleanForm,
                taskAtributes,
            } = {}) => {
                return getAoCarregar({ form: taskData, metaInfo: metaInfoformFields, values }).then((form) => {
                    setForm(form);
                    //setFormWithoutValues(cleanForm);
                    //setFormAttributes({ rootProps: formAttrib, rootElementProps: metaInfoformFields });
                });
            }
        );
    }, [processId]);

    const handleGetForm = ({ form }) => {
        return dispatch(
            getForm({
                form,
                processId: processId,
                taskId: taskId,
            })
        );
    };

    const handleGetFormData = ({ form, id, formStruct }) => {
        return dispatch(
            getFormData({
                form,
                id,
                formStruct,
                processId: processId,
                taskId: taskId,
            })
        );
    };

    const handleToggleModal = ({ ...props }) => {
        return dispatch(toggleModal({ ...props }));
    };

    const handleNavegateToHome = () => {
        history.push({
            pathname: "/",
        });
    };

    return Utilitaries.isEmpty(form) ? (
        <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "200px" }}>
            <Spin />
        </div>
    ) : (
        <TaskExecutionFormProvider
            setTemporaryRelId={({ ...props }) => dispatch(setTemporaryRelId({ ...props }))}
            setTemporaryRelRowKey={({ ...props }) => dispatch(setTemporaryRelRowKey({ ...props }))}
            setTemporaryRelName={({ ...props }) => dispatch(setTemporaryRelName({ ...props }))}
            toggleIsFormReady={toggleIsFormReady}
            // setIsRestorable={setIsRestorable}
            // isRestorable={isRestorable}
            // currentTaskStatus={item["@estado"]}
            // isRestored={isRestored}
            isFormReady={appState.isFormReady}
            selectById={({ ...props }) => selectById({ ...props })}
            history={history}
            getForm={handleGetForm}
            saveForm={saveForm}
            getFormData={handleGetFormData}
            taskId={taskId}
            processId={processId}
            getFieldDataSource={getFieldDataSource}
            dispatch={dispatch}
            toggleModal={handleToggleModal}
            form={form}
            appState={appState}
            formMetaData={processState[processId]?.tasks[taskId]?.formMeta}
        >
            <ExecutionForm
                saveForm={saveForm}
                isRoot={true}
                dispatch={dispatch}
                toggleIsFormReady={toggleIsFormReady}
                formItemsRender={formItemsRender}
                appState={appState}
                isPortal={true}
                handleNavegateToHome={handleNavegateToHome}
                formName={processState[processId]?.tasks[taskId]?.formMeta?.[0]?.form}
            />
        </TaskExecutionFormProvider>
    );
};

export default ProcessPortal;
