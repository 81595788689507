import React from "react";
import { Modal, Tabs, Row, Col, Button } from "antd";

import FormTabPane from "./form.tab";
import HeaderTabPane from "./header.tab";
import DataTabPane from "./data.tab";

const { TabPane } = Tabs;

const NewForm = ({ isVisible, showNewForm }) => {
    return (
        <>
            <Modal
                title="Novo Formulário"
                visible={isVisible !== null}
                onCancel={() => showNewForm(null)}
                footer={null}
                confirmLoading={false}
                destroyOnClose
                width="60%"
                bodyStyle={{ minHeight: 400 }}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Dados formulário" key="1">
                        <FormTabPane />
                    </TabPane>
                    <TabPane tab="Dados cabeçalho" key="2">
                        <HeaderTabPane />
                    </TabPane>
                    <TabPane tab="Campos" key="3">
                        <DataTabPane />
                    </TabPane>
                </Tabs>
                <Button type="primary">Guardar</Button>
                <Button type="primary" style={{ marginLeft: 8, marginTop: 16 }}>
                    Ver Formulário
                </Button>
                <Button type="primary" style={{ marginLeft: 8, marginTop: 16 }}>
                    Correr SQL
                </Button>
            </Modal>
        </>
    );
};

export default NewForm;
