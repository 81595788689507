import React, { Component } from "react";
import { Button, Table, Row, Col, PageHeader, Input, Icon, Pagination } from "antd";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import QaStandartsForm from "./form";
import DetailsImportExport from "./details";

import * as actionCreators from "./../../actions/qa.standart";
import { tableSort, tableSortData, tableFooter } from "./../../../../components/util";
import moment from "moment";
const formatDate = "DD-MM-YYYY";

export class QaStandarts extends Component {
    componentDidMount() {
        if (!this.props.isFetched) this.selectData({ pagina: 1, resultados_pagina: 10 });
    }

    state = {
        isFormVisible: false,
        isModalDetailsVisible: false,
        filterParameters: {
            mostra_lista_eliminados: 0,
            ordenacao_data: "DESC",
        },
    };

    selectData = (obj) => {
        this.setState({
            isFetching: true,
        });
        this.props
            .selectQaStandart({
                ...this.state.filterParameters,
                ...obj,
            })
            .then(() => {
                this.setState({
                    isFetching: false,
                });
            });
    };

    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => {
            text = typeof text === "object" ? "" : text;
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            );
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    /****************************** */

    /** configs table */

    handlePageChange = (page, pageSize) => {
        this.selectData({ pagina: page, resultados_pagina: pageSize });
    };
    handleShowSizeChange = (current, pageSize) => {
        this.selectData({ pagina: current, resultados_pagina: pageSize });
    };
    handleTableChange = (pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val[0]) obj["filtro_" + key] = val[0];
            });
        }
        if (sorter.columnKey) {
            const value = sorter.order === "ascend" ? "ASC" : "DESC";
            obj["ordenacao_" + sorter.columnKey] = value;
        }
        const objFilter = {
            pagina: 1,
            resultados_pagina: this.props.resultadosPagina,
            ...obj,
        };
        this.setState({ filterParameters: obj }, () => {
            this.selectData(objFilter);
        });
    };
    handleEdit = (record) => {
        this.setState({
            isModalDetailsVisible: true,
            idDetails: record.id_insere_normas,
            nome: record.nome_norma,
        });
    };
    handleDetailsCancel = () => {
        this.setState({
            isModalDetailsVisible: false,
        });
    };

    columns = [
        {
            title: "Data",
            dataIndex: "data",
            width: "30%",
            ...this.getColumnSearchProps("data"),
            defaultSortOrder: "descend",
            sorter: (a, b, c) => {
                return tableSortData(moment(a.data, formatDate), moment(b.data, formatDate));
            },
        },
        {
            title: "Ação realizada",
            dataIndex: "acao",
            width: "20%",
            filters: [
                { text: "Importar", value: "importar" },
                { text: "Exportar", value: "exportar" },
            ],
            onFilter: (value, record) => record.acao.indexOf(value) === 0,
            filterMultiple: false,
            sorter: (a, b) => tableSort(a.acao, b.acao),
        },
        {
            title: "Norma",
            dataIndex: "nome_norma",
            width: "30%",
            ...this.getColumnSearchProps("nome_norma"),
            sorter: (a, b) => tableSort(a.nome_norma, b.nome_norma),
        },
        {
            title: "Ação",
            dataIndex: "operation",
            width: "20%",
            render: (text, record) => (
                <Button type="link" onClick={() => this.handleEdit(record)}>
                    Consultar
                </Button>
            ),
        },
    ];
    /************************************ */
    showModalForm = () => {
        this.setState({
            isFormVisible: true,
        });
    };

    handleOk = (e) => {
        this.setState({
            isFormVisible: false,
        });
        this.selectData({ pagina: 1, resultados_pagina: 10 });
    };

    handlePrint = (e) => {};

    handleCancel = (e) => {
        this.setState({
            isFormVisible: false,
        });
    };

    render() {
        return (
            <>
                <PageHeader title="Normativos"></PageHeader>
                <br />
                <Row>
                    <Col xs={24} md={24}>
                        <Button
                            onClick={this.showModalForm}
                            type="primary"
                            icon="plus"
                            style={{ marginBottom: 16, float: "right" }}
                        >
                            Inserir
                        </Button>
                    </Col>
                </Row>

                {this.state.isModalDetailsVisible && (
                    <DetailsImportExport
                        isVisible={this.state.isModalDetailsVisible}
                        handleCancel={this.handleDetailsCancel}
                        id={this.state.idDetails}
                        nome={this.state.nome}
                    />
                )}
                <Table
                    rowKey={(record) => record.id_insere_normas}
                    columns={this.columns}
                    dataSource={this.props.list}
                    loading={this.state.isFetching}
                    pagination={false}
                    footer={() => tableFooter(this.props.total)}
                    onChange={this.handleTableChange}
                    size="middle"
                />
                {this.props.isFetched && (
                    <>
                        <Row>
                            <Col xs={24}>
                                <Pagination
                                    showTitle
                                    size="small"
                                    showSizeChanger
                                    showLessItems
                                    onShowSizeChange={this.handleShowSizeChange}
                                    onChange={this.handlePageChange}
                                    current={this.props.pagina}
                                    total={this.props.total}
                                    style={{ float: "right" }}
                                />
                            </Col>
                        </Row>
                    </>
                )}

                {this.state.isFormVisible && (
                    <QaStandartsForm
                        isVisible={this.state.isFormVisible}
                        handleCancel={this.handleCancel}
                        handleOk={this.handleOk}
                    />
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        list: Object.values(state.qaStandarts.domain.byId),
        isFetched: state.qaStandarts.app.isFetched,
        total: Number(state.qaStandarts.domain.metadata.attributes.resultados),
        pagina: Number(state.qaStandarts.domain.metadata.attributes.pagina),
        resultadosPagina: state.qaStandarts.domain.metadata.attributes.resultados_pagina,
    };
};

const mapDispatchToProps = {
    selectQaStandart: actionCreators.selectQaStandart,
};

export default connect(mapStateToProps, mapDispatchToProps)(QaStandarts);
