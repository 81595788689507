import React from "react";
import { Modal } from "antd";
import { componentToRender } from "../../../../constants/index";
import RunSQL from "./run";
import SeeForm from "./see";
import FieldProperties from "./field.properties";

class FormModal extends React.Component {
    state = {
        isModalVisible: false,
        config: {
            title: "",
            width: 0,
            top: 0,
            okText: "",
            cancelText: "",
        },
    };

    config = ({ title, width = "40%", top = "20%", okText = "Guardar", cancelText = "Cancelar" }) => {
        this.setState({
            ...this.state,
            config: {
                ...this.state.config,
                title,
                width,
                top,
                okText,
                cancelText,
            },
        });
    };

    render() {
        return (
            <Modal
                title={this.state.config.title}
                style={{ top: this.state.config.top }}
                width={this.state.config.width}
                visible={this.props.visible}
                okText={this.state.config.okText}
                cancelText={this.state.config.cancelText}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
            >
                {this.props.componentToRender === componentToRender.runSQL ? (
                    <RunSQL configModal={this.config.bind(this)} data={this.props.data} />
                ) : null}

                {this.props.componentToRender === componentToRender.seeForm ? (
                    <SeeForm configModal={this.config.bind(this)} fields={this.props.data} />
                ) : null}

                {this.props.componentToRender === componentToRender.fieldProperties ? (
                    <FieldProperties configModal={this.config.bind(this)} data={this.props.data} />
                ) : null}
            </Modal>
        );
    }
}

export default FormModal;
