import React, { Component } from "react";
import FormCollectiveAgreement from "./form";
import { PageHeader } from "antd";

class DetailsCollectiveAgreement extends Component {
    handleRedirect = () => {
        this.props.history.push({
            pathname: "/function.manual/index/3",
            search: "?reload=true",
        });
    };

    render() {
        return (
            <>
                <PageHeader
                    onBack={() => {
                        this.props.history.push({
                            pathname: "/function.manual/index/3",
                        });
                    }}
                    title="Detalhes ficha de convenção coletiva"
                />
                <FormCollectiveAgreement isEdit id={this.props.match.params.id} redirect={this.handleRedirect} />
            </>
        );
    }
}

export default DetailsCollectiveAgreement;
