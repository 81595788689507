import React from "react";
import { Table, Row, Button, Select, Popconfirm } from "antd";

const { Option } = Select;

const PermissionsTabPane = ({ formState, setFormState, initialFormData }) => {
    const column = [
        {
            title: "Autorização",
            dataIndex: "idautorizacao",
            width: "80%",
            render: (value, row) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    optionFilterProp="children"
                    onChange={(value) => handleAuthorizationSelected(row.__key__, value)}
                    value={value}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {initialFormData.authorizationList.map((it) => (
                        <Option key={`auth_list_${it.key}`} value={it.idautorizacao}>
                            {it.descricao}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "20%",
            render: (text, record) => (
                // this.state.dataSource.length >= 1 ? (
                <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => handleDelete(record.__key__)}>
                    <Button style={{ color: "red" }} type="link">
                        Eliminar
                    </Button>
                </Popconfirm>
            ),
            // ) : null,
        },
    ];

    const handleAuthorizationSelected = (key, idautorizacao) => {
        const dataSource = [...formState.authorizations];

        const newData = dataSource.map((it, idx) => {
            if (idx === key) {
                it.idautorizacao = idautorizacao;
            }

            return it;
        });

        setFormState({
            ...formState,
            authorizations: newData,
        });
    };

    const handleAdd = () => {
        const newData = {
            idautorizacao: "",
            descricao: "",
        };

        setFormState({
            ...formState,
            authorizations: [newData, ...formState.authorizations],
        });
    };

    const handleDelete = (key) => {
        const dataSource = [...formState.authorizations];

        setFormState({
            ...formState,
            authorizations: dataSource.filter((item, idx) => idx !== key),
        });
    };

    return (
        <div style={{ width: "60%", padding: "0" }}>
            <strong style={{ marginLeft: "2%", padding: "0" }}>
                Autorizações a que é permitido aceder ao Documento
            </strong>
            <Row>
                <Button
                    xs={24}
                    sm={12}
                    onClick={handleAdd}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                pagination={{ pageSize: 8 }}
                size="small"
                columns={column}
                rowKey="__key__"
                dataSource={formState.authorizations.map((it, idx) => ({
                    ...it,
                    __key__: idx,
                }))}
            />
        </div>
    );
};

export default PermissionsTabPane;
