import React, { useEffect, useState } from "react";
import { Tabs, PageHeader, Form, Row, Col, Button, message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

import DocumentTabPane from "./document.tab";
import TasksAssignmentTabPane from "./taskassignment.tab";
import TranslationTabPane from "./translation.tab";

import { FORM_INITIAL_INFO, getDocumentsInitialData, saveDocument, cancelDocument } from "../../actions";

const { TabPane } = Tabs;

const ModelsDocumentsEdit = ({ form }) => {
    const { id } = useParams();
    const history = useHistory();

    const [initialFormData, setInitialFormData] = useState(FORM_INITIAL_INFO);
    const [toReview, setToReview] = useState(false);

    useEffect(() => {
        async function getData() {
            const data = await getDocumentsInitialData(id);
            setInitialFormData(data);
        }
        getData();
    }, [id]);

    const saveData = () => {};

    const handleSubmit = (e) => {
        e.preventDefault();
        saveData();
    };

    const { getFieldDecorator } = form;

    return (
        <div>
            <PageHeader
                onBack={() => {
                    history.push({
                        pathname: "/models/documents",
                    });
                }}
                title="Consulta Documento"
            />
            <Form layout="vertical" onSubmit={handleSubmit}>
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        {toReview && (
                            <>
                                <Button type="primary" onClick={() => saveData()}>
                                    Guardar Revisão
                                </Button>
                                <Button type="danger" onClick={() => setToReview(false)} style={{ marginLeft: 8 }}>
                                    Fechar Revisão
                                </Button>
                            </>
                        )}
                        {!toReview && (
                            <>
                                <Button>Rever</Button>
                                <Button style={{ marginLeft: 8 }}>Pre-visualizar</Button>
                                <Button onClick={() => setToReview(true)} style={{ marginLeft: 8 }}>
                                    Alterar
                                </Button>
                            </>
                        )}
                    </Col>
                </Row>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Modelo Documento" key="1">
                        <DocumentTabPane
                            initialFormData={initialFormData}
                            getFieldDecorator={getFieldDecorator}
                            enableField={!!id}
                            toReview={toReview}
                        />
                    </TabPane>
                    <TabPane tab="Atribuição Tarefas" key="2">
                        <TasksAssignmentTabPane
                            initialFormData={initialFormData}
                            getFieldDecorator={getFieldDecorator}
                            enableField={!!id}
                            toReview={toReview}
                        />
                    </TabPane>
                    <TabPane tab="Traduções" key="3">
                        <TranslationTabPane
                            initialFormData={initialFormData}
                            getFieldDecorator={getFieldDecorator}
                            enableField={!!id}
                            toReview={toReview}
                        />
                    </TabPane>
                </Tabs>
            </Form>
        </div>
    );
};

const WrappedPersonalInfoTabPane = Form.create({ name: "document" })(ModelsDocumentsEdit);

export default WrappedPersonalInfoTabPane;
