import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, message, PageHeader, Row, Tabs } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { PersonalInfo } from "./TabPanes/PersonalInfo";
import { api_fetch } from "../../../utils/HttpRequest/request";
import { ConventionCategoriesProps, FormDataProps, FunctionalGroupsProps, OnChangeProps } from "./utils/types";
import { Utilitaries } from "../../../../business";
import { defaultDataSource } from "./utils/defaultDataSource";
import { ValidationPermissionModal } from "./Components/ValidationPermissionModal";
import { FuntionsConventions } from "./TabPanes/FuntionsConventions";
import { Documents } from "./TabPanes/Document";
import { Historic } from "./TabPanes/Historic";
import { ContractTypeProps } from "../../../types/common";
import { OverTime } from "./TabPanes/OverTime";
import { ProgrammedAbsences } from "./TabPanes/ProgrammedAbsences";
import { Fouls } from "./TabPanes/Fouls";
import { Formation } from "./TabPanes/Formation";

const { TabPane } = Tabs;

interface IProps {
    form: WrappedFormUtils;
}

function ContributorForm({ form }: IProps) {
    const history = useHistory();
    const params = useParams<{ id: string }>();

    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<FormDataProps>(defaultDataSource);
    const [contributorTypeDescription, setContributorTypeDescription] = useState("");
    const [accessValidation, setAccessValidation] = useState("");
    const [contributorTypes, setContributorTypes] = useState<any[]>([]);
    const [establishments, setEstablishments] = useState<any[]>([]);
    const [typesContract, setTypesContract] = useState<ContractTypeProps[]>([]);
    const [countries, setCountries] = useState<any[]>([]);
    const [banks, setBanks] = useState<any[]>([]);
    const [functionalGroups, setFunctionalGroups] = useState<FunctionalGroupsProps[]>([]);
    const [conventionCategories, setConventionCategories] = useState<ConventionCategoriesProps[]>([]);
    const [showModalAccessValidation, setShowModalAccessValidation] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let formValues = defaultDataSource;
            if (params.id) {
                formValues = await getContributor(params.id);
            }
            getQuerys(formValues);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    async function getContributor(id: string) {
        setIsLoading(true);

        const response = await api_fetch({
            endPoint: "/Gestor/gereformulario.php",
            method: "POST",
            params: {
                accao: "dados",
                id,
                formulario: "qa_ficha_colaborador.xml",
            },
        });

        let formValues = defaultDataSource;
        let _accessValidation = "";

        if (response.status === "SUCCESS") {
            const { valores } = response.response;
            formValues = valores?.["ficha_colaborador"];

            // funcoes_colab
            const funcoes_colab_data_source = Utilitaries.toArray(formValues?.funcoes_colab?.funcao_colab)?.map(
                (item: any, index: number) => ({ ...item, key: index + 1 })
            );
            formValues.funcoes_colab.funcao_colab = funcoes_colab_data_source;

            // documentos
            const docs_colab_data_source = Utilitaries.toArray(formValues?.docs_colaboradores?.doc_colaborador)?.map(
                (item: any, index: number) => ({ ...item, key: index + 1 })
            );
            formValues.docs_colaboradores.doc_colaborador = docs_colab_data_source;

            // ausencia
            const ausencia_data_source = Utilitaries.toArray(formValues?.ausencia?.ausencia)?.map(
                (item: any, index: number) => ({ ...item, key: index + 1 })
            );

            formValues = {
                ...formValues,
                linha: {
                    ...formValues.linha,
                    ausencia: ausencia_data_source,
                },
            };

            // faltas
            const falta_data_source = Utilitaries.toArray(formValues?.falta?.falta)
                ?.map((item: any, index: number) => ({ ...item, key: index + 1 }))
                .sort((a: any, b: any) => {
                    return a.key < b.key ? 1 : -1;
                });

            formValues = {
                ...formValues,
                linha: {
                    ...formValues.linha,
                    falta: falta_data_source,
                },
            };

            // formacoes_externa
            const formacoes_externa_data_source = Utilitaries.toArray(formValues?.formacoes_externa?.formacao_externa)
                ?.map((item: any, index: number) => ({ ...item, key: index + 1 }))
                .sort((a: any, b: any) => {
                    return a.key < b.key ? 1 : -1;
                });
            formValues.formacoes_externa.formacao_externa = formacoes_externa_data_source;

            // horas_extras
            formValues.horas_extras.hora_extra = Utilitaries.toArray(formValues?.horas_extras?.hora_extra)?.map(
                (item: any, index: number) => ({ ...item, key: index + 1 })
            );

            // Historico
            const prog_contratual = Utilitaries.toArray(formValues?.progs_contratuais?.prog_contratual)?.map(
                (item: any, index: number) => ({ ...item, key: index + 1 })
            );

            const prog_carreira = Utilitaries.toArray(formValues?.progs_carreiras?.prog_carreira)?.map(
                (item: any, index: number) => ({ ...item, key: index + 1 })
            );

            const prog_funcional = Utilitaries.toArray(formValues?.progs_funcionais?.prog_funcional)?.map(
                (item: any, index: number) => ({ ...item, key: index + 1 })
            );

            const prog_remunatoria = Utilitaries.toArray(formValues?.progs_remunatorias?.prog_remunatoria)?.map(
                (item: any, index: number) => ({ ...item, key: index + 1 })
            );
            formValues = {
                ...formValues,
                progs_contratuais: {
                    prog_contratual,
                },
                progs_carreiras: {
                    prog_carreira,
                },
                progs_funcionais: {
                    prog_funcional,
                },
                progs_remunatorias: {
                    prog_remunatoria,
                },
            };

            setFormData(formValues);
            _accessValidation = formValues.acessos_validacao;
        }

        setAccessValidation(_accessValidation);
        setIsLoading(false);

        if (formValues.convenc_coletiva) {
            getConventionCategories(formValues.convenc_coletiva);
        }

        return formValues;
    }

    async function getQuerys(formValues: FormDataProps) {
        api_fetch({
            endPoint: "/Gestor/gerelistasdinamicas.php",
            method: "POST",
            params: {
                accao: "consultar",
                nome: "tipo_colaborador",
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                const { lista } = response.response;
                const listContributorType = Utilitaries.toArray(lista?.linha);

                setContributorTypes(Utilitaries.toArray(listContributorType));

                if (formValues.tipo_colaborador) {
                    const selectedContributorType = listContributorType.find(
                        (item: any) => item.id_tipo_colaborador === formValues.tipo_colaborador
                    );

                    if (selectedContributorType) {
                        setContributorTypeDescription(selectedContributorType.designacao);
                    }
                }
            }
        });

        api_fetch({
            endPoint: "/Gestor/gerelistas.php",
            method: "POST",
            params: {
                accao: "lista",
                lista: "lista_estabelecimentos",
                id_instituicao: "1",
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                const { dados } = response.response;
                setEstablishments(Utilitaries.toArray(dados?.linha));
            }
        });

        api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "tipo_contrato_ficha_colaborador",
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                const { result } = response.response;
                setTypesContract(Utilitaries.toArray(result));
            }
        });

        api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "pais",
                _valor_campo_: formValues.entidade.moradas.morada?.pais ?? "",
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                const { result } = response.response;
                setCountries(Utilitaries.toArray(result));
            }
        });

        api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "bancos",
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                const { result } = response.response;
                setBanks(Utilitaries.toArray(result));
            }
        });

        api_fetch({
            endPoint: "/Gestor/geremanualfuncoes.php",
            method: "POST",
            params: {
                accao: "funcoes",
                todos_utilizadores: true,
                pagina: 1,
                resultados_pagina: 0,
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                const { linha } = response.response;
                setFunctionalGroups(Utilitaries.toArray(linha));
            }
        });
    }

    async function getConventionCategories(collectiveConvention: any) {
        const response = await api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "categorias_convencao",
                convencao: collectiveConvention,
            },
        });

        if (response.status === "SUCCESS") {
            setConventionCategories(response.response.result);
        }
    }

    function goBack() {
        history.push({
            pathname: "/function.manual/index/2",
        });
    }

    function validateNib(value: any) {
        if (value) {
            const isNumber = /^\d+$/.test(value);

            if (value?.toString()?.split("")?.length !== 21 || !isNumber) {
                message.error("NIB deve ter 21 dígitos numéricos");
                return false;
            }
        }
        return true;
    }

    function validateIban(value: any) {
        if (value) {
            const firstTwoCharater = value.slice(0, 2);
            const rest = value.substring(2);
            if (!/^\d+$/.test(rest?.replace(/\s/g, "")) || !/^[a-zA-Z]+$/.test(firstTwoCharater)) {
                message.error("Os dois primeiro digitos do IBAN deve ser letras e os restantes números");

                return false;
            }

            if (value?.toString()?.replace(/\s/g, "")?.split("")?.length !== 25) {
                message.error("IBAN deve ter 25 carateres");
                return false;
            }
        }
        return true;
    }

    function handleSave() {
        form.validateFields(async (err, values) => {
            if (!err) {
                if (!validateIban(values.iban) || !validateNib(values.nib)) {
                    return;
                }

                let ficha_colaborador: any = {
                    tipo_utilizador: values.tipo_utilizador,
                    tipo_colaborador: values.tipo_colaborador,
                    username: values.username,
                    senha: values.senha,
                    sexo: values.sexo,
                    status: values.status,
                    motivo_suspensao: values.motivo_suspensao,
                    estab: values.estab,
                    estabelecimento_defeito: formData.estabelecimento_defeito || "",
                    tipo_contracto: values.tipo_contracto,
                    exclusividade: values.exclusividade,
                    entidade: {
                        nome_completo: values.nome_completo,
                        nome: values.nome,
                        tlf: values.tlf,
                        tlm: values.tlm,
                        email: values.email,
                        iban: values.iban,
                        nib: values.nib,
                        bank: values.bank,
                        niss: values.niss,
                        nif: values.nif,
                        data_nascimento: values.data_nascimento,
                        moradas: {
                            morada: {
                                pais: values.pais,
                                distrito: values.distrito,
                                concelho: values.concelho,
                                freguesia: values.freguesia,
                                arruamento: values.arruamento,
                                c_postal4: values.c_postal4,
                                c_postal3: values.c_postal3,
                                porta: values.porta,
                                andar: values.andar,
                            },
                        },
                    },
                    habilitacao: values.habilitacao,
                    data_admissao_servico: values.data_admissao_servico,
                    inps: values.inps,
                    num_mecanografico: values.num_mecanografico,
                    escalao: values.escalao,
                    tipo_contrato: values.tipo_contrato,
                    modalidade: values.modalidade,
                    afetacao: values.afetacao,
                    modelo: values.modelo,
                    horas_trabalho: values.horas_trabalho,
                    trab_turnos: values.trab_turnos,
                    dias_trabalho: Utilitaries.toString(values.dias_trabalho, " "),
                    acessos_validacao: formData.acessos_validacao,
                    funcoes_colab: {
                        funcao_colab: formData.funcoes_colab.funcao_colab,
                    },
                    docs_colaboradores: {
                        doc_colaborador: formData.docs_colaboradores.doc_colaborador,
                    },
                    progs_contratuais: {
                        prog_contratual: formData.progs_contratuais.prog_contratual,
                    },
                    progs_carreiras: {
                        prog_carreira: formData.progs_carreiras.prog_carreira,
                    },
                    progs_funcionais: {
                        prog_funcional: formData.progs_funcionais.prog_funcional,
                    },
                    progs_remunatorias: {
                        prog_remunatoria: formData.progs_remunatorias.prog_remunatoria,
                    },
                    horas_extras: {
                        hora_extra: formData.horas_extras.hora_extra,
                    },
                    linha: {
                        ausencia: formData.linha.ausencia,
                        falta: formData.linha.falta,
                    },
                    convenc_coletiva: values.convenc_coletiva,
                    categoria: values.categoria,
                    nivel: values.nivel,
                    info_nivel: values.info_nivel,
                    data_vigor: values.data_vigor,
                    formacoes_externa: {
                        formacao_externa: formData.formacoes_externa.formacao_externa,
                    },
                };

                if (params.id) {
                    // ficha_colaborador["id_ficha_colaborador"] = params.id;
                    ficha_colaborador = {
                        id_ficha_colaborador: params.id,
                        ...ficha_colaborador,
                    };
                }

                if (formData?.entidade.identidade) {
                    ficha_colaborador.entidade["identidade"] = formData?.entidade.identidade;
                }
                if (formData.entidade.moradas.morada?.idmorada) {
                    ficha_colaborador.entidade.moradas.morada["idmorada"] = formData.entidade.moradas.morada?.idmorada;
                }

                setIsSaving(true);

                const resp = await api_fetch({
                    endPoint: "/Gestor/gereprocesso.php",
                    method: "POST",
                    params: {
                        accao: "guardar",
                        formulario: "qa_ficha_colaborador.xml",
                        dados: JSON.stringify({ ficha_colaborador }),
                    },
                });

                setIsSaving(false);

                if (resp.status === "SUCCESS") {
                    message.success("Ficha do funcionário gravado com sucesso!!");
                    goBack();
                } else if (resp.response?.erro) {
                    message.error(resp.response?.erro);
                } else message.error("Erro ao gravar ficha do funcionário!!");
            } else {
                Object.keys(err).forEach((key) => {
                    const errorMessage = err[key]?.["errors"]?.[0]?.message;
                    if (errorMessage) {
                        message.error(errorMessage);
                    }
                });
            }
        });
    }

    function handleCloseValidationPermissionModal(isCancel: boolean, id?: string) {
        setShowModalAccessValidation(false);

        if (!isCancel) {
            setFormData({
                ...formData,
                acessos_validacao: id || "",
            });
        }
    }

    function onChange({ dataIndex, row_key, table_key1, table_key2, value }: OnChangeProps) {
        debugger;
        if (row_key && table_key1 && table_key2) {
            const _formData: any = { ...formData };
            const dataSource = _formData?.[table_key1]?.[table_key2];

            const newDataSource: any[] = dataSource?.map((item: any) => {
                if (item.key === row_key) {
                    if (!dataIndex && typeof value === "object") {
                        item = {
                            ...item,
                            ...value,
                        };
                    } else {
                        item[dataIndex] = value;
                    }
                }
                return item;
            });

            setFormData({
                ..._formData,
                [table_key1]: {
                    ..._formData[table_key1],
                    [table_key2]: newDataSource,
                },
            });
        } else {
        }
    }

    function handleDelete(key: number, table_key1: string, table_key2: string) {
        const _formData: any = { ...formData };

        const dataSource: any[] = _formData?.[table_key1]?.[table_key2] || [];
        const newDataSource = dataSource.filter((item: any) => item.key !== key);

        setFormData({
            ..._formData,
            [table_key1]: {
                ..._formData[table_key1],
                [table_key2]: newDataSource,
            },
        });
    }

    function handleAdd(table_key1: string, table_key2: string) {
        const _formData: any = { ...formData };

        const dataSource: any[] = _formData?.[table_key1]?.[table_key2] || [];
        const newData = { key: dataSource.length + 1 };
        const newDataSource = [newData, ...dataSource];

        setFormData({
            ..._formData,
            [table_key1]: {
                ..._formData[table_key1],
                [table_key2]: newDataSource,
            },
        });
    }

    const { getFieldValue } = form;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <PageHeader onBack={goBack} title="Ficha do funcionário" />
            <Tabs defaultActiveKey="1">
                <TabPane tab="Informação Pessoal" key="1">
                    <PersonalInfo
                        form={form}
                        formData={formData}
                        contributorTypes={contributorTypes}
                        establishments={establishments}
                        typesContract={typesContract}
                        countries={countries}
                        banks={banks}
                        contributorTypeDescription={contributorTypeDescription}
                        setContributorTypeDescription={setContributorTypeDescription}
                        accessValidation={accessValidation}
                        showModalAccessValidation={() => setShowModalAccessValidation(true)}
                    />
                </TabPane>
                {getFieldValue("tipo_utilizador") === "2" && contributorTypeDescription !== "Sócio" && (
                    <TabPane tab="Funções e Convenções" key="2">
                        <FuntionsConventions
                            form={form}
                            formData={formData}
                            functionalGroups={functionalGroups}
                            handleAdd={handleAdd}
                            handleDelete={handleDelete}
                            onChange={onChange}
                            conventionCategories={conventionCategories}
                            getConventionCategories={getConventionCategories}
                        />
                    </TabPane>
                )}
                {getFieldValue("tipo_utilizador") === "2" && contributorTypeDescription !== "Sócio" && (
                    <TabPane tab="Documentos" key="3">
                        <Documents
                            formData={formData}
                            handleAdd={handleAdd}
                            handleDelete={handleDelete}
                            onChange={onChange}
                        />
                    </TabPane>
                )}
                {getFieldValue("tipo_utilizador") === "2" && contributorTypeDescription === "Trabalhador" && (
                    <TabPane tab="Histórico" key="4">
                        <Historic
                            formData={formData}
                            typesContract={typesContract}
                            handleAdd={handleAdd}
                            handleDelete={handleDelete}
                            onChange={onChange}
                            functionalGroups={functionalGroups}
                            conventionCategories={conventionCategories}
                        />
                    </TabPane>
                )}

                {getFieldValue("tipo_utilizador") === "2" && contributorTypeDescription !== "Sócio" && (
                    <TabPane tab="Horas Extraordinárias" key="5">
                        <OverTime
                            formData={formData}
                            handleAdd={handleAdd}
                            handleDelete={handleDelete}
                            onChange={onChange}
                        />
                    </TabPane>
                )}
                {getFieldValue("tipo_utilizador") === "2" && (
                    <TabPane tab="Ausências Programadas" key="6">
                        <ProgrammedAbsences
                            formData={formData}
                            handleAdd={handleAdd}
                            handleDelete={handleDelete}
                            onChange={onChange}
                        />
                    </TabPane>
                )}
                {getFieldValue("tipo_utilizador") === "2" && contributorTypeDescription !== "Sócio" && (
                    <TabPane tab="Faltas" key="7">
                        <Fouls
                            form={form}
                            formData={formData}
                            handleAdd={handleAdd}
                            handleDelete={handleDelete}
                            onChange={onChange}
                        />
                    </TabPane>
                )}
                {getFieldValue("tipo_utilizador") === "2" && contributorTypeDescription !== "Sócio" && (
                    <TabPane tab="Formações" key="8">
                        <Formation
                            formData={formData}
                            handleAdd={handleAdd}
                            handleDelete={handleDelete}
                            onChange={onChange}
                        />
                    </TabPane>
                )}
            </Tabs>
            <Divider />
            <Row gutter={18}>
                <Col xs={24} sm={12}>
                    <div className="qa-group-actions-left">
                        <Button icon="save" loading={isSaving} type="primary" onClick={handleSave}>
                            Guardar
                        </Button>
                    </div>
                </Col>
            </Row>

            {showModalAccessValidation && (
                <ValidationPermissionModal
                    id={formData.acessos_validacao}
                    isVisible={showModalAccessValidation}
                    handleClose={handleCloseValidationPermissionModal}
                    functionalGroups={functionalGroups}
                />
            )}
        </div>
    );
}

export default Form.create<IProps>({})(ContributorForm);
