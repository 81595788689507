import React, { memo, useEffect } from "react";
import { Form, Spin, Icon } from "antd";
import { FormLabel, getLabel } from "../Components/FormLabel";
import { useData } from "../hooks/useData";
import { CommunComponentsProps } from "../../../types/common";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { useInputHandle } from "../hooks/InputHandle";
import { Group } from "../Components/Group";
// import { downFile } from "../../../../modules/process_execution/constants/utils";
import { compareProps, getFullPathRow } from "../handle/inputHandle";
import { Utilitaries } from "../../../../business";
import { mapLoad } from "../../../utils/loadMap";

interface ComponentsPropertiesProps extends CommunComponentsProps {}

const _QADataDisplay: React.FC<ComponentsPropertiesProps> = ({
    // label, itemData, numbersOfColumnsToRender ,
    componentsProperties,
    renderContext,
    formState,
    handleOnInputChangeValue,
    formInputDependenceState,
    inputCalcTrigger,
    tableRow,
    rootElementAttribute,
}) => {
    print_when_component_is_rendered(componentsProperties);

    // if (renderContext === "header" && itemData?.key === "actividade") {
    //     itemData["@etiqueta"] = "Tarefa";
    // }

    // if ((itemData?.isColumn === true && renderContext !== "inputGroup") || !itemData?.["@etiqueta"]) {
    //     if (itemData.value) {
    //         if (itemData.value.length > 30 && numbersOfColumnsToRender > 3) {
    //             return (
    //                 <Tooltip title={itemData.value}>
    //                     <div
    //                         style={{
    //                             textOverflow: "ellipsis",
    //                             // width: " 250px",
    //                             whiteSpace: "nowrap",
    //                             overflow: "hidden",
    //                         }}
    //                     >
    //                         {itemData.value}
    //                     </div>
    //                 </Tooltip>
    //             );
    //         } else {
    //             return itemData.value;
    //         }
    //     }
    //     return null;
    // } else {
    // if (typeof itemData?.value !== "number" && itemData?.value?.includes("\n")) {
    //     return (
    //         <Form.Item label={label}>
    //             {/* <Descriptions>
    //                 <Descriptions.Item> */}
    //             <pre>
    //                 {typeof itemData?.value === "object" ? itemData?.value[0]?.[itemData.key] : itemData?.value}
    //             </pre>
    //             {/* </Descriptions.Item>
    //             </Descriptions> */}
    //         </Form.Item>
    //     );
    // }

    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    const displayValue = () => {
        return Utilitaries.isEmpty(value) ? "" : value;
        // if (componentsProperties.data) {
        //     if (status === "FETCHING") {
        //         return <Spin size="small" />;
        //     } else {
        //         return Utilitaries.isEmpty(componentsProperties?.value) ? "" : componentsProperties.value;
        //     }
        // }
        // if (renderContext === "column") {
        //     return tableRow?.row_data?.[componentsProperties.key];
        // }

        // if (typeof componentsProperties?.value === "object") {
        //     return componentsProperties?.value?.[0]?.[componentsProperties.key];
        // }

        // return Utilitaries.isEmpty(componentsProperties?.value) ? "" : componentsProperties?.value;
    };

    const { data, status: dataStatus } = useData({
        endPoint: "execQuery.php",
        componentsProperties,
        state: formState,
        rootElementAttribute,
    });

    useEffect(() => {
        if (data && dataStatus !== "IDLE" && componentsProperties.data_query) {
            handleOnInputChangeValue({
                value: data?.[0]?.[componentsProperties.key_list || componentsProperties.key],
                componentsProperties,
                row_key: tableRow?.row_key,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);
    const full_path = tableRow?.row_key ? fullPathRow : componentsProperties?.full_path;

    const {
        // data: whenValueChangeData,
        status: whenValueChangeStatus,
        componentValue,
        hasUpdatedValue,
        required,
        visible,
        relevant,
        calculated,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[full_path || ""],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
    });

    useEffect(() => {
        if (hasUpdatedValue > 0 && whenValueChangeStatus !== "IDLE") {
            handleOnInputChangeValue({
                value: componentValue,
                componentsProperties,
                row_key: tableRow?.row_key,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUpdatedValue]);

    useEffect(() => {
        if (
            componentsProperties.calculate &&
            (!Utilitaries.isEmpty(inputCalcTrigger?.[componentsProperties?.full_path || ""]) ||
                !Utilitaries.isEmpty(inputCalcTrigger?.[fullPathRow]) ||
                componentsProperties.persist === "nao")
        ) {
            handleOnInputChangeValue({
                value: calculated,
                componentsProperties,
                row_key: tableRow?.row_key,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculated]);

    if (!visible || !relevant) {
        return null;
    }

    const antIcon = <Icon type="loading" spin />;
    // style={{ fontSize: 24 }}
    // line-height: inherit; for item

    const isBold = Utilitaries.toString(componentsProperties?.style).includes("negrito");

    // const customStyle: any = { margin: 0 };
    const customStyle: any = { margin: 0, whiteSpace: "pre-wrap", maxWidth: "300px" };

    if (isBold) {
        customStyle.fontWeight = "bold";
    }
    if (renderContext === "column") {
        return (
            <pre style={{ ...customStyle }}>
                {displayValue()}{" "}
                {mapLoad([dataStatus, whenValueChangeStatus]) && <Spin indicator={antIcon} size="small" />}
            </pre>
        );
    }

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={required} renderContext={renderContext}>
                <pre style={{ ...customStyle }}>
                    {displayValue()}{" "}
                    {mapLoad([dataStatus, whenValueChangeStatus]) && <Spin indicator={antIcon} size="small" />}
                </pre>
            </FormLabel>
        </Group>
    );
};

export const QADataDisplay = memo(_QADataDisplay, (prev, next) => compareProps<ComponentsPropertiesProps>(prev, next));
