import { Avatar, Button, Card, Col, Descriptions, Form, InputNumber, message, Row, Select } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Utilitaries } from "../../../business";
import { api_fetch } from "../../utils/HttpRequest/request";
import RentalSchedule from "./components/RentalSchedule";
import { RENTAL_TYPE } from "./constants";
import { getRentalTypeColor } from "./formFunctions";
// import { Col, Row, Form, Select } from "antd";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";
// import listPlugin from "@fullcalendar/list";
// import ptPtLocate from "@fullcalendar/core/locales/pt-br";
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
// import "@fullcalendar/list/main.css";

const { Option } = Select;

const getRentalTypeAvatar = (type: string) => {
    if (type === RENTAL_TYPE.AVAILABLE) {
        return (
            <Avatar
                shape="square"
                size="small"
                style={{
                    backgroundColor: getRentalTypeColor(type),
                    borderColor: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderStyle: "solid",
                }}
            />
        );
    }
    return <Avatar shape="square" size="small" style={{ backgroundColor: getRentalTypeColor(type) }} />;
};

const RentalReservation = () => {
    const [propertiesLoading, setPropertiesLoading] = useState(true);
    const [propertiesDataSource, setPropertiesDataSource] = useState([]);

    const [propertieValue, setPropertieValue] = useState<string>("1");
    const [year, setYear] = useState<number>();
    // const [refreshSchedule, setRefreshSchedule] = useState(true);

    useEffect(() => {
        const loadProperties = async () => {
            try {
                setPropertiesLoading(true);
                const params = {
                    accao: "imoveis",
                };

                const {
                    response,
                    status,
                    message: messageError,
                } = await api_fetch({
                    endPoint: `Gestor/gerealuguer.php`,
                    method: "POST",
                    params,
                });

                if (status === "SUCCESS") {
                    const result = response?.result;
                    if (result) {
                        if (result.erro) {
                            message.error(Utilitaries.toString(result.erro) || "Ocorreu um erro");
                        } else {
                            setPropertiesDataSource(Utilitaries.toArray(result?.imoveis?.linha));
                        }
                    }
                } else if (status === "ERROR") {
                    message.error(messageError || "Ocorreu um erro");
                }

                setPropertiesLoading(false);
            } catch (error) {
                setPropertiesLoading(false);
            }
        };

        loadProperties();
    }, []);

    return (
        <>
            <h3>Aluguer de imóveis QualityAlive</h3>

            <Row gutter={[16, 8]} type="flex" align="bottom">
                <Col md={8}>
                    <Form.Item label="Imovel">
                        <Select
                            showSearch
                            allowClear
                            dropdownMatchSelectWidth={false}
                            style={{ width: "100%" }}
                            loading={propertiesLoading}
                            value={propertieValue}
                            onChange={(value: any) => setPropertieValue(value)}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                const children = option.props.children?.toString() || "";
                                return children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
                            }}
                        >
                            {propertiesDataSource?.map((data: any, index) => {
                                const optionText = data["nome"];
                                return (
                                    <Option key={`imovel-${index}`} value={data["id_rent_imovel"]} title={optionText}>
                                        {optionText}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={4}>
                    <Form.Item label="Ano">
                        <InputNumber
                            style={{ width: "100%" }}
                            value={year}
                            onBlur={(value) => setYear(Utilitaries.toNumber(value.target.value))}
                        />
                    </Form.Item>
                </Col>
                {/* <Col md={4}>
                    <Form.Item>
                        <Button
                            icon="refresh"
                            onClick={() => {
                                setRefreshSchedule((prev) => !prev);
                            }}
                        >
                            Atualizar
                        </Button>
                    </Form.Item>
                </Col> */}
            </Row>

            <Row gutter={[16, 8]}>
                <Col md={4}>
                    <Descriptions title="Legenda" size="small" column={1} colon={false}>
                        <Descriptions.Item label={getRentalTypeAvatar(RENTAL_TYPE.AVAILABLE)}>
                            Disponível
                        </Descriptions.Item>
                        <Descriptions.Item label={getRentalTypeAvatar(RENTAL_TYPE.UNAVAILABLE)}>
                            Indisponível
                        </Descriptions.Item>
                        <Descriptions.Item label={getRentalTypeAvatar(RENTAL_TYPE.RESERVED)}>
                            Reservado
                        </Descriptions.Item>
                        <Descriptions.Item label={getRentalTypeAvatar(RENTAL_TYPE.MAINTENANCE)}>
                            Manutenção
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col xs={20}>
                    <RentalSchedule propertie={propertieValue} year={year} />
                </Col>
            </Row>
        </>
    );
};

export default RentalReservation;
