import { componentType } from "../../../modules/process_execution/constants";
import { sendHttpRequest } from "../../http.request";
import { COMPONENT_TYPE, GRAPHIC_ELEMENT } from "./constants";
import { input_validations } from "./validations";
import moment from "moment";
import { isEmpty } from "../../../modules/process_execution/utils/fields";
import { toArray } from "../../util";
import { Utilitaries } from "../../../business";

export const getDynamicType = (input, inputProps) => {
    let dynamicType = null;

    if (input["@elemento_grafico"]) {
        if (input["@elemento_grafico"] === GRAPHIC_ELEMENT.lista_escolha_unica) {
            if (input["@tipo"] === COMPONENT_TYPE.lista_dinamica) {
                dynamicType = COMPONENT_TYPE.lista_dinamica;
            } else {
                dynamicType = COMPONENT_TYPE.select;
            }
        } else if (input["@elemento_grafico"] === GRAPHIC_ELEMENT.botoes_seleccao) {
            dynamicType = COMPONENT_TYPE.radio;
        } else if (input["@elemento_grafico"] === GRAPHIC_ELEMENT.etiqueta) {
            dynamicType = COMPONENT_TYPE.etiqueta;
        } else if (input["@elemento_grafico"] === GRAPHIC_ELEMENT.areatexto) {
            dynamicType = COMPONENT_TYPE.textArea;
        } else if (input["@elemento_grafico"] === GRAPHIC_ELEMENT.combobox) {
            dynamicType = COMPONENT_TYPE.select;
        } else if (input["@elemento_grafico"] === GRAPHIC_ELEMENT.botoes_escolha_multipla) {
            if (input["@tipo"] === COMPONENT_TYPE.boolean) {
                dynamicType = COMPONENT_TYPE.boolean;
            } else {
                dynamicType = COMPONENT_TYPE.checkbox_group;
            }
        } else if (input["@elemento_grafico"] === GRAPHIC_ELEMENT.botao) {
            dynamicType = COMPONENT_TYPE.botao;
        } else if (input["@elemento_grafico"] === GRAPHIC_ELEMENT.sugestao) {
            dynamicType = COMPONENT_TYPE.sugestao;
        } else if (input["@elemento_grafico"] === GRAPHIC_ELEMENT.escolha_unica) {
            dynamicType = COMPONENT_TYPE.select;
        }
    } else if (input["@tipo"] === COMPONENT_TYPE.lista_estatica) {
        dynamicType = COMPONENT_TYPE.select;
    } else if (input["@tipo"] === COMPONENT_TYPE.link) {
        dynamicType = COMPONENT_TYPE.link;
        // } else if (
        // 	input["@tipo"] === "conjunto" &&
        // 	input["@botoes_transacao"] === "sem_botoes"
        // ) {
        // 	dynamicType = "datagrid";
    } else if (input["@tipo"] === COMPONENT_TYPE.relatorio || input["@relatorio"]) {
        dynamicType = COMPONENT_TYPE.relatorio;
    } else if (input["@popupform"]) {
        if (input["@etiqueta"] && ((inputProps && !inputProps.inTable) || !inputProps)) {
            dynamicType = COMPONENT_TYPE.pop_up_label;
        } else {
            dynamicType = COMPONENT_TYPE.pop_up;
        }
    } else if (input["@dados"] && ![COMPONENT_TYPE.datagrid, COMPONENT_TYPE.conjunto].includes(input["@tipo"])) {
        // se tem dados pode ser select -> outros campos tb tem dados como datagrid
        dynamicType = COMPONENT_TYPE.select;
    } else if (input["@mascara"] && input["@mascara"] === "hora") {
        dynamicType = COMPONENT_TYPE.time;
    } else if (input["@tipo"] === "texto") {
        dynamicType = COMPONENT_TYPE.textArea;
    }

    // if (inputProps.inputType && inputProps.inputType === "etiqueta_value") {
    // 	dynamicType = "label"
    // }

    return dynamicType;
};

export const isInRooField = ({ key, formData, options }) => {
    if (key?.startsWith("//")) return true;

    if (!options) return false;

    if (options?.items) {
        const { key: tableKey, table } = options?.items;
        const keyParam = key?.replace(/[.|/]/g, "");
        //if is in table return false
        if (table && tableKey) {
            if (!options.brothers) return false;
            const checkInTable = options.brothers.find((item) => item === keyParam);
            if (checkInTable) return false;
        }
        //is in formData return true
        if (formData?.[keyParam]) {
            return true;
        }
    }
    return false;
};

const getValuesByKey = (key, formData, options, formInputs) => {
    if (!formData) {
        return "";
    }

    // const inRoot = key?.startsWith("//");
    // ;
    const inRoot = isInRooField({ key, formData, formInputs, options });

    key = key?.replace(/[.|/]/g, "");

    if (options && options.inTable && !inRoot) {
        const { data, key: tableKey, table } = options.items;
        if (tableKey) {
            const tableData = toArray(formData[table][tableKey]);
            const value = !tableData[data.__key__][key]
                ? typeof tableData[data.__key__][key] === "number"
                    ? tableData[data.__key__][key]
                    : ""
                : tableData[data.__key__][key];
            return {
                value,
                type: "table",
            };
        }
    } else {
        let value = !formData[key] ? (typeof formData[key] === "number" ? formData[key] : "") : formData[key];

        if (!value) {
            value = getItemValue({ itemKey: key, formData, objectInputs: formInputs });
        }

        return {
            value,
            type: "form",
        };
    }

    return "";
};

const getValuesByKeyInTable = ({ key, jsExp, formData, options, formInputs = {} }) => {
    if (!formData) {
        return "";
    }

    let tableFirstKey = null;
    let tableLastKey = null;
    // get table
    let table = Object.entries(formInputs).find(([tableKey, item]) => {
        if (item[key]) {
            if (item["@tipo"] === "datagrid") {
                tableFirstKey = "linha";
                tableLastKey = tableKey;
            } else tableFirstKey = tableKey;
            return true;
        } else return false;
    });

    if (!table && jsExp?.startsWith("sum")) {
        let allTable = { ...formInputs };

        const parts = jsExp
            ?.replace("sum(", "")
            ?.split("/")
            ?.filter((it) => !Utilitaries.isEmpty(it));

        const possibleTabkeFirstKey = parts[0];

        table = Object.entries(allTable).find(([tableKey, item]) => {
            if (item[possibleTabkeFirstKey] && item[possibleTabkeFirstKey][key]) {
                tableLastKey = possibleTabkeFirstKey;
                tableFirstKey = tableKey;
                return true;
            } else return false;
        });
    }

    // if in inputGroup
    if (!table) {
        let allTable = { ...formInputs };

        table = Object.entries(allTable).find(([inputKey, inputs]) => {
            if (inputs["@tipo"] === "conjunto") {
                const tableSecondKey = Object.keys(inputs).find(
                    (tableAttributes) =>
                        !tableAttributes.startsWith("@") &&
                        !Array.isArray(inputs[tableAttributes]) &&
                        typeof inputs[tableAttributes] === "object"
                );

                if (inputs[tableSecondKey][key]) {
                    tableFirstKey = inputKey;
                    tableLastKey = tableSecondKey;
                    return true;
                }
            }
            return false;
        });
    } else {
        return {
            values: toArray(formData?.[tableFirstKey]?.[tableLastKey]).map((item, index) => ({
                key,
                value: item[key],
                tableKey: tableFirstKey,
                tableSubKey: tableLastKey,
                tableIndex: index,
            })),
            tableFirstKey,
            tableLastKey,
        };
    }

    return {
        values: toArray(formData?.[tableFirstKey]?.[tableLastKey]).map((item, index) => ({
            key,
            value: item[key],
            tableKey: tableFirstKey,
            tableSubKey: tableLastKey,
            tableIndex: index,
        })),
        tableFirstKey,
        tableLastKey,
    };
};

export const hasInputAction = ({ key, input, formData, formInputs, options }) => {
    let inputList = formInputs;
    let inTable = !!options || !!options?.inTable;

    if (!input) {
        if (options.inTable) {
            const { key: tableKey, table } = options.items;
            input = formInputs?.[table]?.[tableKey]?.[key];

            inputList = formInputs?.[table]?.[tableKey];
            inTable = true;
        } else {
            input = formInputs[key];
        }
    }

    if (!input || typeof input !== "object" || !Object.keys(input).find((it) => it === "@quando_valor_alterado")) {
        return {
            hasActions: false,
        };
    }

    let actions = input[Object.keys(input).find((it) => it === "@quando_valor_alterado")];
    actions = actions.split(";");

    // return actions;
    const fieldDeps = [];
    for (const action of actions) {
        const inputs = Object.entries(inputList);
        for (const [key, attributes] of inputs) {
            let deps = null;
            let depsKey = null;

            for (const [key, value] of Object.entries(attributes)) {
                if (key === `@accao_${action}` || (key === "@carregar_accao" && value === action)) {
                    deps = key;
                    break;
                } else if (typeof value === "object") {
                    const depsInValue = Object.keys(value).find((it) => it === `@accao_${action}`);
                    deps = depsInValue;
                    depsKey = key;
                    break;
                }
            }
            // const attributesKeys = attributes.
            // const deps = Object.keys(attributes).find((it) => it === `@accao_${action}`);
            if (deps) {
                const item = {
                    key,
                    deps,
                    depsKey,
                    attributes,
                    inTable,
                };

                fieldDeps.push(item);
            }
        }

        // Object.entries(inputList).forEach(([key, attributes]) => {
        //     //@accao_bota_estabelecimento
        //     //bota_estabelecimento
        //     const deps = Object.keys(attributes).find((it) => it === `@accao_${action}`);
        //     if (deps) {
        //         const item = {
        //             key,
        //             deps,
        //             attributes,
        //         };

        //         fieldDeps.push(item);
        //     }
        // });
    }

    //

    const fields = fieldDeps.map((field) => {
        // let [query, ...params] = field.attributes[field.deps].replace(/ +/g, "").split(",{");
        let query,
            params,
            action = "";
        if (field.attributes["@tipo"] === componentType.datagrid && field.attributes["@dados"]) {
            query = field.attributes["@dados"];
            params = field.attributes["@param_popup"]?.split(";");
        } else {
            const element = field.depsKey ? field.attributes[field.depsKey][field.deps] : field.attributes[field.deps];
            [query, ...params] = element.replace(/ +/g, "").split(",{");

            [action, query] = query.split("(");
        }

        params = Utilitaries.toArray(params).map((param) => {
            param = param.replace(/[}|)]/g, "").split(",");

            // get Value
            let queryParamKey, queryParamValue;

            if (param.length === 1) {
                queryParamKey = queryParamValue = param[0];
            } else {
                queryParamKey = param[0];
                queryParamValue = param[1];
            }

            let value = getValuesByKey(queryParamValue, formData, options);
            return {
                key: queryParamKey,
                ...value,
            };
        });

        return {
            ...field,
            action,
            query,
            params,
        };
    });

    return {
        hasActions: true,
        fields,
    };
};

export const getDataFormInputActions = async ({ key, id, input, formData, formInputs, options, rootElement }) => {
    const { fields, hasActions } = hasInputAction({ key, formInputs, input, formData, options });

    const listOfCall = [];

    if (!hasActions) {
        return listOfCall;
    }
    const fieldsNotRequestData = fields.filter((item) => item?.action === "auto_preenche_popup");

    for await (const field of fields) {
        const fieldType = getDynamicType(field.attributes, options) || field.attributes["@tipo"];

        if (fieldsNotRequestData.findIndex((item) => item.key === field.key) >= 0) {
            listOfCall.push({
                key: field.key,
                input: field,
                tipo: fieldType,
            });
            continue;
        }

        const body = new FormData();

        body.append("query", field.query);

        field.params.forEach((param) => {
            if (key === param.key) {
                body.append(param.key, id);
            } else if (param.value) body.append(param.key, param.value);
            else {
                //forceSendParamsEmpty
                // send zero only if is form key Id
                if (rootElement && param?.key === `id_${rootElement}`) {
                    body.append(param.key, 0);
                }
                // else if (param.key?.startsWith("id_"))
                // body.append(param.key, 0);
                else body.append(param.key, "");
            }
        });

        const { result } = await sendHttpRequest("POST", "/Gestor/execQuery.php", body);

        let value = "";

        if (
            fieldType === COMPONENT_TYPE.etiqueta ||
            fieldType === COMPONENT_TYPE.boolean ||
            fieldType === COMPONENT_TYPE.float ||
            (fieldType === COMPONENT_TYPE.select &&
                field.attributes?.["@dados"] &&
                field.attributes?.["@tipo"] === "integer") ||
            (!fieldType && (["float"].includes(field.attributes?.["@tipo"]) || !field.attributes?.["@tipo"]))
        ) {
            if (result && result[0]) {
                if (result[0]?.[field.attributes?.["@chave_lista"]]) {
                    value = result[0]?.[field.attributes?.["@chave_lista"]];
                } else if (result[0]?.[field.attributes?.["@valor_lista"]]) {
                    value = result[0]?.[field.attributes?.["@valor_lista"]];
                } else if (Object.keys(result[0]).length === 1) {
                    value = result[0][Object.keys(result[0])[0]];
                } else {
                    value = result[0]?.[field.key];
                }
            } else {
                value = false;
            }
        } else if (
            fieldType === COMPONENT_TYPE.conjunto ||
            fieldType === COMPONENT_TYPE.datagrid ||
            fieldType === COMPONENT_TYPE.select
        ) {
            value = result;
        } else {
            value = result[0];
        }
        if (fieldType === COMPONENT_TYPE.datagrid) {
            listOfCall.push({
                key: field.key,
                table: "linha",
                input: field,
                tipo: fieldType,
                value,
            });
        } else {
            listOfCall.push({
                key: field.key,
                input: field,
                tipo: fieldType,
                value,
            });
        }
    }

    return listOfCall;
};

export const getTableAction = ({ item, relKey }) => {
    if (relKey) {
        if (item[relKey]) {
            return {
                inputActionsName: relKey,
                valueDep: item[relKey],
            };
        } else {
            for (const key in item) {
                if (typeof item[key] === "object") {
                    const props = item[key][relKey];
                    if (props) {
                        return {
                            inputActionsName: relKey,
                            valueDep: props,
                        };
                    }
                }
            }
        }
    } else {
        for (const key in item) {
            if (key.startsWith("@accao_")) {
                return {
                    inputActionsName: key,
                    valueDep: item[key],
                };
            } else if (typeof item[key] === "object") {
                const props = Object.keys(item[key]).filter((item) => item.startsWith("@accao_"));
                if (Array.isArray(props)) {
                    return {
                        inputActionsName: props[0],
                        valueDep: item[key][props[0]],
                    };
                }
            }
        }
    }
    return {};
};

export const getElementAction = ({ item }) => {
    for (const key in item) {
        if (key.startsWith("@accao_")) {
            return {
                inputActionsName: key,
                valueDep: item[key],
            };
        }
    }
    return null;
};

export const getAllElementAction = ({ item }) => {
    const actions = [];
    for (const key in item) {
        if (key.startsWith("@accao_")) {
            actions.push({
                inputActionsName: key,
                valueDep: item[key],
            });
        }
    }
    return actions;
};

export const addDoubleQuotesToParams = (expression) => {
    // expression.replace(/{[\w[\],\s']+}|\w+(?=,)/g, '"$&"')
    // return expression.replace(/{[\w[\],\s']+,[\w[\],\s']+}|\w+(?=,)/g, '"$&"');
    return expression.replace(/{[\w[\],\s']+,(\w|[|\]|[À-ú]|,|\s|\-|')+}|[\w|:]+(?=,)/g, '"$&"');
};

export const hasInputDependence = ({ input, formData, formInputs, options }) => {
    if (typeof input !== "object") {
        return {
            hasActions: false,
        };
    }
    const actionKey = Object.keys(input).find((it) => it.startsWith("@accao_"));

    if (!actionKey) {
        return {
            hasActions: false,
        };
    }

    let action = input[actionKey];

    let [query, ...params] = action.replace(/ +/g, "").split(",{");

    [action, query] = query.split("(");

    params = params.map((param) => {
        param = param.replace(/[}|)]/g, "").split(",");

        // get Value
        let queryParamKey, queryParamValue;

        if (param.length === 1) {
            queryParamKey = queryParamValue = param[0];
        } else {
            queryParamKey = param[0];
            queryParamValue = param[1];
        }
        let value = getValuesByKey(queryParamValue, formData, options, formInputs);
        if (value?.value instanceof Date) {
            value.value = moment(value.value).format("YYYY-MM-DD");
        }
        return {
            key: queryParamKey,
            ...value,
        };
    });

    return {
        hasActions: true,
        action,
        query,
        params,
    };
};

export const getDataFormInputDependence = async ({
    key,
    input,
    formData,
    formInputs,
    options,
    action,
    hasActions,
    query,
    params,
}) => {
    if (!query || !params) {
        const result = hasInputDependence({ formInputs, input, formData, options });
        action = result.action;
        query = result.query;
        params = result.params;
        hasActions = result.hasActions;
    }

    if (!hasActions) {
        return "";
    }

    const body = new FormData();

    body.append("query", query);

    params.forEach((param) => {
        body.append(param.key, param.value);
    });

    const { result } = await sendHttpRequest("POST", "/Gestor/execQuery.php", body);

    let value = "";

    const fieldType = getDynamicType(input, options) || input["@tipo"];

    if (
        fieldType === COMPONENT_TYPE.etiqueta ||
        fieldType === COMPONENT_TYPE.boolean ||
        fieldType === COMPONENT_TYPE.float ||
        (fieldType === COMPONENT_TYPE.select && input?.["@dados"] && input?.["@tipo"] === "integer") ||
        (!fieldType && (["float"].includes(input?.["@tipo"]) || !input?.["@tipo"]))
    ) {
        if (result?.[0]) {
            if (result[0]?.[input?.["@chave_lista"]]) {
                value = result[0]?.[input?.["@chave_lista"]];
            } else if (result[0]?.[input?.["@valor_lista"]]) {
                value = result[0]?.[input?.["@valor_lista"]];
            } else if (Object.keys(result[0]).length === 1) {
                value = result[0][Object.keys(result[0])[0]];
            } else {
                value = result[0]?.[key];
            }
        }
    } else if (
        fieldType === COMPONENT_TYPE.conjunto ||
        fieldType === COMPONENT_TYPE.datagrid ||
        fieldType === COMPONENT_TYPE.select
    ) {
        value = result;
    } else {
        value = result[0];
    }

    return value;
};

export const hasInputCalculate = ({ input, formData, options, formInputs }) => {
    if (typeof input !== "object") {
        return {
            hasCalculation: false,
        };
    }

    const actionKey = Object.keys(input).find((it) => it.startsWith("@calcular"));

    if (!actionKey) {
        return {
            hasCalculation: false,
        };
    }

    let calculationExpression = input[actionKey];

    let params = calculationExpression.match(/\/\/\w+|\.\.\/\w+|(?<=\/)\w+(?=\))/g);
    let mergeWithParams = [];
    let allParams = [];
    let tablePath = {
        first: null,
        second: null,
    };
    let isList = false;

    if (calculationExpression?.startsWith("x_lista:")) {
        const listParams = calculationExpression?.match(/\/\w+/g);

        const param = listParams?.[1]?.replace(/\W/g, "");

        let { values, tableFirstKey, tableLastKey } = getValuesByKeyInTable({
            key: param,
            jsExp: null,
            formData,
            options,
            formInputs,
        });

        tablePath.first = tableFirstKey;
        tablePath.second = tableLastKey;
        isList = true;

        mergeWithParams.push(...values);
        allParams = mergeWithParams;
    } else {
        params = params?.map((param) => {
            const pattern = new RegExp(`sum(.*${param}.*?)`);

            param = param.replace(/\W/g, "");

            if (calculationExpression.match(pattern)) {
                const sumExpression = calculationExpression.match(/sum\(.*?\)/g);

                let { values, tableFirstKey, tableLastKey } = getValuesByKeyInTable({
                    key: param,
                    jsExp: sumExpression[0] || null,
                    formData,
                    options,
                    formInputs,
                });
                mergeWithParams.push(...values);
                return {
                    key: null,
                };
            }
            const patternCount = new RegExp(`count(.*${param}.*?)`);
            if (calculationExpression.match(patternCount)) {
                const sumExpression = calculationExpression.match(/count\(.*?\)/g);

                let { values, tableFirstKey, tableLastKey } = getValuesByKeyInTable({
                    key: param,
                    jsExp: sumExpression[0] || null,
                    formData,
                    options,
                    formInputs,
                });
                mergeWithParams.push(...values);
                return {
                    key: null,
                };
            }

            let value = getValuesByKey(param, formData, options);
            return {
                key: param,
                ...value,
            };
        });
        allParams = [...params.filter((it) => it.key), ...mergeWithParams];
    }

    return {
        hasCalculation: true,
        params: allParams,
        tablePath,
        isList,
    };
};

// export const getInputColSize = (style) => {
//     if (style.includes("area_texto") && !style.includes("coluna")) {
//         return 24;
//     }
//     return 8;
// };

export const validateTableField = ({ input, options = {}, formData, values, showInModal, field, functions = {} }) => {
    //
    // return false;
    if (!input?.["@restricao"] && !input?.["@obrigatorio"]) {
        return true;
    } else {
        let data = options?.inTable ? options?.items?.data : formData;
        if (values) {
            data = {
                ...data,
                ...values,
            };
        }

        let visible = true;

        if (input?.["@relevante"]) {
            visible = input_validations({
                item: input,
                relKey: "@relevante",
                formData: data,
                functions,
            });
        }

        if (!visible) {
            return false;
        }

        if (input?.["@obrigatorio"]) {
            let isRequered = false;
            if (input?.["@obrigatorio"] === "true" || input?.["@obrigatorio"] === "true()") {
                isRequered = true;
            } else {
                isRequered = input_validations({
                    item: input,
                    relKey: "@obrigatorio",
                    formData: formData,
                    functions: {},
                    inputProps: options,
                });
            }
            if (isRequered) {
                if (isEmpty(data[field])) {
                    const mess = `${input["@etiqueta"] ? input["@etiqueta"] : ""} obrigatório`;
                    return mess;
                }
            }
        }

        if (!input?.["@restricao"]) return undefined;

        if (
            input_validations({
                item: input,
                relKey: "@restricao",
                formData: formData,
                functions,
                inputProps: options,
            })
        ) {
            return undefined;
        } else {
            const mess = input["@msg_erro"]
                ? input["@msg_erro"]
                : `${input["@etiqueta"] ? input["@etiqueta"] : ""} inválido`;
            return mess;
        }
    }
};

export const getTableFieldsToValidate = (inputAttribute, _tableKey) => {
    const fields = [];
    if (_tableKey) {
        let tableElements = inputAttribute[_tableKey];
        if (!tableElements) {
            tableElements = inputAttribute;
        }

        if (tableElements) {
            Object.entries(tableElements).forEach(([key, value]) => {
                //if (value["@obrigatorio"] === "true" || value["@obrigatorio"] === "true()" || value["@restricao"])
                if (
                    (value?.["@obrigatorio"] &&
                        (value["@obrigatorio"] !== "nao" || value["@obrigatorio"] !== "nao()")) ||
                    value["@restricao"]
                )
                    fields.push(key);
            });
        }
    }

    return fields;
};

const addObjectPropertyRecursively = (obj, arr = [], key, value) => {
    if (arr.length === 0) {
        if (obj) {
            obj[key] = value;
        } else {
            obj = {
                [key]: value,
            };
        }
    } else {
        let el = arr.shift();
        if (!obj || !obj[el]) {
            obj[el] = {};
        }
        obj[el] = addObjectPropertyRecursively(obj[el], arr, key, value);
    }
    return obj;
};

export const getDefaultValueForFormInputs = ({
    formInputs,
    path = [],
    parent = null,
    defaultState = {},
    params = {},
}) => {
    // downFile(JSON.stringify(formInputs), "teste", "application/json");
    let localDefault = { ...defaultState };
    Object.keys(formInputs)
        .filter((key) => !Utilitaries.toString(key).startsWith("@"))
        .forEach((key) => {
            const input = formInputs[key];
            let dynamicType = getDynamicType(input, {}) || input["@tipo"];
            let isParent = false;

            if (!dynamicType && ["conjunto"].includes(parent)) {
                isParent = true;
            } else if (!dynamicType) {
                dynamicType = "input";
            }

            if (["conjunto"].includes(dynamicType) || isParent) {
                getDefaultValueForFormInputs({
                    formInputs: input,
                    path: [...path, key],
                    params,
                    parent: dynamicType,
                    localDefault,
                });
            } else {
                let defaultValue = undefined;
                if (input["$"]) {
                    // ;
                    const info = input["$"];
                    // nao comessa com $ entao valor padrao
                    if (!info.startsWith("$")) {
                        if (info === "{now()}") {
                            if (!localDefault || localDefault[key] === "{now()}" || !localDefault[key]) {
                                defaultValue = moment().format("YYYY-MM-DD");
                            }
                        } else if (info.startsWith("{$param.")) {
                            const paramKey = info.replace("{$param.", "").replace("}", "");

                            defaultValue = params[paramKey] || "";
                        } else {
                            defaultValue = info.toQAFormValue();
                        }
                    }
                    if (defaultValue !== undefined) {
                        if (Utilitaries.isEmpty(localDefault[key])) {
                            // handleInputChange(inputValue, field);
                            localDefault = addObjectPropertyRecursively(localDefault, path, key, defaultValue);
                        }
                    }
                } else {
                    if (typeof localDefault[key] === "object") {
                        const subKey = Object.keys(localDefault[key]);
                        if (
                            input["@tipo"] === "datagrid" &&
                            subKey.length === 1 &&
                            key === subKey[0] &&
                            typeof localDefault[key][subKey[0]] === "object"
                        ) {
                            localDefault["linha"] = {
                                ...localDefault?.linha,
                                [key]: toArray(localDefault[key][subKey[0]]),
                            };
                            delete localDefault[key];
                        }
                    }
                }
            }
        });

    return localDefault;
};

export const getItemValue = ({ itemKey, formData, objectInputs = {} }) => {
    let itemValue = "";

    const items = Object.entries(objectInputs).filter(([key]) => key === itemKey);

    if (items.length === 0) {
        if (Utilitaries.toArray(objectInputs?._nodes).length > 0) {
            itemValue =
                Utilitaries.toArray(objectInputs?._nodes).reduce(
                    (accumulator, current) => {
                        accumulator = accumulator?.[current];
                        return accumulator || {};
                    },
                    { ...formData }
                )?.[itemKey] || "";
        } else {
            return "";
        }
    } else {
        // usei primeira posicao pois e o que precisso para meu casso
        // usar path de referencia (onde esta o campo (_nodes) e se ../ ou //)
        let nodes = [];

        if (objectInputs?.["@tipo"] === "conjunto-in-form") {
            nodes = objectInputs?._nodes;
        } else if (items.length === 1) {
            const [, { _nodes }] = items[0];
            nodes = _nodes;
        }

        if (nodes?.length > 0) {
            itemValue =
                nodes.reduce(
                    (accumulator, current) => {
                        accumulator = accumulator?.[current];
                        return accumulator || {};
                    },
                    { ...formData }
                )?.[itemKey] || "";
        } else {
            itemValue = formData[itemKey] || "";
        }
    }
    return itemValue;
};

export const getInputLabel = (inputAttributes, inputName = null) => {
    let label = inputAttributes?.["@etiqueta"];

    if (Utilitaries.isEmpty(label) && inputName) {
        label = Utilitaries.toString(inputName).replace(/_/g, " ");

        label = label.charAt(0).toUpperCase() + label.substr(1).toLowerCase();
    }

    return label;
};
