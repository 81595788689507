import React from "react";
import { Table, Button, Row } from "antd";
import { componentType } from "../../constants";
import ComponentRendererWrapper from "../execution/component.render.wrapper";

const InputGroup = ({ itemData, type, isRoot, handleTableAdd, form, context, tabName, setLabel }) => {
    const TransformColumns = () => {
        let fields = [];

        for (const itemProp in itemData) {
            if (
                typeof itemData[itemProp] === "object" &&
                itemProp !== "dataSource" &&
                !Array.isArray(itemData[itemProp]) &&
                !itemData[itemProp]?.parent &&
                (!itemData[itemProp]["@visivel"] || itemData[itemProp]["@visivel"] !== "nao")
            ) {
                fields.push(itemData[itemProp]);
            }
        }

        fields = fields.map((fieldItem) => {
            // --- in case fieldItem has other component in it
            if (fieldItem["@tipo"] && (!fieldItem?.["@visivel"] || fieldItem?.["@visivel"] !== "nao")) {
                return (
                    <ComponentRendererWrapper
                        key={fieldItem.key}
                        renderContext="inputGroup"
                        context={context}
                        item={fieldItem}
                        tabName={tabName}
                        value={itemData.value}
                        itemKey={itemData.key}
                        form={form}
                    />
                );
            }
            // // --- in case column has no other component in it
            else return null;
        });

        return fields;
    };

    return TransformColumns();
};

export default InputGroup;
