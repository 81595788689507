import React, { useState } from "react";
import { Modal, Form, Row, Col, Input, Button, Icon } from "antd";
import NewForm from "./form/index";

const NewElaborate = ({ isVisible, showNewElaborate }) => {
    const [isVisibleForm, showNewForm] = useState(null);

    return (
        <>
            <Modal
                title="Inserir Novo Formulário"
                visible={isVisible !== null}
                onCancel={() => showNewElaborate(null)}
                footer={null}
                confirmLoading={false}
                destroyOnClose
            >
                <Form layout="vertical">
                    <Row gutter={18}>
                        <Col xs={24} sm={12} md={24}>
                            <Form.Item label="Código">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={20}>
                            <Form.Item label="Formulário">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={4}>
                            <Form.Item label="Editar">
                                <Button onClick={() => showNewForm({})}>
                                    <Icon type="form" />
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary">
                        <Icon type="save" />
                        Inserir
                    </Button>
                </Form>
            </Modal>
            <NewForm isVisible={isVisibleForm} showNewForm={showNewForm.bind(this)} />
        </>
    );
};

export default NewElaborate;
