import React from "react";
import { Select, Form, Row, Col } from "antd";

const { Option } = Select;

const InitializationProcessFilter = ({
    selectedMesoProcess,
    isMesoProcessLoading,
    selectedMacroProcess,
    isMacroProcessesLoading,
    selectedStablishment,
    mesoProcesses,
    stablishments,
    isStablishmentsLoading,
    processes,
    macroProcesses,
    handleStablishmentChange,
    handleMacroProcessChange,
    handleMesoProcessChange,
}) => {
    return (
        <div>
            <Form
                labelCol={{
                    xs: { span: 24 },
                    sm: { span: 8 },
                }}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 16 },
                }}
                layout="horizontal"
            >
                <Row>
                    <Col xs={24} md={8}>
                        <Form.Item label="Estabelecimento">
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                onChange={handleStablishmentChange}
                                placeholder="Pesquise ou selecione o estabelecimento"
                                loading={isStablishmentsLoading}
                                optionFilterProp="children"
                                value={selectedStablishment}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {stablishments &&
                                    stablishments.map((item) => (
                                        <Option
                                            value={item.id_estabelecimento}
                                            key={item.id_estabelecimento + "_stablishments"}
                                        >
                                            {item.nome}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label="Macro-Processo">
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Pesquise ou selecione o macro-processo"
                                loading={isMacroProcessesLoading}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleMacroProcessChange}
                            >
                                {macroProcesses &&
                                    macroProcesses.map(({ id_parametro, valor }) => (
                                        <Option key={id_parametro}>{valor}</Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label="Meso-Processo">
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                value={selectedMesoProcess || undefined}
                                loading={isMesoProcessLoading}
                                disabled={!selectedMacroProcess ? true : false}
                                onChange={handleMesoProcessChange}
                                placeholder="Selecione um meso-processo"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {mesoProcesses &&
                                    mesoProcesses.map(({ id_parametro, valor }) => (
                                        <Option key={id_parametro}>{valor}</Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default InitializationProcessFilter;
