import React from "react";
import { Row, Table, Input, Select, Form } from "antd";
import moment from "moment";

const { Option } = Select;

const TasksAssignmentTabPane = ({ toReview, getFieldDecorator, initialFormData, enableField }) => {
    const {
        responsavel_criacao,
        data_criacao,
        responsavel_elaboracao,
        data_elaboracao,
        responsavel_verificacao,
        data_verificacao,
        responsavel_aprovacao,
        data_aprovacao,
        responsavel_anulacao,
        data_anulacao,
        descricao_alteracao,
    } = initialFormData.report;

    const handleValue = (value) => {
        if (value && typeof value === "object") {
            return "";
        } else if (value) {
            return value;
        }

        return "";
    };
    const dataSource = [
        {
            key: 0,
            task: "Criação",
            responsavel_criacao: handleValue(responsavel_criacao),
            owner_key: "responsavel_criacao",
            date: data_criacao ? moment(data_criacao) : null,
            date_key: "data_criacao",
        },
        {
            key: 1,
            task: "Elaboração",
            responsavel_elaboracao: handleValue(responsavel_elaboracao),
            owner_key: "responsavel_elaboracao",
            date: data_elaboracao ? moment(data_elaboracao) : null,
            date_key: "data_elaboracao",
        },
        {
            key: 2,
            task: "Verificação",
            responsavel_verificacao: handleValue(responsavel_verificacao),
            owner_key: "responsavel_verificacao",
            date: data_verificacao ? moment(data_verificacao) : null,
            date_key: "data_verificacao",
        },
        {
            key: 3,
            task: "Aprovação",
            responsavel_aprovacao: handleValue(responsavel_aprovacao),
            owner_key: "responsavel_aprovacao",
            date: data_aprovacao ? moment(data_aprovacao) : null,
            date_key: "data_aprovacao",
        },
        {
            key: 4,
            task: "Anulação",
            responsavel_anulacao: handleValue(responsavel_anulacao),
            owner_key: "responsavel_anulacao",
            date: data_anulacao ? moment(data_anulacao) : null,
            date_key: "data_anulacao",
        },
        {
            key: 5,
            task: "Alteração",
            descricao_alteracao: handleValue(descricao_alteracao),
        },
    ];

    const column = [
        { title: "Tarefa", dataIndex: "task", width: "20%" },
        {
            title: "Responsável",
            width: "50%",
            render: (value, row, index) => {
                const obj = {
                    children: value,
                    props: {},
                };

                if (index === 5) {
                    obj.props.colSpan = 2;
                    obj.children = (
                        <Form.Item
                            style={{
                                width: "100%",
                                padding: 0,
                            }}
                        >
                            {getFieldDecorator("descricao_alteracao", {
                                initialValue: row.descricao_alteracao,
                            })(<Input.TextArea disabled={!toReview} rows={4} />)}
                        </Form.Item>
                    );
                } else {
                    obj.children = (
                        <Form.Item
                            style={{
                                width: "100%",
                                padding: 0,
                            }}
                        >
                            {getFieldDecorator(row.owner_key, {
                                initialValue: row[row.owner_key],
                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    disabled={toReview && index != 4 ? false : enableField}
                                    style={{
                                        width: "100%",
                                        margin: "0 auto",
                                        padding: "0",
                                    }}
                                    placeholder="Selecione o responsável"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {initialFormData.users.map((it) => (
                                        <Option key={`select_user_${it.util}`} value={it.util}>
                                            {it.nome}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    );
                }

                return obj;
            },
        },
        {
            title: "Data",
            dataIndex: "date",
            width: "30%",
            render: (value, row, index) => {
                const obj = {
                    children: value,
                    props: {},
                };

                if (index === 5) {
                    obj.props.colSpan = 0;
                } else {
                    obj.children = (
                        <Form.Item
                            style={{
                                width: "100%",
                                padding: 0,
                            }}
                        >
                            {getFieldDecorator(row.date_key, {
                                initialValue: value ? moment(value).format("YYYY-MM-DD") : null,
                            })(
                                <Input
                                    type="date"
                                    disabled={toReview && index != 4 ? false : enableField}
                                    style={{ width: "100%" }}
                                    // format={"DD-MM-YYYY"}
                                />
                            )}
                        </Form.Item>
                    );
                }

                return obj;
            },
        },
    ];

    return (
        <div>
            <Row>
                <Table pagination={false} columns={column} dataSource={dataSource} />
            </Row>
        </div>
    );
};

export default TasksAssignmentTabPane;
