import React from "react";
import { Empty } from "antd";
const EmptyData = () => {
    return (
        <div style={{ height: "372px" }}>
            <Empty description={"Sem dados"} />
        </div>
    );
};

export default EmptyData;
