import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, message, Table, Select, Divider, Popconfirm, Row, Col } from "antd";

import { sendHttpRequest } from "../http.request";

const { Option } = Select;

const DefinedEvent = (props) => {
    // const [modalCreate, setModalCreate] = useState(false);
    // const [modalUpdate, setModalUpdate] = useState({});
    const [permissions, setPermissions] = useState([
        {
            id_qa_acessos_agenda: 1,
            grupo: 7,
            nome_grupo: "Apoio operacional",
            username: "barbosa",
            nome: "B.Barbosa",
            consultar: "pessoal",
            inserir: "pessoal",
            alterar: "ambas",
            eliminar: "ambas",
        },
    ]);

    const [contributores, setContributores] = useState({ 1: [] });
    const [selectedPermissionTableID, setSelectedPermissionTableID] = useState(1);

    useEffect(() => {
        setContributores({
            ...contributores,
            [selectedPermissionTableID]: props.contributorsByFuncionalGroup,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contributorsByFuncionalGroup]);

    useEffect(() => {
        setPermissions(props.eventsPermissions.list);

        let _contributores = {};
        props.eventsPermissions.list.forEach((item) => {
            _contributores[item.id_qa_acessos_agenda] = [];
        });
        setContributores(_contributores);
    }, [props.eventsPermissions.list]);

    const handleChangeFunctionalGroups = (value, option, id_qa_acessos_agenda) => {
        setSelectedPermissionTableID(id_qa_acessos_agenda);
        const id = option.props.data_id;

        props.loadingContributorsByFuncionalGroup(id);
    };

    const deletePermission = (line) => {
        let newPermissions = [];

        permissions.forEach((item) => {
            if (item.id_qa_acessos_agenda !== line.id_qa_acessos_agenda) {
                newPermissions.push(item);
            }
        });

        setPermissions(newPermissions);
    };

    const listOfFuncionalGroups = props.funcionalGroups.map((item) =>
        typeof item.id_ficha_funcao !== "object" && typeof item.descricao_funcao !== "object" ? (
            <Option key={item.codigo} data_id={item.id_ficha_funcao}>
                {item.codigo}
            </Option>
        ) : null
    );

    const permissionsTypes = [
        { name: "Profissional", code: "profissional" },
        { name: "Pessoal", code: "pessoal" },
        { name: "Ambas", code: "ambas" },
        { name: "Nenhuma", code: "nenhuma" },
    ];

    const onChange = (value, key, field) => {
        //
        let newPontual;
        newPontual = [];

        permissions.forEach((item) => {
            if (item.id_qa_acessos_agenda === key) {
                item[field] = value;
            }
            newPontual.push(item);
        });

        setPermissions(newPontual);
    };

    const columns = [
        {
            title: "Grupo Funcional",
            key: "grupo_funcional",
            width: "20%",
            render: (text, record) => (
                <Select
                    mode="default"
                    style={{ width: "100%" }}
                    placeholder=""
                    showSearch
                    allowClear
                    defaultValue={record.nome_grupo}
                    onChange={(value, options) => {
                        handleChangeFunctionalGroups(value, options, record.id_qa_acessos_agenda);
                        onChange(options.props.data_id, record.id_qa_acessos_agenda, "grupo");
                    }}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {listOfFuncionalGroups}
                </Select>
            ),
        },
        {
            title: "Funcionário/a",
            key: "colaborador",
            width: "20%",
            render: (text, record) => (
                <Select
                    defaultValue={typeof record.username === "object" ? "" : record.username}
                    style={{ width: "100%" }}
                    allowClear
                    onChange={(value) => onChange(value, record.id_qa_acessos_agenda, "username")}
                >
                    {contributores[record.id_qa_acessos_agenda].map((item) => (
                        <Option key={item.username} value={item.username}>
                            {item.nome}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Consultar",
            key: "consultar",
            width: "10%",
            render: (text, record) => (
                <Select
                    defaultValue={record.consultar}
                    style={{ width: 120 }}
                    allowClear
                    onChange={(value) => onChange(value, record.id_qa_acessos_agenda, "consultar")}
                >
                    {permissionsTypes.map((item) => (
                        <Option key={item.code} value={item.code}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Inserir",
            key: "inserir",
            width: "10%",
            render: (text, record) => (
                <Select
                    defaultValue={record.inserir}
                    style={{ width: 120 }}
                    allowClear
                    onChange={(value) => onChange(value, record.id_qa_acessos_agenda, "inserir")}
                >
                    {permissionsTypes.map((item) => (
                        <Option key={item.code} value={item.code}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Alterar",
            key: "alterar",
            width: "10%",
            render: (text, record) => (
                <Select
                    defaultValue={record.alterar}
                    style={{ width: 120 }}
                    allowClear
                    onChange={(value) => onChange(value, record.id_qa_acessos_agenda, "alterar")}
                >
                    {permissionsTypes.map((item) => (
                        <Option key={item.code} value={item.code}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Eliminar",
            key: "eliminar",
            width: "10%",
            render: (text, record) => (
                <Select
                    defaultValue={record.eliminar}
                    style={{ width: 120 }}
                    allowClear
                    onChange={(value) => onChange(value, record.id_qa_acessos_agenda, "eliminar")}
                >
                    {permissionsTypes.map((item) => (
                        <Option key={item.code} value={item.code}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Ação",
            key: "acao",
            width: "10%",
            render: (_, item) => (
                <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => deletePermission(item)}>
                    <a
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        href="#!"
                        className="qa-text-del"
                    >
                        Eliminar
                    </a>
                </Popconfirm>
            ),
        },
    ];

    const handleSave = () => {
        const formData = new FormData();
        formData.append("accao", "guarda_permissoes");
        formData.append("permissoes", JSON.stringify(permissions));

        sendHttpRequest("POST", "/Gestor/gereagenda.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                message.error("Erro ao salvar permissões.");
            } else {
                message.success("Permissões criado com sucesso");
                props.eventsPermissions.get();
            }
        });
    };

    return (
        <>
            <Modal
                title="Permissões de Agenda"
                visible={props.visible}
                onCancel={props.onCancel}
                footer={null}
                width="90%"
            >
                {/* <Button
                    icon="clock-circle"
                    style={{ marginTop: 8, marginBottom: 8 }}
                    onClick={() => {
                        setModalUpdate({});
                        setModalCreate(true);
                    }}
                >
                    Adicionar Evento Definido
                </Button> */}

                <Row gutter={16} align="middle" type="flex">
                    <Col xs={12}>
                        <Button
                            xs={24}
                            sm={12}
                            onClick={handleSave}
                            icon="plus"
                            size="small"
                            type="primary"
                            style={{ marginBottom: 16, float: "left" }}
                        >
                            Guardar
                        </Button>

                        <Button
                            xs={24}
                            sm={12}
                            onClick={() => {}}
                            icon="plus"
                            size="small"
                            type="primary"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "left",
                            }}
                        >
                            Lista de Correções
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <Button
                            xs={24}
                            sm={12}
                            onClick={() => {
                                const index = Math.ceil(Math.random() * 500) + "_" + permissions.length;
                                setPermissions([
                                    ...permissions,
                                    {
                                        id_qa_acessos_agenda: index,
                                        grupo: null,
                                        nome_grupo: null,
                                        username: null,
                                        nome: null,
                                        consultar: null,
                                        inserir: null,
                                        alterar: null,
                                        eliminar: null,
                                    },
                                ]);
                                setContributores({
                                    ...contributores,
                                    [index]: [],
                                });
                            }}
                            icon="plus"
                            size="small"
                            type="primary"
                            style={{ marginBottom: 16, float: "right" }}
                        >
                            Adicionar
                        </Button>
                    </Col>
                </Row>

                <Table dataSource={permissions} columns={columns} rowKey="id_qa_acessos_agenda" />
            </Modal>
        </>
    );
};

// const ModalCorrections = props => {

//     const handleSubmit = e => {
//         e.preventDefault();
//         return false;
//     };

//     const onOk = () => {
//         props.form.validateFieldsAndScroll((err, values) => {
//             if (!err) {
//                 //

//                 const accao =
//                     Object.keys(props.update).length > 0 ? "editar" : "enviar";

//                 const formData = new FormData();
//                 formData.append("accao", accao);
//                 formData.append("designacao", values.designacao);
//                 formData.append("descricao", values.descricao);
//                 if (accao === "editar") {
//                     formData.append("id", props.update.id_evento_defeito);
//                 }
//                 sendHttpRequest(
//                     "POST",
//                     "/Gestor/gere.php",
//                     formData
//                 ).then(jsonResponse => {
//
//                     if (jsonResponse.erro) {
//                         message.error("Erro ao salvar eventos.");
//                     } else {
//                         message.success("Eventos criado com sucesso");
//                         props.onCreated();
//                     }
//                 });
//             }
//         });
//     };

//     const { getFieldDecorator } = props.form;

//     return (
//         <Modal
//             title="Criar Evento Definido"
//             visible={props.visible}
//             onCancel={props.onCancel}
//             onOk={onOk}
//             width="50%"
//             centered
//             destroyOnClose
//         >
//             <Form onSubmit={handleSubmit}>
//                 <Form.Item label="Designação">
//                     {getFieldDecorator("designacao", {
//                         initialValue:
//                             Object.keys(props.update).length > 0
//                                 ? props.update.designacao
//                                 : "",
//                         rules: [
//                             {
//                                 required: true,
//                                 message: "Informe a designacao!"
//                             }
//                         ]
//                     })(<Input />)}
//                 </Form.Item>

//                 <Form.Item label="Descrição">
//                     {getFieldDecorator("descricao", {
//                         initialValue:
//                             Object.keys(props.update).length > 0
//                                 ? props.update.descricao
//                                 : "",
//                         rules: [
//                             {
//                                 required: true,
//                                 message: "Informe a descriçao!"
//                             }
//                         ]
//                     })(<Input />)}
//                 </Form.Item>
//             </Form>
//         </Modal>
//     );
// };
// const WrappedModalCreate = Form.create({ name: "register" })(ModalCreate);

export default DefinedEvent;
