import React, { useState, useEffect } from "react";
import { Modal, Table, Tabs, Button, Icon, message } from "antd";
import moment from "moment";

import { sendHttpRequest } from "../http.request";
import Visualization from "../Visualization/index";
import RememberEditor from "./editor";
import { columnsLembrete, columnsLembreteFuturos } from "./tableHeader";

const { TabPane } = Tabs;

const LembrateComponent = (props) => {
    const [editor, setEditor] = useState({
        open: false,
        isCreate: true,
        update: {},
    });
    const [loading, setLoading] = useState(false);

    // switch visualization model status
    const [visualizationData, setVisualizationData] = useState({
        estado: null,
        data: [],
    });

    // Select row in lembrete
    const [lembreteSelected, setLembreteSelected] = useState([]);

    // const [lembreteAddedToViewed, setLembreteAddedToViewed] = useState([]);

    useEffect(() => {
        if (props.isLembreteVisible) {
            setLoading(true);
            props.updateList().then(() => {
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLembreteVisible]);

    // Add or remove reminder ID to selected reminder list when table row check box changes
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setLembreteSelected(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    const rowSelectionLembreteFuturos = {
        onChange: (selectedRowKeys, selectedRows) => {
            setLembreteSelected(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    // Mark list of reminder to not showing in pending list
    const notShowLembretes = () => {
        if (lembreteSelected.length === 0) {
            message.error("Selecione o lembrete");
            return;
        }
        const formData = new FormData();
        formData.append("accao", "anular");
        formData.append("ids", lembreteSelected.join(";").replace(/-lembrete/g, ""));
        sendHttpRequest("POST", "/Gestor/gerelembretes.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) message.error("Ocorreu um erro");
            else {
                props.updateList();
                props.getBadge();
                message.success("Alterado com sucesso");
                setLembreteSelected([]);
            }
        });
    };

    const removeLembrate = () => {
        if (lembreteSelected.length === 0) {
            message.error("Selecione o lembrete");
            return;
        }
        const formData = new FormData();
        formData.append("accao", "anular");
        formData.append("ids", lembreteSelected.join(";").replace(/-lembrete/g, ""));
        sendHttpRequest("POST", "/Gestor/gerelembretes.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) message.error("Ocorreu um erro");
            else {
                props.updateList();
                props.getBadge();
                message.success("Alterado com sucesso");
            }
            setLembreteSelected([]);
        });
    };

    // Get and show reminder details
    const seMoreLembrete = (item) => {
        if (item.read === "f") {
            // const allLembreteVisualizationIds = props.dataSource.lembretes_vistos.filter(it => it.username === 'qa' && it.lembrete === item.id_lembrete)
            //
            const formData = new FormData();
            formData.append("accao", "marcar_visto");
            formData.append("ids", item.id_lembrete);
            sendHttpRequest("POST", "/Gestor/gerelembretes.php", formData).then((jsonResponse) => {
                // setLembreteAddedToViewed([...lembreteAddedToViewed, item.id_lembrete]);
                if (jsonResponse.erro) {
                    // message.error("Erro ao aceder à base de dados");
                } else {
                    props.updateList();
                    props.getBadge();
                    // message.success("Alterado com sucesso");
                }
                // setLembreteSelected([]);
            });
        }

        Modal.info({
            title: "Descrição do lembrete",
            content: (
                <div>
                    <pre style={{ whiteSpace: "pre-wrap" }}>{item.descricao}</pre>
                </div>
            ),
            onOk() {},
            width: "50%",
        });
    };

    const showVisualization = (id, estado) => {
        const formData = new FormData();
        formData.append("accao", "vistos_por");
        formData.append("id", id);
        sendHttpRequest("POST", "/Gestor/gerelembretes.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) message.error("Ocorreu um erro");
            else {
                let data = jsonResponse.visualizacao["linha"];
                data = Array.isArray(data) ? data : [data];
                setVisualizationData({ data, estado });
            }
        });
    };

    const handleUpdate = (item) => {
        const formData = new FormData();
        formData.append("accao", "consultar");
        formData.append("id", item.id_lembrete);

        sendHttpRequest("POST", "/Gestor/gerelembretes.php", formData).then((jsonResponse) => {
            if (jsonResponse.erro) {
                message.error("Ocorreu um erro");
            } else {
                const list = jsonResponse.grupos.linha.length ? jsonResponse.grupos.linha : [jsonResponse.grupos.linha];

                let _users = list.filter(
                    (item) => typeof item.grupo === "object" && typeof item.nome_grupo === "object"
                );
                let _groups = list.filter((item) => typeof item.username === "object" && typeof item.nome === "object");

                _users = _users.map((item) => ({
                    username: item.username,
                    name: item.nome,
                }));

                _groups = _groups.map((item) => ({
                    id: item.grupo,
                    value: item.nome_grupo,
                }));

                setEditor({
                    open: true,
                    isCreate: false,
                    update: {
                        groups: _groups,
                        users: _users,
                        id: item.id_lembrete,
                        data: moment(item.data, "DD-MM-YYYY HH:mm"),
                        hour: moment(item.data, "DD-MM-YYYY HH:mm"),
                        description: item.descricao,
                    },
                });
            }
        });
    };

    return (
        <>
            {/* Create or update remember */}
            <RememberEditor
                visible={editor.open}
                isCreate={editor.isCreate}
                reminder={editor.update}
                onCancel={() => setEditor({ open: false, isCreate: true, update: {} })}
                updateList={props.updateList}
                selectContributorsProps={props.selectContributorsProps}
            />

            {/* Visualization - user that see or not one lembrete */}
            <Visualization
                visible={visualizationData.data.length > 0}
                onCancel={() => setVisualizationData({ estado: null, data: [] })}
                {...visualizationData}
            />

            {/* Reminder list */}
            <Modal
                title="Lembretes do Utilizador"
                visible={props.isLembreteVisible}
                onCancel={props.onCancel}
                width="90%"
                centered
                zIndex={800}
                footer={null}
            >
                <Button
                    type="primary"
                    size="small"
                    onClick={() => setEditor({ open: true, isCreate: true, update: {} })}
                >
                    <Icon type="plus" />
                    Novo Lembrete
                </Button>

                <Button type="primary" size="small" style={{ float: "right" }} onClick={() => props.openAlert()}>
                    <Icon type="clock-circle" />
                    Ver Alertas
                </Button>

                <Tabs defaultActiveKey="1">
                    <TabPane tab="Correntes" key="1">
                        <Button type="link" size="small" onClick={notShowLembretes}>
                            <Icon type="check" />
                            Não voltar a mostrar
                        </Button>
                        <Table
                            size="small"
                            rowKey={(data) => data.id_lembrete + "-lembrete"}
                            pagination={{
                                pageSize: 5,
                            }}
                            rowSelection={rowSelection}
                            dataSource={props.dataSource.lembretes}
                            columns={columnsLembrete(seMoreLembrete, showVisualization)}
                            style={{ marginTop: 20 }}
                            loading={loading}
                        />
                    </TabPane>
                    <TabPane tab="Futuros" key="2">
                        <Button type="link" size="small" onClick={removeLembrate}>
                            <Icon type="delete" />
                            Eliminar
                        </Button>
                        <Table
                            size="small"
                            rowKey={(data) => data.id_lembrete + "-future"}
                            pagination={{
                                pageSize: 5,
                            }}
                            rowSelection={rowSelectionLembreteFuturos}
                            dataSource={props.dataSource.lembretes_futuros}
                            columns={columnsLembreteFuturos(seMoreLembrete, handleUpdate, showVisualization)}
                            style={{ marginTop: 20 }}
                            loading={loading}
                        />
                    </TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

export default LembrateComponent;
