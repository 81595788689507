/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, Select, Form, Row, Col } from "antd";
import { toArray } from "../../../components/util";
import { Utilitaries } from "../../../business";

const generateQuery = ({ uo }) => {
    return {
        valencia_cliente: uo?.trim(),
        qualia_valencia: uo?.trim(),
        valencia_autoriza: uo?.trim(),
        valencia_origem: uo?.trim(),
        valencia_elab: uo?.trim(),
        valencia_pci: uo?.trim(),
        valencia: uo?.trim(),
        valencia_aval: uo?.trim(),
        valencia_mon_pdi: uo?.trim(),
        valencia_sel: uo?.trim(),
        valencia_pdi: uo?.trim(),
        resposta_social: uo?.trim(),
        valencia_original: uo?.trim(),
        valencia_mon_pei: uo?.trim(),
        valencia_mon_psei: uo?.trim(),
    };
};

export default function PopupSocialProcess({
    setVisible,
    process,
    getFieldDataSource,
    handleInitProcess,
    selectedStablishment,
}) {
    const [uo, setUO] = useState([]);
    const [actions, setActions] = useState([]);
    const [tasks, setTasks] = useState([]);

    const [selectedUO, setSelectedUO] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!process) {
            return;
        }
        setSelectedUO(null);
        setSelectedAction(null);

        getFieldDataSource({
            dataSource: "process_mask_options",
            params: { mask_code: process.codigo_externo, idEstabelecimento: selectedStablishment },
        }).then((data) => {
            const list = toArray(data.result);

            const listUO = list
                .filter((it) => !Utilitaries.isEmpty(it.unidade_organica))
                .reduce((acc, item) => {
                    if (
                        !acc.find(
                            (it) => Utilitaries.toString(it.id) === Utilitaries.toString(item.id_unidade_organica)
                        )
                    ) {
                        acc.push({
                            id: item.id_unidade_organica,
                            name: item.unidade_organica?.trim(),
                        });
                    }
                    return acc;
                }, []);
            setUO(listUO);
            setActions(list);
            filterTasks(list);

            let hasEmpty = list.filter((it) => Utilitaries.isEmpty(it.unidade_organica));
            if (listUO.length === 1 && hasEmpty.length === 0) {
                setSelectedUO(listUO[0]);
            }
        });
    }, [process]);

    useEffect(() => {
        if (!Utilitaries.isEmpty(actions)) {
            filterTasks(actions);
        }
    }, [selectedUO]);

    const filterTasks = (actions) => {
        const list = actions.filter(
            (it) =>
                Utilitaries.isEmpty(it?.unidade_organica) ||
                (!Utilitaries.isEmpty(it?.unidade_organica) &&
                    Utilitaries.toString(it?.unidade_organica) === Utilitaries.toString(selectedUO?.name))
        );

        setTasks(list);
    };

    function handleOk() {
        const item = actions.find(
            (it) => Utilitaries.toString(it.nome_externo) === Utilitaries.toString(selectedAction)
        );

        if (item) {
            setLoading(true && !!process);
            let queryParams = {};
            queryParams = generateQuery({
                uo: Utilitaries.toString(selectedUO?.id),
                // process: Utilitaries.toString(item?.codigo_externo),
                // name: Utilitaries.toString(item?.nome_externo),
            });
            // const _uo = uo.find(it => it.designacao.trim() === selectedUO.trim())

            // return;
            handleInitProcess(item, queryParams);
        }
    }

    function handleCancel() {
        setVisible(null);
    }

    return (
        <Modal
            title={`Processo - ${process?.codigo_externo} - ${process?.nome_externo}`}
            visible={!!process}
            onOk={handleOk}
            width="50%"
            onCancel={handleCancel}
            okText="Iniciar"
            okButtonProps={{
                loading: loading,
            }}
        >
            <Form
                labelCol={{
                    xs: { span: 24 },
                    sm: { span: 8 },
                }}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 16 },
                }}
                layout="horizontal"
            >
                <Row>
                    {uo.length > 0 && (
                        <Col xs={24} md={12}>
                            <Form.Item label="Unidade orgânica">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    value={selectedUO?.id}
                                    dropdownMatchSelectWidth={false}
                                    onChange={(value) => {
                                        setSelectedAction("");
                                        if (value) {
                                            const userSelectedUO = uo.find((it) => it?.id?.trim() === value?.trim());
                                            setSelectedUO(userSelectedUO);
                                        } else {
                                            setSelectedUO(null);
                                        }
                                    }}
                                    placeholder="Selecione Unidade orgânica"
                                    // loading={isStablishmentsLoading}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {uo &&
                                        uo.map((item) => (
                                            <Select.Option value={item.id} key={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                    <Col xs={24} md={12}>
                        <Form.Item label="Ação">
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Selecione a ação"
                                dropdownMatchSelectWidth={false}
                                // loading={isMacroProcessesLoading}
                                value={selectedAction}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => setSelectedAction(value)}
                            >
                                {tasks.map(({ nome_externo }) => (
                                    <Select.Option key={nome_externo.trim()}>{nome_externo}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
