import { Button, message, Modal, Popconfirm, Row, Select, Table } from "antd";
import { T } from "antd/lib/upload/utils";
import React, { useEffect, useState } from "react";
import { Utilitaries } from "../../../../../business";
import { api_fetch } from "../../../../utils/HttpRequest/request";

const { Option } = Select;

interface IProps {
    isVisible: boolean;
    handleClose: (isCancel: boolean, id?: string) => void;
    id: string;
    functionalGroups: any[];
}

const tipo = [
    { key: "1", value: "Férias" },
    { key: "2", value: "Faltas-Validação" },
    { key: "3", value: "Faltas-Autorização" },
    { key: "4", value: "Horas Extras" },
    { key: "5", value: "Adiantamentos" },
    { key: "6", value: "Permuta de Férias" },
    { key: "7", value: "Controlo de Férias" },
];

export function ValidationPermissionModal({ isVisible, handleClose, id, functionalGroups }: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [contribuitors, setContribuitors] = useState<any[]>([]);

    useEffect(() => {
        if (id) {
            getAccessValidationById(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    async function getAccessValidationById(id: string) {
        setIsLoading(true);

        getContributorsByGroupId(null);

        const resp = await api_fetch({
            endPoint: "/Gestor/gereformulario.php",
            method: "POST",
            params: {
                accao: "dados",
                formulario: "qa_acessos_validacao_colab.xml",
                id,
            },
        });

        if (resp.status === "SUCCESS") {
            const { valores } = resp.response;
            if (valores) {
                if (valores.acessos_validacao) {
                    const { acessos_validacao } = valores;
                    if (acessos_validacao.acessos_valida) {
                        const { acessos_valida } = acessos_validacao;
                        if (acessos_valida.acesso_valida) {
                            const { acesso_valida } = acessos_valida;
                            const _dataSource = Utilitaries.toArray(acesso_valida).map((item: any, index: number) => {
                                return {
                                    ...item,
                                    key: index + 1,
                                };
                            });

                            setDataSource(_dataSource);
                        }
                    }
                }
            }
        }
        setIsLoading(false);
    }

    function getContributorsByGroupId(groupID: any) {
        let params: any = {
            accao: "lista_funcionarios",
            lista: "0",
        };

        if (groupID) {
            params = {
                ...params,
                id_funcional_group: groupID,
            };
        }

        api_fetch({
            endPoint: "/Gestor/gerelistas.php",
            method: "POST",
            params,
        }).then((response) => {
            if (response.status === "SUCCESS") {
                const { linha } = response.response;
                setContribuitors(Utilitaries.toArray(linha));
            }
        });
    }

    function handleDelete(key: number) {
        const _dataSource = [...dataSource];
        setDataSource(_dataSource.filter((item, index) => item.key !== key));
    }

    function handleAdd() {
        const newData = { key: dataSource.length + 1 };
        setDataSource([newData, ...dataSource]);
    }

    async function handleOk() {
        let empty_row = false;
        let acessos_validacao: any = {
            acessos_valida: {
                acesso_valida: dataSource.map((item) => {
                    if (!item.tipo || !item.utilizador_permitido || !item.grupo_utilizador) {
                        empty_row = true;
                    }
                    return item;
                }),
            },
        };

        if (id) {
            acessos_validacao.id_acessos_validacao = id;
        }

        if (!empty_row) {
            setIsLoading(true);

            const resp = await api_fetch({
                endPoint: "/Gestor/gereprocesso.php",
                method: "POST",
                params: {
                    accao: "guardar",
                    formulario: "qa_acessos_validacao_colab.xml",
                    dados: JSON.stringify({ acessos_validacao }),
                },
            });

            setIsLoading(false);
            if (resp.status === "SUCCESS") {
                message.success("Sucesso!");
                const { sucesso } = resp.response;
                handleClose(false, sucesso);
            } else {
                message.error("Erro ao guardar validações!");
            }
        } else message.warning("Linhas vazios tem de ser preenchidas ou removidas!!");
    }

    function handleCancel() {
        handleClose(true);
    }

    function onChange(dataIndex: string, value: any, record: any) {
        record[dataIndex] = value;
        const index = dataSource.findIndex((item) => record.key === item.key);
        dataSource[index] = record;
        setDataSource([...dataSource]);
    }

    const columns = [
        {
            title: "Grupo Funcional",
            dataIndex: "grupo_utilizador",
            width: "30%",
            render: (text: string, record: any) => {
                text = Utilitaries.toString(text);
                return (
                    <Select
                        showSearch
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        allowClear
                        placeholder="seleciona o grupo funcional"
                        optionFilterProp="children"
                        defaultValue={text}
                        onChange={(groupId: string) => {
                            onChange("grupo_utilizador", groupId, record);
                            getContributorsByGroupId(groupId);
                        }}
                        filterOption={(input, option) =>
                            String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {functionalGroups
                            .filter(
                                (item) => typeof item.id_ficha_funcao !== "object" && typeof item.codigo !== "object"
                            )
                            .map((item) => {
                                return (
                                    <Option key={item.id_ficha_funcao} value={item.id_ficha_funcao}>
                                        {item.descricao_funcao}
                                    </Option>
                                );
                            })}
                    </Select>
                );
            },
        },
        {
            title: "Funcionário",
            dataIndex: "utilizador_permitido",
            width: "30%",
            render: (text: string, record: any) => {
                text = Utilitaries.toString(text);
                return (
                    <Select
                        showSearch
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        allowClear
                        placeholder="Seleciona o colaborador"
                        optionFilterProp="children"
                        defaultValue={text}
                        onChange={(value: string) => onChange("utilizador_permitido", value, record)}
                        filterOption={(input, option) =>
                            String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {contribuitors.map((item) => {
                            return (
                                <Option key={item.username} value={item.username}>
                                    {item.nome}
                                </Option>
                            );
                        })}
                    </Select>
                );
            },
        },
        {
            title: "Tipo",
            dataIndex: "tipo",
            width: "30%",
            render: (text: string, record: any) => {
                text = Utilitaries.toString(text);
                return (
                    <Select
                        showSearch
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        allowClear
                        placeholder="Seleciona o tipo"
                        optionFilterProp="children"
                        defaultValue={text}
                        onChange={(value: string) => onChange("tipo", value, record)}
                        filterOption={(input, option) =>
                            String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {tipo.map((item) => {
                            return (
                                <Option key={item.key} value={item.key}>
                                    {item.value}
                                </Option>
                            );
                        })}
                    </Select>
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (_: any, record: any) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="Eliminar a linha?" onConfirm={() => handleDelete(record.key)}>
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    return (
        <Modal
            centered
            width="70%"
            visible={isVisible}
            title="Colaboradores com Permissão de Validação"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    voltar
                </Button>,
                <Button
                    icon="save"
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={handleOk}
                    disabled={dataSource.length ? false : true}
                >
                    Guardar
                </Button>,
            ]}
        >
            <div>
                <Row>
                    <Button
                        onClick={handleAdd}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{ marginBottom: 16, float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Row>
                <Table
                    rowKey={(record: any) => record.key}
                    size="small"
                    scroll={{ x: 850, y: 300 }}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                />
            </div>
        </Modal>
    );
}
