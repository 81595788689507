import React, { useState, useEffect } from "react";
import { Form, Input, Table, Button, Collapse, Select } from "antd";
import { connect } from "react-redux";

import QADynamicTable from "../../../../components/qa_custom/DynamicTable";
import { selectDataFromExecQuery } from "../../actions/funcional.group";
import { getColumnSearchProps } from "../search.into.table.columns";
import SubstitutosModalForTask from "./substitutos.modal";

const { Panel } = Collapse;
const { Option } = Select;

const EXEC_QUERY_NAME = {
    tarefas_funcao: "tarefas_funcao",
    tarefas_nao_geridas_ffuncao: "tarefas_nao_geridas_ffuncao",
};

const Tasks = ({
    form,
    descricaoFuncao,
    listOfAutorizationIds,
    dataFromExistGroup,
    selectDataFromExecQuery,
    selectedGrupoExist,
    autorizations,
    set_ficha_funcao_tarefas,
    ficha_funcao_tarefas,
}) => {
    // const { ficha_funcao_tarefas } = dataFromExistGroup;
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [substitutoData, setSubstitutoData] = useState({});
    const [tarefasNaoSi, setTarefasNaoSi] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (ficha_funcao_tarefas.tarefa_nao_si) {
            setTarefasNaoSi(ficha_funcao_tarefas.tarefa_nao_si || []);
        }

        if (ficha_funcao_tarefas.tarefa_si) {
            setDataSource(ficha_funcao_tarefas.tarefa_si || []);
        }
    }, [ficha_funcao_tarefas]);

    /**
     * fetch from "tarefas não geridas si"
     */
    // useEffect(() => {
    //     let params = {};
    //     if (ficha_funcao_tarefas) {
    //         params = {
    //             ...params,
    //             id_ficha_funcao_tarefas: ficha_funcao_tarefas,
    //         };
    //     }
    //     if (selectedGrupoExist) {
    //         params = {
    //             ...params,
    //             grupo_exist: selectedGrupoExist,
    //         };
    //     }
    //     if (Object.keys(params).length) {
    //         (async () => {
    //             const response = await selectDataFromExecQuery({
    //                 query: EXEC_QUERY_NAME.tarefas_nao_geridas_ffuncao,
    //                 ...params,
    //             });
    //             if (response.result) {
    //                 if (Array.isArray(response.result)) {
    //                     const tasksNaoSi = response.result.map((item, index) => {
    //                         return { __key__: index, ...item };
    //                     });
    //                     setTarefasNaoSi(tasksNaoSi);
    //                 }
    //             }
    //         })();
    //     }
    // }, [ficha_funcao_tarefas, selectedGrupoExist]);

    /**
     * fetch from "tarefas geradas si"
     */
    // useEffect(() => {
    //     let params = {};
    //     if (ficha_funcao_tarefas) {
    //         params = {
    //             ...params,
    //             id_ficha_funcao_tarefas: ficha_funcao_tarefas,
    //         };
    //     }
    //     if (selectedGrupoExist) {
    //         params = {
    //             ...params,
    //             grupo_exist: selectedGrupoExist,
    //         };
    //     }
    //     if (listOfAutorizationIds) {
    //         params = {
    //             ...params,
    //             lista_nova: listOfAutorizationIds,
    //         };
    //     }

    //     if (listOfAutorizationIds) {
    //         if (Object.keys(params).length) {
    //             (async () => {
    //                 setLoading(true);
    //                 const response = await selectDataFromExecQuery({
    //                     query: EXEC_QUERY_NAME.tarefas_funcao,
    //                     ...params,
    //                 });
    //                 if (response.result) {
    //                     if (Array.isArray(response.result)) {
    //                         const tasksSi = response.result.map((item, index) => {
    //                             return { key: index, ...item };
    //                         });
    //                         setDataSource(tasksSi);
    //                     }
    //                 }
    //                 setLoading(false);
    //             })();
    //         }
    //     }
    // }, [ficha_funcao_tarefas, selectedGrupoExist, listOfAutorizationIds]);

    /**
     * close modal
     */
    const closeSubstitutModal = async (data) => {
        if (data) {
            const { field, record } = data;
            if (field === "tarefa_si") {
                const index = dataSource.findIndex((item) => item.key === record.key);
                const newDataSource = [...dataSource];
                newDataSource.splice(index, 1, record);
                await setDataSource([...newDataSource]);
                set_ficha_funcao_tarefas({
                    tarefa_si: dataSource,
                    tarefa_nao_si: [...newDataSource],
                });
            } else {
                const index = tarefasNaoSi.findIndex((item) => item.__key__ === record.__key__);
                const newDataSource = [...tarefasNaoSi];
                newDataSource.splice(index, 1, record);
                await setTarefasNaoSi([...newDataSource]);
                set_ficha_funcao_tarefas({
                    tarefa_si: dataSource,
                    tarefa_nao_si: [...newDataSource],
                });
            }
        }

        setShow(false);
    };

    /**
     * show modal
     */
    const showSubstitutModal = (data) => {
        setSubstitutoData(data);
        setShow(true);
    };

    /**
     * add row to table
     */
    const addRowToTable = () => {
        let size = tarefasNaoSi.length > 0 ? Math.max(...tarefasNaoSi.map((item) => Number(item.__key__))) + 1 : 0;
        setTarefasNaoSi([{ __key__: size }, ...tarefasNaoSi]);
    };

    /**
     * remove row from table
     */
    const removeRowFromTable = (selectedRowKeys) => {
        const newDataSource = [];
        tarefasNaoSi.forEach((item) => {
            if (!selectedRowKeys.includes(item.__key__)) newDataSource.push(item);
        });
        setTarefasNaoSi(newDataSource);
    };

    /**
     * handle change when user enter degit
     */
    const onChange = async (dataIndex, value, record) => {
        const newState = [...tarefasNaoSi];

        record[dataIndex] = value;
        const index = newState.findIndex((item) => item.__key__ === record.__key__);
        newState.splice(index, 1, record);
        // newState[index] = record;

        await setTarefasNaoSi([...newState]);
        set_ficha_funcao_tarefas({
            tarefa_si: dataSource,
            tarefa_nao_si: [...newState],
        });
    };

    /**
     * columns for "tarefas geradas si"
     */
    const columnsTarefaSi = [
        {
            title: "Processo",
            dataIndex: "processo_identifica",
            width: "10%",
            ...getColumnSearchProps("processo_identifica"),
        },
        {
            title: "Nome processo",
            dataIndex: "processo_tarefa",
            width: "25%",
            ...getColumnSearchProps("processo_tarefa"),
        },
        {
            title: "Tarefa",
            dataIndex: "tarefa_exec",
            width: "20%",
            ...getColumnSearchProps("tarefa_exec"),
        },
        {
            title: "Executantes (Autorizações)",
            dataIndex: "grupo_executante",
            width: "25%",
            ...getColumnSearchProps("grupo_executante"),
        },
        {
            title: "Substitutos (Autorizações)",
            width: "20%",
            align: "center",
            render: (text, record) => (
                <Button icon="plus" onClick={() => showSubstitutModal({ field: "tarefa_si", record })} />
            ),
        },
    ];

    const columnsTarefaNaoSi = [
        {
            title: "Processo",
            dataIndex: "processo_nao_si",
            width: "20%",
            render: (text, record) => (
                <Form.Item
                    style={{
                        width: "100%",
                        margin: "0 auto",
                        padding: "0",
                    }}
                >
                    <Input
                        defaultValue={typeof text !== "object" ? text : undefined}
                        onChange={(e) => onChange("processo_nao_si", e.target.value, record)}
                        placeholder="Introduza o processo"
                    />
                </Form.Item>
            ),
        },
        {
            title: "Tarefa",
            dataIndex: "tarefa_nao_si_desc",
            width: "30%",
            render: (text, record) => (
                <Form.Item
                    style={{
                        width: "100%",
                        margin: "0 auto",
                        padding: "0",
                    }}
                >
                    <Input
                        defaultValue={typeof text !== "object" ? text : undefined}
                        onChange={(e) => onChange("tarefa_nao_si_desc", e.target.value, record)}
                        placeholder="Introduza a tarefa"
                    />
                </Form.Item>
            ),
        },
        {
            title: "Executantes (Autorizações)",
            dataIndex: "grupo",
            width: "25%",
            render: (text, record) => (
                <Select
                    mode="multiple"
                    showSearch
                    allowClear
                    style={{
                        width: "100%",
                        margin: "0 auto",
                        padding: "0",
                    }}
                    defaultValue={typeof text !== "object" && text ? text.split(",") : undefined}
                    placeholder="Selecione uma opção"
                    optionFilterProp="children"
                    onChange={(value) => onChange("grupo", value, record)}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {autorizations.map((item, index) => (
                        <Option key={index} value={item.grupo}>
                            {item.designacao}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Substitutos (Autorizações)",
            dataIndex: "grupo_substituto",
            width: "15%",
            align: "center",
            render: (text, record) => (
                <Button
                    icon="plus"
                    onClick={() =>
                        showSubstitutModal({
                            field: "tarefa_nao_si",
                            record,
                        })
                    }
                />
            ),
        },
    ];

    return (
        <>
            <Form.Item label="Grupo Funcional">
                <Input readOnly value={descricaoFuncao} />
            </Form.Item>
            <Collapse defaultActiveKey={ficha_funcao_tarefas ? ["1", "2"] : selectedGrupoExist ? ["1", "2"] : 0}>
                <Panel key="1" header={<h3>Tarefas geridas SI</h3>}>
                    <Table
                        rowKey="key"
                        size="middle"
                        loading={loading}
                        dataSource={dataSource}
                        columns={columnsTarefaSi}
                    />
                </Panel>

                <Panel key="2" header={<h3>Tarefas não geridas SI</h3>}>
                    <QADynamicTable
                        data={tarefasNaoSi}
                        columns={columnsTarefaNaoSi}
                        addRowToTable={addRowToTable}
                        onRemoveItem={removeRowFromTable}
                    />
                </Panel>
            </Collapse>
            {/* {show && ( */}
            <SubstitutosModalForTask
                data={substitutoData}
                visible={show}
                close={(data) => closeSubstitutModal(data)}
                autorizations={autorizations}
                selectDataFromExecQuery={selectDataFromExecQuery}
            />
            {/* )} */}
        </>
    );
};

const mapDispatchToProps = {
    selectDataFromExecQuery,
};

export default connect(null, mapDispatchToProps)(Tasks);
