import React from "react";
import { Tabs, PageHeader } from "antd";
import UserTable from "./table";
const { TabPane } = Tabs;

const UserIndex = () => {
    return (
        <>
            <PageHeader title={"Lista utilizadores"} />
            <Tabs defaultActiveKey="1">
                <TabPane tab="Internos" key="1">
                    <UserTable type="internal" />
                </TabPane>
                <TabPane tab="Externos" key="2">
                    <UserTable type="external" />
                </TabPane>
                <TabPane tab="Todos" key="3">
                    <UserTable type="all" />
                </TabPane>
            </Tabs>
        </>
    );
};

export default UserIndex;
