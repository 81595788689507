import { Button, Col, Divider, Form, Input, Popconfirm, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
    ConventionCategoriesLevelProps,
    ConventionCategoriesProps,
    DataChangeProps,
    FormDataProps,
    FunctionalGroupsProps,
} from "../utils/types";
import moment from "moment";
import { ContractTypeProps } from "../../../../types/common";
import { Utilitaries } from "../../../../../business";
import { api_fetch } from "../../../../utils/HttpRequest/request";

interface IProps extends DataChangeProps {
    formData: FormDataProps;
    typesContract: ContractTypeProps[];
    functionalGroups: FunctionalGroupsProps[];
    conventionCategories: ConventionCategoriesProps[];
}

interface LevelsProps {
    [key: string | number]: ConventionCategoriesLevelProps[];
}

const { Option } = Select;

const table_contratuais_key1 = "progs_contratuais";
const table_contratuais_key2 = "prog_contratual";

const table_carreira_key1 = "progs_carreiras";
const table_carreira_key2 = "prog_carreira";

const table_funcionais_key1 = "progs_funcionais";
const table_funcionais_key2 = "prog_funcional";

const table_remunatorias_key1 = "progs_remunatorias";
const table_remunatorias_key2 = "prog_remunatoria";

export function Historic({
    formData,
    handleAdd,
    handleDelete,
    onChange,
    typesContract,
    functionalGroups,
    conventionCategories,
}: IProps) {
    const [levels, setLevels] = useState<LevelsProps>({});

    useEffect(() => {
        if (formData?.progs_carreiras?.prog_carreira?.length > 0) {
            loadLevesForEachLine();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getLevels(category: any, row: any) {
        const response = await api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "niveis_categoria",
                categoria: category,
            },
        });

        if (response.status === "SUCCESS") {
            setLevels({
                ...levels,
                [row]: Utilitaries.toArray(response.response.result),
            });
        }
    }

    async function loadLevesForEachLine() {
        Utilitaries.toArray(formData?.progs_carreiras?.prog_carreira)?.forEach(async (line: any) => {
            await getLevels(line.categoria, line.key);
        });
    }

    const columnsContractual: any[] = [
        {
            title: "Data",
            dataIndex: "data",
            width: "15%",
            render: (text: any, record: any) => {
                // text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(date) =>
                                onChange({
                                    dataIndex: "data",
                                    value: date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    row_key: record.key,
                                    table_key1: table_contratuais_key1,
                                    table_key2: table_contratuais_key2,
                                })
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Tipo de contrato",
            dataIndex: "tipo_contracto",
            width: "15%",
            render: (text: string, record: any) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona tipo contrato"
                    optionFilterProp="children"
                    defaultValue={text}
                    onChange={(value: string) => {
                        onChange({
                            dataIndex: "tipo_contracto",
                            value,
                            row_key: record.key,
                            table_key1: table_contratuais_key1,
                            table_key2: table_contratuais_key2,
                        });
                    }}
                    filterOption={(input, option) =>
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {typesContract.map((item) => {
                        return (
                            <Option key={item.id_tipo_contracto} value={item.id_tipo_contracto}>
                                {item.nome}
                            </Option>
                        );
                    })}
                </Select>
            ),
        },
        {
            title: "Modalidade",
            dataIndex: "modalidade_prog",
            width: "15%",
            render: (text: string, record: any) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="selecione modalidade"
                    optionFilterProp="children"
                    defaultValue={text}
                    onChange={(value: string) => {
                        onChange({
                            dataIndex: "modalidade_prog",
                            value,
                            row_key: record.key,
                            table_key1: table_contratuais_key1,
                            table_key2: table_contratuais_key2,
                        });

                        if (value !== "2") {
                            onChange({
                                dataIndex: "afetacao_prog",
                                value: "",
                                row_key: record.key,
                                table_key1: table_contratuais_key1,
                                table_key2: table_contratuais_key2,
                            });
                        }
                    }}
                    filterOption={(input, option) =>
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {[
                        ["Tempo total", "1"],
                        ["Tempo parcial", "2"],
                    ].map((item) => {
                        return (
                            <Option key={item[1]} value={item[1]}>
                                {item[0]}
                            </Option>
                        );
                    })}
                </Select>
            ),
        },
        {
            title: "Afetação",
            dataIndex: "afetacao_prog",
            width: "15%",
            render: (text: any, record: any) => {
                if (record.modalidade_prog !== "2") {
                    return null;
                }

                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(date) =>
                                onChange({
                                    dataIndex: "afetacao_prog",
                                    value: date.target.value,
                                    row_key: record.key,
                                    table_key1: table_contratuais_key1,
                                    table_key2: table_contratuais_key2,
                                })
                            }
                            value={text}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text: any, record: any) =>
                formData.progs_contratuais.prog_contratual.length >= 1 ? (
                    <Popconfirm
                        title="Remover a linha?"
                        onConfirm={() => handleDelete(record.key, table_contratuais_key1, table_contratuais_key2)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Remover
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    const columnsCareer: any[] = [
        {
            title: "Data",
            dataIndex: "data",
            width: "15%",
            render: (text: any, record: any) => {
                // text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(date) =>
                                onChange({
                                    dataIndex: "data",
                                    value: date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    row_key: record.key,
                                    table_key1: table_carreira_key1,
                                    table_key2: table_carreira_key2,
                                })
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Categoria",
            dataIndex: "categoria",
            width: "15%",
            render: (text: string, record: any) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona categoria"
                    optionFilterProp="children"
                    defaultValue={text}
                    onChange={(value: string) => {
                        onChange({
                            dataIndex: "categoria",
                            value,
                            row_key: record.key,
                            table_key1: table_carreira_key1,
                            table_key2: table_carreira_key2,
                        });
                        getLevels(value, record.key);
                    }}
                    filterOption={(input, option) =>
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {conventionCategories.map((item) => {
                        return (
                            <Option key={item.id_categoria} value={item.id_categoria}>
                                {item.designacao}
                            </Option>
                        );
                    })}
                </Select>
            ),
        },
        {
            title: "Nível",
            dataIndex: "nivel",
            width: "15%",
            render: (text: string, record: any) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona nível"
                    optionFilterProp="children"
                    defaultValue={text}
                    onChange={(value: string) => {
                        onChange({
                            dataIndex: "nivel",
                            value,
                            row_key: record.key,
                            table_key1: table_carreira_key1,
                            table_key2: table_carreira_key2,
                        });
                    }}
                    filterOption={(input, option) =>
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {Utilitaries.toArray(levels?.[record.key]).map((item: ConventionCategoriesLevelProps) => {
                        return (
                            <Option key={item.id_nivel_categoria} value={item.id_nivel_categoria}>
                                {item.nivel}
                            </Option>
                        );
                    })}
                </Select>
            ),
        },
        {
            title: "Escalão",
            dataIndex: "escalao_prog",
            width: "15%",
            render: (text: any, record: any) => {
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(date) =>
                                onChange({
                                    dataIndex: "escalao_prog",
                                    value: date.target.value,
                                    row_key: record.key,
                                    table_key1: table_carreira_key1,
                                    table_key2: table_carreira_key2,
                                })
                            }
                            value={text}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text: any, record: any) => (
                <Popconfirm
                    title="Remover a linha?"
                    onConfirm={() => handleDelete(record.key, table_carreira_key1, table_carreira_key2)}
                >
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Remover
                    </a>
                </Popconfirm>
            ),
        },
    ];

    const columnsFunctional: any[] = [
        {
            title: "Data",
            dataIndex: "data",
            width: "15%",
            render: (text: any, record: any) => {
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(date) =>
                                onChange({
                                    dataIndex: "data",
                                    value: date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    row_key: record.key,
                                    table_key1: table_funcionais_key1,
                                    table_key2: table_funcionais_key2,
                                })
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Grupos Funcionais",
            dataIndex: "id_funcao",
            width: "15%",
            render: (text: string, record: any) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="selecione grupo funcional"
                    optionFilterProp="children"
                    defaultValue={text}
                    onChange={(value: string) => {
                        onChange({
                            dataIndex: "id_funcao",
                            value,
                            row_key: record.key,
                            table_key1: table_funcionais_key1,
                            table_key2: table_funcionais_key2,
                        });
                    }}
                    filterOption={(input, option) =>
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {functionalGroups.map((item) => {
                        return (
                            <Option key={item.id_ficha_funcao} value={item.id_ficha_funcao}>
                                {item.descricao_funcao}
                            </Option>
                        );
                    })}
                </Select>
            ),
        },
        {
            title: "Unidade Orgânica",
            dataIndex: "uo_prog",
            width: "15%",
            render: (text: any, record: any) => {
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(date) =>
                                onChange({
                                    dataIndex: "uo_prog",
                                    value: date.target.value,
                                    row_key: record.key,
                                    table_key1: table_funcionais_key1,
                                    table_key2: table_funcionais_key2,
                                })
                            }
                            value={text}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text: any, record: any) => (
                <Popconfirm
                    title="Remover a linha?"
                    onConfirm={() => handleDelete(record.key, table_funcionais_key1, table_funcionais_key2)}
                >
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Remover
                    </a>
                </Popconfirm>
            ),
        },
    ];

    const columnsRemineratory: any[] = [
        {
            title: "Data",
            dataIndex: "data",
            width: "15%",
            render: (text: any, record: any) => {
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(date) =>
                                onChange({
                                    dataIndex: "data",
                                    value: date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    row_key: record.key,
                                    table_key1: table_remunatorias_key1,
                                    table_key2: table_remunatorias_key2,
                                })
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : undefined}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Tipo de remuneração",
            dataIndex: "tipo_remuneracao_prog",
            width: "15%",
            render: (text: any, record: any) => {
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(date) =>
                                onChange({
                                    dataIndex: "tipo_remuneracao_prog",
                                    value: date.target.value,
                                    row_key: record.key,
                                    table_key1: table_remunatorias_key1,
                                    table_key2: table_remunatorias_key2,
                                })
                            }
                            value={text}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Valor",
            dataIndex: "valor_prog",
            width: "15%",
            render: (text: any, record: any) => {
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            onChange={(date) =>
                                onChange({
                                    dataIndex: "valor_prog",
                                    value: date.target.value,
                                    row_key: record.key,
                                    table_key1: table_remunatorias_key1,
                                    table_key2: table_remunatorias_key2,
                                })
                            }
                            value={text}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text: any, record: any) => (
                <Popconfirm
                    title="Remover a linha?"
                    onConfirm={() => handleDelete(record.key, table_remunatorias_key1, table_remunatorias_key2)}
                >
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Remover
                    </a>
                </Popconfirm>
            ),
        },
    ];

    return (
        <>
            <Divider orientation="left">Progressão contratual</Divider>

            <Row>
                <Button
                    onClick={() => handleAdd(table_contratuais_key1, table_contratuais_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                size="small"
                scroll={{ x: 500 }}
                columns={columnsContractual}
                dataSource={formData.progs_contratuais.prog_contratual}
            />

            <Divider orientation="left">Progressão da carreira</Divider>

            <Row>
                <Button
                    onClick={() => handleAdd(table_carreira_key1, table_carreira_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                size="small"
                scroll={{ x: 500 }}
                columns={columnsCareer}
                dataSource={formData.progs_carreiras.prog_carreira}
            />

            <Divider orientation="left">Progressão funcional</Divider>

            <Row>
                <Button
                    onClick={() => handleAdd(table_funcionais_key1, table_funcionais_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                size="small"
                scroll={{ x: 500 }}
                columns={columnsFunctional}
                dataSource={formData.progs_funcionais.prog_funcional}
            />

            <Divider orientation="left">Progressão da remunatória</Divider>

            <Row>
                <Button
                    onClick={() => handleAdd(table_remunatorias_key1, table_remunatorias_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                size="small"
                scroll={{ x: 500 }}
                columns={columnsRemineratory}
                dataSource={formData.progs_remunatorias.prog_remunatoria}
            />
        </>
    );
}
