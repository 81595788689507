export const saveTemporaryInitProocessFilters = (filters) => {
    sessionStorage.setItem("init_process_filters", JSON.stringify(filters));
};

export const getTemporaryInitProocessFilters = () => {
    return JSON.parse(sessionStorage.getItem("init_process_filters")) || {};
};

export const addPanelEstablishedPref = (id) => {
    sessionStorage.setItem("panel_est", id);
};

export const getPanelEstablishedPref = () => {
    return sessionStorage.getItem("panel_est");
};

export const addStartProcessEstablishedPref = (id) => {
    sessionStorage.setItem("start_proc_est", id);
};

export const getStartProcessEstablishedPref = () => {
    return sessionStorage.getItem("start_proc_est");
};

export const addReportEstablishedPref = (id) => {
    sessionStorage.setItem("report_est", id);
};

export const getReportEstablishedPref = () => {
    return sessionStorage.getItem("report_est");
};
