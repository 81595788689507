import React, { useState, useEffect } from "react";
import { Modal, Button, Input, message, Table, Popconfirm, Row, Col } from "antd";
import { sendHttpRequest } from "../http.request";

const Place = ({ visible, onCancel, oldPlaces }) => {
    const [places, setPlaces] = useState([]);

    useEffect(() => {
        setPlaces(oldPlaces.list.interno);
    }, [oldPlaces.list]);

    const handleSave = () => {
        //
        const formData = new FormData();
        formData.append("accao", "guardar");
        formData.append("locais", JSON.stringify(places));

        sendHttpRequest("POST", "/Gestor/gerelocal.php", formData).then((jsonResponse) => {
            //
            if (jsonResponse.erro) {
                message.error("Ocorreu um erro.");
            } else {
                message.success("Guardado com sucesso");
                oldPlaces.get();
            }
        });
    };

    const onChange = (value, key, field) => {
        let newPlaces = [];

        places.forEach((item) => {
            if (item.id === key) {
                item[field] = value;
            }
            newPlaces.push(item);
        });

        setPlaces(newPlaces);
    };

    const removeItem = (line) => {
        let newPlaces = [];

        places.forEach((item) => {
            if (item.id !== line.id) {
                newPlaces.push(item);
            }
        });

        setPlaces(newPlaces);
    };

    const columns = [
        {
            title: "Nome",
            key: "name",
            width: "85%",
            dataIndex: "nome",
            render: (text, record) => (
                <Input defaultValue={text} required onChange={(e) => onChange(e.target.value, record.id, "nome")} />
            ),
        },
        {
            title: "Ação",
            key: "acao",
            width: "15%",
            render: (_, item) => (
                <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => removeItem(item)}>
                    <a
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        href="#!"
                        className="qa-text-del"
                    >
                        Eliminar
                    </a>
                </Popconfirm>
            ),
        },
    ];

    return (
        <Modal
            title="Repetição do Evento"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            width="60%"
            centered
            destroyOnClose
        >
            <Row gutter={16} align="middle" type="flex">
                <Col xs={12}>
                    <Button
                        xs={24}
                        sm={12}
                        onClick={handleSave}
                        icon="plus"
                        size="small"
                        type="primary"
                        style={{ marginBottom: 16, float: "left" }}
                    >
                        Guardar
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        xs={24}
                        sm={12}
                        onClick={() => {
                            const index = Math.ceil(Math.random() * 500) + "_" + places.length;
                            setPlaces([
                                {
                                    id: index,
                                    nome: null,
                                    descricao: "",
                                },
                                ...places,
                            ]);
                        }}
                        icon="plus"
                        size="small"
                        type="primary"
                        style={{ marginBottom: 16, float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Col>
            </Row>

            <Table size="small" scroll={{ y: 300 }} dataSource={places} columns={columns} rowKey="id" />
        </Modal>
    );
};

export default Place;
