import { Divider, Col } from "antd";
import React from "react";

const QaDivider = ({ text }) => {
    // const lgn = sessionStorage.getItem("lgn");

    // if (lgn && lgn === text) {
    //     return null;
    // } else {
    //     sessionStorage.setItem("lgn", text);
    // }

    return (
        <Col span={24}>
            <span>
                <strong>{text}</strong>
            </span>
            <Divider style={{ marginTop: 8 }} />
        </Col>
    );
};

export default QaDivider;
