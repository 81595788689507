/* eslint-disable no-eval */
import { useEffect, useState } from "react";
import { GenericObject, InputInTableProps, RequestStatus } from "../../../types/common";
import {
    FormInputsObjectProps,
    FormInputsProps,
    InputCalcTriggerProps,
    InputDependenceProps,
} from "../../../types/formTypes";
import { api_fetch } from "../../../utils/HttpRequest/request";
import {
    checkInputReadOnly,
    checkInputRelevant,
    checkInputRequired,
    checkInputRestriction,
    checkInputVisibility,
    inputCalculate,
} from "../handle/inputHandle";
import {
    actionToCallInFirstRender,
    getDefaultValueResult,
    getErrorText,
    isToSetValueInWhenValueChanged,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    round as roundNumber,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    round3,
} from "../../../utils/functions";
import { Utilitaries } from "../../../../business";
import { regularExpressionHandle } from "../handle/regularExpressionHandle";

interface UseInputHandleProps {
    when_value_changed_dependence?: InputDependenceProps;
    calcTrigger?: InputCalcTriggerProps;
    calcRowTrigger?: InputCalcTriggerProps;
    componentProperties: FormInputsProps;
    formState?: FormInputsObjectProps;
    value?: string;
    rowTable?: InputInTableProps;
    showError?: boolean;
}

export function useInputHandle({
    when_value_changed_dependence,
    calcTrigger,
    calcRowTrigger,
    componentProperties,
    formState,
    value,
    rowTable,
    showError,
}: UseInputHandleProps) {
    const [status, setStatus] = useState<RequestStatus>("IDLE");
    const [data, setData] = useState<any[]>([]);
    const [componentValue, setComponentValue] = useState<any>();
    const [componentsValues, setComponentsValues] = useState<any>();
    const [calculated, setCalculated] = useState<any>("");
    const [relevant, setRelevant] = useState(true);
    const [visible, setVisible] = useState(true);
    const [restriction, setRestriction] = useState(true);
    const [required, setRequired] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const [error, setError] = useState("");
    // const [errorMessage, setErrorMessage] = useState<string>();
    const [hasUpdatedValue, setHasUpdateValue] = useState(0);
    const [hasUpdatedData, setHasUpdateData] = useState(false);

    // tratar quando valor alterado
    useEffect(() => {
        let when_value_changed_dependence_aux: InputDependenceProps | undefined = when_value_changed_dependence;
        let exec_when_value_changed_is_null = false;

        if (!when_value_changed_dependence_aux || !componentProperties?.full_path) {
            if (!componentProperties?.full_path) {
                return;
            }

            // get action
            const action = actionToCallInFirstRender({ componentProperties, formState });

            if (!action) return;
            if (action) {
                exec_when_value_changed_is_null = true;
                when_value_changed_dependence_aux = { action: [action], key: "", trigger: true };
            }
        }

        const handleWhenValueChanged = async () => {
            try {
                if (!formState || !when_value_changed_dependence_aux) {
                    return;
                }

                // if (componentProperties.full_path === "morada_correspondencia/pais") {
                //     debugger;
                // }

                const actionKey = when_value_changed_dependence_aux;
                for (const key of actionKey.action) {
                    const expression: string = componentProperties?.[`@accao_${key}`];
                    if (expression === undefined) return;

                    const regularExpressionResult = regularExpressionHandle({
                        expression,
                        value: null,
                        runExpression: false,
                        componentProperties,
                        formState,
                        rowTable,
                    });

                    // const exp = String(Utilitaries.toString(regularExpressionResult)).replace("\n", "\n");
                    // debugger;

                    // const regularExpressionWithBreak = Utilitaries.toString(regularExpressionResult).replaceAll(
                    //     /\n/g,
                    //     "\n"
                    // );

                    const formatedExpression = String(Utilitaries.toString(regularExpressionResult)).replace(
                        /{[\w[\],\s']+,(\w|\[|\[|[À-ú]|,|:|;|\(|\)|\s|-|'|º|ª|&|=|_|\.|\/|\?)+}|(?<=\()[\w|:]+(?=(,|\)))/g,
                        "`$&`"
                    );

                    // const formatedExpression = String(Utilitaries.toString(regularExpressionResult)).replace(
                    //     /{[\w[\],\s']+,(\w|\[|\[|[À-ú]|,|\s|-|'|&|=|_|\.|\/|\?)+}|(?<=\()[\w|:]+(?=(,|\)))/g,
                    //     '"$&"'
                    // );

                    let result: any = formatedExpression;
                    // porque quebra na ficha de condominios tab morada botão copiar arumamento
                    // set espreção do tipo //cliente/entidade/moradas/morada/arruamento não precissa executar
                    if (/\/(\/[a-z]+)+$/.test(expression) && !expression.includes("+")) {
                        result = formatedExpression?.replace(/^'|'$/g, "");
                    } else {
                        result = await eval(formatedExpression);
                    }
                    console.log("auto_preenche", key, result);

                    if (expression?.includes("auto_preenche_tudo(")) {
                        setComponentsValues(Array.isArray(result) ? result[0] : result);
                    } else if (isToSetValueInWhenValueChanged(expression, componentProperties)) {
                        let value = "";
                        if (Array.isArray(result)) {
                            value = result?.[0]?.[componentProperties.key_list || componentProperties.key];

                            if (typeof result?.[0] === "object") {
                                const item = result?.[0];
                                if (
                                    !item.hasOwnProperty(componentProperties.key_list) &&
                                    !item.hasOwnProperty(componentProperties.key)
                                ) {
                                    const allKeys = Object.keys(item);

                                    value = item[allKeys[0]];
                                }
                            }
                        } else {
                            value = result;
                        }

                        setStatus("FETCHED");
                        setComponentValue(value);
                        setHasUpdateValue(hasUpdatedValue + 1);
                    } else {
                        setData(result || []);
                        setHasUpdateData(true);
                        // for cases that have _por_defeito
                        const defaultValue = getDefaultValueResult({
                            componentProperties,
                            exec_when_value_changed_is_null,
                            result: result,
                        });

                        if (defaultValue) {
                            setComponentValue(defaultValue[componentProperties.key_list || componentProperties.key]);
                            setHasUpdateValue(hasUpdatedValue + 1);
                        }
                        setStatus("FETCHED");
                    }
                }
            } catch (error) {
                console.log("auto_preenche", error);
                // setError("Erro ao obter dados");
            }
        };

        handleWhenValueChanged();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when_value_changed_dependence?.trigger]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function auto_preenche(query: string, ...listParams: string[]) {
        const params: GenericObject = {};
        try {
            let hasNullParams = false;
            const ignoraCache = true;
            const ignoring_parameters = componentProperties.ignoring_parameters === "true";

            for (const param of listParams) {
                const [key, value] = param.replace(/[{}]/g, "").split(/,(.*)/s);

                // const t = value;

                let realValue = value.trim().replace(/^'([\w|\W]+)'$/g, "`$1`");
                realValue = eval(realValue);
                // const realValue

                if (
                    (realValue === undefined || realValue === null || realValue === "") &&
                    key !== componentProperties.key
                ) {
                    hasNullParams = true;
                    // continue;
                    // break;
                }

                params[key] = Utilitaries.isEmpty(realValue) ? "" : realValue;
            }

            if (hasNullParams && !ignoring_parameters) {
                return;
            }

            if (!Utilitaries.isEmpty(value)) {
                params._valor_campo_ = value;
            }

            setStatus("FETCHING");
            const { response } = await api_fetch({
                endPoint: `Gestor/execQuery.php`,
                method: "POST",
                params: {
                    query,
                    ...params,
                },
            });
            // setStatus("FETCHED");

            return response?.result;
        } catch (error) {
            setStatus("FETCH_ERROR");
            return null;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const auto_preenche_valor = auto_preenche;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const auto_preenche_tudo = auto_preenche;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const round = (x: number) => roundNumber(x);

    // tratar calcular
    useEffect(() => {
        if (
            !componentProperties.calculate ||
            (componentProperties.persist !== "nao" &&
                Utilitaries.isEmpty(calcTrigger) &&
                Utilitaries.isEmpty(calcRowTrigger))
        ) {
            return;
        }

        // if (componentProperties.key === "total_valor") {
        //     debugger;
        // }
        const _calculate = inputCalculate({ componentProperties, formState, rowTable });

        if (_calculate !== calculated) {
            setCalculated(_calculate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcTrigger?.calculateTrigger, calcRowTrigger?.calculateTrigger]);

    // tratar relevante
    useEffect(() => {
        const _relevant = checkInputRelevant({ componentProperties, formState, rowTable });

        if (_relevant !== relevant) {
            setRelevant(_relevant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcTrigger?.relevantTrigger, calcRowTrigger?.relevantTrigger]);

    // tratar visible
    useEffect(() => {
        const _visible = checkInputVisibility({ componentProperties, formState, rowTable });

        if (_visible !== visible) {
            setVisible(_visible);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcTrigger?.visibleTrigger, calcRowTrigger?.visibleTrigger]);

    // tratar restrinção
    useEffect(() => {
        const _restriction = checkInputRestriction({ componentProperties, formState, rowTable, value });

        if (_restriction !== restriction) {
            setRestriction(_restriction);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcTrigger?.restrictionTrigger, calcRowTrigger?.restrictionTrigger]);

    // tratar obrigatorio
    useEffect(() => {
        const _required = checkInputRequired({ componentProperties, formState, rowTable });

        if (_required !== required) {
            setRequired(_required);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcTrigger?.requiredTrigger, calcRowTrigger?.requiredTrigger]);

    // tratar apenas leitura
    useEffect(() => {
        const _readOnly = checkInputReadOnly({ componentProperties, formState, rowTable });

        if (_readOnly !== readOnly) {
            setReadOnly(_readOnly);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcTrigger?.readOnlyTrigger, calcRowTrigger?.readOnlyTrigger]);

    useEffect(() => {
        if (showError) {
            const message = getErrorText({
                required,
                restriction,
                value,
                error_message: componentProperties.error_message,
            });
            setError(message || "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [required, restriction, showError, value]);

    return {
        status,
        data,
        componentValue,
        componentsValues,
        hasUpdatedValue,
        hasUpdatedData,
        calculated,
        relevant,
        restriction,
        required,
        visible,
        readOnly,
        error,
    };
}
