import { Button, Col, Form, Input, Tag, InputNumber, PageHeader, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { sendHttpRequest } from "../../components/http.request";
import { QATable } from "../../components/qa_custom";
import { toArray } from "../../components/util";
import { Session, Utilitaries } from "../../business";

const UnicvPayment = () => {
    const browserHistory = useHistory();

    const [nif, setNIF] = useState(null);
    const [list, setList] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    useEffect(() => {
        const getNif = async () => {
            const formData = new FormData();
            formData.append("accao", "get-nif-by-student-code");
            formData.append("code", Session.getUsername());

            return sendHttpRequest("POST", "/Gestor/gerePagamentoUnicv.php", formData).then((data) => {
                if (data.nif) {
                    setNIF(data.nif);
                    loadData(data.nif);
                }
                // setList(toArray(data?.faturas?.linha));
            });
        };

        if (Session.isCLient()) {
            getNif();
        }
    }, []);

    const loadData = async (_nif = null) => {
        const nifSearch = Utilitaries.toString(nif || _nif);

        if (Utilitaries.isEmpty(nifSearch) && Session.isPortal()) {
            message.warn("Informe o NIF");
            return;
        }

        const formData = new FormData();
        formData.append("accao", "get-by-nif");
        formData.append("nif", nifSearch);
        formData.append("pagina", 1);
        // formData.append("pagina", pagination.page);
        formData.append("resultados_pagina", 10);
        // formData.append("resultados_pagina", pagination.perPage);

        setLoadingData(true);

        return sendHttpRequest("POST", "/Gestor/gerePagamentoUnicv.php", formData).then((data) => {
            setLoadingData(false);
            setList(toArray(data?.faturas?.linha));
        });
    };

    const handleDuc = async ({ duc, id, dataFatura, action }) => {
        const formData = new FormData();
        if (action === "CONSULT" && duc) {
            window.open(`https://portalrep.gov.cv/reports/rwservlet?SIGOF_DUC&p_nu_duc=${duc}`, "_blank");
            return;
        } else {
            formData.append("accao", "duc-create");
            formData.append("id_pagamento", id);

            formData.append("mes", moment(dataFatura).format("MMMM"));
            formData.append("ano", moment(dataFatura).format("YYYY"));
        }

        // setLoadingData(true);

        return sendHttpRequest("POST", "/Gestor/gerePagamentoUnicv.php", formData).then((data) => {
            // setLoadingData(false);
            if (data?.error) {
                message.error(data.error);
            } else {
                loadData();
            }
        });
    };

    // id SERIAL,
    // mes VARCHAR,
    // nif VARCHAR,
    // valor VARCHAR,
    // ano INTEGER,
    // estado VARCHAR,
    // duc integer,
    // id_entidade VARCHAR,
    // id_residencia VARCHAR,

    const tablesColumns = [
        {
            title: "Entidade",
            dataIndex: "entidade",
            key: "entidade",
            ellipsis: true,
        },
        {
            title: "Mês",
            dataIndex: "data_fatura",
            key: "data_fatura",
            ellipsis: true,
            render: (value, _) => {
                return moment(value).format("MMMM");
            },
        },
        {
            title: "Ano",
            dataIndex: "data_fatura",
            key: "data_fatura",
            ellipsis: true,
            render: (value, _) => {
                return moment(value).format("YYYY");
            },
        },
        {
            title: "Valor",
            dataIndex: "valor",
            key: "valor",
            ellipsis: true,
        },
        {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
            ellipsis: true,
            render: (estado, _) => {
                switch (estado) {
                    case "LIQUIDADO":
                        return <Tag color="red">LIQUIDADO</Tag>;

                    case "PAGO":
                        return <Tag color="green">PAGO</Tag>;

                    default:
                        return <Tag color="orange">ANULADO</Tag>;
                }
            },
        },
        {
            title: "DUC",
            dataIndex: "duc",
            key: "duc",
            ellipsis: true,
            render: (duc, row) => {
                if (Utilitaries.isEmpty(duc)) {
                    return (
                        <Button
                            onClick={() =>
                                handleDuc({ duc: null, id: row.id, dataFatura: row.data_fatura, action: "CREATE" })
                            }
                            icon={"play-circle"}
                            size="small"
                        >
                            Gerar DUC
                        </Button>
                    );
                }

                if (!Utilitaries.isEmpty(duc) && row.estado === "ANULADO") {
                    return (
                        <Button
                            onClick={() =>
                                handleDuc({ duc, id: row.id, dataFatura: row.data_fatura, action: "CREATE" })
                            }
                            icon={"play-circle"}
                            size="small"
                        >
                            Gerar novo DUC
                        </Button>
                    );
                }

                return (
                    <Button
                        onClick={() => handleDuc({ duc, action: "CONSULT" })}
                        icon={"file-pdf"}
                        type="link"
                        size="small"
                    >
                        Consultar DUC
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <PageHeader onBack={() => browserHistory.goBack()} title={"Pagamento da residência"} />
            <Row gutter={[16, 16]}>
                {!Session.isCLient() ? (
                    <>
                        <Col xs={8}>
                            <InputNumber
                                // disabled={loading}
                                value={nif}
                                style={{ width: "100%" }}
                                // prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                                onChange={(value) => setNIF(value)}
                                placeholder="NIF da entidade"
                            />
                        </Col>
                        <Col xs={8}>
                            <Button onClick={loadData} icon={"reload"}>
                                Carregar
                            </Button>
                        </Col>
                    </>
                ) : null}

                <Col span={24}>
                    <QATable
                        columns={tablesColumns}
                        data={list}
                        loadingData={loadingData}
                        datakey={"id"}
                        currentPage={1}
                        totalResult={list.length}
                        pageChange={(page, perPage) => {}}
                        tableChange={() => {}}
                    />
                </Col>
            </Row>
        </>
    );
};

export default UnicvPayment;
