import React from "react";
import { xmlProps } from "../../constants";
import QaDataDisplay from "../qa_components/dataDisplay";
import QaDate from "../qa_components/date";
import QaTimePicker from "../qa_components/timePicker";
import QaDynamicList from "../qa_components/dynamicList";
import QaFixedList from "../qa_components/fixedList";
import QaInput from "../qa_components/input";
import QaModal from "../qa_components/modal";
import QaRadio from "../qa_components/radio";
import QaSelect from "../qa_components/select";
import QaSeparator from "../qa_components/separator";
import QaReport from "../qa_components/report";
import QaTable from "../qa_components/table";
import QaDatagrid from "../qa_components/datagrid";
import QAInputGroup from "../qa_components/inputGroup";
import QaAlert from "../qa_components/alert";
import QaDivider from "../qa_components/divider";
import { componentType } from "../../constants/index";
import { Input, Tooltip, Icon } from "antd";
import { MyContext } from "./execution.form.provider";
import QAButton from "../qa_components/button";
import QaCheckBox from "../qa_components/checkBox";
import QaAutoComplete from "../qa_components/sugestion";
import QaUpload from "../qa_components/upload";
import QaLink from "../qa_components/link";
import { getDataSource } from "../../constants/utils";
import { getSelectDataKeys, readOnly, restriction, isRequired } from "../../utils/input_attributes";
import { isEmpty, setValueByPopupParams } from "../../utils/fields";
import { Utilitaries } from "../../../../business";

const setLabel = ({ title, itemData, renderContext, state, record, returnText, allParams }) => {
    let textLabel = "";
    itemData = setValueByPopupParams({ item: itemData, params: allParams, getFromLabel: true });

    if (itemData["@etiqueta"]) textLabel = itemData["@etiqueta"];
    else if (itemData["@etiqueta"] === "") textLabel = "";
    else textLabel = itemData?.key?.toPascalCase(true);

    if (renderContext === "column") {
        if (returnText) {
            return { label: null, textLabel };
        }

        return null;
    }

    let required = false;
    if (itemData?.["@obrigatorio"]) {
        required = isRequired({ itemData, state, record });
    }

    const isBold = Utilitaries.toString(itemData?.["@estilo"]).includes("negrito");

    const label = (
        <span>
            {required && <i style={{ color: "red" }}>*&nbsp;</i>}
            {Utilitaries.isEmpty(textLabel) ? null : isBold ? <b> {textLabel}</b> : textLabel}
            {itemData?.["@ajuda"] && (
                <Tooltip title={itemData?.["@ajuda"]}>
                    <Icon type="question-circle-o" />
                </Tooltip>
            )}
        </span>
    );

    if (returnText) {
        return { label, textLabel };
    }

    return label;
};

const ComponentRenderer = ({
    item,
    itemData,
    appState,
    renderContext,
    formName,
    isRoot,
    columnDataSource,
    itemKey,
    readonly,
    record,
    tabName,
    root,
    formKey,
    error,
    showFieldEditorModal,
    fixedSize,
    allParams,
    numbersOfColumnsToRender,
    loadData,
}) => {
    const dataSource = getDataSource(itemData);
    return (
        <MyContext.Consumer>
            {(context) => {
                let formItemStyle = {};
                const {
                    handleTableRemove,
                    handleInputChange,
                    handleTableInputChange,
                    handleInputGroupChange,
                    showModal,
                    handleButtonComponentClick,
                    state,
                    getReporterByName,
                    getItemByKey,
                    triggerModalId,
                } = context;

                const inputLabel = setLabel({
                    title: itemData["@etiqueta"],
                    itemData,
                    renderContext,
                    state,
                    record,
                    returnText: true,
                    allParams,
                });

                let label = null,
                    textLabel = null;

                if (inputLabel) {
                    label = inputLabel.label;
                    textLabel = inputLabel.textLabel;
                }

                const onChange = (value, restParams) => {
                    if (value === undefined || value === "undefined") {
                        value = "";
                    }

                    if (
                        (value || typeof value == "number" || typeof value == "boolean") &&
                        value !== undefined &&
                        !Utilitaries.isEmpty(value)
                    ) {
                        value = typeof value === "object" ? value.target.value : value;
                    } else {
                        value = "";
                    }

                    if (renderContext === "column") {
                        const columnKey = item.dataIndex;
                        handleTableInputChange({
                            value,
                            key: itemKey,
                            columnKey,
                            recordKey: record.key,
                            inputGroupPath: item?.tablePath ? [...item?.tablePath] : null,
                            ...restParams,
                        });
                    } else if (renderContext === "inputGroup") {
                        handleInputGroupChange({
                            value,
                            key: itemData.key,
                            root: itemData.root,
                            inputGroupPath: itemData?.inputGroupPath,
                            ...restParams,
                        });
                    } else {
                        handleInputChange({
                            value: value,
                            key: itemData.key,
                            inputGroupPath: itemData?.inputGroupPath,
                            ...restParams,
                        });
                    }
                };

                const downloadReport = ({ saida }) => {
                    let params = {};
                    if (item?.["@param_popup"]) {
                        params = item["@param_popup"]
                            .split(";")
                            .map((it) => it.replace(/\[|\]|\s/g, "").split(","))
                            .reduce((accumulator, current) => {
                                if (Array.isArray(current) && current.length === 2) {
                                    const valueName = Utilitaries.toString(current[1])?.trim();
                                    if (/^[[A-zÀ-ú]|\s]+$/.test(valueName)) {
                                        accumulator[current[0]] = valueName.replace(/]/g, "");
                                    } else {
                                        const key = current[1].replace(/\W/g, "").trim();

                                        const itemKeyValue = getItemByKey({
                                            key,
                                            tree: state.form,
                                            rowKey: record?.key,
                                        })[0];

                                        if (record) {
                                            if (itemKeyValue?.key in record) {
                                                const itemValue = record[itemKeyValue?.key];
                                                if (typeof itemValue === "object") {
                                                    accumulator[current[0]] = itemValue?.id;
                                                } else {
                                                    accumulator[current[0]] = itemValue;
                                                }
                                            } else if (!Utilitaries.isEmpty(itemKeyValue?.value)) {
                                                accumulator[current[0]] = itemKeyValue?.value;
                                            }
                                        } else {
                                            accumulator[current[0]] = itemKeyValue?.value;
                                        }
                                    }
                                }

                                return accumulator;
                            }, {});
                    }

                    getReporterByName({
                        saida,
                        nome: item?.["@relatorio"],
                        ...params,
                    });
                };

                const handleRemoveInactivedValueError = () => {
                    context.removeInactivedValueError(itemData, record);
                };

                if (renderContext === "column") {
                    formItemStyle.margin = 0;
                    formItemStyle.padding = 0;
                }

                const commonProps = {
                    loadData,
                };

                switch (item[xmlProps.componentType]) {
                    case componentType._checkBox: {
                        return (
                            <QaCheckBox
                                type={componentType._checkBox}
                                itemData={itemData}
                                formItemStyle={formItemStyle}
                                dataSource={dataSource}
                                {...context}
                                renderContext={renderContext}
                                label={label}
                                error={error}
                                onChange={onChange}
                            />
                        );
                    }

                    case componentType._inputTime: {
                        return (
                            <QaTimePicker
                                type={componentType._inputTime}
                                itemData={itemData}
                                formItemStyle={formItemStyle}
                                {...context}
                                onChange={onChange}
                                readonly={readonly}
                                renderContext={renderContext}
                                label={label}
                                error={error}
                            />
                        );
                    }

                    case componentType.divider: {
                        return (
                            <QaDivider
                                setLabel={setLabel}
                                renderContext={renderContext}
                                itemData={item}
                                type={componentType.divider}
                            />
                        );
                    }

                    case componentType.alert: {
                        return <QaAlert itemData={item} renderContext={renderContext} type={componentType.alert} />;
                    }

                    case componentType._inputGroup: {
                        return (
                            <QAInputGroup
                                type={item["@tipo"]}
                                itemData={itemData}
                                {...context}
                                renderContext={renderContext}
                                isRoot={isRoot}
                                root={root}
                                tabName={tabName}
                                context={context}
                                formItemStyle={formItemStyle}
                                setLabel={setLabel}
                                label={label}
                            />
                        );
                    }

                    case componentType._input:
                    case componentType._inputNumber:
                    case componentType.shortText:
                    case componentType.text:
                    case componentType.areaTexto: {
                        return (
                            <QaInput
                                type={item["@tipo"]}
                                itemData={itemData}
                                renderContext={renderContext}
                                formItemStyle={formItemStyle}
                                label={label}
                                error={error}
                                readonly={readonly}
                                onChange={onChange}
                                textLabel={textLabel}
                                fixedSize={fixedSize}
                                showFieldEditorModal={showFieldEditorModal}
                                {...context}
                            />
                        );
                    }

                    case componentType.dynamicList: {
                        return (
                            <QaDynamicList
                                record={record}
                                itemData={itemData}
                                renderContext={renderContext}
                                label={label}
                                error={error}
                                {...context}
                                root={root}
                                columnDataSource={columnDataSource}
                                dataSource={dataSource}
                                readonly={readonly}
                                onChange={onChange}
                                rowKey={record ? record._key_ : undefined}
                                formItemStyle={formItemStyle}
                            />
                        );
                    }

                    case componentType.fixedList: {
                        return (
                            <QaFixedList
                                type={item["@tipo"]}
                                label={label}
                                fixedSize={fixedSize}
                                showFieldEditorModal={showFieldEditorModal}
                                renderContext={renderContext}
                                formItemStyle={formItemStyle}
                                itemData={itemData}
                                root={root}
                                dataSource={dataSource}
                                rowKey={record ? record.key : undefined}
                                {...commonProps}
                                {...context}
                            />
                        );
                    }

                    case componentType.boolean:
                    case componentType._selectionButtons: {
                        return (
                            <QaRadio
                                type={item["@tipo"]}
                                itemData={itemData}
                                formItemStyle={formItemStyle}
                                {...context}
                                dataSource={dataSource}
                                columnDataSource={columnDataSource}
                                renderContext={renderContext}
                                label={label}
                                error={error}
                                root={root}
                                readonly={readonly}
                                rowKey={record ? record.key : undefined}
                            />
                        );
                    }

                    case componentType.combobox:
                    case componentType._select: {
                        return (
                            <QaSelect
                                type={item["@tipo"] === componentType.combobox ? componentType._select : item["@tipo"]}
                                itemData={itemData}
                                formItemStyle={formItemStyle}
                                {...context}
                                root={root}
                                columnDataSource={columnDataSource}
                                dataSource={dataSource}
                                rowKey={record && record?.key}
                                renderContext={renderContext}
                                label={label}
                                error={error}
                                onChange={onChange}
                                readonly={readonly}
                                fixedSize={fixedSize}
                                showFieldEditorModal={showFieldEditorModal}
                                removeInactivedValueError={handleRemoveInactivedValueError}
                                {...commonProps}
                            />
                        );
                    }

                    case componentType.sugestion: {
                        return (
                            <QaAutoComplete
                                type={item["@tipo"]}
                                itemData={itemData}
                                formItemStyle={formItemStyle}
                                {...context}
                                root={root}
                                columnDataSource={columnDataSource}
                                dataSource={dataSource}
                                rowKey={record && record?.key}
                                renderContext={renderContext}
                                label={label}
                                error={error}
                                readonly={readonly}
                                onChange={onChange}
                            />
                        );
                    }

                    case componentType.upload: {
                        return (
                            <QaUpload
                                type={item["@tipo"]}
                                itemData={itemData}
                                formItemStyle={formItemStyle}
                                {...context}
                                root={root}
                                columnDataSource={columnDataSource}
                                dataSource={dataSource}
                                rowKey={record && record?.key}
                                renderContext={renderContext}
                                error={error}
                                label={label}
                                onChange={onChange}
                            />
                        );
                    }

                    case componentType.link: {
                        return <QaLink itemData={itemData} record={record} />;
                    }

                    case componentType._tab: {
                        return (
                            <QaSeparator
                                formName={formName}
                                itemData={itemData}
                                {...context}
                                renderContext={renderContext}
                            />
                        );
                    }

                    case componentType.date: {
                        return (
                            <QaDate
                                type={componentType.date}
                                itemData={itemData}
                                formItemStyle={formItemStyle}
                                {...context}
                                renderContext={renderContext}
                                label={label}
                                error={error}
                                readonly={readonly}
                                onChange={onChange}
                            />
                        );
                    }

                    case componentType._dataDisplay: {
                        return (
                            <QaDataDisplay
                                label={label}
                                error={error}
                                itemData={itemData}
                                renderContext={renderContext}
                                numbersOfColumnsToRender={numbersOfColumnsToRender}
                            />
                        );
                    }

                    case componentType._button:
                    case componentType.icon: {
                        return (
                            <QAButton
                                label={label}
                                itemData={itemData}
                                handleButtonComponentClick={handleButtonComponentClick}
                                record={record}
                                showModal={showModal}
                                readonly={readonly}
                                error={error}
                                state={state}
                                {...context}
                                formItemStyle={formItemStyle}
                            />
                        );
                    }

                    case componentType.report: {
                        return (
                            <QaReport
                                downloadReport={downloadReport}
                                formItemStyle={formItemStyle}
                                {...context}
                                label={label}
                            ></QaReport>
                        );
                    }

                    case componentType._readonly: {
                        let value = "";
                        if (item && item.value) {
                            value = item.value;
                        } else {
                            value = "Não definido";
                        }
                        return (
                            <Input
                                label={label}
                                placeholder={item["@placeholder"]}
                                value={value}
                                renderContext={renderContext}
                                disabled
                            />
                        );
                    }

                    case componentType._dynamicTable:
                    case componentType._selectionTable: {
                        return (
                            <QaTable
                                type={item["@tipo"]}
                                itemData={itemData}
                                {...context}
                                renderContext={renderContext}
                                isRoot={isRoot}
                                context={context}
                                root={root}
                                formItemStyle={formItemStyle}
                                setLabel={setLabel}
                                label={label}
                                allParams={allParams}
                                formKey={formKey}
                                {...commonProps}
                            />
                        );
                    }

                    case componentType._dynamicDatagrid:
                    case componentType.datagrid: {
                        return (
                            <QaDatagrid
                                type={item["@tipo"]}
                                itemData={itemData}
                                {...context}
                                renderContext={renderContext}
                                isRoot={isRoot}
                                root={root}
                                context={context}
                                formItemStyle={formItemStyle}
                                setLabel={setLabel}
                                label={label}
                                formKey={formKey}
                                {...commonProps}
                            />
                        );
                    }

                    case componentType._popup: {
                        return (
                            <QaModal
                                itemData={itemData}
                                triggerModalId={triggerModalId}
                                renderContext={renderContext}
                                formName={formName}
                                {...context}
                            />
                        );
                    }

                    case componentType._delete: {
                        return (
                            <a
                                href="javascript:;"
                                onClick={(e) => {
                                    handleTableRemove({
                                        key: itemKey,
                                        recordKey: record.key,
                                        inputGroupPath: item?.tablePath ? [...item?.tablePath] : null,
                                    });
                                }}
                                className="qa-text-del"
                            >
                                Eliminar
                            </a>
                        );
                    }

                    default: {
                        return (
                            <div>
                                <span style={{ color: "silver" }}>UI Widget: {itemData["@tipo"]}</span>
                                <span style={{ color: "red" }}> (em construção...)</span>{" "}
                            </div>
                        );
                    }
                }
            }}
        </MyContext.Consumer>
    );
};

export default ComponentRenderer;
