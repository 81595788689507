export const actionTypes = {
    getAuthorizations: "GET_AUTHORIZATIONS",
};

export const autorizacoesSistema = [
    "LISTAS_DINÂMICAS",
    "CONS_DOC_REL",
    "CORRIGIR_REGISTOS",
    "ANUL_PROC",
    "REATRIBUI_TAREFA",
    "ADMIN_AGENDA",
    "INFORMACOES_QA",
    "INTERACAO_PROCESSOS",
    "GERAR_RELATORIOS",
    "LEMBRETES_QA",
    "GESTÃO_DOCUMENTOS",
];
