import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Row, Checkbox, Col, Tooltip, Select, Modal, Input, Icon, Popconfirm, Result, message } from "antd";
import { useParams, Link, useHistory } from "react-router-dom";

import { sendHttpRequest, getToken } from "../http.request";
import { baseUrl } from "../../constants/enviroment";
import QAForm from "../Form/index";
import { QATable } from "../qa_custom";
import { downloadFile, validateResponse } from "../util";
import { RenderListForm } from "../../V2/shared/FormHandler/RenderFormList";
import Confirm from "../qa_custom/confirm";
import { Session, Utilitaries } from "../../business";

const listNameToOpenFormV2 = [
    "condominios_v2",
    "consulta_despesas_condominios",
    "reg_faltas_colab",
    "rent_proprietarios",
    "rent_imoveis",
    "rent_clientes",
    "qa_mapa_ocorrencias",
    "orbis_valida_consumos_ocorrencias",
    "sala_reunioes",
    "orbis_controlo_atas",
    "condominios_atas_registadas",
    "orbis_pagamentos_conta",
    "consulta_recebimentos",
    "consulta_recebimentos_datagrid",
    "orbis_terceiros",
    "orbis_simulacao_processamento",
    "procuradores",
    "condominios_duplicar_condominio",
    "alertas_sistema",
    "lembretes_sistema",
    "con_insere_fornecedores",
    // "saidas_stock",
];

const openInFormV2 = (listName) => {
    return listNameToOpenFormV2.includes(listName);
};

const List = ({ listName, onGetList }) => {
    const { name } = useParams();
    listName = listName ? listName : name;

    const history = useHistory();

    // show or not actions buttons
    let action = new URLSearchParams(window.location.search).get("action");
    action = action ? (action == "false" ? false : true) : true;

    // printer states
    const [printer, setPrinter] = useState(null);
    const [showPrinterSelector, setShowPrinterSelector] = useState({
        id: null,
        attributes: null,
    });
    const [printerList, setPrinterList] = useState([]);

    const [tableSelectedLine, setTableSelectedLine] = useState({});

    const [filter, setfilter] = useState({});
    const [list, setList] = useState({});
    const [showRemoved, setShowRemoved] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [modelForm, setModelForm] = useState(null);
    const [formModalTitle, setFormModalTitle] = useState(null);

    const [tablesColumns, setTableColumns] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });

    const [listNotFound, setListNotFount] = useState(false);
    const [justSeePopup, setJustSeePopup] = useState(false);

    useEffect(() => {
        setfilter({});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listName]);

    useEffect(() => {
        if (!modelForm) {
            setTableSelectedLine({});
        }
    }, [modelForm]);

    // Get form from API
    useEffect(() => {
        const getForm = () => {
            const formData = new FormData();
            formData.append("accao", "lista");
            formData.append("mostra_lista_eliminados", showRemoved ? "true" : "false");
            formData.append("lista", listName);
            formData.append("pagina", pagination.page);
            formData.append("resultados_pagina", pagination.perPage);

            const { sorter, ...objFilter } = filter;
            if (sorter) {
                objFilter["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
            }
            Object.entries(objFilter).forEach(([key, val]) => {
                formData.append(key, val);
            });

            if (Session.isCLient()) {
                const params = Session.getAll();
                formData.append("usr_parametro1", params.parametro1);
                formData.append("usr_parametro2", params.parametro2);
                formData.append("usr_parametro3", params.parametro3);
            }

            setLoadingData(true);

            return sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((data) => {
                setLoadingData(false);
                setList(data);
                //

                if (data["@attributes"]) {
                    setListNotFount(false);
                    getTableColumns(
                        data.dados["@attributes"].nomes_campos,
                        data.dados["@attributes"].tipo_campos,
                        data["@attributes"].headers,
                        data["@attributes"]
                    );
                } else {
                    setListNotFount(true);
                }

                if (onGetList) {
                    onGetList(data);
                }
            });
        };

        const goTo = (page, data) => {
            history.push({
                pathname: `/function.manual/colaborators/create/${data.id}`,
            });
        };

        const getActionsFile = (attributesHeader, record) => {
            if (attributesHeader?.["relatorio"]) {
                if (attributesHeader?.["relatorio"] === "documento") {
                    const ficheiro = Utilitaries.toString(record.id_documento);

                    return ficheiro ? (
                        <Tooltip title="Descarregar documento?">
                            <Button
                                icon="file"
                                onClick={() =>
                                    downloadDocument(ficheiro, {
                                        accao: "ficheiro",
                                        ficheiro,
                                        downloadInBrowser: true,
                                    })
                                }
                            />
                        </Tooltip>
                    ) : null;
                } else if (attributesHeader?.["relatorio"] === "documento_cgd") {
                    const ficheiro = Utilitaries.toString(record.id_documento);

                    return ficheiro ? (
                        <Tooltip title="Descarregar documento?">
                            <Button
                                icon="file"
                                onClick={() =>
                                    downloadDocument(ficheiro, {
                                        accao: "documento",
                                        id: ficheiro,
                                        downloadInBrowser: true,
                                    })
                                }
                            />
                        </Tooltip>
                    ) : null;
                } else {
                    return (
                        <>
                            <Tooltip title="Imprimir">
                                <Button
                                    style={{ marginLeft: 6 }}
                                    icon="printer"
                                    onClick={() => getPrinter(record, attributesHeader)}
                                />
                            </Tooltip>
                            <Tooltip title="Gerar PDF">
                                <Button
                                    style={{
                                        color: "#fa541c",
                                        marginLeft: 8,
                                    }}
                                    icon="file-pdf"
                                    onClick={() => exportItem(record, attributesHeader)}
                                />
                            </Tooltip>
                        </>
                    );
                }
            }
            return null;
        };

        // Table colunms
        const getTableColumns = (attributes = "", attributesTypes = "", headers = "", attributesHeader = {}) => {
            const arrayColumns = attributes.split(";");
            // const arrayTypes = attributesTypes.split(";");
            const arrayHeaders = headers.split(";");

            let columns = [];
            const sortedInfo = filter.sorter || {};

            for (let i = 0; i < arrayColumns.length; i++) {
                if (arrayColumns[i]) {
                    if (
                        ((!arrayColumns[i].startsWith("id") &&
                            !arrayColumns[i].startsWith("id_") &&
                            !arrayColumns[i].startsWith("_")) ||
                            ["id_categoria_escala"].includes(arrayColumns[i])) &&
                        arrayHeaders[i]
                    ) {
                        columns.push({
                            title: arrayHeaders[i],
                            dataIndex: arrayColumns[i],
                            key: arrayColumns[i],
                            ...getColumnSearchProps(arrayColumns[i]),
                            ...getColumnSortProps(arrayColumns[i], sortedInfo),
                            ellipsis: true,
                        });
                    }
                }
            }

            // const renderDocument = attributesHeader?.["relatorio"] === "document";

            columns.push({
                title: "Ação",
                render: (_, record) => {
                    return (
                        <>
                            {action &&
                                attributesHeader.permite_consultar &&
                                attributesHeader.permite_consultar === "t" && (
                                    <Button
                                        type="link"
                                        style={{
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            color: "#8c8c8c",
                                        }}
                                        onClick={() => {
                                            let tipodoc;
                                            if (listName === "entrada_produtos") {
                                                tipodoc = "EP";
                                            }
                                            if (listName === "entrada_inventario") {
                                                tipodoc = "EI";
                                            }
                                            if (listName === "saidas_stock") {
                                                tipodoc = "SS";
                                            }
                                            onShowClick(record, attributesHeader.chaves, true, tipodoc);
                                        }}
                                    >
                                        Consultar
                                    </Button>
                                )}
                            {action &&
                                attributesHeader.permite_alterar &&
                                attributesHeader.permite_alterar === "t" &&
                                record._eliminado_ === "t" && (
                                    <Button
                                        type="link"
                                        style={{
                                            paddingLeft: 6,
                                            paddingRight: 0,
                                            color: "#1890ff",
                                        }}
                                        onClick={() => {
                                            let tipodoc;
                                            if (listName === "entrada_produtos") {
                                                tipodoc = "EP";
                                            }
                                            if (listName === "entrada_inventario") {
                                                tipodoc = "EI";
                                            }
                                            if (listName === "saidas_stock") {
                                                tipodoc = "SS";
                                            }
                                            onShowClick(record, attributesHeader.chaves, false, tipodoc);
                                        }}
                                    >
                                        Editar
                                    </Button>
                                )}

                            {action &&
                                attributesHeader.permite_eliminar &&
                                attributesHeader.permite_eliminar === "t" &&
                                record._eliminado_ === "t" && (
                                    <Popconfirm
                                        title="Tens certeza que desejas eliminar?"
                                        onConfirm={() => onDeleteItem(record, attributesHeader.chaves)}
                                        onCancel={() => {}}
                                        okText="Sim"
                                        cancelText="Não"
                                    >
                                        <Button
                                            type="link"
                                            style={{
                                                paddingLeft: 6,
                                                color: "#fa541c",
                                            }}
                                        >
                                            {listName === "escalas" ? "Remover" : "Eliminar"}
                                        </Button>
                                    </Popconfirm>
                                )}
                            {action &&
                                attributesHeader.permite_eliminar &&
                                attributesHeader.permite_eliminar === "t" &&
                                record._eliminado_ === "f" &&
                                listName === "escalas" && (
                                    <Button
                                        type="link"
                                        style={{
                                            paddingLeft: 6,
                                            color: "#fa001d",
                                        }}
                                        onClick={() => onDeleteItem(record, attributesHeader.chaves, false)}
                                    >
                                        Ativar
                                    </Button>
                                )}

                            {getActionsFile(attributesHeader, record)}
                            {/* {attributesHeader?.["relatorio"] && attributesHeader?.["relatorio"] !== "documento" && (
                                <>
                                    <Tooltip title="Imprimir">
                                        <Button
                                            style={{ marginLeft: 6 }}
                                            icon="printer"
                                            onClick={() => getPrinter(record, attributesHeader)}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Gerar PDF">
                                        <Button
                                            style={{
                                                color: "#fa541c",
                                                marginLeft: 8,
                                            }}
                                            icon="file-pdf"
                                            onClick={() => exportItem(record, attributesHeader)}
                                        />
                                    </Tooltip>
                                </>
                            )}
                            {attributesHeader?.["relatorio"] && attributesHeader?.["relatorio"] === "documento" && (
                                <Tooltip title="Gerar PDF">
                                    <Button
                                        // style={{
                                        //     color: "#fa541c",
                                        //     marginLeft: 8,
                                        // }}
                                        icon="file"
                                        // onClick={() => exportItem(record, attributesHeader)}
                                    />
                                </Tooltip>
                            )} */}

                            {listName === "funcionarios_info" && (
                                <>
                                    <Tooltip title="Consultar no manual de funções">
                                        <Button
                                            type="link"
                                            style={{
                                                color: "#080808",
                                                marginLeft: 8,
                                            }}
                                            onClick={() => goTo("manual_funcoes", record)}
                                        >
                                            Consultar
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                        </>
                    );
                },
            });

            setTableColumns(columns);
        };

        //---------------------------- filter --------------------------------

        const getColumnSearchProps = (dataIndex) => {
            let searchInput = React.createRef();
            return {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={searchInput}
                            placeholder={`Pesquisar`}
                            value={selectedKeys}
                            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                            onPressEnter={() => handleSearch(selectedKeys, confirm)}
                            style={{
                                width: 188,
                                marginBottom: 8,
                                display: "block",
                            }}
                        />
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm)}
                            icon="search"
                            size="small"
                            style={{ width: 90, marginRight: 8 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </div>
                ),
                filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
                filteredValue: filter[`filtro_${dataIndex}`] || null,
            };
        };

        const handleSearch = (selectedKeys, confirm) => {
            confirm();
        };

        const handleReset = (clearFilters) => {
            clearFilters();
        };

        //---------------------------- endfilter -----------------------------
        // debugger;
        getForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, filter, showRemoved]);

    // useEffect(() => {
    //     updateList();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [showRemoved]);

    const getPrinter = (id, attributes) => {
        const formData = new FormData();
        formData.append("accao", "lista_impressoras");

        sendHttpRequest("POST", "/Gestor/gererelatorios.php", formData).then((data) => {
            setPrinterList(validateResponse(data));
            setShowPrinterSelector({ id, attributes });
        });
    };

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }

        if (sorter.columnKey && sorter?.order) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter(obj);
    };

    const clearFormFilters = () => {
        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter({});
    };

    const getColumnSortProps = (dataIndex, sortedInfo) => {
        //
        return {
            sorter: true,
            sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
        };
    };

    const onShowClick = (record, key, justSee = false, tipodoc) => {
        if (listName === "alertas_sistema") {
            record.designacao = record.descricao;
            record.alerta = record.descricao;
        }
        setTableSelectedLine({ ...record, tipodoc });
        setModelForm({ id: record[key], justSee });
    };

    // Apagar um item na tabela
    const onDeleteItem = async (record, key, remove = true) => {
        const fd = new FormData();
        fd.append("accao", remove ? "eliminar" : "recuperar");
        fd.append("lista", listName);
        fd.append("chave1", record[key]);
        message.loading({ content: remove ? "A remover..." : "A recuperar...", key });
        const duration = 3;
        sendHttpRequest("POST", "/Gestor/gerelistas.php", fd).then((result) => {
            if (result) {
                if (result.hasOwnProperty("sucesso")) {
                    const displayMessage =
                        typeof result?.sucesso && result?.sucesso === "string"
                            ? result?.sucesso
                            : "Linha eliminada com sucesso";
                    message.success({ content: displayMessage, key, duration });
                    updateList();
                } else {
                    const displayMessage =
                        result?.erro && typeof result?.erro === "string" ? result?.erro : "Ocorreu um erro";
                    message.error({ content: displayMessage, key, duration });
                }
            } else {
                message.error({ content: "Ocorreu um erro", key, duration });
            }
        });
    };

    const updateList = () => {
        const formData = new FormData();
        formData.append("accao", "lista");
        formData.append("lista", listName);
        formData.append("pagina", pagination.page);
        formData.append("mostra_lista_eliminados", showRemoved ? "true" : "false");
        formData.append("resultados_pagina", pagination.perPage);

        Object.entries(filter).forEach(([key, val]) => {
            formData.append(key, val);
        });

        setLoadingData(true);

        sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((data) => {
            setLoadingData(false);
            setList(data);
        });
    };

    const exportItem = async (record, attributes) => {
        let id;
        if (attributes?.["chaves"] && record?.[attributes["chaves"]]) {
            id = record?.[attributes["chaves"]];
        } else {
            if (record.id) {
                id = record.id;
            }
            if (record.id_socio_inscricao) {
                id = record.id_socio_inscricao;
            }
        }

        downloadFile("/gererelatorios.php", {
            accao: "relatorio_nome",
            nome: attributes.relatorio,
            saida: "pdf",
            id,
            CHAVE1: id,
            downloadInBrowser: true,
        });
    };

    const downloadDocument = async (ficheiro, params) => {
        if (!ficheiro) {
            return;
        }

        downloadFile("/geredocumentos.php", params);
    };

    const exportList = async (type) => {
        // downloadFile("/gereRelatorioListagen.php", {
        //     lista: listName,
        //     tipo_ficheiro: type,
        // });

        var campos = tablesColumns.reduce((accumulator, current) => {
            if (current.title !== "Ação") {
                accumulator += current.key + ":24:" + current.title + ";";
            }
            return accumulator;
        }, "");

        const params = {
            accao: "lista",
            lista: listName,
            // pagina: pagination.page,
            mostra_lista_eliminados: showRemoved ? "true" : "false",
            // resultados_pagina: pagination.perPage,
            saida: type,
            titulo: list["@attributes"].titulo,
            campos,
            downloadInBrowser: true,
        };

        Object.entries(filter).forEach(([key, val]) => {
            params[key] = val;
        });

        downloadFile("/gerelistas.php", params);
    };

    const handlePrint = () => {
        const formData = new FormData();
        formData.append("accao", "relatorio_nome");
        formData.append("id", showPrinterSelector.id);
        formData.append("nome", showPrinterSelector.attributes.relatorio);
        formData.append("saida", "printer");
        formData.append("nome_ficheiro", "ficha_fornecedor");
        formData.append("chave1", "");
        formData.append("impresora", printer);

        sendHttpRequest("POST", "/Gestor/gererelatorios.php", formData).then((data) => {
            setShowPrinterSelector({ id: null, attributes: null });
        });
    };

    // const setJustSeeInPopup = (consultForm) => {
    //     const justSee = modelForm ? modelForm.justSee : false;
    //     if (consultForm === "sim") {
    //         setJustSeePopup(true);
    //     } else if (justSee) {
    //         setJustSeePopup(true);
    //     } else {
    //         setJustSeePopup(false);
    //     }
    // };

    const setJustSeeInPopup = (justSee) => {
        setJustSeePopup(justSee);
        // const justSee = modelForm ? modelForm.justSee : false;
        // if (consultForm === "sim") {
        //     setJustSeePopup(true);
        // } else if (justSee) {
        //     setJustSeePopup(true);
        // } else {
        //     setJustSeePopup(false);
        // }
    };

    const handleCancelModel = () => {
        if (justSeePopup) {
            setModelForm(null);
            setFormModalTitle(null);
        } else {
            Confirm(() => {
                setModelForm(null);
                setFormModalTitle(null);
            });
        }
    };

    const handleOnSaveFormV2 = () => {
        setModelForm(null);
        setFormModalTitle(null);

        updateList();
    };

    const setTitleInFormModal = (title) => {
        setFormModalTitle(title);
    };

    if (listNotFound) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Lamentamos, a lista que procurou não existe."
                extra={
                    <Link to="/">
                        <Button type="primary">Voltar tela principal</Button>
                    </Link>
                }
            />
        );
    }

    return (
        <>
            {/* <ListContext.Provider value={list}> */}
            {/* <QaDyanamicSelect
                placeholder="Lista de teste"
                onChange={value => alert("Selecionau " + value)}
                name="acao"
            /> */}
            <Modal
                title="Selecione uma impressora"
                visible={!!showPrinterSelector.id}
                onOk={() => handlePrint()}
                onCancel={() => {
                    setTableSelectedLine({});
                    setShowPrinterSelector({ id: null, attributes: null });
                }}
            >
                <Select allowClear value={printer} onChange={(value) => setPrinter(value)} style={{ width: "100%" }}>
                    {printerList.map((it) => (
                        <Select.Option key={it.nome} value={it.nome}>
                            {it.nome}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>

            <h3>{list["@attributes"] ? list["@attributes"].titulo : ""}</h3>
            <Row>
                <Col span={16}>
                    {action &&
                        list["@attributes"] &&
                        list["@attributes"].permite_inserir &&
                        list["@attributes"].permite_inserir === "t" && (
                            <Button
                                type="primary"
                                icon="plus"
                                onClick={() => {
                                    if (listName === "entrada_produtos") {
                                        setTableSelectedLine({ tipodoc: "EP" });
                                    } else if (listName === "entrada_inventario") {
                                        setTableSelectedLine({ tipodoc: "EI" });
                                    } else if (listName === "saidas_stock") {
                                        setTableSelectedLine({ tipodoc: "SS" });
                                    } else if (listName === "facturas") {
                                        setTableSelectedLine({ tipodoc: "FR" });
                                    } else if (listName === "notas_credito") {
                                        setTableSelectedLine({ tipodoc: "NC" });
                                    } else if (listName === "facturas_normais") {
                                        setTableSelectedLine({ tipodoc: "FT" });
                                    }
                                    setModelForm({});
                                }}
                            >
                                Inserir
                            </Button>
                        )}

                    {list["@attributes"] &&
                        list["@attributes"].permite_eliminar &&
                        list["@attributes"].permite_eliminar === "t" && (
                            <div style={{ marginTop: 10 }}>
                                <Checkbox
                                    checked={showRemoved}
                                    onChange={(e) => {
                                        setShowRemoved(e.target.checked);
                                    }}
                                >
                                    Mostrar registos eliminados
                                </Checkbox>
                            </div>
                        )}
                </Col>
                <Col span={8}>
                    <Tooltip title="Imprimir PDF">
                        <Button
                            style={{
                                float: "right",
                                color: "#fa541c",
                                marginLeft: 8,
                            }}
                            icon="file-pdf"
                            onClick={() => exportList("pdf")}
                        />
                    </Tooltip>
                    <Tooltip title="Imprimir Excel">
                        <Button
                            style={{
                                float: "right",
                                color: "#237804",
                            }}
                            icon="file-excel"
                            onClick={() => exportList("xls")}
                        />
                    </Tooltip>
                    {Object.keys(filter).length !== 0 && (
                        <Button
                            onClick={clearFormFilters}
                            type="link"
                            size="default"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "right",
                            }}
                        >
                            Limpar filtros
                        </Button>
                    )}
                </Col>

                <Col span={24}>
                    <QATable
                        columns={tablesColumns}
                        data={
                            list.dados
                                ? Array.isArray(list.dados.linha)
                                    ? list.dados.linha
                                    : Object.keys(list.dados.linha).length > 0
                                    ? [list.dados.linha]
                                    : []
                                : []
                        }
                        loadingData={loadingData}
                        datakey={list["@attributes"] ? list["@attributes"].chaves : ""}
                        currentPage={list.dados ? parseInt(list.dados["@attributes"].pagina) : 1}
                        totalResult={list.dados ? parseInt(list.dados["@attributes"].resultados) : 1}
                        pageChange={(page, perPage) => {
                            setPagination({
                                page,
                                perPage,
                            });
                        }}
                        tableChange={handleTableChange}
                    />
                </Col>
            </Row>
            <Modal
                title={formModalTitle}
                visible={modelForm !== null}
                // visible={true}
                footer={null}
                width={
                    list["@attributes"] && list["@attributes"].formulario === "salario_registo_faltas.xml"
                        ? "100%"
                        : "80%"
                }
                onCancel={() => handleCancelModel()}
                destroyOnClose
            >
                {openInFormV2(listName) ? (
                    <RenderListForm
                        list_name={listName}
                        form_id={modelForm ? modelForm.id : null}
                        justSee={modelForm ? modelForm.justSee : false}
                        onSave={handleOnSaveFormV2}
                        setJustSeeInPopup={setJustSeeInPopup}
                        setTitleInModal={setTitleInFormModal}
                    ></RenderListForm>
                ) : (
                    <QAForm
                        // key={new Date().getTime()}
                        formName={list["@attributes"] ? list["@attributes"].formulario : ""}
                        formId={modelForm ? modelForm.id : null}
                        justSee={modelForm ? modelForm.justSee : false}
                        isModal={true}
                        onSave={updateList}
                        onCancel={() => {
                            setModelForm(null);
                            setFormModalTitle(null);
                        }}
                        params={tableSelectedLine}
                        listName={list["@attributes"] ? list["@attributes"]?.nome : ""}
                        setJustSeeInPopup={setJustSeeInPopup}
                    />
                )}

                {/* <RenderListForm list_name={listName} form_id={modelForm ? modelForm.id : null}></RenderListForm> */}
            </Modal>
            {/* </ListContext.Provider> */}
        </>
    );
};

List.propTypes = {
    listName: PropTypes.string,
    onGetList: PropTypes.func,
};

export default List;
