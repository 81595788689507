import { Button, Form, Icon, Input, Modal, message } from "antd";
import React, { useRef } from "react";
import { api_fetch } from "../../../../V2/utils/HttpRequest/request";

// used in condominios - Comunicacao de dividas clientes - tarefa controlar envios
export const ActionBotton = ({ label, icon, record }) => {
    const inputRefEmail = useRef(record.email);

    const executeAction = async () => {
        console.log("email:", inputRefEmail.current.value);
        const params = {
            accao: "envia_emails_dividas_condominios",
            $email: inputRefEmail.current.value,
            $dividas_controlo_lin: 18,
            $entidade_condomino: 10241,
        };

        const {
            // response,
            status,
            message: messageError,
        } = await api_fetch({
            endPoint: `Gestor/gereexecutaacao.php`,
            method: "POST",
            params,
        });

        if (status === "ERROR") {
            message.error(messageError || "Ocorreu um erro");
            return false;
        }
        return true;
    };

    function showConfirm() {
        Modal.confirm({
            title: "Reenviar email?",
            content: (
                <Input
                    ref={inputRefEmail}
                    prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder="Username"
                    defaultValue={record.email}
                    onChange={(event) => {
                        inputRefEmail.current.value = event.target.value;
                    }}
                />
            ),

            onOk: async () => {
                return executeAction().then((result) => {
                    return result ? Promise.resolve() : Promise.reject();
                });
            },
            okText: "Enviar",
            cancelText: "Cancelar",
        });
    }

    return (
        <Form.Item label={label}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                    icon={icon}
                    // disabled={currentTaskStatus || readonly}
                    onClick={() => showConfirm()}
                />
            </div>
        </Form.Item>
    );
};
