import React from "react";
import { Button, Icon, Form } from "antd";

const ButtonGroup = Button.Group;

const QaReport = ({ label, currentTaskStatus, formItemStyle, downloadReport }) => {
    return (
        <Form.Item label={label} style={formItemStyle}>
            <ButtonGroup>
                <Button type="default" onClick={() => downloadReport({ saida: "pdf" })}>
                    <Icon title="Relatório PDF" style={{ fontSize: 20, color: "red" }} type="file-pdf" />
                </Button>
                <Button type="default" onClick={() => downloadReport({ saida: "doc" })}>
                    <Icon title="Relatório Word" style={{ color: "#2962FF", fontSize: 20 }} type="file-word" />
                </Button>
                <Button type="default" onClick={() => downloadReport({ saida: "xls" })}>
                    <Icon title="Relatório Excel" style={{ color: "green", fontSize: 20 }} type="file-excel" />
                </Button>
            </ButtonGroup>
        </Form.Item>
    );
};

export default QaReport;
