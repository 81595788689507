import React, { useState } from "react";
import { Form, Input, DatePicker, Select, Button, Row, Col } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};

const Search = ({ setFilter, setVisible, form, users, levels }) => {
    const { getFieldDecorator } = form;

    const handlerOk = () => {
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            setFilter(values);
            setVisible(false);
            // form.resetFields();
        });
    };

    return (
        // <Modal
        //     title="Filtrar Chat"
        //     visible={visible}
        //     onOk={handlerOk}
        //     okText="Filtrar"
        //     cancelText="Cancelar"
        //     onCancel={() => {
        //         setVisible(false);
        //         setFilter(null);
        //     }}
        // >
        <Form {...formItemLayout}>
            <Form.Item label="Tópicos">{getFieldDecorator("search")(<Input />)}</Form.Item>

            <Form.Item label="Utilizador">
                {getFieldDecorator("username")(
                    <Select allowClear showSearch>
                        {users.map((item, idx) => (
                            <Option key={idx} value={item.username}>
                                {item.nome}
                            </Option>
                        ))}
                    </Select>
                )}
            </Form.Item>

            <Form.Item label="Prioridade">
                {getFieldDecorator("level")(
                    <Select allowClear showSearch>
                        {levels
                            ? levels.map((item, index) => (
                                  <Option value={item["designacao"]} key={index}>
                                      {item["designacao"]}
                                  </Option>
                              ))
                            : null}
                    </Select>
                )}
            </Form.Item>

            <Form.Item label="Data">
                {getFieldDecorator("data")(
                    <RangePicker disabledDate={(date) => !moment().add(1, "day").isAfter(moment(date))} />
                )}
            </Form.Item>

            <Row>
                <Col span={20} style={{ textAlign: "right" }}>
                    <Button
                        onClick={() => {
                            setVisible(false);
                            setFilter(null);
                        }}
                        style={{ marginRight: 8 }}
                    >
                        Limpar
                    </Button>
                    <Button type="primary" onClick={handlerOk}>
                        Filtrar
                    </Button>
                </Col>
            </Row>
        </Form>
        // </Modal>
    );
};

export default Form.create({ name: "dynamic_rule" })(Search);
