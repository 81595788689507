import React, { useState, useRef, useEffect } from "react";
import { Modal, Row, Col, Divider, Button, message, Spin, Tooltip, Typography } from "antd";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import ptPtLocate from "@fullcalendar/core/locales/pt-br";
import moment from "moment";
import ReactDOM from "react-dom";

import EventConfiguration from "./event-configuration";
import EventSearchForm from "./search-form";
import EventEditor from "./editor";
import DefinedEvents from "./DefinedEvents/index";
import SchedulePermission from "./SchedulePermission";
import { sendHttpRequest } from "../http.request";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import { toArray } from "../util";
import { Utilitaries } from "../../business";
import { getEvents } from "./actions/querys";
import { OverlayPermission } from "./components/OverlayPermission";

const SPECIAL_DATES_COLOR = "#629EEE";
const SPECIAL_DATES_AND_HOLIDAY_COLOR = "#629EEE";
const HOLIDAYS_COLOR = "#629EAA";

const Schedule = (props) => {
    const [visibleDateOnCalendar, setVisibleDateOnCalendar] = useState({
        start: "",
        end: "",
    });

    const [events, setEvents] = useState([]);
    const [eventsFilters, setEventsFilters] = useState({});
    const [openEditor, setOpenEditor] = useState({});
    const [loadEvent, setLoadEvent] = useState(false);
    const [isVisibleDefinedEvents, setIsVisibleDefinedEvents] = useState(false);
    const [isVisibleEventPermission, setIsVisibleEventPermission] = useState(false);
    const [isVisibleEventOverlayPermission, setIsVisibleEventOverlayPermission] = useState(false);
    const [holidays, setHolidays] = useState([]);
    const [specialDates, setSpecialDates] = useState([]);

    const calendarRef = useRef();

    const handleEventClick = async (info) => {
        try {
            if (
                Utilitaries.toString(info.event.id).startsWith("feriado_") ||
                Utilitaries.toString(info.event.id).startsWith("special_dates_")
            ) {
                return;
            }

            const formData = new FormData();
            formData.append("accao", "consultar");
            formData.append("id", info.event.id);

            setLoadEvent(true);

            const response = await sendHttpRequest("POST", "/Gestor/gereagenda.php", formData);
            //
            if (response.erro) {
                message.error(response.erro);
            } else {
                const allDay = response?.evento?.linha?.todo_dia === "t";
                setOpenEditor({
                    new: false,
                    // allDay: info.allDay,
                    allDay,
                    ...response.evento.linha,
                    grupo: toArray(response?.grupos?.linha),
                    visualizacao: toArray(response?.visualizacao?.linha),
                    anexos: toArray(response?.anexos?.linha),
                    terceiros: toArray(response?.terceiros?.linha),
                    periodicidade: response?.periodicidade,
                });
                props.getBadge();
            }
        } catch (error) {
            message.error("Erro ao obter dados do evento!");
        } finally {
            setLoadEvent(false);
        }
    };

    const handleDateSelect = (arg) => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.unselect();

        setOpenEditor({
            new: true,
            allDay: arg.allDay,
            end: arg.end,
            start: arg.start,
        });
    };

    const loadEvents = async (_start = null, _end = null) => {
        const result = await getEvents({
            _start,
            _end,
            eventsFilter: eventsFilters,
            calendarDateRange: visibleDateOnCalendar,
        });

        if (result) {
            setEvents(result.events);
            setHolidays(result.holidays);
            setSpecialDates(result.specialDates);
        }
    };

    useEffect(() => {
        loadEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsFilters]);

    const fecthEvents = (info) => {
        const _start = moment(info.view.activeStart).format("YYYY-MM-DD");
        const _end = moment(info.view.activeEnd).format("YYYY-MM-DD");

        const { start, end } = visibleDateOnCalendar;

        if (moment(start).format("YYYY-MM-DD") !== _start && moment(end).format("YYYY-MM-DD") !== _end) {
            setVisibleDateOnCalendar({
                start: _start,
                end: _end,
            });
            loadEvents(_start, _end);
        }
    };

    const dayRender = (el) => {
        if (holidays.includes(moment(el.date).format("YYYY-MM-DD"))) {
            el.el.style.background = HOLIDAYS_COLOR;
        }

        try {
            const items = specialDates.filter((item) => item.date === moment(el.date).format("YYYY-MM-DD"));
            const numberOfSpecialDate = items.length;
            if (items && numberOfSpecialDate > 0) {
                // el.el.style.background = SPECIAL_DATES_COLOR;
                // class="fc-day-grid-event fc-h-event  fc-start fc-end"

                let content =
                    '<a class="" style="color: #fff;background-color:#629EEE;border-color:#629EEE;position: relative;display: block;font-size: 0.85em;line-height: 1.4;border-radius: 0px;padding-bottom: 2px; border: 1px solid #629EEE;height:18px;">';
                // '<a class="" style="color: #fff;background-color:#629EEE;border-color:#629EEE;position: relative;display: block;font-size: 0.85em;line-height: 1.4;border-radius: 0px;padding-bottom: 2px; border: 1px solid #3788d8;">';

                // items.forEach((it, index) => {
                // content += `<span class="ant-tooltip-open">${"aa"}</span>`;
                // if (index >= 1 && index < items.length) {
                //     content += "<br />";
                // }
                // });
                content += "</a>";

                el.el.insertAdjacentHTML("beforeend", content);
                // el.el.insertAdjacentHTML(
                //     "beforeend",
                //     '<a style="color: rgba(0, 0, 0, 0.65);font-size: 1em;margin-left:3px;"><span> Dia S. Tiago</span></a>'
                // );
            }
        } catch (error) {}
    };

    const renderEvent = (info) => {
        const currentContent = () => info.el.innerText;
        const { _description, _title, _total } = info.event?.extendedProps || {};
        const { start, end, id } = info.event || {};

        const dataIni = moment(start).format("YYYY-MM-DD HH:mm");
        const dataFim = moment(end).format("YYYY-MM-DD HH:mm");

        let toolTipContent;

        if (Utilitaries.toString(id).startsWith("special_dates_")) {
            toolTipContent = (
                <>
                    <div>
                        <span>
                            <b>Datas especiais {moment(start).format("YYYY-MM-DD")}</b>
                        </span>
                        <br />
                    </div>
                    {specialDates
                        .filter((item) => moment(item.date).format("YYYY-MM-DD") === moment(start).format("YYYY-MM-DD"))
                        .map((date, idx) => (
                            <div key={idx + "special_dates_tooltip"}>
                                {idx > 0 && <br />}
                                <span>
                                    <b>{date.title}</b>
                                </span>
                                <br />
                                <span>{date.description}</span>
                            </div>
                        ))}
                </>
            );
        } else {
            toolTipContent = (
                <>
                    <span>
                        <b>{_title}</b>
                    </span>
                    <br />
                    {_title === "Feriado" || _title === "Data Especial" ? (
                        <span>{moment(start).format("YYYY-MM-DD")}</span>
                    ) : (
                        <span>{dataIni === dataFim ? dataIni : `${dataIni} - ${dataFim}`}</span>
                    )}
                    <br />
                    <span>{_description}</span>
                </>
            );
        }

        const content = (
            <Tooltip overlayStyle={{ maxWidth: "80%" }} title={toolTipContent}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography.Paragraph ellipsis style={{ color: "inherit", margin: 0 }}>
                        {currentContent()}
                    </Typography.Paragraph>
                    {!Utilitaries.isEmpty(_total) && Utilitaries.toNumber(_total) > 0 && (
                        <span> &nbsp;(+{_total})</span>
                    )}
                </div>
                {/* {currentContent()} */}
            </Tooltip>
        );

        ReactDOM.render(content, info.el);
    };

    return (
        <>
            {loadEvent && (
                <Spin
                    tip="Carregando..."
                    size="large"
                    style={{
                        position: "absolute",
                        zIndex: 1002,
                        top: "50%",
                        left: "50%",
                        transform: "translate(50%, 50%)",
                    }}
                />
            )}

            {Object.keys(openEditor).length > 0 && (
                <EventEditor
                    visible={Object.keys(openEditor).length > 0}
                    event={openEditor}
                    definedEvents={props.definedEvents}
                    places={props.places}
                    selectContributorsProps={props.selectContributorsProps}
                    onCancel={() => setOpenEditor({})}
                    utilizadorSelected={
                        Object.keys(eventsFilters).length > 0 && eventsFilters.tipo === "Outros"
                            ? eventsFilters.utilizador
                            : null
                    }
                    onSave={() => {
                        props.getBadge();
                        props.getRemember();
                        loadEvents();
                        setOpenEditor({});
                    }}
                />
            )}
            <DefinedEvents
                visible={isVisibleDefinedEvents}
                onCancel={() => setIsVisibleDefinedEvents(false)}
                definedEvents={props.definedEvents}
                selectContributorsProps={props.selectContributorsProps}
            />

            <SchedulePermission
                visible={isVisibleEventPermission}
                onCancel={() => setIsVisibleEventPermission(false)}
                eventsPermissions={props.eventsPermissions}
                {...props.selectContributorsProps}
            />

            {isVisibleEventOverlayPermission && (
                <OverlayPermission
                    visible={isVisibleEventOverlayPermission}
                    onCancel={() => setIsVisibleEventOverlayPermission(false)}
                    selectContributorsProps={props.selectContributorsProps}
                />
            )}

            <Modal
                title="Agenda QualityAlive"
                visible={props.visible}
                onOk={null}
                onCancel={props.onCancel}
                footer={null}
                width="90%"
                style={{ top: 20 }}
            >
                <Row gutter={[16, 8]}>
                    <Col span={8}>
                        <h3>Pesquisa:</h3>
                        <Divider style={{ marginTop: 8, marginBottom: 12 }} />
                        <EventSearchForm
                            onEditClick={(id, allDay = false) => {
                                // info.event.id
                                handleEventClick({ event: { id } });
                            }}
                        />

                        <h3 style={{ marginTop: 20 }}>Minha agenda:</h3>
                        <Divider style={{ marginTop: 8, marginBottom: 12 }} />
                        <EventConfiguration
                            selectContributorsProps={props.selectContributorsProps}
                            eventsPermissionGroups={props.eventsPermissionGroups}
                            onChange={(tipologia, utilizador, tipo) => {
                                setEventsFilters({
                                    tipologia,
                                    utilizador,
                                    tipo,
                                });
                            }}
                        />

                        <br />

                        <Button
                            icon="lock"
                            style={{ marginTop: 30 }}
                            onClick={() => {
                                setIsVisibleEventPermission(true);
                            }}
                        >
                            Permissões
                        </Button>

                        <h3 style={{ marginTop: 20 }}>Configurações gerais:</h3>
                        <Divider style={{ marginTop: 8, marginBottom: 12 }} />
                        <Button
                            icon="clock-circle"
                            style={{ marginTop: 10 }}
                            onClick={() => setIsVisibleDefinedEvents(true)}
                        >
                            Eventos Definidos
                        </Button>

                        <Button
                            icon="lock"
                            style={{ marginTop: 10, marginLeft: 8 }}
                            onClick={() => {
                                setIsVisibleEventOverlayPermission(true);
                            }}
                        >
                            Permissões de sobreposição
                        </Button>
                    </Col>
                    <Col span={16}>
                        <FullCalendar
                            ref={calendarRef}
                            defaultView="dayGridMonth"
                            datesRender={fecthEvents}
                            dayRender={dayRender}
                            eventRender={renderEvent}
                            eventCon
                            editable={false}
                            selectable={true}
                            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                            locales={[ptPtLocate]}
                            locale="pt-pt"
                            header={{
                                left: "prev,next today",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                            }}
                            buttonText={{
                                today: "Hoje",
                                month: "Mês",
                                week: "Semana",
                                day: "Dia",
                                list: "Lista",
                            }}
                            events={events}
                            eventClick={handleEventClick}
                            select={handleDateSelect}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default Schedule;
