import { message } from "antd";
import moment from "moment";
import { Utilitaries } from "../../../business";
import { toBoolean } from "../../../business/utils";
import { conversion, downFile } from "../../../modules/process_execution/constants/utils";
import { validateBoolean } from "../../../modules/process_execution/utils/fields";
import { isFieldVisibility, isRequired } from "../../../modules/process_execution/utils/input_attributes";
import { getFullPathRow } from "../../components/FormRender/handle/inputHandle";
import { GenericObject } from "../../types/common";
import {
    FormAttributesProps,
    FormInputCalcTriggerProps,
    FormInputsObjectProps,
    FormInputsProps,
    FormRootElementAttributesProps,
    InputCalcTriggerProps,
} from "../../types/formTypes";
import { getElementByRootPath } from "../functions";
import { COMPONENT_TYPE } from "./XMLInputType";
// import { api_fetch } from "../../../utils/HttpRequest/request";
import { api_fetch } from "../../utils/HttpRequest/request";

export interface ActionsProps {
    full_path: string;
    action: string;
}

export function getInputThatDependsOnOtherInput(
    componentProperties: FormInputsProps,
    form: FormInputsObjectProps,
    row_key?: string
) {
    const depends = componentProperties.when_value_changed;

    const depends_fiels: ActionsProps[] = [];

    if (!depends) {
        return [];
    }

    const actions = depends.split(";").filter((item) => !Utilitaries.isEmpty(item));

    for (const action of actions) {
        const fields = getItemByAction({
            action,
            form_inputs: form,
            parent: form,
            fields: [],
            row_key: undefined,
            root_item: componentProperties.root_item,
            is_column: componentProperties.is_column,
        });

        fields.forEach((item) => {
            if (row_key && item.isColumn === true) {
                const fullpathRow = getFullPathRow(item, row_key);
                depends_fiels.push({ full_path: fullpathRow!, action });
            } else if (item.root && item.full_path?.startsWith("table@")) {
                const table: FormInputsProps = getElementByRootPath({ rootPath: item.root, form });
                if (table.DATA_SOURCE) {
                    const dataSource = Object.values(table.DATA_SOURCE);
                    dataSource.forEach((el: any) => {
                        const fullpathRow = getFullPathRow(item, el?.key);
                        depends_fiels.push({ full_path: fullpathRow!, action });
                    });
                }
            } else {
                depends_fiels.push({ full_path: item.full_path!, action });
            }
        });

        const datagridDeps = getDataGridToLoad({
            action,
            form_inputs: form,
            parent: form,
            fields: [],
            row_key: undefined,
            root_item: componentProperties.root_item,
            is_column: componentProperties.is_column,
        });
        datagridDeps.forEach((item) => {
            depends_fiels.push({ full_path: item.full_path!, action });
        });

        // depends_fiels.push(
        //     ...fields.map((item) => {
        //         // if (
        //         //     row_key &&
        //         //     componentProperties.root === item.root &&
        //         //     item.full_path?.startsWith(`table@${componentProperties.root}/`)
        //         // ) {
        //         //     const fullPathRow = getFullPathRow(item, row_key);
        //         //     return { full_path: fullPathRow, action };
        //         // }
        //         if (row_key) {
        //             const fullpathRow = getFullPathRow(item, row_key);
        //             return { full_path: fullpathRow!, action };
        //         }
        //         if (item.root && item.full_path?.startsWith("table@")) {
        //             const table: any = getElementByRootPath({ rootPath: item.root, form });
        //             if(table){

        //             }
        //         }
        //         return { full_path: item.full_path!, action };
        //     })
        // );

        // depends_fiels.push(...fields.map((item) => ({ full_path: item.full_path!, action })));
    }

    return depends_fiels;
}

interface InputCalcTrigger {
    full_path: string;
    row_key?: string;
}
interface GetInputCalcTriggerProps {
    form_inputs: FormInputsObjectProps;
    typeProperty: string;
    fields?: InputCalcTrigger[];
    key: string;
    row_key?: string;
    input_group_path?: string[];
    root?: string;
    child_root_key?: string;
}

export function getInputCalcTrigger({
    key,
    row_key,
    form_inputs,
    typeProperty,
    fields = [],
}: GetInputCalcTriggerProps) {
    for (const propertie in form_inputs) {
        const element = form_inputs[propertie];
        if (!element) {
            continue;
        }

        if (element?.[typeProperty]) {
            if (element?.[typeProperty]?.match(`/${key}(?=(\\W|$))`)) {
                console.log("encontrou");
                if (!element.full_path) continue;
                if (row_key && element.isColumn) {
                    fields.push({ full_path: `${element.full_path}_${row_key}` });
                } else {
                    fields.push({ full_path: element.full_path });
                }
            }
        }

        if ([COMPONENT_TYPE.DYNAMIC_TABLE, COMPONENT_TYPE.SELECTION_TABLE].includes(String(element.type!))) {
            const tableField = Object.keys(element).find((el) => {
                return el !== "DATA_SOURCE" && typeof element[el] === "object";
            });
            if (tableField) {
                fields = getInputCalcTrigger({ key, row_key, form_inputs: element[tableField], typeProperty, fields });
            }
        } else if ([COMPONENT_TYPE.DYNAMIC_DATAGRID, COMPONENT_TYPE.DATAGRID].includes(String(element.type!))) {
            // check for datagrid;
            fields = getInputCalcTrigger({ key, row_key, form_inputs: element, typeProperty, fields });
        } else if (String(element?.type) === COMPONENT_TYPE.INPUT_GROUP) {
            // const inputGroup = element[String(element?.key)] as FormInputsObjectProps;
            fields = getInputCalcTrigger({ key, row_key, form_inputs: element, typeProperty, fields });
        } else {
            // const t = Object.values(element);
            // const a = t.find()
            // typeof form_inputs[propertie] === "object"
            continue;
        }
    }

    return fields;
}

export interface GetFormInputCalcTriggerProps {
    form_inputs: FormInputsObjectProps;
    full_path?: string;
    // input_group_path?: string[];
    row_key?: string;
    child_root_key?: string;
    componentsProperties: FormInputsProps;
}
export function getFormInputCalcTrigger({
    form_inputs,
    // input_group_path,
    row_key,
    componentsProperties,
    full_path,
}: GetFormInputCalcTriggerProps) {
    const { key, root } = componentsProperties;

    const inputs: FormInputCalcTriggerProps = {};

    const calculateDepends = getInputCalcTrigger({
        key,
        row_key,
        form_inputs,
        typeProperty: "calculate",
        root: componentsProperties.root,
    });
    calculateDepends.forEach((el) => {
        inputs[el.full_path] = { ...inputs[el.full_path], ...el, calculateTrigger: true };
    });

    const relevantDepends = getInputCalcTrigger({
        key,
        row_key,
        form_inputs,
        typeProperty: "relevant",
        root,
    });
    relevantDepends.forEach((el) => {
        inputs[el.full_path] = { ...inputs[el.full_path], ...el, relevantTrigger: true };
    });

    const visibleDepends = getInputCalcTrigger({
        key,
        row_key,
        form_inputs,
        typeProperty: "visible",
        root,
    });
    visibleDepends.forEach((el) => {
        inputs[el.full_path] = { ...inputs[el.full_path], ...el, visibleTrigger: true };
    });

    const restrictionDepends = getInputCalcTrigger({
        key,
        row_key,
        form_inputs,
        typeProperty: "restriction",
        root,
    });
    restrictionDepends.forEach((el) => {
        inputs[el.full_path] = { ...inputs[el.full_path], ...el, restrictionTrigger: true };
    });

    //trigger validation  nif
    if (componentsProperties.restriction && full_path) {
        const hasSelfRestriction = /(?<=(\s|^))nif(?=($|\s))/.test(componentsProperties.restriction.toString());
        if (hasSelfRestriction) {
            if (!inputs[full_path]?.restrictionTrigger) {
                inputs[full_path] = { ...inputs[full_path], full_path, restrictionTrigger: true };
            }
        }
    }
    //trigger validation  email
    if (componentsProperties.mask === "email" && full_path) {
        if (!inputs[full_path]?.restrictionTrigger) {
            inputs[full_path] = { ...inputs[full_path], full_path, restrictionTrigger: true };
        }
    }

    const requiredDepends = getInputCalcTrigger({
        key,
        row_key,
        form_inputs,
        typeProperty: "required",
        root,
    });
    requiredDepends.forEach((el) => {
        inputs[el.full_path] = { ...inputs[el.full_path], ...el, requiredTrigger: true };
    });

    const readOnlyDepends = getInputCalcTrigger({
        key,
        row_key,
        form_inputs,
        typeProperty: "read_only",
        root,
    });
    readOnlyDepends.forEach((el) => {
        inputs[el.full_path] = { ...inputs[el.full_path], ...el, readOnlyTrigger: true };
    });

    return Object.values(inputs);
}

// interface GetInputCalcTriggerProps {
//     componentsProperties: FormInputsProps;
//     form_inputs: FormInputsObjectProps;
//     typeProperty: string;
//     fields: InputCalcTrigger[];
//     // fields: InputDepends[];
//     // full_path: string;
//     // action: string;
// }

// inputCalcTrigger

// export function getInputCalcTrigger({
//     componentsProperties,
//     form_inputs,
//     typeProperty,
//     fields: [],
// }: GetInputCalcTriggerProps) {
//     let fields: InputCalcTrigger[] = [];

//     fields = inputCalcTriggerFromField({ componentsProperties, form_inputs, typeProperty, fields });
//     console.log(fields);
// }

interface GetItemByKeyProps {
    action: string;
    fields: FormInputsProps[];
    row_key?: string;
    form_inputs: FormInputsObjectProps;
    parent: FormInputsObjectProps;
    is_child?: boolean;
    root_item?: string;
    is_column: boolean;
}
export function getItemByAction({
    action,
    fields,
    row_key,
    form_inputs,
    parent,
    is_child,
    root_item = undefined,
    is_column = false,
}: GetItemByKeyProps) {
    for (const propertie in form_inputs) {
        if (propertie === "@accao_" + action) {
            if (
                [
                    COMPONENT_TYPE.DYNAMIC_DATAGRID,
                    COMPONENT_TYPE.DYNAMIC_TABLE,
                    COMPONENT_TYPE.SELECTION_TABLE,
                ].includes(String(parent?.type!)) &&
                (String(form_inputs?.type) === COMPONENT_TYPE.INPUT_GROUP ||
                    (!form_inputs.type && !form_inputs.graphic_element))
            ) {
                parent["@accao_" + action] = form_inputs["@accao_" + action];
                fields.push(parent as FormInputsProps);
            } else {
                if (is_column && form_inputs.is_column) {
                    const input = { key: "", ...form_inputs, is_child } as FormInputsProps;
                    fields.push(input);
                } else {
                    const input = { key: "", ...form_inputs } as FormInputsProps;
                    fields.push(input);
                }
            }
        } else if (propertie === "DATA_SOURCE" && typeof form_inputs[propertie] === "object") {
            getItemByAction({
                action,
                fields,
                form_inputs: form_inputs[propertie][row_key!],
                parent: form_inputs,
                is_child: true,
                root_item,
                is_column,
            });
        } else if (typeof form_inputs[propertie] === "object") {
            getItemByAction({
                action,
                fields,
                form_inputs: form_inputs[propertie],
                parent: form_inputs,
                is_child: true,
                root_item,
                is_column,
            });
        } else {
            continue;
        }
    }

    return fields;
}

export function getDataGridToLoad({
    action,
    fields,
    row_key,
    form_inputs,
    parent,
    is_child,
    root_item = undefined,
    is_column = false,
}: GetItemByKeyProps) {
    for (const propertie in form_inputs) {
        const input = form_inputs[propertie];
        if (typeof input !== "object") {
            continue;
        }

        if (input?.type === COMPONENT_TYPE.DATAGRID) {
            if (input.load_action && input.load_action === action) {
                fields.push(input as FormInputsProps);
            } else {
                const paramsPopups = input.popup_param?.split("");
            }
        } else if (form_inputs[propertie]?.type && form_inputs[propertie].type === COMPONENT_TYPE.INPUT_GROUP) {
            getDataGridToLoad({
                action,
                fields,
                form_inputs: input,
                parent: form_inputs,
                is_child: true,
                root_item,
                is_column,
            });
        }
    }

    return fields;
}

export function setInputGroupData(inputData: FormInputsProps, value: any) {
    for (const key in inputData) {
        if (
            typeof inputData[key] === "object" &&
            inputData[key]?.type &&
            inputData[key]?.type === COMPONENT_TYPE.INPUT_GROUP
        ) {
            if (value[key]) {
                inputData[key] = setInputGroupData(inputData[key], value[key]);
            }
        } else if (inputData[key] && typeof inputData[key] === "object" && inputData[key].key) {
            if (!value[key] || (Array.isArray(value) && value.length === 0)) {
                // return inputData;
                continue;
            }
            if (
                inputData[key]?.type === COMPONENT_TYPE.DYNAMIC_TABLE ||
                inputData[key]?.type === COMPONENT_TYPE.SELECTION_TABLE ||
                inputData[key]?.type === COMPONENT_TYPE.DATAGRID
            ) {
                let dataSource = Object.values(value[key])[0];
                if (Array.isArray(dataSource)) {
                    inputData[key].DATA_SOURCE = dataSource.reduce((acumulator, current, index) => {
                        current.key = index + 1;
                        acumulator[index + 1] = current;
                        return acumulator;
                    }, {});
                } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                    inputData[key].DATA_SOURCE = {
                        1: { key: 1, ...dataSource },
                    };
                }
            } else if (inputData[key]?.type === COMPONENT_TYPE.DYNAMIC_DATAGRID) {
                const _dataSource = value[inputData[key].key];

                if (Array.isArray(_dataSource)) {
                    inputData[key].DATA_SOURCE = _dataSource.reduce((acumulator, current, index) => {
                        current.key = index + 1;
                        acumulator[index + 1] = current;
                        return acumulator;
                    }, {});
                } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                    inputData[key].DATA_SOURCE = {
                        1: { key: 1, ..._dataSource },
                    };
                }

                // formItem.dataSource = _dataSource;
            }
            //  else if (inputData[key]?.type === COMPONENT_TYPE.fixedList) {
            //     let dataSource = Object.values(value[key])[0];

            //     let values = Object.keys(value[key])[0];
            //     let fieldKey = null;

            //     for (const k in inputData[key][values]) {
            //         if (
            //             inputData[key][values][k] &&
            //             typeof inputData[key][values][k] === "object" &&
            //             inputData[key][values][k].key
            //         ) {
            //             fieldKey = k;
            //         }
            //     }

            //     if (fieldKey) {
            //         inputData[key].value = toArray(dataSource).map((item) => item[fieldKey]);
            //     } else {
            //         inputData[key].value = dataSource || "";
            //     }
            // }
            else {
                inputData[key].value = value[key] || "";
            }
        }
    }

    if (value) {
        const inputDataArray = Object.keys(inputData);
        const valuesThatKeyStartWithID = Object.keys(value).filter(
            (field) => field.startsWith("id") && !inputDataArray.some((item) => item === field)
        );

        valuesThatKeyStartWithID.forEach((key) => {
            if (value[key]) {
                inputData = {
                    ...inputData,
                    [key]: {
                        key: key,
                        value: value[key],
                        visible: "nao",
                    },
                };
            }
        });
    }
    return inputData;
}

interface SetDataWhenLoadingProps {
    fields: FormInputsObjectProps;
    values: any;
    keyData: string;
}
const setDataWhenLoading = ({ fields, values = {}, keyData }: SetDataWhenLoadingProps) => {
    const obj: any = { ...fields };
    for (const propertie in fields) {
        if (
            fields[propertie].default_value &&
            typeof fields[propertie].default_value === "string" &&
            fields[propertie].default_value?.startsWith(`$${keyData}.`)
        ) {
            var strRegExPattern = "(?<=" + keyData + ".).*";
            const keyToGetData = fields[propertie].default_value?.match(new RegExp(strRegExPattern, "g"));
            if (keyToGetData?.[0]) {
                let value = values[keyToGetData?.[0]];
                if (fields[propertie].type === COMPONENT_TYPE.DATE) {
                    value = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
                }
                obj[propertie].default_value = value;
                obj[propertie].value = value;
            }
        } else if (typeof fields[propertie] === "object" && fields[propertie].type === COMPONENT_TYPE.INPUT_GROUP) {
            const result = setDataWhenLoading({
                fields: fields[propertie],
                values,
                keyData,
            });
            obj[propertie] = result;
        }
    }
    return obj;
};

export const executeWhenLoading = async (
    rootElementAttributes: FormRootElementAttributesProps,
    form: FormInputsObjectProps
) => {
    if (!rootElementAttributes.when_loading) {
        return form;
    }

    let formWithData: FormInputsObjectProps = form;
    const listWhenLoading = rootElementAttributes.when_loading.split(";");

    for await (const item of listWhenLoading) {
        if (!item) {
            continue;
        }
        const currentAditionalData = item
            .replace(/\s/g, "")
            .match(/(?<=\()'.*'/)?.[0]
            ?.split(",");
        const keyData = currentAditionalData?.[0]?.replace(/'/g, "");
        const query = currentAditionalData?.[1]?.replace(/'/g, "");

        const { response } = await api_fetch({
            endPoint: `Gestor/execQuery.php`,
            method: "POST",
            params: {
                query,
                // ...params,
            },
        });

        const result = Array.isArray(response?.result) && response.result[0] ? response.result[0] : {};

        formWithData = setDataWhenLoading({
            fields: formWithData,
            values: result,
            keyData: Utilitaries.toString(keyData),
        });
    }

    return formWithData;
};

export function addValuesToFormInput(
    formValues: any,
    formAttributes: FormAttributesProps,
    form: FormInputsObjectProps
) {
    const values = formValues[formAttributes.rootElement];

    let formWithData: FormInputsObjectProps = {};

    if (values) {
        formWithData = Object.values(form)
            .map((formItem) => {
                // let tableWithValueInSecoundKey = false;
                let inputKey = formItem.key;

                if (formItem.type === COMPONENT_TYPE.DYNAMIC_TABLE && !(formItem.key in values)) {
                    inputKey = Object.keys(formItem).find(
                        (it) =>
                            typeof formItem[it] === "object" &&
                            !Array.isArray(formItem[it]) &&
                            Object.keys(formItem[it]).length > 0
                    ) as string;
                    // tableWithValueInSecoundKey = true;
                }

                const value = values[inputKey];
                if (inputKey in values) {
                    // * Table cases
                    if (
                        formItem.type === COMPONENT_TYPE.DYNAMIC_TABLE ||
                        formItem.type === COMPONENT_TYPE.SELECTION_TABLE ||
                        formItem.type === COMPONENT_TYPE.DATAGRID
                    ) {
                        let dataSource: any = Object.values(value)[0];

                        if (Array.isArray(dataSource)) {
                            formItem.DATA_SOURCE = dataSource.reduce((acumulator, current, index) => {
                                const objCurrent: GenericObject = {};
                                for (let key in current) {
                                    objCurrent[key] = String(current[key]);
                                }
                                objCurrent.key = index + 1;
                                acumulator[index + 1] = objCurrent;
                                return acumulator;
                            }, {} as GenericObject);
                        } else if (typeof dataSource === "object" && !Array.isArray(dataSource)) {
                            const objValues: GenericObject = {};
                            for (let key in dataSource) {
                                objValues[key] = String(dataSource[key]);
                            }
                            formItem.DATA_SOURCE = {
                                1: { key: 1, ...objValues },
                            };
                        }
                    } else if (formItem.type === COMPONENT_TYPE.DYNAMIC_DATAGRID) {
                        const _dataSource = value[formItem.key];

                        if (Array.isArray(_dataSource)) {
                            formItem.DATA_SOURCE = _dataSource.reduce((acumulator, current, index) => {
                                current.key = index + 1;
                                acumulator[index + 1] = current;
                                return acumulator;
                            }, {});
                        } else if (typeof _dataSource === "object" && !Array.isArray(_dataSource)) {
                            formItem.DATA_SOURCE = {
                                "1": { key: 1, ..._dataSource },
                            };
                        }

                        // formItem.dataSource = _dataSource;
                    }
                    // * Other cases
                    else if (formItem.type === COMPONENT_TYPE.INPUT_GROUP) {
                        const _inputValues = value;

                        formItem = setInputGroupData(formItem, _inputValues);
                        // formItem[formItem.key] = {
                        //     key: formItem.key,
                        //     value: values[formItem.key],
                        //     visible: "nao",
                        // };
                        formItem.value = _inputValues;
                        // for (const _key in _inputValues) {
                        //
                        //     if (formItem?.[_key]) {
                        //         formItem[_key]["value"] = _inputValues[_key];
                        //     }
                        // }
                        // item.dataSource = _dataSource;
                    } else {
                        if (
                            formItem.persist === "nao" &&
                            Utilitaries.isEmpty(value) &&
                            !Utilitaries.isEmpty(formItem["$"])
                        ) {
                            formItem.value = formItem["$"];
                        } else {
                            formItem.value = validateBoolean(value, formItem);
                        }
                    }
                }

                return formItem;
            })
            .reduce((acumulator, current) => {
                acumulator[current.key] = current;
                return acumulator;
            }, {} as FormInputsObjectProps);

        const valuesThatKeyStartWithID = Object.keys(values).filter((field) => field.startsWith("id_"));

        valuesThatKeyStartWithID.forEach((key) => {
            if (!formWithData?.[key]) {
                formWithData[key] = {
                    key,
                    value: values[key],
                    visible: "nao",
                };
            }
        });
    } else {
        formWithData = form;
    }

    return formWithData;
}

export function getFormInputFromPath(key: string, formInputsObject: FormInputsObjectProps, path: string[]): any {
    if (path.length === 0) {
        if (key) {
            return formInputsObject?.[key];
        }
        return formInputsObject;
    } else {
        const currentPath = [...path];
        const nextPath = currentPath.shift();
        if (nextPath) {
            return getFormInputFromPath(key, formInputsObject[nextPath], currentPath);
        }
    }
}

interface ChangeObjectPropertyRecursiveProps {
    key: string;
    path: string[];
    value?: any;
    data_source?: any;
    formInputsObject: FormInputsObjectProps;
    force_value?: boolean;
}
export function changeObjectPropertyRecursive({
    key,
    value,
    data_source,
    formInputsObject,
    force_value,
    path,
}: ChangeObjectPropertyRecursiveProps) {
    if (path.length === 0) {
        if (formInputsObject) {
            if (force_value) {
                formInputsObject[key].VALUES = value || data_source;
            } else {
                if (value !== null) {
                    formInputsObject[key].VALUES = value || data_source;
                }

                if (data_source !== null) {
                    formInputsObject[key].DATA_SOURCE = data_source;
                }
            }
        } else {
            const item: FormInputsProps = {
                key,
            };

            if (value !== null) {
                item.value = value;
            }

            if (data_source) {
                item.DATA_SOURCE = data_source;
            }

            formInputsObject = {
                [key]: item,
            };
        }
    } else {
        const currentPath = [...path];
        const nextPath = currentPath.shift();

        if (nextPath) {
            formInputsObject[key] = changeObjectPropertyRecursive({
                key,
                value,
                data_source,
                formInputsObject: formInputsObject[nextPath],
                force_value,
                path: currentPath,
            }) as FormInputsProps;
        }
    }

    return formInputsObject;
}

interface IFormHandle {
    parent: FormInputsProps;
    formInputsObject: FormInputsObjectProps;
}

export function getChild({ parent, formInputsObject }: IFormHandle) {
    const childs: FormInputsProps[] = [];

    const components = Object.values(formInputsObject);

    if (parent.child_keys) {
        if (parent.input_group_path) {
            parent.child_keys.forEach((key: string) => {
                if (key.startsWith("root@")) {
                    const input_key = key.split("@")[1];
                    const root_childs = components.filter(
                        (component) => component.key === input_key && component.parent === parent.key
                    );
                    childs.push(...root_childs);
                } else {
                    const path = parent.input_group_path || [];
                    const no_root_childs = getFormInputFromPath(key, formInputsObject, [...path]);
                    if (no_root_childs) {
                        childs.push(no_root_childs);
                    }
                }
            });
        } else {
            const root_childs = components.filter((component) => {
                if (component.parent === parent.key && parent?.child_keys?.includes(component.key)) {
                    return true;
                }

                return false;
            });
            childs.push(...root_childs);
        }
    }

    return childs;
}

export function getInputColSize(input: FormInputsProps) {
    const style = input.style;

    let colSize = 8;

    if (style) {
        if (style.includes("qa_col_")) {
            let col = style.match(/(?<=(qa_col_))[0-9]+/)?.toString();

            try {
                colSize = parseInt(col || colSize.toString());
                return colSize;
            } catch (err) {}
        }

        if (style.includes("area_texto") && !style.includes("coluna")) {
            return 24;
        }
        if (style.includes("area_texto_externo") && !style.includes("coluna")) {
            return 24;
        }

        if (style.includes("coluna2")) {
            return 8;
        } else if (style.includes("coluna2xp")) {
            return 4;
        } else if (style.includes("coluna100qa")) {
            return 8;
        }

        // return
        const inputSize = style?.match(/(?!(coluna))\d+/);

        if (inputSize) {
            colSize = Number(inputSize[0]);

            if (colSize < 8) {
                colSize = 8;
            } else if (colSize > 100) {
                colSize = 8;
            } else if (colSize > 12) {
                colSize = 24;
            } else if (colSize <= 12) {
                colSize = 16;
            }
        } else {
            colSize = 8;
        }
    }

    switch (input.type) {
        case COMPONENT_TYPE.INPUT_AREA: {
            colSize = 16;
            break;
        }

        case COMPONENT_TYPE.DATA_DISPLAY: {
            if (!input?.["@estilo"]?.includes("coluna2xp")) {
                var regex = /\d+/g;
                var size = input?.["@estilo"]?.match(regex)?.[0] || 0;
                if (size >= 700) {
                    colSize = 24;
                } else {
                    colSize = 8;
                }
            }

            break;
        }

        case COMPONENT_TYPE.INPUT_GROUP: {
            colSize = 24;
            break;
        }

        case COMPONENT_TYPE.DYNAMIC_TABLE:
        case COMPONENT_TYPE.DYNAMIC_DATAGRID:
        case COMPONENT_TYPE.DATAGRID:
        case COMPONENT_TYPE.SELECTION_TABLE: {
            colSize = 24;
            break;
        }
        case COMPONENT_TYPE.SELECT: {
            if (input["@estilo"]?.includes("coluna600qa")) {
                colSize = 24;
            }

            break;
        }
        default: {
            colSize = colSize > 0 ? colSize : 8;
            break;
        }
    }

    return colSize;
}

export const checkHasColSize = (item: FormInputsProps, hasCol: boolean) => {
    if (item.type === COMPONENT_TYPE.POPUP) {
        hasCol = false;
    }
    return hasCol;
};

export const checkInputHasInputChild = (item: FormInputsProps, hasFormItem = true) => {
    switch (item.type) {
        case COMPONENT_TYPE.DYNAMIC_TABLE:
        case COMPONENT_TYPE.DYNAMIC_DATAGRID:
        case COMPONENT_TYPE.DATAGRID:
        case COMPONENT_TYPE.SELECTION_TABLE:
        case COMPONENT_TYPE.TAB:
        case COMPONENT_TYPE.POPUP: {
            return true;
        }

        default:
            return hasFormItem;
    }
};

export const getDataSource = (itemData: FormInputsProps) => {
    if (!itemData.values) return itemData?.DATA_SOURCE || [];

    //old = \[(.*?)\]|((.*?)\;)|\w+
    let dataSource: any = itemData.values.match(/\[(.*?)\]|((.*?)\;)|((.*?)$)|\w+/g);

    if (dataSource && dataSource[0] === itemData.values) {
        dataSource = dataSource[0].replace("[", "").replace("]", "").split(";");

        dataSource = dataSource
            .filter((el: any) => el)
            .map((it: any) => {
                if (it.match(/,/)) {
                    const [value, key] = it.split(",");
                    return {
                        key:
                            itemData?.type === COMPONENT_TYPE.SELECT
                                ? value?.trim()
                                : value.trim().replace("true", "t").replace("false", "f"),
                        value: key.trim(),
                    };
                }
                return {
                    key:
                        itemData?.type === COMPONENT_TYPE.SELECT
                            ? it.trim()
                            : it.trim().replace("true", "t").replace("false", "f"),
                    value: it.trim(),
                };
            });
    } else {
        dataSource = dataSource.reduce((acumulator: any, current: any) => {
            current = current.replace("[", "").replace("]", "").replace(";", "");

            if (!current) {
                return acumulator;
            }

            if (current.match(/,/)) {
                let value, key;
                const listKeyValue = current.split(",");

                if (listKeyValue.length > 2) {
                    key = listKeyValue.pop();
                    value = listKeyValue.join(",");
                } else {
                    [value, key] = current.split(",");
                }

                if (key && value) {
                    acumulator.push({
                        key:
                            itemData?.type === COMPONENT_TYPE.SELECT
                                ? key?.trim()
                                : key.trim().replace("true", "t").replace("false", "f"),
                        value: value.trim(),
                    });
                }
                return acumulator;
            } else {
                acumulator.push({
                    key:
                        itemData?.type === COMPONENT_TYPE.SELECT
                            ? current?.trim()
                            : current.trim().replace("true", "t").replace("false", "f"),
                    value: current.trim(),
                });
                return acumulator;
            }
        }, []);
    }
    dataSource = Array.isArray(dataSource)
        ? dataSource.map((item) => {
              if (Array.isArray(item.key)) {
                  item.key = item.key[0];
              }

              return item;
          })
        : dataSource;
    return dataSource || [];
};

export const getSelectDataKeys = (componentsProperties: FormInputsProps) => {
    if (componentsProperties.type === COMPONENT_TYPE.STATIC_LIST) {
        return { key: "key", value: "value" };
    }

    let key = componentsProperties.key_list || componentsProperties.key;
    let value: string | string[];

    if (componentsProperties.value_list) {
        if (componentsProperties.value_list?.includes(";")) {
            value = componentsProperties.value_list.split(";").filter((it) => !Utilitaries.isEmpty(it));
        } else {
            value = componentsProperties.value_list;
        }
    } else {
        value = "designacao";
    }
    // if (data && Array.isArray(data) && data.length > 0) {
    //     const _data = data[0];
    //     if (_data?.key && _data?.value) {
    //         return {
    //             key: "key",
    //             value: "value",
    //         };
    //     }

    //     if (!_data?.hasOwnProperty(value)) {
    //         value = "designacao";
    //     }

    //     if (!_data?.hasOwnProperty(key)) {
    //         key = componentsProperties.key;
    //     }
    // }

    return { key, value };
};

export const isEmpty = (value: any) => {
    if (typeof value === "boolean" || typeof value === "number") {
        return false;
    } else if (typeof value === "object" && Array.isArray(value) && value.length === 0) {
        return true;
    } else if (!value) {
        return true;
    }

    return false;
};

export const toArray = (data: any) => {
    if (data && typeof data === "object") {
        if (!Array.isArray(data)) {
            if (Object.keys(data).length === 0) {
                return [];
            }

            return [data];
        }

        return data;
    }

    return [];
};

interface TransformDataToSaveProps {
    formData: FormInputsObjectProps;
    context: any;
    formName: any;
    formKey: any;
    params: any;
    process: any;
    task: any;
    isPortal: any;
    allPopupParams: any;
    temporary: any;
}

export const transformDataToSave = ({
    formData,
    context,
    formName,
    formKey,
    params,
    process,
    task,
    isPortal,
    allPopupParams,
    temporary,
}: TransformDataToSaveProps) => {
    // const temporaryRelRowKey = getState().processExecution.app.temporaryRelRowKey;
    // const temporaryRel = getState().processExecution.app.temporaryRel[temporaryRelRowKey];

    let hasRequiredFieldEmpty = false;
    let hasErrorEmpty = null;

    if (!formKey) {
        formKey = Object.values(formData).find((item: FormInputsProps) => item.form_name === formName)?.form_key;
    }
    const formDataWrapper = new FormData();

    let data = Object.values(formData)
        // ===> get data only from form to post
        // .filter((formDataItem) => formDataItem.formKey === formKey)
        .filter(
            (formDataItem) =>
                (!formDataItem.persist ||
                    formDataItem.persist !== "nao" ||
                    (formDataItem["@minVal"] && formDataItem.type === COMPONENT_TYPE.DATA_DISPLAY)) &&
                !Array.isArray(formDataItem)
        )
        // ===> transform data structure based on especific rules
        .map((formDataItem) => {
            let value = null;

            try {
                //* fixed list data to be saved
                if (
                    formDataItem.type === COMPONENT_TYPE.DYNAMIC_TABLE ||
                    formDataItem.type === COMPONENT_TYPE.SELECTION_TABLE ||
                    formDataItem.type === COMPONENT_TYPE.DYNAMIC_DATAGRID ||
                    formDataItem.type === COMPONENT_TYPE.DATAGRID
                    // || formDataItem["@tipo"] ===  componentType.fixedList
                ) {
                    return getFieldValue({ formDataItem, allPopupParams, temporary, formData });
                }

                if (formDataItem["@tipo"] === COMPONENT_TYPE.INPUT_GROUP) {
                    if (formDataItem.visible && toBoolean(formDataItem.visible) === false) {
                        value = {};
                    } else {
                        value = getInputGroupData({ inputData: formDataItem, result: {}, temporary, formData });
                    }
                    return { [formDataItem.key]: value };
                } else {
                    if (
                        formDataItem.type === COMPONENT_TYPE.DATA_DISPLAY &&
                        formDataItem.persist === "nao" &&
                        formDataItem.visible &&
                        formDataItem["@minVal"]
                    ) {
                        if (
                            Utilitaries.toNumber(formDataItem?.value)! < Utilitaries.toNumber(formDataItem["@minVal"])!
                        ) {
                            hasErrorEmpty = formDataItem?.["@minValErrorMessage"];
                            throw new Error("EmptyTable");
                        }
                    }

                    const fieldIsRequired = getRequiredFieldsWhithoutRequiredKey({
                        field: formDataItem,
                        state: { form: formData },
                    });

                    const fieldIsVisible = isFieldVisibility({ field: formDataItem, state: { form: formData } });

                    value = getValueRealValue({
                        value: formDataItem.value,
                        allPopupParams,
                        required: fieldIsRequired,
                        visible: fieldIsVisible,
                        temporary,
                        input: formDataItem,
                    });

                    // if (formDataItem?.required === true && isEmpty(value)) {
                    //     hasRequiredFieldEmpty = true;
                    // }

                    // if (!(formDataItem["@restricao"] && formDataItem["@tipo"] === componentType._dataDisplay)) {
                    return { [formDataItem.key]: value };
                    // }
                }
            } catch (error: any) {
                console.error(error, error.message, formDataItem);
                if (error?.message === "Empty") {
                    hasRequiredFieldEmpty = true;
                }
                return value;
            }
        })
        // ===> convert array to object
        .reduce((acumulator, current) => {
            if (current?.linha) {
                acumulator = {
                    ...acumulator,
                    linha: {
                        ...acumulator.linha,
                        ...current.linha,
                    },
                };
            } else {
                acumulator = { ...acumulator, ...current };
            }
            return acumulator;
        }, {});

    // ===> full task save
    // return Promise.resolve("empty");
    if (hasRequiredFieldEmpty && !temporary) {
        return Promise.resolve("empty");
    }

    if (hasErrorEmpty) {
        message.error(hasErrorEmpty);
        return Promise.resolve("empty");
    }

    // let formMeta = getState().processExecution.domain.byId[process].tasks[task].formMeta[0];
    data.actividade = data.actividade; //|| formMeta.meta.actividade;
    if (context === "root") {
        // ===> get the root formMeta : formMeta[0]
        if (!isPortal) {
            formDataWrapper.append("processo", process);
            formDataWrapper.append("tarefa", task);
        }
        // TODO: enviar valencia e valencia_desgnacao se necessarios
        data.processo = process;
        // data.actividade = data.actividade || formMeta.meta.actividade;
        data.modelo = data?.modelo; //|| formMeta.meta.modelo;
        data.valencia = data?.valencia; //|| formMeta.meta?.valencia;
        data.estabelecimento = data?.estabelecimento; //|| formMeta.meta.estabelecimento;
        data.cliente = data.cliente; //|| formMeta.meta?.cliente;
    }
    // TODO: Remover
    if (context === "modal") {
        if (!data.processo) {
            data.processo = allPopupParams.idProcesso;
        }
        data.actividade_origem = data.actividade_origem || allPopupParams.idActividadeOrigem;
        // data.modelo = allPopupParams.idModeloOrigem;
        // data.modelo = 2;
        // Necessario no modal - qualia_dr39_01_registo_clientes_pop.qml.xml
        data.modelo = data.modelo || allPopupParams?.idModelo || allPopupParams.idModeloOrigem;

        if (data?.modelo?.startsWith("{$param.idModelo")) {
            data.modelo = allPopupParams?.idModelo || allPopupParams.idModeloOrigem;
        }

        data.modelo_origem = allPopupParams.idModeloOrigem;
        data.estabelecimento = allPopupParams.idEstabelecimento;
    }

    // let felteredData = {};
    for (const key in data) {
        if (formData && Array.isArray(formData) && formData.length && key !== "linha") {
            const element = formData.find((item) => item.key === key);

            if ((element?.key === key && element?.["@persiste"] === "nao") || !element) {
                delete data[key];
            }

            if (!data[key]) {
                delete data[key];
            }
        }
    }

    return data;

    // formDataWrapper.append("accao", "guardar");
    // formDataWrapper.append("formulario", formName);
    // formDataWrapper.append("dados", JSON.stringify({ [formKey]: data }));
    // formDataWrapper.append("obrigatorios", temporary ? "true" : "false");
    // return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formDataWrapper, {}, formData);
};

interface GetFieldValueProps {
    formDataItem: FormInputsProps;
    allPopupParams?: any;
    temporary: any;
    formData: FormInputsObjectProps;
}

function getFieldValue({ formDataItem, allPopupParams = {}, temporary, formData }: GetFieldValueProps) {
    let value: any = "";
    let requiredField: any = [];

    // if (formDataItem["@tipo"] === componentType.fixedList) {
    //     let upperKey = "";
    //     let deeperKey = "";
    //     for (const key in formDataItem) {
    //         if (
    //             typeof formDataItem[key] === "object" &&
    //             key !== "value" &&
    //             key !== "dataSource" &&
    //             key !== "inputGroupPath"
    //         ) {
    //             upperKey = key;
    //             for (const innerKey in formDataItem[key]) {
    //                 if (typeof formDataItem[key][innerKey] === "object") {
    //                     deeperKey = innerKey;
    //                 }
    //             }
    //         }
    //     }
    //     if (Array.isArray(formDataItem.value)) {
    //         value = {
    //             [upperKey]: formDataItem.value.map((item) => ({
    //                 [deeperKey]: item,
    //             })),
    //         };
    //     }
    //     return { [formDataItem.key]: value };
    // }
    // table format data to be saved

    if (
        formDataItem.type === COMPONENT_TYPE.DYNAMIC_TABLE ||
        formDataItem.type === COMPONENT_TYPE.SELECTION_TABLE ||
        formDataItem.type === COMPONENT_TYPE.DYNAMIC_DATAGRID ||
        formDataItem.type === COMPONENT_TYPE.DATAGRID
    ) {
        // let allParamsDef = [];
        let upperKey = "";
        const checkRequiredIsExpression = /..\/.*|\/\/.*/;
        requiredField = [];

        if (formDataItem.type === COMPONENT_TYPE.DATAGRID || formDataItem.type === COMPONENT_TYPE.DYNAMIC_DATAGRID) {
            upperKey = formDataItem.key;
            for (const key in formDataItem) {
                const currentFormDataItem = formDataItem[key] as FormInputsProps;
                if (
                    typeof formDataItem[key] === "object" &&
                    !["value", "DATA_SUORCE", "input_group_path"].includes(key) &&
                    (currentFormDataItem.required === "true()" ||
                        currentFormDataItem.required === "true" ||
                        checkRequiredIsExpression.test(currentFormDataItem.required!))
                ) {
                    requiredField.push(key);
                }
            }
        } else {
            for (const key in formDataItem) {
                if (
                    typeof formDataItem[key] === "object" &&
                    !["value", "DATA_SUORCE", "input_group_path"].includes(key)
                ) {
                    upperKey = key;
                    // allParamsDef = formDataItem[key];
                }
            }
            if (
                (formDataItem.type === COMPONENT_TYPE.DYNAMIC_TABLE &&
                    typeof formDataItem.visible !== "undefined" &&
                    toBoolean(formDataItem.visible) !== false) ||
                formDataItem.type !== COMPONENT_TYPE.DYNAMIC_TABLE
            ) {
                for (const key in formDataItem[upperKey]) {
                    const currentFormDataItem = formDataItem[upperKey][key] as FormInputsProps;
                    if (
                        typeof formDataItem[upperKey][key] === "object" &&
                        !["value", "DATA_SUORCE", "input_group_path"].includes(key) &&
                        (currentFormDataItem.required === "true()" ||
                            currentFormDataItem.required === "true" ||
                            checkRequiredIsExpression.test(currentFormDataItem.required!))
                    ) {
                        requiredField.push(key);
                    }
                }
            }
        }

        // for (const key in formDataItem) {
        //     if (
        //         typeof formDataItem[key] === "object" &&
        //         !["value", "dataSource", "inputGroupPath", "path"].includes(key)
        //     ) {
        //         upperKey = key;
        //         // allParamsDef = formDataItem[key];
        //     }
        // }

        // SEMPRE ESTAVA COMENTADO
        // if (formDataItem["@tipo"] === componentType._dynamicDatagrid) {
        // 	upperKey = "linha";
        // }

        // requiredField = [];
        // for (const key in formDataItem[upperKey]) {
        //     if (
        //         typeof formDataItem[upperKey][key] === "object" &&
        //         !["value", "dataSource", "inputGroupPath", "path"].includes(key) &&
        //         (formDataItem[upperKey][key]?.["@obrigatorio"] === "true()" ||
        //             checkRequiredIsExpression.test(formDataItem[upperKey][key]?.["@obrigatorio"]))
        //     ) {
        //         requiredField.push(key);
        //     }
        // }

        const state = {
            form:
                typeof formData === "object" && !Array.isArray(formData)
                    ? formData
                    : formData.reduce((acummulator, currentInput) => {
                          acummulator[currentInput.key] = currentInput;

                          return acummulator;
                      }, {}),
        };

        const dataSource = Object.values(formDataItem?.DATA_SOURCE || {}) as any[];

        if (dataSource) {
            if (dataSource.length > 0 && !Utilitaries.isEmpty(dataSource[0]?._key_)) {
                //order by _key_
                dataSource.sort((a, b) => {
                    if (a._key_ > b._key_) return 1;
                    else if (a._key_ < b._key_) return -1;
                    return 0;
                });
            }
        }

        value = {
            [upperKey]: dataSource.map((item) => {
                const { key, ...withoutKey } = item;

                const rowKey = key;

                for (const key in withoutKey) {
                    let value = withoutKey[key];

                    const input: FormInputsProps = formDataItem?.[upperKey]?.[key] || formDataItem?.[key];

                    if (key.startsWith("id_") && !input) {
                        withoutKey[key] = value;
                        continue;
                    }
                    if (typeof value === "object") value = withoutKey[key]?.id;

                    if (!input) {
                        delete withoutKey[key];
                        continue;
                    }

                    let visible = true;

                    let required = requiredField.includes(key);

                    if (required && checkRequiredIsExpression.test(input?.required!)) {
                        required = conversion({
                            xpath: input?.required, //input?.["@relevante"],
                            item: input,
                            root: input?.root,
                            rowKey,
                            // relKey: itemKeys.find((key) => key.includes("relevante")),
                            relKey: "", // was added to dribble type error missing params
                            state,
                            rootPath: input?.inputGroupPath,
                        });
                        try {
                            required = eval(required) ? true : false;
                        } catch (error) {
                            console.error(error);
                            required = false;
                        }
                    }

                    const isRequiredField = input?.["@relevante"];
                    if (isRequiredField) {
                        const _visible = conversion({
                            xpath: isRequiredField, //input?.["@relevante"],
                            item: input,
                            root: input?.root,
                            rowKey,
                            // relKey: itemKeys.find((key) => key.includes("relevante")),
                            relKey: "", // was added to dribble type error missing params
                            state,
                            rootPath: input?.inputGroupPath,
                        });
                        try {
                            visible = eval(_visible) ? true : false;
                        } catch (error) {
                            console.error(error);
                            visible = false;
                        }
                    } else if (input?.type === COMPONENT_TYPE.DATA_DISPLAY && !input?.data_query) {
                        required = false;
                    }

                    withoutKey[key] = getValueRealValue({
                        value,
                        allPopupParams,
                        required,
                        visible /*: formDataItem[upperKey]?.[key]?.visible || true*/,
                        input: { ...input, visible },
                        temporary,
                    });
                }
                return withoutKey;
            }),
        };

        if (formDataItem.type === COMPONENT_TYPE.DYNAMIC_DATAGRID) {
            return { linha: { [formDataItem.key]: value[upperKey] } };
        } else if (formDataItem.type === COMPONENT_TYPE.DATAGRID && formDataItem["@resultados_pagina"]) {
            return { linha: { [formDataItem.key]: value[upperKey] } };
        }
        return { [formDataItem.key]: value };
    }
}

interface GetValueRealValue {
    value: any;
    allPopupParams?: any;
    required: any;
    visible: any;
    fatherVisibility?: any;
    input: any;
    temporary: any;
}

const getValueRealValue = ({
    value,
    allPopupParams = {},
    required,
    visible,
    fatherVisibility,
    input,
    temporary,
}: GetValueRealValue) => {
    // if (!value || !isNaN(value)) {
    //     return value;
    // }
    if (!isEmpty(value)) {
        if (typeof value === "object") {
            value = "";
        } else {
            if (typeof value !== "number" && typeof value !== "boolean") {
                if (value?.startsWith("{$param.")) {
                    value = value.split("param.")[1].replace(/\W/g, "");

                    value = allPopupParams[value] || "";
                } else if (/\$\w+\.\w+|dados_(mae|pai).\w+/.test(value)) {
                    value = "";
                } else if (value === "{now()}") {
                    value = moment().format("YYYY-MM-DD");
                }
            }
        }

        if (value === "t") {
            value = "true";
        }

        if (value === "f") {
            value = "false";
        }
    }

    const _visibility = input && typeof input === "object" ? input.visible : visible;

    if (
        isEmpty(value) &&
        !temporary &&
        required &&
        _visibility &&
        (!fatherVisibility || !fatherVisibility?.includes(false))
    ) {
        if (Utilitaries.isEmpty(input?.requiredMessage)) {
            message.error("Campos obrigatórios em branco");
        } else {
            message.error(input.requiredMessage);
        }
        throw new Error("Empty");
    }

    return value;
};

interface GetInputGroupDataProps {
    inputData: FormInputsProps;
    result: any;
    fatherVisibility?: any;
    temporary: any;
    formData: FormInputsObjectProps;
}

function getInputGroupData({
    inputData,
    result = {},
    fatherVisibility = [],
    temporary,
    formData,
}: GetInputGroupDataProps) {
    for (const key in inputData) {
        if (
            typeof inputData[key] === "object" &&
            inputData[key].type &&
            inputData[key].type === COMPONENT_TYPE.INPUT_GROUP
        ) {
            fatherVisibility.push(inputData[key]?.visible);
            result[key] = getInputGroupData({
                inputData: inputData[key],
                result: result[key] || {},
                fatherVisibility,
                temporary,
                formData,
            });
        } else if (inputData[key] && typeof inputData[key] === "object" && inputData[key].key) {
            if (
                inputData[key].type === COMPONENT_TYPE.DYNAMIC_TABLE ||
                inputData[key].type === COMPONENT_TYPE.SELECTION_TABLE ||
                inputData[key].type === COMPONENT_TYPE.DYNAMIC_DATAGRID ||
                inputData[key].type === COMPONENT_TYPE.DATAGRID
                // || inputData[key].type === componentType.fixedList
            ) {
                result = {
                    ...result,
                    ...getFieldValue({ formDataItem: inputData[key], temporary, formData }),
                };
            } else {
                result[key] = getValueRealValue({
                    value: isEmpty(inputData[key].value) ? "" : inputData[key].value,
                    required: inputData[key]?.required,
                    visible: inputData?.visible,
                    fatherVisibility,
                    input: inputData[key],
                    temporary,
                });
            }
        }
    }

    return result;
}

interface GetRequiredFieldsWhithoutRequiredKey {
    field: FormInputsProps;
    state: { form: FormInputsObjectProps };
}

const getRequiredFieldsWhithoutRequiredKey = ({ field, state }: GetRequiredFieldsWhithoutRequiredKey) => {
    let required = field?.required as any;

    if (typeof required !== "undefined") {
        return required;
    }

    if (field?.required) {
        required = isRequired({ itemData: field, state });
        return required;
    }

    return false;
};

interface FunctionsProps {
    comparar_datas: () => void;
    moment: () => void;
    texto: () => void;
    auto_preenche: () => void;
}
// interface InputValidationsProps {
//     item: FormInputsProps;
//     relKey: string;
//     functions: FunctionsProps;
//     returnExpression: boolean;
//     rootDataName: any;
//     inputProps: any;
//     allInputs: FormInputsObjectProps;
//     selfValue: any;
// }

// const emptyFunction = () => {};

// export function inputValidations({
//     item,
//     relKey,
//     functions = {
//         comparar_datas: emptyFunction,
//         moment: emptyFunction,
//         texto: emptyFunction,
//         auto_preenche: emptyFunction,
//     },
//     returnExpression = false,
//     rootDataName = undefined,
//     inputProps = {},
//     allInputs = {},
//     selfValue,
// }: InputValidationsProps) {
//     // eslint-disable-next-line no-unused-vars
//     const { comparar_datas, moment, texto, auto_preenche } = functions;

//     // eslint-disable-next-line no-unused-vars
//     const toInteger = (value: string | number) => {
//         try {
//             if (typeof value === "string") {
//                 return parseInt(value);
//             }

//             return value;
//         } catch (error) {}

//         return "";
//     };

//     const xpath =
//         item.type === (COMPONENT_TYPE.SELECTION_TABLE || item.type === COMPONENT_TYPE.DYNAMIC_TABLE)
//             ? getTableAction({ item, relKey }).valueDep
//             : item[relKey];
//     let allFieldNames = regex_match(xpath);
//     // let jsExp = se tipo conju ? funcao () : item[relKey];
//     let jsExp =
//         COMPONENT_TYPE.SELECTION_TABLE || item.type === COMPONENT_TYPE.DYNAMIC_TABLE
//             ? getTableAction({ item, relKey }).valueDep
//             : item[relKey];

//     if (xpath === "nif") {
//         return Utilitaries.isValidateNIF_PT(selfValue);
//     }

//     if (relKey === "@apenas_leitura" && jsExp === "true()") {
//         return true;
//     }

//     if (jsExp === "../estabelecimento = ''") {
//     }

//     //
//     jsExp = jsExp
//         .replace(/\sor\s/gi, " || ")
//         .replace(/\sand\s/gi, " && ")
//         .replace(/\s=\s/gi, " == ")
//         .replace(/(?<=[a-z])=(?=')/gi, " == ")
//         .replace(/'true'/gi, "'t'")
//         .replace(/true/gi, "'t'")
//         .replace(/'false'/gi, "'f'")
//         .replace(/''''/gi, "''")
//         .replace(/\sdiv\s/gi, "/")
//         .replace(/(?:\\[rn])+/g, "");

//     if (!/\s!=\s/g.test(jsExp) && !/\s>=\s/g.test(jsExp) && !/\s<=\s/g.test(jsExp) && !/\s==\s/g.test(jsExp)) {
//         jsExp = jsExp.replace(/=/g, "==");
//     }

//     // have if - else (then - else) condition
//     if (/then/.test(jsExp)) {
//         // jsExp = jsExp.replace(/\sthen\s/gi, " { ").replace(/\selse\s/gi, " } $& { ");
//         // jsExp = jsExp + "}";
//         jsExp = jsExp.replace(/if/gi, "");
//         jsExp = jsExp.replace(/\sthen\s/gi, " ? ").replace(/\selse\s/gi, " : ");
//     }

//     // have just THEN
//     if (/THEN/.test(jsExp)) {
//         jsExp = jsExp.replace(/THEN/, "");
//     }

//     // ------------------------------
//     const sumRegex = /(?<=sum\().*(?=\))/;
//     if (sumRegex.test(xpath)) {
//         const expression = jsExp.match(/sum\(.*?\)/g);
//         expression.forEach((element: any) => {
//             const resultCount = sumConvertion({ xpath: element, allInputs });
//             jsExp = jsExp.replace(element, resultCount);
//         });
//         // allFieldNames = jsExp.match(fieldNameRegex) || [];
//         allFieldNames = regex_match(jsExp);
//     }

//     const countRegex = /(?<=count\().*(?=\))/;
//     if (countRegex.test(xpath)) {
//         const expression = jsExp.match(/count\(.*?\)/g);
//         expression.forEach((element: any) => {
//             const resultCount = countConvertion({ xpath: element, allInputs });
//             jsExp = jsExp.replace(element, resultCount);
//         });
//         // allFieldNames = jsExp.match(fieldNameRegex) || [];
//         allFieldNames = regex_match(jsExp);
//     }

//     // const listRegex = /x_lista:\/\/\w+\/\w+\[[^\]]*\]/;
//     const listRegex = /x_lista:\/\/\w+\/\w+\[.*?\]|x_lista:\/\/\w+\/\w+/;
//     if (listRegex.test(xpath)) {
//         const expression = jsExp.match(/x_lista:\/\/\w+\/\w+\[.*?\]|x_lista:\/\/\w+\/\w+/g);
//         expression.forEach((element: any) => {
//             const resultCount = listConvertion({ xpath: element, allInputs });
//             jsExp = jsExp.replace(element, resultCount);
//         });
//         // allFieldNames = jsExp.match(fieldNameRegex) || [];
//         allFieldNames = regex_match(jsExp);
//     }
//     // ------------------------------

//     let existEmptyValues = false;
//     // if (
//     //     jsExp ===
//     //     "if(../id_subsidio == '') { auto_preenche(load_subs_by_design,{estab,../estab},{design,../designacao_real},{ignoraCache, 1}) }  else  { ../designacao}"
//     // )
//     //     ;

//     for (const fieldName of allFieldNames) {
//         if (parseInt(fieldName)) {
//             continue;
//         }
//         let fieldValue: any = null;

//         if (Utilitaries.toArray(inputProps?._nodes).length > 0) {
//             fieldValue = getItemValue({ itemKey: fieldName, formData, objectInputs: inputProps });
//         }

//         if (!fieldValue) {
//             if (inputProps.brothers && inputProps.brothers.includes(fieldName)) {
//                 fieldValue = `'${
//                     typeof inputProps.items.data?.[fieldName] == "number"
//                         ? inputProps.items.data?.[fieldName]
//                         : inputProps.items.data?.[fieldName] || ""
//                 }'`;
//             } else {
//                 fieldValue = `${rootDataName ? rootDataName : "formData"}['${fieldName}']`;
//                 const value = eval(fieldValue);
//                 fieldValue = typeof value === "number" ? value : `'${isEmpty(value) ? "" : value}'`;
//             }
//         }

//         // if start with function to compare date
//         if (jsExp.startsWith("comparar_datas(")) {
//             fieldValue = `moment(formData['${fieldName}'])` || null;

//             if (!formData[fieldName]) {
//                 existEmptyValues = true;
//             }
//         } else {
//             let value = null;
//             try {
//                 value = eval(fieldValue);
//             } catch (error) {}

//             if ((!value || value === "''") && typeof value !== "number") {
//                 value = getItemValue({ itemKey: fieldName, formData, objectInputs: allInputs });
//             }
//             fieldValue = typeof value === "number" ? value : `'${isEmpty(value) ? "" : value}'`;
//         }

//         if (jsExp.includes("..//" + fieldName)) {
//             const regex = new RegExp(`..//${fieldName}`, "g");
//             jsExp = jsExp.replace(regex, fieldValue);
//         }
//         if (jsExp.includes("../" + fieldName)) {
//             const regex = new RegExp(`../${fieldName}`, "g");
//             jsExp = jsExp.replace(regex, fieldValue);
//         } else if (jsExp.includes("//" + fieldName)) {
//             jsExp = jsExp.replace("//" + fieldName, fieldValue);
//         }
//     }

//     jsExp = jsExp.replace(/'true'/gi, "'t'");

//     // not validate if field is alread null
//     //
//     if (existEmptyValues && relKey === "@restricao") {
//         return true;
//     }

//     if (jsExp.startsWith("tamanho_maximo(")) {
//         if (inputProps?.items?.data?.["serie"]?.length > parseInt(allFieldNames)) {
//             return false;
//         } else return true;
//     }

//     if (jsExp.startsWith("comparar_datas(")) {
//         jsExp = jsExp.replace(/\s>=\s(?=moment)/g, ".isSameOrAfter(").replace(/\s<=\s(?=moment)/g, ".isSameOrBefore(");
//         jsExp = jsExp.replace(/']\)\)/g, "$&)");

//         if (existEmptyValues) {
//             return true;
//         }
//     }

//     if (returnExpression) {
//         return jsExp;
//     }

//     //
//     let result = "";
//     try {
//         // eslint-disable-next-line no-eval
//         result = eval(jsExp);
//     } catch (error) {
//         console.error(error);
//         //
//         //     item,
//         //     relKey,
//         //     formData,
//         //     functions,
//         //     returnExpression,
//         //     rootDataName,
//         //     inputProps,
//         // });
//     }

//     return result;
// }

// interface GetTableActionProps {
//     item: any;
//     relKey: any;
// }

// export const getTableAction = ({ item, relKey }: GetTableActionProps) => {
//     if (relKey) {
//         if (item[relKey]) {
//             return {
//                 inputActionsName: relKey,
//                 valueDep: item[relKey],
//             };
//         } else {
//             for (const key in item) {
//                 if (typeof item[key] === "object") {
//                     const props = item[key][relKey];
//                     if (props) {
//                         return {
//                             inputActionsName: relKey,
//                             valueDep: props,
//                         };
//                     }
//                 }
//             }
//         }
//     } else {
//         for (const key in item) {
//             if (key.startsWith("@accao_")) {
//                 return {
//                     inputActionsName: key,
//                     valueDep: item[key],
//                 };
//             } else if (typeof item[key] === "object") {
//                 const props = Object.keys(item[key]).filter((item) => item.startsWith("@accao_"));
//                 if (Array.isArray(props)) {
//                     return {
//                         inputActionsName: props[0],
//                         valueDep: item[key][props[0]],
//                     };
//                 }
//             }
//         }
//     }
//     return {};
// };

// const regex =
//     /\w+\s*(?==)|\w+\s*(?=\*)|\w+\s*(?=-)|\w+\s*(?=>)|\w+\s*(?=<)|\w+\s*(?=!)|\w+\s*(?=\+)|\w+(?=})|\w+(?=\))|\w+\s*(?=\s(else))|\w+$/g;
// export function regex_match(xpath: any) {
//     if (!xpath || !xpath.match(regex)) return [];
//     return xpath.match(regex).map((item: any) => /\w+/.exec(item)?.[0]);
// }

// interface ConvertionProps {
//     xpath: string;
//     allInputs: FormInputsObjectProps;
// }

// const sumConvertion = ({ xpath, allInputs = {} }: ConvertionProps) => {
//     // const regex = /\w*\s*(?==)|\w*\s*(?=!=)|\w*\s*(?=>)/g;
//     // downFile(JSON.stringify({ xpath, rootPath: tablePath, formData }), "json.txt", "text/plain");

//     const rootPath = xpath.match(/\w+\s*(?=)/g)?.[1];

//     // if (!formData) {
//     //     return 0;
//     // }
//     const searchedTable = Object.entries(allInputs || {}).find(([key, inputs]) => {
//         if (inputs && typeof inputs === "object" && typeof rootPath === "string" && inputs?.[rootPath]) {
//             return true;
//         }

//         return false;
//     });
//     let tableFirstKey = null;
//     let table = null;
//     if (searchedTable) {
//         [tableFirstKey, table] = searchedTable;
//     }

//     // let table = Object.values(formData).find((item) => item[rootPath]);

//     // let tableFirstKey = null;
//     let tableLastKey = null;

//     // if in inputGroup
//     if (!table) {
//         let allTable = { ...allInputs };

//         // ;
//         table = Object.entries(allTable).find(([inputKey, inputs]) => {
//             if (inputs.type === COMPONENT_TYPE.DYNAMIC_TABLE) {
//                 const tableSecondKey = Object.keys(inputs).find(
//                     (tableAttributes) =>
//                         !tableAttributes.startsWith("@") &&
//                         !Array.isArray(inputs[tableAttributes]) &&
//                         typeof inputs[tableAttributes] === "object"
//                 );

//                 if (!tableSecondKey || !rootPath) {
//                     return false;
//                 }

//                 if (inputs?.[tableSecondKey]?.[rootPath]) {
//                     tableFirstKey = inputKey;
//                     tableLastKey = tableSecondKey;
//                     return true;
//                 }
//             }
//             return false;
//         });
//     }

//     if (table && tableFirstKey) {
//         const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

//         let allFieldNames =
//             xpath
//                 .match(regex)
//                 ?.filter((item) => item)
//                 ?.map((it) => it.trim()) || [];

//         const tableValues = toArray(allInputs[tableFirstKey]?.DATA_SOURCE);

//         //     /**
//         //      * Garantir que funcione
//         //      * count(//requisito_forn/avaliacao[../avaliacao = 'C'])
//         //      * count(//requisito_forn/avaliacao = 'NC')
//         //      * count(//requisito_forn/id_requisito_aval_forn)
//         //      * count(//requisito_forn/avaliacao = 'NA')
//         //      * count(//requisito_forn/avaliacao != 'NA')
//         //      * count(//requisito_forn/avaliacao > 'NA')
//         //      * count(//requisito_forn/avaliacao < 'NA')
//         //      * count(//requisito_forn/avaliacao)
//         //      */
//         let conditionXpath: any = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

//         if (conditionXpath) {
//             conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

//             let fieldToSum: any = xpath.match(/\w*\s*(?=\[)/g);

//             if (!fieldToSum) {
//                 fieldToSum = conditionXpath;
//             } else {
//                 fieldToSum = fieldToSum[0];
//             }

//             let countValue = tableValues.reduce((accumulator, current) => {
//                 let condition = conditionXpath;

//                 allFieldNames.forEach((fieldName) => {
//                     condition = condition.replace(fieldName, `current["${fieldName}"]`);
//                 });

//                 const check = eval(condition);
//                 if (check) {
//                     let currentValue = current[fieldToSum];
//                     if (typeof currentValue === "string") {
//                         currentValue = currentValue.replace(",", ".");
//                     }
//                     accumulator += Number(currentValue);
//                 }
//                 return accumulator;
//             }, 0);
//             return countValue;
//         }
//     }
//     return 0;
// };

// const countConvertion = ({ xpath, allInputs = {} }: ConvertionProps) => {
//     // const regex = /\w*\s*(?==)|\w*\s*(?=!=)|\w*\s*(?=>)/g;
//     // downFile(JSON.stringify({ xpath, rootPath: tablePath, formData }), "json.txt", "text/plain");
//     const rootPath = xpath.match(/\w+\s*(?=)/g)?.[1];

//     // if (!formData) {
//     //     return 0;
//     // }
//     const searchedTable = Object.entries(allInputs || {}).find(([key, inputs]) => {
//         if (typeof inputs === "object" && inputs && typeof rootPath === "string" && inputs?.[rootPath]) {
//             return true;
//         }

//         return false;
//     });
//     let tableFirstKey = null;
//     let table = null;
//     if (searchedTable) {
//         [tableFirstKey, table] = searchedTable;
//     }

//     // let table = Object.values(formData).find((item) => item[rootPath]);

//     // let tableFirstKey = null;
//     let tableLastKey = null;

//     // if in inputGroup
//     if (!table) {
//         let allTable = { ...allInputs };

//         // ;
//         table = Object.entries(allTable).find(([inputKey, inputs]) => {
//             if (inputs["@tipo"] === "conjunto") {
//                 const tableSecondKey = Object.keys(inputs).find(
//                     (tableAttributes) =>
//                         !tableAttributes.startsWith("@") &&
//                         !Array.isArray(inputs[tableAttributes]) &&
//                         typeof inputs[tableAttributes] === "object"
//                 );

//                 if (!tableSecondKey || !rootPath) {
//                     return false;
//                 }

//                 if (inputs[tableSecondKey][rootPath]) {
//                     tableFirstKey = inputKey;
//                     tableLastKey = tableSecondKey;
//                     return true;
//                 }
//             }
//             return false;
//         });
//     }

//     if (table && tableFirstKey) {
//         const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

//         let allFieldNames =
//             xpath
//                 .match(regex)
//                 ?.filter((item) => item)
//                 ?.map((it) => it.trim()) || [];

//         // ;

//         //     // const fieldName = allFieldNames;
//         const tableValues = toArray(allInputs[tableFirstKey]?.DATA_SOURCE);

//         //     /**
//         //      * Garantir que funcione
//         //      * count(//requisito_forn/avaliacao[../avaliacao = 'C'])
//         //      * count(//requisito_forn/avaliacao = 'NC')
//         //      * count(//requisito_forn/id_requisito_aval_forn)
//         //      * count(//requisito_forn/avaliacao = 'NA')
//         //      * count(//requisito_forn/avaliacao != 'NA')
//         //      * count(//requisito_forn/avaliacao > 'NA')
//         //      * count(//requisito_forn/avaliacao < 'NA')
//         //      * count(//requisito_forn/avaliacao)
//         //      */
//         let conditionXpath: any = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

//         if (conditionXpath) {
//             conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

//             let fieldToSum: any = xpath.match(/\w*\s*(?=\[)/g);

//             if (!fieldToSum) {
//                 fieldToSum = conditionXpath;
//             } else {
//                 fieldToSum = fieldToSum[0];
//             }

//             let countValue = tableValues.reduce((accumulator, current) => {
//                 let condition = conditionXpath;

//                 allFieldNames.forEach((fieldName) => {
//                     condition = condition.replace(fieldName, `current["${fieldName}"]`);
//                 });

//                 const check = eval(condition);
//                 if (check) {
//                     accumulator += 1;
//                 }
//                 return accumulator;
//             }, 0);
//             return countValue;
//         }
//     }
//     return 0;
// };

// const listConvertion = ({ xpath, allInputs = {} }: ConvertionProps) => {
//     // downFile(JSON.stringify({ xpath, formData, allInputs }), "json.txt", "text/plain");

//     const root = xpath
//         .replace("x_lista://", "")
//         .split("/")
//         .map((it) => it.replace(/\W/g, ""));

//     // Make to work in dataGrid

//     const tableOrDataGridRoot = root[0];

//     // if (!formData) {
//     //     return "";
//     // }

//     const searchedTable = Object.entries(allInputs || {}).find(([key, inputs]) => {
//         if (typeof inputs === "object" && inputs && inputs?.[tableOrDataGridRoot]) {
//             return true;
//         }

//         return false;
//     });

//     let tableFirstKey = null;
//     let table = null;
//     if (searchedTable) {
//         [tableFirstKey, table] = searchedTable;
//     }

//     // let table = Object.values(formData).find((item) => item[rootPath]);

//     // let tableFirstKey = null;
//     let tableLastKey = null;

//     // if (table) {
//     //     if (formData?.["linha"]?.[tableOrDataGridRoot]) {
//     //         tableFirstKey = "linha";
//     //     }
//     //     tableLastKey = tableOrDataGridRoot;
//     // }

//     //

//     if (table && tableFirstKey) {
//         const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

//         let allFieldNames =
//             xpath
//                 .match(regex)
//                 ?.filter((item) => item)
//                 ?.map((it) => it.trim()) || [];

//         // x_lista://areas/area[../incluir='true']

//         //     // const fieldName = allFieldNames;
//         const tableValues = toArray(allInputs[tableFirstKey]?.DATA_SOURCE);
//         // const tableValues = formData[tableFirstKey][tableLastKey];

//         let conditionXpath: any = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

//         let fieldToGet: any = xpath.match(/\w*\s*(?=\[)/g);
//         fieldToGet = fieldToGet?.[0];

//         if (conditionXpath) {
//             conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

//             if (!fieldToGet) {
//                 fieldToGet = conditionXpath;
//             }
//             //

//             let countValue = tableValues.reduce((accumulator, current) => {
//                 let condition = conditionXpath;
//                 allFieldNames.forEach((fieldName) => {
//                     condition = condition.replace(fieldName, `current["${fieldName}"]`);
//                 });
//                 const check = eval(condition);
//                 if (check) {
//                     if (accumulator.length > 0) {
//                         accumulator += `,'${current[fieldToGet]}'`;
//                     } else {
//                         accumulator += `'${current[fieldToGet]}'`;
//                     }
//                 }
//                 return accumulator;
//             }, "");
//             return countValue;
//         } else {
//             if (!fieldToGet) {
//                 fieldToGet = root[root.length - 1];
//             }

//             let countValue = tableValues.reduce((accumulator, current) => {
//                 if (accumulator.length > 0) {
//                     accumulator += `,'${current[fieldToGet]}'`;
//                 } else {
//                     accumulator += `'${current[fieldToGet]}'`;
//                 }

//                 return accumulator;
//             }, "");
//             return countValue;
//         }
//     }

//     return "";
// };

// interface GetInputValueProps {
//     input_key: string;
//     form_inputs: FormInputsObjectProps;
// }
// export const getItemValue = ({ input_key, form_inputs }: GetInputValueProps) => {
//     let itemValue = "";

//     const items = Object.entries(form_inputs).filter(([key]) => key === input_key);

//     if (items.length === 0) {
//         if (Utilitaries.toArray(form_inputs?.).length > 0) {
//             itemValue =
//                 Utilitaries.toArray(objectInputs?._nodes).reduce(
//                     (accumulator, current) => {
//                         accumulator = accumulator?.[current];
//                         return accumulator || {};
//                     },
//                     { ...formData }
//                 )?.[itemKey] || "";
//         } else {
//             return "";
//         }
//     } else {
//         // usei primeira posicao pois e o que precisso para meu casso
//         // usar path de referencia (onde esta o campo (_nodes) e se ../ ou //)
//         let nodes = [];

//         if (objectInputs?.["@tipo"] === "conjunto-in-form") {
//             nodes = objectInputs?._nodes;
//         } else if (items.length === 1) {
//             const [, { _nodes }] = items[0];
//             nodes = _nodes;
//         }

//         if (nodes?.length > 0) {
//             itemValue =
//                 nodes.reduce(
//                     (accumulator, current) => {
//                         accumulator = accumulator?.[current];
//                         return accumulator || {};
//                     },
//                     { ...formData }
//                 )?.[itemKey] || "";
//         } else {
//             itemValue = formData[itemKey] || "";
//         }
//     }
//     return itemValue;
// };
