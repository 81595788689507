import { actionType } from "./../constants/knowledge";
import { arrayToObjectById } from "./../../../components/util";
const resetFormCreate = () => ({
    tipo_conhecimento: { name: "tipo_conhecimento", value: null },
    designacao: { name: "designacao", value: null },
    n_inc_cri_neg: { name: "n_inc_cri_neg", value: null },
    n_inc_cri_posi: { name: "n_inc_cri_posi", value: null },
    n_sugests: { name: "n_sugests", value: null },
});

const resetFormEdit = () => ({
    id_conhecimento: null,
    tipo_conhecimento: { name: "tipo_conhecimento", value: null },
    designation: { name: "designation", value: null },
    n_inc_cri_neg: { name: "n_inc_cri_neg", value: null },
    n_inc_cri_posi: { name: "n_inc_cri_posi", value: null },
    n_sugests: { name: "n_sugests", value: null },
});

const initialState = {
    domain: {
        formCreate: resetFormCreate(),
        formEdit: resetFormEdit(),
        byId: {},
        metadata: {
            attributes: {},
            formulario: {},
        },
    },
    app: {
        isFetched: false,
    },
};

export const knowledgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.select:
            let list = [];
            if (Number(action.payload["@attributes"].resultados) > 1) list = [...action.payload["linha"]];
            else if (Number(action.payload["@attributes"].resultados) === 1) list = [action.payload["linha"]];

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: list,
                        id: "id_conhecimento",
                    }),
                    metadata: {
                        ...state.domain.metadata,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };

        case actionType.getForm:
            const formCreate = action.payload.formulario["@attributes"];
            return {
                ...state,
                domain: {
                    ...state.domain,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formCreate.nome,
                            elemento_raiz: formCreate.elemento_raiz,
                        },
                    },
                },
            };
        case actionType.insert:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formCreate: resetFormCreate(),
                },
            };

        case actionType.setField:
            if (action.payload.isEdit) {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            ...action.payload.fields,
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            ...action.payload.fields,
                        },
                    },
                };
            }
        case actionType.getById:
            const values = action.payload.valores.conhecimento;
            const formulario = action.payload.formulario["@attributes"];
            const formData = {
                id_conhecimento: values.id_conhecimento,
                tipo_conhecimento: {
                    name: "tipo_conhecimento",
                    value: values.tipo_conhecimento,
                },
                designacao: { name: "designacao", value: values.designacao },
                descricao: {
                    name: "descricao",
                    value: typeof values.descricao === "object" ? null : values.descricao,
                },
                n_inc_cri_neg: {
                    name: "n_inc_cri_neg",
                    value: typeof values.n_inc_cri_neg === "object" ? null : values.n_inc_cri_neg,
                },
                n_inc_cri_posi: {
                    name: "n_inc_cri_posi",
                    value: typeof values.n_inc_cri_posi === "object" ? null : values.n_inc_cri_posi,
                },
                n_sugests: {
                    name: "n_sugests",
                    value: typeof values.n_sugests === "object" ? null : values.n_sugests,
                },
            };
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: {
                        ...state.domain.formEdit,
                        ...formData,
                    },
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formulario.nome,
                            elemento_raiz: formulario.elemento_raiz,
                        },
                    },
                },
            };

        default:
            return state;
    }
};
