import { toArray } from "../components/util";
import actionType from "../constants/action.type";

const initialState = {
    domain: {
        menus: [],
        permissions: [],
        processos_utilizados: [],
        routes: [],
        alerts: [],
        lembretes: [],
        lembretes_futuros: [],
        contributors_by_funcional_group: [],
        alertas_nao_listar: [],
        lembretes_vistos: [],
        badge_resume: {
            mensagens: 0,
            eventos: 0,
            lembretes: 0,
        },
    },
    app: {
        isFetched: false,
    },
};

export const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_ALL_PERMISSIONS:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    permissions: action.payload.linha
                        ? action.payload.linha
                        : action.payload.idautorizacao
                        ? action.payload
                        : [],
                },
            };
        case actionType.GET:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    menus: action.payload["menus"].linha,
                    processos_utilizados: toArray(action.payload["processos_utilizados"]?.linha),
                    routes: [],
                    // alerts: toArray(action.payload["alertas"]?.linha),
                    // alertas_nao_listar: toArray(action.payload["alertas_nao_listar"]?.linha),
                },
                app: {
                    isFetched: true,
                },
            };
        case actionType.SET_ROUTES:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    routes: action.payload,
                },
            };
        case actionType.GET_BADGE_RESUME:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    badge_resume: action.payload,
                },
            };
        // case actionType.GET_LEMBRETES_VISUALIZATION:
        //     return {
        //         ...state,
        //         domain: {
        //             ...state.domain,
        //             lembretes_vistos: Array.isArray(
        //                 action.payload["lembretes_vistos"].linha
        //             )
        //                 ? action.payload["lembretes_vistos"].linha
        //                 : [action.payload["lembretes_vistos"].linha]
        //         }
        //     };
        case actionType.GET_LEMBRATE:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    lembretes: toArray(action.payload["lembretes"].linha),
                    lembretes_futuros: toArray(action.payload["lembretes_futuros"].linha),
                },
            };
        case actionType.GET_CONTRIBUTORS_BY_FUNCIONAL_GROUP:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    contributors_by_funcional_group: action.payload.linha,
                },
            };
        default:
            return state;
    }
};
