/**
 * Set TRUE to build generate
 *
 * Set whether the app is running in developer mode or prodoction mode on the client
 */
export const IN_PROD = process.env.NODE_ENV === "production";
export const IN_DEVELOPMENT = false;

export const BASE_NAME = "";

export const getBaseName = () => (BASE_NAME !== "/" && BASE_NAME ? `/${BASE_NAME}` : "");

/**
 * Backend URL
 */
const app_url = IN_PROD
    ? `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? ":" + window.location.port : ""
      }${window.location.pathname}`
    : `${window.location.protocol}//${window.location.hostname}/`; // por o endereco e a porta do backend
//   `http://localhost/`; // por o endereco e a porta do backend

/**
 * Backend root address
 */
export let BASE_URL = `${app_url}${IN_PROD ? "backend" : "qa-social"}`;
// export let BASE_URL = `http://qualityalive2.selfip.com:81/qa-barroselas/backend`;
// export let BASE_URL = `http://ascudt.dynalias.com:90/qa/backend`;

/**
 * Backend full address
 */
export const baseUrl = `${BASE_URL}/PHP`;

/**
 * Set TRUE if you work on the UNICV plataform
 * Enable
 *  - Registation options for students
 */
export const IS_UNICV = true;

/**
 * Set TRUE if you work on the UNICV plataform
 * Enable
 *  - Make app to use an test URL for UNICV login
 */
export const DEV_UNICV = false;
