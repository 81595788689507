import React, { useState, useEffect } from "react";
import {
    Modal,
    Tabs,
    Radio,
    Select,
    Tooltip,
    Icon,
    InputNumber,
    Popconfirm,
    Table,
    Row,
    Input,
    Col,
    Button,
} from "antd";
import moment from "moment";

const { TabPane } = Tabs;
const { Option } = Select;

const columnsPontualTable = (removeItemFromPunctualEvent, onChange) => [
    {
        title: "Data",
        dataIndex: "data",
        width: "30%",
        render: (text, record) => (
            // <input
            //     type="date"
            //     value={text ? moment(text, "YYYY-MM-DD").format("YYYY-MM-DD") : null}
            //     onChange={(e) => onChange(e.target.value, record.key, "data")}
            // />
            <Input
                type="date"
                defaultValue={text ? moment(text, "YYYY-MM-DD").format("YYYY-MM-DD") : null}
                onChange={(date) =>
                    onChange(
                        date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                        record.key,
                        "data"
                    )
                }
            />
        ),
    },
    {
        title: "Início",
        dataIndex: "inicio",
        width: "30%",
        render: (text, record) => (
            <Input type="time" defaultValue={text} onChange={(e) => onChange(e.target.value, record.key, "inicio")} />
        ),
    },
    {
        title: "Fim",
        dataIndex: "fim",
        width: "25%",
        render: (text, record) => (
            <Input type="time" defaultValue={text} onChange={(e) => onChange(e.target.value, record.key, "fim")} />
        ),
    },
    {
        title: "Ação",
        dataIndex: "actions",
        width: "15%",
        render: (text, record) => (
            <Popconfirm
                title="Tens certeza que desejas eliminar?"
                onConfirm={() => removeItemFromPunctualEvent(record)}
            >
                <a
                    style={{
                        width: "100%",
                        margin: "0 auto",
                        padding: "0",
                    }}
                    href="/"
                    className="qa-text-del"
                >
                    Eliminar
                </a>
            </Popconfirm>
        ),
    },
];

const columnsSemanalTable = (removeItemFromPunctualEvent, onChange) => [
    {
        title: "Dia",
        dataIndex: "dia",
        width: "35%",
        render: (text, record) => (
            <Select
                defaultValue={parseInt(text)}
                style={{ width: 120 }}
                onChange={(value) => onChange(value, record.key, "dia")}
            >
                {[
                    { id: 0, name: "Domingo" },
                    { id: 1, name: "Segunda" },
                    { id: 2, name: "Terça" },
                    { id: 3, name: "Quarta" },
                    { id: 4, name: "Quinta" },
                    { id: 5, name: "Sexta" },
                    { id: 6, name: "Sábado" },
                ].map((item, index) => (
                    <Option key={item.id} value={item.id}>
                        {item.name}
                    </Option>
                ))}
            </Select>
        ),
    },
    {
        title: "Início",
        dataIndex: "inicio",
        width: "25%",
        render: (text, record) => (
            <Input type="time" defaultValue={text} onChange={(e) => onChange(e.target.value, record.key, "inicio")} />
        ),
    },
    {
        title: "Fim",
        dataIndex: "fim",
        width: "25%",
        render: (text, record) => (
            <Input type="time" defaultValue={text} onChange={(e) => onChange(e.target.value, record.key, "fim")} />
        ),
    },
    {
        title: "Ação",
        dataIndex: "actions",
        width: "15%",
        render: (text, record) => (
            <Popconfirm
                title="Tens certeza que desejas eliminar?"
                onConfirm={() => removeItemFromPunctualEvent(record)}
            >
                <a
                    style={{
                        width: "100%",
                        margin: "0 auto",
                        padding: "0",
                    }}
                    href="/"
                    className="qa-text-del"
                >
                    Eliminar
                </a>
            </Popconfirm>
        ),
    },
];

const columnsMensalTable = (removeItemFromPunctualEvent, onChange) => [
    {
        title: "Mês",
        dataIndex: "mes",
        width: "25%",
        render: (text, record) => (
            <Select
                allowClear
                defaultValue={text}
                style={{ width: 120 }}
                onChange={(value) => onChange(value, record.key, "mes")}
            >
                {[
                    { id: 1, name: "Janeiro" },
                    { id: 2, name: "Fevereiro" },
                    { id: 3, name: "Março" },
                    { id: 4, name: "Abril" },
                    { id: 5, name: "Maio" },
                    { id: 6, name: "Junho" },
                    { id: 7, name: "Julho" },
                    { id: 8, name: "Agosto" },
                    { id: 9, name: "Setembro" },
                    { id: 10, name: "Outubro" },
                    { id: 11, name: "Novembro" },
                    { id: 12, name: "Dezembro" },
                ].map((item, index) => (
                    <Option key={item.id} value={item.id}>
                        {item.name}
                    </Option>
                ))}
            </Select>
        ),
    },
    {
        title: "Dia",
        dataIndex: "dia",
        width: "20%",
        render: (text, record) => (
            <Select
                allowClear
                defaultValue={text}
                style={{ width: 120 }}
                onChange={(value) => onChange(value, record.key, "dia")}
            >
                {(() => {
                    const options = [];
                    for (let index = 1; index <= 31; index++) {
                        options.push(
                            <Option key={index} value={index}>
                                {index}
                            </Option>
                        );
                    }
                    return options;
                })()}
            </Select>
        ),
    },
    {
        title: "Início",
        dataIndex: "inicio",
        width: "20%",
        render: (text, record) => (
            <Input type="time" defaultValue={text} onChange={(e) => onChange(e.target.value, record.key, "inicio")} />
        ),
    },
    {
        title: "Fim",
        dataIndex: "fim",
        width: "20%",
        render: (text, record) => (
            <Input type="time" value={text} onChange={(e) => onChange(e.target.value, record.key, "fim")} />
        ),
    },
    {
        title: "Ação",
        dataIndex: "actions",
        width: "15%",
        render: (text, record) => (
            <Popconfirm
                title="Tens certeza que desejas eliminar?"
                onConfirm={() => removeItemFromPunctualEvent(record)}
            >
                <a
                    style={{
                        width: "100%",
                        margin: "0 auto",
                        padding: "0",
                    }}
                    href="/"
                    className="qa-text-del"
                >
                    Eliminar
                </a>
            </Popconfirm>
        ),
    },
];

const ScheduleEvent = (props) => {
    const [type, setType] = useState("pontual");
    const [pontualEventos, setPontualEventos] = useState({
        pontual: [{ key: 1, data: null, inicio: null, fim: null }],
        diaria: {},
        semanal: [{ key: 1, dia: null, inicio: null, fim: null }],
        mensal: [{ key: 1, mes: null, dia: null, inicio: null, fim: null }],
        limit: {
            numero_ocorrencia: null,
            start: null,
            end_date: null,
            tipo_validade: "0",
        },
        repetir: 1,
    });

    useEffect(() => {
        if (Object.keys(props.defaultValues).length > 0) {
            let attr = props.defaultValues["@attributes"];
            //
            setType(attr.tipo);
            const _pontualEventos = {
                ...pontualEventos,
                repetir: attr.repetir,
                limit: {
                    ...pontualEventos.limit,
                    numero_ocorrencia: attr.num_ocorr,
                    start: attr.periodo_ini,
                    end_date: attr.periodo_fim,
                    tipo_validade: attr.tipo_validade,
                },
            };

            let linhas = props.defaultValues["linhas"];
            if (attr.tipo === "diaria") {
                _pontualEventos.diaria = {
                    inicio: linhas.linha.hora_inicio,
                    fim: linhas.linha.hora_fim,
                };
            } else {
                linhas = Array.isArray(linhas.linha) ? linhas.linha : [linhas.linha];
                if (linhas.length > 0) {
                    _pontualEventos[attr.tipo] = linhas;
                }
            }

            setPontualEventos(_pontualEventos);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValues]);

    const removeItemFromPunctualEvent = (line) => {
        const newPontual = [];
        pontualEventos[type].forEach((item) => {
            if (item.key !== line.key) {
                newPontual.push(item);
            }
        });
        setPontualEventos({
            ...pontualEventos,
            [type]: newPontual,
        });
    };

    const onChange = (value, key, field) => {
        //
        let newPontual;
        if (type === "pontual" || type === "semanal" || type === "mensal") {
            newPontual = [];
            pontualEventos[type].forEach((item) => {
                if (item.key === key) {
                    item[field] = value;
                }
                newPontual.push(item);
            });
        } else {
            newPontual = pontualEventos[type];
            newPontual[field] = value;
        }

        setPontualEventos({
            ...pontualEventos,
            [type]: newPontual,
        });
    };

    const onChangeLimit = (value, name) => {
        setPontualEventos({
            ...pontualEventos,
            limit: {
                ...pontualEventos.limit,
                [name]: value,
            },
        });
    };

    const onChangeLimitType = (e) => {
        setPontualEventos({
            ...pontualEventos,
            limit: {
                ...pontualEventos.limit,
                tipo_validade: e.target.value,
            },
        });
    };

    const callback = (key) => {
        setType(key);
    };

    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    };

    const renderMoreOptions = () => (
        <Row style={{ paddingLeft: 10, paddingBottom: 10 }}>
            <Col span={24}>
                <label htmlFor="">Tem início em</label>
                <br />
                <Input
                    type="date"
                    style={{ marginLeft: 5, width: "50%" }}
                    defaultValue={
                        pontualEventos.limit.start
                            ? moment(pontualEventos.limit.start, "YYYY-MM-DD").format("YYYY-MM-DD")
                            : null
                    }
                    onChange={(e) =>
                        onChangeLimit(e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "", "start")
                    }
                />
            </Col>
            <Col span={24} style={{ marginTop: 15 }}>
                <label htmlFor="">Termina no dia</label>
                <br />
                <Radio.Group
                    defaultValue={pontualEventos.limit.tipo_validade}
                    onChange={onChangeLimitType}
                    value={pontualEventos.limit.tipo_validade}
                >
                    <Radio style={radioStyle} value="0">
                        Nunca
                    </Radio>
                    <Radio style={{ ...radioStyle, marginTop: 8 }} value="1">
                        Após
                        <InputNumber
                            disabled={pontualEventos.limit.tipo_validade !== "1"}
                            defaultValue={
                                pontualEventos.limit.numero_ocorrencia ? pontualEventos.limit.numero_ocorrencia : null
                            }
                            min={1}
                            style={{
                                width: 100,
                                marginLeft: 5,
                                marginRight: 5,
                            }}
                            onChange={(value) => onChangeLimit(value, "numero_ocorrencia")}
                        />
                        ocorrências
                    </Radio>
                    <Radio style={{ ...radioStyle, marginTop: 8 }} value="2">
                        No dia
                        <Input
                            type="date"
                            style={{ marginLeft: 5 }}
                            defaultValue={
                                pontualEventos.limit.end_date
                                    ? moment(pontualEventos.limit.end_date, "YYYY-MM-DD").format("YYYY-MM-DD")
                                    : null
                            }
                            disabled={pontualEventos.limit.tipo_validade !== "2"}
                            onChange={(e) =>
                                onChangeLimit(
                                    e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                    "end_date"
                                )
                            }
                        />
                    </Radio>
                </Radio.Group>
            </Col>
        </Row>
    );

    return (
        <Modal
            title="Repetição do Evento"
            visible={props.visible}
            onOk={() => {
                const data = {
                    tipo: type,
                    periodo_ini: pontualEventos.limit.start,
                    periodo_fim: pontualEventos.limit.end_date,
                    tipo_validade: pontualEventos.limit.tipo_validade,
                    num_ocorr: pontualEventos.limit.numero_ocorrencia,
                    repetir: pontualEventos.repetir,
                    linhas: pontualEventos[type],
                };
                props.onOk(data);
            }}
            onCancel={props.onCancel}
            width="80%"
            centered
            destroyOnClose
        >
            <Tabs defaultActiveKey={type} onChange={callback}>
                <TabPane tab="Pontual" key="pontual">
                    <Row gutter={16} align="middle" type="flex" style={{ width: "60%" }}>
                        <Col xs={24}>
                            <Button
                                xs={24}
                                sm={12}
                                onClick={() => {
                                    const index = Math.random() * 500;
                                    setPontualEventos({
                                        ...pontualEventos,
                                        [type]: [
                                            ...pontualEventos[type],
                                            {
                                                key: index,
                                                data: null,
                                                inicio: null,
                                                fim: null,
                                            },
                                        ],
                                    });
                                }}
                                icon="plus"
                                size="small"
                                type="primary"
                                style={{ marginBottom: 16, float: "right" }}
                            >
                                Adicionar
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        rowKey="key"
                        style={{ width: "60%" }}
                        // rowSelection={rowSelection}
                        dataSource={pontualEventos["pontual"]}
                        columns={columnsPontualTable(removeItemFromPunctualEvent, onChange)}
                        size="small"
                        pagination={false}
                        scroll={{ y: 300 }}
                    />
                </TabPane>
                <TabPane tab="Diário" key="diaria">
                    <Row>
                        <Col span={14}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <label htmlFor="">Início:</label>
                                    <br />
                                    <Input
                                        type="time"
                                        defaultValue={
                                            pontualEventos.diaria.inicio
                                                ? moment(pontualEventos.diaria.inicio, "HH:mm:ss").format("HH:mm")
                                                : null
                                        }
                                        onChange={(e) => onChange(e.target.value, null, "inicio")}
                                    />
                                </Col>
                                <Col span={12}>
                                    <label htmlFor="">Fim:</label>
                                    <br />
                                    <Input
                                        type="time"
                                        defaultValue={
                                            pontualEventos.diaria.fim
                                                ? moment(pontualEventos.diaria.fim, "HH:mm:ss").format("HH:mm")
                                                : null
                                        }
                                        onChange={(e) => onChange(e.target.value, null, "fim")}
                                    />
                                </Col>
                                <Col span={24} style={{ marginTop: 20 }}>
                                    <Row>
                                        <Col span={6}>
                                            <label htmlFor="">Repetir a cada x dias:</label>
                                            <br />
                                            <InputNumber
                                                defaultValue={pontualEventos.repetir ? pontualEventos.repetir : null}
                                                min={1}
                                                onChange={(value) =>
                                                    setPontualEventos({
                                                        ...pontualEventos,
                                                        repetir: value,
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col span={2}>
                                            <Tooltip title="Colocar 1 para repetir todos os dias, colocar 2 par repetir de 2 em 2 dias e assim sucessivamente.">
                                                <Icon type="question-circle" style={{ color: "#FF5733" }} />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={10}>{renderMoreOptions()}</Col>
                    </Row>
                </TabPane>
                <TabPane tab="Semanal" key="semanal">
                    <Row>
                        <Col span={14}>
                            <Row gutter={16} align="middle" type="flex">
                                <Col xs={24}>
                                    <Button
                                        xs={24}
                                        sm={12}
                                        onClick={() => {
                                            const index = Math.random() * 500;
                                            setPontualEventos({
                                                ...pontualEventos,
                                                [type]: [
                                                    ...pontualEventos[type],
                                                    {
                                                        key: index,
                                                        dia: null,
                                                        inicio: null,
                                                        fim: null,
                                                    },
                                                ],
                                            });
                                        }}
                                        icon="plus"
                                        size="small"
                                        type="primary"
                                        style={{
                                            marginBottom: 16,
                                            float: "right",
                                        }}
                                    >
                                        Adicionar
                                    </Button>
                                </Col>
                            </Row>
                            <Table
                                rowKey="key"
                                dataSource={pontualEventos["semanal"]}
                                columns={columnsSemanalTable(removeItemFromPunctualEvent, onChange)}
                                size="small"
                                pagination={false}
                                scroll={{ y: 300 }}
                            />
                            <Row style={{ marginTop: 10 }}>
                                <Col span={6}>
                                    <label htmlFor="">Repetir a cada x semanas:</label>
                                    <br />
                                    <InputNumber
                                        defaultValue={pontualEventos.repetir ? pontualEventos.repetir : null}
                                        min={1}
                                        onChange={(value) => {
                                            setPontualEventos({
                                                ...pontualEventos,
                                                repetir: value,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="Colocar 1 para repetir todos as semanas, colocar 2 par repetir de 2 em 2 semanas e assim sucessivamente.">
                                        <Icon type="question-circle" style={{ color: "#FF5733" }} />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={10}>{renderMoreOptions()}</Col>
                    </Row>
                </TabPane>
                <TabPane tab="Mensal" key="mensal">
                    <Row>
                        <Col span={14}>
                            <Row gutter={16} align="middle" type="flex">
                                <Col xs={24}>
                                    <Button
                                        xs={24}
                                        sm={12}
                                        onClick={() => {
                                            const index = Math.random() * 500;
                                            setPontualEventos({
                                                ...pontualEventos,
                                                [type]: [
                                                    ...pontualEventos[type],
                                                    {
                                                        key: index,
                                                        mes: null,
                                                        dia: null,
                                                        inicio: null,
                                                        fim: null,
                                                    },
                                                ],
                                            });
                                        }}
                                        icon="plus"
                                        size="small"
                                        type="primary"
                                        style={{
                                            marginBottom: 16,
                                            float: "right",
                                        }}
                                    >
                                        Adicionar
                                    </Button>
                                </Col>
                            </Row>
                            <Table
                                rowKey="key"
                                // rowSelection={rowSelection}
                                dataSource={pontualEventos["mensal"]}
                                columns={columnsMensalTable(removeItemFromPunctualEvent, onChange)}
                                size="small"
                                pagination={false}
                                scroll={{ y: 300 }}
                            />
                        </Col>
                        <Col span={10}>{renderMoreOptions()}</Col>
                    </Row>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default ScheduleEvent;
