import React from "react";
import {
    FormInputCalcTriggerProps,
    FormInputsObjectProps,
    FormInputsProps,
    FormRootElementAttributesProps,
    RenderContextType,
} from "../../../types/formTypes";
import { ComponentRender } from "../Components/ComponentRender";
import { Group } from "../Components/Group";
import { useInputHandle } from "../hooks/InputHandle";

interface ComponentsPropertiesProps {
    componentsProperties: FormInputsProps;
    renderContext: RenderContextType;
    formState: FormInputsObjectProps;
    inputCalcTrigger: FormInputCalcTriggerProps;
    justSee?: boolean;
    rootElementAttribute: FormRootElementAttributesProps;
}

export function QAInputGroup({
    componentsProperties,
    renderContext,
    formState,
    inputCalcTrigger,
    justSee,
    rootElementAttribute,
}: ComponentsPropertiesProps) {
    const TransformColumns = () => {
        let fields: FormInputsProps[] = [];

        const { visible, relevant } = useInputHandle({
            componentProperties: componentsProperties,
            formState,
            calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        });

        if (!visible || !relevant) {
            return null;
        }

        for (const itemProp in componentsProperties) {
            if (
                typeof componentsProperties[itemProp] === "object" &&
                itemProp !== "dataSource" &&
                !Array.isArray(componentsProperties[itemProp]) &&
                !componentsProperties[itemProp]?.parent &&
                (!componentsProperties[itemProp]?.visible || componentsProperties[itemProp]?.visivble !== "nao")
            ) {
                fields.push(componentsProperties[itemProp]);
            }
        }

        return (
            <Group componentsProperties={componentsProperties} renderContext={renderContext}>
                {fields.map((fieldItem) => {
                    // --- in case fieldItem has other component in it
                    if (fieldItem.type && (!fieldItem?.visible || fieldItem?.visible !== "nao")) {
                        return (
                            <ComponentRender
                                key={"component_input_group" + fieldItem.key}
                                renderContext="inputGroup"
                                componentsProperties={{ ...fieldItem }}
                                justSee={justSee}
                                rootElementAttribute={rootElementAttribute}

                                // item={fieldItem}
                                // tabName={tabName}
                                // value={itemData.value}
                                // itemKey={itemData.key}
                                // form={form}
                            />
                        );
                    }
                    // // --- in case column has no other component in it
                    else return null;
                })}
            </Group>
        );

        // return fields.map((fieldItem) => {
        //     // --- in case fieldItem has other component in it
        //     if (fieldItem.type && (!fieldItem?.visible || fieldItem?.visible !== "nao")) {
        //         return (
        //             <ComponentRender
        //                 key={"component_input_group" + fieldItem.key}
        //                 renderContext="inputGroup"
        //                 componentsProperties={{ ...fieldItem }}
        //                 // item={fieldItem}
        //                 // tabName={tabName}
        //                 // value={itemData.value}
        //                 // itemKey={itemData.key}
        //                 // form={form}
        //             />
        //         );
        //     }
        //     // // --- in case column has no other component in it
        //     else return null;
        // });
    };

    return <>{TransformColumns()}</>;
}
