import React, { useEffect } from "react";
import { Row, Col, Form, Input, Button } from "antd";

const { TextArea } = Input;

const RunSQL = (props) => {
    useEffect(() => {
        props.configModal({
            title: "Visualizador SQL",
            width: "60%",
            top: "10%",
        });
    });

    return (
        <Form layout="vertical">
            <Row gutter={18}>
                <Col xs={24} md={10}>
                    <Form.Item label="Base de dados">
                        <Input placeholder="Introduza o endereço da BD" />
                    </Form.Item>
                </Col>

                <Col xs={24} md={10}>
                    <Form.Item label="Porta">
                        <Input placeholder="Introduza a porta" />
                    </Form.Item>
                </Col>

                <Col xs={24} md={4}>
                    <Form.Item label="&nbsp;">
                        <Button style={{ width: "100%" }} type="primary">
                            Testar
                        </Button>
                    </Form.Item>
                </Col>

                <Col xs={24} md={20}>
                    <Form.Item>
                        <TextArea rows={5} placeholder="Introduza o codigo SQL" />
                    </Form.Item>
                </Col>

                <Col xs={24} md={4}>
                    <Button type="primary" style={{ width: "100%" }}>
                        Correr
                    </Button>
                </Col>

                <Col xs={24} md={24}>
                    <Form.Item>
                        <TextArea disabled rows={5} placeholder="Resultado" />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default RunSQL;
