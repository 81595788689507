import { useState } from "react";
import { mergeRecursive } from "../../modules/process_execution/constants/utils";

export const useQAState = (initialValue) => {
    const [state, setState] = useState(initialValue);

    let current = state;

    const get = () => current;

    const set = (newValue) => {
        let newStateValues;
        // const typr = typeof newValue;
        // if (typeof newState == "function") {
        //     newStateValues = newValue(current);
        // } else {
        //     newStateValues = newValue;
        // }

        current = newValue;
        setState((prev) => mergeRecursive(prev, newValue));
        return current;
    };

    return {
        get,
        set,
        state,
    };
};
