import {
    Alert,
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Switch,
    Table,
    TimePicker,
    Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Utilitaries } from "../../../../../business";
import moment from "moment";
import { api_fetch } from "../../../../utils/HttpRequest/request";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/pt";
import { func } from "prop-types";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const WEEK_DAYS = [
    { label: "Domingo", value: 0 },
    { label: "Segunda", value: 1 },
    { label: "Terça", value: 2 },
    { label: "Quarta", value: 3 },
    { label: "Quinta", value: 4 },
    { label: "Sexta", value: 5 },
    { label: "Sabado", value: 6 },
];

interface IProps {
    isVisible: boolean;
    handleClose: (isCancel: boolean, id?: string, length?: number) => void;
    id: string;
    username: string;
    name: string;
    workDays: number[];
    record: any;
    // functionalGroups: any[];
}

export function DaysModal({ isVisible, handleClose, id, name, record, username, workDays }: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<any>({
        selectedDays: [],
    });
    const [disabledDays, setDisabledDays] = useState(WEEK_DAYS.map((day) => day.value));
    const [disabledHours, setDisabledHours] = useState<any>([]);
    const [selectStartHour, setSelectedStartHour] = useState<number | null>(null);
    const [disabledMinutes, setDisabledMinutes] = useState<any>([]);

    const [weekendActive, setWeekendActive] = useState<0 | 1>(0);

    useEffect(() => {
        if (id) {
            getForm(id);
        }

        if (workDays.length > 0) {
            setDisabledDays(WEEK_DAYS.filter((day) => !workDays.includes(day.value)).map((item) => item.value));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleActivateWeekend(checked: boolean, _list?: number[]) {
        const days = _list ? _list : disabledDays;
        const list = new Set(days);
        if (checked) {
            list.delete(0);
            list.delete(6);
            const newList = [...list];
            setDisabledDays(newList);
        } else {
            const newList = WEEK_DAYS.filter((day) => !workDays.includes(day.value)).map((item) => item.value);

            setDisabledDays(newList);
        }
    }

    function workInWeekend() {
        return workDays.includes(0) && workDays.includes(6);
    }

    async function getForm(id: string) {
        setIsLoading(true);

        const resp = await api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "colaboradores_faltas_dias",
                id,
            },
        });
        if (resp.status === "SUCCESS") {
            if (resp.response?.result?.[0]) {
                const { dias_mes, observacoes, hora_inicio, hora_fim, total_horas } = resp.response.result[0];

                let days = [];
                days = dias_mes.split(" ");
                const dates: Date[] = days.map((item: any) => new Date(record.ano, Number(record.mes) - 1, item));

                setFormData({
                    selectedDays: dates,
                    observacoes,
                    hora_inicio,
                    hora_fim,
                    total_horas,
                    dia_todo: Number(hora_inicio) !== 0 ? 0 : 1,
                });

                // check if weekend is active
                const weekend = dates.filter((item) => item.getDay() === 0 || item.getDay() === 6);

                if (weekend.length > 0) {
                    handleActivateWeekend(
                        true,
                        WEEK_DAYS.filter((day) => !workDays.includes(day.value)).map((item) => item.value)
                    );
                    setWeekendActive(weekend.length > 0 ? 1 : 0);
                }
            }
        }
        setIsLoading(false);
    }

    async function handleOk() {
        const days = formData.selectedDays.map((item: any) => moment(item).format("D"));

        let data: any = {
            faltas_colaborador_dias: {
                username: username,
                colaborador: name,
                dias_mes: days.join(" "),
                observacoes: Utilitaries.toString(formData.observacoes),
                hora_inicio: formData.hora_inicio,
                hora_fim: formData.hora_fim,
                total_horas: formData.total_horas,
            },
        };
        const { faltas_colaborador_dias } = record;

        if (faltas_colaborador_dias && faltas_colaborador_dias !== "" && typeof faltas_colaborador_dias !== "object") {
            data.faltas_colaborador_dias["id_faltas_colaborador_dias"] = faltas_colaborador_dias;
        }

        setIsLoading(true);

        const resp = await api_fetch({
            endPoint: "/Gestor/gereprocesso.php",
            method: "POST",
            params: {
                accao: "guardar",
                formulario: "qa_ficha_colaboradodias_pop.xml",
                dados: JSON.stringify(data),
            },
        });

        setIsLoading(false);

        if (resp.status === "SUCCESS") {
            message.success("Sucesso!");
            const { sucesso } = resp.response;
            record["faltas_colaborador_dias"] = sucesso;
            record["numero_dias"] = days.length;
            record["observacoes"] = formData.observacoes;
            record["horas_mascara"] = formData.total_horas;

            if (Utilitaries.isEmpty(record["tipo_falta"])) {
                record["tipo_falta"] = "FC";
            }
            if (formData.total_horas) {
                record["numero_horas"] = Number(formData.total_horas?.split(":")?.join(","));
            }
            handleClose(false, record);
        } else {
            message.error("Erro ao guardar departamentos!");
        }

        // let result = "";
        // try {
        //     setIsLoading(true);
        //     result = await this.props.postDataToGereProcesso({
        //         accao: "guardar",
        //         formulario: "qa_ficha_colaboradodias_pop.xml",
        //         dados: JSON.stringify(data),
        //     });
        // } catch (error) {
        //     this.setState({ loading: false });
        //     message.error("Erro ao guardar dias de faltas!!");
        // } finally {
        //     if (result.sucesso) {
        //         message.success("Sucesso");
        //         const { record } = this.props;
        //         record["faltas_colaborador_dias"] = result.sucesso;
        //         record["numero_dias"] = days.length;
        //         record["observacoes"] = this.state.observacoes;
        //         record["horas_mascara"] = this.state.total_horas;
        //         record["tipo_falta"] = "FC";
        //         if (this.state.total_horas) {
        //             record["numero_horas"] = Number(this.state.total_horas?.split(":")?.join(","));
        //         }
        //         this.props.hiddeDaysModal(record);
        //     }
        // }

        // let empty_row = false;

        // let fcb_departamentos: any = {
        //     fcb_lista_departamentos: {
        //         fcb_lista_departamento: dataSource.map((item) => {
        //             if (!item.valencia) {
        //                 empty_row = true;
        //             }
        //             delete item.id_fcb_lista_departamento;
        //             return item;
        //         }),
        //     },
        // };

        // if (!empty_row) {
        //     setIsLoading(true);

        //     const resp = await api_fetch({
        //         endPoint: "/Gestor/gereprocesso.php",
        //         method: "POST",
        //         params: {
        //             accao: "guardar",
        //             formulario: "qa_ficha_colaborador_departamentos.qml.xml",
        //             dados: JSON.stringify({ fcb_departamentos }),
        //         },
        //     });

        //     setIsLoading(false);
        //     if (resp.status === "SUCCESS") {
        //         message.success("Sucesso!");
        //         const { sucesso } = resp.response;
        //         handleClose(false, sucesso, dataSource.length);
        //     } else {
        //         message.error("Erro ao guardar departamentos!");
        //     }
        // } else message.warning("Linhas vazios tem de ser preenchidas ou removidas!!");
    }

    function handleCancel() {
        handleClose(true);
    }

    function onChange(dataIndex: string, value: any) {
        setFormData({ ...formData, [dataIndex]: value });
    }

    function handleDayClick(day: any, { selected }: any) {
        const { selectedDays } = formData;
        if (selected) {
            const selectedIndex = selectedDays.findIndex((selectedDay: any) => DateUtils.isSameDay(selectedDay, day));
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        setFormData({
            ...formData,
            selectedDays: [...selectedDays],
        });
    }

    const calcTotalHours = () => {
        const { hora_inicio, hora_fim } = formData;

        if (hora_inicio && hora_fim) {
            let hoursStart = Number(hora_inicio.match(/^(\d+)/)[1]);
            let minutesStart = Number(hora_inicio.match(/:(\d+)/)[1]);

            const date1 = new Date();
            date1.setHours(hoursStart);
            date1.setMinutes(minutesStart);

            const hoursEnd = Number(hora_fim.match(/^(\d+)/)[1]);
            const minutesEnd = Number(hora_fim.match(/:(\d+)/)[1]);

            const date2 = new Date();
            date2.setHours(hoursEnd);
            date2.setMinutes(minutesEnd);

            var diff = date2.getTime() - date1.getTime();

            var hours = Math.floor(diff / (1000 * 60 * 60));
            diff -= hours * (1000 * 60 * 60);

            var mins = Math.floor(diff / (1000 * 60));
            // diff -= mins * (1000 * 60);
            // alert(hours + " hours : " + mins + " minutes : ");

            if (hours < 0) {
                message.error("Hora de início não pode ser maior que hora de fim!");
                setFormData({ ...formData, hora_fim: "", total_horas: "" });
            } else {
                const total_horas = `${hours}:${mins}`;
                setFormData({ ...formData, total_horas });
            }
        }
    };

    const handleDisabledHours = (hour: any, minute: any) => {
        const len = Number(hour);

        const hours: any[] = [];

        for (let i = 0; i < len; i++) {
            if (i < 10) hours.push(i);
            else hours.push(i);
        }
        setDisabledHours(hours);
        setSelectedStartHour(len);

        const minutes: number[] = [];
        for (let i = 0; i < Number(minute); i++) {
            minutes.push(i);
        }
        setDisabledMinutes(minutes);
    };

    const handleHoraInicioChange = (time: any, timeString: any) => {
        const _time = timeString?.split(":");

        handleDisabledHours(_time["0"], _time["1"]);
        setFormData({
            ...formData,
            hora_inicio: timeString,
        });
    };

    return (
        <Modal
            centered
            width="70%"
            visible={isVisible}
            title="Dias correspondentes"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    voltar
                </Button>,
                <Button
                    icon="save"
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={handleOk}
                    // disabled={dataSource.length ? false : true}
                >
                    Guardar
                </Button>,
            ]}
        >
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Row>
                        <Col xs={24}>
                            {!workDays.length && (
                                <Alert
                                    message="Preencher dias de trabalhos no tab Informação Pessoal para ver dias de trabalhos aqui no calendário!"
                                    type="info"
                                />
                            )}

                            <DayPicker
                                localeUtils={MomentLocaleUtils}
                                selectedDays={formData.selectedDays}
                                onDayClick={handleDayClick}
                                locale="pt"
                                canChangeMonth={false}
                                initialMonth={new Date(record.ano, Number(record.mes) - 1, 11)}
                                disabledDays={{
                                    daysOfWeek: disabledDays as any,
                                }}
                            />
                        </Col>
                        {!workInWeekend() && (
                            <Col xs={24}>
                                <Text>
                                    <Radio.Group
                                        buttonStyle="solid"
                                        value={weekendActive}
                                        onChange={(e) => {
                                            setWeekendActive(e.target.value);
                                            handleActivateWeekend(e.target.value === 1);
                                        }}
                                    >
                                        <Radio.Button value={0}>Apenas dias de trabalho</Radio.Button>
                                        <Radio.Button value={1}>Ativar os finais de semana</Radio.Button>
                                    </Radio.Group>
                                    {/* Ativar os finais de semana */}
                                    {/* {hasEnabledWeekend() ? "Apenas dias de trabalho: " : "Ativar os finais de semana"}
                                    <Switch
                                        style={{ marginLeft: "8px" }}
                                        defaultChecked={false}
                                        onChange={handleActivateWeekend}
                                    /> */}
                                </Text>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col xs={24} md={12}>
                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col xs={24}>
                                <Form.Item label="Colaborador">
                                    <Input disabled defaultValue={name} />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item label="Observações">
                                    <TextArea
                                        allowClear
                                        value={formData.observacoes}
                                        onChange={(e) => onChange("observacoes", e.target.value)}
                                        rows={3}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item label="Dia todo?">
                                    <Radio.Group
                                        buttonStyle="solid"
                                        value={formData.dia_todo}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                dia_todo: e.target.value,
                                                total_horas: e.target.value === 1 ? "8:00" : "",
                                            })
                                        }
                                    >
                                        <Radio.Button value={1}>Sim</Radio.Button>
                                        <Radio.Button value={0}>Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Hora inicio">
                                    <TimePicker
                                        onOpenChange={calcTotalHours}
                                        disabled={formData.dia_todo === 1 ? true : false}
                                        onChange={handleHoraInicioChange}
                                        format={"HH:mm"}
                                        value={formData.hora_inicio ? moment(formData.hora_inicio, "HH:mm") : undefined}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Hora fim">
                                    <TimePicker
                                        disabledHours={() => disabledHours}
                                        disabledMinutes={(hour) => (hour === selectStartHour ? disabledMinutes : [])}
                                        onOpenChange={calcTotalHours}
                                        disabled={formData.dia_todo === 1 ? true : false}
                                        onChange={(time, timeString) => onChange("hora_fim", timeString)}
                                        value={formData.hora_fim ? moment(formData.hora_fim, "HH:mm") : undefined}
                                        format={"HH:mm"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item label="Total horas">
                                    <Input disabled value={formData.total_horas} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
}
