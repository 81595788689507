import React, { useEffect, useState } from "react";
import { Tabs, PageHeader, Form, Row, Col, Button, Popconfirm, message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

import DocumentTabPane from "./document.tab";
import PermissionsTabPane from "./permissions.tab";
import ProcessTabPane from "./process.tab";
import TasksAssignmentTabPane from "./taskassignment.tab";

import { FORM_INITIAL_INFO, getReportsInitialData, saveDocument, cancelDocument } from "../../actions";

const { TabPane } = Tabs;

const ModelsReportsEdit = ({ form }) => {
    const { id } = useParams();
    const history = useHistory();

    const [initialFormData, setInitialFormData] = useState(FORM_INITIAL_INFO);
    const [formState, setFormState] = useState({
        file: null,
        authorizations: [],
        process: [],
    });
    const [isEdit, setIsEdit] = useState(false);
    const [toReview, setToReview] = useState(false);

    useEffect(() => {
        async function getData() {
            const data = await getReportsInitialData(id);
            setInitialFormData(data);
            setFormState({
                ...formState,
                authorizations: data.documentsAuthorizations,
                process: data.process,
            });
            if (data.report.id_controlo_documento) {
                setIsEdit(true);
            }
        }
        getData();
    }, [id]);

    const saveData = () => {
        form.validateFields((err, values) => {
            if (!err) {
                values = {
                    ...values,
                    ficheiro: formState.file,
                    autorizacoes: formState.authorizations,
                    processos: formState.process,
                    report: values.nome || initialFormData.report.nome || "",
                };
                if (toReview) {
                    values.rever = initialFormData.report.id_controlo_documento;
                }

                if (id) {
                    values.id_controlo_documento = id;
                }

                saveDocument(values).then(() => {
                    history.push({
                        pathname: "/models/reports",
                    });
                });
            } else {
                Object.values(err).forEach((er) => {
                    message.warn(`${er.errors[0].field}: ${er.errors[0].message}`);
                });
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        saveData();
    };

    const anular = () => {
        cancelDocument(initialFormData.report.id_controlo_documento).then(() => {
            message.success("Sucesso");
            history.push({
                pathname: "/models/reports",
            });
        });
    };

    const { getFieldDecorator } = form;

    return (
        <div>
            <PageHeader
                onBack={() => {
                    history.push({
                        pathname: "/models/reports",
                    });
                }}
                title="Consulta Relatório"
            />
            <Form layout="vertical" onSubmit={handleSubmit}>
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        {toReview && (
                            <>
                                <Button type="primary" onClick={() => saveData()}>
                                    Guardar Revisão
                                </Button>
                                <Button type="danger" onClick={() => setToReview(false)} style={{ marginLeft: 8 }}>
                                    Fechar Revisão
                                </Button>
                            </>
                        )}
                        {isEdit && !toReview && (
                            <>
                                <Button onClick={() => setToReview(true)}>Rever</Button>
                                <Popconfirm
                                    title="Tem certeza que deseja anular?"
                                    onConfirm={anular}
                                    okText="Sim"
                                    cancelText="Não"
                                >
                                    <Button type="danger" style={{ marginLeft: 8 }}>
                                        Anular
                                    </Button>
                                </Popconfirm>
                            </>
                        )}
                        {!toReview && (
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
                                Guardar
                            </Button>
                        )}
                    </Col>
                </Row>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Modelo Relatório" key="1">
                        <DocumentTabPane
                            initialFormData={initialFormData}
                            formState={formState}
                            setFormState={setFormState}
                            getFieldDecorator={getFieldDecorator}
                            enableField={!!id}
                            toReview={toReview}
                        />
                    </TabPane>
                    <TabPane tab="Permissões" key="2">
                        <PermissionsTabPane
                            initialFormData={initialFormData}
                            formState={formState}
                            setFormState={setFormState}
                        />
                    </TabPane>
                    <TabPane tab="Processos" key="3">
                        <ProcessTabPane
                            initialFormData={initialFormData}
                            formState={formState}
                            setFormState={setFormState}
                        />
                    </TabPane>
                    <TabPane tab="Atribuição Tarefas" key="4">
                        <TasksAssignmentTabPane
                            initialFormData={initialFormData}
                            getFieldDecorator={getFieldDecorator}
                            enableField={!!id}
                            toReview={toReview}
                        />
                    </TabPane>
                </Tabs>
            </Form>
        </div>
    );
};

const WrappedPersonalInfoTabPane = Form.create({ name: "report" })(ModelsReportsEdit);

export default WrappedPersonalInfoTabPane;
