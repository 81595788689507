import React from "react";
import { Divider, Button, Form, Input, Select, Checkbox, Row, Col, Table, Icon, Popconfirm } from "antd";
import FormModal from "./modal";
import { componentToRender } from "../../../../constants/index";

const { Option } = Select;

function onChange(value) {}

function onSearch(val) {}

function onCheckboxChange(e) {}

const headOptions = [
    "Unidade Orgânica",
    "Unidade Orgânica Nome",
    "Estabelecimento",
    "Estabelecimento Nome",
    "Cliente",
    "Cliente Nome",
];

class NormalModeForm extends React.Component {
    state = {
        modal: {
            isVisible: false,
            componentToRender: "",
        },
        checkedList: [],
        indeterminate: true,
        checkAll: false,
        propertieFieldDataSource: [
            {
                key: 0,
                type: "normal",
                name: "Nome",
                cEntity: "",
                placeHolder: "Introduza o nome",
                cRepeat: "",
                propertie: "",
            },
            {
                key: 1,
                type: "normal",
                name: "Apelido",
                placeHolder: "Introduza o apelido",
                cEntity: "",
                cRepeat: "",
                propertie: "",
            },
        ],
    };

    componentDidMount() {
        this.props.configModal({
            title: "Novo Formulário",
            width: "90%",
            top: 20,
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.isFormModalVisible === true) this.showFormModal(newProps.componentToRender);
    }

    showFormModal = (component) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                componentToRender: component,
                isVisible: true,
            },
        });
    };

    handleOk = (e) => {
        this.setState({
            modal: {
                ...this.state.modal,
                isVisible: false,
            },
        });
        this.props.cancelFormModal();
    };

    handleCancel = (e) => {
        this.setState({
            modal: {
                ...this.state.modal,
                isVisible: false,
            },
        });
        this.props.cancelFormModal();
    };

    addPropertieField = () => {
        let newPropertie = {
            key: this.state.propertieFieldDataSource[this.state.propertieFieldDataSource.length - 1].key + 1,
            type: "normal",
            name: "Teste",
            cEntity: "",
            cRepeat: "",
            placeHolder: "Introduza o valor",
            propertie: "",
        };

        this.setState({
            ...this.state,
            propertieFieldDataSource: [...this.state.propertieFieldDataSource, newPropertie],
        });
    };

    removePropertieField = (key) => {
        this.setState({
            ...this.state,
            propertieFieldDataSource: this.state.propertieFieldDataSource.filter((item) => item.key !== key),
        });
    };

    render() {
        const propertieFieldColumns = [
            {
                title: "Tipo de Campo",
                dataIndex: "type",
                width: "40%",
                render: (text, record) => (
                    <Select
                        showSearch
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Selecione um tipo"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        defaultValue="normal"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="normal">Normal</Option>
                        <Option value="conjunto">Conjunto</Option>
                        <Option value="cliente">Cliente</Option>
                        <Option value="entidade">Entidade</Option>
                    </Select>
                ),
            },
            {
                title: "Nome do Campo",
                width: "40%",
                dataIndex: "name",
                render: (text) => <Input name="name" placeholder="Introduza o nome" defaultValue={text} />,
            },
            {
                width: "20%",
                title: "Ações",
                render: (text, record) => {
                    return (
                        <span>
                            <a
                                href="javascript:;"
                                onClick={(e) => {
                                    this.showFormModal(componentToRender.fieldProperties);
                                }}
                            >
                                Configurar
                            </a>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="Tens certeza que desejas eliminar?"
                                onConfirm={() => this.removePropertieField(record.key)}
                            >
                                <a
                                    href="javascript:;"
                                    // className="qa-text-del"
                                    style={{ color: "red" }}
                                >
                                    Eliminar
                                </a>
                            </Popconfirm>
                        </span>
                    );
                },
            },
        ];

        return (
            <Form layout="vertical">
                <Row gutter={18}>
                    <Col xs={24} md={6}>
                        <Form.Item label="Código">
                            <Input placeholder="Introduza o código" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                        <Form.Item label="Versão">
                            <Input placeholder="" disabled defaultValue="" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                        <Form.Item label="Revisão">
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Selecione a revisão"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="constrution">Em Construção</Option>
                                <Option value="revision">Em Revisão</Option>
                                <Option value="checked">Verificado</Option>
                                <Option value="aproved">Aprovado</Option>
                                <Option value="canceled">Anulado</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                        <Form.Item label="Modo">
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Altere o modo"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="jack">Normal</Option>
                                <Option value="lucy">Avançado</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Divider orientation="left" style={{ color: "#1890ff" }}>
                        Dados Formulário
                    </Divider>

                    <Col xs={24} md={6}>
                        <Form.Item label="Título Formulário">
                            <Input placeholder="Introduza o título" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                        <Form.Item label="Nome Principal">
                            <Input placeholder="Introduza o nome principal" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="Tipo Formulário">
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Selecione o tipo"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="jack">Processo</Option>
                                <Option value="lucy">Popup</Option>
                                <Option value="tom">Menu</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Divider orientation="left" style={{ color: "#1890ff" }}>
                        Dados Cabeçalho
                    </Divider>

                    <Col xs={24}>
                        {headOptions.map((headOption) => (
                            <Checkbox style={{ marginTop: 5 }} onChange={onCheckboxChange}>
                                {headOption}
                            </Checkbox>
                        ))}
                    </Col>
                </Row>

                <Divider style={{ color: "#1890ff" }} orientation="left">
                    Campos
                </Divider>

                <Row>
                    <Button
                        onClick={this.addPropertieField}
                        type="primary"
                        style={{ float: "right", marginBottom: 16 }}
                    >
                        <Icon type="plus" />
                        Inserir
                    </Button>
                </Row>
                <Table
                    // scroll={{ x: true }}
                    rowClassName={() => "editable-row"}
                    dataSource={this.state.propertieFieldDataSource}
                    columns={propertieFieldColumns}
                    size="default"
                />

                <FormModal
                    componentToRender={this.state.modal.componentToRender}
                    visible={this.state.modal.isVisible}
                    data={this.state.propertieFieldDataSource}
                    handleOk={this.handleOk.bind(this)}
                    handleCancel={this.handleCancel.bind(this)}
                />
            </Form>
        );
    }
}

export default NormalModeForm;
