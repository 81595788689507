import { Button, Icon, Input, Table, Tag, Tooltip } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import { Utilitaries } from "../../../business";

interface IProps {
    filter: any;
    dataSource: any;
    loadingData: boolean;
    setPagination: (pagination: any) => void;
    pagination: any;
    setFilter: (filter: any) => void;
    history: any;
}
export function CDRTable({ filter, dataSource, loadingData, setFilter, setPagination, pagination, history }: IProps) {
    //---------------------------- filter --------------------------------
    const getColumnSearchProps = (dataIndex: string) => {
        let searchInput = React.createRef<any>();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys}
                        onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
            ),
            filteredValue: filter[`filtro_${dataIndex}`] || null,
        };
    };

    const handleSearch = (selectedKeys: any, confirm: any) => {
        confirm();
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
    };

    const getColumnSortProps = (dataIndex: any, sortedInfo: any) => ({
        sorter: true,
        sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
    });

    const handleTableChange = (_pagination: any, filters: any, sorter: any) => {
        const obj: any = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPagination({
            ...pagination,
            page: 1,
        });

        setFilter(obj);
    };
    //---------------------------- endfilter -----------------------------

    const goToDetails = async (processId: any, proc_pai: any, taskId: any, taskFormParams: any) => {
        // const processes = processState?.domain?.byId;
        // if (!processes || !processes[processId] || !processes[processId].tasks[taskId]) {
        //     await dispatch(getTask({ processId, taskId, isInit: true }));
        // }

        const handlerBeforeTask = (proc_pai: any) => {
            if (proc_pai === 0 || proc_pai === "0" || typeof taskId === "object") {
                return "";
            }

            return proc_pai;
        };

        let search = `?taskId=${taskId}&routeContext=documents_records&processParentId=${handlerBeforeTask(proc_pai)}`;

        if (!Utilitaries.isEmpty(taskFormParams)) {
            const urlParams = JSON.parse(taskFormParams);
            search = Object.keys(urlParams).reduce((acumulator, current) => {
                acumulator += `&${current}=${urlParams[current]}`;
                return acumulator;
            }, search);
        }

        history.push({
            pathname: `/task/execution/map/${processId}`,
            search,
        });
    };
    const sortedInfo = filter.sorter || {};

    const columns = [
        {
            title: "Código",
            dataIndex: "codigo_externo",
            ...getColumnSearchProps("codigo_externo"),
            ...getColumnSortProps("codigo_externo", sortedInfo),
            render: (text: string, record: any) => {
                if (typeof text === "object") return null;
                else {
                    if (record.estado >= 0) {
                        return <Tag color={"blue"}>{text}</Tag>;
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={"Anulado"}>
                                <Tag color={"red"}>{text}</Tag>
                            </Tooltip>
                        );
                    }
                }
            },
        },
        {
            title: "Processo",
            dataIndex: "t_nome_processo",
            ...getColumnSearchProps("t_nome_processo"),
            ...getColumnSortProps("t_nome_processo", sortedInfo),
        },
        {
            title: "Tarefa",
            dataIndex: "nome_tarefa",
            ...getColumnSearchProps("nome_tarefa"),
            ...getColumnSortProps("nome_tarefa", sortedInfo),
        },
        {
            title: "Informação adicional",
            dataIndex: "informacao_adicional",
            ...getColumnSearchProps("informacao_adicional"),
            ...getColumnSortProps("informacao_adicional", sortedInfo),
        },
        {
            title: "Nº processo",
            dataIndex: "processo",
            ...getColumnSearchProps("processo"),
            ...getColumnSortProps("processo", sortedInfo),
        },
        {
            title: "Nº tarefa",
            dataIndex: "tarefa",
            ...getColumnSearchProps("tarefa"),
            ...getColumnSortProps("tarfa", sortedInfo),
        },
        {
            title: "Início execução",
            dataIndex: "inicio",
            ...getColumnSearchProps("inicio"),
            ...getColumnSortProps("inicio", sortedInfo),
        },
        {
            title: "Fim execução",
            dataIndex: "fim",
            ...getColumnSearchProps("fim"),
            ...getColumnSortProps("fim", sortedInfo),
        },
        {
            title: "Ação",
            dataIndex: "action",
            render: (text: any, record: any) => (
                <>
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                        onClick={() =>
                            goToDetails(record.processo, record.proc_pai, record.tarefa, record?.task_form_params)
                        }
                    >
                        Consultar
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Table
            size="middle"
            rowKey={(data: any) => data.tarefa + "-table"}
            pagination={false}
            dataSource={dataSource}
            loading={loadingData}
            columns={columns}
            onChange={handleTableChange}
        />
    );
}
