import React, { useEffect, useContext, memo, useMemo } from "react";
import { sendHttpRequest } from "../../http.request";
import FormContext from "../context/FormContext";
import { input_validations, getAllDependencies } from "../utils/validations";
import { getElementAction, addDoubleQuotesToParams, hasInputCalculate } from "../utils/functions";
import { FormUtilitaries, Utilitaries } from "../../../business";
// import { getElementAction, addDoubleQuotesToParams, hasInputAction } from "../utils/functions";

// import { input_validations, regex_match, getAllDependencies } from "../utils/validations";

// Usados pela funcao eval nao tirar daqui
// eslint-disable-next-line no-unused-vars
const { round, round3 } = require("../utils/calc");

const QaEmptyComponent = ({ inputAttribute, field, value: labelValue, objectInputs, ...inputProps }) => {
    // const [dados, setDados] = useState(null);
    // const [value, setValue] = useState(null);
    const { formData, changeInputValue, isInitial } = useContext(FormContext);
    // const firstUpdate = useRef(true);

    // const hasDeps = hasInputDependence({
    //     input: inputAttribute,
    //     formData,
    //     options: inputProps,
    //     formInputs: objectInputs,
    // });
    // Get value
    let valueDep = [];
    let inputActionsName = null;

    const elementAction = getElementAction({ item: inputAttribute });
    if (elementAction) {
        valueDep = getAllDependencies(elementAction.valueDep);
        inputActionsName = elementAction.inputActionsName;
    }

    // [...new Set([...array1 ,...array2])];
    // if (field === "perda_rem_fake") ;

    useEffect(
        () => {
            const getData = async () => {
                // if (firstUpdate.current) {
                //     firstUpdate.current = false;
                //     return;
                // }
                // ;
                //
                // if (inputProps?.items?.data?.__key__ == 0) {
                //   return;
                // }

                try {
                    let expression = input_validations({
                        item: inputAttribute,
                        relKey: inputActionsName,
                        returnExpression: true,
                        formData,
                        inputProps,
                    });
                    expression = addDoubleQuotesToParams(expression);
                    let expressionValue = null;
                    try {
                        expressionValue = await eval(`${expression}`);
                    } catch (error) {}

                    if (expression.includes("auto_preenche_tudo")) {
                        changeInputValue({
                            value: "",
                            allValues: expressionValue,
                            key: field,
                            inputAttribute,
                            appLoading: true,
                            noMessage: true, // nao mostrar pop up com mesagem de campo errado
                            ...inputProps,
                        });
                        return;
                    }

                    const resultKeys =
                        expressionValue && typeof expressionValue === "object" ? Object.keys(expressionValue) : null;
                    const inputValue = resultKeys
                        ? resultKeys.length === 1
                            ? expressionValue[resultKeys[0]]
                            : expressionValue[inputAttribute["@chave_lista"]]
                        : expressionValue;

                    // if (field === "valida") {
                    //     ;
                    // }

                    changeInputValue({
                        value: FormUtilitaries.inputValueConvert(inputValue),
                        key: field,
                        inputAttribute,
                        appLoading: true,
                        noMessage: true, // nao mostrar pop up com mesagem de campo errado
                        ...inputProps,
                    });
                } catch (error) {
                    console.error(error, field, inputAttribute);
                }
            };

            if (
                !inputAttribute[inputActionsName] ||
                !formData ||
                (field === "preenche_dados" && formData?.["id_movimento_stk"] && isInitial)
                // valueDep.filter((it) => {
                //     if (inputProps.items.data[it]) return false;
                //     if (formData[it]) return false;
                //     return true;
                // }).length > 0
            ) {
                return;
            }

            getData();
        },
        //eslint(react-hooks/exhaustive-deps)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueDep
            .filter((it) => it !== field)
            .map((it) => {
                if (inputProps?.items?.data[it]) return inputProps.items.data[it];

                return formData ? formData[it] : null;
            })
    );

    const hasCalculation = useMemo(() => {
        const result = hasInputCalculate({
            input: inputAttribute,
            formData,
            options: inputProps,
            formInputs: objectInputs,
        });
        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    const paramsCalculate = hasCalculation.hasCalculation
        ? hasCalculation.isList
            ? [formData]
            : hasCalculation?.params?.map((it) => it.value)
        : [];

    useEffect(
        () => {
            if (!inputAttribute?.["@calcular"]) {
                return;
            }

            let expression = input_validations({
                item: inputAttribute,
                relKey: "@calcular",
                formData: formData,
                functions: {},
                returnExpression: true,
                inputProps,
                allInputs: objectInputs,
            });

            // expression = expression.replace(/=/g, "==");

            try {
                let _value = expression;

                if (hasCalculation.isList) {
                    _value = expression;
                } else {
                    _value = eval(expression);
                }

                changeInputValue({
                    value: Utilitaries.isEmpty(_value) ? "" : _value, // valor selecionado  in value
                    key: field, // Nome do campo
                    inputAttribute, // input atributos
                    appLoading: true,
                    ...inputProps, // propriedades da tabela
                    callback() {
                        // hasError({ [field]: _value });
                    },
                });
            } catch (error) {
                console.error(error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [Utilitaries.isEmpty(paramsCalculate) ? null : paramsCalculate.join("")]
        // // hasCalculation.hasCalculation ? ( hasCalculation?.params?.map((it) => it.value)) : []
        // // eslint-disable-next-line react-hooks/exhaustive-deps
        // hasCalculation.hasCalculation
        //     ? hasCalculation.isList
        //         ? [formData]
        //         : hasCalculation?.params?.map((it) => it.value)
        //     : []
        // // hasCalculation.hasCalculation
        // //     ? hasCalculation?.tablePath?.first
        // //         ? formData?.[hasCalculation?.tablePath?.first]?.[hasCalculation.tablePath.second] || []
        // //         : hasCalculation?.params?.map((it) => it.value)
        // //     : []
    );

    const auto_preenche_valor = async (query, ...params) => {
        const fd = new FormData();
        fd.append("query", query);
        fd.append("accao", "query");

        let nullValues = false;
        params
            .map((it) => it.replace(/[{}\s]/g, "").split(","))
            .forEach((it) => {
                // eslint-disable-next-line no-eval
                const val = eval(it[1]);

                // if (!value || val === undefined) {
                //     nullValues = true;
                // }
                if (val === undefined || (val === "" && query === "stk:dados_produto_new")) {
                    nullValues = true;
                }

                fd.append(it[0], val === "t" ? "true" : val);
            });

        if (nullValues) {
            return;
        }
        try {
            const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", fd);
            return data.result[0];
        } catch (error) {
            return null;
        }
    };

    // eslint-disable-next-line no-unused-vars
    const auto_preenche_tudo = auto_preenche_valor;
    // eslint-disable-next-line no-unused-vars
    const auto_preenche = auto_preenche_valor;

    return null;
};

export default memo(QaEmptyComponent);
