import { actionType, componentActionType } from "../constants/index";
import { actionType as detailsActionTypes } from "../constants/details";
import { arrayToObjectById, toArray } from "../../../components/util";

const initialState = {
    domain: {
        form: {
            ambito: { value: "", name: "ambito" },
            nome: { value: "", name: "nome" },
            codigo: { value: "", name: "codigo" },
            dono: { value: "", name: "dono" },
            categoria: { value: "", name: "categoria" },
            departamento: { value: "", name: "departamento" },
            processosAssociados: { value: [], name: "processosAssociados" },
            tasks: {
                1: {
                    id: 1,
                    nome: { value: "", name: "nome" },
                    descricao: { value: "", name: "descricao" },
                    allAuthorizations: [],
                    tipo_atribuicao: "",
                },
            },
            goalsIndicators: {},
        },
        byId: {},
    },
    app: { isFetched: false },
};

export const processDefinitionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.insertProcess:
            return {};

        case componentActionType.insertActionComponent:
        case componentActionType.updateActionComponent:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        3: {
                            ...state.domain.byId[3],
                            actions: {
                                ...state.domain.actions,
                                [action.payload.key]: action.payload,
                            },
                        },
                    },
                },
            };

        case actionType.setField: {
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        ...action.payload,
                    },
                },
            };
        }

        case actionType.getProcesses: {
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: action.payload,
                        id: "id",
                    }),
                },
                app: {
                    isFetched: true,
                },
            };
        }

        case actionType.getProcessDetails: {
            const { processo, processos_associados } = action.payload;
            const form = {};
            for (const key in processo["@attributes"]) {
                form[key] = { name: key, value: processo["@attributes"][key] };
            }

            const tasks = Array.isArray(action.payload.tarefas) ? action.payload.tarefas : [action.payload.tarefas];
            form.tasks = arrayToObjectById({
                array: tasks.map((item) => ({
                    ...item,
                    allAuthorizations: [],
                })),
                id: "id",
            });

            form.processosAssociados = {
                name: "processosAssociados",
                value: toArray(processos_associados?.linha).map((it) => it.nome),
            };

            return {
                ...state,
                domain: {
                    ...state.domain,
                    form,
                },
            };
        }

        case actionType.initProcessDefForm: {
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: action.payload,
                },
            };
        }

        case detailsActionTypes.setAttributionType: {
            const { taskId, authorization } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tasks: {
                            ...state.domain.form.tasks,
                            [taskId]: {
                                ...state.domain.form.tasks[taskId],
                                tipo_atribuicao: authorization,
                            },
                        },
                    },
                },
            };
        }

        case detailsActionTypes.addGoalIndicator: {
            const allKeys = Object.keys(state.domain.form.goalsIndicators || []);
            const lastKey = allKeys[allKeys.length - 1] || 0;
            const neewKey = Number(lastKey) + 1;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        goalsIndicators: {
                            ...state.domain.form.goalsIndicators,
                            [neewKey]: {
                                key: neewKey,
                                id_politica: null,
                                id_objectivo: null,
                                id_indicador: null,
                            },
                        },
                    },
                },
            };
        }

        case detailsActionTypes.removeGoalIndicator: {
            const { key } = action.payload;
            const { [key]: itemToRemove, ...dataWithoutKey } = state.domain.form.goalsIndicators;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        goalsIndicators: dataWithoutKey,
                    },
                },
            };
        }

        case detailsActionTypes.setPolicyGoalIndicator: {
            const { name, key, value } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        goalsIndicators: {
                            ...state.domain.form?.goalsIndicators,
                            [key]: {
                                ...state.domain.form?.goalsIndicators?.[key],
                                key,
                                [name]: value,
                            },
                        },
                    },
                },
            };
        }

        case detailsActionTypes.setTaskAuthorizations: {
            const { taskId, authId, exec, read, print, isToEmpty } = action.payload;
            const allAuthorizations = state.domain.form?.tasks?.[taskId]?.allAuthorizations;
            let allAuthsUpdated = [];

            if (!isToEmpty) {
                allAuthsUpdated =
                    allAuthorizations?.length > 0
                        ? allAuthorizations?.find((item) => item.authId === authId)
                            ? allAuthorizations
                            : [...allAuthorizations, { authId, exec, read, print }]
                        : [{ authId, exec, read, print }];
            }

            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tasks: {
                            ...state.domain.form.tasks,
                            [taskId]: {
                                ...state.domain.form.tasks[taskId],
                                allAuthorizations: allAuthsUpdated,
                            },
                        },
                    },
                },
            };
        }

        case detailsActionTypes.removeTaskAuthorizations: {
            const { taskId, authId } = action.payload;
            const allAuthorizations = state.domain.form?.tasks?.[taskId]?.allAuthorizations;
            let allAuthsUpdated = allAuthorizations?.filter((item) => item.authId !== authId);
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tasks: {
                            ...state.domain.form.tasks,
                            [taskId]: {
                                ...state.domain.form.tasks[taskId],
                                allAuthorizations: allAuthsUpdated,
                            },
                        },
                    },
                },
            };
        }

        case detailsActionTypes.setTaskDescription: {
            const { rowIndex, value } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tasks: {
                            ...state.domain.form.tasks,
                            [rowIndex]: {
                                ...state.domain.form.tasks[rowIndex],
                                descricao: value,
                            },
                        },
                    },
                },
            };
        }

        case detailsActionTypes.setGroupToDelegateProcessDef: {
            const { rowIndex, value } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tasks: {
                            ...state.domain.form.tasks,
                            [rowIndex]: {
                                ...state.domain.form.tasks[rowIndex],
                                grupo_delegacao: value,
                            },
                        },
                    },
                },
            };
        }

        case detailsActionTypes.setUserToDelegateProcessDef: {
            const { rowIndex, value } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tasks: {
                            ...state.domain.form.tasks,
                            [rowIndex]: {
                                ...state.domain.form.tasks[rowIndex],
                                resp_delegacao: value,
                            },
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
};
