import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as actionCreators from "../actions/index";
import { getFieldDataSource } from "../../process_execution/actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PageHeader, Pagination, Row, Col, message, Button } from "antd";
import ButtonsGroup from "./buttons.group";
import ProcessTable from "./table";
import ProcessCreate from "./create";
import "./index.css";
import ProcessFilter from "./filter";

import { Session } from "../../../business/index";
import { baseUrl } from "../../../constants/enviroment";

const ProcessIndex = ({ getFieldDataSource, getProcesses, history }) => {
    let { id } = useParams();

    const [pagination, setPagination] = useState({
        currentPage: 1,
        currentPageSize: 10,
    });
    const [filter, setfilter] = useState({});
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isRegistarModalVisible, setIsRegistarModalVisible] = useState(false);
    const [macroProcesses, setMacroProcesses] = useState([]);
    const [externalFilter, setExternalFilter] = useState({
        selectedMacroProcess: null,
        selectedProcess: null,
    });
    const [processes, setProcesses] = useState([]);
    const [tableDatasource, setTableDatasource] = useState([]);
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        getFieldDataSource({ dataSource: "chama_macro_sistema" }).then(({ result }) => {
            setMacroProcesses(result);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (externalFilter.selectedMacroProcess) {
            const params = {
                param1: externalFilter.selectedMacroProcess,
            };
            getFieldDataSource({ dataSource: "param_categorias_processo", params }).then(({ result }) => {
                setProcesses(result);
            });
        } else {
            setProcesses([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalFilter.selectedMacroProcess]);

    // Get form from API
    useEffect(() => {
        setIsTableLoading(true);
        const { sorter, ...objFilter } = filter?.[id || "root"] || {};
        const values = {
            ...objFilter,
            pagina: pagination.currentPage,
            resultados_pagina: pagination.currentPageSize,
            accao: "lista",
        };

        if (id && id !== ":id") {
            values.social_code = id;
        }

        if (externalFilter.selectedMacroProcess) values.MACRO = externalFilter.selectedMacroProcess;
        if (externalFilter.selectedProcess) values.CATEGORIA = externalFilter.selectedProcess;
        if (sorter && sorter.order) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        getProcesses(values).then((response) => {
            if (response) {
                setTableDatasource(response.lista);
                setMetadata(response.metadata);
            } else {
                message.error("Ocorreu um erro");
            }
            setIsTableLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, externalFilter, id, filter]);

    const goTo = (param, toSubProcesses = false) => {
        if (toSubProcesses) {
            const item = tableDatasource.find((it) => it.id === param);
            history.push("/process/definition/index/" + item.codigo_externo);
        } else {
            history.push("/process/details/" + param);
        }
    };

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }

        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPagination({
            ...pagination,
            currentPage: 1,
        });
        setfilter({ ...filter, [id || "root"]: obj });
    };

    const handlePageChange = (currentPage, currentPageSize) => {
        setPagination({
            currentPage,
            currentPageSize,
        });
    };
    const handleShowSizeChange = (currentPage, currentPageSize) => {
        setPagination({
            currentPage: 1,
            currentPageSize,
        });
    };

    const showRegistarModal = () => {
        setIsRegistarModalVisible(true);
    };

    const handleOkRegistarModal = (e) => {
        setIsRegistarModalVisible(false);
    };

    const handleCancelRegistarModal = (e) => {
        setIsRegistarModalVisible(false);
    };

    const changeMacroProcess = (value) => {
        setExternalFilter({ ...externalFilter, selectedMacroProcess: value, selectedProcess: null });
    };
    const changeProcess = (value) => {
        setExternalFilter({ ...externalFilter, selectedProcess: value });
    };

    const exportList = (type) => {
        try {
            let endpoint = "/Gestor/gereprocesso.php";

            let otherParams = {};

            if (filter[id]) {
                Object.entries(filter[id]).forEach(([key, val]) => {
                    otherParams[key] = val;
                });
            }

            if (filter["root"]) {
                Object.entries(filter["root"]).forEach(([key, val]) => {
                    otherParams[key] = val;
                });
            }

            const queryParams = Object.entries({
                accao: "lista",
                saida: type,
                campos: "codigo_externo:5:Código;nome_externo:15:Nome;ambito:20:Âmbito;versao:5:Versão;estado_processo:5:Estado;dono:5:Dono;",
                titulo: "Lista de Processos",
                ...otherParams,
            }).reduce((accumulator, [key, value]) => {
                accumulator = `${accumulator}${key}=${value}&`;
                return accumulator;
            }, "");

            window.open(`${baseUrl}${endpoint}?${queryParams}token=Bearer ${Session.getToken()}`, "_blank");

            // const formData = new FormData();
            // formData.append("accao", "lista");
            // formData.append("saida", type);
            // formData.append(
            //     "campos",
            //     "codigo_externo:5:Código;nome_externo:15:Nome;ambito:20:Âmbito;versao:5:Versão;estado_processo:5:Estado;dono:5:Dono;"
            // );
            // Object.entries(filter[id || "root"]).forEach(([key, val]) => {
            //     formData.append(key, val);
            // });
            // formData.append("titulo", "Lista de Processos");

            // var request = new XMLHttpRequest();
            // request.open("POST", baseUrl + "/Gestor/gereprocesso.php", true);

            // request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
            // request.responseType = "blob";

            // request.onload = function () {
            //     // Only handle status code 200
            //     if (request.status === 200) {
            //         // Try to find out the filename from the content disposition `filename` value
            //         var disposition = request.getResponseHeader("content-disposition");
            //         //
            //         var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
            //         //

            //         var filename = matches != null && matches[1] ? matches[1] : "Lista de Processos";

            //         //
            //         //     'request.getResponseHeader("content-type")',
            //         //     request.getResponseHeader("content-type")
            //         // );

            //         // The actual download
            //         var blob = new Blob([request.response], {
            //             type: request.getResponseHeader("content-type"),
            //         });

            //         var link = document.createElement("a");
            //         link.href = window.URL.createObjectURL(blob);
            //         link.download = filename;

            //         document.body.appendChild(link);

            //         link.click();

            //         document.body.removeChild(link);
            //     }

            //     // some error handling should be done here...
            // };

            // request.send(formData);
            // const formData = new FormData();
            // formData.append("lista", listName);

            // const body = await sendHttpRequest(
            //   "POST",
            //   "Gestor/gereRelatorio.php",
            //   formData,
            // );
            //
        } catch (error) {
            message.error("Ocorreu um erro");
        }
    };

    const headerBack = id ? { onBack: () => history.goBack() } : {};

    return (
        <div>
            <PageHeader title={"Lista de Processos"} {...headerBack} />
            <Row style={{ marginBottom: 5 }}>
                <Col xs={24} md={16}>
                    {!id && (
                        <ProcessFilter
                            selectedMacroProcess={externalFilter.selectedMacroProcess}
                            selectedProcess={externalFilter.selectedProcess}
                            macroProcesses={macroProcesses}
                            processes={processes}
                            changeMacroProcess={changeMacroProcess}
                            changeProcess={changeProcess}
                        />
                    )}
                </Col>
                <Col xs={24} md={8} className="qa-group-actions-right">
                    {!id && <ButtonsGroup showRegistarModal={showRegistarModal} filter={filter[id || "root"]} />}
                    <Button
                        onClick={() => exportList("pdf")}
                        icon="file-pdf"
                        title="Exportar para pdf"
                        size="default"
                        style={{
                            color: "#fa541c",
                        }}
                    />
                    <Button
                        onClick={() => exportList("xls")}
                        icon="file-excel"
                        title="Exportar para excel"
                        size="default"
                        style={{
                            color: "#237804",
                        }}
                    />
                </Col>
            </Row>
            <ProcessTable
                goTo={goTo}
                tableDatasource={tableDatasource}
                handleTableChange={handleTableChange}
                loading={isTableLoading}
                filter={filter?.[id || "root"] || {}}
            />
            <br />
            <Row>
                <Col xs={24}>
                    <Pagination
                        current={pagination.currentPage}
                        defaultPageSize={pagination.currentPageSize}
                        size="small"
                        total={metadata.resultados}
                        showSizeChanger
                        showTotal={(total) => `Total registos: ${total}`}
                        onChange={handlePageChange}
                        onShowSizeChange={handleShowSizeChange}
                        showTitle
                        showLessItems={true}
                        defaultCurrent={1}
                        style={{ float: "right" }}
                    />
                </Col>
            </Row>
            <ProcessCreate
                handleCancel={handleCancelRegistarModal}
                handleOk={handleOkRegistarModal}
                visible={isRegistarModalVisible}
            ></ProcessCreate>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...actionCreators,
            getFieldDataSource,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessIndex);
