import React, { Component } from "react";
import { Form, Row, Col, Checkbox } from "antd";

class HeaderTabPane extends Component {
    render() {
        return (
            <div>
                <Form layout="vertical">
                    <Row gutter={18}>
                        <Col xs={24} sm={12} md={8}>
                            <Checkbox>Unidade orgânica</Checkbox>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Checkbox>Estabelecimento</Checkbox>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Checkbox>Cliente</Checkbox>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Checkbox>Nome Unidade orgânica</Checkbox>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Checkbox>Nome Estabelecimento</Checkbox>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Checkbox>Nome Cliente</Checkbox>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default HeaderTabPane;
