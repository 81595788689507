import React, { Component } from "react";
import { Modal, Row, Col, Input, Badge, Button, Table, Popconfirm, Alert, message } from "antd";
import Scale from "./scale";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/dimension";
import { countScale } from "./../../actions/scale";
import EditInPlace from "./editInPlace";
import Confirm from "./../../../../components/qa_custom/confirm";

const getDefaultDimensionLabel = (number) => {
    return "Dimensão " + (number + 1);
};

const getTitleLabel = (number, etiquetas) => {
    const defaultTitle = "Dimensão " + number;
    const custom = etiquetas[number - 1];

    if (custom === defaultTitle) return defaultTitle;
    return defaultTitle + " - " + custom;
};

export class Dimensao extends Component {
    constructor(props) {
        super(props);
        const dimensionNumber = props.dimensionNumber;
        const labelDimensao = getTitleLabel(dimensionNumber, this.props.etiquetas);

        if (props.etiquetas.length === dimensionNumber) {
            const labelSubDimensao = getDefaultDimensionLabel(dimensionNumber);
            this.props.saveEtiquetas(dimensionNumber, labelSubDimensao);
        }

        this.state = {
            isModalDimensaoVisible: false,
            isVisibleScaleModal: false,
            isLoadingData: true,
            loadingCountSubdimension: false,
            dimensionNumber,
            labelDimensao,
            form: {
                dimensionId: this.props.dimension.dimensionId,
                count: 0,
                dataSource: [],
            },
            dataModalSubDimensao: {},
        };
    }

    componentDidMount() {
        this.props
            .selectDimension({
                formulario: this.props.formName,
                id: this.props.dimension.dimensionId,
                dimensionNumber: this.props.dimensionNumber,
            })
            .then((response) => {
                if (!response) this.props.handleCancel();
                else {
                    const form = {
                        ...this.state.form,
                        dataSource: response.values.list,
                        defaultDataSource: response.values.list,
                        count: response.values.list.length,
                        descricao: response.values.descricao,
                    };

                    this.setState({
                        isLoadingData: false,
                        form: { ...form },
                        formMetadata: response.formulario,
                        subDimensionform: response.formularioSubDimensao,
                        scaleForm: response.formularioEscala,
                    });
                    if (form.dimensionId) this.countSubDimension(form.dimensionId);

                    this.countScale(form.dataSource.map((item) => item.escala));
                }
            });
    }

    columns = (label) => [
        {
            title: "Designação",
            dataIndex: "designacao",
            width: "50%",
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => this.changeDesignation(e.target.value, record)}
                    disabled={this.props.readOnly}
                />
            ),
        },
        {
            title: <EditInPlace value={label} onChangeValue={this.onChangeLabel} readOnly={this.props.readOnly} />,
            dataIndex: "totalSubDimension",
            width: "20%",
            render: (text, record) => (
                <Badge dot={this.state.loadingCountSubdimension} showZero count={text}>
                    <Button
                        icon="edit"
                        onClick={() => {
                            this.showChildDimensao(record);
                        }}
                    />
                </Badge>
            ),
        },
        {
            title: "Escala",
            dataIndex: "totalScale",
            width: "10%",
            render: (text, record) => (
                <Badge dot={this.state.loadingCountScale} showZero count={text}>
                    <Button
                        icon="edit"
                        onClick={() => {
                            this.showScaleModal(record);
                        }}
                    />
                </Badge>
            ),
        },
        {
            dataIndex: "operation",
            width: "20%",
            render: (text, record) =>
                !this.props.readOnly && (
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        onConfirm={() => this.handleDelete(record.key)}
                    >
                        <Button
                            type="link"
                            style={{
                                padding: 0,
                                color: "#fa541c",
                            }}
                        >
                            Eliminar
                        </Button>
                    </Popconfirm>
                ),
        },
    ];

    countSubDimension = (id_dimensao) => {
        this.setState({ loadingCountSubdimension: true });
        this.props
            .countSubDimension({
                dimensionNumber: this.state.dimensionNumber,
                id_dimensao,
            })
            .then((result) => {
                this.setState((prevState, props) => {
                    const dataSource = [...prevState.form.dataSource];
                    return this.mapTotalDimensionInState(result, dataSource);
                });
            });
    };

    mapTotalDimensionInState = (resultTotal, dataSource) => {
        const list = dataSource.map((item) => {
            const objTotal = resultTotal.find((element) => element.id === item.subDimensionId);
            return {
                ...item,
                totalSubDimension: objTotal ? objTotal.total : 0,
            };
        });
        return {
            form: {
                ...this.state.form,
                dataSource: list,
            },
            loadingCountSubdimension: false,
        };
    };

    /** count scale */
    countScale = (ids) => {
        this.setState({ loadingCountScale: true });
        this.props
            .countScale({
                dimensionNumber: this.state.dimensionNumber,
                ids,
            })
            .then((result) => {
                this.setState((prevState, props) => {
                    const dataSource = [...prevState.form.dataSource];
                    return this.mapTotalScaleInState(result, dataSource);
                });
            });
    };

    mapTotalScaleInState = (resultTotal, dataSource) => {
        const list = dataSource.map((item) => {
            const objTotal = resultTotal.find((element) => element.id === item.escala);
            return {
                ...item,
                totalScale: objTotal ? objTotal.total : 0,
            };
        });
        return {
            form: {
                ...this.state.form,
                dataSource: list,
            },
            loadingCountScale: false,
        };
    };

    handleResponse = (response, total) => {
        if (response) {
            const obj = {
                key: this.props.dimension.key,
                dimensionId: response,
                total,
            };

            this.props.assocDimension(obj).then((status) => {
                if (status) {
                    message.success("Guardado com sucesso");
                    this.props.handleOk(obj);
                } else message.error("Ocorreu um erro");
            });
        } else message.error("Ocorreu um erro");
    };

    handleOk = () => {
        const { dimensionNumber, form } = this.state;

        this.setState({ buttonLoading: true });
        const values = {
            list: form.dataSource,
            id: this.props.dimension.dimensionId,
            descricao: form.descricao,
        };
        this.props
            .save({
                dimensionNumber,
                formulario: this.state.formMetadata,
                values,
            })
            .then((response) => this.handleResponse(response, form.dataSource.length));
    };

    /**Table */
    handleAdd = () => {
        const newData = {
            designacao: null,
            subDimensionId: null,
            escala: null,
            totalSubDimension: 0,
            totalScale: 0,
        };

        this.setState((state) => {
            const obj = {
                ...newData,
                key: state.form.count,
            };
            return {
                form: {
                    ...this.state.form,
                    dataSource: [...state.form.dataSource, obj],
                    count: state.form.count + 1,
                },
            };
        });
    };
    handleDelete = (key) => {
        const dataSource = [...this.state.form.dataSource];
        this.setState({
            form: {
                ...this.state.form,
                dataSource: dataSource.filter((item) => item.key !== key),
            },
        });
    };

    changeDesignation = (value, record) => {
        const recordToChange = { ...record };
        recordToChange.designacao = value;
        const dataSource = [...this.state.form.dataSource];

        const index = dataSource.findIndex((item) => recordToChange.key === item.key);
        dataSource[index] = recordToChange;
        this.setState({
            form: { ...this.state.form, dataSource },
        });
    };
    onChangeLabel = (value) => {
        this.props.saveEtiquetas(this.state.dimensionNumber, value);
    };

    /** Dimensao action */

    showChildDimensao = (record) => {
        const { dimensionLabels } = this.state;
        this.setState({
            isModalDimensaoVisible: true,
            dataModalSubDimensao: {
                key: record.key,
                dimensionId: record.subDimensionId,
                dimensionLabels,
                parentDesignation: record.designacao,
            },
        });
    };
    assocDimension = (obj) => {
        const { dimensionNumber, form } = this.state;
        const dataSource = [...form.defaultDataSource];
        if (dataSource.length === 0) {
            let promise = new Promise((resolve) => {
                resolve(true);
            });
            return promise;
        }
        const index = dataSource.findIndex((item) => obj.key === item.key);
        if (index < 0 || dataSource[index].subDimensionId) {
            let promise = new Promise((resolve) => {
                resolve(true);
            });
            return promise;
        }

        const list = dataSource.map((item) => ({ ...item }));
        list[index].subDimensionId = obj.dimensionId;
        const values = {
            list,
            id: this.props.dimension.dimensionId,
        };
        return this.props
            .save({
                dimensionNumber,
                formulario: this.state.formMetadata,
                values,
            })
            .then((response) => {
                this.setState((state) => {
                    return {
                        form: {
                            ...state.form,
                            defaultDataSource: list,
                        },
                    };
                });
                return response;
            });
    };

    submitChildDimension = (obj) => {
        const dataSource = [...this.state.form.dataSource];
        const index = dataSource.findIndex((item) => obj.key === item.key);
        dataSource[index].subDimensionId = obj.dimensionId;
        dataSource[index].totalSubDimension = obj.total;

        this.setState({
            isModalDimensaoVisible: false,
            form: { ...this.state.form, dataSource },
        });
    };

    cancelChildDimension = (e) => {
        this.setState({
            isModalDimensaoVisible: false,
        });
    };

    /**Scale */

    showScaleModal = (record) => {
        this.setState({
            isVisibleScaleModal: true,
            scaleDataModal: {
                key: record.key,
                id: record.escala,
                parentDesignation: record.designacao,
            },
        });
    };

    assocScale = (obj) => {
        const { dimensionNumber, form } = this.state;
        const dataSource = [...form.defaultDataSource];
        if (dataSource.length === 0) {
            let promise = new Promise((resolve) => {
                resolve(true);
            });
            return promise;
        }

        const index = dataSource.findIndex((item) => obj.key === item.key);
        if (index < 0 || dataSource[index].escala) {
            let promise = new Promise((resolve) => {
                resolve(true);
            });
            return promise;
        }

        const list = dataSource.map((item) => ({ ...item }));
        list[index].escala = obj.id;

        const values = {
            list,
            id: this.props.dimension.dimensionId,
        };
        return this.props
            .save({
                dimensionNumber,
                formulario: this.state.formMetadata,
                values,
            })
            .then((response) => {
                this.setState((state) => {
                    return {
                        form: {
                            ...state.form,
                            defaultDataSource: list,
                        },
                    };
                });
                return response;
            });
    };

    submitScale = (obj) => {
        const { defaultDataSource, dataSource } = this.state.form;
        let index = dataSource.findIndex((item) => obj.key === item.key);
        dataSource[index].escala = obj.id;
        dataSource[index].totalScale = obj.total;

        this.setState({
            isVisibleScaleModal: false,
            form: { ...this.state.form, dataSource, defaultDataSource },
        });
    };

    handleCancelScaleModal = (e) => {
        this.setState({
            isVisibleScaleModal: false,
        });
    };

    handleCancelDimension = () => {
        if (this.props.readOnly) this.props.handleCancel();
        else Confirm(this.props.handleCancel);
    };

    render() {
        const { dataSource } = this.state.form;
        const etiqueta = this.props.etiquetas[this.state.dimensionNumber];
        const propsFooter = this.props.readOnly ? { footer: null } : {};
        return (
            <Modal
                title={this.state.labelDimensao}
                visible={this.props.isModalVisible}
                width="60%"
                onOk={() => this.handleOk()}
                confirmLoading={this.state.buttonLoading}
                onCancel={this.handleCancelDimension}
                {...propsFooter}
            >
                <Row style={{ marginBottom: 16 }}>
                    <Col xs={24} md={18}>
                        <Alert message={this.props.dimension.parentDesignation} type="info" />
                    </Col>
                    <Col xs={24} md={6}>
                        {!this.props.readOnly && (
                            <Button
                                onClick={this.handleAdd}
                                disabled={this.state.isLoadingData}
                                type="primary"
                                icon="plus"
                                style={{ float: "right" }}
                            >
                                Inserir
                            </Button>
                        )}
                    </Col>
                </Row>
                <Table
                    rowClassName={() => "editable-row"}
                    // rowKey={}
                    size="middle"
                    loading={this.state.isLoadingData}
                    dataSource={dataSource}
                    columns={this.columns(etiqueta)}
                />
                {this.state.isModalDimensaoVisible && (
                    <DimensionWrapper
                        isModalVisible={this.state.isModalDimensaoVisible}
                        dimension={this.state.dataModalSubDimensao}
                        dimensionNumber={this.state.dimensionNumber + 1}
                        formName={this.state.subDimensionform}
                        assocDimension={this.assocDimension}
                        handleOk={this.submitChildDimension}
                        handleCancel={this.cancelChildDimension}
                        etiquetas={this.props.etiquetas}
                        saveEtiquetas={this.props.saveEtiquetas}
                        readOnly={this.props.readOnly}
                    />
                )}
                {this.state.isVisibleScaleModal && (
                    <Scale
                        isModalVisible={this.state.isVisibleScaleModal}
                        formName={this.state.scaleForm}
                        scale={this.state.scaleDataModal}
                        assocScale={this.assocScale}
                        handleOk={this.submitScale}
                        handleCancel={this.handleCancelScaleModal.bind(this)}
                        readOnly={this.props.readOnly}
                    />
                )}
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            selectDimension: actionCreators.selectDimension,
            save: actionCreators.save,
            countSubDimension: actionCreators.countSubDimension,
            countScale,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return null;
};

var DimensionWrapper = connect(mapStateToProps, mapDispatchToProps)(Dimensao);
export default DimensionWrapper;
