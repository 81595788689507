import React, { Component } from "react";
import { PageHeader, Divider } from "antd";
import ProcessForm from "../form";
// import ComponentIndex from "../component/index";

class ProcessAdvancedEdit extends Component {
    goTo = (route, param) => {
        let fullPath = "";
        if (param !== undefined) fullPath = route + param;
        else fullPath = route;
        this.props.history.push(fullPath);
    };

    render() {
        return (
            <>
                <PageHeader
                    onBack={() => this.goTo("/process/details/", this.props.match.params.id)}
                    title={"Elaborar Processo"}
                />
                <Divider orientation="left">Dados Gerais</Divider>
                <ProcessForm />
                <Divider orientation="left">Componentes</Divider>
                {/* <ComponentIndex goTo={this.goTo.bind(this)} /> */}
            </>
        );
    }
}

export default ProcessAdvancedEdit;
