import React, { Component } from "react";
import { Modal } from "antd";
import { Table, Button, Popconfirm, Input, Row, Col, Icon, message } from "antd";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/collective.agreement";

class LevelCategory extends Component {
    componentDidMount() {
        if (
            this.props.isEdit &&
            !this.props.category.isFetchedNivel &&
            typeof this.props.category.lista_niveis_categoria !== "object"
        ) {
            this.setState({
                isFetching: true,
            });
            this.props
                .getNiveis({
                    record: this.props.category,
                    formulario: this.props.formulario,
                })
                .then(() => {
                    const obj = this.props.listCategoria.find((item) => item.key === this.props.category.key);
                    this.setState({
                        dataSource: obj.level,
                        isFetching: false,
                    });
                });
        }
    }

    state = {
        dataSource: [...this.props.category.level],
        isFetching: false,
        isButtonLoading: false,
    };

    columns = [
        {
            title: "Nível",
            dataIndex: "nivel",
            width: "60%",
            render: (text, record) => <Input value={text} />,
        },
        {
            title: "Ordem",
            dataIndex: "n_nivel",
            width: "10%",
            render: (text) => <Input value={text} />,
        },
        {
            title: "Anos no nível",
            dataIndex: "anos_transitar_nivel",
            width: "15%",
            render: (text) => {
                text = typeof text === "object" ? "" : text;
                return <Input value={text} />;
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "15%",
            render: (text, record) =>
                this.state.dataSource.length >= 1 ? (
                    <div>
                        <Popconfirm
                            title="Tens certeza que desejas eliminar?"
                            okText="Ok"
                            cancelText="Não"
                            onConfirm={() => this.deleteLevel(record.key)}
                        >
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    color: "#fa541c",
                                }}
                            >
                                Eliminar
                            </Button>
                        </Popconfirm>
                    </div>
                ) : null,
        },
    ];

    addLevel = () => {
        const dataSource = [...this.state.dataSource];
        const length = dataSource.length;
        const newData = {
            key: length === 0 ? 1 : dataSource[length - 1].key + 1,
            nivel: null,
            n_nivel: 0,
            anos_transitar_nivel: null,
        };
        this.setState({
            dataSource: [...dataSource, newData],
        });
    };

    deleteLevel = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };

    changeLevel = (dataIndex, value, record) => {
        const recordToAlter = { ...record };
        recordToAlter[dataIndex] = value;
        const dataSource = [...this.state.dataSource];
        const index = dataSource.findIndex((item) => recordToAlter.key === item.key);
        dataSource[index] = recordToAlter;
        this.setState({ dataSource });
    };

    handleResponse = (response) => {
        this.setState({ isButtonLoading: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleCancel();
        } else message.error("Ocorreu um erro");
    };

    handleOk = () => {
        if (this.props.category.id_categoria) {
            this.setState({ isButtonLoading: true });
            const obj = {
                initialDataEdit: this.props.initialDataEdit,
                values: {
                    ...this.props.category,
                    level: [...this.state.dataSource],
                },
                formulario: this.props.formulario,
            };
            this.props.saveNiveis(obj).then((response) => {
                this.handleResponse(response);
            });
        } else
            this.props.submitLevel({
                ...this.props.category,
                level: [...this.state.dataSource],
            });
    };

    render() {
        const columns = this.columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    title: col.title,
                    onChange: (event) => this.changeLevel(col.dataIndex, event.target.value, record),
                }),
            };
        });
        return (
            <Modal
                title="Níveis"
                visible={this.props.isModalVisible}
                onOk={this.handleOk}
                onCancel={this.props.handleCancel}
                confirmLoading={this.state.isButtonLoading}
                width="50%"
                style={{ top: 10 }}
            >
                <Row gutter={16}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={10}>
                        <b>Convenção: </b> <span>{this.props.nomeConvencao}</span>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={10}>
                        <b>Categoria: </b> <span>{this.props.category.designacao}</span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Button onClick={this.addLevel} type="primary" style={{ float: "right", marginBottom: 16 }}>
                            <Icon type="plus" />
                            Inserir
                        </Button>
                    </Col>
                </Row>

                <Table
                    rowKey={(record) => record.key}
                    rowClassName={() => "editable-row"}
                    dataSource={this.state.dataSource}
                    columns={columns}
                    size="small"
                    loading={this.state.isFetching}
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    getNiveis: actionCreators.getNiveis,
    saveNiveis: actionCreators.saveNiveis,
};
const mapStateToProps = (state) => {
    return {
        listCategoria: state.collectiveAgreement.domain.formEdit.categoria,
        formulario: state.collectiveAgreement.domain.metadata.formulario,
        initialDataEdit: state.collectiveAgreement.domain.initialDataEdit,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LevelCategory);
