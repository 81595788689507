import React, { Component } from "react";
import { Modal, Upload, message, Icon, Form, Input, Row, Col } from "antd";
import { initProcessForm, insertProcess } from "../actions/index";
import { connect } from "react-redux";

const { Dragger } = Upload;

class ProcessCreate extends Component {
    state = {
        processCode: "c111",
    };

    render() {
        const that = this;
        const props = {
            name: "file",
            multiple: false,
            customRequest: ({ file, onSuccess }) => {
                setTimeout(() => {
                    onSuccess("ok");
                }, 0);
            },
            onRemove: () => {
                this.setState({});
            },
            beforeUpload: (file) => {
                this.props.insertProcess({
                    file,
                    processCode: this.state.processCode,
                });
            },
            onChange(info) {
                if (info.file.status !== "uploading") {
                }
                if (info.file.status === "done") {
                    message.success(`O ficheiro foi carregado com successo`);
                    that.props.handleOk();
                } else if (info.file.status === "error") {
                    message.error(`O carregamento do ficheiro falhou`);
                    that.props.handleCancel();
                }
            },
        };

        return (
            <Modal
                title="Inserir novo processo"
                visible={this.props.visible}
                width="40%"
                onCancel={() => this.props.handleCancel()}
                footer={<></>}
            >
                <Form layout="inline">
                    <Row>
                        <Col xs={24}>
                            <Input onChange={() => {}} style={{ width: "100%", marginBottom: 10 }}></Input>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">Clique ou largue o ficheiro.zip aqui</p>
                                <p className="ant-upload-hint">
                                    Deverá carregar o ficheiro xml de definição dum processo
                                </p>
                            </Dragger>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    initProcessForm,
    insertProcess,
};

export default connect(null, mapDispatchToProps)(ProcessCreate);
