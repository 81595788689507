import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Input, InputNumber, message, Select, Form, Col, Row, Radio, Button } from "antd";
import { connect } from "react-redux";

import ACTION_TYPE from "../../modules/dynamic_list/constants/action.type";
import { QADynamicTable } from "../qa_custom";
import { componentType } from "../../modules/process_execution/constants/index";
import { query } from "../../actions/makeHttpRequest";
import { toArray } from "../../components/util";
import { api } from "../http.request";
import { Utilitaries } from "../../business";

const { Option } = Select;
const { Group } = Radio;
const { confirm } = Modal;

const LIST_TO_GET_DATA_FROM_ANOTHER_LIST = {
    area_pag: {
        list: "politica",
        title: "Carregar da políticas",
        query: "get_politica_to_pag",
    },
    objetivo_pag: {
        list: "politica_objetivo",
        title: "Carregar dos objetivos da política",
        query: "get_politica_objetivo_to_pag",
    },
    indicador_pag: {
        list: "politica_indicador",
        title: "Carregar dos indicadores das políticas",
        query: "get_politica_indicador_to_pag",
    },
};
//area_pag
//objetivo_pag
//indicador_pag

const GereList = ({ visible, onCancel, name, listOfItems, postDynamicList, onSaveGetData, getDynamicList }) => {
    const [dataSource, setDataSource] = useState([]);
    const [listOrder, setListOrder] = useState({
        campo_ordenacao: listOfItems?.attributes?.campo_ordenacao,
        ordenacao: listOfItems?.attributes?.ordenacao,
    });
    // const [id, setId] = useState(null);
    // const [nome, setNome] = useState(null);
    // const [chave_lista, setChave_lista] = useState(null);
    const [dataToPopulateSelect, setDataToPopulateSelect] = useState({});
    const [selectsToGetData, setSelectToGetData] = useState([]);

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [selectsDataSource, setSelectsDataSource] = useState({});

    const filterFieldList = [];
    const columns = [];
    const dataIndex = {};

    useEffect(() => {
        getDynamicList({
            requestBody: {
                accao: "consultar",
                nome: name,
            },
            url: "gerelistasdinamicas.php",
            actionType: ACTION_TYPE.selectById,
        });
    }, [getDynamicList, name]);

    // let dataToPopulateSelect = [];
    useEffect(() => {
        if (listOfItems) {
            const dataSourceIni = [];
            if (listOfItems.items) {
                if (Array.isArray(listOfItems.items)) {
                    const filteredListOfItems = listOfItems.items.reduce((accumulator, current) => {
                        let newItem = {};
                        for (const key in current) {
                            if (typeof current[key] !== "object") {
                                newItem = {
                                    ...newItem,
                                    [key]: current[key],
                                };
                            }
                        }
                        if (Object.keys(newItem).length) {
                            accumulator.push(newItem);
                        }
                        return accumulator;
                    }, []);
                    filteredListOfItems.forEach((item, index) => {
                        dataSourceIni.push({
                            __key__: index,
                            ...item,
                        });
                    });
                } else if (Object.values(listOfItems.items).length > 0) {
                    let newItem = {};
                    for (const key in listOfItems.items) {
                        if (typeof listOfItems.items[key] !== "object") {
                            newItem = {
                                ...newItem,
                                [key]: listOfItems.items[key],
                            };
                        }
                    }

                    dataSourceIni.push({ __key__: 0, ...newItem });
                }
            }
            setDataSource(dataSourceIni);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const setGereListaData = async () => {
            let keys = [];
            if (listOfItems?.estrutura) {
                keys = Object.keys(listOfItems?.estrutura) || [];
            }

            let stateData = {};

            for await (const el of keys) {
                let nome = "";
                let chave_lista = "";
                let elKey = null;

                let listOfData = [];
                if (Array.isArray(listOfItems?.sublista) && listOfItems?.sublista?.[0]?.["@attributes"]) {
                    listOfItems.sublista.forEach((item) => {
                        if (item?.["@attributes"]?.["nome"] === el) {
                            if (item?.linha) {
                                nome = item?.["@attributes"]?.["nome"];
                                chave_lista = item?.["@attributes"]?.["campo_ordenacao"];

                                elKey = el;
                                // setDataToPopulateSelect({ [nome]: item.linha });
                                listOfData = toArray(item.linha);
                                // dataToPopulateSelect = item.linha;
                            }
                        }
                    });
                } else if (typeof listOfItems?.sublista === "object") {
                    if (listOfItems?.sublista?.["@attributes"]?.["nome"] === el) {
                        nome = listOfItems?.sublista?.["@attributes"]?.["nome"];
                        chave_lista = listOfItems?.sublista?.["@attributes"]?.["campo_ordenacao"];
                        elKey = el;
                        listOfData = toArray(listOfItems?.sublista?.linha);
                        // setDataToPopulateSelect({ [nome]: listOfItems?.sublista?.linha });
                        // dataToPopulateSelect = listOfItems?.sublista?.linha;
                    }
                }
                if (chave_lista && !chave_lista.startsWith("id_")) {
                    if (el === "pais" && (listOfItems.nome === "distrito" || name === "distrito")) {
                        chave_lista = `id${el}`;
                    } else {
                        chave_lista = `id_${el}`;
                    }
                }

                if (nome && !["_original", "ativo", "activo"].includes(nome)) {
                    if (elKey) {
                        let items = toArray(listOfItems?.items)
                            .map((item) => item[elKey])
                            .filter((it) => listOfData.find((itm) => itm[chave_lista] === it) === undefined);

                        items = items.reduce(
                            (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
                            []
                        );

                        if (items.length) {
                            const response = await getDynamicList({
                                requestBody: {
                                    nome,
                                    chave_lista,
                                    id: [...items],
                                },
                                url: "execListaDinamica.php",
                            });

                            if (response.responseType === "Sucess") {
                                const data = toArray(response.dados).map((it) => {
                                    it.designacao = `${it.designacao} - (Eliminado)`;

                                    return it;
                                });
                                // setDataToPopulateSelect({
                                //     ...dataToPopulateSelect,
                                //     [nome]: [
                                //         // ...(dataToPopulateSelect[nome] ? dataToPopulateSelect[nome] : []),
                                //         ...listOfData,
                                //         ...data,
                                //     ],
                                // });
                                listOfData = [...listOfData, ...data];
                            }
                        }
                        // else {
                        //     setDataToPopulateSelect({ ...dataToPopulateSelect, [nome]: listOfData });
                        // }
                    }
                    //  else {
                    //     setDataToPopulateSelect({ ...dataToPopulateSelect, [nome]: listOfData });
                    // }

                    stateData[nome] = listOfData;
                }
            }

            setDataToPopulateSelect(stateData);
        };

        setGereListaData();
    }, [getDynamicList, listOfItems, name]);

    useEffect(() => {
        const listOfQueries = Object.keys(listOfItems?.estrutura || {})
            ?.filter((item) => {
                let optionsType = listOfItems?.estrutura[item]?.["@attributes"]?.["tipo"];

                if (optionsType?.startsWith("query_")) {
                    return true;
                }

                return false;
            })
            .map((key) => ({
                query: listOfItems.estrutura[key]?.["@attributes"]?.["tipo"].replace("query_", ""),
                key,
            }));

        setSelectToGetData(listOfQueries);
    }, [listOfItems.estrutura]);

    useEffect(() => {
        const getData = async () => {
            const selectsData = {};
            for await (const query of selectsToGetData) {
                try {
                    const formData = new FormData();
                    formData.append("query", query.query);
                    const data = await api(`/Gestor/execQuery.php`, formData);
                    let result = undefined;
                    if (
                        query.key === "funcao_agregadora" ||
                        query.key === "objetivo" ||
                        query.key === "objectivo_cao"
                    ) {
                        result = toArray(data.result).map((item) => ({
                            ...item,
                            [query.key]:
                                item["id_politica_objetivo"] ||
                                item["id_funcao_agregadora"] ||
                                item["id_objectivo_cao"],
                        }));
                    }
                    selectsData[query.key] = toArray(result || data.result);
                } catch (error) {
                    continue;
                }
            }
            setSelectsDataSource(selectsData);
        };

        if (selectsToGetData.length > 0) {
            getData();
        }
    }, [selectsToGetData]);

    const getItemDescription = (line = {}) => {
        if (listOfItems.nome === "concelho") {
            return "descricao";
        }

        if (name === "freguesia") {
            return "descricao";
        }

        if (line && line?.descricao && line?.designacao) {
            return "designacao";
        }

        if (line && line?.descricao) {
            return "descricao";
        }

        return "designacao";
    };

    const getItemValueKey = (el = null, item = null) => {
        if (listOfItems.nome === "concelho") {
            return "id_dist_ilhas";
        }

        if (name === "freguesia") {
            return "id_distconc";
        }

        if (name === "categorias") {
            return "id_ambitos";
        }

        if (name === "categorias_cp") {
            return "id_ambitos_cp";
        }

        if (name === "categorias_fc") {
            return "id_ambitos_fc";
        }

        if (name === "gr_indicadores") {
            return "id_gr_objetivos";
        }

        if (item !== null) {
            const keys = Object.keys(item).sort();
            const key = keys.find((el) => el.startsWith("id"));
            if (key) return key;
        }

        return "id";
    };

    if (listOfItems?.estrutura) {
        const keys = Object.keys(listOfItems.estrutura);

        const keyNotRender = ["cod_dist_ilhas", "id_distconc", "cod_conc", "_original", "ativo", "cod_freg"];

        keys.forEach((el) => {
            if (
                (!el.includes("id_") && el !== "idpais" && !keyNotRender.includes(el)) ||
                (el === "id_dist_ilhas" && name === "concelho") ||
                (el === "id_distconc" && name === "freguesia")
            ) {
                dataIndex[el] = "";
                filterFieldList.push(el);

                let optionsType = listOfItems.estrutura[el]?.["@attributes"]?.["tipo"];

                if (optionsType?.startsWith("query_")) {
                    optionsType = componentType.combobox;
                }

                /** check if selected have dependecie to filter it data */
                let needFilter = "";
                const elementStrutura = Object.entries(listOfItems.estrutura);
                if (dataToPopulateSelect?.[el]?.length) {
                    needFilter = Object?.keys(dataToPopulateSelect?.[el]?.[0]).find((item) => {
                        return elementStrutura.find(
                            ([key, value]) => item === key && value["@attributes"]["tipo"] === componentType.combobox
                        );
                    });
                }
                switch (optionsType) {
                    case componentType.combobox:
                        columns.push({
                            title: el.toPascalCase(true),
                            dataIndex: el,
                            render: (text, record) => {
                                text = typeof text !== "object" ? text : null;

                                const maxVersion = Math.max(
                                    ...toArray(dataToPopulateSelect[el]).map((it) => parseInt(it.versao))
                                );

                                /** check if selected have dependecie to filter it data */
                                let defaultValue = "";
                                let filteredData = [];
                                if (!!needFilter) {
                                    filteredData = [...dataToPopulateSelect[el]].filter(
                                        (item) => item[needFilter] === record[needFilter]
                                    );
                                    if (selectsDataSource?.[el]) {
                                        filteredData = [...filteredData, ...selectsDataSource[el]];
                                    }
                                } else {
                                    filteredData = dataToPopulateSelect[el] || selectsDataSource[el] || [];
                                }
                                defaultValue = filteredData?.find(
                                    (item) =>
                                        text ===
                                        (item[`id_${el}`] ||
                                            item[el] ||
                                            item[`id${el}`] ||
                                            item["id_dist_ilhas"] ||
                                            item[getItemValueKey(el, item)])
                                );
                                if (!!needFilter && !defaultValue && text) {
                                    onChange(el, null, record);
                                }

                                // onChange(el, value, record)

                                console.log({
                                    dataToPopulateSelect: dataToPopulateSelect[el],
                                    selectsDataSource: selectsDataSource[el],
                                    getItemDescription: getItemDescription(),
                                    getItemKey: getItemValueKey(),
                                });

                                return (
                                    <Select
                                        style={{
                                            width: "100%",
                                            margin: "0 auto",
                                            padding: "0",
                                        }}
                                        showSearch
                                        allowClear
                                        dropdownMatchSelectWidth={false}
                                        disabled={!Utilitaries.isEmpty(record[`id_${name}`])}
                                        value={defaultValue ? text : undefined}
                                        optionFilterProp="children"
                                        onChange={(value) => onChange(el, value, record)}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {toArray(dataToPopulateSelect[el]).map((item, index) => {
                                            if (!!needFilter) {
                                                if (item[needFilter] === record[needFilter]) {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            disabled={parseInt(item.versao) !== maxVersion}
                                                            value={
                                                                item[`id_${el}`] ||
                                                                item[el] ||
                                                                item[`id${el}`] ||
                                                                item[getItemValueKey(el, item)]
                                                            }
                                                        >
                                                            {item[getItemDescription(item)]}
                                                        </Option>
                                                    );
                                                }
                                            } else {
                                                return (
                                                    <Option
                                                        key={index}
                                                        disabled={parseInt(item.versao) !== maxVersion}
                                                        value={
                                                            item[`id_${el}`] ||
                                                            item[el] ||
                                                            item[`id${el}`] ||
                                                            item[getItemValueKey(el, item)]
                                                        }
                                                    >
                                                        {item[getItemDescription(item)]}
                                                    </Option>
                                                );
                                            }
                                        })}

                                        {toArray(selectsDataSource[el]).map((item, index) => {
                                            return (
                                                <Option
                                                    key={`sub_${index}`}
                                                    value={
                                                        item[`id_${el}`] ||
                                                        item[el] ||
                                                        item[`id${el}`] ||
                                                        item["id_dist_ilhas"] ||
                                                        item[getItemValueKey(el, item)]
                                                    }
                                                >
                                                    {item?.[getItemDescription(item)] || item?.[el]}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                );
                            },
                        });
                        break;

                    case componentType.boolean:
                    case componentType.bool:
                        columns.push({
                            title: el.toPascalCase(true),
                            dataIndex: el,
                            render: (text, record) => {
                                return (
                                    <Group
                                        onChange={(e) => onChange(el, e.target.value, record)}
                                        defaultValue={text}
                                        disabled={!Utilitaries.isEmpty(record[`id_${name}`]) && el !== "activo"}
                                    >
                                        <Radio value={"t"}>Sim</Radio>
                                        <Radio value={"f"}>Não</Radio>
                                    </Group>
                                );
                            },
                        });
                        break;
                    case componentType.int:
                        columns.push({
                            title: el.toPascalCase(true),
                            dataIndex: el,
                            render: (text, record) => {
                                text = typeof text !== "object" ? text : null;
                                return (
                                    <InputNumber
                                        onChange={(e) => onChange(el, e, record)}
                                        defaultValue={text}
                                        disabled={!Utilitaries.isEmpty(record[`id_${name}`])}
                                    />
                                );
                            },
                        });
                        break;

                    default:
                        // if (el === "ativo") {
                        //     columns.push({
                        //         title: el.toPascalCase(true),
                        //         dataIndex: el,
                        //         render: (text, record) => {
                        //             text = typeof text !== "object" ? text : null;
                        //             return (
                        //                 <Group
                        //                     onChange={(e) => onChange(el, e.target.value, record)}
                        //                     value={text || "t"}
                        //                 >
                        //                     <Radio value="t">Sim</Radio>
                        //                     <Radio value="f">Não</Radio>
                        //                 </Group>
                        //             );
                        //         },
                        //     });
                        // } else {
                        columns.push({
                            title: el.toPascalCase(true),
                            dataIndex: el,
                            render: (text, record) => {
                                text = typeof text !== "object" ? text : null;
                                return (
                                    <Input
                                        onChange={(e) => onChange(el, e.target.value, record)}
                                        defaultValue={text}
                                        disabled={!Utilitaries.isEmpty(record[`id_${name}`])}
                                        placeholder="Introduza a designação"
                                    />
                                );
                            },
                        });
                        // }
                        break;
                }
            }
        });
    }

    const onChange = (dataIndex, value, record) => {
        const newState = [...dataSource];
        record[dataIndex] = value;
        const index = newState.findIndex((item) => item.__key__ === record.__key__);
        newState.splice(index, 1, record);
        // newState[index] = record;
        setDataSource([...newState]);
    };

    const addRowToTable = () => {
        let size = dataSource.length > 0 ? Math.max(...dataSource.map((item) => Number(item.__key__))) + 1 : 0;
        setDataSource([{ __key__: size, ...dataIndex, activo: "t" }, ...dataSource]);
    };

    const removeRowFromTable = (selectedRowKeys) => {
        const newDataSource = [];
        let hasItemToNoRemove = false;
        dataSource.forEach((item) => {
            if (!selectedRowKeys.includes(item.__key__)) {
                newDataSource.push(item);
            } else {
                if (item[`id_${name}`]) {
                    newDataSource.push(item);
                    hasItemToNoRemove = true;
                }
            }
        });
        if (hasItemToNoRemove) {
            message.warn("Não podes remover itens já salvos. Tens de inativá-lo caso desejas remover.");
        }
        setDataSource(newDataSource);
    };

    const handlerSave = async () => {
        setConfirmLoading(true);
        const orderData = {};

        if (listOrder.campo_ordenacao) {
            orderData.campo_ordenacao = listOrder.campo_ordenacao;
        }

        if (listOrder.ordenacao) {
            orderData.ordenacao = listOrder.ordenacao;
        }

        orderData.versao = listOfItems?.attributes?.versao;

        let data = toArray(dataSource);

        const dataKeys = data.length === 0 ? [] : Object.keys(listOfItems.estrutura).filter((item) => item !== "ativo");

        const sortedKeys = dataKeys.sort();

        data = dataSource.map((it) => {
            let current = {};
            if (!it._original) {
                it._original = 0;
            }

            sortedKeys.forEach((item) => {
                if (dataKeys.includes(item) || item === "_original") {
                    if (item === "_original") {
                        current[item] = it[item];
                    } else {
                        current[item] = it[item] || "";
                    }
                }
            });
            return current;
        });

        const response = await postDynamicList({
            accao: "guardar",
            dados: JSON.stringify({
                [name]: {
                    ...orderData,
                    linha: data,
                },
            }),
        });

        setConfirmLoading(false);
        if (response) {
            message.success("Guardado com sucesso");
            onSaveGetData({ force: true });
            onCancel();
        } else {
            message.error("Ocorreu um erro");
        }
    };

    const changePosition = ({ up = true, selectedRowKeys, onSuccess }) => {
        const _dataSource = [...dataSource];
        selectedRowKeys.forEach((index) => {
            if (up) {
                if (index === 0) {
                    return;
                }
                const current = _dataSource[index];
                const aux = _dataSource[index - 1];

                _dataSource[index - 1] = current;
                _dataSource[index] = aux;
            } else {
                if (index + 1 === dataSource.length) {
                    return;
                }
                const current = _dataSource[index];
                const aux = _dataSource[index + 1];
                _dataSource[index + 1] = current;
                _dataSource[index] = aux;
            }
        });
        setDataSource(_dataSource);
        onSuccess(up);
    };

    // to handleTemporarySave
    const getDataFromAnotherList = async (query) => {
        try {
            if (query) {
                setDataSource([]);
                const formData = new FormData();
                formData.append("query", query);
                const data = await api(`/Gestor/execQuery.php`, formData);
                const itens = toArray(data.result);

                setDataSource(itens.map((item, index) => ({ __key__: index, ...dataIndex, ...item })));
            }
        } catch (error) {
        } finally {
            return true;
        }
    };

    return (
        <Modal
            centered
            title={`Lista dinâmica: ${name.toPascalCase(true)}`}
            visible={visible}
            destroyOnClose
            onCancel={onCancel}
            onOk={handlerSave}
            confirmLoading={confirmLoading}
            width="80%"
        >
            <Row>
                <Col xs={8}>
                    <Form.Item label="Por">
                        <Select
                            defaultValue={listOfItems?.attributes?.campo_ordenacao}
                            showSearch
                            // allowClear
                            // placeholder=""
                            // optionFilterProp="children"
                            onChange={(value, option) => {
                                setListOrder({ campo_ordenacao: value });
                            }}
                            style={{ minWidth: "50%" }}
                        >
                            <Select.Option key="defeito" value={`id_${name}`}>
                                Defeito
                            </Select.Option>
                            {filterFieldList.map((item, idx) => (
                                <Select.Option key={item} value={item}>
                                    {item.toPascalCase(true)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={8}>
                    <Form.Item label="Forma">
                        <Select
                            defaultValue={listOfItems?.attributes?.ordenacao}
                            showSearch
                            // allowClear
                            // placeholder=""
                            // optionFilterProp="children"
                            onChange={(value, option) => {
                                setListOrder({ ordenacao: value });
                            }}
                            style={{ minWidth: "50%" }}
                        >
                            <Select.Option key={"ASC"} value={"ASC"}>
                                Ascendente
                            </Select.Option>
                            <Select.Option key={"DESC"} value={"DESC"}>
                                Descendente
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={8}>
                    {LIST_TO_GET_DATA_FROM_ANOTHER_LIST[name] && (
                        <Button
                            onClick={() =>
                                confirm({
                                    title: "Isso irá limpar todos os dados. Deseja continuar?",
                                    content: "",
                                    okText: "Sim",
                                    cancelText: "Não",
                                    onOk() {
                                        return getDataFromAnotherList(LIST_TO_GET_DATA_FROM_ANOTHER_LIST[name]?.query);
                                    },
                                    onCancel() {},
                                })
                            }
                        >
                            {LIST_TO_GET_DATA_FROM_ANOTHER_LIST[name]?.title}
                        </Button>
                    )}
                </Col>
            </Row>

            <QADynamicTable
                data={dataSource}
                columns={columns}
                addRowToTable={addRowToTable}
                onRemoveItem={removeRowFromTable}
                changePosition={changePosition}
            />
        </Modal>
    );
};

GereList.proptype = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSaveGetData: PropTypes.func,
    name: PropTypes.string,
    listOfItems: PropTypes.object,
};

const mapDispatchToProps = {
    getDynamicList: query,
    // postDynamicList: insert,
};

export default connect(null, mapDispatchToProps)(GereList);
