import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Button, InputNumber, Select } from "antd";
import { getDecision, getProcess, getTasks } from "./../../../document_record_consult/actions/index";
import moment from "moment";
import { Utilitaries } from "../../../../business";
import { labelTooltip as LabelTooltip } from "../../../../components/util";

const formStyle = {
    border: "1px solid silver",
    borderRadius: "4px",
    borderStyle: "dashed",
    padding: 15,
};

const TaskPanelFilter = ({ filters, setFilters, handleFilter }) => {
    const [processList, setProcessList] = useState([]);
    const [loadingProcess, setLoadingProcess] = useState(true);
    const [processDecision, setProcessDecision] = useState([]);
    const [loadingDecision, setLoadingDecision] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [loadingTasks, setLoadingTasks] = useState(false);

    useEffect(() => {
        let mounted = true;
        getProcess().then((response) => {
            if (mounted) {
                if (response) setProcessList(response);
                setLoadingProcess(false);
            }
        });
    }, []);

    useEffect(() => {
        setLoadingTasks(true);

        const values = {};
        if (filters.processName) values.processo = filters.processName;

        getDecision(values).then((response) => {
            if (response) setProcessDecision(response);
            setLoadingDecision(false);
        });

        getTasks(values).then((response) => {
            if (response) setTasks(response);
            setLoadingTasks(false);
        });
    }, [filters.processName]);

    useEffect(() => {
        setLoadingTasks(true);

        const values = {};
        if (filters.processoDecision) values.processo = filters.processoDecision;

        getTasks(values).then((response) => {
            if (response) setTasks(response);
            setLoadingTasks(false);
        });
    }, [filters.processoDecision]);

    const handleOk = () => handleFilter({ ...filters });

    const handleClean = () => {
        setFilters({
            processName: "",
            processNumber: 0,
            taskName: "",
            taskNumber: 0,
            aditionalInfo: "",
            establishment: "",
            beginDate: "",
            endDate: "",
            order: "desc",
        });
    };

    return (
        <Form layout="vertical" style={formStyle}>
            <Row gutter={18}>
                <Col xs={24} md={6}>
                    <Form.Item label="Nome Processo">
                        <Select
                            allowClear
                            showSearch
                            loading={loadingProcess}
                            placeholder="Selecione um processo"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) =>
                                setFilters({
                                    ...filters,
                                    processName: value,
                                    processoDecision: "",
                                })
                            }
                            value={filters.processName || undefined}
                            style={{ width: "100%" }}
                        >
                            {processList.map((item) => (
                                <Select.Option value={item.nome} key={item.nome}>
                                    {item.nome}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                {!Utilitaries.isEmpty(processDecision) && (
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={
                                <LabelTooltip
                                    label="Decisão"
                                    description="Esta listagem mostra apenas os processos com decisão."
                                />
                            }
                        >
                            <Select
                                allowClear
                                showSearch
                                loading={loadingDecision}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => {
                                    setFilters({
                                        ...filters,
                                        processoDecision: value,
                                    });
                                }}
                                style={{ width: "100%" }}
                                value={filters.processoDecision || undefined}
                            >
                                {processDecision.map((item) => (
                                    <Select.Option value={item.nome} key={item.nome}>
                                        {item.nome}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                <Col xs={24} md={6}>
                    <Form.Item label="Número Processo">
                        <InputNumber
                            onChange={(value) => setFilters({ ...filters, processNumber: value })}
                            value={filters.processNumber || undefined}
                            name="processNumber"
                            style={{ width: "100%" }}
                            allowClear
                            placeholder="Introduza o número processo"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item label="Informação Adicional">
                        <Input
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    aditionalInfo: e.target.value,
                                })
                            }
                            value={filters.aditionalInfo || undefined}
                            name="aditionalInfo"
                            placeholder="Introduza a informação adicional"
                            allowClear
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item label="Nome tarefa">
                        <Select
                            allowClear
                            showSearch
                            loading={loadingTasks}
                            placeholder="Selecione uma tarefa"
                            onChange={(value) => setFilters({ ...filters, taskName: value })}
                            value={filters.taskName || undefined}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: "100%" }}
                        >
                            {tasks.map((item) => (
                                <Select.Option value={item.nome} key={item.nome}>
                                    {item.nome}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item label="Número Tarefa">
                        <InputNumber
                            onChange={(value) => setFilters({ ...filters, taskNumber: value })}
                            value={filters.taskNumber || undefined}
                            name="taskNumber"
                            style={{ width: "100%" }}
                            allowClear
                            placeholder="Introduza o número da tarefa"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item label="Data Início">
                        <Input
                            type="date"
                            style={{ width: "100%" }}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    beginDate: e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : "",
                                })
                            }
                            value={filters.beginDate || undefined}
                            name="beginDate"
                            placeholder="Introduza ou selecione uma data"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item label="Data Fim">
                        <Input
                            type="date"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                                setFilters({
                                    ...filters,
                                    endDate: value.target.value ? moment(value.target.value).format("YYYY-MM-DD") : "",
                                })
                            }
                            value={filters.endDate || undefined}
                            name="endDate"
                            placeholder="Introduza ou selecione uma data"
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                    <Form.Item label="Ordenação">
                        <Select
                            allowClear
                            showSearch
                            placeholder="Selecione uma ordenação"
                            onChange={(value) => setFilters({ ...filters, order: value })}
                            value={filters.order || undefined}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: "100%" }}
                        >
                            <Select.Option value="asc">Ascendente</Select.Option>
                            <Select.Option value="desc">Descendente</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={18}>
                <Col xs={24} md={6}>
                    <Button type="primary" onClick={handleOk}>
                        Pesquisar
                    </Button>
                    <Button style={{ marginLeft: 5 }} onClick={handleClean}>
                        limpar
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default TaskPanelFilter;
