import React, { useState, useEffect } from "react";
import { Row, Col, Select, Form, Input, Checkbox } from "antd";
import { connect } from "react-redux";

import QADynamicTable from "../../../../components/qa_custom/DynamicTable";
import { selectDataFromExecQuery } from "../../actions/funcional.group";
import { getColumnSearchProps } from "../search.into.table.columns";

const { Option } = Select;
const EXEC_QUERY_NAME = {
    habilidades_nec_grupos_func: "habilidades_nec_grupos_func",
    tipos_habilidades: "tipos_habilidades",
    habilidades: "habilidades",
    todas_habilidades: "todas_habilidades",
};

const Requirement = ({
    form,
    selectDataFromExecQuery, //from redux action
    soma_ponderacao_habilidades,
    selectedGrupoExist,
    idGrupoExist,
    set_habilidade_necessaria,
    habilidade_necessaria,
    setHabilityPonderationCalculeValue,
}) => {
    const { getFieldDecorator } = form;

    const [dataSource, setDataSource] = useState([]);
    const [habilityTypes, setHabilityTypes] = useState([]);
    const [habilities, setHabilities] = useState([]);
    const [habilityTipeId, setHabilityTypeId] = useState(undefined);
    const [sumPonderation, setSumPonderation] = useState(false);
    // const [ponderationCalculeValue, setPonderationCalculeValue] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (habilidade_necessaria.length) {
            setDataSource(habilidade_necessaria);
            setSumPonderation(!sumPonderation);
        }
    }, []);

    /**
     * do ponderation calcule
     */
    useEffect(() => {
        const sum = dataSource
            .filter((item) => item["ativo"] === "t")
            .map((item) => Number(item.ponderacao))
            .reduce((a, b) => a + b, 0);
        setHabilityPonderationCalculeValue(sum);
    }, [sumPonderation]);

    /**
     * fetch "habilidades" from group exist
     */
    useEffect(
        () => {
            // if (selectedGrupoExist || idGrupoExist) {
            (async () => {
                setLoading(true);
                // const response = await selectDataFromExecQuery({
                //     query: EXEC_QUERY_NAME.habilidades_nec_grupos_func,
                //     id_ficha_funcao: idGrupoExist || 0,
                //     sel_grupo_exist: selectedGrupoExist,
                // });
                // if (response.result) {
                //     if (Array.isArray(response.result)) {
                //         const data = [];
                //         response.result.forEach((item, index) => {
                //             data.push({ __key__: index, ...item });
                //         });
                //         await setDataSource(data);
                //         set_habilidade_necessaria(data);
                //         setSumPonderation(!sumPonderation); // forcing calculate ponderation
                //     }
                // }

                /**
                 * get all habilidades
                 */
                const req = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.todas_habilidades,
                });
                if (req.result) {
                    if (Array.isArray(req.result)) {
                        setHabilities(req.result);
                    }
                }
                setLoading(false);
            })();
            // }
        },
        [
            /*selectedGrupoExist, idGrupoExist*/
        ]
    );

    /**
     * fetch "tipos habilidades"
     */
    useEffect(() => {
        (async () => {
            const response = await selectDataFromExecQuery({
                query: EXEC_QUERY_NAME.tipos_habilidades,
            });
            if (response.result) {
                if (Array.isArray(response.result)) {
                    setHabilityTypes(response.result);
                }
            }
        })();
    }, []);

    /**
     * fetch "habilidades"
     */
    // useEffect(() => {
    //     if (habilityTipeId) {
    //         (async () => {
    //             const response = await selectDataFromExecQuery({
    //                 query: EXEC_QUERY_NAME.habilidades,
    //                 tipo_habilidade: habilityTipeId,
    //             });
    //             if (response.result) {
    //                 if (Array.isArray(response.result)) {
    //                     setHabilities(response.result);
    //                 }
    //             }
    //         })();

    //
    //     }
    // }, [habilityTipeId]);

    /**
     * add row to table
     */
    const addRowToTable = () => {
        let size = dataSource.length > 0 ? Math.max(...dataSource.map((item) => Number(item.__key__))) + 1 : 0;
        setDataSource([{ __key__: size }, ...dataSource]);
    };

    /**
     * remove row from table
     */
    const removeRowFromTable = (selectedRowKeys) => {
        const newDataSource = [];
        dataSource.forEach((item) => {
            if (!selectedRowKeys.includes(item.__key__)) newDataSource.push(item);
        });
        setDataSource(newDataSource);
        setSumPonderation(!sumPonderation);
    };

    /**
     * handle change when user enter degit
     */
    const onChange = async (dataIndex, value, record) => {
        const newState = [...dataSource];

        record[dataIndex] = value;
        const index = newState.findIndex((item) => item.__key__ === record.__key__);
        newState.splice(index, 1, record);
        // newState[index] = record;

        await setDataSource([...newState]);
        set_habilidade_necessaria([...newState]);
        if (dataIndex === "ponderacao" || dataIndex === "ativo") {
            setSumPonderation(!sumPonderation);
        }
    };

    /**
     * columns to table
     */
    const columns = [
        {
            title: "Tipos de habilidades",
            dataIndex: "tipo_habilidade",
            width: "30%",
            ...getColumnSearchProps("tipo_habilidade"),
            onFilter: (value, record) => {
                const filteredValue = habilityTypes
                    .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.tipo_habilidade);
                return filteredValue.includes(record["tipo_habilidade"]);
            },
            render: (text, record) => {
                const defaultValue = typeof text !== "object" && text ? text : undefined;

                return (
                    <Select
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                        style={{ maxWidth: "300px", margin: "0 auto", padding: "0" }}
                        placeholder="Selecione uma opção"
                        optionFilterProp="children"
                        value={habilityTypes
                            .map((element) => element.tipo_habilidade)
                            .find((item) => item === defaultValue)}
                        onChange={(value) => {
                            onChange("tipo_habilidade", value, record);
                            setHabilityTypeId(value);
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {habilityTypes.map((item) => (
                            <Option key={item.tipo_habilidade} value={item.tipo_habilidade}>
                                {item.designacao}
                            </Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Descrição",
            dataIndex: "habilidade",
            width: "30%",
            ...getColumnSearchProps("habilidade"),
            onFilter: (value, record) => {
                const filteredValue = habilities
                    .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.habilidade);
                return filteredValue.includes(record["habilidade"]);
            },
            render: (text, record) => {
                const defaultValue = typeof text !== "object" && text ? text : undefined;

                return (
                    <Select
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                        style={{ maxWidth: "300px", margin: "0 auto", padding: "0" }}
                        placeholder="Selecione uma opção"
                        optionFilterProp="children"
                        value={habilities.map((element) => element.habilidade).find((item) => item === defaultValue)}
                        onChange={(value) => onChange("habilidade", value, record)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {habilities
                            .filter((item) => item.tipo_habilidade === record.tipo_habilidade)
                            .map((item) => {
                                return (
                                    <Option key={item.habilidade} value={item.habilidade}>
                                        {item.designacao}
                                    </Option>
                                );
                            })}
                    </Select>
                );
            },
        },
        {
            title: "Ponderação",
            dataIndex: "ponderacao",
            width: "20%",
            ...getColumnSearchProps("ponderacao"),
            render: (text, record) => (
                <Form.Item style={{ width: "100%", margin: "0 auto", padding: "0" }}>
                    <Input
                        onChange={(e) => onChange("ponderacao", e.target.value, record)}
                        type="number"
                        defaultValue={typeof text !== "object" ? text : undefined}
                        style={{ width: "100%" }}
                        placeholder="Introduza a ponderação"
                    />
                </Form.Item>
            ),
        },
        {
            title: "Ativo?",
            dataIndex: "ativo",
            width: "10%",
            align: "center",
            ...getColumnSearchProps("ativo"),
            render: (text, record) => (
                <Checkbox
                    onChange={(e) => onChange("ativo", e.target.checked === true ? "t" : "f", record)}
                    defaultChecked={text === "t" || text === true}
                />
            ),
        },
    ];

    return (
        <>
            {/* <Row>
                <Col xs={24} sm={6}>
                    <Form.Item label="Ponderação na dimensão">
                        {getFieldDecorator("soma_ponderacao_habilidades", {
                            initialValue: ponderationCalculeValue,
                        })(
                            <Input
                                readOnly
                                type="number"
                                size="small"
                                style={{ width: "100%" }}
                                placeholder="Ponderação na dimensão"
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row> */}
            <QADynamicTable
                data={dataSource}
                columns={columns}
                addRowToTable={addRowToTable}
                onRemoveItem={removeRowFromTable}
                loadingData={loading}
            />
        </>
    );
};

const mapDispatchToProps = {
    selectDataFromExecQuery,
};

export default connect(null, mapDispatchToProps)(Requirement);
