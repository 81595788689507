import React, { useState, useEffect } from "react";
import { Form, Modal, Input, Select, Icon, Row, Col, Tabs, Transfer, message, Skeleton } from "antd";
import { getDetails, save } from "../actions/index";
import Confirm from "./../../../components/qa_custom/confirm";
import { labelTooltip as LabelTooltip, toArray } from "../../../components/util";
import { Utilitaries } from "../../../business";

const { TabPane } = Tabs;

const UserForm = ({ modalData, hideModal, form }) => {
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [autorizationsList, setAutorizationsList] = useState([]);
    const [autorizationKeys, setAutorizationKeys] = useState([]);
    const [estabelecimentoList, setEstabelecimentoList] = useState([]);
    const [estabelecimentoKeys, setEstabelecimentoKeys] = useState([]);
    const { getFieldDecorator } = form;

    useEffect(() => {
        let mounted = true;
        getDetails(modalData.username).then((response) => {
            if (response && mounted) {
                setAutorizationsList(response.lista_autorizacoes);
                setAutorizationKeys(response.autorizacoes);
                setEstabelecimentoList(response.lista_estabelecimentos);
                setEstabelecimentoKeys(response.estabelecimentos);
                form.setFieldsValue({
                    estabelecimento_defeito: response.estabelecimento_defeito,
                });
                setLoadingData(false);
            } else {
            }
        });
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalData.username]);
    const handleChangeAuthorization = (targetKeys) => {
        setAutorizationKeys(targetKeys);
    };

    const handleChangeEstablishment = (targetKeys) => {
        setEstabelecimentoKeys(targetKeys);
    };

    const renderFooter = ({ direction, dataSource }) => {
        const text = direction === "right" ? "Atribuidos" : "Não atribuidos";
        return (
            <span style={{ float: "right", margin: 5 }}>
                {text} ({dataSource.length})
            </span>
        );
    };
    const filterOptionAuthorization = (inputValue, option) => {
        return option.descricao.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    };
    const filterOptionEstablishment = (inputValue, option) => {
        return option.nome.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    };

    const handleOk = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                values.autorizacoes = autorizationKeys;
                values.estabelecimentos = estabelecimentoKeys;
                values.username = modalData.username;

                save(values).then((response) => {
                    if (response.status) {
                        message.success("Guardado com sucesso");
                        hideModal();
                    } else message.error(response.message);
                    setLoading(false);
                });
            }
        });
    };
    const propsFooter = loadingData ? { footer: null } : {};
    return (
        <Modal
            title={`Editar utilizador - ${modalData.username}`}
            visible={modalData.isVisible}
            onOk={handleOk}
            confirmLoading={loading}
            onCancel={() => Confirm(hideModal)}
            width="60%"
            {...propsFooter}
        >
            <Form layout="vertical">
                <Row gutter={18}>
                    {/* <Col xs={24} md={24}>
                        <label>Utilizador: {modalData.username}</label>
                    </Col> */}
                    <Col xs={24} md={12}>
                        <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={loadingData}>
                            <Form.Item
                                label={
                                    <LabelTooltip
                                        label="Senha"
                                        description="A senha não será alterada caso não introduzir nenhum carater."
                                    />
                                }
                            >
                                {getFieldDecorator("senha")(<Input.Password />)}
                            </Form.Item>
                        </Skeleton>
                    </Col>

                    <Col xs={24} md={12}>
                        <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={loadingData}>
                            <Form.Item
                                label={
                                    <LabelTooltip
                                        label="Estabelecimento por defeito"
                                        description="Estabelecimento que sera selecionado por padrão no painel de tarefas, iniciar processo e gerar relatório."
                                    />
                                }
                            >
                                {getFieldDecorator("estabelecimento_defeito")(
                                    <Select allowClear>
                                        {toArray(estabelecimentoKeys).map((idSelectedEst) => {
                                            const establishment = estabelecimentoList.find(
                                                (est) => est.id_estabelecimento === idSelectedEst
                                            );

                                            return establishment ? (
                                                <Select.Option value={idSelectedEst}>
                                                    {establishment.nome}
                                                </Select.Option>
                                            ) : null;
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        </Skeleton>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24}>
                        <Skeleton paragraph={{ rows: 4, width: "100%" }} active loading={loadingData}>
                            <Tabs defaultActiveKey="1">
                                <TabPane
                                    tab={
                                        <span>
                                            <Icon type="key" />
                                            Autorizações
                                        </span>
                                    }
                                    key="1"
                                >
                                    <Transfer
                                        rowKey={(record) => record.idautorizacao}
                                        dataSource={autorizationsList}
                                        showSearch
                                        filterOption={filterOptionAuthorization}
                                        targetKeys={autorizationKeys}
                                        onChange={handleChangeAuthorization}
                                        render={(item) => item.descricao}
                                        listStyle={{
                                            width: 300,
                                            height: 300,
                                        }}
                                        footer={renderFooter}
                                    />
                                </TabPane>
                                <TabPane
                                    tab={
                                        <span>
                                            <Icon type="bank" />
                                            Estabelecimentos
                                        </span>
                                    }
                                    key="2"
                                >
                                    <Transfer
                                        rowKey={(record) => record.id_estabelecimento}
                                        dataSource={estabelecimentoList}
                                        showSearch
                                        filterOption={filterOptionEstablishment}
                                        targetKeys={estabelecimentoKeys}
                                        onChange={handleChangeEstablishment}
                                        render={(item) => item.nome}
                                        listStyle={{
                                            width: 300,
                                            height: 300,
                                        }}
                                        footer={renderFooter}
                                    />
                                </TabPane>
                            </Tabs>
                        </Skeleton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

// const mapStateToProps = state => {
//   return { user: state.user };
// };

// const mapDispatchToProps = dispatch => {
//   return bindActionCreators({ ...actionCreators }, dispatch);
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Form.create({})(UserForm));
export default Form.create({})(UserForm);
