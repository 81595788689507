import React, { Component } from "react";
import { PageHeader } from "antd";
import UserForm from "./form";

class UserCreate extends Component {
    render() {
        return (
            <div>
                <PageHeader title={"Criar Utilizador"} />
                <UserForm />
            </div>
        );
    }
}

export default UserCreate;
