import React, { Component } from "react";
import { Popconfirm, Table, Divider, Input, Button, Icon, message } from "antd";
import ButtonsGroup from "./buttons.group";
import FormKnowledge from "./form.knowledge";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/knowledge";
import * as knowledgeType from "./../../actions/knowledge.type";
import { allowed } from "./../../../../constants/index";
import { tableSort, tableFooter } from "./../../../../components/util";
import Highlighter from "react-highlight-words";

const REPORT_PARAMS = {
    accao: "lista",
    lista: "conhecimentos",
    campos: "tipo_conhecimento:400:Tipo de conhecimento;designacao:400:Conhecimento;",
    titulo: "Conhecimentos",
};
class Knowledge extends Component {
    componentDidMount() {
        this.selectData();
        this.props.selectTypes({ mostra_lista_eliminados: false });
    }

    state = {
        isVisible: false,
        isEdit: false,
        isFetching: false,
        showDeletedRecords: false,
        filter: {},
    };

    selectData = () => {
        this.setState({
            isFetching: true,
        });
        this.props.select({ mostra_lista_eliminados: this.state.showDeletedRecords, ...this.state.filter }).then(() => {
            this.setState({
                isFetching: false,
            });
        });
    };
    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        // onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: (visible) => {
        //     if (visible) {
        //         setTimeout(() => this.searchInput.select());
        //     }
        // },
        // render: (text) => (
        //     <Highlighter
        //         highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        //         searchWords={[this.state.searchText]}
        //         autoEscape
        //         textToHighlight={text.toString()}
        //     />
        // ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex = null) => {
        this.setState({
            searchText: selectedKeys,
            filter: { ...this.state.filter, [`filtro_${dataIndex}`]: selectedKeys },
        });
        confirm();
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    /****************************** */
    /** configs table */

    showDeletedRecords = (e) => {
        this.setState({ showDeletedRecords: e.target.checked }, () => this.selectData());
    };

    columns = [
        {
            title: "Tipo de conhecimento",
            dataIndex: "tipo_conhecimento",
            width: "30%",
            ...this.getColumnSearchProps("tipo_conhecimento"),
            sorter: (a, b) => tableSort(a.tipo_conhecimento, b.tipo_conhecimento),
        },
        {
            title: "Conhecimento",
            dataIndex: "designacao",
            width: "50%",
            ...this.getColumnSearchProps("designacao"),
            sorter: (a, b) => tableSort(a.designacao, b.designacao),
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "20%",
            render: (text, record) => {
                return (
                    record._eliminado_ === allowed && (
                        <>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                }}
                                onClick={() => this.handleEdit(record.id_conhecimento)}
                            >
                                Editar
                            </Button>
                            {record._eliminado_ === allowed && (
                                <>
                                    <Divider type="vertical" />
                                    <Popconfirm
                                        title="Tens certeza que desejas eliminar?"
                                        okText="Ok"
                                        cancelText="Não"
                                        onConfirm={() => this.delete(record.id_conhecimento)}
                                    >
                                        <Button
                                            type="link"
                                            style={{
                                                padding: 0,
                                                color: "#fa541c",
                                            }}
                                        >
                                            Eliminar
                                        </Button>
                                    </Popconfirm>
                                </>
                            )}
                        </>
                    )
                );
            },
        },
    ];

    /************************************ */

    showModal = () => {
        this.props.getForm(this.props.metatadaForm).then(() => {
            this.setState({
                isVisible: true,
                isEdit: false,
            });
        });
    };

    handleOk = () => {
        this.setState({
            isVisible: false,
        });
        this.selectData();
    };

    handleCancel = () => {
        this.setState({
            isVisible: false,
        });
    };

    handleEdit = (id) => {
        this.props.getById(id).then(() => {
            this.setState({
                isVisible: true,
                isEdit: true,
            });
        });
    };

    delete = (key) => {
        this.props.remove(key).then((response) => {
            if (response) {
                message.success("Eliminado com sucesso");
                this.selectData();
            } else message.error("Ocorreu um erro");
        });
    };

    handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        let ordernacao = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            const order = sorter.order !== "descend" ? "ASC" : "DESC";
            ordernacao = { [`ordenacao_${sorter.columnKey}`]: order };
        }

        this.setState({ ...this.state, currentPage: 1, filter: { ...obj, ...ordernacao } }, () => this.selectData());
    };

    render() {
        return (
            <>
                {this.state.isVisible && (
                    <FormKnowledge
                        handleOk={this.handleOk}
                        isVisible={this.state.isVisible}
                        handleCancel={this.handleCancel}
                        isEdit={this.state.isEdit}
                    />
                )}
                <ButtonsGroup
                    showDeletedRecords={this.showDeletedRecords.bind(this)}
                    handleOnClick={this.showModal.bind(this)}
                    REPORT_PARAMS={REPORT_PARAMS}
                    filter={this.state.filter}
                />
                <Table
                    rowKey={(record) => record.id_conhecimento}
                    dataSource={this.props.knowledgeList}
                    columns={this.columns}
                    size="middle"
                    loading={this.state.isFetching}
                    pagination={true}
                    footer={() => tableFooter(this.props.totalRegisto)}
                    onChange={this.handleTableChange}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        knowledgeList: Object.values(state.knowledge.domain.byId),
        isFetched: state.knowledge.app.isFetched,
        totalRegisto: Number(state.knowledge.domain.metadata.attributes.resultados),
        metatadaForm: state.knowledge.domain.metadata.formulario,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...actionCreators, selectTypes: knowledgeType.select }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Knowledge);
