import React, { Component } from "react";
import {
    Popconfirm,
    Table,
    Row,
    Col,
    PageHeader,
    Divider,
    Input,
    Icon,
    Button,
    Pagination,
    message,
    Checkbox,
} from "antd";
import Highlighter from "react-highlight-words";
import ButtonsGroup from "../buttons.group";
import AuditStandartsRegestryModal from "./auditStandartsRegestryModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as actionCreators from "./../../actions/standarts";
import { createPdfStandartList } from "./createPdfStandartList";
import { tableSort, tableFooter } from "./../../../../components/util";

export class OrganizationalStandarts extends Component {
    componentDidMount() {
        if (!this.props.isFetched) this.selectData();
    }

    state = {
        isModalVisible: false,
        readOnly: false,
        filterParameters: {
            pagina: 1,
            resultados_pagina: 10,
        },
        mostra_lista_eliminados: false,
        filtro_ativo: true,
    };

    selectData = (obj) => {
        this.setState({
            isFetching: true,
        });
        this.props
            .selectOrganizationStandart({
                ...this.state.filterParameters,
                mostra_lista_eliminados: this.state.mostra_lista_eliminados,
                filtro_ativo: this.state.filtro_ativo,
                ...obj,
            })
            .then(() => {
                this.setState({
                    isFetching: false,
                });
            });
    };

    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        return this.handleSearch(selectedKeys, confirm, dataIndex);
                    }}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => {
            text = typeof text === "object" ? "" : text;
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={typeof text === "object" ? "" : text.toString()}
                />
            );
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({
            searchText: "",
        });
    };
    /****************************** */

    /** configs table */
    handlePageChange = (page, pageSize) => {
        this.selectData({ pagina: page, resultados_pagina: pageSize });
    };
    handleShowSizeChange = (current, pageSize) => {
        this.selectData({ pagina: current, resultados_pagina: pageSize });
    };
    handleTableChange = (pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val[0]) obj["filtro_" + key] = val[0];
            });
        }
        if (sorter.columnKey) {
            const value = sorter.order === "ascend" ? "ASC" : "DESC";
            obj["ordenacao_" + sorter.columnKey] = value;
        }
        const objFilter = {
            pagina: 1,
            resultados_pagina: this.props.resultadosPagina,
            ...obj,
        };
        this.setState({ filterParameters: obj }, () => {
            this.selectData(objFilter);
        });
    };

    columns = [
        {
            title: "Nome da norma",
            dataIndex: "nome_norma",
            width: "40%",
            ...this.getColumnSearchProps("nome_norma"),
            sorter: (a, b) => tableSort(a.nome_norma, b.nome_norma),
        },
        {
            title: "Âmbito",
            dataIndex: "ambito",
            width: "35%",
            ...this.getColumnSearchProps("ambito"),
            sorter: (a, b) => tableSort(a.ambito, b.ambito),
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "25%",
            render: (text, record) => (
                <>
                    {this.props.pageConfig.permite_consultar === "t" && (
                        <>
                            <Button type="link" onClick={() => this.handleFormEdit(record.id_normas_auditorias, true)}>
                                Consultar
                            </Button>
                            <Divider type="vertical" />
                        </>
                    )}
                    {this.props.pageConfig.permite_alterar === "t" && record._eliminado_ === "t" && (
                        <>
                            <Button type="link" onClick={() => this.handleFormEdit(record.id_normas_auditorias, false)}>
                                Editar
                            </Button>
                            <Divider type="vertical" />
                        </>
                    )}
                    {this.props.pageConfig.permite_eliminar === "t" && record._eliminado_ === "t" && (
                        <Popconfirm
                            title="Tens certeza que desejas eliminar?"
                            onConfirm={() => this.handleDelete(record.id_normas_auditorias)}
                        >
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    color: "#fa541c",
                                }}
                            >
                                Eliminar
                            </Button>
                        </Popconfirm>
                    )}
                </>
            ),
        },
    ];

    /************************************ */
    handleDelete = (key) => {
        this.props.remove(key).then((response) => {
            if (response) {
                message.success("Eliminado com sucesso");
                this.selectData();
            } else message.error("Ocorreu um erro");
        });
    };

    handleAdd = () => {
        this.showModal();
    };

    //********** modal create *************/
    showModal = () => {
        this.setState({
            isModalVisible: true,
            isEdit: false,
        });
    };
    handleCancelCreate = (e) => {
        this.setState({
            isModalVisible: false,
            readOnly: false,
        });
    };
    handleOk = () => {
        this.setState({
            isModalVisible: false,
            readOnly: false,
        });
        this.selectData();
    };
    //***** modal edit  */

    handleFormEdit = (id, readOnly) => {
        this.setState({
            isModalVisible: true,
            idEdit: id,
            isEdit: true,
            readOnly,
        });
    };

    handleExportListToPdf = () => {
        this.setState({
            loadingPdf: true,
        });
        const list = this.props.list.map((item) => {
            return [item.nome_norma, typeof item.area_gestao === "object" ? "" : item.area_gestao];
        });
        createPdfStandartList(list);
    };

    showDeletedRecords = (e) => {
        this.setState(
            {
                mostra_lista_eliminados: e.target.checked,
            },
            () => this.selectData()
        );
    };

    showInativeRecords = (e) => {
        this.setState(
            {
                filtro_ativo: !e.target.checked,
            },
            () => this.selectData({ filtro_ativo: !e.target.checked })
        );
    };

    onChangeValue = (value) => {
        this.setState({
            value,
        });
    };

    render() {
        return (
            <>
                <PageHeader title="Normas da organização"></PageHeader>
                <br />
                <Row>
                    <Col xs={24} md={24}>
                        <ButtonsGroup
                            allowInsertion={this.props.pageConfig && this.props.pageConfig.permite_inserir === "t"}
                            handleAdd={this.handleAdd.bind(this)}
                            handleExportToPdf={this.handleExportListToPdf.bind(this)}
                        ></ButtonsGroup>
                        {this.props.pageConfig && this.props.pageConfig.permite_eliminar === "t" && (
                            <Checkbox onChange={this.showDeletedRecords}>Mostrar registos eliminados</Checkbox>
                        )}
                        <Checkbox onChange={this.showInativeRecords}>Mostrar registos inativos</Checkbox>
                    </Col>
                </Row>
                <Table
                    rowKey={(record) => record.id_normas_auditorias}
                    columns={this.columns}
                    dataSource={this.props.list}
                    loading={this.state.isFetching}
                    pagination={false}
                    footer={() => tableFooter(this.props.total)}
                    onChange={this.handleTableChange}
                    size="middle"
                />
                {this.props.isFetched && (
                    <>
                        <Row>
                            <Col xs={24}>
                                <Pagination
                                    showTitle
                                    size="small"
                                    showSizeChanger
                                    showLessItems
                                    onShowSizeChange={this.handleShowSizeChange}
                                    onChange={this.handlePageChange}
                                    current={this.props.pagina}
                                    total={this.props.total}
                                    style={{ float: "right" }}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                {this.state.isModalVisible && (
                    <AuditStandartsRegestryModal
                        isModalVisible={this.state.isModalVisible}
                        handleOk={this.handleOk.bind(this)}
                        handleCancel={this.handleCancelCreate.bind(this)}
                        isEdit={this.state.isEdit}
                        idEdit={this.state.idEdit}
                        readOnly={this.state.readOnly}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        list: Object.values(state.standarts.domain.byId),
        isFetched: state.standarts.app.isFetched,
        total: Number(state.standarts.domain.metadata.attributes.resultados),
        pagina: Number(state.standarts.domain.metadata.attributes.pagina),
        resultadosPagina: state.standarts.domain.metadata.attributes.resultados_pagina,
        pageConfig: state.standarts.domain.metadata.pageConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            selectOrganizationStandart: actionCreators.selectOrganizationStandart,
            remove: actionCreators.remove,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationalStandarts);
