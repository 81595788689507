import { Utilitaries } from "../../../../business";
import { InputInTableProps } from "../../../types/common";
import { FormInputsObjectProps, FormInputsProps } from "../../../types/formTypes";
import { regexValidate } from "./regexConversion";

interface ExpressionHandleProps {
    expression: string;
    value: any;
    componentProperties: FormInputsProps;
    formState: FormInputsObjectProps;
    rowTable: InputInTableProps | undefined;
}

const expressionHandle = ({ expression, value, componentProperties, formState, rowTable }: ExpressionHandleProps) => {
    if (expression === "nif") {
        const nifValidation = Utilitaries.isValidateNIF_PT(value);
        return nifValidation;
    }

    // if (expression.startsWith("auto_preenche")) {
    //     let expressionResult: any = expression.match(/(?<=\()(.*)/g);
    //     if (expressionResult?.[0]?.endsWith(")")) {
    //         expressionResult = expressionResult?.[0]?.replace(")", "");
    //         return expressionResult;
    //     }
    // }

    const resultExpression = regexValidate({
        expression,
        componentProperties,
        formState,
        rowTable,
    });

    return resultExpression;
};

type RegularExpressionHandleProps = ExpressionHandleProps & {
    runExpression?: boolean;
};
export const regularExpressionHandle = ({
    expression,
    value,
    runExpression = true,
    componentProperties,
    formState,
    rowTable,
}: RegularExpressionHandleProps) => {
    try {
        const regex: any = expressionHandle({ expression, value, componentProperties, formState, rowTable });

        if (typeof regex === "boolean") {
            return regex;
        }

        if (runExpression) {
            const result = eval(regex);

            return result;
        }

        return regex;
    } catch (error) {
        return "";
    }
};

export const expressao_lenght = (value: string, length: number) => {
    const itemValueLength = Utilitaries.toString(value).length;

    return itemValueLength === length;
};
