/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useMemo } from "react";
import moment from "moment";
import { Col } from "antd";

import { QADynamicTable } from "../../qa_custom/index";
import QADivider from "./divider";
import { QAToolTip } from "../qa_components";
import { sendHttpRequest } from "../../http.request";
import FormContext from "../context/FormContext";
import { input_validations, regex_match } from "../utils/validations";
import { getInputLabel, getTableAction } from "../utils/functions";
import { Utilitaries } from "../../../business";
import { Popconfirm, Icon } from "antd";

const renderSelectAll = ({ currentItem, columnKey, justSee, changeAll }) => {
    if (currentItem?.["@seleccionar_todos"] === "sim") {
        if (!Utilitaries.isEmpty(currentItem?.["@valores"])) {
            return <></>;
        } else if (!Utilitaries.isEmpty(currentItem?.["@dados"])) {
            return <></>;
        } else {
            return (
                <Popconfirm
                    title="Selecionar todos como:"
                    onConfirm={() => changeAll(true, columnKey, currentItem)}
                    onCancel={() => changeAll(false, columnKey, currentItem)}
                    okText="Sim"
                    cancelText="Não"
                    disabled={justSee}
                >
                    <Icon type="check-square" />
                </Popconfirm>
            );
        }
    }
};

const QaRenderTable = ({
    objectInputs,
    fields,
    field,
    inputAttribute,
    index,
    renderItems,
    addItem,
    removeItem,
    visible,
    renderGroup,
    checkIfFieldCanBeVisible,
}) => {
    const [inputType, setInputType] = useState(null);
    const [loadingData, setLoadingData] = useState(false);

    const { formData, setTableData, formAttributes, params, justSee, isInitial } = useContext(FormContext);

    let defaultData = {};

    // Obter o input {[field]: {...}}
    const _tableKey = Object.keys(objectInputs[field]).filter((it) => !it.startsWith("@"))[0];

    // Obter dados da tabela
    useEffect(() => {
        const getData = () => {
            const fd = new FormData();
            // nao caregar para espacos - se nao e novo como espaco modelo
            // if (inputAttribute["@dados"]) {
            //     return;
            // }

            if (!inputAttribute["@dados"]) return;

            let dados = inputAttribute["@dados"];

            dados = dados.split(",");

            fd.append("accao", "query");
            // fd.append("query", inputAttribute["@dados"]);
            fd.append("query", dados[0]);

            if (formAttributes["@nome"] === "qualia_gestao_sistema_pop.qml.xml") {
                fd.append("id_obj", params["id_obj"]);
                fd.append("tipo", params["tipo"].replace(/__/g, " "));
            }

            if (params) {
                Object.keys(params).forEach((param) => {
                    fd.append(param, params[param]);
                });
            }

            try {
                if (dados.length > 1) {
                    dados = inputAttribute["@dados"].replace(/ /g, "").split(",{");

                    // remove first item in array
                    dados.shift();

                    dados = dados[0];

                    dados = dados.split(",");

                    if (!fd.has(dados[0])) {
                        fd.append(dados[0], formData[dados[1].replace(/\W/g, "")]);
                    }
                }
            } catch (error) {}
            setLoadingData(true);
            try {
                return sendHttpRequest("POST", "/Gestor/execQuery.php", fd).then((data) => {
                    if (data && typeof data === "object") {
                        let dataTrad = data.result;
                        debugger;
                        //merge data.result with formData - in traduction lists
                        const formsToMergeData = [
                            "qa_trad_listas_ling.xml",
                            "qa_trad_phps_ling.xml",
                            "qa_trad_menus_ling.xml",
                            "qa_plataforma_traducoes.xml",
                            "qa_trad_rel_sup_ling.xml",
                        ];
                        const formMergeIn = {
                            "qa_trad_listas_ling.xml": "lista",
                            "qa_trad_phps_ling.xml": "id_campo",
                            "qa_trad_menus_ling.xml": "id_campo",
                            "qa_plataforma_traducoes.xml": "id_campo",
                            "qa_trad_rel_sup_ling.xml": "id_relatorio",
                        };

                        if (
                            formsToMergeData.includes(formAttributes["@nome"]) &&
                            formData &&
                            formData[field] &&
                            formData[field][_tableKey] &&
                            formData[field][_tableKey].length > 0
                        ) {
                            dataTrad = dataTrad.map((it) => {
                                const formDataItem = formData[field][_tableKey].find(
                                    (itFDI) =>
                                        itFDI[formMergeIn[formAttributes["@nome"]]] ===
                                        it[formMergeIn[formAttributes["@nome"]]]
                                );
                                return Object.assign({}, it, formDataItem);
                            });
                        }

                        setTableData({
                            table: field,
                            key: _tableKey,
                            data: dataTrad,
                        });
                    }

                    setInputType("etiqueta_value");
                    setLoadingData(() => false);
                });
            } catch (error) {
                setLoadingData(() => false);
            }
        };
        debugger;
        if (
            inputAttribute["@dados"] &&
            (Utilitaries.isEmpty(formData[`id_${formAttributes["@elemento_raiz"]}`]) ||
                [
                    "traducoes_menus_lingua",
                    "traducoes_rel_sup_lingua",
                    "traducoes_listas_lingua",
                    "traducoes_lingua",
                    "traducoes_phps_lingua",
                ].includes(inputAttribute["@dados"])) &&
            visible
        ) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputAttribute["@dados"]]);

    let valueDep = [],
        inputActionsName = null;

    const tableAction = getTableAction({ item: inputAttribute });
    if (!Utilitaries.isEmpty(tableAction)) {
        valueDep = regex_match(tableAction.valueDep);
        inputActionsName = tableAction.inputActionsName;
    }

    useEffect(
        () => {
            const getData = async () => {
                if (inputActionsName) {
                    setLoadingData(true);
                    let expression = input_validations({
                        item: inputAttribute,
                        relKey: inputActionsName,
                        returnExpression: true,
                        formData: fields,
                    });

                    expression = expression.replace(/{[\w[\],\s,\-']+}|\w+\:\w+(?=,)|\w+(?=,)/g, '"$&"');
                    // expression = expression.replace(/{[\w[\],\s']+}|\w+(?=,)/g, '"$&"');

                    const expressionValue = await eval(`${expression}`);
                    if (!expressionValue) {
                        setLoadingData(() => false);
                        return;
                    }
                    setTableData({
                        table: field,
                        key: _tableKey,
                        data: expressionValue,
                    });
                    setLoadingData(() => false);
                    // if (inputActionsName === "@accao_preenche_limpeza") {
                    //     setAutoLoadPupUpFromModel(true);
                    // }
                }
            };

            if (valueDep.length > 0 && !isInitial) {
                getData();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueDep.map((it) => (formData ? formData[it] : null))
    );

    const auto_preenche = async (query, ...params) => {
        if (!formData) {
            return;
        }
        const fd = new FormData();
        fd.append("query", query);
        fd.append("accao", "query");

        let nullValues = false;

        params
            .map((it) => it.replace(/[{}\s]/g, "").split(","))
            .forEach((it) => {
                // eslint-disable-next-line no-eval
                fd.append(it[0], eval(it[1]));

                // eslint-disable-next-line no-eval
                if (!eval(it[1])) {
                    nullValues = true;
                }
            });

        // const a = inputAttribute?.["@ignora_parametros"];

        if (nullValues && inputAttribute?.["@ignora_parametros"] !== "true") {
            return;
        }

        try {
            const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", fd);

            return data.result;
        } catch (error) {
            return null;
        }
    };

    // useEffect(() => {
    //     const __data =
    //         fields && fields[field] && fields[field][_tableKey]
    //             ? fields[field][_tableKey]
    //             : [];

    //     setTableData(__data);
    // }, [_tableKey, field, fields]);

    // return Object.keys(objectInputs[field])
    //     .filter(it => !it.startsWith("@"))
    //     .map(_tableKey => {
    // para cada input dentro do objeto [field]
    // Obter os inputs
    const tableInputs = objectInputs[field][_tableKey];
    // Obter keys de todos os inputs
    const tableInputsKeys = Object.keys(tableInputs).filter((it) => !it.startsWith("@"));

    const defaultProps = inputAttribute._nodes ? { _nodes: inputAttribute._nodes } : {};

    const getInvisibleColumns = () => {
        const list = [];
        tableInputsKeys.forEach((it, idx) => {
            let attributes = tableInputs[it];
            if (
                attributes &&
                Object.keys(attributes).length > 0 &&
                attributes["@visivel"] === "nao" &&
                Object.keys(attributes).find((attr) => attr.startsWith("@accao_") || attr.startsWith("@calcular")) &&
                !Object.keys(attributes).find(
                    (attr) => attr.startsWith("@accao_") && attributes[attr].startsWith("texto(")
                )
            ) {
                list.push({
                    arrayFields: [it], // key deste input
                    objectInputs: tableInputs, // todos os inputs da tabela
                    // value: _allData[it], // _data, // valor do input
                    inTable: true, // se este input pertense a uma tabela
                    // self: it, // atributos deste input
                    brothers: tableInputsKeys, // key dos inputs irmaõs
                    inputType,
                    items: {
                        __id: it,
                        // dados da linha que esta sendo alterada
                        // data: _allData, // linha
                        table: field, // nome da tabela
                        key: _tableKey, // key da tabela
                    },
                    ...defaultProps,
                });
            }
        });
        return list;
    };

    const getData = () => {
        if (inputAttribute._nodes && field === "docs") {
            const nodeKey = inputAttribute._nodes[0];
            return formData && formData[nodeKey] && formData[nodeKey][field][_tableKey]
                ? Array.isArray(formData[nodeKey][field][_tableKey])
                    ? formData[nodeKey][field][_tableKey]
                    : [formData[nodeKey][field][_tableKey]]
                : [];
        } else {
            return formData && formData[field] && formData[field][_tableKey]
                ? Array.isArray(formData[field][_tableKey])
                    ? formData[field][_tableKey]
                    : [formData[field][_tableKey]]
                : [];
        }
    };

    const tableDataSource = getData();

    const checkColumnCanBeRender = (attributes, options) => {
        if (!attributes["@relevante"]) {
            return true;
        }
        // const dataSource = getData();

        for (const dataRow of tableDataSource) {
            options.items.data = dataRow;

            const result = checkIfFieldCanBeVisible({ input: attributes, options });
            if (result) return true;
        }
        return false;
    };

    const changeAll = (value, columnKey, column) => {
        if (column["@tipo"] === "boolean") {
            value = value === true ? "t" : "f";
        }

        const list = tableDataSource.map((el) => {
            return { ...el, [columnKey]: value };
        });

        setTableData({
            table: field,
            key: _tableKey,
            data: list,
        });
    };

    const getVisibleColumns = () => {
        const list = [];
        tableInputsKeys.forEach((it, idx) => {
            let attributes = tableInputs[it];
            defaultData[it] = "";
            // default value in data
            if (attributes && attributes["$"] && attributes["$"] === "{now()}") {
                if (tableInputs["@tabela"] !== "qa_colab_reg_ferias_lin") {
                    defaultData[it] = moment().format("YYYY-MM-DD");
                }
            } else if (attributes && attributes["$"]) {
                defaultData[it] = attributes["$"];
            } else if (attributes && attributes["@calcular"]) {
                const valor = input_validations({
                    item: attributes,
                    relKey: "@calcular",
                    formData: formData,
                    functions: {
                        texto(text) {
                            return text;
                        },
                    },
                });
                defaultData[it] = valor;
            }

            if (
                attributes &&
                Object.keys(attributes).length > 0 &&
                attributes["@visivel"] !== "nao" &&
                checkColumnCanBeRender(attributes, {
                    inTable: true, // se este input pertense a uma tabela
                    // self: it, // atributos deste input
                    brothers: tableInputsKeys, // key dos inputs irmaõs
                    inputType: inputAttribute["@param_popup"] ? "etiqueta_value" : null, // verificar se o input não deve ser apenas label
                    items: {
                        __id: it,
                        // dados da linha que esta sendo alterada
                        // data: _allData, // linha
                        key: _tableKey, // nome da tabela
                        table: field, //"linha", //undefined //field // key da tabela
                    },
                })
            ) {
                list.push({
                    title: () => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <QAToolTip
                                label={getInputLabel(attributes, it)}
                                help={attributes["@ajuda"]}
                                isRequired={attributes?.["@obrigatorio"] === "true()"}
                            />
                            {attributes?.["@seleccionar_todos"] === "sim" &&
                                renderSelectAll({ currentItem: attributes, justSee, columnKey: it, changeAll })}
                        </div>
                    ),
                    dataIndex: it,
                    rowKey: it + idx,
                    render: (_data, _allData) => {
                        return (
                            <>
                                {renderItems({
                                    arrayFields: [it], // key deste input
                                    objectInputs: tableInputs, // todos os inputs da tabela
                                    value: _allData[it], // _data, // valor do input
                                    inTable: true, // se este input pertense a uma tabela
                                    // self: it, // atributos deste input
                                    brothers: tableInputsKeys, // key dos inputs irmaõs
                                    inputType,
                                    items: {
                                        __id: it,
                                        // dados da linha que esta sendo alterada
                                        data: _allData, // linha
                                        table: field, // nome da tabela
                                        key: _tableKey, // key da tabela
                                    },
                                    ...defaultProps,
                                })}
                                {idx === 0 &&
                                    invisibleColumns.map((el) => {
                                        return renderItems({ ...el, items: { ...el.items, data: _allData } });
                                    })}
                            </>
                        );
                    },
                });

                // dataInput[it] = attributes;
            }
        });
        return list;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const invisibleColumns = useMemo(() => getInvisibleColumns(), [field]);

    // const dataInput = {};
    // Percorer os inputs e criar o header da tabela
    const columns = getVisibleColumns();

    const renderActionButtons = () => {
        if (inputAttribute["@dados"]) {
            if (inputAttribute["@botoes_transacao"] === "com_botoes") {
                return true;
            }
            return false;
        } else if (inputAttribute["@botoes_transacao"]) {
            if (inputAttribute["@botoes_transacao"] === "com_botoes" && inputAttribute["@botao_eliminar"] === "sim") {
                return { remove: true };
            } else if (inputAttribute["@botoes_transacao"] === "sem_botoes") {
                return false;
            }
        } else {
            return true;
        }
    };

    const settableScroll = () => {
        if (formAttributes["@nome"] === "qa_trad_rel_sup_ling.xml") {
            return { x: 3500 };
        }

        if (formAttributes["@nome"] === "qa_imobilizado_entrada.qml.xml") {
            return { x: 2500 };
        }
        if (formAttributes["@nome"] === "salario_registo_faltas.xml") {
            return { x: 3000 };
        }
        if (formAttributes["@nome"] === "stk_movimento.xml") {
            return { x: 2000 };
        }
        return {};
    };

    const getRequiredFields = () => {
        const fields = [];
        Object.entries(inputAttribute[_tableKey]).forEach(([key, value]) => {
            //if (value["@obrigatorio"] === "true" || value["@obrigatorio"] === "true()" || value["@restricao"])
            if (
                (value?.["@obrigatorio"] && (value["@obrigatorio"] !== "nao" || value["@obrigatorio"] !== "nao()")) ||
                value["@restricao"]
            )
                fields.push(key);
        });
        return fields;
    };
    const addRowToTable = () => {
        const requiredFields = getRequiredFields();
        addItem(field, _tableKey, defaultData, defaultProps, inputAttribute, requiredFields);
    };

    return visible ? (
        <>
            {/* {renderGroup && <QADivider text={inputAttribute["@grupo"]} />} */}
            {<QADivider text={inputAttribute["@grupo"]} />}

            <Col key={"col-f-" + index} xs={24} style={{ marginBottom: 12 }}>
                <QADynamicTable
                    columns={columns}
                    data={tableDataSource}
                    actions={renderActionButtons()}
                    readOnly={justSee}
                    onRemoveItem={(selectedRowKeys) => removeItem(field, _tableKey, selectedRowKeys, defaultProps)}
                    addRowToTable={addRowToTable}
                    scroll={settableScroll()}
                    loadingData={loadingData}
                />
            </Col>
        </>
    ) : null;
    // });
};

QaRenderTable.propTypes = {};

export default QaRenderTable;
