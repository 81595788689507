import { Utilitaries } from "../../../business";
import { componentType } from "../constants";
import { conversion, downFile, getFieldInInputGroup, mathConversion } from "../constants/utils";

export const isFieldVisibility = ({ field, state }) => {
    let visibility = field?.visible;

    if (typeof visibility !== "undefined" && !field["@tab_relevante"]) {
        return visibility;
    }

    const itemKeys = Object.keys(field);

    const xpath =
        field["@relevante"] || field[itemKeys.find((key) => (key.includes("@grupo_relevante") ? key : undefined))];

    if (!field?.["@visivel"] && !xpath) {
        return true;
    }

    if (xpath) {
        return restriction({ item: field, state, xpath });
    }

    return false;
};

export const readOnly = ({ item, state, record, itemKeys, formData }) => {
    // downFile(JSON.stringify(state), "teste.json", "application/json");
    // TODO: test only

    if (item.key === "area_desenvolv_sel") {
        return false;
    }

    if (!item?.["@apenas_leitura"]) {
        return false;
    }

    if (
        item?.["@apenas_leitura"] === "true()" ||
        Utilitaries.toString(item?.["@apenas_leitura"])?.toLowerCase() === "sim"
    ) {
        return true;
    }

    if (item.key == "cliente") {
        // ;
    }

    const string = (text) => text;

    // const jsExp = conversion({
    //     xpath: item["@apenas_leitura"],
    //     item,
    //     state,
    //     root: item.root,
    //     rowKey: record && record.key,
    //     relKey: itemKeys.find((key) => key.includes("apenas_leitura")),
    //     rootPath: item.tablePath || item.inputGroupPath || null,
    // });

    const jsExp = mathConversion({
        xpath: item["@apenas_leitura"],
        item,
        state,
        root: item.root,
        rowKey: record ? record.key : null,
        formData,
        rootPath: item.tablePath || item.inputGroupPath || null,
    });


    const t = "t";
    const f = "f";


    try {
        const result = eval(jsExp);

        return result;
    } catch (error) {
        console.error(error);
        return true;
    }
};

const getAllInInputGroup = (inputGroup, list = []) => {
    for (const key in inputGroup) {
        var currentInputGroup = inputGroup[key];
        if (typeof inputGroup[key] === "object" && !Array.isArray(inputGroup[key])) {
            if (inputGroup[key]["@tipo"] === "inputGroup") {
                var subInputGroup = Object.keys(currentInputGroup).filter(
                    (item) => typeof currentInputGroup[item] === "object" && !Array.isArray(currentInputGroup[item])
                );
                getAllInInputGroup(currentInputGroup[subInputGroup[0]], list);
            } else {
                list.push(currentInputGroup);
            }
        }
    }
    return list;
};

export const canRenderGroup = ({ item, state }) => {
    // if (!item?.["@grupo"] || item.isColumn) {
    if (!item?.["@grupo"]) {
        return false;
    }

    if (item?.["@tipo"] === "popup") {
        return false;
    }

    if (item?.["@grupo"] && item?.inputGroupPath) {
        // Parent
        const fields = getFieldInInputGroup(state.form, [...item.inputGroupPath]);

        if (
            fields?.["@tipo"] === componentType._inputGroup &&
            fields?.["@grupo"] &&
            fields?.["@grupo"] === item["@grupo"]
        ) {
            return false;
        }

        // Brothers
        const allFields = getAllInInputGroup(state.form[item.inputGroupPath[0]]);

        const index = allFields.findIndex((it) => it.key === item.key);

        if (index > 0 && allFields[index - 1]["@grupo"] && allFields[index - 1]["@grupo"] === item["@grupo"]) {
            return false;
        }

        // Root Parent - Father
        const father = state.form[item.inputGroupPath[0]];
        if (father?.["@grupo"] && father?.["@grupo"] === item["@grupo"]) {
            return false;
        }

        if (item?.parent) {
            const someParent = fields
                ? Object.values(fields).filter(
                      (input) => input?.parent === item.parent && input?.["@visivel"] !== "nao"
                  )
                : [];

            const index = someParent.findIndex((it) => it.key === item.key);

            if (
                index > 0 &&
                someParent[index - 1]?.["@grupo"] &&
                someParent[index - 1]?.["@grupo"] === item["@grupo"]
            ) {
                return false;
            }
            return true;
        }
    } else {
        const fields = state.form ? Object.values(state.form) : [];

        const index = fields.findIndex((it) => it.key === item.key);

        if (index > 0 && fields[index - 1]?.["@grupo"] && fields[index - 1]?.["@grupo"] === item["@grupo"]) {
            return false;
        }
        return true;
    }

    return item?.["@grupo"] ? true : false;
};

export const compareDates = (jsExp) => {
    try {
        const regexFindCompareDate = /(?<=comparar_datas\()[^)]+(?=\))/g;
        const jsExpCompare = jsExp.match(regexFindCompareDate);
        if (jsExpCompare) {
            let checkNullDates = "";
            jsExpCompare.forEach((item) => {
                const regex = /state\.[^>=<\n]+value/g;
                const dateFields = item.match(regex);
                let newItemExp = item;
                dateFields.forEach((itemDate) => {
                    newItemExp = newItemExp.replace(itemDate, `new Date(${itemDate}).getTime()`);
                    checkNullDates += ` || !${itemDate}`;
                });
                // jsExp = jsExp.replace(`comparar_datas(${item})`, ` ( ${newItemExp} )`);
                jsExp = jsExp.replace(`comparar_datas(${item})`, ` ( ${newItemExp} ${checkNullDates} )`);
            });
        }
        return jsExp;
    } catch (error) {
        console.error(error);
        // "@relevante": " != ''",
        return jsExp;
    }
};

export const restriction = ({ item, state, record, xpath, groupItem }) => {
    if ((!xpath && !groupItem) || (!xpath && item["@tipo"] === "tab") || xpath === "true") {
        return true;
    }

    if (xpath === "nif") {
        const nifValidate = Utilitaries.isValidateNIF_PT(item?.value);

        return nifValidate;
    }

    if (xpath === "niss") {
        return Utilitaries.isValidateNISS_PT(item?.value);
    }

    // if (/expressao\(\\+d\{([0-9])\}\)/.test(xpath)) {
    //     const result = xpath.match(/expressao\(\\+d\{([0-9])\}\)/);

    //     const maxLenght = Number(result[1]);

    //     if (maxLenght) {
    //         const lenth = Utilitaries.toString(item?.value).length;
    //         return lenth === maxLenght;
    //     }

    //     return true;
    // }

    // usado pelas expresoes regulares
    const contains = (value, validator) => {
        if (value?.includes("state.form")) {
            value = eval(`${value}`);
        }

        if (value === validator) {
            return true;
        }

        if (value) {
            return value?.includes(validator);
        }

        return false;
    };

    const getBooleanValue = (value) => {
        if (value === "false") {
            return "f";
        }
        if (value === "true") {
            return "t";
        }
        return value;
    };

    const expressao_lenght = (value, length) => {
        // const itemValue = eval(value);

        const itemValueLength = Utilitaries.toString(value).length;

        return itemValueLength === length;
    };

    // eslint-disable-next-line no-unused-vars
    const emptyIfNull = (value) => {
        try {
            const itemValue = eval(value);
            if (itemValue === null || itemValue === undefined) {
                return "";
            } else {
                return itemValue;
            }
        } catch (error) {
            return "";
        }
    };

    let jsExp = "";

    if (xpath) {
        jsExp = conversion({
            xpath: xpath,
            item: item,
            root: item?.root,
            rowKey: record ? record.key : undefined,
            // relKey: itemKeys.find((key) => key.includes("relevante")),
            state,
            rootPath: item.tablePath || item.inputGroupPath || null,
        });
    } else if (groupItem) {
        jsExp = conversion({
            xpath: groupItem.xpathGroup,
            item: groupItem,
            root: groupItem.root,
            rowKey: record ? record.key : undefined,
            // relKey: itemKeys.find((key) => key.includes("relevante")),
            state,
            rootPath: item.tablePath || item.inputGroupPath || null,
        });
    }

    jsExp = compareDates(jsExp);
    if (process.env.NODE_ENV === "test") {
        return jsExp;
    }

    const t = "t";
    const f = "f";

    debugger

    try {
        //

        const condition = eval(jsExp);

        return condition ? true : false;
    } catch (error) {
        console.error(error);
        // "@relevante": " != ''",
        return true;
    }
};

export const getSelectDataKeys = (item, data) => {
    let key = item["@chave_lista"] || "key";
    let value = item["@valor_lista"] || "value";
    //
    if (data && Array.isArray(data) && data.length > 0) {
        const _data = data[0];
        if (_data.key && _data.value) {
            return {
                key: "key",
                value: "value",
            };
        }

        if (!_data.hasOwnProperty(value)) {
            value = "designacao";
        }

        if (!_data.hasOwnProperty(key)) {
            key = item.key;
        }
    }

    return { key, value };
};

export const getValueInQueryParams = (dataSource = [], value, valueKey) => {
    if (Array.isArray(dataSource) && dataSource.length > 0) {
        const listValues = dataSource.map((it) => it[valueKey]);
        switch (value) {
            case "FPR":
                if (listValues.includes("Formação Profissional")) {
                    value = "Formação Profissional";
                }
                break;
            case "CDI":
                if (listValues.includes("Centro de Dia")) {
                    value = "Centro de Dia";
                }
                break;
            case "CEX":
                if (listValues.includes("Clientes Externos")) {
                    value = "Clientes Externos";
                }
                break;
            case "CRE":
                if (listValues.includes("Creche")) {
                    value = "Creche";
                }
                break;
            case "PRE":
                if (listValues.includes("Pré-escolar")) {
                    value = "Pré-escolar";
                }
                break;

            default:
                break;
        }
    }
    return value;
};

export const isRequired = ({ itemData, state, record = null }) => {
    //;
    const xpath = itemData?.["@obrigatorio"];
    if (!xpath) return false;
    if (xpath === "true()" || xpath === "true") return true;

    // usado pelas expresoes regulares
    const contains = (value, validator) => {
        if (value.includes("state.form")) {
            value = eval(`${value}`);
        }

        if (value === validator) {
            return true;
        }

        if (value) {
            return value.includes(validator);
        }

        return false;
    };

    const jsExp = conversion({
        xpath: xpath,
        root: itemData?.root,
        rowKey: record ? record.key : undefined,
        state,
        rootPath: itemData?.inputGroupPath,
    });

    try {
        const condition = eval(jsExp);

        return condition ? true : false;
    } catch (error) {
        // "@relevante": " != ''",

        return true;
    }
};

export const getParamNameToValue = (item) => {
    if (
        /'\w+'\}|^false\}/g.test(item.paramNameToValue) ||
        /(?<!(\.\.\/.*|\/\/.*))\w*}\)?/g.test(item.paramNameToValue)
    ) {
        return `v:${item.paramNameToValue.replace(/\W/g, "")}`;
    }

    if (item.paramNameToValue?.includes("://")) {
        const splitedParamNameToValue = item.paramNameToValue.split("/");
        return splitedParamNameToValue[splitedParamNameToValue.length - 1].replace(/\W/g, "").trim();
    } else if (/\/\/\w+\/\w+/.test(item.paramNameToValue)) {
        const replaceValueName = item.paramNameToValue
            .split("/")
            .filter((item) => !!item)
            .map((item) => item.replace(/\W/g, "").trim());
        return replaceValueName;
    }
    //  else if (!/\W/g.test(item.paramNameToValue)) {
    //     return `v:${item.paramNameToValue}`;
    // }

    return item.paramNameToValue?.replace(/\W/g, "").trim();

    // const paramNameToValue = (item.paramNameToValue.includes("://")
    //             ? (() => {
    //                   const splitedParamNameToValue = item.paramNameToValue.split("/");
    //                   return splitedParamNameToValue[splitedParamNameToValue.length - 1];
    //               })()
    //             : item.paramNameToValue
    //         )
    //             .replace(/\W/g, "")
    //             .trim();
};
