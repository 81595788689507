import React, { useState } from "react";
import { Form, Row, Col, Input, Icon, Button, Upload, message } from "antd";
import { sendHttpRequest } from "../http.request";

import ModalSelectUser from "../select.user";

const { TextArea } = Input;

const NewMessage = (props) => {
    const [isVisibleModalSelectDestiny, setIsVisibleModalSelectDestiny] = useState(false);

    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [fileList, setFileList] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                const formData = new FormData();
                // const fl = await getBase64(file);
                //
                // formData.append("anexos", fl);
                fileList.forEach((file) => {
                    formData.append("anexos[]", file);
                });

                if (props.messageType === "reply") {
                    formData.append("utilizadores", props.message.utilizador);
                } else {
                    formData.append(
                        "grupos",
                        groups.reduce(
                            (accumulator, currentValue) =>
                                accumulator === "" ? currentValue.id : accumulator + ";" + currentValue.id,
                            ""
                        )
                    );
                    formData.append(
                        "utilizadores",
                        users.reduce(
                            (accumulator, currentValue) =>
                                accumulator === "" ? currentValue.username : accumulator + ";" + currentValue.username,
                            ""
                        )
                    );
                }

                formData.append("accao", "enviar");
                formData.append("assunto", values.assunto);
                formData.append("mensagem", values.message);

                sendHttpRequest("POST", "/Gestor/geremensagens.php", formData).then((jsonResponse) => {
                    if (jsonResponse.erro) {
                        message.error("Ocorreu um erro");
                    } else {
                        message.success("Mensagen enviada com sucesso");
                        props.onSave();
                    }
                });
            }
        });
    };

    const { getFieldDecorator } = props.form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    return (
        <>
            <ModalSelectUser
                visible={isVisibleModalSelectDestiny}
                setVisible={setIsVisibleModalSelectDestiny}
                {...props.selectContributorsProps}
                onOk={(usersSelected, groupsSelected) => {
                    setGroups(groupsSelected);
                    setUsers(usersSelected);
                }}
            />
            <Form {...formItemLayout} onSubmit={handleSubmit}>
                <Row type="flex" align="middle">
                    <Col span={4}>
                        <b>Enviar para:</b>
                    </Col>
                    {props.messageType ? (
                        <Col span={20}>
                            <label>{props.message.utilizador}</label>
                        </Col>
                    ) : (
                        <>
                            <Col span={18}>
                                <Row>
                                    <Col span={5}>
                                        <label>Utilizadores</label>
                                    </Col>
                                    <Col span={19}>
                                        <Input
                                            value={users.reduce(
                                                (accumulator, currentValue) =>
                                                    accumulator === ""
                                                        ? currentValue.name
                                                        : accumulator + ";" + currentValue.name,
                                                ""
                                            )}
                                            disabled
                                            style={{ marginBottom: 10 }}
                                        />
                                    </Col>
                                    <Col span={5}>
                                        <label>Grupos</label>
                                    </Col>
                                    <Col span={19}>
                                        <Input
                                            value={groups.reduce(
                                                (accumulator, currentValue) =>
                                                    accumulator === ""
                                                        ? currentValue.value
                                                        : accumulator + ";" + currentValue.value,
                                                ""
                                            )}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2}>
                                <Icon
                                    onClick={() => setIsVisibleModalSelectDestiny(true)}
                                    type="user-add"
                                    style={{ float: "right", fontSize: "22px" }}
                                />
                            </Col>
                        </>
                    )}
                </Row>

                <Form.Item label="Assunto">
                    {getFieldDecorator("assunto", {
                        initialValue: props.message
                            ? (props.messageType === "reply" ? "RE: " : "FW: ") + props.message.assunto
                            : "",
                        rules: [
                            {
                                required: true,
                                message: "Campo obrigatório",
                            },
                        ],
                    })(<Input />)}
                </Form.Item>

                <Form.Item label="Mensagem">
                    {getFieldDecorator("message", {
                        initialValue:
                            props.message && !props.messageType
                                ? props.message.mensagem + "\n--------------------------\n"
                                : "",
                        rules: [
                            {
                                required: true,
                                message: "Campo obrigatório",
                            },
                        ],
                    })(<TextArea autoSize={{ minRows: 8, maxRows: 8 }} />)}
                </Form.Item>

                <Row>
                    <Col>
                        <Upload {...fileProps}>
                            <Button>
                                <Icon type="upload" /> Selecione Ficheiro para enviar
                            </Button>
                        </Upload>
                    </Col>
                </Row>

                <Button htmlType="submit">Enviar</Button>
            </Form>
        </>
    );
};

export default Form.create({ name: "form_send_message" })(NewMessage);
