import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form, Input, message, Collapse, Typography, Card, Spin, Tag, Result, Button } from "antd";
import Confirm from "./../../../components/qa_custom/confirm";
import { Session, Utilitaries } from "../../../business";
import { save } from "./../actions/index";
import { autorizacoesSistema } from "./../constants/index";
import { api_fetch } from "../../../V2/utils/HttpRequest/request";

const { TextArea } = Input;
const { Panel } = Collapse;

const FormAuthorization = ({ modalData, handleOk, handleCancel, form, isDeleting }) => {
    const [loading, setLoading] = useState(false);
    const [utilizations, setUtilizations] = useState({});
    const [loadingUtilization, setLoadingtUtilization] = useState(false);
    const { getFieldDecorator } = form;

    useEffect(() => {
        const load = async () => {
            setLoadingtUtilization(true);
            const result = await api_fetch({
                endPoint: `/Gestor/gereautorizacoes.php`,
                params: {
                    accao: "lista_utilizacao",
                    id: modalData.idautorizacao,
                },
            });

            if (result.status === "SUCCESS") {
                const allTasks = Utilitaries.toArray(result.response?.processes?.linha);
                const processes = allTasks.reduce((acc, item) => {
                    const { process_name, process_code, forma_iniciar, task_name, versao } = item;

                    if (!acc?.[process_name]) {
                        acc[process_name] = {
                            process_name,
                            process_code,
                            forma_iniciar,
                            versao,
                            tasks: [],
                        };
                    }

                    acc[process_name].tasks.push({
                        task_name,
                    });

                    return acc;
                }, {});
                setUtilizations({
                    users: Utilitaries.toArray(result.response?.users?.linha),
                    groups: Utilitaries.toArray(result.response?.groups?.linha),
                    processes,
                    totalTasks: allTasks.length,
                    menus: Utilitaries.toArray(result.response?.menus?.linha),
                    reports: Utilitaries.toArray(result.response?.reports?.linha),
                });
            }

            setLoadingtUtilization(false);
        };
        if (modalData.idautorizacao) {
            load();
        }
    }, [modalData.idautorizacao]);

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                values.id = modalData.idautorizacao;

                save(values).then((response) => {
                    if (response.status) {
                        message.success("Guardado com sucesso");
                        handleOk();
                    } else message.error(response.message);
                    setLoading(false);
                });
            }
        });
    };

    const checkIsEditable = () => {
        if (!modalData.idautorizacao) return true;
        if (modalData.readOnly) return false;
        if (modalData.defeito === "0") {
            if (Session.getUsername() === "qa") return true;
            else return false;
        } else {
            return true;
        }
    };

    const isEditableDesignation = (editable) => {
        if (!modalData.idautorizacao) return true;
        if (!editable) return false;
        if (autorizacoesSistema.includes(modalData.descricao)) return false;
        return true;
    };

    const handleClose = (isEditable) => {
        if (isEditable) Confirm(handleCancel);
        else handleCancel();
    };

    const handleDelete = () => {
        setLoading(true);
        const params = {
            accao: "apagar",
            id: modalData.idautorizacao,
        };
        api_fetch({
            endPoint: `/Gestor/gereautorizacoes.php`,
            params,
        }).then((response) => {
            if (response.status === "SUCCESS") {
                message.success("Apagado com sucesso");
                handleOk();
            } else message.error(response.message);
            setLoading(false);
        });
    };

    const isEditable = checkIsEditable();
    const propsFooter = isDeleting
        ? {
              footer: (
                  <>
                      <Button type="primary" onClick={() => handleClose(false)}>
                          Cancelar
                      </Button>
                      <Button type="danger" onClick={() => handleDelete()}>
                          Eliminar
                      </Button>
                  </>
              ),
          }
        : isEditable
        ? {}
        : { footer: null };
    return (
        <>
            <Modal
                title={
                    isDeleting
                        ? "Eliminar autorização"
                        : modalData.idautorizacao
                        ? "Editar autorização"
                        : "Nova autorização"
                }
                visible={modalData.isVisible}
                onOk={handleSubmit}
                confirmLoading={loading}
                onCancel={() => handleClose(isEditable)}
                {...(modalData.idautorizacao ? { width: "60%" } : {})}
                {...propsFooter}
            >
                <Form layout="vertical">
                    <Row gutter={16}>
                        {isDeleting && (
                            <Col xs={24}>
                                <Result
                                    status="warning"
                                    title="Atenção ao eliminar autorização!"
                                    subTitle="Ao eliminar uma autorização, todas as utilizações associadas a ela serão eliminadas. Esta operação não poderá ser revertida."
                                />
                            </Col>
                        )}

                        <Col xs={24}>
                            <Form.Item label="Designação">
                                {getFieldDecorator("descricao", {
                                    initialValue: modalData.descricao,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório!",
                                        },
                                    ],
                                })(<Input disabled={!isEditableDesignation(isEditable)}></Input>)}
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("designacao", {
                                    initialValue: modalData.designacao || "",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório!",
                                        },
                                    ],
                                })(<TextArea name="descricao" rows={4} disabled={!isEditable} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                {modalData.idautorizacao && (
                    <>
                        <Spin tip="Carregando..." spinning={loadingUtilization}>
                            <Typography.Title level={3}>Utilização</Typography.Title>
                            <Collapse>
                                <Panel
                                    header={`Utilizadores (${Utilitaries.toArray(utilizations?.users).length || 0})`}
                                    key="1"
                                >
                                    <div>
                                        {utilizations.users?.map((user, index) => (
                                            <Tag
                                                key={"menu" + index}
                                                color={user.status !== "Ativo" ? "red" : ""}
                                                style={{ marginTop: 4 }}
                                            >
                                                {user.username}
                                            </Tag>
                                        ))}
                                    </div>
                                </Panel>
                                <Panel
                                    header={`Grupo Funcionais (${
                                        Utilitaries.toArray(utilizations?.groups).length || 0
                                    })`}
                                    key="2"
                                >
                                    <div>
                                        {utilizations.groups?.map((grpup, index) => (
                                            <Tag
                                                key={"group-" + index}
                                                color={grpup.estado !== "Ativo" ? "red" : ""}
                                                style={{ marginTop: 4 }}
                                            >
                                                {grpup.descricao}
                                            </Tag>
                                        ))}
                                    </div>
                                </Panel>
                                <Panel header={`Tarefas (${utilizations?.totalTasks || 0})`} key="3">
                                    {Object.values(utilizations?.processes || {})?.map((process, index) => (
                                        <div key={`process-` + index} className="margin_top-not-first">
                                            <Typography.Text
                                                strong
                                                type={process.forma_iniciar === "_obsoleto_" ? "danger" : ""}
                                            >
                                                {process.process_code + " - " + process.process_name}
                                            </Typography.Text>
                                            <div>
                                                {process.tasks?.map((task, index) => (
                                                    <Tag key={"process-task-" + index} style={{ marginTop: 4 }}>
                                                        {task.task_name}
                                                    </Tag>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </Panel>
                                <Panel
                                    header={`Relatórios (${Utilitaries.toArray(utilizations?.reports).length || 0})`}
                                    key="4"
                                >
                                    {utilizations.reports?.map((report, index) => (
                                        <Tag
                                            key={"report-" + index}
                                            color={report.estado_versao === "Obsoleto" ? "red" : ""}
                                            style={{ marginTop: 4 }}
                                        >
                                            {report.codigo + " - " + report.nome}
                                        </Tag>
                                    ))}
                                </Panel>
                                <Panel
                                    header={`Menus (${Utilitaries.toArray(utilizations?.menus).length || 0})`}
                                    key="5"
                                >
                                    <div>
                                        {utilizations.menus?.map((menu, index) => (
                                            <Tag
                                                key={"menu-" + index}
                                                color={menu.activo !== "t" ? "red" : ""}
                                                style={{ marginTop: 4 }}
                                            >
                                                {menu.titulo !== menu.descricao
                                                    ? menu.titulo + " - " + menu.descricao
                                                    : menu.titulo}
                                            </Tag>
                                        ))}
                                    </div>
                                </Panel>
                            </Collapse>
                        </Spin>
                        <Typography.Text disabled>Os itens a vermelho estão inativos ou obsoletos</Typography.Text>
                    </>
                )}
            </Modal>
        </>
    );
};

export default Form.create({})(FormAuthorization);
