import { Col } from "antd";
import React from "react";
import { checkHasColSize, checkInputHasInputChild, getInputColSize } from "../../../utils/FormMapper/formHandle";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";
import { FormInputsProps, RenderContextType } from "../../../types/formTypes";
import { QADivider } from "../FormComponents/Divider";

interface Props {
    componentsProperties: FormInputsProps;
    renderContext: RenderContextType;
}

export const Group: React.FC<Props> = ({ children, componentsProperties, renderContext }) => {
    // cato tem grupo renderizard
    // nao tem colocar COL
    // sem grupo e sem col
    let colSize: number | undefined = getInputColSize(componentsProperties);
    const hasFormItem = checkInputHasInputChild(componentsProperties, false);
    const hasCol = checkHasColSize(componentsProperties, true);

    colSize = hasFormItem ? (!hasCol ? undefined : 24) : colSize;
    colSize = renderContext === "column" ? undefined : colSize;

    const noRenderCol =
        (renderContext === "inputGroup" && componentsProperties.type === COMPONENT_TYPE.INPUT_GROUP) ||
        renderContext === "column";

    return (
        <>
            {componentsProperties.group && (
                <Col xs={24}>
                    <QADivider componentsProperties={componentsProperties} />
                </Col>
            )}

            {componentsProperties.style?.includes("quebra") && <Col xs={24}></Col>}

            {!noRenderCol && (
                <Col xs={24} md={colSize}>
                    {children}
                </Col>
            )}

            {noRenderCol && children}
        </>
    );
};
