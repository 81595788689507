import React from "react";

import { QAInput } from "../FormComponents/Input";
import { QATab } from "../FormComponents/Tab";
import { QASelect } from "../FormComponents/Select";
import { QAInputGroup } from "../FormComponents/InputGroup";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";
import { QAAlert } from "../FormComponents/Alert";
import { QATimePicker } from "../FormComponents/TimePicker";
import { QADivider } from "../FormComponents/Divider";
import { QACheckBox } from "../FormComponents/CheckBox";
import { QAFixedList } from "../FormComponents/FixedList";
import { QARadio } from "../FormComponents/Radio";
import { QAAutoComplete } from "../FormComponents/AutoComplete";
import { QAUpload } from "../FormComponents/Upload";
import { QALink } from "../FormComponents/Link";
import { QADate } from "../FormComponents/Date";
import { QADataDisplay } from "../FormComponents/DataDisplay";
import { QAButton } from "../FormComponents/Button";
import { QALinkToProcess } from "../FormComponents/LinkToProcess";
import { QAReport } from "../FormComponents/Report";
import { QADynamicList } from "../FormComponents/DynamicList";

import {
    FormAttributesProps,
    FormInputsProps,
    FormRootElementAttributesProps,
    RenderContextType,
} from "../../../types/formTypes";
import { Group } from "./Group";
import { QAPopUP } from "../FormComponents/PopUp";
import { QATable } from "../FormComponents/Table";
import { QASelectStatic } from "../FormComponents/SelectStatic";
import { QADataGrid } from "../FormComponents/Datagrid";
import { useFormState } from "../hooks/FormState";
import { CommunComponentsProps, InputInTableProps } from "../../../types/common";

interface Props {
    renderContext: RenderContextType;
    componentsProperties: FormInputsProps;
    tableRow?: InputInTableProps;
    justSee?: boolean;
    rootElementAttribute: FormRootElementAttributesProps;
}

export function ComponentRender({
    renderContext,
    componentsProperties,
    tableRow,
    justSee,
    rootElementAttribute,
}: Props) {
    const {
        modals,
        changeModalState,
        handleOnInputChangeValue,
        formInputDependenceState,
        inputCalcTrigger,
        state: formState,
        buttonClick,
        handleTableAddRow,
        handleTableRemoveRow,
        handleOnTableChangeDataSource,
        setFieldEditor,
        popup_params,
        triedSave,
    } = useFormState();

    function renderComponent() {
        const defaultProps: CommunComponentsProps = {
            componentsProperties: componentsProperties,
            renderContext: renderContext,
            formInputDependenceState,
            handleOnInputChangeValue: handleOnInputChangeValue,
            formState: formState,
            tableRow: tableRow,
            inputCalcTrigger: inputCalcTrigger,
            justSee,
            triedSave,
            rootElementAttribute,
        };

        switch (componentsProperties.type) {
            // case COMPONENT_TYPE.BOOLEAN:
            //     return <QACheckBox componentsProperties={componentsProperties} renderContext={renderContext} />;

            case COMPONENT_TYPE.INPUT_TIME:
                return <QATimePicker {...defaultProps} />;

            case COMPONENT_TYPE.DIVIDER:
                return <QADivider componentsProperties={componentsProperties} />;

            case COMPONENT_TYPE.ALERT:
                return <QAAlert componentsProperties={componentsProperties} />;

            case COMPONENT_TYPE.INPUT_GROUP:
                return (
                    <QAInputGroup
                        componentsProperties={{ ...componentsProperties }}
                        renderContext={renderContext}
                        formState={formState}
                        inputCalcTrigger={inputCalcTrigger}
                        justSee={justSee}
                        rootElementAttribute={rootElementAttribute}
                    />
                );

            case COMPONENT_TYPE.INPUT:
            case COMPONENT_TYPE.INPUT_NUMBER:
            case COMPONENT_TYPE.INPUT_AREA:
                return <QAInput {...defaultProps} setFieldEditor={setFieldEditor} />;

            case COMPONENT_TYPE.DYNAMIC_LIST:
                return <QADynamicList {...defaultProps} />;

            case COMPONENT_TYPE.STATIC_LIST:
                return <QASelectStatic {...defaultProps} />;

            case COMPONENT_TYPE.BOOLEAN:
                // case COMPONENT_TYPE.SELECTION_BUTTONS:
                return <QARadio {...defaultProps} />;

            case COMPONENT_TYPE.SELECT:
                // case COMPONENT_TYPE.COMBOBOX:
                return <QASelect {...defaultProps} />;

            case COMPONENT_TYPE.SUGESTION:
                return <QAAutoComplete componentsProperties={componentsProperties} renderContext={renderContext} />;

            case COMPONENT_TYPE.UPLOAD:
                return <QAUpload {...defaultProps} />;

            case COMPONENT_TYPE.LINK:
                return <QALink {...defaultProps} />;

            case COMPONENT_TYPE.TAB:
                return (
                    <QATab
                        componentsProperties={componentsProperties}
                        formState={formState}
                        renderContext={renderContext}
                        justSee={justSee}
                        rootElementAttribute={rootElementAttribute}
                    />
                );

            case COMPONENT_TYPE.DATE:
                return <QADate {...defaultProps} />;

            case COMPONENT_TYPE.DATA_DISPLAY:
                return <QADataDisplay {...defaultProps} />;

            case COMPONENT_TYPE.BUTTON:
                // case COMPONENT_TYPE.ICON:
                return <QAButton {...defaultProps} changeModalState={changeModalState} buttonClick={buttonClick} />;

            case COMPONENT_TYPE.LINK_TO_PROCESS:
                // case COMPONENT_TYPE.ICON:
                return <QALinkToProcess {...defaultProps} justSee={justSee} />;

            case COMPONENT_TYPE.REPORT:
                return <QAReport {...defaultProps} />;

            // case COMPONENT_TYPE.READONLY:
            //     return <QAInput componentsProperties={componentsProperties} renderContext={renderContext} />;

            case COMPONENT_TYPE.DYNAMIC_TABLE:
            case COMPONENT_TYPE.SELECTION_TABLE:
                return (
                    <QATable
                        componentsProperties={componentsProperties}
                        formState={formState}
                        handleTableAddRow={handleTableAddRow}
                        handleTableRemoveRow={handleTableRemoveRow}
                        renderContext={renderContext}
                        handleOnTableChangeDataSource={handleOnTableChangeDataSource}
                        popup_params={popup_params}
                        justSee={justSee}
                        formInputDependenceState={formInputDependenceState}
                        inputCalcTrigger={inputCalcTrigger}
                        rootElementAttribute={rootElementAttribute}
                    />
                );

            case COMPONENT_TYPE.DYNAMIC_DATAGRID:
            case COMPONENT_TYPE.DATAGRID:
                return (
                    <QADataGrid
                        componentsProperties={componentsProperties}
                        renderContext={renderContext}
                        formState={formState}
                        handleTableAddRow={handleTableAddRow}
                        handleTableRemoveRow={handleTableRemoveRow}
                        handleOnTableChangeDataSource={handleOnTableChangeDataSource}
                        formInputDependenceState={formInputDependenceState}
                        justSee={justSee}
                        rootElementAttribute={rootElementAttribute}
                        inputCalcTrigger={inputCalcTrigger}
                    />
                );

            case COMPONENT_TYPE.POPUP:
                return (
                    <QAPopUP
                        componentsProperties={componentsProperties}
                        modals={modals}
                        changeModalState={changeModalState}
                        handleOnInputChangeValue={handleOnInputChangeValue}
                        justSee={justSee}
                    />
                );

            default:
                return (
                    <div>
                        <span style={{ color: "silver" }}>UI Widget: {componentsProperties.type}</span>
                        <span style={{ color: "red" }}> (em construção...)</span>{" "}
                    </div>
                );
        }
    }
    return <> {renderComponent()}</>;
}
