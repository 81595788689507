import { Col, Form, Input } from "antd";
import React, { useState, useContext, useEffect } from "react";
import moment from "moment";

import FormContext from "../context/FormContext";
import { QADivider, QAToolTip } from "./";
import { getInputLabel, getElementAction, addDoubleQuotesToParams } from "../utils/functions";
import { input_validations, getAllDependencies } from "../utils/validations";
import { Utilitaries } from "../../../business";
import { sendHttpRequest } from "../../../components/http.request";
import { LineBreak } from "./lineBreak";

const QaDate = ({
    value,
    inputAttribute,
    field,
    visible,
    renderGroup,
    hasError,
    isRequired,
    colSize,
    ...inputProps
}) => {
    const { changeInputValue, justSee, formData, updateError, formErrors, isInitial, validateField } =
        useContext(FormContext);

    const [error, setError] = useState(null);

    const dateStyles = {
        width: "100%",
    };
    const { inTable } = inputProps;

    const getValueFormData = (_nodes, field) => {
        if (_nodes && Array.isArray(_nodes) && _nodes.length > 0) {
            let objValue = formData;
            for (const iterator of _nodes) {
                objValue = objValue?.[iterator];
            }
            return objValue?.[field];
        } else {
            return formData?.[field];
        }
    };

    // Get value
    let valueDep = [];
    let inputActionsName = null;

    const elementAction = getElementAction({ item: inputAttribute });
    if (elementAction) {
        valueDep = getAllDependencies(elementAction.valueDep);
        inputActionsName = elementAction.inputActionsName;
    }

    //Update error
    useEffect(() => {
        if (updateError) setError(formErrors[field]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateError]);

    useEffect(
        () => {
            const getData = async () => {
                try {
                    let expression = input_validations({
                        item: inputAttribute,
                        relKey: inputActionsName,
                        returnExpression: true,
                        formData,
                        inputProps,
                    });
                    expression = addDoubleQuotesToParams(expression);

                    const expressionValue = await eval(`${expression}`);

                    const resultKeys = typeof expressionValue === "object" ? Object.keys(expressionValue) : null;
                    const inputValue = resultKeys
                        ? resultKeys.length === 1
                            ? expressionValue[resultKeys[0]]
                            : expressionValue[inputAttribute["@chave_lista"]]
                        : expressionValue;

                    changeInputValue({
                        value: inputValue,
                        key: field,
                        inputAttribute,
                        appLoading: true,
                        noMessage: true, // nao mostrar pop up com mesagem de campo errado
                        ...inputProps,
                    });
                } catch (error) {
                    console.error(error, field, inputAttribute);
                }
            };

            if (
                !inputAttribute[inputActionsName] ||
                !formData ||
                (inputProps?.inTable && inputProps.items.data[field] && isInitial) ||
                (inputAttribute?.["@action_with_value"] === "false" &&
                    isInitial &&
                    ((inputProps?.inTable && inputProps.items.data[field]) || value))
            ) {
                return;
            }

            getData();
        },
        //eslint(react-hooks/exhaustive-deps)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueDep
            .filter((it) => it !== field)
            .map((it) => {
                if (inputProps?.items?.data[it]) return inputProps.items.data[it];

                return formData ? formData[it] : null;
            })
    );

    const auto_preenche_valor = async (query, ...params) => {
        const fd = new FormData();
        fd.append("query", query);
        fd.append("accao", "query");

        let nullValues = false;
        params
            .map((it) => it.replace(/[{}\s]/g, "").split(","))
            .forEach((it) => {
                // eslint-disable-next-line no-eval
                const val = eval(it[1]);

                if (
                    (!val || val === undefined) &&
                    query !== "equip_preenche_nome" &&
                    !Utilitaries.toString(it?.[0]).startsWith("id_")
                ) {
                    nullValues = true;
                }

                fd.append(it[0], val === "t" ? "true" : val);
            });

        if (nullValues) {
            return;
        }
        try {
            const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", fd);
            return data.result[0];
        } catch (error) {
            return null;
        }
    };

    // eslint-disable-next-line no-unused-vars
    const auto_preenche = auto_preenche_valor;

    if (!visible) {
        return null;
    }

    if (value) {
        if (inTable) {
            if (!inputProps.items.data[field])
                changeInputValue({
                    value,
                    key: field,
                    inputAttribute,
                    appLoading: true,
                    callback: () => {
                        validateField({
                            value,
                            key: field,
                            inputAttribute,
                            showErrorInModal: false,
                            ...inputProps,
                            visible,
                        });
                    },
                    ...inputProps,
                });
        } else {
            let val = getValueFormData(inputProps?._nodes, field);
            if (!val) {
                changeInputValue({
                    value,
                    key: field,
                    inputAttribute,
                    appLoading: true,
                    callback: () => {
                        validateField({
                            value,
                            key: field,
                            inputAttribute,
                            showErrorInModal: false,
                            ...inputProps,
                            visible,
                        });
                    },
                    ...inputProps,
                });
            }
        }
    }

    const RenderInput = () => (
        <Input
            type="date"
            value={
                value?.toString()
                    ? moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                    : formData?.[field]
                    ? moment(formData[field], "YYYY-MM-DD").format("YYYY-MM-DD")
                    : null
            }
            disabled={justSee || (inputAttribute["@apenas_leitura"] && inputAttribute["@apenas_leitura"] === "true()")}
            style={dateStyles}
            onBlur={(e) => {
                console.log(e.target.value);
            }}
            onChange={(date) => {
                changeInputValue({
                    value: date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                    key: field,
                    inputAttribute,
                    noMessage: true,
                    ...inputProps,
                });
            }}
        />
    );

    return inTable ? (
        <>
            {RenderInput()}
            {error && <span style={{ color: "red" }}>{error}</span>}
        </>
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            {<LineBreak item={inputAttribute} />}
            <Col xs={24} sm={24} md={colSize}>
                <Form.Item
                    label={
                        <QAToolTip
                            label={getInputLabel(inputAttribute, field)}
                            help={inputAttribute["@ajuda"]}
                            isRequired={isRequired}
                        />
                    }
                >
                    {RenderInput()}
                    {error && <span style={{ color: "red", display: "block" }}>{error}</span>}
                </Form.Item>
            </Col>
        </>
    );
};

export default QaDate;
