import React, { useState } from "react";
import { Table, Select, Tooltip } from "antd";
import { Utilitaries } from "../../../../business";

const { Option } = Select;

const TaskReasign = ({
    dataSource,
    setDataSource,
    selectedRowKeys,
    setSelectedRowKeys,
    handleGetUserByGroup,
    groups,
    usersByGroup,
    isFetched,
}) => {
    function onUserChange({ user, record }) {
        const recordToChange = { ...record, user };
        const list = [...dataSource];
        const index = list.findIndex((item) => recordToChange.key === item.key);
        list[index] = recordToChange;
        setDataSource(list);
    }

    const handleGroupChange = (group, record) => {
        const recordToChange = { ...record, group, user: null };
        const list = [...dataSource];
        const index = list.findIndex((item) => recordToChange.key === item.key);
        list[index] = recordToChange;
        setDataSource(list);
        handleGetUserByGroup({ group, task: record.id_tarefa });
    };

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        selectedRowKeys,
        onSelect: (record, selected, selectedRows, nativeEvent) => {
            setSelectedRowKeys(selectedRows.map((el) => el.key));

            const recordToChange = { ...record, isChecked: selected };
            const list = [...dataSource];
            const index = list.findIndex((item) => recordToChange.key === item.key);
            list[index] = recordToChange;
            setDataSource(list);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    const getStyleSelect = (value, record) => {
        const styleError =
            record.isChecked === true && Utilitaries.isEmpty(value)
                ? {
                      backgroundColor: "#e78080",
                      border: "1px solid #f5222d",
                  }
                : {};
        return styleError;
    };

    const columns = [
        {
            title: "Nº Tarefa",
            dataIndex: "id_tarefa",
            width: "5%",
        },
        {
            title: "Tarefa",
            dataIndex: "nome_tarefa",
            width: "10%",
        },
        {
            title: "Utilizador Pedido",
            dataIndex: "user_pedido",
            width: "10%",
        },
        {
            title: "Data Pedido",
            dataIndex: "data_pedido",
            width: "8%",
        },
        {
            title: "Motivo Reatribuição",
            dataIndex: "obs",
            width: "32%",
            render: (obs) => {
                if (!obs) return "";

                return obs.length > 70 ? (
                    <Tooltip title={obs}>
                        <div
                            style={{
                                textOverflow: "ellipsis",
                                width: "90%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            {obs}
                        </div>
                    </Tooltip>
                ) : (
                    obs
                );
            },
        },
        {
            title: "Responsável Execução (Grupo)",
            dataIndex: "group",
            width: "15%",
            render: (text, record) => {
                return (
                    <Select
                        showSearch
                        allowClear
                        style={{
                            width: "100%",
                            ...getStyleSelect(text, record),
                        }}
                        placeholder="Selecione o grupo"
                        optionFilterProp="children"
                        onChange={(value) => {
                            handleGroupChange(value, record);
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {groups.map((group) => {
                            const groupName = typeof group.grupo === "object" ? "" : group.grupo;
                            return <Option value={group.id}>{groupName}</Option>;
                        })}
                    </Select>
                );
            },
        },
        {
            title: "Responsável Execução (Indivíduo)",
            dataIndex: "user",
            width: "15%",
            render: (text, record) => {
                const list =
                    usersByGroup[record.group] !== undefined ? Utilitaries.toArray(usersByGroup[record.group]) : [];

                return (
                    <Select
                        showSearch
                        allowClear
                        style={{ width: "100%", ...getStyleSelect(text, record) }}
                        placeholder="Selecione o responsável"
                        optionFilterProp="children"
                        onChange={(value) => onUserChange({ user: value, record })}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={text || undefined}
                    >
                        {list.map((user) => {
                            return <Option value={user.util}>{user.nome}</Option>;
                        })}
                    </Select>
                );
            },
        },
    ];

    return (
        <>
            <Table
                size="small"
                loading={!isFetched}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
            />
        </>
    );
};

export default TaskReasign;
