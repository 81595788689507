import React, { useState } from "react";
import { Typography, Alert, Form, Input, Icon, Button } from "antd";
import { Link } from "react-router-dom";

import { api } from "../../http.request";
import Layout from "../Common/Layout";

import "./styles.css";

const { Title, Text } = Typography;

const RequestResetPassword = (props) => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        props.form.validateFields(async (err, values) => {
            if (!err) {
                const { username, email } = values;
                if (
                    (username == null || username === "" || username === undefined) &&
                    (email === null || email === "" || email === undefined)
                ) {
                    setError("Pelo menos um dos valores (utilizador ou email) tem de ser preenchido.");
                    return;
                }
                setLoading(true);
                const formData = new FormData();
                formData.append("usernameInput", username);
                formData.append("emailInput", email);
                try {
                    const resp = await api("/Gestor/recuperar_senha_enviado.php", formData);
                    setSuccess(true);
                } catch (error) {
                    setError(error.erro);
                    setSuccess(false);
                }
                setLoading(false);
            }
        });
    };

    const { getFieldDecorator } = props.form;

    const PassChangedSuccess = () => (
        <div style={{ marginTop: "auto", marginBottom: "auto", paddingLeft: 40 }}>
            <Title>Recuperar senha</Title>
            <Text>Enviado email para recuperação de senha. Verifique na sua caixa de entrada!</Text>
            <br />
            <Link className="" to="/login">
                Voltar para login
            </Link>
        </div>
    );

    return success ? (
        <Layout>
            <PassChangedSuccess />
        </Layout>
    ) : (
        <Layout>
            <div className="reset-body">
                <Title>Recuperar senha</Title>
                <Title level={4}>Indique o seu username ou email e clique em recuperar senha</Title>

                <div>{error && <Alert message={error} type="error" banner />}</div>

                <Form onSubmit={handleSubmit} className="reset-form">
                    <Form.Item
                        label="Nome de utilizador"
                        help="No caso do seu utilizador existir na aplicação, será enviado, para o e-mail associado, uma mensagem que permite redefinir uma nova senha."
                    >
                        {getFieldDecorator("username", {
                            rules: [],
                        })(
                            <Input
                                disabled={loading}
                                prefix={<Icon type="profile" style={{ color: "rgba(0,0,0,.25)" }} />}
                                placeholder="Informe o utilizador"
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        help="Se não for indicado o nome de utilizador ou se este não existir na aplicação será verificado se o email indicado pertence a algum utilizador e será enviado email para este email."
                        style={{ marginTop: 20 }}
                    >
                        {getFieldDecorator("email", {
                            rules: [
                                {
                                    type: "email",
                                    message: "Informe um email válido!",
                                },
                            ],
                        })(
                            <Input
                                disabled={loading}
                                prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                                type="email"
                                placeholder="Indique o email"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Link className="login-form-forgot" to="/login">
                            Voltar para login
                        </Link>
                        <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                            Redifinir senha
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    );
};

export default Form.create({ name: "normal_reset_pass" })(RequestResetPassword);
