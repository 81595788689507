import React, { Component } from "react";
import { Tabs, PageHeader } from "antd";
import Competence from "./competence";
import CompetenceType from "./competence.type";
const { TabPane } = Tabs;

class CompetenceIndex extends Component {
    goto = (path) => {
        this.props.history.push({
            pathname: path,
        });
    };

    render() {
        return (
            <>
                <PageHeader title="Lista Competências" />
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Tipos de Competências" key="1">
                        <CompetenceType />
                    </TabPane>
                    <TabPane tab="Competências" key="2">
                        <Competence />
                    </TabPane>
                </Tabs>
            </>
        );
    }
}
export default CompetenceIndex;
