import React from "react";
import { Carousel } from "antd";
import IMG1 from "../../../assets/image/unicv_slide/IMG_0026-2.jpg";
import IMG2 from "../../../assets/image/unicv_slide/IMG_0032-2.jpg";
import IMG3 from "../../../assets/image/unicv_slide/IMG_0047-2.jpg";
import IMG4 from "../../../assets/image/unicv_slide/IMG_0064-2.jpg";
import IMG5 from "../../../assets/image/unicv_slide/IMG_0091-2.jpg";
import IMG6 from "../../../assets/image/unicv_slide/IMG_0115-2.jpg";
import { IS_UNICV } from "../../../constants/enviroment";

const CarouselComponent = () =>
    IS_UNICV ? (
        <Carousel autoplay dots={false}>
            <div>
                <img className="auth-slide-img" src={IMG1} alt="unicv" />
            </div>
            <div>
                <img className="auth-slide-img" src={IMG2} alt="unicv" />
            </div>
            <div>
                <img className="auth-slide-img" src={IMG3} alt="unicv" />
            </div>
            <div>
                <img className="auth-slide-img" src={IMG4} alt="unicv" />
            </div>
            <div>
                <img className="auth-slide-img" src={IMG5} alt="unicv" />
            </div>
            <div>
                <img className="auth-slide-img" src={IMG6} alt="unicv" />
            </div>
        </Carousel>
    ) : (
        <Carousel autoplay dots={false}>
            <div>
                <h3>1</h3>
            </div>
            <div>
                <h3>2</h3>
            </div>
            <div>
                <h3>3</h3>
            </div>
            <div>
                <h3>4</h3>
            </div>
            <div>
                <h3>5</h3>
            </div>
            <div>
                <h3>6</h3>
            </div>
        </Carousel>
    );

export default CarouselComponent;
