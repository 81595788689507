import { Col, Form, Checkbox } from "antd";
import React, { useContext, useState, useEffect } from "react";

import FormContext from "../context/FormContext";
import { getInputLabel } from "../utils/functions";
import { QADivider, QAToolTip } from "./";

const QaMultiChachBox = ({
    inputAttribute,
    value,
    field,
    visible,
    disabled,
    colSize,
    hasError,
    renderGroup,
    isRequired,
    ...inputProps
}) => {
    const { changeInputValue, justSee, updateError, formErrors } = useContext(FormContext);
    const [inputValue, setInputValue] = useState(Array.isArray(value) ? value : value ? value.split(" ") : []);
    const [error, setError] = useState(null);

    //Update error
    useEffect(() => {
        if (updateError) setError(formErrors[field]);
    }, [field, formErrors, updateError]);

    if (!visible) {
        return null;
    }

    let options = [];

    if (/\[\s*([^)]+?)\s*\]/.test(inputAttribute["@valores"])) {
        let args = inputAttribute["@valores"]
            .split(";")
            .filter((it) => it != "")
            .map((it) => {
                it = it.replace("[", "");
                it = it.replace("]", "");
                return it;
            });
        // .match(/\[\s*([^)]+?)\s*\]/g)
        // .map((it) => /[\w,]+/.exec(it)[0]);

        // if (args[1]) {
        //     // args = args[1].split(";");
        //     options = args.map((it) => {
        //         const opt = it.split(",");
        //         return {
        //             label: opt[0],
        //             value: opt[1],
        //         };
        //     });
        // }

        if (Array.isArray(args) && args.length > 0) {
            // args = args[1].split(";");
            options = args.map((it) => {
                const opt = it.split(",");
                return {
                    label: opt[0],
                    value: opt[1],
                };
            });
        }
    } else {
        options = inputAttribute["@valores"].split(";").map((it) => {
            return {
                label: it,
                value: it,
            };
        });
    }

    const RenderInput = () => (
        <Checkbox.Group
            options={options}
            disabled={justSee || disabled}
            defaultValue={inputValue}
            onChange={(checkedList) => {
                const _err = hasError({ [field]: checkedList.join(" ") });
                setError(_err);
                changeInputValue({
                    value: checkedList.join(" "),
                    key: field,
                    inputAttribute,
                    ...inputProps,
                });
            }}
        />
    );

    const { inTable } = inputProps;

    return inTable ? (
        RenderInput()
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            <Col xs={colSize}>
                <Form.Item
                    label={
                        <QAToolTip
                            label={getInputLabel(inputAttribute, field)}
                            help={inputAttribute["@ajuda"]}
                            isRequired={isRequired}
                        />
                    }
                >
                    {RenderInput()}
                    {error && (
                        <>
                            <br />
                            <span style={{ color: "red" }}>{error}</span>
                        </>
                    )}
                </Form.Item>
            </Col>
        </>
    );
};

export default QaMultiChachBox;
