import React, { useState } from "react";
import { Row, Col, Form, Input, Collapse } from "antd";
import { useParams } from "react-router-dom";

import Requirement from "./requirement";
import Knowledge from "./knowledge";
import Competence from "./competence";
import Hability from "./hability";
import Behavior from "./behavior";

const { Panel } = Collapse;

const Profile = ({
    // selectDataFromExecQuery, //redux action
    form, // from father component,
    dataFromExistGroup,
    selectedGrupoExist,
    set_requisito_necessario,
    requisito_necessario,
    set_conhece_necessarios,
    conhece_necessarios,
    set_competencia_necessaria,
    competencia_necessaria,
    set_habilidade_necessaria,
    habilidade_necessaria,
    set_parametro_comportamental,
    parametro_comportamental,
}) => {
    const { id } = useParams();
    const { getFieldDecorator } = form;
    const {
        codigo,
        total_ponderacao_requi,
        total_ponderacao_habilidades,
        total_ponderacao_conhe,
        total_ponderacao_comport,
        total_ponderacao_compet,
        soma_ponderacao_competencias,
        soma_ponderacao_conhecimentos,
        soma_ponderacao_habilidades,
        soma_ponderacao_requisitos,
        soma_ponderacao_paramcomp,
    } = dataFromExistGroup;

    const [requirementPonderationCalculeValue, setRequirementPonderationCalculeValue] = useState(undefined);
    const [knowledgePonderationCalculeValue, setKnowledgePonderationCalculeValue] = useState(undefined);
    const [competencePonderationCalculeValue, setCompetencePonderationCalculeValue] = useState(undefined);
    const [habilityPonderationCalculeValue, setHabilityPonderationCalculeValue] = useState(undefined);
    const [behavoirPonderationCalculeValue, setBehavoirPonderationCalculeValue] = useState(undefined);

    const customPanelHeader = (title, inputDefaultValue, inputFieldName, calculatedValue, calculatedFieldValue) => {
        return (
            <Row gutter={32}>
                <Col xs={6}>
                    <h3>{title}</h3>
                </Col>
                <Col
                    xs={6}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <span style={{ marginRight: 4 }}>Ponderação da dimensão </span>
                    <Form.Item style={{ margin: 0, padding: 0 }}>
                        {getFieldDecorator(`${inputFieldName}`, {
                            initialValue: inputDefaultValue,
                        })(
                            <Input
                                type="number"
                                style={{ width: "100px" }}
                                size="small"
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {}}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col
                    xs={6}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <span style={{ marginRight: 4 }}>Ponderação na dimensão </span>
                    <Form.Item style={{ margin: 0, padding: 0 }}>
                        {getFieldDecorator(`${calculatedFieldValue}`, {
                            initialValue: calculatedValue,
                        })(
                            <Input
                                readOnly
                                type="number"
                                style={{ width: "100px" }}
                                size="small"
                                onClick={(e) => e.stopPropagation()}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Collapse
                defaultActiveKey={id ? ["1", "2", "3", "4", "5"] : selectedGrupoExist ? ["1", "2", "3", "4", "5"] : 0}
            >
                <Panel
                    header={customPanelHeader(
                        "Requisitos",
                        total_ponderacao_requi,
                        "total_ponderacao_requi",
                        requirementPonderationCalculeValue,
                        "soma_ponderacao_requisitos"
                    )}
                    key="1"
                >
                    <Requirement
                        form={form}
                        selectedGrupoExist={selectedGrupoExist}
                        idGrupoExist={id}
                        soma_ponderacao_requisitos={soma_ponderacao_requisitos}
                        set_requisito_necessario={set_requisito_necessario}
                        requisito_necessario={requisito_necessario}
                        setRequirementPonderationCalculeValue={setRequirementPonderationCalculeValue}
                    />
                </Panel>

                <Panel
                    header={customPanelHeader(
                        "Conhecimentos",
                        total_ponderacao_conhe,
                        "total_ponderacao_conhe",
                        knowledgePonderationCalculeValue,
                        "soma_ponderacao_conhecimentos"
                    )}
                    key="2"
                >
                    <Knowledge
                        form={form}
                        selectedGrupoExist={selectedGrupoExist}
                        idGrupoExist={id}
                        soma_ponderacao_conhecimentos={soma_ponderacao_conhecimentos}
                        set_conhece_necessarios={set_conhece_necessarios}
                        conhece_necessarios={conhece_necessarios}
                        setKnowledgePonderationCalculeValue={setKnowledgePonderationCalculeValue}
                    />
                </Panel>

                <Panel
                    header={customPanelHeader(
                        "Competências",
                        total_ponderacao_compet,
                        "total_ponderacao_compet",
                        competencePonderationCalculeValue,
                        "soma_ponderacao_competencias"
                    )}
                    key="3"
                >
                    <Competence
                        form={form}
                        selectedGrupoExist={selectedGrupoExist}
                        idGrupoExist={id}
                        soma_ponderacao_competencias={soma_ponderacao_competencias}
                        set_competencia_necessaria={set_competencia_necessaria}
                        competencia_necessaria={competencia_necessaria}
                        setCompetencePonderationCalculeValue={setCompetencePonderationCalculeValue}
                    />
                </Panel>

                <Panel
                    header={customPanelHeader(
                        "Habilidades",
                        total_ponderacao_habilidades,
                        "total_ponderacao_habilidades",
                        habilityPonderationCalculeValue,
                        "soma_ponderacao_habilidades"
                    )}
                    key="4"
                >
                    <Hability
                        form={form}
                        selectedGrupoExist={selectedGrupoExist}
                        idGrupoExist={id}
                        soma_ponderacao_habilidades={soma_ponderacao_habilidades}
                        set_habilidade_necessaria={set_habilidade_necessaria}
                        habilidade_necessaria={habilidade_necessaria}
                        setHabilityPonderationCalculeValue={setHabilityPonderationCalculeValue}
                    />
                </Panel>

                <Panel
                    header={customPanelHeader(
                        "Comportamentos",
                        total_ponderacao_comport,
                        "total_ponderacao_comport",
                        behavoirPonderationCalculeValue,
                        "soma_ponderacao_paramcomp"
                    )}
                    key="5"
                >
                    <Behavior
                        form={form}
                        selectedGrupoExist={selectedGrupoExist}
                        idGrupoExist={id}
                        soma_ponderacao_paramcomp={soma_ponderacao_paramcomp}
                        set_parametro_comportamental={set_parametro_comportamental}
                        parametro_comportamental={parametro_comportamental}
                        setBehavoirPonderationCalculeValue={setBehavoirPonderationCalculeValue}
                    />
                </Panel>
            </Collapse>
        </>
    );
};

export default Profile;
