import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Menu, Empty } from "antd";

import { themes } from "../constants/index";

const Rooms = ({ list, changeRoom }) => {
    useEffect(
        () => {
            if (list && list.length > 0 && list[0].id) {
                changeRoom(list[0].id);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [list]
    );

    const handlerSalaClick = (e) => {
        changeRoom(e.item.props["data-id"]);
    };

    return (
        <Menu
            onClick={handlerSalaClick}
            defaultSelectedKeys={["0"]}
            mode="inline"
            style={{
                maxHeight: "91vh",
                overflowY: "auto",
                overflowX: "hidden",
            }}
        >
            <Menu.ItemGroup key="s1" title="Temas">
                {themes.map((it, idx) => (
                    <Menu.Item key={it.key} data-id={it.key}>
                        {it.title}
                    </Menu.Item>
                ))}
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup key="g1" title="Salas">
                {list && Array.isArray(list) ? (
                    list.map((it, idx) => (
                        <Menu.Item key={idx} data-id={it.id}>
                            {it.nome}
                        </Menu.Item>
                    ))
                ) : (
                    <Empty />
                )}
            </Menu.ItemGroup>
        </Menu>
    );
};

Rooms.propTypes = {
    list: PropTypes.array,
    changeRoom: PropTypes.func,
};

export default Rooms;
