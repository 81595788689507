import React from "react";
import { Row, Button, Tooltip, Icon, Checkbox } from "antd";
import { downloadFile } from "../../../../components/util";

const ButtonsGroup = ({ filter, REPORT_PARAMS, deletedRecords, handleOnClick, showDeletedRecords, setIsEdit }) => {
    const exportList = async (type) => {
        let params = {
            ...REPORT_PARAMS,
            ...filter,
            saida: type,
            downloadInBrowser: true,
        };

        await downloadFile("gerelistas.php", params);
    };

    return (
        <Row>
            <Button
                onClick={() => {
                    handleOnClick(true);
                    setIsEdit(false);
                }}
                type="primary"
                style={{ float: "right", marginLeft: 8, marginBottom: 16 }}
            >
                <Icon type="plus" />
                Inserir
            </Button>
            <Tooltip title="Exportar para pdf">
                <Button
                    onClick={() => exportList("pdf")}
                    icon="file-pdf"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#fa541c",
                    }}
                />
            </Tooltip>
            <Tooltip title="Exportar para excel">
                <Button
                    onClick={() => exportList("xls")}
                    icon="file-excel"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#237804",
                    }}
                />
            </Tooltip>
            <Checkbox onChange={() => showDeletedRecords(!deletedRecords)}>Mostrar registos eliminados</Checkbox>
        </Row>
    );
};

export default ButtonsGroup;
