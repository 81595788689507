export const actionType = {
    setAttributionType: "SET_ATTRIBUTION_TYPE",
    setTaskAuthorizations: "SET_TASK_AUTHORIZATIONS",
    removeTaskAuthorizations: "REMOVE_TASK_AUTHORIZATIONS",
    updateProcessDefinition: "UPDATE_PROCESS_DEFINITION",
    setTaskDescription: "SET_TASK_DESCRIPTION",
    setGroupToDelegateProcessDef: "SET_GROUP_TO_DELEGATE_PROCESS_DEF",
    setUserToDelegateProcessDef: "SET_USER_TO_DELEGATE_PROCESS_DEF",
    addGoalIndicator: "ADD_GOAL_INDICATOR",
    removeGoalIndicator: "REMOVE_GOAL_INDICATOR",
    setPolicyGoalIndicator: "SET_POLICY_GOAL_INDICATOR",
};
