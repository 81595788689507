import React from "react";
import { Modal, Row, Col, Form, Input, Button } from "antd";
const { TextArea } = Input;
const CorrectionReason = ({ isVisible, handleCancel, data }) => {
    return (
        <Modal
            title="Motivo da correção realizada"
            visible={isVisible}
            onCancel={handleCancel}
            footer={[
                <Button key="submit" type="primary" onClick={handleCancel}>
                    Ok
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <Row gutter={18}>
                    <Col xs={24} sm={24} md={24}>
                        <TextArea autosize={{ minRows: 2, maxRows: 6 }} value={data.motivo} disabled />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CorrectionReason;
