export const DEFAULT_COLORS = {
    nodeStroke: "#5B8FF9",
    nodeFill: "#C6E5FF",
    arrowColor: "#e2e2e2",
    endArrowFill: "#e2e2e2",

    TASKS_FINISHED: "#2577C4",
    TASKS_GOING: "#24B56D",
    TASKS_CANCELLED: "#C21717",
    TASKS_UNASSIGNED: "#D3D957",
};
