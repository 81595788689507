import { Col, Form, Radio } from "antd";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Utilitaries } from "../../../business";

import FormContext from "../context/FormContext";
import { getInputLabel } from "../utils/functions";
import { QADivider, QAToolTip } from "./";
// import { getInputColSize } from "../utils/functions";

const QaRadio = ({
    inputAttribute,
    field,
    visible,
    value,
    hasError,
    renderGroup,
    isRequired,
    disabled,
    colSize,
    ...inputProps
}) => {
    const [itens, setItens] = useState([]);
    // const [select, setSelect] = useState(value);
    const [error, setError] = useState(null);

    const { changeInputValue, justSee, updateError, formErrors } = useContext(FormContext);

    //Update error
    useEffect(() => {
        if (updateError) setError(formErrors[field]);
    }, [field, formErrors, updateError]);

    useEffect(() => {
        // alteracao adiciona || valores em 10-07-2020
        if ((inputAttribute["@tipo"] && inputAttribute["@tipo"] === "lista_estatica") || inputAttribute["@valores"]) {
            let dados = inputAttribute["@valores"].split(";");
            dados = dados?.filter((el) => !Utilitaries.isEmpty(el?.trim()));
            dados = dados.map((it) => it.replace(/[\[|\]]/g, ""));

            if (inputAttribute["@valores"].includes(",")) {
                dados = dados.map((item) => item.split(","));
            } else {
                dados = dados.map((item) => [item, item]);
            }

            setItens(dados);
        } else if (inputAttribute["@tipo"] && inputAttribute["@tipo"] === "boolean") {
            setItens([
                ["Sim", "t"],
                ["Não", "f"],
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // const colSize = useMemo(() => {
    //     return getInputColSize(inputAttribute["@estilo"]);
    // }, [inputAttribute["@estilo"]]);

    const RadioInput = () => (
        <Radio.Group
            value={value}
            defaultValue={itens && itens[0] ? itens[0][1] : ""}
            disabled={justSee || disabled}
            onChange={(e) => {
                // setSelect(e.target.value);
                const _err = hasError({ [field]: e.target.value });
                setError(_err);
                changeInputValue({
                    value: e.target.value,
                    key: field,
                    inputAttribute,
                    ...inputProps,
                });
            }}
        >
            {itens.map((it, idx) => (
                <Radio key={"radio-" + idx} value={it[1]}>
                    {it[0]}
                </Radio>
            ))}
        </Radio.Group>
    );

    const { inTable } = inputProps;

    const renderItem = () =>
        inTable ? (
            RadioInput()
        ) : (
            <>
                {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
                <Col xs={24} md={colSize}>
                    <Form.Item
                        label={
                            <QAToolTip
                                label={getInputLabel(inputAttribute, field)}
                                help={inputAttribute["@ajuda"]}
                                isRequired={isRequired}
                            />
                        }
                    >
                        {RadioInput()}
                        {error && <span style={{ color: "red", display: "block" }}>{error}</span>}
                    </Form.Item>
                </Col>
            </>
        );

    return visible ? renderItem() : null;
};

export default QaRadio;
