import React, { useState, useEffect } from "react";
import { PageHeader, Row, Tabs, Radio, Col, Button, Icon, message } from "antd";

import { sendHttpRequest } from "../../components/http.request";

const { TabPane } = Tabs;

const ReportConfig = () => {
    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    };

    const [opcoesCabecalho, setOpcoesCabecalho] = useState("");

    useEffect(() => {
        (async () => {
            const requestBody = new FormData();
            requestBody.append("configurar_cabecalho", "get_configuration");
            const response = await sendHttpRequest("POST", "/Gestor/gereRelatorioListagen.php", requestBody);
            if (!response.status) {
                message.error("Ocorreu um erro");
                return;
            }

            if (response.data) {
                const { header_options } = response.data;
                setOpcoesCabecalho(header_options);
            }
        })();
    }, []);

    const handleRadioChange = (e) => {
        setOpcoesCabecalho(e.target.value);
    };

    const handleSave = async () => {
        const requestBody = new FormData();
        requestBody.append("configurar_cabecalho", opcoesCabecalho);

        const response = await sendHttpRequest("POST", "/Gestor/gereRelatorioListagen.php", requestBody);
        if (response.status && response.status === "error") message.error(response.message);

        message.success(response.message);
    };

    return (
        <>
            <PageHeader title="Configurar relatório das listagens" />
            <Row>
                <Tabs defaultActiveKey="1" onChange={() => {}}>
                    <TabPane tab="Cabeçalho" key="1">
                        <Row>
                            <Radio.Group onChange={handleRadioChange} value={opcoesCabecalho}>
                                <Radio style={radioStyle} value="all_page">
                                    Cabeçalho em todas as páginas
                                </Radio>
                                <Radio style={radioStyle} value="first_page">
                                    Cabeçalho apenas na primeira página
                                </Radio>
                                <Radio style={radioStyle} value="none">
                                    Sem cabeçalho
                                </Radio>
                            </Radio.Group>
                        </Row>
                    </TabPane>
                </Tabs>
            </Row>
            <Row gutter={18} style={{ marginTop: 16 }}>
                <Col xs={24} sm={12}>
                    <div className="qa-group-actions-left">
                        <Button
                            // loading={}
                            type="primary"
                            onClick={handleSave}
                            icon="save"
                        >
                            Guardar
                        </Button>
                        {/* <Button type="primary">
                            <Icon type="printer" />
                                Imprimir
                            </Button>
                        <Button type="primary">
                            <Icon type="save" />
                                Guardar Temporário
                            </Button> */}
                    </div>
                </Col>

                {/* <Col xs={24} sm={12}>
                    <div className="qa-group-actions-right">
                        <Button
                            icon="undo"
                            type="primary"
                            htmlType="submit"
                        >
                            Reformular
                            </Button>
                        <Button
                            icon="cloud-upload"
                            type="primary"
                            htmlType="submit"
                        >
                            Carregar Local
                            </Button>
                        <Button
                            icon="play-circle"
                            type="primary"
                            htmlType="submit"
                        >
                            Gravar Local
                            </Button>
                    </div>
                </Col> */}
            </Row>
        </>
    );
};

export default ReportConfig;
