export const actionType = {
    initProcess: "PROCESS_INIT",
    toggleModal: "TOGGLE_MODAL",
    initCondition: "INIT_CONDITION",
    initModals: "INIT_MODALS",
    getForm: "GET_FORM",
    setSubProcess: "SET_SUB_PROCESS",
    setTaskDetails: "SET_TASK_DETAILS",
    setPreviousTasks: "SET_PREVIOUS_TASKS",
    getFormData: "GET_FORM_DATA",
    isFormReady: "IS_FORM_READY",
    changeViewerSelected: "SELECTED_VIEWER",
};

// Info: properties that starts with _ eg:_propname was added in xml
export const xmlProps = {
    _modal: "@modal",
    calculate: "@calcular",
    componentType: "@tipo",
    action: "@accao_",
    popupForm: "@popupform",
    autoSetValue: "auto_preenche_valor",
    graphicElement: "@elemento_grafico",
    autoSet: "auto_preenche",
    condiitonalParent: "@relevante",
    valueChanged: "@quando_valor_alterado",
    dataSource: "@dados",
    dataSourceTable: "@tabela",
    size: "@size",
    text: "@text",
    opType: "@optype",
    type: "@type",
    icon: "@icon",
    style: "@estilo",
    values: "@valores",
    imagem: "@imagem",
    buttonAction: "@bottao_acao",
};

export const componentStyle = {
    telefone: ["coluna1xp telefone", "coluna2xp  telefone"],
    inputText: [
        "coluna12 minha_opcao",
        "coluna2",
        "coluna12 ",
        "coluna1 ",
        "coluna3 ",
        "coluna2xp nif",
        "coluna2 codigopost tamanhocod4 ",
    ],
};

export const componentType = {
    undefined: undefined,
    sugestion: "sugestao",
    date: "data",
    divider: "grupo",
    dynamicList: "lista_dinamica",
    fixedList: "lista_estatica",
    conjunto: "conjunto",
    boolean: "boolean",
    bool: "bool",
    datagrid: "datagrid",
    integer: "integer",
    int: "int",
    alert: "alert",
    shortText: "texto_curto",
    text: "texto",
    areaTexto: "areaTexto",
    icon: "icon",
    report: "relatorio",
    float: "float",
    string: "string",
    combobox: "combobox",
    upload: "upload_imagem",
    _button: "button",
    _dynamicDatagrid: "dynamicDatagrid",
    _dynamicTable: "dynamicTable",
    _selectionTable: "selectionTable",
    _popup: "popup",
    _tab: "tab",
    _separator: "separator",
    _select: "select",
    _input: "input",
    _inputNumber: "inputNumber",
    _readonly: "readonly",
    _dataDisplay: "etiqueta",
    _selectionButtons: "botoes_seleccao",
    _delete: "deleteAction",
    _inputGroup: "inputGroup",
    _inputTime: "inputTime",
    _checkBox: "checkBox",
    _file: "ficheiro",
    link: "link",
};

export const graphicElement = {
    undefined: undefined,
    singleChoiceList: "lista_escolha_unica",
    singleChoice: "escolha_unica",
    title: "etiqueta",
    selectionButtons: "botoes_seleccao",
    combobox: "combobox",
    button: "botao",
    suggestion: "sugestao",
    multipleSelectionButtons: "botoes_escolha_multipla",
    areaTexto: "areatexto",
};

export const taskStatus = {
    done: "Termindada",
    doing: "Em curso",
    todo: "Por iniciar",
};

export const fieldsToSendZeroNotExist = [
    "id_plano_reunioes",
    "id_cafap_trata_mods",
    "id_elaboracao_contrato",
    "id_auto_aval_ceb",
    "id_crits_aval_ceb",
    "id_cafap_aval_atividades",
    "id_ceb_plano_turma_elab",
    "id_univ_cv_informa_irreg",
];

export const fieldsToAssignAsInputTextIfNotHaveType = [
    "coordenador_eli",
    "eli",
    "morada_eli",
    "telefone",
    "responsavel_caso",
    "telefone2",
    "evidencias",
    "obs_rel",
    "fonte_objetivo",
    "nome",
    "observacoes_vive",
    "aplicados",
    "instrumentos",
    "local",
    "capaz_resolver",
    "precisa_ajuda",
    "preocupa",
    "com_quem",
    "gosta",
    "onde",
    "quando",
    "identificacao",
    "ligacao",
    "participante_reun",
    "email_colab",
];

export const renderTableWithOneField = [];
