import React, { useState } from "react";
import { Table, Row, Button, Select, Popconfirm } from "antd";
import { getTasks } from "../../actions";
const { Option } = Select;

const ProcessTabPane = ({ formState, setFormState, initialFormData }) => {
    const [taskList, setTaskList] = useState({});

    const handleName = (name) => name.replace(/[\s|-]/g, "");

    const onSelectItem = async (item, key) => {
        handleSelectedChange(key, "processo", item);

        if (taskList[handleName(item)]) {
            return;
        }
        const list = await getTasks(item);
        setTaskList({
            ...taskList,
            [handleName(item)]: list,
        });
    };

    const column = [
        {
            title: "Processo",
            dataIndex: "processo",
            width: "40%",
            render: (value, row) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    optionFilterProp="children"
                    onChange={(value) => onSelectItem(value, row.__key__)}
                    value={value}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {initialFormData.processList.map((it) => (
                        <Option key={`proc_list_${it.nome}`} value={it.nome}>
                            {it.nome}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Tarefa",
            dataIndex: "tarefa",
            width: "40%",
            render: (value, row) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", padding: "0" }}
                    optionFilterProp="children"
                    value={value}
                    onChange={(value) => handleSelectedChange(row.__key__, "tarefa", value)}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {taskList[handleName(row.processo)]
                        ? taskList[handleName(row.processo)].map((it) => (
                              <Option key={it.nome} value={it.nome}>
                                  {it.nome}
                              </Option>
                          ))
                        : null}
                </Select>
            ),
        },
        {
            title: "Ação",
            width: "20%",
            render: (text, record) => (
                <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => handleDelete(record.__key__)}>
                    <Button style={{ color: "red" }} type="link">
                        Eliminar
                    </Button>
                </Popconfirm>
            ),
        },
    ];

    const handleSelectedChange = (__key__, key, value) => {
        const dataSource = [...formState.process];

        const newData = dataSource.map((it, idx) => {
            if (idx === __key__) {
                it[key] = value;
            }

            return it;
        });

        setFormState({
            ...formState,
            process: newData,
        });
    };

    const handleAdd = () => {
        const newData = {
            processo: "",
            tarefa: "",
        };

        setFormState({
            ...formState,
            process: [newData, ...formState.process],
        });
    };

    const handleDelete = (key) => {
        const dataSource = [...formState.process];

        setFormState({
            ...formState,
            process: dataSource.filter((item, idx) => idx !== key),
        });
    };

    return (
        <div>
            <Row>
                <Button
                    xs={24}
                    sm={12}
                    onClick={handleAdd}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                pagination={{ pageSize: 8 }}
                size="small"
                rowKey="__key__"
                columns={column}
                dataSource={formState.process.map((it, idx) => ({
                    ...it,
                    __key__: idx,
                }))}
            />
        </div>
    );
};

export default ProcessTabPane;
