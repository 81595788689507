import { Col, Form, Button, Modal, message } from "antd";
import React, { useContext, useState } from "react";

import { QADivider, QAToolTip } from "./";
import FormContext from "../context/FormContext";
import { sendHttpRequest } from "../../http.request";
import { getDataFormInputActions, getInputLabel, hasInputAction } from "../utils/functions";
import { COMPONENT_TYPE } from "../utils/constants";
import { LineBreak } from "./lineBreak";
import { input_validations } from "../utils/validations";

const QaButton = ({ inputAttribute, visible, disabled, field, objectInputs, renderGroup, colSize, ...inputProps }) => {
    const { setTableData, formData, params, rootElement, changeInputValue } = useContext(FormContext);
    const [loading, setLoading] = useState(false);

    const click = async () => {
        let actions = inputAttribute["@quando_valor_alterado"];
        setLoading(true);
        if (inputAttribute["@funcao"] && inputAttribute["@funcao"] === "carregar_tabela") {
            let dados = inputAttribute["@dados"]?.split(",");

            const fd = new FormData();
            fd.append("accao", "query");
            fd.append("query", dados?.[0]);

            let hasNull = false;

            if (inputAttribute["@param_popup"]) {
                const _params = inputAttribute["@param_popup"].split(";");
                _params.forEach((it) => {
                    if (it.includes("$params.")) {
                        const popParam = it.split(",");
                        const key = popParam[1].replace("$params.", "");

                        fd.append(popParam[0], params[key]);
                    } else {
                        if (!formData) {
                            hasNull = true;
                        }
                        fd.append(it, formData[it] || "");
                    }
                });
            } else if (dados.length > 1) {
                try {
                    dados = inputAttribute["@dados"].replace(/ /g, "").split(",{");
                    // remove first item(query name) in array
                    dados.shift();
                    dados.forEach((item) => {
                        const keyValue = item?.split(",");
                        if (keyValue?.[0]?.trim()) {
                            const key = keyValue?.[1]?.replace(/\W/g, "");
                            fd.append(keyValue[0], formData[key] || "");
                        }
                    });
                } catch (error) {
                    console.error(error);
                }
            }

            if (hasNull) {
                return;
            }

            try {
                return sendHttpRequest("POST", "/Gestor/execQuery.php", fd)
                    .then((data) => {
                        if (data && typeof data === "object") {
                            setTableData({
                                key: inputAttribute["@key"],
                                table: inputAttribute["@table"], //undefined,
                                data: data.result,
                            });
                        } else {
                            console.error("Ocorreu um erro");
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (error) {
                setTableData({
                    key: inputAttribute["@key"],
                    table: inputAttribute["@table"], //undefined,
                    data: [],
                });
            }

            return;
        }

        if (!actions) {
            return;
        }

        if (inputAttribute["@funcao"] && inputAttribute["@funcao"] === "calcular_valor") {
            const { fields } = hasInputAction({
                key: field,
                formInputs: objectInputs,
                input: inputAttribute,
                formData,
                options: inputProps,
            });

            if (Array.isArray(fields)) {
                fields.forEach((el) => {
                    const inputActionsName = el?.deps;

                    let expression = input_validations({
                        item: el?.attributes,
                        relKey: inputActionsName,
                        formData: formData,
                        inputProps: inputProps,
                        functions: {},
                        returnExpression: false,
                    });

                    changeInputValue({
                        key: el.key,
                        value: expression,
                        inputAttribute,
                        appLoading: true,
                        ...inputProps,
                    });
                });
            }
            setLoading(false);
            return;
        }

        // actions = actions.split(";").filter((it) => it !== "");
        // upter dados do api

        const results = await getDataFormInputActions({
            key: field,
            input: inputAttribute,
            formInputs: objectInputs,
            formData,
            forceSendParamsEmpty: true,
            rootElement,
            options: inputProps,
        });

        for (const result of results) {
            if (result.tipo === COMPONENT_TYPE.conjunto) {
                const _tableKey = Object.keys(result.input.attributes).filter((it) => !it.startsWith("@"))[0];

                setTableData({
                    table: result.key,
                    key: _tableKey,
                    data: result.value,
                });
            } else if (result.tipo === COMPONENT_TYPE.datagrid) {
                setTableData({
                    key: result.key,
                    table: "linha",
                    data: result.value,
                });
            } else if (result?.input?.action === "auto_preenche") {
                // ;
                if (!result.value) {
                    message.error("Ocorreu um erro");
                }
            } else if (result?.input?.action === "auto_preenche_popup") {
                const executeAction = result?.input?.attributes?.[`@accao_${inputAttribute["@quando_valor_alterado"]}`];
                try {
                    eval(executeAction);
                } catch (error) {
                    message.error("Ocorreu um erro");
                }
            }
            // changeInputValue({
            //     key: result.key,
            //     value: result.value,
            //     ...options,
            // });
        }
        setLoading(false);
    };

    // @accao_duplicar: "auto_preenche_popup('Duplicação efetuada!')"
    // eslint-disable-next-line no-unused-vars
    const auto_preenche_popup = (text) => {
        Modal.info({
            title: "Informação",
            content: <div>{text}</div>,
        });
    };

    const getIcon = () => {
        if (inputAttribute["@imagem"]) {
            if (inputAttribute["@imagem"].includes("duplicar")) {
                return "copy";
            } else if (inputAttribute["@imagem"].includes("refresh") || inputAttribute["@imagem"].includes("iniciar")) {
                return "reload";
            } else {
                return "edit";
            }
        }

        return "";
    };

    // if (!visible && Object.keys(inputAttribute).find((it) => it?.startsWith("@grupo_relevante_")) !== undefined) {
    //     return null;
    // }
    if (!visible) {
        return null;
    }

    const RenderInput = () => (
        <Button onClick={click} icon={getIcon()} disabled={disabled} loading={loading}>
            {getInputLabel(inputAttribute, field)}
        </Button>
    );

    const { inTable } = inputProps;

    return inTable ? (
        RenderInput()
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            {<LineBreak item={inputAttribute} />}
            <Col xs={colSize}>
                <Form.Item
                    label={<QAToolTip label={getInputLabel(inputAttribute, field)} help={inputAttribute["@ajuda"]} />}
                >
                    {RenderInput()}
                </Form.Item>
            </Col>
        </>
    );
};

export default QaButton;
