export const actionTypes = {
    selectOrganizationStandart: "SELECT_ORGANIZATION_STANDARTS",
    getFormulario: "GET_FORMULARIO_ORGANIZATION_STANDARTS",
    save: "SAVE_ORGANIZATION_STANDARTS",
    selectQaStandart: "SELECT_QA_STANDARTS",
    getImportFormulario: "GET_FORMULARIO_QA_NORMA",
    getDetailsQaNormas: "GET_DETAILS_QA_NORMA",
};

export const qaStandartActions = {
    import: "importar",
    export: "exportar",
};

export const lista = {
    normas_organizacao: "normas_instituicao",
    normas_qa: "util_inserir_normas_list",
};
export const count_requirement = "contar_requisitos_norma";
export const count_scale = "contar_escalas";
export const dimensionProps = {
    1: {
        key: "requ_norma",
        id: "id_requisitos_norma",
        table: "requisito",
        subDimension: "clausulas_norma",
        countSubDimension: "contar_clausulas_requisitos",
    },
    2: {
        key: "clau_norma",
        id: "id_clausulas_norma",
        table: "clausulas",
        subDimension: "sub_clausulas_norma",
        countSubDimension: "contar_sub_clausulas_clausulas",
    },
    3: {
        key: "sub_clau_norma",
        id: "id_sub_clausulas_norma",
        table: "sub_clausulas",
        subDimension: "alineas_norma",
        countSubDimension: "contar_alineas_sub_clausulas",
    },
    4: {
        key: "ali_norma",
        id: "id_alineas_norma",
        table: "alineas",
        subDimension: "sub_dimensao_norma",
        countSubDimension: "contar_sub_dimensao_alinea",
    },
    5: {
        key: "dim_norma",
        id: "id_dimensao_norma",
        table: "dimensoes",
        subDimension: "sub_dimensao_norma",
        countSubDimension: "contar_sub_dimensao_dimensao",
    },
};
export const scaleProps = {
    key: "escala_norma",
    table: "escala",
};
