import React from "react";
import { Modal, Form, Row, Col, Input, Button, Icon, Upload } from "antd";

const NewRegister = ({ isVisible, showNewRegister }) => {
    return (
        <>
            <Modal
                title="Inserir Novo Formulário"
                visible={isVisible !== null}
                onCancel={() => showNewRegister(null)}
                footer={null}
                confirmLoading={false}
                destroyOnClose
            >
                <Form layout="vertical">
                    <Row gutter={18}>
                        <Col xs={24} sm={12} md={24}>
                            <Form.Item label="Código">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={20}>
                            <Form.Item label="CódigoFormulário">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={4}>
                            <Form.Item label="Ficheiro">
                                <Upload>
                                    <Button>
                                        <Icon type="file-add" />
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary">
                        <Icon type="save" />
                        Inserir
                    </Button>
                </Form>
            </Modal>
        </>
    );
};

export default NewRegister;
