import { Modal } from "antd";
const { confirm } = Modal;

const Confirm = (handleCancel, description = null) =>
    confirm({
        title: "Tem a certeza que pretende continuar?",
        content: description || "Atenção, poderá perder a informação inserida.",
        okType: "danger",
        onOk: () => {
            handleCancel();
        },
        onCancel() {},
    });

export default Confirm;
