import { Divider } from "antd";
import React from "react";

const QaDivider = ({ itemData }) => (
    <div>
        <span>
            <strong>{itemData["@grupo"]}</strong>
        </span>
        <Divider style={{ marginTop: 8 }} />
    </div>
);

export default QaDivider;
