import { actionTypes, lista } from "../constants/index";
import { sendHttpRequest } from "../../../components/http.request";

export const selectOrganizationStandart = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "lista");
    formData.append("lista", lista.normas_organizacao);
    Object.entries(payload).forEach(([key, val]) => {
        formData.append(key, val);
    });
    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse)
            dispatch({
                type: actionTypes.selectOrganizationStandart,
                payload: jsonResponse,
            });
    });
};

export const getFormulario = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista.normas_organizacao);

    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.formulario) {
            dispatch({
                type: actionTypes.getFormulario,
                payload: jsonResponse,
            });
            const popupForm = jsonResponse.formulario.normas_auditorias.requisitos_norma["@attributes"].popupform;
            return {
                formulario: jsonResponse.formulario,
                formularioDimensao: popupForm,
                formularioEscala: jsonResponse.formulario.normas_auditorias.escala["@attributes"].popupform,
            };
        } else {
            return false;
        }
    });
};

export const save = (payload) => async (dispatch) => {
    const formData = new FormData();
    const obj = {};
    const values = { ...payload.values };
    const etiquetas = values.etiquetas.toString();
    values.etiquetas_dimensao = etiquetas.replace(/,/g, "£");
    delete values.etiquetas;

    const formAttrbitutes = payload.formulario["@attributes"];
    obj[formAttrbitutes.elemento_raiz] = values;

    formData.append("accao", "guardar");
    formData.append("formulario", formAttrbitutes.nome);
    formData.append("dados", JSON.stringify(obj));

    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse.sucesso && jsonResponse.sucesso !== "0" ? true : false;
    });
};

export const getDetails = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista.normas_organizacao);
    formData.append("chave1", payload.id);
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse) {
            dispatch({
                type: actionTypes.getFormulario,
                payload: jsonResponse,
            });
            const data = Object.entries(jsonResponse.valores.normas_auditorias);
            const values = {};

            data.forEach(([key, value]) => (values[key] = typeof value === "object" ? null : value));

            values.etiquetas = values.etiquetas_dimensao ? values.etiquetas_dimensao.split("£") : [];
            delete values.etiquetas_dimensao;

            const result = {
                values,
                formulario: jsonResponse.formulario,
                formularioDimensao: jsonResponse.formulario.normas_auditorias.requisitos_norma["@attributes"].popupform,
                formularioEscala: jsonResponse.formulario.normas_auditorias.escala["@attributes"].popupform,
            };
            return result;
        } else return false;
    });
};

export const remove = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "eliminar");
    formData.append("lista", lista.normas_organizacao);
    formData.append("chave1", payload);
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        return jsonResponse.sucesso ? true : false;
    });
};
