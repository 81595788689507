import React from "react";
import { Button, Tooltip, Icon } from "antd";
import { downloadFile } from "../../../components/util";

const ButtonsGroup = (props) => {
    const exportList = async (type) => {
        const REPORT_PARAMS = {
            accao: "lista",
            campos: "descricao:200:Designação;designacao:600:Descrição;",
            titulo: "Autorizações do utilizador",
        };

        const { sorter, ...objFilter } = props.filter;

        if (sorter.columnKey) {
            objFilter["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        await downloadFile("gereautorizacoes.php", {
            ...REPORT_PARAMS,
            ...objFilter,
            saida: type,
            downloadInBrowser: true,
        });
    };
    return (
        <>
            <Button
                onClick={() => props.handleAdd()}
                type="primary"
                style={{ float: "right", marginLeft: 8, marginBottom: 16 }}
            >
                <Icon type="plus" />
                Inserir
            </Button>
            <Tooltip title="Exportar para pdf">
                <Button
                    onClick={() => exportList("pdf")}
                    icon="file-pdf"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#fa541c",
                    }}
                />
            </Tooltip>
            <Tooltip title="Exportar para excel">
                <Button
                    onClick={() => exportList("xls")}
                    icon="file-excel"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#237804",
                    }}
                />
            </Tooltip>
        </>
    );
};

export default ButtonsGroup;
