import React from "react";
import { Form, Row, Col, Checkbox } from "antd";
import { componentType } from "../../constants/index";
import PropTypes from "prop-types";
import { canCorrectField } from "../../utils/fields";
import { Utilitaries } from "../../../../business";

const QaCheckBox = ({
    dataSource,
    type,
    itemData,
    correctForm,
    handleRadioChange,
    label,
    formItemStyle,
    root,
    currentTaskStatus,
    columnDataSource,
    rowKey,
    error,
    onChange,
}) => {
    const getColItem = () => {
        let colItem = 12;
        if (itemData["@estilo"]?.includes("coluna600qa")) {
            colItem = dataSource.length > 0 && isNaN(dataSource[0].value) ? 6 : 2;
        } else if (itemData["@estilo"]?.includes("qa_col_12")) {
            colItem = dataSource.length > 0 && isNaN(dataSource[0].value) ? 6 : 4;
        } else if (itemData["@estilo"].includes("calenda_diasmes")) {
            colItem = 4;
        } else if (itemData["@estilo"].includes("calenda_meses")) {
            colItem = 4;
        }

        return colItem;
    };

    const options = dataSource.map(({ key, value }) => ({
        label: value,
        value: key,
    }));

    const inputValue = Array.isArray(itemData.value)
        ? itemData.value
        : itemData.value
        ? Utilitaries.toArray(itemData.value)
        : [];

    let col = 12;

    col = getColItem();

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus;

    // const handleValues = (value, get = true) => {
    //     if (get) {
    //         return Utilitaries.toString(value).replace(/\s/g, "_");
    //     } else {
    //         return Utilitaries.toString(value).replace(/_/g, " ");
    //     }
    // };

    const handleChange = (checkedValues) => {
        onChange(Utilitaries.toString(checkedValues));
        // onChange(handleValues(checkedValues, true));
    };

    return (
        <Form.Item label={label} style={formItemStyle} colon={itemData["@etiqueta"] !== ""}>
            {dataSource.length ? (
                dataSource.length === 1 && itemData?.["@elemento_grafico"] !== "botoes_escolha_multipla" ? (
                    <Checkbox
                        checked={itemData.value === "true" ? true : false}
                        disabled={disabled}
                        // disabled={currentTaskStatus || canCorrectField(itemData)}
                        onChange={(e) => onChange(e.target.checked ? "true" : "false")}
                    >
                        {dataSource[0]?.value}
                    </Checkbox>
                ) : (
                    <>
                        <Checkbox.Group
                            disabled={disabled}
                            style={{ width: "100%" }}
                            // disabled={currentTaskStatus || canCorrectField(itemData)}
                            defaultValue={inputValue}
                            onChange={handleChange}
                        >
                            <Row>
                                {dataSource.map((item, index) => (
                                    <Col key={`checkbox-${itemData.key}-${index}`} span={col}>
                                        <Checkbox
                                            value={Utilitaries.toString(
                                                item?.[itemData?.["@chave_lista"]] || item.key
                                            ).replace(/\s/g, "_")}
                                        >
                                            {itemData?.["@sem_label"] === "true"
                                                ? ""
                                                : item?.[itemData?.["@valor_lista"]] || item.value}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </>
                )
            ) : null}
            {error && <span style={{ color: "red" }}>{error}</span>}
        </Form.Item>
    );
};

export default QaCheckBox;

QaCheckBox.propTypes = {
    name: PropTypes.string,
};
