import React, { useContext, useState } from "react";
import { Modal } from "antd";
import FormContext from "../context/FormContext";
import QAForm from "../../Form";
import Confirm from "../../qa_custom/confirm";

const QAPopUp = ({ visible, onCancel, input, params, formId, updateOnSave }) => {
    const { justSee, autoLoadPupUpFromModel, justConsult, ignoreData } = useContext(FormContext);
    const [justSeePopup, setJustSeePopup] = useState(false);

    const onCancelModal = () => {
        if (justSeePopup) {
            onCancel();
        } else {
            Confirm(() => {
                onCancel();
            });
        }
    };

    const setJustSeeInPopup = (consultForm) => {
        if (consultForm === "sim") {
            setJustSeePopup(true);
        } else if (justConsult === "sim" || justSee) {
            setJustSeePopup(true);
        } else {
            setJustSeePopup(false);
        }
    };

    return input ? (
        <Modal
            title={"Formulário"}
            visible={visible}
            onOk={() => {}}
            destroyOnClose
            onCancel={onCancelModal}
            width="80%"
            footer={null}
        >
            <QAForm
                isModal={true}
                formName={input["@popupform"]}
                params={params}
                justSee={justSee}
                formId={formId}
                loadFromModel={autoLoadPupUpFromModel || false}
                updateOnSave={updateOnSave}
                setJustSeeInPopup={setJustSeeInPopup}
            />
        </Modal>
    ) : null;
};

export default QAPopUp;
