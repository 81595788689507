import React, { useEffect, useState } from "react";
import { getUserAuthorizations } from "../actions/user";
import { UserAuthorization } from "../types/user";

interface UserContextProps {
    autorizations: UserAuthorization[];
    hasAuthorization: (authorization: string) => boolean;
}

const UserContext = React.createContext<UserContextProps>({} as UserContextProps);

interface UserProviderProps {
    children: React.ReactNode;
}
export function UserProvider({ children }: UserProviderProps) {
    const [autorizations, setAutorizations] = useState<UserAuthorization[]>([]);

    useEffect(() => {
        const fetchAutorizations = async () => {
            const userAuthorizations = await getUserAuthorizations();
            setAutorizations(userAuthorizations);
        };

        fetchAutorizations();
    }, []);

    function hasAuthorization(authorization: string) {
        return autorizations.some((autorization) => autorization.descricao === authorization);
    }

    return <UserContext.Provider value={{ autorizations, hasAuthorization }}>{children}</UserContext.Provider>;
}

export function useUser() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
}
