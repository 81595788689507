import {
    AllModalProps,
    FormAttributesProps,
    FormInputsObjectProps,
    FormRootElementAttributesProps,
    XMLFormInputProps,
    XMLFormProps,
} from "../../types/formTypes";
import { addSeparators } from "./addSeparators";
import { addFormInputTypes } from "./addType";
import { addKeyToFirstsLevelInputs, getFormAttributesAndFields, removeArrayFromXML } from "./formAttributes";
import { getFormModals } from "./getFormModal";
import { COMPONENT_TYPE } from "./XMLInputType";

interface FormMapperResultProps {
    formInputs: FormInputsObjectProps;
    modal: AllModalProps;
    formAttributes: FormAttributesProps;
    formRootElementAttributes: FormRootElementAttributesProps;
}

export function formMapper(form: XMLFormProps): FormMapperResultProps {
    let formInputsArray: XMLFormInputProps[] = [];
    let modalsState: AllModalProps = {};

    form = removeArrayFromXML(form);
    let { formAttributes, formInputs, formRootElementAttributes } = getFormAttributesAndFields(form);

    // add key to first level
    formInputs = addKeyToFirstsLevelInputs(formInputs, formAttributes);
    formInputsArray = Object.values(formInputs);

    // get modals
    const modals = getFormModals(formInputsArray);
    formInputsArray.push(...modals);

    // Add inputs attributes
    let formInputsArrayFinal = addFormInputTypes(formInputsArray);

    // get tabs
    formInputsArrayFinal = addSeparators(formInputsArrayFinal);

    const modalList = formInputsArrayFinal.filter((item) => item.type === COMPONENT_TYPE.POPUP);

    if (modalList.length > 0) {
        for (const modal of modalList) {
            modalsState[modal.key] = {
                key: modal.key,
                isVisible: false,
            };
        }
    }

    const formInputsObjects = formInputsArrayFinal.reduce((form, field) => {
        if (!Array.isArray(field)) {
            form[field.key] = { ...field };
        }
        return form;
    }, {} as FormInputsObjectProps);

    return {
        formInputs: formInputsObjects,
        modal: modalsState,
        formAttributes,
        formRootElementAttributes,
    };
}
