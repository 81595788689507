import { actionType } from "../constants/contributor";
import { sendHttpRequest } from "../../../components/http.request";
import { validateResponse } from "../../../components/util";

export const postDataToGereFicheiros = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return await sendHttpRequest("POST", "/Gestor/gereficheiros.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};

export const selectDataFromGereFormulário = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return await sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};

export const postDataToGereProcesso = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};

export const select = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "colaboradores");
    Object.entries(payload).forEach(([key, val]) => {
        formData.append(key, val);
    });
    await sendHttpRequest("POST", "/Gestor/geremanualfuncoes.php", formData).then((jsonResponse) => {
        if (jsonResponse) {
            dispatch({ type: actionType.select, payload: jsonResponse });
        }
    });
};

export const getContributors = async (payload) => {
    const formData = new FormData();
    formData.append("accao", "colaboradores");
    Object.entries(payload).forEach(([key, val]) => {
        if (val) {
            formData.append(key, val);
        }
    });
    const response = await sendHttpRequest("POST", "/Gestor/geremanualfuncoes.php", formData);
    if (response) {
        const metadata = response["@attributes"];
        const lista = validateResponse(response);
        return {
            lista,
            metadata: {
                resultados: Number(metadata.resultados),
                pagina: Number(metadata.pagina),
                visivel: metadata.visivel,
                resultados_pagina: Number(metadata.resultados_pagina),
            },
        };
    }
    return false;
};

export const insertValidationPermission = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};

export const selectDepartment = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return await sendHttpRequest("POST", "/Gestor/execQuery.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};

export const selectDataFromExecQuery = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return await sendHttpRequest("POST", "/Gestor/execQuery.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};

export const insertDepartment = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};

export const insert = (payload) => async (dispatch) => {
    //todo: insert in api, then stor
    dispatch({ type: actionType.insert, payload });
    return new Promise((resolve, reject) => {
        resolve(true);
    });
};

export const setField = (payload) => ({ type: actionType.setField, payload });

export const update = (payload) => async (dispatch) => {
    //todo: update in api, then store
    return dispatch({ type: actionType.update, payload });
};

export const remove = (payload) => async (dispatch) => {
    //todo: remove in api, then store
    return dispatch({ type: actionType.remove, payload });
};
