import React, { useEffect, useState } from "react";
import { message, Select } from "antd";
import { Modal, Table, PageHeader, Button, Form, Popover, Checkbox, Row, Col, Typography, Popconfirm } from "antd";

const { Text } = Typography;
const { Option } = Select;

const ExecutionAuthorization = ({ authsPermissions, handleOk, handleCancel, authorizations, isModalVisible }) => {
    const [dataSource, setDataSource] = useState(authsPermissions ? authsPermissions : []);
    const [deletedItems, setDeletedItem] = useState([]);
    const [key, setKey] = useState(0);
    const [acessGroupAll, setAcessGroupAll] = useState({ exec: false, read: false, print: false });

    useEffect(() => {
        if (dataSource?.length > 0) {
            const isAllExecSelected = !dataSource.find((item) => !item.exec);
            const isAllReadSelected = !dataSource.find((item) => !item.read);
            const isAllPrintSelected = !dataSource.find((item) => !item.print);
            setAcessGroupAll({ exec: isAllExecSelected, read: isAllReadSelected, print: isAllPrintSelected });
        }
    }, [dataSource]);

    useEffect(() => {
        if (authsPermissions) {
            setDataSource(authsPermissions);
        }
    }, [authsPermissions]);

    const handleAdd = () => {
        const newData = {
            key,
            authId: null,
            exec: false,
            read: false,
            print: false,
        };
        setDataSource([...dataSource, newData]);
        setKey(key + 1);
    };

    const handleDelete = (key) => {
        const dataSourceWithoutItem = dataSource.filter((item) => item.authId !== key);
        setDeletedItem([...deletedItems, dataSource.find((item) => item.authId === key)]);
        setDataSource(dataSourceWithoutItem);
        message.info(`Autorização  removida, ainda pode ser revertida`, 3);
        // message.info(
        //     `Autorização ${
        //         authorizations.find((item) => item.autorizacao === key)?.designacao
        //     } removida, ainda pode ser revertida`,
        //     3
        // );
    };

    const handleSelectAuthorizationsChange = (key, value) => {
        const updatedDataSource = dataSource.map((item) => {
            if (item.key === key) {
                item.authId = value;
            }
            return item;
        });
        setDataSource(updatedDataSource);
    };

    const handleCheckboxAccessChange = (e) => {};

    const onCancel = () => {
        handleCancel();
        setDataSource([...dataSource, ...deletedItems]);
        setDeletedItem([]);
        setKey(0);
    };

    const onOk = () => {
        const isToDelete = deletedItems.length > 0 ? true : false;
        handleOk(dataSource, isToDelete);
        setDeletedItem([]);
    };

    const handleAccessCheckboxChange = (e, key) => {
        const { name, checked } = e.target;
        const dataSourceUpdated = dataSource.map((item) => {
            if (item.authId === key) {
                item[name] = checked;
            }
            return item;
        });
        setDataSource(dataSourceUpdated);
    };

    const handleAccessCheckboxAllChange = (e) => {
        const { name, checked } = e.target;
        const dataSourceUpdated = dataSource.map((item) => {
            item[name] = checked;
            return item;
        });
        setAcessGroupAll[name] = checked;
        setDataSource(dataSourceUpdated);
    };

    const acessGroupSelection = (
        <div>
            <p>
                <Checkbox name="exec" checked={acessGroupAll.exec} onChange={handleAccessCheckboxAllChange}>
                    Executar
                </Checkbox>
            </p>
            <p>
                <Checkbox name="read" checked={acessGroupAll.read} onChange={handleAccessCheckboxAllChange}>
                    Consultar
                </Checkbox>
            </p>
            <p>
                <Checkbox name="print" checked={acessGroupAll.print} onChange={handleAccessCheckboxAllChange}>
                    Imprimir
                </Checkbox>
            </p>
        </div>
    );

    const columns = [
        {
            title: "Autorizações",
            dataIndex: "authorizations",
            width: "40%",
            key: "authorizations",
            render: (text, record) => {
                const key = record.key || 0;
                return (
                    <Select
                        showSearch
                        allowClear
                        style={{ width: "100%" }}
                        value={record.authId}
                        placeholder="Selecione uma autorização"
                        optionFilterProp="children"
                        onChange={(value) => handleSelectAuthorizationsChange(key, value)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {authorizations.map(({ designacao, autorizacao }) => {
                            return (
                                <Option key={autorizacao} value={autorizacao}>
                                    {designacao}
                                </Option>
                            );
                        })}
                    </Select>
                );
            },
        },
        {
            title: (
                <div>
                    <Popover content={acessGroupSelection} title="Selecionar" trigger="click">
                        <Button type="link" icon="edit"></Button>
                    </Popover>
                    <label>Acessos</label>
                </div>
            ),
            dataIndex: "acesses",
            width: "50%",
            key: "acesses",
            render: (text, record) => {
                const { exec, read, print, authId } = record;
                return (
                    <Row>
                        <Col xs={6}>
                            <Checkbox
                                name="exec"
                                checked={exec}
                                onChange={(e) => handleAccessCheckboxChange(e, authId)}
                            >
                                <Text>Executar</Text>
                            </Checkbox>
                        </Col>
                        <Col xs={6}>
                            <Checkbox
                                name="read"
                                checked={read}
                                onChange={(e) => handleAccessCheckboxChange(e, authId)}
                            >
                                <Text>Consultar</Text>
                            </Checkbox>
                        </Col>
                        <Col xs={6}>
                            <Checkbox
                                name="print"
                                checked={print}
                                onChange={(e) => handleAccessCheckboxChange(e, authId)}
                            >
                                <Text>Imprimir</Text>
                            </Checkbox>
                        </Col>
                    </Row>
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) => {
                const { authId } = record;
                return (
                    <a href="javascript:;" style={{ color: "red" }} onClick={() => handleDelete(authId)}>
                        Eliminar
                    </a>
                );
            },
        },
    ];

    return (
        <Modal
            width="60%"
            title="Grupos responsáveis"
            visible={isModalVisible}
            okText="Guardar"
            cancelText="Cancelar"
            onOk={onOk}
            onCancel={onCancel}
        >
            <PageHeader
                title="Autorizações para a execução"
                extra={[
                    <Button icon="plus" onClick={handleAdd} type="primary">
                        Inserir
                    </Button>,
                ]}
                style={{ marginBottom: 10 }}
            />
            <Form>
                <Table size="small" dataSource={dataSource} columns={columns} />
            </Form>
        </Modal>
    );
};

export default ExecutionAuthorization;
