export const actionType = {
    select: "SELECT_TIME_REGISTRATION",
    getForm: "GET_FORM_TIME_REGISTRATION",
    insert: "INSERT_TIME_REGISTRATION",
    setField: "SET_FIELD_TIME_REGISTRATION",
    getById: "GET_TIME_REGISTRATION_BY_ID",
    update: "UPDATE_TIME_REGISTRATION",
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};

export const lista = "qa_registo_ponto";

export const tableActions = {
    detalhes: "DETALHES",
    editar: "EDITAR",
    marcarSaida: "MARCAR_SAIDA",
};
