import { Alert, Col, Form } from "antd";
import React from "react";
import { componentType } from "../../constants/index";

const QaAlert = ({ type, itemData }) => {
    return (
        <Col xs={24} md={itemData.colSize}>
            <Form.Item>
                {type === componentType.alert && (
                    <Alert message={itemData["@etiqueta"]} description={itemData.value} type="info" />
                )}
            </Form.Item>
        </Col>
    );
};
export default QaAlert;
