export const actionType = {
    initProcess: "PROCESS_INIT",
    initModals: "INIT_MODALS",
    getTaskDetails: "GET_TASK_DETAILS",
    addDocument: "ADD_DOCUMENT",
    removeDocument: "REMOVE_DOCUMENT",
    addUploadedDoc: "ADD_UPLOADED_DOC",
    setDocumentField: "SET_DDOCUMENT_FIELD",
    changeDocDescription: "CHANGE_DOC_DESCRIPTION",
    getHistoryOperation: "GET_HISTORY_OPERATION",
    setTemporaryRelId: "SET_TEMPORARY_REL_ID",
    setTemporaryRelName: "SET_TEMPORARY_REL_NAME",
    setTemporaryRelRowKey: "SET_TEMPORARY_REL_ROW_KEY",
    setSubProcessToBase: "SET_SUB_PROCESS_TO_BASE",
};

export const historyTask = {
    reAssignTask: "historico_reatribuicoes",
    cancelTask: "historico_anulacoes",
    observation: "historico_observacoes",
};

export const taskOperation = {
    requestReAssign: "requisitar_reatribuicao",
    requestCancelTask: "requisitar_anulacao",
    saveObservation: "guardar_observacoes",
};
