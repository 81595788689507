export const actionType = {
    getTodoTasks: "GET_TODO_TASKS",
    getDoingTasks: "GET_DOING_TASKS",
    getDoneTasks: "GET_DONE_TASKS",
    getTasksToReassign: "GET_TASKS_TO_REASSIGN",
    setProcessesToCancelDb: "SET_PROCESSES_TO_CANCEL_Db",
    getGroupsToDelegate: "GET_GROUPS_TO_DELEGATE",
    getTasksToDelegate: "GET_TASKS_TO_DELEGATE",
    getUsersByGroup: "GET_USERS_BY_GROUP",
    setGroupToDelegate: "SET_GROUP_TO_DELEGATE",
    setUserToDelegate: "SET_USER_TO_DELEGATE",
    reassignTasks: "REASSIGN_TASKS",
    setTasksToReassign: "SET_TASKS_TO_REASSIGN",
    getTaskReassignmentHistory: "GET_TASK_REASSIGNMENT_HISTORY",
    annulmentProcess: "ANNULMENT_PROCESS",
};
