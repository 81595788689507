import React, { Component } from "react";
import { Modal, Table, Button, Input, Row, Col, Form, Select, message, Skeleton } from "antd";
import locale from "antd/lib/date-picker/locale/pt_PT";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/holiday";
import { getDetailsVersion } from "./../../../correction/action/index";
import { actionType } from "./../../constants/holiday";

import { selectEstabeleciment } from "../../../fixed_list/actions/index";
const { TextArea } = Input;
const { Option } = Select;

class HolidayRevertVersion extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: "Dia",
                dataIndex: "data",
                width: "20%",
                render: (text) => (
                    <Input ref={(node) => (this.inputs = node)} disabled type="date" value={text} name="data" />
                ),
            },
            {
                title: "Designação",
                dataIndex: "designacao",
                render: (text) => <Input ref={(node) => (this.input = node)} value={text} disabled />,
            },
            {
                title: "Natureza",
                dataIndex: "natureza",
                width: "20%",
                render: (text) => (
                    <Select
                        showSearch
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Selecione uma opção"
                        optionFilterProp="children"
                        defaultValue={text}
                        disabled
                    >
                        {!this.state.loadingNature &&
                            this.props.natureza.items.map((tipo) => (
                                <Option key={tipo.id_natureza_feriado}>{tipo.designacao}</Option>
                            ))}
                    </Select>
                ),
            },
            {
                title: "Estabelecimento",
                dataIndex: "estabelecimentos",
                width: "32%",
                render: (text) => (
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Selecione estabelecimentos"
                        defaultValue={text}
                        disabled
                    >
                        {!this.state.estabelecimentoLoading &&
                            this.props.listEstabelecimentos.data.map((item) => (
                                <Option key={item.estabelecimento} value={item.estabelecimento}>
                                    {item.nome}
                                </Option>
                            ))}
                    </Select>
                ),
            },
        ];
    }

    componentDidMount() {
        this.setState({
            loadingData: true,
        });
        this.props
            .getDetailsVersion({
                id: this.props.row.id,
                actionType: actionType.getDetailsVersion,
            })
            .then(() => {
                this.setState({
                    loadingData: false,
                    defaultDay: this.props.formHoliday.year.value,
                });
            });

        if (this.props.natureza === undefined) {
            this.setState({
                loadingNature: true,
            });
            this.props
                .selectDynamicList({
                    accao: "consultar",
                    nome: "natureza_feriado",
                })
                .then(() => {
                    this.setState({
                        loadingNature: false,
                    });
                });
        }

        if (this.props.estabelecimentos === undefined) {
            this.setState({ estabelecimentoLoading: true });
            this.props
                .selectEstabeleciment({
                    accao: "lista",
                    lista: "lista_estabelecimentos",
                    id_instituicao: "1",
                })
                .then((sucess) => {
                    if (!sucess) message.error("Ocorreu um erro");
                    this.setState({ estabelecimentoLoading: false });
                });
        } else {
            this.setState({ estabelecimentoLoading: false });
        }
    }

    state = {
        buttonLoading: false,
    };

    handleResponse = (response) => {
        this.setState({ buttonLoading: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleOk();
        } else message.error("Ocorreu um erro");
    };
    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ buttonLoading: true });
                const obj = {
                    ...values,
                    id: this.props.formHoliday.id,
                    data: this.props.formHoliday.data,
                    holidays: this.props.formHoliday.holidays,
                };
                this.props.update({ formulario: this.props.formulario, values: obj }).then((response) => {
                    this.handleResponse(response);
                });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const columns = this.columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    title: col.title,
                    onChange: (event) => {
                        return this.Save(col.dataIndex, event.target.value, record);
                    },
                }),
            };
        });

        const buttonFooter = this.props.isFormDisabled
            ? [
                  <Button key="back" onClick={this.props.handleCancel}>
                      Fechar
                  </Button>,
              ]
            : [
                  <Button key="back" onClick={this.props.handleCancel}>
                      Cancelar
                  </Button>,
                  <Button key="submit" type="primary" loading={this.state.buttonLoading} onClick={this.handleOk}>
                      Ok
                  </Button>,
              ];

        return (
            <Modal
                title="Configuração de feriados"
                visible={this.props.isVisible}
                footer={buttonFooter}
                onCancel={this.props.handleCancel}
                width="70%"
                style={{ top: 10 }}
            >
                <Form layout="vertical" onSubmit={this.props.handleOk}>
                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={5}>
                            <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={this.state.loadingData}>
                                <Form.Item label="Ano civil">
                                    {getFieldDecorator("year", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col xs={24} sm={24} md={11}>
                            <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={this.state.loadingData}>
                                <Form.Item label="Descrição">
                                    {getFieldDecorator("descricao", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(
                                        <TextArea
                                            autoSize={{
                                                minRows: 1,
                                                maxRows: 6,
                                            }}
                                            disabled
                                        />
                                    )}
                                </Form.Item>
                            </Skeleton>
                        </Col>
                    </Row>
                    <Table
                        rowKey={(record) => record.key}
                        rowClassName={() => "editable-row"}
                        dataSource={this.state.loadingData ? [] : this.props.list}
                        columns={columns}
                        size="small"
                        loading={this.state.loadingData}
                        scroll={{ y: 300 }}
                    />
                    {!this.props.isFormDisabled && (
                        <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={this.state.loadingData}>
                            <Form.Item label="Motivo">
                                {getFieldDecorator("motivo")(<TextArea autoSize={{ minRows: 1, maxRows: 6 }} />)}
                            </Form.Item>
                        </Skeleton>
                    )}
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...actionCreators, selectEstabeleciment, getDetailsVersion }, dispatch);
};

const mapStateToProps = (state) => {
    return {
        formHoliday: state.holiday.domain.formRevertToVersion,
        list: state.holiday.domain.formRevertToVersion.holidays,
        formulario: state.holiday.domain.metadata.formulario,
        listEstabelecimentos: state.fixedList.domain.byId.estabelecimento,
        natureza: state.dynamicList.domain.byId.natureza_feriado,
    };
};

const formEdit = Form.create({
    mapPropsToFields(props) {
        return {
            year: Form.createFormField({
                ...props.formHoliday.year,
                value: props.formHoliday.year.value,
            }),
            descricao: Form.createFormField({
                ...props.formHoliday.descricao,
                value: props.formHoliday.descricao.value,
            }),
        };
    },
    onFieldsChange(props, fields) {
        props.setField({ isEdit: true, fields });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(formEdit(HolidayRevertVersion));
