import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select, Divider, Button, Icon, Form, Col } from "antd";
import { connect } from "react-redux";

import ACTION_TYPE from "../../modules/dynamic_list/constants/action.type";
import { insert } from "../../modules/dynamic_list/actions/index";
import { query } from "../../actions/makeHttpRequest";
import GereList from "./GereLista";
import { getDataSource } from "../../modules/process_execution/constants/utils";
import { toArray } from "../../components/util";
import { isEmpty } from "../../modules/process_execution/utils/fields";
import { Utilitaries } from "../../business";
import { componentType } from "../../modules/process_execution/constants";
import { useUser } from "../../V2/hooks/user";

const { Option } = Select;

const IN_EXEC_QUERY = [
    /*"distrito"*/
];

const QaDynamicSelect = ({
    onChange,
    defaultValue,
    disabled,
    name, // Nome da lista para efetuar a consulta
    listKey, // Chave da Lista
    listValue, // Valor da lista
    placeholder,
    getDynamicList, // efetuar a consulta -> from redux
    listOfItems, // lista -> from redux,
    // label,
    value,
    tableLineNumber,
    attributes,
    col, // se deve adicionar coluna e qual a largura,
    postDynamicList,
    defaultDataSource,
    // allListDynamic,
    // record,
    tableDataSourceLength,
    style,
}) => {
    const [visibleModelGereList, setVisibleModelGereList] = useState(false);
    const [tmpDataSource, setTmpDataSource] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [checkVersion, setCheckVersion] = useState(false);
    const [newValue, setNewValue] = useState(null);
    const [oldValue, setOldValue] = useState(null);
    const [currentFilter, setCurrentFilter] = useState(null);

    const { hasAuthorization } = useUser();

    const getArrayOfDataKeys = (list) => {
        let dataKeys = [];

        if (Utilitaries.isEmpty(list)) {
            return dataKeys;
        }

        if (list.estrutura && Object.keys(list.estrutura).length) {
            dataKeys = Object.keys(list.estrutura);
        } else {
            if (list.items && Array.isArray(list.items)) {
                dataKeys = Object.keys(list.items[0]);
            }
        }

        return dataKeys;
    };

    useEffect(() => {
        setNewValue(value);
    }, [value]);

    useEffect(() => {
        if (defaultDataSource?.filterValue) {
            if (oldValue && Object.keys(oldValue).length) {
                setOldValue(null);
            }
            setCurrentFilter(defaultDataSource.filterValue);
        }
    }, [defaultDataSource, oldValue]);

    useEffect(() => {
        const fetch = async () => {
            await getDynamicList({
                requestBody: {
                    accao: "consultar",
                    nome: name,
                },
                url: "gerelistasdinamicas.php",
                actionType: ACTION_TYPE.selectById,
            });
        };

        if (
            tableDataSourceLength === tableLineNumber ||
            // tableDataSourceLength === 0 ||
            tableLineNumber === 1 ||
            tableLineNumber === undefined
        ) {
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const setDataSourceToList = async () => {
            let filtered = [];
            const dataKeys = getArrayOfDataKeys(listOfItems);

            if (Array.isArray(listOfItems.items)) {
                filtered = listOfItems.items.filter((it) => typeof it[listValue || dataKeys[1]] === "string");
            } else if (listOfItems.items[listKey || dataKeys[0]]) {
                filtered = [listOfItems.items];
            }
            let data = [];

            const chave_lista =
                /*name === "distrito" && listKey === "id_dist_ilhas" ? `pais` :*/ listKey || dataKeys[0];

            // check if value in listOfItems - if not make request to obtain in
            let itemWithNewVersion = filtered.find((it) => it[chave_lista] === value);
            // let itemWithNewVersion = filtered.find((it) => it._original === value);

            if (!itemWithNewVersion && !Utilitaries.isEmpty(defaultValue || value)) {
                const response = await getDynamicList({
                    requestBody: {
                        nome: name,
                        chave_lista,
                        id: defaultValue || value,
                    },
                    url: "execListaDinamica.php",
                });

                if (response.responseType === "Sucess") {
                    data = toArray(response.dados).map((it) => {
                        it.designacao = `${it.designacao} - (Eliminado)`;

                        return it;
                    });

                    itemWithNewVersion = filtered.find((it) => it._original === data[0]._original);
                    if (itemWithNewVersion) {
                        if (defaultDataSource?.filterName && defaultDataSource?.filterValue) {
                            setCurrentFilter(itemWithNewVersion[defaultDataSource.filterName]);
                        }
                        setNewValue(itemWithNewVersion[listKey || dataKeys[0]]);
                        onChange(itemWithNewVersion[listKey || dataKeys[0]]);
                        data = [];
                    } else {
                        setCheckVersion(true);
                    }
                }
            } else {
                setCheckVersion(false);
            }
            setDataSource([...filtered, ...data]);
        };

        if (listOfItems) {
            setDataSourceToList();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOfItems, value]);

    useEffect(() => {
        if (defaultDataSource) {
            if (defaultDataSource?.filterName && defaultDataSource?.filterValue) {
                let filterValue = currentFilter;
                if (oldValue) {
                    let newVersion = dataSource.find((item) => item._original === oldValue._original);
                    filterValue = newVersion[defaultDataSource.filterName];
                }
                setTmpDataSource(dataSource.filter((it) => it[defaultDataSource.filterName] === filterValue));
            } else {
                setTmpDataSource([]);
            }
        } else {
            setTmpDataSource(dataSource);
        }
    }, [defaultDataSource, dataSource, currentFilter, oldValue]);

    useEffect(() => {
        if (defaultDataSource) {
            if (defaultDataSource?.filterName) {
                const currentItem = dataSource.find((item) => {
                    const key = getArrayOfDataKeys(listOfItems);
                    if (newValue) {
                        return item[listKey || key[0]] === newValue;
                    }
                    return item[listKey || key[0]] === value;
                });
                setOldValue(currentItem);
                if (isEmpty(tableLineNumber) || tableLineNumber === 1) {
                    getDynamicList({
                        requestBody: {
                            accao: "consultar",
                            nome: name,
                        },
                        url: "gerelistasdinamicas.php",
                        actionType: ACTION_TYPE.selectById,
                    });
                }
            }
        }
    }, [dataSource, defaultDataSource, getDynamicList, listKey, listOfItems, name, newValue, tableLineNumber, value]);

    const onSaveGetData = async ({ force = false }) => {
        if (IN_EXEC_QUERY.includes(name)) {
            let labels = {};
            if (attributes && attributes["@campos_lista"]) {
                const attrbs = attributes["@campos_lista"].split(";");
                attrbs.forEach((el) => {
                    let _item = el.split(":");
                    labels[_item[0]] = {};
                });
            }

            getDynamicList({
                requestBody: {
                    query: name,
                    accao: "query",
                },
                url: "execQuery.php",
                labels,
                name,
                actionType: ACTION_TYPE.selectById,
            });
        } else {
            getDynamicList({
                requestBody: {
                    accao: "consultar",
                    nome: name,
                },
                url: "gerelistasdinamicas.php",
                actionType: ACTION_TYPE.selectById,
            });
        }
        setVisibleModelGereList(false);
    };
    const _defaultValue = tmpDataSource.find(
        (it) => it[listKey || getArrayOfDataKeys(listOfItems)[0]] === defaultValue || it._original === defaultValue
    );

    const propsLabel = defaultValue
        ? {
              defaultValue: !value
                  ? _defaultValue?.[listKey || getArrayOfDataKeys(listOfItems)[0]] || defaultValue
                  : defaultValue,
          }
        : {
              placeholder: placeholder ? placeholder : "Seleciona uma opção",
          };

    const maxVersion = Math.max(...toArray(dataSource).map((it) => parseInt(it.versao)));

    let optionsValue = null;
    if (listValue) {
        optionsValue = listValue.split(";").filter((el) => el);
    }

    const RenderSelect = () => (
        <Select
            showSearch
            allowClear
            style={{
                width: "100%",
                margin: "0 auto",
                padding: "0",
                maxWidth: 250,
                ...style,
            }}
            defaultValue={value ? newValue || value : ""}
            dropdownMatchSelectWidth={false}
            {...propsLabel}
            disabled={disabled || false}
            optionFilterProp="children"
            onChange={(value, option) => {
                if (newValue) {
                    setNewValue(null);
                }
                onChange(value, option);
            }}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            dropdownRender={
                hasAuthorization("LISTAS_DINÂMICAS")
                    ? (menu) => (
                          <div onMouseDown={(e) => e.preventDefault()}>
                              {menu}
                              <Divider style={{ margin: "4px 0" }} />
                              <Button
                                  block
                                  style={{ textAlign: "left" }}
                                  onClick={() => setVisibleModelGereList(true)}
                                  type="link"
                              >
                                  <Icon type="edit" /> <span>Editar Lista</span>
                              </Button>
                          </div>
                      )
                    : undefined
            }
        >
            {tmpDataSource.map((item, index) => {
                const dataKeys = getArrayOfDataKeys(listOfItems);

                let description = "";
                if (toArray(optionsValue)[0]) {
                    description = item[optionsValue[0]];
                    description += optionsValue[1] && item[optionsValue[1]] ? " - " + item[optionsValue[1]] : "";
                } else {
                    description = item[dataKeys[1]];
                }
                return (
                    <Option
                        value={item[listKey || dataKeys[0]]}
                        key={index}
                        disabled={checkVersion && parseInt(item.versao) !== maxVersion}
                    >
                        {description}
                    </Option>
                );
            })}
        </Select>
    );

    const RenderCol = () => (
        <Col key={"col-"} xs={col}>
            <RenderSelect />
        </Col>
    );

    return (
        <>
            {col ? <RenderCol /> : <RenderSelect />}

            {visibleModelGereList && (
                <GereList
                    visible={visibleModelGereList}
                    onCancel={() => setVisibleModelGereList(false)}
                    name={name}
                    listOfItems={listOfItems}
                    postDynamicList={postDynamicList}
                    getDynamicList={getDynamicList}
                    onSaveGetData={onSaveGetData}
                />
            )}
        </>
    );
};

// QaDynamicSelect.propTypes = {
//     onChange: PropTypes.func,
//     defaultValue: PropTypes.string,
//     name: PropTypes.string,
//     placeholder: PropTypes.string,
// };

const mapStateToProps = (state, ownProps) => {
    // if (ownProps.name === "distrito")
    //     return {
    //         listOfItems: state.dynamicList.domain.byId["id_dist_ilhas"]
    //     };
    // else
    return {
        listOfItems: state.dynamicList.domain.byId[ownProps.name],
        allListDynamic: state.dynamicList.domain.byId,
    };
};

const mapDispatchToProps = {
    getDynamicList: query,
    postDynamicList: insert,
};

export default connect(mapStateToProps, mapDispatchToProps)(QaDynamicSelect);
