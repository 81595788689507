import React, { useEffect, useState } from "react";
import { Button, Col, Form, Icon, Input, Modal, Row, Typography } from "antd";
import ModalSelectUser from "../../../select.user";
import { Group, User } from "../../types";
import { getOverlaySettings, saveOverlaySettings } from "../../actions/querys";

interface Props {
    visible: boolean;
    onCancel: () => void;
    selectContributorsProps: any;
}

export function OverlayPermission({ visible, onCancel, selectContributorsProps }: Props) {
    const [isVisibleModalSelectUserAndGroup, setIsVisibleModalSelectUserAndGroup] = useState(false);
    const [groups, setGroups] = useState<Group[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const result = await getOverlaySettings();
            if (result) {
                setGroups(result.groups);
                setUsers(result.users);
            }
            setLoading(false);
        };

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleOk() {
        setLoading(true);
        const result = await saveOverlaySettings(users, groups);
        setLoading(false);
        if (result) {
            onCancel();
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        return false;
    };

    const groupStyle = {
        border: "1px solid silver",
        borderRadius: "4px",
        borderStyle: "dashed",
        padding: 8,
    };

    return (
        <>
            <ModalSelectUser
                visible={isVisibleModalSelectUserAndGroup}
                setVisible={setIsVisibleModalSelectUserAndGroup}
                defaultUsers={users}
                defaultGroups={groups}
                {...selectContributorsProps}
                onOk={(usersSelected: User[], groupsSelected: Group[]) => {
                    setGroups(groupsSelected);
                    setUsers(usersSelected);
                }}
            />

            <Modal
                title="Permissões de sobreposição de eventos"
                visible={visible}
                onCancel={onCancel}
                width="50%"
                onOk={handleOk}
                destroyOnClose
                footer={[
                    <Button key="back" onClick={onCancel}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" disabled={loading} onClick={handleOk}>
                        Guardar
                    </Button>,
                ]}
            >
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col span={24}>
                            <Typography.Text type="secondary">
                                Utilizadores que podem criar eventos mesmo que vai sobrepor eventos já agendados.
                            </Typography.Text>
                            <Row className="ant-form-item" style={groupStyle}>
                                <Col className="ant-form-item-control-wrapper" xs={24} sm={22}>
                                    <label>Utilizadores</label>
                                    <Input
                                        value={users.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator === ""
                                                    ? currentValue.name
                                                    : accumulator + ";" + currentValue.name,
                                            ""
                                        )}
                                        disabled
                                        style={{ marginBottom: 10 }}
                                    />
                                </Col>
                                <Col className="ant-form-item-control-wrapper" xs={24} sm={22}>
                                    <label>Grupos funcionais</label>
                                    <Input
                                        value={groups.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator === ""
                                                    ? currentValue.value
                                                    : accumulator + ";" + currentValue.value,
                                            ""
                                        )}
                                        disabled
                                    />
                                </Col>

                                <Col xs={24} sm={2}>
                                    <Icon
                                        onClick={() => setIsVisibleModalSelectUserAndGroup(true)}
                                        type="user-add"
                                        style={{ float: "right", fontSize: "22px" }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}
