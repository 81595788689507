import React, { useState, useEffect } from "react";
import { Table, Button, Input, Icon, Row, Pagination } from "antd";
import { getUsers } from "./../actions/index";
import UserForm from "./form";
import { tableSort } from "./../../../components/util";

const UserTable = ({ type }) => {
    const [loadingData, setLoadingData] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [filter, setfilter] = useState({});
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });
    const [metadata, setMetadata] = useState([]);
    const [modalData, setModalData] = useState({ isVisible: false });

    // Get form from API
    useEffect(() => {
        setLoadingData(true);

        const values = {
            ...filter,
            pagina: pagination.page,
            resultados_pagina: pagination.perPage,
            tab: type,
        };
        getUsers(values).then((response) => {
            setDataSource(response.lista);
            setMetadata(response.metadata);
            setLoadingData(false);
        });
    }, [pagination, filter, type]);

    //---------------------------- filter --------------------------------

    const getColumnSearchProps = () => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        };
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };
    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val[0]) obj["filtro_" + key] = val[0];
            });
        }
        if (sorter.columnKey) {
            const value = sorter.order === "ascend" ? "ASC" : "DESC";
            obj["ordenacao_" + sorter.columnKey] = value;
        }

        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter(obj);
    };
    //---------------------------- endfilter -----------------------------
    const pageChange = (page, perPage) => {
        setPagination({
            page,
            perPage,
        });
    };

    const hideModal = () => {
        setModalData({
            isVisible: false,
        });
    };
    const handleEdit = (record) => {
        setModalData({
            isVisible: true,
            ...record,
        });
    };

    let columns = [
        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
            ...getColumnSearchProps("nome"),
            sorter: (a, b) => tableSort(a.nome, b.nome),
        },
        {
            title: "Utilizador",
            dataIndex: "username",
            key: "username",
            ...getColumnSearchProps("username"),
            sorter: (a, b) => tableSort(a.username, b.username),
        },
        {
            title: "Categoria",
            dataIndex: "categoria",
            key: "categoria",
            ...getColumnSearchProps("categoria"),
            sorter: (a, b) => tableSort(a.tipo_utilizador, b.tipo_utilizador),
        },
        {
            title: "Tipo utilizador",
            dataIndex: "tipo",
            key: "tipo",
            ...getColumnSearchProps("tipo"),
            sorter: (a, b) => tableSort(a.tipo, b.tipo),
        },
        {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps("email"),
            sorter: (a, b) => tableSort(a.email, b.email),
        },
        {
            title: "Telefone",
            dataIndex: "telefone",
            key: "telefone",
            ...getColumnSearchProps("telefone"),
            sorter: (a, b) => tableSort(a.telefone, b.telefone),
        },
    ];
    if (type !== "all") columns = columns.filter((column) => column.dataIndex !== "categoria");

    columns.push({
        title: "Ação",
        key: "action",
        render: (text, record) => (
            <Button
                type="link"
                style={{
                    padding: 0,
                }}
                onClick={() => handleEdit(record)}
            >
                Editar
            </Button>
        ),
    });

    return (
        <>
            <Table
                size="middle"
                rowKey={(data) => data.idautorizacao + "-table"}
                columns={columns}
                pagination={false}
                dataSource={dataSource}
                loading={loadingData}
                onChange={handleTableChange}
            />
            <Row>
                <Pagination
                    style={{ float: "right", marginTop: 16 }}
                    current={metadata.pagina}
                    defaultPageSize={metadata.resultados_pagina}
                    size="small"
                    total={metadata.resultados}
                    showSizeChanger
                    showTotal={(total) => `Total registos: ${total}`}
                    onShowSizeChange={pageChange}
                    onChange={pageChange}
                />
            </Row>
            {modalData.isVisible && <UserForm modalData={modalData} hideModal={hideModal} />}
        </>
    );
};

export default UserTable;
