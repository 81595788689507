import { actionType } from "../constants/panel";
import { arrayToObjectById, toArray } from "../../../components/util";
import { Utilitaries } from "../../../business";

const initialState = {
    domain: {
        terminadas: [],
        nao_atribuidas: [],
        em_curso: [],
        em_curso_attributes: {},
        tasksToReassign: [],
        processesToCancel: [],
        groupsToDelegate: [],
        usersByGroup: [],
    },
    app: {
        isTasksToReassignFetched: false,
        isProcessesToCancelFetched: false,
        tasksToReassignCount: 0,
        processesToCancelCount: 0,
    },
};

export const taskPanelReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.getTodoTasks: {
            return {
                ...state,
                domain: {
                    ...state.domain,
                    nao_atribuidas: action.payload.data,
                    nao_atribuidas_attributes: action.payload.attributes,
                },
            };
        }

        case actionType.getDoingTasks: {
            return {
                ...state,
                domain: {
                    ...state.domain,
                    em_curso: action.payload.data,
                    em_curso_attributes: action.payload.attributes,
                },
            };
        }

        case actionType.getDoneTasks: {
            return {
                ...state,
                domain: {
                    ...state.domain,
                    terminadas: action.payload.data,
                    terminadas_attributes: action.payload.attributes,
                },
            };
        }

        case actionType.getTasksToReassign: {
            let tasksToReassign = {};

            if (Array.isArray(action.payload)) {
                tasksToReassign = arrayToObjectById({
                    array: action.payload,
                    id: "id_tarefa",
                });
            } else if (Object.keys(action.payload).length > 0) {
                tasksToReassign = { [action.payload.id_tarefa]: action.payload };
            }

            return {
                ...state,
                domain: {
                    ...state.domain,
                    tasksToReassign,
                },
                app: {
                    ...state.app,
                    isTasksToReassignFetched: true,
                    tasksToReassignCount: Object.values(tasksToReassign).length,
                },
            };
        }
        case actionType.getTasksToDelegate: {
            let tasksToDelegate = {};

            tasksToDelegate = arrayToObjectById({
                array: Utilitaries.toArray(action.payload),
                id: "id",
            });

            return {
                ...state,
                domain: {
                    ...state.domain,
                    tasksToDelegate,
                },
                app: {
                    ...state.app,
                    isTasksToDelegateFetched: true,
                    tasksToDelegateCount: Object.values(tasksToDelegate).length,
                },
            };
        }

        case actionType.getGroupsToDelegate: {
            return {
                ...state,
                domain: {
                    ...state.domain,
                    groupsToDelegate: action.payload,
                },
            };
        }

        case actionType.getUsersByGroup: {
            const { group, users } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    usersByGroup: {
                        ...state.domain.usersByGroup,
                        [group]: users,
                    },
                },
            };
        }

        // case actionType.setGroupToDelegate: {
        //     const { id, task } = action.payload;
        //     return {
        //         ...state,
        //         domain: {
        //             ...state.domain,
        //             tasksToReassign: {
        //                 ...state.domain.tasksToReassign,
        //                 [task]: {
        //                     ...state.domain.tasksToReassign[task],
        //                     group: id,
        //                 },
        //             },
        //         },
        //     };
        // }

        // case actionType.setUserToDelegate: {
        //     const { id, task } = action.payload;
        //     return {
        //         ...state,
        //         domain: {
        //             ...state.domain,
        //             tasksToReassign: {
        //                 ...state.domain.tasksToReassign,
        //                 [task]: {
        //                     ...state.domain.tasksToReassign[task],
        //                     user: id,
        //                 },
        //             },
        //         },
        //     };
        // }

        // case actionType.setTasksToReassign: {
        //     return {
        //         ...state,
        //         domain: {
        //             ...state.domain,
        //             tasksSelectedToReassign: action.payload,
        //         },
        //     };
        // }

        case actionType.setProcessesToCancelDb: {
            let processesToCancel = [];

            // if (Array.isArray(action.payload)) {
            //     processesToCancel = arrayToObjectById({
            //         array: action.payload,
            //         id: "id_processo",
            //     });
            // } else processesToCancel = action.payload;

            processesToCancel = action.payload;

            return {
                ...state,
                domain: {
                    ...state.domain,
                    processesToCancel,
                },
                app: {
                    ...state.app,
                    isProcessesToCancelFetched: true,
                    processesToCancelCount: toArray(action.payload).reduce((acc, process) => {
                        if (!acc.includes(process.id_processo)) {
                            acc.push(process.id_processo);
                        }
                        return acc;
                    }, []).length,
                },
            };
        }

        case actionType.getTaskReassignmentHistory: {
            return {
                ...state,
                domain: {
                    ...state.domain,
                    [action.payload.tab]: state.domain[action.payload.tab].map((item) => {
                        if (item.tarefa === action.payload.tarefa) {
                            item = {
                                ...item,
                                isReassigned: action.payload.isReassigned,
                            };
                        }
                        return item;
                    }),
                },
            };
        }

        default:
            return state;
    }
};
