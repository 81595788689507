import React from "react";
import { Row, Col, Form, Input, Divider, PageHeader } from "antd";
import ComponentForm from "./form/index";
import AditionalInfoComponent from "./aditional.info";
import TransitionsComponent from "./transitions";
import ActionsComponent from "./actions.table";
import ComponentModal from "./modal";

const { TextArea } = Input;

class ComponentDetails extends React.Component {
    state = {
        modal: {
            isVisible: false,
            componentToRender: "",
            data: {},
        },
        transitionsDataSource: [
            {
                key: "0",
                name: "Edward King 0",
                age: "32",
                address: "London, Park Lane no. 0",
            },
            {
                key: "1",
                name: "Edward King 1",
                age: "32",
                address: "London, Park Lane no. 1",
            },
        ],
        actionsDataSource: [
            {
                key: "0",
                name: "Edward King 0",
            },
            {
                key: "1",
                name: "Edward King 1",
            },
        ],
        aditionalInfoDataSource: [
            {
                key: "0",
                name: "Edward King 0",
                age: "32",
                address: "London, Park Lane no. 0",
            },
            {
                key: "1",
                name: "Edward King 1",
                age: "32",
                address: "London, Park Lane no. 1",
            },
        ],
        count: 2,
    };

    showModal = (component, data) => {
        this.setState({
            modal: {
                componentToRender: component,
                isVisible: true,
                data,
            },
        });
    };

    handleOk = (e) => {
        this.setState({
            modal: {
                ...this.state.modal,
                isVisible: false,
            },
        });
    };

    handleCancel = (e) => {
        this.setState({
            modal: {
                ...this.state.modal,
                isVisible: false,
            },
        });
    };

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };

    handleAdd = (tableName) => {
        const { count, actionsDataSource, transitionsDataSource, aditionalInfoDataSource } = this.state;
        const newData = {
            key: count,
            name: `Edward King ${count}`,
            age: 32,
            address: `London, Park Lane no. ${count}`,
        };
        let dataSource = {
            name: tableName,
            data: [],
        };
        if (tableName === "transitionsDataSource") dataSource.data = [...transitionsDataSource, newData];
        if (tableName === "actionsDataSource") dataSource.data = [...actionsDataSource, newData];
        if (tableName === "aditionalInfoDataSource") dataSource.data = [...aditionalInfoDataSource, newData];

        this.setState({
            [tableName]: dataSource.data,
            count: count + 1,
        });
    };

    handleSave = (row) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
    };

    render() {
        const { transitionsDataSource, actionsDataSource, aditionalInfoDataSource } = this.state;

        return (
            <>
                <PageHeader
                    title="Detalhes Componente"
                    onBack={() => {
                        this.props.history.push("/process/create/advanced");
                    }}
                />

                <Divider orientation="left">Dados</Divider>
                <Row gutter={18}>
                    <Col xs={24} md={6}>
                        <Form.Item label="Nome">
                            <Input placeholder="Introduza o nome" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item label="Descrição">
                            <TextArea placeholder="Introduza a descrição" rows={1} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={10}>
                        <Form.Item label="Formulário">
                            <ComponentForm showComponentModal={this.showModal.bind(this)} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left">Transições</Divider>
                <TransitionsComponent dataSource={transitionsDataSource} handleAdd={this.handleAdd.bind(this)} />

                <Divider orientation="left">Ações</Divider>
                <ActionsComponent
                    dataSource={actionsDataSource}
                    handleAdd={this.handleAdd.bind(this)}
                    showComponentModal={this.showModal.bind(this)}
                />

                <Divider orientation="left">Informações Adicionais</Divider>
                <AditionalInfoComponent dataSource={aditionalInfoDataSource} handleAdd={this.handleAdd.bind(this)} />

                <ComponentModal
                    componentToRender={this.state.modal.componentToRender}
                    visible={this.state.modal.isVisible}
                    data={this.state.modal.data}
                    handleOk={this.handleOk.bind(this)}
                    handleCancel={this.handleCancel.bind(this)}
                />
            </>
        );
    }
}

export default ComponentDetails;
