export const actionType = {
    insert: "INSERT_COMPETENCE_TYPE",
    setField: "SET_FIELD_COMPETENCE_TYPE",
    getEdit: "GET_EDIT_COMPETENCE_TYPE",
    update: "UPDATE_COMPETENCE_TYPE",
    delete: "DELETE_COMPETENCE_TYPE",
    select: "SELECT_COMPETENCE_TYPE",
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
