import React, { Component } from "react";
import { Table, Row, Button, Icon, Select, Input, Popconfirm } from "antd";

const { Option } = Select;

function onChange() {}

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

class DataTabPane extends Component {
    /** handle state */
    state = {
        dataSource: [{ key: 0, type: "", name: "", entity: "", repeat: "", prop: "" }],
        countDataSource: 1,
    };
    /** handle table column */
    column = [
        {
            title: "Tipo de campo",
            dataIndex: "type",
            width: "20%",
            render: () => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    optionFilterProp="children"
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="Normal">Normal</Option>
                    <Option value="Conjunto">Conjunto</Option>
                    <Option value="Cliente">Cliente</Option>
                    <Option value="Entidade">Entidade</Option>
                </Select>
            ),
        },
        {
            title: "Nome do campo",
            dataIndex: "name",
            width: "30%",
            render: () => <Input />,
        },
        {
            title: "C.Entidade",
            dataIndex: "entity",
            width: "12%",
            render: () => (
                <Button>
                    <Icon type="form" />
                </Button>
            ),
        },
        {
            title: "C.Repeat",
            dataIndex: "repeat",
            width: "12%",
            render: () => (
                <Button>
                    <Icon type="form" />
                </Button>
            ),
        },
        {
            title: "Propried.",
            dataIndex: "prop",
            width: "12%",
            render: () => (
                <Button>
                    <Icon type="form" />
                </Button>
            ),
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "20%",
            render: (text, record) =>
                this.state.dataSource.length >= 1 ? (
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        onConfirm={() => this.handleDelete(record.key)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="javascript:;" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];
    /** handle dynamic table rows */
    handleAdd = () => {
        const { countDataSource, dataSource } = this.state;
        const newData = {
            key: countDataSource,
            type: "",
            name: "",
            entity: "",
            repeat: "",
            prop: "",
        };
        this.setState({
            dataSource: [...dataSource, newData],
            countDataSource: countDataSource + 1,
        });
    };
    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };

    render() {
        return (
            <div>
                <Row>
                    <Button
                        xs={24}
                        sm={12}
                        onClick={this.handleAdd}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{ float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Row>
                <Table pagination={false} columns={this.column} dataSource={this.state.dataSource} />
            </div>
        );
    }
}

export default DataTabPane;
