import { actionTypes } from "../constants/index";
import { arrayToObjectById } from "../../../components/util";

const initialState = {
    domain: {
        form: {},
        byId: {},
    },
    app: { isAuthorizationsFetched: false },
};

export const authorizationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.getAuthorizations:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: action.payload,
                        id: "idautorizacao",
                    }),
                },
                app: {
                    isAuthorizationsFetched: true,
                },
            };

        default:
            return state;
    }
};
