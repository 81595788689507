import React from "react";
import { Form, Radio } from "antd";
import { componentType, xmlProps } from "../../constants/index";
import PropTypes from "prop-types";
import { canCorrectField } from "../../utils/fields";
import { FormUtilitaries } from "../../../../business";

const QaRadio = ({
    dataSource,
    type,
    itemData,
    handleRadioChange,
    label,
    formItemStyle,
    root,
    currentTaskStatus,
    columnDataSource,
    rowKey,
    error,
    readonly,
    correctForm,
}) => {
    let booleanOptionTexts = {
        positive: "Sim",
        negative: "Não",
    };
    if (type === componentType.boolean && itemData?.[xmlProps.values]) {
        const values = itemData?.[xmlProps.values].split(";");
        booleanOptionTexts = {
            positive: values[0],
            negative: values[1],
        };
    }

    const validateBoolean = (value) => {
        // if (!itemData?.[xmlProps.values]) {
        if (value === "false") {
            return "f";
        }
        if (value === "true") {
            return "t";
        }
        // }
        return value;
    };

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus || readonly;

    return (
        <Form.Item label={label} style={formItemStyle} colon={itemData["@etiqueta"] !== ""}>
            {type === componentType.boolean && (
                <Radio.Group
                    value={validateBoolean(itemData.value)}
                    disabled={disabled}
                    style={FormUtilitaries.highlightsErrors(itemData.value, itemData.required)}
                    // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                    onChange={(e) => {
                        handleRadioChange({
                            value: e.target.value,
                            key: itemData.key,
                            root,
                            rowKey,
                            inputGroupPath: itemData.tablePath || itemData?.inputGroupPath,
                        });
                    }}
                >
                    <Radio value="t">
                        {dataSource?.find((it) => it.key === "t")?.value || booleanOptionTexts.positive}
                    </Radio>
                    <Radio value="f">
                        {dataSource?.find((it) => it.key === "f")?.value || booleanOptionTexts.negative}
                    </Radio>
                </Radio.Group>
            )}

            {type === componentType._selectionButtons && (
                <Radio.Group
                    value={validateBoolean(itemData.value)}
                    style={FormUtilitaries.highlightsErrors(itemData.value, itemData.required)}
                    disabled={disabled}
                    // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                    onChange={(e) =>
                        handleRadioChange({
                            value: e.target.value,
                            key: itemData.key,
                            root,
                            rowKey,
                            inputGroupPath: itemData?.inputGroupPath,
                        })
                    }
                >
                    {itemData[xmlProps.values]
                        ? dataSource.map((item) => <Radio style={{display:"block"}} value={item["key"]}>{item["value"]}</Radio>)
                        : (rowKey ? columnDataSource : dataSource).map((item) => (
                              <Radio value={item[itemData["@chave_lista"] || "key"]}>
                                  {item[itemData["@valor_lista"] || "value"]}
                              </Radio>
                          ))}
                </Radio.Group>
            )}
            {error && <span style={{ color: "red" }}>{error}</span>}
        </Form.Item>
    );
};

export default QaRadio;

QaRadio.propTypes = {
    name: PropTypes.string,
};
