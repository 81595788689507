import { Utilitaries } from "../../../../business";
import { GenericObject, InputInTableProps } from "../../../types/common";
import { FormInputsObjectProps, FormInputsProps } from "../../../types/formTypes";
import { getFormInputFromPath } from "../../../utils/FormMapper/formHandle";
import { countConvertion, getXList, sumConvertion, getElementByRootPath } from "../../../utils/functions";
import { api_fetch } from "../../../utils/HttpRequest/request";

const regexGetInputName = /\/\/(\w+|\/)*|\.\.\/\w+|\/\/\w*/g;

export function regex_match(expression: string) {
    if (!expression || !expression.match(regexGetInputName)) {
        return [];
    }

    const params = expression.match(regexGetInputName)?.map((item) => {
        let field = item;
        let path: string[] | undefined = undefined;

        let position: "root" | "field" | "path" = "root";

        const allFields = item
            ?.replace(/\./g, "")
            .split("/")
            .filter((item) => item);

        if (allFields.length === 1) {
            field = allFields[0];
            if (item.startsWith("../")) {
                position = "field";
            } else {
                position = "root";
            }
        } else {
            field = allFields.pop() ?? "";
            path = allFields;
            position = "path";
        }

        return {
            name: item,
            path,
            field,
            position,
        };
    });

    return params || [];
}

interface RegexValidationProps {
    expression: string;
    componentProperties: FormInputsProps;
    formState?: FormInputsObjectProps;
    rowTable?: InputInTableProps;
}
export function regexValidate({ expression, formState, componentProperties, rowTable }: RegexValidationProps) {
    const regex_expression = expression;

    // if (regex_expression === "nif") {
    //     return false;
    // }

    let params = regex_match(regex_expression);

    console.log("regex", { params });

    let convertedExpression = expression
        .replace(/\sor\s/gi, " || ")
        .replace(/\sand\s/gi, " && ")
        .replace(/\s=\s/gi, " == ")
        .replace(/(?<=[a-z])=(?=')/gi, " == ")
        .replace(/'true'|true/gi, "'t'")
        .replace(/'false'|false/gi, "'f'")
        .replace(/''''/gi, "''")
        .replace(/\sdiv\s/gi, " / ")
        .replace(/(?:\\[rn])+/g, "");

    if (
        !/\s!=\s/g.test(convertedExpression) &&
        !/\s>=\s/g.test(convertedExpression) &&
        !/\s<=\s/g.test(convertedExpression) &&
        !/\s==\s/g.test(convertedExpression)
    ) {
        convertedExpression = convertedExpression.replace(/=/g, "==");
    }

    if (/then/.test(convertedExpression)) {
        // convertedExpression = convertedExpression.replace(/\sthen\s/gi, " { ").replace(/\selse\s/gi, " } $& { ");
        // convertedExpression = convertedExpression + "}";
        convertedExpression = convertedExpression.replace(/if/gi, "");
        convertedExpression = convertedExpression.replace(/\sthen\s/gi, " ? ").replace(/\selse\s/gi, " : ");
    }

    // have just THEN
    if (/THEN/.test(convertedExpression)) {
        convertedExpression = convertedExpression.replace(/THEN/, "");
    }

    // count function
    const countRegex = /(?<=count\().*(?=\))/;
    if (countRegex.test(regex_expression)) {
        const expression = convertedExpression.match(/count\([/\w\[\]='!\s\.\&]+\)|count\([/\w\[\]='\(\)!\s\W]+\)/g);
        if (expression) {
            expression.forEach((element) => {
                const resultCount = countConvertion({ xpath: element, formState });
                convertedExpression = convertedExpression.replace(element, resultCount);
            });
            params = regex_match(convertedExpression);
        }
    }

    //Sum function
    const sumRegex = /(?<=sum\().*(?=\))/;
    if (sumRegex.test(regex_expression)) {
        const expression = convertedExpression.match(/sum\(.*?\)/g);

        if (expression) {
            expression.forEach((element) => {
                const resultCount = sumConvertion({ xpath: element, formState });
                convertedExpression = convertedExpression.replace(element, resultCount);
            });
        }
        params = regex_match(convertedExpression);
    }

    //x_lista function
    if (regex_expression.startsWith("x_lista:")) {
        const result = getXList({ xpath: convertedExpression, formState: JSON.parse(JSON.stringify(formState)) });
        convertedExpression = convertedExpression.replace(convertedExpression, result);
    }

    for (const param of params) {
        let value: any;

        if (param.position === "root") {
            const fieldValue = formState?.[param.field]?.value;
            value = Utilitaries.isEmpty(fieldValue) ? "" : fieldValue;
        } else if (param.position === "path") {
            const root = getFormInputFromPath(param.field, formState || {}, param.path || []);

            value = Utilitaries.isEmpty(root?.value) ? "" : root?.value;
        } else {
            if (rowTable && rowTable.row_data.hasOwnProperty(param.field)) {
                const fieldValue = rowTable?.row_data?.[param.field];
                value = Utilitaries.isEmpty(fieldValue) ? "" : fieldValue;
            }

            const root = getFormInputFromPath(
                param.field,
                formState || {},
                componentProperties?.input_group_path || []
            );

            if (root) {
                const fieldValue = root?.value;
                value = Utilitaries.isEmpty(fieldValue) ? "" : fieldValue;
            }
            if (param.position === "field" && !rowTable && !root) {
                value = Utilitaries.isEmpty(value) ? "" : value;
            }

            // "CASO_NAO_TRATADO"
        }

        // if (param?.field) {
        //     const root = getElementByRootPath({ rootPath: param.param, form: formState || {} });

        //     value = root?.[param.field]?.value;
        // } else {
        //     value = getInputValue({
        //         componentReference: componentProperties,
        //         formState,
        //         in_root: param.name.startsWith("//"),
        //         key_input_to_find_value: param.param,
        //         rowTable,
        //     });
        // }

        try {
            // ^-?\d*\.?\d*$
            // if (/^\d+$/.test(value)) {
            if (!Utilitaries.isEmpty(value) && /^-?\d*(\.{1}\d+){0,1}$/.test(value)) {
                convertedExpression = convertedExpression.replace(param.name, `${Number(value)}`);
            } else {
                const realValue = value === undefined ? "" : value.replace(/'/g, "\\'");
                convertedExpression = convertedExpression.replace(param.name, `'${realValue}'`);
                // convertedExpression = convertedExpression.replace(
                //     param.name,
                //     value === undefined ? "``" : "`" + value + "`"
                // );
            }
        } catch (error) {
            convertedExpression = convertedExpression.replace(param.name, `'${value === undefined ? "" : value}'`);
        }
    }

    // console.log("regexregexregex", convertedExpression);

    return convertedExpression;
}

interface InputValue {
    componentReference: FormInputsProps;
    formState?: FormInputsObjectProps;
    rowTable?: InputInTableProps;
    key_input_to_find_value: string;
    in_root: boolean;
}

export function getInputValue({
    componentReference,
    rowTable,
    key_input_to_find_value,
    in_root,
    formState,
}: InputValue) {
    if (rowTable && rowTable.row_data.hasOwnProperty(key_input_to_find_value)) {
        const value = rowTable?.row_data?.[key_input_to_find_value];
        return Utilitaries.isEmpty(value) ? "" : value;
    }

    const root = getFormInputFromPath(
        key_input_to_find_value,
        formState || {},
        componentReference?.input_group_path || []
    );

    if (root) {
        const value = formState?.[key_input_to_find_value]?.value;
        return Utilitaries.isEmpty(value) ? "" : value;
    }

    // if (in_root) {
    //     const value = formState?.[key_input_to_find_value]?.value;
    //     return Utilitaries.isEmpty(value) ? "" : value;
    // } else {

    //     if (!componentReference.input_group_path) {
    //         // const value = formState?.[key_input_to_find_value]?.value;
    //         // return Utilitaries.isEmpty(value) ? "" : value;

    //         if (rowTable && rowTable.row_data.hasOwnProperty(key_input_to_find_value)) {
    //             const value = rowTable?.row_data?.[key_input_to_find_value];
    //             return Utilitaries.isEmpty(value) ? "" : value;
    //         }
    //         const value = formState?.[key_input_to_find_value]?.value;
    //         return Utilitaries.isEmpty(value) ? "" : value;
    //     }

    //     // CHECK FOR INPUT GROUP
    //     if (rowTable) {
    //         const value = rowTable?.row_data?.[key_input_to_find_value];
    //         return Utilitaries.isEmpty(value) ? "" : value;
    //     }

    // }

    // return "CASO_NAO_TRATADO";
}
