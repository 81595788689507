import { Utilitaries } from "../../../../business";
import { InputInTableProps } from "../../../types/common";
import {
    FormAttributesProps,
    FormInputsObjectProps,
    FormInputsProps,
    FormRootElementAttributesProps,
} from "../../../types/formTypes";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";
import { api_fetch } from "../../../utils/HttpRequest/request";
import { ButtonsVisibleProps } from "../Components/FormButtonsActions";
import { checkInputRelevant, checkInputRequired, checkInputRestriction } from "./inputHandle";
// FormInputsProps

interface ValidateFieldProps {
    input: FormInputsProps;
    formState?: FormInputsObjectProps;
    rowTable?: InputInTableProps;
}

interface FieldError {
    required: boolean;
    restriction: boolean;
}

const validateField = ({ input, formState, rowTable }: ValidateFieldProps) => {
    const value = rowTable?.row_key ? rowTable.row_data?.[input.key] : input.value;

    const relevant = checkInputRelevant({ componentProperties: input, formState, rowTable });

    if (relevant) {
        const _required = checkInputRequired({ componentProperties: input, formState, rowTable });

        if (_required && Utilitaries.isEmpty(value)) {
            return {
                required: true,
            } as FieldError;
        }

        const _restriction = checkInputRestriction({ componentProperties: input, formState, rowTable, value });

        if (!_restriction) {
            return {
                restriction: true,
            } as FieldError;
        }
    }

    return null;
};

interface ValidateTableProps {
    input: FormInputsProps;
    formState?: FormInputsObjectProps;
}
const validateTable = ({ input, formState }: ValidateTableProps) => {
    const dataSource = Object.values(input.DATA_SOURCE || {});
    let tableFields: FormInputsProps[] = [];
    let errors: string[] = [];

    if ([COMPONENT_TYPE.DATAGRID, COMPONENT_TYPE.DYNAMIC_DATAGRID].includes(input.type!)) {
        for (const key in input) {
            if (typeof input[key] === "object" && !["value", "DATA_SOURCE"].includes(key)) {
                tableFields.push(input[key]);
            }
        }
    } else {
        for (const key in input) {
            if (typeof input[key] === "object" && !["value", "DATA_SOURCE"].includes(key)) {
                tableFields.push(
                    ...((Object.values(input[key])?.filter((item) => typeof item === "object") as FormInputsProps[]) ??
                        [])
                );
            }
        }
    }

    if (Array.isArray(dataSource)) {
        dataSource.forEach((row_data, index) => {
            const rowTable: InputInTableProps = {
                row_key: row_data.key,
                row_data,
            };

            tableFields.forEach((tableItem) => {
                const fieldError = validateField({ input: tableItem, formState, rowTable });
                if (fieldError?.required || fieldError?.restriction) {
                    const messageError = getErrorMessage({
                        fieldError,
                        input: tableItem,
                        indexRow: row_data.key || "",
                    });
                    if (messageError) errors.push(messageError);
                }
            });
        });
    }

    return errors;
};

interface GetErrorMessageProps {
    fieldError: FieldError;
    input: FormInputsProps;
    // rowTable?: InputInTableProps;
    indexRow?: string;
}
const getErrorMessage = ({ fieldError, input, indexRow }: GetErrorMessageProps) => {
    if (fieldError.required) {
        if (indexRow) {
            const errorMessage = `${indexRow}ª linha - `;
            return input.label
                ? `Campo obrigatório: ${errorMessage + input.label}`
                : `${errorMessage} Campo obrigatório: ${input.group || input.key} `;
        } else {
            return input.label
                ? `Campo obrigatório: ${input.label} `
                : `Campo obrigatório: ${input.group || input.key} `;
        }
    } else if (fieldError.restriction) {
        let errorMessage = input.error_message || `Campo com erro: ${input.label || input.key}`;
        if (indexRow) {
            return `${indexRow}ª linha - ${errorMessage}`;
        } else {
            return errorMessage;
        }
    }
    return null;
};

export const getFormErrors = (inputs: FormInputsObjectProps, formState: FormInputsObjectProps) => {
    let errors: string[] = [];

    const keys = Object.keys(inputs);

    keys.forEach((key) => {
        const input = inputs[key];
        if ([COMPONENT_TYPE.TAB_SEPARATOR, COMPONENT_TYPE.POPUP].includes(input.type!)) {
            return;
        }
        if (!input?.key) {
            return;
        }
        if (
            [
                COMPONENT_TYPE.DATAGRID,
                COMPONENT_TYPE.DYNAMIC_DATAGRID,
                COMPONENT_TYPE.SELECTION_TABLE,
                COMPONENT_TYPE.DYNAMIC_TABLE,
            ].includes(input.type!)
        ) {
            //validate table
            const tableErrors = validateTable({ input, formState });
            errors.push(...tableErrors);
        } else if (input.type === COMPONENT_TYPE.INPUT_GROUP) {
            //recursive
            const errorsInputGroup = getFormErrors(input, formState);
            errors.push(...errorsInputGroup);
        } else {
            const fieldError = validateField({ input, formState });
            if (fieldError?.required || fieldError?.restriction) {
                const messageError = getErrorMessage({ fieldError, input });
                if (messageError) errors.push(messageError);
            }
        }
    });
    return errors;
};

/**Get data from form to save */

export const formatDataToSave = (form: FormInputsObjectProps) => {
    const formData: any = {};
    const keys = Object.keys(form);
    keys.forEach((key) => {
        const input = form[key];
        if ([COMPONENT_TYPE.TAB_SEPARATOR, COMPONENT_TYPE.POPUP].includes(input.type!)) {
            return;
        }
        if (input.persist === "nao" || !input?.key) {
            return;
        }
        if ([COMPONENT_TYPE.SELECTION_TABLE, COMPONENT_TYPE.DYNAMIC_TABLE].includes(input.type!)) {
            // !["value", "dataSource", "inputGroupPath", "path"].includes(key)
            const secondKey = Object.keys(input).find(
                (f) => !["DATA_SOURCE", "value"].includes(f) && typeof input[f] === "object"
            );

            const dataSource = input.DATA_SOURCE ? Object.values(input.DATA_SOURCE) : [];

            if (secondKey) {
                formData[key] = {
                    [secondKey]: dataSource,
                };
            }
        } else if ([COMPONENT_TYPE.DATAGRID, COMPONENT_TYPE.DYNAMIC_DATAGRID].includes(input.type!)) {
            const dataSource = input.DATA_SOURCE ? Object.values(input.DATA_SOURCE) : [];
            formData["datagridqa_" + key] = {
                [key]: dataSource,
            };
        } else if (input.type === COMPONENT_TYPE.INPUT_GROUP) {
            formData[key] = formatDataToSave(input);
        } else {
            formData[key] = Utilitaries.toString(Utilitaries.isEmpty(input.value) ? "" : input.value);
        }
    });

    return formData;
};

export const handleSave = async (
    form: FormInputsObjectProps,
    formAttributes: FormAttributesProps,
    temporary = false
) => {
    if (!temporary) {
        const errors = getFormErrors(form, form);
        if (errors.length > 0) {
            return { errors, status: false };
        }
    }

    const _formData = formatDataToSave(form);

    const params = {
        accao: "guardar",
        formulario: formAttributes.name,
        obrigatorios: temporary, //false,
        dados: JSON.stringify({
            [formAttributes.rootElement]: _formData,
        }),
    };

    const response = await api_fetch({
        endPoint: `Gestor/gereprocesso.php`,
        method: "POST",
        params,
    });

    return response;
};

// export const handlePrintForm = async (form: FormInputsObjectProps, formAttributes: FormAttributesProps) => {
//     const _formData = formatDataToSave(form);

//     const params = {
//         accao: "html",
//         saida: "pdf",
//         is_socket: "false",
//         formulario: formAttributes.name,
//         dados: JSON.stringify({
//             [formAttributes.rootElement]: _formData,
//         }),
//     };

//     const response = await api_fetch({
//         endPoint: `Gestor/gererelatorios.php`,
//         method: "POST",
//         params,
//     });

//     return response;
// };

export const showPrinterButton = (attributes?: FormRootElementAttributesProps) => {
    if (attributes?.printer === "nao") {
        return false;
    }
    if (attributes?.printer === "false") {
        return false;
    }
    return true;
};

export const showButtons = (attributes?: FormRootElementAttributesProps, justSee?: boolean) => {
    const buttonsVisible: ButtonsVisibleProps = {} as ButtonsVisibleProps;

    buttonsVisible.print = showPrinterButton(attributes);
    if (justSee) {
        return buttonsVisible;
    }

    if (attributes?.type === "subformulario") {
        buttonsVisible.save = true;
    } else if (attributes?.buttons === "botoes_subformulario") {
        buttonsVisible.save = true;
    } else if (attributes?.buttons === "topo") {
        buttonsVisible.save = true;
        buttonsVisible.temporarySave = true;
        buttonsVisible.correctionList = true;
    } else if (attributes?.consult_form) {
        buttonsVisible.save = false;
        buttonsVisible.temporarySave = false;
        buttonsVisible.reformulate = false;
    }
    // else if (attributes?.ignore_data) {
    //     buttonsVisible.save = false;
    //     buttonsVisible.temporarySave = false;
    //     buttonsVisible.reformulate = false;
    // }
    return buttonsVisible;
};
