import React, { Component } from "react";
import { Table, Button, Popconfirm, Input, Row, Icon, Badge } from "antd";
import LevelCategory from "./level.category";

class ProfessionlCategory extends Component {
    columns = [
        {
            title: "Designação",
            dataIndex: "designacao",
            width: 495,
            render: (text) => {
                text = typeof text === "object" ? "" : text;
                return <Input value={text} />;
            },
        },
        {
            title: "Níveis",
            dataIndex: "levelNumber",
            width: 75,
            render: (text, record) => {
                const countLevel =
                    record.num_niveis && typeof record.num_niveis !== "object"
                        ? record.num_niveis
                        : record.level.length;
                return (
                    <>
                        <Badge showZero count={countLevel}>
                            <Button icon={countLevel === 0 ? "plus" : "edit"} onClick={() => this.showModal(record)} />
                        </Badge>
                    </>
                );
            },
        },
        {
            title: "Nº de escalões",
            dataIndex: "num_escaloes",
            width: 210,
            render: (text) => {
                text = typeof text === "object" ? "" : text;
                return <Input value={text} />;
            },
        },
        {
            title: "Anos transitar escalão",
            dataIndex: "anos_transitar_escalao",
            render: (text) => {
                text = typeof text === "object" ? "" : text;
                return <Input value={text} />;
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: 90,
            fixed: "right",
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        okText="Ok"
                        cancelText="Não"
                        onConfirm={() =>
                            this.props.deleteCategory({
                                isEdit: this.props.isEdit,
                                key: record.key,
                            })
                        }
                    >
                        <Button
                            type="link"
                            style={{
                                padding: 0,
                                color: "#fa541c",
                            }}
                        >
                            Eliminar
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    componentDidMount() {
        this.toggleScrollTable();
        window.addEventListener("resize", this.toggleScrollTable.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.toggleScrollTable.bind(this));
    }

    toggleScrollTable = () => {
        if (window.innerWidth <= 756 && this.state.scrollX === 0) this.setState({ scrollX: 600 });
        else if (this.state.scrollX !== 0) this.setState({ scrollX: 0 });
    };

    saveCategory = (dataIndex, value, record) => {
        const recordToAlter = { ...record };
        recordToAlter[dataIndex] = value;
        this.props.saveCategory({
            isEdit: this.props.isEdit,
            record: recordToAlter,
        });
    };

    //---------- level modal -------------
    state = {
        showVisible: false,
        selectedCategory: {},
        scrollX: 0,
    };

    showModal = (record) => {
        this.setState({
            selectedCategory: record,
            showVisible: true,
        });
    };

    submitLevel = (record) => {
        this.setState({
            showVisible: false,
        });
        this.props.saveCategory({ isEdit: this.props.isEdit, record });
    };

    handleCancelarNivel = (e) => {
        this.setState({
            showVisible: false,
            selectedCategory: null,
        });
    };

    render() {
        const columns = this.columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    title: col.title,
                    onChange: (event) => {
                        return this.saveCategory(col.dataIndex, event.target.value, record);
                    },
                }),
            };
        });
        return (
            <>
                {this.state.showVisible && (
                    <LevelCategory
                        isModalVisible={this.state.showVisible}
                        handleCancel={this.handleCancelarNivel}
                        submitLevel={this.submitLevel}
                        category={this.state.selectedCategory}
                        nomeConvencao={this.props.nomeConvencao}
                        isEdit={this.props.isEdit}
                    />
                )}

                <Row>
                    <Button
                        onClick={() =>
                            this.props.addCategory({
                                isEdit: this.props.isEdit,
                            })
                        }
                        type="primary"
                        style={{ float: "right", marginBottom: 16 }}
                    >
                        <Icon type="plus" />
                        Inserir
                    </Button>
                </Row>

                <Table
                    rowKey={(record) => record.id_categoria}
                    rowClassName={() => "editable-row"}
                    dataSource={this.props.listCategoria}
                    columns={columns}
                    scroll={{ x: this.state.scrollX }}
                    size="middle"
                    loading={this.props.isFetching}
                />
            </>
        );
    }
}
export default ProfessionlCategory;
