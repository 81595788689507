import React, { useMemo } from "react";
import { Row, Table, Input, Select, Form } from "antd";
import moment from "moment";

const { Option } = Select;

const TasksAssignmentTabPane = ({ toReview, getFieldDecorator, initialFormData, id, enableField }) => {
    const { responsavel_aprovacao, data_aprovacao, responsavel_anulacao, data_anulacao, descricao_alteracao } =
        initialFormData.document;

    const handleValue = (value) => {
        if (value && typeof value === "object") {
            return "";
        } else if (value) {
            return value;
        }

        return "";
    };

    const dataSource = [
        {
            key: 0,
            task: "Aprovação",
            responsavel_aprovacao: handleValue(responsavel_aprovacao),
            owner_key: "responsavel_aprovacao",
            date: data_aprovacao ? moment(data_aprovacao) : null,
            date_key: "data_aprovacao",
        },
        {
            key: 1,
            task: "Anulação",
            responsavel_anulacao: handleValue(responsavel_anulacao),
            owner_key: "responsavel_anulacao",
            date: data_anulacao && typeof data_anulacao !== "object" ? moment(data_anulacao) : null,
            date_key: "data_anulacao",
        },
    ];

    if (id) {
        dataSource.push({
            key: 2,
            task: "Alteração",
            descricao_alteracao: handleValue(descricao_alteracao),
        });
    }

    const column = useMemo(
        () => [
            { title: "Tarefa", dataIndex: "task", width: "20%" },
            {
                title: "Responsável",
                width: "50%",
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };

                    if (index === 2) {
                        obj.props.colSpan = 2;
                        obj.children = (
                            <Form.Item
                                style={{
                                    width: "100%",
                                    padding: 0,
                                }}
                            >
                                {getFieldDecorator("descricao_alteracao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                    initialValue: row.descricao_alteracao,
                                })(<Input.TextArea rows={4} disabled={toReview ? false : enableField} />)}
                            </Form.Item>
                        );
                    } else {
                        obj.children = (
                            <Form.Item
                                style={{
                                    width: "100%",
                                    padding: 0,
                                }}
                            >
                                {getFieldDecorator(row.owner_key, {
                                    rules: [
                                        row.owner_key === "responsavel_aprovacao" && {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                    initialValue: row[row.owner_key],
                                })(
                                    <Select
                                        showSearch
                                        allowClear
                                        disabled={toReview && index === 0 ? false : enableField}
                                        style={{
                                            width: "100%",
                                            margin: "0 auto",
                                            padding: "0",
                                        }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {initialFormData.users.map((it) => (
                                            <Option key={`select_user_${it.util}`} value={it.util}>
                                                {it.nome}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        );
                    }

                    return obj;
                },
            },
            {
                title: "Data",
                dataIndex: "date",
                width: "30%",
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };

                    if (index === 2) {
                        obj.props.colSpan = 0;
                    } else {
                        obj.children = (
                            <Form.Item
                                style={{
                                    width: "100%",
                                    padding: 0,
                                }}
                            >
                                {getFieldDecorator(row.date_key, {
                                    // rules: [
                                    //   row.date_key === "data_aprovacao" && {
                                    //     required: true,
                                    //     message: "Campo obrigatorio",
                                    //   },
                                    // ],
                                    initialValue: value ? moment(value).format("YYYY-MM-DD") : "",
                                })(
                                    <Input
                                        type="date"
                                        disabled={toReview && index === 0 ? false : enableField}
                                        style={{ width: "100%" }}
                                        format={"DD-MM-YYYY"}
                                    />
                                )}
                            </Form.Item>
                        );
                    }

                    return obj;
                },
            },
        ],
        [toReview]
    );

    return (
        <div>
            <Row>
                <Table pagination={false} columns={column} dataSource={dataSource} />
            </Row>
        </div>
    );
};

export default TasksAssignmentTabPane;
