import { processDefinitionReducer } from "../modules/process_definition/reducers/index";
import { processExecutionReducer } from "../modules/process_execution/reducers/index";
import { functionalGroupReducer } from "../modules/function_manual/reducers/group";
import { funcionalGroupReducer } from "../modules/function_manual/reducers/funcional.group";
import { contributorReducer } from "../modules/function_manual/reducers/contributor";
import { collectiveAgreementReducer } from "../modules/function_manual/reducers/collective.agreement";
import { knowledgeReducer } from "../modules/function_manual/reducers/knowledge";
import { knowledgeTypeReducer } from "../modules/function_manual/reducers/knowledge.type";
import { competenceReducer } from "../modules/function_manual/reducers/competence";
import { competenceTypeReducer } from "../modules/function_manual/reducers/competence.type";
import { requirementReducer } from "../modules/function_manual/reducers/requirement";
import { requirementTypeReducer } from "../modules/function_manual/reducers/requirement.type";
import { behavioralParameterReducer } from "../modules/function_manual/reducers/behavioral.parameter";
import { timeRegistrationReducer } from "../modules/function_manual/reducers/time.registration";
import { holidayReducer } from "../modules/function_manual/reducers/holiday";
import { dynamicListReducer } from "../modules/dynamic_list/reducers/index";
import { standartsReducer } from "../modules/standards/reducers/standarts";
import { qaStandartsReducer } from "../modules/standards/reducers/qa_standart";
import { MenusReducer } from "../modules/Menu/reducers/index";

import { authorizationsReducer } from "../modules/authorizations/reducers/index";
import { fixedListReducer } from "../modules/fixed_list/reducers/index";
import { politicsReducer } from "../modules/politics/reducers/index";
import { taskPanelReducer } from "../modules/process_execution/reducers/panel";
import { homeReducer } from "../reduces/index";
import { contributorTimeRegistrationReducer } from "../modules/function_manual/reducers/contributors.time.registration";
import { correctionReducer } from "../modules/correction/reducers/index";
import { scheduleReducer } from "../components/Schedule/reduces/index";
import { forumReducer } from "../components/Forum/reducers/index";
import { chatReducer } from "../components/Chat/reducers/index";
import { updateReducer } from "../components/Update/reducers/index";
import { reportsReducer } from "../modules/reports/reducers/index";

export const reducers = {
    processDefinition: processDefinitionReducer,
    processExecution: processExecutionReducer,
    functionalGroup: functionalGroupReducer,
    funcionalGroup: funcionalGroupReducer,
    contributor: contributorReducer,
    collectiveAgreement: collectiveAgreementReducer,
    knowledge: knowledgeReducer,
    knowledgeType: knowledgeTypeReducer,
    competence: competenceReducer,
    competenceType: competenceTypeReducer,
    requirement: requirementReducer,
    requirementType: requirementTypeReducer,
    behavioralParameter: behavioralParameterReducer,
    timeRegistration: timeRegistrationReducer,
    dynamicList: dynamicListReducer,
    holiday: holidayReducer,
    standarts: standartsReducer,
    qaStandarts: qaStandartsReducer,
    authorizations: authorizationsReducer,
    fixedList: fixedListReducer,
    politics: politicsReducer,
    taskPanel: taskPanelReducer,
    contributorsTimeRegistration: contributorTimeRegistrationReducer,
    home: homeReducer,
    correction: correctionReducer,
    schedule: scheduleReducer,
    forum: forumReducer,
    chat: chatReducer,
    menus: MenusReducer,
    reports: reportsReducer,
    updateApp: updateReducer,
};
