const actionType = {
    CHANGE_MODAL_CHAT_STATE: "CHANGE_MODAL_CHAT_STATE",
    CHANGE_MODAL_CHAT_CONF_STATE: "CHANGE_MODAL_CHAT_CONF_STATE",
    ROOMS: "ROOMS",
    MESSAGES: "MESSAGES",
    ADD_MESSAGES: "ADD_MESSAGES",
    GET_USERS: "GET_USERS",
    SET_FILTER: "SET_FILTER",
    SET_SELECTED_ROOM: "SET_SELECTED_ROOM",
};

export default actionType;
