import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Form, Row, Col, Select, Input, message } from "antd";

import { insert, update } from "../../actions/hability";
import { selectDataFromExecQuery } from "../../actions/funcional.group";

const { Option } = Select;

const FormHability = ({
    insert,
    update,
    selectDataFromExecQuery,
    form,
    showModal,
    setShowModal,
    isEdit,
    setReload,
    reload,
    dataToEdit,
}) => {
    const { getFieldDecorator } = form;

    const [loading, setLoading] = useState(false);
    const [habilityType, setHabilityType] = useState([]);

    /**
     * fetch "tipos de habilidades" from server
     */
    useEffect(() => {
        if (showModal) {
            (async () => {
                const response = await selectDataFromExecQuery({
                    query: "tipos_habilidades_menu_habilidades",
                });
                if (response.result) {
                    if (Array.isArray(response.result)) {
                        setHabilityType(response.result);
                    }
                }
            })();
        }
    }, [showModal]);

    /**
     * save data to server
     */
    const handleOk = () => {
        form.validateFields(async (err, values) => {
            if (!err) {
                setLoading(true);
                let response = false;
                if (isEdit) {
                    response = await update({
                        accao: "guardar",
                        formulario: "qa_habilidades.xml",
                        dados: JSON.stringify({
                            habilidade: {
                                ...values,
                                id_habilidade: dataToEdit.id_habilidade,
                                activo: true,
                                versao: dataToEdit.versao || 1,
                            },
                        }),
                    });
                } else {
                    response = await insert({
                        accao: "guardar",
                        formulario: "qa_habilidades.xml",
                        dados: JSON.stringify({
                            habilidade: {
                                ...values,
                                activo: true,
                                versao: 1,
                            },
                        }),
                    });
                }

                if (response) message.success("Sucesso");
                else message.error("Ocorreu um erro");

                setLoading(false);
                setReload(!reload);
                setShowModal(false);
            }
        });
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={isEdit ? "Editar Habilidade" : "Nova Habilidade"}
                visible={showModal}
                onOk={handleOk}
                onCancel={() => setShowModal(false)}
                width="50%"
                confirmLoading={loading}
            >
                <Form layout="vertical">
                    <Row gutter={18}>
                        <Col xs={24}>
                            <Form.Item label="Tipo de habilidade">
                                {getFieldDecorator("tipo_habilidade", {
                                    initialValue: dataToEdit.id_tipo_habilidade,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório!",
                                        },
                                    ],
                                })(
                                    <Select
                                        allowClear
                                        showSearch
                                        // onChange={this.handleTipoChange}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {habilityType.map((item) => (
                                            <Option key={item.id_tipo_habilidade}>{item.designacao}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Habilidade">
                                {getFieldDecorator("designacao", {
                                    initialValue: dataToEdit.designacao,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

const mapDispatchToProps = {
    insert,
    update,
    selectDataFromExecQuery,
};

const WrappedHability = Form.create({ name: "hability" })(FormHability);

export default connect(null, mapDispatchToProps)(WrappedHability);
