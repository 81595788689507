import React from "react";
import { List, Comment, Tooltip } from "antd";
import moment from "moment";
import "moment/locale/pt";
import { baseUrl } from "../../../constants/enviroment";
import { Session } from "../../../business";

const Message = ({ message }) => {
    const downloadFile = (id, name) => {
        name = name ? name : "";
        const formData = new FormData();
        formData.append("accao", "ficheiro");
        formData.append("ficheiro", id);

        var request = new XMLHttpRequest();
        request.open("POST", baseUrl + "/Gestor/geredocumentos.php", true);

        request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
        request.responseType = "blob";

        request.onload = function () {
            if (request.status === 200) {
                // Try to find out the filename from the content disposition `filename` value
                var disposition = request.getResponseHeader("content-disposition");
                var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);

                var filename = matches != null && matches[1] ? matches[1] : name;

                // The actual download
                var blob = new Blob([request.response], {
                    type: request.getResponseHeader("content-type"),
                });

                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);

                // The actual download
                // var blob = new Blob([request.response], {
                //     type: request.getResponseHeader("content-type")
                // });

                // //Build a URL from the file
                // const fileURL = URL.createObjectURL(blob);
                // //Open the URL on new Window
                // window.open(fileURL);
            }
        };

        request.send(formData);
    };

    const renderFiles = (files) =>
        files.map((item, idx) => {
            return (
                <p>
                    <a
                        href="!#"
                        onClick={(e) => {
                            e.preventDefault();
                            downloadFile(item.id_ficheiro, item.nome_ficheiro);
                        }}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {item.nome_ficheiro}
                    </a>
                </p>
            );
        });

    return (
        <List
            header={null}
            footer={null}
            bordered={false}
            split={false}
            style={{
                maxHeight: 400,
                overflow: "auto",
                minHeight: 400,
            }}
            dataSource={message}
            renderItem={(item) => (
                <Comment
                    author={item.username}
                    content={
                        <>
                            {item.message && <span>{item.message}</span>}

                            {item &&
                                item.files &&
                                item.files["linha"] &&
                                (Array.isArray(item.files["linha"]) ||
                                    (typeof item.files["linha"] === "object" &&
                                        Object.keys(item.files["linha"]).length > 0)) &&
                                renderFiles(
                                    Array.isArray(item.files["linha"])
                                        ? item.files["linha"]
                                        : typeof item.files["linha"] === "object"
                                        ? [item.files["linha"]]
                                        : []
                                )}
                        </>
                    }
                    datetime={
                        <Tooltip title={moment(item.data).format("YYYY-MM-DD HH:mm:ss")}>
                            <span>{moment(item.data).fromNow()}</span>
                        </Tooltip>
                    }
                />
            )}
        />
    );
};

export default Message;
