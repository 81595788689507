import { actionType } from "./../constants/funcional.group";
import { arrayToObjectById } from "./../../../components/util";

const initialForm = () => ({
    id_ficha_funcao: "",
    codigo: "",
    descricao_funcao: "",
    missao: "",
    funcao_superior: "",
    total_ponderacao_requi: "",
    total_ponderacao_conhe: "",
    total_ponderacao_compet: "",
    total_ponderacao_comport: "",
    ficha_funcao_tarefas: "",
    activo: "",
    autorizacao_func: {
        autorizacao_func: [],
    },
    requisito_necessario: {
        requisito_necessario: [],
    },
    conhece_necessarios: {
        conhece_necessarios: [],
    },
    competencia_necessaria: {
        competencia_necessaria: [],
    },
    parametro_comportamental: {
        parametro_comportamental: [],
    },
    tarefas: {
        tarefa_si: { tarefa_si: [] },
        tarefa_nao_si: { tarefa_nao_si: [] },
    },
    detentor: [],
});

const initialState = {
    domain: {
        form: {
            ...initialForm(),
        },
        byId: {},
        metaData: {
            attributes: {},
        },
    },
    app: {
        isFetched: false,
        isFetching: false, //TODO: for check if app is calling to server
    },
};

let chave = 1;

export const funcionalGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.setValuesToIndexOfFieldsToTaskTable:
            const indx = state.domain.form.tarefas[action.payload.field][action.payload.field].indexOf(
                (item) => item.chave === action.payload.data.chave
            );
            if (indx !== -1) {
                state.domain.form.tarefas[action.payload.field][action.payload.field][indx] = action.payload.data;
            }
            return { ...state };
        case actionType.setValuesToIndexOfFields:
            const index = state.domain.form[action.payload.field][action.payload.field].indexOf(
                (item) => item.chave === action.payload.data.chave
            );
            state.domain.form[action.payload.field][action.payload.field][index] = action.payload.data;
            return { ...state };
        case actionType.setValuesToFields:
            const arr = (value) => {
                const new_array = [];
                if (Array.isArray(value)) {
                    value.map((item) => {
                        new_array.push({ autorizacao: item });
                    });
                }
                return Array.isArray(value)
                    ? {
                          [action.payload.field]: {
                              [action.payload.field]: new_array,
                          },
                      }
                    : {
                          [action.payload.field]: value,
                      };
            };
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        ...arr(action.payload.value),
                    },
                },
            };
        case actionType.removeRequisitosToFunctionalGroup:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        requisito_necessario: {
                            requisito_necessario: state.domain.form.requisito_necessario.requisito_necessario.filter(
                                (item) => item.chave !== action.id
                            ),
                        },
                    },
                },
            };
        case actionType.removeConhecimentoToFunctionalGroup:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        conhece_necessarios: {
                            conhece_necessarios: state.domain.form.conhece_necessarios.conhece_necessarios.filter(
                                (item) => item.chave !== action.id
                            ),
                        },
                    },
                },
            };
        case actionType.removeCompetenciaToFunctionalGroup:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        competencia_necessaria: {
                            competencia_necessaria: state.domain.form.competencia_necessaria.competencia_necessaria.filter(
                                (item) => item.chave !== action.id
                            ),
                        },
                    },
                },
            };
        case actionType.removeParametroToFunctionalGroup:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        parametro_comportamental: {
                            parametro_comportamental: state.domain.form.parametro_comportamental.parametro_comportamental.filter(
                                (item) => item.chave !== action.id
                            ),
                        },
                    },
                },
            };
        case actionType.removeTaskToFunctionalGroup:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tarefas: {
                            ...state.domain.form.tarefas,
                            tarefa_nao_si: {
                                tarefa_nao_si: state.domain.form.tarefas.tarefa_nao_si.tarefa_nao_si.filter(
                                    (item) => item.chave !== action.id
                                ),
                            },
                        },
                    },
                },
            };
        case actionType.addNewTaskToFunctionalGroup:
            const { tarefa_nao_si } = state.domain.form.tarefas.tarefa_nao_si;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tarefas: {
                            ...state.domain.form.tarefas,
                            tarefa_nao_si: {
                                tarefa_nao_si: [
                                    ...tarefa_nao_si,
                                    {
                                        chave: tarefa_nao_si.length + 2,
                                        processo_nao_si: "",
                                        tarefa_nao_si_desc: "",
                                        grupo: "",
                                        grupo_substituto: "",
                                        grupo_func_substituto: "",
                                        sem_substituto: "",
                                    },
                                ],
                            },
                        },
                    },
                },
            };

        case actionType.addNewRequisito:
            const { requisito_necessario } = state.domain.form.requisito_necessario;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        requisito_necessario: {
                            requisito_necessario: [
                                ...requisito_necessario,
                                {
                                    chave: requisito_necessario.length + 2,
                                    tipo_requisito: "",
                                    requisito: "",
                                    ponderacao: "",
                                    ativo: "",
                                },
                            ],
                        },
                    },
                },
            };
        case actionType.addNewConhecimento:
            const { conhece_necessarios } = state.domain.form.conhece_necessarios;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        conhece_necessarios: {
                            conhece_necessarios: [
                                ...conhece_necessarios,
                                {
                                    chave: conhece_necessarios.length + 2,
                                    tipo_conhecimento: "",
                                    conhecimento: "",
                                    ponderacao: "",
                                    ativo: "",
                                },
                            ],
                        },
                    },
                },
            };
        case actionType.addNewCompetencia:
            const { competencia_necessaria } = state.domain.form.competencia_necessaria;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        competencia_necessaria: {
                            competencia_necessaria: [
                                ...competencia_necessaria,
                                {
                                    chave: competencia_necessaria.length + 2,
                                    tipo_competencia: "",
                                    competencia: "",
                                    ponderacao: "",
                                    contribui: "",
                                    ativo: "",
                                },
                            ],
                        },
                    },
                },
            };
        case actionType.addNewParametroComport:
            const { parametro_comportamental } = state.domain.form.parametro_comportamental;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        parametro_comportamental: {
                            parametro_comportamental: [
                                ...parametro_comportamental,
                                {
                                    chave: parametro_comportamental.length + 2,
                                    tipo_parametros_comportam: "",
                                    ponderacao: "",
                                    ativo: "",
                                },
                            ],
                        },
                    },
                },
            };
        case actionType.selectFunctionalGroupContributors:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        detentor: action.payload.linha,
                    },
                },
            };
        case actionType.selectTasksSIByAutorizathion:
            chave = 1;
            let tarefa_si = [];
            if (action.payload && Array.isArray(action.payload)) {
                action.payload.map((item) => {
                    tarefa_si.push({ chave, ...item });
                    chave++;
                });
            } else if (action.payload && Object.entries(action.payload).length) {
                tarefa_si = [{ chave, ...action.payload }];
            }
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tarefas: {
                            ...state.domain.form.tarefas,
                            tarefa_si: {
                                tarefa_si: tarefa_si,
                            },
                        },
                    },
                },
            };
        case actionType.selectFunctionalGroupsTasks:
            chave = 1;
            let new_tarefa_si = [];
            if (
                action.payload.ficha_funcao_tarefas.tarefa_si !== undefined &&
                action.payload.ficha_funcao_tarefas.tarefa_si.tarefa_si !== undefined
            ) {
                if (Array.isArray(action.payload.ficha_funcao_tarefas.tarefa_si.tarefa_si)) {
                    action.payload.ficha_funcao_tarefas.tarefa_si.tarefa_si.map((item) => {
                        new_tarefa_si.push({ chave, ...item });
                        chave++;
                    });
                } else {
                    new_tarefa_si = [
                        {
                            chave,
                            ...action.payload.ficha_funcao_tarefas.tarefa_si.tarefa_si,
                        },
                    ];
                }
            }
            chave = 1;
            let new_tarefa_nao_si = [];
            if (Array.isArray(action.payload.ficha_funcao_tarefas.tarefa_nao_si.tarefa_nao_si)) {
                action.payload.ficha_funcao_tarefas.tarefa_nao_si.tarefa_nao_si.map((item) => {
                    new_tarefa_nao_si.push({ chave, ...item });
                    chave++;
                });
            } else {
                new_tarefa_nao_si = [
                    {
                        chave,
                        ...action.payload.ficha_funcao_tarefas.tarefa_nao_si.tarefa_nao_si,
                    },
                ];
            }
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        tarefas: {
                            tarefa_si: {
                                tarefa_si: new_tarefa_si,
                            },
                            tarefa_nao_si: {
                                tarefa_nao_si: new_tarefa_nao_si,
                            },
                        },
                    },
                },
            };
        case actionType.selectExistingGroup:
            chave = 1;
            let new_autorizacao_func = [];
            if (Array.isArray(action.payload.ficha_funcao.autorizacao_func.autorizacao_func)) {
                action.payload.ficha_funcao.autorizacao_func.autorizacao_func.map((item) => {
                    new_autorizacao_func.push({ chave, ...item });
                    chave++;
                });
            } else {
                new_autorizacao_func = [
                    {
                        chave,
                        ...action.payload.ficha_funcao.autorizacao_func.autorizacao_func,
                    },
                ];
            }
            chave = 1;
            let new_requisito_necessario = [];
            if (Array.isArray(action.payload.ficha_funcao.requisito_necessario.requisito_necessario)) {
                action.payload.ficha_funcao.requisito_necessario.requisito_necessario.map((item) => {
                    new_requisito_necessario.push({ chave, ...item });
                    chave++;
                });
            } else {
                new_requisito_necessario = [
                    {
                        chave,
                        ...action.payload.ficha_funcao.requisito_necessario.requisito_necessario,
                    },
                ];
            }
            chave = 1;
            let new_conhece_necessarios = [];
            if (Array.isArray(action.payload.ficha_funcao.conhece_necessarios.conhece_necessarios)) {
                action.payload.ficha_funcao.conhece_necessarios.conhece_necessarios.map((item) => {
                    new_conhece_necessarios.push({ chave, ...item });
                    chave++;
                });
            } else {
                new_conhece_necessarios = [
                    {
                        chave,
                        ...action.payload.ficha_funcao.conhece_necessarios.conhece_necessarios,
                    },
                ];
            }
            chave = 1;
            let new_competencia_necessaria = [];
            if (Array.isArray(action.payload.ficha_funcao.competencia_necessaria.competencia_necessaria)) {
                action.payload.ficha_funcao.competencia_necessaria.competencia_necessaria.map((item) => {
                    new_competencia_necessaria.push({ chave, ...item });
                    chave++;
                });
            } else {
                new_competencia_necessaria = [
                    {
                        chave,
                        ...action.payload.ficha_funcao.competencia_necessaria.competencia_necessaria,
                    },
                ];
            }
            chave = 1;
            let new_parametro_comportamental = [];
            if (Array.isArray(action.payload.ficha_funcao.parametro_comportamental.parametro_comportamental)) {
                action.payload.ficha_funcao.parametro_comportamental.parametro_comportamental.map((item) => {
                    new_parametro_comportamental.push({ chave, ...item });
                    chave++;
                });
            } else {
                new_parametro_comportamental = [
                    {
                        chave,
                        ...action.payload.ficha_funcao.parametro_comportamental.parametro_comportamental,
                    },
                ];
            }
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        ...action.payload.ficha_funcao,
                        requisito_necessario: {
                            requisito_necessario: new_requisito_necessario,
                        },
                        conhece_necessarios: {
                            conhece_necessarios: new_conhece_necessarios,
                        },
                        competencia_necessaria: {
                            competencia_necessaria: new_competencia_necessaria,
                        },
                        parametro_comportamental: {
                            parametro_comportamental: new_parametro_comportamental,
                        },
                        autorizacao_func: {
                            autorizacao_func: new_autorizacao_func,
                        },
                    },
                },
            };
        case actionType.select:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: action.payload["linha"]
                            ? Array.isArray(action.payload["linha"])
                                ? action.payload["linha"]
                                : [action.payload["linha"]]
                            : [],
                        id: "id_ficha_funcao",
                    }),
                    metaData: {
                        ...state.domain.metaData,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };

        case actionType.insert:
            const keys = Object.keys(state.domain.byId);
            const key = keys[keys.length - 1] + 1;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [key]: {
                            id: key,
                            designation: state.domain.form.designation.value,
                            key,
                            description: state.domain.form.description.value,
                        },
                    },
                },
            };
        case actionType.clearExistingGroupDataFromForm:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...initialForm(),
                    },
                },
            };
        case actionType.setField:
        default:
            return state;
    }
};
