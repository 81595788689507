import React from "react";
import { Button, Row } from "antd";

const ButtonsGroup = (props) => {
    return (
        <Row gutter={18} className="qa-group-actions-right">
            {/* <Button onClick={() => {}} type="primary" size="default">
                Eliminar
            </Button>

            <Button onClick={() => props.goTo("/process/edit/advanced/")} icon="" type="primary" size="default">
                Rever
            </Button> */}

            {props.isEditionDisabled === true ? (
                <Button onClick={() => props.toggleEdition()} size="default" type="primary">
                    Alterar
                </Button>
            ) : null}

            {props.isEditionDisabled === false ? (
                <Button type="primary" onClick={(e) => props.handleUpdate(e)} size="default">
                    Guardar
                </Button>
            ) : null}

            {props.actions === "edit" ? (
                <Button type="primary" onClick={() => {}} size="default">
                    Fechar Edição
                </Button>
            ) : null}
        </Row>
    );
};

export default ButtonsGroup;
