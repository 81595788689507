import { Card, Col, Descriptions, Table, Tag } from "antd";
import React from "react";
import { getColumnSearchProps } from "../../function_manual/components/search.into.table.columns";
import ChartHelp from "./chartHelp";

export const NestedTableChart = ({ metadata, dataSource }) => {
    const expandedRowRender = (record) => {
        let columns = [];
        if (metadata.expandedRowColumns) {
            metadata.expandedRowColumns.forEach((column, index) => {
                columns.push({
                    key: index,
                    title: column.label,
                    dataIndex: column.key,
                    // width: "15%",
                    ...getColumnSearchProps(column.key),
                });
            });
        }

        columns.push({
            key: "percentage",
            title: "Percentagem",
            dataIndex: "percentage",
            // width: "15%",
            ...getColumnSearchProps("percentage"),
        });

        return (
            <Table
                bordered
                size="small"
                columns={columns}
                dataSource={record?.data?.sort((a, b) => b.label - a.label) || []}
                pagination={false}
            />
        );
    };

    let columns = [];

    if (metadata.columns) {
        metadata.columns.forEach((column, index) => {
            columns.push({
                key: index,
                title: column.label,
                dataIndex: column.key,
                // width: "15%",
                ...getColumnSearchProps(column.key),
            });
        });
    }

    const extraColumns = [
        {
            key: "total",
            title: "Total",
            dataIndex: "total",
            // width: "15%",
            ...getColumnSearchProps("total"),
        },
        {
            key: "percentage",
            title: "Percentagem",
            dataIndex: "percentage",
            // width: "15%",
            ...getColumnSearchProps("percentage"),
        },
    ];

    columns.push(...extraColumns);

    let totalOfAll = 0;
    let newDataSource = dataSource;
    if (!Array.isArray(dataSource)) {
        newDataSource = [dataSource];
    }

    let dataSourceWithTotalAndPercentage = newDataSource.map((item) => {
        const total = item.data.reduce((accumulator, current) => Number(accumulator) + Number(current.valor), 0);

        const data = item.data.map((el) => {
            const percentage = `${((Number(el.valor) / Number(total)) * 100).toFixed(2)}%`;

            return {
                ...el,
                percentage,
            };
        });

        totalOfAll += total;

        return {
            ...item,
            data,
            total,
        };
    });

    dataSourceWithTotalAndPercentage = dataSourceWithTotalAndPercentage.map((data) => {
        const percentage = `${((Number(data.total) / Number(totalOfAll)) * 100).toFixed(2)}%`;

        return {
            ...data,
            percentage,
        };
    });

    return (
        <Col xs={24}>
            <Card
                size={"small"}
                title={metadata.title ? metadata.title : " "}
                extra={metadata.help ? <ChartHelp text={metadata.help} /> : null}
                className={"qa-title-dashboard"}
            >
                {typeof totalOfAll === "number" && (
                    <Descriptions layout="horizontal">
                        <Descriptions.Item label={metadata.labelTotal}>
                            <Tag color="#2db7f5">{totalOfAll}</Tag>
                        </Descriptions.Item>
                    </Descriptions>
                )}

                <Table
                    rowKey={(record) => record.key}
                    size="small"
                    // loading={loadingData}
                    columns={columns}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "40"],
                        showTotal: (total) => `Total registos: ${total}`,
                        total: dataSourceWithTotalAndPercentage.lenght,
                    }}
                    expandedRowRender={expandedRowRender}
                    dataSource={dataSourceWithTotalAndPercentage}
                />
            </Card>
        </Col>
    );
};
