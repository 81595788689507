import React, { useState, useEffect } from "react";
import { Modal, Row, Col, List, Icon, Select, Checkbox, Skeleton, message } from "antd";
import { connect } from "react-redux";

import { getContributorsByFuncionalGroup } from "../../../actions/index";

const { Option } = Select;

const SelectUser = ({
    visible,
    setVisible,
    onOk,
    defaultUsers,
    defaultGroups,
    contributorsByFuncionalGroup,
    allContributors,
    funcionalGroups,
    getContributorsByFuncionalGroup,
}) => {
    const [isloadingContributorsByFuncionalGroup, setIsLoadingContributorsByFuncionalGroup] = useState(false);

    const [groupsOjbect, setGroupsOjbect] = useState({});
    const [groups, setGroups] = useState([]);

    const [users, setUsers] = useState([]);
    const [usersOjbect, setUsersOjbect] = useState({});

    const [contributores, setContributores] = useState([]);

    const [selectedGroup, setSelectedGroup] = useState({});

    useEffect(() => {
        setContributores(contributorsByFuncionalGroup);
    }, [contributorsByFuncionalGroup]);

    useEffect(() => {
        if (defaultUsers) {
            let newUsers = {};
            defaultUsers.forEach((item) => {
                newUsers[item.username] = item;
            });
            setUsers(Object.values(newUsers));
            setUsersOjbect(newUsers);
        }

        if (defaultGroups) {
            let newGroups = {};
            defaultGroups.forEach((item) => {
                newGroups[item.value] = item;
            });
            setGroups(Object.keys(newGroups));
            setGroupsOjbect(newGroups);
        }
    }, [defaultUsers, defaultGroups]);

    const loadingContributorsByFuncionalGroup = async (funcionalGroupId) => {
        try {
            setIsLoadingContributorsByFuncionalGroup(true);

            await getContributorsByFuncionalGroup({
                accao: "lista_funcionarios",
                lista: 0,
                id_funcional_group: funcionalGroupId,
            });
        } catch (error) {
            message.error("Erro ao obter utilizadores do grupo funcional");
        } finally {
            setIsLoadingContributorsByFuncionalGroup(false);
        }
    };

    const removeGroupFromSelected = (item) => {
        const newGroups = groupsOjbect;

        delete newGroups[item];

        setGroups(Object.keys(newGroups));
        setGroupsOjbect(newGroups);
    };

    const removeUserFromSelected = (username) => {
        let newUsers = usersOjbect;
        delete newUsers[username];
        setUsers(Object.values(newUsers));
        setUsersOjbect(newUsers);
    };

    const handleChange = (value, option) => {
        const id = option.props.data_id;

        if (id === "allUsers") {
            setContributores(allContributors.funcionarios.data);
            setSelectedGroup({});
            return;
        }

        setSelectedGroup({
            value,
            id,
        });

        loadingContributorsByFuncionalGroup(id);
    };

    const handleSelectGroup = (e) => {
        if (Object.keys(selectedGroup).length <= 0) {
            return;
        }

        const newGroups = groupsOjbect;

        if (e.target.checked) {
            // add
            newGroups[selectedGroup.value] = selectedGroup;
        } else {
            //remove
            delete newGroups[selectedGroup.value];
        }

        setGroups(Object.keys(newGroups));
        setGroupsOjbect(newGroups);
    };

    const handleUsersList = (e, item) => {
        let newUsers = usersOjbect;
        if (e.target.checked) {
            // add
            newUsers[item.username] = item;
        } else {
            //remove
            delete newUsers[item.username];
        }
        setUsers(Object.values(newUsers));
        setUsersOjbect(newUsers);
    };

    const listOfFuncionalGroups = funcionalGroups.map((item) =>
        typeof item.id_ficha_funcao !== "object" && typeof item.descricao_funcao !== "object" ? (
            <Option key={item.codigo} data_id={item.id_ficha_funcao}>
                {item.codigo}
            </Option>
        ) : null
    );

    listOfFuncionalGroups.push(
        <Option key="allUsers" data_id="allUsers">
            Todos Utilizadores
        </Option>
    );

    return (
        <Modal
            width="50%"
            style={{ top: 20 }}
            visible={visible}
            title="Adicionar Grupos e Utilizadores"
            okText="Guardar"
            onCancel={() => setVisible(false)}
            onOk={() => {
                onOk(Object.values(usersOjbect), Object.values(groupsOjbect));
                // setVisible(false);
            }}
        >
            <Row>
                <Col span={12} style={{ paddingRight: 5 }}>
                    <List
                        className="qa-list"
                        size="small"
                        header={<div>Grupos</div>}
                        footer={null}
                        bordered
                        dataSource={groups}
                        renderItem={(item) => (
                            <List.Item key={item}>
                                <List.Item.Meta description={item} />
                                <div>
                                    <Icon
                                        onClick={() => removeGroupFromSelected(item)}
                                        className="qa-list-action"
                                        type="delete"
                                    />
                                </div>
                            </List.Item>
                        )}
                        style={{ marginBottom: 10 }}
                    />

                    <List
                        className="qa-list"
                        size="small"
                        header={<div>Utilizadores</div>}
                        footer={null}
                        bordered
                        dataSource={users}
                        renderItem={(item) => (
                            <List.Item key={item}>
                                <List.Item.Meta description={item.name} />
                                <div>
                                    <Icon
                                        onClick={() => removeUserFromSelected(item.username)}
                                        className="qa-list-action"
                                        type="delete"
                                    />
                                </div>
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={12} style={{ paddingLeft: 5 }}>
                    <Select
                        mode="default"
                        style={{ width: "100%" }}
                        placeholder=""
                        showSearch
                        onChange={handleChange}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {listOfFuncionalGroups}
                    </Select>

                    <Skeleton
                        loading={isloadingContributorsByFuncionalGroup}
                        paragraph={{ rows: 5, width: "100%" }}
                        active
                    >
                        <List
                            className="qa-list-user"
                            size="small"
                            style={{ marginTop: 8 }}
                            header={
                                <div>
                                    <Checkbox
                                        checked={groups.includes(selectedGroup.value)}
                                        onChange={(e) => handleSelectGroup(e)}
                                    >
                                        Grupo
                                    </Checkbox>
                                </div>
                            }
                            footer={null}
                            bordered
                            dataSource={contributores.map((item) => ({
                                name: item.nome,
                                username: item.username,
                            }))}
                            renderItem={(item) => (
                                <List.Item key={item.username}>
                                    <Checkbox
                                        checked={usersOjbect[item.username] !== undefined}
                                        onChange={(e) => handleUsersList(e, item)}
                                    >
                                        {item.name}
                                    </Checkbox>
                                </List.Item>
                            )}
                        />
                    </Skeleton>
                </Col>
            </Row>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        contributorsByFuncionalGroup: state.home.domain.contributors_by_funcional_group || [],
        funcionalGroups: Object.values(state.funcionalGroup.domain.byId),
        allContributors: state.fixedList.domain.byId,
    };
};
const mapDispatchToProps = {
    // getFunctionalGroups,
    // getAllContributors,
    getContributorsByFuncionalGroup,
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectUser);
