import React from "react";
import { Button, Tooltip, Icon } from "antd";

const ButtonsGroup = ({ allowInsertion, handleAdd, handleExportToPdf }) => {
    return (
        <>
            {allowInsertion && (
                <Button onClick={handleAdd} type="primary" style={{ float: "right", marginLeft: 8, marginBottom: 16 }}>
                    <Icon type="plus" />
                    Inserir
                </Button>
            )}

            <Tooltip title="Exportar para pdf">
                <Button
                    onClick={handleExportToPdf}
                    icon="file-pdf"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#fa541c",
                    }}
                />
            </Tooltip>
            <Tooltip title="Exportar para excel">
                <Button
                    onClick={() => {}}
                    icon="file-excel"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#237804",
                    }}
                />
            </Tooltip>
        </>
    );
};

export default ButtonsGroup;
