import React, { useState, useEffect, useContext, useMemo } from "react";
import { Col, Popconfirm, Radio, Icon } from "antd";

import { sendHttpRequest } from "../../http.request";
import { QADynamicTable } from "../../qa_custom/index";
import QADivider from "./divider";
import FormContext from "../context/FormContext";
import { toArray } from "../../util";
import { FormUtilitaries, Utilitaries } from "../../../business";
import { isEmpty } from "../../../modules/process_execution/utils/fields";
import { QAToolTip } from ".";
import { getInputLabel } from "../utils/functions";
import { Session } from "../../../business";

const renderSelectAll = ({ currentItem, columnKey, justSee, changeAll }) => {
    if (currentItem?.["@seleccionar_todos"] === "sim") {
        if (!isEmpty(currentItem?.["@valores"])) {
            return <></>;
        } else if (!isEmpty(currentItem?.["@dados"])) {
            return <></>;
        } else {
            return (
                <Popconfirm
                    title="Selecionar todos como:"
                    onConfirm={() => changeAll(true, columnKey, currentItem)}
                    onCancel={() => changeAll(false, columnKey, currentItem)}
                    okText="Sim"
                    cancelText="Não"
                    disabled={justSee}
                >
                    <Icon type="check-square" />
                </Popconfirm>
            );
        }
    }
};

const QADataGrid = ({
    objectInputs,
    fields,
    field,
    inputAttribute,
    index,
    renderItems,
    addItem,
    removeItem,
    visible,
    renderGroup,
    checkIfFieldCanBeEdited,
}) => {
    let defaultData = {};

    const [dataGrigList, setDataGridList] = useState([]);
    const [inputType, setInputType] = useState(null);
    const [loadingData, setLoadingData] = useState(false);

    const { setTableData, formData, justSee } = useContext(FormContext);
    // para cada input dentro do objeto [field]
    // Obter os inputs
    const tableInputs = objectInputs[field];
    // Obter o input {[field]: {...}}
    // const tableSecondKey = formData?.[field]?.[field] ? field : "linha";
    const tableSecondKey = "linha";

    // Obter keys de todos os inputs
    const tableInputsKeys = Object.keys(tableInputs).filter(
        (it) => !it.startsWith("@") && typeof tableInputs[it] === "object" && !Array.isArray(tableInputs[it])
    );

    let paramsDependencies = [];
    const actions =
        inputAttribute["@mostrar_apenas_botao_add"] || inputAttribute["@mostrar_botoes_add_rem"]
            ? true
            : inputAttribute["@dados"]
            ? false
            : true;

    const getData = (validationNull = false) => {
        const fd = new FormData();
        fd.append("accao", "lista");
        fd.append("lista", inputAttribute["@dados"]);

        let splitedParamPopup = [];

        let hasNull = false;
        if (formData && formData[inputAttribute["@param_popup"]]) {
            let value = "";
            if (formData[inputAttribute["@param_popup"]] === "t") {
                value = "t";
            } else if (formData[inputAttribute["@param_popup"]] === "f") {
                value = "f";
            } else if (!isEmpty(formData[inputAttribute["@param_popup"]])) {
                value = formData[inputAttribute["@param_popup"]];
            }
            // formData[inputAttribute["@param_popup"]] === "t" ? "true" : "false";

            fd.append(inputAttribute["@param_popup"], value);
        } else {
            splitedParamPopup = inputAttribute["@param_popup"].split(";");

            splitedParamPopup.forEach((item) => {
                // if (["id_socios_cobranca"].includes(item)) {
                if (item?.startsWith("id_")) {
                    fd.append(item, formData[item] || 0);
                } else {
                    // let inpVal = formData?.[item];
                    if (!formData?.[item]) {
                        hasNull = true;
                    }
                    const value = formData?.[item];
                    
                    fd.append(item, value === undefined ? "" : value);
                }
            });

            // fd.append(inputAttribute["@param_popup"], "false");
        }

        if (validationNull && hasNull) {
            return;
        }
        setLoadingData(true);

        try {

            if(Session.isCLient()) {
                const params = Session.getAll();
                fd.append("usr_parametro1", params.parametro1);
                fd.append("usr_parametro2", params.parametro2);
                fd.append("usr_parametro3", params.parametro3);
            }

            return sendHttpRequest("POST", "/Gestor/geredatagrid.php", fd).then((data) => {
                if (data.linha) {
                    setTableData({
                        key: field,
                        table: tableSecondKey,
                        data: toArray(data.linha),
                    });

                    // if (Array.isArray(data.linha)) {
                    //     setDataGridList(data.linha);
                    // } else if (typeof data.linha === "object" && Object.keys(data.linha).length) {
                    //     setDataGridList([data.linha]);
                    // }

                    // setDataGridList(Array.isArray(data.linha) ? data.linha : [data.linha]);
                }

                // if (data && typeof data === "object") {
                // const datas = data.result.map((it) => {
                // 	for (let key in it) {
                // 		if (!tableInputsKeys.includes(key)) {
                // 			delete it[key]
                // 		}
                // 	}

                // 	return it
                // })

                // setTableData({
                // 	key: field,
                // 	table: "linha", //undefined,
                // 	data: data.linha,
                // })

                setInputType("etiqueta_value");
                setLoadingData(() => false);

                // } else {
                // 	console.error("Falhou o reques")
                // }
            });
        } catch (error) {
            setLoadingData(() => false);
        }
    };

    // useEffect(() => {
    //
    //     if (inputAttribute["@carregar_accao"] && inputAttribute[`@${inputAttribute["@carregar_accao"]}`]) {
    //
    //         // setTimeout(() => {
    //         // getData(true);
    //         // }, 3000);
    //     }
    // }, []);

    if (
        inputAttribute["@param_popup"] &&
        (!inputAttribute?.["@carregar_accao"] || inputAttribute?.["@carregar_accao"] !== "carrega")
    ) {
        paramsDependencies = inputAttribute["@param_popup"].split(";");
    }
    // "renumeracao_socios",
    // "lista_backups",
    // "socios_quotas_pagar",
    // "lista_existencias_lote",
    // "imob_lista_existencias",
    // "imob_lista_existencias",
    // salas_ativas
    // não permite inserir
    //
    //
    useEffect(
        () => {
            // if (field === "linha_imposto") {
            // }

            if (
                // [
                //     "renumeracao_socios",
                //     "lista_backups",
                //     "socios_quotas_pagar",
                //     "lista_existencias_lote",
                //     "imob_lista_existencias",
                //     "imob_lista_existencias",
                //     "linha_imposto",
                //     "linha_imposto2",
                //     "linha_imposto3",
                //     "linha_imposto4",
                //     "linha_imposto5",
                //     "linha_impost6",
                // ].includes(field) &&
                !Utilitaries.isEmpty(paramsDependencies) &&
                !(inputAttribute?.["@carregar_accao"] === "dados")
                //  &&
                // !(inputAttribute?.["@auto_carrega"] === "false")
            ) {
                getData();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        paramsDependencies.map((param) => (formData ? formData[param] : null))
    );

    const setGridTableData = useMemo(() => {
        if (dataGrigList.length > 0) {
            return dataGrigList;
        } else {
            return formData && formData[tableSecondKey] && formData[tableSecondKey][field]
                ? Array.isArray(formData[tableSecondKey][field])
                    ? formData[tableSecondKey][field]
                    : [formData[tableSecondKey][field]]
                : [];
        }
    }, [formData, field, dataGrigList]);

    const columns = [];

    const changeAll = (value, columnKey, column) => {
        if (column["@tipo"] === "boolean") {
            value = value === true ? "t" : "f";
        }

        const list = setGridTableData.map((el) => {
            if (column["@apenas_leitura"]) {
                const inputProps = {
                    inTable: true, // se este input pertense a uma tabela
                    // self: it, // atributos deste input
                    brothers: tableInputsKeys, // key dos inputs irmaõs
                    inputType: column["@param_popup"] ? "etiqueta_value" : null, // verificar se o input não deve ser apenas label
                    items: {
                        __id: columnKey,
                        // dados da linha que esta sendo alterada
                        data: el, // linha
                        key: field, // nome da tabela
                        table: tableSecondKey, //"linha", //undefined //field // key da tabela
                    },
                };

                const canEdit = !checkIfFieldCanBeEdited(column, inputProps);
                return canEdit ? { ...el, [columnKey]: value } : { ...el };
            } else {
                return { ...el, [columnKey]: value };
            }
        });
        setTableData({
            key: field,
            table: tableSecondKey,
            data: list,
        });
    };
    // Percorer os inputs e criar o header da tabela
    tableInputsKeys.forEach((it, idx) => {
        let attributes = tableInputs[it];
        defaultData[it] = FormUtilitaries.inputValueConvert(attributes?.["$"] || "", attributes["@tipo"]);
        if (attributes && Object.keys(attributes).length > 0 && attributes["@visivel"] !== "nao") {
            columns.push({
                title: () => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // minWidth: "80px",
                        }}
                    >
                        <QAToolTip
                            label={getInputLabel(attributes, it)}
                            help={attributes["@ajuda"]}
                            isRequired={attributes?.["@obrigatorio"] === "true()"}
                        />
                        {attributes?.["@seleccionar_todos"] === "sim" &&
                            renderSelectAll({ currentItem: attributes, justSee, columnKey: it, changeAll })}
                    </div>
                ),
                dataIndex: it,
                rowKey: it,
                render: (_data, _allData) => {
                    // if (inputAttribute?.["@param_popup"]) {
                    //     return _data;
                    // }
                    return renderItems({
                        arrayFields: [it], // key deste input
                        objectInputs: tableInputs, // todos os inputs da tabela
                        value: _data, // valor do input
                        inTable: true, // se este input pertense a uma tabela
                        // self: it, // atributos deste input
                        brothers: tableInputsKeys, // key dos inputs irmaõs
                        inputType: inputAttribute["@param_popup"] ? "etiqueta_value" : null, // verificar se o input não deve ser apenas label
                        items: {
                            __id: it,
                            // dados da linha que esta sendo alterada
                            data: _allData, // linha
                            key: field, // nome da tabela
                            table: tableSecondKey, //"linha", //undefined //field // key da tabela
                        },
                    });
                },
            });
        }
    });

    return visible ? (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}

            <Col key={"col-" + index} xs={24} style={{ marginBottom: 12 }}>
                <QADynamicTable
                    columns={columns}
                    data={setGridTableData}
                    actions={actions}
                    scroll={field === "produtos_alterar" ? { x: 3000, y: 300 } : {}}
                    onRemoveItem={(selectedRowKeys) => removeItem(tableSecondKey, field, selectedRowKeys)}
                    addRowToTable={() => addItem(tableSecondKey, field, defaultData)}
                    loadingData={loadingData}
                />
                {/* <Table
                    size="small"
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={fields[field][_tableKey]}
                /> */}
            </Col>
        </>
    ) : null;
    // });
};

QADataGrid.propTypes = {};

export default QADataGrid;
