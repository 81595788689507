import React, { useEffect } from "react";
import { sendHttpRequest } from "../../../components/http.request";
import { FormRender } from "../../components/FormRender";

interface Props {
    list_name: string;
    form_id?: string;
    justSee: boolean;
    params: any;
    onSave: (value?: string) => void;
    setJustSeeInPopup?: (value?: boolean) => void;
    setTitleInModal: (title: string) => void;
}

export function RenderListForm({ list_name, form_id, onSave, setJustSeeInPopup, setTitleInModal, justSee }: Props) {
    const [form, setForm] = React.useState<any>({});
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        const getForm = async () => {
            try {
                const _formData = new FormData();
                _formData.append("accao", "dados");
                _formData.append("hack", "true");
                _formData.append("lista", list_name);
                // _formData.append("utilizador", "qa");

                // form_id = "210218";
                // form_id = "307";

                if (!form_id) {
                    // form_id = "307";
                }

                if (form_id) {
                    const id = typeof form_id === "object" ? "" : form_id;
                    _formData.append("chave1", id);
                }
                setLoading(true);
                const { result } = await sendHttpRequest("POST", "/Gestor/gerelistas.php", _formData);

                const formBody = {
                    form: result.formulario,
                    values: result?.valores || {},
                };

                setForm(formBody);

                const rootElement = formBody.form?.["@elemento_raiz"];
                if (setJustSeeInPopup) {
                    const formReadOnly =
                        formBody.form?.[rootElement]?.["@consulta_formulario"] === "sim" ? true : justSee;
                    setJustSeeInPopup(formReadOnly);
                }
                const title = formBody.form?.[rootElement]?.["@titulo"];
                setTitleInModal(title || "Formulário");
                setLoading(false);
            } catch (error) {
                setForm({});
                setLoading(false);
            }
        };

        getForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_id, list_name]);

    return <FormRender loadForm={loading} form={form} onSave={onSave} justSee={justSee} isInModal={true}></FormRender>;
}
