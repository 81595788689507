import { Card, Col, Descriptions, Table, Tag } from "antd";
import React from "react";
import { getColumnSearchProps } from "../../function_manual/components/search.into.table.columns";
import ChartHelp from "./chartHelp";

export const TableChart = ({ metadata, dataSource }) => {
    console.log({ metadata, dataSource });

    const total = dataSource.reduce((accumulator, current) => Number(accumulator) + Number(current.valor), 0);
    const dataSourceWithPercentage = dataSource.map((item) => {
        const percentage = `${((Number(item.valor) / Number(total)) * 100).toFixed(2)}%`;
        return {
            ...item,
            percentage,
        };
    });

    let columns = [];
    if (metadata.columns) {
        metadata.columns.forEach((column, index) => {
            columns.push({
                key: index,
                title: column.label,
                dataIndex: column.key,
                ...getColumnSearchProps(column.key),
            });
        });
    }

    columns.push({
        key: "percentage",
        title: "Percentagem",
        dataIndex: "percentage",
        ...getColumnSearchProps("percentage"),
    });

    return (
        <Col xs={24} md={12}>
            <Card
                size={"small"}
                title={metadata.title ? metadata.title : " "}
                extra={metadata.help ? <ChartHelp text={metadata.help} /> : null}
                className={"qa-title-dashboard"}
            >
                {typeof total === "number" && (
                    <Descriptions layout="horizontal">
                        <Descriptions.Item label={metadata.labelTotal}>
                            <Tag color="#2db7f5">{total}</Tag>
                        </Descriptions.Item>
                    </Descriptions>
                )}

                <Table
                    rowKey={(record) => record.key}
                    size="small"
                    // loading={loadingData}
                    columns={columns}
                    pagination={{
                        defaultPageSize: 6,
                        showSizeChanger: true,
                        pageSizeOptions: ["6", "18", "30", "40"],
                        showTotal: (total) => `Total de ${total} linhas`,
                        total: dataSourceWithPercentage.lenght,
                    }}
                    dataSource={dataSourceWithPercentage}
                />
            </Card>
        </Col>
    );
};
