import React, { useState } from "react";
import { Button } from "antd";
import { downloadFile } from "../../../../components/util";
import { nullSafe, canCorrectField } from "../../utils/fields";
import { BASE_URL } from "../../../../constants/enviroment";
import { Session, Utilitaries } from "../../../../business";

export default function QALink({ itemData, label, record }) {
    const value = record ? Utilitaries.toString(record[itemData.key]) : itemData.value;

    async function onItemClick() {
        try {
            let url = "";
            if (value && value?.startsWith("/PHP/Gestor")) {
                url = `${BASE_URL}${value}&token=Bearer ${Session.getToken()}`;
            } else {
                url = value;
            }

            // UNICV  Verificação do curriculum vitae de docente url no valores da tabela
            if (value) {
                window.open(url, "_blank");

                return;
            }

            // if (inputProps && inputProps.items) {
            //
            //     const { gerar_relatorio, download, tamanho } = inputProps.items.data;

            //     if (gerar_relatorio) {
            //         window.open(`${BASE_URL}${gerar_relatorio}&token=${`Bearer ${Session.getToken()}`}`, "_blank");
            //     } else if (download && typeof download === "object") {
            //         if (tamanho && tamanho === "Eliminado") {
            //             return;
            //         }

            //         const { url, ...rest } = download;

            //         window.open(
            //             `${baseUrl}/Gestor${url}?accao=download&ficheiro=${
            //                 rest.ficheiro
            //             }&token=Bearer ${Session.getToken()}`,
            //             "_blank"
            //         );

            //     }

            //     return;
            // }
        } catch (error) {}
    }

    if (!value) {
        return null;
    }

    // if (itemData['@etiqueta']) {
    //     return <Button onClick={() => onItemClick()} icon="link">{} </Button>;
    // }

    return <Button onClick={() => onItemClick()} icon="link" />;
}
