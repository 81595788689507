import React, { useEffect, useState } from "react";
import { transformParams, urlAndParamsSplit } from "../../../utils/FormMapper/transformParams";
import { GenericObject, RequestStatus } from "../../../types/common";
import {
    FormAttributesProps,
    FormInputsObjectProps,
    FormInputsProps,
    FormRootElementAttributesProps,
    InputDependenceProps,
} from "../../../types/formTypes";
import { api_fetch } from "../../../utils/HttpRequest/request";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";
import { Utilitaries } from "../../../../business";
import {
    checkCanExecuteActionData,
    dataGridDependenciesValues,
    dataGridParamsDependencies,
} from "../../../utils/functions";

interface QueryParamsProps {
    accao?: string;
    query?: string;
    [key: string]: any;
}

interface FetchProps {
    endPoint: string;
    componentsProperties: FormInputsProps;
    state: FormInputsObjectProps;
    inputDependence?: InputDependenceProps;
    popup_params?: GenericObject;
    triggerDataGridData?: number;
    rootElementAttribute: FormRootElementAttributesProps;
}

export const useData = ({
    endPoint,
    componentsProperties,
    state,
    popup_params,
    triggerDataGridData,
    rootElementAttribute,
}: FetchProps) => {
    const [data, setData] = useState<any[]>([]);
    const [status, setStatus] = useState<RequestStatus>("IDLE");

    const { params, queryName } = urlAndParamsSplit(componentsProperties.data_query, null);
    const _transformParams = transformParams({ params, formData: state });

    let newParams: QueryParamsProps = {
        ..._transformParams,
    };
    if (queryName) {
        newParams = {
            ...popup_params,
            ...newParams,
            query: queryName,
        };
    }

    //dependencias da datagrid

    // dataGridDependenciesValues;

    useEffect(() => {
        let cancelRequest = false;

        if (!checkCanExecuteActionData(componentsProperties, state, triggerDataGridData || 0, rootElementAttribute)) {
            return;
        }
        if (!endPoint) return;

        // if (componentsProperties.key === "inquilino") {
        //     debugger;
        // }

        if (
            componentsProperties.type === COMPONENT_TYPE.DATAGRID ||
            componentsProperties.type === COMPONENT_TYPE.DYNAMIC_DATAGRID
        ) {
            // TO CHECK CONDITION TO NOT LOAD DATAGRID
            // if (
            //     componentsProperties.persist !== "nao" &&
            //     status === "IDLE" &&
            //     state[`id_${componentsProperties.form_key}`]
            // ) {
            //     return;
            // }
        }

        // if (
        //     componentsProperties.type === COMPONENT_TYPE.DATAGRID ||
        //     componentsProperties.type === COMPONENT_TYPE.DYNAMIC_DATAGRID
        // ) {
        //     // TO CHECK CONDITION TO NOT LOAD DATAGRID
        //     // if (
        //     //     componentsProperties.persist !== "nao" &&
        //     //     status === "IDLE" &&
        //     //     state[`id_${componentsProperties.form_key}`]
        //     // ) {
        //     //     return;
        //     // }
        //     // debugger;
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        newParams = {
            [`id_${componentsProperties.form_key}`]: state[`id_${componentsProperties.form_key}`]?.value || 0,
            ...newParams,
            ...dataGridParamsDependencies({ componentProperties: componentsProperties, state }),
        };

        const formData = new FormData();
        Object.entries(newParams).forEach(([key, value]) => {
            if (!Utilitaries.isEmpty(value)) {
                formData.append(key, value);
            }
        });

        formData.append(
            "_valor_campo_",
            Utilitaries.isEmpty(componentsProperties.value) ? "" : componentsProperties.value
        );

        if (!queryName && ["execQuery.php", "geredatagrid.php"].includes(endPoint)) {
            return;
        }

        const fetchData = async () => {
            setStatus("FETCHING");

            try {
                const { response } = await api_fetch({
                    endPoint: `Gestor/${endPoint}`,
                    method: "POST",
                    body: formData,
                });

                if (cancelRequest) return;

                if (
                    componentsProperties.type === COMPONENT_TYPE.DATAGRID ||
                    componentsProperties.type === COMPONENT_TYPE.DYNAMIC_DATAGRID
                ) {
                    setData(Utilitaries.toArray(response?.linha));
                } else {
                    setData(response.result);
                }

                setStatus("FETCHED");
            } catch (error) {
                if (cancelRequest) return;
                setStatus("FETCH_ERROR");
            }
        };

        fetchData();

        return function cleanup() {
            cancelRequest = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerDataGridData]);
    // ...dataGridDependenciesValues({ componentProperties: componentsProperties, state })
    return { status, data };
};
