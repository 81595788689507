import React from "react";
import { Button, Form, Icon } from "antd";
import moment from "moment";
import {
    QASelect,
    QARadio,
    QATable,
    QAInput,
    QACheckBox,
    QATextArea,
    QAEtiqueta,
    QADataGrid,
    QADate,
    QADynamicList,
    QAImage,
    QAUpload,
    QAReport,
    QAToolTip,
    QALink,
    QATime,
    QaMultiChachBox,
    QaButton,
    QaAutoComplete,
    QaEmptyComponent,
} from "../qa_components";
import QARenderGroup from "./renderGroup";
import { getDynamicType } from "../utils/functions";
import { isEmpty } from "../../../modules/process_execution/utils/fields";
import { FormUtilitaries, Utilitaries } from "../../../business/index";

const checkIfCanRenderGroup = ({ inputAttribute, lastGroupRendered }) => {
    if (!inputAttribute["@grupo"]) return false;
    else if (inputAttribute["@grupo"] === lastGroupRendered) {
        return false;
    } else return true;
};

function RenderComponent({
    fieldsValue, // value for inputs
    params,
    handleInputChange,
    checkIfFieldCanBeVisible, // validate @relevante
    checkIfFieldCanBeEdited,
    removeItemFromTable,
    addItemToTable,
    setPopUp,
    checkInputRestrinctions, // validate @relevante
    checkIsRequired,
}) {
    /**
     * Renderizar os componentes na tela
     * @param {Array} arrayFields - list of inputs [key of inputs]
     * @param {Object} objectInputs - object with input props
     * @param {String} value - value of input when arrayFields have one key
     */
    const renderItems = ({
        arrayFields, // lista de nome de campos a renderizar
        objectInputs, // propriedades dos campos (do db)
        value, // valo do campo // se for um campo usado nas tabelas
        ...anotherProps // propriedades dinamicas (js)
    }) => {
        if (arrayFields) {
            let lastGroupRendered = null;
            const inputs = arrayFields.map((field, index) => {
                let inputAttribute = { ...objectInputs[field] };
                const inputProps = { ...anotherProps };

                if (
                    !inputAttribute ||
                    typeof inputAttribute !== "object" ||
                    Object.keys(inputAttribute).length === 0 ||
                    inputAttribute["@visivel"] === "nao"
                ) {
                    // colocar no state valores de campos invisiveis
                    if (
                        inputAttribute["@visivel"] === "nao" &&
                        inputAttribute["$"] &&
                        !inputAttribute?.["@calcular"] &&
                        !Object.keys(inputAttribute).find((key) => key.startsWith("@accao_"))
                    ) {
                        if (fieldsValue && !fieldsValue[field]) {
                            const _paramValues = { ...params };
                            let _defaultValue = inputAttribute["$"];
                            let checkIfParamsExist = false;

                            const regex = /{\$[\w.]+\}/g;
                            if (regex.test(_defaultValue)) {
                                const _fieldParams = _defaultValue.match(/(?!{\$param.)\w+(?=})/g)[0];
                                _defaultValue = _paramValues[_fieldParams];
                                checkIfParamsExist = true;
                            }

                            if (
                                _defaultValue !== fieldsValue[field] &&
                                ((checkIfParamsExist && params) || !checkIfParamsExist)
                            ) {
                                if (_defaultValue === "{now()}") {
                                    _defaultValue = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
                                }

                                handleInputChange(_defaultValue, field);
                            }
                        }
                        return null;
                    } else if (inputAttribute["@elemento_grafico"] === "etiqueta") {
                        // user just for get data and update state
                        inputAttribute["utility"] = "update_state";
                    } else if (inputAttribute["@visivel"] === "nao") {
                        if (inputAttribute["@calcular"]) {
                            return (
                                <QaEmptyComponent
                                    key={"_emptyComponent_" + index + "-" + field}
                                    inputAttribute={inputAttribute}
                                    field={field}
                                    objectInputs={objectInputs}
                                    {...inputProps}
                                />
                            );
                        } else if (Object.keys(inputAttribute).findIndex((item) => item.startsWith("@accao_")) >= 0) {
                            if ((inputAttribute._nodes || inputProps._nodes) && !inputProps.inTable) {
                                let nodes = inputAttribute._nodes || inputProps._nodes;

                                inputProps._nodes = nodes;

                                // const nodeLength = nodes.length - 1;

                                // let auxData = {};
                                // for (let i = 0; i <= nodeLength; i++) {
                                //     const element = nodes[i];

                                //     // if it's the first interaction
                                //     if (i === 0) {
                                //         auxData = fieldsValue[element];
                                //     } else {
                                //         auxData = auxData ? auxData[element] : null;
                                //     }
                                // }
                                // inputValue = auxData ? auxData[field] : "";
                            }

                            return (
                                <QaEmptyComponent
                                    key={"_emptyComponent_" + index + "-" + field}
                                    inputAttribute={inputAttribute}
                                    field={field}
                                    objectInputs={objectInputs}
                                    {...inputProps}
                                />
                            );
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                }

                inputAttribute["dinamic_tipo"] = getDynamicType(inputAttribute, inputProps);

                const realType = inputAttribute["dinamic_tipo"]
                    ? inputAttribute["dinamic_tipo"]
                    : inputAttribute["@tipo"];

                // For all fields that have _nodes property => for exemple 'qualia_parametrizacao.qml.xml' form
                let inputValue;

                if ((inputAttribute._nodes || inputProps._nodes) && !inputProps.inTable) {
                    let nodes = inputAttribute._nodes || inputProps._nodes;

                    inputProps._nodes = nodes;

                    const nodeLength = nodes.length - 1;

                    let auxData = {};
                    for (let i = 0; i <= nodeLength; i++) {
                        const element = nodes[i];

                        // if it's the first interaction
                        if (i === 0) {
                            auxData = fieldsValue[element];
                        } else {
                            auxData = auxData ? auxData[element] : null;
                        }
                    }
                    inputValue = auxData ? auxData[field] : "";
                } else if (inputAttribute["$"]) {
                    const info = inputAttribute["$"];
                    // nao comessa com $ entao valor padrao
                    if (!info.startsWith("$")) {
                        if (info === "{now()}") {
                            if (
                                (!inputProps.inTable &&
                                    (!fieldsValue || fieldsValue[field] === "{now()}" || !fieldsValue[field])) ||
                                (inputProps.inTable && !value)
                            ) {
                                inputValue = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
                            } else {
                                inputValue = value
                                    ? value
                                    : fieldsValue && fieldsValue[field]
                                    ? fieldsValue[field]
                                    : "";
                            }
                        } else if (info.startsWith("{$param.")) {
                            const paramKey = info.replace("{$param.", "").replace("}", "");
                            inputValue = params?.[paramKey] || fieldsValue?.[field] || "";

                            if (!fieldsValue?.[field]) {
                                // handleInputChange(inputValue, field);
                            }
                        } else {
                            inputValue = value
                                ? value
                                : fieldsValue && fieldsValue[field]
                                ? fieldsValue[field]
                                : info.toQAFormValue();
                        }
                    } else {
                        // const __key = inputAttribute["$"].split("$dados.")[1];
                        // inputValue = fieldsValue && fieldsValue[__key] ? fieldsValue[__key] : "";
                        inputValue = value ? value : fieldsValue && fieldsValue[field] ? fieldsValue[field] : "";
                    }
                } else {
                    // inputValue = !isEmpty(value) ? value : fieldsValue && fieldsValue[field] ? fieldsValue[field] : "";
                    if (isEmpty(value)) {
                        if (inputProps.inTable) {
                            inputValue = value;
                        } else {
                            inputValue = Utilitaries.toString(fieldsValue?.[field]); // fieldsValue && fieldsValue[field] ? fieldsValue[field] : "";
                        }
                    } else {
                        inputValue = value;
                    }
                }

                const renderGroup = checkIfCanRenderGroup({ inputAttribute, lastGroupRendered });
                if (renderGroup) {
                    lastGroupRendered = inputAttribute["@grupo"];
                }

                const hasError = (values) =>
                    checkInputRestrinctions({
                        input: inputAttribute,
                        options: inputProps,
                        values,
                        field,
                        showInModal: false,
                    });

                const isRequired = checkIsRequired({
                    input: inputAttribute,
                    options: inputProps,
                    field,
                });

                const isVisible = checkIfFieldCanBeVisible({
                    input: inputAttribute,
                    options: inputProps,
                    objectInputs,
                    arrayFields,
                    field,
                });

                const itemKey = (type) => {
                    let key = `${type}_${index}_${field}`;
                    if (inputProps.inTable) {
                        key = `${key}_${inputProps.items.data.__key__}`;
                    }

                    return key;
                };

                const colSize = FormUtilitaries.getInputColSize(inputAttribute, realType);

                switch (realType) {
                    case "relatorio":
                        return (
                            <QAReport
                                key={"_report_" + index + "-" + field}
                                {...inputProps}
                                inputAttribute={inputAttribute}
                                visible={isVisible}
                            />
                        );

                    case "lista_dinamica":
                        return (
                            <QADynamicList
                                key={itemKey("_dynamicList_")}
                                inputAttribute={inputAttribute}
                                self={field}
                                visible={isVisible}
                                value={inputValue}
                                handleInputChange={handleInputChange}
                                isRequired={isRequired}
                                {...inputProps}
                                renderGroup={renderGroup}
                                colSize={colSize}
                                disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                            />
                        );

                    case "select":
                        return (
                            <QASelect
                                key={"_select_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                self={field}
                                // index={index}
                                {...inputProps}
                                objectInputs={objectInputs}
                                visible={isVisible}
                                disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                                hasError={hasError}
                                value={inputValue}
                                renderGroup={renderGroup}
                                isRequired={isRequired}
                                colSize={colSize}
                            />
                        );

                    case "radio":
                        return (
                            <QARadio
                                key={"_radio_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                // index={index}
                                {...inputProps}
                                field={field}
                                value={inputValue}
                                visible={isVisible}
                                hasError={hasError}
                                renderGroup={renderGroup}
                                isRequired={isRequired}
                                disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                                colSize={colSize}
                            />
                        );

                    case "conjunto-in-form":
                        return (
                            <QARenderGroup
                                key={"_table_" + field + "_" + index}
                                objectInputs={objectInputs}
                                fields={fieldsValue}
                                field={field}
                                inputAttribute={inputAttribute}
                                index={index}
                                visible={isVisible}
                                renderItems={renderItems}
                                removeItem={removeItemFromTable}
                                addItem={addItemToTable}
                            />
                        );

                    case "conjunto":
                        return (
                            <QATable
                                key={"_table_" + field + "_" + index}
                                objectInputs={objectInputs}
                                fields={fieldsValue}
                                field={field}
                                inputAttribute={inputAttribute}
                                index={index}
                                visible={isVisible}
                                renderItems={renderItems}
                                removeItem={removeItemFromTable}
                                addItem={addItemToTable}
                                renderGroup={renderGroup}
                                checkIfFieldCanBeVisible={checkIfFieldCanBeVisible}
                            />
                        );

                    case "pop_up":
                        return (
                            <>
                                <Button
                                    key={"_popup_" + field + "_" + index}
                                    onClick={() => setPopUp(inputAttribute, inputProps, field)}
                                    icon="edit"
                                    disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                                />
                                {!Utilitaries.isEmpty(inputValue) && (
                                    <Icon
                                        style={{
                                            color: "green",
                                            marginLeft: 10,
                                        }}
                                        type="check"
                                    />
                                )}
                            </>
                        );

                    case "botao":
                        return (
                            <QaButton
                                key={"_botao_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                                visible={isVisible}
                                objectInputs={objectInputs}
                                field={field}
                                renderGroup={renderGroup}
                                {...inputProps}
                                colSize={colSize}
                            />
                        );

                    case "link":
                        return (
                            <QALink
                                key={"_link_" + index + "-" + field}
                                inputAttribute={inputAttribute}
                                field={field}
                                visible={isVisible}
                                {...inputProps}
                                renderGroup={renderGroup}
                                colSize={colSize}
                            />
                        );

                    case "pop_up_label":
                        return (
                            <Form.Item
                                key={"_popup_label" + field + "_" + index}
                                label={
                                    <QAToolTip label={inputAttribute["@etiqueta"]} help={inputAttribute["@ajuda"]} />
                                }
                            >
                                <Button
                                    onClick={() => setPopUp(inputAttribute, inputProps, field)}
                                    icon="edit"
                                    disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                                />
                            </Form.Item>
                        );

                    case "boolean":
                        return (
                            <QACheckBox
                                key={"_checkbox_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                value={inputProps && inputProps.inTable ? value : inputValue}
                                field={field}
                                hasError={hasError}
                                visible={isVisible}
                                {...inputProps}
                                renderGroup={renderGroup}
                                isRequired={isRequired}
                                colSize={colSize}
                                disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                            />
                        );

                    case "datagrid":
                        return (
                            <QADataGrid
                                key={"_table_" + field + "_" + index}
                                objectInputs={objectInputs}
                                fields={fieldsValue}
                                field={field}
                                inputAttribute={inputAttribute}
                                index={index}
                                visible={isVisible}
                                renderItems={renderItems}
                                removeItem={removeItemFromTable}
                                addItem={addItemToTable}
                                renderGroup={renderGroup}
                                checkIfFieldCanBeEdited={checkIfFieldCanBeEdited}
                            />
                        );

                    case "icon":
                        return (
                            <QAImage
                                key={"data_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                field={field}
                                renderGroup={renderGroup}
                                {...inputProps}
                            />
                        );

                    case "ficheiro":
                    case "upload":
                        return (
                            <QAUpload
                                visible={isVisible}
                                value={inputValue}
                                key={"data_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                field={field}
                                renderGroup={renderGroup}
                                {...inputProps}
                            />
                        );

                    case "upload_imagem":
                        return (
                            <QAUpload
                                visible={isVisible}
                                key={"data_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                preview={true}
                                field={field}
                                renderGroup={renderGroup}
                                {...inputProps}
                            />
                        );

                    case "label":
                        return (
                            <QAEtiqueta
                                key={"_select_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                index={index}
                                objectInputs={objectInputs}
                                field={field}
                                hasError={hasError}
                                value={inputValue}
                                visible={isVisible}
                                renderGroup={renderGroup}
                                {...inputProps}
                                colSize={colSize}
                            />
                        );

                    case "textArea":
                        return (
                            <QATextArea
                                key={"textArea_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                value={inputValue}
                                visible={isVisible}
                                field={field}
                                renderGroup={renderGroup}
                                hasError={hasError}
                                isRequired={isRequired}
                                {...inputProps}
                                colSize={colSize}
                            />
                        );

                    case "data":
                        return (
                            <QADate
                                key={"data_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                value={inputValue}
                                visible={isVisible}
                                field={field}
                                renderGroup={renderGroup}
                                hasError={hasError}
                                isRequired={isRequired}
                                {...inputProps}
                                colSize={colSize}
                            />
                        );

                    case "time":
                        return (
                            <QATime
                                key={"time_" + field + "_" + index}
                                inputAttribute={inputAttribute}
                                value={inputValue}
                                visible={isVisible}
                                field={field}
                                {...inputProps}
                                hasError={hasError}
                                isRequired={isRequired}
                                renderGroup={renderGroup}
                                colSize={colSize}
                                disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                            />
                        );

                    case "checkbox_group":
                        return (
                            <QaMultiChachBox
                                key={
                                    "checkbox_group_" +
                                    field +
                                    "_" +
                                    index +
                                    (inputProps && inputProps.inTable ? "-tabel" : "")
                                }
                                inputAttribute={inputAttribute}
                                value={inputValue}
                                visible={isVisible}
                                disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                                field={field}
                                hasError={hasError}
                                renderGroup={renderGroup}
                                isRequired={isRequired}
                                {...inputProps}
                                colSize={colSize}
                            />
                        );

                    case "sugestao":
                        return (
                            <QaAutoComplete
                                key={
                                    "inputText_" +
                                    field +
                                    "_" +
                                    index +
                                    (inputProps && inputProps.inTable ? "-tabel" : "")
                                }
                                inputAttribute={inputAttribute}
                                value={inputValue}
                                visible={isVisible}
                                disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                                isRequired={isRequired}
                                hasError={hasError}
                                objectInputs={objectInputs}
                                field={field}
                                renderGroup={renderGroup}
                                {...inputProps}
                                colSize={colSize}
                            />
                        );

                    default:
                        return (
                            <QAInput
                                key={
                                    "inputText_" +
                                    field +
                                    "_" +
                                    index +
                                    (inputProps && inputProps.inTable ? "-tabel" : "")
                                }
                                inputAttribute={inputAttribute}
                                value={inputValue}
                                visible={isVisible}
                                disabled={checkIfFieldCanBeEdited(inputAttribute, inputProps)}
                                hasError={hasError}
                                objectInputs={objectInputs}
                                field={field}
                                isRequired={isRequired}
                                renderGroup={renderGroup}
                                {...inputProps}
                                colSize={colSize}
                            />
                        );
                }
            });
            return inputs;
        }
    };

    return renderItems;
}

export default RenderComponent;
