import React from "react";
import { Row, Col, Card, Statistic, Icon } from "antd";

const Widgets = ({ metadata, dataSource }) => {
    let elements = [];

    if (dataSource !== null) {
        elements = metadata.widgets.map((item) => {
            return {
                ...item,
                valor: dataSource[item.value] && dataSource[item.value].valor,
            };
        });
    } else elements = metadata.widgets.map((item) => ({ ...item }));

    return (
        <Col>
            <Row justify="center" gutter={[16, 16]}>
                {elements.map((item, index) => (
                    <Col key={index} xs={24} sm={12} md={8} xl={4}>
                        <Card
                            style={{ borderColor: item.rgba }}
                            size="small"
                            title={item.label}
                            headStyle={{ backgroundColor: item.rgba, color: item.color }}
                        >
                            <Statistic
                                value={item.valor}
                                formatter={(value) => value}
                                valueStyle={{
                                    color: item.color || "rgba(0, 0, 0, 0.85)",
                                }}
                                prefix={<Icon type={item.icon || "book"} />}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </Col>
    );
};

export default Widgets;
