import React from "react";
import { Col, Card } from "antd";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import EmptyData from "./emptyData";
import { TotalValues } from "./totalValues";
import ChartHelp from "./chartHelp";

const MultipleLineChart = ({ metadata, dataSource }) => {
    let fields = [];
    let data = [];

    if (dataSource && dataSource.length !== 0) {
        data = dataSource.map((item) => {
            let itemChart = {};
            let totalItens = 0;
            if (item.data) {
                const dataFormat = item.data.map((element) => ({
                    ...element,
                    valor: Number(element.valor),
                }));
                totalItens = dataFormat.reduce((accumulator, currentValue) => accumulator + currentValue.valor, 0);

                itemChart = dataFormat.reduce((accumulator, currentValue) => {
                    if (!fields.includes(currentValue.label)) fields.push(currentValue.label);
                    return {
                        ...accumulator,
                        [currentValue.label]: currentValue.valor,
                    };
                }, {});
            }

            return {
                name: item.label,
                ...itemChart,
                totalItens,
            };
        });
    }

    const ds = new DataSet();
    const dv = ds.createView().source(data);
    dv.transform({
        type: "fold",
        fields: fields,
        key: "key",
        value: "value",
        retains: ["name"],
    });
    const cols = {
        key: {
            range: [0, 1],
            alias: metadata.horizontalLabel || "Elementos",
        },
        value: {
            alias: metadata.verticalLabel || "Nº",
        },
    };
    return (
        <Col xs={24} md={metadata.col || 12}>
            <Card
                size="small"
                title={metadata.title ? metadata.title : " "}
                extra={metadata.help ? <ChartHelp text={metadata.help} /> : null}
                className={"qa-title-dashboard"}
            >
                {fields.length === 0 || !dataSource || dataSource.length === 0 ? (
                    <EmptyData />
                ) : (
                    <Chart padding={"auto"} height={344} data={dv} scale={cols} forceFit>
                        <TotalValues label={metadata.labelTotal} />
                        <Legend />
                        <Axis name="key" title />
                        <Axis name="value" title />
                        <Tooltip
                            useHtml
                            g2-tooltip={{
                                boxShadow: "none",
                                color: "#fff",
                                backgroundColor: "#222",
                            }}
                            crosshairs={{
                                type: "y",
                            }}
                            style={{
                                color: "red",
                            }}
                        />
                        <Geom type="line" position="key*value" size={2} color={"name"} shape={"smooth"} />
                        <Geom
                            type="point"
                            position="key*value"
                            size={4}
                            shape={"circle"}
                            color={"name"}
                            style={{
                                stroke: "#fff",
                                lineWidth: 1,
                            }}
                        />
                    </Chart>
                )}{" "}
            </Card>
        </Col>
    );
};

export default MultipleLineChart;
