import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Select, Popconfirm, Row, Col } from "antd";

const { Option } = Select;

const DefinedEvent = (props) => {
    const [permissions, setPermissions] = useState([
        {
            id: 1,
            grupo: 7,
            nome_grupo: "",
            username: "",
            nome: "",
            consultar: 0,
            // inserir: false,
            alterar: 0,
            eliminar: 0,
        },
    ]);
    const [contributores, setContributores] = useState({ 1: [] });
    const [selectedPermissionTableID, setSelectedPermissionTableID] = useState(1);

    useEffect(() => {
        setContributores({
            ...contributores,
            [selectedPermissionTableID]: props.contributorsByFuncionalGroup,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contributorsByFuncionalGroup]);

    const handleChangeFunctionalGroups = (value, option, id) => {
        setSelectedPermissionTableID(id);
        const _id = option.props.data_id;

        props.loadingContributorsByFuncionalGroup(_id);
    };

    const deletePermission = (line) => {
        let newPermissions = [];

        permissions.forEach((item) => {
            if (item.id !== line.id) {
                newPermissions.push(item);
            }
        });

        setPermissions(newPermissions);
    };

    const listOfFuncionalGroups = props.funcionalGroups.map((item) =>
        typeof item.id_ficha_funcao !== "object" && typeof item.descricao_funcao !== "object" ? (
            <Option key={item.codigo + "ficha"} data_id={item.id_ficha_funcao}>
                {item.codigo}
            </Option>
        ) : null
    );

    const permissionsTypes = [
        { name: "Sim", code: 1 },
        { name: "Não", code: 0 },
    ];

    const onChange = (value, key, field) => {
        let newPontual;
        newPontual = [];

        permissions.forEach((item) => {
            if (item.id === key) {
                item[field] = value;
            }
            newPontual.push(item);
        });

        setPermissions(newPontual);
    };

    const columns = [
        {
            title: "Grupo Funcional",
            key: "grupo_funcional",
            width: "20%",
            render: (text, record) => (
                <Select
                    mode="default"
                    style={{ width: "100%" }}
                    placeholder=""
                    showSearch
                    defaultValue={record.nome_grupo}
                    onChange={(value, options) => {
                        handleChangeFunctionalGroups(value, options, record.id);
                        onChange(options.props.data_id, record.id, "grupo");
                    }}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {listOfFuncionalGroups}
                </Select>
            ),
        },
        {
            title: "Funcionário/a",
            key: "colaborador",
            width: "20%",
            render: (text, record) => (
                <Select
                    defaultValue={typeof record.username === "object" ? "" : record.username}
                    style={{ width: "100%" }}
                    onChange={(value) => onChange(value, record.id, "username")}
                >
                    {contributores[record.id].map((item) => (
                        <Option key={item.username} value={item.username}>
                            {item.nome}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Consultar",
            key: "consultar",
            width: "10%",
            render: (text, record) => (
                <Select
                    defaultValue={record.consultar}
                    style={{ width: 120 }}
                    onChange={(value) => onChange(value, record.id, "consultar")}
                >
                    {permissionsTypes.map((item, index) => (
                        <Option key={item.name + index} value={item.code}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        // {
        //     title: "Inserir",
        //     key: "inserir",
        //     width: "10%",
        //     render: (text, record) => (
        //         <Select
        //             defaultValue={record.inserir}
        //             style={{ width: 120 }}
        //             onChange={value => onChange(value, record.id, "inserir")}
        //         >
        //             {permissionsTypes.map((item, index) => (
        //                 <Option key={item.name + index} value={item.code}>
        //                     {item.name}
        //                 </Option>
        //             ))}
        //         </Select>
        //     )
        // },
        {
            title: "Alterar",
            key: "alterar",
            width: "10%",
            render: (text, record) => (
                <Select
                    defaultValue={record.alterar}
                    style={{ width: 120 }}
                    onChange={(value) => onChange(value, record.id, "alterar")}
                >
                    {permissionsTypes.map((item) => (
                        <Option key={item.code + "alterar"} value={item.code}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Eliminar",
            key: "eliminar",
            width: "10%",
            render: (text, record) => (
                <Select
                    defaultValue={record.eliminar}
                    style={{ width: 120 }}
                    onChange={(value) => onChange(value, record.id, "eliminar")}
                >
                    {permissionsTypes.map((item) => (
                        <Option key={item.code + "eliminar"} value={item.code}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Ação",
            key: "acao",
            width: "10%",
            render: (_, item) => (
                <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => deletePermission(item)}>
                    <a
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        href="#!"
                        className="qa-text-del"
                    >
                        Eliminar
                    </a>
                </Popconfirm>
            ),
        },
    ];

    // const handleSave = () => {

    //
    // };

    return (
        <>
            <Modal
                title="Permissões de Agenda"
                visible={props.visible}
                onCancel={props.onCancel}
                footer={[
                    <Button key="guardar" type="primary" onClick={() => props.onOk(permissions)}>
                        Ok
                    </Button>,
                ]}
                width="90%"
            >
                <Row gutter={16} align="middle" type="flex">
                    <Col xs={12}>
                        {/* <Button
                            xs={24}
                            sm={12}
                            onClick={handleSave}
                            icon="plus"
                            size="small"
                            type="primary"
                            style={{ marginBottom: 16, float: "left" }}
                        >
                            Guardar
                        </Button>

                        <Button
                            xs={24}
                            sm={12}
                            onClick={() => {}}
                            icon="plus"
                            size="small"
                            type="primary"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "left"
                            }}
                        >
                            Lista de Correões
                        </Button> */}
                    </Col>
                    <Col xs={12}>
                        <Button
                            xs={24}
                            sm={12}
                            onClick={() => {
                                const index = parseInt(Math.ceil(Math.random() * 500)) + "_" + permissions.length;
                                setPermissions([
                                    ...permissions,
                                    {
                                        id: index,
                                        grupo: "",
                                        nome_grupo: "",
                                        username: "",
                                        nome: "",
                                        consultar: 0,
                                        alterar: 0,
                                        eliminar: 0,
                                    },
                                ]);
                                setContributores({
                                    ...contributores,
                                    [index]: [],
                                });
                            }}
                            icon="plus"
                            size="small"
                            type="primary"
                            style={{ marginBottom: 16, float: "right" }}
                        >
                            Adicionar
                        </Button>
                    </Col>
                </Row>

                <Table dataSource={permissions} columns={columns} rowKey="id" />
            </Modal>
        </>
    );
};

export default DefinedEvent;
