import { actionType as indexActionTypes } from "../constants/index";
import { actionType as executionActionTypes } from "../constants/execution";
import { toArray } from "../../../components/util";

const initialState = {
    domain: {
        byId: {},
    },
    app: {
        modals: {},
        isFetched: {},
        isFormReady: false,
        temporary: {},
        temporaryRel: {},
        selectedViewer: "details",
    },
};

export const processExecutionReducer = (state = initialState, action) => {
    switch (action.type) {
        case indexActionTypes.initProcess: {
            // ;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [action.payload["@id"]]: {
                            ...action.payload,
                            key: action.payload["@id"],
                            id: action.payload["@id"],
                        },
                    },
                },
            };
        }

        case executionActionTypes.setSubProcessToBase: {
            const { baseProcessId, subProcessId, taskId } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [baseProcessId]: {
                            ...state.domain.byId?.[baseProcessId],
                            subProcessId,
                            taskToSubProcess: taskId,
                        },
                    },
                },
            };
        }

        case indexActionTypes.setTaskDetails: {
            const { processId, taskId, ...rest } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [processId]: {
                            ...state.domain.byId[processId],
                            tasks: {
                                ...state.domain.byId[processId].tasks,
                                [taskId]: {
                                    ...state.domain.byId[processId].tasks[taskId],
                                    ...rest,
                                },
                            },
                        },
                    },
                },
            };
        }

        case indexActionTypes.initModals: {
            return {
                ...state,
                app: {
                    ...state.app,
                    modals: action.payload,
                },
            };
        }

        case indexActionTypes.toggleModal: {
            const { key, isVisible } = action.payload;
            return {
                ...state,
                app: {
                    ...state.app,
                    modals: {
                        ...state.app.modals,
                        [key]: {
                            ...state.app.modals[key],
                            isVisible: isVisible,
                        },
                    },
                },
            };
        }

        case indexActionTypes.getForm: {
            const { processId, taskId, formMeta } = action.payload;

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [processId]: {
                            ...state.domain.byId[processId],
                            tasks: {
                                ...state.domain.byId[processId].tasks,
                                [taskId]: {
                                    ...state.domain.byId[processId].tasks[taskId],
                                    formMeta: [...state.domain.byId[processId].tasks[taskId].formMeta, ...formMeta],
                                },
                            },
                        },
                    },
                },
            };
        }

        case indexActionTypes.setSubProcess: {
            const { subProcessId, key, ...taskProps } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [subProcessId]: {
                            ...state.domain.byId[subProcessId],
                            tasks: {
                                ...state.domain.byId[subProcessId].tasks,
                                [subProcessId]: { processId: subProcessId, ...taskProps, key },
                            },
                        },
                    },
                },
            };
        }

        case indexActionTypes.setPreviousTasks: {
            const { processId, tasks: tasksUpdated } = action.payload;
            return {
                ...state,
                domain: {
                    ...state?.domain,
                    byId: {
                        ...state?.domain?.byId,
                        [processId]: {
                            ...state?.domain?.byId?.[processId],
                            tasks: { ...state?.domain?.byId?.[processId].tasks, ...tasksUpdated },
                        },
                    },
                },
            };
        }

        case indexActionTypes.getFormData: {
            const { processId, taskId, formFields, formMeta, form } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [processId]: {
                            ...state.domain.byId[processId],
                            tasks: {
                                ...state.domain.byId[processId].tasks,
                                [taskId]: {
                                    ...state.domain.byId[processId].tasks[taskId],
                                    formMeta: [...state.domain.byId[processId].tasks[taskId].formMeta, ...formMeta],
                                    page: {
                                        ...state.domain.byId[processId].tasks[taskId].page,
                                        ...formFields,
                                    },
                                },
                            },
                        },
                    },
                },
                app: {
                    ...state.app,
                    isFetched: {
                        ...state.app.isFetched,
                        [form]: true,
                    },
                },
            };
        }

        case executionActionTypes.getTaskDetails: {
            const { processId, taskId, ...details } = action.payload;

            return {
                ...state,
                domain: {
                    ...state?.domain,
                    byId: {
                        ...state?.domain.byId,
                        [processId]: {
                            ...state?.domain?.byId?.[processId],
                            tasks: {
                                ...state?.domain?.byId?.[processId]?.tasks,
                                [taskId]: {
                                    ...state?.domain?.byId?.[processId]?.tasks?.[taskId],
                                    details: {
                                        ...state?.domain?.byId?.[processId]?.tasks?.[taskId]?.details,
                                        ...details,
                                    },
                                },
                            },
                        },
                    },
                },
                app: {
                    ...state.app,
                },
            };
        }

        case executionActionTypes.addDocument: {
            const { processId, taskId, newDoc } = action.payload;
            const currentKeys = Object.keys(state.domain.byId[processId].tasks[taskId].details.uploadedDocuments);
            let docKey = currentKeys.length > 0 ? Number(currentKeys[currentKeys.length - 1]) + 1 : 1;

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [processId]: {
                            ...state.domain.byId[processId],
                            tasks: {
                                ...state.domain.byId[processId].tasks,
                                [taskId]: {
                                    ...state.domain.byId[processId].tasks[taskId],
                                    details: {
                                        ...state.domain.byId[processId].tasks[taskId].details,
                                        uploadedDocuments: {
                                            ...state.domain.byId[processId].tasks[taskId].details.uploadedDocuments,
                                            [docKey]: {
                                                key: docKey,
                                                ...newDoc,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                app: {
                    ...state.app,
                },
            };
        }

        case executionActionTypes.addUploadedDoc: {
            const { processId, taskId, docKey, file, nome_ficheiro } = action.payload;
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [processId]: {
                            ...state.domain.byId[processId],
                            tasks: {
                                ...state.domain.byId[processId].tasks,
                                [taskId]: {
                                    ...state.domain.byId[processId].tasks[taskId],
                                    details: {
                                        ...state.domain.byId[processId].tasks[taskId].details,
                                        uploadedDocuments: {
                                            ...state.domain.byId[processId].tasks[taskId].details.uploadedDocuments,
                                            [docKey]: {
                                                ...state.domain.byId[processId].tasks[taskId].details.uploadedDocuments[
                                                    docKey
                                                ],
                                                file,
                                                nome_ficheiro,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                app: {
                    ...state.app,
                },
            };
        }

        case executionActionTypes.changeDocDescription: {
            const { processId, taskId, docKey, value } = action.payload;

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [processId]: {
                            ...state.domain.byId[processId],
                            tasks: {
                                ...state.domain.byId[processId].tasks,
                                [taskId]: {
                                    ...state.domain.byId[processId].tasks[taskId],
                                    details: {
                                        ...state.domain.byId[processId].tasks[taskId].details,
                                        uploadedDocuments: {
                                            ...state.domain.byId[processId].tasks[taskId].details.uploadedDocuments,
                                            [docKey]: {
                                                ...state.domain.byId[processId].tasks[taskId].details.uploadedDocuments[
                                                    docKey
                                                ],
                                                descricao: value,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                app: {
                    ...state.app,
                },
            };
        }

        case executionActionTypes.setDocumentField: {
            const { processId, taskId, docKey, ...fields } = action.payload;

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [processId]: {
                            ...state.domain.byId[processId],
                            tasks: {
                                ...state.domain.byId[processId].tasks,
                                [taskId]: {
                                    ...state.domain.byId[processId].tasks[taskId],
                                    details: {
                                        ...state.domain.byId[processId].tasks[taskId].details,
                                        uploadedDocuments: {
                                            ...state.domain.byId[processId].tasks[taskId].details.uploadedDocuments,
                                            [docKey]: {
                                                ...state.domain.byId[processId].tasks[taskId].details.uploadedDocuments[
                                                    docKey
                                                ],
                                                ...fields,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                app: {
                    ...state.app,
                },
            };
        }

        case executionActionTypes.removeDocument: {
            const { processId, taskId, docKey } = action.payload;
            // const { [docKey]: removedDoc, ...docsWithoutRemovedOne } =
            //     state.domain.byId[processId].tasks[taskId].details.uploadedDocuments;

            const docsWithoutRemovedOne = toArray(
                state.domain.byId[processId].tasks[taskId].details.uploadedDocuments
            ).filter((doc) => doc.key !== docKey);

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [processId]: {
                            ...state.domain.byId[processId],
                            tasks: {
                                ...state.domain.byId[processId].tasks,
                                [taskId]: {
                                    ...state.domain.byId[processId].tasks[taskId],
                                    details: {
                                        ...state.domain.byId[processId].tasks[taskId].details,
                                        uploadedDocuments: docsWithoutRemovedOne,
                                    },
                                },
                            },
                        },
                    },
                },
                app: {
                    ...state.app,
                },
            };
        }

        case executionActionTypes.setTemporaryRelId: {
            const { id, rowKey } = action.payload;
            return {
                ...state,
                app: {
                    ...state.app,
                    temporaryRel: {
                        ...state.app.temporaryRel,
                        [rowKey]: {
                            ...state.app.temporaryRel[rowKey],
                            id,
                        },
                    },
                },
            };
        }

        case executionActionTypes.setTemporaryRelName: {
            const { name, rowKey } = action.payload;
            return {
                ...state,
                app: {
                    ...state.app,
                    temporaryRel: {
                        ...state.app.temporaryRel,
                        [rowKey]: {
                            ...state.app.temporaryRel[rowKey],
                            name,
                        },
                    },
                },
            };
        }

        case executionActionTypes.setTemporaryRelRowKey: {
            const { rowKey } = action.payload;
            return {
                ...state,
                app: {
                    ...state.app,
                    temporaryRelRowKey: rowKey,
                },
            };
        }

        case indexActionTypes.isFormReady: {
            return {
                ...state,
                app: {
                    ...state.app,
                    isFormReady: true,
                },
            };
        }

        case indexActionTypes.changeViewerSelected: {
            return {
                ...state,
                app: {
                    ...state.app,
                    selectedViewer: action.payload,
                },
            };
        }

        default:
            return state;
    }
};
