import React, { Component } from "react";
import {
    Row,
    Button,
    Table,
    Select,
    Badge,
    Popconfirm,
    Divider,
    Form,
    Col,
    Tooltip,
    Input,
    Icon,
    Typography,
    message,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";

import DepartmentModal from "./departmentmodal";
import { selectDataFromExecQuery } from "../../actions/contributor";

const { Option } = Select;
const { Text } = Typography;

class FuntionsConventionsTabPane extends Component {
    state = {
        record: {},
        show_department_modal: false,
        dataSource: [],
        countDataSource: 1,
        convencoes: [],
        categoria: [],
        nivel: [],
        infoNivel: [],
        convencaoId: "",
        categoriaId: "",
    };
    componentDidMount() {
        const { ficha_colaborador } = this.props;
        this.getConvencoesFromServer();
        if (ficha_colaborador) {
            this.getCategoriaFromServer(ficha_colaborador.convenc_coletiva);
            this.getNivelFromServer(ficha_colaborador.categoria);
            this.getInfoNivelFromServer(ficha_colaborador.nivel);
            const { funcao_colab } = ficha_colaborador.funcoes_colab;
            if (funcao_colab && Array.isArray(funcao_colab)) {
                const dataSource = [];
                funcao_colab.map((item, index) => {
                    dataSource.push({ key: index, ...item });
                });
                this.setState({ dataSource });
                this.props.getFuncoesColab(dataSource);
            } else if (funcao_colab && funcao_colab.id_funcao_colab) {
                this.setState({ dataSource: [{ key: 0, ...funcao_colab }] });
            }
        }
    }

    getConvencoesFromServer = async () => {
        try {
            const convencoes = await this.props.selectDataFromExecQuery({
                query: "convencoes",
            });
            if (convencoes.result !== undefined) {
                this.setState({ convencoes: convencoes.result });
            }
        } catch (error) {
            message.error("Erro ao listar convenções!!");
        }
    };

    getCategoriaFromServer = async (convencaoId) => {
        try {
            this.props.getConvencColetiva(convencaoId);
            const categoria = await this.props.selectDataFromExecQuery({
                query: "categorias_convencao",
                convencao: convencaoId,
            });
            if (categoria.result !== undefined) {
                this.setState({ convencaoId, categoria: categoria.result });
            }
        } catch (error) {
            message.error("Erro ao listar categoria!!");
        }
    };

    getNivelFromServer = async (categoriaId) => {
        try {
            this.props.getCategoria(categoriaId);
            const nivel = await this.props.selectDataFromExecQuery({
                query: "niveis_categoria",
                categoria: categoriaId,
            });
            if (nivel.result !== undefined) {
                this.setState({ categoriaId, nivel: nivel.result });
            }
        } catch (error) {
            message.error("Erro ao listar nivel!!");
        }
    };

    getInfoNivelFromServer = async (nivelId) => {
        try {
            this.props.getNivel(nivelId);
            const infoNivel = await this.props.selectDataFromExecQuery({
                query: "carrega_info_nivel_colet",
                categoria: this.state.categoriaId,
                nivel: nivelId,
                convenc_coletiva: this.state.convencaoId,
            });
            if (infoNivel.result !== undefined) {
                this.setState({ infoNivel: infoNivel.result });
                if (infoNivel?.result?.length) {
                    this.props.getInfoNivel(infoNivel.result["0"].anos_transitar_nivel);
                }
            }
        } catch (error) {
            message.error("Erro ao listar informação do nivel!!");
        }
    };

    onChange = (dataIndex, value, record) => {
        record[dataIndex] = value;
        const { dataSource } = this.state;
        const index = dataSource.findIndex((item) => item.key === record.key);
        dataSource[index] = record;
        this.setState({ dataSource }, this.props.getFuncoesColab(this.state.dataSource));
    };

    column = [
        {
            title: "Grupo Funcional",
            dataIndex: "id_funcao",
            width: "70%",
            render: (text, record) => (
                <Select
                    allowClear
                    showSearch
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="Seleciona o grupo funcional"
                    optionFilterProp="children"
                    onChange={(value) => this.onChange("id_funcao", value, record)}
                    defaultValue={typeof text !== "object" ? text : null}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {this.props.functionalGroups !== undefined &&
                        this.props.functionalGroups.map((item) => {
                            if (typeof item.id_ficha_funcao !== "object" && typeof item.codigo !== "object") {
                                return (
                                    <Option key={item.id_ficha_funcao} value={item.id_ficha_funcao}>
                                        {item.descricao_funcao}
                                    </Option>
                                );
                            }
                        })}
                </Select>
            ),
        },
        {
            title: "Unidade orgânica",
            dataIndex: "fcb_departamentos",
            align: "center",
            width: "20%",
            render: (_, record) => {
                const totalDepartment = typeof record.total_departamento !== "object" ? record.total_departamento : 0;
                return (
                    <Badge count={record.totalOfDepartment || totalDepartment}>
                        {record.fcb_departamentos && typeof record.fcb_departamentos !== "object" ? (
                            <Button type="primary" icon="edit" onClick={() => this.showDepartmentModal(record)} />
                        ) : (
                            <Button type="primary" icon="plus" onClick={() => this.showDepartmentModal(record)} />
                        )}
                    </Badge>
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (_, record) =>
                this.state.dataSource.length >= 1 ? (
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        onConfirm={() => this.handleDelete(record.key)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState(
            {
                dataSource: dataSource.filter((item) => item.key !== key),
            },
            () => this.props.getFuncoesColab(this.state.dataSource)
        );
    };

    handleAdd = () => {
        const { countDataSource, dataSource } = this.state;
        const newData = {
            key: countDataSource,
        };
        this.setState({
            dataSource: [newData, ...dataSource],
            countDataSource: countDataSource + 1,
        });
    };

    showDepartmentModal = (record) => {
        this.setState({
            show_department_modal: true,
            record,
        });
    };

    hiddeDepartmentModal = (record) => {
        if (record) {
            const { dataSource } = this.state;
            const index = dataSource.findIndex((item) => item.key === record.key);
            dataSource[index] = record;
            this.setState(
                {
                    dataSource,
                },
                () => this.props.getFuncoesColab(this.state.dataSource)
            );
        }
        this.setState({
            show_department_modal: false,
        });
    };
    renderLabel = () => (
        <Row>
            <Tooltip title={this.description}>
                <Icon type="info-circle" />
            </Tooltip>
            <Text> Data em vigor</Text>
        </Row>
    );
    description =
        "Indique a data em que o colaborador iniciou neste nível. Se não preencher este campo não serão criadas alertas.";

    /**
     *disable date until now
     */
    disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf("day");
    };
    /**
     * disable time
     */
    range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    disabledDateTime = () => {
        return {
            disabledHours: () => this.range(0, 24).splice(4, 20),
            disabledMinutes: () => this.range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { ficha_colaborador } = this.props;
        return (
            <div>
                <Row>
                    {this.state.show_department_modal && (
                        <DepartmentModal
                            record={this.state.record}
                            isVisible={this.state.show_department_modal}
                            hiddeDepartmentModal={this.hiddeDepartmentModal.bind(this)}
                        />
                    )}
                    <Button
                        xs={24}
                        sm={12}
                        onClick={this.handleAdd}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{ marginBottom: 16, float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Row>
                <Table
                    size="middle"
                    scroll={{ x: 800, y: 300 }}
                    columns={this.column}
                    dataSource={this.state.dataSource}
                />
                <Divider orientation="left">Convenções</Divider>
                <Form layout="vertical">
                    <Row gutter={16}>
                        <Col xs={24} sm={16} md={8}>
                            <Form.Item label="Convenção coletiva do trabalho">
                                {getFieldDecorator("convenc_coletiva", {
                                    initialValue:
                                        ficha_colaborador && typeof ficha_colaborador.convenc_coletiva !== "object"
                                            ? ficha_colaborador.convenc_coletiva
                                            : undefined,
                                })(
                                    <Select
                                        allowClear
                                        showSearch
                                        style={{
                                            width: "100%",
                                            margin: "0 auto",
                                            padding: "0",
                                        }}
                                        placeholder="seleciona uma opção"
                                        optionFilterProp="children"
                                        onChange={(id) => this.getCategoriaFromServer(id)}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.convencoes.map((item) => {
                                            if (item.nome !== "" && typeof item.nome !== "object") {
                                                return (
                                                    <Option
                                                        key={item.id_convencao_coletiva}
                                                        value={item.id_convencao_coletiva}
                                                    >
                                                        {item.nome}
                                                    </Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={8}>
                            <Form.Item label="Categoria">
                                {getFieldDecorator("categoria", {
                                    initialValue:
                                        ficha_colaborador && typeof ficha_colaborador.categoria !== "object"
                                            ? ficha_colaborador.categoria
                                            : undefined,
                                })(
                                    <Select
                                        allowClear
                                        showSearch
                                        style={{
                                            width: "100%",
                                            margin: "0 auto",
                                            padding: "0",
                                        }}
                                        placeholder="seleciona uma opção"
                                        optionFilterProp="children"
                                        onChange={(id) => this.getNivelFromServer(id)}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.categoria.map((item) => {
                                            if (item.designacao !== "" && typeof item.designacao !== "object") {
                                                return (
                                                    <Option key={item.id_categoria} value={item.id_categoria}>
                                                        {item.designacao}
                                                    </Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={8}>
                            <Form.Item label="Nível">
                                {getFieldDecorator("nivel", {
                                    initialValue:
                                        ficha_colaborador && typeof ficha_colaborador.nivel !== "object"
                                            ? ficha_colaborador.nivel
                                            : undefined,
                                })(
                                    <Select
                                        allowClear
                                        showSearch
                                        style={{
                                            width: "100%",
                                            margin: "0 auto",
                                            padding: "0",
                                        }}
                                        placeholder="seleciona uma opção"
                                        optionFilterProp="children"
                                        onChange={(id) => this.getInfoNivelFromServer(id)}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.nivel.map((item) => {
                                            if (item.nivel !== "" && typeof item.nivel !== "object") {
                                                return (
                                                    <Option
                                                        key={item.id_nivel_categoria}
                                                        value={item.id_nivel_categoria}
                                                    >
                                                        {item.nivel}
                                                    </Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={8}>
                            <Form.Item label="Informação do tempo para transição">
                                {getFieldDecorator("info_nivel", {
                                    initialValue:
                                        ficha_colaborador && typeof ficha_colaborador.info_nivel !== "object"
                                            ? ficha_colaborador.info_nivel
                                            : this.state.infoNivel.length > 0
                                            ? this.state.infoNivel["0"].anos_transitar_nivel
                                            : null,
                                })(<Input readOnly placeholder="Informação do tempo para transição" />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={8}>
                            <Form.Item
                                label={
                                    <Row>
                                        <Tooltip title={this.description}>
                                            <Icon type="info-circle" />
                                        </Tooltip>
                                        <Text> Data em vigor</Text>
                                    </Row>
                                }
                            >
                                <Input
                                    type="date"
                                    min={moment().format("YYYY-MM-DD")}
                                    // disabledDate={this.disabledDate}
                                    // disabledTime={this.disabledDateTime}
                                    defaultValue={
                                        typeof ficha_colaborador?.data_vigor !== "object"
                                            ? moment(ficha_colaborador.data_vigor, "YYYY-MM-DD").format("YYYY-MM-DD")
                                            : null
                                    }
                                    onChange={(e) => {
                                        this.props.getDataVigor(
                                            e.target.value ? moment(e.target.value).format("YYYY-MM-DD") : ""
                                        );
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        functionalGroups: Object.values(state.funcionalGroup.domain.byId),
    };
};

const mapDispatchToProps = {
    selectDataFromExecQuery,
};
export default connect(mapStateToProps, mapDispatchToProps)(FuntionsConventionsTabPane);
