import React, { useState } from "react";
import { Row, Col, Button, Upload, Modal, message } from "antd";
import { FormAttributesProps, FormInputsObjectProps, FormRootElementAttributesProps } from "../../../types/formTypes";
import { showPrinterButton, handleSave, showButtons } from "../handle/formHandle";
import { downloadFile } from "../../../../components/util";
import { useHistory } from "react-router-dom";

const { confirm } = Modal;

function showError(errors: string[] = []) {
    if (errors.length === 0) {
        return;
    }

    Modal.error({
        title: "Erro",
        content: (
            <div>
                {errors.map((it, idx) => (
                    <p key={idx}>{it}</p>
                ))}
            </div>
        ),
    });
}

interface QaFormButtonsProps {
    form?: FormInputsObjectProps;
    formAttributes: FormAttributesProps;
    rootElementAttributes?: FormRootElementAttributesProps;
    justSee?: boolean;
    onSave: (value?: string) => void;
    setTriedSave: (state: any) => void;
    isModal: boolean;
    isProcess: boolean;
}

export interface ButtonsVisibleProps {
    save: boolean;
    print: boolean;
    temporarySave: boolean;
    localSave: boolean;
    reformulate: boolean;
    correctionList: boolean;
}
// select nome, xml, * from qa_formularios where xml not like '%botoes="topo"%' and xml not like '%tipo="subformulario"%'
// and xml not like '%ignora_dados="sim"%' and xml not like '%consulta_formulario%'
// and xml not like '%botoes="botoes_subformulario"%'
const QaFormButtonActions = ({
    form,
    formAttributes,
    onSave,
    rootElementAttributes,
    justSee,
    setTriedSave,
    isModal,
    isProcess,
}: QaFormButtonsProps) => {
    const browserHistory = useHistory();

    const [saving, setSaving] = useState(false);
    const [tempSaving, setTempSaving] = useState(false);

    const [printing, setPrinting] = useState(false);

    const toggleLoadSave = (temporary = false) => {
        if (temporary) {
            setTempSaving((prev) => !prev);
        } else {
            setSaving((prev) => !prev);
        }
    };

    const onClickSave = async (temporary = false) => {
        toggleLoadSave(temporary);

        if (!temporary) {
            setTriedSave(true);
        }

        const result = await handleSave(form!, formAttributes, temporary);

        if (result.status === "SUCCESS") {
            const resp = result.response;
            if (!resp || resp.sucesso === "0") {
                message.error("Ocorreu um erro");
                toggleLoadSave(temporary);
            } else {
                // sucesso
                message.success("Guardado com sucesso");

                if (rootElementAttributes?.report || resp.notificacao) {
                    showNotificationAndReport(resp.sucesso, resp.notificacao);
                } else {
                    if (onSave && typeof onSave === "function") {
                        onSave(resp.sucesso);
                    }

                    if (!isModal && !isProcess) {
                        browserHistory.push("/");
                    }
                }
            }
        } else {
            if (result.status === "ERROR") {
                message.error(result.message || "Ocorreu um erro");
            } else if (!result.status) {
                if (result?.errors) {
                    showError(result.errors);
                }
            }
            toggleLoadSave(temporary);
        }

        // setSaving(false);

        // console.log(response);

        // if (resp !== "empty") {
        //     if (!resp || resp.sucesso === "0") {
        //         message.error("Ocorreu um erro");
        //     } else {
        //         if (isPortal) {
        //             message.success("Guardado com sucesso!");
        //             handleNavegateToHome();
        //         } else if (temporary) {
        //             message.success(
        //                 "Tarefa guardada com sucesso! Podes voltar a ela a qualquer momento e continuar o trabalho. Não podemos avançar sem salvá-la em definitivo."
        //             );
        //         } else {
        //             message.success("Tarefa guardada com sucesso!");
        //             handleDetails({ isSaved: true });
        //         }
        //     }
        // }
    };

    // ------------- Notification and print report on save ------------------------------------------------
    const showConfirmPrintReport = (formId: string) => {
        if (rootElementAttributes?.report) {
            confirm({
                title: "Descarregar documento?",
                onOk() {
                    downloadFile("/gererelatorios.php", {
                        accao: "relatorio_nome",
                        nome: rootElementAttributes?.report,
                        saida: "pdf",
                        id: formId,
                        downloadInBrowser: true,
                    });
                    onSave(formId);
                },
                onCancel() {
                    onSave(formId);
                },
            });
        } else {
            onSave(formId);
        }
    };

    const showNotificationAndReport = (formId: string, notification: string) => {
        if (notification) {
            Modal.confirm({
                title: "Notificação",
                content: <pre>{notification}</pre>,
                className: "email_info",
                okText: "Ok",
                okType: "primary",
                cancelButtonProps: {
                    hidden: true,
                },
                onOk() {
                    showConfirmPrintReport(formId);
                },
            });
        } else {
            showConfirmPrintReport(formId);
        }
    };

    const buttonVisible = showButtons(rootElementAttributes, justSee);
    // const showPrinterButton = () => {
    //     return true;
    // };

    // subformulario --- mostrar so guardar

    // {(!formulario["@consulta_formulario"] ||
    // (formulario["@consulta_formulario"] && formulario["@consulta_formulario"] !== "sim")) &&
    // // formulario?.["@ignora_dados"] !== "sim" &&
    // !justSee && <RenderButtonsActions />}

    // tipo = "subformulario";

    // botoes = "topo";
    // consulta_formulario = "sim"
    // imprimir="sim, nao , false"
    // else if(a[j].name() == "botoes" && a[j] == "botoes_subformulario")
    return (
        <>
            <Row gutter={18} style={{ marginTop: 16 }}>
                <Col xs={24} sm={12}>
                    <div className="qa-group-actions-left">
                        {buttonVisible.save && (
                            <>
                                <Button
                                    // disabled={saving || isSavingTemporarily}
                                    loading={saving}
                                    type="primary"
                                    icon="save"
                                    onClick={() => onClickSave()}
                                    // onClick={(e) => {
                                    //     if (formKey === "qa_parcerias_minutas_protocolo.xml" && formData.get().versao_atual > 0) {
                                    //         Confirm(() => {
                                    //             handleSave(false);
                                    //         }, "Atenção! Se alterar a descrição será considerada uma nova minuta. Se pretende revê-la, não poderá alterar a descrição");
                                    //         return;
                                    //     }
                                    //     handleSave(false);
                                    // }}
                                >
                                    Guardar
                                </Button>
                                {/* <Button onClick={() => showNotificationAndReport("216635", "fffff")}>
                                    Impremir relatorio
                                </Button> */}
                            </>
                        )}
                        {/* {buttonVisible.print && (
                        <Button
                            icon="printer"
                            disabled={saving}
                            loading={printing}
                            onClick={printForm}
                        >
                            Imprimir
                        </Button>
                    )} */}

                        {buttonVisible.temporarySave && (
                            <Button
                                icon="save"
                                disabled={printing}
                                onClick={() => onClickSave(true)}
                                loading={tempSaving}
                            >
                                Guardar Temporário
                            </Button>
                        )}
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="qa-group-actions-right">
                        {/* {buttonVisible.correctionList && <Button icon="snippets">Correções</Button>} */}
                        {buttonVisible.reformulate && (
                            <Button icon="undo" type="danger" ghost>
                                Reformular
                            </Button>
                        )}
                        {buttonVisible.localSave && (
                            <>
                                <Upload
                                    // {...fileProps}
                                    className="load-local"
                                >
                                    <Button
                                        icon="cloud-upload"
                                        htmlType="submit"
                                        //  disabled={saving || isSavingTemporarily}
                                    >
                                        Carregar Local
                                    </Button>
                                </Upload>

                                <Button
                                    icon="cloud-download"
                                    // disabled={saving || isSavingTemporarily}
                                    // htmlType="submit"
                                    // onClick={handleLocalSave}
                                >
                                    Gravar Local
                                </Button>
                            </>
                        )}
                    </div>
                </Col>
            </Row>

            {/* <ReportPrintOnSave
                visible={showReportModal}
                closeModalReportPrint={closeModalReportPrint}
                // handleOkReportPrint={handleOkReportPrint}
            /> */}
        </>
    );
};

export const QaFormActions = QaFormButtonActions;
