import React from "react";
import { Modal, Form, Input, Checkbox, Row, Col } from "antd";

const { TextArea } = Input;

const ChangeReason = ({ visible, handleOk, handleCancel, form, formItemLayout }) => {
    const { getFieldDecorator } = form;

    const changeEvent = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                handleOk(
                    values.reason,
                    values.notify.includes("remember") ? "true" : "false",
                    values.notify.includes("message") ? "true" : "false",
                    values.notify.includes("sms") ? "true" : "false",
                    values.notify.includes("email") ? "true" : "false"
                );
            }
        });
    };

    return (
        <Modal title="Motivo da alteração" visible={visible} onOk={changeEvent} onCancel={handleCancel}>
            <Form {...formItemLayout}>
                {/* Event delete reason */}
                <Form.Item>
                    {getFieldDecorator("reason", {
                        rules: [
                            {
                                required: true,
                                message: "Informe o motivo para alteração do evento",
                            },
                        ],
                    })(<TextArea rows={5} maxLength={500} />)}
                </Form.Item>

                <Form.Item label="Notificar por">
                    {getFieldDecorator("notify", {
                        initialValue: ["remember"],
                    })(
                        <Checkbox.Group style={{ width: "100%" }}>
                            <Row>
                                <Col span={6}>
                                    <Checkbox value="remember">Lembrete</Checkbox>
                                </Col>
                                <Col span={6}>
                                    <Checkbox value="message">Mensagem</Checkbox>
                                </Col>
                                <Col span={6}>
                                    <Checkbox value="sms">SMS</Checkbox>
                                </Col>
                                <Col span={6}>
                                    <Checkbox value="email">Email</Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Form.create({ name: "change_reason" })(ChangeReason);
