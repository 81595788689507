export const actionType = {
    select: "SELECT_COLLECTIVE_AGREEMENT",
    setField: "SET_FIELD_COLLECTIVE_AGREEMENT",
    addCategory: "ADD_CATEGORY_COLLECTIVE_AGREEMENT",
    deleteCategory: "DELETE_CATEGORY_COLLECTIVE_AGREEMENT",
    updateCategory: "UPDATE_CATEGORY_COLLECTIVE_AGREEMENT",
    insert: "INSERT_COLLECTIVE_AGREEMENT",
    getById: "GET_COLLECTIVE_AGREEMENT_BY_ID",
    getNiveis: "GET_COLLECTIVE_AGREEMENT_NIVEL_BY_ID",
    saveNiveis: "SAVE_CATEGORY_NIVEL",
    update: "UPDATE_COLLECTIVE_AGREEMENT",
    // delete: "DELETE_COLLECTIVE_AGREEMENT"
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
