export const COMPONENT_TYPE = {
    etiqueta: "label",
    lista_dinamica: "lista_dinamica",
    select: "select",
    radio: "radio",
    textArea: "textArea",
    boolean: "boolean",
    checkbox_group: "checkbox_group",
    botao: "botao",
    sugestao: "sugestao",
    lista_estatica: "lista_estatica",
    link: "link",
    relatorio: "relatorio",
    pop_up_label: "pop_up_label",
    pop_up: "pop_up",
    datagrid: "datagrid",
    conjunto: "conjunto",
    float: "float",
    time: "time",
};

export const GRAPHIC_ELEMENT = {
    lista_escolha_unica: "lista_escolha_unica",
    botoes_seleccao: "botoes_seleccao",
    etiqueta: "etiqueta",
    areatexto: "areatexto",
    combobox: "combobox",
    botoes_escolha_multipla: "botoes_escolha_multipla",
    botao: "botao",
    sugestao: "sugestao",
    escolha_unica: "escolha_unica",
};
