import React from "react";

import FormComponent from "./Form";
import Layout from "../Common/Layout";

// import "./styles.css";

const LockScreen = ({ unlocked }) => {
    return (
        <Layout defaultStyle={{ zIndex: 9999 }}>
            <FormComponent unlocked={unlocked} />
        </Layout>
    );
};

export default LockScreen;
