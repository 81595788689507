import { Form, Input } from "antd";
import React, { useMemo, memo, useState, useEffect } from "react";
import moment from "moment";
import {
    FormInputsObjectProps,
    FormInputsProps,
    InputDependenceProps,
    RenderContextType,
} from "../../../types/formTypes";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";
import { FormLabel, getLabel } from "../Components/FormLabel";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { Group } from "../Components/Group";
import { CommunComponentsProps, HandleOnInputChangeValueProps, InputInTableProps } from "../../../types/common";
import { getInputThatDependsOnOtherInput, getSelectDataKeys } from "../../../utils/FormMapper/formHandle";
import { compareProps, getFullPathRow } from "../handle/inputHandle";
import { useInputHandle } from "../hooks/InputHandle";
import { Utilitaries } from "../../../../business";
import { highlightsErrors } from "../../../utils/functions";

// import { componentType, graphicElement } from "../../constants/index";
// import QaDataDisplay from "../qa_components/dataDisplay";
// import { canCorrectField } from "../../utils/fields";
// import { FormUtilitaries } from "../../../../business";

const dateStyles = {
    width: "100%",
};

interface ComponentsPropertiesProps extends CommunComponentsProps {
    // setFieldEditor: React.Dispatch<React.SetStateAction<FieldEditorProps>>;
}

// interface ComponentsPropertiesProps {
//     componentsProperties: FormInputsProps;
//     renderContext: RenderContextType;
//     tableRow?: InputInTableProps;
//     formState: FormInputsObjectProps;
//     handleOnInputChangeValue: (props: HandleOnInputChangeValueProps) => void;
// }

const _QADate: React.FC<ComponentsPropertiesProps> = ({
    // onChange,
    // type,
    // currentTaskStatus,
    // itemData,
    // label,
    // formItemStyle,
    // readonly,
    // renderContext,
    // correctForm,
    // state,
    // error,
    formState,
    componentsProperties,
    renderContext,
    tableRow,
    formInputDependenceState,
    inputCalcTrigger,
    handleOnInputChangeValue,
    justSee,
    triedSave,
}) => {
    const [showError, setShowError] = useState(false);

    print_when_component_is_rendered(componentsProperties);

    useEffect(() => setShowError(triedSave), [triedSave]);

    // const value = useMemo(() => {
    //     if (itemData.value === "{now()}") {
    //         return moment(new Date(), "YYYY-MM-DD");
    //     } else if (!itemData.value) {
    //         return null;
    //     } else {
    //         const date = moment(itemData.value, "YYYY-MM-DD");
    //         return date.isValid() ? date : null;
    //     }
    // }, [itemData.value]);

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);
    const full_path = tableRow?.row_key ? fullPathRow : componentsProperties?.full_path;

    const valueToFormat =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    const {
        status: whenValueChangeStatus,
        componentValue,
        hasUpdatedValue,
        calculated,
        required,
        restriction,
        visible,
        relevant,
        readOnly,
        error,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[full_path || ""],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
        showError: triedSave || showError,
        value: valueToFormat,
    });

    useEffect(() => {
        if (hasUpdatedValue > 0 && whenValueChangeStatus !== "IDLE") {
            handleOnInputChangeValue({
                value: componentValue,
                componentsProperties,
                row_key: tableRow?.row_key,
                full_path,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUpdatedValue]);

    useEffect(() => {
        if (
            componentsProperties.calculate &&
            (!Utilitaries.isEmpty(inputCalcTrigger?.[componentsProperties?.full_path || ""]) ||
                !Utilitaries.isEmpty(inputCalcTrigger?.[fullPathRow]) ||
                componentsProperties.persist === "nao")
        ) {
            handleOnInputChangeValue({
                value: calculated,
                componentsProperties,
                row_key: tableRow?.row_key,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculated]);

    const value = useMemo(() => {
        if (!valueToFormat) {
            return undefined;
        } else {
            const date = moment(valueToFormat, "YYYY-MM-DD");
            return date.isValid() ? date.format("YYYY-MM-DD") : undefined;
        }
    }, [valueToFormat]);

    // useEffect(() => {
    //     if (state.form[itemData.key]?.value === "{now()}") {
    //         const td = moment(new Date(), "YYYY-MM-DD");
    //         onChange(td.format("YYYY-MM-DD"));
    //     }
    // }, []);
    // if (itemData["@elemento_grafico"] === graphicElement.title) {
    //     const newItemData = { ...itemData, value: value ? value.format("DD-MM-YYYY") : value };
    //     return <QaDataDisplay label={label} itemData={newItemData} renderContext={renderContext} />;
    // }

    // const styles = renderContext === "column" ? { width: "115px" } : dateStyles;

    // const canEdit = canCorrectField(itemData) && correctForm;

    // const disabled = canEdit ? false : currentTaskStatus;

    if (!visible || !relevant) {
        return null;
    }

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={required} renderContext={renderContext}>
                {componentsProperties.type === COMPONENT_TYPE.DATE && (
                    // <Input
                    //     type="date"
                    //     disabled={disabled}
                    //     style={styles}
                    //     value={value ? moment(value, "YYYY-MM-DD").format("YYYY-MM-DD") : null}
                    //     onChange={(e) => onChange(moment(e.target.value).format("YYYY-MM-DD"))}
                    // />

                    <Input
                        type="date"
                        value={value}
                        // value={value ? moment(value, "YYYY-MM-DD").format("YYYY-MM-DD") : null}
                        disabled={justSee || readOnly}
                        // readOnly={readonly}
                        // // disabled={currentTaskStatus || canCorrectField(itemData)}
                        // style={FormUtilitaries.highlightsErrors(value, itemData.required)}
                        //   name={itemData["name"]}
                        onChange={(date) => {
                            // onChange(date?.target?.value ? moment(date.target.value).format("YYYY-MM-DD") : "")
                            handleOnInputChangeValue({
                                value: date.target.value,
                                componentsProperties,
                                row_key: tableRow?.row_key,
                                full_path,
                            });
                        }}
                        style={highlightsErrors({ value, required, restriction })}
                        // placeholder={itemData["@placeholder"] || "Data"}

                        // format={"DD/MM/YYYY"}
                        // format={["DD/MM/YYYY"]}
                    />
                )}

                {showError && error && <span style={{ color: "red" }}>{error}</span>}
            </FormLabel>
        </Group>
    );
};

export const QADate = memo(_QADate, (prev, next) => compareProps<ComponentsPropertiesProps>(prev, next));
