import React, { Component } from "react";
import { Modal, Form, Input, Radio, message } from "antd";
import { connect } from "react-redux";

import { update } from "../actions/index";

const { TextArea } = Input;

class ConfigDynamicList extends Component {
    /** handle state */
    state = {
        loading: false,
    };
    /** close modal */
    handleCancel = () => {
        this.props.hiddeModal();
    };
    /** save from modal */
    handleOk = () => {
        this.setState({ loading: true });
        this.props.form.validateFields((error, values) => {
            if (!error) {
                this.props
                    .update({
                        accao: "alterar",
                        nome: this.props.dataSource.nome,
                        descricao: values.descricao,
                        esconde: values.esconde,
                    })
                    .then((sucess) => {
                        this.setState({ loading: false });
                        if (sucess) {
                            message.success("Configurado com sucesso");
                            this.props.hiddeModal(true);
                        } else {
                            message.error("Erro na configuração da lista");
                            this.props.hiddeModal();
                        }
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <Modal
                    width="60%"
                    visible={this.props.isVisible}
                    title={`Configurar lista dinâmica: ${this.props.dataSource.nome}`}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    confirmLoading={this.state.loading}
                >
                    <Form layout="vertical">
                        <Form.Item label="Descrição">
                            {getFieldDecorator("descricao", {
                                initialValue: this.props.dataSource.descricao,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(<TextArea autosize={{ minRows: 3 }} />)}
                        </Form.Item>
                        <Form.Item label="Esconder esta lista?">
                            {getFieldDecorator("esconde", {
                                initialValue: this.props.dataSource.esconde,
                            })(
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="t">Sim</Radio.Button>
                                    <Radio.Button value="f">Não</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {
    update,
};

const mapStateToProps = (state) => {
    return {
        dynamicLists: state.dynamicList.domain,
    };
};

const formCreate = Form.create({
    mapPropsToFields(props) {},
    onFieldsChange(props, fields) {},
});

export default connect(mapStateToProps, mapDispatchToProps)(formCreate(ConfigDynamicList));
