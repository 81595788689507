import { actionTypes } from "../constants/index";
import { validateResponse, arrayToObjectById } from "./../../../components/util";
import moment from "moment";
const formatDateBd = "YYYY-MM-DD";
const formatDateTable = "DD-MM-YYYY";

const initialState = {
    domain: {
        formEdit: {
            acao: {
                name: "acao",
                value: null,
            },
            data: {
                name: "data",
                value: null,
            },
            ficheiro: null,
            norma: {
                name: "norma",
                value: null,
            },
        },
        byId: {},
        metadata: {
            attributes: {},
            formulario: {},
        },
    },
    app: {},
};

export const qaStandartsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.insert:
            return { ...state };

        case actionTypes.selectQaStandart:
            const list = validateResponse(action.payload);

            const listWithFormatData = list.map((item) => {
                return {
                    ...item,
                    data:
                        typeof item.data === "object" ? null : moment(item.data, formatDateBd).format(formatDateTable),
                };
            });

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: listWithFormatData,
                        id: "id_insere_normas",
                    }),
                    metadata: {
                        ...state.domain.metadata,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };

        case actionTypes.getImportFormulario:
            const formCreate = action.payload.formulario["@attributes"];
            return {
                ...state,
                domain: {
                    ...state.domain,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formCreate.nome,
                            elemento_raiz: formCreate.elemento_raiz,
                        },
                    },
                },
            };

        case actionTypes.getDetailsQaNormas:
            const values = action.payload.valores.insere_normas;
            const formulario = action.payload.formulario["@attributes"];
            const formData = {
                id_tipo_conhecimento: values.id_tipo_conhecimento,
                acao: {
                    name: "acao",
                    value: values.acao,
                },
                data: {
                    name: "data",
                    value: values.data,
                },
                ficheiro: values.ficheiro,
                norma: {
                    name: "norma",
                    value: typeof values.norma === "object" ? null : values.norma,
                },
            };
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: {
                        ...state.domain.formEdit,
                        ...formData,
                    },
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formulario.nome,
                            elemento_raiz: formulario.elemento_raiz,
                        },
                    },
                },
            };

        default:
            return state;
    }
};
