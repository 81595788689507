import React from "react";
import { Col, Card } from "antd";
import { Chart, Geom, Axis, Tooltip, Legend, Coord, Label } from "bizcharts";
import DataSet from "@antv/data-set";
import EmptyData from "./emptyData";
import { TotalValues } from "./totalValues";
import ChartHelp from "./chartHelp";

const PizzaChart = ({ metadata, dataSource }) => {
    const { DataView } = DataSet;

    let data = [],
        dv = null,
        cols = null,
        total = 0;

    if (Array.isArray(dataSource) && dataSource.length !== 0) {
        total = dataSource.reduce((total, item) => total + Number(item.valor), 0);

        data = dataSource.map((item) => ({
            label: item.label || "Não definido",
            valor: Number(item.valor),
        }));

        dv = new DataView();
        dv.source(data).transform({
            type: "percent",
            field: "valor",
            dimension: "label",
            as: "percent",
        });
        cols = {
            percent: {
                formatter: (val) => {
                    val = (val * 100).toFixed(2) + "%";
                    return val;
                },
            },
        };
    }

    function getXY(c, { index: idx = 0, field = "percent", radius = 0.5 }) {
        const d = c.get("data");
        if (idx > d.length) return;
        const scales = c.get("scales");
        let sum = 0;
        for (let i = 0; i < idx + 1; i++) {
            let val = d[i][field];
            if (i === idx) {
                val = val / 2;
            }
            sum += val;
        }
        const pt = {
            y: scales[field].scale(sum),
            x: radius,
        };
        const coord = c.get("coord");
        let xy = coord.convert(pt);
        return xy;
    }

    return (
        <Col xs={24} md={metadata?.col || 12}>
            <Card
                size={"small"}
                title={metadata.title ? metadata.title : " "}
                extra={metadata.help ? <ChartHelp text={metadata.help} /> : null}
                className={"qa-title-dashboard"}
            >
                {!Array.isArray(dataSource) || dataSource.length === 0 ? (
                    <EmptyData />
                ) : (
                    <Chart
                        height={344}
                        data={dv}
                        scale={cols}
                        padding={"auto"}
                        forceFit
                        onGetG2Instance={(c) => {
                            const xy = getXY(c, { index: 0 });
                            c.showTooltip(xy);
                        }}
                    >
                        <TotalValues label={metadata.labelTotal} total={total} />
                        <Coord type="theta" radius={0.75} />
                        <Axis name="percent" />
                        <Legend position="bottom-center" />
                        <Tooltip
                            showTitle={false}
                            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {valueAbsolute} ({value})</li>'
                        />
                        <Geom
                            type="intervalStack"
                            position="percent"
                            color="label"
                            tooltip={[
                                "label*percent*valor",
                                (item, percent, valor) => {
                                    percent = (percent * 100).toFixed(2) + "%";
                                    return {
                                        name: item,
                                        value: percent,
                                        valueAbsolute: valor,
                                    };
                                },
                            ]}
                            style={{
                                lineWidth: 1,
                                stroke: "#fff",
                            }}
                        >
                            <Label
                                content="percent"
                                type="map"
                                formatter={(val, item) => {
                                    const label = item.point.label;
                                    const valor = item.point.valor;
                                    return `${label}:\n${valor}/${total} (${val})`;
                                }}
                            />
                        </Geom>
                    </Chart>
                )}
            </Card>
        </Col>
    );
};

export default PizzaChart;
