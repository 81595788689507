import React from "react";
import { Form, Row, Col, Select } from "antd";

const { Option } = Select;

const RegistrationControlTabPane = ({ getFieldDecorator, initialFormData }) => {
    const { metodo_arquivo } = initialFormData.document;

    return (
        <div>
            <Row gutter={18}>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Modo Arquivo">
                        {getFieldDecorator("metodo_arquivo", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: metodo_arquivo || "informático",
                        })(
                            <Select
                                showSearch
                                allowClear
                                disabled
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key="arquive_mode" value="informático">
                                    Informático
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Modo Indexação">
                        {getFieldDecorator("index_mode", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: "informático",
                        })(
                            <Select
                                showSearch
                                allowClear
                                disabled
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key="index_mode" value="informático">
                                    Informático
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Local Arquivo">
                        {getFieldDecorator("local_arquivo", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: "informático",
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                disabled
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key="local_arquive" value="informático">
                                    Informático
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Modo Proteção">
                        {getFieldDecorator("protection_mode", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: "Não Aplicado",
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                disabled
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key="protection_mode" value="Não Aplicado">
                                    Não Aplicado
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Modo Recuperação">
                        {getFieldDecorator("recover_mode", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: "Backup",
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                disabled
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key="recover_mode" value="Backup">
                                    Backup
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Tempo Retenção Arquivo Vivo">
                        {getFieldDecorator("arquive_lifecycle", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: "Não Aplicado",
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                disabled
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key="arquive_lifecycle" value="Não Aplicado">
                                    Não Aplicado
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Tempo Retenção Arquivo Morto">
                        {getFieldDecorator("arquive_diecycle", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: "Não Aplicado",
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                disabled
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key="qrquive_diecycle" value="Não Aplicado">
                                    Não Aplicado
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Método Eliminação">
                        {getFieldDecorator("delete_method", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ],
                            initialValue: "Não Aplicado",
                        })(
                            <Select
                                showSearch
                                allowClear
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                disabled
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key="delete_method" value="Não Aplicado">
                                    Não Aplicado
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default RegistrationControlTabPane;
