export const XML_INPUT_TYPE = {
    _MODAL: "@modal",
    CALCULATE: "@calcular",
    TYPE: "@tipo",
    ACTION: "@accao_",
    POPUPFORM: "@popupform",
    AUTO_FILL_VALUE: "auto_preenche_valor",
    GRAPHIC_ELEMENT: "@elemento_grafico",
    AUTO_FILL: "auto_preenche",
    RELEVANT: "@relevante",
    WHEN_VALUE_CHANGED: "@quando_valor_alterado",
    DATASOURCE: "@dados",
    DATASOURCE_TABLE: "@tabela",
    SIZE: "@size",
    TEXT: "@text",
    OPTYPE: "@optype",
    ICON: "@icon",
    STYLE: "@estilo",
    VALUES: "@valores",
    IMAGEM: "@imagem",
    TAB: "@tab",
    READ_ONLY: "@apenas_leitura",
};

export const XML_ORIGINAL_GRAPHIC_ELEMENT = {
    list_choice_sinfle: "lista_escolha_unica",
    label: "etiqueta",
    selection_button: "botoes_seleccao",
};

export const XML_ORIGINAL_COMPONENT_TYPE = {
    SUGESTION: "sugestao",
    DATE: "data",
    DIVIDER: "grupo",
    DYNAMIC_LIST: "lista_dinamica",
    STATIC_LIST: "lista_estatica",
    CONJUNTO: "conjunto",
    BOOLEAN: "boolean",
    BOOL: "bool",
    DATAGRID: "datagrid",
    INTEGER: "integer",
    INT: "int",
    ALERT: "alert",
    SHORT_TEXT: "texto_curto",
    TEXT: "texto",
    AREA_TEXTO: "areaTexto",
    ICON: "icon",
    REPORT: "relatorio",
    FLOAT: "float",
    STRING: "string",
    COMBOBOX: "combobox",
    UPLOAD: "upload_imagem",
    IMAGE: "imagem",
    FILE: "ficheiro",
    LINK: "link",
    LINK_TO_PROCESS: "linkToProcess",
};

export const COMPONENT_TYPE = {
    SUGESTION: "suggestion",
    DATE: "date",
    DIVIDER: "grupo",
    DYNAMIC_LIST: "dynamic_list",
    STATIC_LIST: "static_list",
    BOOLEAN: "boolean",
    DYNAMIC_TABLE: "dynamic_table",
    SELECTION_TABLE: "selection_table",
    DATAGRID: "datagrid",
    DYNAMIC_DATAGRID: "dynamic_datagrid",
    INPUT_NUMBER: "input_number",
    ALERT: "alert",
    INPUT_AREA: "input_area",
    BUTTON: "button",
    LINK: "link",
    LINK_TO_PROCESS: "link_to_process",
    SELECT: "select",
    UPLOAD: "upload_imagem",
    REPORT: "report",
    INPUT: "input",
    // -------------
    DATA_DISPLAY: "label",
    TAB: "tab",
    TAB_SEPARATOR: "tab_separator",
    INPUT_GROUP: "inputGroup",
    POPUP: "popup",
    INPUT_TIME: "time",
    // FLOAT: "float",
    // SHORT_TEXT: "texto_curto",
    // TEXT: "texto",
    // ICON: "icon",
    // STRING: "string",
    // COMBOBOX: "combobox",
    // LINK: "link",
    // READONLY: "readonly",
    // SELECTION_BUTTONS: "botoes_seleccao",
    // DELETE: "deleteAction",
    // CHECKBOX: "checkBox",
    // FILE: "ficheiro",
};

export const GRAPHIC_ELEMENT = {
    SINGLE_CHOICE_LIST: "lista_escolha_unica",
    SINGLE_CHOICE: "escolha_unica",
    TITLE: "etiqueta",
    SELECTION_BUTTONS: "botoes_seleccao",
    COMBOBOX: "combobox",
    BUTTON: "botao",
    SUGGESTION: "sugestao",
    MULTIPLE_SELECTION_BUTTONS: "botoes_escolha_multipla",
    TEXT_AREA: "areatexto",
};

export const FIELDS_TO_ASSIGN_AS_INPUT_TEXT_IF_NOT_HAVE_TYPE = [
    "coordenador_eli",
    "eli",
    "morada_eli",
    "telefone",
    "responsavel_caso",
    "telefone2",
    "evidencias",
    "obs_rel",
    "fonte_objetivo",
    "nome",
    "observacoes_vive",
    "aplicados",
    "instrumentos",
    "local",
    "capaz_resolver",
    "precisa_ajuda",
    "preocupa",
    "com_quem",
    "gosta",
    "onde",
    "quando",
    "identificacao",
    "ligacao",
    "participante_reun",
    "email_colab",
];
