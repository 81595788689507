import React, { useEffect, useState } from "react";
import { Table, Button, Input, Icon, Pagination, Row, Col, Tag, Tooltip, message, Radio } from "antd";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";

import { select, getListFunctionGroup } from "../../actions/funcional.group";

const FunctionalGroupIndex = ({ setReportFilters, filterValues, setFilterValues, showInatives, goto }) => {
    const [loadingData, setLoadingData] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        setLoadingData(true);
        const { sorter, pagination, ...objFilter } = filterValues;
        let values = {
            ...objFilter,
            pagina: pagination?.currentPage || 1,
            resultados_pagina: pagination?.currentPageSize || 10,
            accao: "funcoes",
        };
        if (showInatives) {
            values = {
                ...values,
                todos_utilizadores: showInatives,
            };
        }
        if (sorter && sorter.order) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        getListFunctionGroup(values).then((response) => {
            if (response) {
                setDataSource(response.lista);
                setMetadata(response.metadata);
            } else {
                message.error("Ocorreu um erro");
            }

            setLoadingData(false);
        });
    }, [filterValues, showInatives]);

    const getColumnSearchProps = (dataIndex) => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys}
                        onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
            filteredValue: filterValues[`filtro_${dataIndex}`] || null,
        };
    };

    const getcustomizedColumnSearchProps = (dataIndex) => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => {
                return (
                    <div style={{ padding: 8, minWidth: "143px" }}>
                        <Radio.Group
                            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                            value={selectedKeys}
                        >
                            {filters.map((item, index) => (
                                <Radio
                                    ref={searchInput}
                                    key={`radio-${index}`}
                                    value={item.value}
                                    style={{
                                        marginBottom: 8,
                                        display: "block",
                                    }}
                                >
                                    {item.text}
                                </Radio>
                            ))}
                        </Radio.Group>
                        <div className="ant-table-filter-dropdown-btns">
                            <a
                                className="ant-table-filter-dropdown-link confirm"
                                onClick={() => handleSearch(selectedKeys, confirm)}
                            >
                                Pesquisar
                            </a>
                            <a
                                className="ant-table-filter-dropdown-link clear"
                                onClick={() => handleReset(clearFilters)}
                            >
                                limpar
                            </a>
                        </div>
                    </div>
                );
            },
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : "" }} />,
            filteredValue: filterValues[`filtro_${dataIndex}`] || null,
        };
    };

    const handleSearch = (_selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };
    const getColumnSortProps = (dataIndex, sortedInfo) => ({
        sorter: true,
        sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
    });

    const { pagination } = filterValues;
    const sortedInfo = filterValues.sorter || {};

    const columns = [
        {
            title: "Código",
            dataIndex: "codigo",
            width: "15%",
            ...getColumnSearchProps("codigo"),
            ...getColumnSortProps("codigo", sortedInfo),
        },
        {
            title: "Descrição",
            dataIndex: "descricao_funcao",
            width: "20%",
            ...getColumnSearchProps("descricao_funcao"),
            ...getColumnSortProps("descricao_funcao", sortedInfo),
        },
        {
            title: "Grupo Superior Hierárquico",
            dataIndex: "funcao_superior",
            width: "20%",
            ...getColumnSearchProps("funcao_superior"),
            ...getColumnSortProps("funcao_superior", sortedInfo),
        },
        {
            title: "Missão",
            dataIndex: "missao",
            width: "25%",
            ...getColumnSearchProps("missao"),
            ...getColumnSortProps("missao", sortedInfo),
            render: (text) => {
                text = typeof text !== "object" ? text : "";
                return text.length >= 20 ? (
                    <Tooltip title={text}>
                        <div
                            style={{
                                textOverflow: "ellipsis",
                                width: " 200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            {text}
                        </div>
                    </Tooltip>
                ) : (
                    <div>{text}</div>
                );
            },
        },
        {
            title: "Estado",
            dataIndex: "estado",
            width: "10%",
            filters: [
                { text: "Ativo", value: "Ativo" },
                { text: "Inativo", value: "Inativo" },
            ],
            ...getcustomizedColumnSearchProps("estado"),
            ...getColumnSortProps("estado", sortedInfo),
            render: (text) =>
                typeof text !== "object" ? <Tag color={text === "Ativo" ? "green" : "red"}>{text}</Tag> : null,
        },
        {
            title: "Ação",
            width: "10%",
            render: (text, record) => (
                <Button
                    type="link"
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        color: "#1890ff",
                    }}
                    onClick={() => goto(`/function.manual/group/create/${record.id_ficha_funcao}`)}
                >
                    Editar
                </Button>
            ),
        },
    ];

    const handleTableChange = (_pagination, filters, sorter) => {
        let _filters = {};
        const obj = {
            pagination: {
                ...filterValues.pagination,
                currentPage: 1,
            },
        };
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) {
                    obj["filtro_" + key] = val;
                    _filters["filtro_" + key] = val;
                }
            });
        }
        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };

            _filters["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }
        setReportFilters(_filters);
        setFilterValues(obj);
    };

    const handleShowSizeChange = (_currentPage, currentPageSize) => {
        setFilterValues({
            ...filterValues,
            pagination: {
                currentPage: 1,
                currentPageSize,
            },
        });
    };

    const pageChange = (currentPage, currentPageSize) => {
        setFilterValues({
            ...filterValues,
            pagination: {
                currentPage,
                currentPageSize,
            },
        });
    };

    return (
        <>
            <Table
                rowKey={(record) => record.id_ficha_funcao}
                size="middle"
                loading={loadingData}
                columns={columns}
                pagination={false}
                dataSource={dataSource}
                onChange={handleTableChange}
            />
            <Row>
                <Col xs={24}>
                    <Pagination
                        current={pagination.currentPage}
                        defaultPageSize={pagination.currentPageSize}
                        size="small"
                        total={metadata.resultados}
                        showSizeChanger
                        showTotal={(total) => `Total registos: ${total}`}
                        onChange={pageChange}
                        onShowSizeChange={handleShowSizeChange}
                        showTitle
                        showLessItems={true}
                        defaultCurrent={1}
                        style={{ float: "right", marginTop: 16 }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default FunctionalGroupIndex;
