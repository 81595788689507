import { actionType } from "../constants/panel";
import moment from "moment";
import { sendHttpRequest } from "../../../components/http.request";
import { toArray } from "../../../components/util";

export const getDoneTasks = (payload) => (dispatch) => {
    const formData = new FormData();

    if (payload.filterParams !== undefined) {
        const beginDate =
            payload.filterParams.beginDate !== null &&
            payload.filterParams.beginDate !== undefined &&
            payload.filterParams.beginDate !== ""
                ? moment(payload.filterParams.beginDate).format("DD-MM-YYYY")
                : "";

        const endDate =
            payload.filterParams.endDate !== null &&
            payload.filterParams.endDate !== undefined &&
            payload.filterParams.endDate !== ""
                ? moment(payload.filterParams.endDate).format("DD-MM-YYYY")
                : "";

        const filtersParams = {
            filtro_nome_externo_processo: payload.filterParams.processName,
            filtro_processo: payload.filterParams.processNumber,
            filtro_informacao_adicional: payload.filterParams.aditionalInfo,
            filtro_inicio: beginDate,
            filtro_limite: endDate,
            filtro_nome_tarefa: payload.filterParams.taskName,
            filtro_tarefa: payload.filterParams.taskNumber,
            estabelecimento: payload.filterParams.establishment !== "" ? Number(payload.filterParams.establishment) : 1,
            ordenacao_id: payload.filterParams.order || "desc",
        };

        for (const key in filtersParams) {
            if (
                filtersParams[key] !== "" &&
                filtersParams[key] !== undefined &&
                filtersParams[key] !== null &&
                filtersParams[key] !== 0
            ) {
                formData.append(key, filtersParams[key]);
            }
        }
    }
    formData.append("accao", payload.accao);
    formData.append("pagina", payload.pagina);
    formData.append("resultados_pagina", payload.resultados_pagina);

    if (payload.filterParams === undefined) formData.append("estabelecimento", 1);
    return sendHttpRequest("POST", "/Gestor/gerepainel.php", formData).then((data) => {
        let payload = {
            data: [],
            attributes: {},
        };

        if (data.linha !== undefined) {
            if (Number(data["@attributes"].resultados) === 1) payload.data.push(data.linha);
            else payload.data = toArray(data.linha);
        }
        if (data["@attributes"]) {
            payload.attributes = data["@attributes"];
        }
        dispatch({ type: actionType.getDoneTasks, payload });
    });
};

export const getDoingTasks = (payload) => (dispatch) => {
    const formData = new FormData();
    if (payload.filterParams !== undefined) {
        const beginDate =
            payload.filterParams.beginDate !== null &&
            payload.filterParams.beginDate !== undefined &&
            payload.filterParams.beginDate !== ""
                ? moment(payload.filterParams.beginDate).format("DD-MM-YYYY")
                : "";

        const endDate =
            payload.filterParams.endDate !== null &&
            payload.filterParams.endDate !== undefined &&
            payload.filterParams.endDate !== ""
                ? moment(payload.filterParams.endDate).format("DD-MM-YYYY")
                : "";

        const filtersParams = {
            filtro_nome_externo_processo: payload.filterParams.processName,
            filtro_processo: payload.filterParams.processNumber,
            filtro_informacao_adicional: payload.filterParams.aditionalInfo,
            filtro_inicio: beginDate,
            filtro_limite: endDate,
            filtro_tarefa: payload.filterParams.taskNumber,
            filtro_nome_tarefa: payload.filterParams.taskName,
            estabelecimento: payload.filterParams.establishment !== "" ? Number(payload.filterParams.establishment) : 1,
            ordenacao_id: payload.filterParams.order || "desc",
        };

        for (const key in filtersParams) {
            if (filtersParams[key]) {
                formData.append(key, filtersParams[key]);
            }
        }
    }
    formData.append("accao", payload.accao);
    formData.append("pagina", payload.pagina);
    formData.append("resultados_pagina", payload.resultados_pagina);
    if (payload.filterParams === undefined) formData.append("estabelecimento", 1);
    return sendHttpRequest("POST", "/Gestor/gerepainel.php", formData).then((data) => {
        let payload = {
            data: [],
            attributes: {},
        };
        if (data.linha !== undefined) {
            if (Number(data["@attributes"].resultados) === 1) payload.data.push(data.linha);
            else payload.data = toArray(data.linha);
        }
        if (data["@attributes"]) {
            payload.attributes = data["@attributes"];
        }
        dispatch({ type: actionType.getDoingTasks, payload });
    });
};

export const getTodoTasks = (payload) => (dispatch) => {
    const formData = new FormData();
    if (payload.filterParams !== undefined) {
        const beginDate =
            payload.filterParams.beginDate !== null &&
            payload.filterParams.beginDate !== undefined &&
            payload.filterParams.beginDate !== ""
                ? moment(payload.filterParams.beginDate).format("DD-MM-YYYY")
                : "";

        const endDate =
            payload.filterParams.endDate !== null &&
            payload.filterParams.beginDate !== undefined &&
            payload.filterParams.endDate !== ""
                ? moment(payload.filterParams.endDate).format("DD-MM-YYYY")
                : "";

        const filtersParams = {
            filtro_nome_externo_processo: payload.filterParams.processName,
            filtro_processo: payload.filterParams.processNumber,
            filtro_informacao_adicional: payload.filterParams.aditionalInfo,
            filtro_inicio: beginDate,
            filtro_limite: endDate,
            filtro_nome_tarefa: payload.filterParams.taskName,
            filtro_tarefa: payload.filterParams.taskNumber,
            estabelecimento: payload.filterParams.establishment !== "" ? Number(payload.filterParams.establishment) : 1,
            ordenacao_id: payload.filterParams.order || "desc",
        };

        for (const key in filtersParams) {
            if (
                filtersParams[key] !== "" &&
                filtersParams[key] !== undefined &&
                filtersParams[key] !== null &&
                filtersParams[key] !== 0
            ) {
                formData.append(key, filtersParams[key]);
            }
        }
    }
    formData.append("accao", payload.accao);
    formData.append("pagina", payload.pagina);
    formData.append("resultados_pagina", payload.resultados_pagina);

    if (payload.filterParams === undefined) formData.append("estabelecimento", 1);
    return sendHttpRequest("POST", "/Gestor/gerepainel.php", formData).then((data) => {
        let payload = {
            data: [],
            attributes: {},
        };
        if (data.linha !== undefined) {
            if (Number(data["@attributes"].resultados) === 1) payload.data.push(data.linha);
            else payload.data = toArray(data.linha);
        }
        if (data["@attributes"]) {
            payload.attributes = data["@attributes"];
        }
        dispatch({ type: actionType.getTodoTasks, payload });

        return data;
    });
};

export const getTasksToReassign = (payload) => (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "lista_reatribuicoes");
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
        dispatch({
            type: actionType.getTasksToReassign,
            payload: data.linha,
        });
    });
};

export const getProcessesToCancel = (payload) => (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "lista_proc_anular");
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
        dispatch({
            type: actionType.setProcessesToCancelDb,
            payload: data.linha,
        });
    });
};

export const getGroupsToDelegate = (payload) => (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "lista_delegacoes");
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
        dispatch({
            type: actionType.getGroupsToDelegate,
            payload: data.grupos.linha,
        });

        dispatch({
            type: actionType.getTasksToDelegate,
            payload: data.delegar.linha,
        });
    });
};

export const getUserByGroup = (payload) => (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "utilizadores_grupo");
    formData.append("grupo", payload.group);
    return sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData).then((data) => {
        dispatch({
            type: actionType.getUsersByGroup,
            payload: {
                group: payload.group,
                users: data.linha,
                task: payload.task,
            },
        });
    });
};

export const annulmentProcess = (ids = [], accept) => {
    const formData = new FormData();
    formData.append("accao", accept ? "anular" : "anular_rejeitar");
    formData.append("processo", ids.join(";"));
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
};

export const reassignTasks = (tasks) => {
    const tasksToReassign = tasks.map((el) => ({
        id: el.id_tarefa_reatribuida,
        utilizador: el.user,
        tarefa: el.id_tarefa,
    }));

    const formData = new FormData();
    formData.append("accao", "reatribuir");
    formData.append("tarefas", JSON.stringify(tasksToReassign));
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
};

export const delegateTasks = (tasks) => {
    const tasksToDelegate = tasks.map((el) => ({
        id: el.id,
        utilizador: el.user,
    }));

    const formData = new FormData();
    formData.append("accao", "delegar");
    formData.append("lista", JSON.stringify(tasksToDelegate));
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData);
};

export const getTaskReassignmentHistory =
    ({ tarefa, tab }) =>
    (dispatch) => {
        const formData = new FormData();
        formData.append("accao", "historico_reatribuicoes");
        formData.append("tarefa", tarefa);
        return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((response) => {
            //TODO: review this condition
            if (Object.keys(response).length === 0) {
                dispatch({
                    type: actionType.getTaskReassignmentHistory,
                    payload: { isReassigned: false, tarefa, tab },
                });
            } else {
                dispatch({
                    type: actionType.getTaskReassignmentHistory,
                    payload: { isReassigned: true, tarefa, tab },
                });
            }
        });
    };
