import { PageHeader, Row, Col, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProcessFilter from "./filter";
import { getFieldDataSource } from "../../process_execution/actions/index";
import { useDispatch } from "react-redux";
import { getProcesses } from "../actions";
import IntegactionGraph from "./graph";
import getGraphData from "../utils/getGraphData";
import { Utilitaries } from "../../../business";
import { toArray } from "../../../components/util";

const ProcessInteraction = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [macroProcesses, setMacroProcesses] = useState([]);
    const [mesoProcesses, setMesoProcesses] = useState([]);
    const [processes, setProcesses] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [selectedMesoProcess, setSelectedMesoProcess] = useState(null);
    const [selectedMacroProcess, setSelectedMacroProcess] = useState(null);
    const [selectedProcess, setSelectedProcess] = useState(null);

    // Get Macro Process
    useEffect(() => {
        dispatch(getFieldDataSource({ dataSource: "chama_macro_sistema" })).then(({ result }) => {
            setMacroProcesses(result);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Get Meso Process
    useEffect(() => {
        if (selectedMacroProcess) {
            const params = {
                param1: selectedMacroProcess,
            };
            dispatch(getFieldDataSource({ dataSource: "param_categorias_processo", params })).then(({ result }) => {
                setMesoProcesses(result);
            });
        } else {
            setMesoProcesses([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMacroProcess]);

    // useEffect(() => {
    //     if (selectedProcess) {
    //         const item = processes.find((proc) => proc.id === selectedProcess);
    //         const data = getGraphData(item);
    //         setGraphData(data);
    //     } else {
    //         setGraphData([]);
    //     }
    // }, [selectedProcess]);

    useEffect(() => {
        getProcesses(selectedMacroProcess, selectedMesoProcess).then((result) => {
            setProcesses(result);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMacroProcess, selectedMesoProcess]);

    const changeMacroProcess = (value) => {
        setSelectedMacroProcess(value);
    };
    const changeMesoProcess = (value) => {
        setSelectedMesoProcess(value);
    };
    const changeProcess = (value) => {
        setSelectedProcess(value);
    };

    const processGraphData = () => {
        let items = [];
        if (selectedProcess) {
            items = processes.find((proc) => proc.id === selectedProcess);
        } else {
            items = processes;
        }
        // setGraphData([]);
        const data = getGraphData(items);
        setGraphData(data);
    };

    return (
        <>
            <PageHeader title="Interação de processos" onBack={() => history.goBack()} />

            <Row style={{ marginBottom: 5 }}>
                <Col xs={24} md={20}>
                    <ProcessFilter
                        selectedMacroProcess={selectedMacroProcess}
                        selectedMesoProcess={selectedMesoProcess}
                        selectedProcess={selectedProcess}
                        macroProcesses={macroProcesses}
                        mesoProcesses={mesoProcesses}
                        processes={processes}
                        changeMacroProcess={changeMacroProcess}
                        changeMesoProcess={changeMesoProcess}
                        changeProcess={changeProcess}
                        processGraphData={processGraphData}
                    />
                </Col>
                <Col xs={24} md={24}>
                    {toArray(graphData).length > 0 && <IntegactionGraph data={graphData} />}
                </Col>
            </Row>
        </>
    );
};

export default ProcessInteraction;
