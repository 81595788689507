import React, { useState } from "react";
import { Row, Col, Form, Badge, Icon, Button, Dropdown, Input, Table, Menu, message, Pagination } from "antd";

import { body } from "./constants";
import MessageDetails from "./view";

const ButtonGroup = Button.Group;

const List = (props) => {
    const [selectedRow, setSelectedRow] = useState([]);
    const [visibleShowDetails, setVisibleShowDetails] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState({});

    const handleMenuClick = (e) => {
        if (selectedRow.length === 0) {
            message.error("Selecione alguma linha antes.");
            return;
        }

        props.onMoveToNewFolder(selectedRow.join(";"), e.key, (success) => {
            if (success) {
                setSelectedRow([]);
            }
        });
    };

    const menuDrop = (
        <Menu onClick={handleMenuClick}>
            {props.folders.map((item) => (
                <Menu.Item key={item.id}>{item.nome}</Menu.Item>
            ))}
        </Menu>
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                if (!values.search || values.search == "") {
                    message.info("Escreva o termo de pesquisa.");
                    return;
                }

                props.search(values.search);
            }
        });
    };

    const rowSelection = {
        selectedRowKeys: selectedRow,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    const changeState = (to) => {
        if (selectedRow.length === 0) {
            message.error("Selecione alguma linha antes.");
            return;
        }

        props.onChangeState(to, selectedRow.join(";"));

        // if (to === "eliminar") {
        setSelectedRow([]);
        // }
    };

    const showMessageDetails = (message) => {
        setSelectedMessage(message);
        setVisibleShowDetails(true);
    };

    const columns = (viewDetails) => [
        {
            title: props.isSendEmailPage ? "Destinatários" : "Remetente",
            dataIndex: "utilizador",
            key: "name",
            ellipsis: true,
        },
        {
            title: "Assunto",
            dataIndex: "assunto",
            key: "assunto",
            render: (assundo, item) =>
                item.estado === "0" && !props.isSendEmailPage ? (
                    <b onClick={() => viewDetails(item)} style={{ cursor: "pointer" }}>
                        {
                            <>
                                {item.anexos === "t" && <Icon type="link" />} <Badge status="error" /> {assundo}
                            </>
                        }
                    </b>
                ) : (
                    <span onClick={() => viewDetails(item)} style={{ cursor: "pointer" }}>
                        {item.anexos === "t" && <Icon type="link" />} {assundo}
                    </span>
                ),
        },
        {
            title: "Data",
            dataIndex: "data_str",
            key: "data_str",
        },
        {
            title: "Ação",
            key: "action",
            render: (text, record) => <Icon type="delete" onClick={() => props.delete(record.id)} />,
        },
    ];

    const { getFieldDecorator } = props.form;

    return (
        <>
            <MessageDetails
                getBadge={props.getBadge}
                item={selectedMessage}
                visible={visibleShowDetails}
                onCancel={setVisibleShowDetails}
                changeLocalStatus={props.changeLocalStatus}
                type={props.isSendEmailPage ? "Enviada" : "Recebida"}
                forward={props.forward}
                reply={props.reply}
            />
            <Row type="flex" justify="space-between">
                <Col span={9}>
                    <Form layout="inline" onSubmit={handleSubmit}>
                        <Form.Item>{getFieldDecorator("search", {})(<Input placeholder="" />)}</Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Pesquisar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={15} style={{ marginTop: 5 }}>
                    <ButtonGroup style={{ marginLeft: 5 }}>
                        <Button onClick={() => props.changeToPage(body.folder)}>Pastas</Button>
                    </ButtonGroup>

                    <ButtonGroup style={{ marginLeft: 5 }}>
                        <Button onClick={() => changeState("marcar_lida")}>Marcar como lida</Button>
                        <Button onClick={() => changeState("marcar_nao_lida")}>Marcar como não lida</Button>
                        <Dropdown overlay={menuDrop} overlayStyle={{ overflowY: "auto", maxHeight: "90vh" }}>
                            <Button>Mover para</Button>
                        </Dropdown>
                    </ButtonGroup>

                    <ButtonGroup style={{ marginLeft: 5 }}>
                        <Button onClick={() => changeState("eliminar")}>Eliminar</Button>
                    </ButtonGroup>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                    <Table
                        rowKey="id"
                        loading={props.loading}
                        size="small"
                        pagination={false}
                        rowSelection={rowSelection}
                        columns={columns(showMessageDetails)}
                        dataSource={props.data}
                    />

                    <Pagination
                        style={{ float: "right", marginBottom: 10, marginTop: 10 }}
                        defaultCurrent={props.info.current}
                        defaultPageSize={10}
                        size="small"
                        total={props.info.total}
                        showSizeChanger
                        showTotal={(total) => `Total registos: ${total}`}
                        onShowSizeChange={(current, pageSize) => {
                            props.pageChange(current, pageSize);
                        }}
                        onChange={(current, pageSize) => {
                            props.pageChange(current, pageSize);
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Form.create({ name: "form_message_search" })(List);
