import { actionType, lista } from "../constants/holiday";
import { sendHttpRequest } from "../../../components/http.request";
import moment from "moment";
const formatDate = "DD-MM-YYYY";

export const select = (payload) => async (dispatch) => {
    const formData = new FormData();

    formData.append("accao", "lista");
    formData.append("lista", lista);
    formData.append("resultados_pagina", 0);

    Object.entries(payload).forEach(([key, value]) => {
        formData.append(key, value);
    });

    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse) {
            let list = [];
            if (Number(jsonResponse.dados["@attributes"].resultados) > 1) list = [...jsonResponse.dados["linha"]];
            else if (Number(jsonResponse.dados["@attributes"].resultados) === 1) list = [jsonResponse.dados["linha"]];

            jsonResponse.dados.linha = list.map((item, index) => {
                const data = moment(item.data, "YYYY-MM-DD");
                return { ...item, data: data.format(formatDate) };
            });
            dispatch({ type: actionType.select, payload: jsonResponse });
        }
    });
};

export const addNewDay = (payload) => ({ type: actionType.addNewDay, payload });

export const setFieldList = (payload) => ({
    type: actionType.setFieldList,
    payload,
});
export const setField = (payload) => ({ type: actionType.setField, payload });
export const removeDay = (payload) => ({
    type: actionType.removeDayFromHoliday,
    payload,
});

export const importHoliday = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista);
    formData.append("chave1", payload.id);
    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        dispatch({
            type: actionType.importHoliday,
            payload: { ano: payload.ano, importedHolidays: jsonResponse },
        });
    });
};

export const getFormulario = () => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista);
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        dispatch({ type: actionType.getFormulario, payload: jsonResponse });
        return true;
    });
};

export const insert = (payload) => async (dispatch) => {
    var listHolidays = [];

    payload.values.holidays.forEach((item) => {
        const holiday = {
            data: item.data,
            designacao: item.designacao,
            id_natureza: item.natureza,
        };

        item.estabelecimentos.forEach((item) => {
            listHolidays.push({ ...holiday, estabs: item });
        });
    });
    const obj = {};
    const data = moment(new Date());
    obj[payload.formulario.elemento_raiz] = {
        ano: payload.values.year,
        data: data.format("YYYY-MM-DD"),
        descricao: payload.values.descricao,
        linhas_feriados: { linha_feriado: listHolidays },
    };
    const formData = new FormData();
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario.nome);
    formData.append("dados", JSON.stringify(obj));
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            dispatch({ type: actionType.insert });
            return true;
        } else {
            return false;
        }
    });
};

export const getDetails = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista);
    formData.append("chave1", payload.id);
    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        dispatch({ type: actionType.getDetails, payload: jsonResponse });
    });
};

export const update = (payload) => async (dispatch) => {
    var listHolidays = [];
    payload.values.holidays.forEach((item) => {
        const holiday = {
            data: item.data,
            designacao: item.designacao,
            id_natureza: item.natureza,
        };
        item.estabelecimentos.forEach((itemEstabelecimento) => {
            // get and add linha
            const objHoliday = {
                ...holiday,
                estabs: itemEstabelecimento,
            };
            const index = item.initialEstabelecimentos.findIndex((d) => d.id_estabelecimento === itemEstabelecimento);

            if (index >= 0) objHoliday.id_linha_feriado = item.initialEstabelecimentos[index].id_linha_feriado;

            listHolidays.push(objHoliday);
        });
    });
    const obj = {};
    obj[payload.formulario.elemento_raiz] = {
        id_config_feriado: payload.values.id,
        ano: payload.values.year,
        data: payload.values.data.value,
        descricao: payload.values.descricao,
        motivo: payload.values.motivo,
        linhas_feriados: { linha_feriado: listHolidays },
    };
    const formData = new FormData();
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario.nome);
    formData.append("dados", JSON.stringify(obj));
    if (payload.values.motivo && payload.values.motivo !== "")
        formData.append("motivo_alteracao", payload.values.motivo);

    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            dispatch({ type: actionType.update });
            return true;
        } else {
            return false;
        }
    });
};
