import React, { Component } from "react";
import { Tabs, Icon, PageHeader, Button, Form, Select, Dropdown, Row, Col, Menu } from "antd";
import queryString from "query-string";
import { connect } from "react-redux";

import Organograma from "./functional_group/organograma";
import FunctionalGroupIndex from "./functional_group/index";
import ContributorsIndex from "./contributors/index";
import ListCollectiveAgreement from "./collective_agreement/index";
import ButtonsGroup from "./buttons.group";
import { exportToFile } from "../actions/export.to.file";
import { downloadDocument } from "../../process_execution/actions/execution";
import { downloadFile } from "../../../components/util";

const { TabPane } = Tabs;
const { Option } = Select;

const REPORT_PARAMS = {
    GF: {
        accao: "funcoes",
        campos: "codigo:120:Código do Grupo;descricao_funcao:248:Descrição do Grupo Funcional;funcao_superior:248:Grupo Superior Hierárquico;missao:546:Missão;estado:80:Estado;",
        titulo: "Funções",
        endPoint: "geremanualfuncoes.php",
    },
    FUNC: {
        accao: "colaboradores",
        campos: "nome:669:Nome;tipo:100:Tipo;username:150:Utilizador;status:80:Estado;",
        titulo: "Funcionários/as",
        endPoint: "geremanualfuncoes.php",
    },
    CC: {
        accao: "convencoes",
        campos: "nome:912:Nome;data:200:Data da Convenção;em_vigor:80:Vigor;",
        titulo: "Convenções coletivas",
        endPoint: "geremanualfuncoes.php",
    },
};
class FunctionManualIndex extends Component {
    state = {
        vistaForma: 0,
        activeKey: "1",
        filterValues: {
            pagination: { currentPage: 1, currentPageSize: 10 },
            sorter: {
                columnKey: "codigo",
                order: "ascend",
            },
        },
        filterValuesFunc: {
            pagination: { currentPage: 1, currentPageSize: 10 },
            sorter: {},
        },
        showInativesGrupos: false,
        showInativesFunc: false,
        filter: {},
    };

    goto = (path) => {
        this.props.history.push({
            pathname: path,
        });
    };

    activeTabPaneKey = (key) => {
        return key === "" ? "1" : key;
    };

    setFilterValues = (filterValues) => {
        this.setState({ filterValues });
    };

    setFilterValuesFunc = (filterValuesFunc) => {
        this.setState({ filterValuesFunc });
    };

    clearFormFilters = () => {
        this.setState({
            filterValues: {
                pagination: { currentPage: 1, currentPageSize: 10 },
            },
            showInatives: false,
        });
    };

    setShowInatives = () => {
        const { showInativesGrupos, showInativesFunc } = this.state;

        if (this.state.activeKey === "1") {
            this.setState({
                showInativesGrupos: !showInativesGrupos,
            });
        } else {
            this.setState({
                showInativesFunc: !showInativesFunc,
            });
        }
    };

    downloadDocument = (nome) => {
        downloadFile("/gererelatorios.php", {
            accao: "geras03",
            nome,
            downloadInBrowser: true,
        });
    };

    conceptsList = (
        <Menu>
            <Menu.Item onClick={(e) => this.downloadDocument("ListaTodosTiposConhecimentos.jasper")}>
                <Button type="link" key="conhecimento">
                    Conhecimentos
                </Button>
            </Menu.Item>

            <Menu.Item onClick={(e) => this.downloadDocument("ListaTodosTiposCompetencias.jasper")}>
                <Button type="link" key="competencia">
                    Competências
                </Button>
            </Menu.Item>
        </Menu>
    );

    setReportFilters = (filter) => {
        this.setState({ filter });
    };

    render() {
        const values = queryString.parse(this.props.location.search);
        return (
            <>
                <PageHeader
                    title="Manual de funções"
                    extra={[
                        <Dropdown overlay={this.conceptsList}>
                            <Button type="link" key="3">
                                <Icon type="question-circle" />
                                Conceitos
                            </Button>
                        </Dropdown>,
                    ]}
                />
                <Tabs
                    defaultActiveKey={this.props.match.params.activeKey ? this.props.match.params.activeKey : "1"}
                    onTabClick={(key) => this.setState({ activeKey: key })}
                >
                    <TabPane tab="Grupos funcionais" key="1">
                        <Row gutter={16}>
                            <Col xs={24} sm={8}>
                                <Form.Item label="Vista">
                                    <Select
                                        style={{ width: "100%" }}
                                        defaultValue={this.state.vistaForma}
                                        onChange={(value) => this.setState({ vistaForma: value })}
                                    >
                                        <Option value={0}>Tabela</Option>
                                        <Option value={1}>Organograma</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24}>
                                {this.state.vistaForma === 0 ? (
                                    <div>
                                        <ButtonsGroup
                                            filter={this.state.filter}
                                            REPORT_PARAMS={REPORT_PARAMS}
                                            activeTabPaneKey={this.activeTabPaneKey.bind(this)}
                                            tabControl="GF"
                                            goto={this.goto.bind(this)}
                                            filterValues={this.state.filterValues}
                                            clearFormFilters={this.clearFormFilters.bind(this)}
                                            setShowInatives={this.setShowInatives.bind(this)}
                                            showInatives={this.state.showInativesGrupos}
                                        />

                                        <FunctionalGroupIndex
                                            goto={this.goto.bind(this)}
                                            setFilterValues={this.setFilterValues.bind(this)}
                                            filterValues={this.state.filterValues}
                                            showInatives={this.state.showInativesGrupos}
                                            setReportFilters={this.setReportFilters}
                                        />
                                    </div>
                                ) : (
                                    <Organograma></Organograma>
                                )}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Funcionários" key="2">
                        <ButtonsGroup
                            filter={this.state.filter}
                            REPORT_PARAMS={REPORT_PARAMS}
                            activeTabPaneKey={this.activeTabPaneKey.bind(this)}
                            tabControl="FUNC"
                            goto={this.goto.bind(this)}
                            setShowInatives={this.setShowInatives.bind(this)}
                            showInatives={this.state.showInativesFunc}
                        />
                        <ContributorsIndex
                            goto={this.goto.bind(this)}
                            setFilterValues={this.setFilterValuesFunc.bind(this)}
                            filterValues={this.state.filterValuesFunc}
                            activeTabPaneKey={this.activeTabPaneKey.bind(this)}
                            setReportFilters={this.setReportFilters.bind(this)}
                            showInatives={this.state.showInativesFunc}
                        />
                    </TabPane>
                    <TabPane tab="Convenções coletivas" key="3">
                        <ButtonsGroup
                            filter={this.state.filter}
                            REPORT_PARAMS={REPORT_PARAMS}
                            activeTabPaneKey={this.activeTabPaneKey.bind(this)}
                            tabControl="CC"
                            goto={this.goto.bind(this)}
                        />
                        <ListCollectiveAgreement
                            goTo={this.goto.bind(this)}
                            reload={values.reload ? values.reload : false}
                            setReportFilters={this.setReportFilters.bind(this)}
                        />
                    </TabPane>
                </Tabs>
            </>
        );
    }
}

const mapDispatchToProps = {
    exportToFile,
};

// const mapStateToProps = state => {
//   return {
//     userState: Object.values(state.user.domain.byKey).filter(
//       user => user.type === "internal"
//     )
//   };
// };

// export default connect(
//   mapStateToProps,
//   null
// )(FunctionManualIndex);

export default connect(null, mapDispatchToProps)(FunctionManualIndex);
