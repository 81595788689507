import React from "react";
import { Avatar, Tag } from "antd";

const content = (lembreteVisualizationList = [], currentLembreteID, showVisualization) => {
    let list = lembreteVisualizationList;

    if (list.length === 0) {
        return <div>Sem utilizadores</div>;
    } else if (list.length === 1) {
        if (parseInt(list[0].estado) === 1) {
            // return (<Avatar
            //     key={"view-" + currentLembreteID}
            //     style={{
            //         backgroundColor: "green"
            //     }}
            // >
            //     {list[0].username}
            // </Avatar>)
            return (
                <Tag color={"green"} onClick={() => showVisualization(currentLembreteID, 1)}>
                    {list[0].username}
                </Tag>
            );
        } else {
            // return (<Avatar
            //     key={"view-" + currentLembreteID}
            //     style={{
            //         backgroundColor: "red"
            //     }}
            // >
            //     {list[0].username}
            // </Avatar>)
            return (
                <Tag color={"red"} onClick={() => showVisualization(currentLembreteID, 2)}>
                    {list[0].username}
                </Tag>
            );
        }
    } else {
        // const view = list.filter((item) => parseInt(item.estado) === 1);
        // const notView = list.filter((item) => parseInt(item.estado) === 0);

        let items = [];

        // if (view.length > 0) {
        items.push(
            <Avatar
                key={"view-" + currentLembreteID}
                // style={{
                //     backgroundColor: "green",
                // }}
                onClick={() => showVisualization(currentLembreteID, "")}
            >
                {`+ ${list.length}`}
            </Avatar>
        );
        // }

        // if (notView.length > 0) {
        //     items.push(
        //         <Avatar
        //             key={"notview-" + currentLembreteID}
        //             style={{
        //                 backgroundColor: "red",
        //                 // marginLeft: -15
        //             }}
        //             onClick={() => showVisualization(currentLembreteID, 2)}
        //         >
        //             {notView.length}
        //         </Avatar>
        //     );
        // }

        return items;
    }
};

export default content;
