import React, { useEffect } from "react";
import { Button, Col, Form, Icon, Input, PageHeader, Row, Table, Typography } from "antd";
import {
    ContributorsProps,
    ContributorsPropsTimeRegistration,
    getContributors,
    getContributorsTimeRegistration,
} from "../../../../../V2/actions/user";
import { GenericObject } from "../../../../../V2/types/common";
import QATable from "../../../../../V2/components/Table";
import { Utilitaries } from "../../../../../business";
import DetailsContributorsTimeRegistration from "../details.time.registration";
import MenuOptionsToGeneratePdfReport from "../report.options";

interface TimeRegistrationDetalsProps {
    show: boolean;
    data?: ContributorsPropsTimeRegistration;
}

export function ListagemTimeRegistration() {
    const [fecthingContributors, setFecthingContributors] = React.useState(true);
    const [contributors, setContributors] = React.useState<ContributorsProps[]>([]);
    const [contributorsFilter, setContributorsFilter] = React.useState<GenericObject>({});
    const [paginationContributors, setPaginationContributors] = React.useState({
        pageSize: 10,
        current: 1,
        total: 0,
    });

    const [fecthingContributorsTimeRegistration, setFecthingContributorsTimeRegistration] = React.useState(false);
    const [contributorsTimeRegistration, setContributorsTimeRegistration] = React.useState<any[]>([]);
    const [contributorsTimeRegistrationFilter, setContributorsTimeRegistrationFilter] = React.useState<GenericObject>(
        {}
    );
    const [paginationContributorsTimeRegistration, setPaginationContributorsTimeRegistration] = React.useState({
        pageSize: 10,
        current: 1,
        total: 0,
    });

    const [selectedContributor, setSelectedContributor] = React.useState<ContributorsProps>();
    const [showDrawer, setShowDrawer] = React.useState(false);
    const [timeRegistrationDetals, setTimeRegistrationDetals] = React.useState<TimeRegistrationDetalsProps>({
        show: false,
        data: undefined,
    });

    useEffect(() => {
        fecthContributors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contributorsFilter, paginationContributors.current, paginationContributors.pageSize]);

    useEffect(() => {
        fecthContributorsTimeRegistration(selectedContributor?.username ?? "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        contributorsTimeRegistrationFilter,
        paginationContributorsTimeRegistration.current,
        paginationContributorsTimeRegistration.pageSize,
    ]);

    const onUserRowClick = (record: ContributorsProps) => {
        setSelectedContributor(record);
        fecthContributorsTimeRegistration(record.username);
    };

    const fecthContributors = async () => {
        setFecthingContributors(true);
        let queryParams: GenericObject = {
            pagina: paginationContributors.current,
            resultados_pagina: paginationContributors.pageSize,
        };

        const { sorter, ...objFilter } = contributorsFilter;
        if (sorter) {
            queryParams["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }
        Object.entries(objFilter).forEach(([key, val]) => {
            queryParams[key] = val;
        });

        const { contributors, total } = await getContributors(queryParams);
        setContributors(contributors);
        setPaginationContributors({
            ...paginationContributors,
            total,
        });
        setFecthingContributors(false);
    };

    const fecthContributorsTimeRegistration = async (username: string) => {
        setFecthingContributorsTimeRegistration(true);
        let queryParams: GenericObject = {
            username,
            pagina: paginationContributorsTimeRegistration.current,
            resultados_pagina: paginationContributorsTimeRegistration.pageSize,
        };

        const { sorter, ...objFilter } = contributorsTimeRegistrationFilter;
        if (sorter) {
            queryParams["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }
        Object.entries(objFilter).forEach(([key, val]) => {
            queryParams[key] = val;
        });

        const { contributorsTimeRegistration, total } = await getContributorsTimeRegistration(queryParams);
        setContributorsTimeRegistration(contributorsTimeRegistration);
        setPaginationContributorsTimeRegistration({
            ...paginationContributorsTimeRegistration,
            total,
        });
        setFecthingContributorsTimeRegistration(false);
    };

    const handleContributorsTableChange = (_pagination: any, filters: any, sorter: any) => {
        const obj: GenericObject = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }

        if (sorter.columnKey && sorter?.order) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPaginationContributors({
            ...paginationContributors,
            current: 1,
        });
        setContributorsFilter(obj);
    };

    const handleContributorsTimeRegistrationTableChange = (_pagination: any, filters: any, sorter: any) => {
        const obj: GenericObject = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }

        if (sorter.columnKey && sorter?.order) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPaginationContributorsTimeRegistration({
            ...paginationContributors,
            current: 1,
        });
        setContributorsTimeRegistrationFilter(obj);
    };

    const getColumnSearchProps = (dataIndex: string, filterInfo: any) => {
        let searchInput = React.createRef<any>();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys}
                        onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: any) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
            filteredValue: filterInfo?.[`filtro_${dataIndex}`] || null,
        };
    };

    const handleSearch = (selectedKeys: any, confirm: any) => {
        confirm();
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
    };

    const getColumnSortProps = (dataIndex: string, sortedInfo: any) => {
        return {
            sorter: true,
            sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
        };
    };

    const contributorsColumn = [
        {
            title: "Nome",
            dataIndex: "nome",
            width: "100%",
            ...getColumnSearchProps("nome", contributorsFilter),
            ...getColumnSortProps("nome", contributorsFilter.sorter || {}),
            render: (text: string, record: ContributorsProps) => {
                return record.username === selectedContributor?.username ? (
                    <Typography.Text strong>{text}</Typography.Text>
                ) : (
                    <Typography.Text>{text}</Typography.Text>
                );
            },
        },
    ];

    const showDetailsTimeRegistration = (record: ContributorsPropsTimeRegistration) => {
        setTimeRegistrationDetals({
            show: true,
            data: {
                ...record,
            },
        });
    };

    const pointRegistryColumn = [
        {
            title: "Data / Hora do registo",
            dataIndex: "system_data_entrada",
            ...getColumnSearchProps("system_data_entrada", contributorsTimeRegistrationFilter),
            ...getColumnSortProps("system_data_entrada", contributorsTimeRegistrationFilter.sorter || {}),
            render: (text: string, record: ContributorsPropsTimeRegistration) => {
                return !text ? (
                    <span>{`${record.data_registo} ${record.user_data_entrada}`}</span>
                ) : (
                    <span>{text}</span>
                );
            },
        },
        {
            title: "Data / Hora Entrada",
            dataIndex: "user_data_entrada",
            ...getColumnSearchProps("user_data_entrada", contributorsTimeRegistrationFilter),
            ...getColumnSortProps("user_data_entrada", contributorsTimeRegistrationFilter.sorter || {}),
            render: (text: string, record: ContributorsPropsTimeRegistration) => {
                if (!record.system_data_entrada)
                    return <span>{`${record.data_dia} ${Utilitaries.toString(text)}`}</span>;
                else
                    return (
                        <div>
                            {record.system_data_entrada === record.user_data_entrada ? (
                                <span>{text}</span>
                            ) : (
                                <span style={{ color: "#1890ff" }}>{text}</span>
                            )}
                        </div>
                    );
            },
        },
        {
            title: "Data / Hora Saída",
            dataIndex: "user_data_saida",
            ...getColumnSearchProps("user_data_saida", contributorsTimeRegistrationFilter),
            ...getColumnSortProps("user_data_saida", contributorsTimeRegistrationFilter.sorter || {}),
            render: (text: string, record: ContributorsPropsTimeRegistration) => {
                if (!record.system_data_saida)
                    return <span>{record.data_dia ? `${record.data_dia} ${Utilitaries.toString(text)}` : ""}</span>;
                else
                    return (
                        <div>
                            {record.system_data_saida === record.user_data_saida ? (
                                <span>{text}</span>
                            ) : (
                                <span style={{ color: "#1890ff" }}>{text}</span>
                            )}
                        </div>
                    );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            align: "center",
            render: (text: string, record: ContributorsPropsTimeRegistration) => (
                <Button onClick={() => showDetailsTimeRegistration(record)} type="link">
                    Detalhes
                </Button>
            ),
        },
    ];

    return (
        <>
            {showDrawer && <MenuOptionsToGeneratePdfReport show={showDrawer} hidde={() => setShowDrawer(false)} />}

            {timeRegistrationDetals.show && (
                <DetailsContributorsTimeRegistration
                    isVisible={timeRegistrationDetals.show}
                    detailsData={timeRegistrationDetals.data}
                    closeModal={() => setTimeRegistrationDetals({ show: false, data: undefined })}
                />
            )}
            <PageHeader title="Listagem de Registo de Ponto" />
            <Row gutter={20}>
                <Col xs={24} sm={8}>
                    <QATable
                        columns={contributorsColumn}
                        data={contributors}
                        loadingData={fecthingContributors}
                        size="small"
                        datakey={"id"}
                        currentPage={paginationContributors.current ?? 1}
                        totalResult={paginationContributors.total ?? 1}
                        pageChange={(current: number, pageSize?: number) => {
                            setPaginationContributors({
                                ...paginationContributors,
                                current,
                                pageSize: pageSize ?? paginationContributors.pageSize,
                            });
                        }}
                        onRowClick={(record) => {
                            onUserRowClick(record);
                        }}
                        tableChange={handleContributorsTableChange}
                    />
                </Col>
                <Col xs={24} sm={16}>
                    <Typography.Text>
                        <b>Registo de Ponto</b>
                    </Typography.Text>

                    <Row gutter={20}>
                        <Form layout="vertical">
                            <Col xs={16}>
                                <Form.Item label="Nome">
                                    <Input value={selectedContributor?.nome} readOnly />
                                </Form.Item>
                            </Col>
                            <Col xs={8}>
                                <Form.Item label="Gerar Relatório">
                                    <Button
                                        type="primary"
                                        onClick={() => setShowDrawer(true)}
                                        style={{
                                            marginBottom: 16,
                                        }}
                                    >
                                        <Icon type="file-pdf" style={{ marginRight: 12 }} />
                                        Exportar para pdf
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Form>
                    </Row>

                    <QATable
                        columns={pointRegistryColumn}
                        data={contributorsTimeRegistration}
                        loadingData={fecthingContributorsTimeRegistration}
                        size="small"
                        datakey={"id"}
                        currentPage={paginationContributorsTimeRegistration.current ?? 1}
                        totalResult={paginationContributorsTimeRegistration.total ?? 1}
                        pageChange={(current: number, pageSize?: number) => {
                            setPaginationContributorsTimeRegistration({
                                ...paginationContributorsTimeRegistration,
                                current,
                                pageSize: pageSize ?? paginationContributorsTimeRegistration.pageSize,
                            });
                        }}
                        tableChange={handleContributorsTimeRegistrationTableChange}
                    />
                </Col>
            </Row>
        </>
    );
}
