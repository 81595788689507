import React, { useEffect, useState } from "react";
import { PageHeader, Select, message } from "antd";
import ReportFilter from "../components/filter";

import { getFieldDataSource } from "../../process_execution/actions/index";
import { getStablishments } from "../../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toArray } from "../../../components/util";
import { User_Preferences, Utilitaries } from "../../../business";

const { Option } = Select;

const reportType = {
    ALL: -1, // return all
    AUTHORIZED: 0, // return only report that user have authorization
    WITH_TYPOLOGY: 1, // return only report that user have authorizarion and belongs to a typology select
};

const ReportBuilderIndex = ({ getStablishments, getFieldDataSource }) => {
    const [stablishments, setStablishments] = useState([]);
    const [reportTypes, setReportTypes] = useState([]);
    const [reports, setReports] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectedStablishment, setSelectedStablishment] = useState(null);
    // const [selectedReport, setSelectedReport] = useState(null);
    const [selectedReportType, setSelectedReportType] = useState(null);
    const [selectedReport, setSelectedReport] = useState(null);
    // const [selectedReport, setSelectedReport] = useState(null);

    useEffect(() => {
        getStablishments().then((data) => {
            const list = toArray(data.estabelecimentos.linha);
            setStablishments(list);

            const defaultEstablishment = Utilitaries.isEmpty(data.estabelecimento_defeito)
                ? null
                : data.estabelecimento_defeito;

            if (User_Preferences.getReportEstablishedPref()) {
                let saveStableshment = User_Preferences.getReportEstablishedPref();
                saveStableshment = Utilitaries.toString(saveStableshment);
                setSelectedStablishment(saveStableshment);
            } else if (defaultEstablishment) {
                setSelectedStablishment(Utilitaries.toString(defaultEstablishment));
            }
        });
    }, [getFieldDataSource, getStablishments]);

    useEffect(() => {
        getFieldDataSource({
            dataSource: "tipologias_relatorios",
            params: {
                estabelecimento: selectedStablishment,
            },
        }).then((data) => {
            setReportTypes(data.result);
        });

        getFieldDataSource({
            dataSource: "relatorios_suportados",
            params: {
                id_tipologia: "",
                estabelecimento: selectedStablishment,
                tipo: reportType.AUTHORIZED,
            },
        }).then((data) => {
            setReports(data.result);
        });

        // getFieldDataSource({
        //     // dataSource: "busca_departamentos_qa",
        //     dataSource: "valencias_actuais",
        //     params: {
        //         // estabelecimento: selectedStablishment,
        //         idEstabelecimento: selectedStablishment,
        //     },
        // }).then((data) => {
        //     setDepartments(data.result);
        // });
    }, [getFieldDataSource, getStablishments, selectedStablishment]);

    useEffect(() => {
        setSelectedReport(null);

        getFieldDataSource({
            dataSource: "tipologias_relatorios",
            params: {
                estabelecimento: selectedStablishment,
            },
        }).then((data) => {
            setReportTypes(data.result);
        });

        getFieldDataSource({
            dataSource: "relatorios_suportados",
            params: {
                id_tipologia: "",
                estabelecimento: selectedStablishment,
                tipo: reportType.AUTHORIZED,
            },
        }).then((data) => {
            setReports(data.result);
        });

        // e  visivel_departamento
        // if (selectedStablishment) {
        //     getFieldDataSource({
        //         // dataSource: "busca_departamentos_qa",
        //         dataSource: "valencias_actuais",
        //         params: {
        //             // estabelecimento: selectedStablishment,
        //             idEstabelecimento: selectedStablishment,
        //         },
        //     }).then((data) => {
        //         setDepartments(data.result);
        //     });
        // }
    }, [getFieldDataSource, selectedStablishment]);

    useEffect(() => {
        setSelectedReport(null);
        if (!selectedReportType) {
            getFieldDataSource({
                dataSource: "relatorios_suportados",
                params: {
                    id_tipologia: "",
                    estabelecimento: selectedStablishment,
                    tipo: reportType.AUTHORIZED,
                },
            }).then((data) => {
                setReports(data.result);
            });
        } else {
            getFieldDataSource({
                dataSource: "relatorios_suportados",
                params: {
                    id_tipologia: selectedReportType,
                    estabelecimento: selectedStablishment,
                    tipo: reportType.WITH_TYPOLOGY,
                },
            }).then((data) => {
                setReports(data.result);
            });
        }
    }, [getFieldDataSource, selectedReportType, selectedStablishment]);

    // useEffect(() => {
    //     if (selectedStablishment && selectedReport?.visivel_departamento) {
    //         getFieldDataSource({
    //             // dataSource: "busca_departamentos_qa",
    //             dataSource: "valencias_actuais",
    //             params: {
    //                 // estabelecimento: selectedStablishment,
    //                 idEstabelecimento: selectedStablishment,
    //             },
    //         }).then((data) => {
    //             setDepartments(data.result);
    //         });
    //     }
    // }, [selectedReport]);

    const handleStablishmentChange = (value) => {
        const newValue = Utilitaries.toString(value);
        setSelectedStablishment(newValue);
        User_Preferences.addReportEstablishedPref(newValue);
    };

    const handleReportTypeChange = (value) => setSelectedReportType(value);
    const handleReportChange = (value) => {
        setSelectedReport(value);
        if (Utilitaries.isEmpty(Utilitaries.toString(selectedStablishment))) {
            message.warn("Selecione um estabelecimento");
        }
    };

    const getFilter = ({
        dataSource,
        dataSourceName,
        reportId,
        ...params
        // estado_clientes,
        // cliente,
        // param1,
        // param2,
        // param3,
        // param4,
        // data_inicio,
        // data_fim,
    }) => {
        if (Utilitaries.isEmpty(Utilitaries.toString(selectedStablishment))) {
            return;
        }
        getFieldDataSource({
            dataSource,
            params: {
                ...params,
                id_relatorio: reportId,
                estabelecimento: selectedStablishment,
                // estado_clientes: estado_clientes,
                // cliente: cliente,
                // param1: param1,
                // param2: param2,
                // param3: param3,
                // param4: param4,
                // data_inicio: data_inicio,
                // data_fim: data_fim,
            },
            sendRequestWithEmptyParams: true,
        }).then((data) => {
            setReports((preState) =>
                preState.map((item) => {
                    if (item.id_relatorio === reportId) {
                        return { ...item, [dataSourceName]: data.result };
                    } else {
                        return item;
                    }
                })
            );
        });
    };

    return (
        <>
            <PageHeader
                title="Geração de Relatório"
                extra={[
                    <div>
                        <label style={{ marginRight: 8 }}>
                            <strong>Estabelecimento:</strong>
                        </label>
                        <Select
                            showSearch
                            allowClear
                            value={Utilitaries.toString(selectedStablishment)}
                            style={{ width: "250px" }}
                            showArrow={false}
                            placeholder="Selecione o estabelecimento"
                            onChange={handleStablishmentChange}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {stablishments.map((item) => (
                                <Option
                                    key={`reports.stablishment.${item.id_estabelecimento}`}
                                    title={item.nome}
                                    value={item.id_estabelecimento}
                                >
                                    {item.nome}
                                </Option>
                            ))}
                        </Select>
                    </div>,
                ]}
            ></PageHeader>
            <ReportFilter
                selectedReport={selectedReport}
                data={selectedReport && reports.find((item) => item.id_relatorio === selectedReport)}
                getFieldDataSource={getFieldDataSource}
                getFilter={getFilter}
                selectedStablishment={selectedStablishment}
                setDepartments={setDepartments}
                reportTypes={reportTypes}
                reports={reports}
                departments={departments}
                handleReportTypeChange={handleReportTypeChange}
                handleReportChange={handleReportChange}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return { report: state.report };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getStablishments, getFieldDataSource }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportBuilderIndex);
