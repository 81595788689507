import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button, List, Popover, Avatar } from "antd";
import { connect } from "react-redux";

import {
    changeChatModalState,
    getRooms,
    setSelectedRoom,
    getMessages,
    sendMessage,
    getUsers,
    setFilter,
} from "./actions/index";

import RoomsComponent from "./components/Rooms";
import MessageComponent from "./components/Messages";
import EditorComponent from "./components/Editor";
import SearchComponent from "./components/Search";

const Chat = ({
    getRooms,
    room,
    changeRoom,
    modalChatState,
    changeChatModalState,
    getMessages,
    message,
    sendMessage,
    user,
    getUsers,
    setFilter,
    listOfItems,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [visibleSearch, setVisibleSeach] = useState(false);
    const [newMessage, setNewMessage] = useState("");

    useEffect(() => {
        getRooms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room.selected]);

    useEffect(() => {
        getMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room.selected, room.filter]);

    const onSubmit = async () => {
        if (!newMessage) {
            return;
        }

        setSubmitting(true);

        await sendMessage(newMessage);
        setNewMessage("");
        setSubmitting(false);
    };

    const listStyle = {
        maxHeight: 450,
        overflow: "auto",
    };

    return (
        <>
            {/* <SearchComponent
                // onChange={() => {}}
                visible={visibleSearch}
                setVisible={setVisibleSeach}
                users={user.list}
                setFilter={setFilter}
                levels={
                    listOfItems &&
                    listOfItems.items &&
                    (Array.isArray(listOfItems.items)
                        ? listOfItems.items
                        : listOfItems.items["designacao"]
                        ? [listOfItems.items]
                        : []
                    ).filter(it => typeof it["designacao"] === "string")
                }
            /> */}

            <Modal
                title="Chat"
                visible={modalChatState}
                onOk={() => {}}
                footer={null}
                width="80%"
                style={{ top: 20 }}
                onCancel={() => changeChatModalState(false)}
            >
                <Row gutter={16}>
                    <Col xs={24} sm={6}>
                        <h4>Salas</h4>
                        <RoomsComponent list={room.list} changeRoom={changeRoom} />
                    </Col>

                    <Col className="borderLeft borderRight" xs={24} sm={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <h4>Mensagens</h4>
                            <div>
                                {room.filter && !visibleSearch && (
                                    <a
                                        href="!#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setFilter(null);
                                        }}
                                        style={{ marginRight: 5 }}
                                    >
                                        Limpar filtros
                                    </a>
                                )}
                                <Popover
                                    trigger="click"
                                    visible={visibleSearch}
                                    title="Filtrar Mensagens"
                                    onVisibleChange={(visible) => setVisibleSeach(visible)}
                                    content={
                                        <SearchComponent
                                            setVisible={setVisibleSeach}
                                            users={user.list}
                                            setFilter={setFilter}
                                            levels={
                                                listOfItems &&
                                                listOfItems.items &&
                                                (Array.isArray(listOfItems.items)
                                                    ? listOfItems.items
                                                    : listOfItems.items["designacao"]
                                                    ? [listOfItems.items]
                                                    : []
                                                ).filter((it) => typeof it["designacao"] === "string")
                                            }
                                        />
                                    }
                                >
                                    <Button shape="circle" icon="search" onClick={() => setVisibleSeach(true)} />
                                </Popover>
                            </div>
                        </div>
                        <MessageComponent message={message.list} />
                        <EditorComponent
                            onChange={setNewMessage}
                            onSubmit={onSubmit}
                            submitting={submitting}
                            value={newMessage}
                        />
                    </Col>

                    <Col xs={24} sm={6}>
                        <h4>Utilizadores</h4>
                        <List
                            header={null}
                            footer={null}
                            bordered={false}
                            size="small"
                            style={listStyle}
                            dataSource={user.list}
                            renderItem={(item) => (
                                <List.Item>
                                    {item && item.nome && (
                                        <List.Item.Meta
                                            style={{ alignItems: "center" }}
                                            avatar={
                                                <Avatar
                                                    size={14}
                                                    style={{
                                                        backgroundColor: `${
                                                            item.estado === "t" ? "#18BC86" : "#FF5733"
                                                        }`,
                                                    }}
                                                />
                                            }
                                            title={item.nome}
                                        />
                                    )}
                                </List.Item>
                            )}
                        />
                    </Col>

                    {/* <Col span={24}>
                        <Divider
                            type="horizontal"
                            style={{ margin: "5px 0" }}
                        />
                    </Col> */}
                </Row>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        modalChatState: state.chat.modalChatState || false,
        room: state.chat.room,
        message: state.chat.message,
        user: state.chat.user,
        listOfItems: state.dynamicList.domain.byId["nivel_mensagem_chat"],
    };
};
const mapDispatchToProps = {
    changeChatModalState,
    getRooms,
    changeRoom: setSelectedRoom,
    getMessages,
    sendMessage,
    getUsers,
    setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
