import {
    Button,
    Col,
    Form,
    Icon,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Skeleton,
    Table,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { Utilitaries } from "../../business";
import { isEmpty } from "../../business/utils";
import { sendHttpRequest } from "../../components/http.request";
import Confirm from "../../components/qa_custom/confirm";
import { toArray } from "../../components/util";

const MONTHS = [
    { key: 1, name: "Janeiro" },
    { key: 2, name: "Fevereiro" },
    { key: 3, name: "Março" },
    { key: 4, name: "Abril" },
    { key: 5, name: "Maio" },
    { key: 6, name: "Junho" },
    { key: 7, name: "Julho" },
    { key: 8, name: "Agosto" },
    { key: 9, name: "Setembro" },
    { key: 10, name: "Outubro" },
    { key: 11, name: "Novembro" },
    { key: 12, name: "Dezembro" },
];

function SpecialDatesEditor({ isVisible, close, form }) {
    const [loading, setLoading] = React.useState(false);
    const [dataSource, setDataSource] = React.useState([]);
    const [stablishments, setStablishments] = React.useState([]);

    React.useEffect(() => {
        const getStablishments = async () => {
            try {
                const formData = new FormData();

                formData.append("accao", "lista_estabelecimentos");

                const data = await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);

                setStablishments(toArray(data?.estabelecimentos?.linha));
            } catch (error) {
                console.error(error);
            }
        };
        getStablishments();
        //da_estabelecimento
    }, []);

    const checkInvalidHolidays = (holidays) => {
        let hasEmpty = false;

        for (const item of holidays) {
            if (
                isEmpty(item.name) ||
                isEmpty(item.description) ||
                isEmpty(item.day) ||
                isEmpty(item.month) ||
                isEmpty(item.category)
            ) {
                hasEmpty = true;
            }
        }
        return hasEmpty;
    };

    const handleOk = () => {
        form.validateFields((err, values) => {
            if (!err) {
                const hasEmpty = checkInvalidHolidays(dataSource);
                if (hasEmpty) {
                    message.error("Campos obrigatórios em branco");
                } else {
                    // this.setState({ buttonLoading: true });
                    const obj = {
                        ...values,
                        linhas: dataSource,
                    };
                }
            }
        });
    };

    const addNewDay = () => {
        const length = dataSource.length;
        const key = length === 0 ? 1 : dataSource[0].key + 1;
        const newDataSource = [
            { key, name: "", description: "", stablishment: [], day: "", month: "", year: 2021, category: "" },
            ...dataSource,
        ];
        setDataSource(newDataSource);
    };

    const { getFieldDecorator } = form;

    const requiredLabel = (text) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minWidth: "80px",
                }}
            >
                <span>
                    <i style={{ color: "red" }}>*</i>
                    &nbsp;{text}&nbsp;
                </span>
            </div>
        );
    };

    const handleChangeTableFields = (dataIndex, text, record) => {
        const obj = { ...record, [dataIndex]: text };

        const dataSourceCopy = [...dataSource];

        const index = dataSourceCopy.findIndex((item) => obj.key === item.key);
        dataSourceCopy[index] = obj;

        setDataSource(dataSourceCopy);
    };

    const removeLine = (key) => {
        const newDataSource = dataSource.filter((item) => item.key !== key);

        setDataSource(newDataSource);
    };

    const columns = [
        // {
        //     title: requiredLabel("Ano"),
        //     dataIndex: "year",
        //     width: "20%",
        //     render: (value, record) => {
        //         return (
        //             <>
        //                 <Input
        //                     value={value ? value : null}
        //                     name="year"
        //                     // onChange={(date) => handleChangeTableFields("year", date?.target?.value ? date.target.value : null, record)}
        //                 />
        //             </>
        //         );
        //     },
        // },
        {
            title: requiredLabel("Nome"),
            dataIndex: "name",
            width: "20%",
            render: (value, record) => {
                return (
                    <>
                        <Input
                            value={value}
                            onChange={(event) => {
                                handleChangeTableFields("name", event.target.value, record);
                            }}
                        />
                        {!value && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: requiredLabel("Descrição"),
            dataIndex: "description",
            width: "30%",
            render: (value, record) => {
                return (
                    <>
                        <TextArea
                            value={value}
                            onChange={(event) => {
                                handleChangeTableFields("description", event.target.value, record);
                            }}
                        />
                        {!value && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: requiredLabel("Categoria"),
            dataIndex: "category",
            width: "10%",
            render: (value, record) => {
                return (
                    <>
                        <Select
                            placeholder={"Selecione a área de gestão"}
                            value={value}
                            onChange={(event) => {
                                handleChangeTableFields("category", event, record);
                            }}
                            name="category"
                        >
                            <Select.Option value="Fixo">Fixo</Select.Option>
                            <Select.Option value="Móvel">Móvel</Select.Option>
                        </Select>
                        {!value && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: requiredLabel("Dia"),
            dataIndex: "day",
            width: "10%",
            render: (value, record) => {
                return (
                    <>
                        <InputNumber
                            value={value ? value : null}
                            name="day"
                            min={1}
                            max={31}
                            onChange={(value) => handleChangeTableFields("day", value ? value : null, record)}
                        />
                        {!value && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: requiredLabel("Mês"),
            dataIndex: "month",
            width: "10%",
            render: (value, record) => {
                return (
                    <>
                        <Select
                            value={value ? value : null}
                            name="month"
                            onChange={(value) => handleChangeTableFields("month", value ? value : null, record)}
                        >
                            {MONTHS.map((month) => (
                                <Select.Option value={month.key}>{month.name}</Select.Option>
                            ))}
                        </Select>
                        {!value && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: requiredLabel("Estabelecimento"),
            dataIndex: "stablishment",
            width: "20%",
            render: (value, record) => {
                const error = !value || value.length === 0;
                return (
                    <>
                        <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            placeholder="Selecione estabelecimentos"
                            onChange={(values) => {
                                handleChangeTableFields("stablishment", toArray(values).join(";"), record);
                            }}
                            defaultValue={value ? Utilitaries.toString(value).split(";") : null}
                        >
                            {stablishments.map((item) => (
                                <Select.Option key={item.id_estabelecimento} value={item.id_estabelecimento}>
                                    {item.nome}
                                </Select.Option>
                            ))}
                        </Select>
                        {error && <span style={{ color: "red" }}>Campo obrigatório</span>}
                    </>
                );
            },
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        okText="Ok"
                        cancelText="Não"
                        onConfirm={() => removeLine(record.key)}
                    >
                        <Button
                            type="link"
                            style={{
                                padding: 0,
                                color: "#fa541c",
                            }}
                        >
                            Eliminar
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <Modal
            title="Configuração de datas especiais"
            visible={isVisible}
            onOk={handleOk}
            onCancel={() => Confirm(close)}
            confirmLoading={loading}
            width="90%"
            style={{ top: 10 }}
        >
            <Form layout="vertical" onSubmit={handleOk}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={5}>
                        <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={loading}>
                            <Form.Item label="Ano civil">
                                {getFieldDecorator("year", {
                                    initialValue: "",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                        </Skeleton>
                    </Col>
                    <Col xs={24} sm={24} md={9}>
                        <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={loading}>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    initialValue: "", //this.props.formHoliday.descricao.value,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <TextArea
                                        placeholder="Introduza a descrição"
                                        autoSize={{
                                            minRows: 1,
                                            maxRows: 6,
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </Skeleton>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item label="&nbsp;">
                            <Select
                                placeholder="Importar do ano"
                                allowClear
                                showSearch
                                style={{
                                    float: "right",
                                    marginLeft: 8,
                                    marginBottom: 16,
                                }}
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {/* {this.state.registeredHolidays.map((item) => (
                                                <Option key={item.id_config_feriado} value={item.id_config_feriado}>
                                                    {item.ano}
                                                </Option>
                                            ))} */}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4}>
                        <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={loading}>
                            <Form.Item label="&nbsp;">
                                <Button
                                    onClick={() => addNewDay({})}
                                    type="primary"
                                    style={{
                                        float: "right",
                                        marginBottom: 16,
                                    }}
                                >
                                    <Icon type="plus" />
                                    Inserir
                                </Button>
                            </Form.Item>
                        </Skeleton>
                    </Col>
                </Row>
                <Table
                    rowKey={(record) => record.key}
                    rowClassName={() => "editable-row"}
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                    loading={loading}
                    scroll={{ y: 300 }}
                />
                {/* <Row>
                        <Col xs={24} sm={24} md={14}>
                            <Skeleton paragraph={{ rows: 1, width: "100%" }} active loading={this.state.loadingData}>
                                <Form.Item label="Motivo de alteração">
                                    {this.props.formHoliday.year.value &&
                                        getFieldDecorator(
                                            "motivo",
                                            {}
                                        )(
                                            <TextArea
                                                placeholder="Introduza o motivo de alteração"
                                                autoSize={{
                                                    minRows: 1,
                                                    maxRows: 6,
                                                }}
                                            />
                                        )}
                                </Form.Item>
                            </Skeleton>
                        </Col> */}
                {/* </Row> */}
            </Form>
        </Modal>
    );
}

const formEdit = Form.create();
export default formEdit(SpecialDatesEditor);
