export default {
    "qualia_socios_renumeracao.qml.xml": {
        socios_renumeracao: {
            data_registo: "",
            renumeracao_socios: {
                data_inscricao: "",
                entidade: "",
                n_socio_novo: "",
                nome: "",
                numero: "",
            },
        },
    },
    "qualia_socios_ficha_inscricao.qml.xml": {
        id_socio_inscricao: "",
        data_registo: "", //"2018-09-19",
        data_inscricao: "", //"2018-09-19",
        foto_upload: "", //[],
        tipo_socio: "", //"23",
        cota_socio: "", //"7.5",
        modo_pagamento: "", //"transferencia",
        numero: "", //"282",
        estado: "", //"inativo",
        entidade: {
            identidade: "", //"2658",
            nome: "", //"teste1",
            nif: "", //"254874371",
            tlf: "", //"914532687",
            tlm: "", //[],
            email: "", //"ola@hotmail.com",
            dados_adicionais: {
                id_entidade: "", //"2658",
                data_nascimento: "", //"1981-09-10",
                estado_civil: "", //"Solteiro(a)",
                sexo: "", //"Masculino",
                naturalidade: "", //"portugal",
                habilitacao: "", //"72",
                actividade_profissional: {
                    id_actividade_profissional: "", //"2218",
                    profissao: "", //"816",
                },
            },
            moradas: {
                morada: {
                    idmorada: "", //"2777",
                    arruamento: "", //"Teste1",
                    porta: "", //"5",
                    andar: "", //"5",
                    c_postal4: "", //"4705",
                    c_postal3: "", //"628",
                    pais: "", //"16",
                    distrito: "", //"3",
                    concelho: "", //"36",
                    freguesia: "", //"430",
                },
            },
            docs: {
                doc: {
                    id_doc: "", // "1439",
                    tipo_documento: "", // "63",
                    numero: "", // "146857518",
                },
            },
        },
        criar_user: "f",
        username: "",
        comunicar: "f",
        senha: "",
        familiar: "",
    },
    "qa_parcerias_entidades.xml": {
        id_parceria_entidade: "",
        activo: "",
        pais_instituicao: "",
        pais_selecionado: "",
        entidade: {
            identidade: "",
            nome: "",
            nif: "",
            tlf: "",
            tlm: "",
            email: "",
            moradas: {
                morada: {
                    idmorada: "",
                    arruamento: "",
                    porta: "",
                    andar: "",
                    c_postal4: "",
                    c_postal3: "",
                    pais: "",
                    distrito: "",
                    concelho: "",
                    freguesia: "",
                },
            },
        },
        representante: "",
        tlf_rep: "",
        email_rep: "",
        username_portal: "",
        senha_portal: "",
    },
    "qa_financiadores.xml": {
        id_financiador: "",
        entidade: {
            identidade: "",
            nome: "",
            nif: "",
            tlf: "",
            tlm: "",
            email: "",
            moradas: {
                morada: {
                    idmorada: "",
                    arruamento: "",
                    porta: "",
                    andar: "",
                    c_postal4: "",
                    c_postal3: "",
                    pais: "",
                    distrito: "",
                    concelho: "",
                    freguesia: "",
                },
            },
        },
        representante: "",
        username_portal: "",
        senha_portal: "",
    },
    // "estabelecimentos.xml": {
    //     id_estabelecimento: "",
    //     entidade: {
    //         identidade: "",
    //         nome: "",
    //         tlf: "",
    //         fax: "",
    //         nif: "",
    //         nib: "",
    //         email: "",
    //         niss: "",
    //         cod_fin: "",
    //         nif_toc: "",
    //         nif_rep_legal: "",
    //         bank: "",
    //         seguros: "",
    //         numero_apolice: "",
    //         cae: "",
    //         moradas: {
    //             morada: {
    //                 idmorada: "",
    //                 arruamento: "",
    //                 porta: "",
    //                 andar: "",
    //                 c_postal4: "",
    //                 c_postal3: "",
    //                 pais: "",
    //                 distrito: "",
    //                 concelho: "",
    //                 freguesia: "",
    //             },
    //         },
    //     },
    // },
};
