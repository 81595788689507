import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Select, Popconfirm, Row, Table } from "antd";
import { toArray } from "../../util";

const RememberInput = (props) => {
    const handleNumberChange = (e) => {
        const number = parseInt(e.target.value || 0, 10);
        if (isNaN(number)) {
            return;
        }
        triggerChange({ number });
    };

    const handleTypeChange = (type) => {
        triggerChange({ type });
    };

    const triggerChange = (changedValue) => {
        const { onChange, value } = props;
        if (onChange) {
            onChange({
                ...value,
                ...changedValue,
            });
        }
    };

    const { size, value } = props;

    return (
        <div>
            <Input
                type="text"
                size={size}
                value={value?.number || 0}
                onChange={handleNumberChange}
                style={{ width: "150px", marginRight: "12px" }}
            />
            <Select value={value?.type || ""} size={size} style={{ width: "110px" }} onChange={handleTypeChange}>
                <Select.Option value="Minuto">Minuto</Select.Option>
                <Select.Option value="Hora">Hora</Select.Option>
                <Select.Option value="Dia">Dia</Select.Option>
                <Select.Option value="Semana">Semana</Select.Option>
                <Select.Option value="Mês">Mês</Select.Option>
            </Select>
        </div>
    );
};

function CollectionRemember({ visible, onCancel, onCreate, defaultValues }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const list = toArray(defaultValues).map((item, index) => ({
            key: index + 1,
            avisar: item,
        }));

        setData(list);
    }, [defaultValues]);

    const addLine = (type) => {
        let newKey = 1;
        newKey = data.length > 0 ? data[data.length - 1].key : 1;
        setData([...data, { key: newKey + 1 }]);

        return newKey;
    };

    const removeLine = (key) => {
        setData(data.filter((it) => it.key !== key));
    };

    const handleChange = (value, record) => {
        record.avisar = {
            ...record.avisar,
            ...value,
        };

        const dataCopy = [...data];
        const index = dataCopy.findIndex((item) => item.key === record.key);
        dataCopy[index] = record;
        setData(dataCopy);
    };

    const columns = [
        {
            title: "Avisar antes de",
            dataIndex: "avisar",
            width: "20%",
            render: (text, record) => {
                return <RememberInput value={text} onChange={(value) => handleChange(value, record)} />;
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) => (
                <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => removeLine(record.key)}>
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Eliminar
                    </a>
                </Popconfirm>
            ),
        },
    ];

    const handleOk = () => {
        onCreate(data.map((record) => record?.avisar));
        onCancel(false);
    };

    return (
        <Modal
            visible={visible}
            title="Adicionar agendamento notificacões"
            okText="Ok"
            width="30%"
            onCancel={() => onCancel(false)}
            onOk={handleOk}
        >
            <Row>
                <Button
                    xs={24}
                    sm={12}
                    onClick={addLine}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table size="small" scroll={{ x: 500 }} columns={columns} dataSource={data} />
        </Modal>
    );
}

export default CollectionRemember;
