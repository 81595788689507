import React, { Component } from "react";
import { Modal, Button, Row, Table, Select, Popconfirm, message } from "antd";
import { connect } from "react-redux";

import { selectAllContributors } from "../../../fixed_list/actions/index";
import { insertValidationPermission, selectDataFromGereFormulário } from "../../actions/contributor";
import { Utilitaries } from "../../../../business";

const { Option } = Select;
const tipo = [
    { key: "1", value: "Férias" },
    { key: "2", value: "Faltas-Validação" },
    { key: "3", value: "Faltas-Autorização" },
    { key: "4", value: "Horas Extras" },
    { key: "5", value: "Adiantamentos" },
    { key: "6", value: "Permuta de Férias" },
    { key: "7", value: "Controlo de Férias" },
];

class ValidationPermissionModal extends Component {
    state = {
        loading: false,
        countDataSource: 1,
        dataSource: [],
        contributors: [],
    };

    componentDidMount() {
        const { ficha_colaborador } = this.props;
        if (ficha_colaborador) {
            const { acessos_validacao } = ficha_colaborador;
            if (acessos_validacao && typeof acessos_validacao !== "object") {
                this.getAccesValidationById(acessos_validacao);
            }
        }
    }
    getAccesValidationById = async (id) => {
        try {
            this.setState({ loading: true });
            const contributors = await this.props.selectAllContributors({
                accao: "lista_funcionarios",
                lista: "0",
            });
            const resp = await this.props.selectDataFromGereFormulário({
                accao: "dados",
                formulario: "qa_acessos_validacao_colab.xml",
                id,
            });
            if (resp.valores) {
                const { valores } = resp;
                if (valores.acessos_validacao) {
                    const { acessos_validacao } = valores;
                    if (acessos_validacao.acessos_valida) {
                        const { acessos_valida } = acessos_validacao;
                        if (acessos_valida.acesso_valida) {
                            const { acesso_valida } = acessos_valida;
                            const _dataSource = Utilitaries.toArray(acesso_valida).map((item, index) => {
                                return {
                                    ...item,
                                    key: index,
                                };
                            });
                            this.setState({
                                dataSource: _dataSource,
                                contributors: contributors.linha,
                                countDataSource: _dataSource.length + 1,
                            });
                        }

                        // if (Array.isArray(acesso_valida))
                        //     this.setState({
                        //         dataSource: acesso_valida,
                        //         contributors: contributors.linha,
                        //     });
                        // else if (acesso_valida.id_acesso_valida && contributors.linha)
                        //     this.setState({
                        //         dataSource: [acesso_valida],
                        //         contributors: contributors.linha,
                        //     });
                        // }
                    }
                }
            }
        } catch (error) {
            message.error("Erro ao carregar acessos a validação!!");
        } finally {
            this.setState({ loading: false });
        }
    };

    getContributorsByGroupId = async (groupID) => {
        const contributors = await this.props.selectAllContributors({
            accao: "lista_funcionarios",
            lista: "0",
            id_funcional_group: groupID,
        });
        if (contributors.linha) this.setState({ contributors: contributors.linha });
    };

    column = [
        {
            title: "Grupo Funcional",
            dataIndex: "grupo_utilizador",
            width: "30%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Select
                        showSearch
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        allowClear
                        placeholder="seleciona o grupo funcional"
                        optionFilterProp="children"
                        defaultValue={text}
                        onChange={(groupId) => {
                            this.onChange("grupo_utilizador", groupId, record);
                            this.getContributorsByGroupId(groupId);
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {this.props.functionalGroups !== undefined &&
                            this.props.functionalGroups.map((item) => {
                                if (typeof item.id_ficha_funcao !== "object" && typeof item.codigo !== "object") {
                                    return (
                                        <Option key={item.id_ficha_funcao} value={item.id_ficha_funcao}>
                                            {item.descricao_funcao}
                                        </Option>
                                    );
                                } else return [];
                            })}
                    </Select>
                );
            },
        },
        {
            title: "Funcionário",
            dataIndex: "utilizador_permitido",
            width: "30%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Select
                        showSearch
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        allowClear
                        placeholder="Seleciona o colaborador"
                        optionFilterProp="children"
                        defaultValue={text}
                        onChange={(value) => this.onChange("utilizador_permitido", value, record)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {this.state.contributors !== undefined &&
                            this.state.contributors.map((item) => {
                                return (
                                    <Option key={item.username} value={item.username}>
                                        {item.nome}
                                    </Option>
                                );
                            })}
                    </Select>
                );
            },
        },
        {
            title: "Tipo",
            dataIndex: "tipo",
            width: "30%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Select
                        showSearch
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                        allowClear
                        placeholder="Seleciona o tipo"
                        optionFilterProp="children"
                        defaultValue={text}
                        onChange={(value) => this.onChange("tipo", value, record)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {tipo.map((item) => {
                            return (
                                <Option key={item.key} value={item.key}>
                                    {item.value}
                                </Option>
                            );
                        })}
                    </Select>
                );
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) =>
                this.state.dataSource.length >= 1 ? (
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        onConfirm={() => this.handleDelete(record.key)}
                    >
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];
    onChange = (dataIndex, value, record) => {
        record[dataIndex] = value;
        const { dataSource } = this.state;
        const index = dataSource.findIndex((item) => record.key === item.key);
        dataSource[index] = record;
        this.setState({ dataSource });
    };
    handleOk = async () => {
        let empty_row = false;
        let acessos_validacao = {
            acessos_valida: {
                acesso_valida: this.state.dataSource.map((item) => {
                    if (item.tipo && item.utilizador_permitido && item.grupo_utilizador) return item;
                    else empty_row = true;
                }),
            },
        };

        if (this.props.ficha_colaborador) {
            acessos_validacao.id_acessos_validacao = this.props.ficha_colaborador?.acessos_validacao;
        }
        let result = "";
        if (!empty_row) {
            try {
                this.setState({ loading: true });
                result = await this.props.insertValidationPermission({
                    accao: "guardar",
                    formulario: "qa_acessos_validacao_colab.xml",
                    dados: JSON.stringify({ acessos_validacao }),
                });
            } catch (error) {
                this.setState({ loading: false });
                message.error("Erro ao guardar validações!");
            } finally {
                if (result !== "") {
                    message.success("Sucesso!");
                    this.props.senData(result);
                } else message.error("Erro ao guardar validações!");
                this.setState({ loading: false });
            }
        } else message.warning("Linhas vazios tem de ser preenchidas ou removidas!!");
    };
    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };
    handleAdd = () => {
        const { countDataSource, dataSource } = this.state;
        const newData = { key: countDataSource };
        this.setState({
            dataSource: [newData, ...dataSource],
            countDataSource: countDataSource + 1,
        });
    };
    handleCancel = () => {
        this.props.senData(false);
    };

    render() {
        const { loading } = this.state;
        return (
            <>
                <Modal
                    centered
                    width="70%"
                    visible={this.props.isVisible}
                    title="Colaboradores com Permissão de Validação"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            voltar
                        </Button>,
                        <Button
                            icon="save"
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={this.handleOk}
                            disabled={this.state.dataSource.length ? false : true}
                        >
                            Guardar
                        </Button>,
                    ]}
                >
                    <div>
                        <Row>
                            <Button
                                xs={24}
                                sm={12}
                                onClick={this.handleAdd}
                                icon="plus"
                                size="default"
                                type="primary"
                                style={{ marginBottom: 16, float: "right" }}
                            >
                                Adicionar
                            </Button>
                        </Row>
                        <Table
                            rowKey={(record) => record.key}
                            size="small"
                            scroll={{ x: 850, y: 300 }}
                            columns={this.column}
                            dataSource={this.state.dataSource}
                            loading={this.state.loading}
                        />
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        functionalGroups: Object.values(state.funcionalGroup.domain.byId),
        contributors: state.fixedList.domain.byId.funcionarios,
    };
};

const mapDispatchToProps = {
    selectDataFromGereFormulário,
    selectAllContributors,
    insertValidationPermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationPermissionModal);
