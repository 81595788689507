import React, { useEffect } from "react";
import { Card, Row, Col, Tooltip, Icon } from "antd";
import { isEmpty } from "../../utils/fields";
import { Utilitaries } from "../../../../business";

const TaskCard = (props) => {
    const aditionalInfo = typeof props.item.informacao_adicional !== "object" ? props.item.informacao_adicional : "---";
    const limite = typeof props.item.limite !== "object" ? props.item.limite : "---";
    const inicio = typeof props.item.inicio !== "object" ? props.item.inicio : "---";
    const fim = typeof props.item.fim !== "object" ? props.item.inicio : "---";

    useEffect(() => {
        //TODO: implement this in backend
        // props.getTaskReassignmentHistory({
        //     tarefa: props.item.tarefa,
        //     tab: props.type.key,
        // });
    }, []);

    const getReports = () => {
        let reports = props.item?.report;

        if (Utilitaries.isEmpty(reports)) {
            return [];
        }

        reports = reports.split(", ");

        reports = reports.map((report) => {
            report = report.split(":");
            return {
                id: report[0],
                name: report[1],
            };
        });

        return reports;
    };

    return (
        <Card
            className="task-card"
            title={
                <div>
                    {/* <Tooltip mouseEnterDelay={1} title={props.item.nome_tarefa}>
						<label style={{ display: 'inline-block' }}>{props.item.nome_tarefa}</label>
					</Tooltip>
					{props.type.name === 'done' && (
						<Icon
							style={{ color: 'red', fontSize: '1.5em', float: 'right' }}
							type="file-pdf"
							title="Consultar relatório"
						></Icon>
					)} */}
                    <Tooltip mouseEnterDelay={1} title={`${props.item.tarefa} - ${props.item.nome_tarefa}`}>
                        <label>
                            {props.item.tarefa} - {props.item.nome_tarefa}
                        </label>
                    </Tooltip>
                </div>
            }
            actions={[
                <span
                    onClick={() =>
                        props.handleGetTask({
                            processId: props.item.processo,
                            taskId: props.item.tarefa,
                            processParentId: props.item.proc_pai,
                            taskFormParams: props.item.task_form_params,
                        })
                    }
                >
                    {props.type.key === "nao_atribuidas" || props.item.isReassigned ? (
                        <Icon type="team" />
                    ) : (
                        <Icon type="file-search" />
                    )}{" "}
                    Detalhes
                </span>,
            ]}
        >
            <Row className="card-row">
                <Col xs={12}>
                    <label className="field-title">Nº Processo</label>
                    <label className="field-data">{props.item.processo}</label>
                </Col>
                {typeof props.item.nome_externo_processo !== "object" ? (
                    <Tooltip mouseEnterDelay={1} title={props.item.nome_externo_processo}>
                        <Col xs={12}>
                            <label className="field-title">Processo</label>
                            <label className="field-data ant-card-meta-title">{props.item.nome_externo_processo}</label>
                        </Col>
                    </Tooltip>
                ) : (
                    <Col xs={12}>
                        <label className="field-title">Processo</label>
                        <label style={{ color: "red" }} className="field-data ant-card-meta-title">
                            Não definido
                        </label>
                    </Col>
                )}
            </Row>
            <Row className="card-row">
                {props.type.name === "doing" || props.type.name === "done" ? (
                    <Col xs={12}>
                        <label className="field-title">Data Inicio</label>
                        <label className="field-data" style={inicio === "---" ? { textAlign: "center" } : null}>
                            {inicio}
                        </label>
                    </Col>
                ) : (
                    false
                )}
                {props.type.name === "done" && (
                    <Col xs={12}>
                        <label className="field-title">Data Fim</label>
                        <label className="field-data" style={!isEmpty(fim) === "---" ? { textAlign: "center" } : null}>
                            {Utilitaries.toString(fim)}
                        </label>
                    </Col>
                )}

                {props.type.name === "doing" || props.type.name === "todo" ? (
                    <Col xs={12}>
                        <label className="field-title">Data Limite</label>
                        <label className="field-data">{limite}</label>
                    </Col>
                ) : (
                    false
                )}
            </Row>
            <Row className="card-row qa-adicional-info">
                <Tooltip mouseEnterDelay={1} title={aditionalInfo}>
                    <Col xs={12}>
                        <label className="field-title">Info Adicional</label>
                        <label className="field-data ant-card-meta-title">{aditionalInfo}</label>
                    </Col>
                </Tooltip>

                {/*  */}

                {!Utilitaries.isEmpty(props.item?.report) && (
                    <Col xs={12}>
                        <label className="field-title">Relatórios</label>
                        <div>
                            {getReports().map((report) => (
                                <Tooltip key={report.id + report.nome} mouseEnterDelay={1} title={report.name}>
                                    <Icon
                                        type="file-pdf"
                                        style={{ color: "red", marginLeft: 4 }}
                                        onClick={() =>
                                            props.handleXFormatDownloadDocument({
                                                id: report.id,
                                                saida: "pdf",
                                                processo: props.item.processo,
                                                tarefa: props.item.tarefa,
                                            })
                                        }
                                    />
                                </Tooltip>
                            ))}
                        </div>
                    </Col>
                )}
            </Row>
        </Card>
    );
};

export default TaskCard;
