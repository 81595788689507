import React from "react";

interface IProps {
    onClick: (label: string, real: number | string) => void;
    label: string;
    classes?: string[];
    real?: string | number;
}

export function NumberButtom({ onClick, label, classes, real }: IProps) {
    const custonClasses = classes ? classes : [];
    const classNames = ["button", ...custonClasses];
    return (
        <button className={classNames.join(" ")} onClick={() => onClick(label, real || "")}>
            <span>{label}</span>
        </button>
    );
}
