import React, { useMemo } from "react";
import { Form, Input } from "antd";
import moment from "moment";
import { componentType } from "../../constants/index";
import { canCorrectField } from "../../utils/fields";

const dateStyles = {
    width: "100%",
};

const staticStyles = {
    width: "110px",
};

const format = "HH:mm";

const QaTimePicker = ({
    handleInputChange,
    onChange,
    type,
    currentTaskStatus,
    itemData,
    readonly,
    label,
    renderContext,
    formItemStyle,
    error,
    correctForm,
}) => {
    const handleOnChange = (time) => {
        onChange(time.target.value);
        // handleInputChange({ value: timeString, key: itemData.key, root: itemData.root, rowKey: itemData.recordKey });
    };

    // const value = useMemo(() => {
    //     const date = new Date();
    //     if (itemData.value === "{now()}") {
    //         return moment(`${date.getHours()}:${date.getMinutes()}`, format);
    //     } else if (!itemData.value || itemData.value === "__:__" || Array.isArray(itemData)) {
    //         return null;
    //     } else {
    //         return moment(itemData.value, format);
    //     }
    // }, [itemData.value]);

    const value = useMemo(() => {
        const date = new Date();
        if (itemData.value === "{now()}") {
            return `${date.getHours()}:${date.getMinutes()}`;
        } else if (!itemData.value || itemData.value === "__:__" || Array.isArray(itemData)) {
            return null;
        } else {
            return itemData.value;
        }
    }, [itemData.value]);

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus;

    return (
        <Form.Item style={formItemStyle} label={label}>
            {type === componentType._inputTime && (
                <>
                    {/* <TimePicker
                        popupClassName="maskOnTime"
                        value={value}
                        disabled={disabled}
                        // disabled={currentTaskStatus || canCorrectField(itemData)}
                        name={itemData["name"]}
                        style={renderContext === "column" ? staticStyles : dateStyles}
                        onChange={handleOnChange}
                        placeholder={itemData["@placeholder"] || "Hora"}
                        format={format}
                        // onOpenChange={(open) => {
                        //     if (open) {
                        //         window.document
                        //             .getElementsByClassName("ant-time-picker-panel-input")[0]
                        //             .setAttribute("type", "time");
                        //     }
                        // }}
                    /> */}
                    {/* <input type="time" disabled={disabled}></input> */}
                    <Input
                        type="time"
                        value={value}
                        disabled={disabled}
                        readOnly={readonly}
                        // disabled={currentTaskStatus || canCorrectField(itemData)}
                        name={itemData["name"]}
                        style={renderContext === "column" ? staticStyles : dateStyles}
                        onChange={handleOnChange}
                        placeholder={itemData["@placeholder"] || "Hora"}
                    />
                </>
            )}
            {error && <span style={{ color: "red" }}>{error}</span>}
        </Form.Item>
    );
};

export default QaTimePicker;
