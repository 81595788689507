import React, { useEffect, useState } from "react";
import { Modal, Tabs, Transfer, Row, Col, Select } from "antd";

import { Session } from "../../../business";
import { sendHttpRequest } from "../../http.request";
import { toArray } from "../../util";
import ClientTab from "./clients.tab";
import BusinessPartnerTab from "./business.partner.tab";
import PartnerTab from "./partners.tab";
import SuppliersTab from "./suppliers.tab";

const { TabPane } = Tabs;

// UO => valencias_actuais

// familhares => lista_clientes_inscritos

// geredatagrid =>  lista => reu_conv_participantes

export default function Stakeholders(props) {
    const { visible, setVisible, onOk, defaultValues } = props;

    const [stablishments, setStablishments] = useState([]);
    const [UO, setUO] = useState([]);
    // const [selectedClients, setSelectedClients] = useState([]);
    // const [selectedPartners, setSelectedPartners] = useState([]);
    // const [selectedBusinessParter, setSelectedBusinessParter] = useState([]);
    // const [selectedSuppliers, setSelectedSuppliers] = useState([]);

    const [suppliersDataSource, setSuppliersDataSource] = useState([]);
    const [businessPartnerDataSource, setBusinessPartnerDataSource] = useState([]);
    const [partnerDataSource, setPartnerDataSource] = useState([]);
    const [clientDataSource, setClientDataSource] = useState([]);

    useEffect(() => {
        const { tirceiros_clientes, tirceiros_parceiros, tirceiros_socios, tirceiros_fornecedores } = defaultValues;
        setBusinessPartnerDataSource(toArray(tirceiros_socios).map((it, index) => ({ ...it, key: index + 1 })));
        setClientDataSource(toArray(tirceiros_clientes).map((it, index) => ({ ...it, key: index + 1 })));
        setSuppliersDataSource(toArray(tirceiros_fornecedores).map((it, index) => ({ ...it, key: index + 1 })));
        setPartnerDataSource(toArray(tirceiros_parceiros).map((it, index) => ({ ...it, key: index + 1 })));
    }, [defaultValues]);

    useEffect(() => {
        const _getStablishments = async () => {
            try {
                const formData = new FormData();
                formData.append("accao", "acessos");
                formData.append("username", Session.getUsername());

                const response = await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);

                const estabelecimentos = toArray(response?.lista_estabelecimentos?.linha);

                setStablishments(estabelecimentos);
            } catch (error) {}
        };

        const _getUO = async () => {
            try {
                const formData = new FormData();
                // formData.append("accao", "query");
                // formData.append("query", "all_unidade_organica_v1");
                formData.append("query", "valencias_actuais");

                const response = await sendHttpRequest("POST", "/Gestor/execQuery.php", formData);

                const list = toArray(response?.result);

                setUO(list);
            } catch (error) {}
        };

        if (stablishments.length === 0) {
            _getStablishments();
        }
        if (UO.length === 0) {
            _getUO();
        }
    });

    const addLine = (type, props) => {
        let newKey = 0;
        if (type === "suppliers") {
            newKey = suppliersDataSource.length > 0 ? suppliersDataSource[suppliersDataSource.length - 1].key : 0;
            setSuppliersDataSource([...suppliersDataSource, { ...props, key: newKey + 1 }]);
        } else if (type === "businessPartner") {
            newKey =
                businessPartnerDataSource.length > 0
                    ? businessPartnerDataSource[businessPartnerDataSource.length - 1].key
                    : 0;
            setBusinessPartnerDataSource([...businessPartnerDataSource, { ...props, key: newKey + 1 }]);
        } else if (type === "partner") {
            newKey = partnerDataSource.length > 0 ? partnerDataSource[partnerDataSource.length - 1].key : 0;
            setPartnerDataSource([...partnerDataSource, { ...props, key: newKey + 1 }]);
        } else if (type === "clients") {
            newKey = clientDataSource.length > 0 ? clientDataSource[clientDataSource.length - 1].key : 0;
            setClientDataSource([...clientDataSource, { ...props, key: newKey + 1 }]);
        }

        return newKey + 1;
    };

    const removeLine = (type, key) => {
        if (type === "suppliers") {
            setSuppliersDataSource(suppliersDataSource.filter((it) => it.key !== key));
        } else if (type === "businessPartner") {
            setBusinessPartnerDataSource(businessPartnerDataSource.filter((it) => it.key !== key));
        } else if (type === "partner") {
            setPartnerDataSource(partnerDataSource.filter((it) => it.key !== key));
        } else if (type === "clients") {
            setClientDataSource(clientDataSource.filter((it) => it.key !== key));
        }
    };

    const putDataSource = (type, key, dataIndex, data) => {
        if (type === "suppliers") {
            const dataSourceCopy = [...suppliersDataSource];
            const index = suppliersDataSource.findIndex((item) => item.key === key);

            let current = dataSourceCopy[index];

            if (!current) {
                return;
            }

            if (current?.[dataIndex]) {
                current[dataIndex] = {
                    value: current[dataIndex],
                    dataSource: data,
                };
            } else if (!current) {
                current = {
                    [dataIndex]: {
                        value: "",
                        dataSource: data,
                    },
                };
            } else {
                current[dataIndex] = {
                    value: "",
                    dataSource: data,
                };
            }

            dataSourceCopy[index] = current;
            setSuppliersDataSource(dataSourceCopy);
        } else if (type === "clients") {
            const dataSourceCopy = [...clientDataSource];
            const index = clientDataSource.findIndex((item) => item.key === key);

            let current = dataSourceCopy[index];

            if (!current) {
                return;
            }

            if (current?.[dataIndex]) {
                current[dataIndex] = {
                    value: current[dataIndex],
                    dataSource: data,
                };
            } else if (!current) {
                current = {
                    [dataIndex]: {
                        value: "",
                        dataSource: data,
                    },
                };
            } else {
                current[dataIndex] = {
                    value: "",
                    dataSource: data,
                };
            }

            dataSourceCopy[index] = current;
            setClientDataSource(dataSourceCopy);
        }
    };

    const handleFieldsChange = (type, dataIndex, value, record, otherProps = {}) => {
        if (record?.[dataIndex] && typeof record?.[dataIndex] === "object") {
            record[dataIndex] = {
                ...record[dataIndex],
                value,
            };
        } else {
            record[dataIndex] = value;
        }

        if (type === "suppliers") {
            const dataSourceCopy = [...suppliersDataSource];
            const index = suppliersDataSource.findIndex((item) => item.key === record.key);
            dataSourceCopy[index] = { ...record, ...otherProps };
            setSuppliersDataSource(dataSourceCopy);
        } else if (type === "businessPartner") {
            const dataSourceCopy = [...businessPartnerDataSource];
            const index = businessPartnerDataSource.findIndex((item) => item.key === record.key);
            dataSourceCopy[index] = { ...record, ...otherProps };
            setBusinessPartnerDataSource(dataSourceCopy);
        } else if (type === "partner") {
            const dataSourceCopy = [...partnerDataSource];
            const index = partnerDataSource.findIndex((item) => item.key === record.key);
            dataSourceCopy[index] = { ...record, ...otherProps };
            setPartnerDataSource(dataSourceCopy);
        } else if (type === "clients") {
            const dataSourceCopy = [...clientDataSource];
            const index = clientDataSource.findIndex((item) => item.key === record.key);
            dataSourceCopy[index] = { ...record, ...otherProps };
            setClientDataSource(dataSourceCopy);
        }
    };

    return (
        <Modal
            title="Gerir partes interessadas"
            visible={visible}
            onCancel={() => setVisible(false)}
            width="80%"
            style={{ top: 20 }}
            okText="Guardar"
            onOk={() => {
                onOk({
                    selectedClients: clientDataSource,
                    selectedPartners: partnerDataSource,
                    selectedBusinessParter: businessPartnerDataSource,
                    selectedSuppliers: suppliersDataSource,
                });
                setVisible(false);
            }}
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="Clientes" key="1">
                    <ClientTab
                        UO={UO}
                        stablishments={stablishments}
                        dataSource={clientDataSource}
                        addLine={addLine}
                        removeLine={removeLine}
                        handleFieldsChange={handleFieldsChange}
                        putDataSource={putDataSource}
                    />
                </TabPane>
                <TabPane tab="Fornecedores" key="2">
                    <SuppliersTab
                        dataSource={suppliersDataSource}
                        addLine={addLine}
                        removeLine={removeLine}
                        handleFieldsChange={handleFieldsChange}
                        putDataSource={putDataSource}
                    />
                </TabPane>
                <TabPane tab="Parceiros" key="3">
                    <PartnerTab
                        dataSource={partnerDataSource}
                        addLine={addLine}
                        removeLine={removeLine}
                        handleFieldsChange={handleFieldsChange}
                        putDataSource={putDataSource}
                    />
                </TabPane>
                <TabPane tab="Sócios" key="4">
                    <BusinessPartnerTab
                        dataSource={businessPartnerDataSource}
                        addLine={addLine}
                        removeLine={removeLine}
                        handleFieldsChange={handleFieldsChange}
                        putDataSource={putDataSource}
                    />
                </TabPane>
            </Tabs>
        </Modal>
    );
}
