import React from "react";
import { Table, Pagination } from "antd";

interface Props<T = any> {
    data: T[];
    columns: any[];
    onRowClick?: (record: T) => void;
    pageChange: (current: number, pageSize?: number) => void;
    totalResult: number;
    currentPage: number;
    datakey: string;
    loadingData: boolean;
    tableChange?: (pagination: any, filters: any, sorter: any) => void;
    size?: "small" | "middle";
}
function QATable<T = any>({
    data,
    columns,
    pageChange,
    onRowClick,
    totalResult,
    currentPage,
    datakey,
    loadingData,
    tableChange,
    size,
}: Props<T>) {
    return (
        <>
            <Table
                size={size ?? "middle"}
                rowKey={(data, index) => (data as any)?.[datakey] + "_table_" + index}
                pagination={false}
                dataSource={data}
                loading={loadingData}
                columns={columns}
                onRowClick={onRowClick}
                onChange={tableChange}
                style={{ marginTop: 20 }}
            />
            <Pagination
                style={{ float: "right", marginBottom: 10 }}
                defaultCurrent={currentPage}
                defaultPageSize={10}
                size="small"
                total={totalResult}
                showSizeChanger
                showTotal={(total) => `Total: ${total}`}
                onShowSizeChange={(current, pageSize) => {
                    pageChange(current, pageSize);
                }}
                onChange={(current, pageSize) => {
                    pageChange(current, pageSize);
                }}
            />
        </>
    );
}

export default QATable;
