import { actionTypes } from "../constants/index";
import { sendHttpRequest } from "../../../components/http.request";

export const setOrganicUnitDomain = (payload) => ({
    type: actionTypes.setOrganicUnitDomain,
    payload,
});

export const setProcessCategoryDomain = (payload) => ({
    type: actionTypes.setProcessCategoryDomain,
    payload,
});

// get element from the server to populate alls select fields
export const selectDataForFixedSelectList = (requestBody, keyOfItemAsId) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/execQuery.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: actionTypes.genericSelectDataForFixedSelectList,
                payload: jsonResponse.result,
                keyOfItemAsId,
            });
            return true;
        }
    });
};

// get all organic by estabeleciment from the server
export const selectUnitOrganicByEstabeleciment = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: actionTypes.selectUnitOrganicByEstabeleciment,
                payload: jsonResponse["dados"],
            });
            return true;
        }
    });
};

// get all "estabelecimento" from the server
export const selectEstabeleciment = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: actionTypes.selectEstabeleciment,
                payload: jsonResponse["dados"],
            });
            return true;
        }
    });
};

// get all funcional group by nunit organic from the server
export const selectFuncionalGroupByUnitORganic = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: actionTypes.selectFuncionalGroupByUnitOrganic,
                payload: jsonResponse["dados"],
            });
            return true;
        }
    });
};

// get all contributors by estabeleciment - unit organic - functional group
export const selectAllContributors = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.linha === undefined) return false;
        else {
            dispatch({
                type: actionTypes.selectAllContributors,
                payload: jsonResponse,
            });
            return jsonResponse;
        }
    });
};
