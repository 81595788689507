import React, { useEffect, useState, useMemo } from "react";
import { Modal, message, Button, Typography, Table } from "antd";
import ExecutionForm from "../execution/execution.form";
import { getChild } from "../../constants/utils";
import {
    getFieldDataSource,
    getForm,
    getFormData,
    getCorrectionFormData,
    toggleModal,
    toggleIsFormReady,
    saveForm,
    getAoCarregar,
} from "../../actions/index";
import TaskExecutionFormProvider, { MyContext } from "../execution/execution.form.provider";
import { selectById } from "../../../dynamic_list/actions/";
import { componentType } from "../../constants/index";
import { isEmpty, canCorrectField } from "../../utils/fields";
import { validateFormAfterSave } from "../../utils/form";

const QaModal = ({
    visibleModalSee,
    setVisibleModalSee,
    state,
    modalOpened,
    eventKey,
    processId,
    isFormReady,
    taskId,
    processParentId,
    getFieldDecorator,
    handleModalOk,
    dispatch,
    correctForm,
    allPopupParams,
    currentTaskStatus,
    ...restProps
}) => {
    const [form, setForm] = useState(null);
    const [formMeta, setFormMeta] = useState(null);
    const [formItemsRender, setFormItemsRender] = useState(null);

    useEffect(() => {
        if (visibleModalSee) {
            handleGetCorrectionForm({ id: visibleModalSee });
        }
        if (!visibleModalSee) {
            setForm({});
        }
    }, [visibleModalSee]);

    const disableSaveButtom = useMemo(() => {
        if (!formMeta) {
            return false;
        }

        if (formMeta?.["@persiste"] === "nao") {
            return true;
        }

        return false;
    }, [formMeta]);

    const handleGetCorrectionForm = ({ id }) => {
        if (!id) {
            return;
        }

        // return dispatch(
        //     getFormData({
        //         form,
        //         id: triggerModalId,
        //         // formStruct,
        //         processId,
        //         taskId,
        //         allPopupParams,
        //     })
        // )?.then(({ form, formMeta, values }) => {
        //     getAoCarregar({ form, metaInfo: formMeta, values }).then((newForm) => {
        //         setForm(newForm);
        //         setFormMeta(formMeta);
        //     });
        // });

        return dispatch(
            getCorrectionFormData({
                id,
            })
        )?.then(({ form, formMeta, values }) => {
            getAoCarregar({ form, metaInfo: formMeta, values }).then((newForm) => {
                setForm(newForm);
                setFormMeta(formMeta);
            });
        });
    };

    const handleModalCancel = () => {
        setVisibleModalSee(null);
    };

    // const handleGetFormData = ({ form, id, formStruct }) => {
    //     return dispatch(
    //         getFormData({
    //             form,
    //             id,
    //             formStruct,
    //             processId,
    //             taskId,
    //         })
    //     ).then(({ form }) => {
    //         setForm(form);
    //     });
    // };

    const handleGetFormData = ({ form, id, formStruct }) => {
        // return dispatch(
        //     getFormData({
        //         form,
        //         id,
        //         formStruct,
        //         processId: processId,
        //         taskId: taskId,
        //         allPopupParams,
        //     })
        // );
    };

    const handleToggleModal = ({ ...props }) => {
        return dispatch(toggleModal({ ...props }));
    };

    useEffect(() => {
        if (form && Object.values(form)?.length > 0) {
            setFormItemsRender(Object.values(form));
        }
    }, [form]);

    return form && formMeta ? (
        <TaskExecutionFormProvider
            toggleIsFormReady={toggleIsFormReady}
            currentTaskStatus={true}
            selectById={({ ...props }) => selectById({ ...props })}
            getForm={handleGetCorrectionForm}
            saveForm={saveForm}
            getFormData={handleGetFormData}
            // getFormData={getFormData}
            taskId={taskId}
            processId={processId}
            getFieldDataSource={getFieldDataSource}
            dispatch={dispatch}
            toggleModal={handleToggleModal}
            form={form}
            formName={""}
            triggerModalId={() => {}}
            processParentId={processParentId}
            formMetaData={[{ meta: formMeta }]}
            modalParams={allPopupParams}
            correctForm={correctForm}
        >
            <MyContext.Consumer>
                {(context) => (
                    <RenderModal
                        visibleModalSee={visibleModalSee}
                        allPopupParams={allPopupParams}
                        dispatch={dispatch}
                        formName={""}
                        // form={context.state.form}
                        context={context}
                        currentTaskStatus={currentTaskStatus}
                        correctForm={correctForm}
                        setForm={setForm}
                        handleModalCancel={handleModalCancel}
                        handleModalOk={handleModalOk}
                        itemData={{}}
                        modalOpened={modalOpened}
                        modals={{}}
                        disableSaveButtom={disableSaveButtom}
                    >
                        <ExecutionForm
                            formItemsRender={formItemsRender?.filter(
                                (item) =>
                                    (!item.parent || item["@tipo"] === componentType._popup) &&
                                    (item["@tipo"] || item["@elemento_grafico"])
                            )}
                            conditions={state.conditions}
                            formName={""}
                            formItems={Object.values(form)}
                            dispatch={dispatch}
                            isFormReady={isFormReady}
                            saveForm={saveForm}
                            eventKey={eventKey}
                            isRoot={false}
                            {...restProps}
                        />
                    </RenderModal>
                )}
            </MyContext.Consumer>
        </TaskExecutionFormProvider>
    ) : null;
};

export default QaModal;

const RenderModal = ({
    visibleModalSee,
    children,
    correctForm,
    formName,
    allPopupParams,
    handleModalOk,
    dispatch,
    setForm,
    currentTaskStatus,
    handleModalCancel,
    disableSaveButtom,
    context,
}) => {
    const [loading, setLoading] = useState(false);

    const canEdit = correctForm;

    const disabled = canEdit ? false : currentTaskStatus;

    return (
        <>
            <Modal
                title={"Ver correção"}
                visible={!isEmpty(visibleModalSee)}
                okButtonProps={{
                    disabled: true,
                }}
                destroyOnClose
                onCancel={() => {
                    handleModalCancel();
                    setForm(null);
                }}
                footer={[
                    <Button
                        // disabled={disabled}
                        key="submit"
                        type="primary"
                        size="default"
                        loading={loading}
                        onClick={() => {
                            handleModalCancel();
                            setForm(null);
                        }}
                    >
                        ok
                    </Button>,
                ]}
                width="80%"
                bodyStyle={{
                    paddingTop: 50,
                }}
            >
                {children}
            </Modal>
        </>
    );
};
