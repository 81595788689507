import React, { useState } from "react";
import { Button, Modal, Input, message, Table, Divider } from "antd";
import { sendHttpRequest, api } from "../../../../components/http.request";
import { isEmpty } from "../../utils/fields";
import { Utilitaries } from "../../../../business";
import { toArray } from "../../../../components/util";
import CorrectTaskSee from "./correct.task.see";

const CorrectTask = ({
    processId,
    taskId,
    enableCorrectTask,
    formProps,
    handleVersionRollback,
    visibleModalSee,
    setVisibleModalSee,
}) => {
    const [visibleModal, setVisibleModal] = useState(false);

    const [visibleModalList, setVisibleModalList] = useState(false);
    const [listOfFix, setlistOfFix] = useState([]);
    const [reason, setReason] = useState("");

    //rootProps
    //rootElementProps

    const correct = () => {
        const formData = new FormData();
        formData.append("accao", "corrigir");
        formData.append("motivo", reason);
        formData.append("processo", processId);
        formData.append("tarefa", taskId);

        api("/Gestor/gereprocesso.php", formData)
            .then((res) => {
                setReason("");
                setVisibleModal(false);
                enableCorrectTask();
            })
            .catch((err) => {
                if (err?.erro) {
                    message.error(err.erro);
                }
            });
    };

    const correctList = () => {
        const formData = new FormData();
        formData.append("accao", "lista_correcoes");
        formData.append("id_tabela", formProps?.rootProps?.["@id_tabela"]);
        formData.append("elemento_raiz", formProps?.rootProps?.["@elemento_raiz"]);

        sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData)
            .then((res) => {
                setlistOfFix(toArray(res?.linha));
                setVisibleModalList(true);
            })
            .catch((err) => {
                if (err?.erro) {
                    message.error(err.erro);
                }
            });
    };

    const noAllowCorrectTask = () => {
        const message = isEmpty(formProps?.rootProps?.["@ajuda_corrigir"])
            ? "Após terminada a tarefa não é possível a sua correção. Poderá no entanto, pedir a anulação do processo e iniciar um novo."
            : formProps?.rootProps?.["@ajuda_corrigir"];

        Modal.warning({
            title: "Aviso",
            content: message,
        });
    };

    const correctTask = () => {
        if (
            !isEmpty(formProps?.rootElementProps?.["@corrigir"]) &&
            formProps?.rootElementProps?.["@corrigir"] === "nao"
        ) {
            noAllowCorrectTask();
        } else {
            setVisibleModal(true);
        }
    };

    if (
        Utilitaries.toNumber(formProps?.rootProps?.["@id_tabela"]) <= 0 ||
        (!isEmpty(formProps?.rootElementProps?.["@corrigir"]) && formProps?.rootElementProps?.["@corrigir"] === "nao")
    ) {
        return null;
    }

    const rollbackTo = (id) => {
        handleVersionRollback(id, () => {
            setVisibleModalList(false);
        });
    };

    const columns = [
        {
            title: "Data",
            dataIndex: "data_correcao",
            key: "data_correcao",
        },
        {
            title: "Utilizador",
            dataIndex: "user_correcao",
            key: "user_correcao",
        },
        {
            title: "Motivo",
            dataIndex: "motivo",
            key: "motivo",
        },
        {
            title: "Ação",
            render: (text, record) => {
                return (
                    <span>
                        <Button type="link" onClick={() => setVisibleModalSee(record.id)}>
                            Abrir
                        </Button>
                        <Divider type="vertical" />
                        <Button style={{ color: "red" }} type="link" onClick={() => rollbackTo(record.id)}>
                            Reverter
                        </Button>
                    </span>
                );
            },
        },
    ];

    return (
        <>
            {!isEmpty(formProps?.rootProps?.["@correcoes"]) &&
                Utilitaries.toNumber(formProps?.rootProps?.["@correcoes"]) > 0 && (
                    <Button
                        onClick={() => {
                            correctList();
                        }}
                        size="default"
                        type="link"
                        icon="form"
                        style={{ float: "right" }}
                    >
                        Lista de correções
                    </Button>
                )}

            <Button onClick={correctTask} size="default" type="link" icon="form" style={{ float: "right" }}>
                Corrigir
            </Button>

            <Modal
                title={`Alteração`}
                visible={visibleModalList}
                width="50%"
                destroyOnClose
                onCancel={() => {
                    setVisibleModalList(false);
                }}
                footer={null}
            >
                <Table dataSource={listOfFix} columns={columns} size="small" />
            </Modal>

            <Modal
                title={`Motivo da alteração`}
                visible={visibleModal}
                destroyOnClose
                onOk={() => {
                    correct();
                }}
                okButtonProps={{}}
                onCancel={() => {
                    setVisibleModal(false);
                    setReason("");
                }}
            >
                <Input.TextArea
                    onChange={(e) => setReason(e.target.value)}
                    autoSize={{ minRows: 10, maxRows: 10 }}
                    style={{ width: "100%" }}
                    value={reason}
                    compact
                    allowClear
                />
            </Modal>

            {/* <CorrectTaskSee fixID={visibleModalSee} onClose={() => setVisibleModalSee(null)} /> */}
        </>
    );
};

export default CorrectTask;
