import React, { Component } from "react";
import { Modal, Row, Descriptions, Col, Button, Icon } from "antd";

import { createPdfFile, prepareDataToReport } from "./createPdfReport";

class DetailsContributorsTimeRegistration extends Component {
    closeModal = () => {
        this.props.closeModal();
    };

    checkValuesAndReturnJsx = (user_data, system_data) => {
        if (typeof user_data !== "object") {
            if (typeof system_data !== "object") {
                if (user_data === system_data) {
                    return <span>------</span>;
                } else {
                    return <span style={{ color: "#1890ff" }}>{user_data}</span>;
                }
            } else {
                return <span>{user_data}</span>;
            }
        } else if (typeof system_data !== "object") {
            return <span>{system_data}</span>;
        }

        return <span>-----------</span>;
    };

    render() {
        const startDate =
            typeof this.props.detailsData.data_registo === "object"
                ? this.props.detailsData.system_data_entrada
                : this.props.detailsData.data_registo;
        return (
            <>
                <Modal
                    title="Registo de ponto detalhado"
                    onCancel={this.closeModal}
                    visible={this.props.isVisible}
                    style={{ top: 10 }}
                    width="80%"
                    footer={null}
                >
                    <Row>
                        <Button
                            onClick={() => {
                                const value = prepareDataToReport([this.props.detailsData]);
                                createPdfFile(value, {
                                    startDate: startDate,
                                    endDate: startDate,
                                });
                            }}
                            type="primary"
                            style={{
                                float: "right",
                                marginBottom: 16,
                            }}
                        >
                            <Icon type="file-pdf" style={{ marginRight: 12 }} />
                            Exportar para pdf
                        </Button>
                    </Row>
                    <Row gutter={18}>
                        <Descriptions
                            layout="vertical"
                            bordered
                            column={{
                                xxl: 2,
                                xl: 2,
                                lg: 2,
                                md: 2,
                                sm: 2,
                                xs: 1,
                            }}
                        >
                            <Descriptions.Item label="Nome">{this.props.detailsData.username}</Descriptions.Item>
                            <Descriptions.Item label="Data do Registo">
                                {typeof this.props.detailsData.data_registo === "object"
                                    ? this.props.detailsData.system_data_entrada
                                    : this.props.detailsData.data_registo}
                            </Descriptions.Item>
                        </Descriptions>

                        <Col xs={24} md={12}>
                            <Descriptions
                                title="Início da atividade"
                                layout="vertical"
                                style={{ marginTop: 30 }}
                                bordered
                                column={{
                                    xxl: 2,
                                    xl: 2,
                                    lg: 2,
                                    md: 2,
                                    sm: 2,
                                    xs: 1,
                                }}
                            >
                                <Descriptions.Item style={{ width: "25%" }} label="Data/Hora registada pelo sistema">
                                    {typeof this.props.detailsData.system_data_entrada === "object"
                                        ? `${this.props.detailsData.data_dia} ${this.props.detailsData.user_data_entrada}`
                                        : this.props.detailsData.system_data_entrada}
                                </Descriptions.Item>
                                <Descriptions.Item style={{ width: "25%" }} label="Data/Hora registada pelo utilizador">
                                    {this.checkValuesAndReturnJsx(
                                        this.props.detailsData.user_data_entrada,
                                        this.props.detailsData.system_data_entrada
                                    )}
                                    {/* {this.props.detailsData
										.user_data_entrada ===
									this.props.detailsData
										.system_data_entrada ? (
										<span>------</span>
									) : (
										<span style={{ color: "#1890ff" }}>
											{
												this.props.detailsData
													.user_data_entrada
											}
										</span>
									)} */}
                                </Descriptions.Item>
                                <Descriptions.Item span={2} label="Motivo da alteração da data / hora">
                                    {typeof this.props.detailsData.motivo_alteracao_data_entrada !== "object" ? (
                                        <span>{this.props.detailsData.motivo_alteracao_data_entrada}</span>
                                    ) : (
                                        <span>------</span>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={24} md={12}>
                            <Descriptions
                                title="Fim da atividade"
                                layout="vertical"
                                style={{ marginTop: 30 }}
                                bordered
                                column={{
                                    xxl: 2,
                                    xl: 2,
                                    lg: 2,
                                    md: 2,
                                    sm: 2,
                                    xs: 1,
                                }}
                            >
                                <Descriptions.Item label="Data/Hora registada pelo sistema">
                                    {typeof this.props.detailsData.system_data_saida === "object" &&
                                    typeof this.props.detailsData.data_dia !== "object"
                                        ? `${this.props.detailsData.data_dia} ${this.props.detailsData.user_data_entrada}`
                                        : typeof this.props.detailsData.user_data_saida !== "object"
                                        ? this.props.detailsData.user_data_saida
                                        : ""}
                                </Descriptions.Item>
                                <Descriptions.Item label="Data/Hora registada pelo utilizador">
                                    {this.checkValuesAndReturnJsx(
                                        this.props.detailsData.user_data_saida,
                                        this.props.detailsData.system_data_saida
                                    )}

                                    {/* {this.props.detailsData
										.system_data_saida ===
									this.props.detailsData.user_data_saida ? (
										<span>------</span>
									) : typeof this.props.detailsData
											.user_data_saida !== "object" ? (
										<span style={{ color: "#1890ff" }}>
											{
												this.props.detailsData
													.user_data_saida
											}
										</span>
									) : (
										<span>------</span>
									)} */}
                                </Descriptions.Item>
                                <Descriptions.Item span={2} label="Motivo da alteração da data / hora">
                                    {typeof this.props.detailsData.motivo_alteracao_data_saida !== "object" ? (
                                        <span>{this.props.detailsData.motivo_alteracao_data_saida}</span>
                                    ) : (
                                        <span>------</span>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={24}>
                            <Descriptions layout="vertical" style={{ marginTop: 30 }} bordered column={{ xs: 1 }}>
                                <Descriptions.Item label="Observação geral">
                                    {typeof this.props.detailsData.observacoes !== "object" ? (
                                        <span>{this.props.detailsData.observacoes}</span>
                                    ) : (
                                        <span>------</span>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </Modal>
            </>
        );
    }
}

export default DetailsContributorsTimeRegistration;
