import React, { useState, useEffect } from "react";

import { Modal, Button, message } from "antd";
import TaskReassign from "./task.reassign";
import { Utilitaries } from "../../../../business";
import Confirm from "../../../../components/qa_custom/confirm";

const TaskReassignModal = (props) => {
    const [dataSource, setDataSource] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        const list = [...props.data].map((el, idx) => {
            return { ...el, key: idx };
        });
        setDataSource(list);
    }, []);

    const onSave = async () => {
        setSaveLoading(true);
        const list = selectedRowKeys.map((el) => {
            return {
                ...dataSource[el],
            };
        });

        const userEmpty = list.find((el) => Utilitaries.isEmpty(el.user));
        if (userEmpty != null) {
            message.error("Campos obrigatórios em branco");
            return;
        }

        await props.handleOk(list);
        setSaveLoading(false);
    };

    const onCancelModal = () => {
        Confirm(() => {
            props.handleCancel();
        });
    };

    return (
        <Modal
            width="90%"
            style={{ top: "5%" }}
            title="Reatribuição Tarefas"
            okText="Confirmar Reatribuição"
            cancelText="Cancelar"
            visible={props.isVisible}
            footer={[
                <Button key="cancel" onClick={props.handleCancel}>
                    Cancelar
                </Button>,
                <Button
                    key="ok"
                    disabled={!selectedRowKeys.length > 0}
                    type="primary"
                    loading={saveLoading}
                    onClick={onSave}
                >
                    Confirmar Reatribuição
                </Button>,
            ]}
            onCancel={onCancelModal}
        >
            <TaskReassign
                dataSource={dataSource}
                setDataSource={setDataSource}
                isFetched={props.isFetched}
                groups={props.groups}
                usersByGroup={props.usersByGroup}
                tasksToReassign={props.tasksToReassign}
                handleGetUserByGroup={props.handleGetUserByGroup}
                handleGroupUserToDelegateChange={props.handleGroupUserToDelegateChange}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
            />
        </Modal>
    );
};

export default TaskReassignModal;
