import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Tooltip,
    Button,
    Checkbox,
    Table,
    PageHeader,
    Input,
    Icon,
    Divider,
    Pagination,
    message,
    Modal,
    Spin,
} from "antd";
import Highlighter from "react-highlight-words";

import ConfigDynamicList from "./config.dynamic.list";
import { select, insert } from "../actions/index";
import { query } from "../../../actions/makeHttpRequest";
import { tableSort, tableFooter, downloadFile } from "../../../components/util";
import GereList from "../../../components/DynamicList/GereLista";
import ACTION_TYPE from "../constants/action.type";

const IN_EXEC_QUERY = ["distrito"];

class DynamicList extends Component {
    componentDidMount() {
        if (!this.props.isDataLoadded) {
            this.getDynamicList();
        }
    }

    exportList = async (type) => {
        const REPORT_PARAMS = {
            accao: "lista",
            campos: "nome:24:Nome;descricao:24:Descrição;",
            titulo: "Listas dinâmicas",
        };

        const { sorter, ...objFilter } = this.state.filter;
        if (sorter) {
            objFilter["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        await downloadFile("gerelistasdinamicas.php", {
            ...REPORT_PARAMS,
            ...this.state.reportFilter.filterFields,
            ...objFilter,
            saida: type,
            downloadInBrowser: true,
        });
    };

    // get from the server
    getDynamicList = () => {
        this.setState({ isFetching: true });

        const { sorter, ...objFilter } = this.state.filter;
        if (sorter) {
            objFilter["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        this.props
            .select({
                accao: "lista",
                pagina: this.state.currentPage,
                obsoletos: this.state.obsoletos,
                resultados_pagina: this.state.pageSize,
                ...this.state.reportFilter.filterFields,
                ...objFilter,
            })
            .then((sucess) => {
                if (!sucess) message.error("Ocorreu um erro");
                this.setState({ isFetching: false });
            });
    };

    /** handle state */
    state = {
        obsoletos: "nao",
        currentPage: 1,
        pageSize: 10,
        isFetching: false,
        searchText: "",
        isVisibleToConfig: false,
        isVisibleToEdit: false,
        dataSource: {},
        filter: {},
        title: "",
        loadModel: false,
        reportFilter: {
            filterFields: {},
            sort: {},
        },
    };

    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar ${dataIndex}`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        // onFilter: (value, record) =>
        // 	record[dataIndex]
        // 		.toString()
        // 		.toLowerCase()
        // 		.includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: (visible) => {
        // 	if (visible) {
        // 		setTimeout(() => this.searchInput.select());
        // 	}
        // },
        filteredValue: this.state.filter[`filtro_${dataIndex}`] || null,
        // render: (text) => {
        // 	text = typeof text === "object" ? "" : text;
        // 	return (
        // 		<Highlighter
        // 			highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        // 			searchWords={[this.state.searchText]}
        // 			autoEscape
        // 			textToHighlight={text /*.toString()*/}
        // 		/>
        // 	);
        // },
    });

    handleSearch = (selectedKeys, confirm, dataIndex = null) => {
        this.setState({
            reportFilter: {
                ...this.state.reportFilter,
                filterFields: {
                    ...this.state.reportFilter.filterFields,
                    [`filtro_${dataIndex}`]: selectedKeys,
                },
            },
        });
        confirm();
        // this.setState({ searchText: selectedKeys });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        // this.setState({ searchText: "" });
    };

    getColumnSortProps = (dataIndex, sortedInfo) => {
        return {
            sorter: true,
            sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
        };
    };

    /****************************** */
    /** handle table column */
    sortedInfo = this.state.filter.sorter || {};

    column = [
        {
            title: "Nome",
            dataIndex: "nome",
            width: "25%",
            key: "nome",
            // sorter: (a, b) => tableSort(a.nome, b.nome),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("nome"),
            ...this.getColumnSortProps("nome", this.sortedInfo),
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            width: "60%",
            ellipsis: true,
            key: "descricao",
            // sorter: (a, b) => tableSort(a.descricao, b.descricao),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("descricao"),
            ...this.getColumnSortProps("descricao", this.sortedInfo),
            // render: (text) => {
            //     text = typeof text !== "object" ? text : "";
            //     return text.length >= 100 ? (
            //         <Tooltip title={text}>
            //             <div
            //                 style={{
            //                     textOverflow: "ellipsis",
            //                     width: " 500px",
            //                     whiteSpace: "nowrap",
            //                     overflow: "hidden",
            //                 }}
            //             >
            //                 {text}
            //             </div>
            //         </Tooltip>
            //     ) : (
            //         <div>{text}</div>
            //     );
            // },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "15%",
            render: (text, record) => (
                <>
                    <a href="javascript:;" onClick={() => this.showEditModal(record)}>
                        Editar
                    </a>
                    <Divider type="vertical" />
                    <a href="javascript:;" onClick={() => this.showConfigModal(record)}>
                        Configurar
                    </a>
                </>
            ),
        },
    ];

    /** close modal */
    hiddeEditModal = () => {
        this.setState({
            isVisibleToEdit: false,
        });
    };

    hiddeConfigModal = (reloadList = false) => {
        if (reloadList) this.getDynamicList();

        this.setState({
            isVisibleToConfig: false,
        });
    };

    /** show modal */
    showEditModal = async (record) => {
        this.setState({ loadModel: true });
        await this.onSaveGetData({ name: record.nome });
        this.setState({
            loadModel: false,
            dataSource: record,
            isVisibleToEdit: true,
        });
    };

    showConfigModal = (record) => {
        this.setState({
            dataSource: record,
            isVisibleToConfig: true,
        });
    };

    /** paginations functions */
    onShowSizeChange = (current, pageSize) => {
        this.setState({ currentPage: current, pageSize }, () => this.getDynamicList());
    };

    handlePageChange = (page, pageSize) => {
        this.setState({ ...this.state, currentPage: page, pageSize }, () => this.getDynamicList());
    };

    handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            if (!this.state.filter.sorter || this.state.filter.sorter.columnKey != sorter.columnKey) {
                obj["sorter"] = {
                    columnKey: sorter.columnKey,
                    order: sorter.order,
                };
            } else {
                obj["sorter"] = {
                    columnKey: sorter.columnKey,
                    order:
                        sorter.order === "descend" && this.state.filter.sorter.order === "descend"
                            ? "ascend"
                            : "descend",
                };
            }
        }

        this.setState({ ...this.state, currentPage: 1, filter: obj }, () => this.getDynamicList());
    };

    /** show hidden list */
    showHiddenList = (e) => {
        this.setState(
            {
                ...this.state,
                obsoletos: e.target.checked ? "sim" : "nao",
            },
            () => this.getDynamicList()
        );
    };

    onSaveGetData = async ({ name = null, openGereLista = false }) => {
        if (!name) return;
        // if (IN_EXEC_QUERY.includes(name)) {
        //     await this.props.getListDynamicItens({
        //         requestBody: {
        //             query: name,
        //             accao: "query",
        //         },
        //         url: "execQuery.php",
        //         // labels,
        //         // name,
        //         actionType: ACTION_TYPE.selectById,
        //     });
        // } else {
        await this.props.getListDynamicItens({
            requestBody: {
                accao: "consultar",
                nome: name,
            },
            url: "gerelistasdinamicas.php",
            actionType: ACTION_TYPE.selectById,
        });
        // }
    };

    clearFormFilters = () => {
        this.setState(
            {
                ...this.setState,
                filter: {},
                currentPage: 1,
            },
            () => this.getDynamicList()
        );
    };

    render() {
        return (
            <>
                {this.state.loadModel && (
                    <Modal
                        centered
                        visible={this.state.loadModel}
                        closable={false}
                        footer={null}
                        style={{ textAlign: "center" }}
                    >
                        <Spin size="large" />
                    </Modal>
                )}
                {this.state.isVisibleToEdit && (
                    <GereList
                        visible={this.state.isVisibleToEdit}
                        onCancel={this.hiddeEditModal}
                        name={this.state.dataSource.nome}
                        listOfItems={this.props.listOfItems[this.state.dataSource.nome]}
                        postDynamicList={this.props.postDynamicList}
                        onSaveGetData={this.onSaveGetData}
                    />
                )}
                {this.state.isVisibleToConfig && (
                    <ConfigDynamicList
                        dataSource={this.state.dataSource}
                        isVisible={this.state.isVisibleToConfig}
                        hiddeModal={this.hiddeConfigModal.bind(this)}
                    />
                )}
                <PageHeader title="Listas dinâmicas" />
                <Row>
                    <Checkbox
                        style={{
                            bottom: 0,
                        }}
                        onChange={this.showHiddenList}
                    >
                        Mostrar listas ocultas
                    </Checkbox>
                    <Tooltip title="Exportar para pdf">
                        <Button
                            onClick={() => this.exportList("pdf")}
                            icon="file-pdf"
                            size="default"
                            style={{
                                marginLeft: 8,
                                color: "#fa541c",
                                float: "right",
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Exportar para excel">
                        <Button
                            onClick={() => this.exportList("xls")}
                            icon="file-excel"
                            size="default"
                            style={{
                                marginLeft: 8,
                                color: "#237804",
                                float: "right",
                            }}
                        />
                    </Tooltip>

                    {Object.keys(this.state.filter).length !== 0 && (
                        <Button
                            onClick={this.clearFormFilters}
                            type="link"
                            size="default"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "right",
                            }}
                        >
                            Limpar filtros
                        </Button>
                    )}
                </Row>
                <Table
                    rowKey={(record, index) => `${record.nome}_${index}`}
                    size="middle"
                    columns={this.column}
                    dataSource={this.props.dynamicLists}
                    footer={() => tableFooter(this.props.totalRegisto)}
                    loading={this.state.isFetching}
                    pagination={false}
                    onChange={this.handleTableChange}
                />
                {this.props.isDataLoadded ? (
                    <Pagination
                        style={{ float: "right" }}
                        showSizeChanger
                        size="small"
                        onChange={this.handlePageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultPageSize={parseInt(this.props.defaultPageSize)}
                        defaultCurrent={parseInt(this.props.defaultCurrent)}
                        total={parseInt(this.props.totalRegisto)}
                    />
                ) : null}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        listOfItems: state.dynamicList.domain.byId,
        dynamicLists: Object.values(state.dynamicList.domain.byId),
        isDataLoadded: state.dynamicList.app.isFetched,
        totalRegisto: state.dynamicList.domain.metaData.attributes.resultados,
        defaultCurrent: state.dynamicList.domain.metaData.attributes.pagina,
        defaultPageSize: state.dynamicList.domain.metaData.attributes.resultados_pagina,
    };
};
const mapDispatchToProps = {
    select,
    postDynamicList: insert,
    getListDynamicItens: query,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicList);
