import React, { useEffect, useState } from "react";
import { Transfer, Row, Col, Popconfirm, Input, Checkbox, Select } from "antd";

import EditableFormTable from "./tableEditor";
import { sendHttpRequest } from "../../http.request";
import { toArray } from "../../util";

const TAB_NAME = "partner";

export default function PartnerTab({
    setSelectedPartners,
    selectedPartners,
    dataSource,
    handleFieldsChange,
    addLine,
    removeLine,
}) {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        const getPartners = async () => {
            try {
                setPartners([]);
                const formData = new FormData();
                formData.append("query", "buscar_entidades_parceiras");

                const response = await sendHttpRequest("POST", "/Gestor/execQuery.php", formData);

                const _clients = toArray(response?.result).map((it) => ({
                    id: it.id_parceiro,
                    nome: it.designacao,
                    email: "",
                    telemovel: "",
                }));

                setPartners(_clients);
            } catch (error) {}
        };

        getPartners();
    }, []);

    const addNewLine = async () => {
        addLine(TAB_NAME, {});
    };

    const columns = [
        {
            title: "Entidade",
            dataIndex: "name",
            width: "30%",
            render: (text, record) => {
                const dValue = typeof text === "object" ? text?.value : text || "";
                return (
                    <Select
                        defaultValue={dValue}
                        onChange={(value) => {
                            const otherProps = {};
                            if (record?.send_email || record?.send_sms) {
                                const list = toArray(partners).find((it) => it.id === value);
                                otherProps.email = record?.send_email ? list?.email || "" : "";
                                otherProps.number = record?.send_sms ? list?.telemovel || "" : "";
                            }
                            handleFieldsChange(TAB_NAME, "name", value, record, otherProps);
                        }}
                        mode="default"
                        style={{ width: "100%" }}
                        placeholder="Selecionar fornecedor"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {toArray(partners).map((e) => (
                            <Select.Option key={e.id} value={e.id}>
                                {e.nome}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Enviar email",
            dataIndex: "send_email",
            width: "10%",
            render: (text, record) => {
                return (
                    <Checkbox
                        defaultChecked={text}
                        onChange={(e) => {
                            if (e.target.checked) {
                                const list = toArray(record?.name?.dataSource).find(
                                    (it) => it.id === record?.name?.value
                                );

                                if (list) {
                                    record.email = list?.email || "";
                                }
                            } else {
                                record.email = "";
                            }

                            handleFieldsChange(TAB_NAME, "send_email", e.target.checked, record);
                        }}
                    >
                        Email
                    </Checkbox>
                );
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "30%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : null;
                return record.send_email ? (
                    <Input
                        placeholder="Introduza o email"
                        onChange={(e) => handleFieldsChange(TAB_NAME, "email", e.target.value, record)}
                        value={text}
                    />
                ) : null;
            },
        },
        {
            title: "Enviar SMS",
            dataIndex: "send_sms",
            width: "10%",
            render: (text, record) => {
                return (
                    <Checkbox
                        defaultChecked={text}
                        onChange={(e) => {
                            if (e.target.checked) {
                                const list = toArray(record?.name?.dataSource).find(
                                    (it) => it.id === record?.name?.value
                                );

                                if (list) {
                                    record.number = list?.telemovel || "";
                                }
                            } else {
                                record.number = "";
                            }
                            handleFieldsChange(TAB_NAME, "send_sms", e.target.checked, record);
                        }}
                    >
                        SMS
                    </Checkbox>
                );
            },
        },
        {
            title: "Telefone",
            dataIndex: "number",
            width: "20%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : null;
                return record.send_sms ? (
                    <Input
                        placeholder="Introduza o número"
                        onChange={(e) => handleFieldsChange(TAB_NAME, "number", e.target.value, record)}
                        value={text}
                        type="number"
                    />
                ) : null;
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) => (
                <Popconfirm
                    title="Tens certeza que desejas eliminar?"
                    onConfirm={() => removeLine(TAB_NAME, record.key)}
                >
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Eliminar
                    </a>
                </Popconfirm>
            ),
        },
    ];

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <EditableFormTable dataSource={dataSource} addLine={addNewLine} columns={columns} />
            </Col>
        </Row>
    );
}
