export const actionTypes = {
    setProcessCategoryDomain: "SET_PROCESS_CATEGORY_DOMAIN",
    setOrganicUnitDomain: "SET_ORGANIC_UNIT_DOMAIN",
    selectEstabeleciment: "SELECT_ESTABELECIMENT",
    selectUnitOrganicByEstabeleciment: "SELECT_UNIT_ORGANIC_BY_ESTABELECIMENT",
    selectFuncionalGroupByUnitOrganic: "SELECT_FUNCIONAL_GROUP_BY_UNIT_ORGANIC",
    selectAllContributors: "SELECT_ALL_CONTRIBUTORS_BY_PARAM",
    genericSelectDataForFixedSelectList: "GENERIC_SELECT_DATA_FOR_FIXED_SELECT_LIST",
};

export const domainTypes = {
    processCategory: "PROCESS_CATEGORY",
    organicUnit: "ORGANIC_UNIT",
};
