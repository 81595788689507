import { actionType } from "./../constants/competence.type";
import { arrayToObjectById } from "./../../../components/util";

const initialState = {
    domain: {
        form: {
            designacao: { name: "designacao", value: null },
            descricao: { name: "descricao", value: null },
        },
        byId: {},
        metaData: {
            attributes: {},
        },
    },
    app: {
        isFetched: false,
    },
};

export const competenceTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.insert:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        designacao: { name: "designacao", value: null },
                        descricao: { name: "descricao", value: null },
                    },
                },
            };

        case actionType.setField:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        ...state.domain.form,
                        ...action.payload,
                    },
                },
            };
        case actionType.select:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: Array.isArray(action.payload["linha"])
                            ? action.payload["linha"]
                            : [action.payload["linha"]],
                        id: "id_tipo_competencia",
                    }),
                    metaData: {
                        ...state.domain.metaData,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };
        case actionType.update:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    form: {
                        designacao: { name: "designacao", value: null },
                        descricao: { name: "descricao", value: null },
                    },
                },
            };
        default:
            return state;
    }
};
