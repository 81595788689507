import React, { useState, useEffect } from "react";

import { Modal, Button, message, Select, Table } from "antd";
import { Utilitaries } from "../../../../business";
import Confirm from "../../../../components/qa_custom/confirm";
import { sendHttpRequest } from "../../../../components/http.request";

const TaskDelegateModal = ({ data, isVisible, handleOk, handleCancel, groups, isFetched }) => {
    const [dataSource, setDataSource] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [usersByGroup, setUsersByGroup] = useState({});

    useEffect(() => {
        const list = [...data].map((el, idx) => {
            return { ...el, key: idx };
        });
        setDataSource(list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserByGroup = (group) => {
        if (!Utilitaries.isEmpty(usersByGroup[group])) {
            return;
        }
        const formData = new FormData();
        formData.append("accao", "utilizadores_grupo");
        formData.append("grupo", group);
        sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData).then((data) => {
            setUsersByGroup({ ...usersByGroup, [group]: Utilitaries.toArray(data.linha) });
        });
    };

    const onSave = async () => {
        const list = selectedRowKeys.map((el) => {
            return {
                ...dataSource[el],
            };
        });

        const userEmpty = list.find((el) => Utilitaries.isEmpty(el.user));
        if (userEmpty != null) {
            message.error("Campos obrigatórios em branco");
            return;
        }

        setSaveLoading(true);
        await handleOk(list);
        setSaveLoading(false);
    };

    const onCancelModal = () => {
        Confirm(() => {
            handleCancel();
        });
    };

    function onUserChange({ user, record }) {
        const recordToChange = { ...record, user };
        const list = [...dataSource];
        const index = list.findIndex((item) => recordToChange.key === item.key);
        list[index] = recordToChange;
        setDataSource(list);
    }

    const handleGroupChange = (group, record) => {
        const recordToChange = { ...record, group, user: null };
        const list = [...dataSource];
        const index = list.findIndex((item) => recordToChange.key === item.key);
        list[index] = recordToChange;
        setDataSource(list);
        getUserByGroup(group);
        // handleGetUserByGroup({ group, task: record.id });
    };

    const rowSelection = {
        selectedRowKeys,
        onSelect: (record, selected, selectedRows, nativeEvent) => {
            setSelectedRowKeys(selectedRows.map((el) => el.key));

            const recordToChange = { ...record, isChecked: selected };
            const list = [...dataSource];
            const index = list.findIndex((item) => recordToChange.key === item.key);
            list[index] = recordToChange;
            setDataSource(list);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    const getStyleSelect = (value, record) => {
        const styleError =
            record.isChecked === true && Utilitaries.isEmpty(value)
                ? {
                      backgroundColor: "#e78080",
                      border: "1px solid #f5222d",
                  }
                : {};
        return styleError;
    };

    const columns = [
        {
            title: "Nº Tarefa",
            dataIndex: "id",
            width: "10%",
        },
        {
            title: "Processo",
            dataIndex: "processo",
            width: "20%",
        },
        {
            title: "Tarefa",
            dataIndex: "tarefa",
            width: "20%",
        },
        {
            title: "Responsável Execução (Grupo)",
            dataIndex: "group",
            width: "25%",
            render: (text, record) => {
                return (
                    <Select
                        showSearch
                        allowClear
                        style={{
                            width: "100%",
                            ...getStyleSelect(text, record),
                        }}
                        placeholder="Selecione o grupo"
                        optionFilterProp="children"
                        onChange={(value) => {
                            handleGroupChange(value, record);
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {groups.map((group) => {
                            const groupName = typeof group.grupo === "object" ? "" : group.grupo;
                            return <Select.Option value={group.id}>{groupName}</Select.Option>;
                        })}
                    </Select>
                );
            },
        },
        {
            title: "Responsável Execução (Indivíduo)",
            dataIndex: "user",
            width: "25%",
            render: (text, record) => {
                const list = Utilitaries.toArray(usersByGroup[record.group]);
                return (
                    <Select
                        showSearch
                        allowClear
                        style={{ width: "100%", ...getStyleSelect(text, record) }}
                        placeholder="Selecione o responsável"
                        optionFilterProp="children"
                        onChange={(value) => onUserChange({ user: value, record })}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={text || undefined}
                    >
                        {list.map((user) => {
                            return <Select.Option value={user.util}>{user.nome}</Select.Option>;
                        })}
                    </Select>
                );
            },
        },
    ];

    return (
        <Modal
            width="90%"
            style={{ top: "5%" }}
            title="Delegação Tarefas"
            okText="Confirmar Delegação"
            cancelText="Cancelar"
            visible={isVisible}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button
                    key="ok"
                    disabled={!selectedRowKeys.length > 0}
                    type="primary"
                    loading={saveLoading}
                    onClick={onSave}
                >
                    Confirmar Delegação
                </Button>,
            ]}
            onCancel={onCancelModal}
        >
            <Table
                size="small"
                loading={!isFetched}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
            />
            {/* <TaskReassign
                dataSource={dataSource}
                setDataSource={setDataSource}
                isFetched={props.isFetched}
                groups={props.groups}
                usersByGroup={props.usersByGroup}
                tasksToReassign={props.tasksToReassign}
                handleGetUserByGroup={props.handleGetUserByGroup}
                handleGroupUserToDelegateChange={props.handleGroupUserToDelegateChange}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
            /> */}
        </Modal>
    );
};

export default TaskDelegateModal;
