import React, { useState, useEffect } from "react";
import { Row, Col, Table, Input, Popconfirm, Form, Button, Icon } from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";

let dragingIndex = -1;

const BodyRow = (props) => {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
        if (restProps.index > dragingIndex) {
            className += " drop-over-downward";
        }
        if (restProps.index < dragingIndex) {
            className += " drop-over-upward";
        }
    }

    return connectDragSource(connectDropTarget(<tr {...restProps} className={className} style={style} />));
};

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource("row", rowSource, (connect) => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow)
);

const EditableContext = React.createContext();

const EditableCell = (props) => {
    const renderCell = ({ getFieldDecorator }) => {
        const { editing, dataIndex, inputType, record, index, children, ...restProps } = props;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: "Informe o nome da pasta",
                                },
                            ],
                            initialValue: record.nome,
                        })(<Input />)}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    return <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>;
};

const createFolder = (props) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                props.changeFolderCreate(values.name);
            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <Form layout="inline" onSubmit={handleSubmit}>
            <Form.Item label="Nome da Pasta">
                {getFieldDecorator("name", {
                    rules: [
                        {
                            required: true,
                            message: "Informe o nome da pasta",
                        },
                    ],
                })(<Input placeholder="Nome da Pasta" />)}
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Adicionar
                </Button>
            </Form.Item>
        </Form>
    );
};

const CreateForm = Form.create()(createFolder);

const Folders = (props) => {
    const [editingKey, setEditingKey] = useState("");
    const [state, setState] = useState({ data: props.folders });
    const [isOrderChange, setOrderChange] = useState(false);

    useEffect(() => {
        setState({ data: props.folders });
    }, [props.folders]);

    const isEditing = (record) => record.id === editingKey;

    const save = (form, key) => {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            props.changeFolderName(row.nome, key);
            setState({
                data: state.data.map((item) => {
                    return item.id === key ? { ...item, nome: row.nome } : item;
                }),
            });
            setEditingKey("");
        });
    };

    const changeFolderOrder = () => {
        props.changeFolderOrder(state.data);
        setOrderChange(false);
    };

    const _columns = [
        {
            title: "Nome",
            dataIndex: "nome",
            width: "75%",
            editable: true,
        },
        {
            title: "Ação",
            dataIndex: "action",
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <EditableContext.Consumer>
                            {(form) => (
                                <a onClick={() => save(form, record.id)} style={{ marginRight: 8 }}>
                                    Salvar
                                </a>
                            )}
                        </EditableContext.Consumer>
                        <Popconfirm title="Desejas cancelar?" onConfirm={() => setEditingKey("")}>
                            <a>Cancelar</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <>
                        <Button
                            type="link"
                            icon="edit"
                            disabled={editingKey !== ""}
                            onClick={() => setEditingKey(record.id)}
                        />
                        <Popconfirm
                            title="Eliminar a pasta？"
                            okText="Sim"
                            cancelText="Cancelar"
                            onConfirm={() => props.deletefolder(record.id)}
                        >
                            <Button type="link" icon="delete" disabled={editingKey !== ""} />
                        </Popconfirm>

                        {/* <Icon type="edit" disabled={editingKey !== ""} onClick={() => setEditingKey(record.id)} />
                        <Icon
                            type="delete"
                            disabled={editingKey !== ""}
                            onClick={() => props.deletefolder(record.id)}
                        /> */}
                    </>
                );
            },
        },
    ];

    const components = {
        body: {
            cell: EditableCell,
            row: DragableBodyRow,
        },
    };

    const moveRow = (dragIndex, hoverIndex) => {
        const { data } = state;
        const dragRow = data[dragIndex];

        setState(
            update(state, {
                data: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                },
            })
        );

        setOrderChange(true);
    };

    const columns = _columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <div>
            <h2>Gestão de Pastas</h2>
            <Row>
                <Col span={24}>
                    <p>Criar Pasta</p>
                    <CreateForm changeFolderCreate={props.changeFolderCreate} />
                </Col>
                <Col span={24}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <p>Pastas Existentes</p>
                        {isOrderChange && (
                            <Button type="primary" size="small" onClick={() => changeFolderOrder()}>
                                Salvar ordem
                            </Button>
                        )}
                    </div>
                    <EditableContext.Provider value={props.form}>
                        <DndProvider backend={HTML5Backend}>
                            <Table
                                rowKey="id"
                                size="small"
                                components={components}
                                bordered
                                dataSource={state.data}
                                columns={columns}
                                rowClassName="editable-row"
                                onRow={(record, index) => ({
                                    index,
                                    moveRow: moveRow,
                                })}
                                //   pagination={{
                                //     onChange: this.cancel,
                                //   }}
                            />
                        </DndProvider>
                    </EditableContext.Provider>
                </Col>
            </Row>
        </div>
    );
};

export default Form.create()(Folders);
