import React from "react";
import { Select, Form } from "antd";

const { Option } = Select;

const ProcessFilter = ({
    selectedMacroProcess,
    selectedProcess,
    macroProcesses,
    processes,
    changeMacroProcess,
    changeProcess,
}) => {
    return (
        <Form layout="inline">
            <Form.Item label="Macro-Processo">
                <Select
                    style={{ width: 230 }}
                    allowClear
                    onChange={(value) => {
                        changeMacroProcess(value);
                    }}
                >
                    {macroProcesses.map(({ id_parametro, valor }) => (
                        <Option key={id_parametro}>{valor}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item label="Meso-Processo">
                <Select
                    style={{ width: 220 }}
                    value={selectedProcess ? selectedProcess : undefined}
                    allowClear
                    disabled={!selectedMacroProcess}
                    onChange={(value) => changeProcess(value)}
                >
                    {processes.map(({ id_parametro, valor }) => (
                        <Option key={id_parametro}>{valor}</Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    );
};

export default ProcessFilter;
