import React, { memo, useEffect, useState } from "react";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";
import { FormLabel } from "../Components/FormLabel";
import { Utilitaries } from "../../../../business";
import { getSelectDataKeys } from "../../../utils/FormMapper/formHandle";
import { useData } from "../hooks/useData";
import { CommunComponentsProps } from "../../../types/common";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { useInputHandle } from "../hooks/InputHandle";
import { mapLoad } from "../../../utils/loadMap";
import { Group } from "../Components/Group";
import { compareProps, getFullPathRow } from "../handle/inputHandle";
import { highlightsErrors } from "../../../utils/functions";
import { SelectInput } from "../../SelectInput";

interface ComponentsPropertiesProps extends CommunComponentsProps {}

const _QASelect: React.FC<ComponentsPropertiesProps> = ({
    componentsProperties,
    renderContext,
    formState,
    formInputDependenceState,
    inputCalcTrigger,
    tableRow,
    handleOnInputChangeValue,
    justSee,
    triedSave,
    rootElementAttribute,
}) => {
    const [showError, setShowError] = useState(false);

    print_when_component_is_rendered(componentsProperties);

    useEffect(() => setShowError(triedSave), [triedSave]);

    const { data, status: dataStatus } = useData({
        endPoint: "execQuery.php",
        componentsProperties,
        state: formState,
        rootElementAttribute,
    });

    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);
    const full_path = tableRow?.row_key ? fullPathRow : componentsProperties?.full_path;

    const {
        data: whenValueChangeData,
        status: whenValueChangeStatus,
        hasUpdatedData,
        componentValue,
        hasUpdatedValue,
        required,
        restriction,
        visible,
        relevant,
        readOnly,
        error,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[full_path!],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        value,
        showError,
        rowTable: tableRow,
    });

    useEffect(() => {
        if (hasUpdatedValue && whenValueChangeStatus !== "IDLE") {
            handleOnInputChangeValue({
                value: componentValue,
                componentsProperties,
                row_key: tableRow?.row_key,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentValue, hasUpdatedValue]);

    let { key } = getSelectDataKeys(componentsProperties);

    let dataSource = hasUpdatedData ? [...whenValueChangeData] : [...data];

    if (!visible || !relevant) {
        return null;
    }

    const selectedDefaultValue = () => {
        const _value_ = Utilitaries.nullSafe(value);

        const item = Utilitaries.toArray(dataSource)?.find(
            (it: any) => Utilitaries.toString(it[key]) === Utilitaries.toString(_value_)
        );

        if (item) {
            return String(Utilitaries.toString(item[key]));
        }

        return "";
    };

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={required} renderContext={renderContext}>
                {componentsProperties.type === COMPONENT_TYPE.SELECT && (
                    <SelectInput
                        options={dataSource}
                        value={selectedDefaultValue()}
                        disabled={justSee || readOnly}
                        key_list={componentsProperties.key_list!}
                        value_list={componentsProperties.value_list!}
                        typeList={"dinamica"}
                        loading={mapLoad([dataStatus, whenValueChangeStatus])}
                        style={{ ...highlightsErrors({ value, required, restriction }), width: "100%" }}
                        onChange={(value, option) => {
                            const select_value_text = option?.title;
                            handleOnInputChangeValue({
                                value,
                                componentsProperties,
                                select_value_text,
                                row_key: tableRow?.row_key,
                            });
                        }}
                    />
                )}
                {showError && error && <span style={{ color: "red" }}>{error}</span>}
            </FormLabel>
        </Group>
    );
};

export const QASelect = memo(_QASelect, (prev, next) => compareProps<ComponentsPropertiesProps>(prev, next));
