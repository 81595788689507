import React, { Component } from "react";
import { Form, Radio, Row, Col, Modal, Select, message, Upload, Button, Icon, Table, Input, Badge } from "antd";
import { qaStandartActions } from "../../constants/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import QaDynamicSelect from "../../../../components/DynamicList/dynamicSelect";
import { selectDataForFixedSelectList as selectFixeList } from "./../../../fixed_list/actions/index";
import Scale from "./scale";
import Confirm from "./../../../../components/qa_custom/confirm";

import { ButtonDownloadTemplate } from "./button.download";
import { getFormulario, insert, exportStandart } from "../../actions/qa.standart";

const { Option } = Select;

export class QaStandartsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            action: qaStandartActions.export,
            importedFile: "",
            dataSource: [],
            file: [],
            isUploaded: false,
            areaLoading: true,
            labels: [],
            isVisibleScaleModal: false,
            globalScale: {
                designations: null,
                escala: null,
            },
            loadingImport: false,
            exportLoading: false,
            reloadList: false,
            loadingListNorma: false,
            loadingFormulario: false,
        };
    }

    componentDidMount() {
        this.setState({ loadingListNorma: true, loadingFormulario: true });
        this.props.getFormulario().then(() => {
            this.setState({ loadingFormulario: false });
        });
        this.props.selectFixeList({ query: "listar_normas" }, "lista_normas").then(() => {
            this.setState({ loadingListNorma: false });
        });
    }

    /***** handle form dynamic list */
    hiddeEditModal = () => {
        this.setState({
            isVisibleToEdit: false,
        });
    };
    showEditModel = () => {
        this.setState({
            isVisibleToEdit: true,
        });
    };
    /** end dynamic list */

    /** handle ok */
    handleResponse = (response) => {
        this.setState({ loadingImport: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleOk();
        } else message.error("Ocorreu um erro");
    };

    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.state.action === qaStandartActions.import) {
                    this.setState({ loadingImport: true });
                    values.list = [...this.state.dataSource];
                    values.globalScale = this.state.globalScale;

                    this.props
                        .insert({
                            values,
                            file: this.state.file,
                            fileName: this.state.fileName,
                            formulario: this.props.formulario,
                        })
                        .then((response) => {
                            this.handleResponse(response);
                        });
                }
            }
        });
    };

    handleActionChange = (e) => {
        this.setState({
            action: e.target.value,
            importedFile: "",
            dataSource: [],
            file: [],
            isUploaded: false,
            labels: [],
            globalScale: {
                designations: null,
                escala: null,
            },
        });
    };

    onChange = (dataIndex, value, record) => {
        const recordToChange = { ...record };
        recordToChange[dataIndex] = value;
        const dataSource = [...this.state.dataSource];

        const index = dataSource.findIndex((item) => recordToChange.key === item.key);
        dataSource[index] = recordToChange;
        this.setState({
            dataSource,
        });
    };

    export = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    exportLoading: true,
                });

                this.props
                    .exportStandart({
                        values,
                        formulario: this.props.formulario,
                    })
                    .then((response) => {
                        this.setState({
                            exportLoading: false,
                            reloadList: true,
                        });
                        if (response) message.success("Norma exportada");
                        else message.error("Ocorreu um erro");
                    });
            }
        });
    };

    /**Scale */
    showGlobalScale = () => {
        this.setState({
            isVisibleScaleModal: true,
            scaleDataModal: {
                "Nome escala": this.state.globalScale.designations,
                Escala: this.state.globalScale.escala,
            },
        });
    };

    showScaleModal = (record) => {
        this.setState({
            isVisibleScaleModal: true,
            scaleDataModal: record,
        });
    };

    handleCancelScaleModal = (e) => {
        this.setState({
            isVisibleScaleModal: false,
        });
    };

    handleCancel = () => {
        if (this.state.reloadList) this.props.handleOk();
        else this.props.handleCancel();
    };

    render() {
        const resetForm = {
            dataSource: [],
            globalScale: {
                designations: null,
                escala: null,
            },
            isUploaded: false,
            labels: [],
            file: [],
            fileName: null,
        };
        const props = {
            name: "file",
            accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
            headers: {
                authorization: "authorization-text",
            },
            encoding: "UTF-8",
            customRequest: ({ file, onSuccess }) => {
                setTimeout(() => {
                    onSuccess("ok");
                }, 0);
            },
            onRemove: () => {
                this.setState({ ...resetForm, isUploaded: false });
                this.props.form.setFields({
                    nome_norma: { value: null },
                });
            },
            beforeUpload: async (file) => {
                const Papa = require("papaparse/papaparse.min.js");
                let data = [];
                const react = this;
                let fileReaded;
                //===> read file async
                const readAsync = () =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            resolve(reader.result);
                        };
                        reader.onerror = reject;
                    });

                // ===> read file safely
                try {
                    fileReaded = await readAsync();
                } catch (err) {}
                return new Promise((resolve, reject) => {
                    Papa.parse(file, {
                        header: true,
                        download: true,
                        skipEmptyLines: true,
                        complete: function (results) {
                            data = results.data;
                            const firstLine = data[0];
                            if (!firstLine["Norma"]) {
                                Modal.error({
                                    title: "Ocorreu um erro",
                                    content:
                                        "O ficheiro não está preenchido corretamente. Podes descarregar o template e preencher.",
                                });
                                react.setState(() => {
                                    return {
                                        ...resetForm,
                                    };
                                });
                                reject(false);
                            } else {
                                const labelsTable = data[0].Etiqueta;
                                let columns = [];
                                if (labelsTable) {
                                    columns = labelsTable.split("£");
                                }
                                const list = [];
                                data.forEach((item) => {
                                    const text = item["Dimensão 1"] ? item["Dimensão 1"].trim() : null;
                                    if (text !== null && text !== "") list.push({ ...item });
                                });

                                const globalScale = {
                                    designations: null,
                                    escala: null,
                                };
                                const firstDimension = data[0]["Dimensão 1"].trim();
                                const nameNorma = data[0]["Norma"].trim();
                                if (firstDimension === "" && nameNorma !== "") {
                                    globalScale.designations = data[0]["Nome escala"];
                                    globalScale.categories = data[0]["Categoria"];
                                    globalScale.escala = data[0]["Escala"];
                                }

                                react.setState(() => {
                                    return {
                                        dataSource: list,
                                        globalScale: {
                                            ...globalScale,
                                        },
                                        isUploaded: true,
                                        labels: columns,
                                        file,
                                        // file: fileReaded,
                                        fileName: file.name,
                                    };
                                });
                                react.props.form.setFields({
                                    nome_norma: { value: data[0].Norma },
                                });
                                resolve(true);
                            }
                        },
                    });
                });
            },
            onChange(info) {
                if (info.file.status !== "uploading") {
                    //
                }
                if (info.file.status === "done") {
                    message.success(`${info.file.name} ficheiro carregado.`);
                } else if (info.file.status === "error") {
                    message.error(
                        `Ocorreu um erro ao carregar o ficheiro. Pode-se descarregar o template e preencher.`
                    );
                }
            },
        };
        const { labels } = this.state;

        const columns = labels.map((item, index) => {
            const dataIndex = "Dimensão " + (index + 1);
            return {
                title: item,
                dataIndex,
                width: "20%",
                render: (text, record) => (
                    <Input
                        value={record[dataIndex]}
                        onChange={(e) => this.onChange(dataIndex, e.target.value, record)}
                    ></Input>
                ),
            };
        });

        const columnScale = {
            title: "Escala",
            width: "10%",
            render: (text, record) => {
                const scales = record["Nome escala"];
                const total = scales !== null && scales !== "" ? scales.split("£").length : 0;
                return (
                    <Badge showZero count={total}>
                        <Button
                            icon="edit"
                            onClick={() => {
                                this.showScaleModal(record);
                            }}
                        />
                    </Badge>
                );
            },
        };

        const columnsTable = [...columns, columnScale];

        const { getFieldDecorator } = this.props.form;

        const designations = this.state.globalScale.designations;
        let countGlobalScale = 0;
        if (designations !== "" && designations !== null) countGlobalScale = designations.split("£").length;

        return (
            <Modal
                title="Exportação e importação de normas"
                visible={this.props.isVisible}
                maskClosable={false}
                width="90%"
                style={{ top: 20 }}
                onCancel={this.handleCancel}
                footer={
                    this.state.action === qaStandartActions.import
                        ? [
                              <Button key="back" type="default" onClick={() => Confirm(this.props.handleCancel)}>
                                  Cancelar
                              </Button>,
                              <Button
                                  key="submit"
                                  type="primary"
                                  loading={this.state.loadingImport}
                                  disabled={this.state.loadingFormulario}
                                  onClick={this.handleOk}
                              >
                                  Guardar
                              </Button>,
                          ]
                        : null
                }
            >
                <Form layout="vertical">
                    <Row gutter={18}>
                        <Col xs={24} md={6}>
                            <Form.Item label="Ação">
                                {getFieldDecorator("acao", {
                                    initialValue: qaStandartActions.export,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Radio.Group onChange={this.handleActionChange}>
                                        <Radio.Button value={qaStandartActions.export}>Exportar</Radio.Button>
                                        <Radio.Button value={qaStandartActions.import}>Importar</Radio.Button>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </Col>
                        {this.state.action === qaStandartActions.export && (
                            <>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Norma a exportar">
                                        {getFieldDecorator("norma", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(
                                            <Select
                                                showSearch
                                                allowClear
                                                style={{ width: "100%" }}
                                                optionFilterProp="children"
                                                loading={this.state.loadingListNorma}
                                                filterOption={(input, option) => {
                                                    if (option.props.children === null) return false;
                                                    return (
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                            >
                                                {this.props.standartList &&
                                                    this.props.standartList.map((item) => {
                                                        return (
                                                            <Option key={item.id_norma} value={item.id_norma}>
                                                                {item.designacao}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="&nbsp;">
                                        <Button type="primary" onClick={this.export} loading={this.state.exportLoading}>
                                            Exportar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        {this.state.action === qaStandartActions.import && (
                            <>
                                <Col xs={24} md={6}>
                                    <Form.Item label="Ficheiro com a norma">
                                        {getFieldDecorator("file", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(
                                            <Upload {...props}>
                                                <Button>
                                                    <Icon type="upload" />
                                                    Clique para carregar
                                                </Button>
                                            </Upload>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="&nbsp;">
                                        <ButtonDownloadTemplate text={"Descarregar template"} template={"norma"} />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>
                    {this.state.action === qaStandartActions.import && (
                        <>
                            <Row gutter={18}>
                                <Col xs={24} md={6}>
                                    <Form.Item label="Norma">
                                        {getFieldDecorator("nome_norma", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(<Input></Input>)}
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={6}>
                                    <Form.Item label="Âmbito">
                                        {getFieldDecorator("ambito", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(<QaDynamicSelect name="ambito_norma" />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="Tipo Norma">
                                        {getFieldDecorator("tipo", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(<QaDynamicSelect name="tipo_norma" />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="Escala global">
                                        <Badge count={countGlobalScale} showZero>
                                            <Button
                                                icon="edit"
                                                onClick={() => {
                                                    this.showGlobalScale();
                                                }}
                                            />
                                        </Badge>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {this.state.isVisibleScaleModal && (
                                <Scale
                                    isModalVisible={this.state.isVisibleScaleModal}
                                    dimension={this.state.scaleDataModal}
                                    handleCancel={this.handleCancelScaleModal}
                                />
                            )}
                        </>
                    )}
                    {this.state.isUploaded && this.state.action === qaStandartActions.import && (
                        <Row>
                            <Col xs={24}>
                                <Table
                                    size="small"
                                    dataSource={this.state.dataSource}
                                    pagination={{ pageSize: 50 }}
                                    pageSize
                                    scroll={{ y: 300 }}
                                    columns={columnsTable}
                                />
                            </Col>
                        </Row>
                    )}
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getFormulario,
            insert,
            exportStandart,
            selectFixeList,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        standartList: state.fixedList.domain.byId.lista_normas ? state.fixedList.domain.byId.lista_normas.data : [],
        formulario: state.qaStandarts.domain.metadata.formulario,
    };
};

const formCreate = Form.create();
export default connect(mapStateToProps, mapDispatchToProps)(formCreate(QaStandartsForm));
