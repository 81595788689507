import React from "react";
import { Row, Button, Icon, Table, Input } from "antd";

const AditionalInfoComponent = (props) => {
    const aditioinalInfoColumns = [
        {
            title: "Label",
            width: "50%",
            dataIndex: "label",
            render: () => <Input placeholder="Introduza o label" />,
        },
        {
            title: "Campo",
            width: "50%",
            dataIndex: "field",
            render: () => <Input placeholder="Introduza o campo" />,
        },
        {
            render: () => (
                <a href="javasccript:;" className="qa-text-del" onClick={() => this.handleDelete}>
                    Eliminar
                </a>
            ),
        },
    ];

    return (
        <>
            <Row>
                <Button
                    onClick={() => props.handleAdd("aditionalInfoDataSource")}
                    type="primary"
                    style={{ float: "right", marginBottom: 16 }}
                >
                    <Icon type="plus" />
                    Inserir
                </Button>
            </Row>

            <Table
                rowClassName={() => "editable-row"}
                dataSource={props.dataSource}
                columns={aditioinalInfoColumns}
                size="default"
            />
        </>
    );
};

export default AditionalInfoComponent;
