import React, { useState } from "react";
import { Button, Tooltip, message } from "antd";

const ButtonsGroup = ({ handleExport }) => {
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const handleExportPdf = () => {
        setLoadingPdf(true);
        handleExport("pdf").then((response) => {
            if (!response) message.error("Ocorreu um erro");
            setLoadingPdf(false);
        });
    };
    const handleExportExcel = () => {
        setLoadingExcel(true);
        handleExport("xls").then((response) => {
            if (!response) message.error("Ocorreu um erro");
            setLoadingExcel(false);
        });
    };
    return (
        <>
            <Tooltip title="Exportar para pdf">
                <Button
                    onClick={handleExportPdf}
                    loading={loadingPdf}
                    icon="file-pdf"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#fa541c",
                    }}
                />
            </Tooltip>
            <Tooltip title="Exportar para excel">
                <Button
                    onClick={handleExportExcel}
                    loading={loadingExcel}
                    icon="file-excel"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#237804",
                    }}
                />
            </Tooltip>
        </>
    );
};

export default ButtonsGroup;
