import React from "react";
import { Divider } from "antd";
import { FormInputsProps } from "../../../types/formTypes";
import { print_when_component_is_rendered } from "../../../../utils/develop";

interface ComponentsPropertiesProps {
    componentsProperties: FormInputsProps;
}

export const QADivider: React.FC<ComponentsPropertiesProps> = ({ componentsProperties }) => {
    print_when_component_is_rendered(componentsProperties);

    return (
        <div>
            <span>
                <strong>{componentsProperties.group}</strong>
            </span>
            <Divider style={{ marginTop: 8 }} />
        </div>
    );
};
