export const actionType = {
    insert: "INSERT_COMPETENCE",
    setField: "SET_FIELD_COMPETENCE",
    getEdit: "GET_EDIT_COMPETENCE",
    update: "UPDATE_COMPETENCE",
    delete: "DELETE_COMPETENCE",
    select: "SELECT_COMPETENCE",
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
