import React, { Component } from "react";
import { PageHeader, Popconfirm, Table, Divider, Input, Button, Icon, Tooltip, message } from "antd";
import ButtonsGroup from "./buttons.group";
import FormBehavioralParameters from "./form.behavioral.parameter";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/behavioral.parameter";
import { allowed } from "./../../../../constants/index";
import { tableSort, tableFooter } from "./../../../../components/util";

const REPORT_PARAMS = {
    accao: "lista",
    lista: "tipo_param_comportam",
    campos: "designacao:24:Comportamento;descricao:24:Descrição;",
    titulo: "Comportamentos",
};

class BehavioralParameterIndex extends Component {
    componentDidMount() {
        this.selectData();
    }

    state = {
        isVisible: false,
        isEdit: false,
        isFetching: false,
        showDeletedRecords: false,
        filter: {},
    };

    selectData = () => {
        this.setState({
            isFetching: true,
        });
        this.props.select({ mostra_lista_eliminados: this.state.showDeletedRecords, ...this.state.filter }).then(() => {
            this.setState({
                isFetching: false,
            });
        });
    };

    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        // onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: (visible) => {
        //     if (visible) {
        //         setTimeout(() => this.searchInput.select());
        //     }
        // },
        // render: (text) => (
        //     <Highlighter
        //         highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        //         searchWords={[this.state.searchText]}
        //         autoEscape
        //         textToHighlight={text.toString()}
        //     />
        // ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({
            searchText: selectedKeys,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    /****************************** */
    /** configs table */
    showDeletedRecords = (e) => {
        this.setState({ showDeletedRecords: e.target.checked }, () => this.selectData());
    };

    columns = [
        {
            title: "Comportamento",
            dataIndex: "designacao",
            width: "30%",
            ...this.getColumnSearchProps("designacao"),
            sorter: (a, b) => tableSort(a.designacao, b.designacao),
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            ...this.getColumnSearchProps("descricao"),
            width: "50%",
            render: (text) => {
                text = typeof text !== "object" ? text : "";
                return text && text.length >= 100 ? (
                    <Tooltip title={text}>
                        <div
                            style={{
                                textOverflow: "ellipsis",
                                width: " 500px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            {text}
                        </div>
                    </Tooltip>
                ) : (
                    <div>{text}</div>
                );
            },
            sorter: (a, b) => tableSort(a.descricao, b.descricao),
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "20%",
            render: (text, record) => {
                return (
                    record._eliminado_ === allowed && (
                        <>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                }}
                                onClick={() => this.handleEdit(record.id_tipo_parametros_comportam)}
                            >
                                Editar
                            </Button>
                            {record._eliminado_ === allowed && (
                                <>
                                    <Divider type="vertical" />
                                    <Popconfirm
                                        title="Tens certeza que desejas eliminar?"
                                        okText="Ok"
                                        cancelText="Não"
                                        onConfirm={() => this.delete(record.id_tipo_parametros_comportam)}
                                    >
                                        <Button
                                            type="link"
                                            style={{
                                                padding: 0,
                                                color: "#fa541c",
                                            }}
                                        >
                                            Eliminar
                                        </Button>
                                    </Popconfirm>
                                </>
                            )}
                        </>
                    )
                );
            },
        },
    ];
    /************************************ */

    showModal = () => {
        this.props.getForm(this.props.metatadaForm).then(() => {
            this.setState({
                isVisible: true,
                isEdit: false,
            });
        });
    };
    handleOk = () => {
        this.setState({
            isVisible: false,
        });
        this.selectData();
    };

    handleCancel = () => {
        this.setState({
            isVisible: false,
        });
    };

    handleEdit = (id) => {
        this.props.getById(id).then(() => {
            this.setState({
                isVisible: true,
                isEdit: true,
            });
        });
    };

    delete = (key) => {
        this.props.remove(key).then((response) => {
            if (response) {
                message.success("Eliminado com sucesso");
                this.selectData();
            } else message.error("Ocorreu um erro");
        });
    };

    handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        let ordernacao = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            const order = sorter.order !== "descend" ? "ASC" : "DESC";
            ordernacao = { [`ordenacao_${sorter.columnKey}`]: order };
        }

        this.setState({ ...this.state, currentPage: 1, filter: { ...obj, ...ordernacao } }, () => this.selectData());
    };

    render() {
        return (
            <>
                <PageHeader title="Lista comportamentos" />
                {this.state.isVisible && (
                    <FormBehavioralParameters
                        handleOk={this.handleOk}
                        isVisible={this.state.isVisible}
                        handleCancel={this.handleCancel}
                        isEdit={this.state.isEdit}
                    />
                )}
                <ButtonsGroup
                    showDeletedRecords={this.showDeletedRecords.bind(this)}
                    handleOnClick={this.showModal.bind(this)}
                    REPORT_PARAMS={REPORT_PARAMS}
                    filter={this.state.filter}
                />
                <Table
                    rowKey={(record) => record.id_tipo_parametros_comportam}
                    dataSource={this.props.behavioralParameterList}
                    columns={this.columns}
                    size="middle"
                    loading={this.state.isFetching}
                    pagination
                    footer={() => tableFooter(this.props.totalRegisto)}
                    onChange={this.handleTableChange}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        behavioralParameterList: Object.values(state.behavioralParameter.domain.byId),
        isFetched: state.behavioralParameter.app.isFetched,
        totalRegisto: Number(state.behavioralParameter.domain.metadata.attributes.resultados),
        metatadaForm: state.behavioralParameter.domain.metadata.formulario,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...actionCreators }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BehavioralParameterIndex);
