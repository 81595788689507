import React, { memo, useEffect, useState } from "react";
import { Radio } from "antd";
import { FormLabel } from "../Components/FormLabel";
import { COMPONENT_TYPE, GRAPHIC_ELEMENT } from "../../../utils/FormMapper/XMLInputType";
import { FormUtilitaries, Utilitaries } from "../../../../business";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { Group } from "../Components/Group";
import { CommunComponentsProps } from "../../../types/common";
import { compareProps, getFullPathRow } from "../handle/inputHandle";
import { useInputHandle } from "../hooks/InputHandle";

interface ComponentsPropertiesProps extends CommunComponentsProps {}

const _QARadio: React.FC<ComponentsPropertiesProps> = ({
    formState,
    componentsProperties,
    renderContext,
    tableRow,
    inputCalcTrigger,
    formInputDependenceState,
    handleOnInputChangeValue,
    justSee,
    triedSave,
}) => {
    const [showError, setShowError] = useState(false);

    print_when_component_is_rendered(componentsProperties);

    useEffect(() => setShowError(triedSave), [triedSave]);

    let booleanOptionTexts = {
        positive: "Sim",
        negative: "Não",
    };
    if (componentsProperties.type === COMPONENT_TYPE.BOOLEAN && componentsProperties.values) {
        const values = componentsProperties.values.split(";");
        booleanOptionTexts = {
            positive: values[0],
            negative: values[1],
        };
    }

    const validateBoolean = (value: string) => {
        if (value === "false") {
            return "f";
        }
        if (value === "true") {
            return "t";
        }
        return value;
    };

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);

    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    const full_path = tableRow?.row_key ? fullPathRow : componentsProperties?.full_path;

    const {
        data: whenValueChangeData,
        status: whenValueChangeStatus,
        componentValue,
        hasUpdatedValue,
        required,
        visible,
        relevant,
        readOnly,
        error,
        calculated,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[full_path || ""],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
        showError,
        value,
    });

    useEffect(() => {
        if (hasUpdatedValue && whenValueChangeStatus !== "IDLE") {
            handleOnInputChangeValue({
                value: componentValue,
                componentsProperties,
                row_key: tableRow?.row_key,
                full_path,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentValue]);

    useEffect(() => {
        if (
            componentsProperties.calculate &&
            (!Utilitaries.isEmpty(inputCalcTrigger?.[componentsProperties?.full_path || ""]) ||
                !Utilitaries.isEmpty(inputCalcTrigger?.[fullPathRow]) ||
                componentsProperties.persist === "nao")
        ) {
            handleOnInputChangeValue({
                value: calculated,
                componentsProperties,
                row_key: tableRow?.row_key,
                full_path,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculated]);

    // const canEdit = canCorrectField(componentsProperties) && correctForm;

    // const disabled = canEdit ? false : currentTaskStatus || readonly;

    if (!visible || !relevant) {
        return null;
    }

    const renderRadio = () => {
        // if (
        //     componentsProperties.type === COMPONENT_TYPE.BOOLEAN &&
        //     componentsProperties.graphic_element === GRAPHIC_ELEMENT.SELECTION_BUTTONS &&
        //     componentsProperties.values
        // ) {

        // }

        if (
            componentsProperties.type === COMPONENT_TYPE.BOOLEAN &&
            componentsProperties.graphic_element === GRAPHIC_ELEMENT.SELECTION_BUTTONS &&
            componentsProperties.values
        ) {
            // if (rowKey) {
            //     return columnDataSource?.map((item: any) => (
            //         <Radio value={item[componentsProperties.key_list || "key"]}>
            //             {item[componentsProperties.value_list || "value"]}
            //         </Radio>
            //     ));
            // }

            return componentsProperties?.DATA_SOURCE?.map((item: any) => (
                <Radio style={{ display: "block" }} value={item["key"]} disabled={justSee || readOnly}>
                    {item["value"]}
                </Radio>
            ));
        }

        return (
            <>
                <Radio value="t" disabled={justSee || readOnly}>
                    {componentsProperties.DATA_SOURCE?.find((it: any) => it.key === "t")?.value ||
                        booleanOptionTexts.positive}
                </Radio>
                <Radio value="f">
                    {componentsProperties.DATA_SOURCE?.find((it: any) => it.key === "f")?.value ||
                        booleanOptionTexts.negative}
                </Radio>
            </>
        );
    };

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={required} renderContext={renderContext}>
                <Radio.Group
                    value={validateBoolean(value)}
                    disabled={justSee || readOnly}
                    style={FormUtilitaries.highlightsErrors(value, required)}
                    onChange={(e) => {
                        handleOnInputChangeValue({
                            value: e.target.value,
                            componentsProperties,
                            row_key: tableRow?.row_key,
                            full_path,
                        });
                    }}
                >
                    {renderRadio()}
                </Radio.Group>
                {showError && error && <span style={{ color: "red" }}>{error}</span>}
            </FormLabel>
        </Group>
    );
};

// const compareProps = (prevProps: ComponentsPropertiesProps, nextProps: ComponentsPropertiesProps) => {
//     if (nextProps.renderContext === "column") {
//         return (
//             prevProps.tableRow?.row_data?.[prevProps.componentsProperties.key] ===
//             nextProps.tableRow?.row_data?.[prevProps.componentsProperties.key]
//         );
//     }
//     return prevProps.componentsProperties.value === nextProps.componentsProperties.value;
// };

// export const QARadio = memo(_QARadio, compareProps);
// export const QARadio = _QARadio;
export const QARadio = memo(_QARadio, (prev, next) => compareProps<ComponentsPropertiesProps>(prev, next));
