import React, { useState } from "react";
import { Button } from "antd";
import { downloadFile } from "./../../../../components/util";

export const ButtonDownloadTemplate = (props) => {
    const [loading, setLoading] = useState(false);

    const downloadTemplate = (type) => {
        setLoading(true);
        downloadFile("/geretemplate.php", {
            tipo: type,
        }).then(() => {
            setLoading(false);
        });
    };
    return (
        <Button type="link" icon="download" loading={loading} onClick={() => downloadTemplate(props.template)}>
            {props.text}
        </Button>
    );
};
