import React, { Component } from "react";
import ExecutionAuthorization from "./execution.authorization";
import { setAttributionType, setTaskAuthorizations, removeTaskAuthorizations } from "../../actions/details";
import { getAuthorizations } from "../../../authorizations/actions/index";
import { connect } from "react-redux";
import TaskTable from "../../../process_execution/components/execution/tasks.table";
import { getFieldDataSource } from "../../../process_execution/actions/index";

class AssignmentTasks extends Component {
    state = { visible: false, taskId: 0, authorizations: [] };

    async componentDidMount() {
        await this.props.getFieldDataSource({ dataSource: "todas_autorizacoes" }).then(({ result }) => {
            this.setState({ authorizations: result });
        });
    }

    showModal = (taskId) => {
        this.setState({
            visible: true,
            taskId,
        });
    };

    handleOk = (dataSource, isToDelete) => {
        const { tasksAuthorizations, setTaskAuthorizations, removeTaskAuthorizations } = this.props;
        const { taskId } = this.state;
        const allAuthorizations = tasksAuthorizations?.find((item) => item.id === taskId)?.allAuthorizations;
        this.setState({ visible: false });
        if (dataSource.length > 0) {
            if (isToDelete) {
                for (const item of allAuthorizations.filter(
                    (item) => !dataSource.map((dcItem) => dcItem.authId).includes(item.authId)
                )) {
                    const param = {
                        taskId: this.state.taskId,
                        authId: item.authId,
                    };
                    removeTaskAuthorizations(param);
                }
            }
            for (const item of dataSource) {
                const param = {
                    taskId: this.state.taskId,
                    authId: item.authId,
                    read: item.read,
                    print: item.print,
                    exec: item.exec,
                };
                setTaskAuthorizations(param);
            }
        } else {
            setTaskAuthorizations({ isToEmpty: true, taskId: this.state.taskId });
        }
    };

    handleCancel = (e) => {
        this.setState({ visible: false });
    };

    handleFormValidation = (isValid) => {
        this.props.handleFormValidation("taskAssignment", isValid);
    };

    setAttributionType = (value, taskId) => {
        this.props.setAttributionType({ authorization: value, taskId });
    };

    setTaskAuthorizations = (authorization) => {
        this.props.setTaskAuthorizations({
            authorization,
            taskId: this.state.taskId,
        });
    };

    render() {
        let { data, isDisabled, authorizationsIds } = this.props;
        let { visible, authorizations, taskId } = this.state;
        return (
            <div>
                <ExecutionAuthorization
                    isModalVisible={visible}
                    handleCancel={this.handleCancel.bind(this)}
                    handleOk={this.handleOk.bind(this)}
                    authsPermissions={
                        this.props.tasksAuthorizations?.find((item) => item.id === taskId)?.allAuthorizations
                    }
                    authorizations={authorizations}
                />
                <TaskTable
                    type="toAssign"
                    isDisabled={isDisabled}
                    showModal={this.showModal.bind(this)}
                    handleSetUsersByGroup={this.props.handleSetUsersByGroup}
                    groupsToDelegate={this.props.groupsToDelegate}
                    usersByGroupToDelegate={this.props.usersByGroupToDelegate}
                    setUserToDelegateProcessDef={this.props.setUserToDelegateProcessDef}
                    data={data}
                    authorizationsIds={authorizationsIds}
                    authorizations={authorizations}
                    setAttributionType={this.setAttributionType.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    setAttributionType,
    getAuthorizations,
    setTaskAuthorizations,
    removeTaskAuthorizations,
    getFieldDataSource,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentTasks);
