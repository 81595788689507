import React, { Component } from "react";
import FormCollectiveAgreement from "./form";
import { PageHeader } from "antd";

class NewCollectiveAgreement extends Component {
    handleRedirect = () => {
        this.props.history.push({
            pathname: "/function.manual/index/3",
            search: "?reload=true",
        });
    };

    render() {
        return (
            <>
                <PageHeader
                    onBack={() => {
                        this.props.history.push({
                            pathname: "/function.manual/index/3",
                        });
                    }}
                    title="Ficha de Convenção Coletiva"
                />
                <FormCollectiveAgreement type="insert" isEdit={false} redirect={this.handleRedirect} />
            </>
        );
    }
}

export default NewCollectiveAgreement;
