import { actionType } from "../constants/contrubitor.time.registration";
import { sendHttpRequest } from "../../../components/http.request";

export const selectContributors = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.dados !== undefined) {
            dispatch({
                type: actionType.selectContributors,
                payload: jsonResponse["dados"],
            });
            return true;
        } else return false;
    });
};

export const selectPointRegisterByContributor = (requestBody, contributorId) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.dados !== undefined) {
            dispatch({
                type: actionType.timeRegistrationByContributor,
                payload: jsonResponse["dados"],
                contributorId,
            });
            return true;
        } else return false;
    });
};

export const selectPointRegisterByParametrization = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.linha !== undefined) {
            if (Array.isArray(jsonResponse.linha)) return jsonResponse.linha;
            else if (Object.keys(jsonResponse.linha).length > 0) return [jsonResponse.linha];
            else return [];
        } else return false;
    });
};
