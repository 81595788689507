import React, { Component } from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { Icon } from "antd";
import { connect } from "react-redux";

import { toArray, canRenderMenu } from "./util";
import "./home.css";

const styleCommon = {};

const iconStyle = {
    fontSize: "24px",
    color: "#ECEBEF",
};

const LIST_MENUS = [
    "qa_painel",
    "qa_iniciar_processo",
    "qa_manual_funcoes",
    "qa_cosulta_documento_registo",
    "qa_dossie_cliente",
];

class Home extends Component {
    componentDidMount() {
        const element = document.querySelector("#div-content-layout");
        element.classList.add("qa-layout-content");
    }

    componentWillUnmount() {
        const element = document.querySelector("#div-content-layout");
        element.classList.remove("qa-layout-content");
    }

    render() {
        const canShow = (key) => canRenderMenu(key, this.props.menus);

        const menus = toArray(this.props.menus)
            .filter((item) => LIST_MENUS.includes(item.identificador))
            .reduce((acc, item) => {
                acc[item.identificador] = item;
                return acc;
            }, {});

        return (
            <div className="qa-bg">
                <div className="qa-img"></div>
                <div className="qa-btn">
                    {canShow("OpenPainel") && (
                        <div className="">
                            <Link to="/task/panel">
                                <Card
                                    className="qa-card-home"
                                    hoverable
                                    style={styleCommon}
                                    cover={<Icon type="unordered-list" style={iconStyle} theme="outlined" />}
                                >
                                    <div className="ant-card-meta-detail">
                                        <div className="ant-card-meta-description qa-card-content">
                                            <p className="qa-card-content-text">
                                                {menus?.["qa_painel"]?.titulo || "Painel Tarefas"}
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Link>
                        </div>
                    )}
                    {canShow("OpenInicarNovoprocesso") && (
                        <div className="">
                            <Link to="/process/execution/index">
                                <Card
                                    className="qa-card-home"
                                    hoverable
                                    style={styleCommon}
                                    cover={<Icon type="play-square" style={iconStyle} theme="outlined" />}
                                >
                                    <div className="ant-card-meta-detail">
                                        <div className="ant-card-meta-description qa-card-content">
                                            <p className="qa-card-content-text">
                                                {menus?.["qa_iniciar_processo"]?.titulo || "Iniciar processo"}
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Link>
                        </div>
                    )}

                    {canShow("qa_cosulta_documento_registo") && (
                        <div className="">
                            <Link to="/document_record_consult">
                                <Card
                                    className="qa-card-home"
                                    hoverable
                                    style={styleCommon}
                                    cover={<Icon type="folder-open" style={iconStyle} theme="outlined" />}
                                >
                                    <div className="ant-card-meta-detail">
                                        <div className="ant-card-meta-description qa-card-content">
                                            <p className="qa-card-content-text">
                                                {menus?.["qa_cosulta_documento_registo"]?.titulo ||
                                                    "Consulta de Documentos e Registos"}
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Link>
                        </div>
                    )}

                    {canShow("qa_manual_funcoes") && (
                        <div className="">
                            <Link to="/function.manual/index">
                                <Card
                                    className="qa-card-home"
                                    hoverable
                                    style={styleCommon}
                                    cover={<Icon type="team" style={iconStyle} theme="outlined" />}
                                >
                                    <div className="ant-card-meta-detail">
                                        <div className="ant-card-meta-description qa-card-content">
                                            <p className="qa-card-content-text">
                                                {menus?.["qa_manual_funcoes"]?.titulo || "Manual de Funções"}
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Link>
                        </div>
                    )}

                    {canShow("dossier_clientes") && (
                        <div className="">
                            <Link to="/client_dossier">
                                <Card
                                    className="qa-card-home"
                                    hoverable
                                    style={styleCommon}
                                    cover={<Icon type="snippets" style={iconStyle} theme="outlined" />}
                                >
                                    <div className="ant-card-meta-detail">
                                        <div className="ant-card-meta-description qa-card-content">
                                            <p className="qa-card-content-text">
                                                {menus?.["qa_dossie_cliente"]?.titulo || "Dossiê de Cliente"}
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menus: toArray(state.home.domain.menus),
    };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

/**
 <div className="qa-home-content">
        <div className="qa-div-card">
          <Row gutter={-4} type="flex" justify="center">
            <Col {...colSize}>
              <Link to="/task/panel">
                <Card
                  className="qa-card-home"
                  hoverable
                  style={styleCommon}
                  cover={<Icon
                    type="unordered-list"
                    style={{
                      fontSize: "24px",
                      color: "#08c",
                    }}
                    theme="outlined"
                  />}
                >
                  <div className="ant-card-meta-detail">
                    <div className="ant-card-meta-description qa-card-content">
                      <p className="qa-card-content-text">
                        Painel de Tarefas
                      </p>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col {...colSize}>
              <Link to="/process/execution/index">
                <Card
                  className="qa-card-home"
                  hoverable
                  style={styleCommon}
                  cover={<Icon
                    type="play-square"
                    style={{
                      fontSize: "24px",
                      color: "#08c",
                    }}
                    theme="outlined"
                  />}
                >
                  <div className="ant-card-meta-detail">
                    <div className="ant-card-meta-description qa-card-content">
                      <p className="qa-card-content-text">
                        Iniciar processo
                      </p>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col {...colSize}>
              <Link to="/document_record_consult">
                <Card
                  className="qa-card-home"
                  hoverable
                  style={styleCommon}
                  cover={<Icon
                    type="folder-open"
                    style={{
                      fontSize: "24px",
                      color: "#08c",
                    }}
                    theme="outlined"
                  />}
                >
                  <div className="ant-card-meta-detail">
                    <div className="ant-card-meta-description qa-card-content">
                      <p className="qa-card-content-text">
                        Consulta de Documentos e Registos
                      </p>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col {...colSize}>
              <Link to="/function.manual/index">
                <Card
                  className="qa-card-home"
                  hoverable
                  style={styleCommon}
                  cover={<Icon
                    type="team"
                    style={{
                      fontSize: "24px",
                      color: "#08c",
                    }}
                    theme="outlined"
                  />}
                >
                  <div className="ant-card-meta-detail">
                    <div className="ant-card-meta-description qa-card-content">
                      <p className="qa-card-content-text">
                        Manual de Funções
                      </p>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col {...colSize}>
              <Link to="/client_dossier">
                <Card
                  className="qa-card-home"
                  hoverable
                  style={styleCommon}
                  cover={<Icon
                    type="snippets"
                    style={{
                      fontSize: "24px",
                      color: "#08c",
                    }}
                    theme="outlined"
                  />}
                >
                  <div className="ant-card-meta-detail">
                    <div className="ant-card-meta-description qa-card-content">
                      <p className="qa-card-content-text">
                        Dossiê de Cliente
                      </p>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
 */
