import React from "react";

import FormComponent from "./Form";
import Layout from "../Common/Layout";

import "./styles.css";

const Login = () => {
    return (
        <Layout>
            <FormComponent />
        </Layout>
    );
};

export default Login;
