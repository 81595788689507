export const actionType = {
    select: "SELECT_BEHAVIORAL_PARAMETER",
    insert: "INSERT_BEHAVIORAL_PARAMETER",
    setField: "SET_FIELD_BEHAVIORAL_PARAMETER",
    getEdit: "GET_EDIT_BEHAVIORAL_PARAMETER",
    getForm: "GET_FORM_BEHAVIORAL_PARAMETER",
    getById: "GET_BEHAVIORAL_PARAMETER_BY_ID",
    // update: "UPDATE_KNOWLEDGE_TYPE",
    delete: "DELETE_BEHAVIORAL_PARAMETER",
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
export const lista = "tipo_param_comportam";
