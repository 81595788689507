import React, { Component } from "react";
import { Popconfirm, Table, Divider, Input, Button, Icon, Row, Tooltip, message } from "antd";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";

import ButtonsGroup from "./buttons.group";
import FormKnowledgeType from "./form.competence.type";
import { select, remove } from "./../../actions/competence.type";
import { tableSort, tableFooter } from "../../../../components/util";

const REPORT_PARAMS = {
    accao: "lista",
    lista: "tipos_competencias",
    campos: "designacao:400:Tipo de competência;descricao:400:Descrição;",
    titulo: "Tipos de Competências",
};
class CompetenceType extends Component {
    componentDidMount() {
        this.getCompetenceType();
    }
    /** get competence type from server */
    getCompetenceType = () => {
        this.setState({ isFetching: true });
        this.props
            .select({
                accao: "lista",
                lista: "tipos_competencias",
                pagina: 1,
                resultados_pagina: 0,
                mostra_lista_eliminados: this.state.show_deleted_records,
                ...this.state.filter,
            })
            .then(() => this.setState({ isFetching: false }));
    };
    /** for search results in table */
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar ${dataIndex}`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        // onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: (visible) => {
        //     if (visible) {
        //         setTimeout(() => this.searchInput.select());
        //     }
        // },
        // render: (text) => {
        //     text = typeof text === "object" ? "" : text;
        //     return (
        //         <Highlighter
        //             highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        //             searchWords={[this.state.searchText]}
        //             autoEscape
        //             textToHighlight={text.toString()}
        //         />
        //     );
        // },
    });

    handleSearch = (selectedKeys, confirm, dataIndex = null) => {
        this.setState({
            searchText: selectedKeys,
            filter: { ...this.state.filter, [`filtro_${dataIndex}`]: selectedKeys },
        });
        confirm();
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    /****************************** */

    columns = [
        {
            title: "Tipo de competência",
            dataIndex: "designacao",
            width: "30%",
            sorter: (a, b) => tableSort(a.designacao, b.designacao),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("designacao"),
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            width: "50%",
            sorter: (a, b) => tableSort(a.descricao, b.descricao),
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("descricao"),
            render: (text) => {
                text = typeof text !== "object" ? text : "";
                return text && text.length >= 100 ? (
                    <Tooltip title={text}>
                        <div
                            style={{
                                textOverflow: "ellipsis",
                                width: " 500px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            {text}
                        </div>
                    </Tooltip>
                ) : (
                    <div>{text}</div>
                );
            },
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "20%",
            render: (text, record) => {
                return record._eliminado_ === "t" ? (
                    <div>
                        <a href="javascript:;" onClick={this.handleEdit.bind(this, record)}>
                            Editar
                        </a>
                        <Divider type="vertical" />
                        <Popconfirm
                            title="Tens certeza que desejas eliminar?"
                            okText="Ok"
                            cancelText="Não"
                            onConfirm={() => this.delete(record.id_tipo_competencia)}
                        >
                            <a href="javascript:;" className="qa-text-del">
                                Eliminar
                            </a>
                        </Popconfirm>
                    </div>
                ) : null;
            },
        },
    ];

    state = {
        show_deleted_records: false,
        isVisible: false,
        editRecord: false,
        isFetching: false,
        recordToEdit: {},
        filter: {},
    };

    showModal = () => {
        this.setState({
            isVisible: true,
            editRecord: false,
        });
    };

    hiddeModal = () => {
        this.setState({
            isVisible: false,
        });
    };

    handleEdit = (row) => {
        this.setState({
            isVisible: true,
            editRecord: true,
            recordToEdit: { ...row },
        });
    };

    delete = (id) => {
        this.props
            .remove({
                accao: "eliminar",
                lista: "tipos_competencias",
                chave1: id,
            })
            .then((sucess) => {
                if (sucess) {
                    message.success("Eliminado com sucesso");
                    this.getCompetenceType();
                } else {
                    message.error("Ocorreu um erro");
                }
            });
    };

    showDeletedRecords = (e) => {
        this.setState({ show_deleted_records: e.target.checked }, () => this.getCompetenceType());
    };

    handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        let ordernacao = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            const order = sorter.order !== "descend" ? "ASC" : "DESC";
            ordernacao = { [`ordenacao_${sorter.columnKey}`]: order };
        }

        this.setState({ ...this.state, currentPage: 1, filter: { ...obj, ...ordernacao } }, () =>
            this.getCompetenceType()
        );
    };

    render() {
        return (
            <>
                {this.state.isVisible ? (
                    <FormKnowledgeType
                        dataToEdit={this.state.recordToEdit}
                        isEdit={this.state.editRecord}
                        hiddeModal={this.hiddeModal}
                        handleOk={this.handleOk}
                        isVisible={this.state.isVisible}
                    />
                ) : null}
                <Row>
                    <ButtonsGroup
                        showDeletedRecords={this.showDeletedRecords.bind(this)}
                        handleOnClick={this.showModal.bind(this)}
                        REPORT_PARAMS={REPORT_PARAMS}
                        filter={this.state.filter}
                    />
                    <Table
                        rowKey={(record) => record.id_tipo_competencia}
                        dataSource={this.props.competenceTypeList}
                        columns={this.columns}
                        size="middle"
                        footer={() => tableFooter(this.props.totalRegisto)}
                        loading={this.state.isFetching}
                        onChange={this.handleTableChange}
                    />
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        competenceTypeList: Object.values(state.competenceType.domain.byId),
        isDataLoaded: state.competenceType.app.isFetched,
        totalRegisto: state.competenceType.domain.metaData.attributes.resultados,
    };
};

const mapDispatchToProps = {
    select,
    remove,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetenceType);
