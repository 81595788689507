import { Utilitaries } from "../../../business";
import moment from "moment";

export interface CalendarEventProps {
    id: string;
    start?: string;
    end?: string;
    allDay?: boolean;
    title: string;
    _description?: string;
    _data_ini?: string;

    // id: string;
    // start: string;
    // end: string;
    // allDay?: boolean;
    // title: string;
    // color: string;
    // _description?: string;
    // _title?: string;
    // _total?: number;
    // _location?: string;

    // defId: string;
    // sourceId: string;
    // publicId: string;
    // groupId: string;
    // allDay: boolean;
    // hasEnd: boolean;
    // recurringDef: {
    //     typeId: number;
    //     typeData: any;
    //     duration: Duration | null;
    // } | null;
    // title: string;
    // url: string;
    // rendering: EventRenderingChoice;
    // ui: EventUi;
    // extendedProps: any;
}

export interface PageAttributesProps {
    title: string;
    comboQueryName?: string;
    comboLabel?: string;
    // statesColor: [];
    // cor_drag_drop:
    php: string;
    showAddButton: string;
    showPopupOptions: string;
    showPending: string;
    alertWithoutSelection: string;
    formName: string;
}

export const getPageAttributes = (form?: any) => {
    if (typeof form !== "object") return null;
    const pageAttributes: PageAttributesProps = {
        title: form["titulo_janela"],
        comboQueryName: form["nome_query_combo"],
        comboLabel: form["label_titulo_combo"],
        php: form["php"],
        showAddButton: form["show_add_button"],
        showPopupOptions: form["show_pop_options"],
        showPending: form["show_pending"],
        alertWithoutSelection: form["alerta_sem_seleccao"],
        formName: form["formulario"],
    };
    return pageAttributes;
};

export const formatDataCalendarEvents = (result: any[]) => {
    const dataSource = result.map((el: any) => {
        let start = null;
        if (Utilitaries.isEmpty(el.hora_ini)) {
            start = moment(el.data_ini, "YYYY-MM-DD").format("YYYY-MM-DD");
        } else {
            start = moment(`${el.data_ini} ${el.hora_ini}`, "YYYY-MM-DD HH:mm:ii").format("YYYY-MM-DD HH:mm");
        }

        let end = null;

        if (Utilitaries.isEmpty(el.hora_fi)) {
            end = moment(el.data_fi, "YYYY-MM-DD").format("YYYY-MM-DD");
        } else {
            end = moment(`${el.data_fi} ${el.hora_fi}`, "YYYY-MM-DD HH:mm:ii").format("YYYY-MM-DD HH:mm");
        }

        const _description = Utilitaries.toString(el.descricao);
        const __event: CalendarEventProps = {
            id: el.id,
            start,
            end,
            title: Utilitaries.toString(el.titulo),
            _description,
            // _data_ini: Utilitaries.toString(el.data_ini),
        };

        return __event;
    });
    return dataSource;
};
