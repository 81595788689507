import { useEffect, useRef, useState } from "react";

export const useQACustomState = (initialValue) => {
    const [state, setState] = useState(initialValue);
    const [stack, setStack] = useState([]);
    const [stackBusy, setStackBusy] = useState(false);

    // let current = JSON.parse(JSON.stringify(state));
    let tmp = useRef(state);

    const setNewStateWithStack = (newState) => {
        setStack([...stack, newState]);
    };

    const setNewState = (newState) => {
        // setStack([...stack, newState]);

        let newStateValues = newState;
        if (typeof newState === "function") {
            newStateValues = newState(tmp.current);
        }

        tmp.current = newStateValues;
        setState(newStateValues);
    };

    useEffect(() => {
        const handleStack = async () => {
            if (stack.length > 0 && !stackBusy) {
                setStackBusy(true);
                const [firstInStack, ...restStack] = stack;
                setStack(restStack);

                let newStateValues = firstInStack;
                if (typeof newStateValues === "function") {
                    // current = newStateValues(current);
                    newStateValues = newStateValues(tmp.current);
                }

                tmp.current = newStateValues;
                setState(newStateValues);

                setStackBusy(false);
            }
        };

        handleStack();
    }, [stack, stackBusy]);

    return [state, setNewStateWithStack, setNewState];
};
