import React, { memo, useEffect, useMemo, useState } from "react";
import { Form, Input, InputNumber, Button, Spin, Icon, Tooltip } from "antd";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";
import { FormLabel, getLabel } from "../Components/FormLabel";
import { Utilitaries } from "../../../../business";
import { CommunComponentsProps, FieldEditorProps } from "../../../types/common";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { useData } from "../hooks/useData";
import { useInputHandle } from "../hooks/InputHandle";
import { Group } from "../Components/Group";
import { compareProps, getFullPathRow } from "../handle/inputHandle";
import { highlightsErrors } from "../../../utils/functions";
import { mapLoad } from "../../../utils/loadMap";

interface ComponentsPropertiesProps extends CommunComponentsProps {
    setFieldEditor: React.Dispatch<React.SetStateAction<FieldEditorProps>>;
}

const { TextArea } = Input;

const QAInputComponent: React.FC<ComponentsPropertiesProps> = ({
    componentsProperties,
    renderContext,
    handleOnInputChangeValue,
    formState,
    formInputDependenceState,
    tableRow,
    inputCalcTrigger,
    setFieldEditor,
    justSee,
    triedSave,
    rootElementAttribute,
}) => {
    // const debounceChange = debounce(onChange, 500);
    const [showError, setShowError] = useState(false);

    print_when_component_is_rendered(componentsProperties);

    const showFieldEditorModal = true;

    useEffect(() => setShowError(triedSave), [triedSave]);

    const { data, status: dataStatus } = useData({
        endPoint: "execQuery.php",
        componentsProperties,
        state: formState,
        rootElementAttribute,
    });

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);

    const full_path = tableRow?.row_key ? fullPathRow : componentsProperties?.full_path;

    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    const {
        // data: whenValueChangeData,
        status: whenValueChangeStatus,
        componentValue,
        componentsValues,
        hasUpdatedValue,
        calculated,
        required,
        restriction,
        visible,
        relevant,
        readOnly,
        error,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[full_path!],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
        value,
        showError,
    });

    const { label, colon } = getLabel({ componentsProperties, renderContext, required });

    useEffect(() => {
        if (hasUpdatedValue > 0 && whenValueChangeStatus !== "IDLE") {
            handleOnInputChangeValue({
                value: componentValue,
                componentsProperties,
                row_key: tableRow?.row_key,
                full_path,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUpdatedValue]);

    useEffect(() => {
        if (whenValueChangeStatus !== "IDLE") {
            handleOnInputChangeValue({
                value: componentValue,
                componentsProperties,
                row_key: tableRow?.row_key,
                full_path,
                more_values: componentsValues,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentsValues]);

    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        if (!tableRow?.row_key && componentsProperties.value !== currentValue) {
            setCurrentValue(componentsProperties.value);
        } else if (tableRow?.row_key && tableRow?.row_data?.[componentsProperties.key] !== currentValue) {
            setCurrentValue(tableRow?.row_data?.[componentsProperties.key]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        if (
            componentsProperties.calculate &&
            (!Utilitaries.isEmpty(inputCalcTrigger?.[componentsProperties?.full_path || ""]) ||
                !Utilitaries.isEmpty(inputCalcTrigger?.[fullPathRow]) ||
                componentsProperties.persist === "nao")
        ) {
            handleOnInputChangeValue({
                value: calculated,
                componentsProperties,
                row_key: tableRow?.row_key,
                full_path,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculated]);

    // const autosize =
    //     renderContext === "column" && showFieldEditorModal
    //         ? { minRows: 1, maxRows: 1 }
    //         : { minRows: renderContext === "column" ? 1 : 2, maxRows: 50 };
    // const textAreaStyle = renderContext === "column" && showFieldEditorModal && fixedSize ? { width: "150px" } : {};

    // const textAreaProps = {
    //     autoSize: autosize,
    //     style: textAreaStyle,
    //     allowClear: renderContext === "column" && showFieldEditorModal ? false : true,
    // };

    // const canEdit = canCorrectField(itemData) && correctForm;

    // const disabled = canEdit ? false : currentTaskStatus || readonly;

    const stylesInput: React.CSSProperties | undefined =
        renderContext === "column" && showFieldEditorModal && componentsProperties.type !== COMPONENT_TYPE.INPUT_NUMBER
            ? {
                  display: "flex",
                  flexDirection: "row",
                  width: "200px",
              }
            : undefined;

    // let stylesInputNumber = { width: "100%" };
    // if (renderContext === "column" && type === componentType._inputNumber && itemData?.["dataIndex"] === "meta") {
    //     stylesInputNumber = { width: 100 };
    // }

    if (!visible || !relevant) {
        return null;
    }

    let customPropsInputChanges: any = {};

    if (componentsProperties.mask === "inteiro") {
        customPropsInputChanges.min = 0;
    }

    const isLoaging = mapLoad([dataStatus, whenValueChangeStatus]);

    return (
        <>
            <Group componentsProperties={componentsProperties} renderContext={renderContext}>
                <FormLabel
                    componentsProperties={componentsProperties}
                    required={required}
                    renderContext={renderContext}
                >
                    <div style={stylesInput}>
                        {componentsProperties.type === COMPONENT_TYPE.INPUT && (
                            <>
                                <Input
                                    value={currentValue}
                                    onChange={(e) => setCurrentValue(e.target.value)}
                                    disabled={justSee || readOnly}
                                    // placeholder={itemData["@placeholder"]}
                                    // defaultValue={Utilitaries.nullSafe(value)}
                                    allowClear={!isLoaging}
                                    onBlur={(e) => {
                                        handleOnInputChangeValue({
                                            value: e.target.value,
                                            componentsProperties,
                                            row_key: tableRow?.row_key,
                                            full_path,
                                        });
                                        setShowError(true);
                                    }}
                                    type={
                                        Utilitaries.toString(componentsProperties.mask).includes("password")
                                            ? "password"
                                            : "text"
                                    }
                                    // value={Utilitaries.nullSafe(value)}
                                    style={highlightsErrors({ value, required, restriction })}
                                    suffix={isLoaging && <Spin indicator={<Icon type="loading" spin />} size="small" />}
                                />
                            </>
                        )}

                        {componentsProperties.type === COMPONENT_TYPE.INPUT_AREA && (
                            <TextArea
                                value={currentValue}
                                onChange={(e) => setCurrentValue(e.target.value)}
                                disabled={justSee || readOnly}
                                // // autoSize={autosize}
                                // // style={textAreaStyle}
                                onBlur={(e) => {
                                    handleOnInputChangeValue({
                                        value: e.target.value,
                                        componentsProperties,
                                        row_key: tableRow?.row_key,
                                        full_path,
                                    });
                                    setShowError(true);
                                }}
                                style={highlightsErrors({ value, required, restriction })}
                                // {...textAreaProps}
                                // disabled={readOnly}
                                // // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                                // // value={nullSafe(itemData.value)}
                                // compact
                                // style={FormUtilitaries.highlightsErrors(currentValue, itemData.required)}
                            />
                        )}

                        {componentsProperties.type === COMPONENT_TYPE.INPUT_NUMBER && (
                            <InputNumber
                                style={{ width: "100%" }}
                                disabled={justSee || readOnly}
                                onBlur={(e) => {
                                    handleOnInputChangeValue({
                                        value: e.target.value,
                                        componentsProperties,
                                        row_key: tableRow?.row_key,
                                        full_path,
                                    });
                                    setShowError(true);
                                }}
                                // style={{
                                //     ...FormUtilitaries.highlightsErrors(nullSafe(itemData.value), itemData.required),
                                //     ...stylesInputNumber,
                                // }}
                                // allowClear
                                value={Utilitaries.nullSafe(value)}
                                {...customPropsInputChanges}
                                // defaultValue={Utilitaries.nullSafe(value)}
                                // // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                                // onChange={debounceChange}
                                // // onChange={onChange}
                                // // min={0}
                                // placeholder={itemData.placeholder}
                            />
                        )}

                        {renderContext === "column" &&
                            showFieldEditorModal &&
                            componentsProperties.type !== COMPONENT_TYPE.INPUT_NUMBER && (
                                <Button
                                    style={{ marginLeft: 5, ...highlightsErrors({ value, required, restriction }) }}
                                    icon="edit"
                                    onClick={() =>
                                        setFieldEditor({
                                            componentsProperties,
                                            onChange: (value) => {
                                                handleOnInputChangeValue({
                                                    value,
                                                    componentsProperties,
                                                    row_key: tableRow?.row_key,
                                                    full_path,
                                                });
                                                setShowError(true);
                                            },
                                            onCloseEditor: () => {},
                                            disabled: justSee || readOnly, //: currentTaskStatus || readonly || canCorrectField(itemData),
                                            // disabled, //: currentTaskStatus || readonly || canCorrectField(itemData),
                                            value: Utilitaries.toString(
                                                tableRow
                                                    ? tableRow?.row_data?.[componentsProperties.key]
                                                    : componentsProperties.value
                                            ),
                                        })
                                    }
                                />
                            )}
                    </div>
                    {showError && error && <span style={{ color: "red" }}>{error}</span>}
                </FormLabel>
            </Group>
        </>
    );
};

export const QAInput = memo(QAInputComponent, (prev, next) => compareProps<ComponentsPropertiesProps>(prev, next));
