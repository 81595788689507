import { componentType, fieldsToSendZeroNotExist } from "../constants";
import { getFieldInInputGroup } from "../constants/utils";
import { getItemByKey } from "./fields";
import { getParamNameToValue } from "./input_attributes";
import moment from "moment";
import { constrainPoint } from "@fullcalendar/core";
import { Utilitaries } from "../../../business";
import { toArray } from "../../../components/util";

const queryParamsDontSpecified = (query, form, allParams) => {
    const params = {};
    if (query === "cri_lista_servs_aval") {
        params.idCliente = form?.["cliente"]?.value | 0;
    } else if (query === "cri_quest_ident_cli_dif") {
        params.idProcesso = form?.["processo"]?.value | allParams?.idProcesso | 0;
    } else if (query === "competencias_formacao") {
        params.idFormacao = form?.["id_formacao"]?.value | "";
    } else if (query === "proc_audi_interna_v4") {
        params.id_auditoria = form?.["id_auditoria"]?.value | "";
    }

    return params;
};

export const setZeroInFormId = ({ allParams, formKey }) => {
    const obj = {};
    for (const key in allParams) {
        if (allParams[key] || allParams[key] === 0) {
            obj[key] = allParams[key];
        } else if ("id_" + formKey === key) {
            obj[key] = 0;
        }
    }
    return obj;
};

export const getInputParamsFromAttr = ({ item, form, allParams = {}, query = null, formKey }) => {
    if (!item?.["@param_popup"]) {
        const moreParams = queryParamsDontSpecified(query, form, allParams);

        return moreParams;
    }

    const params = item["@param_popup"].split(";").reduce((accumulator, current) => {
        if (form?.[current]?.value) {
            accumulator[current] = form?.[current]?.value;
        } else if (allParams?.[current]) {
            accumulator[current] = allParams?.[current];
        } else if (fieldsToSendZeroNotExist.includes(current)) {
            accumulator[current] = 0;
        } else if ("id_" + formKey === current && !["univ_itens_ad_inv_ex"].includes(formKey)) {
            accumulator[current] = 0;
        }

        return accumulator;
    }, {});

    return params;
};

const getValueInRootElement = ({ listItemByKey, paramNameToValue }) => {
    for (const el of listItemByKey) {
        if (!el.isColumn && !el.root) {
            let value = el.value;
            if (typeof value === "object" && "id" in value) {
                value = value.id;
            }
            return value;
        }
    }
    return "";
};

export const transformParams = ({
    params,
    root,
    record,
    rowKey,
    allDependencies,
    formData,
    allParams,
    inputGroupPath,
}) => {
    // ;
    const paramsTransformed = params
        ?.reduce((acumulator, current) => {
            const splitedParam = current.split(",");
            const paramName = splitedParam[0];
            let paramNameToValue = splitedParam[1];
            current = { paramName, paramNameToValue };
            acumulator.push(current);
            return acumulator;
        }, [])
        .map((item) => {
            let value = null;
            if (item.paramNameToValue.startsWith("param.")) {
                let paramNameToValue = item.paramNameToValue.replace("param.", "").replace(/\W/g, "").trim();
                value = allParams?.[paramNameToValue];
            } else if (item.paramNameToValue.startsWith("x_lista:")) {
                // //lista_presenca_sessao_v2/cliente[../participou!='false']})
                let xpath = item.paramNameToValue.sub("x_lista:").substr(8);

                xpath = xpath
                    .replace(/\sor\s/gi, " || ")
                    .replace(/\sand\s/gi, " && ")
                    .replace(/=/gi, "==") //TODO: replace to safer ===, that evaluates type and value
                    .replace(/'true'/gi, "'t'")
                    .replace(/'false'/gi, "'f'")
                    .replace(/(?:\\[rn])+/g, "")
                    .replace(/!==/gi, "!=");

                const rootPath = xpath.match(/\w+\s*(?=)/g)[1];

                let table = Object.values(formData).find(
                    (item) =>
                        item[rootPath] &&
                        (item["@tipo"] === componentType._dynamicTable ||
                            item["@tipo"] === componentType._selectionTable)
                );

                if (table) {
                    const regex = /\w*\s*(?=(=|\)|!|>?<))/g;

                    let allFieldNames = xpath
                        .match(regex)
                        .filter((item) => item)
                        .map((it) => it.trim());

                    const tableValues = Object.values(table.dataSource);

                    let conditionXpath = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

                    if (conditionXpath) {
                        conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

                        let fieldToSend = xpath.match(/\w*\s*(?=\[)/g);

                        if (!fieldToSend) {
                            fieldToSend = conditionXpath;
                        } else {
                            fieldToSend = fieldToSend[0];
                        }

                        value = tableValues.reduce((accumulator, current) => {
                            let condition = conditionXpath;

                            allFieldNames.forEach((fieldName) => {
                                condition = condition.replace(fieldName, `current["${fieldName}"]`);
                            });

                            const check = eval(condition);

                            if (check) {
                                accumulator = `${accumulator}${accumulator.length === 0 ? "" : ", "}'${
                                    current[fieldToSend]
                                }'`;
                            }
                            return accumulator;
                        }, "");
                    } else if (xpath.match(/(?!=\/)\w+(?=\})/g)) {
                        let fieldToSend = xpath.match(/(?!=\/)\w+(?=\})/g);

                        value = tableValues.reduce((accumulator, current) => {
                            accumulator = `${accumulator}${accumulator.length === 0 ? "" : ", "}'${
                                current[fieldToSend]
                            }'`;

                            return accumulator;
                        }, "");
                    }
                }
            } else {
                //
                // ;

                const paramNameToValue = getParamNameToValue(item);

                if (Array.isArray(paramNameToValue)) {
                    let path = formData;

                    let firsts = paramNameToValue.slice(0, paramNameToValue.length - 1);
                    let last = paramNameToValue.splice(-1)[0];

                    firsts.forEach((el) => {
                        path = path[el];
                    });

                    value = path?.["dataSource"]?.[rowKey]?.[last];
                    if (!value) {
                        value = record?.[last];
                    }
                    if (!value) {
                        value = path?.[last]?.value || "";
                    }
                } else if (paramNameToValue?.startsWith("v:")) {
                    value = paramNameToValue.replace("v:", "");
                } else {
                    if (inputGroupPath || !root) {
                        if (isNaN(paramNameToValue)) {
                            const itemByKey = getItemByKey({
                                key: paramNameToValue,
                                tree: formData,
                                item,
                            });

                            const listItemByKey = toArray(itemByKey);
                            const sizeItemByKey = listItemByKey.length;
                            if (inputGroupPath && sizeItemByKey > 1) {
                                const itemValue = getFieldInInputGroup(
                                    { ...formData },
                                    [...inputGroupPath],
                                    paramNameToValue
                                );
                                value = itemValue?.value;
                            } else {
                                if (!inputGroupPath && !root && !rowKey && sizeItemByKey > 1) {
                                    if (item.paramNameToValue.startsWith("//")) {
                                        // get in root,
                                        value = getValueInRootElement({ listItemByKey, paramNameToValue });
                                    } else {
                                        value = itemByKey[0]?.value;
                                    }
                                } else {
                                    value = itemByKey[0]?.value;
                                }
                            }
                        } else {
                            value = paramNameToValue;
                        }
                        /* } 
                    if (inputGroupPath) {
                        const itemValue = getFieldInInputGroup({ ...formData }, [...inputGroupPath], paramNameToValue);
                        value = itemValue?.value;
                    } else if (!root) {
                        if (isNaN(paramNameToValue)) {
                            // value = getItemByKey({
                            //     key: paramNameToValue,
                            //     tree: formData,
                            // })[0]?.value;
                            const itemByKey = getItemByKey({
                                key: paramNameToValue,
                                tree: formData,
                                item,
                            });
                            value = itemByKey[0]?.value;
                        } else {
                            value = paramNameToValue;
                        } */
                    } else {
                        if (item.paramNameToValue.startsWith("../") && record && record[paramNameToValue]) {
                            value = record?.[paramNameToValue];
                            if (typeof value === "object" && "id" in value) {
                                value = value.id;
                            }
                        } else {
                            const itemValue = Object.values(formData).find((formItem) => formItem.key === root)
                                ?.dataSource?.[rowKey]?.[paramNameToValue];
                            value = itemValue?.id || itemValue || formData?.[paramNameToValue]?.value; //TODO: alterar caso gerar problema
                            if (!value) {
                                value = record?.[paramNameToValue];
                            } else if (typeof value === "object" && "id" in value) {
                                value = value.id;
                            }
                        }
                    }
                }
            }

            //TODO: para booleam -  pode causar problema se for para enviar t ou f
            if (value === "t") {
                value = "true";
            } else if (value === "f") {
                value = "false";
            } else if (value === "{now()}") {
                value = moment().format("YYYY-MM-DD");
            }

            return { ...item, value: Utilitaries.toString(value) };
        })
        .reduce((acumulator, current) => {
            acumulator[current.paramName] = current.value;
            return acumulator;
        }, {});

    return paramsTransformed;
};

export const handleRequestParams = (params = {}) => {
    let requestParams = params;

    Object.keys(params).forEach((key) => {
        let value = params[key];

        if (typeof value === "string" && /[A-z]'[A-z]/.test(value)) {
            value = value.trim().replace(/([A-z])'/g, "$1''");
        }

        requestParams[key] = value;
    });

    return requestParams;
};
