import { Utilitaries } from "../../../business";
import moment from "moment";
import { RENTAL_TYPE } from "./constants";

export interface CalendarEventProps {
    id: string;
    start?: string;
    end?: string;
    allDay?: boolean;
    title: string;
    backgroundColor: string;
    borderColor: string;
    _description?: string;
    _data_inicio?: string;
    _data_fim?: string;
    _tipo?: string;
}

const getTypeDescription = (type: string) => {
    switch (type) {
        case "1":
            return "Reservado";
        case "2":
            return "Manutenção";
        case "3":
            return "Indisponivel";
        default:
            return "";
    }
};

export const formatDataCalendarEvents = (result: any[]) => {
    const dataSource = result.map((el: any) => {
        const start = moment(`${el.data_inicio}`, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm");
        const end = moment(`${el.data_fim} 23:59`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");

        const color = getRentalTypeColor(el.tipo) || "inherit";
        const __event: CalendarEventProps = {
            id: el.id_rent_reserva,
            start,
            end,
            title: getTypeDescription(el.tipo),
            backgroundColor: color,
            borderColor: color,
            // allDay: true,
            _description: Utilitaries.toString(el.descricao),
            _data_inicio: el.data_inicio,
            _data_fim: el.data_fim,
            _tipo: el.tipo,
        };

        return __event;
    });
    return dataSource;
};

export const getRentalTypeColor = (type: string) => {
    switch (type) {
        case RENTAL_TYPE.AVAILABLE:
            return "White";
        case RENTAL_TYPE.RESERVED:
            return "#DC7633";
        case RENTAL_TYPE.MAINTENANCE:
            return "#3788d8";
        case RENTAL_TYPE.UNAVAILABLE:
            return "#ccc";

        default:
            return "";
    }
};
