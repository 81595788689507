import React, { Component } from "react";
import { Radio, Input, Button, Upload, message, Icon } from "antd";
import { componentToRender } from "../../../constants/index";

const buttonStyle = { marginRight: 4, display: "inline" };

const normalMode = {
    mode: componentToRender.normalForm,
    icon: "file-text",
    title: "Modo Normal",
    style: buttonStyle,
};

const advancedMode = {
    mode: componentToRender.advancedForm,
    icon: "code",
    title: "Modo Avançado",
    style: buttonStyle,
};

class ComponentForm extends Component {
    state = { selected: "pc" };

    onChangehandler = (e) => {
        this.setState({ selected: e.target.value });
    };

    render() {
        const formButton = ({ mode, icon, title, style }) => {
            return (
                <Button
                    data-mode={mode}
                    style={style}
                    icon={icon}
                    onClick={() => {
                        this.props.showComponentModal(mode);
                    }}
                    title={title}
                />
            );
        };

        const existingAndNew = (
            <div style={{ display: "block", marginTop: 5 }}>
                <Input placeholder="Introduza o nome" style={{ width: "45%", marginRight: 4 }} />
                {formButton(normalMode)}
                {formButton(advancedMode)}
            </div>
        );

        const props = {
            name: "file",
            action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
            headers: {
                authorization: "authorization-text",
            },
            onChange(info) {
                if (info.file.status !== "uploading") {
                }
                if (info.file.status === "done") {
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === "error") {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <div>
                <Radio.Group onChange={this.onChangehandler} defaultValue="a">
                    <Radio.Button value="pc">PC</Radio.Button>
                    <Radio.Button value="existing">Existente</Radio.Button>
                    <Radio.Button value="new">Novo</Radio.Button>
                </Radio.Group>
                {this.state.selected === "pc" ? (
                    <div style={{ display: "block", marginTop: 5 }}>
                        {formButton(normalMode)}
                        {formButton(advancedMode)}
                        <Upload {...props}>
                            <Button title="Modo Pc">
                                <Icon type="upload" />
                            </Button>
                        </Upload>
                    </div>
                ) : null}
                {this.state.selected === "existing" ? existingAndNew : null}
                {this.state.selected === "new" ? existingAndNew : null}
            </div>
        );
    }
}

export default ComponentForm;
