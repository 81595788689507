import { PageHeader } from "antd";
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Session, Utilitaries } from "../../../business";
import { sendHttpRequest } from "../../../components/http.request";
import { FormRender } from "../../components/FormRender";
import { GenericObject } from "../../types/common";

interface Props {
    form_name?: string;
    form_id?: string;
    popup_params?: GenericObject;
    onSave: (value?: string) => void;
    setJustSeePopup?: (justSeePopup: boolean) => void;
    justSee?: boolean;
    isInModal: boolean;
}

interface ParamProps {
    name: string;
    id: string;
}

export function MenuFormRender({
    form_name,
    form_id,
    popup_params,
    onSave,
    justSee,
    setJustSeePopup,
    isInModal = false,
}: Props) {
    const { name, id } = useParams<ParamProps>();

    const form_key = form_name || name || "";
    const browserHistory = useHistory();

    const [form, setForm] = React.useState<any>({});
    const [formTitle, setFormTitle] = React.useState<string>();

    const [loading, setLoading] = React.useState(false);

    // const handleSave = () => {
    //     debugger;
    //     // if (formulario?.["@relatorio"]) {
    //     //     Modal.confirm({
    //     //         title: "Imprimir relatório",
    //     //         content: "",
    //     //         okType: "danger",
    //     //         onOk: () => {
    //     //             downloadFile("/gererelatorios.php", {
    //     //                 accao: "relatorio_nome",
    //     //                 nome: formulario?.["@relatorio"],
    //     //                 saida: "pdf",
    //     //                 id: result.sucesso,
    //     //                 CHAVE1: result.sucesso,
    //     //                 downloadInBrowser: true,
    //     //             });
    //     //         },
    //     //         onCancel() {},
    //     //     });
    //     // }
    //     onSave();
    // };

    useEffect(() => {
        const getForm1 = async () => {
            const _formData = new FormData();

            _formData.append("accao", "consultar");
            _formData.append("hack", "true");
            // _formData.append("processo", "14147");
            // _formData.append("tarefa", "18800");
            _formData.append("processo", "52886");
            _formData.append("tarefa", "67341");

            _formData.append("hack", "true");
            _formData.append("formulario", form_key);
            setLoading(true);

            const { dados } = await sendHttpRequest("POST", "/Gestor/gereprocesso.php", _formData);
            let formBody = {
                form: null,
                values: null,
            };
            formBody = {
                form: dados.formulario,
                values: dados.valores,
            };

            setForm(formBody);
            setLoading(false);
        };
        const getForm = async () => {
            try {
                const _formData = new FormData();

                // form_id = "216590";

                // if (form_id) {
                // _formData.append("accao", "dados");
                // _formData.append("id", form_id);
                // } else {
                //     _formData.append("accao", "consultar");
                // }
                _formData.append("utilizador", Session.getUsername());
                // const paramsKeys = params && typeof params === "object" ? Object.keys(params) : [];

                // paramsKeys.forEach((el) => {
                //     if (!Utilitaries.isEmpty(params[el]) && !_formData.has(el)) {
                //         _formData.append(el, params[el]);
                //     }
                // });

                _formData.append("accao", "dados");
                let formID = "";
                // se menu enviar 0
                // se popup enviar vazio para novo e id para editar
                if (form_id && isInModal) {
                    formID = form_id;
                } else if (id && !isInModal) {
                    formID = id;
                } else if (form_name) {
                    formID = "";
                } else {
                    formID = "0";
                }
                _formData.append("id", formID);

                if (!Utilitaries.isEmpty(popup_params) && popup_params) {
                    Object.keys(popup_params).forEach((el) => {
                        if (!_formData.has(el)) {
                            _formData.append(el, popup_params[el]);
                        }
                    });
                }

                _formData.append("hack", "true");
                _formData.append("formulario", form_key);
                setLoading(true);
                const { formulario, result } = await sendHttpRequest("POST", "/Gestor/gereformulario.php", _formData);
                let formBody = {
                    form: null,
                    values: null,
                };

                // if (form_id) {
                formBody = {
                    form: formulario || result?.formulario,
                    values: result?.valores || null,
                };
                // } else {
                //     formBody = {
                //         form: formulario,
                //         values: null,
                //     };
                // }

                setForm(formBody);
                const rootElement = formBody.form?.["@elemento_raiz"];

                if (setJustSeePopup) {
                    const formReadOnly =
                        formBody.form?.[rootElement!]?.["@consulta_formulario"] === "sim" ? true : justSee;
                    setJustSeePopup(formReadOnly || false);
                }

                if (!isInModal) {
                    setFormTitle(formBody.form?.[rootElement!]?.["@titulo"]);
                }

                setLoading(false);
            } catch (error) {
                setForm({});
                setLoading(false);
            }
        };

        getForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_id, form_key]);

    return (
        <>
            {!isInModal && <PageHeader onBack={() => browserHistory.goBack()} title={formTitle} />}
            <FormRender
                loadForm={loading}
                form={form}
                popup_params={popup_params}
                onSave={onSave}
                justSee={justSee}
                isInModal={isInModal}
            ></FormRender>
        </>
    );
}
