import React, { useState, useEffect, useMemo } from "react";
import {
    AutoComplete,
    Modal,
    message,
    Icon,
    Button,
    Form,
    Input,
    Row,
    Col,
    Radio,
    Checkbox,
    Table,
    Select,
    Upload,
    Typography,
    Divider,
    List,
    Badge,
    Alert,
} from "antd";
import { ChromePicker } from "react-color";
import moment from "moment";

import { sendHttpRequest } from "../http.request";
import ModalSelectUser from "../select.user";
import Visualization from "../Visualization/index";
import ScheduleEvent from "./scheduleEvent";
import EventPermission from "./EventPermission";
import ChangeReason from "./ChangeReason";
import PlaceEditor from "./Place";
import { Session, Utilitaries } from "../../business";
import { baseUrl } from "../../constants/enviroment";
import { toArray } from "../util";
import Stakeholders from "../qa_custom/Stakeholders";

const { TextArea } = Input;
const { Option } = Select;

const DEFAUL_COLOR = "#999";

let tmpUpdateInfo = null;

const ScheduleEditor = (props) => {
    const [eventType, setEventType] = useState(1);
    const [eventLocationType, setEventLocationType] = useState("interno");
    const [allDay, setAllDay] = useState(props.event.allDay || false);
    const [periods, setPediods] = useState({});
    const [typologyEvents, setTypologyEvents] = useState("Pessoal");
    const [definedEvents, setdefinedEvents] = useState("");

    const [dataInicio, setDataInicio] = useState("");
    const [horaInicio, setHoraInicio] = useState("");
    const [dataFim, setDataFim] = useState("");
    const [horaFim, setHoraFim] = useState("");
    const [dataError, setDataError] = useState({});

    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [showModalEventPermissions, setShowModalEventPermissions] = useState(false);
    const [eventPermissions, setEventPermissions] = useState([]);
    const [optionsExternalLocalSearch, setOptionsExternalLocalSearch] = useState([]);

    // switch visualization model status
    const [visualizationData, setVisualizationData] = useState([]);

    const [isVisibleModalSelectUserAndGroup, setIsVisibleModalSelectUserAndGroup] = useState(false);
    const [isVisibleModalStakehoders, setIsVisibleModalStakehoders] = useState(false);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const [showScheduleEvent, setShowScheduleEvent] = useState(false);
    const [showReapetedEvents, setShowReapetedEvents] = useState(false);
    const [repeatedEvents, setRepeatedEvents] = useState([]);
    const [overlapEventsSpace, setOverlapEventsSpace] = useState([]);
    const [canTryAgain, setCanTryAgain] = useState(true);
    const [spaceOverlay, setSpaceOverlay] = useState(false);
    const [dateOverlay, setDateOverlay] = useState(false);
    const [repeatedEventsUsers, setRepeatedEventsUsers] = useState([]);

    const [showDeleteReason, setShowDeleteReason] = useState(false);
    const [visibleChangeReason, setVisibleChangeReason] = useState(false);
    const [visiblePlaceEditor, setVisiblePlaceEditor] = useState(false);
    const [color, setColor] = useState("");

    const [communicateTo, setCommunicateTo] = useState({
        email_users: "",
        email_groups: "",
        sms_users: "",
        sms_groups: "",
    });

    const [thirdParties, setThirdParties] = useState({
        tirceiros_clientes: [],
        tirceiros_parceiros: [],
        tirceiros_socios: [],
        tirceiros_fornecedores: [],
    });

    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        setAllDay(props.event.allDay || false);
    }, [props.event.allDay]);

    useEffect(() => {
        if (props.event.grupo) {
            let _users = props.event.grupo.filter(
                (item) => typeof item.grupo === "object" && typeof item.nome_grupo === "object"
            );
            let _groups = props.event.grupo.filter(
                (item) => typeof item.username === "object" && typeof item.nome === "object"
            );

            _users = _users.map((item) => ({
                username: item.username,
                name: item.nome,
            }));

            _groups = _groups.map((item) => ({
                id: item.grupo,
                value: item.nome_grupo,
            }));

            setEventLocationType(props.event.tipo_local ? props.event.tipo_local : "interno");

            setGroups(_groups);
            setUsers(_users);

            if (props.event?.periodicidade && props.event?.periodicidade?.["@attributes"]) {
                let linhas;

                if (props.event.periodicidade["@attributes"].tipo === "diaria") {
                    linhas = {
                        inicio: props.event.periodicidade.linhas["linha"].hora_inicio,
                        fim: props.event.periodicidade.linhas["linha"].hora_fim,
                    };
                } else {
                    linhas = Array.isArray(props.event.periodicidade.linhas["linha"])
                        ? props.event.periodicidade.linhas["linha"]
                        : [props.event.periodicidade.linhas["linha"]];
                }

                setPediods({
                    ...props.event.periodicidade["@attributes"],
                    // tipo: type,
                    // periodo_ini: pontualEventos.limit.start,
                    // periodo_fim: pontualEventos.limit.end_date,
                    // tipo_validade: pontualEventos.limit.tipo_validade,
                    // num_ocorr: pontualEventos.limit.numero_ocorrencia,
                    // repetir: pontualEventos.repetir,
                    linhas,
                });
            }
        }

        if (props.event.terceiros && toArray(props.event.terceiros).length > 0) {
            const tirceiros_clientes = toArray(props.event.terceiros).filter((it) => it.tipo_terceiro === "C");
            // .map((it) => it.terceiro);
            const tirceiros_parceiros = toArray(props.event.terceiros).filter((it) => it.tipo_terceiro === "P");
            // .map((it) => it.terceiro);
            const tirceiros_socios = toArray(props.event.terceiros).filter((it) => it.tipo_terceiro === "S");
            // .map((it) => it.terceiro);
            const tirceiros_fornecedores = toArray(props.event.terceiros).filter((it) => it.tipo_terceiro === "F");
            // .map((it) => it.terceiro);

            setThirdParties({
                tirceiros_clientes,
                tirceiros_parceiros,
                tirceiros_socios,
                tirceiros_fornecedores,
            });
        }

        if (!props.event.new && props.event.cor && !color) {
            setColor(props.event.cor);
        }

        setEventType(props.event.periodicidade ? 2 : 1);

        setdefinedEvents(Utilitaries.toString(props.event.evento_defeito));

        if (props.event.tipologia_evento) {
            setTypologyEvents(props.event.tipologia_evento);
        }

        let statDate = null,
            endDate = null;

        if (props.event.new) {
            statDate = moment(props.event.start, formatPicker);
            endDate = moment(props.event.end, formatPicker).subtract(1, "d");
        } else {
            statDate = moment(props.event.data_inicio, formatPicker);
            endDate = moment(props.event.data_fim, formatPicker);
        }

        if (statDate) {
            setDataInicio(statDate.format("YYYY-MM-DD"));
            setHoraInicio(statDate.format("HH:mm"));
        }

        if (endDate) {
            setDataFim(endDate.format("YYYY-MM-DD"));
            setHoraFim(endDate.format("HH:mm"));
        }
    }, [props.event]);

    const countThirdParteis = useMemo(() => {
        const count = Object.keys(thirdParties)
            .map((it) => toArray(thirdParties[it]).length)
            .reduce((a, b) => a + b, 0);
        return count;
    }, [thirdParties]);

    const isValidDate = () => {
        if (eventType === 1 && (!dataInicio || !dataFim || (!allDay && (!horaInicio || !horaFim)))) {
            setDataError({
                dataInicio: Utilitaries.isEmpty(dataInicio),
                dataFim: Utilitaries.isEmpty(dataFim),
                horaInicio: Utilitaries.isEmpty(horaInicio) && !allDay,
                horaFim: Utilitaries.isEmpty(horaFim) && !allDay,
            });

            return false;
        }

        return true;
    };

    const handleOk = (confirmEventOptions = { confirmEvent: false }) => {
        if (!isValidDate()) {
            return;
        }

        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const formData = new FormData();
                formData.append("accao", props.event.new ? "enviar" : "alterar");
                if (!props.event.new) {
                    formData.append("id", props.event.id);

                    // Verificar se existe utilizadores ou grupos nesse evento
                    if (
                        props.event.grupo &&
                        Array.isArray(props.event.grupo) &&
                        (props.event.grupo.length > 1 ||
                            (props.event.grupo.length === 1 && Object.keys(props.event.grupo[0]).length > 0))
                    ) {
                        // há utilizadores tem de aticionar motivo
                        // e enviar notificacões pelos meios escolhidos
                        const _confirmEventOptions = tmpUpdateInfo ? tmpUpdateInfo : confirmEventOptions;
                        formData.append("aviso_lembrete", _confirmEventOptions.sendRemember);
                        formData.append("aviso_sms", _confirmEventOptions.sendSms);
                        formData.append("aviso_message", _confirmEventOptions.sendMessage);
                        formData.append("aviso_email", _confirmEventOptions.sendEmail);
                        formData.append("motivo", _confirmEventOptions.reason);
                    }
                }

                fileList.forEach((file) => {
                    formData.append("anexos[]", file);
                });

                formData.append("communicate_email_users", communicateTo.email_users);
                formData.append("communicate_email_groups", communicateTo.email_groups);
                formData.append("communicate_sms_users", communicateTo.sms_users);
                formData.append("communicate_sms_groups", communicateTo.sms_groups);

                Object.keys(thirdParties).forEach((key) => {
                    formData.append(
                        key,
                        JSON.stringify(
                            thirdParties[key].map((third) => {
                                Object.keys(third).forEach((thirdKey) => {
                                    if (typeof third[thirdKey] === "object") {
                                        third[thirdKey] = third[thirdKey]?.value || "";
                                    }
                                });

                                return third;
                            })
                        )
                    );
                });

                formData.append("titulo", values.nome);
                formData.append("descricao", values.descricao);
                formData.append("local", values.local);
                formData.append("tipologia_evento", typologyEvents);
                formData.append("tipo_local", eventLocationType);
                formData.append("todo_dia", allDay);

                if (confirmEventOptions.confirmEvent) {
                    formData.append("confirmEvent", "1");
                }

                if (eventType === 1) {
                    //Unico
                    // formData.append("data_inicio", values.data[0].format("YYYY-MM-DD HH:mm"));
                    // formData.append("data_fim", values.data[1].format("YYYY-MM-DD HH:mm"));

                    formData.append("data_inicio", `${dataInicio} ${allDay ? "00:00" : horaInicio}`);
                    formData.append("data_fim", `${dataFim} ${allDay ? "23:59" : horaFim}`);
                } else {
                    // repetir
                    formData.append("periodicidade", JSON.stringify(periods));
                }

                formData.append("cor", color);
                formData.append("evento_defeito", definedEvents || "");

                formData.append("permissao", JSON.stringify(eventPermissions));

                formData.append(
                    "grupos",
                    groups.reduce(
                        (accumulator, currentValue) =>
                            accumulator === "" ? currentValue.id : accumulator + ";" + currentValue.id,
                        ""
                    )
                );
                formData.append(
                    "utilizadores",
                    users.reduce(
                        (accumulator, currentValue) =>
                            accumulator === "" ? currentValue.username : accumulator + ";" + currentValue.username,
                        ""
                    )
                );

                if (props.utilizadorSelected) {
                    formData.append("utilizador_agenda", props.utilizadorSelected);
                }

                sendHttpRequest("POST", "/Gestor/gereagenda.php", formData).then((jsonResponse) => {
                    //
                    if (jsonResponse.erro) {
                        message.error(jsonResponse.erro);
                    } else {
                        if (jsonResponse.repeated || jsonResponse?.space) {
                            tmpUpdateInfo = confirmEventOptions;
                            setRepeatedEventsUsers(jsonResponse.users);
                            setRepeatedEvents(jsonResponse.events_date);
                            setDateOverlay(!!jsonResponse?.repeated);

                            setOverlapEventsSpace(jsonResponse.events_space);
                            setSpaceOverlay(jsonResponse?.space || false);
                            setCanTryAgain(!!jsonResponse?.tryAgain);
                            setShowReapetedEvents(true);
                        } else {
                            if (confirmEventOptions?.resolve && typeof confirmEventOptions?.resolve === "function") {
                                confirmEventOptions.resolve();
                            }
                            message.success(
                                props.event.new ? "Evento criado com sucesso" : "Evento alterado com sucesso"
                            );
                            props.onSave();
                            tmpUpdateInfo = null;
                        }
                    }
                });
            }
        });
    };

    const handleDelete = (reason) => {
        const formData = new FormData();
        formData.append("accao", "anular");
        formData.append("id", props.event.id);
        formData.append("motivo", reason);

        if (props.utilizadorSelected) {
            formData.append("utilizador_agenda", props.utilizadorSelected);
        }

        sendHttpRequest("POST", "/Gestor/gereagenda.php", formData).then((jsonResponse) => {
            //
            if (jsonResponse.erro) {
                message.error(jsonResponse.erro);
            } else {
                message.success("Evento apagado com sucesso");
                setShowDeleteReason(false);
                props.onSave();
            }
        });
    };

    const handlerDefaultEventSelect = (value) => {
        const item = props.definedEvents.list.find((item) => item.id_evento_defeito === value);
        if (item) {
            setColor(item.cor);
            setdefinedEvents(value);
            props.form.setFieldsValue({
                nome: item.descricao,
            });
        }
    };

    const { getFieldDecorator } = props.form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 17 },
        },
    };

    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    };

    const popover = {
        position: "absolute",
        zIndex: "2",
    };

    const cover = {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    };

    const formatPicker = allDay ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    const downloadFile = (e, file) => {
        e.preventDefault();
        window.open(
            `${baseUrl}/Gestor/gereagenda.php?accao=anexo&id=${file.id}&token=Bearer ${Session.getToken()}`,
            "_blank"
        );
        // const formData = new FormData();
        // formData.append("accao", "anexo");
        // formData.append("id", file.id);

        // var request = new XMLHttpRequest();
        // request.open("POST", baseUrl + "/Gestor/geremensagens.php", true);

        // request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
        // request.responseType = "blob";

        // request.onload = function () {
        //     // Only handle status code 200
        //     if (request.status === 200) {
        //         // Try to find out the filename from the content disposition `filename` value
        //         var disposition = request.getResponseHeader("content-disposition");
        //
        //         var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
        //

        //         var filename = matches != null && matches[1] ? matches[1] : file.nome_ficheiro;

        //         //
        //         //     'request.getResponseHeader("content-type")',
        //         //     request.getResponseHeader("content-type")
        //         // );

        //         // The actual download
        //         var blob = new Blob([request.response], {
        //             type: request.getResponseHeader("content-type"),
        //         });

        //         var link = document.createElement("a");
        //         link.href = window.URL.createObjectURL(blob);
        //         link.download = filename;

        //         document.body.appendChild(link);

        //         link.click();

        //         document.body.removeChild(link);
        //     }

        //     // some error handling should be done here...
        // };

        // request.send(formData);
    };

    return (
        <>
            <PlaceEditor
                visible={visiblePlaceEditor}
                onCancel={() => setVisiblePlaceEditor(false)}
                oldPlaces={props.places}
            />

            <ChangeReason
                visible={visibleChangeReason}
                handleOk={(reason, sendRemember, sendMessage, sendSms, sendEmail) => {
                    //
                    handleOk({
                        reason,
                        sendRemember,
                        sendSms,
                        sendMessage,
                        sendEmail,

                        confirmEvent: false,
                        resolve: () => {
                            setVisibleChangeReason(false);
                        },
                    });
                }}
                handleCancel={() => setVisibleChangeReason(false)}
                formItemLayout={props.formItemLayout}
            />

            <ShowReapetedEventsModal
                showReapetedEvents={showReapetedEvents}
                events={repeatedEvents}
                users={repeatedEventsUsers}
                repeated={dateOverlay}
                tryAgain={canTryAgain}
                eventsSpace={overlapEventsSpace}
                space={spaceOverlay}
                onOk={() => {
                    handleOk({
                        confirmEvent: true,
                        resolve: () => {
                            setShowReapetedEvents(false);
                        },
                    });
                }}
                onCancel={() => {
                    setShowReapetedEvents(false);
                    props.onCancel();
                }}
            />

            <DeleteReasonForm
                showDeleteReason={showDeleteReason}
                handleDelete={handleDelete}
                setShowDeleteReason={setShowDeleteReason}
                formItemLayout={props.formItemLayout}
            />

            <Visualization
                visible={visualizationData.length > 0}
                onCancel={() => setVisualizationData([])}
                data={visualizationData}
            />

            {/* {isVisibleModalSelectUserAndGroup && ( */}
            <ModalSelectUser
                visible={isVisibleModalSelectUserAndGroup}
                setVisible={setIsVisibleModalSelectUserAndGroup}
                defaultUsers={users}
                defaultGroups={groups}
                {...props.selectContributorsProps}
                communication={true}
                onOk={(usersSelected, groupsSelected, sendEmail, sendSMS) => {
                    setGroups(groupsSelected);
                    setUsers(usersSelected);
                    setCommunicateTo({
                        email_users: Object.keys(sendEmail.users).join(";"),
                        email_groups: Object.keys(sendEmail.groups).join(";"),
                        sms_users: Object.keys(sendSMS.users).join(";"),
                        sms_groups: Object.keys(sendSMS.groups).join(";"),
                    });
                }}
            />
            {/* )} */}

            {isVisibleModalStakehoders && (
                <Stakeholders
                    visible={isVisibleModalStakehoders}
                    setVisible={setIsVisibleModalStakehoders}
                    defaultValues={thirdParties}
                    onOk={(selected) => {
                        setThirdParties({
                            tirceiros_clientes: toArray(selected?.selectedClients),
                            tirceiros_parceiros: toArray(selected?.selectedPartners),
                            tirceiros_socios: toArray(selected?.selectedBusinessParter),
                            tirceiros_fornecedores: toArray(selected?.selectedSuppliers),
                        });
                    }}
                />
            )}

            <ScheduleEvent
                visible={showScheduleEvent}
                onCancel={() => setShowScheduleEvent(false)}
                defaultValues={props.event?.periodicidade ? props.event.periodicidade : {}}
                onOk={(periods) => {
                    //
                    setPediods(periods);
                    setShowScheduleEvent(false);
                }}
            />

            <EventPermission
                visible={showModalEventPermissions}
                onCancel={() => setShowModalEventPermissions(false)}
                onOk={(permissions) => {
                    setEventPermissions(permissions);

                    setShowModalEventPermissions(false);
                }}
                eventsPermissions={[]}
                {...props.selectContributorsProps}
            />

            <Modal
                title={props.event.new ? "Adicionar Evento" : "Alterar Evento"}
                visible={props.visible}
                onOk={() => handleOk()}
                onCancel={props.onCancel}
                destroyOnClose
                width="60%"
                footer={
                    props.event.new
                        ? [
                              <Button key="cancel" onClick={props.onCancel}>
                                  Cancelar
                              </Button>,
                              <Button key="guardar" type="primary" onClick={() => handleOk()}>
                                  Guardar
                              </Button>,
                          ]
                        : [
                              <Button type="danger" key="apagar" onClick={() => setShowDeleteReason(true)}>
                                  Apagar
                              </Button>,
                              <Button key="visualizacao" onClick={() => setVisualizationData(props.event.visualizacao)}>
                                  Visualizações
                              </Button>,
                              <Button key="cancelar_1" onClick={props.onCancel}>
                                  Cancelar
                              </Button>,
                              <Button
                                  key="alterar"
                                  type="primary"
                                  onClick={() => {
                                      if (
                                          props.event.grupo &&
                                          Array.isArray(props.event.grupo) &&
                                          (props.event.grupo.length > 1 ||
                                              (props.event.grupo.length === 1 &&
                                                  Object.keys(props.event.grupo[0]).length > 0))
                                      ) {
                                          if (!isValidDate()) {
                                              return;
                                          }
                                          //   há utilizadores tem de aticionar motivo
                                          setVisibleChangeReason(true);
                                      } else {
                                          handleOk();
                                      }
                                  }}
                              >
                                  Alterar
                              </Button>,
                          ]
                }
            >
                <Form {...formItemLayout} layout="vertical">
                    {/* Event exist */}
                    <Form.Item label="Eventos definidos">
                        {/* {getFieldDecorator("defined_events", {
                            initialValue: Utilitaries.toString(
                                props.event.evento_defeito ? props.event.evento_defeito : null
                            ),
                        })( */}
                        <Select
                            allowClear
                            showSearch
                            placeholder=""
                            value={Utilitaries.toString(definedEvents)}
                            onChange={(value) => {
                                handlerDefaultEventSelect(value);
                            }}
                        >
                            {props.definedEvents.list.map((item) => (
                                <Option
                                    key={`${item.id_evento_defeito}_${new Date().getTime()}`}
                                    value={item.id_evento_defeito}
                                    title={item.descricao}
                                >
                                    {item.designacao}
                                </Option>
                            ))}
                        </Select>
                        {/* )} */}
                    </Form.Item>

                    {/* Event Name */}
                    <Form.Item label="Nome do evento:">
                        {getFieldDecorator("nome", {
                            initialValue: props.event.new ? "" : props.event.titulo,
                            rules: [
                                {
                                    required: true,
                                    message: "Informe o nome do evento!",
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>

                    {/* Event Date */}
                    <Row className="ant-form-item">
                        <Col className="ant-form-item-label" xs={24} sm={5}>
                            <label className="ant-form-item-required" htmlFor="" title="Tipo de evento">
                                Tipo de evento
                            </label>
                        </Col>
                        <Col className="ant-form-item-control-wrapper" xs={24} sm={17}>
                            <Row className="ant-form-item-control">
                                <Col span={5}>
                                    <Radio.Group onChange={(e) => setEventType(e.target.value)} value={eventType}>
                                        <Radio style={radioStyle} value={1}>
                                            Único
                                        </Radio>
                                        <Radio style={radioStyle} value={2}>
                                            Repetir
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                                <Col span={19}>
                                    {eventType === 1 ? (
                                        <>
                                            {/* <Form.Item
                                                wrapperCol={{
                                                    xs: { span: 24 },
                                                }}
                                            >
                                                {getFieldDecorator("data", {
                                                    initialValue: props.event.new
                                                        ? [
                                                              moment(props.event.start, formatPicker),
                                                              moment(props.event.end, formatPicker),
                                                          ]
                                                        : [
                                                              moment(props.event.data_inicio, formatPicker),
                                                              moment(props.event.data_fim, formatPicker),
                                                          ],
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Informe a data do evento!",
                                                        },
                                                    ],
                                                })(
                                                    <RangePicker
                                                        {...rangePickerProps}
                                                        placeholder={["Data Inicio", "Data Fim"]}
                                                    />
                                                )}
                                            </Form.Item> */}
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        wrapperCol={{
                                                            xs: { span: 24 },
                                                        }}
                                                        labelCol={{
                                                            xs: { span: 24 },
                                                        }}
                                                        label="Data início"
                                                    >
                                                        <input
                                                            type="date"
                                                            value={dataInicio}
                                                            placeholder="DD-MM-YYYY"
                                                            // min={moment().format("DD-MM-YYYY")}
                                                            // pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                                            onChange={(e) => setDataInicio(e.target.value)}
                                                        />
                                                        <br />
                                                        <span style={{ color: "red" }}>
                                                            {dataError?.dataInicio && !dataInicio
                                                                ? "Campo obrigatório"
                                                                : " "}
                                                        </span>
                                                    </Form.Item>
                                                </Col>
                                                {!allDay && (
                                                    <Col span={12}>
                                                        <Form.Item
                                                            wrapperCol={{
                                                                xs: { span: 24 },
                                                            }}
                                                            labelCol={{
                                                                xs: { span: 24 },
                                                            }}
                                                            label="Hora início"
                                                        >
                                                            <input
                                                                type="time"
                                                                value={horaInicio}
                                                                onChange={(e) => setHoraInicio(e.target.value)}
                                                            />
                                                            <br />
                                                            <span style={{ color: "red" }}>
                                                                {dataError?.horaInicio && !horaInicio
                                                                    ? "Campo obrigatório"
                                                                    : " "}
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                )}

                                                <Col span={12}>
                                                    <Form.Item
                                                        wrapperCol={{
                                                            xs: { span: 24 },
                                                        }}
                                                        labelCol={{
                                                            xs: { span: 24 },
                                                        }}
                                                        label="Data fim"
                                                    >
                                                        <input
                                                            type="date"
                                                            value={dataFim}
                                                            onChange={(e) => setDataFim(e.target.value)}
                                                        />
                                                        <br />
                                                        {dataError?.dataFim && (
                                                            <span style={{ color: "red" }}>
                                                                Informe a data de fim do evento!
                                                            </span>
                                                        )}
                                                    </Form.Item>
                                                </Col>

                                                {!allDay && (
                                                    <Col span={12}>
                                                        <Form.Item
                                                            wrapperCol={{
                                                                xs: { span: 24 },
                                                            }}
                                                            labelCol={{
                                                                xs: { span: 24 },
                                                            }}
                                                            label="Hora fim"
                                                        >
                                                            <input
                                                                type="time"
                                                                value={horaFim}
                                                                onChange={(e) => setHoraFim(e.target.value)}
                                                            />
                                                            <br />
                                                            {dataError?.horaFim && (
                                                                <span style={{ color: "red" }}>
                                                                    Informe a hora de fim do evento!
                                                                </span>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Checkbox
                                                defaultChecked={props.event.allDay}
                                                onChange={(e) => setAllDay(e.target.checked)}
                                            >
                                                Todo o dia
                                            </Checkbox>
                                        </>
                                    ) : (
                                        <Button onClick={() => setShowScheduleEvent(true)} icon="clock-circle">
                                            Agendar
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Event Locate */}
                    <Row className="ant-form-item">
                        <Col className="ant-form-item-label" xs={24} sm={5}>
                            <label className="ant-form-item-required" htmlFor="" title="Local de evento">
                                Local de evento
                            </label>
                        </Col>
                        <Col className="ant-form-item-control-wrapper" xs={24} sm={17}>
                            <Row className="ant-form-item-control">
                                <Col span={5}>
                                    <Radio.Group
                                        onChange={(e) => setEventLocationType(e.target.value)}
                                        value={eventLocationType}
                                    >
                                        <Radio style={radioStyle} value={"interno"}>
                                            Interno
                                        </Radio>
                                        <Radio style={radioStyle} value={"externo"}>
                                            Externo
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                                <Col span={19}>
                                    {eventLocationType === "interno" ? (
                                        <>
                                            <Form.Item
                                                wrapperCol={{
                                                    xs: { span: 24 },
                                                }}
                                            >
                                                {getFieldDecorator("local", {
                                                    initialValue: props.event.new
                                                        ? ""
                                                        : typeof props.event.local === "object"
                                                        ? ""
                                                        : props.event.local,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Informe o local do evento!",
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        dropdownRender={(menu) => (
                                                            <div>
                                                                {menu}
                                                                <Divider
                                                                    style={{
                                                                        margin: "4px 0",
                                                                    }}
                                                                />
                                                                <div
                                                                    style={{
                                                                        padding: "4px 8px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onMouseDown={(e) => e.preventDefault()}
                                                                    onClick={() => setVisiblePlaceEditor(true)}
                                                                >
                                                                    <Icon type="edit" /> Gerir Locais
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {props.places.list.interno.map((item) => (
                                                            <Option key={`${item.id}_places_list`} value={item.nome}>
                                                                {item.nome}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </>
                                    ) : (
                                        <Form.Item wrapperCol={24}>
                                            {getFieldDecorator("local", {
                                                initialValue: props.event.new
                                                    ? ""
                                                    : typeof props.event.local === "object"
                                                    ? ""
                                                    : props.event.local,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Informe o local do evento!",
                                                    },
                                                ],
                                            })(
                                                <AutoComplete
                                                    onSearch={(value) => {
                                                        let listFilter = [];
                                                        props.places.list.externo.forEach((element) => {
                                                            if (
                                                                element &&
                                                                element.local &&
                                                                element.local
                                                                    .toLowerCase()
                                                                    .startsWith(value.toLowerCase())
                                                            ) {
                                                                listFilter.push(element);
                                                            }
                                                        });
                                                        setOptionsExternalLocalSearch(listFilter);
                                                    }}
                                                >
                                                    {optionsExternalLocalSearch.map((i) => (
                                                        <AutoComplete.Option key={i.local} value={i.local}>
                                                            {i.local}
                                                        </AutoComplete.Option>
                                                    ))}
                                                </AutoComplete>
                                            )}
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Event User and Group */}
                    <Row className="ant-form-item">
                        <Col className="ant-form-item-label" xs={24} sm={5}>
                            <label>Utilizadores</label>
                        </Col>
                        <Col className="ant-form-item-control-wrapper" xs={24} sm={17}>
                            <Input
                                value={users.reduce(
                                    (accumulator, currentValue) =>
                                        accumulator === "" ? currentValue.name : accumulator + ";" + currentValue.name,
                                    ""
                                )}
                                disabled
                                style={{ marginBottom: 10 }}
                            />
                        </Col>
                        <Col className="ant-form-item-label" xs={24} sm={5}>
                            <label>Grupos funcionais</label>
                        </Col>
                        <Col className="ant-form-item-control-wrapper" xs={24} sm={17}>
                            <Input
                                value={groups.reduce(
                                    (accumulator, currentValue) =>
                                        accumulator === ""
                                            ? currentValue.value
                                            : accumulator + ";" + currentValue.value,
                                    ""
                                )}
                                disabled
                            />
                        </Col>
                        <Col xs={24} sm={2}>
                            <Icon
                                onClick={() => setIsVisibleModalSelectUserAndGroup(true)}
                                type="user-add"
                                style={{ float: "right", fontSize: "22px" }}
                            />
                        </Col>
                    </Row>

                    {/* Event Type */}
                    <Row className="ant-form-item">
                        <Col className="ant-form-item-label" xs={24} sm={5}>
                            <label>Agenda</label>
                        </Col>
                        <Col className="ant-form-item-control-wrapper" xs={24} sm={8}>
                            <div className="ant-form-item-control has-success">
                                <Radio.Group
                                    onChange={(e) => setTypologyEvents(e.target.value)}
                                    defaultValue={typologyEvents}
                                >
                                    <Radio value="Pessoal">Pessoal</Radio>
                                    <Radio value="Profissional">Profissional</Radio>
                                </Radio.Group>
                            </div>
                        </Col>
                        <Col
                            className="ant-form-item-control-wrapper"
                            xs={24}
                            sm={9}
                            style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                        >
                            <Badge count={countThirdParteis}>
                                <Button icon="usergroup-add" onClick={() => setIsVisibleModalStakehoders(true)}>
                                    Entidades interessadas
                                </Button>
                            </Badge>

                            <Button
                                icon="lock"
                                onClick={() => setShowModalEventPermissions(true)}
                                style={{ marginLeft: 24 }}
                            >
                                Permissões do Evento
                            </Button>
                        </Col>
                    </Row>

                    {/* Event Description */}
                    <Form.Item label="Descrição do Evento">
                        {getFieldDecorator("descricao", {
                            initialValue: !props.event.new ? props.event.descricao : "",
                            rules: [
                                {
                                    required: true,
                                    message: "Informe a descrição do evento!",
                                },
                            ],
                        })(<TextArea rows={6} />)}
                    </Form.Item>

                    {/* Event Color */}
                    <Form.Item label="Cor do Evento">
                        {getFieldDecorator("cor")(
                            <Input
                                readOnly
                                style={{
                                    padding: "5px",
                                    background: "#fff",
                                    borderRadius: "1px",
                                    boxShadow: "0 0 0 1px rgba(0,0,0,0.1)",
                                    backgroundColor: `${color}`,
                                    display: "inline-block",
                                    cursor: "pointer",
                                }}
                                onClick={() => setDisplayColorPicker(true)}
                            />
                        )}
                        {displayColorPicker ? (
                            <div style={popover}>
                                <div style={cover} onClick={() => setDisplayColorPicker(false)} />
                                <ChromePicker
                                    color={color}
                                    onChange={(_color) => {
                                        setColor(_color.hex);
                                    }}
                                />
                            </div>
                        ) : null}
                    </Form.Item>

                    <Row>
                        <Col>
                            <Upload {...fileProps}>
                                <Button>
                                    <Icon type="upload" /> Selecione Ficheiro para enviar
                                </Button>
                            </Upload>
                        </Col>
                    </Row>

                    {!props.event.new && toArray(props.event.anexos).length > 0 && (
                        <Row>
                            <Divider type="horizontal" />
                            <Col span={18}>
                                <List
                                    header="Anexos"
                                    size="small"
                                    className="demo-loadmore-list"
                                    itemLayout="horizontal"
                                    dataSource={props.event.anexos}
                                    renderItem={(item) => (
                                        <List.Item
                                            actions={[
                                                <a
                                                    href="/"
                                                    onClick={(e) => downloadFile(e, item)}
                                                    key="list-loadmore-edit"
                                                >
                                                    Descarregar
                                                </a>,
                                            ]}
                                        >
                                            <List.Item.Meta
                                                className="qa_anexo_name"
                                                style={{ width: 200 }}
                                                description={item.nome_ficheiro}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    )}
                </Form>
            </Modal>
        </>
    );
};

const ShowReapetedEventsModal = (props) => {
    const columns = [
        {
            title: "Título",
            dataIndex: "titulo",
            key: "titulo",
        },
        {
            title: "Utilizador",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Data Início",
            dataIndex: "data_inicio",
            key: "data_inicio",
        },
        {
            title: "Data Fim",
            dataIndex: "data_fim",
            key: "data_fim",
        },
        {
            title: "Tipologia",
            dataIndex: "tipologia_evento",
            key: "tipologia_evento",
        },
    ];

    return (
        <Modal
            title="Eventos Sobrepostos"
            visible={props.showReapetedEvents}
            okText="Confirmar Criação do Evento"
            width="60%"
            okButtonProps={{
                disabled: !props.tryAgain,
            }}
            onOk={() => {
                props.onOk();
            }}
            onCancel={() => {
                props.onCancel();
            }}
        >
            {!props.tryAgain && <Alert message="Não estás autorizado a sobrepor este tipo de evento." type="error" />}

            {props.space && (
                <>
                    <Divider orientation="left">Sobreposição de local</Divider>

                    <pre style={{ whiteSpace: "pre-wrap", paddingBottom: 10, marginTop: 10 }}>
                        {`Já tem evento(s) mascarado(s) no local seleccionado.`}
                    </pre>

                    <Typography.Text strong>Eventos:</Typography.Text>
                    <Table
                        rowKey={(item) => item.username + "-" + item.data_inicio}
                        style={{ marginTop: 10 }}
                        size="small"
                        dataSource={props.eventsSpace}
                        columns={columns}
                        pagination={false}
                    />
                </>
            )}

            {props.repeated && (
                <>
                    <Divider orientation="left">Sobreposição de data</Divider>

                    <pre style={{ whiteSpace: "pre-wrap", paddingBottom: 10 }}>
                        {`O(s) utilizador(es) a seguir possue(m) evento(s) na mesma data e hora. Eles serão avisados da sobreposição do evento. ${props.users.join(
                            ", "
                        )}`}
                    </pre>
                    <Typography.Text strong>Eventos:</Typography.Text>
                    <Table
                        rowKey={(item) => item.username + "-" + item.data_inicio}
                        style={{ marginTop: 10 }}
                        size="small"
                        dataSource={props.events}
                        columns={columns}
                        pagination={false}
                    />
                </>
            )}
        </Modal>
    );
};

const DeleteReason = (props) => {
    const { getFieldDecorator } = props.form;

    const deleteEvent = () => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                props.handleDelete(values.reason);
            }
        });
    };
    return (
        <Modal
            title="Motivo para anular o evento"
            visible={props.showDeleteReason}
            onOk={deleteEvent}
            onCancel={() => props.setShowDeleteReason(false)}
        >
            <Form {...props.formItemLayout}>
                {/* Event delete reason */}
                <Form.Item>
                    {getFieldDecorator("reason", {
                        rules: [
                            {
                                required: true,
                                message: "Informe o motivo para anulação do evento!",
                            },
                        ],
                    })(<TextArea rows={5} maxLength={500} />)}
                </Form.Item>
            </Form>
        </Modal>
    );
};

const DeleteReasonForm = Form.create({ name: "delete_reason_event" })(DeleteReason);
export default Form.create({ name: "register_event" })(ScheduleEditor);
