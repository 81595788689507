import React from "react";
import { Table, Pagination } from "antd";
import PropTypes from "prop-types";

const QATable = ({ data, columns, pageChange, totalResult, currentPage, datakey, loadingData, tableChange }) => {
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {},
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <>
            <Table
                size={"small"}
                rowKey={(data, index) => data[datakey] + "_table_" + index}
                pagination={false}
                rowSelection={rowSelection}
                dataSource={data}
                loading={loadingData}
                columns={columns}
                onChange={tableChange}
                style={{ marginTop: 20 }}
                scroll={{ x: "max-content" }}
            />
            <Pagination
                style={{ float: "right", marginBottom: 10 }}
                defaultCurrent={currentPage}
                defaultPageSize={10}
                size="small"
                total={totalResult}
                showSizeChanger
                showTotal={(total) => `Total registos: ${total}`}
                onShowSizeChange={(current, pageSize) => {
                    pageChange(current, pageSize);
                }}
                onChange={(current, pageSize) => {
                    pageChange(current, pageSize);
                }}
            />
        </>
    );
};

QATable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    pageChange: PropTypes.func,
    totalResult: PropTypes.number,
    currentPage: PropTypes.number,
    datakey: PropTypes.string,
    loadingData: PropTypes.bool,
};

export default QATable;
