/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useMemo, useEffect, useState } from "react";
import { Col, message } from "antd";
import QaDivider from "../qa_components/divider";
import {
    conversion,
    canShowInput,
    getFieldInInputGroup,
    mathConversion,
    getInputColSize,
    getDefaultValue,
    downFile,
    mergeRecursive,
    checkHasFormItem,
    checkHasColSize,
} from "../../constants/utils";
import {
    xmlProps,
    componentType as ComponentType,
    componentType,
    fieldsToSendZeroNotExist,
    graphicElement,
} from "../../constants";
import ComponentRenderer from "../execution/component.render";
import { contextType } from "react-image-crop";
import moment from "moment";
import { getInputParamsFromAttr, handleRequestParams, setZeroInFormId } from "../../utils/request_params";
import {
    canRenderGroup,
    getSelectDataKeys,
    getValueInQueryParams,
    readOnly,
    restriction,
    isRequired,
} from "../../utils/input_attributes";
import { isEmpty, setValueByPopupParams, nullSafe } from "../../utils/fields";

import { Utilitaries } from "../../../../business";
import { toArray } from "../../../../components/util";

const Group = ({ children, item, colSize, noRenderCol }) => {
    return item["@grupo"] ? (
        <>
            <Col xs={24}>
                <QaDivider itemData={item} />
            </Col>
            {!noRenderCol ? (
                <Col xs={24} md={colSize}>
                    {children}
                </Col>
            ) : (
                children
            )}
        </>
    ) : (
        <></>
    );
};

const getDataSource = (component) => {
    let dataSourcePath = "";
    for (const key in component) {
        if (typeof component[key] === "object") {
            for (const deepKey in component[key]) {
                if (typeof component[key][deepKey] === "object" && key !== "value") {
                    dataSourcePath = component[key]?.[deepKey]?.["@dados"];
                }
            }
        }
    }
    return dataSourcePath;
};

const getItemData = ({ formItems, key }) => {
    if (!formItems) return;
    return Object.values(formItems).find((item) => item.key === key);
};

const getGroupItem = ({ item, form }) => {
    //TODO check condition if has root element
    if (item["root"]) return;

    // if (item.isColumn) return;

    if (item["@tipo"] === componentType._inputGroup) {
        const el = Object.values(item)
            .filter((it) => typeof it === "object" && !Array.isArray(it))
            .find((it) => Object.keys(it).find((_key) => _key.startsWith("@grupo_relevante")));

        if (el) {
            const relKey = Object.keys(el).find((key) => key.startsWith("@grupo_relevante"));
            if (relKey) {
                el.xpathGroup = el[relKey];
                return el;
            }
        }
    }

    if (!item["@grupo"]) {
        const formElements = form ? Object.values(form) : [];
        const indexItem = formElements?.findIndex((formItem) => formItem.key === item.key);
        const previousFormElements = formElements?.slice(0, indexItem).reverse();

        let closestGroup = null;
        for (let el of previousFormElements) {
            if (el?.["@relevante"]) {
                return;
            }
            if (el["@grupo"]) {
                if (closestGroup && closestGroup !== el["@grupo"]) return;
                //
                closestGroup = el["@grupo"];
                const itemKeysFormItem = Object.keys(el);
                const relKey = itemKeysFormItem.find((key) => key.includes("@grupo_relevante"));
                if (relKey) {
                    el.xpathGroup = el[relKey];
                    return el;
                }
            }
            if (el["@tab"]) return;
        }
    } else {
        let relKey = null;
        if (!form) return;
        const elementGroup = Object.values(form).find((formItem) => {
            const itemKeysFormItem = Object.keys(formItem);
            relKey = itemKeysFormItem.find((key) => key.includes("@grupo_relevante"));
            return relKey && formItem["@grupo"] === item["@grupo"];
        });

        if (!elementGroup) return;
        //
        elementGroup.xpathGroup = elementGroup[relKey];
        return elementGroup;
    }
};

/**
 * ! review eval usage
 * ? Consider others javascript parsing options
 */
const ComponentRendererWrapper = ({
    renderContext,
    context,
    record,
    itemKey,
    appState,
    isRoot,
    formName,
    item,
    colSize = 0,
    tabName,
    tablePath, // for table that have inputGroupPath
    rowKey,
    formKey,
    showFieldEditorModal,
    fixedSize,
    numbersOfColumnsToRender,
    tableParentCOmponent,
}) => {
    const {
        getFieldDataSource,
        dispatch,
        prepareFieldToPopulate,
        setState,
        setNewStateWithStack,
        processId,
        taskId,
        mergeStateWithDataFetched,
        formData,
        state,
        modalOpened,
        handleGetFieldDataSource,
        convertNullToEmpty,
        handleInputChange,
        handleTableInputChange,
        handleInputGroupChange,
        getDependencies,
        urlAndParamsSplit,
        getItemByAction,
        getItemByKey,
        changeContext,
        transformParams,
        processParentId,
        modalParams: allParams,
        formOpened,
        removeDuplicatedData,
        paramsFromStartProcess,
        triedSave,
        addRestrinctionError,
        removeRestrinctionError,
        restrinctionErrors,
        addToCache,
        getFromCache,
        setLoadingQuandoValorAlterado,
        lastActionType,
        addInactivedValueError,
        taskMetaData,
        triggerWhenValueChangedPopup,
    } = context;

    const [columnDataSource, setColumnDataSource] = useState([]);
    const [error, setError] = useState("");
    const [loadData, setLoadData] = useState(false);

    let hasFormItem = true;
    let hasCol = true;
    let itemData = null;
    let isToShow = false;
    const itemKeys = Object.keys(item);

    let required = false;

    if (item?.["@obrigatorio"]) {
        required = isRequired({ itemData: item, state, record });
    }
    item.required = required;

    if (required) {
        item.requiredMessage = `${item["@etiqueta"] || item.key} obrigatório${
            tabName ? " - no separador " + tabName : ""
        }.`;
    }

    const groupItem = renderContext !== "column" && getGroupItem({ item, form: state.form || {} });

    const xpath =
        item["@relevante"] || item[itemKeys.find((key) => (key.includes("@grupo_relevante") ? key : undefined))];

    // let allParams = allPopupParams; //.find((param) => param.popup === formOpened);

    // allParams = allParams ? allParams.params : {};

    if (renderContext === "column") {
        const columnKey = item.dataIndex;
        const value = typeof record[columnKey] === "object" ? record[columnKey]?.id : record?.[columnKey];
        const dataSource = typeof record?.[columnKey] === "object" ? record[columnKey]?.dataSource : undefined;
        itemData = { ...item, recordKey: record?.key, value, dataSource };
    } else {
        if (renderContext === "inputGroup") {
            const inputGroupPath = item?.inputGroupPath ? [...item?.inputGroupPath] : [];
            itemData = getFieldInInputGroup({ ...state.form }, inputGroupPath, item.key);

            if (!itemData) {
                itemData = itemData || item;
            }
        } else {
            itemData =
                getItemData({
                    formItems: state.form,
                    key: item.key,
                }) || item;
        }
        //* in case value is in popupParams
        // const regex = /{.*}/;
        // if (regex.test(itemData?.value || itemData?.["$"])) {
        //     const name = (itemData.value || itemData["$"]).replace(/\W|param/gi, "");
        //     // const popupParamsItem = allParams.find((param) => param.key === name);
        //     // itemData.value = allParams ? popupParamsItem.value || popupParamsItem["$"] : itemData.value;
        //     itemData.value = allParams?.[name] || itemData.value;
        // }
        itemData = setValueByPopupParams({ item: itemData, params: allParams });
    }

    itemData.required = required;

    if (tablePath) {
        item.tablePath = tablePath;
        itemData.tablePath = tablePath;
    }

    let readonly = useMemo(() => {
        if (
            paramsFromStartProcess?.[item.key] &&
            (Utilitaries.isEmpty(taskMetaData?.["@tarefa_anterior"]) || taskMetaData?.["@tarefa_anterior"] === "0")
        ) {
            return true;
        }
        return readOnly({ item, state, record, itemKeys, formData });
    }, [state.form]);

    const hasGroup = useMemo(() => {
        if (!Utilitaries.isEmpty(record)) {
            return false;
        }
        return canRenderGroup({ item, state });
    }, [item["@grupo"]]);

    const condition = useMemo(() => {
        return restriction({ item, state, record, xpath, groupItem });
    }, [xpath, state.form]);

    // const condition = restriction({ item, state, record, xpath, groupItem });

    useEffect(() => {
        if (required && isEmpty(itemData.value) && triedSave && !error) {
            setError(`Campo obrigatório`);
        }

        if ((!isEmpty(itemData.value) && error && triedSave) || !triedSave) {
            setError("");
        }

        if (item?.["@obrigatorio"] && !required && error) {
            setError("");
        }
    }, [itemData.value, triedSave, required]);

    /**
     *
     * @param {*} x
     * used by eval
     */
    // eslint-disable-next-line no-unused-vars
    function round(x) {
        let result = x;
        try {
            result = x.toFixed(2);
        } catch (error) {
            result = Math.round(x * 10) / 10;
            result = x.toFixed(2);
        }

        return Number(result);
    }

    function getCalculateValue() {
        if (itemData[xmlProps.calculate].includes("count(")) {
            let jsExp = mathConversion({
                state,
                xpath: itemData[xmlProps.calculate],
                item: itemData,
                formData,
                rootPath: itemData?.inputGroupPath,
            });
            try {
                const val = String(eval(jsExp));
                return nullSafe(val);
            } catch (error) {
                return "";
            }
        } else if (itemData[xmlProps.calculate].includes("x_lista://")) {
            let jsExp = mathConversion({
                state,
                xpath: itemData[xmlProps.calculate],
                item: itemData,
                formData,
                rootPath: itemData?.inputGroupPath,
            });

            try {
                const val = String(jsExp);
                return nullSafe(val);
            } catch (error) {
                return "";
            }
        } else {
            //TODO: can make loop
            // if (itemData["@tipo"] === componentType._dataDisplay || !item.value) {
            let jsExp = mathConversion({
                state,
                xpath: itemData[xmlProps.calculate],
                item: itemData,
                formData,
                root: item.root,
                rowKey: record && record.key,
                rootPath: itemData?.inputGroupPath,
            });

            if (/then/.test(jsExp) && /else/.test(jsExp)) {
                jsExp = jsExp.replace(/then/, "?");
                jsExp = jsExp.replace(/else/, ":");
            } else if (/then/.test(jsExp)) {
                jsExp = jsExp.replace(/then/, "");
            }

            const texto = (text) => {
                return itemData.value;
            };

            let result;
            try {
                result = eval(jsExp);
            } catch (error) {
                result = itemData?.value;
            }
            return nullSafe(result);
        }
    }

    if (itemData.key === "") {
    }

    if (
        itemData[xmlProps.calculate] &&
        !record &&
        ((changeContext === "init" &&
            (item["@tipo"] !== componentType.boolean || (item["@tipo"] === componentType.boolean && !item.value))) ||
            item["@tipo"] === componentType._dataDisplay ||
            item?.["@visivel"] === "nao" ||
            item?.["@apenas_leitura"] === "true()")
    ) {
        //it has @calcular: "../mostra_acomp" that set a string in value
        const calcValue = getCalculateValue();

        const calculatedValue = isEmpty(calcValue) ? getDefaultValue(itemData) : calcValue;

        itemData.value = calculatedValue;
    }

    useEffect(() => {
        if (
            itemData[xmlProps.calculate] &&
            !record &&
            ((changeContext === "init" &&
                (item["@tipo"] !== componentType.boolean ||
                    (item["@tipo"] === componentType.boolean && !item.value))) ||
                item["@tipo"] === componentType._dataDisplay ||
                item?.["@visivel"] === "nao" ||
                item?.["@apenas_leitura"] === "true()")
        ) {
            handleInputChange({
                value: itemData.value,
                key: itemData.key,
                root: itemData.root,
                inputGroupPath: itemData?.inputGroupPath,
                contextInputChange: "calculate",
            });
        }
    }, [itemData.value]);

    let calculateDependencyInTable = [];
    let calculateInTable = null;
    let calculateInTableRow = null;

    if (itemData[xmlProps.calculate] && (record || item["@tipo"] === componentType._dataDisplay)) {
        calculateDependencyInTable = item["@calcular"]?.match(/\/\w+\s*(\)|>|!|=|<|\*|\+|\selse\s|$)/g);
        calculateDependencyInTable = calculateDependencyInTable
            ?.map((it) => it.replace(/\selse\s/, "").replace(/\W/g, ""))
            ?.reduce((accumulator, current) => {
                if (!accumulator.includes(current)) {
                    accumulator.push(current);
                }

                return accumulator;
            }, []);
    }
    if (itemData[xmlProps.calculate] && record) {
        if (record) {
            calculateInTableRow = itemData.recordKey;
            calculateInTable = itemData.root;
        } else {
            const rootPath = item["@calcular"].match(/\w+\s*(?=)/g)[1];
            let table = Object.values(formData).find(
                (item) =>
                    item[rootPath] &&
                    (item["@tipo"] === componentType._dynamicTable || item["@tipo"] === componentType._selectionTable)
            );
            if (!table) {
                table = Object.values(formData).find((inputs) => {
                    if (inputs["@tipo"] === componentType._selectionTable) {
                        const tableSecondKey = Object.keys(inputs).find(
                            (tableAttributes) =>
                                !tableAttributes.startsWith("@") &&
                                tableAttributes !== "dataSource" &&
                                !Array.isArray(inputs[tableAttributes]) &&
                                typeof inputs[tableAttributes] === "object"
                        );

                        if (inputs[tableSecondKey][rootPath]) {
                            return true;
                        }
                    }

                    return false;
                });
            }

            calculateInTableRow = null;
            calculateInTable = table?.key;
        }
    }

    let allDependenciesCalculate = [];

    if (calculateDependencyInTable) {
        allDependenciesCalculate = [
            ...calculateDependencyInTable?.map((field) => {
                if (field === itemData.key) {
                    return true;
                }
                if (record) {
                    return state?.form?.[calculateInTable]?.dataSource?.[calculateInTableRow]?.[field];
                }
                return state?.form?.[field]?.value || state.form?.[field]?.dataSource;
            }),
        ];
    }

    useEffect(() => {
        if (itemData[xmlProps.calculate] && renderContext === "column") {
            const value = getCalculateValue();

            // if (itemData["key"] === "quantidade") {
            //     // value = 123;
            // }

            if (record) {
                const columnKey = item.dataIndex;
                handleTableInputChange({
                    value,
                    key: itemKey,
                    columnKey,
                    recordKey: record?.key,
                });
            } else {
                handleInputGroupChange({
                    value,
                    key: itemData.key,
                    root: itemData.root,
                    inputGroupPath: itemData?.inputGroupPath,
                });
            }
        }
    }, [
        ...allDependenciesCalculate,
        // ...calculateDependencyNotInTable.map((field) => {
        //     return state.form[field]?.value;
        // }),
    ]);

    // if (itemData.key === "preq_fake") ;

    const notError = useMemo(() => {
        // se campo estava visivel e ficou invisivel nao validar
        if (item.key === "quantidade_abate" && record.key === 1) {
            debugger;
        }

        if (item?.["@relevante"] && !condition) {
            return true;
        }

        const result = restriction({ item, state, record, xpath: item?.["@restricao"] });
        // const itemKey = `${item.key}${record ? record.key : ""}`;

        // if (!result && !restrinctionErrors[itemKey]) {
        //     // add error
        //     // from @msg_erro
        //     const errorMessage = item?.["@msg_erro"] || `${item?.["@etiqueta"]} tem erro.`;
        //     addRestrinctionError(itemKey, errorMessage, itemData);
        //     // message.error(errorMessage);
        // } else {
        //     // check if exist error for this remove
        //     if (restrinctionErrors[itemKey]) {
        //         removeRestrinctionError(itemKey);
        //     }
        // }
        return result;
    }, [state, itemData?.value]);

    useEffect(() => {
        if (item.key === "quantidade_abate" && record.key === 1) {
            debugger;
        }
        const itemKey = `${item.key}${record ? record.key : ""}`;
        if (!notError) {
            // add error
            // from @msg_erro
            const errorMessage =
                (item?.["@msg_erro"] || item?.["@etiqueta"]) + (record ? " Linha " + record.key : "") ||
                `${item?.["@etiqueta"]} tem erro.`;
            addRestrinctionError(itemKey, errorMessage, itemData);
            // message.error(errorMessage);
        } else {
            // check if exist error for this remove
            removeRestrinctionError(itemKey);
        }
    }, [notError]);

    // set value sended from start process - to select
    if (
        paramsFromStartProcess?.[item.key] &&
        (Utilitaries.isEmpty(taskMetaData?.["@tarefa_anterior"]) || taskMetaData?.["@tarefa_anterior"] === "0")
    ) {
        let optionText = paramsFromStartProcess?.[item.key];
        if (changeContext === "init") {
            if (item?.dataSource?.length > 0) {
                const _data = item.dataSource;

                const { key, value } = getSelectDataKeys(item, _data);

                optionText = getValueInQueryParams(_data, optionText, value);

                const option = item?.dataSource.find((it) => it[value] === optionText);

                if (option) {
                    item.value = option[key] || paramsFromStartProcess?.[item.key];
                    readonly = true;
                }
            } else {
                itemData.value = optionText;
                readonly = true;
            }
        } else {
            readonly = true;
        }
    }

    colSize = getInputColSize(item?.["@estilo"], colSize, item);

    hasFormItem = checkHasFormItem(item, hasFormItem);
    hasCol = checkHasColSize(item, hasCol);

    colSize = !hasFormItem ? (!hasCol ? undefined : 24) : colSize;
    colSize = renderContext === "column" ? undefined : colSize;

    isToShow = condition ? true : false;

    // TODO: para processo ver campos invisivel na tabelas xxxx
    if (item.key === "parceria_execucao") {
        isToShow = true;
    }

    // TODO: para processo Gestão da melhoria contínua nao mostra campo  Resposta social na tabelas
    if (item.key === "departamento") {
        isToShow = true;
    }

    const extractActionParams = (item, priorityToData = false) => {
        const getAttributes = (key) => {
            const { params, dataSource } = urlAndParamsSplit(item[key]);
            const componentType = item["@tipo"];
            const formActionType = key.includes("@accao_") ? item[key].split("(")[0] : undefined;

            const paramsTransformed = transformParams({
                params,
                formData: state.form,
                root: item?.root,
                rowKey: record?.key,
                allParams,
                inputGroupPath: item?.inputGroupPath,
            });

            return {
                componentType,
                formActionType,
                dataSource,
                paramsTransformed,
                allParams,
            };
        };

        if (priorityToData && item?.["@dados"]) {
            return getAttributes("@dados");
        }

        for (const key in item) {
            if (key.includes("@accao_") || key === xmlProps.dataSource || key === xmlProps.dataSourceTable) {
                return getAttributes(key);
            }
        }
        return;
    };

    const checkCanCallForActionFill = (itemData) => {
        if (itemData.key === "") {
        }
        const keysItemData = Object.keys(itemData);
        let canCallForActionFill =
            keysItemData.find(
                (it) =>
                    (it.startsWith("@accao_preenche_") && !itemData[it].startsWith("auto_preenche_valor")) ||
                    it.startsWith("@accao_calcula_")
            ) &&
            (changeContext === "init" || (itemData.isColumn && !itemData["@dados"]));

        if (canCallForActionFill) return canCallForActionFill;

        const checkHasAutoPreencheValor =
            keysItemData.find(
                (it) => it.startsWith("@accao_preenche_") && itemData[it].startsWith("auto_preenche_valor")
            ) &&
            (changeContext === "init" || (itemData.isColumn && !itemData["@dados"]));

        if (
            checkHasAutoPreencheValor &&
            [componentType._inputTime, componentType._selectionButtons].includes(itemData[xmlProps.componentType])
        ) {
            return false;
        }

        return checkHasAutoPreencheValor;
    };

    const valideDynamicListIsActive = (value, selectDataSource, field) => {
        let { key } = getSelectDataKeys(field, selectDataSource);
        const selectedOptions = Utilitaries.toArray(selectDataSource)?.find(
            (it) => Utilitaries.toString(it[key]) == Utilitaries.toString(value)
        );
        if (selectedOptions?.activo === "f" /*|| selectedOptions?.is_disable === "t"*/) {
            const error_message = `Valor inválido: ${
                tableParentCOmponent?.["@tab"] ? "Separador: " + tableParentCOmponent?.["@tab"] : ""
            }${tableParentCOmponent?.["@grupo"] ? " Grupo: " + tableParentCOmponent?.["@grupo"] : ""} Campo: ${
                field?.["@etiqueta"]
            }${record ? " Linha: " + record.key : ""} está inativo.`;

            addInactivedValueError(field, record, error_message);
            console.log(
                "valideDynamicListIsActive",
                itemData.key,
                error_message,
                selectedOptions,
                tableParentCOmponent
            );
        }
    };

    // testar se item tem dados e parametros
    // const hasDatasourceAndParams = item?.["@dados"] && /^\w+\,\s\{\w+\,\W+\w+\}\,/.test(item?.["@dados"]);
    useEffect(() => {
        if (
            !record &&
            state.form?.["id_" + formKey]?.value &&
            itemData[xmlProps.graphicElement] === graphicElement.title &&
            itemData["@persiste"] === "nao" &&
            itemData["@visivel"] === "nao"
        ) {
            return;
        }

        if (record && record.key && Object.keys(itemData).find((it) => it.startsWith("@accao_preenche_"))) {
            return;
        }

        if (item[xmlProps.componentType] === ComponentType.dynamicList) {
            return;
        }

        if (
            ((Array.isArray(itemData.dataSource) && itemData.dataSource.length > 0) ||
                (!Array.isArray(itemData.dataSource) &&
                    itemData.dataSource &&
                    Object.values(itemData.dataSource).length > 0) ||
                // (!isEmpty(item.value) &&
                //     item["@dados"] &&
                // !isEmpty(item.value) &&
                (!item["@dados"] &&
                    Object.keys(item).find(
                        (el) => el.startsWith("@accao_preenche_") && item[el].includes("auto_preenche(")
                    ))) &&
            itemData?.["@ignora_dados"] !== "sim" &&
            (!itemData?.["@dados_after_save"] || itemData?.["@dados_after_save"] !== "true")
            // hasDatasourceAndParams
        ) {
            return;
        }

        // * nested datasource paths
        if (item[xmlProps.componentType] === ComponentType.fixedList) {
            // let dataSourcePath = getDataSource(item);
            // if (dataSourcePath) {
            //     handleGetFieldDataSource({
            //         dataSource: dataSourcePath,
            //         fieldToPopulateObj: getItemByKey({
            //             rowKey: record?.key,
            //             tree: state?.form,
            //             key: item?.key,
            //         })[0],
            //     });
            // }

            let dataSourcePath = getDataSource(item);

            const dynamicItem = {
                ...item,
                "@dados": dataSourcePath,
            };

            const { paramsTransformed, dataSource } = extractActionParams(dynamicItem, true) || {};

            const params = {
                ...paramsTransformed,
                // idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                // idProcesso: state?.form?.processo?.value,
                // idActividade: state?.form?.actividade?.value,
                // idEstabelecimento: state?.form?.estabelecimento?.value,
                // idModelo: state?.form?.modelo?.value,
                // idValencia: state?.form?.valencia?.value,
                // idProjeto: state?.form?.id_projeto?.value,
                // ...allParams,
            };
            handleGetFieldDataSource({
                // componentType,
                // processId,
                // formActionType,
                dataSource,
                // taskId,
                params,
                // fieldToPopulate: item?.key,
                fieldToPopulateObj: item?.inputGroupPath
                    ? item
                    : getItemByKey({
                          rowKey: record?.key,
                          tree: state?.form,
                          key: item?.key,
                      })[0],
            });

            return;
        }

        // Do it if component has type and @dados or @accao_preenche_
        // const canCallForActionFill =
        //     Object.keys(itemData).find((it) => it.startsWith("@accao_preenche_") || it.startsWith("@accao_calcula_")) &&
        //     (changeContext === "init" || (itemData.isColumn && !itemData["@dados"]));
        const canCallForActionFill = checkCanCallForActionFill(itemData);

        if (item[xmlProps.componentType] && (item[xmlProps.dataSource] || canCallForActionFill)) {
            const { componentType, paramsTransformed, dataSource, formActionType } =
                extractActionParams(item, true) || {};

            if (dataSource && dataSource.startsWith("../")) {
                return;
            }

            const popupParams = getInputParamsFromAttr({
                item,
                form: state.form,
                allParams,
                query: dataSource,
                formKey,
            });

            const objParams = setZeroInFormId({ allParams: paramsTransformed, formKey });

            const params = handleRequestParams({
                idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                idProcesso: state?.form?.processo?.value,
                idActividade: state?.form?.actividade?.value,
                idEstabelecimento: state?.form?.estabelecimento?.value,
                idModelo: state?.form?.modelo?.value,
                idValencia: state?.form?.valencia?.value,
                idProjeto: state?.form?.id_projeto?.value,
                idCli: state?.form?.idcliente?.value,
                ...allParams,
                ...popupParams,
                ...objParams,
                _valor_campo_: itemData?.value || "",
            });

            setLoadData(true);

            if (record?.key || record?.key === 0) {
                const cache_data = getFromCache(dataSource, params);
                if (cache_data) {
                    setLoadData(false);
                    setColumnDataSource(convertNullToEmpty(cache_data));
                    return;
                }

                dispatch(
                    getFieldDataSource({
                        dataSource,
                        params, //: paramsTransformed,
                        formActionType,
                        componentType,
                        processId,
                        root: item.root,
                        field: item.key,
                        fieldToPopulateObj: getItemByKey({
                            rowKey: record?.key,
                            tree: state?.form,
                            key: item?.key,
                        })[0],
                        taskId,
                    })
                ).then((response) => {
                    const response_data = removeDuplicatedData(response?.result, item?.["@chave_lista"]);
                    addToCache(dataSource, response_data, params, itemData);
                    setLoadData(false);
                    const selectDataSource = convertNullToEmpty(response_data);
                    setColumnDataSource(selectDataSource);
                    valideDynamicListIsActive(itemData?.value, selectDataSource, itemData);
                });
            } else {
                handleGetFieldDataSource({
                    componentType,
                    processId,
                    formActionType,
                    root: item?.root,
                    dataSource,
                    taskId,
                    params,
                    fieldToPopulate: item?.key,
                    fieldToPopulateObj: item?.inputGroupPath
                        ? item
                        : getItemByKey({
                              rowKey: record?.key,
                              tree: state?.form,
                              key: item?.key,
                          })[0],
                    callback: (data) => {
                        const data_source = convertNullToEmpty(data);
                        setColumnDataSource(data_source);
                        valideDynamicListIsActive(itemData?.value, data_source, itemData);
                        setLoadData(false);
                    },
                });
            }

            return;
        }

        if (
            item[xmlProps.componentType] &&
            item[xmlProps.dataSourceTable] &&
            item[xmlProps.componentType] !== ComponentType.dynamicList &&
            item[xmlProps.componentType] !== ComponentType._inputGroup
        ) {
            const { componentType, paramsTransformed, dataSource, formActionType } = extractActionParams(item) || {};

            const params = handleRequestParams({
                ...paramsTransformed,
                idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                idProcesso: state?.form?.processo?.value,
                idActividade: state?.form?.actividade?.value,
                idEstabelecimento: state?.form?.estabelecimento?.value,
                idModelo: state?.form?.modelo?.value,
                idValencia: state?.form?.valencia?.value,
                idProjeto: state?.form?.id_projeto?.value,
                idCli: state?.form?.idcliente?.value,
                ...allParams,
            });

            setLoadData(true);

            if (record?.key || record?.key === 0) {
                const customController = item[xmlProps.dataSourceTable] ? { controller: "execQueryTabela" } : {};
                dispatch(
                    getFieldDataSource({
                        dataSource,
                        params, //: paramsTransformed,
                        formActionType,
                        componentType,
                        processId,
                        root: item.root,
                        field: item.key,

                        fieldToPopulateObj: getItemByKey({
                            rowKey: record?.key,
                            tree: state.form,
                            key: item?.key,
                        })[0],
                        ...customController,
                        taskId,
                    })
                ).then((resp) => {
                    const data = removeDuplicatedData(resp?.result);
                    setColumnDataSource(convertNullToEmpty(data));
                    setLoadData(false);
                });

                return;
            }

            handleGetFieldDataSource({
                componentType,
                processId,
                formActionType,
                root: item?.root,
                dataSource,
                taskId,
                params,
                fieldToPopulate: item?.key,
                fieldToPopulateObj: getItemByKey({
                    rowKey: record?.key,
                    tree: state?.form,
                    key: item?.key,
                })[0],
                controller: "execQueryTabela",
                rowKey: record?.key,
                callback: () => {
                    setLoadData(false);
                },
            });

            return;
        }
    }, []);

    useEffect(() => {
        if (record?.key && item?.root) {
            const dataSourceItem = state?.form?.[item.root]?.dataSource[record.key]?.[itemData.key]?.dataSource;
            if (dataSourceItem) {
                setColumnDataSource(dataSourceItem);
            }
        }
    }, [record ? record?.[itemData.key]?.dataSource : null]);

    let _params = [];

    if (
        item?.["@param_popup"] &&
        (item[xmlProps.componentType] === componentType.datagrid ||
            item[xmlProps.componentType] === componentType._dynamicDatagrid)
    ) {
        _params = item["@param_popup"].split(";");
    }

    useEffect(
        () => {
            if (changeContext === "init") return;

            if (record?.key && item.root) {
                const dataSourceItem = state.form[item.root]?.dataSource[record.key]?.[itemData.key]?.dataSource;
                if (dataSourceItem) {
                    setColumnDataSource(dataSourceItem);
                }
            }

            // Para tabelas que tem params
            if (
                item?.["@param_popup"] &&
                (item[xmlProps.componentType] === componentType.datagrid ||
                    item[xmlProps.componentType] === componentType._dynamicDatagrid)
            ) {
                const { componentType, formActionType, paramsTransformed, dataSource } =
                    extractActionParams(item, true) || {};

                const paramsTable = getInputParamsFromAttr({
                    item,
                    form: state.form,
                    allParams,
                    formKey,
                });

                const params = {
                    ...paramsTransformed,
                    idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                    idProcesso: state.form?.processo?.value,
                    idActividade: state.form?.actividade?.value,
                    idEstabelecimento: state.form?.estabelecimento?.value,
                    idModelo: state.form?.modelo?.value,
                    idValencia: state?.form?.valencia?.value,
                    idProjeto: state?.form?.id_projeto?.value,
                    ...allParams,
                    ...paramsTable,
                };

                const itemByKey = getItemByKey({
                    rowKey: record?.key,
                    tree: state.form,
                    key: item?.key,
                })[0];
                if (itemByKey) {
                    setLoadData(true);

                    handleGetFieldDataSource({
                        componentType,
                        processId,
                        formActionType,
                        root: item?.root,
                        dataSource,
                        taskId,
                        params,
                        fieldToPopulate: item?.key,
                        fieldToPopulateObj: itemByKey,
                        callback: () => {
                            setLoadData(false);
                        },
                    });
                }
            }
        },
        _params.map((item) => {
            //TODO: check nivel dependencia
            return Utilitaries.toString(state.form[item]?.value);
        })
    );

    const checkExisteIgnoreParameters = (fields) => {
        for (const field of fields) {
            if (field?.["@ignora_parametros"] === "true") {
                return true;
            }
        }
        return false;
    };

    // eslint-disable-next-line no-unused-vars
    const auto_preenche = async (query, ...params) => {
        const paramsTransformed = params.reduce((acc, cur) => {
            acc = { ...acc, ...cur };
            return acc;
        }, {});

        const requestParams = {
            idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
            idProcesso: state?.form?.processo?.value,
            idActividade: state?.form?.actividade?.value,
            idEstabelecimento: state?.form?.estabelecimento?.value,
            idValencia: state?.form?.valencia?.value || allParams?.idValencia,
            idModelo: state?.form?.modelo?.value,
            idProjeto: state?.form?.id_projeto?.value,
            id: state.form?.["id_" + formKey]?.value,
            ...paramsTransformed,
        };
        const result = await dispatch(
            getFieldDataSource({
                dataSource: query,
                componentType,
                processId,
                hack: true,
                taskId,
                params: requestParams,
                controller: "execQuery",
            })
            // eslint-disable-next-line no-loop-func
        );
        return result;
    };

    // existealways call

    useEffect(() => {
        // return false;

        let dependencie = itemData["@quando_valor_alterado"];

        const deepKeys = [];
        if (!dependencie) {
            Object.keys(itemData).forEach((key, index) => {
                if (
                    itemData[key] !== null &&
                    typeof itemData[key] === "object" &&
                    key !== "dataSource" &&
                    key !== "value"
                ) {
                    deepKeys.push(key);
                    Object.keys(itemData[key]).forEach((deepKey, index) => {
                        if (typeof itemData[key][deepKey] === "object" && key !== "dataSource" && key !== "value") {
                            deepKeys.push(deepKey);
                        }
                    });
                }
            });
            dependencie = itemData?.[deepKeys?.[0]]?.[deepKeys?.[1]]?.["@quando_valor_alterado"];
        }

        // if (dependencie && (itemData.value || itemData['$']) && changeContext === 'onchange') {
        // if (item.key === "unanimidade_contra") {
        // debugger;
        // }

        if (
            dependencie &&
            (!Utilitaries.isEmpty(itemData.value) ||
                itemData["$"] ||
                itemData[xmlProps.componentType] === ComponentType.dynamicList)
        ) {
            if (Array.isArray(itemData.value) && itemData.value.length === 0) return;

            const actions = dependencie.split(";").filter((it) => it);

            (async () => {
                let currentState = Object.assign({}, context.state);
                let newValues = [];

                for await (const action of actions) {
                    let allDependencies = [];

                    formData.forEach((element) => {
                        allDependencies = getDependencies({
                            action,
                            // tree: state.form,
                            tree: element,
                            dependencies: allDependencies,
                            rootItem: item.root,
                            isColumn: item.isColumn,
                        });
                    });

                    // const as = item?.["@tipo"] === "icon";
                    // let vv = allDependencies?.filter((it) => it.key === item.key);
                    // vv = vv.length === allDependencies?.length;

                    // check for popup buttom
                    // ;
                    if (
                        item?.["@tipo"] === "icon" &&
                        allDependencies?.filter((it) => it.key === item.key).length === allDependencies?.length
                    ) {
                        allDependencies = [item];
                    }

                    // Calculo de mensalidade nao funciona
                    // disparado por campos dentro e fora da tabela -> onde record nao existe
                    // portanto sempre vai ser falso
                    let isAllDependenciesValued =
                        allDependencies
                            .map((item) => {
                                if (
                                    item?.["@relevante"] ||
                                    item?.["@tipo"] === "popup" ||
                                    item?.["@tipo"] === "button"
                                ) {
                                    // campo podes estar ivisivel - sem valor
                                    return true;
                                } else if (item.isColumn) {
                                    if (record) {
                                        let dataSourceItem =
                                            state.form?.[item?.path[0]]?.dataSource?.[record?.key]?.[item?.key];

                                        if (!dataSourceItem && (item.value || item["$"])) {
                                            dataSourceItem = item.value || item["$"];
                                        }
                                        if (!dataSourceItem) {
                                            dataSourceItem = record[item?.key];
                                        }
                                        return typeof dataSourceItem === "object" ? dataSourceItem.id : dataSourceItem;
                                    }

                                    if (item.inputGroupPath && Array.isArray(item.inputGroupPath)) {
                                        let inputValue = state.form;
                                        item.inputGroupPath.forEach((el) => {
                                            inputValue = inputValue[el];
                                        });
                                        return inputValue?.[itemData.key]?.value;
                                    }

                                    return state.form[item?.path[0]]?.value?.length > 0
                                        ? state.form[item?.path[0]]?.value
                                        : undefined;
                                } else {
                                    return Utilitaries.toString(item.value || item["$"]) || !Boolean(item.visible);
                                }
                            })
                            .findIndex((item) => !item || (Array.isArray(item) && item.length === 0)) === -1
                            ? true
                            : false;

                    // TODO: Para poder limpar dinamicas dependentes de outras lista dinamicas
                    if (itemData[xmlProps.componentType] === ComponentType.dynamicList && !isAllDependenciesValued) {
                        isAllDependenciesValued = true;
                    }

                    const fields = getItemByAction({
                        action,
                        tree: state.form,
                        field: [],
                        path: [],
                        rowKey: record?.key,
                        rootItem: item.root,
                        isColumn: item.isColumn,
                    });
                    // if (item.key === "modo_informacao") {
                    //     debugger;
                    // }

                    let existeIgnoreParameters = checkExisteIgnoreParameters(fields);

                    if (isAllDependenciesValued || existeIgnoreParameters) {
                        // if (fields?.length > 0) {
                        setLoadingQuandoValorAlterado(true);
                        // }

                        for await (const field of fields) {
                            // TODO: Continuar depois
                            // check if field is table and return sub item
                            if (
                                !isAllDependenciesValued &&
                                existeIgnoreParameters &&
                                field?.["@ignora_parametros"] !== "true"
                            ) {
                                continue;
                            }

                            let actionItem = field;

                            if (
                                field?.root &&
                                state.form?.[field.root]?.["@tipo"] === componentType._dynamicTable &&
                                Object.keys(state.form?.[field.root]?.dataSource).length === 0
                            ) {
                                continue;
                            }

                            let requestParamsToSplit = actionItem?.["@accao_" + action];

                            if (field?.["@condicao_carregar"] === "onchange" && lastActionType !== "onchange") {
                                continue;
                            }

                            if (
                                ((actionItem[xmlProps.componentType] === componentType._dynamicTable &&
                                    actionItem.dataSource &&
                                    ((Array.isArray(actionItem.dataSource) && actionItem.dataSource.length > 0) ||
                                        (!Array.isArray(actionItem.dataSource) &&
                                            actionItem.dataSource &&
                                            Object.values(actionItem.dataSource).length > 0))) ||
                                    (actionItem[xmlProps.componentType] === componentType.text &&
                                        isEmpty(actionItem.value) &&
                                        field?.["@ignora_parametros"] !== "true") ||
                                    (state.form?.["id_" + formKey]?.value &&
                                        (!field["@persiste"] || field["@persiste"] !== "nao") &&
                                        (actionItem[xmlProps.componentType] === componentType._input ||
                                            actionItem[xmlProps.componentType] === componentType._inputNumber ||
                                            [
                                                componentType.shortText,
                                                componentType.areaTexto,
                                                componentType.text,
                                            ].includes(actionItem[xmlProps.componentType]) ||
                                            actionItem[xmlProps.componentType] === componentType.date)) ||
                                    (!isEmpty(field.value || record?.[field.key]) &&
                                        requestParamsToSplit?.startsWith("auto_preenche_valor") &&
                                        (!field["@persiste"] || field["@persiste"] !== "nao") &&
                                        !["etiqueta"].includes(field?.["@tipo"])) ||
                                    (state.form?.["id_" + formKey]?.value &&
                                        actionItem[xmlProps.componentType] === componentType._select &&
                                        !isEmpty(actionItem[xmlProps.values]))) &&
                                changeContext === "init" &&
                                field?.["@ignora_dados"] !== "sim"
                            ) {
                                continue;
                            }

                            if (requestParamsToSplit === "'true'") {
                                newValues.push({
                                    dataTransformed: { key: field.key, value: true },
                                    componentType: field["@type"],
                                    root:
                                        field?.path.length >= 1 // > para >= PARA pegar dynamic datagrid
                                            ? field?.path[0]
                                            : null,
                                    field,
                                    rowKey: field.isColumn ? record?.key : null,
                                    // fullPath: field?.path.length > 1 ? field?.path : undefined,
                                    fullPath: field?.inputGroupPath
                                        ? field?.inputGroupPath
                                        : item?.tablePath
                                        ? [...item.tablePath]
                                        : null,
                                });
                                continue;
                            }

                            if (requestParamsToSplit.startsWith("texto(") || requestParamsToSplit.startsWith("text(")) {
                                continue;
                            } else if (
                                requestParamsToSplit.startsWith("round") ||
                                requestParamsToSplit.startsWith("sum") ||
                                ((/^(\/\/|\.\.\/)\w+|if\((\.\.\/|\/\/)\w+/.test(requestParamsToSplit) ||
                                    /if\(([0-9]|=|\s)+\)/.test(requestParamsToSplit)) &&
                                    !requestParamsToSplit.includes(xmlProps.autoSet + "("))
                            ) {
                                try {
                                    if (itemData["@atualizar_valores_tabela"] === "true" && field.isColumn) {
                                        const tableNameUpdate = field?.path?.[0];
                                        if (tableNameUpdate) {
                                            let dataSourceToUpdate = state?.form?.[tableNameUpdate]?.dataSource;

                                            if (!Array.isArray(dataSourceToUpdate)) {
                                                dataSourceToUpdate = Object.values(dataSourceToUpdate);
                                                for (const el of dataSourceToUpdate) {
                                                    /* let myState = { ...state };

                                                    if (newValues.length > 0) {
                                                        newValues.forEach((item) => {
                                                            myState = mergeStateWithDataFetched({
                                                                myState,
                                                                ...item,
                                                            });
                                                        });
                                                    } */

                                                    const jsExp = mathConversion({
                                                        state,
                                                        xpath: requestParamsToSplit,
                                                        item: field,
                                                        formData,
                                                        root: field.root,
                                                        rowKey: el.key, //record && record.key,
                                                    });

                                                    const result = eval(jsExp);

                                                    newValues.push({
                                                        dataTransformed: {
                                                            key: field.key,
                                                            value: result,
                                                        },
                                                        componentType: field["@type"],
                                                        field,
                                                        root:
                                                            field?.path.length >= 1 // > para >= PARA pegar dynamic datagrid
                                                                ? field?.path[0]
                                                                : null,
                                                        rowKey: field.isColumn ? el?.key : null,
                                                    });
                                                }
                                            }
                                        }
                                    } else {
                                        const { jsExp, jsExpTest } = mathConversion({
                                            state,
                                            xpath: requestParamsToSplit,
                                            item: field,
                                            formData,
                                            root: field.root,
                                            rowKey: record && record.key,
                                            isTest: true,
                                        });

                                        const result =
                                            field.key === "registo_votos_ata" ? eval(jsExpTest) : eval(jsExp);

                                        newValues.push({
                                            dataTransformed: {
                                                key: field.key,
                                                value: result,
                                            },
                                            componentType: field["@type"],
                                            field,
                                            root:
                                                field?.path.length >= 1 // > para >= PARA pegar dynamic datagrid
                                                    ? field?.path[0]
                                                    : null,
                                            rowKey: field.isColumn ? record?.key : null,
                                        });
                                    }
                                } catch (error) {
                                    console.log(error);
                                }
                            } else if (requestParamsToSplit === "limpa_dados") {
                                newValues.push({
                                    dataTransformed: {
                                        key: field.key,
                                        dataSource: [],
                                    },
                                    componentType: field["@type"],
                                    field,
                                    root:
                                        field?.path.length >= 1 // > para >= PARA pegar dynamic datagrid
                                            ? field?.path[0]
                                            : null,
                                    rowKey: field.isColumn ? record?.key : null,
                                });
                            } else if (
                                requestParamsToSplit &&
                                ((requestParamsToSplit.match(/auto_preenche\(/g) || []).length >= 2 ||
                                    (requestParamsToSplit.includes("if(") && requestParamsToSplit.includes("else ")))
                            ) {
                                let jsExp = mathConversion({
                                    state,
                                    xpath: requestParamsToSplit,
                                    item: field,
                                    formData,
                                    root: field.root,
                                    rowKey: record && record.key,
                                });

                                const addColonToParams = (expression) => {
                                    return expression.replace(/(?<={"[\w]+"),/g, ":");
                                };
                                const addDoubleQuotesToParams = (expression) => {
                                    return expression.replace(/[\w|:]+(?=,)/g, '"$&"');
                                };

                                const jsExpWithDoubleQuotes = addDoubleQuotesToParams(jsExp);
                                const jsExpWithColon = addColonToParams(jsExpWithDoubleQuotes);

                                const data = await eval(jsExpWithColon);

                                if (data) {
                                    let dataTransformed = {};

                                    if (
                                        !data ||
                                        (Array.isArray(data?.result) && data?.result.length === 0) ||
                                        (typeof data === "object" && Array.isArray(data) && data.length === 0)
                                    ) {
                                        if (
                                            [
                                                ComponentType.areaTexto,
                                                ComponentType._input,
                                                ComponentType._dataDisplay,
                                            ].includes(field?.["@tipo"]) ||
                                            requestParamsToSplit?.startsWith("auto_preenche_valor")
                                        ) {
                                            dataTransformed = { key: field.key, value: "" };
                                        } else {
                                            dataTransformed = { key: field.key, dataSource: [] };
                                        }
                                    } else {
                                        dataTransformed = prepareFieldToPopulate({
                                            data: data?.result
                                                ? convertNullToEmpty(data.result)
                                                : convertNullToEmpty(data),
                                            key: field["@chave_lista"],
                                            value: field["@valor_lista"],
                                            formActionType: "auto_preenche",
                                            startIndex: 1,
                                            componentType,
                                            fieldToPopulate: field.key,
                                            inputGroupPath: field?.inputGroupPath,
                                            item: field,
                                        });
                                    }

                                    newValues.push({
                                        dataTransformed,
                                        componentType,
                                        root:
                                            field?.path?.length >= 1 // 0 && field?.path.length === 1 // > para >= PARA pegar dynamic datagrid
                                                ? field?.path[item?.tablePath ? 1 : 0]
                                                : null,
                                        field,
                                        rowKey: record?.key,
                                        fullPath: field?.inputGroupPath
                                            ? field?.inputGroupPath
                                            : item?.tablePath
                                            ? [...item.tablePath]
                                            : null,
                                    });
                                }

                                // end ---------------------------------
                            } else if (requestParamsToSplit /*&& changeContext !== "init"*/) {
                                // Not load data - if component is in load and input have dataSource
                                // TODO: The condition can fail if value not in dataSource or dataSource is empty
                                //Check condition
                                if (/if\((\.\.\/|\/\/)\w+/.test(requestParamsToSplit)) {
                                    const conditionRegex = ".*(?=\\) then)";
                                    let allConditions = requestParamsToSplit.match(conditionRegex);

                                    allConditions = allConditions.map((item) => item.replace(/if\(/gi, ""));
                                    let jsExpCondition = mathConversion({
                                        state,
                                        xpath: allConditions[0],
                                        item: field,
                                        formData,
                                        root: field.root,
                                        rowKey: record && record.key,
                                    });
                                    const result = eval(jsExpCondition);
                                    if (!result) {
                                        continue;
                                    }
                                    let allRequestParamsToSplit = requestParamsToSplit.match("auto_preenche\\(.*\\)");
                                    requestParamsToSplit = allRequestParamsToSplit[0];
                                }

                                //End heck condition

                                const { params, dataSource } = urlAndParamsSplit(requestParamsToSplit);

                                // if (["servico_af_sad_v2"].includes(dataSource)) {
                                //     const fieldDataSource = field.dataSource;
                                //     if (
                                //         fieldDataSource &&
                                //         typeof fieldDataSource === "object" &&
                                //         !Array.isArray(fieldDataSource) &&
                                //         Object.values(fieldDataSource).length > 0
                                //     ) {
                                //         return;
                                //     }
                                // }

                                const paramsTransformed = record
                                    ? transformParams({
                                          params,
                                          rowKey: record.key,
                                          record,
                                          root: field?.path ? field?.path[0] : null,
                                          allDependencies,
                                          formData: state.form,
                                          allParams,
                                      })
                                    : transformParams({
                                          params,
                                          allDependencies,
                                          formData: state.form,
                                          allParams,
                                          inputGroupPath: field?.inputGroupPath,
                                      });

                                /*
                                Nao chamar se registo de entidade parceria e for consultar
                                processo = Gestão de parceria
                                tarefa => 2 definir ou rever parceria
                                */
                                if (
                                    (dataSource === "da_entidade" &&
                                        itemData.key === "ent_parceira" &&
                                        (isEmpty(paramsTransformed.ent_solicitadora) ||
                                            paramsTransformed?.ent_solicitadora == 0)) ||
                                    (dataSource === "entidades_parceiras_v2" &&
                                        (isEmpty(paramsTransformed.tenho_valor) || paramsTransformed?.tenho_valor == 0))
                                ) {
                                    continue;
                                }

                                if (action === "preenche_clientes") {
                                    // downFile(
                                    //     JSON.stringify({
                                    //         params,
                                    //         allDependencies,
                                    //         formData: state.form[activeFormName[activeFormName.length - 1]],
                                    //         allParams,
                                    //     }),
                                    //     "json.txt",
                                    //     "text/plain"
                                    // );
                                    // return;
                                }

                                const componentType = field["@tipo"];
                                const formActionType = requestParamsToSplit.split("(")[0];
                                const fieldToPopulate = getItemByKey({
                                    key: field.key,
                                    tree: state.form,
                                })[0];
                                let filters = {};

                                if (
                                    (formActionType === "auto_preenche_lista_dinamica" ||
                                        componentType === ComponentType.dynamicList) &&
                                    !(formActionType === xmlProps.autoSetValue)
                                ) {
                                    let resultss = { key: field.key, dataSource: paramsTransformed };

                                    if (
                                        formActionType !== "auto_preenche_lista_dinamica" &&
                                        Utilitaries.isEmpty(paramsTransformed)
                                    ) {
                                        const math = requestParamsToSplit.match(/auto_preenche\((\w+)\)/);
                                        const inputKey = math[1];

                                        if (record && inputKey in record) {
                                            let filterValue = record[inputKey];

                                            if (typeof filterValue === "object") {
                                                filterValue = filterValue?.id;
                                            }

                                            resultss.dataSource = { [inputKey]: filterValue };
                                        }
                                    }

                                    newValues.push({
                                        dataTransformed: resultss,
                                        componentType,
                                        field,
                                        root:
                                            field?.path?.length >= 1 // 0 && field?.path.length === 1 // > para >= PARA pegar dynamic datagrid
                                                ? field?.path[item?.tablePath ? 1 : 0]
                                                : null,
                                        rowKey: record?.key,
                                        // fullPath: field?.path.length > 1 ? field?.path : undefined,
                                        fullPath: field?.inputGroupPath
                                            ? field?.inputGroupPath
                                            : item?.tablePath
                                            ? [...item.tablePath]
                                            : null,
                                    });
                                    continue;
                                    // Object.entries(paramsTransformed).forEach(([key, value]) => {
                                    //     filters[`filtro_${key}`] = value;
                                    // });
                                } else if (
                                    isEmpty(itemData.value) &&
                                    itemData[xmlProps.componentType] === ComponentType.dynamicList
                                ) {
                                    continue;
                                } else {
                                    filters = handleRequestParams({ ...paramsTransformed });
                                }

                                let currentFieldValue = field?.value || "";

                                if (record && !currentFieldValue) {
                                    currentFieldValue = record[field?.key];

                                    currentFieldValue =
                                        typeof currentFieldValue === "object"
                                            ? currentFieldValue?.id
                                            : currentFieldValue;
                                }

                                const requestParams = {
                                    idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                                    idProcesso: state?.form?.processo?.value,
                                    idActividade: state?.form?.actividade?.value,
                                    idEstabelecimento: state?.form?.estabelecimento?.value,
                                    idValencia: state?.form?.valencia?.value || allParams?.idValencia,
                                    idModelo: state?.form?.modelo?.value,
                                    idProjeto: state?.form?.id_projeto?.value,
                                    id: state.form?.["id_" + formKey]?.value,
                                    ...filters,
                                    _valor_campo_:
                                        dataSource === "sessoes_plano" && changeContext !== "init"
                                            ? ""
                                            : currentFieldValue,
                                };
                                // get from cache if exists
                                const fromCache = getFromCache(dataSource, requestParams);
                                if (fromCache) {
                                    newValues.push({
                                        dataTransformed: { key: field.key, dataSource: fromCache },
                                        componentType,
                                        root:
                                            field?.path?.length >= 1 // 0 && field?.path.length === 1 // > para >= PARA pegar dynamic datagrid
                                                ? field?.path[item?.tablePath ? 1 : 0]
                                                : null,
                                        rowKey: record?.key,
                                        field,
                                        // fullPath: field?.path.length > 1 ? field?.path : undefined,
                                        fullPath: field?.inputGroupPath
                                            ? field?.inputGroupPath
                                            : item?.tablePath
                                            ? [...item.tablePath]
                                            : null,
                                    });
                                    continue;
                                }

                                if (
                                    [ComponentType._selectionTable].includes(field?.["@tipo"]) &&
                                    !Utilitaries.isEmpty(field?.dataSource) &&
                                    changeContext === "init" &&
                                    field?.["@dados"]
                                ) {
                                    continue;
                                }

                                // não carregar para textoarea com valor no inicio antes do utilizador melher
                                if (
                                    [ComponentType.text].includes(field?.["@tipo"]) &&
                                    !Utilitaries.isEmpty(field?.value) &&
                                    changeContext === "init"
                                ) {
                                    continue;
                                }

                                await dispatch(
                                    getFieldDataSource({
                                        dataSource,
                                        componentType,
                                        processId,
                                        field: field,
                                        hack: true,
                                        taskId,
                                        params: requestParams,
                                        fieldToPopulateObj: fieldToPopulate,
                                        controller:
                                            formActionType === "auto_preenche_lista_dinamica"
                                                ? "execListaDinamica"
                                                : "execQuery",
                                    })
                                    // eslint-disable-next-line no-loop-func
                                ).then((data) => {
                                    if (data) {
                                        let dataTransformed = {};
                                        if (field.key === "resultado_hig") {
                                            debugger;
                                        }

                                        if (
                                            !data ||
                                            (Array.isArray(data?.result) && data?.result.length === 0) ||
                                            (typeof data === "object" && Array.isArray(data) && data.length === 0)
                                        ) {
                                            if (
                                                [
                                                    ComponentType.areaTexto,
                                                    ComponentType._input,
                                                    ComponentType._dataDisplay,
                                                    // ComponentType._select,
                                                ].includes(field?.["@tipo"]) ||
                                                requestParamsToSplit?.startsWith("auto_preenche_valor")
                                            ) {
                                                dataTransformed = { key: field.key, value: "" };
                                            } else {
                                                dataTransformed = { key: field.key, dataSource: [] };
                                            }
                                        } else {
                                            dataTransformed = prepareFieldToPopulate({
                                                data: convertNullToEmpty(data.result),
                                                key: field["@chave_lista"],
                                                value: field["@valor_lista"],
                                                formActionType,
                                                startIndex: 1,
                                                componentType,
                                                fieldToPopulate: field.key,
                                                inputGroupPath: field?.inputGroupPath,
                                                item: field,
                                            });
                                        }

                                        if (dataTransformed?.dataSource) {
                                            valideDynamicListIsActive(
                                                currentFieldValue,
                                                dataTransformed?.dataSource,
                                                field
                                            );
                                        }

                                        if (
                                            !record &&
                                            field?.path?.length >= 1 &&
                                            currentState.form?.[field?.path[0]] &&
                                            currentState.form?.[field?.path[0]]?.["@tipo"] ===
                                                ComponentType._dynamicTable &&
                                            field?.isColumn
                                        ) {
                                            const datasource = currentState.form?.[field?.path[0]].dataSource;

                                            if (!datasource || !Object.entries(datasource).length) {
                                                newValues.push({
                                                    dataTransformed,
                                                    componentType,
                                                    field,
                                                    root:
                                                        field?.path.length >= 1 // 0 && field?.path.length === 1 // > para >= PARA pegar dynamic datagrid
                                                            ? field?.path[0]
                                                            : null,
                                                    // rowKey: key,
                                                    // fullPath: field?.path.length > 1 ? field?.path : undefined,
                                                    fullPath: field?.inputGroupPath,
                                                });
                                            } else {
                                                Object.entries(datasource).forEach(([key, value]) => {
                                                    newValues.push({
                                                        dataTransformed,
                                                        componentType,
                                                        field,
                                                        root:
                                                            field?.path.length >= 1 // 0 && field?.path.length === 1 // > para >= PARA pegar dynamic datagrid
                                                                ? field?.path[0]
                                                                : null,
                                                        rowKey: key,
                                                        // fullPath: field?.path.length > 1 ? field?.path : undefined,
                                                        fullPath: field?.inputGroupPath,
                                                    });
                                                });
                                            }
                                        } else if (
                                            currentState.form?.[field?.path?.[0]] &&
                                            (currentState.form?.[field?.path?.[0]]?.["@tipo"] ===
                                                ComponentType.fixedList ||
                                                (currentState.form?.[field?.path?.[0]]?.["@tipo"] ===
                                                    ComponentType._dynamicTable &&
                                                    currentState.form?.[field?.path?.[0]]?.["@accao_preenche_pres"]))
                                        ) {
                                            //  currentState.form?.[field?.path[0]]?.["@accao_preenche_pres"] -> pode substituir por startWith @accao_
                                            const root =
                                                field?.path.length >= 1 // 0 && field?.path.length === 1 // > para >= PARA pegar dynamic datagrid
                                                    ? field?.path[item?.tablePath ? 1 : 0]
                                                    : null;

                                            dataTransformed.key = root;
                                            newValues.push({
                                                dataTransformed,
                                                componentType,
                                                root,
                                                rowKey: null,
                                                field,
                                                fullPath: field?.inputGroupPath
                                                    ? field?.inputGroupPath
                                                    : item?.tablePath
                                                    ? [...item.tablePath]
                                                    : null,
                                            });
                                        } else {
                                            // cache para tabelas
                                            if (record?.key && dataTransformed?.dataSource) {
                                                addToCache(dataSource, dataTransformed?.dataSource, requestParams);
                                            }
                                            newValues.push({
                                                dataTransformed,
                                                componentType,
                                                root:
                                                    field?.path?.length >= 1 // 0 && field?.path.length === 1 // > para >= PARA pegar dynamic datagrid
                                                        ? field?.path[item?.tablePath ? 1 : 0]
                                                        : null,
                                                field,
                                                rowKey: record?.key,
                                                fullPath: field?.inputGroupPath
                                                    ? field?.inputGroupPath
                                                    : item?.tablePath
                                                    ? [...item.tablePath]
                                                    : null,
                                            });
                                        }
                                    }
                                });
                            }
                        }
                    }
                }

                setNewStateWithStack((preState) => {
                    let newSate = Object.assign({}, preState);

                    // if (item.key === "resultado_hig") {
                    //     debugger;
                    // }

                    // let newSate = { ...preState };
                    newValues.forEach((item) => {
                        newSate = mergeStateWithDataFetched({
                            newSate,
                            ...item,
                        });
                    });
                    const mergedData = mergeRecursive({ ...preState }, { ...newSate });
                    return JSON.parse(JSON.stringify(mergedData));
                    // return Object.assign({}, et);
                });

                setLoadingQuandoValorAlterado(false);
            })();
        }
    }, [itemData.value, itemData["@tipo"] === componentType._button ? triggerWhenValueChangedPopup : null]);

    if (itemData?.["@tipo"] === componentType._popup) {
        isToShow = true;
    } else if (!canShowInput(item, state.form)) {
        return null;
    }

    itemData.visible = isToShow;

    if (renderContext === "inputGroup") {
    }

    const noRenderInsideCol =
        (renderContext === "inputGroup" && item["@tipo"] === componentType._inputGroup) || renderContext === "column";
    // TODO: condition and isToShow have some value => true or false
    return isToShow ? (
        <>
            {((renderContext === "inputGroup" && item["@tipo"] === componentType._inputGroup) ||
                Utilitaries.toString(item["@estilo"])?.includes("quebra")) && <Col xs={24}></Col>}
            {/* {item?.["@estilo"] && /(^|\s)coluna1($|\s)/g.test(item?.["@estilo"]) && <Col xs={24}></Col>} */}
            {hasGroup && (
                <Group colSize={colSize} item={item} noRenderCol={noRenderInsideCol}>
                    <ComponentRenderer
                        item={item}
                        record={record}
                        itemData={itemData}
                        columnDataSource={columnDataSource}
                        error={error}
                        formName={formName}
                        isRoot={isRoot}
                        renderContext={renderContext}
                        readonly={readonly}
                        tabName={tabName}
                        itemKey={itemKey}
                        appState={appState}
                        root={item.root}
                        formKey={formKey}
                        showFieldEditorModal={showFieldEditorModal}
                        fixedSize={fixedSize}
                        allParams={allParams}
                        loadData={loadData}
                        numbersOfColumnsToRender={numbersOfColumnsToRender}
                    />
                </Group>
            )}

            {(!item["@grupo"] || !hasGroup) && !noRenderInsideCol ? (
                <Col xs={24} md={colSize}>
                    <ComponentRenderer
                        item={item}
                        record={record}
                        columnDataSource={columnDataSource}
                        itemData={itemData}
                        error={error}
                        isRoot={isRoot}
                        loadData={loadData}
                        renderContext={renderContext}
                        readonly={readonly}
                        formName={formName}
                        itemKey={itemKey}
                        appState={appState}
                        tabName={tabName}
                        root={item.root}
                        showFieldEditorModal={showFieldEditorModal}
                        fixedSize={fixedSize}
                        formKey={formKey}
                        allParams={allParams}
                        numbersOfColumnsToRender={numbersOfColumnsToRender}
                    />
                </Col>
            ) : null}

            {(!item["@grupo"] || !hasGroup) && noRenderInsideCol ? (
                <ComponentRenderer
                    item={item}
                    record={record}
                    columnDataSource={columnDataSource}
                    error={error}
                    itemData={itemData}
                    loadData={loadData}
                    isRoot={isRoot}
                    renderContext={renderContext}
                    readonly={readonly}
                    formName={formName}
                    itemKey={itemKey}
                    appState={appState}
                    tabName={tabName}
                    root={item.root}
                    showFieldEditorModal={showFieldEditorModal}
                    fixedSize={fixedSize}
                    formKey={formKey}
                    allParams={allParams}
                    numbersOfColumnsToRender={numbersOfColumnsToRender}
                />
            ) : null}
        </>
    ) : (
        <></>
    );
};

export default ComponentRendererWrapper;
