import React, { useEffect, useState } from "react";
import { Table, Button, Popconfirm, Row, Icon, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { getFieldDataSource } from "../../../process_execution/actions/index";
const { Option } = Select;

const GoalsIndicators = ({
    isDisabled,
    removeGoalIndicator,
    addGoalIndicator,
    policyGoalsIndicators: dataSource,
    setPolicyGoalIndicator,
}) => {
    const [count, setCount] = useState(1);
    const [politicsList, setPoliticsList] = useState([]);
    const [goalsList, setGoalsList] = useState([]);
    const [goalsListByPolitic, setGoalsListByPolitic] = useState({});
    const [indicatorsList, setIndicatorsList] = useState([]);
    const [indicatorsListByObjective, setIndicatorsListByObjective] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFieldDataSource({ dataSource: "busca_politicas" })).then(({ result }) => {
            setPoliticsList([...result]);
        });

        dispatch(getFieldDataSource({ dataSource: "objectivos_politica" })).then(({ result }) => {
            setGoalsList([...result]);
        });

        dispatch(getFieldDataSource({ dataSource: "indicadores_objectivo" })).then(({ result }) => {
            setIndicatorsList([...result]);
        });
    }, []);

    const columns = [
        {
            title: "Políticas",
            dataIndex: "politicas",
            width: "30%",
            render: (text, record) => {
                return (
                    <Select
                        showSearch
                        disabled={isDisabled}
                        style={{ width: "100%" }}
                        value={record.politica || record.id_politica}
                        allowClear
                        placeholder="Selecione a política"
                        optionFilterProp="children"
                        onChange={(value) => handlePoliticasChange({ value, rowKey: record.key })}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {Object.values(politicsList).map((item) => (
                            <Option value={item.id_politica}>{item.politica}</Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Objectivos",
            width: "30%",
            dataIndex: "objectivos",
            render: (text, record) => {
                return (
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        disabled={isDisabled}
                        placeholder="Selececione o objectivo"
                        value={record.objectivo || record.id_objectivo}
                        optionFilterProp="children"
                        allowClear
                        onChange={(value) => handleObjetivosChange({ value, rowKey: record.key })}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {(Object.values(goalsListByPolitic).length === 0
                            ? goalsList
                            : goalsListByPolitic?.[record?.key]
                        )?.map(({ designacao, id_objectivo_processo }) => (
                            <Option value={id_objectivo_processo}>{designacao}</Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Indicadores",
            width: "30%",
            dataIndex: "indicadores",
            render: (text, record) => (
                <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Selecione o indicador"
                    disabled={isDisabled}
                    value={record.indicador || record.id_indicador}
                    optionFilterProp="children"
                    allowClear
                    onChange={(value) => handleIndicadoresChange({ value, rowKey: record.key })}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {(Object.values(indicatorsListByObjective).length === 0
                        ? indicatorsList
                        : indicatorsListByObjective?.[record.key]
                    )?.map(({ id_politica_indicador, designacao }) => (
                        <Option value={id_politica_indicador}>{designacao}</Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Ação",
            dataIndex: "actions",
            render: (text, record) =>
                Object.values(dataSource).length >= 1 ? (
                    <Popconfirm
                        title="Tens certeza que desejas eliminar?"
                        onConfirm={() => removeGoalIndicator({ key: record.key })}
                    >
                        <a href="javascript:;" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    const handlePoliticasChange = ({ value, rowKey }) => {
        setPolicyGoalIndicator({ value, key: rowKey, name: "id_politica" });
        dispatch(getFieldDataSource({ dataSource: "objectivos_politica", params: { politica: value } })).then(
            ({ result }) => {
                setGoalsListByPolitic({ [rowKey]: result });
            }
        );
    };

    const handleObjetivosChange = ({ value, rowKey }) => {
        setPolicyGoalIndicator({ value, key: rowKey, name: "id_objectivo" });
        dispatch(getFieldDataSource({ dataSource: "indicadores_objectivo", params: { objectivo: value } })).then(
            ({ result }) => {
                setIndicatorsListByObjective({ [rowKey]: result });
            }
        );
    };

    const handleIndicadoresChange = ({ value, rowKey }) => {
        setPolicyGoalIndicator({ value, key: rowKey, name: "id_indicador" });
    };

    return (
        <>
            <Row>
                <Button
                    disabled={isDisabled}
                    onClick={addGoalIndicator}
                    type="primary"
                    style={{ float: "right", marginBottom: 16 }}
                >
                    <Icon type="plus" />
                    Inserir
                </Button>
            </Row>

            <Table
                rowClassName={() => "editable-row"}
                dataSource={Object.values(dataSource || {})}
                columns={columns}
                size="default"
            />
        </>
    );
};

export default GoalsIndicators;
