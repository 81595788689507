import { sendHttpRequest } from "../../../components/http.request";
import { validateResponse } from "./../../../components/util";

const propsToString = (text) => {
    return typeof text === "object" ? null : text.toString();
};

export const getAuthorizations = async (requestBody) => {
    const formData = new FormData();
    formData.append("accao", "lista");

    !requestBody ||
        Object.entries(requestBody).forEach(([key, val]) => {
            formData.append(key, val);
        });

    const response = await sendHttpRequest("POST", "/Gestor/gereautorizacoes.php", formData);
    if (response) {
        const data = validateResponse(response);
        const lista = data.map((item) => ({
            ...item,
            descricao: propsToString(item.descricao),
            defeito: propsToString(item.defeito),
            grupo: propsToString(item.grupo),
            designacao: propsToString(item.designacao),
        }));
        const metadata = response["@attributes"];
        return {
            lista,
            metadata: {
                resultados: Number(metadata.resultados),
                pagina: Number(metadata.pagina),
                visivel: metadata.visivel,
                resultados_pagina: Number(metadata.resultados_pagina),
            },
        };
    }

    return false;
};

export const save = async (payload) => {
    const formData = new FormData();
    if (payload.id) {
        formData.append("accao", "alterar");
        formData.append("id", payload.id);
    } else formData.append("accao", "nova");

    formData.append("descricao", payload.descricao);
    formData.append("designacao", payload.designacao);
    formData.append("defeito", payload.defeito);
    const response = await sendHttpRequest("POST", "/Gestor/gereautorizacoes.php", formData);
    if (response) {
        if (response.erro) return { status: false, message: response.erro };
        return { status: true };
    }
    return { status: false, message: "Ocorreu um erro" };
};

export const remove = async (payload) => {
    const formData = new FormData();
    if (!payload) return false;
    formData.append("accao", "eliminar");
    formData.append("id", payload);
    const response = await sendHttpRequest("POST", "/Gestor/gereautorizacoes.php", formData);
    return response;
};
