import { actionTypes } from "../constants/index";
import { arrayToObjectById, validateResponse } from "../../../components/util";

const initialState = {
    domain: {
        form: {},
        byId: {},
    },
    app: {
        isOrganicUnitFetched: false,
        isProcessCategoryFetched: false,
    },
};

export const fixedListReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.setOrganicUnitDomain:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        ...arrayToObjectById({
                            array: action.payload,
                            id: "id",
                        }),
                    },
                },
                app: {
                    ...state.app,
                    isOrganicUnitFetched: true,
                },
            };

        case actionTypes.setProcessCategoryDomain:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        ...arrayToObjectById({
                            array: action.payload,
                            id: "id",
                        }),
                    },
                },
                app: {
                    ...state.app,
                    isProcessCategoryFetched: true,
                },
            };
        case actionTypes.selectEstabeleciment:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        estabelecimento: {
                            ...state.domain.byId["estabelecimento"],
                            attributes: action.payload["@attributes"],
                            data:
                                action.payload["linha"] !== undefined
                                    ? Array.isArray(action.payload["linha"])
                                        ? action.payload["linha"]
                                        : [action.payload["linha"]]
                                    : [],
                        },
                    },
                },
            };
        case actionTypes.selectUnitOrganicByEstabeleciment:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        unidade_organica: {
                            attributes: action?.payload?.["@attributes"],
                            data: action.payload["linha"] === undefined ? [] : action.payload["linha"],
                        },
                    },
                },
            };

        case actionTypes.selectFuncionalGroupByUnitOrganic:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        grupo_funcional: {
                            attributes: action.payload["@attributes"],
                            data: action.payload["linha"] === undefined ? [] : action.payload["linha"],
                        },
                    },
                },
            };

        case actionTypes.selectAllContributors:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        funcionarios: {
                            data: validateResponse(action.payload),
                        },
                    },
                },
            };

        case actionTypes.genericSelectDataForFixedSelectList:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [action.keyOfItemAsId]: { data: action.payload },
                    },
                },
            };

        default:
            return state;
    }
};
