import { baseUrl } from "../../../constants/enviroment";
import { Session } from "../../../business/index";

export const exportToFile = (e, requestBody) => async () => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    var request = new XMLHttpRequest();
    request.open("POST", `${baseUrl}/Gestor/gereExportList.php`, true);

    request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
    request.responseType = "blob";

    request.onload = function () {
        // Only handle status code 200
        if (request.status === 200) {
            // Try to find out the filename from the content disposition `filename` value
            var disposition = request.getResponseHeader("Content-disposition");

            var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);

            var filename = matches != null && matches[1] ? matches[1] : "";

            // The actual download
            var blob = new Blob([request.response], {
                type: request.getResponseHeader("content-type"),
            });

            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        }

        // some error handling should be done here...
    };
    request.send(formData);
};
