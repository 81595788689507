import React, { useState, useEffect } from "react";
import { Modal, Input } from "antd";
import { FieldEditorProps } from "../../../types/common";

interface ComponentsPropertiesProps extends FieldEditorProps {}

const { TextArea } = Input;

export const QAModalEditField: React.FC<ComponentsPropertiesProps> = ({
    onChange,
    onCloseEditor,
    value,
    disabled,
    componentsProperties,
}) => {
    const name = componentsProperties?.name ?? "";

    const [text, setText] = useState("");

    useEffect(() => {
        if (value) {
            setText(value);
        } else {
            setText("");
        }
    }, [value]);

    return (
        <Modal
            title={`Editar ${name}`}
            visible={onChange !== undefined}
            destroyOnClose
            onOk={() => {
                if (onChange !== undefined) {
                    onChange(text);
                }
                onCloseEditor();
            }}
            okButtonProps={{
                disabled: disabled,
            }}
            onCancel={onCloseEditor}
        >
            <TextArea
                onChange={(e) => setText(e.target.value)}
                autoSize={{ minRows: 10, maxRows: 10 }}
                style={{ width: "100%" }}
                disabled={disabled || false}
                value={text}
                allowClear
            />
        </Modal>
    );
};
