import React, { useState, useEffect } from "react";
import { Row, Col, Select, Form, Input, Checkbox } from "antd";
import { connect } from "react-redux";

import QADynamicTable from "../../../../components/qa_custom/DynamicTable";
import { selectDataFromExecQuery } from "../../actions/funcional.group";
import { getColumnSearchProps } from "../search.into.table.columns";

const { Option } = Select;
const EXEC_QUERY_NAME = {
    conhecimentos_nec_grupos_func: "conhecimentos_nec_grupos_func",
    tipos_conhecimentos: "tipos_conhecimentos",
    conhecimentos: "conhecimentos",
    todos_conhecimentos: "todos_conhecimentos",
};

const Knowledge = ({
    form,
    selectDataFromExecQuery, //from redux action
    soma_ponderacao_conhecimentos,
    selectedGrupoExist,
    idGrupoExist,
    set_conhece_necessarios,
    conhece_necessarios,
    setKnowledgePonderationCalculeValue,
}) => {
    const { getFieldDecorator } = form;

    const [dataSource, setDataSource] = useState([]);
    const [knowledgeTypes, setKnowledgeTypes] = useState([]);
    const [knowledges, setKnowledges] = useState([]);
    const [knowledgeTypeId, setKnowledgeTypeId] = useState(undefined);
    const [sumPonderation, setSumPonderation] = useState(false);
    // const [ponderationCalculeValue, setPonderationCalculeValue] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (conhece_necessarios.length) {
            setDataSource(conhece_necessarios);
            setSumPonderation(!sumPonderation);
        }
    }, []);

    /**
     * do ponderation calcule
     */
    useEffect(() => {
        const sum = dataSource
            .filter((item) => item["ativo"] === "t")
            .map((item) => Number(item.ponderacao))
            .reduce((a, b) => a + b, 0);
        setKnowledgePonderationCalculeValue(sum);
    }, [sumPonderation]);

    /**
     * fetch "conhecimentos" from group exist
     */
    useEffect(
        () => {
            // if (selectedGrupoExist || idGrupoExist) {
            (async () => {
                setLoading(true);
                // const response = await selectDataFromExecQuery({
                //     query: EXEC_QUERY_NAME.conhecimentos_nec_grupos_func,
                //     id_ficha_funcao: idGrupoExist || 0,
                //     sel_grupo_exist: selectedGrupoExist,
                // });
                // if (response.result) {
                //     if (Array.isArray(response.result)) {
                //         const data = [];
                //         response.result.forEach((item, index) => {
                //             data.push({ __key__: index, ...item });
                //         });
                //         await setDataSource(data);
                //         set_conhece_necessarios(data);
                //         setSumPonderation(!sumPonderation); // forcing calculate ponderation
                //     }
                // }

                /**
                 * get all conhecimentos
                 */
                const req = await selectDataFromExecQuery({
                    query: EXEC_QUERY_NAME.todos_conhecimentos,
                });
                if (req.result) {
                    if (Array.isArray(req.result)) {
                        setKnowledges(req.result);
                    }
                }
                setLoading(false);
            })();
            // }
        },
        [
            /*selectedGrupoExist, idGrupoExist*/
        ]
    );

    /**
     * fetch "tipos conhecimentos"
     */
    useEffect(() => {
        (async () => {
            const response = await selectDataFromExecQuery({
                query: EXEC_QUERY_NAME.tipos_conhecimentos,
            });
            if (response.result) {
                if (Array.isArray(response.result)) {
                    setKnowledgeTypes(response.result);
                }
            }
        })();
    }, []);

    /**
     * fetch "conhecimentos"
     */
    // useEffect(() => {
    //     if (knowledgeTypeId) {
    //         (async () => {
    //             const response = await selectDataFromExecQuery({
    //                 query: EXEC_QUERY_NAME.conhecimentos,
    //                 tipo_conhecimento: knowledgeTypeId,
    //             });
    //             if (response.result) {
    //                 if (Array.isArray(response.result)) {
    //                     setKnowledges(response.result);
    //                 }
    //             }
    //         })();
    //     }
    // }, [knowledgeTypeId]);

    /**
     * add row to table
     */
    const addRowToTable = () => {
        let size = dataSource.length > 0 ? Math.max(...dataSource.map((item) => Number(item.__key__))) + 1 : 0;
        setDataSource([{ __key__: size }, ...dataSource]);
    };

    /**
     * remove row from table
     */
    const removeRowFromTable = (selectedRowKeys) => {
        const newDataSource = [];
        dataSource.forEach((item) => {
            if (!selectedRowKeys.includes(item.__key__)) newDataSource.push(item);
        });
        setDataSource(newDataSource);
        setSumPonderation(!sumPonderation);
    };

    /**
     * handle change when user enter degit
     */
    const onChange = async (dataIndex, value, record) => {
        const newState = [...dataSource];

        record[dataIndex] = value;
        const index = newState.findIndex((item) => item.__key__ === record.__key__);
        newState.splice(index, 1, record);
        // newState[index] = record;

        await setDataSource([...newState]);
        set_conhece_necessarios([...newState]);

        if (dataIndex === "ponderacao" || dataIndex === "ativo") {
            setSumPonderation(!sumPonderation);
        }
    };

    /**
     * columns to table
     */
    const columns = [
        {
            title: "Tipo de conhecimento",
            dataIndex: "tipo_conhecimento",
            width: "30%",
            ...getColumnSearchProps("tipo_conhecimento"),
            onFilter: (value, record) => {
                const filteredValue = knowledgeTypes
                    .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.tipo_conhecimento);
                return filteredValue.includes(record["tipo_conhecimento"]);
            },
            render: (text, record) => {
                const defaultValue = typeof text !== "object" && text ? text : undefined;

                return (
                    <Select
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                        style={{ maxWidth: "300px", margin: "0 auto", padding: "0" }}
                        placeholder="Selecione uma opção"
                        optionFilterProp="children"
                        value={knowledgeTypes
                            .map((element) => element.tipo_conhecimento)
                            .find((item) => item === defaultValue)}
                        onChange={(value) => {
                            onChange("tipo_conhecimento", value, record);
                            setKnowledgeTypeId(value);
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {knowledgeTypes.map((item) => (
                            <Option key={item.tipo_conhecimento} value={item.tipo_conhecimento}>
                                {item.designacao}
                            </Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Descrição",
            dataIndex: "conhecimento",
            width: "30%",
            ...getColumnSearchProps("conhecimento"),
            onFilter: (value, record) => {
                const filteredValue = knowledges
                    .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.conhecimento);
                return filteredValue.includes(record["conhecimento"]);
            },
            render: (text, record) => {
                const defaultValue = typeof text !== "object" && text ? text : undefined;

                return (
                    <Select
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                        style={{ maxWidth: "300px", margin: "0 auto", padding: "0" }}
                        placeholder="Selecione uma opção"
                        optionFilterProp="children"
                        value={knowledges.map((element) => element.conhecimento).find((item) => item === defaultValue)}
                        onChange={(value) => onChange("conhecimento", value, record)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {knowledges
                            .filter((item) => item.tipo_conhecimento === record.tipo_conhecimento)
                            .map((item) => {
                                return (
                                    <Option key={item.conhecimento} value={item.conhecimento}>
                                        {item.designacao}
                                    </Option>
                                );
                            })}
                    </Select>
                );
            },
        },
        {
            title: "Ponderação",
            dataIndex: "ponderacao",
            width: "20%",
            ...getColumnSearchProps("ponderacao"),
            render: (text, record) => (
                <Form.Item style={{ width: "100%", margin: "0 auto", padding: "0" }}>
                    <Input
                        onChange={(e) => onChange("ponderacao", e.target.value, record)}
                        type="number"
                        defaultValue={typeof text !== "object" ? text : undefined}
                        style={{ width: "100%" }}
                        placeholder="Introduza a ponderação"
                    />
                </Form.Item>
            ),
        },
        {
            title: "Ativo?",
            dataIndex: "ativo",
            width: "10%",
            align: "center",
            ...getColumnSearchProps("ativo"),
            render: (text, record) => (
                <Checkbox
                    onChange={(e) => onChange("ativo", e.target.checked === true ? "t" : "f", record)}
                    defaultChecked={text === "t"}
                />
            ),
        },
    ];

    return (
        <>
            {/* <Row>
                <Col xs={24} sm={6}>
                    <Form.Item label="Ponderação na dimensão">
                        {getFieldDecorator("soma_ponderacao_conhecimentos", {
                            initialValue: ponderationCalculeValue,
                        })(
                            <Input
                                readOnly
                                type="number"
                                size="small"
                                style={{ width: "100%" }}
                                placeholder="Ponderação na dimensão"
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row> */}
            <QADynamicTable
                data={dataSource}
                columns={columns}
                addRowToTable={addRowToTable}
                onRemoveItem={removeRowFromTable}
                loadingData={loading}
            />
        </>
    );
};

const mapDispatchToProps = {
    selectDataFromExecQuery,
};

export default connect(null, mapDispatchToProps)(Knowledge);
