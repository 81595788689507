import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory, Link } from "react-router-dom";
import { Row, Col, Button, Icon, Spin, PageHeader, message, Modal, Upload, Result } from "antd";

import { sendHttpRequest, api } from "../http.request";
import FormContext from "./context/FormContext";
import AppContext from "../AppContext";
import FormRender from "./FormRender";
import DEFAULT_FORM_STATE from "./utils/defaultFormState";
import { downFileFrontend, downloadFile, toArray } from "../util";
import { getDefaultValueForFormInputs, validateTableField, getTableFieldsToValidate } from "./utils/functions";
import { Session, Utilitaries } from "../../business";
import { handleCalculateValue, handleRequered, handleRestrinction, handleSetNewValue } from "./utils/inputChange";

import { useQAState } from "./qaState";
import Confirm from "../qa_custom/confirm";
import { mergeRecursive } from "../../modules/process_execution/constants/utils";
import moment from "moment";

function showError(errors = []) {
    if (Utilitaries.isEmpty(errors)) {
        return;
    }
    Modal.error({
        title: "Erro",
        content: (
            <div>
                {errors.map((it, idx) => (
                    <p key={idx}>{it}</p>
                ))}
            </div>
        ),
    });
}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsText(img, "UTF-8");
}

const Form = ({
    formName,
    isModal,
    justSee,
    formId,
    onSave,
    onCancel,
    params,
    updateOnSave,
    processFix = false,
    setJustSeeInPopup,
    listName,
}) => {
    const { name: nameFromUrl } = useParams();
    const browserHistory = useHistory();

    const { getLogo, getChatRoomsOnSaveChatForm } = useContext(AppContext);

    // using to save data used by eval -> when call function in xml
    // eslint-disable-next-line no-unused-vars
    let localRootData = {};

    let formKey = undefined;
    let form_id = formId;
    if (isModal) {
        formKey = formName;
    } else {
        formKey = nameFromUrl;
        if (formKey !== "relatorios_portal_cli_disponi.qml.xml") {
            form_id = 1;
            if (formKey === "quala_gestao_sistema_politicas.qml.xml") {
                form_id = "7";
            }
        }
    }

    if (typeof form_id === "object") {
        form_id = undefined;
    }

    const [formulario, setFormulario] = useState(null); // or {qa_type: "tab" || "", attributes: {}, ...{}, tabs: [string], data: {}, fields: {}, inputs: {}}
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [isSavingTemporarily, setIsSavingTemporarily] = useState(false);

    // const [formData, setFormData] = useState({});
    const formData = useQAState({});

    const [inputDeps, setInputDeps] = useState({});
    const [formErrors, setFormErrors] = useState({}); // or ['erro message 1', ...]
    const [tablesToValidate, setTablesToValidate] = useState([]); //tables to validate in save
    const [isInitial, setIsInitial] = useState(true);
    const [flagAddTable, setFlagAddTable] = useState({});
    const [updateError, setUpdateError] = useState(false);

    // Get form from API
    useEffect(() => {
        getForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formKey]);

    const setDadosWhenLoading = ({ tree, values, keyData }) => {
        const obj = {};
        let hasValue;
        for (const propertie in tree) {
            if (tree[propertie]["$"] && tree[propertie]["$"].startsWith(`$${keyData}.`)) {
                var strRegExPattern = "(?<=" + keyData + ".).*";
                const keyToGetData = tree[propertie]["$"].match(new RegExp(strRegExPattern, "g"));
                obj[propertie] = values[keyToGetData];
                hasValue = true;
            } else if (typeof tree[propertie] === "object") {
                const result = setDadosWhenLoading({
                    tree: tree[propertie],
                    values,
                    keyData,
                });
                if (result) {
                    obj[propertie] = result;
                    hasValue = true;
                }
            }
        }
        return hasValue ? obj : null;
    };

    const getForm = async () => {
        try {
            formData.set({});
            setLoading(true);
            const _formData = new FormData();

            //clean state

            let _result;
            if (!processFix) {
                if (form_id) {
                    _formData.append("accao", "dados");
                    _formData.append("id", form_id);
                } else {
                    _formData.append("accao", "consultar");
                }
                _formData.append("utilizador", Session.getUsername());
                const paramsKeys = params && typeof params === "object" ? Object.keys(params) : [];

                paramsKeys.forEach((el) => {
                    if (!Utilitaries.isEmpty(params[el]) && !_formData.has(el)) {
                        _formData.append(el, params[el]);
                    }
                });

                _formData.append("hack", "true");
                _formData.append("formulario", formKey);

                if (Session.isCLient()) {
                    const params = Session.getAll();
                    _formData.append("usr_parametro1", params.parametro1);
                    _formData.append("usr_parametro2", params.parametro2);
                    _formData.append("usr_parametro3", params.parametro3);
                }

                if (
                    listName &&
                    [
                        "orbis_registo_movimentos.xml",
                        "orbis_inscr_nova_despesa.xml",
                        "orbis_inscr_nova_receita.xml",
                        "orbis_reg_pag_despesas.xml",
                        "orbis_reg_pag_receitas.xml",
                        "orbis_reg_recibimentos_datagrid.xml",
                        "orbis_reg_recibimentos_v2.xml",
                    ].includes(formKey)
                ) {
                    _formData.delete("accao");
                    _formData.append("lista", listName);
                    _formData.append("accao", "dados");

                    if (form_id) {
                        _formData.append("chave1", form_id);
                    }
                    _result = await sendHttpRequest("POST", "/Gestor/gerelistas.php", _formData);
                } else {
                    _result = await sendHttpRequest("POST", "/Gestor/gereformulario.php", _formData);
                }
            } else {
                _formData.append("accao", "consultar_correcao");
                if (form_id) {
                    _formData.append("id", form_id);
                }

                _result = await sendHttpRequest("POST", "/Gestor/gereprocesso.php", _formData);
            }
            let result = form_id ? _result["result"] : _result;

            if (result) {
                // form menu that call gerelista
                if (!form_id && !result?.formulario && _result["result"]) {
                    result = _result["result"];
                }
                const { formulario: formJson, valores } = result;
                // se existe dados no formulario
                if (formJson && formJson["@elemento_raiz"]) {
                    const formAttributes = {};
                    // Percores a lista e obter todos os atributos -> os que comesam com @
                    Object.entries(formJson).forEach(([key, value]) => {
                        if (key.startsWith("@")) {
                            formAttributes[key] = value;
                        }
                    });

                    // Obter os dados do formulario
                    let data = valores ? valores[formAttributes["@elemento_raiz"]] : {};

                    if (
                        [
                            "qa_parcerias_entidades.xml",
                            "qa_financiadores.xml",
                            "estabelecimentos.xml",
                            "qualia_socios_ficha_inscricao.qml.xml",
                        ].includes(formKey)
                    ) {
                        const formDefaultState = DEFAULT_FORM_STATE[formKey];

                        if (Object.keys(data).length === 0) {
                            data = formDefaultState;
                        } else {
                            data = Object.assign({}, formDefaultState, data);
                        }
                    } else if (formKey === "qa_alertas_autorizacoes.qml.xml") {
                        data = {
                            ...data,
                            alerta: data?.alerta?.["$"],
                        };
                    }

                    const elementoRaiz = formJson[formAttributes["@elemento_raiz"]];
                    const elementoRaizAttributes = {};
                    const elementoRaizInputs = {};

                    // Percores elemento rais e separar atributos de inputs
                    Object.entries(elementoRaiz).forEach(([key, value]) => {
                        if (key.startsWith("@")) {
                            elementoRaizAttributes[key] = value;
                        } else {
                            elementoRaizInputs[key] = value;
                        }
                    });

                    const defaultState = getDefaultValueForFormInputs({
                        formInputs: elementoRaizInputs,
                        params,
                        defaultState: data,
                    });

                    // ;
                    // colocar valores no state
                    let tempSaveData = localStorage.getItem(formKey);
                    if (tempSaveData && !form_id) {
                        try {
                            tempSaveData = JSON.parse(atob(tempSaveData));
                            // setFormData({ ...tempSaveData, ...defaultState });
                            formData.set({ ...tempSaveData, ...defaultState });
                            message.success("Foi caregados dados guardados temporariamente.");
                        } catch (error) {}
                    } else {
                        if (!Utilitaries.isEmpty(data)) {
                            let newData = {};
                            Object.keys(data).forEach((dataKey) => {
                                if (typeof data[dataKey] === "object") {
                                    const subKey = Object.keys(data[dataKey]);
                                    if (
                                        subKey.length === 1 &&
                                        dataKey === subKey[0] &&
                                        typeof data[dataKey][subKey[0]] === "object" &&
                                        elementoRaizInputs[dataKey]["@tipo"] === "datagrid"
                                    ) {
                                        newData = {
                                            ...newData,
                                            linha: {
                                                ...newData?.linha,
                                                [dataKey]: toArray(data[dataKey][dataKey]),
                                            },
                                        };
                                    } else {
                                        newData[dataKey] = data[dataKey];
                                    }
                                } else {
                                    newData[dataKey] = data[dataKey];
                                }
                            });

                            // setFormData({ ...newData, ...defaultState });
                            formData.set({ ...newData, ...defaultState });
                        } else if (elementoRaiz["@aoCarregar"]) {
                            const listAoCarregar = elementoRaiz["@aoCarregar"]?.split(";");

                            let newState = { ...defaultState };

                            for await (const str of listAoCarregar) {
                                // const str = elementoRaiz["@aoCarregar"];
                                if (!str) {
                                    continue;
                                }

                                let args = /\(\s*([^)]+?)\s*\)/.exec(str);

                                if (args) {
                                    if (args[1]) {
                                        args = args[1].replace(/'/gi, "").split(/\s*,\s*/);
                                    }

                                    const dataFormData = new FormData();
                                    dataFormData.append("accao", args[0]);
                                    dataFormData.append("query", args[1]);

                                    if (params) {
                                        Object.entries(params).forEach(([key, item]) => {
                                            if (!Utilitaries.isEmpty(item)) {
                                                dataFormData.append(key, item);
                                            }
                                        });
                                    }

                                    data = await sendHttpRequest("POST", "/Gestor/execQuery.php", dataFormData);

                                    //prepare formData so state

                                    const result = Array.isArray(data.result) && data.result[0] ? data.result[0] : {};
                                    const valuesForm = setDadosWhenLoading({
                                        tree: elementoRaiz,
                                        values: result,
                                        keyData: args[0],
                                    });
                                    // setFormData({ ...valuesForm, ...defaultState } || {});
                                    newState = { ...valuesForm, ...newState };
                                    // formData.set({ ...valuesForm, ...defaultState } || {});
                                }
                            }

                            formData.set({ ...newState });
                        } else {
                            // setFormData({ ...defaultState });
                            formData.set({ ...defaultState });
                        }
                    }

                    // if para formularios especificos - com tab
                    if (
                        formAttributes &&
                        [
                            "qualia_parametrizacao.qml.xml",
                            "qualia_socios_ficha_inscricao.qml.xml",
                            "qualia_parametrizacao_mensal_dados.qml.xml",
                        ].includes(formAttributes["@nome"])
                    ) {
                        const tabs = [];
                        const fields = {};
                        let inputs = {};

                        // util => remeber te last tab index
                        let lastTabIndex = -1;

                        Object.entries(elementoRaizInputs).forEach(([_key, _val]) => {
                            // ;
                            if (typeof _val === "object" && _val["@tab"]) {
                                if (!tabs.includes(_val["@tab"])) {
                                    tabs.push(_val["@tab"]);
                                    lastTabIndex++;
                                }
                            }

                            // check if current field have children
                            const { _fields, _inputs } = getInputRecursively(_key, _val);

                            if (lastTabIndex !== -1) {
                                let currentIndex = "tab" + lastTabIndex;
                                fields[currentIndex] = fields[currentIndex]
                                    ? [...fields[currentIndex], ..._fields]
                                    : _fields;
                            } else {
                                fields["fields"] = fields["fields"] ? [...fields["fields"], ..._fields] : _fields;
                            }

                            inputs = { ...inputs, ..._inputs };
                        });

                        setFormulario({
                            qa_type: "tab",
                            attributes: formAttributes, // formAtributes
                            ...elementoRaizAttributes, // Elemento rais atributes
                            tabs,
                            data,
                            fields,
                            inputs,
                        });
                    } else if (
                        formAttributes &&
                        [
                            "qa_ficha_portal_colab.xml",
                            "salario_mapas.xml",
                            "qa_parcerias_entidades.xml",
                            "qa_financiadores.xml",
                            "estabelecimentos.xml",
                            "fac_cria_terceiro.xml",
                            "config_rela_portal_cliente.qml.xml",
                            "config_rela_portal_parceiro.qml.xml",
                            "config_rela_portal_fornecedor.qml.xml",
                            "config_rela_portal_financiador.qml.xml",
                            "config_rela_portal_socio.qml.xml",
                            "qualia_ds15_01_inscricao_fornecedores.qml.xml",
                        ].includes(formAttributes["@nome"])
                    ) {
                        const fields = [];
                        let inputs = {};

                        const errors = {};

                        Object.entries(elementoRaizInputs).forEach(([_key, _val]) => {
                            //TODO: refator this condition
                            if (
                                [
                                    "entidade",
                                    "jasper_dispcli",
                                    "jasper_dispfi",
                                    "jasper_disppar",
                                    "jasper_dispsoc",
                                    "jasper_dispfo",
                                ].includes(_key)
                            ) {
                                let hasGroup = _val["@grupo"] || null;

                                Object.entries(_val).forEach(([entityKey, entityValue]) => {
                                    if (entityKey.startsWith("@")) {
                                        return;
                                    }

                                    if (entityKey === "moradas") {
                                        const moradaEntity = Object.entries(entityValue.morada).reduce(
                                            (accumulator, current) => {
                                                accumulator.field = [...accumulator.field, current[0]];
                                                const element = {
                                                    ...current[1],
                                                };

                                                if (hasGroup) {
                                                    element["@grupo"] = hasGroup;
                                                    hasGroup = null;
                                                }
                                                accumulator.inputs = {
                                                    ...accumulator.inputs,
                                                    [current[0]]: {
                                                        ...element,
                                                        _nodes: ["entidade", "moradas", "morada"],
                                                    },
                                                };

                                                if (
                                                    !valores &&
                                                    typeof element === "object" &&
                                                    !element["$"] &&
                                                    (element["@obrigatorio"] === "true()" ||
                                                        element["@obrigatorio"] === "true")
                                                ) {
                                                    errors[current[0]] = `${element["@etiqueta"]} obrigatório`;
                                                }

                                                return accumulator;
                                            },
                                            {
                                                field: [],
                                                inputs: {},
                                            }
                                        );

                                        fields.push(...moradaEntity.field);
                                        inputs = {
                                            ...inputs,
                                            ...moradaEntity.inputs,
                                        };
                                    } else {
                                        const element = {
                                            ...entityValue,
                                        };

                                        if (hasGroup) {
                                            element["@grupo"] = hasGroup;
                                            hasGroup = null;
                                        }

                                        fields.push(entityKey);
                                        inputs[entityKey] = {
                                            ...element,
                                            _nodes: [_key],
                                        };
                                        if (
                                            !valores &&
                                            typeof element === "object" &&
                                            !element["$"] &&
                                            (element["@obrigatorio"] === "true()" || element["@obrigatorio"] === "true")
                                        ) {
                                            errors[entityKey] = `${element["@etiqueta"]} obrigatório`;
                                        }
                                    }
                                });
                            } else {
                                fields.push(_key);
                                inputs[_key] = {
                                    ..._val,
                                    noNode: true,
                                };

                                if (
                                    !valores &&
                                    typeof _val === "object" &&
                                    !_val["$"] &&
                                    (_val["@obrigatorio"] === "true()" || _val["@obrigatorio"] === "true")
                                ) {
                                    errors[_key] = `${_val["@etiqueta"]} obrigatório`;
                                }
                            }
                        });

                        setFormErrors(errors);

                        setFormulario({
                            qa_type: "",
                            attributes: formAttributes,
                            ...elementoRaizAttributes,
                            fields: {
                                fields,
                            },
                            tabs: [],
                            data,
                            inputs,
                        });
                    } else {
                        // const fieldss = Object.keys(elementoRaizInputs);
                        let qa_type = "tab";
                        // if exist @tab in fields
                        const tabs = [];
                        const fields = {};
                        // if (formAttributes["@nome"] === "orbis_lista_fornecedores_sector.xml") {
                        //     const dataFormData = new FormData();

                        //     dataFormData.append("accao", "dados");
                        //     dataFormData.append("query", "qa_param_consulta_fornec");

                        //     data = await sendHttpRequest("POST", "/Gestor/execQuery.php", dataFormData);

                        //     setFormData(data.result[0]);
                        // } else if (
                        //     [
                        //         // "qa_ficha_espaco_acoes_limpeza.qml.xml",
                        //         "qa_parcerias_minutas_clausula.xml",
                        // "qa_ficha_espaco_calendarizacao.qml.xml",
                        //     ].includes(formAttributes["@nome"])
                        // ) {
                        //     const dataFormData = new FormData();

                        //     dataFormData.append("accao", "dados");

                        //     let dontCall = false;

                        //     if (
                        //         formAttributes["@nome"] === "qa_parcerias_minutas_clausula.xml" &&
                        //         params &&
                        //         params.conteudo_id_temp
                        //     ) {
                        //         dataFormData.append("conteudo_id_temp", params.conteudo_id_temp);
                        //         dataFormData.append("minuta_id", params.minuta_id);
                        //         dataFormData.append("query", "parceria_conteudo_clausula");
                        //      } else {
                        //         dontCall = true;
                        //     }

                        //     //

                        //     if (!dontCall) {
                        //         data = await sendHttpRequest("POST", "/Gestor/execQuery.php", dataFormData);

                        //             setFormData(data.result[0]);

                        //     }
                        // }

                        // util => remeber te last tab index
                        let lastTabIndex = -1;
                        const errors = {};

                        Object.entries(elementoRaizInputs).forEach(([_key, _val], findex) => {
                            if (typeof _val === "object" && _val["@tab"]) {
                                if (!tabs.includes(_val["@tab"])) {
                                    tabs.push(_val["@tab"]);
                                    lastTabIndex++;
                                }
                            }

                            let currentFieldIndex = "";
                            if (lastTabIndex !== -1) {
                                currentFieldIndex = "tab" + lastTabIndex;
                            } else {
                                qa_type = "";
                                currentFieldIndex = "fields";
                            }

                            // Desistruturar os botoes no formulario que vem em formato array
                            if (formAttributes["@nome"] === "salario_config_colaborador.xml") {
                                let additionalInputs = [];
                                additionalInputs = [_key];
                                fields[currentFieldIndex] = fields[currentFieldIndex]
                                    ? [...fields[currentFieldIndex], ...additionalInputs]
                                    : additionalInputs;

                                if (
                                    !valores &&
                                    typeof _val === "object" &&
                                    !_val["$"] &&
                                    (_val["@obrigatorio"] === "true()" || _val["@obrigatorio"] === "true")
                                ) {
                                    errors[_key] = `${_val["@etiqueta"]} obrigatório`;
                                }
                            } else {
                                fields[currentFieldIndex] = fields[currentFieldIndex]
                                    ? [...fields[currentFieldIndex], _key]
                                    : [_key];
                                if (
                                    !valores &&
                                    typeof _val === "object" &&
                                    !_val["$"] &&
                                    (_val["@obrigatorio"] === "true()" || _val["@obrigatorio"] === "true") &&
                                    _val["@elemento_grafico"] !== "etiqueta"
                                ) {
                                    errors[_key] = `${_val["@etiqueta"]} obrigatório`;
                                }
                            }

                            // if (lastTabIndex !== -1) {
                            //   let currentIndex = "tab" + lastTabIndex;
                            //   fields[currentIndex] = fields[currentIndex]
                            //     ? [...fields[currentIndex], _key]
                            //     : [_key];
                            // } else {
                            //   qa_type = "";
                            //   fields["fields"] = fields["fields"]
                            //     ? [...fields["fields"], _key]
                            //     : [_key];
                            // }
                        });
                        setFormErrors(errors);

                        setFormulario({
                            qa_type,
                            attributes: formAttributes,
                            ...elementoRaizAttributes,
                            fields,
                            tabs,
                            data,
                            inputs: elementoRaizInputs,
                        });
                    }

                    if (setJustSeeInPopup) {
                        const formReadOnly = elementoRaiz?.["@consulta_formulario"] === "sim" ? true : justSee;
                        setJustSeeInPopup(formReadOnly);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getInputRecursively = (keys, value) => {
        let _inputs = {},
            _fields = [];

        if (
            Object.keys(value).filter((it) => !(it.startsWith("@") || it.startsWith("$"))).length > 0 &&
            !value["@tipo"]
        ) {
            Object.entries(value).forEach(([inp, attr]) => {
                // If have more that one property that not start with @
                if (
                    !inp.startsWith("@") &&
                    Object.keys(attr).filter((it) => !(it.startsWith("@") || it.startsWith("$"))).length === 1 &&
                    !attr["@tipo"]
                ) {
                    const _nodes = [keys, inp, Object.keys(attr).filter((it) => !it.startsWith("@"))[0]];
                    _inputs[inp] = {
                        ...attr,
                        "@tipo": "conjunto-in-form",
                        _nodes,
                    };

                    _fields.push(inp);
                } else if (inp === "dados_adicionais") {
                    const allAttr = { ...attr };

                    _inputs["actividade_profissional"] = {
                        ...allAttr["actividade_profissional"],
                        "@tipo": "conjunto-in-form",
                        "@inputs_in": "root",
                        _nodes: [keys, inp, "actividade_profissional"],
                    };
                    delete allAttr.actividade_profissional;

                    _inputs[inp] = {
                        ...allAttr,
                        "@tipo": "conjunto-in-form",
                        "@inputs_in": "root",
                        _nodes: [keys, inp],
                    };

                    _fields.push(inp, "actividade_profissional");
                } else if (!inp.startsWith("@")) {
                    _inputs[inp] = {
                        ...attr,
                        _nodes: [keys],
                    };

                    _fields.push(inp);
                }
            });
        } else {
            _inputs[keys] = value;
            _fields = [keys];
        }

        return {
            _inputs,
            _fields,
        };
    };

    const getTableErrors = () => {
        const errors = [];
        tablesToValidate.forEach((item) => {
            //get data
            let data = [];
            let fieldsProps = {};
            let tableTag = null;
            if (item.node) {
                // data = [...formData[item.node][item.table][item._tableKey]];
                data = [...formData.get()[item.node][item.table][item._tableKey]];
                fieldsProps = formulario.inputs?.[item.node]?.[item.table]?.[item._tableKey];
            } else if (item._tableKey) {
                // data = [...formData[item.table][item._tableKey]];
                data = [...formData.get()[item.table][item._tableKey]];

                let table = formulario.inputs?.[item.table];
                if (table) {
                    fieldsProps = formulario.inputs?.[item.table]?.[item._tableKey];
                    tableTag = formulario.inputs?.[item.table]?.["@grupo"];
                } else {
                    fieldsProps = formulario.inputs?.[item._tableKey];
                    tableTag = formulario.inputs?.[item._tableKey]?.["@grupo"];
                }
            } else {
                // data = [...formData[item.table]];
                data = [...formData.get()[item.table]];
                fieldsProps = formulario.inputs?.[item.table];
            }
            // get erros
            data.forEach((element, index) => {
                // validar
                for (const field of item.fields) {
                    const error = validateTableField({
                        input: fieldsProps?.[field],
                        options: {
                            inTable: true,
                            items: {
                                __id: field,
                                table: item.table,
                                key: item._tableKey,
                                data: element,
                            },
                            brothers: Object.keys(element),
                            _nodes: item.node,
                        },
                        // formData,
                        formData: formData.get(),
                        values: element,
                        field,
                        functions: { comparar_datas, moment },
                    });
                    if (error) {
                        errors.push(`${index + 1}ª linha${tableTag ? " " + tableTag : ""}: ${error}`);
                    }
                }
            });
        });
        return errors;
    };

    const handleSave = (temporary = false) => {
        if (temporary) {
            setIsSavingTemporarily(true);
        } else {
            if (!updateError) setUpdateError(true);
            const tableErrors = getTableErrors();
            if (Object.keys(formErrors).length > 0 || tableErrors.length > 0) {
                showError([...Object.values(formErrors), ...tableErrors]);
                return;
            }

            setSaving(true);
        }

        // let _formData = { ...formData };
        let _formData = { ...formData.get() };

        if (formKey === "qa_parcerias_minutas_protocolo.xml") {
            _formData.versao = (parseInt(_formData.versao_atual) || 0) + 1;
        }

        if (formKey === "qa_imobilizado_transferencia_armazem.qml.xml") {
            _formData.utilizador_registo = Session.getUsername();
        }

        if (formKey === "stk_movimento.xml") {
            if (_formData.tipodoc === "SS") {
                Utilitaries.toArray(_formData?.linhas_mov?.linha).forEach((linha, index) => {
                    _formData.linhas_mov.linha[index].localizacao_destino = null;
                    _formData.linhas_mov.linha[index].armazemdestino = null;
                    _formData.linhas_mov.linha[index].localizacao = null;
                });
                // localizacao_destino
                // armazemdestino
            }
        }

        const allInputs = { ...formulario.inputs };

        Object.keys(allInputs).forEach((key) => {
            if (
                allInputs[key] &&
                allInputs[key]["$"] &&
                allInputs[key]["$"].startsWith("{$param.") &&
                !_formData[key]
            ) {
                _formData[key] = params?.[key];
            }
        });

        const fd = new FormData();
        fd.append("accao", "guardar");
        // if (temporary) {
        //     fd.append("obrigatorios", "false");
        // }
        fd.append(
            "dados",
            JSON.stringify({
                [formulario.attributes["@elemento_raiz"]]: _formData,
            })
        );
        fd.append("formulario", formKey);
        // fd.append("obrigatorios", temporary ? "false" : "true");

        api("/Gestor/gereprocesso.php", fd, "POST", formData.get())
            .then((result) => {
                if (temporary) {
                    message.success("Dados salvos temporariamente com sucesso!");
                    getForm();
                    setIsSavingTemporarily(false);
                    return;
                }

                // action to make after save item
                if (onSave && typeof onSave === "function") {
                    onSave();
                }

                // to close model
                if (onCancel) {
                    onCancel();
                }

                // // send inserted id to parent
                if (updateOnSave) {
                    updateOnSave.callback(updateOnSave.field, updateOnSave.options, result.sucesso);
                }

                if (formKey === "qualia_parametrizacao.qml.xml") {
                    getLogo();
                } else if (formKey === "criar_salas.qml.xml") {
                    getChatRoomsOnSaveChatForm();
                }

                message.success("Guardado com sucesso");
                setSaving(false);

                if (result?.notificacao) {
                    Modal.confirm({
                        title: "Notificação",
                        content: <pre>{result?.notificacao}</pre>,
                        className: "email_info",
                        okText: "Ok",
                        okType: "primary",
                        cancelButtonProps: {
                            hidden: true,
                        },
                        onOk() {},
                    });
                }

                if (formulario?.["@relatorio"]) {
                    Modal.confirm({
                        title: "Imprimir relatório",
                        content: "",
                        okType: "danger",
                        onOk: () => {
                            downloadFile("/gererelatorios.php", {
                                accao: "relatorio_nome",
                                nome: formulario?.["@relatorio"],
                                saida: "pdf",
                                id: result.sucesso,
                                CHAVE1: result.sucesso,
                                downloadInBrowser: true,
                            });
                        },
                        onCancel() {},
                    });
                }

                if (!isModal) {
                    browserHistory.push("/");
                }
            })
            .catch((err) => {
                // ;
                message.error(err.erro);
                setSaving(false);
                setIsSavingTemporarily(false);
            });
    };

    const handleTemporarySave = () => {
        const tempSaveData = btoa(JSON.stringify(formData.get()));
        localStorage.setItem(formKey, tempSaveData);
        message.success("Dados temporários guardados com sucesso.");
    };

    const hangleReset = () => {
        let formDataCopy = { ...formData.get() };

        formDataCopy = Object.keys(formDataCopy)
            .filter((it) => it.startsWith("id_"))
            .reduce((accumulator, current) => {
                accumulator[current] = formDataCopy[current];

                return accumulator;
            }, {});

        // setFormData(formDataCopy);
        formData.set(formDataCopy);
    };

    const handleLocalSave = () => {
        const item = { key: formKey, formData: formData.get() };

        const data = btoa(JSON.stringify(item));

        let filename = `${formulario["@titulo"]}-${new Date()}`;

        downFileFrontend(data, `${filename}.qa`);
    };

    const fileProps = {
        accept: ".qa",
        showUploadList: false,
        beforeUpload: (file) => {
            try {
                getBase64(file, (cont) => {
                    const content = JSON.parse(atob(cont));

                    if (content.key === formKey) {
                        // setFormData(content.formData);
                        formData.set(content.formData);
                    } else {
                        message.error("Ocorreu um erro");
                    }
                });
            } catch (error) {
                message.error("Ocorreu um erro");
            }
        },
    };

    const RenderButtonsActions = () => (
        <Row gutter={18} style={{ marginTop: 16 }}>
            <Col xs={24} sm={12}>
                <div className="qa-group-actions-left">
                    <Button
                        disabled={saving || isSavingTemporarily}
                        // loading={saving}
                        type="primary"
                        onClick={(e) => {
                            if (formKey === "qa_parcerias_minutas_protocolo.xml" && formData.get().versao_atual > 0) {
                                Confirm(() => {
                                    handleSave(false);
                                }, "Atenção! Se alterar a descrição será considerada uma nova minuta. Se pretende revê-la, não poderá alterar a descrição");
                                return;
                            }
                            handleSave(false);
                        }}
                    >
                        <Icon type="save" />
                        Guardar
                    </Button>
                    <Button disabled={saving || isSavingTemporarily} type="primary">
                        <Icon type="printer" />
                        Imprimir
                    </Button>
                    {/* <Button disabled={saving} type="primary" onClick={handleTemporarySave}> */}
                    <Button disabled={saving || isSavingTemporarily} type="primary" onClick={(e) => handleSave(true)}>
                        <Icon type="save" />
                        Guardar Temporário
                    </Button>
                </div>
            </Col>

            <Col xs={24} sm={12}>
                <div className="qa-group-actions-right">
                    <Button
                        disabled={saving || isSavingTemporarily}
                        icon="undo"
                        type="primary"
                        htmlType="submit"
                        onClick={hangleReset}
                    >
                        Reformular
                    </Button>

                    <Upload {...fileProps} className="load-local">
                        <Button
                            icon="cloud-upload"
                            type="primary"
                            htmlType="submit"
                            disabled={saving || isSavingTemporarily}
                        >
                            Carregar Local
                        </Button>
                    </Upload>

                    <Button
                        disabled={saving || isSavingTemporarily}
                        icon="play-circle"
                        type="primary"
                        htmlType="submit"
                        onClick={handleLocalSave}
                    >
                        Gravar Local
                    </Button>
                </div>
            </Col>
        </Row>
    );

    const inputToDontChangeValue = (input) => {
        const inputAction = input ? Object.keys(input).find((key) => key.startsWith("@accao")) : undefined;
        if (input?.["@visivel"] === "nao" && inputAction && input[inputAction]?.startsWith("texto(")) {
            return true;
        }

        return false;
    };

    const validateField = ({ key, value, showErrorInModal = true, visible, ...options }) => {
        let requiredErrors = {};
        if (visible) {
            const {
                _formErrors,
                _showError,
                value: alteredValue,
            } = handleRestrinction({
                comparar_datas,
                formData: formData.get(),
                formErrors: {},
                key,
                options,
                value,
            });

            requiredErrors = handleRequered({
                formData: formData.get(),
                formErrors: _formErrors,
                key,
                options,
                value: alteredValue,
            });
            if (showErrorInModal) {
                showError(_showError);
            }
        }
        setFormErrors((prev) => {
            const { [key]: currentError, ...restErors } = prev;
            return { ...restErors, ...requiredErrors };
        });
    };

    const changeInputValue1 = async ({
        key,
        value,
        callback = () => {},
        allValues = {},
        appLoading = false,
        ...options
    }) => {
        if (isInitial && !appLoading) {
            setIsInitial(false);
        }

        // otimization
        if (formData.get()?.[key] === value && !options?.inTable && key !== "preenche_dados") {
            return;
        }

        if (key === "param_utilizar") {
            console.log("key === param_utilizar");
            // return;
        }

        if (inputToDontChangeValue(options.inputAttribute)) {
            return;
        }

        console.log(`Change input value`, { key, value, options, callback, allValues, before: formData.get() });

        if (typeof value === "boolean") {
            value = value === true ? "t" : "f";
        }

        const valueKey =
            options && options.inputAttribute && options.inputAttribute["$"]
                ? options.inputAttribute["$"].split("$dados.")[1]
                : undefined;

        let inputValue = value;

        let { newFormData, newValues } = handleSetNewValue({
            allValues,
            changeObjectPropertyRecursively,
            formData: formData.get(),
            key,
            options,
            value: inputValue,
            valueKey,
            formulario,
        });

        const {
            _formErrors,
            _showError,
            value: alteredValue,
        } = handleRestrinction({
            comparar_datas,
            formData: newFormData,
            valueKey,
            formErrors: {},
            key,
            options,
            value,
            formFields: formulario?.inputs,
        });

        let requiredErrors = handleRequered({
            formData: newFormData,
            formErrors: _formErrors,
            key,
            options,
            value: alteredValue,
        });

        Object.entries(allValues).forEach(([valKey, valValue]) => {
            requiredErrors = handleRequered({
                formData: newFormData,
                formErrors: requiredErrors,
                key: valKey,
                options,
                value: valValue,
            });
        });

        // So mostrar depos que o utilizador alterar algo no formulario
        if (!appLoading) {
            showError(_showError);
        }

        // setFormErrors(() => ({ ...requiredErrors }));
        setFormErrors((prev) => {
            const errorToRemove = [key, ...Object.keys(allValues)];
            const restErrors = Object.entries(prev).reduce((acc, [errorKey, errorValue]) => {
                if (!errorToRemove.includes(errorKey)) {
                    acc[errorKey] = errorValue;
                }

                return acc;
            }, {});
            return { ...restErrors, ...requiredErrors };
        });

        inputValue = alteredValue;

        const newCalulteFormData = handleCalculateValue({
            changeObjectPropertyRecursively,
            formFields: formulario?.inputs,
            newFormData,
            options,
            tableData: newValues,
            formErrors,
            showError,
            setFormErrors,
        });

        const newUpadetedState = mergeRecursive(newFormData, newCalulteFormData);

        formData.set(mergeRecursive(formData.get(), newUpadetedState));
    };

    const setInputDependences = (key, inputProps, ...options) => {
        // ;
        if (inputProps["@quando_valor_alterado"]) {
            const dep = inputProps["@quando_valor_alterado"];

            let newProperties;

            if (options && options.inTable) {
                // if (formData[options.items.table][options.items.key]) {
                const currentState = inputDeps[options.items.table][options.items.key]
                    ? formData.get()[options.items.table][options.items.key]
                    : [];
                currentState.push(dep);

                newProperties = {
                    [options.items.table]: {
                        [options.items.key]: {
                            key: currentState,
                        },
                    },
                };
                // }
            } else {
                newProperties = { [key]: [dep] };
            }

            setInputDeps(Object.assign({}, inputDeps, newProperties));
        }
    };

    const changeObjectPropertyRecursively = (obj, arr = [], key, value, allValues = {}, path = {}) => {
        if (arr.length === 0) {
            if (path) {
                path[key] = value;
                path = { ...path, ...allValues };
            } else {
                path = {
                    [key]: value,
                    ...allValues,
                };
            }
        } else {
            if (!obj) {
                obj = {};
            }
            let el = arr.shift();
            path[el] = changeObjectPropertyRecursively(obj[el], arr, key, value, allValues);
        }
        return path;
    };

    const removeItemFromTable = (table, key, selectedRowKeys, options = null) => {
        if (selectedRowKeys.length === 0) {
            message.warn("Nenhuma linha foi selecionada!");
            return;
        }

        let currentState = [];
        let newValues = [];

        if (options && options._nodes) {
            const node = options._nodes[0];
            currentState = Array.isArray(formData.get()[node][table][key])
                ? formData.get()[node][table][key]
                : [formData.get()[node][table][key]];
        } else if (formData.get()[table][key]) {
            currentState = Array.isArray(formData.get()[table][key])
                ? formData.get()[table][key]
                : [formData.get()[table][key]];

            // selectedRowKeys.forEach(it => {
            //     currentState.splice(it, 1);
            // });
        }

        currentState.forEach((item, idx) => {
            if (!selectedRowKeys.includes(idx)) {
                newValues.push(item);
            }
        });

        let newList = {};

        if (options && options._nodes) {
            newList = {
                [options._nodes[0]]: {
                    ...formData.get()[options._nodes[0]],
                    [table]: {
                        [key]: newValues,
                    },
                },
            };
        } else {
            newList = {
                [table]: {
                    ...formData.get()[table],
                    [key]: newValues,
                },
            };
        }

        formData.set({
            ...formData.get(),
            ...newList,
        });
        message.success(`${selectedRowKeys.length} item foi removido da tabela`);
    };

    const setErrorsInAddTable = ({ table, _tableKey, node, fields }) => {
        const check = [...tablesToValidate].findIndex((item) => item.table === table) < 0;

        if (fields.length) {
            if (check) {
                //set errors in
                setTablesToValidate([
                    ...tablesToValidate,
                    {
                        table,
                        _tableKey,
                        node,
                        fields,
                    },
                ]);
            }
            setFlagAddTable({
                ...flagAddTable,
                [table]: !flagAddTable[table],
            });
        }
    };

    const addItemToTable = (table, key, object, options = null, inputAttribute = {}, requiredFields = []) => {
        let currentState = formData.get();
        if (options && options._nodes) {
            const node = options._nodes[0];
            if (
                key &&
                table &&
                currentState &&
                currentState[node] &&
                currentState[node][table] &&
                currentState[node][table][key]
            ) {
                currentState = [
                    ...(Array.isArray(currentState[node][table][key])
                        ? currentState[node][table][key]
                        : [currentState[node][table][key]]),
                    object,
                ];
            } else {
                currentState = [object];
            }
            setErrorsInAddTable({ table, _tableKey: key, node, fields: requiredFields });
        } else if (key && table && currentState && currentState[table] && currentState[table][key]) {
            currentState = [
                ...(Array.isArray(currentState[table][key]) ? currentState[table][key] : [currentState[table][key]]),
                object,
            ];
            setErrorsInAddTable({ table, _tableKey: key, fields: requiredFields });
        } else if (!key && table && currentState[table]) {
            currentState = [
                ...(Array.isArray(currentState[table]) ? currentState[table] : [currentState[table]]),
                object,
            ];
            setErrorsInAddTable({ table, fields: requiredFields });
        } else {
            currentState = [object];
            setErrorsInAddTable({ table, _tableKey: key, fields: requiredFields });
        }

        if (key) {
            let newList = {};
            if (options && options._nodes) {
                newList = {
                    [options._nodes[0]]: {
                        ...formData.get()[options._nodes[0]],
                        [table]: {
                            [key]: currentState,
                        },
                    },
                };
            } else {
                newList = {
                    [table]: {
                        ...formData.get()[table],
                        [key]: currentState,
                    },
                };
            }
            const ssss = Object.assign({}, formData.get(), newList);
            // ;
            formData.set(ssss);
            // setFormData(Object.assign({}, formData, newList));
            // const added = mergeRecursive(formData, newList);
            // setFormData(added);
        } else {
            // setFormData(
            //     Object.assign({}, formData, {
            //         [table]: currentState,
            //     })
            // );
            formData.set(
                Object.assign({}, formData.get(), {
                    [table]: currentState,
                })
            );
        }

        message.success("Item foi adicionado a tabela");
    };

    const setTableData = ({ table, key, data }) => {
        const auxFormData = { ...formData.get() };

        if (auxFormData && auxFormData[key]) {
            delete auxFormData[key];
        }

        if (!key) {
            formData.set(
                {
                    ...auxFormData,
                    [table]: data,
                }
                // Object.assign({}, formData, {
                //     [table]: data
                // })
            );
        } else {
            // setFormData((previewData) => ({
            //     ...previewData,
            //     [table]: {
            //         [key]: data,
            //     },
            // }));

            formData.set({
                ...formData.get(),
                [table]: {
                    ...formData.get()?.[table],
                    [key]: data,
                },
            });
        }

        //set state tableError to validate on save
        if (table) {
            let currentTable = formulario.inputs?.[table];
            if (!currentTable) {
                currentTable = formulario.inputs?.[key];
            }

            const fielsValidate = getTableFieldsToValidate(currentTable, key);
            setErrorsInAddTable({ table, _tableKey: key, fields: fielsValidate });
        }

        // message.success("Item foi adicianado a tabela");
    };

    // Functions to validation xml -> call by eval
    const comparar_datas = (res) => {
        return res;
    };

    const auto_preenche = async (query, ...params) => {
        const fd = new FormData();
        fd.append("query", query);
        fd.append("accao", "query");

        let nullValues = false;
        params
            .map((it) => it.replace(/[{}\s]/g, "").split(","))
            .forEach((it) => {
                // eslint-disable-next-line no-eval
                fd.append(it[0], eval(it[1]));

                // eslint-disable-next-line no-eval
                if (eval(it[1]) === undefined) {
                    nullValues = true;
                }
            });

        if (nullValues) {
            return;
        }

        try {
            const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", fd);
            const _value = data.result[0];

            return _value.ano || null;
            // changeInputValue({
            //   value: _value, // valor selecionado  in value
            //   key: field, // Nome do campo
            //   inputAttribute, // input atributos
            //   ...inputProps, // propriedades da tabela
            // });
        } catch (error) {
            return null;
        }
    };

    return (
        <div style={{ cursor: saving || isSavingTemporarily ? "wait" : "default" }}>
            <FormContext.Provider
                value={{
                    formData: formData.state,
                    changeInputValue: changeInputValue1,
                    setInputDependences,
                    params,
                    justSee: justSee || saving,
                    setTableData,
                    validationsFunctions: { comparar_datas },
                    formAttributes: formulario ? formulario.attributes : {},
                    justConsult: formulario?.["@consulta_formulario"],
                    ignoreData: formulario?.["@ignora_dados"],
                    formErrors,
                    updateError,
                    setFormErrors,
                    flagAddTable,
                    isInitial,
                    validateField,
                    rootElement: formulario?.attributes?.["@elemento_raiz"],
                }}
            >
                {isModal ? (
                    <h2>{formulario && formulario["@titulo"] ? formulario["@titulo"] : ""}</h2>
                ) : (
                    <PageHeader
                        onBack={() => browserHistory.goBack()}
                        title={formulario && formulario["@titulo"] ? formulario["@titulo"] : ""}
                    />
                )}

                {!formulario && loading ? (
                    <Row type="flex" align="middle" justify="center">
                        <Col>
                            <Spin />
                        </Col>
                    </Row>
                ) : !formulario && !loading ? (
                    <Result
                        status="404"
                        title="404"
                        subTitle="Lamentamos, não foi possível carregar o formulário!"
                        extra={
                            <Link to="/">
                                <Button type="primary">Voltar tela principal</Button>
                            </Link>
                        }
                    />
                ) : (
                    <>
                        <FormRender
                            form={formulario}
                            formData={formData.state}
                            params={params}
                            addItemToTable={addItemToTable}
                            removeItemFromTable={removeItemFromTable}
                            changeInputValue={changeInputValue1}
                            rootElement={formulario?.attributes?.["@elemento_raiz"]}
                        />
                        {(!formulario["@consulta_formulario"] ||
                            (formulario["@consulta_formulario"] && formulario["@consulta_formulario"] !== "sim")) &&
                            // formulario?.["@ignora_dados"] !== "sim" &&
                            !justSee && <RenderButtonsActions />}
                    </>
                )}
            </FormContext.Provider>
        </div>
    );
};

Form.propTypes = {
    formName: PropTypes.string,
    isModal: PropTypes.bool,
};

export default Form;
