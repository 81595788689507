import React, { useState, useEffect } from "react";
import { Modal, Alert, Table, Tabs, Icon, message } from "antd";
import { api_fetch } from "../V2/utils/HttpRequest/request";
import { Utilitaries } from "../business";
import { IN_DEVELOPMENT } from "../constants/enviroment";

const { TabPane } = Tabs;

const columnsCommun = [
    {
        title: "Tipo",
        dataIndex: "tipo",
        key: "tipo",
        sorter: (a, b) => a.tipo > b.tipo,
    },
    {
        title: "Descrição",
        dataIndex: "descricao",
        key: "descricao",
        sorter: (a, b) => a.descricao > b.descricao,
    },
    {
        title: "Prescrição",
        dataIndex: "data",
        key: "data",
        sorter: (a, b) => new Date(a.data) - new Date(b.data),
    },
];

const columns = (showActions = false, addToNotSow = (x) => {}) =>
    showActions
        ? [
              ...columnsCommun,
              {
                  title: "Ação",
                  render: (_, row) => (
                      <Icon
                          onClick={() => addToNotSow(row)}
                          type="eye-invisible"
                          title="Remover alerta da lista de pendentes"
                      />
                  ),
              },
          ]
        : columnsCommun;

const AlertComponent = ({ isAlertVisible, showModal, onCancel }) => {
    const [loading, setLoading] = useState(false);
    const [allAlerts, setAllAlerts] = useState([]);
    const [pendingAlerts, setPendingAlerts] = useState([]);
    const [toogleLoadAlert, setToogleLoadAlert] = useState(false);

    const alertas_nao_listar_have_alert = (alert, alertsNotShow) => {
        const findElement = alertsNotShow.find(
            (item) => item.descricao === alert.descricao && item.tipo === alert.tipo && item.d_fim === alert.d_fim
        );
        return findElement ? true : false;
    };

    useEffect(() => {
        setLoading(true);
        api_fetch({
            endPoint: `Gestor/gereutilizador.php`,
            method: "POST",
            params: {
                accao: "get-alertas",
            },
        }).then((result) => {
            if (result.status === "SUCCESS") {
                const alerts = Utilitaries.toArray(result.response?.alertas?.linha);
                const alertsNotList = Utilitaries.toArray(result.response?.alertas_nao_listar?.linha);

                const data = [];
                alerts.forEach((item) => {
                    if (alertas_nao_listar_have_alert(item, alertsNotList) === false) {
                        data.push(item);
                    }
                });

                if (!IN_DEVELOPMENT) {
                    const checkShowAlerts = (alert) => {
                        const existInAlertsNotList = alertsNotList.find(
                            (el) =>
                                el.descricao === alert.descricao && el.tipo === alert.tipo && el.d_fim === alert.d_fim
                        );
                        return !existInAlertsNotList;
                    };
                    const checkAlerts = alerts.find((alert) => checkShowAlerts(alert));

                    if (checkAlerts) {
                        showModal();
                    }
                }

                setPendingAlerts(data);
                setAllAlerts(alerts);
            } else if (result.status === "ERROR") {
                message.error(result.message || "Ocorreu um erro");
            } else {
                message.error("Ocorreu um erro");
            }
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toogleLoadAlert]);

    const notShow = (item) => {
        setLoading(true);
        const params = {
            accao: "not-show",
            descricao: item.descricao,
            tipo: item.tipo,
            d_inicio: item.d_inicio,
            d_fim: item.d_fim,
            data: item.data,
        };
        api_fetch({
            endPoint: `Gestor/gereAlertas.php`,
            method: "POST",
            params,
        }).then((result) => {
            if (result.status === "SUCCESS") {
                message.success("Alerta removido da lista pendente com sucesso");
                setToogleLoadAlert(!toogleLoadAlert);
            } else {
                message.error("Não foi possível remover o alerta da lista de pendentes");
                setLoading(false);
            }
        });
    };

    return (
        <Modal
            title="Alertas do Sistema"
            visible={isAlertVisible}
            onCancel={onCancel}
            width="90%"
            centered
            footer={null}
            zIndex={801}
        >
            <Alert message="Verifique todos os alertas pendentes!" type="warning" showIcon />
            <Tabs defaultActiveKey="1">
                <TabPane tab="Pendentes" key="1">
                    <Table
                        rowKey={(data) => Math.random() * 95564548}
                        size="small"
                        pagination={{
                            pageSize: 5,
                        }}
                        dataSource={pendingAlerts}
                        columns={columns(true, notShow)}
                        style={{ marginTop: 20 }}
                        loading={loading}
                    />
                </TabPane>
                <TabPane tab="Todos" key="2">
                    <Table
                        rowKey={(data) => Math.random() * 95564548}
                        size="small"
                        pagination={{
                            pageSize: 5,
                        }}
                        dataSource={allAlerts}
                        columns={columns(false)}
                        style={{ marginTop: 20 }}
                        loading={loading}
                    />
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default AlertComponent;
