// import { actionType, initialState } from "../constants/index";
import { actionType } from "../constants/index";
import { sendHttpRequest } from "../../../components/http.request";
import { validateResponse } from "../../../components/util";

export const insertProcess = (payload) => (dispatch) => {
    const formData = new FormData();
    formData.append("processo", payload.file);
    formData.append("codigo", payload.processCode);
    return sendHttpRequest("POST", "/Forms/insere_processo.php", formData).then((respBody) => {});
};

export const setField = (payload) => ({ type: actionType.setField, payload });

export const initProcessForm = (payload) => ({
    type: actionType.initProcessDefForm,
    payload,
});

export const getProcesses = (params) => (dispatch) => {
    const formData = new FormData();
    // const myProcesses = Object.values(initialState.domain.byId);
    for (const key in params) {
        if (params[key]) {
            formData.append(key, params[key]);
        }
    }
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
        const metadata = data["@attributes"];
        if (!metadata) return false;

        const list = validateResponse(data);

        const mappedProcess = list.map((item, index) => {
            // FIXME: remove this hack by adding columns to db tables if necessary
            // let process = myProcesses.find(
            //   process => process.codigo_externo === item.codigo_externo
            // );
            // if (process === undefined) {
            //   process = item;
            // } else {
            //   const { id, ...restProps } = process;
            //   process = { id: item.id, ...restProps };
            // }
            // return process;
            return { ...item, index };
        });
        return {
            lista: list,
            metadata: {
                resultados: Number(metadata.resultados),
                pagina: Number(metadata.pagina),
                visivel: metadata.visivel,
                resultados_pagina: Number(metadata.resultados_pagina),
            },
        };
        // dispatch({ type: actionType.getProcesses, payload: mappedProcess });
        // return Number(data["@attributes"].resultados);
    });
};

export const getProcessDetails = (payload) => (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "definicao");
    formData.append("processo", payload);
    return sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((data) => {
        dispatch({ type: actionType.getProcessDetails, payload: data });
        return data;
    });
};
