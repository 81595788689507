import React, { useState, useEffect } from "react";
import { Table, Checkbox, Row, Col, Button, message } from "antd";
import { connect } from "react-redux";

import { saveAutorizations } from "../actions/index";

const PermissionsTransfer = ({
    idmenu,
    webaccao,
    webtipo,
    leftDataSource,
    rightDataSource,
    onCloseEditor,
    saveAutorizations,
    setReload,
    reload,
}) => {
    const [dataSource, setDataSource] = useState([]);
    const [targetDataSource, setTargetDataSource] = useState([]);
    const [selectedLeftRowKeys, setSelectedLeftRowKeys] = useState([]);
    const [selectedRightRowKeys, setSelectedRightRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const rightDataKeys = rightDataSource.map((item) => item.key);
        const newLeftDataSource = [];
        leftDataSource.forEach((item) => {
            if (!rightDataKeys.includes(item.key)) newLeftDataSource.push(item);
        });
        setDataSource(newLeftDataSource);
        setTargetDataSource(rightDataSource);
    }, [rightDataSource, leftDataSource]);

    useEffect(() => {
        setSelectedLeftRowKeys([]);
        setSelectedRightRowKeys([]);
    }, [dataSource, targetDataSource]);

    const handleChanges = (dataIndex, value, record) => {
        record[dataIndex] = value ? "t" : "f";
        const newTargetDataSource = [...targetDataSource];
        const index = newTargetDataSource.findIndex((item) => item.key === record.key);
        newTargetDataSource[index] = record;
        setTargetDataSource(newTargetDataSource);
    };

    const rightTableColumns = [
        {
            dataIndex: "descricao",
            title: "Permissão",
        },
    ];

    if (webtipo && typeof webtipo !== "object") {
        if (webtipo.toLowerCase() === "lista") {
            rightTableColumns.push(
                {
                    dataIndex: "permite_inserir",
                    title: "Inserir",
                    render: (text, record) => (
                        <Checkbox
                            style={{}}
                            checked={text === "t"}
                            onChange={(e) => {
                                handleChanges("permite_inserir", e.target.checked, record);
                            }}
                        />
                    ),
                },
                {
                    dataIndex: "permite_consultar",
                    title: "Consultar",
                    render: (text, record) => (
                        <Checkbox
                            checked={text === "t"}
                            onChange={(e) => handleChanges("permite_consultar", e.target.checked, record)}
                        />
                    ),
                },
                {
                    dataIndex: "permite_alterar",
                    title: "Alterar",
                    render: (text, record) => (
                        <Checkbox
                            checked={text === "t"}
                            onChange={(e) => handleChanges("permite_alterar", e.target.checked, record)}
                        />
                    ),
                },
                {
                    dataIndex: "permite_eliminar",
                    title: "Eliminar",
                    render: (text, record) => (
                        <Checkbox
                            checked={text === "t"}
                            onChange={(e) => handleChanges("permite_eliminar", e.target.checked, record)}
                        />
                    ),
                }
            );
        }
    }

    const leftTableColumns = [
        {
            dataIndex: "descricao",
            title: "Permissão",
        },
    ];

    const onLeftSelectChange = (selectedRowKeys) => {
        setSelectedLeftRowKeys(selectedRowKeys);
    };
    const onRightSelectChange = (selectedRowKeys) => {
        setSelectedRightRowKeys(selectedRowKeys);
    };

    const rightRowSelection = {
        selectedLeftRowKeys,
        onChange: onRightSelectChange,
    };
    const leftRowSelection = {
        selectedLeftRowKeys,
        onChange: onLeftSelectChange,
    };

    const hasLeftSelected = selectedLeftRowKeys.length > 0;
    const leftTableTitle = () => {
        if (hasLeftSelected) return `Todas as Permissões | ${selectedLeftRowKeys.length} selecionado`;
        else return `Todas as Permissões`;
    };

    const hasRightSelected = selectedRightRowKeys.length > 0;
    const rightTableTitle = () => {
        if (hasRightSelected) return `Permissões do Menu | ${selectedRightRowKeys.length} selecionado`;
        else return `Permissões do Menu`;
    };

    const sendToRightTable = () => {
        const newTargetDataSource = [];
        const newDataSource = [];

        dataSource.forEach((item) => {
            if (!selectedLeftRowKeys.includes(item.key)) {
                if (!newDataSource.map((el) => el.key).includes(item.key)) newDataSource.push(item);
            } else {
                if (!newTargetDataSource.map((it) => it.key).includes(item.key)) {
                    newTargetDataSource.push(item);
                }
            }
        });

        setDataSource(newDataSource);
        const current = [...newTargetDataSource, ...targetDataSource];
        setTargetDataSource(current);
    };

    const sendToLeftTable = () => {
        const newTargetDataSource = [];
        const newDataSource = [];

        targetDataSource.forEach((item) => {
            if (!selectedRightRowKeys.includes(item.key)) {
                if (!newTargetDataSource.map((el) => el.key).includes(item.key)) newTargetDataSource.push(item);
            } else {
                if (!newDataSource.map((it) => it.key).includes(item.key)) {
                    newDataSource.push(item);
                }
            }
        });

        const current = [...newDataSource, ...dataSource];
        setDataSource(current);
        setTargetDataSource(newTargetDataSource);
    };

    const postAutorizatons = async () => {
        let autorizacoes = "";
        let autorizacoes_lista = "";
        targetDataSource.forEach((item) => {
            if (autorizacoes) autorizacoes = `${autorizacoes};${item?.key || item?.linha?.idautorizacao}`;
            else autorizacoes = item.key;
            if (autorizacoes_lista) autorizacoes_lista = `${autorizacoes_lista};${item.key}`;
            else autorizacoes_lista = `${item.key}`;

            if (item.permite_inserir && item.permite_inserir === "t") autorizacoes_lista = `${autorizacoes_lista}:i`;
            if (item.permite_consultar && item.permite_consultar === "t")
                autorizacoes_lista = `${autorizacoes_lista}:c`;
            if (item.permite_alterar && item.permite_alterar === "t") autorizacoes_lista = `${autorizacoes_lista}:a`;
            if (item.permite_eliminar && item.permite_eliminar === "t") autorizacoes_lista = `${autorizacoes_lista}:e`;
        });
        setLoading(true);
        const response = await saveAutorizations({
            accao: "guardar_autorizacoes",
            idmenu: idmenu,
            webaccao: webaccao,
            webtipo: webtipo,
            autorizacoes: autorizacoes,
            autorizacoes_lista: autorizacoes_lista,
        });
        setLoading(false);
        if (response) message.success("Sucesso");
        else message.error("Ocorreu um erro");
        setReload(!reload);
        onCloseEditor();
    };

    return (
        <Row gutter={16}>
            <Col xs={11}>
                <Table
                    size="small"
                    title={leftTableTitle}
                    dataSource={dataSource}
                    columns={leftTableColumns}
                    rowSelection={leftRowSelection}
                />
            </Col>
            <Col style={{ verticalAlign: "middle" }} xs={2}>
                <div>
                    <Button
                        onClick={sendToRightTable}
                        disabled={!selectedLeftRowKeys.length}
                        type="primary"
                        icon="right"
                    />
                </div>
                <div>
                    <Button
                        onClick={sendToLeftTable}
                        disabled={!selectedRightRowKeys.length}
                        type="primary"
                        icon="left"
                    />
                </div>
            </Col>
            <Col xs={11}>
                <Table
                    size="small"
                    title={rightTableTitle}
                    dataSource={targetDataSource}
                    columns={rightTableColumns}
                    rowSelection={rightRowSelection}
                />
            </Col>
            <Col xs={24}>
                <Button loading={loading} onClick={postAutorizatons} type="primary" icon="save" style={{ margin: 2 }}>
                    Guardar
                </Button>
            </Col>
        </Row>
    );
};

const mapDispatchToProps = {
    saveAutorizations,
};

export default connect(null, mapDispatchToProps)(PermissionsTransfer);
