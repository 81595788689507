import { actionType } from "./../constants/time.registration";
import { arrayToObjectById } from "./../../../components/util";
import { tableActions } from "./../constants/time.registration";
import moment from "moment";
const formatDateTime = "DD-MM-YYYY HH:mm";
const formatRegisterDate = "DD-MM-YYYY HH:mm";

function resetFormEdit() {
    return {};
}

function resetFormCreate() {
    return {
        system_data_entrada: { name: "system_data_entrada", value: null },
        hora_entrada: { name: "hora_entrada", value: null },
        motivo_alteracao_data: { name: "hora_entrada", value: null },
        observacoes: { name: "observacoes", value: null },
    };
}

const initialState = {
    domain: {
        formCreate: resetFormCreate(),
        formEdit: resetFormEdit(),
        byId: {},
        metadata: {
            attributes: {},
            formulario: {},
        },
    },
    app: {
        isFetched: false,
    },
};

export const timeRegistrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.select:
            let list = [];
            if (Number(action.payload["@attributes"].resultados) > 1) list = [...action.payload["linha"]];
            else if (Number(action.payload["@attributes"].resultados) === 1) list = [action.payload["linha"]];

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: arrayToObjectById({
                        array: list,
                        id: "id",
                    }),
                    metadata: {
                        ...state.domain.metadata,
                        attributes: {
                            ...action.payload["@attributes"],
                        },
                    },
                },
                app: {
                    isFetched: true,
                },
            };

        case actionType.getForm:
            const formCreate = action.payload.formulario["@attributes"];
            return {
                ...state,
                domain: {
                    ...state.domain,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formCreate.nome,
                            elemento_raiz: formCreate.elemento_raiz,
                        },
                    },
                },
            };

        case actionType.insert:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    formCreate: resetFormCreate(),
                },
            };

        case actionType.setField:
            if (typeof action.payload.id === "undefined") {
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formCreate: {
                            ...state.domain.formCreate,
                            ...action.payload,
                        },
                    },
                };
            } else
                return {
                    ...state,
                    domain: {
                        ...state.domain,
                        formEdit: {
                            ...state.domain.formEdit,
                            ...action.payload.fields,
                        },
                    },
                };

        case actionType.getById:
            const values = action.payload.response.valores.registo_ponto;
            const formulario = action.payload.response.formulario["@attributes"];
            //system data entrada
            let system_data_entrada = null;

            if (typeof values.system_data_entrada === "object")
                system_data_entrada = `${values.data_registo} ${values.hora_entrada}`;
            else system_data_entrada = values.system_data_entrada;

            // hora entrada
            let hora_entrada = null;
            if (typeof values.system_data_entrada === "object") {
                hora_entrada = `${values.data_dia} ${hora_entrada}`;
            } else {
                hora_entrada = values.hora_entrada;
            }

            // system hora saida
            let system_data_saida = null;
            let hora_saida = null;
            if (action.payload.tableAction === tableActions.marcarSaida) {
                const data = new Date();
                system_data_saida = data;
                hora_saida = data;
            } else {
                if (typeof values.hora_saida !== "object") {
                    if (typeof values.system_data_saida === "object") {
                        system_data_saida = `${values.data_registo} ${values.hora_saida}`;
                    } else system_data_saida = values.system_data_saida;
                }

                // hora saida

                if (typeof values.hora_saida !== "object") {
                    if (typeof values.system_data_saida === "object") {
                        hora_saida = `${values.data_dia} ${values.hora_saida}`;
                    } else {
                        hora_saida = values.hora_saida;
                    }
                }
            }

            // motivo_alteracao_data_entrada
            let motivo_alteracao_entrada = null;
            if (typeof values.motivo_alteracao_data_entrada !== "object")
                motivo_alteracao_entrada = values.motivo_alteracao_data_entrada;

            // motivo_alteracao_data_saida
            let motivo_alteracao_saida = null;
            if (typeof values.motivo_alteracao_data_saida !== "object")
                motivo_alteracao_saida = values.motivo_alteracao_data_saida;

            // motivo_alteracao_data_saida
            let observacao = null;
            if (typeof values.observacoes !== "object") observacao = values.observacoes;

            let formData = {
                id_registo_ponto: values.id_registo_ponto,
                username: {
                    name: "username",
                    value: values.username,
                },
                data_registo: {
                    name: "data_registo",
                    value: moment(values.data_registo, formatRegisterDate),
                },
                data_dia: {
                    name: "data_dia",
                    value: moment(values.data_dia, formatDateTime),
                },
                observacoes: {
                    name: "observacoes",
                    value: observacao,
                },
                system_data_entrada: {
                    name: "system_data_entrada",
                    value: moment(system_data_entrada, formatDateTime),
                },
                hora_entrada: {
                    name: "hora_entrada",
                    value: moment(hora_entrada, formatDateTime),
                },
                system_data_saida: {
                    name: "system_data_saida",
                    value: system_data_saida ? moment(system_data_saida, formatDateTime) : null,
                },
                hora_saida: {
                    name: "hora_saida",
                    value: hora_saida ? moment(hora_saida, formatDateTime) : null,
                },
                motivo_alteracao_data_entrada: {
                    name: "motivo_alteracao_data_entrada",
                    value: motivo_alteracao_entrada,
                },
                motivo_alteracao_data_saida: {
                    name: "motivo_alteracao_data_saida",
                    value: motivo_alteracao_saida,
                },
            };

            return {
                ...state,
                domain: {
                    ...state.domain,
                    formEdit: formData,
                    metadata: {
                        ...state.domain.metadata,
                        formulario: {
                            nome: formulario.nome,
                            elemento_raiz: formulario.elemento_raiz,
                        },
                    },
                },
            };

        case actionType.update:
            const oldObj = state.domain.byId[state.domain.formEdit.id.value];
            const objForm = state.domain.formEdit;

            let objToUpdate = {
                ...oldObj,
                startTime: objForm.startTime.value.format("DD-MM-YYYY HH:mm"),
                reasonChangeStartTime: objForm.reasonChangeStartTime.value,
                observation: objForm.observation.value,
            };

            if (objForm.defaultStartTime.value.isSame(objForm.startTime.value))
                objToUpdate.reasonChangeStartTime = null;

            if (objForm.exitTime.value !== null) {
                objToUpdate.defaultExitTime = objForm.defaultExitTime.value.format("DD-MM-YYYY HH:mm");
                objToUpdate.exitTime = objForm.exitTime.value.format("DD-MM-YYYY HH:mm");
                if (objForm.defaultExitTime.value.isSame(objForm.exitTime.value))
                    objToUpdate.reasonChangeExitTime = null;
                else objToUpdate.reasonChangeExitTime = objForm.reasonChangeExitTime.value;
            } else {
                objToUpdate.defaultExitTime = null;
                objToUpdate.exitTime = null;
                objToUpdate.reasonChangeExitTime = null;
            }

            return {
                ...state,
                domain: {
                    ...state.domain,
                    byId: {
                        ...state.domain.byId,
                        [oldObj.key]: objToUpdate,
                    },
                    formEdit: resetFormEdit(),
                },
            };

        // case actionType.setFieldEdit:
        //   return {
        //     ...state,
        //     domain: {
        //       ...state.domain,
        //       formEdit: {
        //         ...state.domain.formEdit,
        //         ...action.payload
        //       }
        //     }
        //   };

        default:
            return state;
    }
};
