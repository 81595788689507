import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import ptPtLocate from "@fullcalendar/core/locales/pt-br";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";

import moment from "moment";
import { api_fetch } from "../../../utils/HttpRequest/request";
import { message, Modal, Tooltip, Typography } from "antd";
import { GenericObject } from "../../../types/common";
import FormRental from "./formRental";
import { CalendarEventProps, formatDataCalendarEvents, getRentalTypeColor } from "../formFunctions";
import { Utilitaries } from "../../../../business";

interface RentalScheduleProps {
    // refreshSchedule: boolean;
    propertie?: string;
    year?: number;
    // comboValue?: string;
    // phpFile?: string;
    // refreshSchedule?: boolean;
    // handleEditSchedule: (id?: string) => void;
    // handleNewSchedule: (props: GenericObject) => void;
}

interface ScheduleModalProps {
    visible: boolean;
    id?: string;
    formParams?: GenericObject;
}

// comboValue,
// phpFile,
// refreshSchedule,
// handleNewSchedule,
// handleEditSchedule,

interface GetToolTipContentProps {
    title?: string;
    description?: string;
    dateIni?: string;
    dateEnd?: string;
    type?: string;
}

const getToolTipContent = ({ title, dateIni, dateEnd, description, type }: GetToolTipContentProps) => {
    return (
        <>
            <span>
                <b>{title}</b>
            </span>
            {dateIni && (
                <>
                    <br />
                    <span>{dateIni + " - " + dateEnd}</span>
                </>
            )}

            <br />
            <span>{type}</span>
            {description && (
                <>
                    <br />
                    <span>{description}</span>
                </>
            )}
        </>
    );
};

const RentalSchedule = ({ propertie, year }: RentalScheduleProps) => {
    const [toogleSave, setToogleSave] = useState(false);
    const [scheduleEvents, setScheduleEvents] = useState<CalendarEventProps[]>([]);

    const [visibleDateOnCalendar, setVisibleDateOnCalendar] = useState({
        start: "",
        end: "",
    });

    const [modalProps, setModalProps] = useState<ScheduleModalProps>({ visible: false });

    let calendarRef = useRef<FullCalendar | null>(null);

    const handleDatesRender = (info: any) => {
        const _start = moment(info.view.activeStart).format("YYYY-MM-DD HH:mm:ss");
        const _end = moment(info.view.activeEnd).format("YYYY-MM-DD HH:mm:ss");
        const { start, end } = visibleDateOnCalendar;
        if (moment(start).format("YYYY-MM-DD") !== _start && moment(end).format("YYYY-MM-DD") !== _end) {
            setVisibleDateOnCalendar({
                start: _start,
                end: _end,
            });
        }
    };

    useEffect(() => {
        if (year) {
            const api = calendarRef?.current?.getApi();
            const data = api?.getDate();
            data?.setFullYear(year);
            api?.gotoDate(data);
        }
    }, [year]);

    useEffect(() => {
        const loadCalendarEvents = async () => {
            try {
                const { start, end } = visibleDateOnCalendar;
                if (!start || !end) {
                    return;
                }

                const params = {
                    accao: "consulta",
                    data_inicio: start,
                    data_fim: end,
                    imovel: propertie || "0",
                };
                const {
                    response,
                    status,
                    message: messageError,
                } = await api_fetch({
                    endPoint: `Gestor/gerealuguer.php`,
                    method: "POST",
                    params,
                });

                if (status === "ERROR") {
                    message.error(messageError || "Ocorreu um erro");
                    return;
                } else if (status === "SUCCESS") {
                    const result = Utilitaries.toArray(response?.reservas?.linha);
                    const dataSource = formatDataCalendarEvents(result);
                    setScheduleEvents(dataSource);
                }
            } catch (error) {
                // setComboLoading(false);
            }
        };
        loadCalendarEvents();
    }, [toogleSave, propertie, visibleDateOnCalendar]);

    const handleRenderEvent = (info: any) => {
        const { _description } = info.event?.extendedProps || {};
        const { start, end, title } = info.event || {};

        const dateIni = start ? moment(start).format("DD-MM-YYYY") : undefined;
        const dateEnd = end ? moment(end).format("DD-MM-YYYY") : undefined;

        const toolTipContent = getToolTipContent({ title, dateIni, dateEnd, description: _description });

        const content = (
            <Tooltip overlayStyle={{ maxWidth: "80%" }} title={toolTipContent}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography.Paragraph ellipsis style={{ color: "inherit", margin: 0 }}>
                        <div className="fc-content">
                            <div className="fc-time" data-start={"00:00"} data-full={"23:59"}>
                                <span>{dateIni + " - " + dateEnd}</span>
                            </div>

                            <div className="fc-title">
                                {title} <br />
                                {_description}
                            </div>
                        </div>
                    </Typography.Paragraph>
                </div>
            </Tooltip>
        );

        ReactDOM.render(content, info.el);
    };

    const handleDateSelect = (arg: any) => {
        if (!propertie) {
            message.warning("Por favor, selecione um imóvel");
            return;
        }
        const formParams = {
            dataInicio: moment(arg.start).format("YYYY-MM-DD"),
            dataFim: moment(arg.end).format("YYYY-MM-DD"),
            propertie,
        } as GenericObject;

        setModalProps({ visible: true, formParams });
    };

    const handleOnSave = () => {
        setModalProps({ visible: false });
        setToogleSave((prev) => !prev);
    };
    return (
        <>
            <FullCalendar
                ref={calendarRef}
                defaultView="dayGridMonth"
                datesRender={handleDatesRender}
                eventRender={handleRenderEvent}
                editable={false}
                selectable={true}
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                locales={[ptPtLocate]}
                locale="pt-pt"
                header={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                }}
                buttonText={{
                    today: "Ano / Mês atual",
                    month: "Mês",
                    week: "Semana",
                    day: "Dia",
                    list: "Lista",
                }}
                events={scheduleEvents}
                select={handleDateSelect}
                // eventClick={handleEventClick}
            />
            {modalProps.visible && (
                <FormRental
                    id={modalProps.id}
                    formParams={modalProps.formParams}
                    visible={modalProps.visible}
                    handleCancel={() => setModalProps({ visible: false })}
                    handleOnSave={handleOnSave}
                    propertie={propertie}
                />
            )}
        </>
    );
};

export default RentalSchedule;
