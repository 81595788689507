import React, { useState, useEffect } from "react";
import { Table, Button, Row, Popconfirm, Popover, Radio, Icon } from "antd";
import { componentType, xmlProps } from "../../constants";
import ComponentRendererWrapper from "../execution/component.render.wrapper";
import { toArray } from "../../../../components/util";
import { isEmpty, setValueByPopupParams, canCorrectField } from "../../utils/fields";
import { downFile, getDataSource } from "../../constants/utils";
import { Utilitaries } from "../../../../business";

const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
};

const renderSelectAll = ({ currentItem, changeAll, currentTaskStatus, context }) => {
    let contentSelection = (dataSource, key) => (
        <Radio.Group onChange={(e) => changeAll(e.target.value, key)}>
            {dataSource.map((data) => (
                <Radio style={radioStyle} key={data.key} value={data.key}>
                    {data.value}
                </Radio>
            ))}
        </Radio.Group>
    );

    let optionsSelect = null;
    if (currentItem?.["@seleccionar_todos"] === "sim") {
        if (!isEmpty(currentItem?.["@valores"])) {
            const dataSource = getDataSource(currentItem);
            optionsSelect = contentSelection(dataSource, currentItem);
            return (
                <Popover content={optionsSelect} title="Selecionar" trigger="click">
                    <Button type="link" icon="edit"></Button>
                </Popover>
            );
        } else {
            if (!isEmpty(currentItem?.["@dados"])) {
                const dataSource = toArray(context.getFromCache(context.dataSelectAll?.[currentItem.key], {})).map(
                    (item) => ({
                        key: item[currentItem["@chave_lista"]],
                        value: item[currentItem["@valor_lista"]],
                    })
                );
                optionsSelect = contentSelection(dataSource, currentItem);
                return (
                    <Popover content={optionsSelect} title="Selecionar" trigger="click">
                        <Button type="link" icon="edit"></Button>
                    </Popover>
                );
            } else {
                return (
                    <Popconfirm
                        title="Selecionar todos como:"
                        onConfirm={() => changeAll(true, currentItem)}
                        onCancel={() => changeAll(false, currentItem)}
                        okText="Sim"
                        cancelText="Não"
                        disabled={currentTaskStatus}
                    >
                        <Icon type="check-square" />
                    </Popconfirm>
                );
            }
        }
    }
};

const transformColumns = ({
    itemData,
    form,
    context,
    setLabel,
    type,
    formKey,
    currentTaskStatus,
    changeAll,
    disabled,
}) => {
    let columns = [];

    for (const itemProp in itemData) {
        if (
            typeof itemData[itemProp] === "object" &&
            itemProp !== "dataSource" &&
            !Array.isArray(itemData[itemProp])
            // &&
            // (!itemData[itemProp]["@visivel"] || itemData[itemProp]["@visivel"] !== "nao")
        ) {
            const currentItem = itemData[itemProp];
            columns.push({
                ...itemData?.[itemProp],
                dataIndex: itemProp,
                title: (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minWidth: "80px",
                        }}
                    >
                        {setLabel({
                            state: { form },
                            title: currentItem?.["@etiqueta"],
                            itemData: currentItem,
                        })}
                        {currentItem?.["@seleccionar_todos"] === "sim" &&
                            renderSelectAll({ currentItem, changeAll, currentTaskStatus, context })}
                    </div>
                ),
            });
        }
    }

    if (type === componentType._dynamicDatagrid && itemData?.["@mostrar_botoes_add_rem"] === "sim" && !disabled) {
        columns.push({
            dataIndex: "actions",
            title: "Ação",
            "@tipo": "deleteAction",
            width: "10%",
        });
    }

    const columnNumber = columns.filter(
        (column) => column["@tipo"] && (!column?.["@visivel"] || column?.["@visivel"] !== "nao")
    ).length;

    columns = columns.map((column) => {
        // --- in case column has other component in it
        if (column["@tipo"] && (!column?.["@visivel"] || column?.["@visivel"] !== "nao")) {
            const columnKey = column.dataIndex;
            column.root = itemData.key;
            column.key = columnKey;
            return {
                ...column,
                render: (text, record) => {
                    const value = record[columnKey];

                    return (
                        <ComponentRendererWrapper
                            renderContext="column"
                            context={context}
                            item={column}
                            value={value}
                            itemKey={itemData.key}
                            record={record}
                            form={form}
                            formKey={formKey}
                            tablePath={itemData?.inputGroupPath}
                            showFieldEditorModal={columnNumber >= 4}
                            fixedSize={columnNumber >= 5}
                        />
                    );
                },
            };
        }
        // --- in case column has no other component in it
        else return column;
    });
    return columns;
};

const Datagrid = ({
    itemData,
    type,
    isRoot,
    handleTableAdd,
    form,
    context,
    setLabel,
    formKey,
    correctForm,
    currentTaskStatus,
    changeTableBooleanColumn,
    loadData,
    tableAddButtonLoad,
    setLastActionType,
}) => {
    const changeAll = (status, column) => {
        changeTableBooleanColumn({ status, column, table: itemData });
    };

    const canEdit = canCorrectField(itemData) && correctForm;
    const disabled = canEdit ? false : currentTaskStatus;

    const columns = transformColumns({
        itemData,
        form,
        context,
        setLabel,
        type,
        formKey,
        currentTaskStatus,
        changeAll,
        disabled,
    });
    const columnsToRender = columns.filter(
        (column) =>
            (column["@tipo"] || column?.["@elemento_grafico"] === "texto") &&
            (!column?.["@visivel"] || column?.["@visivel"] !== "nao")
    );

    let tableDataSource = toArray((itemData.dataSource && Object.values(itemData.dataSource)) || []);
    if (tableDataSource) {
        if (tableDataSource.length > 0 && !Utilitaries.isEmpty(tableDataSource[0]._key_)) {
            //order by _key_
            tableDataSource.sort((a, b) => {
                if (a._key_ > b._key_) return 1;
                else if (a._key_ < b._key_) return -1;
                return 0;
            });
        }
    }

    // ;
    // const columns = Object.values(itemData)
    // 	.filter(
    // 		(item) =>
    // 			typeof item === "object" &&
    // 			!Array.isArray(item) &&
    // 			(!item["@visivel"] || item["@visivel"] !== "nao")
    // 	)
    // 	.map((col) => ({
    // 		dataIndex: col["@chave_lista"],
    // 		title: col["@etiqueta"] || col["@chave_lista"],
    // 	}));

    // const getTableScroll = () => {
    //     // if (itemData.key === "reunioes_plan" && itemData.formKey === "plano_reunioes") {
    //     //     return { x: 3000, y: 300 };
    //     // }
    //     return {};
    // };

    return (
        <>
            {type == componentType.datagrid && (
                <>
                    {/* <div style={{ marginBottom: 8 }}>
                        <Button style={{ color: "green" }} icon="file-excel" title="Exportar excel" onClick={""} />
                        <Button
                            style={{ color: "red", marginLeft: 4 }}
                            icon="file-pdf"
                            title="Exportar pdf"
                            onClick={""}
                        />
                    </div> */}
                    <Table
                        columns={columnsToRender}
                        pagination={{
                            defaultPageSize: 10,
                            showTotal: (total) => `Total registos: ${total}`,
                            showSizeChanger: true,
                        }}
                        dataSource={toArray((itemData.dataSource && Object.values(itemData.dataSource)) || [])}
                        loading={loadData}
                        style={{ marginBottom: 16 }}
                    />
                </>
            )}

            {type == componentType._dynamicDatagrid && (
                <>
                    <Row>
                        {itemData["@titulo_datagrid"]}
                        {itemData?.["@mostrar_botoes_add_rem"] === "sim" && (
                            <Button
                                icon="plus"
                                type={"primary"}
                                size="default"
                                disabled={disabled || loadData}
                                loading={tableAddButtonLoad}
                                onClick={() => {
                                    handleTableAdd({
                                        key: itemData.key,
                                        columns: columns
                                            .filter((item) => item["@tipo"] !== "deleteAction")
                                            .map((item) => item.key || item.dataIndex),
                                        schemaColumns: columns,
                                        inputGroupPath: itemData?.inputGroupPath ? [...itemData?.inputGroupPath] : null,
                                    });
                                }}
                                style={{
                                    float: "right",
                                    marginLeft: 10,
                                    marginBottom: 20,
                                }}
                            >
                                Adicionar
                            </Button>
                        )}
                    </Row>

                    <Table
                        pagination={{
                            defaultPageSize: 10,
                            showTotal: (total) => `Total registos: ${total}`,
                            showSizeChanger: true,
                        }}
                        columns={columnsToRender}
                        // dataSource={toArray((itemData.dataSource && Object.values(itemData.dataSource)) || [])}
                        dataSource={tableDataSource}
                        // scroll={getTableScroll()}
                        size={isRoot ? "middle" : "small"}
                        loading={loadData}
                        // tableLayout={"fixed"}
                        style={{ marginBottom: 16 }}
                        onChange={() => setLastActionType("pagination")}
                    />
                </>
            )}
        </>
    );
};

export default Datagrid;
