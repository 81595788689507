import React, { useState, useEffect, useContext } from "react";
// import PropTypes from "prop-types";
import { Col, Table, Button } from "antd";

import { QADivider } from "../qa_components";

import FormContext from "../context/FormContext";

const QaRenderGroup = ({
    objectInputs,
    fields,
    field,
    inputAttribute,
    index,
    renderItems,
    addItem,
    removeItem,
    visible,
}) => {
    const { formData } = useContext(FormContext);

    // Obter o input {[field]: {...}}
    let tableInputs = [];
    if (objectInputs[field]["@inputs_in"] && objectInputs[field]["@inputs_in"] === "root") {
        tableInputs = objectInputs[field];
    } else {
        const _key = Object.keys(objectInputs[field]).filter((it) => !it.startsWith("@") && !it.startsWith("_"))[0];
        tableInputs = objectInputs[field][_key];
    }
    //

    // Obter os inputs
    //
    // Obter keys de todos os inputs
    const tableInputsKeys = Object.keys(tableInputs).filter((it) => !(it.startsWith("@") || it.startsWith("_")));
    //
    //
    //

    return visible ? (
        <>
            {objectInputs[field] && objectInputs[field]["@grupo"] && (
                <Col span={24}>
                    <QADivider text={objectInputs[field]["@grupo"]} />
                </Col>
            )}

            {tableInputsKeys.map((it, idx) => {
                let attributes = tableInputs[it];

                if (
                    (attributes && Object.keys(attributes).length > 0 && attributes["@visivel"] !== "nao") ||
                    attributes?.["@tipo"] === "empty_component"
                ) {
                    return renderItems({
                        arrayFields: [it], // key deste input
                        objectInputs: tableInputs, // todos os inputs da tabela
                        value: "", // _data, // valor do input
                        _nodes: objectInputs[field]._nodes, // somente para esse caso caminho para chegar nos dados os dados
                        //inTable: true, // se este input pertense a uma tabela
                        // self: it, // atributos deste input
                        // brothers: tableInputsKeys, // key dos inputs irmaõs
                        // items: {
                        //     __id: it,
                        //     // dados da linha que esta sendo alterada
                        //     data: _allData, // linha
                        //     table: field, // nome da tabela
                        //     key: _tableKey // key da tabela
                        // }
                    });

                    // dataInput[it] = attributes;
                }
            })}
        </>
    ) : null;
    // });
};

QaRenderGroup.propTypes = {};

export default QaRenderGroup;
