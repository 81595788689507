import React from "react";
import { Form, Row, Col, Input, Button, Select } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/index";
import { domainTypes } from "../../fixed_list/constants/index";
import { toArray } from "../../../components/util";

const { TextArea } = Input;
const { Option } = Select;

function onChange(value) {}

function handleChange(value) {}

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class ProcessForm extends React.Component {
    render() {
        const { getFieldDecorator } = this.props.form;

        const { categories, organicUnits, processosAssociados, processos } = this.props;

        return (
            <Form layout="vertical">
                <Row gutter={18}>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Código do processo">
                            {getFieldDecorator("codigo", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(<Input disabled={this.props.isDisabled} name="codigo" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Nome do processo">
                            {getFieldDecorator("nome", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(<Input disabled={this.props.isDisabled} name="name" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Dono do procecsso">
                            {getFieldDecorator("dono", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(<Input disabled={this.props.isDisabled} name="dono" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Unidade orgânica">
                            {
                                //TODO: change departamento to unidadeOrganica in db, backend and here
                                getFieldDecorator("departamento", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        disabled={this.props.isDisabled}
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {organicUnits?.length > 0 &&
                                            organicUnits.map((domain) => (
                                                <Option value={domain.id}>{domain.descricao}</Option>
                                            ))}
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Categoria">
                            {getFieldDecorator("categoria", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(
                                <Select
                                    showSearch
                                    allowClear
                                    disabled={this.props.isDisabled}
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    name="cateogry"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {categories?.length > 0 &&
                                        categories.map((domain) => (
                                            <Option value={domain.id}>{domain.descricao}</Option>
                                        ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Processos associados">
                            {getFieldDecorator(
                                "processosAssociados",
                                {}
                            )(
                                <Select
                                    mode="multiple"
                                    disabled={this.props.isDisabled}
                                    style={{ width: "100%" }}
                                    allowClear
                                    name="processosAssociados"
                                    onChange={handleChange}
                                >
                                    {processos.map((proc) => (
                                        <Option value={proc.nome}>{proc.nome}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                        <Form.Item label="Âmbito">
                            {getFieldDecorator("ambito", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório",
                                    },
                                ],
                            })(<TextArea autosize={true} name="scope" disabled={this.props.isDisabled} />)}
                        </Form.Item>
                    </Col>
                </Row>
                {this.props.type === "insert" ? (
                    <Row gutter={18}>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                ) : null}
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        formData: state.processDefinition.domain.form,
        domainData: state.dynamicList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actionCreators, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Form.create({
        mapPropsToFields(props) {
            return {
                ambito: Form.createFormField({
                    ...props.formData.ambito,
                    value: props.formData.ambito.value,
                }),
                categoria: Form.createFormField({
                    ...props.formData.categoria,
                    value: props.formData.categoria.value,
                }),
                processosAssociados: Form.createFormField({
                    ...props.formData.processosAssociados,
                    value: props.formData.processosAssociados.value,
                }),
                nome: Form.createFormField({
                    ...props.formData.nome,
                    value: props.formData.nome.value,
                }),
                departamento: Form.createFormField({
                    ...props.formData.departamento,
                    value: props.formData.departamento.value,
                }),
                dono: Form.createFormField({
                    ...props.formData.dono,
                    value: props.formData.dono.value,
                }),
                codigo: Form.createFormField({
                    ...props.formData.codigo,
                    value: props.formData.codigo.value,
                }),
            };
        },
        onFieldsChange(props, fields) {
            props.setField(fields);
            const hasError = hasErrors(props.form.getFieldsError());
            if (hasError) props.handleFormValidation("process", false);
            else props.handleFormValidation("process", true);
        },
    })(ProcessForm)
);
