import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table, Row, Button, Icon, Popconfirm, Popover, Radio } from "antd";
import { componentType } from "../../constants/index";
import ComponentRendererWrapper from "../execution/component.render.wrapper";
import { downFile, getDataSource, mathConversion } from "../../constants/utils";
import { isEmpty, setValueByPopupParams, canCorrectField } from "../../utils/fields";
import { restriction } from "../../utils/input_attributes";
import { Utilitaries } from "../../../../business";

// // rowSelection object indicates the need for row selection
// const rowSelection = {
// 	onChange: (selectedRowKeys, selectedRows) => {},
// 	getCheckboxProps: (record) => ({
// 		disabled: record.name === 'Disabled User', // Column configuration not to be checked
// 		name: record.name,
// 	}),
// };

const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
};

const transformColumns = ({
    itemData,
    form,
    context,
    setLabel,
    type,
    changeAll,
    currentTaskStatus,
    allParams,
    formKey,
    disabled,
}) => {
    let contentSelection = (dataSource, key) => (
        <Radio.Group onChange={(e) => changeAll(e.target.value, key)}>
            {dataSource.map((data) => (
                <Radio style={radioStyle} key={data.key} value={data.key}>
                    {data.value}
                </Radio>
            ))}
        </Radio.Group>
    );

    let columns = [];
    for (const itemProp in itemData) {
        if (typeof itemData[itemProp] === "object" && itemProp !== "dataSource") {
            for (const col in itemData[itemProp]) {
                if (typeof itemData[itemProp][col] === "object") {
                    let optionsSelect = null;

                    if (
                        itemData?.[itemProp]?.[col]?.["@seleccionar_todos"] === "sim" &&
                        !isEmpty(itemData?.[itemProp]?.[col]?.["@valores"])
                    ) {
                        const dataSource = getDataSource(itemData?.[itemProp]?.[col]);

                        optionsSelect = contentSelection(dataSource, itemData?.[itemProp]?.[col]);
                    }

                    const currentItem = setValueByPopupParams({
                        item: itemData?.[itemProp]?.[col],
                        params: allParams,
                        getFromLabel: true,
                    });

                    columns.push({
                        ...currentItem,
                        dataIndex: col,
                        title: (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    minWidth: "80px",
                                }}
                            >
                                {setLabel({
                                    title: currentItem?.["@etiqueta"],
                                    state: context?.state,
                                    itemData: currentItem,
                                })}
                                {currentItem?.["@seleccionar_todos"] === "sim" &&
                                    !isEmpty(currentItem?.["@valores"]) &&
                                    !currentTaskStatus && (
                                        <Popover content={optionsSelect} title="Selecionar" trigger="click">
                                            <Button type="link" icon="edit" />
                                        </Popover>
                                    )}
                                {currentItem?.["@seleccionar_todos"] === "sim" && isEmpty(currentItem?.["@valores"]) && (
                                    <Popconfirm
                                        title="Selecionar todos como:"
                                        onConfirm={() => changeAll(true, currentItem)}
                                        onCancel={() => changeAll(false, currentItem)}
                                        okText="Sim"
                                        cancelText="Não"
                                        disabled={currentTaskStatus}
                                    >
                                        <Icon type="check-square" />
                                    </Popconfirm>
                                )}
                            </div>
                        ),
                    });
                }
            }
        }
    }

    const columnNumber = columns.filter(
        (column) => column["@tipo"] && (!column?.["@visivel"] || column?.["@visivel"] !== "nao")
    ).length;

    if (
        type === componentType._dynamicTable &&
        itemData?.["@botoes_transacao"] !== "sem_botoes" &&
        !disabled
        // &&
        // !currentTaskStatus
    ) {
        columns.push({
            dataIndex: "actions",
            title: "Ação",
            "@tipo": "deleteAction",
            width: "10%",
        });
    }

    const invisibleColumns = columns.filter((column) => column?.["@visivel"] === "nao" && column["@tipo"]);

    let renderizar = true;

    const numbersOfColumnsToRender = Math.abs(columns.length - invisibleColumns.length);

    const _dataSource = Object.values(itemData?.dataSource || {});

    const getRowSpan = ({ index, columnKey, column, record }) => {
        //rowSpan

        if (!column?.["@rowSpan"] || column?.["@rowSpan"] !== "true" || Utilitaries.isEmpty(_dataSource)) {
            return 1;
        }

        const currentItemIndex = _dataSource.findIndex((it) => it.key === record.key);

        if (currentItemIndex < 0) {
            return 1;
        }

        const fromSubItemWithSomeText = [..._dataSource].splice(currentItemIndex);

        let rowSpan = 0;

        if (_dataSource[currentItemIndex - 1]?.[columnKey] === record[columnKey] && index !== 0) {
            rowSpan = 0;
        } else {
            for (const it of fromSubItemWithSomeText) {
                if (it[columnKey] === record[columnKey]) {
                    rowSpan += 1;
                } else {
                    break;
                }
            }
        }

        return rowSpan;

        // return 1;
    };

    columns = columns.map((column) => {
        // --- in case column has other component in it
        let item = column;
        if (column["@tipo"] && (!column?.["@visivel"] || column?.["@visivel"] !== "nao")) {
            let render = {};

            if (renderizar) {
                // item = {
                //     ...column,
                //     // ellipsis: true,
                //     // fixed: true,
                render = (text, record, index) => {
                    const columnKey = column.dataIndex;
                    const value = record[columnKey];
                    const rowSpan = getRowSpan({ index, columnKey, column, record });
                    return {
                        children: (
                            <>
                                <ComponentRendererWrapper
                                    renderContext="column"
                                    context={context}
                                    item={column}
                                    tablePath={itemData?.inputGroupPath}
                                    tableParentCOmponent={itemData}
                                    value={value}
                                    itemKey={itemData.key}
                                    record={record}
                                    form={form}
                                    showFieldEditorModal={columnNumber >= 4}
                                    fixedSize={columnNumber >= 4}
                                    formKey={formKey}
                                    numbersOfColumnsToRender={numbersOfColumnsToRender}
                                />
                                {invisibleColumns.map((column) => {
                                    const columnKey = column.dataIndex;
                                    const value = record[columnKey];
                                    return (
                                        <ComponentRendererWrapper
                                            renderContext="column"
                                            context={context}
                                            item={column}
                                            tablePath={itemData?.inputGroupPath}
                                            value={value}
                                            itemKey={itemData.key}
                                            tableParentCOmponent={itemData}
                                            record={record}
                                            form={form}
                                            showFieldEditorModal={columnNumber >= 4}
                                            fixedSize={columnNumber >= 4}
                                            formKey={formKey}
                                            numbersOfColumnsToRender={numbersOfColumnsToRender}
                                        />
                                    );
                                })}
                            </>
                        ),
                        props: {
                            rowSpan,
                        },
                    };
                    // };
                };
                // };
                renderizar = false;
            } else {
                // item = {
                //     ...column,
                // ellipsis: true,
                // fixed: true,
                render = (text, record, index) => {
                    const columnKey = column.dataIndex;
                    const value = record[columnKey];

                    const rowSpan = getRowSpan({ index, columnKey, column, record });

                    return {
                        children: (
                            <ComponentRendererWrapper
                                renderContext="column"
                                context={context}
                                item={column}
                                tablePath={itemData?.inputGroupPath}
                                value={value}
                                itemKey={itemData.key}
                                tableParentCOmponent={itemData}
                                record={record}
                                form={form}
                                showFieldEditorModal={columnNumber >= 4}
                                fixedSize={columnNumber >= 4}
                                formKey={formKey}
                                numbersOfColumnsToRender={numbersOfColumnsToRender}
                            />
                        ),

                        props: {
                            rowSpan,
                        },
                    };
                };
                // };
            }

            item = { ...column, render };
        }
        return item;
    });
    return columns;
};

const QaTable = ({
    itemData,
    context,
    setLabel,
    currentTaskStatus,
    isRoot,
    form,
    type,
    handleTableAdd,
    setRecordTableAdded,
    changeTableBooleanColumn,
    allParams,
    correctForm,
    formKey,
    loadData,
    tableAddButtonLoad,
}) => {
    // const [buttonAddLoad, setButtonLoad] = useState(false);
    const changeAll = (status, column) => {
        changeTableBooleanColumn({ status, column, table: itemData });
    };

    const canEdit = canCorrectField(itemData) && correctForm;
    const disabled = canEdit ? false : currentTaskStatus;

    const columns = transformColumns({
        itemData,
        form,
        currentTaskStatus,
        context,
        setLabel,
        type,
        allParams,
        changeAll,
        formKey,
        disabled,
    });
    let dataSource = itemData.dataSource;

    if (dataSource) {
        if (!Array.isArray(dataSource)) {
            dataSource = Object.values(dataSource); //.reverse();

            if (dataSource.length > 0 && !Utilitaries.isEmpty(dataSource[0]._key_)) {
                //order by _key_
                dataSource.sort((a, b) => {
                    if (a._key_ > b._key_) return 1;
                    else if (a._key_ < b._key_) return -1;
                    return 0;
                });
            }
        }
    } else {
        dataSource = [];
    }

    let columnsToRender = columns.filter((item) => !item["@visivel"] || item["@visivel"] !== "nao");
    if (columnsToRender.length === 0) return null;

    const { state } = context;
    columnsToRender = columnsToRender.filter((item) => {
        if (item["@relevante"]) {
            const xpath = item["@relevante"];

            for (const record of dataSource) {
                const isToRender = restriction({ item, state, xpath, record });
                if (isToRender) return true;
            }

            return false;
        }

        return true;
    });

    return (
        <>
            {type === componentType._selectionTable && (
                <div>
                    <Table
                        tableLayout="fixed"
                        size={"small"}
                        // size={isRoot ? "middle" : "small"}
                        // dataSource={[]}
                        dataSource={dataSource}
                        pagination={{
                            defaultPageSize: 10,
                            showTotal: (total) => `Total registos: ${total}`,
                            showSizeChanger: true,
                        }}
                        columns={columnsToRender}
                        scroll={{ x: "max-content" }}
                        style={{ marginBottom: 32 }}
                        // loading={itemData?.isLoading}
                        // style={{marginBottom:16}}
                        loading={loadData}
                        // style={{ marginBottom: 16 }}
                    />
                </div>
            )}

            {type === componentType._dynamicTable && (
                <div>
                    <Row>
                        {itemData?.["@botoes_transacao"] !== "sem_botoes" && !itemData?.["@botao_eliminar"] && (
                            <Button
                                icon="plus"
                                type={"primary"}
                                size="default"
                                // disabled={disabled || itemData?.isLoading}~
                                disabled={disabled || loadData}
                                loading={tableAddButtonLoad}
                                // disabled={currentTaskStatus || canCorrectField(itemData)}
                                onClick={() => {
                                    // setButtonLoad(true);
                                    handleTableAdd({
                                        key: itemData.key,
                                        columns: columns
                                            .filter((item) => item["@tipo"] !== "deleteAction")
                                            .map((item) => item.key || item.dataIndex),
                                        schemaColumns: columns,
                                        inputGroupPath: itemData?.inputGroupPath ? [...itemData?.inputGroupPath] : null,
                                    });
                                    setRecordTableAdded({
                                        isAdded: true,
                                        tableKey: itemData.key,
                                        inputGroupPath: itemData?.inputGroupPath ? [...itemData?.inputGroupPath] : null,
                                    });
                                    // setTimeout(() => {
                                    //     setButtonLoad(false);
                                    // }, 1000);
                                }}
                                style={{
                                    float: "right",
                                    marginLeft: 10,
                                    marginBottom: 20,
                                }}
                            >
                                Adicionar
                            </Button>
                        )}
                    </Row>
                    <Table
                        // tableLayout="fixed"
                        pagination={{
                            defaultPageSize: 10,
                            showTotal: (total) => `Total registos: ${total}`,
                            showSizeChanger: true,
                        }}
                        dataSource={dataSource}
                        size={"small"}
                        // size={isRoot ? "middle" : "small"}
                        // loading={itemData?.isLoading}
                        loading={loadData}
                        // scroll={getTableScroll()}
                        scroll={{ x: "max-content" }}
                        columns={columnsToRender}
                        style={{ marginBottom: 16 }}
                    />
                </div>
            )}
        </>
    );
};

QaTable.propTypes = {};

export default QaTable;
