import React, { Component } from "react";
import { Modal, Form, Row, Col, Input, message } from "antd";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/knowledge.type";
const { TextArea } = Input;

class FormKnowledgeType extends Component {
    state = {
        loading: false,
    };

    handleResponse = (response) => {
        this.setState({ loading: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleOk();
        } else message.error("Ocorreu um erro");
    };

    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                if (this.props.isEdit) {
                    values.id_tipo_conhecimento = this.props.formKnowledgeType.id_tipo_conhecimento;
                    this.props.update({ formulario: this.props.formulario, values }).then((response) => {
                        this.handleResponse(response);
                    });
                } else {
                    this.props.insert({ formulario: this.props.formulario, values }).then((response) => {
                        this.handleResponse(response);
                    });
                }
            }
        });
    };

    render() {
        const title = this.props.isEdit ? "Editar tipo de conhecimento" : "Novo tipo de conhecimento";

        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title={title}
                visible={this.props.isVisible}
                onOk={this.handleOk}
                onCancel={this.props.handleCancel}
                width="50%"
                confirmLoading={this.state.loading}
            >
                <Form layout="vertical" onSubmit={this.handleOk}>
                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Designação">
                                {getFieldDecorator("designacao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input name="designacao" />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<TextArea rows={4} name="descricao" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    setField: actionCreators.setField,
    insert: actionCreators.insert,
    update: actionCreators.update,
};

const mapStateToProps = (state, ownProps) => {
    return {
        formKnowledgeType: ownProps.isEdit
            ? state.knowledgeType.domain.formEdit
            : state.knowledgeType.domain.formCreate,
        formulario: state.knowledgeType.domain.metadata.formulario,
    };
};

const formCreate = Form.create({
    mapPropsToFields(props) {
        return {
            designacao: Form.createFormField({
                ...props.formKnowledgeType.designacao,
                value: props.formKnowledgeType.designacao.value,
            }),
            descricao: Form.createFormField({
                ...props.formKnowledgeType.descricao,
                value: props.formKnowledgeType.descricao.value,
            }),
        };
    },
    onFieldsChange(props, fields) {
        props.setField({ isEdit: props.isEdit, fields });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(formCreate(FormKnowledgeType));
