import { actionTypes } from "../constants/index";
import { sendHttpRequest } from "../../../components/http.request";

export const getPolitics = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("query", "busca_politicas");
    return sendHttpRequest("/Gestor/execQuery.php", "POST", formData).then((data) => {
        if (!data) return;
        dispatch({
            type: actionTypes.getPolitics,
            payload: data,
        });
    });
};
