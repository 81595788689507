import React, { useState } from "react";

import { Table, Row, Button } from "antd";

const EditableTable = (props) => {
    const { dataSource, addLine, columns } = props;

    return (
        <>
            <Row>
                <Button
                    xs={24}
                    sm={12}
                    onClick={addLine}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table size="small" scroll={{ x: 500 }} columns={columns} dataSource={dataSource} />
        </>
    );
};

export default EditableTable;
