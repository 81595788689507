import React, { useEffect } from "react";
import G6 from "@antv/g6";
import { DEFAULT_COLORS } from "../../../components/FlowChart/utils/constants";
import { useRef } from "react";
import { Button } from "antd";

G6.registerNode(
    "sql",
    {
        drawShape(cfg, group) {
            const rect = group.addShape("rect", {
                attrs: {
                    x: -75,
                    y: -25,
                    width: 150,
                    height: 50,
                    radius: 10,
                    stroke: "#5B8FF9",
                    fill: "#C6E5FF",
                    lineWidth: 3,
                },
                name: "rect-shape",
            });
            if (cfg.name) {
                group.addShape("text", {
                    attrs: {
                        text: cfg.name,
                        x: 0,
                        y: 0,
                        fill: "#00287E",
                        fontSize: 14,
                        textAlign: "center",
                        textBaseline: "middle",
                        fontWeight: "bold",
                    },
                    name: "text-shape",
                });
            }
            return rect;
        },
    },
    "single-node"
);

G6.registerEdge(
    "line-arrow",
    {
        getPath(points) {
            const startPoint = points[0];
            const endPoint = points[1];
            return [
                ["M", startPoint.x, startPoint.y],
                ["L", endPoint.x / 3 + (2 / 3) * startPoint.x, startPoint.y],
                ["L", endPoint.x / 3 + (2 / 3) * startPoint.x, endPoint.y],
                ["L", endPoint.x, endPoint.y],
            ];
        },
        getShapeStyle(cfg) {
            const startPoint = cfg.startPoint;
            const endPoint = cfg.endPoint;
            const controlPoints = this.getControlPoints(cfg);
            let points = [startPoint]; // the start point
            // the control points
            if (controlPoints) {
                points = points.concat(controlPoints);
            }
            // the end point
            points.push(endPoint);
            const path = this.getPath(points);
            const style = Object.assign(
                {},
                G6.Global.defaultEdge.style,
                {
                    stroke: "#BBB",
                    lineWidth: 1,
                    path,
                },
                cfg.style
            );
            return style;
        },
    },
    "line"
);

const IntegactionGraph = ({ data }) => {
    let graph = useRef();

    useEffect(() => {
        loadGraph();
    }, []);

    useEffect(() => {
        if (data && graph.current) {
            // graph.data(data);
            // graph.render();
            // const graph = loadGraph();
            const newData = Object.assign({}, data);
            // graph.clear();
            graph.current.data({ ...newData });
            graph.current.render();
        }
    }, [data]);

    const loadGraph = () => {
        const container = document.getElementById("divContainerGraph");
        const width = container.scrollWidth;
        const height = container.scrollHeight || 500;

        const toolBarStyle = `position: absolute;
            list-style-type: none;
            padding: 6px;
            right: 0px;
            left: auto;
            top: 0px;
            background-color: rgba(255, 255, 255, 0.9);
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            font-size: 12px;
            color: #545454;
            margin: 0;`;

        const toolBar = new G6.ToolBar({
            getContent: () => {
                return `
                <ul class="g6-flowchart-toolbar" style="${toolBarStyle}">
                    <li code="zoomOut">
                        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                            <path d="M658.432 428.736a33.216 33.216 0 0 1-33.152 33.152H525.824v99.456a33.216 33.216 0 0 1-66.304 0V461.888H360.064a33.152 33.152 0 0 1 0-66.304H459.52V296.128a33.152 33.152 0 0 1 66.304 0V395.52H625.28c18.24 0 33.152 14.848 33.152 33.152z m299.776 521.792a43.328 43.328 0 0 1-60.864-6.912l-189.248-220.992a362.368 362.368 0 0 1-215.36 70.848 364.8 364.8 0 1 1 364.8-364.736 363.072 363.072 0 0 1-86.912 235.968l192.384 224.64a43.392 43.392 0 0 1-4.8 61.184z m-465.536-223.36a298.816 298.816 0 0 0 298.432-298.432 298.816 298.816 0 0 0-298.432-298.432A298.816 298.816 0 0 0 194.24 428.8a298.816 298.816 0 0 0 298.432 298.432z"></path>
                        </svg>
                    </li>
                    <li code="zoomIn">
                        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                            <path d="M639.936 416a32 32 0 0 1-32 32h-256a32 32 0 0 1 0-64h256a32 32 0 0 1 32 32z m289.28 503.552a41.792 41.792 0 0 1-58.752-6.656l-182.656-213.248A349.76 349.76 0 0 1 480 768 352 352 0 1 1 832 416a350.4 350.4 0 0 1-83.84 227.712l185.664 216.768a41.856 41.856 0 0 1-4.608 59.072zM479.936 704c158.784 0 288-129.216 288-288S638.72 128 479.936 128a288.32 288.32 0 0 0-288 288c0 158.784 129.216 288 288 288z" p-id="3853"></path>
                        </svg>
                    </li>
                    <li code="realZoom">
                        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="24">
                            <path d="M384 320v384H320V320h64z m256 0v384H576V320h64zM512 576v64H448V576h64z m0-192v64H448V384h64z m355.968 576H92.032A28.16 28.16 0 0 1 64 931.968V28.032C64 12.608 76.608 0 95.168 0h610.368L896 192v739.968a28.16 28.16 0 0 1-28.032 28.032zM704 64v128h128l-128-128z m128 192h-190.464V64H128v832h704V256z"></path>
                        </svg>
                    </li>
                    <li code="autoZoom">
                        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="24">
                            <path d="M684.288 305.28l0.128-0.64-0.128-0.64V99.712c0-19.84 15.552-35.904 34.496-35.712a35.072 35.072 0 0 1 34.56 35.776v171.008h170.944c19.648 0 35.84 15.488 35.712 34.432a35.072 35.072 0 0 1-35.84 34.496h-204.16l-0.64-0.128a32.768 32.768 0 0 1-20.864-7.552c-1.344-1.024-2.816-1.664-3.968-2.816-0.384-0.32-0.512-0.768-0.832-1.088a33.472 33.472 0 0 1-9.408-22.848zM305.28 64a35.072 35.072 0 0 0-34.56 35.776v171.008H99.776A35.072 35.072 0 0 0 64 305.216c0 18.944 15.872 34.496 35.84 34.496h204.16l0.64-0.128a32.896 32.896 0 0 0 20.864-7.552c1.344-1.024 2.816-1.664 3.904-2.816 0.384-0.32 0.512-0.768 0.768-1.088a33.024 33.024 0 0 0 9.536-22.848l-0.128-0.64 0.128-0.704V99.712A35.008 35.008 0 0 0 305.216 64z m618.944 620.288h-204.16l-0.64 0.128-0.512-0.128c-7.808 0-14.72 3.2-20.48 7.68-1.28 1.024-2.752 1.664-3.84 2.752-0.384 0.32-0.512 0.768-0.832 1.088a33.664 33.664 0 0 0-9.408 22.912l0.128 0.64-0.128 0.704v204.288c0 19.712 15.552 35.904 34.496 35.712a35.072 35.072 0 0 0 34.56-35.776V753.28h170.944c19.648 0 35.84-15.488 35.712-34.432a35.072 35.072 0 0 0-35.84-34.496z m-593.92 11.52c-0.256-0.32-0.384-0.768-0.768-1.088-1.088-1.088-2.56-1.728-3.84-2.688a33.088 33.088 0 0 0-20.48-7.68l-0.512 0.064-0.64-0.128H99.84a35.072 35.072 0 0 0-35.84 34.496 35.072 35.072 0 0 0 35.712 34.432H270.72v171.008c0 19.84 15.552 35.84 34.56 35.776a35.008 35.008 0 0 0 34.432-35.712V720l-0.128-0.64 0.128-0.704a33.344 33.344 0 0 0-9.472-22.848zM512 374.144a137.92 137.92 0 1 0 0.128 275.84A137.92 137.92 0 0 0 512 374.08z"></path>
                        </svg>
                    </li>
                </ul>
                `;
            },
        });

        graph.current = new G6.Graph({
            container: "divContainerGraph",
            width,
            height,
            fitView: true,
            fitCenter: true,
            fitViewPadding: 45,
            controlPoints: false,
            plugins: [toolBar],
            minZoom: 0.1,
            maxZoom: 6,
            animate: true,
            layout: {
                type: "dagre",
                // nodeSize: [40, 20],
                nodesep: 20,
                ranksep: 35,
                rankdir: "LR",
                align: "DL",
            },
            defaultNode: {
                size: [40, 20],
                type: "rect",
                anchorPoints: [
                    [1, 0.25], // right
                    [1, 0.75], // right
                    [0, 0.25], // left
                    [0, 0.75], // left
                ],
                style: {
                    lineWidth: 2,
                    // stroke: DEFAULT_COLORS.nodeStroke,
                    stroke: DEFAULT_COLORS.nodeFill,
                    fill: DEFAULT_COLORS.nodeFill,
                },
            },
            defaultEdge: {
                size: 2,
                color: "#e2e2e2",
                // color: DEFAULT_COLORS.nodeFill,
                style: {
                    endArrow: {
                        path: "M 0,0 L 8,4 L 8,-4 Z",
                        fill: "#e2e2e2",
                        lineWidth: 2,
                        // fill: DEFAULT_COLORS.nodeFill,
                    },
                },
            },
            // layout: {
            //     // type: "dagre",
            //     // rankdir: "LR",
            //     // align: "DL",
            //     // nodesepFunc: () => 1,
            //     // ranksepFunc: () => 1,
            //     // ------------------------
            //     type: "dagre",
            //     rankdir: "",
            //     align: "UL", // "DL", //UL
            //     // controlPoints: true,
            //     nodesepFunc: () => 10,
            //     ranksepFunc: () => 10,
            //     // ---------------------------
            //     // type: "dagre",
            //     // rankdir: "LR", //'TB' | 'BT' | 'LR' | 'RL'
            //     // align: "DL", //  'UL' | 'UR' | 'DL' | 'DR' |
            //     // nodesep: 30,
            //     // ranksep: 30,
            //     // controlPoints: true,
            // },
            modes: {
                // behavior
                // default: ["drag-node"],
                default: ["zoom-canvas", "drag-canvas", "drag-node"],
            },
            // defaultNode: {
            //     // type: "sql",
            //     size: [120, 30],
            //     type: "rect",
            //     anchorPoints: [
            //         [1, 0.5], // right
            //         [0, 0.5], // left
            //         [0.25, 0], // top 25%
            //         [0.5, 0], // top
            //         [0.75, 0], // top 75%
            //         [0.5, 1], // bottom
            //         [0.25, 1], // bottom 25%
            //         [0.75, 1], //bottom 75%
            //     ],
            //     style: {
            //         lineWidth: 2,
            //         // stroke: DEFAULT_COLORS.nodeStroke,
            //         stroke: DEFAULT_COLORS.nodeFill,
            //         fill: DEFAULT_COLORS.nodeFill,
            //         fontSize: 12,
            //     },
            // },
            // defaultEdge: {
            //     // type: "line",
            //     // type: "polyline",
            //     style: {
            //         // radius: 0,
            //         // offset: 0,
            //         endArrow: true,
            //         lineWidth: 2,
            //         /* and other styles */
            //         // stroke: '#F6BD16',
            //     },
            //     //--------------------
            //     // style: {
            //     // radius: 20,
            //     // offset: 45,
            //     // endArrow: true,
            //     //     lineWidth: 2,
            //     //     stroke: "#C2C8D5",
            //     // },
            //     // type: "polyline",
            //     // size: 2,
            //     // color: DEFAULT_COLORS.arrowColor,

            //     // style: {
            //     //     endArrow: {
            //     //         path: "M 0,0 L 8,4 L 8,-4 Z",
            //     //         fill: DEFAULT_COLORS.endArrowFill,
            //     //     },
            //     //     radius: 2,
            //     // },
            // },
        });

        // graph.data(data);
        // graph.render();

        graph.current.on("edge:mouseenter", (evt) => {
            const { item } = evt;
            graph.current.setItemState(item, "active", true);
        });

        graph.current.on("edge:mouseleave", (evt) => {
            const { item } = evt;
            graph.current.setItemState(item, "active", false);
        });

        graph.current.on("edge:click", (evt) => {
            const { item } = evt;
            graph.current.setItemState(item, "selected", true);
        });
        graph.current.on("canvas:click", (evt) => {
            graph.current.getEdges().forEach((edge) => {
                graph.current.clearItemStates(edge);
            });
        });
        if (typeof window !== "undefined")
            window.onresize = () => {
                if (!graph.current || graph.current.get("destroyed")) return;
                if (!container || !container.scrollWidth || !container.scrollHeight) return;
                graph.current.changeSize(container.scrollWidth, container.scrollHeight);
            };

        return graph;
    };

    return (
        <>
            {/* {" "}
            <Button
                onClick={() => {
                    // graph.current.downloadFullImage("tree-graph", {
                    //     backgroundColor: "#ddd",
                    //     padding: [30, 15, 15, 15],
                    // });
                    graph.current.downloadImage("image", "image/jpeg", "#c4c5c6");
                    // const dataURL = graph.current.toDataURL();
                    // alert(dataURL);
                }}
            >
                Baixar
            </Button> */}
            <div className="graph-container" id="divContainerGraph" style={{ overflow: "auto" }} />{" "}
        </>
    );
};

export default IntegactionGraph;

/**
 * Adcionar tooltip ao passar rato sobre item
 * no data
 *  conf: [
                {
                    label: "conf",
                    value: "pai_graph.conf",
                },
                {
                    label: "dot",
                    value: "pai_graph.dot",
                },
                {
                    label: "init",
                    value: "init.rc",
                },
            ],

add to grafp
modes: {
                default: [
                    "drag-canvas",
                    "zoom-canvas",
                    "click-select",
                    {
                        type: "tooltip",
                        formatText(model) {
                            const cfg = model.conf;
                            const text = [];
                            cfg.forEach((row) => {
                                text.push(row.label + ":" + row.value + "<br>");
                            });
                            return text.join("\n");
                        },
                        offset: 30,
                    },
                ],
            },
 */
