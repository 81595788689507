import { AutoComplete, Form } from "antd";
import React from "react";
import { canCorrectField } from "../../utils/fields";

const dateStyles = {
    width: "100%",
};

const QaAutoComplete = ({
    itemData,
    dataSource,
    label,
    root,
    rowKey,
    columnDataSource,
    formItemStyle,
    currentTaskStatus,
    type,
    readonly,
    handleSelectChange,
    error,
    onChange,
    correctForm,
}) => {
    let key = itemData["@chave_lista"] || "key";
    let value = itemData["@valor_lista"] || "value";

    const _data = dataSource && dataSource.length === 0 ? (columnDataSource ? columnDataSource : []) : dataSource;

    if (_data && Array.isArray(_data) && _data.length > 0) {
        if (!_data[0][value]) {
            value = "designacao";
        }

        if (!_data[0][key]) {
            key = itemData.key;
        }
    }

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus || readonly;

    return (
        <Form.Item label={label} style={formItemStyle}>
            <AutoComplete
                // disabled={disabled}
                disabled={disabled}
                // disabled={currentTaskStatus || readonly || canCorrectField(itemData)}
                placeholder={null}
                dataSource={_data
                    .filter((it) => it[key])
                    .map((it) => (
                        <AutoComplete.Option key={it[key]} value={it[key]} title={it[value]}>
                            {it[value]}
                        </AutoComplete.Option>
                    ))}
                dropdownMatchSelectWidth={false}
                value={itemData.value !== undefined ? String(itemData.value) : itemData.value}
                filterOption={(inputValue, option) =>
                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={(selectValue) => {
                    const description = _data.find((it) => it[key] === selectValue);
                    onChange(selectValue, { selectValueText: description?.[value] });
                }}
            />
            {error && <span style={{ color: "red" }}>{error}</span>}
        </Form.Item>
    );
};

export default QaAutoComplete;
