import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Table, Col, Form, Input, Upload, Popconfirm, message } from "antd";
import moment from "moment";
import { getClientDocuments } from "../actions";
import { QaDynamicSelect } from "../../../components/DynamicList";
import ButtonDownloadDocument from "../../utilities/component/button.download.document";
import { Utilitaries } from "../../../business";
import { api, sendHttpRequest } from "../../../components/http.request";

const ClientDocuments = ({ client }) => {
    const [loadingData, setLoadingData] = useState(false);
    const [savingData, setSavingData] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [isVisibleModal, setIsVisibleModal] = useState(false);

    useEffect(() => {
        if (isVisibleModal && dataSource.length === 0) {
            setLoadingData(true);

            getClientDocuments({ client }).then((data) => {
                setDataSource(Utilitaries.toArray(data));
                setLoadingData(false);
            });
        } else {
            setDataSource([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, isVisibleModal]);

    const handleFieldsChange = (dataIndex, value, record) => {
        const newData = [...dataSource];
        record[dataIndex] = value;
        const index = newData.findIndex((item) => item.key === record.key);
        newData[index] = record;
        setDataSource(newData);
    };

    const onRemove = (dataIndex, record) => {
        delete record[dataIndex];
        const index = dataSource.findIndex((item) => item.key === record.key);
        dataSource[index] = record;
        setDataSource(dataSource);
    };

    const uploadFileToServer = async (file, record) => {
        const data = {
            accao: "upload",
            nome: file.name,
            descricao: file.type,
            ficheiro: file,
            chave_sessao: "teste",
        };

        try {
            const formData = new FormData();
            Object.entries(data).forEach(([key, val]) => {
                formData.append(key, val);
            });
            const response = await sendHttpRequest("POST", "/Gestor/gereficheiros.php", formData);
            // const response = await postDataToGereFicheiros(data);
            if (response.sucesso && response.sucesso !== "0") {
                handleFieldsChange("documento", response.sucesso, record);
            } else {
                message.error("Erro ao gravar ficheiro");
            }
        } catch (error) {
            message.error("Ocorreu um erro");
        }
    };

    const handleAdd = () => {
        const newData = {
            key: dataSource.length + 1,
        };
        setDataSource([...dataSource, newData]);
    };

    const handleDelete = (key) => {
        setDataSource(dataSource.filter((item) => item.key !== key));
    };

    const columns = [
        {
            title: "Tipo de documento",
            dataIndex: "tipo_documento",
            width: "30%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Col>
                        <QaDynamicSelect
                            onChange={(value) => handleFieldsChange("tipo_documento", value, record)}
                            defaultValue={text}
                            name="tipo_documento"
                        />
                    </Col>
                );
            },
        },
        {
            title: "Número",
            dataIndex: "numero",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" ? text : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            placeholder="Introduza o número"
                            onChange={(e) => handleFieldsChange("numero", e.target.value, record)}
                            defaultValue={text}
                            type="number"
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data emissão",
            dataIndex: "data_emissao",
            width: "15%",
            render: (text, record) => {
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(date) =>
                                handleFieldsChange(
                                    "data_emissao",
                                    date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    record
                                )
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : null}
                            format={"YYYY-MM-DD"}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Data validade",
            dataIndex: "data_validade",
            width: "15%",
            render: (text, record) => {
                text = typeof text !== "object" && text !== "" && text ? moment(text, "YYYY-MM-DD") : null;
                return (
                    <Form.Item
                        style={{
                            width: "100%",
                            margin: "0 auto",
                            padding: "0",
                        }}
                    >
                        <Input
                            type="date"
                            onChange={(date) =>
                                handleFieldsChange(
                                    "data_validade",
                                    date.target.value ? moment(date.target.value).format("YYYY-MM-DD") : "",
                                    record
                                )
                            }
                            value={text ? moment(text).format("YYYY-MM-DD") : null}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: "Documento",
            dataIndex: "documento",
            align: "center",
            width: "15%",
            render: (text, record) => {
                if (record["documento"] && Object.entries(record["documento"]).length !== 0) {
                    return <ButtonDownloadDocument ficheiro={record["documento"]} text="Descarregar" />;
                } else {
                    return (
                        <Upload
                            openFileDialogOnClick
                            defaultFileList={typeof text !== "object" && text ? [text] : []}
                            beforeUpload={(file) => {
                                uploadFileToServer(file, record);
                                return false;
                            }}
                            name="file"
                            multiple={false}
                            onRemove={() => onRemove("documento", record)}
                        >
                            <Button type="primary" icon="file-add" />
                        </Upload>
                    );
                }
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="Tens certeza que desejas eliminar?" onConfirm={() => handleDelete(record.key)}>
                        <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                            Eliminar
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    const handleSave = async () => {
        try {
            // validade
            // if (dataSource.length === 0) {
            //     message.error("Não existem linhas para gravar!!");
            //     return;
            // }

            if (dataSource.length > 0) {
                let hasNullFields = false;
                dataSource.forEach((item, index) => {
                    if (Utilitaries.isEmpty(item.tipo_documento)) {
                        hasNullFields = true;
                        message.info(`Tipo de documento é obrigatório, linha ${item.key}.`);
                    }
                    if (Utilitaries.isEmpty(item.documento)) {
                        hasNullFields = true;
                        message.info(`Documento é obrigatório, linha ${item.key},.`);
                    }
                });

                if (hasNullFields) {
                    return;
                }
            }

            setSavingData(true);

            const fd = new FormData();
            fd.append("accao", "save-client-documents");
            fd.append("dados", JSON.stringify({ client, documents: dataSource }));

            const result = await api("/Gestor/geredossiercliente.php", fd, "POST");

            if (result.sucesso) {
                message.success("Documentos do cliente gravado com sucesso");
            } else if (result.erro) {
                message.error(result.erro);
            } else message.error("Ocorreu um erro");
        } catch (error) {
            message.error("Ocorreu um erro");
        } finally {
            setSavingData(false);
        }
    };

    return (
        <>
            <Button
                onClick={() => setIsVisibleModal(true)}
                type="ghost"
                icon="file-text"
                size="default"
                style={{
                    marginBottom: 16,
                    marginLeft: 8,
                    float: "left",
                }}
            >
                Documentos do cliente
            </Button>

            <Modal
                title={"Documentos do cliente"}
                width="90%"
                style={{ top: 20 }}
                visible={isVisibleModal}
                onCancel={() => setIsVisibleModal(false)}
                footer={[
                    <Button key="back" onClick={() => setIsVisibleModal(false)}>
                        Cancelar
                    </Button>,
                    <Button loading={savingData} key="save" type="primary" onClick={() => handleSave()}>
                        Guardar
                    </Button>,
                ]}
            >
                <Row>
                    <Button
                        xs={24}
                        sm={12}
                        onClick={handleAdd}
                        icon="plus"
                        size="default"
                        type="primary"
                        style={{ marginBottom: 16, float: "right" }}
                    >
                        Adicionar
                    </Button>
                </Row>
                <Table
                    size="small"
                    scroll={{ x: 500 }}
                    loading={loadingData}
                    columns={columns}
                    dataSource={dataSource}
                />
            </Modal>
        </>
    );
};

export { ClientDocuments };
