import { Utilitaries } from "../../../business";
import { toArray } from "../../util";
import { DEFAULT_COLORS } from "./constants";

export const getBorderColor = ({ state, taskId }) => {
    if (state === "1") {
        return DEFAULT_COLORS.TASKS_GOING;
    } else if (state === "0") {
        return DEFAULT_COLORS.TASKS_UNASSIGNED;
    } else if (state === "-1") {
        return DEFAULT_COLORS.TASKS_CANCELLED;
    } else if (state === "2") {
        return DEFAULT_COLORS.TASKS_FINISHED;
    } else if (!state && !taskId) {
        return DEFAULT_COLORS.nodeFill;
    }

    return DEFAULT_COLORS.nodeStroke;
};

export const defineNodeType = (type) => {
    if (type === "tarefa") {
        return "rect";
    } else if (type === "inicio" || type === "fim") {
        return "ellipse";
    } else if (type === "decisao") {
        return "diamond";
    } else if (type === "fork" || type === "join") {
        // return "triangle";
        return "rect";
    } else if (type === "processo") {
        // return "modelRect";
        // return "hexagon";
        return "rect-xml";
        // return "rect-jsx";
    }
};

export const defaultProps = (node) => {
    const { tipo: type, width } = node;
    const borderColor = getBorderColor({ state: node?.["estado"], taskId: node?.["id_tarefa"] });

    switch (type) {
        case "fork":
        case "join":
            return {
                label: "",
                size: [Utilitaries.toNumber(width), 10],
                style: {
                    fill: DEFAULT_COLORS.arrowColor,
                    stroke: DEFAULT_COLORS.arrowColor,
                },
            };
        default:
            const nodeAttrs = node?.["estado"] ? node : null;
            return {
                style: {
                    fill: DEFAULT_COLORS.nodeFill,
                    stroke: borderColor,
                },
                nodeAttrs,
            };
    }
};
