import React, { Component } from "react";
import { Tabs, PageHeader, message, Row, Col, Table, Form, Input, Select, Skeleton } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "./../../actions/holiday";
import QaDynamicSelect from "../../../../components/DynamicList/dynamicSelect";
import { selectEstabeleciment } from "../../../fixed_list/actions/index";
import ListCorrection from "./../../../correction/components/list.correction";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

class DetailsHoliday extends Component {
    componentDidMount() {
        this.getDetails();

        if (this.props.estabelecimentos === undefined) {
            this.setState({ estabelecimentoLoading: true });
            this.props
                .selectEstabeleciment({
                    accao: "lista",
                    lista: "lista_estabelecimentos",
                    id_instituicao: "1",
                })
                .then((sucess) => {
                    if (!sucess) message.error("Ocorreu um erro");
                    this.setState({ estabelecimentoLoading: false });
                });
        } else {
            this.setState({ estabelecimentoLoading: false });
        }
    }

    getDetails() {
        this.setState({ loadingData: true });
        this.props.getDetails({ id: this.props.match.params.id }).then(() => {
            this.setState({
                loadingData: false,
            });
        });
    }

    state = {
        loadingData: false,
        estabelecimentoLoading: true,
    };

    goto = (path) => {
        this.props.history.push({
            pathname: path,
        });
    };

    columns = [
        {
            title: "Dia",
            dataIndex: "data",
            width: "20%",
            render: (text) => <Input ref={(node) => (this.inputs = node)} type="date" value={text} disabled />,
        },
        {
            title: "Designação",
            dataIndex: "designacao",
            render: (text) => <Input ref={(node) => (this.input = node)} value={text} disabled />,
        },
        {
            title: "Natureza",
            dataIndex: "natureza",
            width: "20%",
            render: (text, record) => (
                <QaDynamicSelect
                    defaultValue={text}
                    onChange={(event) => {
                        return this.save("natureza", event, record);
                    }}
                    name="natureza_feriado"
                    disabled
                />
            ),
        },
        {
            title: "Estabelecimento",
            dataIndex: "estabelecimentos",
            width: "32%",
            render: (text) => {
                return (
                    <Select mode="multiple" style={{ width: "100%" }} defaultValue={text} disabled>
                        {!this.state.estabelecimentoLoading &&
                            this.props.listEstabelecimentos.data.map((item) => (
                                <Option key={item.estabelecimento} value={item.estabelecimento}>
                                    {item.nome}
                                </Option>
                            ))}
                    </Select>
                );
            },
        },
    ];

    render() {
        return (
            <>
                <PageHeader
                    onBack={() => {
                        this.props.history.push({
                            pathname: "/function_manual/holiday",
                        });
                    }}
                    title="Feriados"
                />
                <Tabs
                    onChange={(activeKey) => {
                        if (activeKey === "1") {
                            this.getDetails();
                        }
                    }}
                    defaultActiveKey="1"
                >
                    <TabPane tab="Detalhes" key="1">
                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={5}>
                                    <Skeleton
                                        paragraph={{ rows: 1, width: "100%" }}
                                        active
                                        loading={this.state.loadingData}
                                    >
                                        <Form.Item label="Ano civil">
                                            <Input disabled value={this.props.formHoliday.year.value} />
                                        </Form.Item>
                                    </Skeleton>
                                </Col>
                                <Col xs={24} sm={24} md={11}>
                                    <Skeleton
                                        paragraph={{ rows: 1, width: "100%" }}
                                        active
                                        loading={this.state.loadingData}
                                    >
                                        <Form.Item label="Descrição">
                                            <TextArea
                                                autoSize={{
                                                    minRows: 1,
                                                    maxRows: 6,
                                                }}
                                                value={this.props.formHoliday.descricao.value}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Skeleton>
                                </Col>
                            </Row>
                            <Table
                                rowKey={(record) => record.key}
                                rowClassName={() => "editable-row"}
                                dataSource={this.state.loadingData ? [] : this.props.list}
                                columns={this.columns}
                                size="small"
                                loading={this.state.loadingData}
                                scroll={{ y: 300 }}
                            />
                        </Form>
                    </TabPane>
                    <TabPane tab="Correções" key="2">
                        <ListCorrection id={this.props.match.params.id} formName="config_holidays" />
                    </TabPane>
                </Tabs>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getDetails: actionCreators.getDetails,
            selectEstabeleciment,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        formHoliday: state.holiday.domain.formEdit,
        list: state.holiday.domain.formEdit.holidays,
        listEstabelecimentos: state.fixedList.domain.byId.estabelecimento,
        natureza: state.dynamicList.domain.byId.natureza_feriado,
    };
};
const formCreate = Form.create();

export default connect(mapStateToProps, mapDispatchToProps)(formCreate(DetailsHoliday));
