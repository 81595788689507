import React from "react";
import { Row, Tooltip, Typography, Icon } from "antd";

import { sendSimpleHttpRequest } from "./http.request";
import { Session } from "../business";
import { baseUrl } from "../constants/enviroment";
// import { sendSimpleHttpRequest } from "../../../components/http.request";

const { Text } = Typography;

/**
 *
 * @param {String} input window.location.search
 */
export const queryToString = (input = window.location.search) => {
    if (!input) {
        const params = window.location.hash;
        input = params.split("?")[1];
    }
    // remove ? # & in first charecter
    input = input?.trim().replace(/^[?#&]/, "");

    // create an object with no prototype
    const object = Object.create(null);

    if (!input) {
        return object;
    }

    for (const param of input.split("&")) {
        // search for + and replace with space
        // and get key and value
        let [key, value] = param.replace(/\+/g, "").split("=");

        object[key] = value;
    }

    return object;
};

/** converte string to array of javaScript object */
export const convertDataComeAsStringFromTheServerToArrayOfObject = (data) => {
    let transformedData = [];

    if (typeof data === "string") {
        const splitedData = data.split("�");
        let [fist, second, ...restSplitedData] = splitedData;
        restSplitedData.forEach((item, index) => {
            if (index % 2 === 0 && restSplitedData[index + 1] !== undefined) {
                transformedData.push({
                    [fist]: item.trim(),
                    [second]: restSplitedData[index + 1],
                });
            }
        });
    } else {
        transformedData = data;
    }
    return transformedData;
};

/** render tooltips, receive description and label via props */
export const labelTooltip = (props) => (
    <span>
        {props.label}&nbsp;
        <Tooltip title={props.description}>
            <Icon type="question-circle-o" />
        </Tooltip>
    </span>
);

/** convert response object */
export const arrayToObjectById = ({ array, id }) =>
    array.reduce((obj, item) => {
        if (id) obj[item[id]] = item;
        else if (item.nome) obj[item.nome] = item;
        return obj;
    }, {});

/** sort table value */
export const tableSort = (a, b) => {
    a = typeof a !== "object" ? a.toLowerCase() : "";
    b = typeof b !== "object" ? b.toLowerCase() : "";
    if (a === "") return 1;
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
};

export const tableSortNumber = (a, b) => {
    return Number(a) - Number(b);
};

export const tableSortData = (a, b) => {
    if (!a.isValid()) return -1;
    if (a.isAfter(b, "day")) return 1;
    return a.isSame(b, "day") ? 0 : -1;
};

export const tableFooter = (total) => (
    <Row>
        <span style={{ float: "right" }}>Total registos: {total ? total : 0}</span>
    </Row>
);

export const validateResponse = (response, keyData = "linha") => {
    let data = [];
    if (response[keyData] !== undefined) {
        if (Array.isArray(response[keyData])) data = response[keyData];
        else {
            const responseData = response[keyData];
            if (Object.keys(responseData).length > 0) {
                data = [responseData];
            }
        }
    }
    return data;
};

export const downloadFile = async (url, body = {}) => {
    if (body.downloadInBrowser) {
        const { downloadInBrowser, ...rest } = body;

        const params = Object.entries(rest).reduce((accumulator, current) => {
            accumulator = `${accumulator}${accumulator.length === 0 ? "" : "&"}${current[0]}=${current[1]}`;
            return accumulator;
        }, "");

        console.log(
            "url",
            `${baseUrl}/Gestor/${url}?${params}&IMPRIMIR_BRANCO=false&token=Bearer ${Session.getToken()}`
        );

        window.open(
            `${baseUrl}/Gestor/${url}?${params}&IMPRIMIR_BRANCO=false&token=Bearer ${Session.getToken()}`,
            "_blank"
        );
        return;
    }

    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return sendSimpleHttpRequest("POST", "/Gestor" + url, formData)
        .then((response) => {
            var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(response.headers.get("content-disposition"));

            const filename = matches != null && matches[1] ? matches[1].replace(/['"]/g, "") : null;

            if (!filename) throw new Error();

            // get file
            return response.blob().then((response) => {
                return {
                    filename,
                    file: response,
                };
            });
        })
        .then((response) => {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(response.file);
            link.download = response.filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return true;
        })
        .catch((error) => {
            return false;
        });
};

export const downloadFileV2 = async (controller, body = {}) => {
    try {
        const formData = new FormData();
        Object.entries(body).map(([key, value]) => {
            formData.append(key, value);
        });

        var request = new XMLHttpRequest();
        request.open("POST", `${baseUrl}/Gestor${controller}`, true);

        request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
        request.responseType = "blob";

        request.onload = function () {
            // Only handle status code 200
            if (request.status === 200) {
                // Try to find out the filename from the content disposition `filename` value
                var disposition = request.getResponseHeader("content-disposition");
                var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);

                var filename = matches != null && matches[1] ? matches[1] : "qa_file";

                // The actual download
                var blob = new Blob([request.response], {
                    type: request.getResponseHeader("content-type"),
                });

                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
            }

            // some error handling should be done here...
        };

        request.send(formData);
    } catch (error) {}
};

export const downFileFrontend = (content, fileName, contentType = "text/plain") => {
    const a = document.createElement("a");

    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;

    a.click();
};

export const toArray = (data) => {
    if (data && typeof data === "object") {
        if (!Array.isArray(data)) {
            if (Object.keys(data).length === 0) {
                return [];
            }

            return [data];
        }

        return data;
    }

    return [];
};

export const canRenderMenu = (identity, menus = []) => {
    if (!menus || menus.length === 0) {
        return false;
    }

    const item = menus.find(
        (it) => it.webaccao === identity || it.titulo === identity || it.identificador === identity
    );

    if (item) {
        return true;
    }
    return false;
};

/**
 * JS Implementation of MurmurHash3 (r136) (as of May 20, 2011)
 *
 * @author <a href="mailto:gary.court@gmail.com">Gary Court</a>
 * @see http://github.com/garycourt/murmurhash-js
 * @author <a href="mailto:aappleby@gmail.com">Austin Appleby</a>
 * @see http://sites.google.com/site/murmurhash/
 *
 * @param {string} key ASCII only
 * @param {number} seed Positive integer only
 * @return {number} 32-bit positive integer hash
 */

export const murmurhash3_32_gc = (key, seed) => {
    var remainder, bytes, h1, h1b, c1, c1b, c2, c2b, k1, i;

    remainder = key.length & 3; // key.length % 4
    bytes = key.length - remainder;
    h1 = seed;
    c1 = 0xcc9e2d51;
    c2 = 0x1b873593;
    i = 0;

    while (i < bytes) {
        k1 =
            (key.charCodeAt(i) & 0xff) |
            ((key.charCodeAt(++i) & 0xff) << 8) |
            ((key.charCodeAt(++i) & 0xff) << 16) |
            ((key.charCodeAt(++i) & 0xff) << 24);
        ++i;

        k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
        k1 = (k1 << 15) | (k1 >>> 17);
        k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;

        h1 ^= k1;
        h1 = (h1 << 13) | (h1 >>> 19);
        h1b = ((h1 & 0xffff) * 5 + ((((h1 >>> 16) * 5) & 0xffff) << 16)) & 0xffffffff;
        h1 = (h1b & 0xffff) + 0x6b64 + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16);
    }

    k1 = 0;

    switch (remainder) {
        case 3:
            k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16;
        case 2:
            k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8;
        case 1:
            k1 ^= key.charCodeAt(i) & 0xff;

            k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
            k1 = (k1 << 15) | (k1 >>> 17);
            k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;
            h1 ^= k1;
    }

    h1 ^= key.length;

    h1 ^= h1 >>> 16;
    h1 = ((h1 & 0xffff) * 0x85ebca6b + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff;
    h1 ^= h1 >>> 13;
    h1 = ((h1 & 0xffff) * 0xc2b2ae35 + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16)) & 0xffffffff;
    h1 ^= h1 >>> 16;

    return h1 >>> 0;
};

export const getuserComputerInfo = () => {
    const BROWSER_NAMES = [
        {
            rgx: /(chrome|omniweb|arora|[tizenoka]{5}\s?browser)\/v?([\w\.]+)/i,
            name: "Chrome",
        },
        {
            rgx: /(firefox|seamonkey|k-meleon|icecat|iceape|firebird|phoenix)\/([\w\.-]+)/i,
            name: "Firefox",
        },
        {
            rgx: /(mozilla)\/([\w\.]+).+rv\:.+gecko\/\d+/i,
            name: "Firefox",
        },
        {
            rgx: /(edge)\/((\d+)?[\w\.]+)/i,
            name: "Microsoft Edge",
        },
    ];

    const OD_NAMES = [
        {
            rgx: /microsoft\s(windows)\s(vista|xp)/i,
            name: "Windows",
        },
        {
            rgx: /(windows)\snt\s6\.2;\s(arm)/i,
            name: "Windows",
        },
        {
            rgx: /(joli|[kxln]?ubuntu|debian|[open]*suse|gentoo|(?=\s)arch|slackware|fedora|mandriva|centos|pclinuxos|redhat|zenwalk|linpus)[\/\s-]?([\w\.-]+)*/i,
            name: "Linux",
        },
        {
            rgx: /(hurd|linux)\s?([\w\.]+)*/i,
            name: "Linux",
        },
    ];

    let browser = BROWSER_NAMES.find((it) => it.rgx.test(window.navigator.userAgent));
    let plataform = OD_NAMES.find((it) => it.rgx.test(window.navigator.platform));

    const width = window.screen.width;
    const height = window.screen.height;
    browser = browser ? browser.name : "Navegador";
    plataform = plataform ? plataform.name : window.navigator.platform;
    const computer_uuid = murmurhash3_32_gc(`${width}${height}${plataform}`, 256);

    return {
        width,
        height,
        browser,
        plataform,
        computer_uuid,
    };
};
