import { actionType } from "../constants/funcional.group";
import { sendHttpRequest } from "../../../components/http.request";
import { validateResponse } from "./../../../components/util";

export const selectDataFromExecQuery = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, val]) => {
        formData.append(key, val);
    });
    return await sendHttpRequest("POST", "/Gestor/execQuery.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};

export const insertFunctionTasks = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse.sucesso !== undefined ? jsonResponse.sucesso : false;
    });
};

export const selectTasksByAutorizationType = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/execQuery.php", formData).then((jsonResponse) => {
        dispatch({
            type: actionType.selectTasksSIByAutorizathion,
            payload: jsonResponse.result,
        });
    });
};

export const insert = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) =>
        jsonResponse.sucesso !== undefined ? true : false
    );
};
export const select = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/geremanualfuncoes.php", formData).then((jsonResponse) => {
        dispatch({ type: actionType.select, payload: jsonResponse });
    });
};

export const getListFunctionGroup = async (requestBody) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    const response = await sendHttpRequest("POST", "/Gestor/geremanualfuncoes.php", formData);
    if (response) {
        const metadata = response["@attributes"];
        const lista = validateResponse(response);
        return {
            lista,
            metadata: {
                resultados: Number(metadata.resultados),
                pagina: Number(metadata.pagina),
                visivel: metadata.visivel,
                resultados_pagina: Number(metadata.resultados_pagina),
            },
        };
    }
    return false;
};

export const selectAllFunctionalGroup = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/geremanualfuncoes.php", formData).then((jsonResponse) => {
        return jsonResponse;
    });
};

export const selectExistingGroup = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((jsonResponse) => {
        if (jsonResponse.valores !== undefined) {
            dispatch({
                type: actionType.selectExistingGroup,
                payload: jsonResponse.valores,
            });
            return true;
        }
        return false;
    });
};

export const selectFunctionalGroupTasks = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((jsonResponse) => {
        if (jsonResponse.valores && jsonResponse.valores.ficha_funcao_tarefas !== undefined) {
            dispatch({
                type: actionType.selectFunctionalGroupsTasks,
                payload: jsonResponse.valores,
            });
            return true;
        }
        return false;
    });
};

export const selectFunctionalGroupContributors = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        if (key !== "actionType") formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse.linha === undefined) return false;
        else {
            if (!requestBody.actionType) {
                dispatch({
                    type: actionType.selectFunctionalGroupContributors,
                    payload: jsonResponse,
                });
            }
            return true;
        }
    });
};

export const setValuesToFields = (payload) => ({
    type: actionType.setValuesToFields,
    payload,
});

export const setValuesToIndexOfRowOfTable = (payload) => (dispatch, getState) => {
    const { form } = getState().funcionalGroup.domain;
    const newData = [];
    form[payload.field][payload.field].forEach((item) => {
        if (item.chave === payload.data.chave) {
            item[payload.dataIndex] = payload.value;
        }
        newData.push(item);
    });
    dispatch({
        type: actionType.setValuesToIndexOfFields,
        payload: { data: newData, field: payload.field },
    });
};

export const setValuesToTasksTable = (payload) => (dispatch, getState) => {
    const { form } = getState().funcionalGroup.domain;
    const newData = [];
    form.tarefas[payload.field][payload.field].forEach((item) => {
        if (item.chave === payload.data.chave) {
            item[payload.dataIndex] = payload.value;
        }
        newData.push(item);
    });
    dispatch({
        type: actionType.setValuesToIndexOfFieldsToTaskTable,
        payload: { data: newData, field: payload.field },
    });
};

export const addNewRowToTable = (actionType) => ({ type: actionType });

export const removeRowFromTable = (payload) => ({
    type: payload.action_type,
    id: payload.id,
});

export const clearForm = () => ({
    type: actionType.clearExistingGroupDataFromForm,
});
