import React, { useEffect, useState } from "react";
import { Button, Col, Icon, Input, message, Modal, Row, Table } from "antd";
import { api_fetch } from "../../../utils/HttpRequest/request";
import { Utilitaries } from "../../../../business";
import { RenderListForm } from "../../../shared/FormHandler/RenderFormList";

export interface CustomerModalProps {
    visible: boolean;
    value?: string;
    handleCancel: () => void;
    handleOnSave: (value?: string) => void;
}

const CustomerModal = ({ visible, value, handleCancel, handleOnSave }: CustomerModalProps) => {
    const [filter, setfilter] = useState<any>({});
    const [customersLoading, setCustomersLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [popupNewCustomer, setPopupNewCustomer] = useState(false);
    const [formModalTitle, setFormModalTitle] = useState<string>();

    const [refreshList, setRefreshList] = useState(0);

    const handleClear = () => {
        handleOnSave();
    };

    useEffect(() => {
        const loadCustomers = async () => {
            try {
                setCustomersLoading(true);

                const { pagination, ...objFilter } = filter;

                const params = {
                    ...filter,
                    accao: "clientes",
                    ...objFilter,
                    pagina: pagination?.currentPage || "1",
                    resultados_pagina: pagination?.pageSize || "50",
                };

                const {
                    response,
                    status,
                    message: messageError,
                } = await api_fetch({
                    endPoint: `Gestor/gerealuguer.php`,
                    method: "POST",
                    params,
                });

                if (status === "SUCCESS") {
                    const result = response?.clientes;
                    if (result) {
                        setDataSource(Utilitaries.toArray(result?.linha));
                    }
                } else if (status === "ERROR") {
                    message.error(messageError || "Ocorreu um erro");
                }

                setCustomersLoading(false);
            } catch (error) {
                setCustomersLoading(false);
            }
        };

        loadCustomers();
    }, [filter, refreshList]);

    //---------------------------- filter --------------------------------
    const searchInput = React.useRef<Input>(null);

    const getColumnSearchProps = (dataIndex: string) => {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys}
                        onChange={(e: any) => setSelectedKeys(e.target.value ? e.target.value : null)}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
            ),

            filteredValue: filter[`filtro_${dataIndex}`] || null,
        };
    };
    const handleSearch = (_selectedKeys: any, confirm: any) => {
        confirm();
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const obj: any = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        obj.pagination = {
            currentPage: pagination.current,
            pageSize: pagination.pageSize,
        };
        setfilter(obj);
    };

    const handleOnRow = (record: any) => {
        return {
            onClick: () => {
                handleOnSave(record["id_rent_cliente"]);
            },
        };
    };

    const tooglePopupNewCustomer = () => {
        setPopupNewCustomer((prev) => !prev);
    };

    const onSaveCustomer = () => {
        tooglePopupNewCustomer();
        setRefreshList((prev) => prev + 1);
    };

    const setTitleInFormModal = (title: string) => {
        setFormModalTitle(title);
    };

    //---------------------------- endfilter -----------------------------

    const columns = [
        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
            ...getColumnSearchProps("nome"),
        },
        {
            title: "Nif",
            dataIndex: "nif",
            key: "nif",
            ...getColumnSearchProps("nif"),
        },
        {
            title: "Telemóvel",
            dataIndex: "tlm",
            key: "tlm",
            ...getColumnSearchProps("tlm"),
        },
        {
            title: "Telefone",
            dataIndex: "tlf",
            key: "tlf",
            ...getColumnSearchProps("tlf"),
        },
    ];

    return (
        <>
            <Modal
                title={formModalTitle}
                visible={visible}
                onCancel={handleCancel}
                width={"80%"}
                destroyOnClose
                footer={[
                    <Button key="clear" type="danger" onClick={handleClear}>
                        Limpar
                    </Button>,
                    <Button key="back" onClick={handleCancel}>
                        Cancelar
                    </Button>,
                ]}
            >
                <h3>Terceiros</h3>
                <Row gutter={[16, 8]} type="flex" align="bottom">
                    <Col md={6}>
                        <Button type="primary" onClick={() => setPopupNewCustomer((prev) => !prev)}>
                            Novo
                        </Button>
                    </Col>
                </Row>
                <Row gutter={[16, 8]} type="flex" align="bottom">
                    <Col md={24}>
                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            size="small"
                            loading={customersLoading}
                            onRow={handleOnRow}
                            rowKey={(record: any) => record["id_rent_cliente"]}
                            rowSelection={{
                                type: "radio",
                                selectedRowKeys: [value || ""],
                                onSelect: (record: any) => handleOnSave(record["id_rent_cliente"]),
                                getCheckboxProps: (record) => ({
                                    disabled: record?.["is_disable"] === "t",
                                }),
                            }}
                            onChange={handleTableChange}
                            pagination={{
                                defaultPageSize: 50,
                                showSizeChanger: true,
                                pageSizeOptions: ["20", "50", "100", "200"],
                            }}
                            scroll={{ y: 240 }}
                        />
                    </Col>
                </Row>
            </Modal>
            {popupNewCustomer && (
                <Modal
                    title={formModalTitle}
                    visible={popupNewCustomer}
                    onCancel={tooglePopupNewCustomer}
                    width={"80%"}
                    destroyOnClose
                    footer={null}
                >
                    <RenderListForm
                        list_name="rent_clientes"
                        onSave={() => onSaveCustomer()}
                        justSee={false}
                        params={{}}
                        setTitleInModal={setTitleInFormModal}
                    />
                </Modal>
            )}
        </>
    );
};

export default CustomerModal;
