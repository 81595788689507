import React, { Component } from "react";
import { Modal, Form, Row, Col, Input, InputNumber, message } from "antd";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/behavioral.parameter";
const { TextArea } = Input;

class FormBehavioralParametersType extends Component {
    state = {
        loading: false,
    };

    handleResponse = (response) => {
        this.setState({ loading: false });
        if (response) {
            message.success("Guardado com sucesso");
            this.props.handleOk();
        } else message.error("Ocorreu um erro");
    };

    handleOk = (e) => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                if (this.props.isEdit) {
                    values.id_tipo_parametros_comportam =
                        this.props.formBehavioralParameter.id_tipo_parametros_comportam;
                    this.props.update({ formulario: this.props.formulario, values }).then((response) => {
                        this.handleResponse(response);
                    });
                } else {
                    this.props.insert({ formulario: this.props.formulario, values }).then((response) => {
                        this.handleResponse(response);
                    });
                }
            }
        });
    };

    render() {
        const title = this.props.isEdit
            ? "Novo tipo de parâmetro de comportamento"
            : "Editar tipo de parâmetro de comportamento";
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title={title}
                visible={this.props.isVisible}
                onOk={this.handleOk}
                onCancel={this.props.handleCancel}
                width="50%"
                confirmLoading={this.state.loading}
            >
                <Form layout="vertical" onSubmit={this.handleOk}>
                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Designação">
                                {getFieldDecorator("designacao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input name="designacao" placeholder="Introduza a designação" />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<TextArea name="descricao" rows={4} />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Nº Inc. Nega.">
                                {getFieldDecorator("n_inc_cri_neg")(<InputNumber min={0} style={{ width: "100%" }} />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Nº Inc. Posi.">
                                {getFieldDecorator("n_inc_cri_posi")(<InputNumber min={0} style={{ width: "100%" }} />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Nº Sugestões">
                                {getFieldDecorator("n_sugests")(<InputNumber min={1} style={{ width: "100%" }} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    setField: actionCreators.setField,
    insert: actionCreators.insert,
    update: actionCreators.update,
};

const mapStateToProps = (state, ownProps) => {
    return {
        formBehavioralParameter: ownProps.isEdit
            ? state.behavioralParameter.domain.formEdit
            : state.behavioralParameter.domain.formCreate,
        formulario: state.behavioralParameter.domain.metadata.formulario,
    };
};

const formCreate = Form.create({
    mapPropsToFields(props) {
        return {
            designacao: Form.createFormField({
                ...props.formBehavioralParameter.designacao,
                value: props.formBehavioralParameter.designacao.value,
            }),
            descricao: Form.createFormField({
                ...props.formBehavioralParameter.descricao,
                value: props.formBehavioralParameter.descricao.value,
            }),
            n_inc_cri_neg: Form.createFormField({
                ...props.formBehavioralParameter.n_inc_cri_neg,
                value: props.formBehavioralParameter.n_inc_cri_neg.value,
            }),
            n_inc_cri_posi: Form.createFormField({
                ...props.formBehavioralParameter.n_inc_cri_posi,
                value: props.formBehavioralParameter.n_inc_cri_posi.value,
            }),
            n_sugests: Form.createFormField({
                ...props.formBehavioralParameter.n_sugests,
                value: props.formBehavioralParameter.n_sugests.value,
            }),
        };
    },
    onFieldsChange(props, fields) {
        props.setField({ isEdit: props.isEdit, fields });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(formCreate(FormBehavioralParametersType));
