import React from "react";
import { Tooltip, Icon } from "antd";

const ChartHelp = ({ text }) => {
    return (
        <Tooltip title={text} placement="topRight" trigger="click">
            <Icon type="question-circle" />
        </Tooltip>
    );
};

export default ChartHelp;
