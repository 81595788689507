import { FormInputsProps } from "../../types/formTypes";
import { COMPONENT_TYPE } from "./XMLInputType";

interface InputsProps {
    [key: string]: FormInputsProps;
}

const getInputsWithTabInInputGroup = (
    inputs = {} as InputsProps,
    list: FormInputsProps[] = [],
    isInputGroup = false
) => {
    for (const key in inputs) {
        if (typeof inputs[key] === "object" && !Array.isArray(inputs[key])) {
            if (inputs[key][COMPONENT_TYPE.TAB] && isInputGroup) {
                list.push(inputs[key]);
            } else if (inputs[key].type === COMPONENT_TYPE.INPUT_GROUP) {
                list = getInputsWithTabInInputGroup(inputs[key], list, true);
            }
        }
    }

    return list;
};

const orderTabs = (allTabs: FormInputsProps[], allFormInput: FormInputsProps[]) => {
    let newAllTabs = [];

    for (const tab of allTabs) {
        let tabIndex = -1;
        if (tab.input_group_path) {
            tabIndex = allFormInput.findIndex((it) => it.key === tab.input_group_path?.[0]);
        } else {
            tabIndex = allFormInput.findIndex((it) => it.key === tab.key);
        }

        newAllTabs.push({ ...tab, tabPosition: tabIndex });
    }

    newAllTabs.sort((a, b) => a.tabPosition - b.tabPosition);

    //
    return newAllTabs;
};

const getFieldInInputGroup = (
    inputGroup: FormInputsProps,
    path: String[] | any = [],
    key: string = ""
): FormInputsProps | any => {
    if (path.length === 0) {
        if (inputGroup) {
            if (key) {
                return inputGroup?.[key];
            } else {
                return inputGroup;
            }
        }
    } else {
        let el = path.shift();
        return getFieldInInputGroup(inputGroup?.[el], path, key);
    }
};

interface ObjectToAddProps {
    parent: string;
}

const addPropertyToObjectInArray = (
    obj: FormInputsProps | any,
    root: string[] = [],
    key: string,
    objectToAdd = {} as ObjectToAddProps
): FormInputsProps[] => {
    if (root.length === 0) {
        if (obj) {
            obj[key] = {
                ...obj[key],
                ...objectToAdd,
            };
        }
    } else {
        let el = root.shift();
        if (typeof obj === "object" && Array.isArray(obj)) {
            const index = obj.findIndex((item) => {
                return item && item.key === el;
            });
            if (index && Array.isArray(obj)) {
                obj[index] = addPropertyToObjectInArray(obj[index], root, key, objectToAdd);
            }
        } else {
            obj[el!!] = addPropertyToObjectInArray(obj[el!!], root, key, objectToAdd);
        }
    }
    return obj;
};

export const addSeparators = (inputFormArray: FormInputsProps[]) => {
    const firstTabIndex = inputFormArray.findIndex((item) => item.tab);
    // if input Group
    // array to object od inputs
    const fieldsObj = inputFormArray.reduce((accumulator: FormInputsProps, current: FormInputsProps) => {
        accumulator[current.key] = current;
        return accumulator;
    }, {} as FormInputsProps);
    // Get all tabs inside input group
    const inputGroupTabs = getInputsWithTabInInputGroup(fieldsObj, []);

    let addedChildKeyes: string[] = []; //* cache values

    /**
     * * gets executed only if has a tab in form
     */
    if (firstTabIndex !== -1 || inputGroupTabs.length > 0) {
        let tabComponents = inputFormArray.slice(firstTabIndex);

        const tab: FormInputsProps = {
            key: `tab_${tabComponents[0] ? tabComponents[0].form_key : ""}`,
            type: COMPONENT_TYPE.TAB,
            persist: "nao",
            child_keys: [],
        };

        let separators = inputFormArray.filter((item) => item.type);

        let separatorsNormalized: FormInputsProps[] = [];

        const inputGroupRootIsTab = tabComponents.find((item) => item.type === COMPONENT_TYPE.INPUT_GROUP);

        if (inputGroupRootIsTab || inputGroupTabs.length > 0) {
            // Get list of separetors
            separatorsNormalized = separators.reduce((acumulator, current) => {
                const separator = separators.filter((item) => item.tab === current.tab && current.tab);

                if (
                    (separator.length > 1 && !acumulator.find((item) => item.tab === current.tab)) ||
                    separator.length === 1
                ) {
                    if (separator[0].type === COMPONENT_TYPE.INPUT_GROUP) {
                        acumulator.push({ ...current, input_group_path: [separator[0].key] });
                        const list = getInputsWithTabInInputGroup(separator[0]);
                        acumulator.push(...list);
                    } else {
                        acumulator.push(current);
                    }
                }

                return acumulator;
            }, [] as FormInputsProps[]);

            const allTabs = [...separatorsNormalized, ...inputGroupTabs];
            // inputGroupRootIsTab && separators.length > 0
            //     ? separatorsNormalized
            //     : [...separatorsNormalized, ...inputGroupTabs]
            const tabsLists = orderTabs(allTabs, inputFormArray);

            separatorsNormalized = tabsLists.map((item) => ({
                key: `sep_${item.tab}`,
                child_keys: [],
                parent: tab.key,
                persist: "nao",
                type: COMPONENT_TYPE.TAB_SEPARATOR,
                name: item.tab,
                input_group_path: item.input_group_path,
            }));

            //----------------------------------------------------------------//
            for (const it of separatorsNormalized) {
                const itens = separatorsNormalized.filter((sep) => it.key === sep.key);

                if (itens?.length > 1) {
                    const last = itens[itens.length - 1];

                    const currentPosition = separatorsNormalized.indexOf(it);
                    const lastPosition = separatorsNormalized.indexOf(last);

                    if (currentPosition !== lastPosition) {
                        it.key = `${it.key}@${currentPosition}`;
                        // separatorsNormalized.splice(currentPosition, 1);
                    }

                    //
                }
            }
            //-----------------------------------

            for (const sep of separatorsNormalized) {
                let formItens: FormInputsProps | FormInputsProps[] = {} as FormInputsProps;
                formItens = fieldsObj;
                // ;
                if (sep.input_group_path) {
                    formItens = getFieldInInputGroup(fieldsObj, [...sep.input_group_path]);

                    let fieldInputGroup: FormInputsProps[] = Object.values(formItens).filter(
                        (it) => typeof it === "object" && !Array.isArray(it)
                    );

                    let foundCurrentTab = false;

                    for (const [tabItemIndex, item] of Object.entries(fieldInputGroup)) {
                        // const index = fieldInputGroup.findIndex((formItem) => formItem.key === item.key);
                        /**
                         * * add field that points to tab
                         */
                        if (!Array.isArray(formItens) && formItens?.tab === sep.name) {
                            foundCurrentTab = true;
                        }

                        if (item.tab && item.tab === sep.name) {
                            foundCurrentTab = true;
                            separatorsNormalized.find((s) => s.key === sep.key)?.child_keys?.push(item.key);
                            addedChildKeyes.push(item.key);
                            inputFormArray = addPropertyToObjectInArray(
                                inputFormArray,
                                item.input_group_path ? [...item.input_group_path] : [],
                                item.key,
                                { parent: sep.key }
                            );
                        }
                        //

                        if (item.tab && item.tab !== sep.name && !addedChildKeyes.includes(item.key)) {
                            fieldInputGroup = fieldInputGroup.slice(Number(tabItemIndex));
                            break;
                        }

                        /**
                         * * add fields that are next to tab pointer
                         */
                        if (!item.tab && !addedChildKeyes.includes(item.key) && foundCurrentTab) {
                            separatorsNormalized.find((s) => s.key === sep.key)?.child_keys?.push(item.key);
                            addedChildKeyes.push(item.key);
                            inputFormArray = addPropertyToObjectInArray(
                                inputFormArray,
                                item.input_group_path ? [...item.input_group_path] : [],
                                item.key,
                                { parent: sep.key }
                            );
                        }
                        /**
                         * * only add field that points to tab that was not yet being added
                         */
                        if (
                            item.tab &&
                            !addedChildKeyes.includes(item.key) &&
                            item.tab === sep.name &&
                            foundCurrentTab
                        ) {
                            separatorsNormalized.find((s) => s.key === sep.key)?.child_keys?.push(item.key);
                            addedChildKeyes.push(item.key);
                            inputFormArray = addPropertyToObjectInArray(
                                inputFormArray,
                                item.input_group_path ? [...item.input_group_path] : [],
                                item.key,
                                { parent: sep.key }
                            );
                        }
                    }
                } else {
                    // pode ser input group
                    formItens = inputFormArray.filter((item) => item.tab === sep.name);

                    for (const _item of formItens) {
                        let index = inputFormArray.findIndex((formItem) => formItem.key === _item.key);
                        const subItens = inputFormArray.slice(index);

                        for (const item of subItens) {
                            index = inputFormArray.findIndex((formItem) => formItem.key === item.key);
                            const itemParent = sep.key.split("@")[0];
                            /**
                             * * add field that points to tab
                             */
                            if (item.tab && item.tab === sep.name && !addedChildKeyes.includes(item.key)) {
                                separatorsNormalized.find((s) => s.key === sep.key)?.child_keys?.push(item.key);
                                addedChildKeyes.push(item.key);
                                inputFormArray[index].parent = itemParent;
                            }
                            // /**
                            //  * * current separator is finished
                            //  */
                            if (item.tab && item.tab !== sep.name) {
                                // tabComponents = tabComponents.slice(tabItemIndex);
                                break;
                            }
                            /**
                             * * add fields that are next to tab pointer
                             */
                            if (
                                !item.tab &&
                                !addedChildKeyes.includes(item.key) &&
                                item.type !== COMPONENT_TYPE.POPUP
                            ) {
                                separatorsNormalized.find((s) => s.key === sep.key)?.child_keys?.push(item.key);
                                addedChildKeyes.push(item.key);
                                inputFormArray[index].parent = itemParent;
                            }
                            /**
                             * * only add field that points to tab that was not yet being added
                             */
                            if (item.tab && !addedChildKeyes.includes(item.key) && item.tab === sep.name) {
                                separatorsNormalized.find((s) => s.key === sep.key)?.child_keys?.push(item.key);
                                addedChildKeyes.push(item.key);
                                inputFormArray[index].parent = itemParent;
                            }
                        }
                    }
                }
            }

            //---------------------------------
            const separatorsNormalizedAux = [];

            for (const it of separatorsNormalized) {
                const itKey = it.key.split("@")[0];

                const itens = separatorsNormalized.filter((sep) => itKey === sep.key || it.key === sep.key);

                if (itens?.length > 1) {
                    const last = itens[itens.length - 1];

                    const currentPosition = separatorsNormalized.indexOf(it);
                    const lastPosition = separatorsNormalized.indexOf(last);

                    if (currentPosition !== lastPosition) {
                        const currentChildren: string[] =
                            it.child_keys?.map((child: string) => (!it.input_group_path ? `root@${child}` : child)) ||
                            [];
                        // it.key = `${it.key}@${currentPosition}`;
                        // separatorsNormalized[lastPosition].childKeys = [
                        //     ...separatorsNormalized[lastPosition].childKeys,
                        //     ...currentChildren,
                        // ];

                        const lasItem = separatorsNormalized[lastPosition];

                        const lastChildren: string[] =
                            lasItem.child_keys?.map((child: string) =>
                                !lasItem.input_group_path ? `root@${child}` : child
                            ) || [];
                        // it.key = `${it.key}@${currentPosition}`;
                        let uniqueList: string[] = [];
                        [...currentChildren, ...lastChildren].forEach((c) => {
                            if (!uniqueList.includes(c)) {
                                uniqueList.push(c);
                            }
                        });

                        separatorsNormalized[lastPosition].child_keys = uniqueList;

                        if (it.input_group_path && !separatorsNormalized[lastPosition].input_group_path) {
                            separatorsNormalized[lastPosition].input_group_path = it.input_group_path;
                        }
                    } else {
                        separatorsNormalizedAux.push(it);
                    }

                    //
                } else {
                    separatorsNormalizedAux.push(it);
                }
            }

            separatorsNormalized = separatorsNormalizedAux;
            //---------------------------------
        } else {
            separatorsNormalized = separators.reduce((acumulator: FormInputsProps[], current: FormInputsProps) => {
                const separator = separators.filter((item) => item.tab === current.tab);
                if (separator.length > 1 && !acumulator.find((item) => item.tab === current.tab)) {
                    acumulator.push(current);
                    return acumulator;
                } else if (separator.length === 1) {
                    acumulator.push(current);
                    return acumulator;
                }
                return acumulator;
            }, []);

            separatorsNormalized = separatorsNormalized.map((item) => ({
                key: `sep_${item.tab}`,
                child_keys: [],
                parent: tab.key,
                persist: "nao",
                type: COMPONENT_TYPE.TAB_SEPARATOR,
                name: item.tab,
            }));

            /**
             * * add chils keyes to each separator
             */
            for (const sep of separatorsNormalized) {
                for (const [tabItemIndex, item] of Object.entries(tabComponents)) {
                    const index = inputFormArray.findIndex((formItem) => formItem.key === item.key);
                    /**
                     * * add field that points to tab
                     */
                    if (item.tab && item.tab === sep.name && !addedChildKeyes.includes(item.key)) {
                        separatorsNormalized.find((s) => s.key === sep.key)?.child_keys?.push(item.key);
                        addedChildKeyes.push(item.key);
                        inputFormArray[index].parent = sep.key;
                    }
                    /**
                     * * current separator is finished
                     */
                    if (item.tab && item.tab !== sep.name) {
                        tabComponents = tabComponents.slice(Number(tabItemIndex));
                        break;
                    }
                    /**
                     * * add fields that are next to tab pointer
                     */
                    if (!item.tab && !addedChildKeyes.includes(item.key) && item.type !== COMPONENT_TYPE.POPUP) {
                        separatorsNormalized.find((s) => s.key === sep.key)?.child_keys?.push(item.key);
                        addedChildKeyes.push(item.key);
                        inputFormArray[index].parent = sep.key;
                    }
                    /**
                     * * only add field that points to tab that was not yet being added
                     */
                    if (item.tab && !addedChildKeyes.includes(item.key) && item.tab === sep.name) {
                        separatorsNormalized.find((s) => s.key === sep.key)?.child_keys?.push(item.key);
                        addedChildKeyes.push(item.key);
                        inputFormArray[index].parent = sep.key;
                    }
                }
            }
        }
        tab.child_keys = separatorsNormalized.map((sep) => sep.key);
        separatorsNormalized.push(tab);

        inputFormArray = inputFormArray.concat(separatorsNormalized);
        return inputFormArray;
    }

    return inputFormArray;
};
