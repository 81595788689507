import actionType from "../constants/action.type";
import { sendHttpRequest } from "../../../components/http.request";

// Actions creator
export const editList = (payload) => (dispatch) => {
    dispatch({
        type: actionType.editItems,
        payload,
    });
};

// get list from the server
export const select = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistasdinamicas.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({ type: actionType.select, payload: jsonResponse });
            return true;
        }
    });
};

// get list from the server by id
export const selectById = (requestBody) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistasdinamicas.php", formData).then((jsonResponse) => {
        if (jsonResponse["lista"] !== undefined) {
            dispatch({
                type: actionType.selectById,
                payload: jsonResponse,
            });
            return jsonResponse;
        } else if (jsonResponse["result"] !== undefined) {
            dispatch({
                type: actionType.selectById,
                payload: jsonResponse,
                name: requestBody.query,
            });
            return jsonResponse;
        } else {
            return false;
        } // TODO: criar pagina de erro
    });
};

// insert
export const insert = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistasdinamicas.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) return true;
        else return false;
    });
};

// configuration
export const update = (requestBody) => async () => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return await sendHttpRequest("POST", "/Gestor/gerelistasdinamicas.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) return true;
        else return false;
    });
};

export const setField = (payload) => ({ type: actionType.setField, payload });
