import React, { useState } from "react";
import { Modal, Table, Button, Tooltip } from "antd";
import { toArray } from "../../../../components/util";

const ProcessAnnulmentModal = (props) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loadingConfirm, setLoadingConfirm] = useState(false);
    const [loadingReject, setLoadingReject] = useState(false);

    const columns = [
        {
            title: "Nº",
            dataIndex: "id_processo",
        },
        {
            title: "Processo",
            dataIndex: "nome_processo",
        },
        {
            title: "Utilizador Pedido",
            dataIndex: "user_pedido",
        },
        {
            title: "Data Pedido",
            dataIndex: "data_pedido",
        },
        {
            title: "Motivo Anulação",
            dataIndex: "obs",
            render: (obs) => {
                if (!obs) return "";

                return obs.length > 70 ? (
                    <Tooltip title={obs}>
                        <div
                            style={{
                                textOverflow: "ellipsis",
                                width: "90%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            {obs}
                        </div>
                    </Tooltip>
                ) : (
                    obs
                );
            },
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    const dataSource = props.data.map((el, index) => ({
        ...el,
        key: index,
    }));

    const handleConfirm = async (accepted) => {
        setLoadingConfirm(true);
        const proccessIds = selectedRowKeys.map((el) => {
            return dataSource[el].id_processo;
        });
        const ids = [...new Set(proccessIds)];
        const result = await props.handleOk(ids, accepted);
        if (result) {
            setSelectedRowKeys([]);
        }
        setLoadingConfirm(false);
    };

    const handleReject = async (accepted) => {
        setLoadingReject(true);
        const proccessIds = selectedRowKeys.map((el) => {
            return dataSource[el].id_processo;
        });
        const ids = [...new Set(proccessIds)];
        const result = await props.handleOk(ids, accepted);
        if (result) {
            setSelectedRowKeys([]);
        }
        setLoadingReject(false);
    };

    return (
        <Modal
            width="90%"
            style={{ top: "5%" }}
            title="Anulação de processos"
            visible={props.isVisible}
            onCancel={props.handleCancel}
            footer={[
                <Button key="cancel" onClick={props.handleCancel} disabled={loadingConfirm || loadingReject}>
                    Cancelar
                </Button>,
                <Button
                    key="reject"
                    disabled={!props.data.length > 0 || loadingConfirm}
                    loading={loadingReject}
                    type="danger"
                    onClick={() => handleReject(false)}
                >
                    Rejeitar Anulação
                </Button>,
                <Button
                    key="ok"
                    disabled={!props.data.length > 0 || loadingReject}
                    loading={loadingConfirm}
                    type="primary"
                    onClick={() => handleConfirm(true)}
                >
                    Confirmar Anulação
                </Button>,
            ]}
        >
            <Table size="small" rowSelection={rowSelection} columns={columns} dataSource={dataSource} />
        </Modal>
    );
};

export default ProcessAnnulmentModal;
