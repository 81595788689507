import React from "react";

import { Row, Tooltip, Icon, Typography } from "antd";

const { Text } = Typography;

const ToolTip = ({ help, label, noRow, isRequired, isBold = false }) =>
    noRow ? (
        <>
            <Text strong={isBold}>{label} </Text>
            {help && (
                <Tooltip title={help}>
                    <Icon type="info-circle" style={{ color: "#faad14" }} />
                </Tooltip>
            )}
        </>
    ) : (
        <Row>
            {isRequired && <i style={{ color: "red" }}>*</i>}
            <Text strong={isBold}> {label} </Text>
            {help && (
                <Tooltip title={help}>
                    <Icon type="info-circle" style={{ color: "#faad14" }} />
                </Tooltip>
            )}
        </Row>
    );

export default ToolTip;
