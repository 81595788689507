import React, { useState } from "react";
import { Row, Select, Col, Form } from "antd";
import DynamicFilter from "../components/dynamic.filter";

const { Option } = Select;

const ReportFilter = ({
    selectedReport,
    data,
    getFilter,
    getFieldDataSource,
    selectedStablishment,
    reportTypes,
    reports,
    setDepartments,
    departments,
    handleReportTypeChange,
    handleReportChange,
    form,
}) => {
    function onReportTypeChange(value) {
        handleReportTypeChange(value);
    }

    const onReportChange = (value) => {
        handleReportChange(value);
        form.setFieldsValue({
            departamento: undefined,
            estado_clientes: undefined,
            clientes: undefined,
            sub_relatorio_opcoes: undefined,
            param1: undefined,
            param2: undefined,
            param3: undefined,
            param4: undefined,
            ass_respons: undefined,
            ass_cliente: undefined,
            ass_entida: undefined,
            data_inicio: undefined,
            data_fim: undefined,
        });
        setDepartamento("");
        setEstado("");
        setClients([]);
        setParam1("");
        setParam2("");
        setParam3("");
        setParam4("");
        setSelectedSubRelatorioOpcoes("");
    };

    const [selectedDepartamento, setDepartamento] = useState("");
    const [selectedEstado, setEstado] = useState("");
    const [clients, setClients] = useState([]);
    const [selectedSubRelatorioOpcoes, setSelectedSubRelatorioOpcoes] = useState("");
    const [param1, setParam1] = useState("");
    const [param2, setParam2] = useState("");
    const [param3, setParam3] = useState("");
    const [param4, setParam4] = useState("");

    return (
        <Form layout="vertical">
            <Row gutter={18} style={{ marginTop: 12 }}>
                <Col xs={8} md={8}>
                    <Form.Item label="Tipologia">
                        <Select
                            showSearch
                            allowClear
                            showArrow={false}
                            style={{ width: "100%" }}
                            onChange={onReportTypeChange}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {reportTypes &&
                                reportTypes.map((item) => (
                                    <Option
                                        key={`reportType-${item.designacao}`}
                                        title={item.designacao}
                                        value={item.id_tipologia}
                                    >
                                        {item.designacao}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={8} md={8}>
                    <Form.Item label="Relatório">
                        <Select
                            showSearch
                            allowClear
                            showArrow={false}
                            value={selectedReport}
                            style={{ width: "100%" }}
                            placeholder="Selecione o relatório"
                            onChange={onReportChange}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {reports &&
                                reports.map((reportItem) => (
                                    <Option
                                        key={`'report-${reportItem.id_relatorio}`}
                                        title={reportItem.designacao_nova}
                                        value={reportItem.id_relatorio}
                                    >
                                        {reportItem.designacao_nova}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            {selectedReport && (
                <DynamicFilter
                    data={data}
                    getFilter={getFilter}
                    getFieldDataSource={getFieldDataSource}
                    selectedStablishment={selectedStablishment}
                    setDepartments={setDepartments}
                    departments={departments}
                    form={form}
                    setDepartamento={setDepartamento}
                    setEstado={setEstado}
                    setClients={setClients}
                    selectedDepartamento={selectedDepartamento}
                    selectedEstado={selectedEstado}
                    clients={clients}
                    selectedSubRelatorioOpcoes={selectedSubRelatorioOpcoes}
                    setSelectedSubRelatorioOpcoes={setSelectedSubRelatorioOpcoes}
                    setParam1={setParam1}
                    setParam2={setParam2}
                    setParam3={setParam3}
                    setParam4={setParam4}
                    param1={param1}
                    param2={param2}
                    param3={param3}
                    param4={param4}
                />
            )}
            <br />
        </Form>
    );
};

const WrappedPersonalInfoTabPane = Form.create({ name: "report.filter" })(ReportFilter);

export default WrappedPersonalInfoTabPane;
