import { count_requirement, dimensionProps } from "../constants/index";
import { sendHttpRequest } from "../../../components/http.request";

export const getCurrentDimension = (dimensionNumber) => {
    if (dimensionNumber > 5) dimensionNumber = 5;
    return dimensionProps[dimensionNumber];
};

const getSubDimensionForm = (form, currentDimension) => {
    const subDimension = form[currentDimension.key][currentDimension.table][currentDimension.subDimension];

    return subDimension ? subDimension["@popupform"] : null;
};

const getScaleForm = (form, currentDimension) => {
    const scale = form[currentDimension.key][currentDimension.table].escala;
    return scale ? scale["@popupform"] : null;
};

const getList = (values, currentDimension) => {
    const listTable = values[currentDimension.key][currentDimension.table];
    let list = [];

    if (Array.isArray(listTable)) list = [...listTable];
    else if (listTable && Object.keys(listTable).length !== 0) list = [listTable];

    return list.map((item, index) => {
        if (Array.isArray(item[currentDimension.subDimension])) {
            item.subDimensionId = null;
            delete item[currentDimension.subDimension];
        } else item.subDimensionId = item[currentDimension.subDimension];

        const escala = Array.isArray(item.escala) ? null : item.escala;

        return {
            ...item,
            key: index,
            subDimensionId: Array.isArray(item[currentDimension.subDimension])
                ? null
                : item[currentDimension.subDimension],
            escala,
        };
    });
};

const prepareData = (dimensionNumber, data) => {
    const formulario = data.formulario;
    const elemento_raiz = formulario["@elemento_raiz"];
    const values = data.valores[elemento_raiz];

    //get props dimension
    const currentDimension = getCurrentDimension(dimensionNumber);
    values.list = getList(values, currentDimension);
    return {
        values,
        formulario: formulario,
        formularioSubDimensao: getSubDimensionForm(formulario[elemento_raiz], currentDimension),
        formularioEscala: getScaleForm(formulario[elemento_raiz], currentDimension),
    };
};

export const selectDimension = (payload) => async (dispatch) => {
    if (!payload.formulario) return false;
    const formData = new FormData();
    formData.append("hack", true);
    formData.append("formulario", payload.formulario);

    if (payload.id) {
        formData.append("accao", "dados");
        formData.append("id", payload.id);
        return await sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((jsonResponse) => {
            return prepareData(payload.dimensionNumber, jsonResponse.result);
        });
    } else {
        formData.append("accao", "consultar");
        return await sendHttpRequest("POST", "/Gestor/gereformulario.php", formData).then((jsonResponse) => {
            if (!jsonResponse) return false;
            const currentDimension = getCurrentDimension(payload.dimensionNumber);
            const values = {
                list: [],
            };

            const formulario = jsonResponse.formulario;
            const elemento_raiz = formulario["@elemento_raiz"];
            return {
                values,
                formulario: formulario,
                formularioSubDimensao: getSubDimensionForm(formulario[elemento_raiz], currentDimension),
                formularioEscala: getScaleForm(formulario[elemento_raiz], currentDimension),
            };
        });
    }
};

export const save = (payload) => async (dispatch) => {
    const elemento_raiz = payload.formulario["@elemento_raiz"];
    //get props dimension
    const currentDimension = getCurrentDimension(payload.dimensionNumber);
    const listToSave = payload.values.list.map((item) => {
        const obj = { ...item };
        const subDimensionId = obj.subDimensionId;
        delete obj.subDimensionId;
        delete obj.key;
        if (subDimensionId) {
            obj[currentDimension.subDimension] = subDimensionId;
        } else {
            delete obj[currentDimension.subDimension];
        }
        return obj;
    });
    const obj = {
        [elemento_raiz]: {
            descricao: payload.values.descricao,
            [currentDimension.key]: {
                [currentDimension.table]: [...listToSave],
            },
        },
    };

    if (payload.values.id) obj[elemento_raiz][currentDimension.id] = payload.values.id;

    const formData = new FormData();
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario["@nome"]);
    formData.append("dados", JSON.stringify(obj));

    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse.sucesso && jsonResponse.sucesso !== "0" ? jsonResponse.sucesso : false;
    });
};

const getQueryName = (dimensionNumber) => {
    if (dimensionNumber) {
        const dimension = getCurrentDimension(dimensionNumber);
        return dimension.countSubDimension;
    } else {
        return count_requirement;
    }
};

export const countSubDimension = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("query", getQueryName(payload.dimensionNumber));
    formData.append("id_dimensao", payload.id_dimensao);

    return await sendHttpRequest("POST", "/Gestor/execQuery.php", formData).then((jsonResponse) => {
        if (Array.isArray(jsonResponse.result)) {
            if (payload.dimensionNumber) {
                return jsonResponse.result.map((item) => ({
                    ...item,
                    total: Number(item.total),
                }));
            } else {
                return Number(jsonResponse.result[0].count);
            }
        }
        return 0;
    });
};
