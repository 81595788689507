export const defaultDataSource = {
    id_ficha_colaborador: "",
    tipo_utilizador: "",
    tipo_colaborador: "",
    username: "",
    senha: "",
    sexo: "",
    status: "",
    motivo_suspensao: "",
    estab: "",
    estabelecimento_defeito: "",
    tipo_contracto: "",
    exclusividade: "",
    entidade: {
        identidade: "",
        nome_completo: "",
        nome: "",
        tlf: "",
        tlm: "",
        email: "",
        iban: "",
        nib: "",
        bank: "",
        niss: "",
        nif: "",
        data_nascimento: "",
        moradas: {
            morada: {
                idmorada: "",
                pais: "",
                distrito: "",
                concelho: "",
                freguesia: "",
                arruamento: "",
                c_postal4: "",
                c_postal3: "",
                porta: "",
                andar: "",
            },
        },
    },
    habilitacao: "",
    data_admissao_servico: "",
    inps: "",
    num_mecanografico: "",
    escalao: "",
    tipo_contrato: "",
    modalidade: "",
    afetacao: "",
    modelo: "",
    horas_trabalho: "",
    dias_trabalho: "",
    trab_turnos: "",
    acessos_validacao: "",
    ausencia: {
        ausencia: [],
    },
    falta: {
        falta: [],
    },
    funcoes_colab: {
        funcao_colab: [],
    },
    docs_colaboradores: {
        doc_colaborador: [],
    },
    progs_contratuais: {
        prog_contratual: [],
    },
    progs_carreiras: {
        prog_carreira: [],
    },
    progs_funcionais: {
        prog_funcional: [],
    },
    progs_remunatorias: {
        prog_remunatoria: [],
    },
    horas_extras: {
        hora_extra: [],
    },
    linha: {
        ausencia: [],
        falta: [],
    },
    convenc_coletiva: "",
    categoria: "",
    nivel: "",
    info_nivel: "",
    data_vigor: "",
    formacoes_externa: {
        formacao_externa: [],
    },
};
