import React, { Children, useEffect, useState, useMemo } from "react";
import { Modal, message, Button, Typography, Table } from "antd";
import ExecutionForm from "../execution/execution.form";
import { getChild } from "../../constants/utils";
import {
    getFieldDataSource,
    getForm,
    getFormData,
    toggleModal,
    toggleIsFormReady,
    saveForm,
    getAoCarregar,
} from "../../actions/index";
import TaskExecutionFormProvider, { MyContext } from "../execution/execution.form.provider";
import { selectById } from "../../../dynamic_list/actions/";
import { componentType } from "../../constants/index";
import { isEmpty, canCorrectField } from "../../utils/fields";
import { formsToGetAoCarregarSameItSaved, validateFormAfterSave } from "../../utils/form";

import { useSelector } from "react-redux";
import Confirm from "../../../../components/qa_custom/confirm";

const QaModal = ({
    itemData,
    // modals,
    // appState,
    state,
    modalOpened,
    eventKey,
    processId,
    isFormReady,
    formName = itemData["@popupform"],
    taskId,
    processParentId,
    handleModalCancel,
    getFieldDecorator,
    handleModalOk,
    triggerModalId,
    dispatch,
    correctForm,
    allPopupParams,
    currentTaskStatus,
    ...restProps
}) => {
    const [form, setForm] = useState(null);
    const [formMeta, setFormMeta] = useState(null);
    const [formItemsRender, setFormItemsRender] = useState(null);
    const [hiddeSaveAndCancelButtom, setHiddeSaveAndCancelButtom] = useState(false);

    const modals = useSelector((state) => state.processExecution.app.modals);

    useEffect(() => {
        if (modals?.[itemData.key]?.isVisible) {
            handleGetForm({ form: formName });
        }

        // if (!modals[itemData.key]?.isVisible) {
        //     setForm({});
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modals?.[itemData.key]?.isVisible]);

    const disableSaveButtom = useMemo(() => {
        if (!formMeta) {
            return false;
        }

        if (formMeta?.["@persiste"] === "nao") {
            return true;
        }

        return false;
    }, [formMeta]);

    const handleGetForm = ({ form }) => {
        const formName = form;

        // const formParams = Object.keys(allPopupParams).reduce((acc, key) => {
        //     if (allPopupParams[key] === undefined || allPopupParams[key] === null) {
        //         acc[key] = "";
        //     } else {
        //         acc[key] = allPopupParams[key];
        //     }

        //     return acc;
        // }, {});

        // console.log("opem form with", formParams);

        return dispatch(
            getFormData({
                form,
                id: triggerModalId,
                // formStruct,
                processId,
                taskId,
                allPopupParams,
            })
        )?.then(({ form, formMeta, values, hiddeSaveButtomOnPopup }) => {
            setHiddeSaveAndCancelButtom(hiddeSaveButtomOnPopup);
            const forceGetAoCarregar = formsToGetAoCarregarSameItSaved(formName);
            getAoCarregar({ form, metaInfo: formMeta, values, allPopupParams, forceGetAoCarregar }).then((newForm) => {
                setForm(newForm);
                setFormMeta(formMeta);
            });
        });
    };

    const handleGetFormData = ({ form, id, formStruct }) => {
        return dispatch(
            getFormData({
                form,
                id,
                formStruct,
                processId: processId,
                taskId: taskId,
                allPopupParams,
            })
        );
    };

    const handleToggleModal = ({ ...props }) => {
        return dispatch(toggleModal({ ...props }));
    };

    useEffect(() => {
        if (form && Object.values(form)?.length > 0) {
            setFormItemsRender(Object.values(form));
        }
    }, [form]);

    return form && formMeta ? (
        <TaskExecutionFormProvider
            toggleIsFormReady={toggleIsFormReady}
            currentTaskStatus={itemData["@estado"] || currentTaskStatus}
            selectById={({ ...props }) => selectById({ ...props })}
            getForm={handleGetForm}
            saveForm={saveForm}
            getFormData={handleGetFormData}
            // getFormData={getFormData}
            getReporterByName={restProps?.getReporterByName}
            taskId={taskId}
            processId={processId}
            getFieldDataSource={getFieldDataSource}
            dispatch={dispatch}
            toggleModal={handleToggleModal}
            form={form}
            formName={formName}
            triggerModalId={triggerModalId}
            processParentId={processParentId}
            formMetaData={[{ meta: formMeta }]}
            modalParams={allPopupParams}
            correctForm={correctForm}
        >
            <MyContext.Consumer>
                {(context) => (
                    <RenderModal
                        taskId={taskId}
                        processId={processId}
                        allPopupParams={allPopupParams}
                        dispatch={dispatch}
                        formName={formName}
                        form={context.state.form}
                        context={context}
                        currentTaskStatus={currentTaskStatus}
                        correctForm={correctForm}
                        setForm={setForm}
                        handleModalCancel={handleModalCancel}
                        handleModalOk={handleModalOk}
                        itemData={itemData}
                        modalOpened={modalOpened}
                        modals={modals}
                        disableSaveButtom={disableSaveButtom}
                        hiddeSaveAndCancelButtom={hiddeSaveAndCancelButtom}
                    >
                        <ExecutionForm
                            formItemsRender={formItemsRender?.filter(
                                (item) =>
                                    (!item.parent || item["@tipo"] === componentType._popup) &&
                                    (item["@tipo"] || item["@elemento_grafico"])
                            )}
                            conditions={state.conditions}
                            formName={formName}
                            formItems={Object.values(form)}
                            dispatch={dispatch}
                            isFormReady={isFormReady}
                            saveForm={saveForm}
                            eventKey={eventKey}
                            isRoot={false}
                            {...restProps}
                        />
                    </RenderModal>
                )}
            </MyContext.Consumer>
        </TaskExecutionFormProvider>
    ) : null;
};

export default QaModal;

const RenderModal = ({
    children,
    taskId,
    processId,
    modalOpened,
    form,
    correctForm,
    formName,
    allPopupParams,
    handleModalOk,
    dispatch,
    modals,
    itemData,
    setForm,
    currentTaskStatus,
    handleModalCancel,
    disableSaveButtom,
    context,
    hiddeSaveAndCancelButtom,
}) => {
    const [loading, setLoading] = useState(false);
    const [eventAlerts, setEventAlerts] = useState(null);

    const onOk = async (validateOnSave = true) => {
        if (validateOnSave) {
            const validateForm = await validateFormAfterSave({ formName, form, allPopupParams });

            if (validateForm !== null && validateForm?.users) {
                setEventAlerts(validateForm);
                return;
            }
        }

        const isValid = context.validateFormData();
        if (!isValid) {
            return;
        }

        setLoading(true);
        context.setTriedSave(true);
        const resp = await dispatch(
            saveForm({
                task: taskId,
                process: processId,
                params: modalOpened.paramsKeyValue,
                context: "modal",
                formData: Object.values(form),
                formName,
                allPopupParams: allPopupParams, //.find((it) => it.popup === itemData.key)?.params || {},
                temporary: false,
            })
        );
        if (resp !== "empty") {
            if (resp && resp.erro) {
                message.error(resp.erro);
            } else if (resp && resp.sucesso !== "0") {
                handleModalOk(resp.sucesso);
                setForm(null);
                message.success("Guardado com sucesso");
            } else {
                message.error("Ocorreu um erro");
            }
        }
        setLoading(false);
    };

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus;

    const onCancelModal = () => {
        Confirm(() => {
            handleModalCancel(itemData.key);
            setForm(null);
        });
    };

    return (
        <>
            {eventAlerts !== null && (
                <ShowReapetedEventsModal
                    showReapetedEvents={!!eventAlerts}
                    events={eventAlerts.events}
                    users={eventAlerts.users}
                    onOk={async () => {
                        await onOk(false);
                        setEventAlerts(null);
                    }}
                    onCancel={() => {
                        setEventAlerts(null);
                    }}
                />
            )}

            <Modal
                title={itemData["@titulo"]}
                style={itemData.style}
                visible={modals[itemData.key] ? modals[itemData.key].isVisible : false}
                okButtonProps={{
                    disabled: disableSaveButtom,
                }}
                destroyOnClose
                onCancel={onCancelModal}
                footer={
                    !hiddeSaveAndCancelButtom
                        ? [
                              <Button
                                  key="back"
                                  size="default"
                                  onClick={() => {
                                      handleModalCancel(itemData.key);
                                      setForm(null);
                                  }}
                              >
                                  Cancelar
                              </Button>,
                              <Button
                                  disabled={disabled}
                                  key="submit"
                                  type="primary"
                                  size="default"
                                  loading={loading}
                                  onClick={onOk}
                              >
                                  Guardar
                              </Button>,
                          ]
                        : []
                }
                width="80%"
                bodyStyle={{
                    paddingTop: isEmpty(itemData["@titulo"]) ? 50 : 0,
                }}
            >
                {children}
            </Modal>
        </>
    );
};

const ShowReapetedEventsModal = (props) => {
    const columns = [
        {
            title: "Título",
            dataIndex: "titulo",
            key: "titulo",
        },
        {
            title: "Utilizador",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Data Início",
            dataIndex: "data_inicio",
            key: "data_inicio",
        },
        {
            title: "Data Fim",
            dataIndex: "data_fim",
            key: "data_fim",
        },
        {
            title: "Tipologia",
            dataIndex: "tipologia_evento",
            key: "tipologia_evento",
        },
    ];

    return (
        <Modal
            title="Eventos Repetidos"
            visible={props.showReapetedEvents}
            okText="Confirmar criação de eventos para Reunião"
            width="60%"
            onOk={() => {
                props.onOk();
            }}
            onCancel={() => {
                props.onCancel();
            }}
        >
            <pre style={{ whiteSpace: "pre-wrap", paddingBottom: 10 }}>
                {`O(s) utilizador(es) a seguir possue(m) evento(s) na mesma data e hora. Eles serão avisados da sobreposição do evento. ${props.users.join(
                    ", "
                )}`}
                {/* {`O(s) utilisador(es) ${props.users.join(
                    ", "
                )} possue(m) evento(s) na mesma data e hora. \n\nEles serão avisados da sobreposição do evento.`} */}
            </pre>

            <Typography.Text strong>Eventos Repetidos:</Typography.Text>
            <Table
                rowKey={(item) => item.usename + "-" + item.data_inicio}
                style={{ marginTop: 10 }}
                size="small"
                dataSource={props.events}
                columns={columns}
                pagination={false}
            />
        </Modal>
    );
};
