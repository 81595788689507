import React, { useEffect, useState } from "react";
import { Modal, Table, Pagination, Row, Icon, Input, Button, Col } from "antd";
import { getDossier } from "./../actions/index";
import ButtonsGroup from "./buttons.group";
import { downloadFile } from "./../../../components/util";
import { ClientDocuments } from "./ClientDocuments";

const Dossier = ({ redirect, isModalVisible, clientNumber, clientName, hideModal }) => {
    const [loadingData, setLoadingData] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [filter, setfilter] = useState({});
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });
    // Get form from API
    useEffect(() => {
        setLoadingData(true);
        const { sorter, ...objFilter } = filter;
        const values = {
            ...objFilter,
            pagina: pagination.page,
            resultados_pagina: pagination.perPage,
            cliente: clientNumber,
        };
        if (sorter) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        getDossier(values).then((response) => {
            setDataSource(response.lista);
            setMetadata(response.metadata);
            setLoadingData(false);
        });
    }, [pagination, filter, clientNumber]);

    //---------------------------- filter --------------------------------

    const getColumnSearchProps = (dataIndex) => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys}
                        onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
            filteredValue: filter[`filtro_${dataIndex}`] || null,
        };
    };

    const getColumnSortProps = (dataIndex, sortedInfo) => ({
        sorter: true,
        sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }
        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter(obj);
    };

    //---------------------------- endfilter -----------------------------

    const exportList = async (saida) => {
        const { sorter, ...objFilter } = filter;

        const values = {
            ...objFilter,
            pagina: pagination.page,
            resultados_pagina: pagination.perPage,
            cliente: clientNumber,
        };
        if (sorter) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }
        const formExport = {};
        Object.entries(values).forEach(([key, val]) => {
            if (val) formExport[key] = val;
        });

        formExport.saida = saida;
        formExport.accao = "selecao_cliente";
        const titulo = "Dossiê do cliente";
        formExport.titulo = titulo;
        formExport.campos =
            "processo:122:Processo;nome_processo:384:Nome Processo;tarefa:92:Tarefa;nome_tarefa:307:Nome Tarefa;inicio:192:Data Início;fim:153:Data Fim;";

        return await downloadFile("/geredossiercliente.php", formExport);
    };

    const goToDetails = (processId, taskId) => {
        const search = `?taskId=${taskId}&routeContext=customer_dossier`;
        redirect({
            pathname: `/task/execution/map/${processId}`,
            search,
        });
    };

    const sortedInfo = filter.sorter || {};
    const columns = [
        {
            title: "Nº processo",
            dataIndex: "processo",
            ...getColumnSearchProps("processo"),
            ...getColumnSortProps("processo", sortedInfo),
        },
        {
            title: "Nome processo",
            dataIndex: "nome_processo",
            ...getColumnSearchProps("nome_processo"),
            ...getColumnSortProps("nome_processo", sortedInfo),
        },
        {
            title: "Nº tarefa",
            dataIndex: "tarefa",
            ...getColumnSearchProps("tarefa"),
            ...getColumnSortProps("tarefa", sortedInfo),
        },
        {
            title: "Nome tarefa",
            dataIndex: "nome_tarefa",
            ...getColumnSearchProps("nome_tarefa"),
            ...getColumnSortProps("nome_tarefa", sortedInfo),
        },
        {
            title: "Data início",
            dataIndex: "inicio",
            ...getColumnSearchProps("inicio"),
            ...getColumnSortProps("inicio", sortedInfo),
        },
        {
            title: "Data fim",
            dataIndex: "fim",
            ...getColumnSearchProps("fim"),
            ...getColumnSortProps("fim", sortedInfo),
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                        onClick={() => goToDetails(record.processo, record.tarefa)}
                    >
                        Consultar
                    </Button>
                </>
            ),
        },
    ];

    const pageChange = (page, perPage) => {
        setPagination({
            page,
            perPage,
        });
    };

    const clearFormFilters = () => {
        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter({});
    };

    return (
        <Modal
            title={"Dossiê do cliente"}
            width="90%"
            style={{ top: 20 }}
            visible={isModalVisible}
            onCancel={hideModal}
            footer={null}
        >
            <Row>
                <Col xs={24} md={24} style={{ textAlign: "center" }}>
                    <ClientDocuments client={clientNumber} />
                    <span>{clientName}</span>
                    <ButtonsGroup exportList={exportList} />
                    {Object.keys(filter).length !== 0 && (
                        <Button
                            onClick={clearFormFilters}
                            type="link"
                            size="default"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "right",
                            }}
                        >
                            Limpar filtros
                        </Button>
                    )}
                </Col>
            </Row>
            <Table
                size="small"
                rowKey={(data) => data.tarefa + "-table"}
                pagination={false}
                dataSource={dataSource}
                loading={loadingData}
                columns={columns}
                onChange={handleTableChange}
            />
            <Row>
                <Pagination
                    style={{ float: "right", marginTop: 16 }}
                    current={metadata.pagina}
                    defaultPageSize={metadata.resultados_pagina}
                    size="small"
                    total={metadata.resultados}
                    showSizeChanger
                    showTotal={(total) => `Total de ${total} registo.`}
                    onShowSizeChange={pageChange}
                    onChange={pageChange}
                />
            </Row>
        </Modal>
    );
};
export default Dossier;
