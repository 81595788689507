import React, { Component } from "react";
import { Modal, Form, Row, Col, Input, Select, Radio, message, InputNumber } from "antd";
import { labelTooltip as LabelTooltip } from "../../../../components/util";
import { connect } from "react-redux";

import * as actionCreators from "./../../actions/competence";

const { Option } = Select;

class FormCompetence extends Component {
    state = {
        loading: false,
    };
    checkResponse = (sucess) => {
        if (sucess) {
            message.success("Guardado com sucesso");
            this.props.hiddeModal();
            //TODO: eliminar posteriormente
            this.props.select({
                accao: "lista",
                lista: "competencias",
                pagina: 1,
                resultados_pagina: 0,
                mostra_lista_eliminados: this.props.showDeletedRecords,
            });
            /****************************** */
        } else {
            message.error("Ocorreu um erro");
            this.setState({ loading: false });
        }
    };
    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                if (this.props.isEdit) {
                    this.props
                        .update({
                            accao: "guardar",
                            formulario: "qa_competencias.xml",
                            dados: JSON.stringify({
                                competencia: {
                                    ...values,
                                    id_competencia: this.props.dataToEdit.id_competencia,
                                    activo: true,
                                    versao:
                                        typeof this.props.dataToEdit.versao !== "object"
                                            ? this.props.dataToEdit.versao + 1
                                            : 1,
                                },
                            }),
                        })
                        .then((sucess) => {
                            this.checkResponse(sucess);
                        });
                } else {
                    this.props
                        .insert({
                            accao: "guardar",
                            formulario: "qa_competencias.xml",
                            dados: JSON.stringify({
                                competencia: {
                                    ...values,
                                    activo: true,
                                    versao: 1,
                                },
                            }),
                        })
                        .then((sucess) => {
                            this.checkResponse(sucess);
                        });
                }
            }
        });
    };

    handleTipoChange = (value) => {
        this.props.form.setFieldsValue();
    };

    render() {
        const n_sugests = typeof this.props.dataToEdit.n_sugests !== "object" ? this.props.dataToEdit.n_sugests : null;
        const n_inc_cri_posi =
            typeof this.props.dataToEdit.n_inc_cri_posi !== "object" ? this.props.dataToEdit.n_inc_cri_posi : null;
        const n_inc_cri_neg =
            typeof this.props.dataToEdit.n_inc_cri_neg !== "object" ? this.props.dataToEdit.n_inc_cri_neg : null;
        const { getFieldDecorator } = this.props.form;
        const title = this.props.isEdit ? "Editar competência" : "Nova competência";

        return (
            <Modal
                style={{ top: 10 }}
                title={title}
                visible={this.props.isVisible}
                onOk={this.handleOk}
                onCancel={this.props.hiddeModal}
                width="50%"
                confirmLoading={this.state.loading}
            >
                <Form layout="vertical" onSubmit={this.handleOk}>
                    <Row gutter={18}>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Tipo de competência">
                                {getFieldDecorator("tipo_competencia", {
                                    initialValue: this.props.dataToEdit.id_tipo_competencia,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        allowClear
                                        onChange={this.handleTipoChange}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.props.competenceTypeList.map((item) => (
                                            <Option key={item.id_tipo_competencia}>{item.designacao}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label="Competência">
                                {getFieldDecorator("designacao", {
                                    initialValue: this.props.dataToEdit.designacao,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item
                                label={
                                    <LabelTooltip
                                        label="Contribui para QV dos clientes?"
                                        description="Indicar se a comptência contribui ou não para a qualidade de vida dos clientes."
                                    />
                                }
                            >
                                {getFieldDecorator("contribui_qv", {
                                    initialValue: this.props.dataToEdit.contribui_qv === "Sim" ? 1 : 0,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Radio.Group buttonStyle="solid" onChange={this.onChange}>
                                        <Radio.Button value={1}>Sim</Radio.Button>
                                        <Radio.Button value={0}>Não</Radio.Button>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Nº Inc. Nega.">
                                {getFieldDecorator("n_inc_cri_neg", {
                                    initialValue: n_inc_cri_neg,
                                })(<InputNumber style={{ width: "100%" }} min={0} />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Nº Inc. Posi.">
                                {getFieldDecorator("n_inc_cri_posi", {
                                    initialValue: n_inc_cri_posi,
                                })(<InputNumber style={{ width: "100%" }} min={0} />)}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item label="Nº Sugestões">
                                {getFieldDecorator("n_sugests", {
                                    initialValue: n_sugests,
                                })(<InputNumber style={{ width: "100%" }} min={0} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    setField: actionCreators.setField,
    insert: actionCreators.insert,
    select: actionCreators.select,
    update: actionCreators.update,
};

const mapStateToProps = (state) => {
    return {
        formCompetence: state.competence.domain.form,
        competenceTypeList: Object.values(state.competenceType.domain.byId),
    };
};

const formCreate = Form.create({
    mapPropsToFields(props) {
        if (!props.isEdit) {
            return {
                designacao: Form.createFormField({
                    ...props.formCompetence.designacao,
                    value: props.formCompetence.designacao.value,
                }),
                tipo_competencia: Form.createFormField({
                    ...props.formCompetence.tipo_competencia,
                    value: props.formCompetence.tipo_competencia.value,
                }),
                contribui_qv: Form.createFormField({
                    ...props.formCompetence.contribui_qv,
                    value: props.formCompetence.contribui_qv.value,
                }),
                n_inc_cri_neg: Form.createFormField({
                    ...props.formCompetence.n_inc_cri_neg,
                    value: props.formCompetence.n_inc_cri_neg.value,
                }),
                n_inc_cri_posi: Form.createFormField({
                    ...props.formCompetence.n_inc_cri_posi,
                    value: props.formCompetence.n_inc_cri_posi.value,
                }),
                n_sugests: Form.createFormField({
                    ...props.formCompetence.n_sugests,
                    value: props.formCompetence.n_sugests.value,
                }),
            };
        }
    },
    onFieldsChange(props, fields) {
        if (!props.isEdit) props.setField(fields);
    },
})(FormCompetence);

export default connect(mapStateToProps, mapDispatchToProps)(formCreate);
