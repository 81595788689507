import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, Select, message, Result, Icon, Button, Modal } from "antd";
import { api_fetch } from "../../utils/HttpRequest/request";
import { getPageAttributes, PageAttributesProps } from "./formFunctions";
import { Utilitaries } from "../../../business";
import { MenuFormRender } from "../../shared/FormHandler";
import { GenericObject } from "../../types/common";
import MeetingRoomSchedule from "./components/MeetingRoomSchedule";

const { Option } = Select;

interface ScheduleModalProps {
    visible: boolean;
    id?: string;
    popupParams?: GenericObject;
}

const MeetingRoomRequest = () => {
    const [pageAttributes, setPageAttributes] = useState<PageAttributesProps>();
    const [pageLoading, setPageLoading] = useState(true);

    const [comboLoading, setComboLoading] = useState(true);
    const [comboValue, setComboValue] = useState<string>();
    const [comboDataSource, setComboDataSource] = useState([]);

    const [modalProps, setModalProps] = useState<ScheduleModalProps>({ visible: false });

    const [refreshSchedule, setRefreshSchedule] = useState(true);

    const comboQueryName = pageAttributes?.comboQueryName;

    const handleNewSchedule = (props: GenericObject) => {
        const popupParams = { ...props, servico: comboValue } as GenericObject;
        setModalProps({ visible: true, popupParams });
    };

    const handleEditSchedule = (id?: string) => {
        setModalProps({ visible: true, id });
    };

    const handleOnSave = () => {
        setRefreshSchedule((prev) => !prev);
        setModalProps({ visible: false });
    };

    useEffect(() => {
        const loadComboData = async () => {
            try {
                if (!comboQueryName) {
                    return;
                }
                setComboLoading(true);
                const params = {
                    query: comboQueryName,
                };

                const {
                    response,
                    status,
                    message: messageError,
                } = await api_fetch({
                    endPoint: `Gestor/execQuery.php`,
                    method: "POST",
                    params,
                });

                if (status === "ERROR") {
                    message.error(messageError || "Ocorreu um erro");
                    setComboLoading(false);
                    return;
                }
                setComboDataSource(Utilitaries.toArray(response.result));
                setComboLoading(false);
            } catch (error) {
                setComboLoading(false);
            }
        };

        loadComboData();
    }, [comboQueryName]);

    useEffect(() => {
        const getPage = async () => {
            try {
                setPageLoading(true);
                const params = {
                    accao: "consultar",
                    nome: "ESPAÇOS",
                };
                const {
                    response,
                    status,
                    message: messageError,
                } = await api_fetch({
                    endPoint: `Gestor/gerecompagenda.php`,
                    method: "POST",
                    params,
                });
                if (status === "ERROR") {
                    message.error(messageError || "Ocorreu um erro");
                    return;
                }

                if (!response?.dados?.linha) {
                    message.error("Ocorreu um erro");
                    return;
                }
                const attributes = getPageAttributes(response.dados.linha);
                if (attributes) {
                    setPageAttributes(attributes);
                }
                setPageLoading(false);
            } catch (error) {
                setPageLoading(false);
            }
        };

        getPage();
    }, []);

    if (pageLoading) {
        return <Result icon={<Icon type="loading" />} title="Carregando..." />;
    }

    if (!pageAttributes) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Lamentamos, não foi possível carregar o formulário!"
                extra={
                    <Link to="/">
                        <Button type="primary">Voltar tela principal</Button>
                    </Link>
                }
            />
        );
    }

    return (
        <>
            <h3>{pageAttributes.title}</h3>
            <Row gutter={[16, 8]} type="flex" align="bottom">
                <Col md={8}>
                    <Form.Item label={pageAttributes.comboLabel}>
                        <Select
                            showSearch
                            allowClear
                            dropdownMatchSelectWidth={false}
                            style={{ width: "100%" }}
                            loading={comboLoading}
                            value={comboValue}
                            onChange={(value: any) => setComboValue(value)}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                const children = option.props.children?.toString() || "";
                                return children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
                            }}
                        >
                            {comboDataSource?.map((data: any, index) => {
                                const optionText = data["designacao"];
                                return (
                                    <Option key={`sala-${index}`} value={data["id_subunidade"]} title={optionText}>
                                        {optionText}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={16}>
                    <Form.Item>
                        <Button icon="plus" onClick={() => handleNewSchedule({ tipo_agendamento: "indefinido" })}>
                            Novo
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <MeetingRoomSchedule
                        comboValue={comboValue}
                        phpFile={pageAttributes?.php}
                        refreshSchedule={refreshSchedule}
                        handleEditSchedule={handleEditSchedule}
                        handleNewSchedule={handleNewSchedule}
                    />
                </Col>
            </Row>

            {modalProps.visible && (
                <Modal
                    title="Formulário"
                    visible={modalProps.visible}
                    footer={null}
                    onCancel={() => setModalProps({ visible: false })}
                    width={"80%"}
                    destroyOnClose
                >
                    <MenuFormRender
                        form_name={pageAttributes.formName}
                        popup_params={modalProps.popupParams}
                        onSave={handleOnSave}
                        form_id={modalProps.id}
                        isInModal={true}
                    />
                </Modal>
            )}
        </>
    );
};

export default MeetingRoomRequest;
