import React, { Component } from "react";
import { Row, Col, Form, Input, Checkbox, Select, Collapse } from "antd";
import { labelTooltip as LabelTooltip } from "../../../../../../components/util";
const { Panel } = Collapse;
const { Option } = Select;

const tiposData = [
    "Boleano (sim, não)",
    "Data",
    "Área de texto",
    "Decimal",
    "Lista dinâmica",
    "Lista estática",
    "Inteiro",
    "Sub-formulário",
    "Ficheiro",
    "Imagem",
    "Sugestão",
];
const graficElementData = {
    "Lista dinâmica": ["Escolha Múltipla", "Lista de valores"],
    "Boleano (Sim, Não)": ["Botões de seleção", "Lista de valores"],
    "Área de texto": ["Etiqueta", "Área de texto", "Área de texto (editor)"],
    "Lista estática": ["Escolha Múltipla", "Lista de valores"],
    Inteiro: ["Etiqueta", "Lista de valores"],
    "Sub-formulário": ["Icon de agenda", "Icon de lembretes"],
};

class FieldProperties extends Component {
    componentDidMount() {
        this.props.configModal({
            title: "Propriedades do campo",
            width: "60%",
            top: "10%",
        });
    }

    state = {
        graficElements: null,
        selectedType: null,
    };

    handleTipoChange = (value) => {
        this.setState({
            graficElements: graficElementData[value],
            selectedType: value,
        });
    };

    render() {
        const { graficElements } = this.state;
        return (
            <div>
                <Form layout="vertical">
                    <Collapse accordion defaultActiveKey={["1"]}>
                        <Panel header="Informações" key="1">
                            <Row gutter={18}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Etiqueta">
                                        <Checkbox>Visível</Checkbox>
                                        <Checkbox>Persiste</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="Etiqueta">
                                        <Input placeholder="Introduza etiqueta" />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={6}>
                                    <Form.Item label="Estilo">
                                        <Input placeholder="Introduza estilo" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="&nbsp;">
                                        <Checkbox>Quebra</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={18}>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Valor Inicial"
                                                description="Exemplo: $param.parametro_definido - Parâmetro definido, now() - Data Atual, now_year() - Data um ano depois."
                                            />
                                        }
                                    >
                                        <Input placeholder="Introduza valor inicial" allowClear />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={6}>
                                    <Form.Item label="Ajuda">
                                        <Input placeholder="Introduza ajuda" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Tipo"
                                                description="Indicar o tipo de campo a criar na base de dados."
                                            />
                                        }
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            // style={{ width: 200 }}
                                            placeholder="Selecione um tipo"
                                            onChange={this.handleTipoChange}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {tiposData.map((tipo) => (
                                                <Option key={tipo}>{tipo}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {graficElements == null ? null : (
                                    <Col xs={24} md={6}>
                                        <Form.Item
                                            label={
                                                <LabelTooltip
                                                    label="Elemento Gráfico"
                                                    description="Defini-se o elemento gráfico do campo que se pretende."
                                                />
                                            }
                                        >
                                            <Select
                                                placeholder="Selecione elemento gráfico"
                                                style={{ width: "100%" }}
                                                allowClear
                                                // value={this.state.secondCity}
                                                // onChange={this.onSecondCityChange}
                                            >
                                                {graficElements.map((city) => (
                                                    <Option key={city}>{city}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}

                                <Col xs={24} md={6}>
                                    <Form.Item label="Máscara">
                                        <Input placeholder="Introduza máscara" />
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={24} md={6}>
                  <Form.Item label="&nbsp;">
                    <Checkbox>Selecionar todos</Checkbox>
                    <Tooltip title="Exemplo: Para os campos que tenhan elemento gráfico = botoes_seleccao ou tipo = boolean.">
                      <Icon type="info-circle" />
                    </Tooltip>
                  </Form.Item>
                </Col> */}
                                <Col xs={24} md={18}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Dados"
                                                description="Exemplo: nome da query a guardar."
                                            />
                                        }
                                    >
                                        <Input placeholder="Introduza dados" />
                                    </Form.Item>
                                </Col>
                                {this.state.selectedType === "Lista dinâmica" ? (
                                    <div>
                                        <Col xs={24} md={24}>
                                            <Form.Item
                                                label={
                                                    <LabelTooltip
                                                        label="Campos"
                                                        description="Exemplo: designacao:text; lista_dinamica1:lista_qa - vai adicionar um campo designacao e vai ligar esta lista a lista dinâmica lista_qa através de uma lista de valores."
                                                    />
                                                }
                                            >
                                                <Input placeholder="Introduza dados" value="designacao:string" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Chave lista">
                                                <Input value="id_dsf" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Valor lista">
                                                <Input value="designacao" />
                                            </Form.Item>
                                        </Col>
                                    </div>
                                ) : null}
                            </Row>
                        </Panel>
                        <Panel header="Tab e Grupo" key="2">
                            <Row gutter={18}>
                                <Col xs={24} md={6}>
                                    <Form.Item label="Tab">
                                        <Input placeholder="Introduza dados" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Tab Relevante"
                                                description='Exemplo: ../nome_campo = "true" - neste caso só vai mostrar a tab e todos os components nela contidos caso o nome_campo seja true.'
                                            />
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="Grupo">
                                        <Input placeholder="Introduza grupo" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Relevante"
                                                description='Exemplo: ../nome_campo = "true" - neste caso só vai mostrar o grupo e todos os componentes nele contidos caso o nome_campo seja true.'
                                            />
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header="Ações" key="3">
                            <Row gutter={18}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Etiqueta">
                                        <Checkbox>Auto Preenche?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={18}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Nome ação">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Query Ação"
                                                description="Exemplo: 'texto(../campoX)' - vai buscar o texto do campoX; nome_query, var1, ../nome_campo, var2, ../nome_campo1, var3, ..nome_campo2 - Passa para a query a variável var1, var2, var3 definidas em cada campo."
                                            />
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Lançar Ações">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header="Restrições" key="4">
                            <Row gutter={18}>
                                <Col xs={24} md={24}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Relevante"
                                                description="Exemplo: ../nome_campo = 'true' - neste caso só vai mostrar o campo caso o nome_campo seja true."
                                            />
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Restrições"
                                                description="Exemplo: expressao(\d9) - Este Campo tem de ter 9 dígitos; tamanho_maximo(50) - Este campo só pode conter no máximo 50 carateres; nif - Vale como restrição a nifs Portugueses."
                                            />
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Apenas Leitura"
                                                description="Exemplo: ../nome_campo='true' - Este campo só vai ser possível alterar caso o nome_campo seja true."
                                            />
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Calcular"
                                                description="Exemplo: if(../campo!='') then 'sim' else 'não' - vai retornar sim caso o valor do campo seja diferente de vazio se não retorna não."
                                            />
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Obrigatório"
                                                description="Exemplo: ../nome_campo = 'true' - Este campo deixa de ser obrigatório caso o nome_campo seja true."
                                            />
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Form>
            </div>
        );
    }
}

export default FieldProperties;
