import React, { useEffect, useState } from "react";
import { Row, Col, Select, Popconfirm, Input, Checkbox } from "antd";

import { sendHttpRequest } from "../../http.request";
import { toArray } from "../../util";
import EditableFormTable from "./tableEditor";

const { Option } = Select;

const TAB_NAME = "suppliers";

export default function ClientTab({ dataSource, putDataSource, handleFieldsChange, addLine, removeLine }) {
    const [family, setFamily] = useState([]);

    // const [selectedFamily, setSelectedFamily] = useState("");

    // const [suppliers, setSuppliers] = useState([]);

    // const [historyData, setHistoryData] = useState({
    //     all: [],
    // });

    useEffect(() => {
        const getFamily = async () => {
            try {
                const formData = new FormData();
                formData.append("query", "stk:familias");

                const response = await sendHttpRequest("POST", "/Gestor/execQuery.php", formData);

                const sectors = toArray(response?.result);

                setFamily(sectors);
            } catch (error) {}
        };

        if (family.length === 0) {
            getFamily();
        }

        const getForColumns = async () => {
            try {
                if (dataSource.length === 0) {
                    return;
                }

                for (const client of dataSource) {
                    if (!client?.name?.dataSource) {
                        const formData = new FormData();
                        formData.append("query", "lista_fornecedores_todos");
                        formData.append("familia_produto", client.family || "");

                        const response = await sendHttpRequest("POST", "/Gestor/execQuery.php", formData);

                        const _suppliers = toArray(response?.result).map((it) => ({
                            id: it.id,
                            nome: it.fornecedor,
                            email: it.email,
                            telemovel: it.telemovel,
                        }));

                        putDataSource(TAB_NAME, client.key, "name", _suppliers);
                    }
                }
            } catch (error) {}
        };

        getForColumns();
    }, []);

    // useEffect(() => {
    //     const getSuppliers = async () => {
    //         try {
    //             // setSuppliers([]);

    //             const formData = new FormData();
    //             formData.append("query", "lista_info_fornecedores_menu_social");

    //             formData.append("familia_produto", selectedFamily || "");

    //             const response = await sendHttpRequest("POST", "/Gestor/execQuery.php", formData);

    //             const _suppliers = toArray(response?.result).map((it) => ({
    //                 id: it.fornecedor,
    //                 nome: it.fornecedor,
    //             }));

    //             // setSuppliers(_suppliers);

    //             setHistoryData({
    //                 ...historyData,
    //                 [selectedFamily ? selectedFamily : "all"]: _suppliers,
    //             });
    //         } catch (error) {}
    //     };

    //     if ((setSelectedFamily && !historyData[setSelectedFamily]) || (!setSelectedFamily && !historyData["all"])) {
    //         getSuppliers();
    //     }
    // }, [selectedFamily]);

    const getSuppliers = async (selectedFamily = "") => {
        try {
            // setSuppliers([]);

            const formData = new FormData();
            // formData.append("query", "lista_info_fornecedores_menu_social");
            formData.append("query", "lista_fornecedores_todos");

            formData.append("familia_produto", selectedFamily || "");

            const response = await sendHttpRequest("POST", "/Gestor/execQuery.php", formData);

            const _suppliers = toArray(response?.result).map((it) => ({
                id: it.id,
                nome: it.fornecedor,
                email: it.email,
                telemovel: it.telemovel,
            }));

            // setSuppliers(_suppliers);

            // setHistoryData({
            //     ...historyData,
            //     [row]: _suppliers,
            // });

            return _suppliers;
        } catch (error) {
            return [];
        }
    };

    const addNewLine = async () => {
        const data = await getSuppliers();

        addLine(TAB_NAME, { name: { value: "", dataSource: data } });

        // putDataSource(TAB_NAME, key, "name", data);
    };

    const columns = [
        {
            title: "Família",
            dataIndex: "family",
            width: "20%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : undefined;
                return (
                    <Select
                        mode="default"
                        style={{ width: "100%" }}
                        placeholder="Filtrar por família"
                        allowClear
                        showSearch
                        defaultValue={text}
                        onChange={async (value) => {
                            handleFieldsChange(TAB_NAME, "family", value, record);
                            const data = await getSuppliers(value);
                            putDataSource(TAB_NAME, record.key, "name", data);
                        }}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {family.map((e) => (
                            <Option value={e.id_familia}>{e.descricao}</Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Entidade",
            dataIndex: "name",
            width: "30%",
            render: (text, record) => {
                const dataSource = typeof text === "object" ? text?.dataSource || [] : [];
                const dValue = typeof text === "object" ? text?.value : text || "";
                return (
                    <Select
                        defaultValue={dValue}
                        onChange={(value) => {
                            const otherProps = {};
                            if (record?.send_email || record?.send_sms) {
                                const list = toArray(record?.name?.dataSource).find((it) => it.id === value);

                                if (list) {
                                    otherProps.email = record?.send_email ? list?.email || "" : "";
                                    otherProps.number = record?.send_sms ? list?.telemovel || "" : "";
                                }
                            }
                            handleFieldsChange(TAB_NAME, "name", value, record, otherProps);
                        }}
                        mode="default"
                        style={{ width: "100%" }}
                        placeholder="Selecionar fornecedor"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {toArray(dataSource).map((e) => (
                            <Option key={e.id} value={e.id}>
                                {e.nome}
                            </Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Enviar email",
            dataIndex: "send_email",
            width: "10%",
            render: (text, record) => {
                return (
                    <Checkbox
                        defaultChecked={text}
                        onChange={(e) => {
                            if (e.target.checked) {
                                const list = toArray(record?.name?.dataSource).find(
                                    (it) => it.id === record?.name?.value
                                );

                                if (list) {
                                    record.email = list?.email || "";
                                }
                            } else {
                                record.email = "";
                            }

                            handleFieldsChange(TAB_NAME, "send_email", e.target.checked, record);
                        }}
                    >
                        Email
                    </Checkbox>
                );
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "30%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : null;
                return record.send_email ? (
                    <Input
                        placeholder="Introduza o email"
                        onChange={(e) => handleFieldsChange(TAB_NAME, "email", e.target.value, record)}
                        value={text}
                    />
                ) : null;
            },
        },
        {
            title: "Enviar SMS",
            dataIndex: "send_sms",
            width: "10%",
            render: (text, record) => {
                return (
                    <Checkbox
                        defaultChecked={text}
                        onChange={(e) => {
                            if (e.target.checked) {
                                const list = toArray(record?.name?.dataSource).find(
                                    (it) => it.id === record?.name?.value
                                );

                                if (list) {
                                    record.number = list?.telemovel || "";
                                }
                            } else {
                                record.number = "";
                            }
                            handleFieldsChange(TAB_NAME, "send_sms", e.target.checked, record);
                        }}
                    >
                        SMS
                    </Checkbox>
                );
            },
        },
        {
            title: "Telefone",
            dataIndex: "number",
            width: "20%",
            render: (text, record) => {
                // text = typeof text !== "object" && text !== "" ? text : null;
                return record.send_sms ? (
                    <Input
                        placeholder="Introduza o número"
                        onChange={(e) => handleFieldsChange(TAB_NAME, "number", e.target.value, record)}
                        value={text}
                        type="number"
                    />
                ) : null;
            },
        },
        {
            title: "Ação",
            dataIndex: "actions",
            width: "10%",
            render: (text, record) => (
                <Popconfirm
                    title="Tens certeza que desejas eliminar?"
                    onConfirm={() => removeLine(TAB_NAME, record.key)}
                >
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Eliminar
                    </a>
                </Popconfirm>
            ),
        },
    ];

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <EditableFormTable dataSource={dataSource} addLine={addNewLine} columns={columns} />
            </Col>
            {/* <Col span={8}>
                <Select
                    mode="default"
                    style={{ width: "100%" }}
                    placeholder="Filtar por família"
                    allowClear
                    showSearch
                    onChange={(value, option) => setSelectedFamily(value)}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {family.map((e) => (
                        <Option value={e.id_familia}>{e.descricao}</Option>
                    ))}
                </Select>
            </Col>

            <Col span={24}>
                <Transfer
                    dataSource={suppliers}
                    showSearch
                    listStyle={{
                        width: 360,
                        height: 400,
                    }}
                    filterOption={(inputValue, option) => option.description.indexOf(inputValue) > -1}
                    targetKeys={selectedSuppliers}
                    onChange={handleChange}
                    // onSearch={handleSearch}
                    render={(item) => item.title}
                />
            </Col> */}
        </Row>
    );
}
