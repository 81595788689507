import { Col, Form, Input, InputNumber } from "antd";
import React, { useContext, memo, useState, useEffect, useMemo } from "react";

import FormContext from "../context/FormContext";
import { QADivider, QAToolTip } from "./";
import { input_validations, getAllDependencies } from "../utils/validations";
import { sendHttpRequest } from "../../../components/http.request";
import { getElementAction, addDoubleQuotesToParams, hasInputCalculate, getInputLabel } from "../utils/functions";
import { Utilitaries } from "../../../business";
import { LineBreak } from "./lineBreak";

const dateStyles = {
    width: "100%",
};

const QaInput = ({
    inputAttribute,
    value,
    field,
    visible,
    disabled,
    hasError,
    objectInputs,
    renderGroup,
    isRequired,
    colSize,
    ...inputProps
}) => {
    const {
        changeInputValue,
        justSee,
        params: formParams,
        formData,
        updateError,
        formErrors,
        flagAddTable,
        validateField,
        isInitial,
    } = useContext(FormContext);
    const [currentValue, setCurrentValue] = useState();
    const [error, setError] = useState(null);

    let changeValue = null;

    if (inputAttribute["@quando_valor_alterado"]) {
        // changeValue = debounce(changeInputValue, 500);
        changeValue = changeInputValue;
    } else {
        changeValue = changeInputValue;
    }

    // Get value
    let valueDep = [];
    let inputActionsName = null;

    const elementAction = getElementAction({ item: inputAttribute });
    if (elementAction) {
        valueDep = getAllDependencies(elementAction.valueDep);
        inputActionsName = elementAction.inputActionsName;
    }

    // const inputActions = hasInputAction({
    //     key: field,
    //     input: inputAttribute,
    //     formInputs: objectInputs,
    //     formData,
    //     options: inputProps,
    // });

    useEffect(() => {
        if ((currentValue && value) || currentValue !== value) {
            setCurrentValue(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(
        () => {
            const getData = async () => {
                try {
                    let expression = input_validations({
                        item: inputAttribute,
                        relKey: inputActionsName,
                        returnExpression: true,
                        formData,
                        inputProps,
                    });

                    expression = addDoubleQuotesToParams(expression);

                    const expressionValue = await eval(`${expression}`);

                    const resultKeys = typeof expressionValue === "object" ? Object.keys(expressionValue) : null;
                    const inputValue = resultKeys
                        ? resultKeys.length === 1
                            ? expressionValue[resultKeys[0]]
                            : expressionValue[inputAttribute["@chave_lista"]]
                        : expressionValue;

                    changeInputValue({
                        value: inputValue,
                        key: field,
                        inputAttribute,
                        appLoading: true,
                        noMessage: true, // nao mostrar pop up com mesagem de campo errado
                        ...inputProps,
                    });
                } catch (error) {
                    console.error(error, field, inputAttribute);
                }
            };

            if (
                !inputAttribute[inputActionsName] ||
                !formData ||
                (inputProps?.inTable && inputProps.items.data[field] && isInitial) ||
                (inputAttribute?.["@action_with_value"] === "false" &&
                    isInitial &&
                    ((inputProps?.inTable && inputProps.items.data[field]) || value))
                //  ||
                // valueDep.filter((it) => {
                //     if (inputProps.items.data[it]) return false;
                //     if (formData[it]) return false;
                //     return true;
                // }).length > 0
            ) {
                return;
            } else {
                // formulario Nota de Credito - não carregar preso porque ja vem no query que carrega os dados na tabela
                if (
                    inputProps?.inTable &&
                    inputProps.items.data[field] &&
                    formParams &&
                    formParams?.tipodoc === "NC" &&
                    (field === "preco" || field === "descricao" || field === "taxaiva")
                ) {
                    return;
                }
            }

            getData();
        },
        //eslint(react-hooks/exhaustive-deps)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueDep
            .filter((it) => it !== field)
            .map((it) => {
                if (inputProps?.items?.data[it]) return inputProps.items.data[it];

                return formData ? formData[it] : null;
            })
    );

    const hasCalculation = hasInputCalculate({ input: inputAttribute, formData, options: inputProps });

    // eslint-disable-next-line no-unused-vars
    const round = (x) => x;
    const texto = (x) => x;

    useEffect(
        () => {
            if (!inputAttribute?.["@calcular"]) {
                return;
            }

            let expression = input_validations({
                item: inputAttribute,
                relKey: "@calcular",
                formData: formData,
                functions: {},
                returnExpression: true,
                inputProps,
            });

            // expression = expression.replace(/=/g, "==");
            try {
                const _value = eval(expression);

                changeInputValue({
                    value: Utilitaries.handleValues(_value), // valor selecionado  in value
                    key: field, // Nome do campo
                    inputAttribute, // input atributos
                    appLoading: true,
                    ...inputProps, // propriedades da tabela
                    callback() {
                        // hasError({ [field]: _value });
                    },
                });
            } catch (error) {
                console.error(error);
            }
        },
        // hasCalculation.hasCalculation ? hasCalculation?.params?.map((it) => it.value) : []
        // eslint-disable-next-line react-hooks/exhaustive-deps
        hasCalculation.hasCalculation
            ? hasCalculation?.params
                  .filter((it) => it.key !== field)
                  .map((it) => {
                      if (inputProps?.items?.data[it.key]) return inputProps.items.data[it.key];

                      return formData ? formData[it.key] : null;
                  })
            : []
    );

    const auto_preenche_valor = async (query, ...params) => {
        const fd = new FormData();
        fd.append("query", query);
        fd.append("accao", "query");

        let nullValues = false;
        params
            .map((it) => it.replace(/[{}\s]/g, "").split(","))
            .forEach((it) => {
                // eslint-disable-next-line no-eval
                const val = eval(it[1]);

                if (
                    (!val || val === undefined) &&
                    query !== "equip_preenche_nome" &&
                    !Utilitaries.toString(it?.[0]).startsWith("id_")
                ) {
                    nullValues = true;
                }

                fd.append(it[0], val === "t" ? "true" : val);
            });

        if (nullValues) {
            return;
        }
        try {
            const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", fd);
            return data.result[0];
        } catch (error) {
            return null;
        }
    };

    // eslint-disable-next-line no-unused-vars
    const auto_preenche = auto_preenche_valor;

    useEffect(() => {
        if (inputProps.inTable) {
            if (flagAddTable.hasOwnProperty(inputProps?.items?.table)) {
                setError(hasError({ [field]: value }));
            }
        } else if (updateError) {
            setError(formErrors[field]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateError, flagAddTable[inputProps?.items?.table]]);

    // required and restriction dependencies

    let valueDepValidation = [];
    if (inputAttribute["@obrigatorio"] && Utilitaries.toString(inputAttribute?.["@obrigatorio"])?.includes("/")) {
        valueDepValidation = getAllDependencies(inputAttribute["@obrigatorio"]) || [];
    }
    if (inputAttribute["@restricao"] && Utilitaries.toString(inputAttribute?.["@restricao"])?.includes("/")) {
        const dependences = getAllDependencies(inputAttribute["@restricao"]) || [];
        valueDepValidation = [...new Set(valueDepValidation.concat(...dependences))];
    }

    useEffect(
        () => {
            if (valueDepValidation.length === 0) {
                return;
            }

            validateField({
                value,
                key: field,
                showErrorInModal: false,
                inputAttribute,
                ...inputProps,
                visible,
            });

            if (updateError) {
                const _err = hasError({ [field]: value });
                setError(_err);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueDepValidation
            .filter((it) => it !== field)
            .map((it) => {
                if (inputProps?.items?.data[it]) return inputProps.items.data[it];

                return formData ? formData[it] : null;
            })
    );

    if (!visible) {
        return null;
    }

    if (inputAttribute["@visivel"] && inputAttribute["@visivel"] === "nao") {
        return null;
    }

    const RenderInput = () => {
        const changeInputValue = (e) => {
            const _err = hasError({ [field]: e.target.value });
            setError(_err);
            changeValue({
                value: e.target.value,
                key: field,
                inputAttribute,
                noMessage: true, // nao mostrar pop up com mesagem de campo errado
                ...inputProps,
            });
        };

        let customPropsInputChanges = { onChange: changeInputValue };

        // if (inputAttribute["@update_on_blur"] === "true") {
        customPropsInputChanges = {
            onChange: (e) => setCurrentValue(e.target.value),
            onBlur: (e) => changeInputValue(e),
        };

        return (
            <Input
                disabled={justSee || disabled}
                placeholder={null}
                // value={value}
                type={
                    Utilitaries.toString(inputAttribute["@mascara"]).includes("password_simples") ? "password" : "text"
                }
                value={currentValue}
                {...customPropsInputChanges}
                style={{
                    minWidth: 150,
                }}
            />
        );
    };

    const RenderInputNumber = () => {
        const changeInputNumberValue = (value) => {
            changeInputValue({
                value,
                key: field,
                inputAttribute,
                noMessage: true, // nao mostrar pop up com mesagem de campo errado
                ...inputProps,
            });

            const _err = hasError({ [field]: value });
            setError(_err);
        };

        let customPropsInputChanges = { onChange: changeInputNumberValue };

        // if (inputAttribute["@update_on_blur"] === "true") {
        customPropsInputChanges = {
            onChange: setCurrentValue,
            onBlur: (e) => changeInputNumberValue(e.target.value),
        };

        if (!Utilitaries.isEmpty(inputAttribute?.["@casas_decimais"])) {
            const precision = Number(inputAttribute?.["@casas_decimais"]);
            if (!Utilitaries.isEmpty(precision)) {
                customPropsInputChanges.precision = precision;
            }
        }
        if (!Utilitaries.isEmpty(inputAttribute?.["@mascara"]) && inputAttribute?.["@mascara"] === "positivo") {
            customPropsInputChanges.min = 0;
        }

        return (
            <InputNumber
                style={{ ...dateStyles, minWidth: 150 }}
                disabled={justSee || disabled}
                placeholder={null}
                value={currentValue}
                precision={inputAttribute["@tipo"] === "integer" ? 0 : null}
                {...customPropsInputChanges}
                // onChange={(value) => {
                //     changeInputValue({
                //         value,
                //         key: field,
                //         inputAttribute,
                //         noMessage: true, // nao mostrar pop up com mesagem de campo errado
                //         ...inputProps,
                //     });

                //     const _err = hasError({ [field]: value });
                //     setError(_err);
                // }}
            />
        );
    };

    if ((inputAttribute[0] === "{" || inputAttribute["0"] === "1") && field === "modelo") {
        return (
            <Col xs={8}>
                <Form.Item
                    label={
                        <QAToolTip
                            label={inputAttribute["@etiqueta"] || field.toPascalCase()}
                            help={inputAttribute["@ajuda"]}
                            isRequired={isRequired}
                        />
                    }
                >
                    {<span>1</span>}
                </Form.Item>
            </Col>
        );
    }

    const { inTable } = inputProps;

    return inTable ? (
        <>
            {(inputAttribute["@mascara"] && ["decimal"].includes(inputAttribute["@mascara"])) ||
            (inputAttribute["@tipo"] && (inputAttribute["@tipo"] === "float" || inputAttribute["@tipo"] === "integer"))
                ? RenderInputNumber()
                : RenderInput()}
            {error && <span style={{ color: "red" }}>{error}</span>}
        </>
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            {<LineBreak item={inputAttribute} />}
            <Col xs={colSize}>
                <Form.Item
                    label={
                        <QAToolTip
                            label={getInputLabel(inputAttribute, field)}
                            help={inputAttribute["@ajuda"]}
                            isRequired={isRequired}
                        />
                    }
                >
                    {(inputAttribute["@mascara"] && ["decimal"].includes(inputAttribute["@mascara"])) ||
                    (inputAttribute["@tipo"] &&
                        (inputAttribute["@tipo"] === "float" || inputAttribute["@tipo"] === "integer"))
                        ? RenderInputNumber()
                        : RenderInput()}
                    {error && <span style={{ color: "red" }}>{error}</span>}
                </Form.Item>
            </Col>
        </>
    );
};

export default memo(QaInput);
