import { actionType, lista } from "../constants/knowledge";
import { sendHttpRequest } from "../../../components/http.request";

export const select = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "lista");
    formData.append("lista", lista);
    formData.append("resultados_pagina", 0);

    Object.entries(payload).forEach(([key, value]) => {
        formData.append(key, value);
    });

    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse)
            dispatch({
                type: actionType.select,
                payload: jsonResponse["dados"],
            });
    });
};

export const getForm = (payload) => async (dispatch) => {
    if (Object.entries(payload).length === 0 && payload.constructor === Object) {
        const formData = new FormData();
        formData.append("accao", "dados");
        formData.append("lista", lista);
        await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
            dispatch({ type: actionType.getForm, payload: jsonResponse });
        });
    }
};

export const insert = (payload) => async (dispatch) => {
    const formData = new FormData();
    const obj = {};
    obj[payload.formulario.elemento_raiz] = payload.values;
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario.nome);
    formData.append("dados", JSON.stringify(obj));

    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            dispatch({ type: actionType.insert });
            return true;
        } else {
            return false;
        }
    });
};

export const setField = (payload) => ({ type: actionType.setField, payload });

export const getById = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista);
    formData.append("chave1", payload);
    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        dispatch({ type: actionType.getById, payload: jsonResponse });
    });
};

export const update = (payload) => async (dispatch) => {
    const formData = new FormData();
    const obj = {};
    obj[payload.formulario.elemento_raiz] = payload.values;
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario.nome);
    formData.append("dados", JSON.stringify(obj));
    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse.sucesso ? true : false;
    });
};

export const remove = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "eliminar");
    formData.append("lista", lista);
    formData.append("chave1", payload);
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        return jsonResponse.sucesso ? true : false;
    });
};
