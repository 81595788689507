import ACTIONS_TYPES from "../constants/index";
import { sendHttpRequest } from "../../http.request";

export const getDefinedEvents = (requestBody = {}) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    formData.append("accao", "lista");

    return await sendHttpRequest("POST", "/Gestor/gereagendadefinido.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.GET_DEFINED_EVENTS,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const getEventsPermission = (requestBody = {}) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    formData.append("accao", "lista_permissoes");

    return await sendHttpRequest("POST", "/Gestor/gereagenda.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.GET_EVENTS_PERMISSION,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const getEventsPlaces = (requestBody = {}) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    formData.append("accao", "lista");

    return await sendHttpRequest("POST", "/Gestor/gerelocal.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.GET_EVENTS_PLACES,
                payload: jsonResponse,
            });
            return true;
        }
    });
};

export const getEventsPermissionGroups = (requestBody = {}) => async (dispatch) => {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
        formData.append(key, value);
    });

    formData.append("accao", "grupos_disponiveis_agenda");

    return await sendHttpRequest("POST", "/Gestor/gereagenda.php", formData).then((jsonResponse) => {
        if (jsonResponse.erro) return false;
        else {
            dispatch({
                type: ACTIONS_TYPES.GET_EVENTS_PERMISSION_GROUPS,
                payload: jsonResponse,
            });
            return true;
        }
    });
};
