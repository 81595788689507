import React, { useMemo } from "react";
import { Form } from "antd";
import QaDynamicSelect from "../../../../components/DynamicList/dynamicSelect";
import { nullSafe, canCorrectField } from "../../utils/fields";
import { toArray } from "../../../../components/util";
import { Utilitaries } from "../../../../business";

const QaDynamicList = ({
    root,
    itemData,
    currentTaskStatus,
    onChange,
    label,
    formItemStyle,
    handleSelectChange,
    correctForm,
    rowKey,
    error,
    dataSource,
    readonly,
    formItems,
    record,
    modalParams,
    tableDataSourceLength,
}) => {
    const handleOnChange = (value) => {
        onChange(value);
    };

    let key_list = itemData["@valor_lista"];

    if (typeof key_list === "string" && key_list.includes(";")) {
        key_list = key_list.split(";")[0];
    }

    if (typeof key_list === "string" && key_list.includes(":")) {
        key_list = key_list.split(":")[0];
    }

    let hasDependence = Object.keys(itemData).find(
        (it) =>
            it.startsWith("@accao_") &&
            itemData[it]?.startsWith("auto_preenche") &&
            !itemData[it]?.startsWith("auto_preenche_valor")
    );

    let listFilter = null;

    if (hasDependence && dataSource && typeof dataSource === "object") {
        listFilter = {
            filterName: toArray(Object.keys(dataSource))[0],
            filterValue: toArray(Object.values(dataSource))[0] || record?.[toArray(Object.keys(dataSource))[0]]?.id,
        };
    } else {
        const fieldList = Utilitaries.toString(itemData?.["@campos_lista"])?.split(";");
        const numberOfFields = fieldList?.length;
        if (numberOfFields > 1) {
            const targetField = Utilitaries.toString(fieldList[numberOfFields - 1]).split(":")[0];

            // if (targetField /*&& modalParams?.[targetField]*/) {
            if (targetField && modalParams?.[targetField]) {
                // Planeamento de avaliações FPR
                listFilter = {
                    filterName: targetField,
                    filterValue: modalParams?.[targetField] || record?.[targetField],
                };
            }
        }
    }

    const formKeysToBeDisabled = ["pe_obj_definicao", "pe_metas_intermediarias"];
    const formNamesToBeDisabled = [
        "qualia_dr94.01.01_pe_elaboracao_objetivos.qml.xml",
        "qualia_dr94.01.01_pe_elaboracao_metas_intermediarias.qml.xml",
    ];

    return (
        <Form.Item label={label} style={formItemStyle}>
            <QaDynamicSelect
                disabled={
                    formKeysToBeDisabled.includes(itemData.formKey) && formNamesToBeDisabled.includes(itemData.formName)
                        ? true
                        : currentTaskStatus || readonly
                        ? !(canCorrectField(itemData) && correctForm)
                        : currentTaskStatus || readonly
                }
                name={itemData.key}
                listKey={itemData["@chave_lista"]}
                value={nullSafe(itemData.value)}
                col={24}
                tableLineNumber={Utilitaries.toNumber(rowKey, true)}
                listValue={key_list}
                onChange={handleOnChange}
                record={record}
                defaultDataSource={listFilter}
                tableDataSourceLength={tableDataSourceLength}
            />
            {error && <span style={{ color: "red" }}>{error}</span>}
        </Form.Item>
    );
};

export default QaDynamicList;
