import { isEmpty, toString } from "./utils";

export const save = ({ username, name, token, photoURL, type, isFirstLogin, parametro1, parametro2, parametro3 }) => {
    sessionStorage.setItem(
        "user",
        JSON.stringify({
            username,
            name,
            token,
            type,
            photoURL,
            isFirstLogin,
            parametro1,
            parametro2,
            parametro3
        })
    );
    if (username !== "_portal_") {
        const tempSaveData = btoa(username);
        localStorage.setItem("llus", tempSaveData);
    }
};

export const getAll = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user;
    } catch (error) {
        return {};
    }
};

export const update = ({ username, name, token, type, isFirstLogin }) => {
    const current = getAll();
    const newState = Object.assign({}, current, { username, name, token, type, isFirstLogin });
    sessionStorage.setItem("user", JSON.stringify(newState));

    const tempSaveData = btoa(username);
    localStorage.setItem("llus", tempSaveData);
};

export const getToken = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user.token;
    } catch (error) {
        return "";
    }
};

export const getType = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user.type;
    } catch (error) {
        return false;
    }
};

export const getPhotoUrl = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user.photoURL || "";
    } catch (error) {
        return false;
    }
};

export const isPortal = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user.type === "portal";
    } catch (error) {
        return false;
    }
};

export const isCLient = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user.type === "cliente";
    } catch (error) {
        return false;
    }
};

export const isFirstLogin = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user.isFirstLogin === "true";
    } catch (error) {
        return false;
    }
};

export const isClient = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user.type === "cliente";
    } catch (error) {
        return "";
    }
};

export const isPortalOrClient = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);

        if (user.type === "portal" || user.type === "cliente") {
            return true;
        }

        return false;
    } catch (error) {
        return false;
    }
};

export const isAuthenticated = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return !isEmpty(user.token);
    } catch (error) {
        return false;
    }
};

export const getUsername = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user.username;
    } catch (error) {
        return "";
    }
};

export const getName = () => {
    let user = sessionStorage.getItem("user");
    try {
        user = JSON.parse(user);
        return user.name;
    } catch (error) {
        return "";
    }
};

export const remove = () => {
    sessionStorage.clear();
};

export const getLastLoginUsername = () => {
    const last = localStorage.getItem("llus");

    if (!last) {
        return "";
    }

    return atob(last) || "";
};

export const lockScreen = () => {
    localStorage.setItem("slocked", "true");
};

export const isLockedScreen = () => {
    return toString(localStorage.getItem("slocked")) === "true";
};

export const unlockScreen = () => {
    localStorage.removeItem("slocked");
};
