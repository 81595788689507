import React from "react";
import { Card, List, Skeleton } from "antd";

function IndexSkeleton({ current, pageSize, total }) {
    return (
        <List
            itemLayout="vertical"
            grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 4,
                xxl: 4,
            }}
            size="default"
            pagination={{
                current,
                total,
                pageSize,
                showTotal: (total) => `Total registos: ${total}`,
                showSizeChanger: true,
                pageSizeOptions: [12, 24, 48, 72, 96],
                disabled: true,
            }}
            dataSource={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            renderItem={() => (
                <List.Item>
                    <Card title="" bordered={false} style={{ width: 350 }}>
                        <Skeleton paragraph={{ rows: 3 }} />
                    </Card>
                </List.Item>
            )}
        />
    );
}

export default IndexSkeleton;
