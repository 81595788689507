import React, { useEffect, useState } from "react";
import { PageHeader, Divider, Table, Input, Button, Icon, Row, Col, Popconfirm, Pagination, message } from "antd";
import ButtonsGroup from "./buttons.group";
import FormAuthorization from "./formAuthorization";
import { getAuthorizations, remove } from "./../actions/index";
import { Session, Utilitaries } from "../../../business";

const AuthorizationsIndex = () => {
    const [filter, setfilter] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });
    const [modalData, setModalData] = useState({
        isVisible: false,
        readOnly: false,
    });
    const [isDeleting, setIsDeleting] = useState(false);

    // Get form from API
    useEffect(() => {
        setLoadingData(true);

        const { sorter, ...objFilter } = filter;

        const values = {
            ...objFilter,
            pagina: pagination.page,
            resultados_pagina: pagination.perPage,
        };
        if (sorter) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }

        getAuthorizations(values).then((response) => {
            setDataSource(response.lista);
            setMetadata(response.metadata);
            setLoadingData(false);
        });
    }, [pagination, filter]);

    const updateList = () => {
        const values = {
            ...filter,
            pagina: pagination.page,
            resultados_pagina: pagination.perPage,
        };
        getAuthorizations(values).then((response) => {
            setDataSource(response.lista);
            setMetadata(response.metadata);
            setLoadingData(false);
        });
    };

    //---------------------------- filter --------------------------------

    const getColumnSearchProps = (dataIndex) => {
        let searchInput = React.createRef();
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys}
                        onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
            filteredValue: filter[`filtro_${dataIndex}`] || null,
        };
    };
    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const getColumnSortProps = (dataIndex, sortedInfo) => ({
        sorter: true,
        sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
    });

    const handleTableChange = (_pagination, filters, sorter) => {
        const obj = {};
        if (filters) {
            Object.entries(filters).forEach(([key, val]) => {
                if (val) obj["filtro_" + key] = val;
            });
        }

        if (sorter.columnKey) {
            obj["sorter"] = {
                columnKey: sorter.columnKey,
                order: sorter.order,
            };
        }

        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter(obj);
    };
    //---------------------------- endfilter -----------------------------
    const handleAdd = () => {
        setModalData({
            isVisible: true,
            designacao: null,
            descricao: null,
        });
    };
    const handleOk = () => {
        setModalData({
            isVisible: false,
        });
        setIsDeleting(false);
        setLoadingData(true);
        updateList();
    };
    const handleCancel = () => {
        setModalData({
            isVisible: false,
        });
        setIsDeleting(false);
    };
    const handleEdit = (record) => {
        setModalData({
            isVisible: true,
            readOnly: false,
            ...record,
        });
    };

    const handleShow = (record) => {
        setModalData({
            isVisible: true,
            readOnly: true,
            ...record,
        });
    };

    const handleDelete = (id, record) => {
        setLoadingData(true);
        remove(id).then((response) => {
            if (response && response.sucesso) {
                message.success("Eliminado com sucesso");
                updateList();
            } else {
                if (
                    response &&
                    Utilitaries.toString(response.erro) ===
                        "Erro a eliminar a autorização: Erro a executar um pedido à base de dados."
                ) {
                    setModalData({
                        isVisible: true,
                        readOnly: true,
                        ...record,
                    });
                    setIsDeleting(true);
                } else {
                    message.error(
                        response.erro ||
                            "Não pode eliminar esta autorização. Ela está associada a algum menu ou utilizador/unidade orgânica"
                    );
                }
                setLoadingData(false);
            }
        });
    };

    const showChangeAndDelete = (record) => {
        if (record.defeito === "0") {
            if (Session.getUsername() === "qa") return true;
            else return false;
        } else {
            return true;
        }
    };

    const sortedInfo = filter.sorter || {};

    const columns = [
        {
            title: "Designação",
            dataIndex: "descricao",
            ...getColumnSearchProps("descricao"),
            ...getColumnSortProps("descricao", sortedInfo),
        },
        {
            title: "Descrição",
            dataIndex: "designacao",
            ...getColumnSearchProps("designacao"),
            ...getColumnSortProps("designacao", sortedInfo),
        },
        {
            title: "Ações",
            dataIndex: "actions",
            width: "30%",
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                        onClick={() => handleShow(record)}
                    >
                        Consultar
                    </Button>
                    {showChangeAndDelete(record) && (
                        <>
                            <Divider type="vertical" />
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                }}
                                onClick={() => handleEdit(record)}
                            >
                                Editar
                            </Button>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="Tens certeza que desejas eliminar?"
                                onConfirm={() => handleDelete(record.idautorizacao, record)}
                            >
                                <Button
                                    type="link"
                                    style={{
                                        padding: 0,
                                        color: "#fa541c",
                                    }}
                                >
                                    Eliminar
                                </Button>
                            </Popconfirm>
                        </>
                    )}
                </>
            ),
        },
    ];

    const pageChange = (page, perPage) => {
        setPagination({
            page,
            perPage,
        });
    };

    const clearFormFilters = () => {
        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter({});
    };

    return (
        <>
            <PageHeader title="Autorizações" />
            <br />
            <Row>
                <Col xs={24} md={24}>
                    <ButtonsGroup filter={filter} handleAdd={handleAdd}></ButtonsGroup>
                    {Object.keys(filter).length !== 0 && (
                        <Button
                            onClick={clearFormFilters}
                            type="link"
                            size="default"
                            style={{
                                marginBottom: 16,
                                marginLeft: 8,
                                float: "right",
                            }}
                        >
                            Limpar filtros
                        </Button>
                    )}
                </Col>
            </Row>
            <Table
                size="middle"
                rowKey={(data) => data.idautorizacao + "-table"}
                pagination={false}
                dataSource={dataSource}
                loading={loadingData}
                columns={columns}
                onChange={handleTableChange}
            />
            <Row>
                <Pagination
                    style={{ float: "right", marginTop: 16 }}
                    current={metadata.pagina}
                    defaultPageSize={metadata.resultados_pagina}
                    size="small"
                    total={metadata.resultados}
                    showSizeChanger
                    showTotal={(total) => `Total de ${total} registo.`}
                    onShowSizeChange={pageChange}
                    onChange={pageChange}
                />
            </Row>
            {modalData.isVisible && (
                <FormAuthorization
                    modalData={modalData}
                    handleOk={handleOk}
                    isDeleting={isDeleting}
                    handleCancel={handleCancel}
                />
            )}
        </>
    );
};

export default AuthorizationsIndex;
