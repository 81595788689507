export const actionType = {
    select: "SELECT_KNOWLEDGE_TYPE",
    insert: "INSERT_KNOWLEDGE_TYPE",
    getForm: "GET_FORM_KNOWLEDGE_TYPE",
    setField: "SET_FIELD_KNOWLEDGE_TYPE",
    getById: "GET_KNOWLEDGE_TYPE_BY_ID",
    // update: "UPDATE_KNOWLEDGE_TYPE",
    delete: "DELETE_KNOWLEDGE_TYPE",
};

export const apiUrl = {
    insert: "",
    update: "",
    remove: "",
};
export const lista = "tipos_conhecimentos";
