import React from "react";
import { Col } from "antd";
import { Utilitaries } from "../../../business";

const LineBreak = ({ item }) => {
    if (Utilitaries.toString(item?.["@estilo"]).includes("quebra")) {
        return <Col span={24} />;
    }
    return null;
};

export { LineBreak };
