import React from "react";
import { Row, Button, Tooltip, Icon } from "antd";

const ButtonsGroup = (props) => {
    return (
        <Row>
            <Button
                onClick={props.showModal}
                type="primary"
                style={{ float: "right", marginLeft: 8, marginBottom: 16 }}
            >
                <Icon type="plus" />
                Inserir
            </Button>
            <Tooltip title="Exportar para pdf">
                <Button
                    onClick={() => props.exportList("pdf")}
                    icon="file-pdf"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#fa541c",
                    }}
                />
            </Tooltip>
            <Tooltip title="Exportar para excel">
                <Button
                    onClick={() => props.exportList("xls")}
                    icon="file-excel"
                    size="default"
                    style={{
                        marginBottom: 16,
                        marginLeft: 8,
                        float: "right",
                        color: "#237804",
                    }}
                />
            </Tooltip>
        </Row>
    );
};

export default ButtonsGroup;
