import React, { useState, useEffect } from "react";
import { Modal, Input, message, Tabs, Button, Checkbox } from "antd";
import { connect } from "react-redux";

// import FormComponent from "../Form";
import {
    changeForumConfModalState,
    createRooms,
    saveTopicsPermissions,
    getForumRooms,
    getTopicsPermission,
    getUserForumRooms,
} from "./actions/index";
import { QADynamicTable } from "../qa_custom/index";
import SelectUserAndGroup from "./components/SelectUserAndGroup";
import QADynamicSelect from "../DynamicList/dynamicSelect";

import { themes } from "./constants/index";

const { TabPane } = Tabs;

const ForumConf = ({
    rooms,
    modalForumConfState,
    changeForumConfModalState,
    createRooms,
    saveTopicsPermissions,
    getForumRooms,
    getTopicsPermission,
    topics_permissions,
    getUserForumRooms,
}) => {
    const [selectTab, setSelectTab] = useState("salas");
    const [listOfRooms, setListOfRooms] = useState([]);
    const [listOfTopicsPermissions, setListOfTopicsPermissions] = useState({
        users: [],
        groups: [],
    });
    const [permissionsType, setPermissionsType] = useState("rooms");
    const [selectTableRow, setSelectTableRow] = useState(null);
    const [isAutorizationVisivel, setIsAutorizationVisivel] = useState(false);
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);

    // para permisao de setor de atividade
    const [selectSectorPermission, setSelectSectorPermission] = useState(null);
    const [localSectionPermissions, setLocalSectionPermissions] = useState([]);

    // const [
    //     listOfAllSectionPermissions,
    //     setListOfAllSectionPermissions
    // ] = useState({});

    useEffect(() => {
        getForumRooms();
        getTopicsPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //

        if (selectTableRow) {
            setUsers(selectTableRow.permissions.users);
            setGroups(selectTableRow.permissions.groups);
        }
    }, [selectTableRow]);

    useEffect(() => {
        if (rooms.length === 1 && Object.keys(rooms[0]).length === 0) {
            setListOfRooms([]);
        } else {
            const _getPermissions = (item) => {
                if (item.aberto == "t") {
                    return {
                        users: [],
                        groups: [],
                    };
                }

                if (item.permissao && item.permissao.linha) {
                    let permissions = Array.isArray(item.permissao.linha)
                        ? item.permissao.linha
                        : item.permissao.linha
                        ? [item.permissao.linha]
                        : [];

                    let _users = permissions.filter(
                        (item) => typeof item.grupo === "object" && typeof item.nome_grupo === "object"
                    );
                    let _groups = permissions.filter(
                        (item) => typeof item.username === "object" && typeof item.nome === "object"
                    );

                    _users = _users.map((item) => ({
                        username: item.username,
                        name: item.nome,
                    }));

                    _groups = _groups.map((item) => ({
                        id: item.grupo,
                        value: item.nome_grupo,
                    }));

                    return {
                        users: _users,
                        groups: _groups,
                    };
                }
            };
            const newRows = rooms.map((it) => {
                return {
                    ...it,
                    permissions: _getPermissions(it),
                };
            });
            setListOfRooms(newRows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rooms]);

    // tratar lista de permissoes de sesoes recebidas do api
    useEffect(() => {
        setLocalSectionPermissions(topics_permissions);
    }, [topics_permissions]);

    useEffect(() => {
        let _users = localSectionPermissions.filter(
            (item) => typeof item.grupo === "object" && typeof item.nome_grupo === "object"
        );
        let _groups = localSectionPermissions.filter(
            (item) => typeof item.username === "object" && typeof item.nome === "object"
        );

        _users = _users.map((item) => ({
            username: item.username,
            name: item.nome,
            sala: item.sala,
        }));

        _groups = _groups.map((item) => ({
            id: item.grupo,
            value: item.nome_grupo,
            sala: item.sala,
        }));

        setListOfTopicsPermissions({ users: _users, groups: _groups });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localSectionPermissions]);

    const addItem = () => {
        setListOfRooms([
            {
                nome: "",
                descricao: "",
                topico: "",
                aberto: "f",
                permissions: {
                    users: [],
                    groups: [],
                },
            },
            ...listOfRooms,
        ]);
    };

    const removeItem = (selectedRowKeys) => {
        if (selectedRowKeys.length === 0) {
            message.warn("Nenhuma linha foi selecionada!");
            return;
        }

        let currentState = [];
        let newValues = [];
        currentState = listOfRooms;

        currentState.forEach((item, idx) => {
            if (!selectedRowKeys.includes(idx)) {
                newValues.push(item);
            }
        });

        setListOfRooms(newValues);
    };

    const onChange = (key, value, row) => {
        let newValues = [];
        let currentState = listOfRooms;

        currentState.forEach((it, idx) => {
            if (idx === row.__key__) {
                it[key] = value;
            }
            newValues.push(it);
        });

        setListOfRooms(newValues);
    };

    const handlerOk = () => {
        if (selectTab === "salas") {
            const saveRooms = listOfRooms.map((it) => {
                const { permissions, permissao, ...others } = it;

                let newItem = {
                    ...others,
                    autorizacao: {
                        users: permissions.users.map((it) => it.username).join(";"),
                        groups: permissions.groups.map((it) => it.id).join(";"),
                    },
                };

                return newItem;
            });

            createRooms(saveRooms)
                .then((res) => {
                    message.success("Salas guardadas com sucesso.");
                    getForumRooms();
                    getUserForumRooms();
                })
                .catch((e) => {
                    message.error("Ocorreu um erro.");
                });
        } else {
            const perm = localSectionPermissions.map((it) => ({
                sala: it.sala,
                username: typeof it.username === "string" ? it.username : "",
                grupo: typeof it.grupo === "string" ? it.grupo : "",
            }));

            saveTopicsPermissions(perm)
                .then((res) => {
                    message.success("Salas guardadas com sucesso.");
                    getTopicsPermission();
                })
                .catch((e) => {
                    message.error("Ocorreu um erro.");
                });
        }
    };

    // const handlerSaveTopicsPermissions = () => {
    //     saveTopicsPermissions()
    //         .then(res => {
    //             message.success("Salas guardadas com sucesso.");
    //             getTopicsPermission();
    //         })
    //         .catch(e => {
    //             message.error("Erro ao salvar dados.");
    //         });
    // };

    const columns = (onChange) => [
        {
            title: "Nome",
            dataIndex: "nome",
            rowKey: "nome",
            render: (_data, _allData) => {
                return <Input value={_data} onChange={(e) => onChange("nome", e.target.value, _allData)} />;
            },
        },
        {
            title: "Tópico",
            dataIndex: "topico",
            rowKey: "topico",
            render: (_data, _allData) => {
                return (
                    <QADynamicSelect
                        placeholder=""
                        name="forum_topico"
                        value={_data}
                        listValue="designacao"
                        listKey="id_forum_topico"
                        onChange={(type) => onChange("topico", type, _allData)}
                    />
                );
            },
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            rowKey: "descricao",
            render: (_data, _allData) => {
                return <Input value={_data} onChange={(e) => onChange("descricao", e.target.value, _allData)} />;
            },
        },
        {
            title: "Aberto a todos?",
            dataIndex: "aberto",
            rowKey: "aberto",
            render: (_data, _allData) => {
                return (
                    <Checkbox
                        checked={_data === "t"}
                        onChange={(e) => onChange("aberto", e.target.checked ? "t" : "f", _allData)}
                    />
                );
            },
        },
        {
            title: "Autorização",
            dataIndex: "autorizations",
            rowKey: "autorizations",
            render: (_, _allData) => {
                return (
                    <Button
                        disabled={_allData.aberto === "t"}
                        icon="edit"
                        onClick={() => {
                            setSelectTableRow(_allData);
                            setIsAutorizationVisivel(true);
                            setPermissionsType("rooms");
                        }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <SelectUserAndGroup
                visible={isAutorizationVisivel}
                setVisible={setIsAutorizationVisivel}
                defaultGroups={groups}
                defaultUsers={users}
                onOk={(users, groups) => {
                    // const autorizations = {
                    //     users: users.map(it => it.username).join(";"),
                    //     groups: groups.map(it => it.id).join(";")
                    // };

                    if (permissionsType === "rooms") {
                        onChange("permissions", { groups, users }, selectTableRow);
                    } else {
                        // handlerSaveTopicsPermissions(autorizations);
                        setLocalSectionPermissions([
                            ...localSectionPermissions.filter((it) => it.sala !== selectSectorPermission),
                            ...users.map((it) => ({
                                username: it.username,
                                nome: it.name,
                                nome_grupo: {},
                                grupo: {},
                                sala: selectSectorPermission,
                            })),
                            ...groups.map((it) => ({
                                nome_grupo: it.value,
                                grupo: it.id,
                                username: {},
                                nome: {},
                                sala: selectSectorPermission,
                            })),
                        ]);
                    }
                    setIsAutorizationVisivel(false);
                }}
            />

            <Modal
                title="Configuração do Fórum"
                visible={modalForumConfState}
                onOk={() => handlerOk()}
                width="80%"
                destroyOnClose
                // okText="Salvar"
                cancelText="Cancelar"
                onCancel={() => changeForumConfModalState(false)}
                footer={[
                    // <Button
                    //     key="permissao"
                    //     onClick={() => {
                    //         setPermissionsType("topics");
                    //         setUsers(listOfTopicsPermissions.users);
                    //         setGroups(listOfTopicsPermissions.groups);
                    //         setIsAutorizationVisivel(true);
                    //     }}
                    // >
                    //     Permissões de Atividades no Setor
                    // </Button>,
                    <Button
                        key="cancel"
                        onClick={() => {
                            changeForumConfModalState(false);
                        }}
                    >
                        Cancelar
                    </Button>,
                    <Button key="guardar" type="primary" onClick={() => handlerOk()}>
                        Guardar
                    </Button>,
                ]}
            >
                <Tabs defaultActiveKey="salas" onChange={(key) => setSelectTab(key)}>
                    <TabPane tab="Salas" key="salas">
                        <QADynamicTable
                            columns={columns(onChange)}
                            data={listOfRooms}
                            onRemoveItem={(selectedRowKeys) => removeItem(selectedRowKeys)}
                            addRowToTable={() => addItem()}
                        />
                    </TabPane>
                    <TabPane tab="Atividades no Setor" key="topics">
                        <QADynamicTable
                            columns={[
                                {
                                    title: "Nome",
                                    dataIndex: "title",
                                    rowKey: "title",
                                },
                                {
                                    title: "Autorização",
                                    dataIndex: "autorizations",
                                    rowKey: "autorizations",
                                    render: (_, _allData) => {
                                        return (
                                            <Button
                                                icon="edit"
                                                onClick={() => {
                                                    setSelectSectorPermission(_allData.key);

                                                    setUsers(
                                                        listOfTopicsPermissions.users.filter(
                                                            (it) => it.sala === _allData.key
                                                        )
                                                    );
                                                    setGroups(
                                                        listOfTopicsPermissions.groups.filter(
                                                            (it) => it.sala === _allData.key
                                                        )
                                                    );

                                                    setIsAutorizationVisivel(true);
                                                    setPermissionsType("topics");
                                                }}
                                            />
                                        );
                                    },
                                },
                            ]}
                            data={themes}
                            actions={false}
                        />
                    </TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        modalForumConfState: state.forum.modalForumConfState || false,
        formForumConf: state.forum.formForumConf || "",
        rooms: state.forum.forum_rooms,
        topics_permissions: state.forum.topics_permissions,
    };
};
const mapDispatchToProps = {
    changeForumConfModalState,
    createRooms,
    saveTopicsPermissions,
    getForumRooms,
    getTopicsPermission,
    getUserForumRooms,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumConf);
